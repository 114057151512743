import React from 'react';
import { Box } from 'rebass';
import { ThemeSelector } from './ThemeSelector';
export const ThemeStatusbar = () => {
  return React.createElement(Box, {
    onClick: event => {
      event.stopPropagation();
    }
  }, React.createElement(ThemeSelector, {
    size: "small"
  }));
};