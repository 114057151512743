import { PopupShowForm } from '../../Redux/ActionsReducers/PopupRedux';
import { ApiBase } from './ApiBase';
import { ActionRowInternalApi } from '../Internal/ActionRowInternalApi';
export class ActionRowApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.internalApi = new ActionRowInternalApi(adaptable);
  }
  getActionRowButtons() {
    var actionRowButtons = this.getActionRowOptions().actionRowButtons;
    if (typeof actionRowButtons === 'function') {
      const context = this.getAdaptableApi().internalApi.buildBaseContext();
      actionRowButtons = actionRowButtons(context);
    }
    return actionRowButtons !== null && actionRowButtons !== void 0 ? actionRowButtons : [];
  }
  displayEditActionRow(primaryKey) {
    const rowNode = this.getAdaptableApi().gridApi.getRowNodeForPrimaryKey(primaryKey);
    if (!rowNode) {
      this.logWarn(`Can NOT edit row: rowNode not found for primaryKey ${primaryKey}`);
    }
    const editForm = this.internalApi.buildRowEditForm(rowNode);
    this.dispatchAction(PopupShowForm({
      Id: 'edit_row_form',
      Form: editForm,
      // formProps are added to the formContext
      FormProps: {
        rowNode
      }
    }));
  }
  displayCreateActionRow() {
    const createForm = this.internalApi.buildRowCreateForm();
    this.dispatchAction(PopupShowForm({
      Id: 'create_row_form',
      Form: createForm
    }));
  }
  displayCloneActionRow(primaryKey) {
    const rowNode = this.getAdaptableApi().gridApi.getRowNodeForPrimaryKey(primaryKey);
    if (!rowNode) {
      this.logWarn(`Can NOT clone row: rowNode not found for primaryKey ${primaryKey}`);
    }
    const createForm = this.internalApi.buildRowCreateForm(rowNode);
    this.dispatchAction(PopupShowForm({
      Id: 'create_row_form',
      Form: createForm,
      // formProps are added to the formContext
      FormProps: {
        clonedRowNode: rowNode
      }
    }));
  }
}