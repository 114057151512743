import * as React from 'react';
import { forwardRef, useImperativeHandle } from 'react';
import { QuickFilterFormReact } from '../View/Components/FilterForm/QuickFilterForm';
const filterContainerStyle = {
  overflow: 'hidden',
  minWidth: '0',
  height: '100%',
  display: 'flex',
  alignItems: 'stretch',
  position: 'relative'
};
export const FloatingFilterWrapperFactory = adaptable => {
  function getContainerId(colId) {
    return `floatingFilter_${colId}_${adaptable.adaptableOptions.adaptableId}`;
  }
  function getFilterProps(colId) {
    const column = adaptable.api.columnApi.getColumnWithColumnId(colId);
    const columnFilterProps = {
      Column: column,
      Adaptable: adaptable,
      ShowCloseButton: false
    };
    return columnFilterProps;
  }
  function patchParentElement(filterContainer) {
    if (filterContainer) {
      // todo: consider theme
      filterContainer.parentElement.style.overflow = 'visible';
      filterContainer.parentElement.parentElement.style.padding = 'var(--ab-space-1)';
    }
  }
  if (adaptable.isAgGridInitialising) {
    if (adaptable.variant === 'react') {
      return () => React.createElement(React.Fragment, null);
    }
    return class FloatingFilterStub {
      init(params) {
        this.filterContainer = document.createElement('div');
      }
      getGui() {
        return this.filterContainer;
      }
      destroy() {
        var _a;
        (_a = this.unmountReactRoot) === null || _a === void 0 ? void 0 : _a.call(this);
        this.filterContainer = null;
      }
      onParentModelChanged() {
        // nothing yet
      }
    };
  }
  if (adaptable.variant === 'react') {
    return forwardRef(function ReactFloatingFilterWrapper(props, ref) {
      const colId = props.column.getId();
      const column = adaptable.api.columnApi.getColumnWithColumnId(colId);
      useImperativeHandle(ref, () => {
        return {
          onParentModelChanged() {}
        };
      });
      if (!column) {
        return null;
      }
      return React.createElement("div", {
        ref: patchParentElement,
        id: getContainerId(colId),
        style: filterContainerStyle
      }, QuickFilterFormReact(getFilterProps(colId)));
    });
  }
  return class FloatingFilterWrapper {
    onParentModelChanged(parentModel, filterChangedEvent) {
      // todo?
    }
    afterGuiAttached() {
      patchParentElement(this.filterContainer);
    }
    init(params) {
      const colId = params.column.getColId();
      this.filterContainer = document.createElement('div');
      this.filterContainer.id = getContainerId(colId);
      Object.keys(filterContainerStyle).forEach(key => {
        //@ts-ignore
        this.filterContainer.style[key] = filterContainerStyle[key];
      });
      const column = adaptable.api.columnApi.getColumnWithColumnId(colId);
      if (column) {
        const filterProps = getFilterProps(colId);
        this.unmountReactRoot = adaptable.renderReactRoot(QuickFilterFormReact(filterProps), this.filterContainer);
      }
    }
    getGui() {
      return this.filterContainer;
    }
    destroy() {
      var _a;
      (_a = this.unmountReactRoot) === null || _a === void 0 ? void 0 : _a.call(this);
      this.filterContainer = null;
    }
  };
};