import ObjectFactory from '../../../Utilities/ObjectFactory';
import { AlertType } from './getAlertType';
/**
 * This logic should be moved into Object factory once we have types on alert definitions
 * @param type AlertType
 */
export const getDefaultAlertDefinition = (alertDefinition, type) => {
  const newAlert = Object.assign(Object.assign({}, ObjectFactory.CreateEmptyAlertDefinition()), {
    Uuid: alertDefinition.Uuid
  });
  // can only be all
  if (type === AlertType.Observable || type === AlertType.Aggregation || type === AlertType.RowChange) {
    newAlert.Scope = {
      All: true
    };
  }
  if (type === AlertType.RowChange) {
    newAlert.Rule = {
      Predicates: [{
        PredicateId: 'AddedRow'
      }]
    };
  }
  if (type === AlertType.Validation) {
    newAlert.AlertProperties = {
      PreventEdit: true
    };
  }
  return newAlert;
};