import { EMPTY_ARRAY } from '../../Utilities/Constants/GeneralConstants';
import AdaptableHelper from '../../Utilities/Helpers/AdaptableHelper';
import { changeIsSuspendInList, suspendAllInList, unsuspendAllInList } from './utils';
/**
 * @ReduxAction An Alert Definition has been added
 */
export const ALERT_DEFINITION_ADD = 'ALERT_DEFINITION_ADD';
/**
 * @ReduxAction An Alert Definition has been edited
 */
export const ALERT_DEFINITION_EDIT = 'ALERT_DEFINITION_EDIT';
/**
 * @ReduxAction An Alert Definition has been deleted
 */
export const ALERT_DEFINITION_DELETE = 'ALERT_DEFINITION_DELETE';
/**
 * @ReduxAction Alert Definition is suspended
 */
export const ALERT_DEFINITION_SUSPEND = 'ALERT_DEFINITION_SUSPEND';
/**
 * @ReduxAction Alert Definition is unsuspended, or activated
 */
export const ALERT_DEFINITION_UNSUSPEND = 'ALERT_DEFINITION_UNSUSPEND';
/**
 * @ReduxAction All Alert Definitions are suspended
 */
export const ALERT_DEFINITION_SUSPEND_ALL = 'ALERT_DEFINITION_SUSPEND_ALL';
/**
 * @ReduxAction All Alert Definitions are unsuspended, or activated
 */
export const ALERT_DEFINITION_UNSUSPEND_ALL = 'ALERT_DEFINITION_UNSUSPEND_ALL';
export const ALERT_CLEAR_FLASHING_CELLS = 'ALERT_CLEAR_FLASHING_CELLS';
/**
 * @ReduxAction Alert Module is ready
 */
export const ALERT_READY = 'ALERT_READY';
export const AlertDefinitionAdd = alertDefinition => ({
  type: ALERT_DEFINITION_ADD,
  alertDefinition
});
export const AlertDefinitionEdit = alertDefinition => ({
  type: ALERT_DEFINITION_EDIT,
  alertDefinition
});
export const AlertDefinitionDelete = alertDefinition => ({
  type: ALERT_DEFINITION_DELETE,
  alertDefinition
});
export const AlertDefinitionSuspend = alertDefinition => ({
  type: ALERT_DEFINITION_SUSPEND,
  alertDefinition
});
export const AlertDefinitionUnSuspend = alertDefinition => ({
  type: ALERT_DEFINITION_UNSUSPEND,
  alertDefinition
});
export const AlertDefinitionSuspendAll = () => ({
  type: ALERT_DEFINITION_SUSPEND_ALL
});
export const AlertDefinitionUnSuspendAll = () => ({
  type: ALERT_DEFINITION_UNSUSPEND_ALL
});
export const AlertReady = alertState => ({
  type: ALERT_READY,
  alertState
});
export const AlertClearFlashingCells = () => ({
  type: ALERT_CLEAR_FLASHING_CELLS
});
const initialState = {
  AlertDefinitions: EMPTY_ARRAY
};
export const AlertReducer = (state = initialState, action) => {
  let alertDefinitions;
  switch (action.type) {
    case ALERT_DEFINITION_ADD:
      {
        const actionAlertDefinition = action.alertDefinition;
        AdaptableHelper.addUuidAndSource(actionAlertDefinition);
        alertDefinitions = [].concat(state.AlertDefinitions);
        alertDefinitions.push(actionAlertDefinition);
        return Object.assign(Object.assign({}, state), {
          AlertDefinitions: alertDefinitions
        });
      }
    case ALERT_DEFINITION_EDIT:
      {
        const actionAlertDefinition = action.alertDefinition;
        return Object.assign(Object.assign({}, state), {
          AlertDefinitions: state.AlertDefinitions.map(abObject => abObject.Uuid === actionAlertDefinition.Uuid ? actionAlertDefinition : abObject)
        });
      }
    case ALERT_DEFINITION_DELETE:
      {
        const actionAlertDefinition = action.alertDefinition;
        return Object.assign(Object.assign({}, state), {
          AlertDefinitions: state.AlertDefinitions.filter(abObject => abObject.Uuid !== actionAlertDefinition.Uuid)
        });
      }
    case ALERT_DEFINITION_SUSPEND:
      {
        return Object.assign(Object.assign({}, state), {
          AlertDefinitions: changeIsSuspendInList(action.alertDefinition, state.AlertDefinitions, true)
        });
      }
    case ALERT_DEFINITION_UNSUSPEND:
      {
        return Object.assign(Object.assign({}, state), {
          AlertDefinitions: changeIsSuspendInList(action.alertDefinition, state.AlertDefinitions, false)
        });
      }
    case ALERT_DEFINITION_SUSPEND_ALL:
      {
        return Object.assign(Object.assign({}, state), {
          AlertDefinitions: suspendAllInList(state.AlertDefinitions)
        });
      }
    case ALERT_DEFINITION_UNSUSPEND_ALL:
      {
        return Object.assign(Object.assign({}, state), {
          AlertDefinitions: unsuspendAllInList(state.AlertDefinitions)
        });
      }
    case ALERT_CLEAR_FLASHING_CELLS:
      {
        return {
          AlertDefinitions: state.AlertDefinitions
        };
      }
    default:
      return state;
  }
};