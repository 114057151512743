import { AdaptableModuleBase } from './AdaptableModuleBase';
import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
import { DataChangeHistoryStatusBarContent } from '../View/DataChangeHistory/DataChangeHistoryStatusBarContent';
import { ModuleNames } from '@ag-grid-community/core';
export class DataChangeHistoryModule extends AdaptableModuleBase {
  constructor(api) {
    super(ModuleConstants.DataChangeHistoryModuleId, ModuleConstants.DataChangeHistoryFriendlyName, 'target', 'DataChangeHistoryPopup', 'Provides an overview of all previous changes, giving the possibility to undo specific changes', api);
  }
  onAdaptableReady() {
    this.api.internalApi.getDataService().on('CellDataChanged', cellDataChangedInfo => {
      if (cellDataChangedInfo.trigger !== 'undo' && cellDataChangedInfo.trigger !== 'aggChange') {
        if (this.shouldLogDataChange(cellDataChangedInfo)) {
          this.api.dataChangeHistoryApi.addDataChangeHistoryEntry(cellDataChangedInfo);
        }
      }
    });
  }
  isModuleAvailable() {
    const isAdaptableModuleAvailable = super.isModuleAvailable();
    const isAgGridModuleAvailable = this.api.internalApi.getAdaptableInstance().agGridAdapter.isModulePresent(ModuleNames.ClientSideRowModelModule);
    if (isAdaptableModuleAvailable && !isAgGridModuleAvailable) {
      this.api.logWarn(`Data Change History is NOT available due to missing required AG Grid module: ${ModuleNames.ClientSideRowModelModule}`);
    }
    return isAdaptableModuleAvailable && isAgGridModuleAvailable;
  }
  getPopupMaxWidth() {
    return 1000;
  }
  hasNamedQueryReferences() {
    return false;
  }
  shouldLogDataChange(cellDataChangedInfo) {
    // we log changes only if the change tracking is active
    if (this.api.internalApi.getState().System.DataChangeHistory.currentMode !== 'ACTIVE') {
      return false;
    }
    if (this.api.optionsApi.getDataChangeHistoryOptions().showDataChange) {
      return this.api.optionsApi.getDataChangeHistoryOptions().showDataChange(cellDataChangedInfo);
    }
    return true;
  }
  getViewProperties() {
    return {
      getStatusBarPanelProps: () => {
        return {
          triggerActionOnWrapperClick: false,
          content: DataChangeHistoryStatusBarContent
        };
      }
    };
  }
}