import * as React from 'react';
import { Box } from 'rebass';
import { useAdaptable } from '../../../AdaptableContext';
import { OnePageAdaptableWizard } from '../../../Wizard/OnePageAdaptableWizard';
import { PreviewChartSection } from './PreviewChartSection';
import { isSettingsValid, SettingsSection } from './SettingsSection';
import { ObjectTagsWizardSection, renderObjectTagsSummary } from '../../../Wizard/ObjectTagsWizardSection';
export const AgChargingWizard = props => {
  const adaptable = useAdaptable();
  const [chartDefinition, setChartDefinition] = React.useState(() => {
    var _a, _b;
    return (_a = props.data) !== null && _a !== void 0 ? _a : (_b = props.popupParams) === null || _b === void 0 ? void 0 : _b.value;
  });
  const handleFinish = React.useCallback(() => {
    adaptable.api.chartingApi.editChartDefinition(chartDefinition);
    props.onFinishWizard(chartDefinition);
  }, [chartDefinition]);
  return React.createElement(OnePageAdaptableWizard, {
    defaultCurrentSectionName: props.defaultCurrentSectionName,
    moduleInfo: props.moduleInfo,
    data: chartDefinition,
    onHide: props.onCloseWizard,
    onFinish: handleFinish,
    sections: [{
      title: 'Settings',
      details: 'Chart Settings',
      isValid: isSettingsValid,
      render: () => React.createElement(Box, {
        padding: 2
      }, React.createElement(SettingsSection, {
        chartDefinition: chartDefinition,
        onChange: setChartDefinition
      }))
    }, {
      details: 'Select Chart Tags',
      title: 'Tags',
      isVisible: () => adaptable.api.internalApi.shouldDisplayTagSections(),
      render: () => React.createElement(Box, {
        padding: 2
      }, React.createElement(ObjectTagsWizardSection, {
        onChange: setChartDefinition
      })),
      renderSummary: renderObjectTagsSummary
    }, '-', {
      title: 'Preview Chart',
      details: 'Chart Preview',
      render: () => React.createElement(Box, {
        p: 2
      }, React.createElement(PreviewChartSection, {
        chartDefinition: chartDefinition,
        onChange: setChartDefinition
      }))
    }]
  });
};