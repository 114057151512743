import * as React from 'react';
import { Box, Flex, Text } from 'rebass';
import { CheckBox } from '../../../../components/CheckBox';
import DropdownButton from '../../../../components/DropdownButton';
import FormLayout, { FormRow } from '../../../../components/FormLayout';
import { Tabs } from '../../../../components/Tabs';
import { Tag } from '../../../../components/Tag';
import { useAdaptable } from '../../../AdaptableContext';
import { ValueSelector } from '../../../Components/ValueSelector';
import { useOnePageAdaptableWizardContext } from '../../../Wizard/OnePageAdaptableWizard';
import { columnFilter } from './Utilities';
import ArrayExtensions from '../../../../Utilities/Extensions/ArrayExtensions';
const WEIGHTED_AVERAGE_AGG_FN_NAME = 'weightedAvg';
export const isAggregationsSectionValid = data => {
  const weightedAvg = data.AggregationColumns ? Object.values(data.AggregationColumns).find(agg => typeof agg === 'object' && agg.type === 'weightedAverage') : null;
  if (weightedAvg && !weightedAvg.weightedColumnId) {
    return 'The Weighted Average Aggregation requires a Weighted Column';
  }
  return true;
};
export const AggregationsSectionSummary = () => {
  const adaptable = useAdaptable();
  const {
    data: layout
  } = useOnePageAdaptableWizardContext();
  const aggregations = layout.AggregationColumns;
  const entires = Object.entries(aggregations !== null && aggregations !== void 0 ? aggregations : {});
  let content = null;
  if (entires.length) {
    content = React.createElement(FormLayout, null, entires.map(([columnId, aggregation]) => React.createElement(FormRow, {
      key: columnId,
      label: adaptable.api.columnApi.getFriendlyNameForColumnId(columnId)
    }, React.createElement(Tag, null, typeof aggregation === 'object' ? aggregation.type : aggregation))));
  } else {
    content = React.createElement(Tag, null, "No Aggregations");
  }
  return React.createElement(Box, null, content);
};
const ColumnRow = props => {
  var _a, _b;
  const adaptable = useAdaptable();
  const aggValue = (_b = (_a = props.layout) === null || _a === void 0 ? void 0 : _a.AggregationColumns) === null || _b === void 0 ? void 0 : _b[props.column.columnId];
  const adaptableAggFunctions = [];
  if (props.column.dataType === 'Number') {
    adaptableAggFunctions.push(WEIGHTED_AVERAGE_AGG_FN_NAME);
  }
  const aggOptions = [...props.column.availableAggregationFunctions, ...adaptableAggFunctions].map(fnName => {
    return {
      label: fnName,
      onClick: () => {
        const aggCols = Object.assign({}, props.layout.AggregationColumns);
        if (!aggCols) {
          return;
        }
        if (fnName === WEIGHTED_AVERAGE_AGG_FN_NAME) {
          aggCols[props.column.columnId] = {
            type: 'weightedAverage',
            weightedColumnId: null
          };
        } else {
          aggCols[props.column.columnId] = fnName;
        }
        props.onChangeAggFunction(aggCols);
      }
    };
  });
  const numericColumnsOptions = props.numberColumns.filter(col => col.columnId !== props.column.columnId).map(col => {
    return {
      label: col.friendlyName,
      onClick: () => {
        const aggCols = Object.assign({}, props.layout.AggregationColumns);
        if (!aggCols) {
          return;
        }
        aggCols[props.column.columnId] = {
          type: 'weightedAverage',
          weightedColumnId: col.columnId
        };
        props.onChangeAggFunction(aggCols);
      }
    };
  });
  const currentAggFnName = props.aggregationColumnsMap[props.column.columnId];
  let weightName = null;
  if (typeof aggValue === 'object' && aggValue.type === 'weightedAverage') {
    weightName = aggValue.weightedColumnId ? adaptable.api.columnApi.getFriendlyNameForColumnId(aggValue.weightedColumnId) : 'Select Weight';
  }
  return React.createElement(Flex, {
    alignItems: "center"
  }, props.column.friendlyName, aggValue && React.createElement(DropdownButton, {
    columns: ['label'],
    items: aggOptions,
    ml: 2
  }, currentAggFnName), currentAggFnName === WEIGHTED_AVERAGE_AGG_FN_NAME && React.createElement(Flex, {
    backgroundColor: "primary",
    ml: 3,
    alignItems: "center"
  }, React.createElement(Text, null, "Weight"), ' ', React.createElement(DropdownButton, {
    columns: ['label'],
    items: numericColumnsOptions,
    ml: 2
  }, weightName)));
};
export const AggregationsSection = props => {
  var _a, _b;
  const adaptable = useAdaptable();
  const {
    data: layout
  } = useOnePageAdaptableWizardContext();
  const allAggregableColumns = adaptable.api.columnApi.getAggregatableColumns();
  const allColumns = adaptable.api.columnApi.getColumns();
  const numberColumns = adaptable.api.columnApi.getNumericColumns();
  const sortedAggregableColumns = React.useMemo(() => {
    var _a, _b;
    return ArrayExtensions.sortArrayWithOrder(allAggregableColumns.map(col => col.columnId), (_b = Object.keys((_a = layout.AggregationColumns) !== null && _a !== void 0 ? _a : {})) !== null && _b !== void 0 ? _b : [], {
      sortUnorderedItems: false
    }).map(colId => adaptable.api.columnApi.getColumnWithColumnId(colId));
  }, [layout, allAggregableColumns]);
  const handleColumnsSelectionChange = React.useCallback(columnIds => {
    props.onChange(Object.assign(Object.assign({}, layout), {
      AggregationColumns: columnIds.reduce((acc, colId) => {
        var _a, _b;
        acc[colId] = (_b = (_a = layout.AggregationColumns) === null || _a === void 0 ? void 0 : _a[colId]) !== null && _b !== void 0 ? _b : adaptable.api.columnApi.getDefaultAggFunc(colId);
        return acc;
      }, {})
    }));
  }, [layout]);
  const handleAggregationChange = React.useCallback(aggFunctions => {
    props.onChange(Object.assign(Object.assign({}, layout), {
      AggregationColumns: aggFunctions
    }));
  }, [layout]);
  const aggregationColumnsMap = React.useMemo(() => {
    const allColumnsMap = allColumns.reduce((acc, col) => {
      acc[col.columnId] = col;
      return acc;
    }, {});
    return Object.keys(layout.AggregationColumns || {}).reduce((acc, colId) => {
      var _a;
      let fn = (_a = layout.AggregationColumns) === null || _a === void 0 ? void 0 : _a[colId];
      let fnName = '';
      if (typeof fn === 'boolean') {
        fnName = allColumnsMap[colId].aggregationFunction;
      }
      if (typeof fn === 'object' && fn.type === 'weightedAverage') {
        fnName = WEIGHTED_AVERAGE_AGG_FN_NAME;
      } else if (typeof fn === 'string') {
        fnName = fn;
      }
      acc[colId] = fnName;
      return acc;
    }, {});
  }, [layout]);
  const handleSuppressAggFuncInHeader = checked => {
    props.onChange(Object.assign(Object.assign({}, layout), {
      SuppressAggFuncInHeader: checked
    }));
  };
  return React.createElement(Tabs, {
    style: {
      height: '100%'
    }
  }, React.createElement(Tabs.Tab, null, "Column Aggregations"), React.createElement(Tabs.Content, null, React.createElement(Flex, null, React.createElement(FormLayout, null, React.createElement(CheckBox, {
    checked: layout.SuppressAggFuncInHeader,
    onChange: handleSuppressAggFuncInHeader
  }, "Suppress Aggregation Function in Header"))), React.createElement(ValueSelector, {
    showFilterInput: true,
    showSelectedOnlyPosition: "top",
    filter: columnFilter,
    toIdentifier: option => `${option.columnId}`,
    toLabel: option => {
      var _a;
      return (_a = option.friendlyName) !== null && _a !== void 0 ? _a : option.columnId;
    },
    toListLabel: column => React.createElement(ColumnRow, {
      onChangeAggFunction: handleAggregationChange,
      layout: layout,
      column: column,
      aggregationColumnsMap: aggregationColumnsMap,
      numberColumns: numberColumns
    }),
    options: sortedAggregableColumns,
    value: (_b = Object.keys((_a = layout.AggregationColumns) !== null && _a !== void 0 ? _a : {})) !== null && _b !== void 0 ? _b : [],
    allowReorder: () => false,
    xSelectedLabel: () => {
      return `Active aggregations:`;
    },
    onChange: handleColumnsSelectionChange
  })));
};