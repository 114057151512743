import * as React from 'react';
import { StringExtensions } from '../../../Utilities/Extensions/StringExtensions';
import Dialog from '../../../components/Dialog';
import { Flex, Box } from 'rebass';
import SimpleButton from '../../../components/SimpleButton';
import Input from '../../../components/Input';
import { useDispatch } from 'react-redux';
export const AdaptablePopupPrompt = props => {
  var _a;
  const dispatch = useDispatch();
  const [promptText, setPromptText] = React.useState((_a = props.defaultValue) !== null && _a !== void 0 ? _a : '');
  const onCloseForm = () => {
    setPromptText('');
    props.onClose();
  };
  const onConfirmForm = () => {
    props.onConfirm(promptText);
    const confirmationAction = typeof props.onConfirmActionCreator === 'function' && props.onConfirmActionCreator(promptText);
    if (confirmationAction) {
      dispatch(confirmationAction);
    }
    setPromptText('');
  };
  const changeContent = e => {
    setPromptText(e.target.value);
  };
  return React.createElement(Dialog, {
    modal: true,
    "data-name": `prompt-popup`,
    isOpen: true,
    onDismiss: props.onClose,
    showCloseButton: false,
    style: {
      minHeight: 'auto',
      maxWidth: '50%'
    }
  }, React.createElement(Flex, {
    flexDirection: "column"
  }, React.createElement(Box, {
    marginTop: 3,
    mx: 2
  }, props.header), StringExtensions.IsNotNullOrEmpty(props.message) && React.createElement(Box, {
    marginTop: 3,
    mx: 2
  }, ' ', React.createElement("div", {
    style: {
      display: 'flex',
      alignItems: 'center'
    }
  }, props.message.split('\n').map(function (item, index) {
    return React.createElement("span", {
      key: index
    }, item, React.createElement("br", null));
  }))), React.createElement(Input, {
    autoFocus: true,
    marginTop: 3,
    mx: 3,
    value: promptText,
    type: "string",
    placeholder: "Enter text",
    onChange: e => changeContent(e)
  }), React.createElement(Box, {
    marginTop: 3
  }, React.createElement(Flex, {
    padding: 2
  }, React.createElement(SimpleButton, {
    tone: "accent",
    "data-name": "ok",
    variant: "raised",
    disabled: StringExtensions.IsNullOrEmpty(promptText),
    onClick: () => onConfirmForm()
  }, "OK"), React.createElement("div", {
    style: {
      flex: 1
    }
  }), React.createElement(SimpleButton, {
    tone: "neutral",
    variant: "raised",
    onClick: () => onCloseForm(),
    "data-name": "cancel"
  }, "Cancel")))));
};