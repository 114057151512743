import * as React from 'react';
import SimpleButton from '../../../components/SimpleButton';
export const ButtonClone = props => {
  return React.createElement(SimpleButton, Object.assign({
    "data-name": "clone",
    tooltip: "Clone",
    tone: "accent",
    icon: "clone",
    variant: "raised"
  }, props), props.children === undefined ? 'Clone' : props.children);
};