import * as React from 'react';
import { NewScopeComponent, renderScopeSummary } from '../../Components/NewScopeComponent';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
import { DEFAULT_PREDICATE_ID_FOR_FORMAT_COLUMN } from './constants';
export const renderFormatColumnScopeSummary = data => {
  return renderScopeSummary(data.Scope, {
    scopeWholeRow: 'Matching rows will be formatted',
    scopeColumns: 'Cells in selected columns will be formatted',
    scopeDataTypes: 'Cells in columns with these Data Types will be formatted'
  });
};
export const FormatColumnScopeWizardSection = props => {
  const {
    data,
    api
  } = useOnePageAdaptableWizardContext();
  return React.createElement(NewScopeComponent, {
    descriptions: {
      rowScope: 'Format all columns in the row',
      columnScope: 'Select columns to format'
    },
    scope: data.Scope,
    updateScope: Scope => {
      var _a, _b;
      const newData = Object.assign(Object.assign({}, data), {
        Scope
      });
      const wholeRow = api.columnScopeApi.scopeIsAll(Scope);
      if ((_b = (_a = newData.Rule) === null || _a === void 0 ? void 0 : _a.Predicates) === null || _b === void 0 ? void 0 : _b.length) {
        if (wholeRow) {
          // if scope is whole row, a predicate cannot be present, so we set the rule
          // to be a boolean expression
          delete newData.Rule.Predicates;
          newData.Rule.BooleanExpression = '';
        } else {
          // if scope is not whole row, if you have a predicate, reset it
          newData.Rule.Predicates = [{
            PredicateId: DEFAULT_PREDICATE_ID_FOR_FORMAT_COLUMN
          }];
        }
      }
      props.onChange(newData);
    }
  });
};