import { ApiBase } from '../Implementation/ApiBase';
export class ScheduleInternalApi extends ApiBase {
  /**
   * Fires Schedule Triggered event
   */
  fireScheduleTriggeredEvent(schedule) {
    const adaptableApi = this.getAdaptableApi();
    const scheduleTriggeredInfo = Object.assign(Object.assign({}, this.getAdaptableApi().internalApi.buildBaseContext()), {
      schedule: schedule
    });
    adaptableApi.eventApi.emit('ScheduleTriggered', scheduleTriggeredInfo);
  }
}