import * as React from 'react';
import EmptyContent from '../../components/EmptyContent';
import { DataSource, InfiniteTable } from '../../components/InfiniteTable';
const columnsMap = new Map([['Operation', {
  field: 'Operation',
  defaultFlex: 1
}], ['Value', {
  field: 'Value',
  defaultFlex: 1
}]]);
const tableDOMProps = {
  style: {
    height: '100%',
    minWidth: '20rem',
    minHeight: 380
  }
};
export class CellSummaryDetails extends React.Component {
  render() {
    let data = [];
    if (this.props.CellSummary != null) {
      data = Object.keys(this.props.CellSummary).map(operationName => {
        const operationLabel = operationName === 'Std_Deviation' ? 'Standard Deviation' : operationName;
        return {
          Operation: operationLabel,
          Value: this.props.CellSummary[operationName]
        };
      });
    }
    return React.createElement(React.Fragment, null, this.props.CellSummary != null ? React.createElement(DataSource, {
      data: data,
      primaryKey: "Operation"
    }, React.createElement(InfiniteTable, {
      domProps: tableDOMProps,
      columns: columnsMap
    })) : React.createElement(EmptyContent, null, React.createElement("p", null, "No cells are selected - please select some cells.")));
  }
}