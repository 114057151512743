import * as React from 'react';
import { Box, Flex, Text } from 'rebass';
import HelpBlock from '../../../../components/HelpBlock';
import { useAdaptable } from '../../../AdaptableContext';
import { ButtonInfo } from '../../Buttons/ButtonInfo';
import { PredicateDocsLink } from '../../../../Utilities/Constants/DocumentationLinkConstants';
import SimpleButton from '../../../../components/SimpleButton';
import { Tag } from '../../../../components/Tag';
import { EntityRulePredicateEditor } from './EntityRulePredicateEditor';
import Radio from '../../../../components/Radio';
import { getScopeViewItems } from '../../../../Strategy/Utilities/getScopeViewItems';
export const EntityRulePredicatesEditor = props => {
  var _a, _b, _c, _d, _e;
  const {
    api
  } = useAdaptable();
  const showDocumentationLinks = api.internalApi.isDocumentationLinksDisplayed();
  const columnPredicateEnabled = props.data.Rule.Predicates.some(predicate => predicate.ColumnId !== undefined);
  // filter out predicates that are already in use
  const defaultPredicateDefs = props.predicateDefs.filter(predicateDef => {
    var _a, _b;
    return (_b = (_a = props.data.Rule) === null || _a === void 0 ? void 0 : _a.Predicates) === null || _b === void 0 ? void 0 : _b.every(rulePrediate => rulePrediate.PredicateId !== predicateDef.id);
  });
  const handlePredicateScopeTypeChange = columnPredicateEnabled => {
    if (columnPredicateEnabled) {
      props.onChange(Object.assign(Object.assign({}, props.data), {
        Rule: {
          Predicates: [{
            PredicateId: null,
            ColumnId: ''
          }]
        }
      }));
    } else {
      props.onChange(Object.assign(Object.assign({}, props.data), {
        Rule: {
          Predicates: []
        }
      }));
    }
  };
  const handleAddNewPredicate = () => {
    var _a;
    props.onChange(Object.assign(Object.assign({}, props.data), {
      Rule: {
        Predicates: [...(((_a = props.data.Rule) === null || _a === void 0 ? void 0 : _a.Predicates) || []), {
          PredicateId: defaultPredicateDefs[0].id,
          Inputs: []
        }]
      }
    }));
  };
  const scopeString = getScopeViewItems(props.data.Scope, api).values.join(', ');
  const enablePredicateColumnId = (_a = props.enablePredicateColumnId) !== null && _a !== void 0 ? _a : true;
  return React.createElement(React.Fragment, null, React.createElement(Flex, {
    mb: 2,
    justifyContent: "space-between"
  }, React.createElement(Text, {
    fontSize: 2,
    mb: 2
  }, props.descriptions.selectPredicate), React.createElement(SimpleButton, {
    disabled: !((_c = (_b = props.data.Rule) === null || _b === void 0 ? void 0 : _b.Predicates) === null || _c === void 0 ? void 0 : _c.length),
    onClick: handleAddNewPredicate,
    icon: "plus",
    variant: "raised"
  }, "Add Predicate")), enablePredicateColumnId && React.createElement(Flex, {
    className: "ab-EntityRulePredicateEditor-ScopeTypeSelector",
    p: 2,
    mb: 2,
    flexDirection: "column"
  }, React.createElement(Text, {
    mb: 2
  }, "Create Predicate Rule(s) using"), React.createElement(Radio, {
    "data-name": "entity-scope",
    onClick: () => handlePredicateScopeTypeChange(false),
    mr: 3,
    checked: !columnPredicateEnabled
  }, "Active Column:", ' ', React.createElement(Tag, {
    ml: 1,
    mr: 1
  }, scopeString || 'Not Defined'), ' '), React.createElement(Radio, {
    "data-name": "column-scope",
    onClick: () => handlePredicateScopeTypeChange(true),
    checked: columnPredicateEnabled
  }, "Selected Columns (overriding Active Column)")), (((_e = (_d = props.data.Rule) === null || _d === void 0 ? void 0 : _d.Predicates) === null || _e === void 0 ? void 0 : _e.length) ? props.data.Rule.Predicates : [null]).map((predicate, index) => {
    const currentPredicatDef = props.predicateDefs.find(pd => pd.id === (predicate === null || predicate === void 0 ? void 0 : predicate.PredicateId));
    let editorPredicateDefs = defaultPredicateDefs;
    if (currentPredicatDef) {
      editorPredicateDefs = [currentPredicatDef, ...defaultPredicateDefs];
    }
    return React.createElement(React.Fragment, {
      key: `${index}-${predicate === null || predicate === void 0 ? void 0 : predicate.PredicateId}`
    }, index > 0 && React.createElement(Box, null, React.createElement(Tag, {
      mb: 3
    }, "AND")), React.createElement(EntityRulePredicateEditor, {
      data: props.data,
      predicate: predicate,
      onChange: props.onChange,
      predicateDefs: editorPredicateDefs,
      getPredicateDefsForColId: props.getPredicateDefsForColId,
      columnPredicateEnabled: columnPredicateEnabled
    }));
  }), showDocumentationLinks && React.createElement(HelpBlock, {
    "data-name": "query-documentation",
    mt: 3,
    mb: 2,
    style: {
      fontSize: 'var(--ab-font-size-3)',
      padding: 0
    }
  }, React.createElement(ButtonInfo, {
    mr: 2,
    onClick: () => window.open(PredicateDocsLink, '_blank')
  }), "See Predicate documentation for more details and examples"));
};