import { __rest } from "tslib";
import React from 'react';
import { Flex } from 'rebass';
import { StylePreview } from '../../components/StylePreview';
export const FlashingCellStyle = props => {
  const {
      data
    } = props,
    boxProps = __rest(props, ["data"]);
  return React.createElement(Flex, Object.assign({
    flexDirection: "row"
  }, boxProps), React.createElement(StylePreview, {
    "data-value": "up-style",
    mr: 1,
    flex: 1,
    styleObject: props.data.UpChangeStyle
  }, "Up ", !props.data.UpChangeStyle ? ' - no style' : ''), React.createElement(StylePreview, {
    "data-value": "down-style",
    mr: 1,
    flex: 1,
    styleObject: props.data.DownChangeStyle
  }, "Down", !props.data.DownChangeStyle ? ' - no style' : ''), React.createElement(StylePreview, {
    "data-value": "neutral-style",
    flex: 1,
    styleObject: props.data.NeutralChangeStyle
  }, "Neutral ", !props.data.NeutralChangeStyle ? ' - no style' : ''));
};