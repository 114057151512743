import * as React from 'react';
import { useState } from 'react';
import { Box, Flex, Text } from 'rebass';
import Radio from '../../components/Radio';
import { CheckBox } from '../../components/CheckBox';
import { Tabs } from '../../components/Tabs';
import { SummaryText } from '../Wizard/OnePageAdaptableWizard';
import { NewColumnSelector } from './ColumnSelector';
import { AdaptableFormControlTextClear } from './Forms/AdaptableFormControlTextClear';
import { useAdaptable } from '../AdaptableContext';
import { ValueOptionsTags } from './ValueSelector';
export const isScopeValid = ({
  Scope
}) => {
  const result = [];
  if (!Scope) {
    return 'No scope specified or scope is empty';
  }
  if (Scope && 'ColumnIds' in Scope && Scope.ColumnIds.length === 0) {
    result.push('No Columns selected in Scope');
  }
  if (Scope && 'DataTypes' in Scope && Scope.DataTypes.length === 0) {
    result.push('No Data Types selected in Scope');
  }
  return result.length ? result.join(', ') : true;
};
const DATA_TYPES_MAP = {
  Date: {
    label: 'Date',
    value: 'Date'
  },
  Number: {
    label: 'Number',
    value: 'Number'
  },
  String: {
    label: 'String',
    value: 'String'
  },
  Boolean: {
    label: 'Boolean',
    value: 'Boolean'
  }
};
export const renderScopeSummary = (scope, labels) => {
  const adaptable = useAdaptable();
  const scopeApi = adaptable.api.columnScopeApi;
  const columnsInScope = scopeApi.getColumnsForScope(scope);
  return React.createElement(React.Fragment, null, React.createElement(Box, null, React.createElement(SummaryText, {
    mb: 0
  }, scopeApi.scopeIsAll(scope) ? labels.scopeWholeRow : null)), React.createElement(Box, {
    style: {
      overflow: 'hidden'
    }
  }, 'ColumnIds' in scope ? React.createElement(React.Fragment, null, React.createElement(SummaryText, {
    mb: columnsInScope.length ? 2 : 0
  }, labels.scopeColumns), columnsInScope.length ? React.createElement(ValueOptionsTags, {
    options: columnsInScope,
    value: columnsInScope.map(c => c.columnId),
    toLabel: c => c.friendlyName,
    allowWrap: true,
    readOnly: true,
    renderLabel: c => React.createElement(SummaryText, {
      mb: 0
    }, c),
    toIdentifier: c => c.columnId
  }) : null) : null), React.createElement(Box, null, 'DataTypes' in scope ? React.createElement(React.Fragment, null, React.createElement(Text, {
    fontSize: 2,
    mb: 2
  }, labels.scopeDataTypes), React.createElement(ValueOptionsTags, {
    readOnly: true,
    options: scope.DataTypes,
    value: scope.DataTypes,
    toLabel: c => c,
    allowWrap: true,
    renderLabel: c => React.createElement(Text, {
      fontSize: 2
    }, `DataType: ${c}`),
    toIdentifier: c => c
  })) : null));
};
const DATA_TYPES_OPTIONS = Object.values(DATA_TYPES_MAP);
export const NewScopeComponent = props => {
  var _a, _b;
  const {
    api
  } = useAdaptable();
  const {
    columnScopeApi: scopeApi,
    columnApi
  } = api;
  const [columnsSearchText, setColumnsSearchText] = useState('');
  const scopeColumns = React.useMemo(() => {
    const allColumns = props.scopeColumns || columnApi.getColumns();
    if (typeof props.isColumnAvailable === 'function') {
      return allColumns.filter(c => props.isColumnAvailable(c));
    }
    return allColumns;
  }, []);
  const getScopeChoice = scope => {
    if (!scope) {
      return undefined;
    }
    if (scopeApi.scopeIsAll(scope)) {
      return 'All';
    }
    if (scopeApi.scopeHasColumns(props.scope)) {
      return 'Column';
    }
    if (scopeApi.scopeHasDataType(props.scope)) {
      return 'DataType';
    }
    if (scopeApi.scopeHasColumnType(props.scope)) {
      return 'ColumnType';
    }
    return undefined;
  };
  const onScopeSelectChanged = value => {
    let newScope;
    if (value == 'Column') {
      newScope = {
        ColumnIds: []
      };
    } else if (value == 'DataType') {
      newScope = {
        DataTypes: []
      };
    } else if (value == 'ColumnType') {
      newScope = {
        ColumnTypes: []
      };
    } else {
      newScope = {
        All: true
      };
    }
    props.updateScope(newScope);
  };
  const onColumnsSelectedChanged = cols => {
    const newScope = {
      ColumnIds: cols
    };
    props.updateScope(newScope);
  };
  const onCheckBoxDataTypeChecked = (checked, item) => {
    let dataTypes = [].concat(scopeApi.getDataTypesInScope(props.scope));
    if (checked) {
      dataTypes.push(item);
    } else {
      const index = dataTypes.indexOf(item, 0);
      if (index > -1) {
        dataTypes.splice(index, 1);
      }
    }
    let newScope = {
      DataTypes: dataTypes
    };
    props.updateScope(newScope);
  };
  const scopeChoice = getScopeChoice(props.scope);
  const dataTypesInScope = props.scope && 'DataTypes' in props.scope ? scopeApi.getDataTypesInScope(props.scope) : null;
  let dataTypeOptions = DATA_TYPES_OPTIONS;
  if (Array.isArray(props.availableDataTypes)) {
    dataTypeOptions = props.availableDataTypes.map(dataType => DATA_TYPES_MAP[dataType]);
  }
  const hasColumnTypes = React.useMemo(() => {
    var _a;
    return ((_a = api.columnApi.getColumnTypes()) === null || _a === void 0 ? void 0 : _a.length) > 0;
  }, []);
  return React.createElement(Tabs, {
    "data-name": 'scope-component',
    className: "ab-ScopeComponent",
    value: scopeChoice,
    style: Object.assign({
      height: '100%'
    }, props.style),
    onValueChange: onScopeSelectChanged
  }, props.hideWholeRow ? null : React.createElement(Tabs.Tab, {
    value: "Row"
  }, React.createElement(Radio, {
    margin: 0,
    checked: scopeChoice == 'All',
    tabIndex: -1
  }, "All Columns")), !props.disableColumns && React.createElement(Tabs.Tab, {
    value: "Column"
  }, React.createElement(Radio, {
    margin: 0,
    value: "Column",
    checked: scopeChoice == 'Column',
    tabIndex: -1
  }, "Selected Columns")), !props.disableDataTypes && React.createElement(Tabs.Tab, {
    value: "DataType"
  }, React.createElement(Radio, {
    margin: 0,
    value: "DataType",
    checked: scopeChoice == 'DataType',
    tabIndex: -1
  }, "Data Types")), hasColumnTypes && React.createElement(Tabs.Tab, {
    value: "ColumnType"
  }, React.createElement(Radio, {
    margin: 0,
    value: "ColumnType",
    checked: scopeChoice == 'ColumnType',
    tabIndex: -1
  }, "Column Types")), props.hideWholeRow ? null : React.createElement(Tabs.Content, {
    value: "Row",
    style: {
      flex: 'none'
    },
    "data-name": "row-scope"
  }, React.createElement(Text, {
    padding: 2,
    pl: 0,
    fontSize: 2
  }, props.descriptions.rowScope)), !props.disableColumns && React.createElement(Tabs.Content, {
    value: "Column",
    style: {
      flex: 1,
      overflow: 'auto'
    },
    "data-name": "column-scope",
    padding: 0
  }, React.createElement(Flex, {
    flexDirection: "row",
    alignItems: "center",
    padding: 2
  }, React.createElement(Text, {
    fontSize: 2
  }, props.descriptions.columnScope), React.createElement(Flex, {
    flex: 3
  }), React.createElement(AdaptableFormControlTextClear, {
    value: columnsSearchText,
    OnTextChange: setColumnsSearchText,
    placeholder: "Type to search columns",
    style: {
      flex: 1
    }
  })), React.createElement(Flex, {
    paddingLeft: 2,
    flex: 1,
    style: {
      overflow: 'hidden'
    }
  }, React.createElement(NewColumnSelector, {
    columnFilterText: columnsSearchText,
    allowReorder: false,
    availableColumns: scopeColumns,
    selected: scopeApi.getColumnIdsInScope(props.scope),
    onChange: onColumnsSelectedChanged
  }))), !props.disableDataTypes && React.createElement(Tabs.Content, {
    value: "DataType",
    style: {
      flex: 'none'
    },
    "data-name": "datatype-scope"
  }, React.createElement(Box, null, props.descriptions.dataTypeScope && React.createElement(Text, {
    p: 2,
    pl: 0,
    mb: 2,
    fontSize: 2
  }, props.descriptions.dataTypeScope), React.createElement(Flex, {
    flexDirection: "column"
  }, dataTypeOptions.map(dataTypeOption => React.createElement(CheckBox, {
    "data-name": "scope",
    "data-value": dataTypeOption.value,
    key: dataTypeOption.value,
    checked: dataTypesInScope && dataTypesInScope.includes(dataTypeOption.value),
    onChange: checked => onCheckBoxDataTypeChecked(checked, dataTypeOption.value)
  }, dataTypeOption.label))))), hasColumnTypes && React.createElement(Tabs.Content, {
    value: "ColumnType",
    style: {
      flex: 'none'
    },
    "data-name": "column-type-scope"
  }, React.createElement(Box, null, React.createElement(Flex, {
    flexDirection: "column"
  }, (_b = (_a = api.columnApi.getColumnTypes()) === null || _a === void 0 ? void 0 : _a.map) === null || _b === void 0 ? void 0 : _b.call(_a, columnType => {
    var _a;
    return React.createElement(CheckBox, {
      "data-name": "scope",
      "data-value": columnType,
      key: columnType,
      checked: 'ColumnTypes' in props.scope && ((_a = props.scope.ColumnTypes) === null || _a === void 0 ? void 0 : _a.includes(columnType)),
      onChange: checked => {
        let columnTypes = [].concat(props.scope.ColumnTypes);
        if (checked) {
          columnTypes.push(columnType);
        } else {
          columnTypes = columnTypes.filter(ct => ct !== columnType);
        }
        let newScope = {
          ColumnTypes: columnTypes
        };
        props.updateScope(newScope);
      }
    }, columnType);
  })))));
};