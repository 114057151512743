import * as React from 'react';
import { EnumExtensions } from '../../Utilities/Extensions/EnumExtensions';
import { SummaryOperation } from '../../PredefinedConfig/Common/Enums';
import ArrayExtensions from '../../Utilities/Extensions/ArrayExtensions';
import { CellSummaryPopover } from './CellSummaryPopover';
import { Flex } from 'rebass';
import * as GeneralConstants from '../../Utilities/Constants/GeneralConstants';
import { AdaptablePopover } from '../AdaptablePopover';
import * as SystemRedux from '../../Redux/ActionsReducers/SystemRedux';
import * as GridRedux from '../../Redux/ActionsReducers/GridRedux';
import { connect } from 'react-redux';
import { Select } from '../../components/Select';
class CellSummaryViewPanelComponent extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    let adaptable = this.props.api.internalApi.getAdaptableInstance();
    if (adaptable) {
      this.cleanupEvent = adaptable._on('CellsSelected', () => {
        this.checkSelectedCells();
      });
    }
    this.checkSelectedCells();
  }
  componentWillUnmount() {
    var _a;
    (_a = this.cleanupEvent) === null || _a === void 0 ? void 0 : _a.call(this);
  }
  render() {
    let operationMenuItems = EnumExtensions.getNames(SummaryOperation).map((summaryOperation, index) => {
      return {
        label: summaryOperation,
        value: summaryOperation
      };
    });
    const cellSummaryOperationDefinitions = this.props.api.cellSummaryApi.getCellSummaryOperationDefinitions();
    const operationDefinitions = ArrayExtensions.IsNullOrEmpty(cellSummaryOperationDefinitions) ? [] : cellSummaryOperationDefinitions.map(operationDefinition => {
      return {
        value: operationDefinition.operationName,
        label: operationDefinition.operationName
      };
    });
    let cellSummaryPopover = React.createElement(CellSummaryPopover, {
      CellSummary: this.props.CellSummary
    });
    let shouldDisable = this.props.accessLevel == 'ReadOnly' || this.props.api.internalApi.isGridInPivotMode() || this.props.CellSummary == null;
    const renderOperationValue = () => {
      if (shouldDisable) {
        return;
      }
      const operationValue = this.getOperationValue();
      if (operationValue == undefined) {
        return;
      }
      return this.props.viewType === 'ToolPanel' ? React.createElement(Flex, {
        className: `ab-${elementType}__CellSummary__value`,
        style: {
          borderRadius: 'var(--ab__border-radius)'
        },
        marginRight: 2,
        padding: 2,
        color: 'var( --ab-color-text-on-info)',
        backgroundColor: 'var(--ab-color-info)',
        fontSize: 'var( --ab-font-size-2)'
      }, operationValue) : React.createElement(Flex, {
        flex: 1,
        marginRight: 2,
        marginLeft: 1,
        justifyContent: "center",
        className: `ab-${elementType}__CellSummary__value`,
        color: 'var(--ab-color-text-on-primary)'
      }, operationValue);
    };
    const elementType = this.props.viewType === 'Toolbar' ? 'DashboardToolbar' : 'ToolPanel';
    return React.createElement(Flex, {
      flexDirection: "row",
      className: shouldDisable ? GeneralConstants.READ_ONLY_STYLE : `ab-${elementType}__CellSummary__wrap`,
      flexWrap: this.props.viewType === 'ToolPanel' ? 'wrap' : 'nowrap'
    }, React.createElement(Flex, {
      flex: 1
    }, React.createElement(Select, {
      style: {
        width: '100%'
      },
      className: `ab-${elementType}__CellSummary__select`,
      disabled: shouldDisable,
      options: [...operationMenuItems, ...operationDefinitions],
      onChange: x => this.props.onCellSummaryOperationChange(x),
      value: this.props.CellSummaryOperation
    })), React.createElement(Flex, {
      alignItems: "center",
      ml: 2
    }, React.createElement(React.Fragment, null, renderOperationValue(), this.props.CellSummary != null && this.props.CellSummary.Count > 0 && React.createElement(AdaptablePopover, {
      popoverMaxWidth: 360,
      className: "ab-ToolPanel__CellSummary__info",
      bodyText: [cellSummaryPopover],
      useButton: true,
      showEvent: 'focus',
      hideEvent: "blur"
    }))));
  }
  checkSelectedCells() {
    this.props.onCreateCellSummary();
  }
  getOperationValue() {
    const api = this.props.api;
    return api.cellSummaryApi.getCellSummaryOperationValue(this.props.CellSummaryOperation);
  }
}
function mapStateToProps(state, ownProps) {
  return {
    SelectedCellInfo: state.Grid.SelectedCellInfo,
    CellSummaryOperation: state.System.CellSummaryOperation,
    CellSummary: state.Grid.CellSummary
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onCellSummaryOperationChange: summaryOperation => dispatch(SystemRedux.SystemCellSummaryChangeOperation(summaryOperation)),
    onCreateCellSummary: () => dispatch(GridRedux.GridCreateCellSummary())
  };
}
export let CellSummaryViewPanelControl = connect(mapStateToProps, mapDispatchToProps)(CellSummaryViewPanelComponent);