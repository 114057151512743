import * as React from 'react';
import { Box, Flex } from 'rebass';
import DropdownButton from '../../../../components/DropdownButton';
import FormLayout, { FormRow } from '../../../../components/FormLayout';
import SimpleButton from '../../../../components/SimpleButton';
import { useAdaptable } from '../../../AdaptableContext';
import { PredicateEditor } from '../../PredicateEditor/PredicateEditor';
export const EntityRulePredicateEditor = props => {
  var _a, _b, _c, _d, _e;
  const {
    api
  } = useAdaptable();
  const {
    predicate
  } = props;
  const allColumns = React.useMemo(() => {
    return api.columnApi.getColumns().map(column => ({
      label: column.friendlyName,
      value: column.columnId
    }));
  }, []);
  const handleClear = () => {
    var _a, _b;
    const newPredicates = (_b = (_a = props.data.Rule) === null || _a === void 0 ? void 0 : _a.Predicates) === null || _b === void 0 ? void 0 : _b.filter(prevPredicate => prevPredicate !== predicate);
    props.onChange(Object.assign(Object.assign({}, props.data), {
      Rule: {
        Predicates: newPredicates
      }
    }));
  };
  const handlePredicateChange = newPredicateDef => {
    var _a, _b;
    let newPredicates = [];
    if (predicate) {
      newPredicates = (_b = (_a = props.data.Rule) === null || _a === void 0 ? void 0 : _a.Predicates) === null || _b === void 0 ? void 0 : _b.map(prevPredicate => {
        if (prevPredicate === predicate) {
          return newPredicateDef;
        }
        return prevPredicate;
      });
    } else {
      // no previous predicate, e.g. it was cleared
      newPredicates = [newPredicateDef];
    }
    props.onChange(Object.assign(Object.assign({}, props.data), {
      Rule: {
        Predicates: newPredicates
      }
    }));
  };
  const predicateDefs = React.useMemo(() => {
    var _a;
    let preparedPredicates = [];
    if (props.getPredicateDefsForColId && ((_a = props === null || props === void 0 ? void 0 : props.predicate) === null || _a === void 0 ? void 0 : _a.ColumnId)) {
      preparedPredicates = props.getPredicateDefsForColId(props.predicate.ColumnId);
    } else {
      preparedPredicates = props.predicateDefs;
    }
    return preparedPredicates;
  }, [props.predicate]);
  const columnIdPredicateScopeEnabled = Boolean(props.columnPredicateEnabled);
  const columnFriendlyName = (_a = allColumns.find(col => col.value === (predicate === null || predicate === void 0 ? void 0 : predicate.ColumnId))) === null || _a === void 0 ? void 0 : _a.label;
  const columnOptions = allColumns.map(col => ({
    label: col.label,
    onClick: () => {
      handlePredicateChange({
        PredicateId: null,
        ColumnId: col.value,
        Inputs: []
      });
    }
  }));
  // use global scope or custom scope
  let predicateColumnId = null;
  if ((predicate === null || predicate === void 0 ? void 0 : predicate.ColumnId) && predicate.ColumnId !== '') {
    predicateColumnId = predicate.ColumnId;
  } else if ('ColumnIds' in props.data.Scope && ((_c = (_b = props.data.Scope) === null || _b === void 0 ? void 0 : _b.ColumnIds) === null || _c === void 0 ? void 0 : _c[0])) {
    predicateColumnId = (_e = (_d = props.data.Scope) === null || _d === void 0 ? void 0 : _d.ColumnIds) === null || _e === void 0 ? void 0 : _e[0];
  }
  return React.createElement(Flex, {
    className: "ab-EntityRulePredicateEditor",
    p: 3,
    mb: 3
  }, React.createElement(Box, {
    flex: 1
  }, React.createElement(FormLayout, null, columnIdPredicateScopeEnabled && React.createElement(FormRow, {
    label: "Column"
  }, React.createElement(DropdownButton, {
    width: "100%",
    columns: ['label'],
    items: columnOptions
  }, columnFriendlyName !== null && columnFriendlyName !== void 0 ? columnFriendlyName : 'Select Column')), React.createElement(FormRow, {
    label: "Predicate"
  }, React.createElement(PredicateEditor, {
    columnId: predicateColumnId,
    predicateDefs: predicateDefs,
    predicate: predicate,
    onChange: handlePredicateChange,
    onClear: handleClear
  })))), React.createElement(Box, null, React.createElement(SimpleButton, {
    disabled: !predicate,
    ml: 2,
    onClick: handleClear,
    icon: "delete"
  })));
};