import { readJSONFile } from '../../components/FileDroppable';
const jsonHandler = {
  fileExtension: '.json',
  handleFile: file => readJSONFile(file)
};
export const parseCSV = content => {
  const lines = content.split('\n');
  const headers = lines[0].split(',');
  const data = [];
  if (lines.length <= 1) {
    return null;
  }
  for (let i = 1; i < lines.length; i++) {
    const values = lines[i].split(',');
    if (values.length === headers.length) {
      const item = {};
      for (let j = 0; j < headers.length; j++) {
        const value = values[j];
        item[headers[j]] = value === '' || isNaN(Number(value)) ? value : Number(value); // '0012345'
      }
      data.push(item);
    }
  }
  return data;
};
const csvFileHandler = {
  fileExtension: '.csv',
  handleFile: file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = event => {
        var _a;
        const content = (_a = event.target) === null || _a === void 0 ? void 0 : _a.result;
        resolve(parseCSV(content));
      };
      reader.readAsText(file);
    });
  }
};
export const systemFileHandlers = [jsonHandler, csvFileHandler];