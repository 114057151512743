import { InitState } from '../../Redux/Store/AdaptableStore';
import { ApiBase } from './ApiBase';
import Helper from '../../Utilities/Helpers/Helper';
import { initPredefinedConfigWithUuids } from '../../Utilities/Helpers/AdaptableHelper';
import * as LayoutRedux from '../../Redux/ActionsReducers/LayoutRedux';
import * as DashboardRedux from '../../Redux/ActionsReducers/DashboardRedux';
import * as AlertRedux from '../../Redux/ActionsReducers/AlertRedux';
import * as BulkUpdateRedux from '../../Redux/ActionsReducers/BulkUpdateRedux';
import * as CalculatedColumnRedux from '../../Redux/ActionsReducers/CalculatedColumnRedux';
import * as ChartingRedux from '../../Redux/ActionsReducers/ChartingRedux';
import * as NoteRedux from '../../Redux/ActionsReducers/NoteRedux';
import * as CustomSortRedux from '../../Redux/ActionsReducers/CustomSortRedux';
import * as ExportRedux from '../../Redux/ActionsReducers/ExportRedux';
import * as FormatColumnRedux from '../../Redux/ActionsReducers/FormatColumnRedux';
import * as FreeTextColumnRedux from '../../Redux/ActionsReducers/FreeTextColumnRedux';
import * as PlusMinusRedux from '../../Redux/ActionsReducers/PlusMinusRedux';
import * as NamedQueryRedux from '../../Redux/ActionsReducers/NamedQueryRedux';
import * as QuickSearchRedux from '../../Redux/ActionsReducers/QuickSearchRedux';
import * as ScheduleRedux from '../../Redux/ActionsReducers/ScheduleRedux';
import * as ShortcutRedux from '../../Redux/ActionsReducers/ShortcutRedux';
import * as SystemStatusRedux from '../../Redux/ActionsReducers/SystemStatusRedux';
import * as SmartEditRedux from '../../Redux/ActionsReducers/SmartEditRedux';
import * as ThemeRedux from '../../Redux/ActionsReducers/ThemeRedux';
import * as ToolPanelRedux from '../../Redux/ActionsReducers/ToolPanelRedux';
export class ConfigApiImpl extends ApiBase {
  configInit() {
    this.dispatchAction(InitState());
  }
  copyAllStateToClipboard() {
    let state = this.getAdaptableState();
    let stringifiedState = JSON.stringify(state);
    Helper.copyToClipboard(stringifiedState);
  }
  copyUserStateToClipboard() {
    // This doesnt currently work...
    let state = this.getAdaptableState();
    //  let userState = state
    let stringifiedState = JSON.stringify(state);
    Helper.copyToClipboard(stringifiedState);
  }
  getPredefinedConfig() {
    return this.adaptable.adaptableOptions.predefinedConfig;
  }
  reloadPredefinedConfig(newPredefinedConfig) {
    var _a;
    const adaptableOptions = this.adaptable.adaptableOptions;
    const oldState = this.getPersistedState();
    // clearing filters so that AG Grid doesnt display the filter icon in a column header after grid reloads
    // but this is a hack so would be nicer if there was a better way to do this
    this.getAdaptableApi().gridApi.clearGridFiltering();
    const clearStateFn = ((_a = adaptableOptions.stateOptions) === null || _a === void 0 ? void 0 : _a.clearState) || (() => {
      localStorage.removeItem(adaptableOptions.adaptableStateKey);
      return Promise.resolve(true);
    });
    this.adaptable.adaptableStore.saveStateNow(this.adaptable).then(() => {
      const promise = clearStateFn({
        adaptableId: adaptableOptions.adaptableId,
        adaptableStateKey: adaptableOptions.adaptableStateKey,
        userName: adaptableOptions.userName
      });
      promise.then(() => {
        this.setAdaptableStateKey(adaptableOptions.adaptableStateKey, {
          predefinedConfig: newPredefinedConfig || adaptableOptions.predefinedConfig,
          flushCurrentState: false
        }).then(() => {
          const newState = this.getPersistedState();
          const stateReloadedInfo = Object.assign({
            oldState,
            newState,
            adaptableStateKey: adaptableOptions.adaptableStateKey
          }, this.getAdaptableApi().internalApi.buildBaseContext());
          this.getAdaptableApi().eventApi.emit('AdaptableStateReloaded', stateReloadedInfo);
        });
      });
    });
  }
  getAllState() {
    return this.getAdaptableState();
  }
  getPersistedState() {
    // no assertion should be required here as soon as we make order in our state typings
    return this.adaptable.adaptableStore.getCurrentStorageState();
  }
  getAllUserState() {
    return Object.values(this.getPersistedState());
  }
  loadUserState(state) {
    const adaptableOptions = this.adaptable.adaptableOptions;
    this.setAdaptableStateKey(adaptableOptions.adaptableStateKey, {
      predefinedConfig: state || adaptableOptions.predefinedConfig,
      flushCurrentState: false
    });
  }
  getAdaptableSearchState() {
    const gridFilter = this.getAdaptableApi().gridFilterApi.getCurrentGridFilterExpression();
    const adaptableSearchState = {
      dataSet: this.getAdaptableApi().dataSetApi.getCurrentDataSet(),
      gridFilter: gridFilter,
      gridFilterAST: gridFilter ? this.getAdaptableApi().expressionApi.getASTForExpression(gridFilter) : null,
      columnFilters: this.getAdaptableApi().columnFilterApi.getColumnFilters()
    };
    return adaptableSearchState;
  }
  getAdaptableSortState() {
    const adaptableSortState = {
      columnSorts: this.getAdaptableApi().gridApi.getColumnSorts(),
      customSorts: this.getAdaptableApi().customSortApi.getCustomSorts()
    };
    return adaptableSortState;
  }
  getAdaptableOptions() {
    return undefined;
  }
  setAdaptableStateKey(adaptableStateKey, config) {
    return new Promise((resolve, reject) => {
      var _a;
      this.getAdaptableApi().internalApi.executeWithProgressIndicator((_a = config === null || config === void 0 ? void 0 : config.progressIndicatorLabel) !== null && _a !== void 0 ? _a : `Initialising...`, () => {
        const flushCurrentState = !config || config.flushCurrentState !== false;
        // make sure we persist any "pending" changes to state - since stateOptions.debounceStateDelay can cause
        // the state to be persisted with a delay, which we dont want in this case
        const flushStatePromise = flushCurrentState ? this.adaptable.adaptableStore.saveStateNow(this.adaptable) : Promise.resolve(true);
        flushStatePromise.then(() => {
          this.adaptable.adaptableOptions.adaptableStateKey = adaptableStateKey;
          let predefinedConfig = config === null || config === void 0 ? void 0 : config.predefinedConfig;
          if (predefinedConfig) {
            predefinedConfig = initPredefinedConfigWithUuids(predefinedConfig);
          }
          const promise = this.adaptable.adaptableStore.loadStore({
            adaptable: this.adaptable,
            adaptableStateKey,
            predefinedConfig,
            postLoadHook: state => {
              const agGridOptions = {
                columnDefs: this.adaptable.agGridAdapter.getGridOption('columnDefs'),
                autoGroupColumnDef: this.adaptable.agGridAdapter.getGridOption('autoGroupColumnDef'),
                rowModelType: this.adaptable.agGridAdapter.getGridOption('rowModelType'),
                treeData: this.adaptable.agGridAdapter.getGridOption('treeData')
              };
              return this.adaptable.normalizeAdaptableState(state, agGridOptions);
            }
          });
          promise.then(() => {
            this.adaptable.updateColumnModelAndRefreshGrid({
              preemptiveColumnStateRefresh: true
            });
            this.adaptable.setLayout();
          }).then(() => {
            // resolve main(result) promise
            resolve();
          }, error => {
            // reject main(result) promise
            reject(error);
          }).catch(e => {
            this.logError('Error setting Adaptable State Key', e);
          }).finally(() => {
            this.getUserInterfaceApi().hideProgressIndicator();
          });
        });
      });
    });
  }
  getDescriptionForModule(module) {
    return this.adaptable.ModuleService.getModuleInfoByModule(module).Description;
  }
  getHelpPageForModule(module) {
    return this.adaptable.ModuleService.getModuleInfoByModule(module).HelpPage;
  }
  getUserStateByStateKey(stateKey, returnJson = false) {
    switch (stateKey) {
      case 'Alert':
        return returnJson ? JSON.stringify(this.getAdaptableState().Alert) : this.getAdaptableState().Alert;
      case 'Application':
        return returnJson ? JSON.stringify(this.getAdaptableState().Application) : this.getAdaptableState().Application;
      case 'CalculatedColumn':
        return returnJson ? JSON.stringify(this.getAdaptableState().CalculatedColumn) : this.getAdaptableState().CalculatedColumn;
      case 'Charting':
        return returnJson ? JSON.stringify(this.getAdaptableState().Charting) : this.getAdaptableState().Charting;
      case 'Note':
        return returnJson ? JSON.stringify(this.getAdaptableState().Note) : this.getAdaptableState().Note;
      case 'CustomSort':
        return returnJson ? JSON.stringify(this.getAdaptableState().CustomSort) : this.getAdaptableState().CustomSort;
      case 'Dashboard':
        return returnJson ? JSON.stringify(this.getAdaptableState().Dashboard) : this.getAdaptableState().Dashboard;
      case 'Export':
        return returnJson ? JSON.stringify(this.getAdaptableState().Export) : this.getAdaptableState().Export;
      case 'FormatColumn':
        return returnJson ? JSON.stringify(this.getAdaptableState().FormatColumn) : this.getAdaptableState().FormatColumn;
      case 'FreeTextColumn':
        return returnJson ? JSON.stringify(this.getAdaptableState().FreeTextColumn) : this.getAdaptableState().FreeTextColumn;
      case 'Layout':
        return returnJson ? JSON.stringify(this.getAdaptableState().Layout) : this.getAdaptableState().Layout;
      case 'PlusMinus':
        return returnJson ? JSON.stringify(this.getAdaptableState().PlusMinus) : this.getAdaptableState().PlusMinus;
      case 'NamedQuery':
        return returnJson ? JSON.stringify(this.getAdaptableState().NamedQuery) : this.getAdaptableState().NamedQuery;
      case 'QuickSearch':
        return returnJson ? JSON.stringify(this.getAdaptableState().QuickSearch) : this.getAdaptableState().QuickSearch;
      case 'Schedule':
        return returnJson ? JSON.stringify(this.getAdaptableState().Schedule) : this.getAdaptableState().Schedule;
      case 'Shortcut':
        return returnJson ? JSON.stringify(this.getAdaptableState().Shortcut) : this.getAdaptableState().Shortcut;
      case 'Theme':
        return returnJson ? JSON.stringify(this.getAdaptableState().Theme) : this.getAdaptableState().Theme;
      case 'ToolPanel':
        return returnJson ? JSON.stringify(this.getAdaptableState().ToolPanel) : this.getAdaptableState().ToolPanel;
    }
  }
  incrementUserStateRevision(stateKey) {
    let state = this.getUserStateByStateKey(stateKey);
    if (typeof state.Revision === 'number') {
      state.Revision += 1;
    } else {
      state.Revision.Key += 1;
    }
    // need to save it...
  }
  getAlertState(returnJson = false) {
    return this.getUserStateByStateKey('Alert', returnJson);
  }
  getApplicationState(returnJson = false) {
    return this.getUserStateByStateKey('Application', returnJson);
  }
  getCalculatedColumnState(returnJson = false) {
    return this.getUserStateByStateKey('CalculatedColumn', returnJson);
  }
  getChartingState(returnJson = false) {
    return this.getUserStateByStateKey('Charting', returnJson);
  }
  getNoteState(returnJson = false) {
    return this.getUserStateByStateKey('Note', returnJson);
  }
  getCustomSortState(returnJson = false) {
    return this.getUserStateByStateKey('CustomSort', returnJson);
  }
  getDashboardState(returnJson = false) {
    return this.getUserStateByStateKey('Dashboard', returnJson);
  }
  getExportState(returnJson = false) {
    return this.getUserStateByStateKey('Export', returnJson);
  }
  getFreeTextColumnState(returnJson = false) {
    return this.getUserStateByStateKey('FreeTextColumn', returnJson);
  }
  getFormatColumnState(returnJson = false) {
    return this.getUserStateByStateKey('FormatColumn', returnJson);
  }
  getFlashingCellState(returnJson = false) {
    return this.getUserStateByStateKey('FlashingCell', returnJson);
  }
  getLayoutState(returnJson = false) {
    return this.getUserStateByStateKey('Layout', returnJson);
  }
  getPlusMinusState(returnJson = false) {
    return this.getUserStateByStateKey('PlusMinus', returnJson);
  }
  getNamedQueryState(returnJson = false) {
    return this.getUserStateByStateKey('NamedQuery', returnJson);
  }
  getQuickSearchState(returnJson = false) {
    return this.getUserStateByStateKey('QuickSearch', returnJson);
  }
  getScheduleState(returnJson = false) {
    return this.getUserStateByStateKey('Schedule', returnJson);
  }
  getShortcutState(returnJson = false) {
    return this.getUserStateByStateKey('Shortcut', returnJson);
  }
  getStatusBarState(returnJson = false) {
    return this.getUserStateByStateKey('StatusBar', returnJson);
  }
  getStyledColumnState(returnJson = false) {
    return this.getUserStateByStateKey('StyledColumn', returnJson);
  }
  getThemeState(returnJson = false) {
    return this.getUserStateByStateKey('Theme', returnJson);
  }
  getToolPanelState(returnJson = false) {
    return this.getUserStateByStateKey('ToolPanel', returnJson);
  }
  dispatchStateReadyAction(module) {
    switch (module) {
      case 'Alert':
        this.dispatchAction(AlertRedux.AlertReady(this.getAlertState()));
        break;
      case 'BulkUpdate':
        this.dispatchAction(BulkUpdateRedux.BulkUpdateReady());
        break;
      case 'CalculatedColumn':
        this.dispatchAction(CalculatedColumnRedux.CalculatedColumnReady(this.getCalculatedColumnState()));
        break;
      case 'Charting':
        this.dispatchAction(ChartingRedux.ChartingReady(this.getChartingState()));
        break;
      case 'Note':
        this.dispatchAction(NoteRedux.NoteReady(this.getNoteState()));
        break;
      case 'CustomSort':
        this.dispatchAction(CustomSortRedux.CustomSortReady(this.getCustomSortState()));
        break;
      case 'Dashboard':
        this.dispatchAction(DashboardRedux.DashboardReady(this.getDashboardState()));
        break;
      case 'Export':
        this.dispatchAction(ExportRedux.ExportReady(this.getExportState()));
        break;
      case 'FormatColumn':
        this.dispatchAction(FormatColumnRedux.FormatColumnReady(this.getFormatColumnState()));
        break;
      case 'FreeTextColumn':
        this.dispatchAction(FreeTextColumnRedux.FreeTextColumnReady(this.getFreeTextColumnState()));
        break;
      case 'Layout':
        this.dispatchAction(LayoutRedux.LayoutReady(this.getLayoutState()));
        break;
      case 'PlusMinus':
        this.dispatchAction(PlusMinusRedux.PlusMinusReady(this.getPlusMinusState()));
        break;
      case 'NamedQuery':
        this.dispatchAction(NamedQueryRedux.NamedQueryReady(this.getNamedQueryState()));
        break;
      case 'QuickSearch':
        this.dispatchAction(QuickSearchRedux.QuickSearchReady(this.getQuickSearchState()));
        break;
      case 'Schedule':
        this.dispatchAction(ScheduleRedux.ScheduleReady(this.getScheduleState()));
        break;
      case 'Shortcut':
        this.dispatchAction(ShortcutRedux.ShortcutReady(this.getShortcutState()));
        break;
      case 'SmartEdit':
        this.dispatchAction(SmartEditRedux.SmartEditReady());
        break;
      case 'SystemStatus':
        this.dispatchAction(SystemStatusRedux.SystemStatusReady());
        break;
      case 'Theme':
        this.dispatchAction(ThemeRedux.ThemeReady(this.getThemeState()));
        break;
      case 'ToolPanel':
        this.dispatchAction(ToolPanelRedux.ToolPanelReady(this.getToolPanelState()));
        break;
      case 'StatusBar':
        this.dispatchAction(ToolPanelRedux.ToolPanelReady(this.getToolPanelState()));
        break;
    }
  }
}