import * as React from 'react';
import { Flex, Text } from 'rebass';
import Radio from '../../components/Radio';
export const TypeRadio = props => React.createElement(Radio, {
  onClick: () => props.onClick(),
  checked: props.checked
}, React.createElement(Flex, {
  flexDirection: "column"
}, React.createElement(Text, null, props.text), React.createElement(Text, {
  fontSize: 2,
  marginTop: 1
}, props.description)));