import { DataImportInternalApi } from '../Internal/DataImportInternalApi';
import { ApiBase } from './ApiBase';
export class DataImportApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.internalApi = new DataImportInternalApi(adaptable);
  }
  openImportWizard() {
    this.getSettingsPanelApi().openSettingsPanel('DataImport');
  }
  importData(partialRows) {}
}