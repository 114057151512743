import * as React from 'react';
import SimpleButton from '../SimpleButton';
import join from '../utils/join';
import { Flex } from 'rebass';
export function DashboardToolbar(props) {
  return React.createElement("div", {
    className: join('ab-Dashboard__toolbar', props.className)
  }, React.createElement("div", {
    className: "ab-Dashboard__toolbar-content"
  }, props.children), React.createElement(Flex, {
    className: "ab-Dashboard__toolbar-title",
    flexDirection: "row",
    alignItems: "center"
  }, React.createElement("span", null, props.title), props.showConfigure && React.createElement(SimpleButton, {
    icon: "spanner",
    variant: "text",
    tone: "none",
    "data-name": "configure",
    iconSize: 16,
    marginLeft: 1,
    tooltip: props.tooltip || `Configure ${props.title} Toolbar`,
    onClick: () => props.onConfigure()
  }), ' ', props.showClose && React.createElement(SimpleButton, {
    style: {
      alignSelf: 'flex-end'
    },
    disabled: props.accessLevel == 'ReadOnly',
    "data-name": "close",
    icon: "close",
    variant: "text",
    tone: "none",
    iconSize: 16,
    marginLeft: 1,
    tooltip: props.tooltip || `Close ${props.title} Toolbar`,
    onClick: () => props.onClose()
  })));
}