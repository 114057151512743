import { GridFilterWindowPopup } from '../../../GridFilter/GridFilterWindowPopup';
import { PivotDetailsPopoup } from '../../../Layout/PivotDetailsPopoup';
import { ExportTablePopup } from '../../../Export/ExportTablePopup';
import { ThemeEditorWindow } from '../../../Theme/ThemeEditorWindow';
import { TransposedPopup } from '../../../Layout/TransposedPopup';
export const WINDOW_GRID_FILTER_EDITOR = 'WINDOW_GRID_FILTER_EDITOR';
export const SHOW_PIVOT_COLUMN_DETAILS = 'SHOW_PIVOT_COLUMN_DETAILS';
export const SHOW_EXPORT_TABLE = 'SHOW_EXPORT_TABLE';
export const SHOW_THEME_EDITOR = 'SHOW_THEME_EDITOR';
export const WINDOW_SHOW_TRANSPOSED_VIEW = 'WINDOW_SHOW_TRANSPOSED_VIEW';
export const windowFactory = {
  [WINDOW_GRID_FILTER_EDITOR]: GridFilterWindowPopup,
  [SHOW_PIVOT_COLUMN_DETAILS]: PivotDetailsPopoup,
  [SHOW_EXPORT_TABLE]: ExportTablePopup,
  [SHOW_THEME_EDITOR]: ThemeEditorWindow,
  [WINDOW_SHOW_TRANSPOSED_VIEW]: TransposedPopup
};