import ObjectFactory, { createBaseContext } from '../../Utilities/ObjectFactory';
import { ApiBase } from '../Implementation/ApiBase';
import * as SystemRedux from '../../Redux/ActionsReducers/SystemRedux';
export class DataImportInternalApi extends ApiBase {
  async importData(partialRows) {
    const dataImportOptions = this.getOptions().dataImportOptions;
    let importData = partialRows;
    if (typeof dataImportOptions.handleImportedData === 'function') {
      const resolution = await dataImportOptions.handleImportedData(Object.assign({
        data: importData
      }, createBaseContext(this.getAdaptableApi())));
      if (resolution && (resolution === null || resolution === void 0 ? void 0 : resolution.emitDataImportedEvent)) {
        this.publishDataImportedEvent(importData, resolution.addedRows, resolution.updatedRows);
      }
      return;
    }
    const gridApi = this.getGridApi();
    const primaryKey = this.getOptions().primaryKey;
    let newRowCount = 0;
    let updatedRowCount = 0;
    const preparedDataRows = importData.map(dataRow => {
      const node = gridApi.getRowNodeForPrimaryKey(dataRow[primaryKey]);
      if (!node) {
        newRowCount += 1;
        return dataRow;
      }
      updatedRowCount += 1;
      return Object.assign(Object.assign({}, node === null || node === void 0 ? void 0 : node.data), dataRow);
    });
    const {
      addedRows,
      updatedRows
    } = await gridApi.addOrUpdateGridData(preparedDataRows);
    let message = '';
    if (newRowCount && updatedRowCount) {
      message = `Added: ${newRowCount} new Row${newRowCount > 1 ? 's' : ''}; Updated: ${updatedRowCount} existing Row${updatedRowCount > 1 ? 's' : ''}`;
    } else if (newRowCount && !updatedRowCount) {
      message = `Added: ${newRowCount} new Row${newRowCount > 1 ? 's' : ''}`;
    } else if (!newRowCount && updatedRowCount) {
      message = `Updated: ${updatedRowCount} existing Row${updatedRowCount > 1 ? 's' : ''}`;
    }
    const alert = {
      alertType: 'generic',
      header: 'Data Imported',
      message,
      alertDefinition: Object.assign(Object.assign({}, ObjectFactory.CreateEmptyAlertDefinition()), {
        MessageType: 'Info',
        AlertProperties: {
          DisplayNotification: true
        }
      })
    };
    this.getAlertApi().showAdaptableAlert(alert);
    this.publishDataImportedEvent(importData, addedRows, updatedRows);
  }
  /**
   * Publishes an event when Data has been imported
   * @param gridDataRows Rows that were imported
   */
  publishDataImportedEvent(importData, addedRows, updatedRows) {
    const dataImportedInfo = Object.assign({
      importData,
      addedRows,
      updatedRows
    }, this.getAdaptableApi().internalApi.buildBaseContext());
    this.getAdaptableApi().internalApi.dispatchReduxAction(SystemRedux.DataImportCompleted(dataImportedInfo));
    this.getEventApi().emit('DataImported', dataImportedInfo);
  }
}