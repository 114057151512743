import { getStatusPanelsSelector, StatusBarSetPanels } from '../../Redux/ActionsReducers/StatusBarRedux';
import { ApiBase } from './ApiBase';
export class StatusBarApiImpl extends ApiBase {
  getAdaptableStatusBars() {
    return getStatusPanelsSelector(this.getAdaptableState());
  }
  getAgGridStatusPanels() {
    return this.adaptable.getAgGridStatusPanels();
  }
  setStatusBarPanels(statusPanels) {
    this.dispatchAction(StatusBarSetPanels(statusPanels));
  }
}