import React from 'react';
import { useAdaptable } from '../AdaptableContext';
// need to trigger other show-chart buttons to close/hide
const buttonChangeSubscribers = [];
export const useExternalChartState = chartDefinition => {
  const adaptable = useAdaptable();
  // needs to update when ever a chart is:
  // - opened, hidden
  const [isOpen, setIsOpen] = React.useState(() => {
    return chartDefinition && adaptable.api.chartingApi.internalApi.isExternalChartOpened(chartDefinition);
  });
  const liveChartState = adaptable.api.chartingApi.internalApi.isExternalChartOpened(chartDefinition);
  if (liveChartState !== isOpen) {
    setIsOpen(liveChartState);
  }
  const updateIsOpen = React.useCallback(() => {
    setIsOpen(chartDefinition && adaptable.api.chartingApi.internalApi.isExternalChartOpened(chartDefinition));
  }, [chartDefinition]);
  React.useEffect(() => {
    const subscriber = () => {
      updateIsOpen();
    };
    buttonChangeSubscribers.push(subscriber);
    // clear own subscriptions
    return () => {
      const index = buttonChangeSubscribers.indexOf(subscriber);
      if (index > -1) {
        buttonChangeSubscribers.splice(index, 1);
      }
    };
  }, [updateIsOpen]);
  return {
    showChart: container => {
      adaptable.api.chartingApi.internalApi.onShowExternalChart(chartDefinition, container);
      // The small delay allows the library to render the chart before the button is updated
      requestAnimationFrame(() => {
        buttonChangeSubscribers.forEach(subscriber => subscriber());
      });
    },
    hideChart: () => {
      adaptable.api.chartingApi.internalApi.onHideExternalChart(chartDefinition);
      // The small delay allows the library to destroy the chart before the button is updated
      requestAnimationFrame(() => {
        buttonChangeSubscribers.forEach(subscriber => subscriber());
      });
    },
    isOpen
  };
};