import { unstable_batchedUpdates } from 'react-dom';
const BatchUpdate = fn => {
  let fns = [];
  if (fn) {
    fns.push(fn);
  }
  const didCommit = false;
  const queue = fn => {
    fns.push(fn);
  };
  queue.commit = lastFn => {
    if (didCommit) {
      return;
    }
    if (lastFn) {
      fns.push(lastFn);
    }
    unstable_batchedUpdates(() => {
      fns.forEach(fn => fn());
      fns = undefined;
    });
  };
  return queue;
};
export default BatchUpdate;