import { AdaptableModuleBase } from './AdaptableModuleBase';
import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
import { ThemeStatusbar } from '../View/Theme/ThemeStatusbar';
export class ThemeModule extends AdaptableModuleBase {
  constructor(api) {
    super(ModuleConstants.ThemeModuleId, ModuleConstants.ThemeFriendlyName, 'theme', 'ThemePopup', 'Theme AdapTable with a colour configuration set of your choosing', api);
  }
  getModuleAdaptableObjects() {
    return this.api.themeApi.getThemes();
  }
  hasNamedQueryReferences() {
    return false;
  }
  getViewProperties() {
    return {
      getStatusBarPanelProps: () => {
        return {
          content: ThemeStatusbar
        };
      }
    };
  }
}