import * as React from 'react';
import DefaultIcon from './DefaultIcon';
export default (props => React.createElement(DefaultIcon, Object.assign({}, props), React.createElement("g", {
  transform: "matrix(0.966625, 0, 0, 0.966625, -161.245651, -58.219734)"
}, React.createElement("g", {
  transform: "matrix(1, 0, 0, 1, 167.951233, 60.368694)"
}, React.createElement("path", {
  d: "M 14.67 5 L 14.67 19 L 9.33 19 L 9.33 5 L 14.67 5 Z M 15.67 19 L 21 19 L 20.99 11.456 L 15.67 11.459 L 15.67 19 Z M 8.33 19 L 8.33 5 L 3 5 L 3 19 L 8.33 19 Z"
})), React.createElement("path", {
  d: "M 188.844 62.299 L 186.844 62.299 L 186.844 65.299 L 183.844 65.299 C 183.854 65.309 183.844 67.299 183.844 67.299 L 186.844 67.299 L 186.844 70.289 C 186.854 70.299 188.844 70.289 188.844 70.289 L 188.844 67.299 L 191.844 67.299 L 191.844 65.299 L 188.844 65.299 L 188.844 62.299 Z"
}))));