import { EMPTY_ARRAY } from '../../Utilities/Constants/GeneralConstants';
import AdaptableHelper from '../../Utilities/Helpers/AdaptableHelper';
import { changeIsSuspendInList, suspendAllInList, unsuspendAllInList } from './utils';
/**
 * @ReduxAction Runs a Scheduled job
 */
export const SCHEDULE_JOB_RUN = 'SCHEDULE_JOB_RUN';
/**
 * @ReduxAction A Report Schedule has been added
 */
export const REPORT_SCHEDULE_ADD = 'REPORT_SCHEDULE_ADD';
/**
 * @ReduxAction A Report Schedule has been edited
 */
export const REPORT_SCHEDULE_EDIT = 'REPORT_SCHEDULE_EDIT';
/**
 * @ReduxAction A Report Schedule has been deleted
 */
export const REPORT_SCHEDULE_DELETE = 'REPORT_SCHEDULE_DELETE';
/**
 * @ReduxAction A Report Schedule has been suspended
 */
export const REPORT_SCHEDULE_SUSPEND = 'REPORT_SCHEDULE_SUSPEND';
/**
 * @ReduxAction A Report Schedule has been unsuspended or activated
 */
export const REPORT_SCHEDULE_UNSUSPEND = 'REPORT_SCHEDULE_UNSUSPEND';
/**
 * @ReduxAction All Report Schedules have been suspended
 */
export const REPORT_SCHEDULE_SUSPEND_ALL = 'REPORT_SCHEDULE_SUSPEND_ALL';
/**
 * @ReduxAction All Report Schedules have been unsuspended or activated
 */
export const REPORT_SCHEDULE_UNSUSPEND_ALL = 'REPORT_SCHEDULE_UNSUSPEND_ALL';
/**
 * @ReduxAction A Reminder Schedule has been added
 */
export const REMINDER_SCHEDULE_ADD = 'REMINDER_SCHEDULE_ADD';
/**
 * @ReduxAction A Reminder Schedule has been edited
 */
export const REMINDER_SCHEDULE_EDIT = 'REMINDER_SCHEDULE_EDIT';
/**
 * @ReduxAction A Reminder Schedule has been deleted
 */
export const REMINDER_SCHEDULE_DELETE = 'REMINDER_SCHEDULE_DELETE';
/**
 * @ReduxAction A Reminder Schedule has been suspended
 */
export const REMINDER_SCHEDULE_SUSPEND = 'REMINDER_SCHEDULE_SUSPEND';
/**
 * @ReduxAction A Reminder Schedule has been unsuspended or activated
 */
export const REMINDER_SCHEDULE_UNSUSPEND = 'REMINDER_SCHEDULE_UNSUSPEND';
/**
 * @ReduxAction All Reminder Schedules have been suspended
 */
export const REMINDER_SCHEDULE_SUSPEND_ALL = 'REMINDER_SCHEDULE_SUSPEND_ALL';
/**
 * @ReduxAction All Reminder Schedules have been unsuspended or activated
 */
export const REMINDER_SCHEDULE_UNSUSPEND_ALL = 'REMINDER_SCHEDULE_UNSUSPEND_ALL';
/**
 * @ReduxAction An ipushpull Schedule has been added
 */
export const IPUSHPULL_SCHEDULE_ADD = 'IPUSHPULL_SCHEDULE_ADD';
/**
 * @ReduxAction An ipushpull Schedule has been edited
 */
export const IPUSHPULL_SCHEDULE_EDIT = 'IPUSHPULL_SCHEDULE_EDIT';
/**
 * @ReduxAction An ipushpull Schedule has been deleted
 */
export const IPUSHPULL_SCHEDULE_DELETE = 'IPUSHPULL_SCHEDULE_DELETE';
/**
 * @ReduxAction An ipushpull Schedule has been suspended
 */
export const IPUSHPULL_SCHEDULE_SUSPEND = 'IPUSHPULL_SCHEDULE_SUSPEND';
/**
 * @ReduxAction An ipushpull Schedule has been unsuspended or activated
 */
export const IPUSHPULL_SCHEDULE_UNSUSPEND = 'IPUSHPULL_SCHEDULE_UNSUSPEND';
/**
 * @ReduxAction All ipushpull Schedules have been suspended
 */
export const IPUSHPULL_SCHEDULE_SUSPEND_ALL = 'IPUSHPULL_SCHEDULE_SUSPEND_ALL';
/**
 * @ReduxAction All ipushpull Schedules have been unsuspended or activated
 */
export const IPUSHPULL_SCHEDULE_UNSUSPEND_ALL = 'IPUSHPULL_SCHEDULE_UNSUSPEND_ALL';
/**
 * @ReduxAction An OpenFin Schedule has been added
 */
export const OPENFIN_SCHEDULE_ADD = 'OPENFIN_SCHEDULE_ADD';
/**
 * @ReduxAction An OpenFin Schedule has been edited
 */
export const OPENFIN_SCHEDULE_EDIT = 'OPENFIN_SCHEDULE_EDIT';
/**
 * @ReduxAction An OpenFin Schedule has been deleted
 */
export const OPENFIN_SCHEDULE_DELETE = 'OPENFIN_SCHEDULE_DELETE';
/**
 * @ReduxAction An OpenFin Schedule has been suspended
 */
export const OPENFIN_SCHEDULE_SUSPEND = 'OPENFIN_SCHEDULE_SUSPEND';
/**
 * @ReduxAction An OpenFin Schedule has been unsuspended or activated
 */
export const OPENFIN_SCHEDULE_UNSUSPEND = 'OPENFIN_SCHEDULE_UNSUSPEND';
/**
 * @ReduxAction All OpenFin Schedules have been suspended
 */
export const OPENFIN_SCHEDULE_SUSPEND_ALL = 'OPENFIN_SCHEDULE_SUSPEND_ALL';
/**
 * @ReduxAction All OpenFin Schedules have been unsuspended or activated
 */
export const OPENFIN_SCHEDULE_UNSUSPEND_ALL = 'OPENFIN_SCHEDULE_UNSUSPEND_ALL';
/**
 * @ReduxAction Schedule Module is ready
 */
export const SCHEDULE_READY = 'SCHEDULE_READY';
export const ScheduleJobRun = (schedule, scheduleType) => ({
  type: SCHEDULE_JOB_RUN,
  schedule,
  scheduleType
});
export const ScheduleReady = scheduleState => ({
  type: SCHEDULE_READY,
  scheduleState
});
export const OpenFinScheduleAdd = openFinSchedule => ({
  type: OPENFIN_SCHEDULE_ADD,
  openFinSchedule
});
export const OpenFinScheduleEdit = openFinSchedule => ({
  type: OPENFIN_SCHEDULE_EDIT,
  openFinSchedule
});
export const OpenFinScheduleDelete = openFinSchedule => ({
  type: OPENFIN_SCHEDULE_DELETE,
  openFinSchedule
});
export const OpenFinScheduleSuspend = openFinSchedule => ({
  type: OPENFIN_SCHEDULE_SUSPEND,
  openFinSchedule
});
export const OpenFinScheduleUnSuspend = openFinSchedule => ({
  type: OPENFIN_SCHEDULE_UNSUSPEND,
  openFinSchedule
});
export const OpenFinScheduleSuspendAll = () => ({
  type: OPENFIN_SCHEDULE_SUSPEND_ALL
});
export const OpenFinScheduleUnSuspendAll = () => ({
  type: OPENFIN_SCHEDULE_UNSUSPEND_ALL
});
// Report
export const ReportScheduleAdd = reportSchedule => ({
  type: REPORT_SCHEDULE_ADD,
  reportSchedule
});
export const ReportScheduleEdit = reportSchedule => ({
  type: REPORT_SCHEDULE_EDIT,
  reportSchedule
});
export const ReportScheduleDelete = reportSchedule => ({
  type: REPORT_SCHEDULE_DELETE,
  reportSchedule
});
export const ReportScheduleSuspend = reportSchedule => ({
  type: REPORT_SCHEDULE_SUSPEND,
  reportSchedule
});
export const ReportScheduleUnSuspend = reportSchedule => ({
  type: REPORT_SCHEDULE_UNSUSPEND,
  reportSchedule
});
export const ReportScheduleSuspendAll = () => ({
  type: REPORT_SCHEDULE_SUSPEND
});
export const ReportScheduleUnSuspendAll = () => ({
  type: REPORT_SCHEDULE_UNSUSPEND
});
export const ReminderScheduleAdd = reminderSchedule => ({
  type: REMINDER_SCHEDULE_ADD,
  reminderSchedule
});
export const ReminderScheduleEdit = reminderSchedule => ({
  type: REMINDER_SCHEDULE_EDIT,
  reminderSchedule
});
export const ReminderScheduleDelete = reminderSchedule => ({
  type: REMINDER_SCHEDULE_DELETE,
  reminderSchedule
});
export const ReminderScheduleSuspend = reminderSchedule => ({
  type: REMINDER_SCHEDULE_SUSPEND,
  reminderSchedule
});
export const ReminderScheduleUnSuspend = reminderSchedule => ({
  type: REMINDER_SCHEDULE_UNSUSPEND,
  reminderSchedule
});
export const ReminderScheduleSuspendAll = () => ({
  type: REMINDER_SCHEDULE_SUSPEND_ALL
});
export const ReminderScheduleUnSuspendAll = () => ({
  type: REMINDER_SCHEDULE_UNSUSPEND_ALL
});
export const IPushPullScheduleAdd = iPushPullSchedule => ({
  type: IPUSHPULL_SCHEDULE_ADD,
  iPushPullSchedule
});
export const IPushPullScheduleEdit = iPushPullSchedule => ({
  type: IPUSHPULL_SCHEDULE_EDIT,
  iPushPullSchedule
});
export const IPushPullScheduleDelete = iPushPullSchedule => ({
  type: IPUSHPULL_SCHEDULE_DELETE,
  iPushPullSchedule
});
export const IPushPullScheduleSuspend = iPushPullSchedule => ({
  type: IPUSHPULL_SCHEDULE_SUSPEND,
  iPushPullSchedule
});
export const IPushPullScheduleUnSuspend = iPushPullSchedule => ({
  type: IPUSHPULL_SCHEDULE_UNSUSPEND,
  iPushPullSchedule
});
export const IPushPullScheduleSuspendAll = () => ({
  type: IPUSHPULL_SCHEDULE_SUSPEND
});
export const IPushPullScheduleUnSuspendAll = () => ({
  type: IPUSHPULL_SCHEDULE_UNSUSPEND
});
const initialState = {
  ReportSchedules: EMPTY_ARRAY,
  Reminders: EMPTY_ARRAY,
  IPushPullSchedules: EMPTY_ARRAY,
  OpenFinSchedules: EMPTY_ARRAY
};
export const ScheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPENFIN_SCHEDULE_ADD:
      {
        const actionSchedule = action.openFinSchedule;
        AdaptableHelper.addUuidAndSource(actionSchedule);
        const OpenFinSchedules = [].concat(state.OpenFinSchedules);
        OpenFinSchedules.push(actionSchedule);
        return Object.assign(Object.assign({}, state), {
          OpenFinSchedules: OpenFinSchedules
        });
      }
    case OPENFIN_SCHEDULE_EDIT:
      {
        const actionSchedule = action.openFinSchedule;
        return Object.assign(Object.assign({}, state), {
          OpenFinSchedules: state.OpenFinSchedules.map(abObject => abObject.Uuid === actionSchedule.Uuid ? actionSchedule : abObject)
        });
      }
    case OPENFIN_SCHEDULE_DELETE:
      {
        const actionSchedule = action.openFinSchedule;
        return Object.assign(Object.assign({}, state), {
          OpenFinSchedules: state.OpenFinSchedules.filter(abObject => abObject.Uuid !== actionSchedule.Uuid)
        });
      }
    case OPENFIN_SCHEDULE_SUSPEND:
      {
        return Object.assign(Object.assign({}, state), {
          OpenFinSchedules: changeIsSuspendInList(action.openFinSchedule, state.OpenFinSchedules, true)
        });
      }
    case OPENFIN_SCHEDULE_UNSUSPEND:
      {
        return Object.assign(Object.assign({}, state), {
          OpenFinSchedules: changeIsSuspendInList(action.openFinSchedule, state.OpenFinSchedules, false)
        });
      }
    case OPENFIN_SCHEDULE_SUSPEND_ALL:
      {
        return Object.assign(Object.assign({}, state), {
          OpenFinSchedules: suspendAllInList(state.OpenFinSchedules)
        });
      }
    case OPENFIN_SCHEDULE_UNSUSPEND_ALL:
      {
        return Object.assign(Object.assign({}, state), {
          OpenFinSchedules: unsuspendAllInList(state.OpenFinSchedules)
        });
      }
    case REPORT_SCHEDULE_ADD:
      {
        const actionSchedule = action.reportSchedule;
        AdaptableHelper.addUuidAndSource(actionSchedule);
        const reportSchedules = [].concat(state.ReportSchedules);
        reportSchedules.push(actionSchedule);
        return Object.assign(Object.assign({}, state), {
          ReportSchedules: reportSchedules
        });
      }
    case REPORT_SCHEDULE_EDIT:
      {
        const actionSchedule = action.reportSchedule;
        return Object.assign(Object.assign({}, state), {
          ReportSchedules: state.ReportSchedules.map(abObject => abObject.Uuid === actionSchedule.Uuid ? actionSchedule : abObject)
        });
      }
    case REPORT_SCHEDULE_DELETE:
      {
        const actionSchedule = action.reportSchedule;
        return Object.assign(Object.assign({}, state), {
          ReportSchedules: state.ReportSchedules.filter(abObject => abObject.Uuid !== actionSchedule.Uuid)
        });
      }
    case REPORT_SCHEDULE_SUSPEND:
      {
        return Object.assign(Object.assign({}, state), {
          ReportSchedules: changeIsSuspendInList(action.reportSchedule, state.ReportSchedules, true)
        });
      }
    case REPORT_SCHEDULE_UNSUSPEND:
      {
        return Object.assign(Object.assign({}, state), {
          ReportSchedules: changeIsSuspendInList(action.reportSchedule, state.ReportSchedules, false)
        });
      }
    case REPORT_SCHEDULE_SUSPEND_ALL:
      {
        return Object.assign(Object.assign({}, state), {
          ReportSchedules: suspendAllInList(state.ReportSchedules)
        });
      }
    case REPORT_SCHEDULE_UNSUSPEND_ALL:
      {
        return Object.assign(Object.assign({}, state), {
          ReportSchedules: unsuspendAllInList(state.ReportSchedules)
        });
      }
    case REMINDER_SCHEDULE_ADD:
      {
        const actionSchedule = action.reminderSchedule;
        AdaptableHelper.addUuidAndSource(actionSchedule);
        const reminderSchedules = [].concat(state.Reminders);
        reminderSchedules.push(actionSchedule);
        return Object.assign(Object.assign({}, state), {
          Reminders: reminderSchedules
        });
      }
    case REMINDER_SCHEDULE_EDIT:
      {
        const actionSchedule = action.reminderSchedule;
        return Object.assign(Object.assign({}, state), {
          Reminders: state.Reminders.map(abObject => abObject.Uuid === actionSchedule.Uuid ? actionSchedule : abObject)
        });
      }
    case REMINDER_SCHEDULE_DELETE:
      {
        const actionSchedule = action.reminderSchedule;
        return Object.assign(Object.assign({}, state), {
          Reminders: state.Reminders.filter(abObject => abObject.Uuid !== actionSchedule.Uuid)
        });
      }
    case REMINDER_SCHEDULE_SUSPEND:
      {
        return Object.assign(Object.assign({}, state), {
          Reminders: changeIsSuspendInList(action.reminderSchedule, state.Reminders, true)
        });
      }
    case REMINDER_SCHEDULE_UNSUSPEND:
      {
        return Object.assign(Object.assign({}, state), {
          Reminders: changeIsSuspendInList(action.reminderSchedule, state.Reminders, false)
        });
      }
    case REMINDER_SCHEDULE_SUSPEND_ALL:
      {
        return Object.assign(Object.assign({}, state), {
          Reminders: suspendAllInList(state.Reminders)
        });
      }
    case REMINDER_SCHEDULE_UNSUSPEND_ALL:
      {
        return Object.assign(Object.assign({}, state), {
          Reminders: unsuspendAllInList(state.Reminders)
        });
      }
    case IPUSHPULL_SCHEDULE_ADD:
      {
        const actionSchedule = action.iPushPullSchedule;
        AdaptableHelper.addUuidAndSource(actionSchedule);
        const iPushPullSchedules = state.IPushPullSchedules ? [...state.IPushPullSchedules] : [];
        iPushPullSchedules.push(actionSchedule);
        return Object.assign(Object.assign({}, state), {
          IPushPullSchedules: iPushPullSchedules
        });
      }
    case IPUSHPULL_SCHEDULE_EDIT:
      {
        const actionSchedule = action.iPushPullSchedule;
        return Object.assign(Object.assign({}, state), {
          IPushPullSchedules: (state.IPushPullSchedules || []).map(abObject => abObject.Uuid === actionSchedule.Uuid ? actionSchedule : abObject)
        });
      }
    case IPUSHPULL_SCHEDULE_DELETE:
      {
        const actionSchedule = action.iPushPullSchedule;
        return Object.assign(Object.assign({}, state), {
          IPushPullSchedules: (state.IPushPullSchedules || []).filter(abObject => abObject.Uuid !== actionSchedule.Uuid)
        });
      }
    case IPUSHPULL_SCHEDULE_SUSPEND:
      {
        return Object.assign(Object.assign({}, state), {
          IPushPullSchedules: changeIsSuspendInList(action.iPushPullSchedule, state.IPushPullSchedules, true)
        });
      }
    case IPUSHPULL_SCHEDULE_UNSUSPEND:
      {
        return Object.assign(Object.assign({}, state), {
          IPushPullSchedules: changeIsSuspendInList(action.iPushPullSchedule, state.IPushPullSchedules, false)
        });
      }
    case IPUSHPULL_SCHEDULE_SUSPEND_ALL:
      {
        return Object.assign(Object.assign({}, state), {
          IPushPullSchedules: suspendAllInList(state.IPushPullSchedules)
        });
      }
    case IPUSHPULL_SCHEDULE_UNSUSPEND_ALL:
      {
        return Object.assign(Object.assign({}, state), {
          IPushPullSchedules: unsuspendAllInList(state.IPushPullSchedules)
        });
      }
    default:
      return state;
  }
};