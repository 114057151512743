import { __rest } from "tslib";
import * as React from 'react';
import { connect } from 'react-redux';
import * as PopupRedux from '../../../Redux/ActionsReducers/PopupRedux';
import SimpleButton from '../../../components/SimpleButton';
class ButtonDeleteComponent extends React.Component {
  render() {
    var _a, _b;
    const _c = this.props,
      {
        onConfirmWarning,
        ConfirmAction,
        ConfirmationMsg,
        ConfirmationTitle,
        onClickAction
      } = _c,
      props = __rest(_c, ["onConfirmWarning", "ConfirmAction", "ConfirmationMsg", "ConfirmationTitle", "onClickAction"]);
    return React.createElement(SimpleButton, Object.assign({
      "data-name": "delete",
      tooltip: (_a = props.tooltip) !== null && _a !== void 0 ? _a : 'Delete',
      variant: "text",
      icon: "delete",
      iconSize: (_b = props.iconSize) !== null && _b !== void 0 ? _b : 24
    }, props, {
      onClick: () => this.onClick()
    }));
  }
  onClick() {
    if (this.props.ConfirmAction) {
      let confirmation = {
        CancelButtonText: 'Cancel',
        Header: this.props.ConfirmationTitle,
        Msg: this.props.ConfirmationMsg,
        ConfirmButtonText: 'Delete',
        CancelAction: null,
        ConfirmAction: this.props.ConfirmAction,
        ShowInputBox: false,
        MessageType: 'Warning'
      };
      this.props.onConfirmWarning(confirmation);
    } else {
      this.props.onClickAction();
    }
  }
}
function mapStateToProps(state, ownProps) {
  return {
    ConfirmAction: ownProps.ConfirmAction,
    ConfirmationMsg: ownProps.ConfirmationMsg,
    ConfirmationTitle: ownProps.ConfirmationTitle
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onConfirmWarning: confirmation => dispatch(PopupRedux.PopupShowConfirmation(confirmation))
  };
}
export let ButtonDelete = connect(mapStateToProps, mapDispatchToProps)(ButtonDeleteComponent);