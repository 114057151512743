import { AdaptableModuleBase } from './AdaptableModuleBase';
import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
import * as ShortcutRedux from '../Redux/ActionsReducers/ShortcutRedux';
import { MathOperation } from '../PredefinedConfig/Common/Enums';
import { ArrayExtensions } from '../Utilities/Extensions/ArrayExtensions';
import StringExtensions from '../Utilities/Extensions/StringExtensions';
import { ShortcutWizard } from '../View/Shortcut/Wizard/ShortcutWizard';
import { getScopeViewItems } from './Utilities/getScopeViewItems';
import { getShortcutSettingsViewItems } from './Utilities/Shortcut/getShortcutSettingsViewItems';
import { getObjectTagsViewItems } from './Utilities/getObjectTagsViewItems';
export class ShortcutModule extends AdaptableModuleBase {
  constructor(api) {
    super(ModuleConstants.ShortcutModuleId, ModuleConstants.ShortcutFriendlyName, 'link', 'ShortcutPopup', 'Set up rules so cells update mathematically when keys are pressed in numeric cells', api);
    this.shouldHandleKeyDown = false;
    this.adaptable = api.internalApi.getAdaptableInstance();
  }
  getModuleAdaptableObjects(config) {
    return this.api.shortcutApi.getShortcuts(config);
  }
  hasNamedQueryReferences() {
    return false;
  }
  getTeamSharingAction() {
    return {
      ModuleEntities: this.api.shortcutApi.getShortcuts(),
      AddAction: ShortcutRedux.ShortcutAdd,
      EditAction: ShortcutRedux.ShortcutEdit
    };
  }
  onAdaptableReady() {
    this.checkListenToKeyDown();
    this.adaptable._on('CellEditorKeyDown', cellEditorKeyDownEvent => {
      this.handleKeyDown(cellEditorKeyDownEvent);
    });
  }
  checkListenToKeyDown() {
    const shortcuts = this.api.shortcutApi.getShortcuts();
    this.shouldHandleKeyDown = ArrayExtensions.IsNotNullOrEmpty(shortcuts);
  }
  handleKeyDown(cellEditorKeyDownEvent) {
    if (!this.shouldHandleKeyDown || StringExtensions.IsNullOrEmpty(cellEditorKeyDownEvent.cellValue)) {
      return;
    }
    const {
      keyDownEvent,
      cellValue,
      columnId,
      updateValueCallback
    } = cellEditorKeyDownEvent;
    const matchingShortcut = this.api.shortcutApi.getActiveShortcuts().find(x => keyDownEvent.key.toLowerCase() === x.ShortcutKey.toLowerCase() && this.api.columnScopeApi.isColumnInScope(this.api.columnApi.getColumnWithColumnId(columnId), x.Scope));
    if (!matchingShortcut || !matchingShortcut.ShortcutValue) {
      return;
    }
    const newValue = this.calculateShortcut(cellValue, matchingShortcut.ShortcutValue, matchingShortcut.ShortcutOperation);
    // update the editor value
    updateValueCallback(newValue);
  }
  calculateShortcut(first, second, shortcutOperation) {
    let firstNumber = Number(first);
    let secondNumber = Number(second);
    switch (shortcutOperation) {
      case MathOperation.Add:
        return firstNumber + secondNumber;
      case MathOperation.Subtract:
        return firstNumber - secondNumber;
      case MathOperation.Multiply:
        return firstNumber * secondNumber;
      case MathOperation.Divide:
        return firstNumber / secondNumber;
    }
  }
  toView(shortcut) {
    return {
      abObject: shortcut,
      items: [getScopeViewItems(shortcut.Scope, this.api), getShortcutSettingsViewItems(shortcut), getObjectTagsViewItems(shortcut, this.api)]
    };
  }
  toViewAll() {
    return this.getModuleAdaptableObjects({
      includeLayoutNotAssociatedObjects: this.showLayoutNotAssociatedObjects()
    }).map(shortcut => this.toView(shortcut));
  }
  getViewProperties() {
    return {
      getEditAction: ShortcutRedux.ShortcutEdit,
      getDeleteAction: ShortcutRedux.ShortcutDelete,
      getSuspendAction: ShortcutRedux.ShortcutSuspend,
      getUnSuspendAction: ShortcutRedux.ShortcutUnSuspend,
      getSuspendAllAction: ShortcutRedux.ShortcutSuspendAll,
      getUnSuspendAllAction: ShortcutRedux.ShortcutUnSuspendAll,
      getEditWizard() {
        return ShortcutWizard;
      }
    };
  }
  canBeAssociatedWithLayouts() {
    return true;
  }
}