import * as React from 'react';
import { Text } from 'rebass';
import { Tabs } from '../../../components/Tabs';
import { Tag } from '../../../components/Tag';
import { NewColumnSelector } from '../../Components/ColumnSelector';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
export const renderCustomSortColumn = data => {
  const {
    api
  } = useOnePageAdaptableWizardContext();
  return React.createElement(Text, {
    pr: 2,
    py: 2,
    fontSize: 2
  }, "Custom sort column: ", React.createElement(Tag, null, api.columnApi.getFriendlyNameForColumnId(data.ColumnId)));
};
export const isValidCustomSortColumn = data => {
  if (!data.ColumnId) {
    return 'You have to select a Column for Custom Sort';
  }
  return true;
};
export const CustomSortColumnWizardSection = props => {
  const {
    data,
    api
  } = useOnePageAdaptableWizardContext();
  const sortableCols = React.useMemo(() => {
    const sortableColumns = api.columnApi.getSortableColumns();
    const customSorts = api.customSortApi.getCustomSorts();
    const columnSortComparers = api.optionsApi.getCustomSortOptions().customSortComparers || [];
    const usedColumnIds = [...customSorts.map(customSort => customSort.ColumnId), ...columnSortComparers.map(comparer => api.columnScopeApi.getColumnIdsInScope(comparer.scope))];
    // filter out used colum ids, but include the current one
    return sortableColumns.filter(column => {
      if (!props.isNew && column.columnId === (data === null || data === void 0 ? void 0 : data.ColumnId)) {
        return true;
      }
      return usedColumnIds.every(usedColumnId => {
        return column.columnId !== usedColumnId;
      });
    });
  }, []);
  return React.createElement(Tabs, {
    style: {
      height: '100%'
    }
  }, React.createElement(Tabs.Tab, null, "Column"), React.createElement(Tabs.Content, null, React.createElement(NewColumnSelector, {
    availableColumns: sortableCols,
    selected: data.ColumnId ? [data.ColumnId] : [],
    singleSelect: true,
    onChange: ids => {
      props.onChange(Object.assign(Object.assign({}, data), {
        SortedValues: [],
        ColumnId: ids[0]
      }));
    },
    allowReorder: false
  })));
};