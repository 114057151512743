import * as ShortcutRedux from '../../Redux/ActionsReducers/ShortcutRedux';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import { ApiBase } from './ApiBase';
export class ShortcutApiImpl extends ApiBase {
  getShortcutState() {
    return this.getAdaptableState().Shortcut;
  }
  getShortcuts(config) {
    var _a;
    return (_a = this.handleLayoutAssociatedObjects(this.getShortcutState().Shortcuts, 'Shortcut', config)) !== null && _a !== void 0 ? _a : [];
  }
  getShortcutById(id) {
    return this.getShortcuts().find(shortcut => (shortcut === null || shortcut === void 0 ? void 0 : shortcut.Uuid) === id);
  }
  getActiveShortcuts() {
    return this.getShortcuts().filter(shortcut => !shortcut.IsSuspended);
  }
  getSuspendedShortcuts() {
    return this.getShortcuts().filter(shortcut => shortcut.IsSuspended);
  }
  addShortcut(shortcut) {
    this.addUidToAdaptableObject(shortcut);
    this.dispatchAction(ShortcutRedux.ShortcutAdd(shortcut));
    return this.getShortcutById(shortcut.Uuid);
  }
  deleteShortcut(shortcut) {
    this.dispatchAction(ShortcutRedux.ShortcutDelete(shortcut));
  }
  deleteAllShortcuts() {
    this.getShortcuts().forEach(s => {
      this.deleteShortcut(s);
    });
  }
  suspendShortcut(shortcut) {
    this.dispatchAction(ShortcutRedux.ShortcutSuspend(shortcut));
    return this.getShortcutById(shortcut.Uuid);
  }
  unSuspendShortcut(shortcut) {
    this.dispatchAction(ShortcutRedux.ShortcutUnSuspend(shortcut));
    return this.getShortcutById(shortcut.Uuid);
  }
  openShortcutSettingsPanel() {
    this.showModulePopup(ModuleConstants.ShortcutModuleId);
  }
}