import * as React from 'react';
import Input from '../../../components/Input';
import { useAdaptable } from '../../AdaptableContext';
import AdaptableDateInput from '../AdaptableDateInput';
const AdaptableInput = React.forwardRef((props, ref) => {
  var _a, _b;
  const useNativeInputDate = (_b = (_a = useAdaptable().adaptableOptions.userInterfaceOptions) === null || _a === void 0 ? void 0 : _a.dateInputOptions) === null || _b === void 0 ? void 0 : _b.useNativeInput;
  if (props.type === 'date' && !useNativeInputDate) {
    // use AdapTable date input
    return React.createElement(AdaptableDateInput, Object.assign({}, props, {
      ref: ref
    }));
  } else {
    // native input
    return React.createElement(Input, Object.assign({}, props, {
      ref: ref
    }));
  }
});
export default AdaptableInput;