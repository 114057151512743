import * as React from 'react';
import { useState } from 'react';
import { Box } from 'rebass';
import { OnePageAdaptableWizard, OnePageWizardSummary } from '../../Wizard/OnePageAdaptableWizard';
import { cloneObject } from '../../../Utilities/Helpers/Helper';
import { AlertScopeWizardSection } from './AlertScopeWizardSection';
import { AlertRulesWizardSection, getRuleStepDescription, renderAlertRulesSummary } from './AlertRulesWizardSection';
import { isValidAlertRules } from './isValidAlertRules';
import { AlertBehaviourWizardSection, renderAlertBehaviourWizardSummary } from './AlertBehaviourWizardSection';
import { ALERT_DEFAULT_MESSAGE_TYPE } from '../../../Utilities/Constants/GeneralConstants';
import { AlertMessageWizardSection, renderAlertMessageSummary } from './AlertMessageWizardSection';
import { renderScopeSummary } from './BaseAlertScopeWizardSection';
import { isScopeValid } from '../../Components/NewScopeComponent';
import ObjectFactory from '../../../Utilities/ObjectFactory';
import { useDispatch } from 'react-redux';
import * as AlertRedux from '../../../Redux/ActionsReducers/AlertRedux';
import { useAdaptable } from '../../AdaptableContext';
import { ObjectTagsWizardSection, renderObjectTagsSummary } from '../../Wizard/ObjectTagsWizardSection';
import { AlertTypeWizardSection, renderAlertTypeSummary } from './AlertTypeWizardSection';
import { AlertType, getAlertType } from '../Utilities/getAlertType';
import { mapAlertDefinition } from '../Utilities/mapAlertDefinition';
import { getDefaultAlertDefinition } from '../Utilities/getDefaultAlertDefinition';
import { AlertNotificationWizardSection, renderAlertNotificationSummary } from './AlertNotificationWizardSection';
export const AlertWizard = props => {
  const {
    api
  } = useAdaptable();
  const dispatch = useDispatch();
  const behaviourSpelling = api.internalApi.getCorrectEnglishVariant('Behaviour');
  const [alertDefinition, setAlertDefinition] = useState(() => {
    var _a, _b, _c, _d, _e, _f, _g;
    const alertDefinition = props.data ? cloneObject(props.data) : ObjectFactory.CreateEmptyAlertDefinition();
    if (!((_a = alertDefinition.Rule) === null || _a === void 0 ? void 0 : _a.BooleanExpression) && !((_b = alertDefinition.Rule) === null || _b === void 0 ? void 0 : _b.ObservableExpression) && !((_c = alertDefinition.Rule) === null || _c === void 0 ? void 0 : _c.AggregatedBooleanExpression) && !((_d = alertDefinition.Rule) === null || _d === void 0 ? void 0 : _d.Predicates)) {
      alertDefinition.Rule = {
        BooleanExpression: ''
      };
    }
    if (((_e = props.popupParams) === null || _e === void 0 ? void 0 : _e.column) && ((_f = props.popupParams) === null || _f === void 0 ? void 0 : _f.action) === 'New') {
      alertDefinition.Scope = {
        ColumnIds: [props.popupParams.column.columnId]
      };
    }
    alertDefinition.MessageType = (_g = alertDefinition.MessageType) !== null && _g !== void 0 ? _g : ALERT_DEFAULT_MESSAGE_TYPE;
    return alertDefinition;
  });
  // Alert type is based initialy on data, but can be changed by the user
  // the default objects do not neccesary have enough information to determine the type
  const [alertType, setAlertType] = useState(() => {
    var _a;
    return (_a = getAlertType(alertDefinition)) !== null && _a !== void 0 ? _a : AlertType.DataChange;
  });
  const handleFinish = () => {
    if (props.data) {
      dispatch(AlertRedux.AlertDefinitionEdit(alertDefinition));
    } else {
      dispatch(AlertRedux.AlertDefinitionAdd(alertDefinition));
    }
    props.onCloseWizard();
  };
  const updateAlertDefinition = newAlertDefinition => {
    setAlertDefinition(mapAlertDefinition(api, newAlertDefinition));
  };
  const handleAlertTypeChange = newAlertType => {
    setAlertType(newAlertType);
    setAlertDefinition(getDefaultAlertDefinition(alertDefinition, newAlertType));
  };
  return React.createElement(OnePageAdaptableWizard, {
    defaultCurrentSectionName: props.defaultCurrentSectionName,
    moduleInfo: props.moduleInfo,
    data: alertDefinition,
    onHide: props.onCloseWizard,
    onFinish: handleFinish,
    sections: [{
      title: 'Type',
      details: React.createElement(React.Fragment, null, "Select ", React.createElement("b", null, "what"), " type of change in AdapTable will cause the Alert to fire"),
      render: () => React.createElement(Box, {
        padding: 2
      }, React.createElement(AlertTypeWizardSection, {
        alertType: alertType,
        onAlertTypeChange: handleAlertTypeChange
      })),
      renderSummary: () => renderAlertTypeSummary(alertType)
    }, {
      title: 'Trigger',
      isVisible: () => alertType === AlertType.DataChange || alertType == AlertType.Validation,
      details: React.createElement(React.Fragment, null, "Specify ", React.createElement("b", null, "where"), " data changes should trigger the Alert"),
      isValid: isScopeValid,
      render: () => React.createElement(AlertScopeWizardSection, {
        alertType: alertType,
        onChange: setAlertDefinition
      }),
      renderSummary: renderScopeSummary
    }, {
      title: 'Rule',
      details: getRuleStepDescription(alertType),
      isValid: isValidAlertRules,
      render: () => React.createElement(AlertRulesWizardSection, {
        alertType: alertType,
        onChange: updateAlertDefinition,
        module: "alert"
      }),
      renderSummary: renderAlertRulesSummary
    }, {
      title: 'Message',
      details: "Select the Alert's Message Type and Text",
      render: () => React.createElement(Box, {
        padding: 2
      }, React.createElement(AlertMessageWizardSection, {
        alertType: alertType,
        onChange: setAlertDefinition
      })),
      renderSummary: renderAlertMessageSummary
    }, {
      title: 'Notification',
      details: 'Create a Notification for the Alert',
      render: () => React.createElement(Box, {
        padding: 2
      }, React.createElement(AlertNotificationWizardSection, {
        alertType: alertType,
        onChange: setAlertDefinition
      })),
      renderSummary: renderAlertNotificationSummary
    }, {
      title: behaviourSpelling,
      details: 'Configure ' + behaviourSpelling + 's for the Alert',
      render: () => React.createElement(Box, {
        padding: 2
      }, React.createElement(AlertBehaviourWizardSection, {
        alertType: alertType,
        onChange: setAlertDefinition
      })),
      renderSummary: renderAlertBehaviourWizardSummary
    }, {
      details: 'Select Alert Tags',
      title: 'Tags',
      isVisible: () => api.internalApi.shouldDisplayTagSections(),
      render: () => React.createElement(Box, {
        padding: 2
      }, React.createElement(ObjectTagsWizardSection, {
        onChange: setAlertDefinition
      })),
      renderSummary: renderObjectTagsSummary
    }, '-', {
      details: 'Review your Alert',
      render: () => React.createElement(Box, {
        padding: 2
      }, React.createElement(OnePageWizardSummary, null)),
      title: 'Summary'
    }]
  });
};