import * as React from 'react';
import parseISO from 'date-fns/parseISO';
import useProperty from '../../../components/utils/useProperty';
import AdaptableInput from '../../../View/Components/AdaptableInput';
import { DatepickerContext } from '../../../components/Datepicker/DatepickerContext';
import { parseToISO } from '../../../Utilities/Helpers/DateHelper';
const fillStyle = {
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0
};
const hostStyle = Object.assign(Object.assign({}, fillStyle), {
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
});
const inputStyle = {
  position: 'relative',
  background: 'transparent',
  boxSizing: 'border-box',
  minWidth: 0,
  outline: 'none',
  width: '100%',
  border: 'none'
};
export const InternalAdaptableDateEditor = React.forwardRef((props, ref) => {
  const inputRef = React.useRef(null);
  const focus = () => {
    var _a;
    (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
  };
  const [value, setValue] = useProperty(props, 'value', props.defaultValue, {
    onChange: value => {
      var _a, _b;
      if (value === '' || value == undefined) {
        (_a = props.onValueChange) === null || _a === void 0 ? void 0 : _a.call(props, null);
      } else {
        const date = typeof value === 'string' ? parseISO(value) : new Date(value);
        (_b = props.onValueChange) === null || _b === void 0 ? void 0 : _b.call(props, date);
      }
    }
  });
  React.useImperativeHandle(ref, () => {
    return {
      focus
    };
  });
  const stringValue = parseToISO(value, props.dateFormat);
  const onChange = React.useCallback(event => {
    setValue(event.target.value);
  }, []);
  return React.createElement("div", {
    style: hostStyle
  }, React.createElement(DatepickerContext.Provider, {
    value: {
      onHide: keyboardEventKey => {
        var _a;
        (_a = props.onStopEdit) === null || _a === void 0 ? void 0 : _a.call(props, keyboardEventKey);
      },
      onShow: () => {}
    }
  }, React.createElement(AdaptableInput, {
    type: 'date',
    value: stringValue,
    onChange: onChange,
    style: inputStyle,
    ref: inputRef
  })));
});