export const editorButtonsObservable = [{
  functionName: 'ROW_CHANGE',
  data: 'ROW_CHANGE()',
  text: 'RowChange'
}, {
  functionName: 'GRID_CHANGE',
  data: 'GRID_CHANGE()',
  text: 'GridChange'
}, {
  functionName: 'ROW_ADDED',
  data: 'ROW_ADDED()',
  text: 'RowAdded'
}, {
  functionName: 'ROW_REMOVED',
  data: 'ROW_REMOVED()',
  text: 'RowRemoved'
}, {
  functionName: 'COUNT',
  data: 'COUNT()',
  text: 'Count'
}, {
  functionName: 'NONE',
  data: 'NONE()',
  text: 'None'
}, {
  functionName: 'MIN',
  data: 'MIN()',
  text: 'Min'
}, {
  functionName: 'MAX',
  data: 'MAX()',
  text: 'Max'
}, {
  functionName: 'TIMEFRAME',
  data: 'TIMEFRAME()',
  text: 'Timeframe'
}];