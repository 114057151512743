import * as React from 'react';
import { renderWithAdaptableContext } from '../View/renderWithAdaptableContext';
export const createAgStatusPanelComponent = (component, adaptable, context) => {
  if (adaptable.variant === 'react') {
    return props => {
      const children = renderWithAdaptableContext(React.createElement(component, {
        params: props,
        adaptableApi: adaptable.api,
        context
      }), adaptable);
      return React.createElement("div", {
        className: "ab-StatusBar",
        ref: node => {
          if (node) {
            // in React rendering, AG Grid renders an additional extra div
            // around the status panel container, with className of 'ag-react-container'
            // which needs to be stretched in order to fill the status panel
            node.parentElement.style.display = 'flex';
          }
        }
      }, children);
    };
  }
  return class StatusBarRenderer {
    init(params) {
      this.params = params;
      this.eGui = document.createElement('div');
      this.eGui.classList.add('ab-StatusBar');
      this.render();
    }
    render() {
      const props = {
        params: this.params,
        adaptableApi: adaptable.api,
        context
      };
      const children = renderWithAdaptableContext(React.createElement(component, props), adaptable);
      this.unmountReactRoot = adaptable.renderReactRoot(children, this.eGui);
    }
    getGui() {
      return this.eGui;
    }
    destroy() {
      var _a;
      (_a = this.unmountReactRoot) === null || _a === void 0 ? void 0 : _a.call(this);
    }
  };
};