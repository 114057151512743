export const getScopeViewItems = (scope, api) => {
  let values = [];
  if ('ColumnIds' in scope && Array.isArray(scope.ColumnIds)) {
    values = scope.ColumnIds;
    values = scope.ColumnIds.map(columnId => api.columnApi.getFriendlyNameForColumnId(columnId)).filter(Boolean);
  }
  if ('DataTypes' in scope) {
    values = scope.DataTypes;
  }
  if ('All' in scope) {
    values = ['All Columns'];
  }
  if ('ColumnTypes' in scope) {
    values = scope.ColumnTypes;
  }
  return {
    label: 'Target',
    name: 'Target',
    values
  };
};