import * as React from 'react';
import { Text } from 'rebass';
import { convertAdaptableStyleToCSS } from '../../../Utilities/Helpers/StyleHelper';
import { StyleComponent } from '../../Components/StyleComponent';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
import UIHelper from '../../UIHelper';
export const isFormatColumnStyleValid = (data, api) => {
  if (data.Style && UIHelper.IsEmptyStyle(data.Style) && data.DisplayFormat === undefined && data.CellAlignment === undefined && !api.columnScopeApi.isSingleBooleanColumnScope(data.Scope)) {
    return 'No format applied';
  }
  return true;
};
const toStyle = data => {
  var _a, _b;
  return Object.assign(Object.assign({}, convertAdaptableStyleToCSS((_a = data.Style) !== null && _a !== void 0 ? _a : {})), {
    borderWidth: ((_b = data.Style) === null || _b === void 0 ? void 0 : _b.BorderColor) ? 2 : 0,
    borderStyle: 'solid',
    textAlign: data.CellAlignment ? data.CellAlignment.toLowerCase() : undefined
  });
};
export const renderFormatColumnStyleWizardSummary = data => {
  const {
    api
  } = useOnePageAdaptableWizardContext();
  return renderFormatColumnStyleSummary(data, api);
};
export const renderFormatColumnStyleSummary = (data, api) => {
  return React.createElement(Text, {
    className: "ab-FormatPreview",
    padding: 2,
    style: toStyle(data)
  }, "Preview result");
};
export function FormatColumnStyleWizardSection(props) {
  var _a;
  const {
    data,
    api
  } = useOnePageAdaptableWizardContext();
  return React.createElement(StyleComponent, {
    headless: true,
    api: api,
    Style: (_a = data.Style) !== null && _a !== void 0 ? _a : {},
    UpdateStyle: Style => {
      props.onChange(Object.assign(Object.assign({}, data), {
        Style
      }));
    }
  });
}