import { __rest } from "tslib";
import * as React from 'react';
import join from '../../utils/join';
import { useSelectionEvent } from '../../SelectableList';
const baseClassName = 'ab-ListGroupItem';
const ListGroupItem = React.forwardRef((props, ref) => {
  const {
      className,
      active,
      factory: Tag = 'button',
      noZebra
    } = props,
    domProps = __rest(props, ["className", "active", "factory", "noZebra"]);
  const onSelectionClick = useSelectionEvent();
  const result = React.createElement(Tag, Object.assign({}, domProps, {
    onClick: event => {
      if (domProps.onClick) {
        domProps.onClick(event);
      }
      onSelectionClick(event, {
        index: props.index
      });
    },
    ref: ref,
    className: join(className, baseClassName, noZebra ? `${baseClassName}--no-zebra` : null, `${baseClassName}--${active ? 'active' : 'not-active'}`)
  }));
  return result;
});
export default ListGroupItem;