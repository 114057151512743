import { EMPTY_ARRAY } from '../../Utilities/Constants/GeneralConstants';
import AdaptableHelper from '../../Utilities/Helpers/AdaptableHelper';
/**
 * @ReduxAction A Named Query has been added
 */
export const NAMED_QUERY_READY = 'NAMED_QUERY_READY';
/**
 * @ReduxAction A Named Query has been added
 */
export const NAMED_QUERY_ADD = 'NAMED_QUERY_ADD';
/**
 * @ReduxAction Multiple Named Queries have been added
 */
export const NAMED_QUERIES_ADD = 'NAMED_QUERY_ADD_MULTIPLE';
/**
 * @ReduxAction A Named Query has been edited
 */
export const NAMED_QUERY_EDIT = 'NAMED_QUERY_EDIT';
/**
 * @ReduxAction A Named Query has been deleted
 */
export const NAMED_QUERY_DELETE = 'NAMED_QUERY_DELETE';
export const NamedQueryAdd = namedQuery => ({
  type: NAMED_QUERY_ADD,
  namedQuery: namedQuery
});
export const NamedQueriesAdd = namedQueries => ({
  type: NAMED_QUERIES_ADD,
  namedQueries: namedQueries
});
export const NamedQueryEdit = namedQuery => ({
  type: NAMED_QUERY_EDIT,
  namedQuery: namedQuery
});
export const NamedQueryDelete = namedQuery => ({
  type: NAMED_QUERY_DELETE,
  namedQuery: namedQuery
});
export const NamedQueryReady = namedQueryState => ({
  type: NAMED_QUERY_READY,
  namedQueryState
});
const initialState = {
  NamedQueries: EMPTY_ARRAY
};
export const NamedQueryReducer = (state = initialState, action) => {
  let namedQuerys;
  switch (action.type) {
    case NAMED_QUERY_ADD:
      {
        const actionnamedQuery = action.namedQuery;
        AdaptableHelper.addUuidAndSource(actionnamedQuery);
        namedQuerys = [].concat(state.NamedQueries);
        namedQuerys.push(actionnamedQuery);
        return Object.assign(Object.assign({}, state), {
          NamedQueries: namedQuerys
        });
      }
    case NAMED_QUERIES_ADD:
      {
        const actionnamedQueries = action.namedQueries;
        actionnamedQueries.forEach(namedQuery => {
          AdaptableHelper.addUuidAndSource(namedQuery);
        });
        return Object.assign(Object.assign({}, state), {
          NamedQueries: [...state.NamedQueries, ...actionnamedQueries]
        });
      }
    case NAMED_QUERY_EDIT:
      {
        const actionnamedQuery = action.namedQuery;
        return Object.assign(Object.assign({}, state), {
          NamedQueries: state.NamedQueries.map(abObject => abObject.Uuid === actionnamedQuery.Uuid ? actionnamedQuery : abObject)
        });
      }
    case NAMED_QUERY_DELETE:
      {
        const actionnamedQuery = action.namedQuery;
        return Object.assign(Object.assign({}, state), {
          NamedQueries: state.NamedQueries.filter(abObject => abObject.Uuid !== actionnamedQuery.Uuid)
        });
      }
    default:
      return state;
  }
};