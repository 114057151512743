import * as React from 'react';
import { Box } from 'rebass';
import { OnePageAdaptableWizard, OnePageWizardSummary } from '../../../View/Wizard/OnePageAdaptableWizard';
import { ScheduleSettingsWizard, ScheduleSettingsSummary, isSettingsValid } from './ScheduleSettingsWizard';
import { ScheduleScheduleWizard, isScheduleValid } from './ScheduleScheduleWizard';
import { ScheduleScheduleSummary } from './ScheduleScheduleSummary';
import { ScheduleType } from '../../../PredefinedConfig/Common/Enums';
import ObjectFactory from '../../../Utilities/ObjectFactory';
import { useDispatch } from 'react-redux';
import * as ScheduleRedux from '../../../Redux/ActionsReducers/ScheduleRedux';
import { ObjectTagsWizardSection, renderObjectTagsSummary } from '../../Wizard/ObjectTagsWizardSection';
export const ScheduleWizard = props => {
  var _a, _b, _c, _d;
  const data = (_a = props.data) !== null && _a !== void 0 ? _a : (_b = props.popupParams) === null || _b === void 0 ? void 0 : _b.value;
  const isNew = ((_c = props.popupParams) === null || _c === void 0 ? void 0 : _c.action) ? props.popupParams.action === 'New' : !props.data;
  const scheduleType = data ? data.ScheduleType : (_d = props === null || props === void 0 ? void 0 : props.abObjectType) === null || _d === void 0 ? void 0 : _d.name;
  const [schedule, setSchedule] = React.useState(() => {
    if (data) {
      return data;
    }
    let scheduleState = null;
    switch (scheduleType) {
      case ScheduleType.Reminder:
        scheduleState = ObjectFactory.CreateEmptyReminderSchedule();
        break;
      case ScheduleType.Report:
        scheduleState = ObjectFactory.CreateEmptyReportSchedule();
        break;
      case ScheduleType.ipushpull:
        scheduleState = ObjectFactory.CreateEmptyIPushPullSchedule();
        break;
      case ScheduleType.OpenFin:
        scheduleState = ObjectFactory.CreateEmptyOpenFinSchedule();
        break;
    }
    return scheduleState;
  });
  const [isOneOff, setIsOneOff] = React.useState(false);
  const dispatch = useDispatch();
  const handleFinish = () => {
    let action = null;
    if (!isNew) {
      switch (scheduleType) {
        case ScheduleType.Reminder:
          action = ScheduleRedux.ReminderScheduleEdit(schedule);
          break;
        case ScheduleType.Report:
          action = ScheduleRedux.ReportScheduleEdit(schedule);
          break;
        case ScheduleType.ipushpull:
          action = ScheduleRedux.IPushPullScheduleEdit(schedule);
          break;
          break;
        case ScheduleType.OpenFin:
          action = ScheduleRedux.OpenFinScheduleEdit(schedule);
          break;
      }
    } else {
      switch (scheduleType) {
        case ScheduleType.Reminder:
          action = ScheduleRedux.ReminderScheduleAdd(schedule);
          break;
        case ScheduleType.Report:
          action = ScheduleRedux.ReportScheduleAdd(schedule);
          break;
        case ScheduleType.ipushpull:
          action = ScheduleRedux.IPushPullScheduleAdd(schedule);
          break;
        case ScheduleType.OpenFin:
          action = ScheduleRedux.OpenFinScheduleAdd(schedule);
          break;
      }
    }
    dispatch(action);
    props.onFinishWizard(schedule);
  };
  return React.createElement(OnePageAdaptableWizard, {
    defaultCurrentSectionName: props.defaultCurrentSectionName,
    moduleInfo: props.moduleInfo,
    data: schedule,
    onHide: props.onCloseWizard,
    onFinish: handleFinish,
    sections: [{
      title: 'Settings',
      isValid: isSettingsValid,
      renderSummary: () => React.createElement(ScheduleSettingsSummary, null),
      render: () => React.createElement(Box, {
        p: 3
      }, React.createElement(ScheduleSettingsWizard, {
        onChange: setSchedule
      }))
    }, {
      title: 'Schedule',
      isValid: data => isScheduleValid(data, isOneOff),
      renderSummary: () => React.createElement(ScheduleScheduleSummary, null),
      render: () => React.createElement(Box, {
        p: 3
      }, React.createElement(ScheduleScheduleWizard, {
        isOneOff: isOneOff,
        onSetIsOneOff: setIsOneOff,
        onChange: setSchedule
      }))
    }, {
      details: 'Select Schedule Tags',
      title: 'Tags',
      isVisible: (_, api) => api.internalApi.shouldDisplayTagSections(),
      render: () => React.createElement(Box, {
        padding: 2
      }, React.createElement(ObjectTagsWizardSection, {
        onChange: setSchedule
      })),
      renderSummary: renderObjectTagsSummary
    }, '-', {
      render: () => {
        return React.createElement(Box, {
          padding: 2
        }, React.createElement(OnePageWizardSummary, null));
      },
      title: 'Summary'
    }]
  });
};