import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '../../components/Select';
import * as ThemeRedux from '../../Redux/ActionsReducers/ThemeRedux';
import { ThemeModuleId } from '../../Utilities/Constants/ModuleConstants';
import { useAdaptable } from '../AdaptableContext';
export const ThemeSelector = props => {
  const adaptable = useAdaptable();
  const dispatch = useDispatch();
  const availableThemes = adaptable.api.themeApi.getThemes();
  const currentTheme = useSelector(state => state.Theme.CurrentTheme);
  const disabled = adaptable.api.entitlementApi.getEntitlementAccessLevelForModule(ThemeModuleId) === 'ReadOnly';
  const optionThemes = availableThemes.map(theme => {
    if (typeof theme === 'string') {
      // protection against old state, which could be string
      theme = {
        Name: theme,
        Description: theme
      };
    }
    return {
      value: theme.Name,
      label: theme.Description
    };
  });
  return React.createElement(Select, {
    "data-name": "select-theme-dropdown",
    searchable: false,
    options: optionThemes,
    value: currentTheme,
    disabled: disabled,
    size: props.size,
    onChange: themeName => {
      dispatch(ThemeRedux.ThemeSelect(themeName));
    }
  });
};