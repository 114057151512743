import * as React from 'react';
import { Tabs } from '../../../components/Tabs';
import { AlertType, getAlertTypeText } from '../Utilities/getAlertType';
import { Box, Flex } from 'rebass';
import { TypeRadio } from '../../Wizard/TypeRadio';
import { Tag } from '../../../components/Tag';
export const renderAlertTypeSummary = alertType => {
  const text = getAlertTypeText(alertType);
  return React.createElement(Box, null, React.createElement(Tag, null, text));
};
export const AlertTypeWizardSection = props => {
  return React.createElement(Tabs, null, React.createElement(Tabs.Tab, null, "Alert Type"), React.createElement(Tabs.Content, null, React.createElement(Flex, {
    flexDirection: "column"
  }, React.createElement(TypeRadio, {
    text: getAlertTypeText(AlertType.DataChange),
    description: "A change has been made to the Grid's underlying data",
    checked: props.alertType === AlertType.DataChange,
    onClick: () => props.onAlertTypeChange(AlertType.DataChange)
  }), React.createElement(TypeRadio, {
    text: getAlertTypeText(AlertType.RowChange),
    description: "A Row was added or removed from the data source",
    checked: props.alertType === AlertType.RowChange,
    onClick: () => props.onAlertTypeChange(AlertType.RowChange)
  }), React.createElement(TypeRadio, {
    text: getAlertTypeText(AlertType.Aggregation),
    description: "A change has been made to aggregated data (i.e. from multiple Rows)",
    onClick: () => props.onAlertTypeChange(AlertType.Aggregation),
    checked: props.alertType === AlertType.Aggregation
  }), React.createElement(TypeRadio, {
    text: getAlertTypeText(AlertType.Observable),
    description: "A specified change (or lack of change) over time has been observed in the Grid",
    onClick: () => props.onAlertTypeChange(AlertType.Observable),
    checked: props.alertType === AlertType.Observable
  }), React.createElement(TypeRadio, {
    text: getAlertTypeText(AlertType.Validation),
    description: "A change has broken a data validation rule",
    checked: props.alertType === AlertType.Validation,
    onClick: () => props.onAlertTypeChange(AlertType.Validation)
  }))));
};