import * as React from 'react';
import { Box, Flex } from 'rebass';
import { useAdaptable } from '../../../../AdaptableContext';
import { PercentBarStylePreview } from './PercentBarStylePreview';
export const PercentBarColumnComparisonPreview = props => {
  const {
    api
  } = useAdaptable();
  const columnComparison = props.percentBarStyle.ColumnComparison;
  return React.createElement(Flex, {
    alignItems: "center",
    mr: 3
  }, React.createElement(Box, {
    flexBasis: 120,
    mr: 3
  }, isNaN(Number(columnComparison.MinValue)) ? '[' + api.columnApi.getFriendlyNameForColumnId(String(columnComparison.MinValue)) + ']' : columnComparison.MinValue, ' ', "to", ' ', isNaN(Number(columnComparison.MaxValue)) ? '[' + api.columnApi.getFriendlyNameForColumnId(String(columnComparison.MaxValue)) + ']' : columnComparison.MaxValue, ' '), React.createElement(PercentBarStylePreview, {
    backgroundColor: columnComparison.Color,
    barBackgroundColor: props.percentBarStyle.BackColor,
    cellText: props.percentBarStyle.CellText,
    toolTipText: props.percentBarStyle.ToolTipText
  }));
};