import * as React from 'react';
import { Box } from 'rebass';
import HelpBlock from '../../components/HelpBlock';
import SimpleButton from '../../components/SimpleButton';
import { PopupPanel } from '../Components/Popups/AdaptablePopup/PopupPanel';
import { DataImportWizard } from './DataImportWizard';
export const DataImportPopup = props => {
  const [isWizardOpen, setIsWizardOpen] = React.useState(false);
  React.useEffect(() => {
    var _a, _b;
    if ((props === null || props === void 0 ? void 0 : props.popupParams) == undefined || ((_a = props.popupParams) === null || _a === void 0 ? void 0 : _a.action) === 'New' && ((_b = props.popupParams) === null || _b === void 0 ? void 0 : _b.source) !== 'ModuleMenu') {
      setIsWizardOpen(true);
    }
  }, []);
  return React.createElement(React.Fragment, null, React.createElement(PopupPanel, {
    headerText: 'Data Import',
    glyphicon: 'import',
    infoLink: props.moduleInfo.HelpPage
  }, React.createElement(Box, null, React.createElement(HelpBlock, {
    mb: 2,
    fontSize: 3
  }, "Click the 'Import Data' button to Insert or Update data into AdapTable"), React.createElement(SimpleButton, {
    icon: "import",
    variant: "raised",
    onClick: () => setIsWizardOpen(true)
  }, "Import Data"))), isWizardOpen && React.createElement(DataImportWizard, {
    onClose: () => {
      var _a;
      setIsWizardOpen(false);
      if ((props === null || props === void 0 ? void 0 : props.popupParams) == undefined || ['Toolbar', 'ContextMenu', 'ColumnMenu', 'ModuleButton'].includes((_a = props === null || props === void 0 ? void 0 : props.popupParams) === null || _a === void 0 ? void 0 : _a.source)) {
        props.onClosePopup();
      }
    }
  }));
};