import * as React from 'react';
import { Flex } from 'rebass';
import Input from '../../../components/Input';
import Radio from '../../../components/Radio';
import FormLayout, { FormRow } from '../../../components/FormLayout';
import { Tabs } from '../../../components/Tabs';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
import { PlusMinusRuleSummary, PlusMinusRuleWizardSection } from './PlusMinusRuleWizardSection';
import { isAdaptableRuleValid } from '../../Components/EntityRulesEditor/Utilities';
import { SummaryText } from '../../../View/Wizard/OnePageAdaptableWizard';
import { Tag } from '../../../components/Tag';
export const PlusMinusSettingsSummary = props => {
  var _a;
  const {
    data
  } = useOnePageAdaptableWizardContext();
  return React.createElement(React.Fragment, null, React.createElement(SummaryText, null, "Nudge Value ", React.createElement(Tag, null, (_a = data.NudgeValue) !== null && _a !== void 0 ? _a : 'Not selected')), props.hasCondition && React.createElement(PlusMinusRuleSummary, null));
};
export const isSettingsValid = hasCondition => (data, api, context) => {
  var _a;
  if (hasCondition) {
    if (!((_a = data === null || data === void 0 ? void 0 : data.Rule) === null || _a === void 0 ? void 0 : _a.BooleanExpression)) {
      return 'No valid Rule is specified';
    }
    const ruleValidation = isAdaptableRuleValid(data, api, context);
    if (typeof ruleValidation === 'string') {
      return ruleValidation;
    }
  }
  if (typeof data.NudgeValue !== 'number') {
    return 'Nudge value is not specified';
  }
  if (data.NudgeValue === 0) {
    return 'Nudge value must be different than 0';
  }
  return true;
};
export const PlusMinusSettingsWizardSection = props => {
  var _a;
  const {
    data
  } = useOnePageAdaptableWizardContext();
  const handleNudgeValueChange = event => {
    const newValue = parseFloat(event.target.value);
    props.onChange(Object.assign(Object.assign({}, data), {
      NudgeValue: isNaN(newValue) ? '' : newValue
    }));
  };
  return React.createElement(Flex, {
    height: "100%",
    flexDirection: "column",
    "data-name": "plus-minus-column-settings"
  }, React.createElement(Tabs, {
    autoFocus: false,
    padding: 2
  }, React.createElement(Tabs.Tab, null, "Plus Minus Settings"), React.createElement(Tabs.Content, null, React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Nudge Value"
  }, React.createElement(Input, {
    "data-name": "nudge-value",
    flex: 1,
    marginRight: 3,
    onChange: handleNudgeValueChange,
    placeholder: "Enter Number",
    type: "number",
    value: (_a = data.NudgeValue) !== null && _a !== void 0 ? _a : ''
  })), React.createElement(FormRow, {
    label: "Nudge Type"
  }, React.createElement(Flex, null, React.createElement(Radio, {
    "data-name": "nudge-type-always",
    value: "Always",
    checked: !props.hasCondition,
    onChange: () => props.onConditionChange(false)
  }, "Always Apply Nudge"), React.createElement(Radio, {
    "data-name": "nudge-type-condition",
    marginLeft: 3,
    value: "Expression",
    checked: props.hasCondition,
    onChange: () => props.onConditionChange(true)
  }, "Create a Rule")))))), props.hasCondition && React.createElement(PlusMinusRuleWizardSection, {
    defaultPredicateId: "NonBlanks",
    onChange: props.onChange
  }));
};