import cloneDeepWith from 'lodash/cloneDeepWith';
import isPlainObject from 'lodash/isPlainObject';
import { createUuid } from '../../PredefinedConfig/Uuid';
export function initPredefinedConfigWithUuids(predefinedConfig) {
  const customizer = value => {
    // so whenever we clone a plain object,
    // we add a Uuid property
    // jw added 9/8/20: unless there is on there already
    if (isPlainObject(value) && value != predefinedConfig) {
      if (value.Uuid == null || value.Uuid == undefined) {
        value.Uuid = createUuid();
      }
    }
  };
  return cloneDeepWith(predefinedConfig, customizer);
}
export function getAccessLevelForObject(adaptableObject, moduleAccessLevel) {
  // we always return the Module Access Level unless the following condition is met:
  // the object prpoerty has been set and set to true and the Module entitlement is full
  if (moduleAccessLevel == 'Full' && adaptableObject && adaptableObject.IsReadOnly && adaptableObject.IsReadOnly == true) {
    return 'ReadOnly';
  }
  return moduleAccessLevel;
}
export function addUuidAndSource(adaptableObject) {
  if (!adaptableObject.Uuid) {
    adaptableObject.Uuid = createUuid();
  }
  adaptableObject.Source = 'User';
  return adaptableObject;
}
export function removeUuidAndSource(adaptableObject) {
  const clonedObject = structuredClone(adaptableObject);
  const sanitiseObject = object => {
    for (const key in object) {
      if (key === 'Source' || key === 'Uuid') {
        delete object[key];
      } else if (Array.isArray(object[key])) {
        object[key].forEach(item => sanitiseObject(item));
      } else if (typeof object[key] === 'object') {
        sanitiseObject(object[key]);
      }
    }
  };
  sanitiseObject(clonedObject);
  return clonedObject;
}
// pretty primitive type guard for AdaptableObject, but currently there is no better/safer way
export function isAdaptableObject(object) {
  return object != undefined && typeof object['Uuid'] === 'string';
}
export const AdaptableHelper = {
  initPredefinedConfigWithUuids,
  getAccessLevelForObject,
  addUuidAndSource,
  removeUuidAndSource,
  isAdaptableObject
};
export default AdaptableHelper;