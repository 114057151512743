import * as LayoutRedux from '../../Redux/ActionsReducers/LayoutRedux';
import { LayoutSetColumnCaption } from '../../Redux/ActionsReducers/LayoutRedux';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import { ApiBase } from './ApiBase';
import StringExtensions from '../../Utilities/Extensions/StringExtensions';
import ObjectFactory from '../../Utilities/ObjectFactory';
import Helper from '../../Utilities/Helpers/Helper';
import { createUuid } from '../../PredefinedConfig/Uuid';
import { DEFAULT_LAYOUT } from '../../Utilities/Constants/GeneralConstants';
import { PopupShowPrompt } from '../../Redux/ActionsReducers/PopupRedux';
import { LayoutInternalApi } from '../Internal/LayoutInternalApi';
export class LayoutApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.shouldAutoSaveLayouts = () => {
      var _a;
      return (_a = this.getLayoutOptions()) === null || _a === void 0 ? void 0 : _a.autoSaveLayouts;
    };
    this.internalApi = new LayoutInternalApi(adaptable);
  }
  isCurrentLayoutPivot() {
    return this.getCurrentLayout().EnablePivot;
  }
  getLayoutState() {
    return this.getAdaptableState().Layout;
  }
  getCurrentVisibleColumnIdsMap() {
    const layout = this.getCurrentLayout();
    return layout.Columns.reduce((acc, colId) => {
      acc[colId] = true;
      return acc;
    }, {});
  }
  getCurrentVisibleColumnIds() {
    return this.getCurrentLayout().Columns;
  }
  getCurrentRowGroupsColumnIds() {
    return this.getCurrentLayout().RowGroupedColumns;
  }
  setLayout(layoutName) {
    var _a;
    if (StringExtensions.IsNullOrEmpty(layoutName)) {
      return;
    }
    if (((_a = this.getLayoutOptions()) === null || _a === void 0 ? void 0 : _a.autoSaveLayouts) && layoutName === this.getCurrentLayoutName()) {
      return;
    }
    let layout = this.getLayoutByName(layoutName);
    if (this.checkItemExists(layout, layoutName, 'Layout')) {
      this.dispatchAction(LayoutRedux.LayoutSelect(layoutName));
    }
  }
  getCurrentLayout() {
    const state = this.getAdaptableState();
    if (state.Grid.CurrentLayout) {
      return state.Grid.CurrentLayout;
    }
    let layoutName = state.Layout.CurrentLayout;
    return this.getLayoutByName(layoutName) || ObjectFactory.CreateEmptyLayout({
      Name: DEFAULT_LAYOUT
    });
  }
  getCurrentLayoutColumnSort(columnId) {
    var _a, _b, _c, _d;
    const currentLayout = this.getCurrentLayout();
    return (_d = (_c = (_b = (_a = currentLayout === null || currentLayout === void 0 ? void 0 : currentLayout.ColumnSorts) === null || _a === void 0 ? void 0 : _a.find) === null || _b === void 0 ? void 0 : _b.call(_a, sort => sort.ColumnId === columnId)) === null || _c === void 0 ? void 0 : _c.SortOrder) !== null && _d !== void 0 ? _d : null;
  }
  getCurrentLayoutName() {
    return this.getAdaptableState().Layout.CurrentLayout;
  }
  getLayoutByName(layoutName) {
    if (StringExtensions.IsNotNullOrEmpty(layoutName)) {
      let layout = this.getLayouts().find(l => l.Name == layoutName);
      if (this.checkItemExists(layout, layoutName, 'Layout')) {
        return layout;
      }
    }
  }
  getExtendedLayoutByName(layoutName) {
    const layout = this.getLayoutByName(layoutName);
    if (!layout) {
      return undefined;
    }
    const config = {
      includeLayoutNotAssociatedObjects: true,
      associatedWithLayout: layoutName
    };
    // cannot see a better way than to go through this module by module...
    let extensions = [];
    this.getAlertApi().getAlertDefinitions(config).forEach(obj => {
      extensions.push({
        Module: 'Alert',
        Object: obj
      });
    });
    this.getCustomSortApi().getCustomSorts(config).forEach(obj => {
      extensions.push({
        Module: 'CustomSort',
        Object: obj
      });
    });
    this.getFlashingCellApi().getFlashingCellDefinitions(config).forEach(obj => {
      extensions.push({
        Module: 'FlashingCell',
        Object: obj
      });
    });
    this.getFormatColumnApi().getFormatColumns(config).forEach(obj => {
      extensions.push({
        Module: 'FormatColumn',
        Object: obj
      });
    });
    this.getPlusMinusApi().getAllPlusMinus(config).forEach(obj => {
      extensions.push({
        Module: 'PlusMinus',
        Object: obj
      });
    });
    this.getScheduleApi().getReminderSchedules(config).forEach(obj => {
      extensions.push({
        Module: 'Schedule',
        Object: obj
      });
    });
    this.getScheduleApi().getReportSchedules(config).forEach(obj => {
      extensions.push({
        Module: 'Schedule',
        Object: obj
      });
    });
    this.getShortcutApi().getShortcuts(config).forEach(obj => {
      extensions.push({
        Module: 'Shortcut',
        Object: obj
      });
    });
    this.getStyledColumnApi().getStyledColumns(config).forEach(obj => {
      extensions.push({
        Module: 'StyledColumn',
        Object: obj
      });
    });
    return {
      Layout: layout,
      Extensions: extensions
    };
  }
  getLayouts() {
    var _a;
    return (_a = this.getAdaptableState().Layout.Layouts) !== null && _a !== void 0 ? _a : [];
  }
  getLayoutById(id) {
    var _a;
    return (_a = this.getLayouts()) === null || _a === void 0 ? void 0 : _a.find(layout => (layout === null || layout === void 0 ? void 0 : layout.Uuid) === id);
  }
  saveCurrentLayout() {
    var _a;
    let currentLayout = this.getCurrentLayout();
    if (!((_a = this.getLayoutOptions()) === null || _a === void 0 ? void 0 : _a.autoSaveLayouts)) {
      currentLayout = this.getAdaptableState().Grid.CurrentLayout || currentLayout;
    }
    if (currentLayout) {
      this.createOrUpdateLayout(currentLayout);
    }
  }
  doesLayoutExist(layout) {
    if (layout == null) {
      return false;
    }
    let existingLayout = this.getLayouts().find(l => l.Uuid == layout.Uuid || l.Name === layout.Name);
    return existingLayout != null;
  }
  createAndSetLayout(layoutToCreate) {
    const layout = this.createLayout(layoutToCreate);
    if (layout) {
      this.setLayout(layoutToCreate.Name);
    }
    return layout;
  }
  createLayout(layoutToCreate) {
    if (this.doesLayoutExist(layoutToCreate)) {
      this.logError("Cannot create layout with the Name: '" + layoutToCreate.Name + "' as it already exists");
      return false;
    }
    const newLayout = ObjectFactory.CreateEmptyLayout(Object.assign({}, layoutToCreate));
    this.addUidToAdaptableObject(newLayout);
    this.dispatchAction(LayoutRedux.LayoutAdd(newLayout));
    return newLayout;
  }
  cloneAndSetLayout(layoutToClone, layoutName) {
    const newLayout = this.cloneLayout(layoutToClone, layoutName);
    if (newLayout) {
      this.setLayout(layoutName);
    }
    return newLayout;
  }
  cloneLayout(layoutToClone, layoutName) {
    if (!this.doesLayoutExist(layoutToClone)) {
      this.logError("Cannot clone layout with Name: '" + layoutName + "' as other Layout does not exist");
      return false;
    }
    const newLayout = Helper.cloneObject(layoutToClone);
    newLayout.Uuid = createUuid();
    newLayout.Name = layoutName;
    this.dispatchAction(LayoutRedux.LayoutAdd(newLayout));
    return this.getLayoutById(newLayout.Uuid);
  }
  setColumnCaption(columnId, caption) {
    if (StringExtensions.IsNotNullOrEmpty(columnId) && StringExtensions.IsNotNullOrEmpty(caption)) {
      const currentLayoutName = this.getCurrentLayoutName();
      if (StringExtensions.IsNotNullOrEmpty(currentLayoutName)) {
        this.dispatchAction(LayoutRedux.LayoutSetColumnCaption(currentLayoutName, columnId, caption));
      }
    }
  }
  createOrUpdateLayout(layout) {
    if (!this.doesLayoutExist(layout)) {
      this.createLayout(layout);
    } else {
      this.dispatchAction(LayoutRedux.LayoutSave(layout));
    }
  }
  showChangeColumnCaption(column) {
    this.dispatchAction(PopupShowPrompt({
      Header: `Change caption for '${column.friendlyName}'`,
      Msg: '',
      DefaultValue: column.friendlyName,
      ConfirmActionCreator: inputText => inputText !== column.friendlyName && LayoutSetColumnCaption(this.getCurrentLayoutName(), column.columnId, inputText)
    }));
  }
  openLayoutSettingsPanel() {
    this.showModulePopup(ModuleConstants.LayoutModuleId);
  }
  showLayoutEditor(layoutName, action = 'Edit') {
    let preparedAction = action;
    if (!['Edit', 'New', 'Clone'].includes(action)) {
      preparedAction = 'Edit';
      this.logError(`When opening the layout editor the action must be one of: New, Clone, Edit; given: ${action}`);
    }
    let layout = layoutName ? this.getLayoutByName(layoutName) : this.getCurrentLayout();
    if (preparedAction === 'New') {
      layout = null;
    }
    this.showModulePopup(ModuleConstants.LayoutModuleId, {
      source: 'Toolbar',
      action: preparedAction,
      value: layout
    });
  }
  isCurrentLayoutReadOnly() {
    const currentLayout = this.getCurrentLayout();
    if (currentLayout) {
      return currentLayout.IsReadOnly;
    }
    return true;
  }
  deleteLayout(layout) {
    if (layout.Name === DEFAULT_LAYOUT) {
      this.logWarn('The Default Layout cannot be deleted');
      return;
    }
    const layoutCount = this.getLayouts().length;
    if (layoutCount === 1) {
      this.logWarn('You cannot delete the only Layout.  AdapTable always requires one layout');
      return;
    }
    this.dispatchAction(LayoutRedux.LayoutDelete(layout));
  }
  deleteLayoutByName(layoutName) {
    const layout = this.getLayoutByName(layoutName);
    if (!layout) {
      this.logWarn('No Layout exists with that name');
      return;
    }
    return this.deleteLayout(layout);
  }
  isDefaultLayout(layout) {
    return layout.Name === DEFAULT_LAYOUT;
  }
  isCurrentLayoutDefault() {
    return this.isDefaultLayout(this.getCurrentLayout());
  }
  removeColumnFromCurrentLayout(columnId) {
    this.removeColumnFromLayout(columnId, this.getCurrentLayoutName());
  }
  removeColumnFromAllLayouts(columnId) {
    this.getLayouts().forEach(layout => {
      this.removeColumnFromLayout(columnId, layout.Name);
    });
  }
  removeColumnFromLayout(columnId, layoutName) {
    const column = this.getAdaptableApi().columnApi.getColumnWithColumnId(columnId);
    if (column) {
      const layout = this.getLayoutByName(layoutName);
      if (layout) {
        if (layout.Columns.includes(columnId)) {
          this.dispatchAction(LayoutRedux.LayoutRemoveColumn(layoutName, columnId));
          this.getAdaptableApi().columnApi.hideColumn(columnId);
        }
      }
    }
  }
  addColumnToLayout(columnId, layoutName) {
    const column = this.getAdaptableApi().columnApi.getColumnWithColumnId(columnId);
    if (column) {
      const layout = this.getLayoutByName(layoutName);
      if (layout) {
        if (!layout.Columns.includes(columnId)) {
          this.dispatchAction(LayoutRedux.LayoutAddColumn(layoutName, columnId));
          this.getAdaptableApi().columnApi.showColumn(columnId);
        }
      }
    }
  }
  addColumnToCurrentLayout(columnId) {
    this.addColumnToLayout(columnId, this.getCurrentLayoutName());
  }
}