import * as React from 'react';
import { withTheme } from 'styled-components';
import NewPanel from '../../../components/Panel';
import { Box, Flex } from 'rebass';
import { allIcons } from '../../../components/icons';
import { ButtonInfo } from '../Buttons/ButtonInfo';
//We cannot destructure this.props using the react way in typescript which is a real pain as you
//need to transfer props individually as a consequence
//let { buttonContent, ...other } = this.props
class PanelWithImageCmp extends React.Component {
  render() {
    var _a;
    const IconCmp = allIcons[this.props.icon || this.props.glyphicon];
    const headerStyle = {};
    if (this.props.headerColor) {
      headerStyle.color = this.props.headerColor;
      headerStyle.fill = this.props.headerColor;
    }
    let headerRow = React.createElement(Flex, {
      flex: 1
    }, React.createElement(Flex, {
      alignItems: "center"
    }, React.createElement(Flex, {
      minHeight: 15,
      alignItems: "center",
      style: headerStyle
    }, IconCmp ? React.createElement(IconCmp, null) : null, React.createElement(Box, {
      marginRight: 2
    }), (_a = this.props.header) !== null && _a !== void 0 ? _a : '', React.createElement(Box, {
      marginRight: 2,
      "data-name": "space-after"
    }), this.props.infoLink != null && !this.props.infoLinkDisabled && React.createElement(ButtonInfo, {
      onClick: () => window.open(this.props.infoLink, '_blank' // <- opens the link in a new tab or window.
      )
    })), React.createElement(Box, {
      flex: 1
    }), this.props.button && React.cloneElement(this.props.button)));
    return React.createElement(NewPanel, {
      flex: this.props.flex,
      header: headerRow,
      variant: this.props.variant,
      style: this.props.style,
      bodyScroll: this.props.bodyScroll !== undefined ? this.props.bodyScroll : true,
      border: "none",
      borderRadius: this.props.borderRadius || 'none',
      bodyProps: Object.assign({
        padding: 0
      }, this.props.bodyProps)
    }, this.props.children);
  }
}
// apparently it's very hard to type `withTheme()` result in typescript
// @ts-ignore
export const PanelWithImage = withTheme(PanelWithImageCmp);