import { ApiBase } from '../Implementation/ApiBase';
export class NamedQueryInternalApi extends ApiBase {
  /**
   * Returns the name of all the referenced Named Queries as an array of strings
   * @param expression query string
   */
  getReferencedNamedQueryNames(expression = '') {
    try {
      return this.getAdaptableApi().internalApi.getQueryLanguageService().getNamedQueryNamesFromExpression(expression);
    } catch (error) {
      // defensive programing, this should happen only if someone botched the predefined configs
      this.adaptable.logger.warn(`QueryApi.getReferencedNamedQueryNames received an invalid expression: ${expression}
                  Error: ${error}`);
      return [];
    }
  }
  /**
   * Returns all the references in the AdaptableState of the NamedQuery with the given name
   * @param namedQueryName namedQuery name
   */
  getNamedQueryModuleReferences(namedQueryName) {
    const references = [];
    this.adaptable.adaptableModules.forEach(module => {
      if (module.getModuleNamedQueryReferences().some(moduleReference => moduleReference === namedQueryName)) {
        references.push(module.moduleInfo.FriendlyName);
      }
    });
    return references;
  }
}