import * as React from 'react';
import ListGroupItem from '../../../components/List/ListGroupItem';
import ListGroup from '../../../components/List/ListGroup';
import { IconComponent } from '../../../components/Icon';
export class ListBoxMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let menuItems = this.props.MenuItems.map((menuItem, index) => {
      return React.createElement(ListGroupItem, {
        key: index,
        onClick: () => this.onClick(menuItem)
      }, menuItem.icon && React.createElement(IconComponent, {
        icon: menuItem.icon
      }), menuItem.label);
    });
    return React.createElement("div", {
      style: divStyle
    }, React.createElement(ListGroup, null, menuItems));
  }
  onClick(menuItem) {
    this.props.onMenuItemClick(menuItem);
  }
}
let divStyle = {
  overflowY: 'auto',
  overflowX: 'hidden',
  height: '450px',
  marginBottom: '0'
};