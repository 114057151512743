import * as React from 'react';
import { Box, Flex } from 'rebass';
import Input from '../../../components/Input';
import { shortcutOperationList } from '../shortcutOperations';
import FormLayout, { FormRow } from '../../../components/FormLayout';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
import { Tabs } from '../../../components/Tabs';
import { SummaryText } from '../../../View/Wizard/OnePageAdaptableWizard';
import { Tag } from '../../../components/Tag';
import HelpBlock from '../../../components/HelpBlock';
import { Select } from '../../../components/Select';
export const isSettingsValid = data => {
  const shortcutKey = data.ShortcutKey && typeof data.ShortcutKey === 'string' ? '' : 'Shortcut key is not selected';
  const shortcutValue = typeof data.ShortcutValue === 'number' ? '' : 'Shortcut value is not specified';
  const shortcutOperation = data.ShortcutOperation && typeof data.ShortcutOperation === 'string' ? '' : 'Shortcut operation is not selected';
  const result = [shortcutKey, shortcutValue, shortcutOperation].filter(Boolean);
  return result.length ? result.join(', ') : true;
};
export const ShortcutSettingsSummary = () => {
  var _a, _b;
  const {
    data: shortcut
  } = useOnePageAdaptableWizardContext();
  return React.createElement(React.Fragment, null, React.createElement(SummaryText, null, "Shortcut Key ", React.createElement(Tag, null, shortcut.ShortcutKey || 'Not selected')), React.createElement(SummaryText, null, "Value ", React.createElement(Tag, null, (_a = shortcut.ShortcutValue) !== null && _a !== void 0 ? _a : 'Not specified')), React.createElement(SummaryText, null, "Operation ", React.createElement(Tag, null, (_b = shortcut.ShortcutOperation) !== null && _b !== void 0 ? _b : 'Not Specified')));
};
export const ShortcutSettingsWizard = props => {
  var _a;
  const {
    data: shortcut
  } = useOnePageAdaptableWizardContext();
  const handleKeyChange = React.useCallback(ShortcutKey => {
    props.onChange(Object.assign(Object.assign({}, shortcut), {
      ShortcutKey
    }));
  }, [shortcut]);
  const handleOperationChange = React.useCallback(ShortcutOperation => props.onChange(Object.assign(Object.assign({}, shortcut), {
    ShortcutOperation
  })), [shortcut]);
  const handleOperationValueChange = React.useCallback(event => {
    props.onChange(Object.assign(Object.assign({}, shortcut), {
      ShortcutValue: event.target.value ? parseFloat(event.target.value) : event.target.value
    }));
  }, [shortcut]);
  const optionActions = shortcutOperationList.map(operation => ({
    value: operation,
    label: operation
    // onClick: () => handleOperationChange(operation),
  }));
  const optionKeys = props.availableKeys.map(key => ({
    value: key,
    label: key
  }));
  return React.createElement(Box, {
    "data-name": "shortcut-column-settings"
  }, React.createElement(Tabs, {
    autoFocus: false
  }, React.createElement(Tabs.Tab, null, "Shortcut Settings"), React.createElement(Tabs.Content, null, React.createElement(FormLayout, null, React.createElement(FormRow, null, ' ', React.createElement(HelpBlock, {
    fontSize: 2,
    mb: 0
  }, "Keyboard key that, when pressed, triggers the Shortcut")), React.createElement(FormRow, {
    label: "Key"
  }, React.createElement(Flex, {
    flexDirection: "row"
  }, React.createElement(Select, {
    "data-name": "shortcut-key",
    placeholder: "Select Key",
    options: optionKeys,
    onChange: key => handleKeyChange(key),
    value: shortcut.ShortcutKey || 'Select Key'
  }))), React.createElement(FormRow, null, ' ', React.createElement(HelpBlock, {
    fontSize: 2,
    mb: 0
  }, "Mathematical operation performed on Cell's current value (using the Shortcut's 'value') - used to calculate the Cell's new total")), React.createElement(FormRow, {
    label: "Operation"
  }, React.createElement(Flex, {
    flexDirection: "row"
  }, React.createElement(Select, {
    "data-name": "shortcut-operation",
    placeholder: "Select Operation",
    options: optionActions,
    onChange: operation => handleOperationChange(operation),
    value: shortcut.ShortcutOperation
  }))), React.createElement(FormRow, null, ' ', React.createElement(HelpBlock, {
    fontSize: 2,
    mb: 0
  }, "Number that is used - together with the 'Operation' and the current cell value - to calculate the new total for the cell")), React.createElement(FormRow, {
    label: "Value"
  }, React.createElement(Flex, {
    flexDirection: "row"
  }, React.createElement(Input, {
    "data-name": "shortcut-value",
    style: {
      maxWidth: 100
    },
    flex: 1,
    marginRight: 3,
    onChange: handleOperationValueChange,
    placeholder: "Enter Number",
    type: "number",
    value: (_a = shortcut.ShortcutValue) !== null && _a !== void 0 ? _a : ''
  })))))));
};