import { ApiBase } from './ApiBase';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import * as BulkUpdateRedux from '../../Redux/ActionsReducers/BulkUpdateRedux';
export class BulkUpdateApiImpl extends ApiBase {
  getBulkUpdateValue() {
    return this.getAdaptableState().System.BulkUpdateValue;
  }
  openBulkUpdateSettingsPanel() {
    this.showModulePopup(ModuleConstants.BulkUpdateModuleId);
  }
  showBulkUpdatePopup() {
    this.openBulkUpdateSettingsPanel();
  }
  applyBulkUpdate(cellUpdateRequests) {
    this.dispatchAction(BulkUpdateRedux.BulkUpdateApply(cellUpdateRequests));
  }
}