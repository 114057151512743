import { ApiBase } from '../Implementation/ApiBase';
import { CreateEmptyCalculatedColumn } from '../../Utilities/ObjectFactory';
import { AB_SPECIAL_COLUMN } from '../../Utilities/Constants/GeneralConstants';
import ArrayExtensions from '../../Utilities/Extensions/ArrayExtensions';
import StringExtensions from '../../Utilities/Extensions/StringExtensions';
export class CalculatedColumnInternalApi extends ApiBase {
  /**
   * Returns all the references in the AdaptableState of the given CalculatedColumn
   * @param calculatedColumn CalculatedColumn to be checked
   */
  getCalculatedColumnModuleReferences(calculatedColumn) {
    const references = [];
    this.adaptable.adaptableModules.forEach(module => {
      if (module.getModuleCalculatedColumnReferences().some(moduleReference => moduleReference.Uuid === calculatedColumn.Uuid)) {
        references.push(module.moduleInfo.FriendlyName);
      }
    });
    return references;
  }
  /**
   * Returns all Calculated Columns whose Expression contains the ColumnId
   * @param columnId ColumnId to check
   */
  getCalculatedColumnsReferencingColumnId(columnId) {
    const calcColumns = [];
    this.getCalculatedColumnApi().getCalculatedColumns().forEach(calculatedColumn => {
      if (this.getExpressionApi().internalApi.isColumnReferencedInExpression(columnId, this.getAdaptableApi().expressionApi.getAdaptableQueryExpression(calculatedColumn.Query))) {
        calcColumns.push(calculatedColumn);
      }
    });
    return calcColumns;
  }
  /**
   * Gets any ColumnIds referenced in a Calculated Column
   * @param calculatedColumn Calculated Column to check
   */
  getReferencedColumnIdsForCalculatedColumn(calculatedColumn) {
    const columnIds = this.getAdaptableApi().expressionApi.getColumnsFromExpression(this.getAdaptableApi().expressionApi.getAdaptableQueryExpression(calculatedColumn.Query));
    return columnIds;
  }
  /**
   * Gets any ColumnIds referenced in a Calculated Column
   * @param calculatedColumnId CalculatedColumnId to check
   */
  getReferencedColumnIdsForCalculatedColumnId(calculatedColumnId) {
    const calculatedColumn = this.getAdaptableApi().calculatedColumnApi.getCalculatedColumns().find(cc => cc.ColumnId == calculatedColumnId);
    if (calculatedColumn) {
      return this.getReferencedColumnIdsForCalculatedColumn(calculatedColumn);
    }
  }
  getCalculatedColumnsDependentOnColumn(column) {
    const calculatedColumns = this.getAdaptableApi().calculatedColumnApi.getCalculatedColumns();
    return calculatedColumns.filter(calculatedColumn => {
      const dependentColumns = this.getReferencedColumnIdsForCalculatedColumnId(calculatedColumn.ColumnId);
      return dependentColumns.includes(column.columnId);
    }).map(calculatedColumn => calculatedColumn.ColumnId);
  }
  isCumulativeAggregatedExpression(input) {
    var _a;
    return !!((_a = this.getAdaptableApi().internalApi.getQueryLanguageService().getNodesFromExpression(input, 'CUMUL')) === null || _a === void 0 ? void 0 : _a.length);
  }
  isQuantileAggregatedExpression(input) {
    var _a;
    return !!((_a = this.getAdaptableApi().internalApi.getQueryLanguageService().getNodesFromExpression(input, 'QUANT')) === null || _a === void 0 ? void 0 : _a.length);
  }
  getExpressionFromCalculatedColumn(calculatedColumn) {
    var _a;
    return (_a = calculatedColumn.Query.ScalarExpression) !== null && _a !== void 0 ? _a : calculatedColumn.Query.AggregatedScalarExpression;
  }
  fireCalculatedColumnChangedEvent(trigger, calculatedColumn) {
    const adaptableApi = this.getAdaptableApi();
    const calculatedColumnChangedInfo = Object.assign({
      actionName: trigger,
      calculatedColumn: calculatedColumn,
      calculatedColumnExpressionAST: adaptableApi.expressionApi.getASTForExpression(this.getExpressionFromCalculatedColumn(calculatedColumn))
    }, this.getAdaptableApi().internalApi.buildBaseContext());
    this.getAdaptableApi().eventApi.emit('CalculatedColumnChanged', calculatedColumnChangedInfo);
  }
  getColDefsForCalculatedColumns() {
    const defaultSpecialColumnSettings = this.getGridApi().internalApi.deriveSpecialColumnSettingsFromAgGridDefaultColDef();
    const defaultCalculatedColumnSettings = CreateEmptyCalculatedColumn(defaultSpecialColumnSettings).CalculatedColumnSettings;
    return this.getCalculatedColumnApi().getCalculatedColumns().map(calculatedColumn => {
      const calculatedColumnSettings = Object.assign(Object.assign({}, defaultCalculatedColumnSettings), calculatedColumn.CalculatedColumnSettings);
      if (!calculatedColumnSettings.DataType) {
        calculatedColumnSettings.DataType = this.getAdaptableApi().internalApi.getAdaptableInstance().CalculatedColumnExpressionService.getCalculatedColumnDataType(calculatedColumn.Query);
      }
      const columnTypes = [AB_SPECIAL_COLUMN, this.getGridApi().internalApi.getAgGridDataType(calculatedColumnSettings.DataType)];
      if (ArrayExtensions.IsNotNullOrEmpty(calculatedColumnSettings.ColumnTypes)) {
        columnTypes.push(...calculatedColumnSettings.ColumnTypes);
      }
      const isExternalEvaluation = !this.getExpressionApi().internalApi.evaluateExpressionInAdaptableQL('CalculatedColumn', calculatedColumn, this.getExpressionApi().getAdaptableQueryExpression(calculatedColumn.Query));
      const valueGetter = params => {
        var _a, _b;
        if (isExternalEvaluation) {
          return (_a = params.data) === null || _a === void 0 ? void 0 : _a[calculatedColumn.ColumnId];
        }
        // if the grid data is NOT client side, we can aggregate only simple scalar expression (which depend on row data, not on other rows)
        if (this.getGridApi().getAgGridRowModelType() !== 'clientSide' && StringExtensions.IsNotNullOrEmpty(calculatedColumn.Query.AggregatedScalarExpression)) {
          return (_b = params.data) === null || _b === void 0 ? void 0 : _b[calculatedColumn.ColumnId];
        }
        return this.getAdaptableApi().internalApi.getAdaptableInstance().CalculatedColumnExpressionService.evaluateCalculatedColumnQuery(calculatedColumn, params.node);
      };
      const newColDef = {
        headerName: calculatedColumn.FriendlyName ? calculatedColumn.FriendlyName : calculatedColumn.ColumnId,
        colId: calculatedColumn.ColumnId,
        hide: false,
        editable: false,
        width: calculatedColumnSettings.Width,
        enableValue: calculatedColumnSettings.Aggregatable,
        filter: calculatedColumnSettings.Filterable,
        floatingFilter: calculatedColumnSettings.Filterable,
        resizable: calculatedColumnSettings.Resizable,
        enableRowGroup: calculatedColumnSettings.Groupable,
        sortable: calculatedColumnSettings.Sortable,
        enablePivot: calculatedColumnSettings.Pivotable,
        suppressHeaderMenuButton: calculatedColumnSettings.SuppressMenu,
        suppressMovable: calculatedColumnSettings.SuppressMovable,
        type: columnTypes,
        valueGetter
      };
      if (calculatedColumnSettings.ShowToolTip != null && calculatedColumnSettings.ShowToolTip == true) {
        newColDef.tooltipValueGetter = () => {
          return this.getAdaptableApi().internalApi.getAdaptableQueryExpressionText(calculatedColumn.Query);
        };
      }
      if (calculatedColumnSettings.HeaderToolTip) {
        newColDef.headerTooltip = calculatedColumnSettings.HeaderToolTip;
      }
      this.getAdaptableApi().logInfo('Setting up Calculated Column: ' + calculatedColumn.ColumnId);
      return newColDef;
    });
  }
}