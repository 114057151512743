import { createUuid } from '../../PredefinedConfig/Uuid';
import { EMPTY_ARRAY } from '../../Utilities/Constants/GeneralConstants';
/**
 * @ReduxAction Set AG Grid Charting Models
 */
export const SET_CHARTING_CHARTS = 'SET_CHARTING_CHARTS';
/**
 * @ReduxAction Charting Module is ready
 */
export const CHARTING_READY = 'CHARTING_READY';
/**
 * @ReduxAction Edit chart definition
 */
export const CHARTING_EDIT_CHART = 'CHARTING_EDIT_CHART';
/**
 * @ReduxAction Add chart definition
 */
export const CHARTING_ADD_CHART = 'CHARTING_ADD_CHART';
/**
 * @ReduxAction Deelte chart definition
 */
export const CHARTING_DELETE_CHART = 'CHARTING_DELETE_CHART';
/**
 * @ReduxAction Edit chart definition
 */
export const CHARTING_EDIT_EXTERNAL_CHART = 'CHARTING_EDIT_EXTERNAL_CHART';
/**
 * @ReduxAction Add chart definition
 */
export const CHARTING_ADD_EXTERNAL_CHART = 'CHARTING_ADD_EXTERNAL_CHART';
/**
 * @ReduxAction Deelte chart definition
 */
export const CHARTING_DELETE_EXTERNAL_CHART = 'CHARTING_DELETE_EXTERNAL_CHART';
export const ChartingSetChartModels = charts => ({
  charts,
  type: SET_CHARTING_CHARTS
});
export const ChartingReady = chartingState => ({
  type: CHARTING_READY,
  chartingState
});
export const ChartingEditChart = chartDefinition => ({
  type: CHARTING_EDIT_CHART,
  chartDefinition
});
export const ChartingAddChart = chartDefinition => ({
  type: CHARTING_ADD_CHART,
  chartDefinition
});
export const ChartingDeleteChart = chartDefinition => ({
  type: CHARTING_DELETE_CHART,
  chartDefinition
});
export const ChartingEditExternalChart = chartDefinition => ({
  type: CHARTING_EDIT_EXTERNAL_CHART,
  chartDefinition
});
export const ChartingAddExternalChart = chartDefinition => ({
  type: CHARTING_ADD_EXTERNAL_CHART,
  chartDefinition
});
export const ChartingDeleteExternalChart = chartDefinition => ({
  type: CHARTING_DELETE_EXTERNAL_CHART,
  chartDefinition
});
export const initialState = {
  ChartDefinitions: EMPTY_ARRAY,
  ExternalChartDefinitions: EMPTY_ARRAY
};
export const ChartingGetChartModels = state => {
  var _a;
  return (_a = state.Charting.ChartDefinitions) !== null && _a !== void 0 ? _a : [];
};
export const ChartingGetExternalChartDefinitions = state => {
  var _a;
  return (_a = state.Charting.ExternalChartDefinitions) !== null && _a !== void 0 ? _a : [];
};
export const ChartingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHARTING_CHARTS:
      return Object.assign(Object.assign({}, state), {
        ChartDefinitions: action.charts
      });
    case CHARTING_EDIT_CHART:
      return Object.assign(Object.assign({}, state), {
        ChartDefinitions: state.ChartDefinitions.map(chartDefinition => {
          if (chartDefinition.Uuid === action.chartDefinition.Uuid) {
            return action.chartDefinition;
          }
          return chartDefinition;
        })
      });
    case CHARTING_ADD_CHART:
      let chartDefinition = action.chartDefinition;
      if (!('Uuid' in chartDefinition)) {
        chartDefinition = Object.assign(Object.assign({}, chartDefinition), {
          Uuid: createUuid()
        });
      }
      return Object.assign(Object.assign({}, state), {
        ChartDefinitions: [...state.ChartDefinitions, chartDefinition]
      });
    case CHARTING_DELETE_CHART:
      return Object.assign(Object.assign({}, state), {
        ChartDefinitions: state.ChartDefinitions.filter(chartDefinition => chartDefinition.Name !== action.chartDefinition.Name)
      });
    case CHARTING_EDIT_EXTERNAL_CHART:
      return Object.assign(Object.assign({}, state), {
        ExternalChartDefinitions: state.ExternalChartDefinitions.map(chartDefinition => {
          if (chartDefinition.Uuid === action.chartDefinition.Uuid) {
            return action.chartDefinition;
          }
          return chartDefinition;
        })
      });
    case CHARTING_ADD_EXTERNAL_CHART:
      let externalChartDefinition = action.chartDefinition;
      if (!('Uuid' in externalChartDefinition)) {
        externalChartDefinition = Object.assign(Object.assign({}, externalChartDefinition), {
          Uuid: createUuid()
        });
      }
      return Object.assign(Object.assign({}, state), {
        ExternalChartDefinitions: [...state.ExternalChartDefinitions, externalChartDefinition]
      });
    case CHARTING_DELETE_EXTERNAL_CHART:
      return Object.assign(Object.assign({}, state), {
        ExternalChartDefinitions: state.ExternalChartDefinitions.filter(chartDefinition => chartDefinition.Name !== action.chartDefinition.Name)
      });
  }
  return state;
};