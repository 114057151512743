import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SystemDataChangeHistoryDisable, SystemDataChangeHistoryEnable, SystemDataChangeHistoryResume, SystemDataChangeHistorySuspend } from '../../Redux/ActionsReducers/SystemRedux';
import { Box, Flex, Text } from 'rebass';
import { ButtonPlay } from '../Components/Buttons/ButtonPlay';
import { ButtonPause } from '../Components/Buttons/ButtonPause';
import { ButtonStop } from '../Components/Buttons/ButtonStop';
import { DateFormatter } from '../../Utilities/Helpers/FormatHelper';
import Tooltip from '../../components/Tooltip';
import { useAdaptable } from '../AdaptableContext';
import SimpleButton from '../../components/SimpleButton';
import { Icon } from '../../components/icons';
export const DataChangeHistoryViewPanelControl = props => {
  var _a, _b;
  const adaptable = useAdaptable();
  const dispatch = useDispatch();
  const onChangeHistoryEnable = React.useCallback(() => dispatch(SystemDataChangeHistoryEnable()), []);
  const onChangeHistoryDisable = React.useCallback(() => dispatch(SystemDataChangeHistoryDisable()), []);
  const onChangeHistorySuspend = React.useCallback(() => dispatch(SystemDataChangeHistorySuspend()), []);
  const onChangeHistoryResume = React.useCallback(() => dispatch(SystemDataChangeHistoryResume()), []);
  const handleOpenPopup = React.useCallback(() => {
    adaptable.api.settingsPanelApi.openSettingsPanel('DataChangeHistory');
  }, []);
  const {
    changeHistoryMode,
    activationTime,
    suspensionTime
  } = useSelector(state => ({
    changeHistoryMode: state.System.DataChangeHistory.currentMode,
    activationTime: state.System.DataChangeHistory.enableTime,
    suspensionTime: state.System.DataChangeHistory.suspendTime
  }));
  const enabled = changeHistoryMode === 'ACTIVE';
  const disabled = changeHistoryMode === 'INACTIVE';
  const suspended = changeHistoryMode === 'SUSPENDED';
  const gap = (_a = props.gap) !== null && _a !== void 0 ? _a : 'var(--ab-space-1)';
  const buttonsPaddingY = (_b = props.buttonsPaddingY) !== null && _b !== void 0 ? _b : 2;
  const buttonPanel = React.createElement(Flex, {
    className: "ab-DataChangeHistoryPanel--button-panel",
    paddingY: buttonsPaddingY,
    style: {
      gap: gap
    }
  }, disabled && React.createElement(ButtonPlay, {
    className: "ab-DataChangeHistoryPanel--button-activate",
    "data-name": 'data-change-history--button-activate',
    tooltip: '',
    onClick: () => onChangeHistoryEnable()
  }), suspended && React.createElement(ButtonPlay, {
    className: "ab-DataChangeHistoryPanel--button-resume",
    "data-name": 'data-change-history--button-resume',
    tooltip: 'Resume tracking data changes',
    onClick: () => onChangeHistoryResume()
  }), enabled && React.createElement(ButtonPause, {
    className: "ab-DataChangeHistoryPanel--button-suspend",
    "data-name": 'data-change-history--button-suspend',
    tooltip: 'Suspend tracking data changes',
    onClick: () => onChangeHistorySuspend()
  }), (enabled || suspended) && React.createElement(ButtonStop, {
    className: "ab-DataChangeHistoryPanel--button-deactivate",
    "data-name": 'data-change-history--button-deactivate',
    tooltip: 'Deactivate data change tracking',
    onClick: () => onChangeHistoryDisable()
  }));
  const dateFormat = `${adaptable.api.optionsApi.getUserInterfaceOptions().dateInputOptions.dateFormat} HH:mm:ss`;
  const statusPanel = React.createElement(Flex, {
    className: "ab-DataChangeHistoryPanel--status-panel",
    alignItems: 'center',
    style: {
      gap: gap
    }
  }, enabled && React.createElement(Tooltip, {
    label: !!activationTime && `since ${DateFormatter(activationTime, {
      Pattern: dateFormat
    })}`
  }, React.createElement(Text, {
    className: "ab-DataChangeHistoryPanel--status-active",
    fontSize: 2,
    style: {
      color: 'var(--ab-color-success)'
    }
  }, "Active")), suspended && React.createElement(Tooltip, {
    label: !!suspensionTime && `since ${DateFormatter(suspensionTime, {
      Pattern: dateFormat
    })}`
  }, React.createElement(Text, {
    className: "ab-DataChangeHistoryPanel--status-suspended",
    fontSize: 2,
    style: {
      color: 'var(--ab-color-warn)'
    }
  }, "Suspended")), disabled && React.createElement(Text, {
    className: "ab-DataChangeHistoryPanel--status-disabled",
    fontSize: 2
  }, "Activate Data Tracking"));
  return React.createElement(Flex, {
    flexDirection: 'row'
  }, buttonPanel, statusPanel, React.createElement(Flex, {
    ml: 2,
    alignItems: "center"
  }, React.createElement(SimpleButton, {
    onClick: handleOpenPopup,
    variant: "text"
  }, React.createElement(Icon, {
    name: "open-in-new"
  }), React.createElement(Box, {
    ml: 1
  }, "Show"))));
};