import * as React from 'react';
import DropdownButton from '../../../components/DropdownButton';
import { handleExportState } from '../handleExportState';
export const ExportDropdown = props => {
  const handleChange = destination => {
    switch (props.type) {
      case 'adaptableState':
        const adaptableState = props.api.configApi.getPersistedState();
        handleExportState(destination, 'adaptableState', adaptableState);
        break;
      case 'predefinedConfig':
        const predefinedConfig = props.api.configApi.getPredefinedConfig();
        handleExportState(destination, 'predefinedConfig', predefinedConfig);
        break;
    }
  };
  const destinationOptions = [{
    value: 'Clipboard',
    label: 'Clipboard',
    onClick: () => handleChange('Clipboard')
  }, {
    value: 'Console',
    label: 'Console',
    onClick: () => handleChange('Console')
  }, {
    value: 'JSON',
    label: 'JSON',
    onClick: () => handleChange('JSON')
  }];
  return React.createElement(DropdownButton, Object.assign({
    className: "ab-StateManagement__Export-Dropdown"
  }, props, {
    items: destinationOptions,
    value: undefined
  }));
};