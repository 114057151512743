import * as React from 'react';
import { Tag } from '../../../components/Tag';
import { AlertPreview } from '../../../View/Alert/Wizard/AlertNotificationWizardSection';
export const getAlertPreviewViewItems = (alert, api) => {
  return {
    name: 'Type and Display',
    view: () => {
      var _a;
      if (!((_a = alert === null || alert === void 0 ? void 0 : alert.AlertProperties) === null || _a === void 0 ? void 0 : _a.DisplayNotification)) {
        return React.createElement(Tag, null, alert.MessageType);
      }
      return React.createElement(AlertPreview, {
        focusFirstButton: false,
        style: {
          maxWidth: 400
        },
        mt: 2,
        api: api,
        alertDefinition: alert
      });
    }
  };
};