import * as React from 'react';
import { useOverlay } from '../../../components/InfiniteTable';
import { getRect } from '../../../components/OverlayTrigger/utils';
import { useAdaptable } from '../../AdaptableContext';
import { isBrowserDocumentAvailable } from '../../UIHelper';
let portalElement;
const ensurePortalElement = className => {
  if (!isBrowserDocumentAvailable()) {
    return;
  }
  if (portalElement) {
    return;
  }
  portalElement = document.createElement('div');
  portalElement.style.position = 'absolute';
  portalElement.style.zIndex = '4'; // under the context menu
  portalElement.style.top = '0px';
  portalElement.style.left = '0px';
  if (className) {
    portalElement.className = className;
  }
  document.body.appendChild(portalElement);
};
export const CellPopup = React.forwardRef((props, ref) => {
  ensurePortalElement(props.className);
  const adaptable = useAdaptable();
  const {
    showOverlay,
    clearAll: clearAllOverlays,
    hideOverlay,
    portal
  } = useOverlay({
    portalContainer: portalElement
  });
  const OVERLAY_ID = 'cell-overlay';
  const showCellPopupContent = () => {
    var _a;
    const cellSelector = `[row-id="${props.primaryKeyValue}"] [col-id="${props.columnId}"]`;
    const alignTo = document.querySelector(cellSelector);
    if (!alignTo) {
      adaptable.logger.consoleError(`Could not find cell with selector ${cellSelector} to show popup`);
      return;
    }
    const showOverlayOptions = {
      id: OVERLAY_ID,
      alignPosition: [
      // overlay - target
      ['TopLeft', 'TopRight'], ['TopRight', 'TopLeft'], ['TopCenter', 'BottomCenter'], ['BottomCenter', 'TopCenter']],
      constrainTo: (_a = adaptable.getAgGridContainerElement()) !== null && _a !== void 0 ? _a : true,
      alignTo: getRect(alignTo)
    };
    showOverlay(() => props.children, showOverlayOptions);
  };
  React.useEffect(() => {
    if (props.isOpen) {
      showCellPopupContent();
    } else {
      clearAllOverlays();
      hideOverlay(OVERLAY_ID);
    }
  }, [props.isOpen]);
  React.useImperativeHandle(ref, () => {
    return {
      refreshContent: () => showCellPopupContent()
    };
  });
  return React.createElement(React.Fragment, null, portal);
});