import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
import { createBaseContext } from '../Utilities/ObjectFactory';
import { AdaptableModuleBase } from './AdaptableModuleBase';
export class NoteModule extends AdaptableModuleBase {
  constructor(api) {
    super(ModuleConstants.NoteModuleId, ModuleConstants.NoteFriendlyName, 'note', 'NotePopup', 'Notes', api);
    this.adaptable = api.internalApi.getAdaptableInstance();
  }
  isModuleAvailable() {
    // Note module doesn't support autogenerated primary keys
    return super.isModuleAvailable() && !this.api.optionsApi.getAutogeneratePrimaryKey();
  }
  createContextMenuItems(menuContext) {
    var _a, _b;
    if (!this.isModuleAvailable()) {
      return;
    }
    if (!this.api.noteApi.internalApi.areNotesSupported()) {
      return;
    }
    if (menuContext.isRowGroupColumn || menuContext.isGroupedNode) {
      return;
    }
    const isReadOnly = this.adaptable.api.entitlementApi.getEntitlementAccessLevelForModule(this.moduleInfo.ModuleName) === 'ReadOnly';
    if (isReadOnly) {
      return undefined;
    }
    const isCellNotable = typeof ((_b = (_a = this.adaptable.api.optionsApi) === null || _a === void 0 ? void 0 : _a.getNoteOptions()) === null || _b === void 0 ? void 0 : _b.isCellNotable) === 'function' ? this.adaptable.api.optionsApi.getNoteOptions().isCellNotable(Object.assign({
      gridCell: menuContext.gridCell
    }, createBaseContext(this.api))) : true;
    if (!isCellNotable) {
      return;
    }
    const note = this.adaptable.api.noteApi.getNoteForCell({
      PrimaryKeyValue: menuContext.primaryKeyValue,
      ColumnId: menuContext.adaptableColumn.columnId
    });
    if (note) {
      return [this.createMenuItemClickFunction('note-remove', 'Remove Note', this.moduleInfo.Glyph, () => {
        this.api.noteApi.deleteNote(note);
      })];
    } else {
      return [this.createMenuItemClickFunction('note-add', 'Add Note', this.moduleInfo.Glyph, () => {
        // add an empty one
        this.api.noteApi.addNote('', menuContext.primaryKeyValue, menuContext.adaptableColumn.columnId);
        this.api.internalApi.getCellPopupService().showPopup({
          PrimaryKeyValue: menuContext.primaryKeyValue,
          ColumnId: menuContext.adaptableColumn.columnId
        }, true);
      })];
    }
  }
}