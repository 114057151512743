import * as React from 'react';
import { useState } from 'react';
import { Box } from 'rebass';
import { OnePageAdaptableWizard, OnePageWizardSummary } from '../../Wizard/OnePageAdaptableWizard';
import { cloneObject } from '../../../Utilities/Helpers/Helper';
import { CustomSortColumnWizardSection, isValidCustomSortColumn, renderCustomSortColumn } from './CustomSortColumnWizardSection';
import { CustomSortValuesWizardSection, isValidCustomSortOrder, renderCustomSortValuesSummary } from './CustomSortValuesWizardSection';
import ObjectFactory from '../../../Utilities/ObjectFactory';
import { useDispatch, useSelector } from 'react-redux';
import * as CustomSortRedux from '../../../Redux/ActionsReducers/CustomSortRedux';
import { ObjectTagsWizardSection, renderObjectTagsSummary } from '../../Wizard/ObjectTagsWizardSection';
export const CustomSortWizard = props => {
  var _a, _b;
  let data = props.data;
  const allCustomSorts = useSelector(state => {
    var _a;
    return (_a = state.CustomSort) === null || _a === void 0 ? void 0 : _a.CustomSorts;
  });
  if (((_a = props.popupParams) === null || _a === void 0 ? void 0 : _a.action) === 'Edit' && ((_b = props.popupParams) === null || _b === void 0 ? void 0 : _b.column)) {
    data = allCustomSorts.find(x => {
      var _a;
      return x.ColumnId == ((_a = props.popupParams) === null || _a === void 0 ? void 0 : _a.column.columnId);
    });
  }
  const [customSort, setCustomSort] = useState(() => {
    var _a, _b;
    if (data) {
      return cloneObject(data);
    }
    const newData = ObjectFactory.CreateEmptyCustomSort();
    if ((_a = props.popupParams) === null || _a === void 0 ? void 0 : _a.column) {
      newData.ColumnId = (_b = props.popupParams) === null || _b === void 0 ? void 0 : _b.column.columnId;
    }
    return newData;
  });
  const dispatch = useDispatch();
  const handleFinish = () => {
    if (data) {
      dispatch(CustomSortRedux.CustomSortEdit(customSort));
    } else {
      dispatch(CustomSortRedux.CustomSortAdd(customSort));
    }
    props.onFinishWizard(customSort);
  };
  return React.createElement(OnePageAdaptableWizard, {
    defaultCurrentSectionName: props.defaultCurrentSectionName,
    moduleInfo: props.moduleInfo,
    data: customSort,
    onFinish: handleFinish,
    onHide: props.onCloseWizard,
    sections: [{
      isValid: isValidCustomSortColumn,
      renderSummary: renderCustomSortColumn,
      details: 'Select a Column for Custom Sort',
      render: () => {
        return React.createElement(Box, {
          padding: 2,
          style: {
            height: '100%'
          }
        }, React.createElement(CustomSortColumnWizardSection, {
          isNew: props.isNew,
          onChange: setCustomSort
        }));
      },
      title: 'Column'
    }, {
      title: 'Sort Order',
      details: 'Specify Custom Sort order - drag selected items to modify order',
      isValid: isValidCustomSortOrder,
      renderSummary: renderCustomSortValuesSummary,
      render: () => React.createElement(Box, {
        padding: 2,
        style: {
          height: '100%'
        }
      }, React.createElement(CustomSortValuesWizardSection, {
        onChange: setCustomSort
      }))
    }, {
      details: 'Select Custom Sort Tags',
      title: 'Tags',
      isVisible: (_, api) => api.internalApi.shouldDisplayTagSections(),
      render: () => React.createElement(Box, {
        padding: 2
      }, React.createElement(ObjectTagsWizardSection, {
        onChange: setCustomSort
      })),
      renderSummary: renderObjectTagsSummary
    }, '-', {
      title: 'Summary',
      details: 'Review your Custom Sort',
      render: () => {
        return React.createElement(Box, {
          padding: 2
        }, React.createElement(OnePageWizardSummary, null));
      }
    }]
  });
};