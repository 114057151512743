import { __rest } from "tslib";
import * as React from 'react';
import { useMemo } from 'react';
import { ValueSelector } from '../ValueSelector';
export const NewColumnSelector = _a => {
  var {
      allowReorder = true
    } = _a,
    props = __rest(_a, ["allowReorder"]);
  const {
    columnFilterText,
    availableColumns,
    selected,
    onChange,
    singleSelect
  } = props;
  const filterFn = useMemo(() => {
    return columnFilterText ? c => c.friendlyName.toLowerCase().indexOf(columnFilterText.toLowerCase()) != -1 : null;
  }, [columnFilterText]);
  return React.createElement(ValueSelector, {
    value: selected,
    options: availableColumns,
    filter: filterFn,
    singleSelect: singleSelect,
    allowReorder: false,
    onChange: colIds => {
      if (singleSelect) {
        onChange(colIds.length ? [colIds.pop()] : []);
      } else {
        onChange(colIds);
      }
    },
    toIdentifier: c => c.columnId,
    toLabel: c => c.friendlyName,
    noSelectionLabel: singleSelect ? 'Please select a column' : 'You have not selected any columns.',
    xSelectedLabel: singleSelect ? () => null : count => count > 1 ? `You selected ${count} columns.` : 'You selected one column.'
  });
};