import * as React from 'react';
import { allIcons, Icon } from '../icons';
import join from '../utils/join';
import { useEffect, useRef } from 'react';
export const IconComponent = props => {
  var _a, _b, _c, _d, _e;
  const {
    icon,
    iconClassName
  } = props;
  if (!icon) {
    return null;
  }
  const className = !isAdaptableElementIcon(icon) ? join(icon.className, iconClassName) : iconClassName;
  const divRef = useRef(null);
  useEffect(() => {
    let htmlElement;
    if (isAdaptableElementIcon(icon)) {
      if (typeof icon.element === 'string') {
        const domParser = new DOMParser();
        htmlElement = domParser.parseFromString(icon.element, 'text/html').body.firstChild;
      } else {
        htmlElement = icon.element;
        // THe element neets to be cloned.
        // when it is used in more than one plce the element is removed from the DOM
        htmlElement = htmlElement.cloneNode(true);
      }
      divRef.current.insertAdjacentElement('afterend', htmlElement);
    }
    return () => {
      if (isAdaptableElementIcon(icon)) {
        htmlElement === null || htmlElement === void 0 ? void 0 : htmlElement.remove();
      }
    };
  }, [icon]);
  if (isAdaptableSystemIcon(icon) && icon.name in allIcons) {
    return React.createElement(Icon, {
      name: icon.name,
      size: icon.size,
      style: icon.style,
      className: className
    });
  }
  if (isAdaptableCustomIcon(icon)) {
    let width = (_b = (_a = icon.style) === null || _a === void 0 ? void 0 : _a.width) !== null && _b !== void 0 ? _b : 'var(--ab-cmp-icon__width)';
    let height = (_d = (_c = icon.style) === null || _c === void 0 ? void 0 : _c.height) !== null && _d !== void 0 ? _d : 'var(--ab-cmp-icon__height)';
    const iconStyle = (_e = icon.style) !== null && _e !== void 0 ? _e : {};
    return React.createElement("img", {
      src: icon.src,
      className: className,
      style: Object.assign({
        height,
        width
      }, iconStyle)
    });
  }
  if (isAdaptableElementIcon(icon)) {
    return React.createElement("span", {
      ref: divRef,
      style: {
        display: 'none'
      },
      "data-name": "adaptable-icon-placeholder"
    });
  }
  return null;
};
export const isAdaptableSystemIcon = icon => {
  return typeof (icon === null || icon === void 0 ? void 0 : icon.name) === 'string';
};
export const isAdaptableCustomIcon = icon => {
  return typeof (icon === null || icon === void 0 ? void 0 : icon.src) === 'string';
};
export const isAdaptableElementIcon = icon => {
  return typeof HTMLElement === 'function' && (icon === null || icon === void 0 ? void 0 : icon.element) instanceof HTMLElement || typeof (icon === null || icon === void 0 ? void 0 : icon.element) === 'string';
};
export const isAdaptableIcon = icon => {
  return isAdaptableSystemIcon(icon) || isAdaptableCustomIcon(icon) || isAdaptableElementIcon(icon);
};