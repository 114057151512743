import * as SystemRedux from '../../Redux/ActionsReducers/SystemRedux';
import * as React from 'react';
import { ActiveFiltersPanel } from './ActiveFiltersPanel';
import { Flex } from 'rebass';
import { ArrayExtensions } from '../../Utilities/Extensions/ArrayExtensions';
import { AdaptablePopover } from '../AdaptablePopover';
import { ButtonClear } from '../Components/Buttons/ButtonClear';
import { CheckBox } from '../../components/CheckBox';
import { connect } from 'react-redux';
import { getColumnFilterSelector } from '../../Redux/ActionsReducers/LayoutRedux';
import SimpleButton from '../../components/SimpleButton';
import join from '../../components/utils/join';
class FilterViewPanelComponent extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    var _a, _b;
    const elementType = this.props.viewType === 'Toolbar' ? 'DashboardToolbar' : 'ToolPanel';
    const showClearButton = (_a = this.props.api.optionsApi.getColumnFilterOptions()) === null || _a === void 0 ? void 0 : _a.showClearFilterButton;
    const showSuspendFilterButton = (_b = this.props.api.optionsApi.getColumnFilterOptions()) === null || _b === void 0 ? void 0 : _b.showSuspendFilterButton;
    const isAtLeastOneFilterActive = this.props.api.columnFilterApi.getActiveColumnFilters().length > 0;
    const isAtLeastOneFilter = this.props.api.columnFilterApi.getColumnFilters().length > 0;
    const handleSuspendUnsuspendAll = () => {
      if (isAtLeastOneFilterActive) {
        this.props.api.columnFilterApi.suspendAllColumnFilters();
      } else {
        this.props.api.columnFilterApi.unSuspendAllColumnFilters();
      }
    };
    return React.createElement(Flex, {
      flexDirection: "row",
      className: `ab-${elementType}__Filter__wrap`,
      flexWrap: this.props.viewType === 'ToolPanel' ? 'wrap' : 'nowrap'
    }, React.createElement(Flex, null, ArrayExtensions.IsNotNullOrEmpty(this.props.ColumnFilters) && React.createElement(AdaptablePopover, {
      popupPadding: 0,
      className: `ab-${elementType}__Filter__info`,
      headerText: "",
      bodyText: [React.createElement(ActiveFiltersPanel, null)],
      useButton: true,
      showEvent: 'focus',
      hideEvent: "blur",
      popoverMinWidth: 400
    }), showClearButton && React.createElement(ButtonClear, {
      className: `ab-${elementType}__Filter__clear`,
      marginLeft: 1,
      marginBottom: 0,
      marginRight: 1,
      onClick: () => this.onClearFilters(),
      tooltip: "Clear Filters",
      disabled: this.props.ColumnFilters.length == 0,
      showText: this.props.viewType === 'ToolPanel'
    }, this.props.viewType === 'ToolPanel' && 'Clear'), showSuspendFilterButton && React.createElement(SimpleButton, {
      className: join(`ab-${elementType}__Filter__suspend-button`, isAtLeastOneFilterActive && `ab-${elementType}__Filter__suspend-all`, !isAtLeastOneFilterActive && `ab-${elementType}__Filter__un-suspend-all`),
      disabled: !isAtLeastOneFilter,
      onClick: handleSuspendUnsuspendAll,
      tone: isAtLeastOneFilterActive ? 'neutral' : 'success',
      variant: "text",
      icon: isAtLeastOneFilterActive ? 'pause' : 'play',
      accessLevel: this.props.accessLevel
    })), React.createElement(Flex, {
      alignItems: "center"
    }, this.props.api.columnFilterApi.isQuickFilterAvailable() && React.createElement(CheckBox, {
      className: `ab-${elementType}__Filter__active-check`,
      disabled: this.props.accessLevel === 'ReadOnly' || this.props.api.internalApi.isGridInPivotMode(),
      marginTop: 0,
      marginBottom: 0,
      fontSize: 2,
      padding: 1,
      checked: this.props.IsQuickFilterVisible,
      onChange: checked => {
        checked ? this.props.onShowQuickFilterBar() : this.props.onHideQuickFilterBar();
      }
    }, "Filter Bar")));
  }
  onClearFilters() {
    this.props.api.columnFilterApi.clearColumnFilters();
  }
  onClearColumnFilter(columnFilter) {
    this.props.api.columnFilterApi.clearColumnFilterForColumn(columnFilter.ColumnId);
  }
}
function mapStateToProps(state) {
  return {
    ColumnFilters: getColumnFilterSelector(state),
    IsQuickFilterVisible: state.System.IsQuickFilterVisible
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onHideQuickFilterBar: () => dispatch(SystemRedux.SystemQuickFilterBarHide()),
    onShowQuickFilterBar: () => dispatch(SystemRedux.SystemQuickFilterBarShow())
  };
}
export const FilterViewPanelControl = connect(mapStateToProps, mapDispatchToProps)(FilterViewPanelComponent);