import * as React from 'react';
import { Box, Flex } from 'rebass';
const getText = options => {
  let text = '';
  if (options && options.includes('CellValue')) {
    text += '20';
  }
  if (options && options.includes('PercentageValue')) {
    text += ' (50%)';
  }
  return text.trim();
};
export const PercentBarStylePreview = props => {
  const cellText = getText(props.cellText);
  const tooltip = getText(props.toolTipText);
  return React.createElement(Box, {
    title: tooltip,
    flex: 1
  }, React.createElement(Flex, {
    height: cellText ? 8 : 18,
    backgroundColor: props.backgroundColor,
    flex: 1
  }, React.createElement(Box, {
    flexBasis: "50%",
    style: {
      background: props.barBackgroundColor
    }
  })), cellText && React.createElement(Box, {
    fontSize: 3
  }, cellText));
};