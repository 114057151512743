import * as React from 'react';
import DropdownButton from '../../components/DropdownButton';
import SimpleButton from '../../components/SimpleButton';
import { useAdaptable } from '../AdaptableContext';
export const VariantSelector = props => {
  var _a;
  const adaptable = useAdaptable();
  const systemTemes = adaptable.api.themeApi.getSystemThemes();
  const options = systemTemes.map(theme => ({
    label: theme.Description,
    value: theme.Name,
    onClick: () => props.onChange(theme.Name)
  }));
  const label = props.theme.Variant ? (_a = options.find(theme => theme.value === props.theme.Variant)) === null || _a === void 0 ? void 0 : _a.label : 'Select a theme';
  return React.createElement(React.Fragment, null, React.createElement(DropdownButton, {
    mr: 2,
    columns: ['label'],
    items: options
  }, label), React.createElement(SimpleButton, {
    onClick: () => props.onChange(null),
    disabled: !props.theme.Variant,
    icon: "delete"
  }));
};