import * as SystemRedux from '../../Redux/ActionsReducers/SystemRedux';
import * as LayoutRedux from '../../Redux/ActionsReducers/LayoutRedux';
import { ApiBase } from './ApiBase';
import ArrayExtensions from '../../Utilities/Extensions/ArrayExtensions';
import { ColumnFilterInternalApi } from '../Internal/ColumnFilterInternalApi';
export class ColumnFilterApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.internalApi = new ColumnFilterInternalApi(adaptable);
  }
  getFilterPredicateDefsForColumn(column) {
    const scope = {
      ColumnIds: [column.columnId]
    };
    return this.getAdaptableApi().predicateApi.internalApi.getFilterPredicateDefs(scope).filter(predicate => this.getAdaptableApi().columnScopeApi.isColumnInScope(column, predicate.columnScope));
  }
  getFilterPredicateDefsForColumnId(columnId) {
    const column = this.getAdaptableApi().columnApi.getColumnWithColumnId(columnId);
    return column ? this.getFilterPredicateDefsForColumn(column) : undefined;
  }
  showQuickFilterBar() {
    this.dispatchAction(SystemRedux.SystemQuickFilterBarShow());
  }
  hideQuickFilterBar() {
    this.dispatchAction(SystemRedux.SystemQuickFilterBarHide());
  }
  isQuickFilterAvailable() {
    return this.adaptable.isQuickFilterAvailable();
  }
  isQuickFilterVisible() {
    return this.isQuickFilterAvailable() && this.getAdaptableApi().internalApi.getSystemState().IsQuickFilterVisible;
  }
  getColumnFilters() {
    var _a;
    const currentLayout = this.getAdaptableApi().layoutApi.getCurrentLayout();
    return (_a = currentLayout.ColumnFilters) !== null && _a !== void 0 ? _a : [];
  }
  getActiveColumnFilters() {
    return this.getColumnFilters().filter(columnFilter => !columnFilter.IsSuspended);
  }
  getColumnFilterDefs() {
    const filters = this.getColumnFilters();
    return filters.map(columnFilter => {
      const column = this.getAdaptableApi().columnApi.getColumnWithColumnId(columnFilter.ColumnId);
      const predicate = this.getAdaptableApi().predicateApi.getPredicateDefById(columnFilter.Predicate.PredicateId);
      return {
        predicate,
        dataType: column === null || column === void 0 ? void 0 : column.dataType,
        columnFilter: columnFilter
      };
    });
  }
  getColumnFiltersForLayout(layoutName) {
    var _a;
    const layout = this.getAdaptableApi().layoutApi.getLayoutByName(layoutName);
    return (_a = layout.ColumnFilters) !== null && _a !== void 0 ? _a : [];
  }
  setColumnFilters(columnFilters) {
    columnFilters.forEach(columnFilter => {
      if (!columnFilter.Predicate) {
        this.logWarn('Column Filter has no Predicate', columnFilter);
      } else {
        const isValidPredicate = this.getAdaptableApi().predicateApi.isValidPredicate(columnFilter.Predicate);
        if (!isValidPredicate) {
          this.logWarn('Column Filter Predicate is not valid', columnFilter);
        } else {
          this.addUidToAdaptableObject(columnFilter);
          if (this.getColumnFilters().find(cf => cf.ColumnId == columnFilter.ColumnId)) {
            this.dispatchAction(LayoutRedux.LayoutColumnFilterSet(columnFilter));
          } else {
            this.dispatchAction(LayoutRedux.LayoutColumnFilterAdd(columnFilter));
          }
        }
      }
    });
    return columnFilters === null || columnFilters === void 0 ? void 0 : columnFilters.map(columnFilter => this.getColumnFilterById(columnFilter.Uuid)).filter(Boolean);
  }
  clearColumnFilter(columnFilter) {
    this.dispatchAction(LayoutRedux.LayoutColumnFilterClear(columnFilter));
  }
  clearColumnFiltersForColumns(columns) {
    columns.forEach(c => {
      this.clearColumnFilterForColumn(c);
    });
  }
  clearColumnFilters() {
    this.dispatchAction(LayoutRedux.LayoutColumnFilterClearAll());
    this.adaptable.clearColumnFiltering();
  }
  clearAndSetColumnFilters(columnFilters) {
    this.clearColumnFilters();
    this.setColumnFilters(columnFilters);
  }
  columnFilterToString(columnFilter) {
    return this.internalApi.columnFilterToString(columnFilter);
  }
  columnFiltersToString(columnFilters) {
    return this.internalApi.columnFiltersToString(columnFilters);
  }
  getColumnFilterById(id) {
    var _a;
    return (_a = this.getColumnFilters()) === null || _a === void 0 ? void 0 : _a.find(columnFilter => columnFilter.Uuid === id);
  }
  /**
   * Checks if a filter is applied. It is applied when:
   * - has no inputs
   * - has inputs and inputs have values
   *
   * @param filter
   */
  isColumnFilterActive(filter) {
    if (!filter || !filter.Predicate || !filter.Predicate.PredicateId) {
      return false;
    }
    const predicateDef = this.getAdaptableApi().predicateApi.getPredicateDefById(filter === null || filter === void 0 ? void 0 : filter.Predicate.PredicateId);
    if (ArrayExtensions.IsNull(predicateDef.inputs) &&
    // values predicates have no inputs in the definition
    !this.getAdaptableApi().predicateApi.internalApi.hasPredicateValues(filter.Predicate)) {
      return true;
    }
    const inputs = filter.Predicate.Inputs;
    if (ArrayExtensions.IsEmpty(inputs)) {
      return false;
    }
    // all values in the array need to be non-empty (e.g. between has two values, [min, max])
    const allInputsHaveValues = inputs.every(value => {
      return value !== undefined && value !== null && value !== '';
    });
    return allInputsHaveValues;
  }
  setColumnFilterForColumn(columnId, columnFilterConfig) {
    const columnFilterPredicate = {
      PredicateId: columnFilterConfig.PredicateId
    };
    if (columnFilterConfig.PredicateInputs) {
      columnFilterPredicate.Inputs = columnFilterConfig.PredicateInputs;
    }
    const columnFilter = {
      ColumnId: columnId,
      Predicate: columnFilterPredicate
    };
    this.setColumnFilters([columnFilter]);
  }
  suspendColumnFilter(columnFilter) {
    this.dispatchAction(LayoutRedux.LayoutColumnFilterSuspend(columnFilter));
  }
  unSuspendColumnFilter(columnFilter) {
    this.dispatchAction(LayoutRedux.LayoutColumnFilterUnSuspend(columnFilter));
  }
  suspendAllColumnFilters() {
    this.dispatchAction(LayoutRedux.LayoutColumnFilterSuspendAll());
  }
  unSuspendAllColumnFilters() {
    this.dispatchAction(LayoutRedux.LayoutColumnFilterUnSuspendAll());
  }
  getColumnFilterForColumn(columnId) {
    return this.getColumnFilters().find(columnFilter => columnFilter.ColumnId == columnId);
  }
  clearColumnFilterForColumn(columnId) {
    let columnFilterForColumn = this.getColumnFilterForColumn(columnId);
    if (columnFilterForColumn) {
      this.dispatchAction(LayoutRedux.LayoutColumnFilterClear(columnFilterForColumn));
      this.adaptable.clearColumnFilteringForColumns([columnId]);
    }
  }
}