import * as React from 'react';
import throttle from 'lodash/throttle';
import { useAdaptable } from '../AdaptableContext';
import { getVariableColor } from '../../Utilities/Helpers/StyleHelper';
import { FormRow } from '../../components/FormLayout';
import SimpleButton from '../../components/SimpleButton';
import { Flex } from 'rebass';
import { ColorPicker } from '../../components/ColorPicker';
import AdaptableInput from '../Components/AdaptableInput';
export const Field = props => {
  const adaptable = useAdaptable();
  let input = null;
  let value = props.value;
  const throttledOnChange = React.useMemo(() => {
    return throttle(value => {
      props.onChange(value);
    }, 300);
  }, []);
  if (value === undefined) {
    value = getVariableColor(`var(${props.variable})`);
  }
  switch (props.type) {
    case 'number':
      input = React.createElement(AdaptableInput, {
        disabled: props.disabled,
        type: "number",
        onChange: event => throttledOnChange(event.target.value),
        value: value
      });
      break;
    case 'color':
      input = React.createElement(ColorPicker, {
        disabled: props.disabled,
        includeAlpha: false,
        api: adaptable.api,
        value: value,
        onChange: color => throttledOnChange(color)
      });
      break;
  }
  return React.createElement(FormRow, {
    label: props.name
  }, React.createElement(Flex, null, input, ' ', React.createElement(SimpleButton, {
    disabled: props.value === undefined,
    onClick: () => props.onChange(false)
  }, "Clear")));
};