export const mapAlertDefinition = (api, alertDefinition) => {
  var _a;
  const isRemovedRowChangeAlert = api.alertApi.internalApi.isAlertDefinitionForRemovedRowChangeEvent(alertDefinition);
  const mapValidButtonActions = alertAction => {
    if (isRemovedRowChangeAlert) {
      if (alertAction === 'highlight-row' || alertAction === 'jump-to-row') {
        return null;
      }
      return alertAction;
    }
  };
  // the only case needed is when changing from AddedRow to RemovedRow
  // map FormButton Actions
  if (alertDefinition.AlertForm && typeof alertDefinition.AlertForm !== 'string') {
    const alertButtons = (_a = alertDefinition.AlertForm) === null || _a === void 0 ? void 0 : _a.Buttons;
    alertButtons === null || alertButtons === void 0 ? void 0 : alertButtons.forEach(alertButton => {
      const alertAction = alertButton.Action;
      if (Array.isArray(alertAction)) {
        alertButton.Action = alertAction.map(mapValidButtonActions).filter(Boolean);
      } else {
        alertButton.Action = mapValidButtonActions(alertAction);
      }
    });
  }
  // behaviours
  if (isRemovedRowChangeAlert) {
    delete alertDefinition.AlertProperties.HighlightRow;
    delete alertDefinition.AlertProperties.JumpToRow;
  }
  return alertDefinition;
};