import * as React from 'react';
import { ValueOptionsTags, ValueSelector } from '../ValueSelector';
import { useAdaptable } from '../../AdaptableContext';
export const TagValueSelector = props => {
  const {
    api
  } = useAdaptable();
  const getDefaultTags = () => {
    var _a;
    return (_a = api.internalApi.getAvailableTags()) !== null && _a !== void 0 ? _a : [];
  };
  const {
    availableTags = getDefaultTags(),
    selectedTags = [],
    onChange,
    singleSelect
  } = props;
  return React.createElement(ValueSelector, {
    options: availableTags,
    value: selectedTags.map(tag => api.internalApi.getLabelForTag(tag)),
    singleSelect: singleSelect,
    allowReorder: false,
    onChange: (selectedTagsIds, selectedTagsMap) => {
      onChange(selectedTagsIds.map(id => selectedTagsMap.get(id)));
    },
    selectionBoxPosition: 'top',
    toIdentifier: tag => api.internalApi.getLabelForTag(tag),
    toLabel: tag => api.internalApi.getLabelForTag(tag),
    noSelectionLabel: singleSelect ? 'Select a tag' : 'You have not selected any tags.',
    xSelectedLabel: singleSelect ? () => 'Selected tag:' : () => 'Selected tags:'
  });
};
export const TagValueOptionsTags = props => {
  const {
    tags = []
  } = props;
  const {
    api
  } = useAdaptable();
  return React.createElement(ValueOptionsTags, {
    options: tags,
    value: tags.map(tag => api.internalApi.getValueForTag(tag)),
    toIdentifier: tag => api.internalApi.getValueForTag(tag),
    toLabel: tag => api.internalApi.getLabelForTag(tag),
    allowWrap: true,
    readOnly: true
  });
};