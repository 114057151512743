import * as React from 'react';
import Panel from '../Panel';
import join from '../utils/join';
const WizardPanel = props => {
  return React.createElement(Panel, Object.assign({
    border: "none",
    borderRadius: "none",
    variant: "primary",
    bodyScroll: true
  }, props, {
    className: join(props.className, 'ab-WizardPanel'),
    style: Object.assign({
      height: '100%'
    }, props.style)
  }));
};
export default WizardPanel;