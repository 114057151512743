import * as React from 'react';
import { useSelector } from 'react-redux';
import { Flex } from 'rebass';
import { GetNoteSelector } from '../../../../Redux/ActionsReducers/NoteRedux';
import { SystemCommentsAndNotesEditModeSelector, SystemCommentsAndNotesFocusedEntitySelector, SystemCommentsAndNotesSelector } from '../../../../Redux/ActionsReducers/SystemRedux';
import { useAdaptable } from '../../../AdaptableContext';
import { CommentsEditor } from '../../../Comments/CommentsEditor';
import { NoteEditor } from '../../../Note/NoteEditor';
import { CellPopup } from '../../CellPopup';
const ConnectedNoteEditor = ({
  enableEditMode
}) => {
  const {
    api
  } = useAdaptable();
  const isReadonly = api.entitlementApi.isModuleReadOnlyEntitlement('Note');
  const editMode = useSelector(state => SystemCommentsAndNotesEditModeSelector(state.System));
  const cellPopupService = api.internalApi.getCellPopupService();
  const cellAddress = useSelector(state => SystemCommentsAndNotesSelector(state.System));
  const note = useSelector(state => {
    return cellAddress ? GetNoteSelector(state.Note, cellAddress) : null;
  });
  const handleNoteChange = React.useCallback(value => {
    api.noteApi.updateNoteText(value, note);
  }, [note]);
  return React.createElement(Flex, {
    onClick: () => enableEditMode(),
    flexDirection: "column",
    className: "ab-NotePopup"
  }, React.createElement(NoteEditor, {
    isReadonly: isReadonly,
    editMode: editMode,
    key: note === null || note === void 0 ? void 0 : note.Uuid,
    onClose: () => cellPopupService.hidePopup(),
    note: note === null || note === void 0 ? void 0 : note.Text,
    onNoteChange: value => handleNoteChange(value)
  }));
};
export const GridCellPopup = props => {
  const {
    api
  } = useAdaptable();
  const cellPopupRef = React.useRef(null);
  const editMode = useSelector(state => SystemCommentsAndNotesEditModeSelector(state.System));
  const focusedEntity = useSelector(state => SystemCommentsAndNotesFocusedEntitySelector(state.System));
  const cellAddress = useSelector(state => SystemCommentsAndNotesSelector(state.System));
  const enableEditMode = entity => {
    if (!editMode) {
      const cellPopupService = api.internalApi.getCellPopupService();
      cellPopupService.showPopup(cellAddress, true);
      cellPopupService.editFocusedEntity(entity);
    }
  };
  React.useEffect(() => {
    var _a, _b;
    (_b = (_a = cellPopupRef === null || cellPopupRef === void 0 ? void 0 : cellPopupRef.current) === null || _a === void 0 ? void 0 : _a.refreshContent) === null || _b === void 0 ? void 0 : _b.call(_a);
  }, [focusedEntity]);
  if (!cellAddress) {
    return React.createElement(React.Fragment, null);
  }
  const noteEditor = React.createElement(ConnectedNoteEditor, {
    enableEditMode: () => enableEditMode('Note')
  });
  const commentEditor = React.createElement(CommentsEditor, {
    onRefreshContent: () => {
      var _a, _b;
      return (_b = (_a = cellPopupRef === null || cellPopupRef === void 0 ? void 0 : cellPopupRef.current) === null || _a === void 0 ? void 0 : _a.refreshContent) === null || _b === void 0 ? void 0 : _b.call(_a);
    },
    enableEditMode: () => enableEditMode('Comment')
  });
  return React.createElement(CellPopup, {
    ref: cellPopupRef,
    key: `${cellAddress.PrimaryKeyValue}-${cellAddress.ColumnId}`,
    isOpen: true,
    primaryKeyValue: cellAddress.PrimaryKeyValue,
    columnId: cellAddress.ColumnId
  }, (() => {
    switch (focusedEntity) {
      case 'Note':
        return noteEditor;
      case 'Comment':
        return commentEditor;
      default:
        return React.createElement(React.Fragment, null, noteEditor, commentEditor);
    }
  })());
};