import * as React from 'react';
import { Select } from '../../../components/Select';
import { useAdaptable } from '../../AdaptableContext';
export const ColumnSelector = function (props) {
  var _a;
  const {
    api
  } = useAdaptable();
  const columns = React.useMemo(() => {
    return api.columnApi.getColumnsOfType(props.type);
  }, []);
  const getColumn = columnId => columns.find(c => c.columnId === columnId);
  const options = columns.map(column => {
    var _a;
    return {
      label: (_a = column.friendlyName) !== null && _a !== void 0 ? _a : column.columnId,
      value: column.columnId
    };
  }).filter(option => {
    if (props.filterColumn) {
      return props.filterColumn(getColumn(option.value));
    }
    return true;
  });
  return React.createElement(Select, {
    "data-name": "column-selector",
    disabled: props.disabled,
    isMulti: props.isMulti,
    menuPosition: props.menuPosition,
    value: props.value,
    placeholder: (_a = props.placeholder) !== null && _a !== void 0 ? _a : 'Select Column',
    onChange: colId => {
      props.onChange(colId);
    },
    options: options
  });
};