import { __rest } from "tslib";
import * as React from 'react';
import { Tabs } from '../../../components/Tabs';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
import { CheckBox } from '../../../components/CheckBox';
import { Box, Text } from 'rebass';
import { AdaptablePopupAlert } from '../../Components/Popups/AdaptablePopupAlert';
import { AlertButtonsEditor } from './AlertButtonsEditor';
import join from '../../../components/utils/join';
export const renderAlertNotificationSummary = () => {
  var _a;
  const {
    data,
    api
  } = useOnePageAdaptableWizardContext();
  if (!((_a = data.AlertProperties) === null || _a === void 0 ? void 0 : _a.DisplayNotification)) {
    return React.createElement(Text, {
      fontSize: 2
    }, "No Alert Notification will be displayed");
  }
  return React.createElement(AlertPreview, {
    api: api,
    alertDefinition: data
  });
};
export const AlertPreview = _a => {
  var {
      alertDefinition,
      api,
      focusFirstButton
    } = _a,
    boxProps = __rest(_a, ["alertDefinition", "api", "focusFirstButton"]);
  const mapButtons = React.useCallback(button => {
    let buttonStyle = button.ButtonStyle;
    if (buttonStyle && buttonStyle.tone == null && (alertDefinition === null || alertDefinition === void 0 ? void 0 : alertDefinition.MessageType)) {
      return Object.assign(Object.assign({}, button), {
        ButtonStyle: Object.assign({
          tone: alertDefinition.MessageType.toLowerCase()
        }, buttonStyle)
      });
    }
    return button;
  }, [alertDefinition === null || alertDefinition === void 0 ? void 0 : alertDefinition.MessageType]);
  const alertToPreview = React.useMemo(() => {
    var _a;
    const alertForm = alertDefinition.AlertForm || api.alertApi.internalApi.getDefaultAlertNotificationForm();
    if (typeof alertForm !== 'string') {
      alertForm.Buttons = ((_a = alertForm.Buttons) !== null && _a !== void 0 ? _a : []).map(mapButtons);
    }
    const result = {
      alertType: 'generic',
      header: api.alertApi.internalApi.getAlertMessageHeaderForDataChange(alertDefinition),
      message: api.alertApi.internalApi.getAlertDescriptionForDataChange(alertDefinition),
      alertDefinition: Object.assign(Object.assign({}, alertDefinition), {
        AlertForm: alertForm
      })
    };
    return result;
  }, [alertDefinition]);
  return React.createElement(Box, Object.assign({}, boxProps, {
    className: join(boxProps.className, 'ab-AlertPreview'),
    style: Object.assign({
      border: '1px solid var(--ab-color-inputborder)'
    }, boxProps.style)
  }), React.createElement(AdaptablePopupAlert, {
    headless: true,
    focusFirstButton: focusFirstButton,
    adaptableAlert: alertToPreview,
    onClose: () => {}
  }));
};
export const AlertNotificationWizardSection = props => {
  var _a, _b, _c, _d;
  const {
    data,
    api
  } = useOnePageAdaptableWizardContext();
  const adaptableAlert = {
    alertType: 'generic',
    header: data.MessageType,
    message: '',
    alertDefinition: data
  };
  if (data.AlertProperties.DisplayNotification && data.AlertForm == undefined) {
    data.AlertForm = api.alertApi.internalApi.getDefaultAlertNotificationForm();
  }
  return React.createElement(React.Fragment, null, React.createElement(Tabs, {
    "data-name": "display-options",
    mt: 2,
    mb: 3,
    autoFocus: false
  }, React.createElement(Tabs.Tab, null, "Notification Options"), React.createElement(Tabs.Content, null, React.createElement(CheckBox, {
    checked: (_a = data.AlertProperties) === null || _a === void 0 ? void 0 : _a.DisplayNotification,
    onChange: DisplayNotification => {
      var _a, _b, _c;
      const newAlertDefinition = Object.assign(Object.assign({}, data), {
        AlertProperties: Object.assign(Object.assign({}, data.AlertProperties), {
          DisplayNotification
        })
      });
      if (!DisplayNotification && typeof newAlertDefinition.AlertForm === 'object') {
        delete newAlertDefinition.AlertForm.Buttons;
      }
      if (DisplayNotification && newAlertDefinition.AlertForm == undefined) {
        newAlertDefinition.AlertForm = api.alertApi.internalApi.getDefaultAlertNotificationForm();
      }
      // make sure we have at least one button
      if (typeof newAlertDefinition.AlertForm === 'object' && (!((_a = newAlertDefinition.AlertForm) === null || _a === void 0 ? void 0 : _a.Buttons) || ((_c = (_b = newAlertDefinition.AlertForm) === null || _b === void 0 ? void 0 : _b.Buttons) === null || _c === void 0 ? void 0 : _c.length) === 0)) {
        newAlertDefinition.AlertForm.Buttons = [api.alertApi.internalApi.getDefaultButton()];
      }
      props.onChange(newAlertDefinition);
    }
  }, "Display a Notification when Alert is triggered (with action buttons)"), ((_b = data.AlertProperties) === null || _b === void 0 ? void 0 : _b.DisplayNotification) ? typeof data.AlertForm === 'string' ? React.createElement(Text, {
    fontSize: 2
  }, "Alert buttons cannot be customized because form is dynamically driven") : React.createElement(AlertButtonsEditor, {
    alertType: props.alertType,
    AlertButtons: (_c = data.AlertForm) === null || _c === void 0 ? void 0 : _c.Buttons,
    api: api,
    adaptableAlert: adaptableAlert,
    onChange: buttons => {
      props.onChange(Object.assign(Object.assign({}, data), {
        AlertForm: Object.assign(Object.assign({}, data.AlertForm), {
          Buttons: buttons
        })
      }));
    }
  }) : null)), ((_d = data.AlertProperties) === null || _d === void 0 ? void 0 : _d.DisplayNotification) ? React.createElement(Tabs, {
    "data-name": "alert-preview",
    autoFocus: false
  }, React.createElement(Tabs.Tab, null, "Alert Preview"), React.createElement(Tabs.Content, null, typeof data.AlertForm === 'string' ? React.createElement(Text, {
    fontSize: 2
  }, "Preview not available because form is dynamically driven") : React.createElement(AlertPreview, {
    alertDefinition: data,
    api: api
  }))) : null);
};