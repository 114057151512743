import * as React from 'react';
import { Box } from 'rebass';
import { Tabs } from '../../../../components/Tabs';
import { UIOptionsActionColumnsForm } from './UIOptionsActionColumnsForm';
import { UIOptionsSidebarForm } from './UIOptionsSidebarForm';
import { UIOptionsStatusbarForm } from './UIOptionsStatusbarForm';
export const UIElementsForm = props => {
  return React.createElement(Box, {
    p: 2
  }, React.createElement(Tabs, null, React.createElement(Tabs.Tab, null, "Tool Panel"), React.createElement(Tabs.Tab, null, "Status Bar"), React.createElement(Tabs.Tab, null, "Action Rows"), React.createElement(Tabs.Content, null, React.createElement(UIOptionsSidebarForm, {
    gridOptions: props.gridOptions,
    onChange: props.onChangedGridOptions
  })), React.createElement(Tabs.Content, null, React.createElement(UIOptionsStatusbarForm, {
    gridOptions: props.gridOptions,
    onChange: props.onChangedGridOptions
  })), ' ', React.createElement(Tabs.Content, null, React.createElement(UIOptionsActionColumnsForm, {
    abOptions: props.adaptableOptions,
    onChange: props.onChangedAdaptableOptions
  }))));
};