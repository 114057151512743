import * as React from 'react';
import { Box } from 'rebass';
import { Tabs } from '../../../../components/Tabs';
import FormLayout, { FormRow } from '../../../../components/FormLayout';
import Radio from '../../../../components/Radio';
import DropdownButton from '../../../../components/DropdownButton';
import { FormDescriptionText } from '../../../Wizard/OnePageAdaptableWizard';
export const ScheduleSettingsIPushPull = props => {
  var _a, _b, _c, _d, _e, _f;
  const reportOptions = props.allReports.map(report => ({
    label: report.Name,
    value: report.Name,
    onClick: () => props.onChange(Object.assign(Object.assign({}, props.iPushPull), {
      IPushPullReport: Object.assign(Object.assign({}, props.iPushPull.IPushPullReport), {
        ReportName: report.Name
      })
    }))
  }));
  const folderOptions = props.allFolders.map(folder => ({
    label: folder.Name,
    value: folder.Name,
    onClick: () => props.onChange(Object.assign(Object.assign({}, props.iPushPull), {
      IPushPullReport: Object.assign(Object.assign({}, props.iPushPull.IPushPullReport), {
        Folder: folder.Name
      })
    }))
  }));
  const pagesOptions = props.availablePages.map(page => ({
    label: page,
    value: page,
    onClick: () => props.onChange(Object.assign(Object.assign({}, props.iPushPull), {
      IPushPullReport: Object.assign(Object.assign({}, props.iPushPull.IPushPullReport), {
        Page: page
      })
    }))
  }));
  const handleDataTypeChange = Transmission => props.onChange(Object.assign(Object.assign({}, props.iPushPull), {
    Transmission
  }));
  return React.createElement(Box, {
    "data-name": "schedule-settings-ipushpull"
  }, React.createElement(Tabs, {
    autoFocus: false
  }, React.createElement(Tabs.Tab, null, "Ipushpull Schedule Settings"), React.createElement(Tabs.Content, null, React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Select Report"
  }, React.createElement(DropdownButton, {
    "data-name": "report-name",
    disabled: props.allReports.length == 0,
    items: reportOptions,
    style: {
      width: 300
    }
  }, ((_b = (_a = props === null || props === void 0 ? void 0 : props.iPushPull) === null || _a === void 0 ? void 0 : _a.IPushPullReport) === null || _b === void 0 ? void 0 : _b.ReportName) || 'Select Report')), React.createElement(FormRow, {
    label: "Select Folder"
  }, React.createElement(DropdownButton, {
    "data-name": "folder-name",
    disabled: props.allFolders.length == 0,
    items: folderOptions,
    style: {
      width: 300
    }
  }, ((_d = (_c = props === null || props === void 0 ? void 0 : props.iPushPull) === null || _c === void 0 ? void 0 : _c.IPushPullReport) === null || _d === void 0 ? void 0 : _d.Folder) || 'Select Folder')), React.createElement(FormRow, {
    label: "Select Page"
  }, React.createElement(DropdownButton, {
    "data-name": "page-name",
    disabled: props.allFolders.length == 0,
    items: pagesOptions,
    style: {
      width: 300
    }
  }, ((_f = (_e = props === null || props === void 0 ? void 0 : props.iPushPull) === null || _e === void 0 ? void 0 : _e.IPushPullReport) === null || _f === void 0 ? void 0 : _f.Page) || 'Select Page')), React.createElement(FormRow, {
    label: "Data Type"
  }, React.createElement(Radio, {
    "data-name": "data-type",
    mr: 3,
    value: "Snapshot",
    checked: props.iPushPull.Transmission === 'Snapshot',
    onChange: checked => checked && handleDataTypeChange('Snapshot')
  }, "Snapshot (one off report)"), React.createElement(Radio, {
    "data-name": "data-live",
    value: "Live Data",
    checked: props.iPushPull.Transmission === 'Live Data',
    onChange: checked => checked && handleDataTypeChange('Live Data')
  }, "Live Data (real-time updates)")), React.createElement(FormRow, {
    label: ""
  }, React.createElement(FormDescriptionText, null, "Choose whether to send ipushpull Data as 'Snapshot' (One-off report) or 'Live Data' (updating as Grid updates)"))))));
};