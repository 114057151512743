import { LIGHT_THEME, DARK_THEME, OS_THEME } from '../Utilities/Constants/GeneralConstants';
export const StaticThemes = [{
  Name: LIGHT_THEME,
  Description: 'Light Theme'
}, {
  Name: DARK_THEME,
  Description: 'Dark Theme'
}, {
  Name: OS_THEME,
  Description: 'OS Theme'
}];