import { SHOW_THEME_EDITOR } from '../../View/Components/Popups/WindowPopups/windowFactory';
import { ApiBase } from '../Implementation/ApiBase';
export class ThemeInternalApi extends ApiBase {
  isSystemTheme(themeName) {
    return this.getThemeApi().getSystemThemes().some(theme => theme.Name === themeName);
  }
  getThemeClassName(theme) {
    return `ab--theme-${theme}`;
  }
  openInWindow() {
    this.getAdaptableApi().internalApi.showPopupWindow({
      id: SHOW_THEME_EDITOR,
      title: 'Theme',
      icon: 'theme',
      factoryId: SHOW_THEME_EDITOR,
      popupProps: {}
    });
  }
}