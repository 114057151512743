import * as React from 'react';
import { Box, Flex, Text } from 'rebass';
import { CheckBox } from '../../components/CheckBox';
import { ColorPicker } from '../../components/ColorPicker';
import FormLayout, { FormRow } from '../../components/FormLayout';
import HelpBlock from '../../components/HelpBlock';
import Panel from '../../components/Panel';
import Radio from '../../components/Radio';
import { Select } from '../../components/Select';
import { StylePreview } from '../../components/StylePreview';
import { Toggle } from '../../components/Toggle/Toggle';
import { ToggleGroup } from '../../components/Toggle/ToggleGroup';
import { FontSize, FontStyle, FontWeight } from '../../PredefinedConfig/Common/Enums';
import ArrayExtensions from '../../Utilities/Extensions/ArrayExtensions';
import { EnumExtensions } from '../../Utilities/Extensions/EnumExtensions';
import { StringExtensions } from '../../Utilities/Extensions/StringExtensions';
import AdaptableInput from './AdaptableInput';
export class StyleComponent extends React.Component {
  constructor(props) {
    super(props);
    this.onShowClassNameChanged = checked => {
      this.setState(state => {
        const newState = Object.assign({}, state);
        delete newState.componentStyle.BackColor;
        delete newState.componentStyle.ForeColor;
        delete newState.componentStyle.BorderColor;
        delete newState.componentStyle.FontSize;
        delete newState.componentStyle.FontStyle;
        delete newState.componentStyle.FontWeight;
        newState.componentStyle.ClassName = '';
        newState.ShowClassName = checked;
        return newState;
      });
    };
    this.state = {
      componentStyle: this.props.Style,
      ShowClassName: StringExtensions.IsNotNullOrEmpty(this.props.Style.ClassName)
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      componentStyle: nextProps.Style
    };
  }
  render() {
    var _a, _b;
    const Cmp = this.props.headless ? Box : Panel;
    const cmpProps = this.props.headless ? {} : {
      header: 'Style',
      margin: 2,
      'data-name': 'style-component'
    };
    const styleClassNames = this.props.api.userInterfaceApi.getStyleClassNames();
    const colourSpellingVariant = this.props.api.internalApi.getCorrectEnglishVariant('Colour');
    const coloursSpellingVariant = this.props.api.internalApi.getCorrectEnglishVariant('colours');
    let fontSizes = ['Default'];
    fontSizes.push(...EnumExtensions.getNames(FontSize));
    return React.createElement(Cmp, Object.assign({}, cmpProps, {
      className: "ab-StyleComponent"
    }), ArrayExtensions.IsNotNullOrEmpty(styleClassNames) && React.createElement(Flex, {
      flexDirection: "row",
      padding: 2
    }, React.createElement(Radio, {
      "data-name": "show-class-name",
      value: "classname",
      checked: this.state.ShowClassName,
      onChange: () => this.onShowClassNameChanged(true)
    }, "Use Style Class Name"), ' ', React.createElement(Radio, {
      "data-name": "create-style",
      marginLeft: 3,
      value: "expression",
      checked: !this.state.ShowClassName,
      onChange: () => this.onShowClassNameChanged(false)
    }, "Create Style"), ' '), this.state.ShowClassName ? React.createElement("div", null, React.createElement(HelpBlock, {
      fontSize: 2
    }, 'Select a CSS Class Name'), React.createElement(Box, {
      style: {
        maxWidth: '15rem'
      },
      m: 3
    }, React.createElement(Select, {
      options: ArrayExtensions.IsNullOrEmpty(styleClassNames) ? [] : styleClassNames.map(item => ({
        label: item,
        value: item
      })),
      value: this.state.componentStyle.ClassName,
      onChange: value => this.onStyleClassNameChanged(value)
    })), React.createElement(Text, {
      color: 'var(--ab-color-warn)',
      style: {
        flex: 2
      },
      margin: 2
    }, 'Please ensure that the styles listed are in the current stylesheet')) : React.createElement(Flex, {
      flexDirection: "column"
    }, React.createElement(Flex, {
      flex: 1
    }, React.createElement("div", null, React.createElement(HelpBlock, {
      fontSize: 2,
      marginTop: 2,
      marginBottom: 2
    }, "Set ", coloursSpellingVariant, " by ticking a checkbox and selecting from the dropdown; leave unchecked to use cell's existing ", coloursSpellingVariant), React.createElement(FormLayout, {
      columns: [1, 2]
    }, React.createElement(FormRow, null, React.createElement(CheckBox, {
      "data-name": "set-background-color",
      value: "existing",
      margin: 1,
      checked: !!this.state.componentStyle.BackColor,
      onChange: checked => this.onUseBackColorCheckChange(checked)
    }, "Back ", colourSpellingVariant), this.state.componentStyle.BackColor != null ? React.createElement(ColorPicker, {
      api: this.props.api,
      value: this.state.componentStyle.BackColor,
      onChange: x => this.onBackColorSelectChange(x)
    }) : React.createElement("span", null)), React.createElement(FormRow, null, React.createElement(CheckBox, {
      "data-name": "set-foreground-color",
      margin: 1,
      value: "existing",
      checked: this.state.componentStyle.ForeColor ? true : false,
      onChange: checked => this.onUseForeColorCheckChange(checked)
    }, "Fore ", colourSpellingVariant), this.state.componentStyle.ForeColor != null ? React.createElement(ColorPicker, {
      api: this.props.api,
      value: this.state.componentStyle.ForeColor,
      onChange: x => this.onForeColorSelectChange(x)
    }) : React.createElement("span", null)), React.createElement(FormRow, null, React.createElement(CheckBox, {
      "data-name": "set-border-color",
      margin: 1,
      value: "existing",
      checked: this.state.componentStyle.BorderColor ? true : false,
      onChange: checked => this.onUseBorderColorCheckChange(checked)
    }, "Border ", colourSpellingVariant), this.state.componentStyle.BorderColor != null ? React.createElement(ColorPicker, {
      api: this.props.api,
      value: this.state.componentStyle.BorderColor,
      onChange: x => this.onBorderColorSelectChange(x)
    }) : React.createElement("span", null))), React.createElement(HelpBlock, {
      fontSize: 2,
      marginTop: 2,
      marginBottom: 2
    }, "Set the font properties of the Style"), React.createElement(FormLayout, null, React.createElement(FormRow, {
      label: "Font Style"
    }, React.createElement(ToggleGroup, null, React.createElement(Toggle, {
      pressed: this.state.componentStyle.FontStyle == FontStyle.Italic,
      onPressedChange: checked => this.onFontStyleChange(checked),
      icon: "italic"
    }), React.createElement(Toggle, {
      pressed: this.state.componentStyle.FontWeight == FontWeight.Bold,
      onPressedChange: checked => this.onFontWeightChange(checked),
      icon: "bold"
    }))), React.createElement(FormRow, {
      label: "Text Decoration"
    }, React.createElement(ToggleGroup, null, React.createElement(Toggle, {
      icon: "underline",
      pressed: this.state.componentStyle.TextDecoration === 'Underline',
      onPressedChange: checked => this.onTextDecorationChange(checked ? 'Underline' : 'None')
    }), React.createElement(Toggle, {
      icon: "strikethrough",
      pressed: this.state.componentStyle.TextDecoration === 'LineThrough',
      onPressedChange: checked => this.onTextDecorationChange(checked ? 'LineThrough' : 'None')
    }), React.createElement(Toggle, {
      icon: "overline",
      pressed: this.state.componentStyle.TextDecoration === 'Overline',
      onPressedChange: checked => this.onTextDecorationChange(checked ? 'Overline' : 'None')
    }))), React.createElement(FormRow, {
      label: "Font Size"
    }, !this.props.showFontSizeAs || this.props.showFontSizeAs === 'dropdown' ? React.createElement(Flex, {
      flexDirection: "row",
      alignItems: "center"
    }, React.createElement(Select, {
      placeholder: "Default",
      options: [{
        label: 'Default',
        value: ''
      }, ...EnumExtensions.getNames(FontSize).map(enumName => ({
        label: enumName,
        value: enumName
      }))],
      value: (_b = (_a = this.state.componentStyle.FontSize) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '',
      onChange: value => this.onFontSizeChange(value)
    })) : React.createElement(Box, null, fontSizes.map(enumName => {
      return React.createElement(Radio, {
        onClick: () => this.onFontSizeChange(enumName),
        checked: enumName == 'Default' && !this.state.componentStyle.FontSize || this.state.componentStyle.FontSize === enumName,
        mr: 3,
        key: enumName
      }, enumName);
    }))), React.createElement(FormRow, {
      label: "Border Radius"
    }, React.createElement(AdaptableInput, {
      width: "100px",
      type: "number",
      min: "0",
      value: this.state.componentStyle.BorderRadius,
      onChange: event => {
        const number = Number(event.target.value);
        if (isNaN(number)) {
          this.onBorderRadiusChange(0);
        } else {
          this.onBorderRadiusChange(number);
        }
      }
    })))))), !this.props.hidePreview && React.createElement(Box, null, React.createElement(HelpBlock, {
      fontSize: 2,
      marginTop: 2,
      marginBottom: 2
    }, "Preview"), React.createElement(StylePreview, {
      styleObject: this.state.componentStyle
    })));
  }
  onStyleClassNameChanged(value) {
    this.state.componentStyle.ClassName = value == 'select' ? '' : value;
    this.props.UpdateStyle(this.state.componentStyle);
  }
  onUseBackColorCheckChange(checked) {
    if (checked) {
      this.state.componentStyle.BackColor = '#ffffff';
    } else {
      delete this.state.componentStyle.BackColor;
    }
    this.props.UpdateStyle(this.state.componentStyle);
  }
  onUseForeColorCheckChange(checked) {
    if (checked) {
      this.state.componentStyle.ForeColor = '#000000';
    } else {
      delete this.state.componentStyle.ForeColor;
    }
    this.props.UpdateStyle(this.state.componentStyle);
  }
  onUseBorderColorCheckChange(checked) {
    if (checked) {
      this.state.componentStyle.BorderColor = '#000000';
    } else {
      delete this.state.componentStyle.BorderColor;
    }
    this.props.UpdateStyle(this.state.componentStyle);
  }
  onUseFontSizeCheckChange(checked) {
    if (checked) {
      this.state.componentStyle.FontSize = FontSize.Medium;
    } else {
      delete this.state.componentStyle.FontSize;
    }
    this.props.UpdateStyle(this.state.componentStyle);
  }
  onBackColorSelectChange(color) {
    this.state.componentStyle.BackColor = color;
    this.props.UpdateStyle(this.state.componentStyle);
  }
  onForeColorSelectChange(color) {
    this.state.componentStyle.ForeColor = color;
    this.props.UpdateStyle(this.state.componentStyle);
  }
  onBorderColorSelectChange(color) {
    this.state.componentStyle.BorderColor = color;
    this.props.UpdateStyle(this.state.componentStyle);
  }
  onFontWeightChange(checked) {
    if (checked) {
      this.state.componentStyle.FontWeight = FontWeight.Bold;
    } else {
      delete this.state.componentStyle.FontWeight;
    }
    this.props.UpdateStyle(this.state.componentStyle);
  }
  onFontStyleChange(checked) {
    if (checked) {
      this.state.componentStyle.FontStyle = FontStyle.Italic;
    } else {
      delete this.state.componentStyle.FontStyle;
    }
    this.props.UpdateStyle(this.state.componentStyle);
  }
  onTextDecorationChange(textDecoration) {
    this.state.componentStyle.TextDecoration = textDecoration;
    if (textDecoration === 'None' || !textDecoration) {
      delete this.state.componentStyle.TextDecoration;
    }
    this.props.UpdateStyle(this.state.componentStyle);
  }
  onBorderRadiusChange(value) {
    this.state.componentStyle.BorderRadius = value;
    if (value === 0) {
      delete this.state.componentStyle.BorderRadius;
    }
    this.props.UpdateStyle(this.state.componentStyle);
  }
  onFontSizeChange(value) {
    this.state.componentStyle.FontSize = value;
    if (value === '' || value == 'Default') {
      delete this.state.componentStyle.FontSize;
    }
    this.props.UpdateStyle(this.state.componentStyle);
  }
}