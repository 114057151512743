import flatten from 'lodash/flatten';
import * as React from 'react';
import HelpBlock from '../../../../components/HelpBlock';
import { Icon } from '../../../../components/icons';
import { DataSource, InfiniteTable } from '../../../../components/InfiniteTable';
import { Tag } from '../../../../components/Tag';
import { useAdaptable } from '../../../AdaptableContext';
import { AdaptablePopover } from '../../../AdaptablePopover';
import UIHelper from '../../../UIHelper';
import { createBaseContext } from '../../../../Utilities/ObjectFactory';
const tableDOMProps = {
  style: {
    height: '100%',
    minWidth: '10rem',
    minHeight: 300,
    flex: 1
  }
};
const getRowErrors = (errors, primaryKeyValue) => {
  if (!errors) {
    return null;
  }
  if (!errors[primaryKeyValue]) {
    return null;
  }
  return errors[primaryKeyValue];
};
const getCellError = (errors, primaryKeyValue, columnId) => {
  var _a, _b;
  return (_b = (_a = getRowErrors(errors, primaryKeyValue)) === null || _a === void 0 ? void 0 : _a.find(e => e.columnId === columnId)) !== null && _b !== void 0 ? _b : null;
};
export const DataPreview = props => {
  const adaptable = useAdaptable();
  const dataImportOptions = adaptable.api.optionsApi.getDataImportOptions();
  if (!props.data) {
    return React.createElement(HelpBlock, {
      color: 'var(--ab-color-error)'
    }, "No Data has been Imported. Go to ", React.createElement(Tag, null, "Upload"), " step and provide Data");
  }
  const primaryKey = adaptable.api.optionsApi.getPrimaryKey();
  const columns = React.useMemo(() => {
    var _a;
    const mappedAdaptableColumns = (_a = props.columnsMap) === null || _a === void 0 ? void 0 : _a.filter(map => map.include && map.abColumn);
    const mapDataType = dataType => {
      switch (dataType) {
        case 'Boolean':
          return 'boolean';
        case 'Date':
          return 'date';
        case 'String':
          return 'number';
      }
      return dataType;
    };
    const getPrimaryKeyValue = rowData => {
      if (typeof dataImportOptions._getPrimaryKeyValue === 'function') {
        return dataImportOptions._getPrimaryKeyValue(Object.assign(Object.assign({}, createBaseContext(adaptable.api)), {
          rowData
        }));
      }
      return rowData[primaryKey];
    };
    const infiniteColumns = mappedAdaptableColumns.reduce((acc, colMap) => {
      var _a;
      const isPrimaryKey = ((_a = colMap === null || colMap === void 0 ? void 0 : colMap.abColumn) === null || _a === void 0 ? void 0 : _a.columnId) === primaryKey;
      let editable = !isPrimaryKey;
      if (props.editable !== undefined && !isPrimaryKey) {
        editable = props.editable;
      }
      const abColumn = colMap.abColumn;
      acc[colMap.field] = {
        field: colMap.field,
        header: adaptable.api.columnApi.getFriendlyNameForColumnId(colMap.abColumn.columnId),
        dataType: mapDataType(abColumn.dataType),
        defaultEditable: editable,
        valueGetter: params => {
          if (abColumn.dataType === 'Boolean') {
            return params.data[colMap.field] ? 'True' : 'False';
          } else {
            return params.data[colMap.field];
          }
        }
      };
      return acc;
    }, {});
    infiniteColumns['valid'] = {
      header: 'Valid',
      maxWidth: 100,
      minWidth: 90,
      align: 'center',
      renderValue: params => {
        const cellErrors = getRowErrors(props.errors, getPrimaryKeyValue(params.data));
        const successColor = UIHelper.getColorByMessageType('Success');
        if (!cellErrors || cellErrors.length === 0) {
          return React.createElement(Icon, {
            name: "check",
            style: {
              color: successColor,
              fill: 'currentColor'
            }
          });
        }
        const errorsStr = cellErrors.map(e => e.error).join(', ');
        return React.createElement(AdaptablePopover, {
          popoverMinWidth: 200,
          showEvent: "mouseenter",
          hideEvent: "mouseleave",
          headerText: 'Validation Error',
          bodyText: [errorsStr],
          MessageType: 'Error'
        });
      }
    };
    infiniteColumns['type'] = {
      header: 'Type',
      maxWidth: 100,
      minWidth: 90,
      renderValue: params => {
        try {
          const isExistingRow = adaptable.api.gridApi.getRowNodeForPrimaryKey(getPrimaryKeyValue(params.data));
          return isExistingRow ? 'Update' : 'New';
        } catch (error) {
          // empty
        }
        return React.createElement(React.Fragment, null);
      }
    };
    return infiniteColumns;
  }, [props.data]);
  const getCellErrorStyle = (columnId, primaryKeyValue) => {
    const cellError = getCellError(props.errors, primaryKeyValue, columnId);
    if (!cellError) {
      return {};
    }
    return {
      border: 'var(--ab-Dataimport-validation-error__border)'
    };
  };
  return React.createElement(DataSource, {
    data: props.data,
    primaryKey: primaryKey,
    onDataMutations: params => {
      const newRowData = [...props.data];
      const mutationsList = flatten(Array.from(params.mutations.values()));
      if (!mutationsList.length) {
        return;
      }
      mutationsList.forEach(mutation => {
        const index = newRowData.findIndex(row => row[primaryKey] === mutation.primaryKey);
        if (index !== -1) {
          newRowData[index] = Object.assign(Object.assign({}, newRowData[index]), mutation.data);
        }
      });
      props.onDataChange(newRowData);
    }
  }, React.createElement(InfiniteTable, {
    columnPinning: {
      valid: 'end',
      type: 'end'
    },
    columnTypes: {
      default: {
        style: params => {
          return getCellErrorStyle(params.column.id, params.rowInfo.id);
        },
        minWidth: 100,
        defaultFlex: 1
      }
    },
    domProps: tableDOMProps,
    columns: columns
  }));
};