import * as React from 'react';
import { useState } from 'react';
import { Box, Flex } from 'rebass';
import { OnePageAdaptableWizard, OnePageWizardSummary } from '../../Wizard/OnePageAdaptableWizard';
import { cloneObject } from '../../../Utilities/Helpers/Helper';
import { FormatColumnScopeWizardSection, renderFormatColumnScopeSummary } from './FormatColumnScopeWizardSection';
import { FormatColumnStyleWizardSection, isFormatColumnStyleValid, renderFormatColumnStyleWizardSummary } from './FormatColumnStyleWizardSection';
import { FormatColumnFormatWizardSection, getFormatDisplayTypeForScope, renderFormatColumnFormatSummary } from './FormatColumnFormatWizardSection';
import { useAdaptable } from '../../AdaptableContext';
import { FormatColumnSettingsWizardSection, renderFormatColumnSettingsSummary } from './FormatColumnSettingsWizardSection';
import { isScopeValid } from '../../Components/NewScopeComponent';
import ObjectFactory from '../../../Utilities/ObjectFactory';
import { useDispatch } from 'react-redux';
import * as FormatColumnRedux from '../../../Redux/ActionsReducers/FormatColumnRedux';
import { ObjectTagsWizardSection, renderObjectTagsSummary } from '../../Wizard/ObjectTagsWizardSection';
import { isAdaptableRuleValid } from '../../Components/EntityRulesEditor/Utilities';
import { FormatColumnRuleWizardSection } from './FormatColumnRuleWizardSection';
import { DEFAULT_PREDICATE_ID_FOR_FORMAT_COLUMN } from './constants';
import { isObjectEmpty } from '../../../Utilities/Extensions/ObjectExtensions';
const adjustDisplayFormat = (formatColumn, api) => {
  formatColumn = Object.assign({}, formatColumn);
  const formatDataType = getFormatDisplayTypeForScope(formatColumn.Scope, api);
  if (!formatDataType && formatColumn.DisplayFormat) {
    formatColumn.DisplayFormat = undefined;
  }
  if ((!formatColumn.DisplayFormat || formatColumn.DisplayFormat.Formatter !== 'NumberFormatter') && formatDataType === 'Number') {
    formatColumn.DisplayFormat = {
      Formatter: 'NumberFormatter',
      Options: {}
    };
  }
  if ((!formatColumn.DisplayFormat || formatColumn.DisplayFormat.Formatter !== 'DateFormatter') && formatDataType === 'Date') {
    formatColumn.DisplayFormat = {
      Formatter: 'DateFormatter',
      Options: {}
    };
  }
  if ((!formatColumn.DisplayFormat || formatColumn.DisplayFormat.Formatter !== 'StringFormatter') && formatDataType === 'String') {
    formatColumn.DisplayFormat = {
      Formatter: 'StringFormatter',
      Options: {}
    };
  }
  return formatColumn;
};
const adjustFormatColumn = (formatColumn, api) => {
  formatColumn = adjustDisplayFormat(formatColumn, api);
  return formatColumn;
};
export function FormatColumnWizard(props) {
  var _a, _b;
  const {
    api
  } = useAdaptable();
  const data = (_a = props.data) !== null && _a !== void 0 ? _a : (_b = props.popupParams) === null || _b === void 0 ? void 0 : _b.value;
  const [formatColumn, doSetFormatColumn] = useState(() => {
    var _a, _b, _c, _d;
    let formatColumn = data ? cloneObject(data) : ObjectFactory.CreateEmptyFormatColumn();
    formatColumn.Scope = (_a = formatColumn.Scope) !== null && _a !== void 0 ? _a : {
      ColumnIds: []
    };
    if (((_b = props.popupParams) === null || _b === void 0 ? void 0 : _b.action) === 'New' && ((_c = props.popupParams) === null || _c === void 0 ? void 0 : _c.column)) {
      formatColumn.Scope = {
        ColumnIds: [(_d = props.popupParams) === null || _d === void 0 ? void 0 : _d.column.columnId]
      };
    }
    formatColumn = adjustFormatColumn(formatColumn, api);
    return formatColumn;
  });
  const setFormatColumn = React.useCallback(formatColumn => {
    doSetFormatColumn(adjustFormatColumn(formatColumn, api));
  }, []);
  const dispatch = useDispatch();
  const handleFinish = () => {
    var _a, _b;
    if (((_a = formatColumn === null || formatColumn === void 0 ? void 0 : formatColumn.DisplayFormat) === null || _a === void 0 ? void 0 : _a.Options) && isObjectEmpty((_b = formatColumn === null || formatColumn === void 0 ? void 0 : formatColumn.DisplayFormat) === null || _b === void 0 ? void 0 : _b.Options)) {
      delete formatColumn.DisplayFormat;
    }
    if (formatColumn.Style && isObjectEmpty(formatColumn.Style)) {
      delete formatColumn.Style;
    }
    if (data) {
      dispatch(FormatColumnRedux.FormatColumnEdit(formatColumn));
    } else {
      dispatch(FormatColumnRedux.FormatColumnAdd(formatColumn));
    }
    props.onFinishWizard(formatColumn);
  };
  return React.createElement(OnePageAdaptableWizard, {
    defaultCurrentSectionName: props.defaultCurrentSectionName,
    moduleInfo: props.moduleInfo,
    data: formatColumn,
    onHide: props.onCloseWizard,
    onFinish: handleFinish,
    sections: [{
      title: 'Scope',
      details: 'Select which Columns will be formatted',
      isValid: isScopeValid,
      renderSummary: renderFormatColumnScopeSummary,
      render: () => {
        return React.createElement(Flex, {
          flexDirection: "column",
          style: {
            height: '100%'
          },
          padding: 2
        }, React.createElement(FormatColumnScopeWizardSection, {
          onChange: setFormatColumn
        }));
      }
    }, {
      isValid: (abObject, api, context) => {
        if (!abObject.Rule) {
          return true;
        }
        return isAdaptableRuleValid(abObject, api, context);
      },
      title: 'Condition',
      details: 'Build a Rule when the Format Column should be applied',
      render: () => {
        return React.createElement(FormatColumnRuleWizardSection, {
          onChange: setFormatColumn,
          defaultPredicateId: DEFAULT_PREDICATE_ID_FOR_FORMAT_COLUMN
        });
      }
    }, {
      title: 'Style',
      details: 'Create a Style',
      isValid: isFormatColumnStyleValid,
      renderSummary: renderFormatColumnStyleWizardSummary,
      render: () => {
        return React.createElement(Box, {
          padding: 2
        }, React.createElement(FormatColumnStyleWizardSection, {
          onChange: setFormatColumn
        }));
      }
    }, {
      title: 'Display Format',
      details: 'Provide a Display Format',
      renderSummary: data => renderFormatColumnFormatSummary(data),
      render: () => {
        return React.createElement(FormatColumnFormatWizardSection, {
          onChange: setFormatColumn
        });
      }
    }, {
      title: 'Settings',
      details: 'Set Additional Properties',
      renderSummary: renderFormatColumnSettingsSummary,
      render: () => {
        return React.createElement(Box, {
          padding: 2
        }, React.createElement(FormatColumnSettingsWizardSection, {
          onChange: setFormatColumn
        }));
      }
    }, {
      details: 'Select Format Column Tags',
      title: 'Tags',
      isVisible: (_, api) => api.internalApi.shouldDisplayTagSections(),
      render: () => React.createElement(Box, {
        padding: 2
      }, React.createElement(ObjectTagsWizardSection, {
        onChange: setFormatColumn
      })),
      renderSummary: renderObjectTagsSummary
    }, '-', {
      details: 'Review the Format Column',
      render: () => {
        return React.createElement(Box, {
          padding: 2
        }, React.createElement(OnePageWizardSummary, null));
      },
      title: 'Summary'
    }]
  });
}