import * as React from 'react';
import { Box } from 'rebass';
import SimpleButton from '../../../../components/SimpleButton';
import { Tabs } from '../../../../components/Tabs';
import { Tag } from '../../../../components/Tag';
import { getLayoutSortViewItems } from '../../../../Strategy/Utilities/Layout/getLayoutSortViewItems';
import { useAdaptable } from '../../../AdaptableContext';
import { ValueSelector } from '../../../Components/ValueSelector';
import { useOnePageAdaptableWizardContext } from '../../../Wizard/OnePageAdaptableWizard';
import { columnFilter } from './Utilities';
import ArrayExtensions from '../../../../Utilities/Extensions/ArrayExtensions';
export const SortSectionSummary = () => {
  const {
    data: layout
  } = useOnePageAdaptableWizardContext();
  const adaptable = useAdaptable();
  const sortViewItems = getLayoutSortViewItems(layout, adaptable.api);
  return React.createElement(Box, null, sortViewItems.values.length ? sortViewItems.values.map(value => React.createElement(Tag, {
    key: value,
    mb: 1,
    mr: 1
  }, value)) : React.createElement(Tag, null, "No Sorts"));
};
const ColumnRow = props => {
  var _a, _b, _c;
  const SortOrder = (_c = ((_b = (_a = props.layout) === null || _a === void 0 ? void 0 : _a.ColumnSorts) !== null && _b !== void 0 ? _b : []).find(sort => sort.ColumnId === props.column.columnId)) === null || _c === void 0 ? void 0 : _c.SortOrder;
  const icon = SortOrder === 'Asc' ? 'sort-asc' : 'sort-desc';
  return React.createElement(Box, null, props.column.friendlyName, SortOrder && React.createElement(SimpleButton, {
    ml: 2,
    onClick: () => props.onSortChange(props.column.columnId, SortOrder === 'Asc' ? 'Desc' : 'Asc'),
    variant: "raised",
    icon: icon
  }));
};
export const SortSection = props => {
  var _a;
  const adaptable = useAdaptable();
  const {
    data: layout
  } = useOnePageAdaptableWizardContext();
  const allSortableColumns = adaptable.api.columnApi.getSortableColumns();
  const sortedSortColumns = React.useMemo(() => {
    var _a;
    return ArrayExtensions.sortArrayWithOrder(allSortableColumns.map(col => col.columnId), ((_a = layout.ColumnSorts) !== null && _a !== void 0 ? _a : []).map(sort => sort.ColumnId), {
      sortUnorderedItems: false
    }).map(colId => adaptable.api.columnApi.getColumnWithColumnId(colId));
  }, [layout, allSortableColumns]);
  const handleColumnsSelectionChange = React.useCallback(columnIds => {
    props.onChange(Object.assign(Object.assign({}, layout), {
      ColumnSorts: (columnIds || []).map(columnId => {
        var _a, _b, _c;
        const SortOrder = (_c = (_b = (_a = layout.ColumnSorts) === null || _a === void 0 ? void 0 : _a.find(sort => sort.ColumnId === columnId)) === null || _b === void 0 ? void 0 : _b.SortOrder) !== null && _c !== void 0 ? _c : 'Asc';
        return {
          ColumnId: columnId,
          SortOrder: SortOrder
        };
      })
    }));
  }, [layout]);
  const handleSortChange = React.useCallback((columnId, SortOrder) => {
    var _a;
    props.onChange(Object.assign(Object.assign({}, layout), {
      ColumnSorts: (_a = layout.ColumnSorts) === null || _a === void 0 ? void 0 : _a.map(sort => {
        if (sort.ColumnId === columnId) {
          return Object.assign(Object.assign({}, sort), {
            SortOrder: SortOrder
          });
        }
        return sort;
      })
    }));
  }, [layout]);
  return React.createElement(Tabs, {
    style: {
      height: '100%'
    }
  }, React.createElement(Tabs.Tab, null, "Column Sorts"), React.createElement(Tabs.Content, null, React.createElement(ValueSelector, {
    showFilterInput: true,
    showSelectedOnlyPosition: "top",
    filter: columnFilter,
    toIdentifier: option => `${option.columnId}`,
    toLabel: option => {
      var _a;
      return (_a = option.friendlyName) !== null && _a !== void 0 ? _a : option.columnId;
    },
    toListLabel: column => React.createElement(ColumnRow, {
      onSortChange: handleSortChange,
      layout: layout,
      column: column
    }),
    options: sortedSortColumns,
    value: ((_a = layout.ColumnSorts) !== null && _a !== void 0 ? _a : []).map(sort => sort.ColumnId),
    allowReorder: () => true,
    xSelectedLabel: () => {
      return `Active aggregations:`;
    },
    onChange: handleColumnsSelectionChange
  })));
};