import * as SystemRedux from '../../Redux/ActionsReducers/SystemRedux';
import * as SmartEditRedux from '../../Redux/ActionsReducers/SmartEditRedux';
import { MathOperation, StatusColour } from '../../PredefinedConfig/Common/Enums';
import * as React from 'react';
import { StringExtensions } from '../../Utilities/Extensions/StringExtensions';
import { PreviewResultsPanel } from '../Components/PreviewResultsPanel';
import { EnumExtensions } from '../../Utilities/Extensions/EnumExtensions';
import { Flex } from 'rebass';
import Input from '../../components/Input';
import { ButtonApply } from '../Components/Buttons/ButtonApply';
import { AdaptablePopover } from '../AdaptablePopover';
import { UIHelper } from '../UIHelper';
import { connect } from 'react-redux';
import { Select } from '../../components/Select';
class SmartEditViewPanelComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedColumnId: ''
    };
  }
  componentDidMount() {
    if (this.props.api) {
      const adaptable = this.props.api.internalApi.getAdaptableInstance();
      if (adaptable) {
        this.cleanupEvent = adaptable._on('CellsSelected', () => {
          this.checkSelectedCells();
        });
      }
    }
    this.checkSelectedCells();
  }
  componentWillUnmount() {
    var _a;
    (_a = this.cleanupEvent) === null || _a === void 0 ? void 0 : _a.call(this);
  }
  render() {
    let statusColour = this.getStatusColour();
    let selectedColumn = StringExtensions.IsNotNullOrEmpty(this.state.SelectedColumnId) ? this.props.api.columnApi.getColumnWithColumnId(this.state.SelectedColumnId) : null;
    let previewPanel = React.createElement(PreviewResultsPanel, {
      previewInfo: this.props.PreviewInfo,
      api: this.props.api,
      selectedColumn: selectedColumn,
      showPanel: true,
      showHeader: false
    });
    const operationMenuItems = EnumExtensions.getNames(MathOperation).map((mathOperation, index) => {
      return {
        onClick: () => this.props.onSmartEditOperationChange(mathOperation),
        value: mathOperation,
        label: mathOperation
      };
    });
    const customOperations = this.props.api.smartEditApi.getSmartEditCustomOperations();
    if (customOperations === null || customOperations === void 0 ? void 0 : customOperations.length) {
      operationMenuItems.push(...customOperations.map(operation => {
        return {
          onClick: () => this.props.onSmartEditOperationChange(operation),
          label: operation.name,
          value: operation
        };
      }));
    }
    const applyButtonStyle = {
      color: statusColour,
      fill: 'currentColor'
    };
    let shouldDisable = this.props.accessLevel == 'ReadOnly' || !this.props.IsValidSelection || this.props.InPivotMode == true;
    const elementType = this.props.viewType === 'Toolbar' ? 'DashboardToolbar' : 'ToolPanel';
    return React.createElement(Flex, {
      flexDirection: "row",
      className: `ab-${elementType}__SmartEdit__wrap`,
      flexWrap: this.props.viewType === 'ToolPanel' ? 'wrap' : 'nowrap'
    }, React.createElement(Flex, null, React.createElement(Select, {
      value: typeof this.props.SmartEditOperation === 'object' ? this.props.SmartEditOperation.name : this.props.SmartEditOperation,
      options: operationMenuItems,
      onChange: operation => this.props.onSmartEditOperationChange(operation),
      style: {
        marginRight: 1
      }
    }), React.createElement(Input, {
      style: {
        width: '5rem'
      },
      className: `ab-${elementType}__SmartEdit__select-value`,
      value: this.props.SmartEditValue.toString(),
      type: "number",
      placeholder: "Enter a Number",
      step: "any",
      onChange: e => this.onSmartEditValueChange(e),
      disabled: shouldDisable
    })), React.createElement(Flex, null, !shouldDisable && React.createElement(ButtonApply, {
      onClick: () => this.onApplyClick(),
      style: applyButtonStyle,
      className: `ab-${elementType}__SmartEdit__apply`,
      tooltip: "Apply Smart Edit",
      disabled: StringExtensions.IsNullOrEmpty(`${this.props.SmartEditValue}`) || this.props.PreviewInfo != null && this.props.PreviewInfo.previewValidationSummary.validationResult == 'All',
      accessLevel: this.props.accessLevel
    }, this.props.viewType === 'ToolPanel' && 'Apply Smart Edit'), !shouldDisable && React.createElement(AdaptablePopover, {
      headerText: "Preview Results",
      className: `ab-${elementType}__SmartEdit__info`,
      //  tooltipText="Preview Results"
      bodyText: [previewPanel],
      MessageType: UIHelper.getMessageTypeByStatusColour(statusColour),
      useButton: true,
      showEvent: 'focus',
      hideEvent: "blur"
    })));
  }
  checkSelectedCells() {
    this.props.onSmartEditCheckSelectedCells();
  }
  onSmartEditValueChange(event) {
    const e = event.target;
    this.props.onSmartEditValueChange(Number(e.value));
  }
  getStatusColour() {
    if (StringExtensions.IsNotNullOrEmpty(`${this.props.SmartEditValue}`) && this.props.PreviewInfo) {
      if (this.props.PreviewInfo.previewValidationSummary.validationResult == 'All') {
        return StatusColour.Error;
      }
      if (this.props.PreviewInfo.previewValidationSummary.validationResult == 'Some') {
        return StatusColour.Warn;
      }
    }
    return StatusColour.Success;
  }
  onApplyClick() {
    this.onApplySmartEdit();
  }
  onApplySmartEdit() {
    this.props.onRunSmartEdit();
  }
}
function mapStateToProps(state, ownProps) {
  return {
    SmartEditValue: state.System.SmartEditValue,
    SmartEditOperation: state.System.SmartEditOperation,
    IsValidSelection: state.System.IsValidSmartEditSelection,
    PreviewInfo: state.System.SmartEditPreviewInfo,
    InPivotMode: state.Grid.IsGridInPivotMode
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onSmartEditValueChange: value => dispatch(SystemRedux.SmartEditChangeValue(value)),
    onSmartEditOperationChange: SmartEditOperation => dispatch(SystemRedux.SmartEditChangeOperation(SmartEditOperation)),
    onSmartEditCheckSelectedCells: () => dispatch(SystemRedux.SmartEditCheckCellSelection()),
    onRunSmartEdit: () => dispatch(SmartEditRedux.SmartEditRun(false))
  };
}
export let SmartEditViewPanelControl = connect(mapStateToProps, mapDispatchToProps)(SmartEditViewPanelComponent);