import { WizardStatus } from './Components/SharedProps/EditableConfigEntityState';
import { FontWeight, FontStyle, StatusColour } from '../PredefinedConfig/Common/Enums';
import { StringExtensions } from '../Utilities/Extensions/StringExtensions';
import ArrayExtensions from '../Utilities/Extensions/ArrayExtensions';
export const BLACK = 'Black';
export const WHITE = 'White';
export const LIGHT_GRAY = 'LightGray';
export const GRAY = 'Gray';
export const BROWN = 'Brown';
export const DARK_GREEN = 'DarkGreen';
export const GREEN = 'Green';
export const LIME_GREEN = 'LimeGreen';
export const YELLOW = 'Yellow';
export const LIGHT_YELLOW = 'LightYellow';
export const DARK_BLUE = 'DarkBlue';
export const BLUE = 'Blue';
export const LIGHT_BLUE = 'LightBlue';
//xport const LIGHT_GREEN: string = 'LightGreen';
export const CYAN = 'Cyan';
export const MAGENTA = 'Magenta';
export const PURPLE = 'Purple';
export const DARK_RED = 'DarkRed';
export const RED = 'Red';
export const LIGHT_RED = 'LightRed';
export const ORANGE = 'Orange';
export function getHexForName(name) {
  switch (name) {
    case BLACK:
      return '#000000';
    case WHITE:
      return '#FFFFFF';
    case LIGHT_GRAY:
      return '#D3D3D3';
    case GRAY:
      return '#808080';
    case BROWN:
      return '#A52A2A';
    case DARK_GREEN:
      return '#006400';
    case GREEN:
      return '#008000';
    case LIME_GREEN:
      return '#32CD32';
    case YELLOW:
      return '#FFFF00';
    case LIGHT_YELLOW:
      return '#FFFFE0';
    case DARK_BLUE:
      return '#00008B';
    case BLUE:
      return '#0000FF';
    case LIGHT_BLUE:
      return '#87CEFA';
    case CYAN:
      return '#00FFFF';
    case MAGENTA:
      return '#FF00FF';
    case PURPLE:
      return '#800080';
    case DARK_RED:
      return '#8B0000';
    case RED:
      return '#FF0000';
    case LIGHT_RED:
      return '#DC143C';
    case ORANGE:
      return '#FFA500';
    default:
      return 'not found';
  }
}
export function getDefaultColors() {
  return [getHexForName(BLACK), getHexForName(WHITE), getHexForName(LIGHT_GRAY), getHexForName(GRAY), getHexForName(BROWN), getHexForName(DARK_GREEN), getHexForName(GREEN), getHexForName(LIME_GREEN), getHexForName(YELLOW), getHexForName(LIGHT_YELLOW), getHexForName(DARK_BLUE), getHexForName(BLUE), getHexForName(LIGHT_BLUE), getHexForName(CYAN), getHexForName(MAGENTA), getHexForName(PURPLE), getHexForName(DARK_RED), getHexForName(RED), getHexForName(LIGHT_RED), getHexForName(ORANGE)];
}
export function getEmptyConfigState() {
  return {
    editedAdaptableObject: null,
    wizardStartIndex: 0,
    wizardStatus: WizardStatus.None
  };
}
export function getDescriptionForDataType(dataType) {
  switch (dataType) {
    case 'String':
      return 'string';
    case 'Number':
      return 'number';
    case 'Date':
      return 'date';
  }
}
export function getPlaceholderForDataType(dataType) {
  switch (dataType) {
    case 'String':
      return 'Enter Value';
    case 'Number':
      return 'Enter Number';
    case 'Date':
      return 'Enter Date';
  }
}
export function getModalContainer(adaptableOptions, document) {
  let modalContainer;
  if (adaptableOptions.containerOptions.modalContainer) {
    // this has been set, so we use the property
    modalContainer = typeof adaptableOptions.containerOptions.modalContainer === 'string' ? document.getElementById(adaptableOptions.containerOptions.modalContainer) : adaptableOptions.containerOptions.modalContainer;
    if (modalContainer) {
      const modalContainerClassName = ' modal-container';
      if (!modalContainer.className.includes(modalContainerClassName)) {
        modalContainer.className += modalContainerClassName;
      }
    }
  }
  if (!modalContainer) {
    modalContainer = document.body;
  }
  return modalContainer;
}
export function IsEmptyStyle(style) {
  return !IsNotEmptyStyle(style);
}
export function IsNotEmptyStyle(style) {
  return style.BackColor != null || style.ForeColor != null || style.BorderColor != null || style.FontWeight != FontWeight.Normal || style.FontStyle != FontStyle.Normal || style.FontSize != null || StringExtensions.IsNotNullOrEmpty(style.ClassName);
}
export function getMessageTypeByStatusColour(statusColour) {
  switch (statusColour) {
    case StatusColour.Error:
      return 'Error';
    case StatusColour.Warn:
      return 'Warning';
    case StatusColour.Success:
      return 'Success';
    case StatusColour.Info:
      return 'Info';
  }
}
export function getButtonToneByMessageType(messageType) {
  switch (messageType) {
    case 'Info':
      return 'info';
    case 'Success':
      return 'success';
    case 'Warning':
      return 'warning';
    case 'Error':
      return 'error';
    default:
      return 'neutral';
  }
}
export function getGlyphByMessageType(messageType) {
  switch (messageType) {
    case 'Info':
      return 'info';
    case 'Success':
      return 'check';
    case 'Warning':
      return 'warning';
    case 'Error':
      return 'error';
  }
}
export function getColorByMessageType(messageType) {
  switch (messageType) {
    case 'Error':
      return 'var(--ab-color-error)';
    case 'Warning':
      return 'var(--ab-color-warn)';
    case 'Success':
      return 'var(--ab-color-success)';
    case 'Info':
      return 'var(--ab-color-info)';
  }
}
export function getStyleForStatusColour(statusColour) {
  let result;
  switch (statusColour) {
    case StatusColour.Info:
      result = {
        fill: 'var(--ab-color-info)'
      };
      break;
    case StatusColour.Success:
      result = {
        fill: 'var(--ab-color-success)'
      };
      break;
    case StatusColour.Warn:
      result = {
        fill: 'var(--ab-color-warn)'
      };
      break;
    case StatusColour.Error:
      result = {
        fill: 'var(--ab-color-error)'
      };
      break;
  }
  if (result) {
    result.color = result.fill;
  }
  return result;
}
export function getStyleForMessageType(messageType) {
  let result;
  switch (messageType) {
    case 'Info':
      result = {
        fill: 'var(--ab-color-info)'
      };
      break;
    case 'Success':
      result = {
        fill: 'var(--ab-color-success)'
      };
      break;
    case 'Warning':
      result = {
        fill: 'var(--ab-color-warn)'
      };
      break;
    case 'Error':
      result = {
        fill: 'var(--ab-color-error)'
      };
      break;
  }
  if (result) {
    result.color = result.fill;
  }
  return result;
}
export function getGlyphForStatusColour(statusColour) {
  switch (statusColour) {
    case StatusColour.Info:
      return 'info';
    case StatusColour.Success:
      return 'check';
    case StatusColour.Warn:
      return 'warning';
    case StatusColour.Error:
      return 'error';
  }
}
export function getGlyphForMessageType(messageType) {
  switch (messageType) {
    case 'Info':
      return 'info';
    case 'Success':
      return 'check';
    case 'Warning':
      return 'warning';
    case 'Error':
      return 'error';
  }
}
export function getButtonToneForMessageType(messageType) {
  switch (messageType) {
    case 'Info':
      return 'info';
    case 'Warning':
      return 'warning';
    case 'Error':
      return 'error';
    case 'Success':
      return 'success';
  }
}
export function getScheduleDescription(schedule) {
  if (schedule == null) {
    return '[No Schedule]';
  }
  let dateString;
  if (schedule.OneOffDate == null) {
    if (ArrayExtensions.ContainsItem(schedule.DaysOfWeek, 'Monday') && ArrayExtensions.ContainsItem(schedule.DaysOfWeek, 'Tuesday') && ArrayExtensions.ContainsItem(schedule.DaysOfWeek, 'Wednesday') && ArrayExtensions.ContainsItem(schedule.DaysOfWeek, 'Thursday') && ArrayExtensions.ContainsItem(schedule.DaysOfWeek, 'Friday')) {
      if (ArrayExtensions.ContainsItem(schedule.DaysOfWeek, 'Sunday') && ArrayExtensions.ContainsItem(schedule.DaysOfWeek, 'Saturday')) {
        dateString = 'Everyday';
      } else {
        dateString = 'Weekdays';
      }
    } else {
      const names = schedule.DaysOfWeek.sort().map(d => getShortenedDayString(d));
      dateString = ArrayExtensions.createCommaSeparatedString(names);
    }
  } else {
    dateString = new Date(schedule.OneOffDate).toDateString();
  }
  return `${dateString} @ ${addLeadingZero(schedule.Hour)}:${addLeadingZero(schedule.Minute)}`;
}
function getShortenedDayString(dayOfWeek) {
  switch (dayOfWeek) {
    case 'Sunday':
      return 'Sun';
    case 'Monday':
      return 'Mon';
    case 'Tuesday':
      return 'Tues';
    case 'Wednesday':
      return 'Weds';
    case 'Thursday':
      return 'Thurs';
    case 'Friday':
      return 'Fri';
    case 'Saturday':
      return 'Sat';
  }
}
export function getWeekDayByIndex(dayOfWeek) {
  switch (dayOfWeek) {
    case 0:
      return 'Sunday';
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
  }
}
function addLeadingZero(item) {
  item = item || 0;
  if (item < 10) {
    return `0${item && item.toString ? item.toString() : item}`;
  }
  return item.toString();
}
export function getMessageTypeFromAdaptableAlerts(adaptableAlerts) {
  if (adaptableAlerts.find(a => a.alertDefinition.MessageType == 'Error') != null) {
    return 'Error';
  }
  if (adaptableAlerts.find(a => a.alertDefinition.MessageType == 'Warning') != null) {
    return 'Warning';
  }
  if (adaptableAlerts.find(a => a.alertDefinition.MessageType == 'Success') != null) {
    return 'Success';
  }
  return 'Info';
}
export function getButtonColourForAdaptableAlerts(adaptableAlerts, messageTypeColor) {
  return ArrayExtensions.IsNotNullOrEmpty(adaptableAlerts) ? messageTypeColor : 'primary';
}
export function getButtonTextColourForArrayandMessageType(adaptableAlerts, messageType) {
  if (ArrayExtensions.IsNullOrEmpty(adaptableAlerts)) {
    return 'text-on-primary';
  }
  return this.getButtonTextColourForMessageType(messageType);
}
export function getButtonTextColourForMessageType(messageType) {
  switch (messageType) {
    case 'Info':
      return 'var( --ab-color-text-on-info)';
    case 'Success':
      return 'var( --ab-color-text-on-success)';
    case 'Warning':
      return 'var( --ab-color-text-on-warn)';
    case 'Error':
      return 'var( --ab-color-text-on-error)';
  }
}
function getNumericCSSVariableValue(stringValue, defaultValue) {
  var _a;
  const numericValue = typeof stringValue === 'string' ? +((_a = stringValue.match(/\d/g)) === null || _a === void 0 ? void 0 : _a.join('')) : stringValue;
  return typeof numericValue === 'number' ? numericValue : defaultValue;
}
export function getAdaptableToolPanelWidth() {
  return getNumericCSSVariableValue(getCSSVariableValue('--ab-cmp-toolpanel__width'), 200);
}
export function getSimpleButtonPaddingWidth() {
  return getNumericCSSVariableValue(getCSSVariableValue('--ab-space-1'), 4);
}
export function getCSSVariableValue(cssVariable) {
  if (!isBrowserDocumentAvailable()) {
    // possible during static/server-side rendering (should happen only in test cases)
    return undefined;
  }
  return getComputedStyle(document.documentElement).getPropertyValue(cssVariable);
}
export function setCSSVariableValue(cssVariable, value) {
  if (!isBrowserDocumentAvailable()) {
    // possible during static/server-side rendering (should happen only in test cases)
    return undefined;
  }
  document.documentElement.style.setProperty(cssVariable, value);
}
export function isBrowserDocumentAvailable() {
  return typeof window !== 'undefined' && typeof window.document !== 'undefined';
}
export const UIHelper = {
  getHexForName,
  getDefaultColors,
  getEmptyConfigState,
  getDescriptionForDataType,
  getPlaceHolderforDataType: getPlaceholderForDataType,
  getModalContainer,
  IsEmptyStyle,
  IsNotEmptyStyle,
  getMessageTypeByStatusColour,
  getGlyphByMessageType,
  getButtonToneByMessageType,
  getStyleForStatusColour,
  getGlyphForStatusColour,
  getButtonToneForMessageType,
  getScheduleDescription,
  getWeekDayByIndex,
  getColorByMessageType,
  getGlyphForMessageType,
  getStyleForMessageType,
  getMessageTypeFromAdaptableAlerts,
  getButtonColourForAdaptableAlerts,
  getButtonTextColourForArrayandMessageType,
  getButtonTextColourForMessageType,
  getCSSVariableValue,
  setCSSVariableValue,
  getAdaptableToolPanelWidth,
  isBrowserDocumentAvailable,
  getSimpleButtonPaddingWidth
};
export default UIHelper;