import React, { useState, useEffect, useRef } from 'react';
import SimpleButton from './SimpleButton';
import Panel from './Panel';
export const Accordion = ({
  title,
  children
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState('0px');
  const content = useRef(null);
  const observer = useRef(null);
  useEffect(() => {
    setHeight(isOpen && content.current ? `${content.current.scrollHeight}px` : '0px');
  }, [isOpen, content]);
  useEffect(() => {
    if (content.current) {
      observer.current = new MutationObserver(() => {
        var _a;
        setHeight(`${(_a = content.current) === null || _a === void 0 ? void 0 : _a.scrollHeight}px`);
      });
      observer.current.observe(content.current, {
        childList: true,
        subtree: true
      });
    }
    return () => {
      var _a;
      (_a = observer.current) === null || _a === void 0 ? void 0 : _a.disconnect();
    };
  }, []);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  return React.createElement(Panel, {
    className: "ab-Accordion"
  }, React.createElement(SimpleButton, {
    iconPosition: "end",
    icon: isOpen ? 'expand-all' : 'collapse-all',
    variant: "text",
    onClick: toggleAccordion,
    style: {
      width: '100%',
      textAlign: 'left'
    }
  }, title), React.createElement("div", {
    ref: content,
    style: {
      maxHeight: `${height}`,
      overflow: 'hidden',
      transition: 'max-height 0.6s ease'
    }
  }, children));
};
export default Accordion;