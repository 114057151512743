import { EMPTY_ARRAY } from '../../Utilities/Constants/GeneralConstants';
import AdaptableHelper from '../../Utilities/Helpers/AdaptableHelper';
import { changeIsSuspendInList, suspendAllInList, unsuspendAllInList } from './utils';
/**
 * @ReduxAction A Plus Minus Rule has been applied
 */
export const PLUS_MINUS_APPLY = 'PLUS_MINUS_APPLY';
/**
 * @ReduxAction A Plus Minus Rule has been added
 */
export const PLUS_MINUS_RULE_ADD = 'PLUS_MINUS_RULE_ADD';
/**
 * @ReduxAction A Plus Minus Rule has been edited
 */
export const PLUS_MINUS_RULE_EDIT = 'PLUS_MINUS_RULE_EDIT';
/**
 * @ReduxAction A Plus Minus Rule has been deleted
 */
export const PLUS_MINUS_RULE_DELETE = 'PLUS_MINUS_RULE_DELETE';
/**
 * @ReduxAction A Plus Minus Rule has been suspended
 */
export const PLUS_MINUS_RULE_SUSPEND = 'PLUS_MINUS_RULE_SUSPEND';
/**
 * @ReduxAction A Plus Minus Rule has been unsuspended
 */
export const PLUS_MINUS_RULE_UNSUSPEND = 'PLUS_MINUS_RULE_UNSUSPEND';
/**
 * @ReduxAction All Plus Minus Rules have been suspended
 */
export const PLUS_MINUS_RULE_SUSPEND_ALL = 'PLUS_MINUS_RULE_SUSPEND_ALL';
/**
 * @ReduxAction All Plus Minus Rules have been unsuspended
 */
export const PLUS_MINUS_RULE_UNSUSPEND_ALL = 'PLUS_MINUS_RULE_UNSUSPEND_ALL';
/**
 * @ReduxAction A Plus Minus Rule has been moved up
 */
export const PLUS_MINUS_RULE_MOVE_UP = 'PLUS_MINUS_RULE_MOVE_UP';
/**
 * @ReduxAction A Plus Minus Rule has been moved down
 */
export const PLUS_MINUS_RULE_MOVE_DOWN = 'PLUS_MINUS_RULE_MOVE_DOWN';
/**
 * @ReduxAction PlusMinus Module is ready
 */
export const PLUS_MINUS_READY = 'PLUS_MINUS_READY';
export const PlusMinusApply = cellUpdateRequests => ({
  type: PLUS_MINUS_APPLY,
  cellUpdateRequests
});
export const PlusMinusNudgeAdd = plusMinusNudge => ({
  type: PLUS_MINUS_RULE_ADD,
  plusMinusNudge: plusMinusNudge
});
export const PlusMinusNudgeEdit = plusMinusNudge => ({
  type: PLUS_MINUS_RULE_EDIT,
  plusMinusNudge: plusMinusNudge
});
export const PlusMinusNudgeDelete = plusMinusNudge => ({
  type: PLUS_MINUS_RULE_DELETE,
  plusMinusNudge: plusMinusNudge
});
export const PlusMinusNudgeSuspend = plusMinusNudge => ({
  type: PLUS_MINUS_RULE_SUSPEND,
  plusMinusNudge: plusMinusNudge
});
export const PlusMinusNudgeUnSuspend = plusMinusNudge => ({
  type: PLUS_MINUS_RULE_UNSUSPEND,
  plusMinusNudge: plusMinusNudge
});
export const PlusMinusNudgeSuspendAll = () => ({
  type: PLUS_MINUS_RULE_SUSPEND_ALL
});
export const PlusMinusNudgeUnSuspendAll = () => ({
  type: PLUS_MINUS_RULE_UNSUSPEND_ALL
});
export const PlusMinusMoveUp = plusMinusNudge => ({
  type: PLUS_MINUS_RULE_MOVE_UP,
  plusMinusNudge
});
export const PlusMinusMoveDown = plusMinusNudge => ({
  type: PLUS_MINUS_RULE_MOVE_DOWN,
  plusMinusNudge
});
export const PlusMinusReady = plusMinusState => ({
  type: PLUS_MINUS_READY,
  plusMinusState
});
const initialState = {
  PlusMinusNudges: EMPTY_ARRAY
};
export const PlusMinusReducer = (state = initialState, action) => {
  var _a, _b;
  let plusMinusNudges;
  switch (action.type) {
    case PLUS_MINUS_APPLY:
      //we apply logic in the middleware since it's an Api call
      return Object.assign({}, state);
    case PLUS_MINUS_RULE_ADD:
      {
        const actionPlusMinusNudge = action.plusMinusNudge;
        AdaptableHelper.addUuidAndSource(actionPlusMinusNudge);
        plusMinusNudges = [].concat(state.PlusMinusNudges);
        plusMinusNudges.push(actionPlusMinusNudge);
        return Object.assign(Object.assign({}, state), {
          PlusMinusNudges: plusMinusNudges
        });
      }
    case PLUS_MINUS_RULE_EDIT:
      {
        const actionPlusMinusNudge = action.plusMinusNudge;
        return Object.assign(Object.assign({}, state), {
          PlusMinusNudges: state.PlusMinusNudges.map(abObject => abObject.Uuid === actionPlusMinusNudge.Uuid ? actionPlusMinusNudge : abObject)
        });
      }
    case PLUS_MINUS_RULE_DELETE:
      {
        const actionPlusMinusNudge = action.plusMinusNudge;
        return Object.assign(Object.assign({}, state), {
          PlusMinusNudges: state.PlusMinusNudges.filter(abObject => abObject.Uuid !== actionPlusMinusNudge.Uuid)
        });
      }
    case PLUS_MINUS_RULE_SUSPEND_ALL:
      {
        return Object.assign(Object.assign({}, state), {
          PlusMinusNudges: suspendAllInList(state.PlusMinusNudges)
        });
      }
    case PLUS_MINUS_RULE_UNSUSPEND_ALL:
      {
        return Object.assign(Object.assign({}, state), {
          PlusMinusNudges: unsuspendAllInList(state.PlusMinusNudges)
        });
      }
    case PLUS_MINUS_RULE_SUSPEND:
      {
        return Object.assign(Object.assign({}, state), {
          PlusMinusNudges: changeIsSuspendInList(action.plusMinusNudge, state.PlusMinusNudges, true)
        });
      }
    case PLUS_MINUS_RULE_UNSUSPEND:
      {
        return Object.assign(Object.assign({}, state), {
          PlusMinusNudges: changeIsSuspendInList(action.plusMinusNudge, state.PlusMinusNudges, false)
        });
      }
    case PLUS_MINUS_RULE_MOVE_UP:
      {
        const actionPlusMinusNudge = action.plusMinusNudge;
        const plusMinusNudges = [...((_a = state.PlusMinusNudges) !== null && _a !== void 0 ? _a : [])];
        const index = plusMinusNudges.findIndex(plusMinus => plusMinus.Uuid === actionPlusMinusNudge.Uuid);
        let swapIndex = index - 1;
        if (swapIndex < 0) {
          swapIndex = plusMinusNudges.length - 1;
        }
        const temp = plusMinusNudges[index];
        plusMinusNudges[index] = plusMinusNudges[swapIndex];
        plusMinusNudges[swapIndex] = temp;
        return Object.assign(Object.assign({}, state), {
          PlusMinusNudges: plusMinusNudges
        });
      }
    case PLUS_MINUS_RULE_MOVE_DOWN:
      {
        const actionPlusMinusNudge = action.plusMinusNudge;
        const plusMinusNudges = [...((_b = state.PlusMinusNudges) !== null && _b !== void 0 ? _b : [])];
        const index = plusMinusNudges.findIndex(plusMinus => plusMinus.Uuid === actionPlusMinusNudge.Uuid);
        let swapIndex = index + 1;
        if (swapIndex >= plusMinusNudges.length) {
          swapIndex = 0;
        }
        const temp = plusMinusNudges[index];
        plusMinusNudges[index] = plusMinusNudges[swapIndex];
        plusMinusNudges[swapIndex] = temp;
        return Object.assign(Object.assign({}, state), {
          PlusMinusNudges: plusMinusNudges
        });
      }
    default:
      return state;
  }
};