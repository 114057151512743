import { __rest } from "tslib";
import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import batchUpdate from '../utils/batchUpdate';
import usePrevious from '../utils/usePrevious';
export const useRefresh = () => {
  const [x, update] = useState(0);
  return () => {
    update(x + 1);
  };
};
const Overlay = React.forwardRef((props, ref) => {
  const {
      visible
    } = props,
    domProps = __rest(props, ["visible"]);
  const domRef = useRef(null);
  const setRef = node => {
    domRef.current = node;
    if (ref && typeof ref === 'function') {
      ref(node);
    }
  };
  const [opacity, setOpacity] = useState(0);
  const transitionInProgressRef = useRef(false);
  const prevVisible = usePrevious(visible, visible);
  const prevOpacity = usePrevious(opacity, opacity);
  const refresh = useRefresh();
  useEffect(() => {
    batchUpdate(() => {
      transitionInProgressRef.current = true;
      setOpacity(props.visible ? 1 : 0);
    }).commit();
  }, [visible]);
  if (prevVisible && !visible) {
    transitionInProgressRef.current = true;
  }
  const onTransitionEnd = () => {
    var _a;
    transitionInProgressRef.current = false;
    refresh();
    (_a = props === null || props === void 0 ? void 0 : props.onTransitionEnd) === null || _a === void 0 ? void 0 : _a.call(props);
  };
  const renderTime = Date.now();
  const renderTimeRef = useRef(renderTime);
  renderTimeRef.current = renderTime;
  useEffect(() => {
    let timeoutId;
    if (prevOpacity && !opacity) {
      timeoutId = setTimeout(() => {
        if (renderTimeRef.current != renderTime) {
          //we had other renders, so dont do anything - bail out
          return;
        }
        if (!visible) {
          onTransitionEnd();
        }
      }, 1500);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [opacity, prevOpacity, renderTime]);
  const transitionInProgress = transitionInProgressRef.current;
  if (!visible && !transitionInProgress) {
    return null;
  }
  return React.createElement("div", Object.assign({}, domProps, {
    ref: setRef,
    style: Object.assign(Object.assign({}, props.style), {
      opacity
    }),
    onTransitionEnd: onTransitionEnd
  }));
});
export default Overlay;