import * as React from 'react';
import { AdaptablePopover } from '../AdaptablePopover';
import { DataSource, InfiniteTable } from '../../components/InfiniteTable';
import CheckIcon from '../../components/icons/check';
import UIHelper from '../UIHelper';
import Panel from '../../components/Panel';
const columnsMap = new Map([['InitialValue', {
  field: 'InitialValue',
  defaultFlex: 1,
  header: 'Current',
  align: 'center',
  renderMenuIcon: false
}], ['ComputedValue', {
  field: 'ComputedValue',
  defaultFlex: 1,
  header: 'New',
  align: 'center',
  renderMenuIcon: false
}], ['ValidInfo', {
  field: 'ValidInfo',
  defaultWidth: 80,
  header: 'Valid',
  align: 'center',
  renderMenuIcon: false
}]]);
const tableDOMProps = {
  style: {
    flex: 1
  }
};
export class PreviewResultsPanel extends React.Component {
  render() {
    let previewHeader = this.props.showHeader && this.props.previewInfo != null ? 'Preview Results: ' + (this.props.selectedColumn ? this.props.selectedColumn.friendlyName : '') : '';
    let successColor = UIHelper.getColorByMessageType('Success');
    const dataSource = this.props.previewInfo.previewResults.map((previewResult, index) => {
      return {
        Id: index,
        InitialValue: previewResult.initialValue,
        ComputedValue: previewResult.computedValue,
        ValidInfo: previewResult.validationRules.length > 0 ? React.createElement(React.Fragment, null, this.props.previewInfo.previewValidationSummary.validationResult != 'None' && React.createElement(AdaptablePopover, {
          showEvent: "mouseenter",
          hideEvent: "mouseleave",
          headerText: 'Validation Error',
          bodyText: [this.getValidationErrorMessage(previewResult.validationRules)],
          MessageType: 'Error'
        })) : React.createElement(React.Fragment, null, ' ', React.createElement(CheckIcon, {
          style: {
            color: successColor,
            fill: 'currentColor'
          }
        }))
      };
    });
    return this.props.showPanel ? React.createElement(Panel, {
      header: previewHeader,
      style: {
        flex: 1
      },
      bodyProps: {
        style: {
          border: 0,
          padding: 0,
          display: 'flex',
          flexFlow: 'column',
          minWidth: '17rem',
          minHeight: '20rem'
        }
      }
    }, React.createElement(DataSource, {
      data: dataSource,
      primaryKey: "Id"
    }, React.createElement(InfiniteTable, {
      sortable: false,
      domProps: tableDOMProps,
      columns: columnsMap
    }))) : null;
  }
  getValidationErrorMessage(alertDefinitions) {
    let validationService = this.props.api.internalApi.getValidationService();
    let returnString = [];
    for (let alertDefinition of alertDefinitions) {
      let expressionDescription = alertDefinition.Rule.BooleanExpression ? ' when ' + alertDefinition.Rule.BooleanExpression : '';
      returnString.push(validationService.createValidationDescription(alertDefinition) + expressionDescription);
    }
    return returnString.join('\n');
  }
}