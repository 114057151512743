import AdaptableHelper from '../../Utilities/Helpers/AdaptableHelper';
import { EMPTY_ARRAY } from '../../Utilities/Constants/GeneralConstants';
/**
 * @ReduxAction A Note has been added
 */
export const NOTE_ADD = 'NOTE_ADD';
/**
 * @ReduxAction A Note has been edited
 */
export const NOTE_EDIT = 'NOTE_EDIT';
/**
 * @ReduxAction A Note has been deleted
 */
export const NOTE_DELETE = 'NOTE_DELETE';
/**
 * @ReduxAction Note Module is ready
 */
export const NOTE_READY = 'NOTE_READY';
export const NoteAdd = note => ({
  type: NOTE_ADD,
  adaptableNote: note
});
export const NoteEdit = note => ({
  type: NOTE_EDIT,
  adaptableNote: note
});
export const NoteDelete = note => ({
  type: NOTE_DELETE,
  adaptableNote: note
});
export const NoteReady = note => ({
  type: NOTE_READY,
  noteState: note
});
export const GetAllNotesSelector = state => state.Notes;
export const GetNoteSelector = (state, address) => {
  var _a;
  if (!address) {
    return null;
  }
  return ((_a = state === null || state === void 0 ? void 0 : state.Notes) !== null && _a !== void 0 ? _a : []).find(note => {
    if (note.PrimaryKeyValue === address.PrimaryKeyValue && note.ColumnId === address.ColumnId) {
      // happy check
      return true;
    }
    // Primary keys retreived from the grid dom are always strings, so we must also consider them strings
    if (typeof address.PrimaryKeyValue === 'number' && typeof note.PrimaryKeyValue === 'string' || typeof address.PrimaryKeyValue === 'string' && typeof note.PrimaryKeyValue === 'number') {
      return note.PrimaryKeyValue.toString() === address.PrimaryKeyValue.toString() && note.ColumnId === address.ColumnId;
    }
    return false;
  });
};
const initialState = {
  Notes: EMPTY_ARRAY
};
export const NoteReducer = (state = initialState, action) => {
  let adaptableNotes;
  switch (action.type) {
    case NOTE_ADD:
      {
        const note = action.adaptableNote;
        AdaptableHelper.addUuidAndSource(note);
        adaptableNotes = [...state.Notes, note];
        return Object.assign(Object.assign({}, state), {
          Notes: adaptableNotes
        });
      }
    case NOTE_EDIT:
      {
        const note = action.adaptableNote;
        return Object.assign(Object.assign({}, state), {
          Notes: state.Notes.map(abObject => abObject.Uuid === note.Uuid ? note : abObject)
        });
      }
    case NOTE_DELETE:
      {
        const note = action.adaptableNote;
        return Object.assign(Object.assign({}, state), {
          Notes: state.Notes.filter(abObject => abObject.Uuid !== note.Uuid)
        });
      }
    default:
      return state;
  }
};