import { ApiBase } from './ApiBase';
import Emitter from '../../Utilities/Emitter';
export class EventApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.on = (eventName, callback) => {
      let result;
      if (eventName === 'AdaptableReady') {
        this.emitter.onIncludeFiredOnce(eventName).then(callback);
        return () => {};
      } else {
        result = this.emitter.on(eventName, callback);
      }
      return result;
    };
    this.off = (eventName, callback) => this.emitter.off(eventName, callback);
    this.emit = (eventName, data) => this.emitter.emit(eventName, data);
    this.emitSync = (eventName, data) => this.emitter.emitSync(eventName, data);
    this.emitter = new Emitter();
  }
  destroy() {
    super.destroy();
    this.emitSync('AdaptableDestroy');
    this.emitter.destroy();
  }
}