import * as React from 'react';
import ObjectFactory from '../../../Utilities/ObjectFactory';
import { AdaptablePopupAlert } from './AdaptablePopupAlert';
import { toast } from '../../../components/Toastify';
export const showToast = props => {
  // we're doing this hack and not simply using props.api in order not to have a memory
  // leak where the api is still kept around in memory by the toaster
  let api = props.api;
  const off = api.eventApi.on('AdaptableDestroy', () => {
    toast.dismiss();
    api = null;
  });
  const adaptableOptions = props.api.optionsApi.getAdaptableOptions();
  const toastProps = ObjectFactory.CreateToastOptions(adaptableOptions.notificationsOptions, {
    containerId: `Toastify-${adaptableOptions.adaptableId}`,
    onClose: () => {
      off();
    }
  }, {
    duration: props.adaptableAlert.alertDefinition.AlertProperties.NotificationDuration
  });
  const content = React.createElement(AdaptablePopupAlert, {
    headless: true,
    adaptableAlert: props.adaptableAlert,
    onClose: () => {
      toast.dismiss(toastId);
    }
  });
  let toastId;
  switch (props.adaptableAlert.alertDefinition.MessageType) {
    case 'Info':
      toastId = toast.info(content, toastProps);
      break;
    case 'Error':
      toastId = toast.error(content, toastProps);
      break;
    case 'Success':
      toastId = toast.success(content, toastProps);
      break;
    case 'Warning':
      toastId = toast.warn(content, toastProps);
      break;
  }
};