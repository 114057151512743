import * as QuickSearchRedux from '../../Redux/ActionsReducers/QuickSearchRedux';
import * as SystemRedux from '../../Redux/ActionsReducers/SystemRedux';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import { ApiBase } from './ApiBase';
export class QuickSearchApiImpl extends ApiBase {
  getQuickSearchState() {
    return this.getAdaptableState().QuickSearch;
  }
  runQuickSearch(quickSearchText) {
    this.dispatchAction(QuickSearchRedux.QuickSearchRun(quickSearchText));
  }
  clearQuickSearch() {
    this.dispatchAction(QuickSearchRedux.QuickSearchRun(''));
  }
  getQuickSearchValue() {
    return this.getQuickSearchState().QuickSearchText;
  }
  getQuickSearchStyle() {
    return this.getQuickSearchState().Style;
  }
  setQuickSearchStyle(style) {
    this.dispatchAction(QuickSearchRedux.QuickSearchSetStyle(style));
  }
  openQuickSearchSettingsPanel() {
    this.showModulePopup(ModuleConstants.QuickSearchModuleId);
  }
  showFloatingQuickSearch() {
    this.dispatchAction(SystemRedux.SystemQuickSearchFloatingVisibility(true));
  }
  hideFloatingQuickSearch() {
    this.dispatchAction(SystemRedux.SystemQuickSearchFloatingVisibility(false));
  }
}