import * as React from 'react';
import { connect } from 'react-redux';
import { Flex } from 'rebass';
import { Select } from '../../components/Select';
import join from '../../components/utils/join';
import { ExportDestination } from '../../PredefinedConfig/Common/Enums';
import * as ExportRedux from '../../Redux/ActionsReducers/ExportRedux';
import * as PopupRedux from '../../Redux/ActionsReducers/PopupRedux';
import * as GeneralConstants from '../../Utilities/Constants/GeneralConstants';
import { VISUAL_DATA_REPORT } from '../../Utilities/Constants/GeneralConstants';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import ArrayExtensions from '../../Utilities/Extensions/ArrayExtensions';
import AdaptableHelper from '../../Utilities/Helpers/AdaptableHelper';
import ObjectFactory from '../../Utilities/ObjectFactory';
import { ButtonDelete } from '../Components/Buttons/ButtonDelete';
import { ButtonEdit } from '../Components/Buttons/ButtonEdit';
import { ButtonExport } from '../Components/Buttons/ButtonExport';
import { ButtonNew } from '../Components/Buttons/ButtonNew';
import { ButtonSchedule } from '../Components/Buttons/ButtonSchedule';
import { SELECT_DESTINATION_STRING, SELECT_REPORT_STRING } from './constants';
class ExportViewPanelComponent extends React.Component {
  render() {
    let currentReport = this.props.api.exportApi.getReportByName(this.props.CurrentReport);
    let currentReportId = this.props.CurrentReport;
    const allReportNames = this.getAllReportNames();
    const reportItems = allReportNames.map(report => {
      return {
        label: report,
        value: report,
        onClick: () => this.onSelectedReportChanged(report)
      };
    });
    if (currentReport && !allReportNames.includes(currentReport.Name)) {
      // current report is not available
      currentReport = undefined;
      currentReportId = undefined;
    }
    let currentDestination = this.props.api.exportApi.getDestinationByName(this.props.CurrentDestination);
    let currentDestinationId = this.props.CurrentDestination;
    const destinationItems = [...this.props.api.exportApi.getAvailableExportDestinations().map(destination => ({
      label: destination,
      onClick: () => this.onSelectedDestinationChanged(destination),
      value: destination,
      isDisabled: !this.props.api.exportApi.internalApi.isDestinationEnabled(destination)
    })), ...this.props.api.exportApi.getCustomDestinations().map(destination => ({
      label: destination.name,
      onClick: () => this.onSelectedDestinationChanged(destination.name),
      value: destination.name,
      isDisabled: false
    }))];
    if ((currentReport === null || currentReport === void 0 ? void 0 : currentReport.Name) === VISUAL_DATA_REPORT) {
      destinationItems.forEach(item => item.isDisabled = item.label === ExportDestination.Excel ? false : true);
    }
    if (currentDestination && !destinationItems.filter(destinationItem => !destinationItem.isDisabled).map(destinationItem => destinationItem.label).includes(this.props.CurrentDestination)) {
      // current destination is not available
      currentDestination = undefined;
      currentDestinationId = undefined;
    }
    let accessLevel = AdaptableHelper.getAccessLevelForObject(currentReport, this.props.accessLevel);
    let deleteMessage = "Are you sure you want to delete '";
    if (currentReport != null) {
      deleteMessage = deleteMessage + currentReport.Name + "'?";
    }
    const onApplyExport = () => {
      this.props.onApplyExport(currentReport, this.isCustomDestination(currentDestination) ? currentDestination.name : currentDestination);
    };
    const elementType = this.props.viewType === 'Toolbar' ? 'DashboardToolbar' : 'ToolPanel';
    return React.createElement(Flex, {
      style: {
        gap: 5
      },
      flexDirection: "row",
      className: `ab-${elementType}__Export__wrap`,
      flexWrap: this.props.viewType === 'ToolPanel' ? 'wrap' : 'nowrap'
    }, React.createElement(Flex, {
      flex: 1,
      minWidth: 150
    }, React.createElement(Select, {
      style: {
        width: '100%'
      },
      placeholder: SELECT_REPORT_STRING,
      disabled: allReportNames.length == 0,
      className: `ab-${elementType}__Export__select`,
      options: reportItems,
      onChange: report => this.onSelectedReportChanged(report),
      value: currentReportId,
      isClearable: true
    })), React.createElement(Flex, {
      flex: 1,
      minWidth: 150
    }, React.createElement(Select, {
      style: {
        width: '100%'
      },
      placeholder: SELECT_DESTINATION_STRING,
      disabled: currentReportId === SELECT_REPORT_STRING,
      options: destinationItems,
      value: currentDestinationId,
      className: `ab-${elementType}__Export__select`,
      onChange: destination => this.onSelectedDestinationChanged(destination)
    })), React.createElement(Flex, {
      className: join(accessLevel == 'ReadOnly' ? GeneralConstants.READ_ONLY_STYLE : '', `ab-${elementType}__Export__controls`),
      style: {
        width: '100%'
      }
    }, React.createElement(ButtonExport, {
      onClick: () => onApplyExport(),
      tooltip: "Export Report",
      className: `ab-${elementType}__Export__export`,
      disabled: currentReport == null || currentDestination == null
    }), React.createElement(ButtonEdit, {
      onClick: () => this.props.onEditReport(this.props.moduleInfo.Popup),
      tooltip: "Edit Report",
      className: `ab-${elementType}__Export__edit`,
      disabled: currentReport == null || this.props.api.exportApi.isExternalReport(currentReport),
      accessLevel: accessLevel
    }), React.createElement(ButtonNew, {
      variant: "text",
      className: `ab-${elementType}__Export__new`,
      tone: "neutral",
      children: null,
      onClick: () => this.props.onNewReport(this.props.moduleInfo.Popup),
      tooltip: "Create New Report",
      accessLevel: accessLevel
    }), React.createElement(ButtonDelete, {
      tooltip: "Delete Report",
      className: `ab-${elementType}__Export__delete`,
      disabled: currentReport == null,
      ConfirmAction: ExportRedux.ReportDelete(currentReport),
      ConfirmationMsg: deleteMessage,
      ConfirmationTitle: 'Delete Report',
      accessLevel: accessLevel
    }), this.props.api.entitlementApi.isModuleFullEntitlement('Schedule') && React.createElement(ButtonSchedule, {
      marginLeft: 1,
      className: `ab-${elementType}__Export__schedule`,
      onClick: () => this.onNewReportSchedule(),
      tooltip: "Schedule",
      disabled: currentReport == null,
      accessLevel: this.props.accessLevel
    })));
  }
  onSelectedReportChanged(reportName) {
    this.props.onSelectReport(reportName);
  }
  onSelectedDestinationChanged(destination) {
    this.props.onSelectDestination(destination);
  }
  getAllReportNames() {
    const reportNames = [...this.props.api.exportApi.getAvailableSystemReports(), ...this.props.UserReports.map(r => r.Name)];
    const externalReports = this.props.api.exportApi.getExternalReports();
    if (ArrayExtensions.IsNotNullOrEmpty(externalReports)) {
      reportNames.push(...externalReports.map(r => r.name));
    }
    return reportNames;
  }
  isCustomDestination(destination) {
    return !!(destination === null || destination === void 0 ? void 0 : destination.name);
    // && typeof (destination as CustomDestination)?.onExport === 'function'
  }
  onNewReportSchedule() {
    let reportSchedule = ObjectFactory.CreateReportSchedule(this.props.CurrentReport);
    this.props.onNewReportSchedule(reportSchedule);
  }
}
function mapStateToProps(state) {
  return {
    CurrentReport: state.Export.CurrentReport,
    CurrentDestination: state.Export.CurrentDestination,
    UserReports: state.Export.Reports
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onApplyExport: (report, exportDestination) => dispatch(ExportRedux.ExportApply(report, exportDestination)),
    onSelectReport: Report => dispatch(ExportRedux.ReportSelect(Report)),
    onSelectDestination: Destination => dispatch(ExportRedux.DestinationSelect(Destination)),
    onNewReport: popup => dispatch(PopupRedux.PopupShowScreen(ModuleConstants.ExportModuleId, popup, {
      action: 'New',
      source: 'Toolbar'
    })),
    onEditReport: popup => dispatch(PopupRedux.PopupShowScreen(ModuleConstants.ExportModuleId, popup, {
      action: 'Edit',
      source: 'Toolbar'
    })),
    onNewReportSchedule: reportSchedule => dispatch(PopupRedux.PopupShowScreen(ModuleConstants.ScheduleModuleId, 'SchedulePopup', {
      action: 'New',
      source: 'Toolbar',
      value: reportSchedule
    }))
  };
}
export let ExportViewPanelControl = connect(mapStateToProps, mapDispatchToProps)(ExportViewPanelComponent);