import { ApiBase } from './ApiBase';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import * as ToolPanelRedux from '../../Redux/ActionsReducers/ToolPanelRedux';
import { ToolPanelSetModuleButtons } from '../../Redux/ActionsReducers/ToolPanelRedux';
export class ToolPanelApiImpl extends ApiBase {
  getToolPanelState() {
    return this.getAdaptableState().ToolPanel;
  }
  showToolPanelPopup() {
    this.showModulePopup(ModuleConstants.ToolPanelModuleId);
  }
  openAdapTableToolPanel() {
    const agGridApi = this.getAdaptableApi().agGridApi;
    if (agGridApi) {
      agGridApi.openToolPanel('adaptable');
    }
  }
  closeAdapTableToolPanel() {
    const agGridApi = this.getAdaptableApi().agGridApi;
    if (agGridApi) {
      agGridApi.closeToolPanel();
    }
  }
  getCustomToolPanels() {
    var _a;
    return (_a = this.getToolPanelOptions().customToolPanels) !== null && _a !== void 0 ? _a : [];
  }
  getCustomToolPanelButtons() {
    var _a;
    return (_a = this.getToolPanelOptions().customButtons) !== null && _a !== void 0 ? _a : [];
  }
  getCustomToolPanelByName(name) {
    return this.getCustomToolPanels().find(customToolPanel => customToolPanel.name === name);
  }
  setAdaptableToolPanelVisibilityMode(adaptableToolPanel, visibilityMode) {
    if (visibilityMode === 'expanded') {
      this.setExpandedToolPanelVisibility(adaptableToolPanel);
    } else {
      this.setCollapsedToolPanelVisibility(adaptableToolPanel);
    }
  }
  setCustomToolPanelVisibilityMode(customToolPanelName, visibilityMode) {
    if (visibilityMode === 'expanded') {
      this.setExpandedToolPanelVisibility(customToolPanelName);
    } else {
      this.setCollapsedToolPanelVisibility(customToolPanelName);
    }
  }
  setModuleButtons(moduleButtons) {
    this.dispatchAction(ToolPanelSetModuleButtons(moduleButtons));
  }
  getModuleButtons() {
    var _a, _b;
    return (_b = (_a = this.getToolPanelState()) === null || _a === void 0 ? void 0 : _a.ModuleButtons) !== null && _b !== void 0 ? _b : [];
  }
  setExpandedToolPanelVisibility(toolPanelName) {
    this.dispatchAction(ToolPanelRedux.ToolPanelExpandToolPanel(toolPanelName));
  }
  setCollapsedToolPanelVisibility(toolPanelName) {
    this.dispatchAction(ToolPanelRedux.ToolPanelCollapseToolPanel(toolPanelName));
  }
}