import * as React from 'react';
import { Select } from '../../components/Select';
import SimpleButton from '../../components/SimpleButton';
import StringExtensions from '../../Utilities/Extensions/StringExtensions';
import { useAdaptable } from '../AdaptableContext';
const isCustomDestination = destination => {
  return !!destination.name;
};
export const ExportSelector = () => {
  const adaptable = useAdaptable();
  // reports
  const handleReportChange = report => {
    adaptable.api.exportApi.selectReport(report);
  };
  const allReports = adaptable.api.exportApi.getReports();
  const allReportNames = allReports.map(report => report.Name);
  const reportItems = allReportNames.map(report => {
    return {
      label: report,
      value: report
    };
  });
  const currentReport = adaptable.api.exportApi.getCurrentReport();
  const currentReportId = StringExtensions.IsNullOrEmpty(currentReport === null || currentReport === void 0 ? void 0 : currentReport.Name) ? currentReport : currentReport.Name;
  // destinations
  const handleDestinationChange = destination => {
    adaptable.api.exportApi.selectDestination(destination);
  };
  const destinationItems = [...adaptable.api.exportApi.getAvailableExportDestinations().map(destination => ({
    label: destination,
    value: destination,
    isDisabled: !adaptable.api.exportApi.internalApi.isDestinationEnabled(destination)
  })), ...adaptable.api.exportApi.getCustomDestinations().map(destination => ({
    label: destination.name,
    value: destination.name
  }))];
  const currentDestination = adaptable.api.exportApi.getCurrentDestination();
  let currentDestinationId = undefined;
  if (typeof currentDestination === 'string') {
    currentDestinationId = currentDestination;
  } else if (currentDestination && 'name' in currentDestination) {
    currentDestinationId = currentDestination.name;
  }
  // export
  const handleExport = () => {
    if (isCustomDestination(currentDestination)) {
      adaptable.api.exportApi.runReport(currentReport.Name, currentDestination.name);
    } else {
      adaptable.api.exportApi.runReport(currentReport.Name, currentDestination);
    }
  };
  return React.createElement(React.Fragment, null, React.createElement(Select, {
    size: "small",
    isClearable: true,
    placeholder: 'Select Report',
    value: currentReportId,
    options: reportItems,
    onChange: handleReportChange
  }), React.createElement(Select, {
    size: "small",
    isClearable: true,
    placeholder: 'Select Destination',
    value: currentDestinationId,
    options: destinationItems,
    onChange: destination => {
      if (destination) {
        handleDestinationChange(destination);
      } else {
        handleDestinationChange(null);
      }
    }
  }), React.createElement(SimpleButton, {
    disabled: !currentDestinationId || !currentReportId,
    iconSize: 15,
    icon: "export",
    variant: "text",
    onClick: handleExport
  }));
};