import * as React from 'react';
import { Flex } from 'rebass';
import { PopupPanel } from '../Components/Popups/AdaptablePopup/PopupPanel';
import { ColumnInfo } from './ColumnInfo';
export const ColumnInfoPopup = props => {
  var _a, _b;
  return React.createElement(PopupPanel, {
    headerText: 'Column Info',
    glyphicon: 'info'
  }, React.createElement(Flex, {
    flexDirection: "column",
    flex: 1,
    height: "100%"
  }, React.createElement(ColumnInfo, {
    selectedColumnId: (_b = (_a = props.popupParams) === null || _a === void 0 ? void 0 : _a.column) === null || _b === void 0 ? void 0 : _b.columnId
  })));
};