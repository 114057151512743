import * as React from 'react';
import { Flex } from 'rebass';
import { baseClassName } from '.';
import { CheckBox } from '../CheckBox';
import { AdaptableFormControlTextClear } from '../../View/Components/Forms/AdaptableFormControlTextClear';
import FormLayout, { FormRow } from '../FormLayout';
import EditorButton from './EditorButton';
import Input from '../Input';
import { isValueValidDate } from '../../Utilities/Helpers/DateHelper';
import { useAdaptable } from '../../View/AdaptableContext';
import AdaptableInput from '../../View/Components/AdaptableInput';
export const DataTableEditor = ({
  fields,
  dataFormatter,
  data: initialData,
  type = 'column',
  labels
}) => {
  var _a, _b;
  const adaptable = useAdaptable();
  const [data, setData] = React.useState(initialData);
  const [showColumnIds, setShowValues] = React.useState(false);
  const [searchInputValue, setSearchInputValue] = React.useState('');
  const getColValue = fieldValue => {
    return adaptable.api.internalApi.getValueUsingField(data, fieldValue);
  };
  const updateColValue = (rowData, fielValue, newValue) => {
    const updatedRowData = adaptable.api.internalApi.setValueUsingField(rowData, fielValue, newValue);
    return Object.assign({}, updatedRowData);
  };
  const getColDateValue = fieldValue => {
    const colValue = getColValue(fieldValue);
    return colValue && isValueValidDate(colValue) ? new Date(colValue).toISOString().substr(0, 10) : '';
  };
  const hasFriendlyNames = React.useMemo(() => {
    return fields.some(field => field.label !== field.value);
  }, []);
  return React.createElement(Flex, {
    flexDirection: "column",
    "data-name": `expression-${type}-picker`,
    style: {
      height: '100%'
    }
  }, React.createElement(Flex, {
    className: `${baseClassName}__${type}-list`,
    flexDirection: "column",
    alignItems: "start",
    style: {
      marginTop: 2
    },
    mb: 2
  }, hasFriendlyNames && React.createElement(CheckBox, {
    checked: showColumnIds,
    onChange: checked => setShowValues(checked),
    style: {
      float: 'right',
      margin: 0,
      paddingTop: 'var(--ab-space-1)',
      paddingBottom: 'var(--ab-space-1)'
    }
  }, (_a = labels === null || labels === void 0 ? void 0 : labels.showIds) !== null && _a !== void 0 ? _a : 'Show Column IDs'), React.createElement(AdaptableFormControlTextClear, {
    value: searchInputValue,
    OnTextChange: setSearchInputValue,
    placeholder: (_b = labels === null || labels === void 0 ? void 0 : labels.filterPlaceholder) !== null && _b !== void 0 ? _b : 'Filter columns...',
    style: {
      flex: 1,
      marginBottom: 3
    }
  })), React.createElement(FormLayout, {
    className: "ab-ExpressionEditor__columns",
    gridColumnGap: "var(--ab-space-1)",
    gridRowGap: "var(--ab-space-1)",
    sizes: ['auto', '1fr'],
    style: {
      alignItems: 'stretch',
      overflow: 'auto'
    }
  }, fields.filter(field => {
    var _a, _b;
    if (!searchInputValue) {
      return true;
    }
    return field.label.includes(searchInputValue) || ((_b = (_a = field.value) === null || _a === void 0 ? void 0 : _a.includes) === null || _b === void 0 ? void 0 : _b.call(_a, searchInputValue));
  }).map(field => {
    var _a, _b, _c;
    const label = showColumnIds ? `[${field.value}]` : field.label;
    return React.createElement(FormRow, {
      key: field.label,
      label: React.createElement(EditorButton, {
        width: "100%",
        height: "100%",
        style: {
          background: 'var(--ab-color-primary)',
          cursor: 'grab',
          marginRight: 'var(--ab-space-1)'
        },
        data: dataFormatter(field.value),
        "data-name": type,
        "data-value": field.value,
        icon: "drag"
      }, React.createElement(Flex, {
        flexDirection: "column",
        alignItems: "start"
      }, label))
    }, field.dataType === 'Number' ? React.createElement(Input, {
      type: "number",
      "data-name": `${type}-input`,
      "data-value": field.value,
      value: (_a = getColValue(field.value)) !== null && _a !== void 0 ? _a : '',
      onChange: e => setData(updateColValue(data, field.value, Number(e.target.value))),
      width: "100%",
      disabled: field.readOnly
    }) : field.dataType === 'String' ? React.createElement(Input, {
      type: "text",
      "data-name": `${type}-input`,
      "data-value": field.value,
      value: (_b = getColValue(field.value)) !== null && _b !== void 0 ? _b : '',
      onChange: e => setData(updateColValue(data, field.value, e.target.value)),
      width: "100%",
      disabled: field.readOnly
    }) : field.dataType === 'Date' ? React.createElement(AdaptableInput, {
      type: "date",
      "data-name": `${type}-input`,
      "data-value": field.value,
      value: (_c = getColDateValue(field.value)) !== null && _c !== void 0 ? _c : '',
      onChange: e => {
        setData(updateColValue(data, field.value, new Date(e.target.value)));
      },
      style: {
        width: '100%'
      },
      disabled: field.readOnly
    }) : field.dataType === 'Boolean' ? React.createElement(CheckBox, {
      "data-name": `${type}-input`,
      "data-value": field.value,
      checked: Boolean(getColValue(field.value)),
      onChange: checked => setData(updateColValue(data, field.value, checked)),
      disabled: field.readOnly
    }) : null);
  })));
};