import { ApiBase } from './ApiBase';
import ArrayExtensions from '../../Utilities/Extensions/ArrayExtensions';
import StringExtensions from '../../Utilities/Extensions/StringExtensions';
import { PredicateInternalApi } from '../Internal/PredicateInternalApi';
import { SystemPredicateDefs } from '../../PredefinedConfig/Common/AdaptablePredicate';
export class PredicateApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.internalApi = new PredicateInternalApi(adaptable);
  }
  getPredicateDefs() {
    return this.internalApi.mergeSystemAndCustomPredicates(this.getSystemPredicateDefs(), this.getCustomPredicateDefs());
  }
  getSystemPredicateDefs() {
    return SystemPredicateDefs;
  }
  getSystemPredicateDefsByModuleScope(moduleScope) {
    return SystemPredicateDefs.filter(p => p.moduleScope.includes(moduleScope));
  }
  getCustomPredicateDefs() {
    return this.getPredicateOptions().customPredicateDefs;
  }
  getPredicateDefsByModuleScope(moduleScope) {
    return this.getPredicateDefs().filter(p => p.moduleScope.includes(moduleScope));
  }
  getPredicateDefById(predicateId) {
    return this.getPredicateDefs().find(predicateDef => predicateDef.id === predicateId);
  }
  getSystemPredicateDefById(predicateId) {
    return this.getSystemPredicateDefs().find(predicateDef => predicateDef.id === predicateId);
  }
  getCustomPredicateDefById(predicateId) {
    return this.getCustomPredicateDefs().find(predicateDef => predicateDef.id === predicateId);
  }
  predicateToString(predicate) {
    const predicateDef = this.getPredicateDefById(predicate.PredicateId);
    if (!predicateDef) {
      this.logWarn('Cannot find Predicate with Id:' + predicate.PredicateId);
      return '[Predicate Not found]';
    }
    let strPredicate = predicateDef.hasOwnProperty('toString') ? predicateDef.toString({
      inputs: predicate.Inputs
    }) : predicateDef.label;
    if ('ColumnId' in predicate) {
      strPredicate = `[${predicate.ColumnId}] ${strPredicate}`;
    }
    return strPredicate;
  }
  isValidPredicate(predicate) {
    if (!predicate) {
      return false;
    }
    const adaptablePredicateDef = this.getPredicateDefById(predicate.PredicateId);
    if (!adaptablePredicateDef) {
      return false;
    }
    // not perfect but if there are no inputs - and there should be some - we treat the filter as invalid
    if (adaptablePredicateDef.inputs) {
      if (ArrayExtensions.IsNullOrEmpty(predicate.Inputs) || StringExtensions.IsNullOrEmpty(predicate.Inputs[0])) {
        return false;
      }
    }
    return !(['Values', 'ExcludeValues'].includes(predicate.PredicateId) && ArrayExtensions.IsNullOrEmpty(predicate.Inputs));
  }
  isEveryPredicateValid(predicates) {
    return predicates.every(predicate => this.isValidPredicate(predicate));
  }
  handleColumnPredicate(predicate, context, defaultReturn) {
    if (!predicate.ColumnId) {
      return this.handlePredicate(predicate, context, defaultReturn);
    }
    const paramsForColumn = Object.assign(Object.assign({}, context), {
      column: this.getAdaptableApi().columnApi.getColumnWithColumnId(predicate.ColumnId),
      // value: params?.node?.data?.[predicate.ColumnId],
      value: this.getAdaptableApi().gridApi.getRawValueFromRowNode(context.node, predicate.ColumnId)
    });
    if ('oldValue' in paramsForColumn) {
      delete paramsForColumn.oldValue;
    }
    return this.handlePredicate(predicate, paramsForColumn, defaultReturn);
  }
  handleColumnPredicates(predicates, context, defaultReturn) {
    return predicates.every(predicate => this.handleColumnPredicate(predicate, context, defaultReturn));
  }
  handlePredicate(predicate, context, defaultReturn) {
    var _a;
    if (!predicate) {
      return defaultReturn;
    }
    const predicateDef = this.getPredicateDefById(predicate.PredicateId);
    if (predicateDef === undefined) {
      return defaultReturn;
    }
    if ((_a = predicateDef.inputs) === null || _a === void 0 ? void 0 : _a.some((_, i) => {
      var _a, _b;
      return ((_a = predicate.Inputs) === null || _a === void 0 ? void 0 : _a[i]) === undefined || ((_b = predicate.Inputs) === null || _b === void 0 ? void 0 : _b[i]) === '';
    })) {
      return defaultReturn;
    }
    try {
      return predicateDef.handler(Object.assign({
        adaptableApi: this.getAdaptableApi(),
        inputs: predicate.Inputs
      }, context));
    } catch (error) {
      console.error(`Error in predicate ${predicateDef.label}`, error);
      return false;
    }
  }
  handlePredicates(predicates, params, defaultReturn) {
    if (predicates === undefined || predicates === null || (predicates === null || predicates === void 0 ? void 0 : predicates.length) === 0) {
      return this.handlePredicate(undefined, params, defaultReturn);
    }
    return predicates === null || predicates === void 0 ? void 0 : predicates.every(p => this.handlePredicate(p, params, defaultReturn));
  }
  useCaseSensitivity() {
    return this.adaptable.adaptableOptions.predicateOptions.caseSensitivePredicates;
  }
}