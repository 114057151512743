import { useState, useRef, useCallback } from 'react';
export function useSelectionRange() {
  const [range, setRange] = useState([null, null]);
  const onSelectionChange = () => {
    const isActive = ref.current === document.activeElement;
    const start = isActive ? ref.current.selectionStart : null;
    const end = isActive ? ref.current.selectionEnd : null;
    setRange([start, end]);
  };
  const ref = useRef();
  const refCallback = useCallback(newNode => {
    const oldNode = ref.current;
    if (oldNode) document.removeEventListener('selectionchange', onSelectionChange);
    if (newNode) document.addEventListener('selectionchange', onSelectionChange);
    ref.current = newNode;
  }, []);
  return {
    ref: refCallback,
    selectionStart: range[0],
    selectionEnd: range[1]
  };
}