import * as React from 'react';
import { useRef } from 'react';
import { Flex, Text } from 'rebass';
import Input from '../../../components/Input';
import usePrevious from '../../../components/utils/usePrevious';
import Radio from '../../../components/Radio';
import { Tabs } from '../../../components/Tabs';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
import { CodeBlock } from '../../../components/CodeBlock';
import FormLayout, { FormRow } from '../../../components/FormLayout';
import { CheckBox } from '../../../components/CheckBox';
export const renderFlashingAlertSettingsSummary = flashingAlert => {
  return React.createElement(React.Fragment, null, React.createElement(Text, {
    fontSize: 2
  }, flashingAlert.FlashDuration === 'always' ? React.createElement(React.Fragment, null, "Flashing is never removed") : React.createElement(React.Fragment, null, "Flashing is removed after ", React.createElement(CodeBlock, null, flashingAlert.FlashDuration), ' ', "milliseconds")));
};
export const FlashingAlertSettingsWizardSection = props => {
  var _a, _b, _c, _d, _e, _f, _g;
  let {
    data: flashingCell
  } = useOnePageAdaptableWizardContext();
  flashingCell = flashingCell !== null && flashingCell !== void 0 ? flashingCell : props.flashingCell;
  const setDuration = FlashDuration => {
    props.onChange(Object.assign(Object.assign({}, flashingCell), {
      FlashDuration: FlashDuration
    }));
  };
  const duration = (_a = flashingCell.FlashDuration) !== null && _a !== void 0 ? _a : 500;
  const numberDuration = React.useRef(typeof duration === 'number' ? duration : 500);
  const inputRef = useRef(null);
  const oldDuration = usePrevious(duration, duration);
  React.useEffect(() => {
    var _a;
    if (oldDuration === 'always' && duration != oldDuration) {
      (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }
  }, [duration, oldDuration]);
  const handleTypeChange = type => {
    setDuration(type === 'number' ? numberDuration.current : 'always');
  };
  const handleTargetChange = (type, checked) => {
    var _a;
    let FlashTarget = (_a = flashingCell.FlashTarget) !== null && _a !== void 0 ? _a : [];
    if (typeof FlashTarget === 'string') {
      FlashTarget = [FlashTarget];
    }
    if (Array.isArray(FlashTarget)) {
      if (checked) {
        FlashTarget = [...FlashTarget, type];
      } else {
        FlashTarget = FlashTarget.filter(target => type != target);
      }
    }
    props.onChange(Object.assign(Object.assign({}, flashingCell), {
      FlashTarget
    }));
  };
  return React.createElement(Flex, {
    height: "100%",
    flexDirection: "column",
    "data-name": "plus-minus-column-settings"
  }, React.createElement(Tabs, {
    autoFocus: false,
    padding: 2
  }, React.createElement(Tabs.Tab, null, "Flashing Cell Settings"), React.createElement(Tabs.Content, null, React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Duration Type"
  }, React.createElement(Radio, {
    "data-name": "duration-type-always",
    mr: 3,
    checked: duration == 'always',
    tabIndex: -1,
    onChange: () => handleTypeChange('always')
  }, "Always"), React.createElement(Radio, {
    "data-name": "duration-type-number",
    checked: duration !== 'always',
    tabIndex: -1,
    onChange: () => handleTypeChange('number')
  }, "Limited (ms)")), React.createElement(FormRow, {
    label: ""
  }, duration !== 'always' && React.createElement(Flex, {
    flexDirection: "row",
    alignItems: "baseline"
  }, React.createElement(Text, {
    fontSize: 2,
    mr: 2
  }, "After"), React.createElement(Input, {
    "data-name": "duration-input",
    readOnly: props.readOnly,
    type: "text",
    name: "value",
    ref: inputRef,
    mt: 2,
    mr: 1,
    value: duration,
    onChange: event => {
      const value = event.target.value;
      setDuration(isNaN(Number(value)) ? 500 : Number(value));
    }
  }), React.createElement(Text, {
    fontSize: 2
  }, "milliseconds")), duration === 'always' && React.createElement(Text, {
    height: 43,
    fontSize: 3
  }, "Persists the flashing style indeterminately - can be removed manually")), React.createElement(FormRow, {
    label: "Flash Target"
  }, React.createElement(CheckBox, {
    "data-name": "flashing-target",
    onChange: checked => handleTargetChange('cell', checked),
    mr: 3,
    checked: (flashingCell === null || flashingCell === void 0 ? void 0 : flashingCell.FlashTarget) === 'cell' || ((_c = (_b = flashingCell === null || flashingCell === void 0 ? void 0 : flashingCell.FlashTarget) === null || _b === void 0 ? void 0 : _b.includes) === null || _c === void 0 ? void 0 : _c.call(_b, 'cell')),
    tabIndex: -1
  }, "Cell"), React.createElement(CheckBox, {
    "data-name": "flashing-target-row",
    mr: 3,
    onChange: checked => handleTargetChange('row', checked),
    checked: (flashingCell === null || flashingCell === void 0 ? void 0 : flashingCell.FlashTarget) === 'row' || ((_e = (_d = flashingCell === null || flashingCell === void 0 ? void 0 : flashingCell.FlashTarget) === null || _d === void 0 ? void 0 : _d.includes) === null || _e === void 0 ? void 0 : _e.call(_d, 'row')),
    tabIndex: -1
  }, "Row"), React.createElement(CheckBox, {
    "data-name": "flashing-target-aggFuncCell",
    onChange: checked => handleTargetChange('aggFuncCell', checked),
    checked: (flashingCell === null || flashingCell === void 0 ? void 0 : flashingCell.FlashTarget) === 'aggFuncCell' || ((_g = (_f = flashingCell === null || flashingCell === void 0 ? void 0 : flashingCell.FlashTarget) === null || _f === void 0 ? void 0 : _f.includes) === null || _g === void 0 ? void 0 : _g.call(_f, 'aggFuncCell')),
    tabIndex: -1
  }, "Aggregated Function Cell"))))));
};