import * as React from 'react';
import FormLayout, { FormRow } from '../../components/FormLayout';
import { useAdaptable } from '../AdaptableContext';
import AdaptableInput from '../Components/AdaptableInput';
import throttle from 'lodash/throttle';
import SimpleButton from '../../components/SimpleButton';
import { Box, Flex, Text } from 'rebass';
import Panel from '../../components/Panel';
import { Field } from './ThemeField';
import { VariantSelector } from './VariantSelector';
const fields = [{
  name: 'Primary Color',
  type: 'color',
  variable: '--ab-color-primary'
}, {
  name: 'Primary Dark Color',
  type: 'color',
  variable: '--ab-color-primarydark'
}, {
  name: 'Primary Light Color',
  type: 'color',
  variable: '--ab-color-primarylight'
}, {
  name: 'Text on Primary Color',
  type: 'color',
  variable: '--ab-color-text-on-primary'
}, {
  name: 'Text on Primary Dark Color',
  type: 'color',
  variable: '--ab-color-text-on-primarydark'
}, {
  name: 'Secondary Color',
  type: 'color',
  variable: '--ab-color-secondary'
}, {
  name: 'Secondary Dark Color',
  type: 'color',
  variable: '--ab-color-secondarydark'
}, {
  name: 'Secondary Light Color',
  type: 'color',
  variable: '--ab-color-secondarylight'
}, {
  name: 'Text on Secondary Color',
  type: 'color',
  variable: '--ab-color-text-on-secondary'
}, {
  name: 'Text on Secondary Light Color',
  type: 'color',
  variable: '--ab-color-text-on-secondarylight'
}, {
  name: 'Default background',
  type: 'color',
  variable: '--ab-color-defaultbackground'
}, {
  name: 'Text Color on Default Background',
  type: 'color',
  variable: '--ab-color-text-on-defaultbackground'
}];
export const ThemeEditor = props => {
  var _a, _b;
  const adaptable = useAdaptable();
  const allThemes = adaptable.api.themeApi.getUserThemes();
  const disabled = props.accessLevel === 'ReadOnly';
  const [currentThemeObject, setCurrentThemeObject] = React.useState(() => {
    return adaptable.api.themeApi.getCurrentThemeObject();
  });
  // THEME variables
  const themeVariables = currentThemeObject === null || currentThemeObject === void 0 ? void 0 : currentThemeObject.CSSVariables;
  const valuesFromTheme = fields.reduce((acc, field) => {
    if (themeVariables === null || themeVariables === void 0 ? void 0 : themeVariables[field.variable]) {
      acc[field.variable] = themeVariables === null || themeVariables === void 0 ? void 0 : themeVariables[field.variable];
    }
    return acc;
  }, {});
  React.useEffect(() => {
    setCurrentThemeObject(adaptable.api.themeApi.getCurrentThemeObject());
  }, [props.theme]);
  // This is needed when clearing variables, so the new value is pickedup up from the document
  React.useEffect(() => {
    const updateTheme = () => {
      const theme = adaptable.api.themeApi.getCurrentThemeObject();
      setCurrentThemeObject(Object.assign({}, theme));
    };
    const themeChangedUnsubscribe = adaptable.api.eventApi.on('ThemeChanged', updateTheme);
    return () => {
      themeChangedUnsubscribe();
    };
  }, []);
  const handleNameDescriptionChangeThrottled = React.useMemo(() => {
    return throttle((key, value) => {
      const theme = adaptable.api.themeApi.getCurrentThemeObject();
      const newTheme = Object.assign(Object.assign({}, theme), {
        [key]: value
      });
      adaptable.api.themeApi.editTheme(newTheme);
    }, 600);
  }, []);
  const handleDescriptionChange = React.useCallback((key, value) => {
    setCurrentThemeObject(prev => Object.assign(Object.assign({}, prev), {
      [key]: value
    }));
    handleNameDescriptionChangeThrottled(key, value);
  }, []);
  const handleDeleteTheme = React.useCallback(() => {
    adaptable.api.themeApi.loadLightTheme();
    adaptable.api.themeApi.deleteUserTheme(currentThemeObject);
  }, []);
  const handleSaveName = () => {
    const name = currentThemeObject.Name;
    setCurrentThemeObject(prev => Object.assign(Object.assign({}, prev), {
      Name: name
    }));
    handleNameDescriptionChangeThrottled('Name', name);
  };
  const handleVariantChange = variant => {
    const newTheme = Object.assign({}, currentThemeObject);
    if (!variant) {
      delete newTheme.Variant;
    } else {
      newTheme.Variant = variant;
    }
    setCurrentThemeObject(newTheme);
    adaptable.api.themeApi.editTheme(newTheme);
  };
  const nameHasChanged = currentThemeObject.Name !== props.theme;
  const nameIsNotUnique = allThemes.some(theme => theme.Uuid !== currentThemeObject.Uuid && currentThemeObject.Name === theme.Name);
  const saveNameDisabled = !nameHasChanged || nameIsNotUnique || currentThemeObject.Name === '';
  return React.createElement(Panel, {
    header: React.createElement(Flex, {
      alignItems: "center",
      width: "100%"
    }, React.createElement(Box, {
      flex: 1
    }, " Edit Custom Theme"), React.createElement(SimpleButton, {
      "data-name": "delete",
      disabled: props.accessLevel !== 'Full',
      icon: "delete",
      onClick: handleDeleteTheme,
      variant: "text"
    }))
  }, React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Theme Name"
  }, React.createElement(AdaptableInput, {
    mr: 2,
    onChange: event => setCurrentThemeObject(Object.assign(Object.assign({}, currentThemeObject), {
      Name: event.target.value.replace(/ /g, '-')
    })),
    value: (_a = currentThemeObject.Name) !== null && _a !== void 0 ? _a : ''
  }), React.createElement(SimpleButton, {
    onClick: handleSaveName,
    disabled: saveNameDisabled,
    icon: "save"
  }), React.createElement(Text, {
    fontSize: 2,
    marginTop: 1
  }, "The name cannot contain spaces."), nameIsNotUnique && React.createElement(Text, {
    fontSize: 2,
    color: "var(--ab-color-error)"
  }, "Name must be unique.")), React.createElement(FormRow, {
    label: "Description"
  }, React.createElement(AdaptableInput, {
    onChange: event => handleDescriptionChange('Description', event.target.value),
    value: (_b = currentThemeObject === null || currentThemeObject === void 0 ? void 0 : currentThemeObject.Description) !== null && _b !== void 0 ? _b : ''
  })), React.createElement(FormRow, {
    label: "Variant"
  }, React.createElement(VariantSelector, {
    onChange: handleVariantChange,
    theme: currentThemeObject
  })), fields.map(field => {
    return React.createElement(Field, {
      disabled: disabled,
      key: field.name,
      type: field.type,
      name: field.name,
      value: valuesFromTheme[field.variable],
      variable: field.variable,
      onChange: val => {
        // needs a fresh copy
        const currentThemeObject = adaptable.api.themeApi.getCurrentThemeObject();
        let newTheme = null;
        if (val === false) {
          newTheme = Object.assign(Object.assign({}, currentThemeObject), {
            CSSVariables: Object.assign({}, currentThemeObject === null || currentThemeObject === void 0 ? void 0 : currentThemeObject.CSSVariables)
          });
          delete newTheme.CSSVariables[field.variable];
        } else {
          newTheme = Object.assign(Object.assign({}, currentThemeObject), {
            CSSVariables: Object.assign(Object.assign({}, currentThemeObject === null || currentThemeObject === void 0 ? void 0 : currentThemeObject.CSSVariables), {
              [field.variable]: val
            })
          });
        }
        setCurrentThemeObject(newTheme);
        adaptable.api.themeApi.editTheme(newTheme);
      }
    });
  })));
};