// constants used in Config
export const SYSTEM = 'System';
export const GRID = 'Grid';
export const POPUP = 'Popup';
export const TEAM_SHARING = 'TeamSharing';
export const BULK_UPDATE = 'BulkUpdate';
export const APPLICATION = 'Application';
export const CELL_SUMMARY = 'CellSummary';
export const SYSTEM_FILTER = 'SystemFilter';
export const PLUGINS = 'Plugins';
export const COMMENT = 'Comment';