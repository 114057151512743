import * as React from 'react';
import { ExpressionPreview } from '../../components/ExpressionEditor/ExpressionPreview';
import { Tag } from '../../components/Tag';
export const getExpressionViewItems = (query, api) => {
  // const expressionText = api.internalApi.getAdaptableQueryExpressionText(query);
  return {
    name: 'Expression',
    // values: [],
    view: React.createElement(Tag, null, React.createElement(ExpressionPreview, {
      query: query
    }))
  };
};