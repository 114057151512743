import * as ThemeRedux from '../../Redux/ActionsReducers/ThemeRedux';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import { ApiBase } from './ApiBase';
import { StaticThemes } from '../../themes';
import { ThemeInternalApi } from '../Internal/ThemeInternalApi';
import AdaptableHelper from '../../Utilities/Helpers/AdaptableHelper';
export class ThemeApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.internalApi = new ThemeInternalApi(adaptable);
  }
  getThemeState() {
    return this.getAdaptableState().Theme;
  }
  loadTheme(theme) {
    this.dispatchAction(ThemeRedux.ThemeSelect(theme));
  }
  loadLightTheme() {
    this.loadTheme('light');
  }
  loadDarkTheme() {
    this.loadTheme('dark');
  }
  getCurrentTheme() {
    return this.getAdaptableState().Theme.CurrentTheme;
  }
  getCurrentThemeObject() {
    const currentTheme = this.getCurrentTheme();
    return this.getThemes().find(themeObj => themeObj.Name === currentTheme);
  }
  setSystemThemes(systemThemes) {
    this.dispatchAction(ThemeRedux.ThemeSetSystemThemes(systemThemes));
  }
  setUserThemes(userThemes) {
    this.dispatchAction(ThemeRedux.ThemeSetUserThemes(userThemes));
  }
  getSystemThemes() {
    var _a;
    const themes = (_a = this.getAdaptableState().Theme.SystemThemes) !== null && _a !== void 0 ? _a : [];
    return themes.map(theme => {
      var _a, _b;
      if (typeof theme === 'string') {
        const description = (_b = (_a = StaticThemes.find(staticTheme => staticTheme.Name === theme)) === null || _a === void 0 ? void 0 : _a.Description) !== null && _b !== void 0 ? _b : theme;
        return {
          Name: theme,
          Description: description
        };
      }
      return theme;
    });
  }
  applyCurrentTheme() {
    const currentTheme = this.getThemes().filter(theme => typeof theme === 'string' ? theme === this.getCurrentTheme() : theme.Name === this.getCurrentTheme())[0];
    if (!currentTheme) {
      return;
    }
    this.adaptable.applyAdaptableTheme(currentTheme);
    let themeChangedInfo = Object.assign(Object.assign({}, this.getAdaptableApi().internalApi.buildBaseContext()), {
      trigger: 'ThemeSelected',
      theme: currentTheme
    });
    this.getAdaptableApi().eventApi.emit('ThemeChanged', themeChangedInfo);
  }
  getUserThemes() {
    var _a;
    return (_a = this.getAdaptableState().Theme.UserThemes) !== null && _a !== void 0 ? _a : [];
  }
  getThemes() {
    return [...this.getSystemThemes(), ...this.getUserThemes()];
  }
  getThemeByName(themeName) {
    return this.getThemes().find(theme => theme.Name === themeName);
  }
  openThemeSettingsPanel() {
    this.showModulePopup(ModuleConstants.ThemeModuleId);
  }
  getAgGridCurrentThemeName() {
    return this.adaptable.getAgGridCurrentThemeName();
  }
  editTheme(theme) {
    this.dispatchAction(ThemeRedux.ThemeEdit(theme));
    let themeChangedInfo = Object.assign(Object.assign({}, this.getAdaptableApi().internalApi.buildBaseContext()), {
      trigger: 'ThemeEdited',
      theme: theme
    });
    this.getAdaptableApi().eventApi.emit('ThemeChanged', themeChangedInfo);
    const currentTheme = this.getCurrentTheme();
    if (currentTheme === theme.Name) {
      this.adaptable.applyAdaptableTheme(theme);
    }
  }
  addUserTheme(theme) {
    AdaptableHelper.addUuidAndSource(theme);
    this.dispatchAction(ThemeRedux.ThemeAdd(theme));
  }
  deleteUserTheme(theme) {
    this.dispatchAction(ThemeRedux.ThemeDelete(theme));
  }
}