import * as React from 'react';
import { useMemo } from 'react';
import { Box } from 'rebass';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
import { ExpressionEditor } from '../../../components/ExpressionEditor';
import { Tabs } from '../../../components/Tabs';
import { CodeBlock } from '../../../components/CodeBlock';
import { Humanize } from '../../../Utilities/Extensions/StringExtensions';
export const renderCalculatedColumnExpressionSummary = data => {
  const {
    api
  } = useOnePageAdaptableWizardContext();
  return React.createElement(Box, {
    fontSize: 2
  }, "Column scalar expression:", ' ', React.createElement(CodeBlock, null, api.internalApi.getAdaptableQueryExpressionText(data.Query)));
};
export const isValidCalculatedColumnExpression = (data, api) => {
  var _a;
  const calculatedColumnExpressionService = api.internalApi.getCalculatedColumnExpressionService();
  const expression = (_a = api.expressionApi.getAdaptableQueryExpression(data.Query)) === null || _a === void 0 ? void 0 : _a.trim();
  if (!expression) {
    return 'Calculated column expression cannot be empty';
  }
  const isValid = calculatedColumnExpressionService.isCalculatedColumnQueryValid(data.Query);
  if (!isValid) {
    return 'Calculated column expression is not valid';
  }
  return true;
};
export const CalculatedColumnExpressionWizardSection = props => {
  const {
    api,
    data,
    moduleInfo
  } = useOnePageAdaptableWizardContext();
  const initialData = useMemo(() => api.internalApi.getQueryPreviewData(), []);
  const setCalculatedColumnExpression = calculatedColumnQuery => {
    const calculatedColumnExpressionService = api.internalApi.getCalculatedColumnExpressionService();
    const dataType = calculatedColumnExpressionService.getCalculatedColumnDataType(calculatedColumnQuery);
    props.onChange(Object.assign(Object.assign({}, data), {
      Query: calculatedColumnQuery,
      CalculatedColumnSettings: Object.assign(Object.assign({}, data.CalculatedColumnSettings), {
        DataType: dataType
      })
    }));
  };
  const getEditorType = expressionType => {
    switch (expressionType) {
      case 'AggregatedScalarExpression':
        return 'aggregatedScalar';
      case 'CumulativeAggregatedExpression':
        return 'cumulativeAggregatedScalar';
      case 'QuantileAggregatedExpression':
        return 'quantileAggregatedScalar';
    }
  };
  return React.createElement(Tabs, {
    autoFocus: false,
    height: "100%"
  }, React.createElement(Tabs.Tab, null, Humanize(props.expressionType)), React.createElement(Tabs.Content, null, (() => {
    switch (props.expressionType) {
      case 'ScalarExpression':
        return React.createElement(ExpressionEditor, {
          type: 'scalar',
          module: moduleInfo.ModuleName,
          style: {
            paddingLeft: 0
          },
          value: data.Query.ScalarExpression,
          onChange: ScalarExpression => setCalculatedColumnExpression({
            ScalarExpression
          }),
          initialData: initialData,
          columns: api.columnApi.getQueryableColumns(),
          fields: api.expressionApi.internalApi.getAvailableFields(),
          namedQueries: api.namedQueryApi.getNamedQueries(),
          isFullExpression: true,
          api: api
        });
      case 'AggregatedScalarExpression':
      case 'CumulativeAggregatedExpression':
      case 'QuantileAggregatedExpression':
        return React.createElement(ExpressionEditor, {
          type: getEditorType(props.expressionType),
          module: moduleInfo.ModuleName,
          style: {
            paddingLeft: 0
          },
          value: data.Query.AggregatedScalarExpression,
          onChange: AggregatedScalarExpression => setCalculatedColumnExpression({
            AggregatedScalarExpression
          }),
          initialData: initialData,
          columns: api.columnApi.getQueryableColumns(),
          namedQueries: api.namedQueryApi.getNamedQueries(),
          isFullExpression: true,
          api: api
        });
      default:
        return React.createElement(React.Fragment, null);
    }
  })()));
};