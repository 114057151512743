export const editorButtonsQuantileAggregatedScalar = [{
  functionName: 'QUANT',
  data: 'QUANT()',
  text: 'Quantile'
}, {
  functionName: 'QUARTILE',
  data: 'QUARTILE()',
  text: 'Quartile'
}, {
  functionName: 'PERCENTILE',
  data: 'PERCENTILE()',
  text: 'Percentile'
}, {
  functionName: 'GROUP_BY',
  data: 'GROUP_BY()',
  text: 'GroupBy'
}];