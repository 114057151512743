import * as SystemRedux from '../../Redux/ActionsReducers/SystemRedux';
import * as React from 'react';
import { StringExtensions } from '../../Utilities/Extensions/StringExtensions';
import { Flex } from 'rebass';
import { connect } from 'react-redux';
import ArrayExtensions from '../../Utilities/Extensions/ArrayExtensions';
import { Select } from '../../components/Select';
class DataSetViewPanelComponent extends React.Component {
  getCurrentDataSet() {
    return StringExtensions.IsNullOrEmpty(this.props.CurrentDataSetName) ? null : this.getDataSets().find(ds => ds.name == this.props.CurrentDataSetName);
  }
  getDataSets() {
    return this.props.api.dataSetApi.getDataSets();
  }
  render() {
    var _a, _b;
    const selectDataSetString = 'Select Data Set';
    let currentDataSetName = (_b = (_a = this.getCurrentDataSet()) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : selectDataSetString;
    let availableDataSets = this.getDataSets().map(dataSet => {
      return {
        value: dataSet.name,
        label: dataSet.name,
        onClick: () => this.onSelectedDataSetChanged(dataSet.name)
      };
    });
    const elementType = this.props.viewType === 'Toolbar' ? 'DashboardToolbar' : 'ToolPanel';
    return React.createElement(Flex, {
      flexDirection: "row",
      className: `ab-${elementType}__DataSet__wrap`
    }, React.createElement(Select, {
      style: {
        width: '100%'
      },
      placeholder: 'Select Data Set',
      disabled: ArrayExtensions.IsNullOrEmpty(availableDataSets),
      options: availableDataSets,
      value: currentDataSetName,
      className: `ab-${elementType}__DataSet__select`,
      onChange: destination => this.onSelectedDataSetChanged(destination)
    }));
  }
  onSelectedDataSetChanged(dataSetName) {
    if (StringExtensions.IsNullOrEmpty(dataSetName)) {
      this.setState({
        CurrentDataSet: null
      });
    } else {
      let newDataSet = this.getDataSets().find(ds => ds.name == dataSetName);
      this.setState({
        CurrentDataSet: newDataSet
      });
      this.props.onSelectDataSet(newDataSet);
    }
  }
}
function mapStateToProps(state) {
  return {
    CurrentDataSetName: state.System.CurrentDataSet
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onSelectDataSet: dataSet => {
      return dispatch(SystemRedux.SystemDataSetSelect(dataSet));
    }
  };
}
export let DataSetViewPanelControl = connect(mapStateToProps, mapDispatchToProps)(DataSetViewPanelComponent);