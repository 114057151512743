import { useRef } from 'react';
import useProperty from '../utils/useProperty';
import getAvailableSizeInfo from '../utils/getAvailableSizeInfo';
import { getConstrainRect } from '../OverlayTrigger';
export default ((props, positionerRef) => {
  const positionInfoRef = useRef({
    maxHeight: '50vh',
    maxWidth: '50vw',
    verticalPosition: 'bottom',
    horizontalPosition: 'right'
  });
  const updatePosition = () => {
    const positionRect = positionerRef.current.getBoundingClientRect();
    positionInfoRef.current = getAvailableSizeInfo({
      constrainRect: getConstrainRect(positionerRef.current, props.constrainTo),
      targetRect: positionRect,
      maxSizeOffset: 30
    });
  };
  const [expanded, doSetExpanded] = useProperty(props, 'expanded', false, {
    onChange: expanded => {
      if (expanded) {
        if (props.onExpand) {
          props.onExpand();
        }
      } else if (props.onCollapse) {
        props.onCollapse();
      }
      if (props.onToggle) {
        props.onToggle(expanded);
      }
      if (props.onExpandedChange) {
        props.onExpandedChange(expanded);
      }
    }
  });
  const setExpanded = newExpanded => {
    // if (!newExpanded) {
    //   return;
    // }
    if (!expanded && newExpanded) {
      updatePosition();
    }
    doSetExpanded(newExpanded);
  };
  const toggle = () => {
    setExpanded(!expanded);
  };
  return {
    expanded,
    setExpanded,
    toggle,
    positionInfo: positionInfoRef.current
  };
});