/**
 * Returns a new object with the property updated.
 * The property is identified by the path.
 *
 * @param obj Object
 * @param path path to the property
 * @param value new property value
 * @returns
 */
export function setInPath(obj, path, value) {
  var _a;
  if (path.length === 1) {
    return Object.assign(Object.assign({}, obj), {
      [path[0]]: value
    });
  }
  return Object.assign(Object.assign({}, obj), {
    [path[0]]: setInPath((_a = obj === null || obj === void 0 ? void 0 : obj[path[0]]) !== null && _a !== void 0 ? _a : {}, path.slice(1), value)
  });
}
export function isObjectEmpty(obj) {
  return !obj || Object.keys(obj).length === 0;
}
export const deepClone = obj => {
  return JSON.parse(JSON.stringify(obj));
};
export const diff = (obj1, obj2) => {
  let changed = [];
  for (const key of Object.keys(obj1)) {
    if (obj1[key] !== obj2[key]) {
      changed.push(key);
    }
  }
  return changed;
};