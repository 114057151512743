import * as React from 'react';
import * as PopupRedux from '../../Redux/ActionsReducers/PopupRedux';
import { ButtonEdit } from '../Components/Buttons/ButtonEdit';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import { useDispatch } from 'react-redux';
export const EditChartButton = props => {
  const dispatch = useDispatch();
  return React.createElement(ButtonEdit, {
    iconSize: props.iconSize,
    tooltip: "Edit Chart",
    // It gets complicated when a chart is both open and opened in edit wizard
    // There an be conflicts between the two open charts (preview and main) and the changes to the active chart may not be syncronized correctly.
    disabled: !props.chart || props.isOpen,
    accessLevel: props.accessLevel,
    onClick: () => dispatch(PopupRedux.PopupShowScreen(ModuleConstants.ChartingModuleId, 'Chart', {
      action: 'Edit',
      value: props.chart,
      source: 'Toolbar'
    }))
  });
};