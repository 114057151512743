import * as React from 'react';
import { Flex } from 'rebass';
import { PercentBarStylePreview } from './PercentBarStylePreview';
export const PercentBarPreview = props => {
  let postfix = '';
  if (props.percentBarStyle.RangeValueType === 'Percentage') {
    postfix = '%';
  }
  return React.createElement(React.Fragment, null, props.percentBarStyle.CellRanges.map((range, i) => React.createElement(Flex, {
    key: i,
    alignItems: "stretch",
    mr: 3,
    mb: 2
  }, React.createElement(Flex, {
    flexBasis: 120,
    alignItems: "center",
    mr: 3
  }, range.Min, postfix, " to ", range.Max, postfix, ' '), React.createElement(PercentBarStylePreview, {
    backgroundColor: props.percentBarStyle.BackColor,
    barBackgroundColor: range.Color,
    cellText: props.percentBarStyle.CellText,
    toolTipText: props.percentBarStyle.ToolTipText
  }))));
};