import * as React from 'react';
import { useState } from 'react';
import SimpleButton from '../../components/SimpleButton';
import kebabCase from 'lodash/kebabCase';
export const CustomDashboardButton = props => {
  const {
    button
  } = props;
  const [componentRevision, setComponentRevision] = useState(1);
  // TODO: variants of this mapping are present in several places (just search for api.internalApi.getStyleForButton() usages)
  // with the next opportunity we should abstract it
  const dashboardContext = Object.assign(Object.assign({}, props.api.internalApi.buildBaseContext()), {
    dashboardState: props.api.dashboardApi.getDashboardState()
  });
  const buttonIcon = props.api.internalApi.getIconForButton(button, dashboardContext);
  let buttonStyle = props.api.internalApi.getStyleForButton(button, dashboardContext);
  let buttonLabel = props.api.internalApi.getLabelForButton(button, dashboardContext);
  let buttonTooltip = props.api.internalApi.getTooltipForButton(button, dashboardContext);
  if (button.hidden && button.hidden(button, dashboardContext)) {
    return null;
  }
  const disabled = button.disabled && button.disabled(button, dashboardContext);
  return React.createElement(SimpleButton, {
    key: buttonLabel,
    variant: buttonStyle && buttonStyle.variant ? buttonStyle.variant : 'text',
    tone: buttonStyle && buttonStyle.tone ? buttonStyle.tone : 'none',
    className: `ab-DashboardToolbar__Home__${kebabCase(buttonLabel)} ${(buttonStyle === null || buttonStyle === void 0 ? void 0 : buttonStyle.className) || ''}`,
    tooltip: buttonTooltip,
    icon: buttonIcon,
    disabled: disabled || props.accessLevel == 'ReadOnly',
    onClick: () => {
      var _a;
      (_a = button.onClick) === null || _a === void 0 ? void 0 : _a.call(button, button, dashboardContext);
      setTimeout(() => {
        // mutate state to force a re-rendering
        setComponentRevision(componentRevision + 1);
      }, 16);
    },
    accessLevel: 'Full'
  }, buttonLabel);
};