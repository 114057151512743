import { EMPTY_ARRAY } from '../../Utilities/Constants/GeneralConstants';
import AdaptableHelper from '../../Utilities/Helpers/AdaptableHelper';
/**
 * @ReduxAction A Calculated Column has been added
 */
export const CALCULATED_COLUMN_ADD = 'CALCULATED_COLUMN_ADD';
/**
 * @ReduxAction A Calculated Column has been edited
 */
export const CALCULATED_COLUMN_EDIT = 'CALCULATED_COLUMN_EDIT';
/**
 * @ReduxAction A Calculated Column has been deleted
 */
export const CALCULATED_COLUMN_DELETE = 'CALCULATED_COLUMN_DELETE';
/**
 * @ReduxAction Calculated Column Module is ready
 */
export const CALCULATED_COLUMN_READY = 'CALCULATED_COLUMN_READY';
export const CalculatedColumnAdd = calculatedColumn => ({
  type: CALCULATED_COLUMN_ADD,
  calculatedColumn
});
export const CalculatedColumnEdit = calculatedColumn => ({
  type: CALCULATED_COLUMN_EDIT,
  calculatedColumn
});
export const CalculatedColumnDelete = calculatedColumn => ({
  type: CALCULATED_COLUMN_DELETE,
  calculatedColumn
});
export const CalculatedColumnReady = calculatedColumnState => ({
  type: CALCULATED_COLUMN_READY,
  calculatedColumnState
});
const initialState = {
  CalculatedColumns: EMPTY_ARRAY
};
export const CalculatedColumnReducer = (state = initialState, action) => {
  let calculatedColumns;
  switch (action.type) {
    case CALCULATED_COLUMN_ADD:
      {
        const actionCalculatedColumn = action.calculatedColumn;
        AdaptableHelper.addUuidAndSource(actionCalculatedColumn);
        calculatedColumns = [].concat(state.CalculatedColumns);
        calculatedColumns.push(actionCalculatedColumn);
        return Object.assign(Object.assign({}, state), {
          CalculatedColumns: calculatedColumns
        });
      }
    // !! MUTABLE STATE
    case CALCULATED_COLUMN_EDIT:
      {
        const actionCalculatedColumn = action.calculatedColumn;
        return Object.assign(Object.assign({}, state), {
          CalculatedColumns: state.CalculatedColumns.map(abObject => abObject.Uuid === actionCalculatedColumn.Uuid ?
          // we need to assign and return the same object
          // in order to make working with aggrid easier, as the col getter
          // references this object
          Object.assign(abObject, actionCalculatedColumn) : abObject)
        });
      }
    case CALCULATED_COLUMN_DELETE:
      {
        const actionCalculatedColumn = action.calculatedColumn;
        return Object.assign(Object.assign({}, state), {
          CalculatedColumns: state.CalculatedColumns.filter(abObject => abObject.Uuid !== actionCalculatedColumn.Uuid)
        });
      }
    default:
      return state;
  }
};