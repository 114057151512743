import * as React from 'react';
import { connect } from 'react-redux';
import * as SystemRedux from '../../Redux/ActionsReducers/SystemRedux';
import { Box } from 'rebass';
import SimpleButton from '../../components/SimpleButton';
import { AdaptableObjectCollection } from '../Components/AdaptableObjectCollection';
import AdaptableHelper from '../../Utilities/Helpers/AdaptableHelper';
import { SystemStatusEntityRow } from './SystemStatusEntityRow';
import ArrayExtensions from '../../Utilities/Extensions/ArrayExtensions';
import { PopupPanel } from '../Components/Popups/AdaptablePopup/PopupPanel';
import { SortOrder } from '../../PredefinedConfig/Common/Enums';
const colItems = [{
  Content: 'Message',
  Size: 10
}, {
  Content: 'Timestamp',
  Size: 3
}];
class SystemStatusPopupComponent extends React.Component {
  constructor(props) {
    super(props);
    // this.state = { EditedSystemStatusText: '', EditedStyle: null };
  }
  onSystemStatusMessageInfoDeleteAll() {
    this.props.onSystemStatusMessageInfoDeleteAll();
  }
  render() {
    let clearAllButton = React.createElement(SimpleButton, {
      onClick: () => this.onSystemStatusMessageInfoDeleteAll(),
      tooltip: "Clear All",
      tone: "neutral",
      variant: "raised",
      accessLevel: this.props.accessLevel,
      disabled: ArrayExtensions.IsNullOrEmpty(this.props.SystemStatusMessages)
    }, "Delete All Messages");
    const messages = ArrayExtensions.sortArrayWithProperty(SortOrder.Desc, this.props.SystemStatusMessages, 'Timestamp').map((smi, index) => {
      const accessLevel = AdaptableHelper.getAccessLevelForObject(smi, this.props.accessLevel);
      return React.createElement(SystemStatusEntityRow, {
        adaptableObject: smi,
        key: 'smi' + index,
        onEdit: null,
        moduleInfo: this.props.moduleInfo,
        colItems: colItems,
        api: this.props.api,
        accessLevel: accessLevel,
        teamSharingActivated: this.props.teamSharingActivated,
        onDeleteConfirm: null,
        onShare: null
      });
    });
    return React.createElement(PopupPanel, {
      button: clearAllButton,
      headerText: this.props.moduleInfo.FriendlyName,
      infoLink: this.props.moduleInfo.HelpPage,
      infoLinkDisabled: !this.props.api.internalApi.isDocumentationLinksDisplayed()
    }, ArrayExtensions.IsNotNullOrEmpty(this.props.SystemStatusMessages) && React.createElement(Box, {
      className: "ab-SystemStatus-Popup-List",
      padding: 2
    }, React.createElement(AdaptableObjectCollection, {
      colItems: colItems,
      items: messages
    })));
  }
}
function mapStateToProps(state, ownProps) {
  return {
    SystemStatusMessageInfos: state.System.SystemStatusMessages,
    SystemStatusMessages: state.System.SystemStatusMessages
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onSystemStatusMessageInfoDeleteAll: () => dispatch(SystemRedux.SystemStatusMessageInfoDeleteAll())
  };
}
export let SystemStatusPopup = connect(mapStateToProps, mapDispatchToProps)(SystemStatusPopupComponent);