import { useAdaptable } from '../../../View/AdaptableContext';
import { renderAlertBehaviourSummary } from '../../../View/Alert/Wizard/AlertBehaviourWizardSection';
const BehaviourView = props => {
  const adaptable = useAdaptable();
  return renderAlertBehaviourSummary(props.data, adaptable.api, true);
};
export const getAlertBehaviourViewItems = api => {
  return {
    name: api.internalApi.getCorrectEnglishVariant('Behaviour'),
    view: BehaviourView
  };
};