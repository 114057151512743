import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import { ApiBase } from './ApiBase';
import * as SmartEditRedux from '../../Redux/ActionsReducers/SmartEditRedux';
import * as SystemRedux from '../../Redux/ActionsReducers/SystemRedux';
export class SmartEditApiImpl extends ApiBase {
  setSmartEditOperation(mathOperation) {
    this.dispatchAction(SystemRedux.SmartEditChangeOperation(mathOperation));
  }
  getSmartEditOperation() {
    return this.getAdaptableState().System.SmartEditOperation;
  }
  setSmartEditValue(smartEditValue) {
    this.dispatchAction(SystemRedux.SmartEditChangeValue(smartEditValue));
  }
  getSmartEditValue() {
    return this.getAdaptableState().System.SmartEditValue;
  }
  openSmartEditSettingsPanel() {
    this.showModulePopup(ModuleConstants.SmartEditModuleId);
  }
  applySmartEdit(cellUpdateRequests) {
    this.dispatchAction(SmartEditRedux.SmartEditApply(cellUpdateRequests));
  }
  getSmartEditCustomOperations() {
    var _a;
    return (_a = this.getEditOptions().smartEditCustomOperations) !== null && _a !== void 0 ? _a : [];
  }
  setCustomSmartEditOperation(customOperationName) {
    const customOperation = this.getSmartEditCustomOperations().find(so => so.name == customOperationName);
    if (customOperation) {
      this.dispatchAction(SystemRedux.SmartEditChangeOperation(customOperation));
    }
  }
}