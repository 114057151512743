export const editorButtonsCumulativeAggregatedScalar = [{
  functionName: 'CUMUL',
  data: 'CUMUL()',
  text: 'Cumulate'
}, {
  functionName: 'OVER',
  data: 'OVER()',
  text: 'Over'
}, {
  functionName: 'SUM',
  data: 'SUM()',
  text: 'Sum'
}, {
  functionName: 'PERCENTAGE',
  data: 'PERCENTAGE()',
  text: 'Percentage'
}, {
  functionName: 'MIN',
  data: 'MIN()',
  text: 'Min'
}, {
  functionName: 'MAX',
  data: 'MAX()',
  text: 'Max'
}, {
  functionName: 'AVG',
  data: 'AVG()',
  text: 'Average'
}, {
  functionName: 'WEIGHT',
  data: 'WEIGHT()',
  text: 'Weight'
}, {
  functionName: 'GROUP_BY',
  data: 'GROUP_BY()',
  text: 'GroupBy'
}];