import * as React from 'react';
/// <reference path="../../typings/.d.ts" />
import { Flex, Text } from 'rebass';
import join from '../../../components/utils/join';
export class AdaptableObjectRow extends React.Component {
  render() {
    const colItems = this.props.colItems.map((colItem, index) => React.createElement(Text, {
      className: "ab-AdaptableObjectRow__cell",
      "data-name": "list-group-cell",
      key: index,
      title: typeof colItem.Content === 'string' ? colItem.Content : undefined,
      flex: colItem.Size,
      paddingLeft: 1,
      paddingRight: 1
    }, colItem.Content));
    const className = join('ab-AdaptableObjectRow', this.props.isSuspended && 'ab-AdaptableObjectRow--is-suspended');
    return React.createElement(Flex, {
      className: className,
      onClick: this.props.onClick,
      style: this.props.style
    }, colItems);
  }
}