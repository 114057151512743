import React from 'react';
import SimpleButton from '../../components/SimpleButton';
import { useDispatch } from 'react-redux';
export const getObjectTagsViewItems = (object, api) => {
  var _a, _b, _c, _d;
  if (!api.internalApi.shouldDisplayTagSections() || !((_a = object === null || object === void 0 ? void 0 : object.Tags) === null || _a === void 0 ? void 0 : _a.length)) {
    return;
  }
  const currentLayoutName = api.layoutApi.getCurrentLayoutName();
  const hasCurrentLayoutAsTag = (_c = (_b = object === null || object === void 0 ? void 0 : object.Tags) !== null && _b !== void 0 ? _b : []) === null || _c === void 0 ? void 0 : _c.includes(currentLayoutName);
  return {
    name: 'Tags',
    values: (_d = object === null || object === void 0 ? void 0 : object.Tags) === null || _d === void 0 ? void 0 : _d.map(tag => api.internalApi.getLabelForTag(tag)),
    viewAfter: props => {
      const dispatch = useDispatch();
      const viewOptions = props.module.getViewProperties();
      const handleToggleTag = React.useCallback(() => {
        var _a;
        let tags = (_a = props.data.Tags) !== null && _a !== void 0 ? _a : [];
        if (hasCurrentLayoutAsTag) {
          // remove layout
          tags = tags.filter(tag => tag !== currentLayoutName);
        } else {
          tags.push(currentLayoutName);
        }
        const newObject = Object.assign(Object.assign({}, props.data), {
          Tags: tags
        });
        const editAction = viewOptions.getEditAction(newObject);
        dispatch(editAction);
      }, [hasCurrentLayoutAsTag, props.data]);
      return React.createElement(SimpleButton, {
        onClick: handleToggleTag,
        mt: 1,
        icon: hasCurrentLayoutAsTag ? 'minus' : 'plus'
      }, hasCurrentLayoutAsTag ? 'Remove from Layout' : 'Add to Layout');
    }
  };
};