import * as e from "react";
import { Logo as t } from "../../components/Logo";
import { Flex as r } from "rebass";
const o = {
    border: "1px solid var(--ab-color-error)",
    padding: "5px",
    fontWeight: 600,
    margin: "5px",
    fontSize: "14px",
    alignItems: "center",
    color: "var(--ab-color-text-on-defaultbackground)",
    background: "var(--ab-color-defaultbackground)"
  },
  n = e => {
    const t = [["display", "none"], ["opacity", "0"], ["position", "absolute"], ["position", "fixed"], ["position", "relative"], ["visibility", "hidden"]];
    for (const [r, o] of t) if (e.style[r] === o) return !1;
    return !0;
  };
export const LicenseWatermark = i => {
  const l = e.useRef(null);
  return e.useEffect(() => {
    const e = setInterval(() => {
      var e, t;
      (null === (e = l.current) || void 0 === e ? void 0 : e.isConnected) || alert("It is not allowed to remove the Adaptable watermark."), n(l.current) || alert("It is not allowed to modify the Adaptable watermark."), (null === (t = null == l ? void 0 : l.current) || void 0 === t ? void 0 : t.style) && (l.current.style.border = o.border, l.current.style.padding = o.padding, l.current.style.fontWeight = `${o.fontWeight}`, l.current.style.margin = o.margin, l.current.style.fontSize = o.fontSize, l.current.style.color = o.color, l.current.style.background = o.background, l.current.style.display = "flex", l.current.style.position = "static", l.current.style.opacity = "1", l.current.style.visibility = "visible");
    }, 5e3);
    return () => clearTimeout(e);
  }, []), e.createElement(r, {
    style: o,
    ref: l
  }, e.createElement(t, {
    style: {
      marginRight: 10
    }
  }), e.createElement("div", null, i.children));
};