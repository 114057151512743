function insertAtCorrectIndex(result, colState, visibleColumnsIndexes, offset = 0, lastVisibleOffset = 0) {
  const prevColState = result[result.length - 1 - offset];
  if (!prevColState) {
    result.splice(result.length - lastVisibleOffset, 0, colState);
    return;
  }
  if (prevColState.hide) {
    insertAtCorrectIndex(result, colState, visibleColumnsIndexes, offset + 1, /* do not advance this as, as we've encountered a hidden column*/lastVisibleOffset);
    return;
  }
  const prevCol_visibilityIndex = visibleColumnsIndexes[prevColState.colId];
  const currentCol_visibilityIndex = visibleColumnsIndexes[colState.colId];
  if (currentCol_visibilityIndex < prevCol_visibilityIndex) {
    insertAtCorrectIndex(result, colState, visibleColumnsIndexes, offset + 1, /*make sure we sync with the normal offset, as we've hit a visible col*/offset + 1);
  } else {
    result.splice(result.length - lastVisibleOffset, 0, colState);
  }
}
/**
 * This method takes a column state and the list of visible columns
 * and returns a new column state that respects the order of the visible columns
 * while trying to keep the hidden columns in their original order.
 *
 */
export function sortColumnStateForVisibleColumns(columnState, visibleColumns) {
  const visibleColumnsIndexes = visibleColumns.reduce((acc, colId, index) => {
    acc[colId] = index;
    return acc;
  }, {});
  const result = [];
  for (let i = 0, len = columnState.length; i < len; i++) {
    const colState = columnState[i];
    const colVisible = visibleColumnsIndexes[colState.colId] != null;
    if (!colVisible) {
      // for cols not present in this layout, we keep them
      // where they are
      result.push(colState);
      continue;
    }
    insertAtCorrectIndex(result, colState, visibleColumnsIndexes);
  }
  return result;
}