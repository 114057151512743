import * as React from 'react';
import isSameMonth from 'date-fns/isSameMonth';
import add from 'date-fns/add';
import { CaptionLabel } from './CaptionLabel';
import { useDayPicker, useNavigation } from 'react-day-picker';
/**
 * Render the caption of a month, which includes title and navigation buttons.
 * The caption has a different layout when setting the `numberOfMonths` prop.
 */
export function Caption(props) {
  const {
    displayMonth
  } = props;
  const context = useDayPicker();
  const {
    classNames,
    disableNavigation,
    styles,
    onMonthChange,
    dir
  } = context;
  const {
    goToMonth,
    displayMonths
  } = useNavigation();
  const handlePreviousClick = () => {
    handleMonthChange(add(displayMonth, {
      months: -1
    }));
  };
  const handleNextClick = () => {
    handleMonthChange(add(displayMonth, {
      months: 1
    }));
  };
  const handleMonthChange = newMonth => {
    goToMonth(newMonth);
    onMonthChange === null || onMonthChange === void 0 ? void 0 : onMonthChange(newMonth);
  };
  const handleNextYearClick = () => {
    handleMonthChange(add(displayMonth, {
      years: 1
    }));
  };
  const handlePreviousYearClick = () => {
    handleMonthChange(add(displayMonth, {
      years: -1
    }));
  };
  const displayIndex = displayMonths.findIndex(month => isSameMonth(displayMonth, month));
  let isFirst = displayIndex === 0;
  let isLast = displayIndex === displayMonths.length - 1;
  if (dir === 'rtl') {
    [isLast, isFirst] = [isFirst, isLast];
  }
  const captionLabel = React.createElement(CaptionLabel, {
    displayMonth: displayMonth
  });
  return React.createElement("div", {
    className: classNames.caption,
    style: styles.caption
  }, disableNavigation && captionLabel, !disableNavigation && React.createElement(React.Fragment, null, React.createElement(CaptionLabel, {
    displayMonth: displayMonth,
    onPreviousYearClick: handlePreviousYearClick,
    onNextYearClick: handleNextYearClick,
    onNextMonthClick: handleNextClick,
    onPreviousMonthClick: handlePreviousClick
  })));
}