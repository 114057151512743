import * as React from 'react';
import { connect } from 'react-redux';
import * as SystemRedux from '../../Redux/ActionsReducers/SystemRedux';
import { AdaptablePopover } from '../AdaptablePopover';
import { Flex } from 'rebass';
import UIHelper from '../UIHelper';
import { getAlertButtonStyle } from './Utilities/getAlertButtonStyle';
import { ActiveAlertsPanel } from './ActiveAlertsPanel';
class AlertViewPanelComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ShowMessage: false,
      Alerts: this.props.AdaptableAlerts
    };
  }
  componentDidUpdate() {
    if (this.state.Alerts.length != this.props.AdaptableAlerts.length) {
      this.setState({
        ShowMessage: true,
        Alerts: this.props.AdaptableAlerts
      });
    }
  }
  render() {
    const messageType = UIHelper.getMessageTypeFromAdaptableAlerts(this.props.AdaptableAlerts);
    const {
      color: buttonTextColor,
      background: buttonBackground
    } = getAlertButtonStyle(this.props.AdaptableAlerts);
    const collapsedText = this.props.AdaptableAlerts.length == 0 ? '0 Alerts' : this.props.AdaptableAlerts.length == 1 ? '1 Alert' : this.props.AdaptableAlerts.length + ' Alerts';
    const alertsPanel = React.createElement(ActiveAlertsPanel, null);
    const elementType = this.props.viewType === 'Toolbar' ? 'DashboardToolbar' : 'ToolPanel';
    return React.createElement(Flex, {
      alignItems: "stretch",
      className: `ab-${elementType}__Alert__wrap`
    }, React.createElement(Flex, {
      style: {
        borderRadius: 'var(--ab__border-radius)'
      },
      className: `ab-${elementType}__Alert__text`,
      marginRight: 2,
      padding: 2,
      color: buttonTextColor,
      backgroundColor: buttonBackground,
      fontSize: 'var( --ab-font-size-2)',
      alignItems: "center"
    }, collapsedText), this.props.AdaptableAlerts.length > 0 && React.createElement(Flex, {
      alignItems: "center"
    }, React.createElement(AdaptablePopover, {
      className: `ab-${elementType}__Alert__info`,
      headerText: "",
      // tooltipText="Alerts"
      bodyText: [alertsPanel],
      MessageType: messageType,
      useButton: true,
      showEvent: 'focus',
      hideEvent: "blur",
      popoverMinWidth: 400
    })));
  }
}
function mapStateToProps(state, ownProps) {
  return {
    AlertDefinitions: state.Alert.AlertDefinitions,
    AdaptableAlerts: state.System.AdaptableAlerts
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onDeleteAlert: alert => dispatch(SystemRedux.SystemAlertDelete(alert)),
    onDeleteAllAlert: alerts => dispatch(SystemRedux.SystemAlertDeleteAll(alerts))
  };
}
export const AlertViewPanelControl = connect(mapStateToProps, mapDispatchToProps)(AlertViewPanelComponent);