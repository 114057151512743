import * as React from 'react';
import { UIHelper } from '../../UIHelper';
import { StringExtensions } from '../../../Utilities/Extensions/StringExtensions';
import { PanelWithImage } from '../Panels/PanelWithImage';
import { Flex, Box, Text } from 'rebass';
import SimpleButton from '../../../components/SimpleButton';
import Input from '../../../components/Input';
import Dialog from '../../../components/Dialog';
import { CheckBox } from '../../../components/CheckBox';
import * as SystemRedux from '../../../Redux/ActionsReducers/SystemRedux';
import { useDispatch } from 'react-redux';
export const AdaptablePopupConfirmation = props => {
  const dispatch = useDispatch();
  const [disableDeleteConfirmation, setDisableDeleteConfirmation] = React.useState(false);
  const [promptText, setPromptText] = React.useState('');
  const header = props.header;
  const glyph = UIHelper.getGlyphByMessageType(props.messageType);
  function onCancelForm() {
    setPromptText('');
    props.onCancel();
  }
  function onConfirmmForm() {
    props.onConfirm(promptText);
    setPromptText('');
    if (disableDeleteConfirmation) {
      dispatch(SystemRedux.SystemDisableDeleteConfirmation());
    }
  }
  function changeContent(e) {
    setPromptText(e.target.value);
  }
  function canConfirm() {
    if (props.showInputBox) {
      return StringExtensions.IsNotNullOrEmpty(promptText);
    }
    return true;
  }
  if (!props.showPopup) {
    return React.createElement(React.Fragment, null);
  }
  return React.createElement(Dialog, {
    modal: true,
    "data-name": `confirmation-popup confirmation-popup--${props.messageType.toLowerCase()}`,
    isOpen: props.showPopup,
    onDismiss: props.onCancel,
    showCloseButton: false,
    style: {
      minHeight: 'auto',
      maxWidth: '50%'
    }
  }, React.createElement(PanelWithImage, {
    header: header,
    icon: glyph,
    variant: "primary"
  }, React.createElement("div", null, React.createElement("div", {
    style: {
      display: 'flex',
      alignItems: 'center'
    }
  }, props.messsage.split('\n').map(function (item, index) {
    return React.createElement(Text, {
      key: index,
      margin: 2
    }, item, React.createElement("br", null));
  })), React.createElement(Box, {
    pl: 2
  }, React.createElement(CheckBox, {
    checked: disableDeleteConfirmation,
    onChange: () => {
      setDisableDeleteConfirmation(!disableDeleteConfirmation);
    }
  }, "Do not show this again")), props.showInputBox && React.createElement(Box, {
    padding: 2,
    "data-name": "body"
  }, React.createElement("p", null, "Please enter a comment to confirm"), React.createElement(Input, {
    marginTop: 2,
    width: '100%',
    value: promptText,
    type: "string",
    placeholder: "Enter text",
    onChange: e => changeContent(e)
  })), React.createElement(Flex, {
    marginTop: 3,
    padding: 2,
    "data-name": "footer"
  }, React.createElement(SimpleButton, {
    tone: "error",
    "data-name": "delete",
    variant: "raised",
    disabled: !canConfirm(),
    onClick: () => onConfirmmForm()
  }, props.confirmButtonText), React.createElement("div", {
    style: {
      flex: 1
    }
  }), React.createElement(SimpleButton, {
    "data-name": "cancel",
    tone: "neutral",
    variant: "raised",
    onClick: () => onCancelForm()
  }, props.cancelButtonText)))));
};