import React from 'react';
import { Box, Flex, Text } from 'rebass';
import { getQlPredicateSymbol } from '../../../parser/src/predicate/mapQlPredicateToExpression';
import { useAdaptable } from '../../../View/AdaptableContext';
import AdaptableInput from '../../../View/Components/AdaptableInput';
import { ColumnSelector } from '../../../View/Components/Selectors/ColumnSelector';
import { FieldSelector } from '../../../View/Components/Selectors/FieldSelector';
import { PermittedValuesSelector } from '../../../View/Components/Selectors/PermittedValuesSelector';
import { CheckBox } from '../../CheckBox';
import DropdownButton from '../../DropdownButton';
import { Icon } from '../../icons';
import { InputGroup } from '../../InputGroup';
import { Select } from '../../Select';
import { useQueryBuilderContext } from './QueryBuilder';
import { isFieldValue, mapColumnExpressionToColumnId, mapExpressionFunctionTypeToColumnDataType, mapExpressionToFieldValue, mapFieldValueToExpression } from './utils';
export const PrimitiveColumnOrFieldSelector = props => {
  const adaptable = useAdaptable();
  const [type, setType] = React.useState(() => {
    return (
      // default to column
      !props.fieldOrColumn || props.fieldOrColumn.includes('[') ? 'column' : 'field'
    );
  });
  const hasFields = React.useMemo(() => {
    var _a;
    return ((_a = adaptable.api.expressionApi.internalApi.getAvailableFields()) === null || _a === void 0 ? void 0 : _a.length) > 0;
  }, []);
  const hasFieldsOrValueIsField = hasFields || isFieldValue(props.fieldOrColumn);
  let input = null;
  if (type === 'column') {
    const columnId = mapColumnExpressionToColumnId(props.fieldOrColumn);
    input = React.createElement(ColumnSelector, {
      value: columnId,
      type: props.type,
      onChange: columnId => {
        props.onChange(`[${columnId}]`);
      }
    });
  } else {
    input = React.createElement(FieldSelector, {
      value: mapExpressionToFieldValue(props.fieldOrColumn),
      type: props.type,
      onChange: fieldValue => {
        props.onChange(mapFieldValueToExpression(fieldValue));
      }
    });
  }
  const typeOptions = [{
    label: React.createElement(Flex, {
      alignItems: "center"
    }, React.createElement(Icon, {
      name: "grid"
    }), React.createElement(Text, {
      ml: 2
    }, "Column")),
    value: 'column',
    icon: 'grid'
  }, {
    label: React.createElement(Flex, {
      alignItems: "center"
    }, React.createElement(Icon, {
      name: "column-outline"
    }), React.createElement(Text, {
      ml: 2
    }, "Field")),
    value: 'field',
    icon: 'column-outline'
  }];
  return !hasFieldsOrValueIsField || props.hideFields ? React.createElement(Box, null, input) : React.createElement(InputGroup, {
    Component: Flex,
    "data-id": "query-first-arg-wrapper"
  }, React.createElement(Select, {
    renderSingleValue: value => {
      return React.createElement(React.Fragment, null, type === 'column' ? React.createElement(Icon, {
        name: "grid"
      }) : React.createElement(Icon, {
        name: "column-outline"
      }));
    },
    value: type,
    options: typeOptions,
    onChange: value => {
      props.onChange(null);
      setType(value);
    }
  }), input);
};
export const PrimiteValueInput = props => {
  const adaptable = useAdaptable();
  const hasFields = React.useMemo(() => {
    var _a;
    return ((_a = adaptable.api.expressionApi.internalApi.getAvailableFields()) === null || _a === void 0 ? void 0 : _a.length) > 0;
  }, []);
  const hasFieldsOrValueIsField = hasFields || isFieldValue(props.value);
  const [type, setType] = React.useState(() => {
    var _a, _b;
    if (typeof (props === null || props === void 0 ? void 0 : props.value) === 'string' && ((_a = props === null || props === void 0 ? void 0 : props.value) === null || _a === void 0 ? void 0 : _a.includes('['))) {
      return 'column-name';
    }
    if (typeof (props === null || props === void 0 ? void 0 : props.value) === 'string' && ((_b = props === null || props === void 0 ? void 0 : props.value) === null || _b === void 0 ? void 0 : _b.includes('FIELD'))) {
      return 'field';
    }
    return 'input-value';
  });
  const handleTypeChange = newType => {
    if (type !== newType) {
      // need to reset value
      props.onChange(undefined);
      setType(newType);
    }
  };
  const getEditor = () => {
    var _a, _b;
    const common = {
      'data-id': 'query-input'
    };
    switch (props.inputType) {
      case 'boolean':
        return React.createElement(CheckBox, Object.assign({}, common, {
          checked: props.value,
          onChange: () => props.onChange(!props.value)
        }));
      case 'number':
        return React.createElement(AdaptableInput, Object.assign({}, common, {
          type: "number",
          value: (_a = props.value) !== null && _a !== void 0 ? _a : '',
          onChange: event => {
            const value = event.target.value;
            if (value === '') {
              props.onChange(undefined);
            } else {
              props.onChange(parseFloat(value));
            }
          }
        }));
      case 'text':
        return React.createElement(AdaptableInput, Object.assign({}, common, {
          type: "text",
          value: (_b = props.value) !== null && _b !== void 0 ? _b : '',
          onChange: event => {
            props.onChange(event.target.value);
          }
        }));
      case 'date':
        // date format = 'DATE(2020-01-01)'
        const dateStr = typeof props.value === 'string' ? props.value.replace('DATE(', '').replace(')', '') : '';
        return React.createElement(AdaptableInput, Object.assign({}, common, {
          type: "date",
          value: dateStr !== null && dateStr !== void 0 ? dateStr : '',
          onChange: event => {
            const stringified = `DATE("${event.target.value}")`;
            props.onChange(stringified);
          }
        }));
      default:
        return React.createElement(React.Fragment, null);
    }
  };
  let editor = null;
  if (type === 'column-name') {
    const abColType = mapExpressionFunctionTypeToColumnDataType(props.inputType);
    editor = React.createElement(PrimitiveColumnOrFieldSelector, {
      hideFields: true,
      fieldOrColumn: props.value,
      type: abColType,
      onChange: columnId => {
        props.onChange(columnId);
      }
    });
  } else if (type === 'field') {
    editor = React.createElement(FieldSelector, {
      value: mapExpressionToFieldValue(props.value),
      onChange: fieldValue => {
        props.onChange(mapFieldValueToExpression(fieldValue));
      }
    });
  } else if (!['date', 'boolean'].includes(props.inputType)) {
    editor = React.createElement(PermittedValuesSelector, {
      allowNewValues: true,
      value: props.value,
      columnId: mapColumnExpressionToColumnId(props.lefthandColumnIdParam),
      onChange: value => {
        props.onChange(value);
      }
    });
  } else {
    editor = getEditor();
  }
  const options = [{
    label: React.createElement(Flex, {
      alignItems: "center"
    }, React.createElement(Icon, {
      name: "columns"
    }), React.createElement(Text, {
      ml: 2
    }, "Column")),
    icon: 'columns',
    value: 'column-name'
  }, {
    label: React.createElement(Flex, {
      alignItems: "center"
    }, React.createElement(Icon, {
      name: "edit"
    }), React.createElement(Text, {
      ml: 2
    }, "Value")),
    icon: 'edit',
    value: 'input-value'
  }];
  if (hasFieldsOrValueIsField || type === 'field') {
    options.push({
      label: React.createElement(Flex, {
        alignItems: "center"
      }, React.createElement(Icon, {
        name: "column-outline"
      }), React.createElement(Text, {
        ml: 2
      }, "Field")),
      icon: 'column-outline',
      value: 'field'
    });
  }
  const typeOption = options.find(option => option.value === type);
  return React.createElement(InputGroup, {
    Component: Flex,
    "data-id": "query-input-wrapper",
    mr: 2
  }, React.createElement(Select, {
    renderSingleValue: value => {
      return React.createElement(React.Fragment, null, typeOption.value === 'column-name' ? React.createElement(Icon, {
        name: "grid"
      }) : React.createElement(Icon, {
        name: "edit"
      }));
    },
    value: typeOption.value,
    options: options,
    onChange: value => handleTypeChange(value)
  }), editor);
};
export const PrimitiveMultiValueInput = props => {
  return React.createElement(PermittedValuesSelector, {
    isMulti: true,
    allowNewValues: true,
    value: props.value,
    columnId: mapColumnExpressionToColumnId(props.lefthandColumnIdParam),
    onChange: value => {
      props.onChange(value);
    }
  });
};
const SymbolToIcon = props => {
  switch (props.symbol) {
    case '=':
      return React.createElement(Icon, {
        name: "equals"
      });
    case '!=':
      return React.createElement(Icon, {
        name: "not-equal"
      });
    case '>':
      return React.createElement(Icon, {
        name: "greater-than"
      });
    case '>=':
      return React.createElement(Icon, {
        name: "greater-than-or-equal"
      });
    case '<':
      return React.createElement(Icon, {
        name: "less-than"
      });
    case '<=':
      return React.createElement(Icon, {
        name: "less-than-or-equal"
      });
    default:
      return React.createElement(React.Fragment, null, props.symbol);
  }
};
export const ExpressionSelector = props => {
  var _a;
  const {
    getExpressions
  } = useQueryBuilderContext();
  const expressions = props.dataType ? getExpressions(props.dataType) : [];
  return React.createElement(DropdownButton, {
    "data-id": "expression-selector",
    "data-value": props.value,
    variant: "raised",
    tone: 'accent',
    columns: ['label'],
    items: expressions.map(expression => ({
      label: React.createElement(SymbolToIcon, {
        symbol: getQlPredicateSymbol(expression)
      }),
      onClick: () => {
        if (expression !== props.value) {
          props.onExpressionChange(expression);
        }
      }
    }))
  }, React.createElement(SymbolToIcon, {
    symbol: (_a = getQlPredicateSymbol(props.value)) !== null && _a !== void 0 ? _a : 'Select Operator'
  }));
};
export const CombinatorSelector = props => {
  return React.createElement(DropdownButton, {
    "data-id": "combinator-selector",
    "data-value": props.value,
    columns: ['label'],
    variant: "raised",
    tone: "accent",
    items: [{
      label: 'AND',
      onClick: () => props.onChange('AND')
    }, {
      label: 'OR',
      onClick: () => props.onChange('OR')
    }]
  }, props.value);
};