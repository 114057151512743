import * as React from 'react';
import { Flex } from 'rebass';
import DropdownButton from '../../components/DropdownButton';
import FieldWrap from '../../components/FieldWrap';
import FormLayout, { FormRow } from '../../components/FormLayout';
import Input from '../../components/Input';
import Panel from '../../components/Panel';
import StringExtensions from '../../Utilities/Extensions/StringExtensions';
import { ButtonClear } from '../Components/Buttons/ButtonClear';
import { ButtonExpand } from '../Components/Buttons/ButtonExpand';
import { ButtonInvalid } from '../Components/Buttons/ButtonInvalid';
import { ButtonPause } from '../Components/Buttons/ButtonPause';
import { ButtonPlay } from '../Components/Buttons/ButtonPlay';
import { ButtonSave } from '../Components/Buttons/ButtonSave';
import { ButtonUnsuspend } from '../Components/Buttons/ButtonUnsuspend';
import { PopupPanel } from '../Components/Popups/AdaptablePopup/PopupPanel';
import { NamedQuerySelector } from './NamedQuerySelector';
import { useGridFilterExpressionEditor } from './useGridFilterExpressionEditor';
export const GridFilterPopup = () => {
  const {
    cachedQueries,
    expression,
    setExpression,
    isExpressionNamedQuery,
    isExpressionValid,
    isSuspended,
    gridFilter,
    namedQueries,
    availableColumns,
    runQuery,
    onExpand,
    clearQuery,
    namedQueryModuleAccessLevel,
    saveQuery,
    suspendGridFilter,
    unSuspendGridFilter,
    setGridFilterExpression,
    gridFilterAccessLevel,
    gridFilterHelpPage,
    gridFilterLinkDisabled,
    isReadOnly
  } = useGridFilterExpressionEditor();
  const handleEnter = e => {
    if (e.key === 'Enter') {
      runQuery();
    }
  };
  const disabled = isReadOnly || isSuspended || gridFilterAccessLevel === 'ReadOnly';
  return React.createElement(PopupPanel, {
    className: "ab-GridFilter",
    headerText: "Grid Filter",
    glyphicon: "grid-filter",
    infoLink: gridFilterHelpPage,
    infoLinkDisabled: gridFilterLinkDisabled
  }, React.createElement(Panel, {
    bodyProps: {
      height: '100%'
    },
    height: "100%"
  }, React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Expression"
  }, React.createElement(FieldWrap, {
    marginRight: 1,
    width: "100%"
  }, React.createElement(ButtonExpand, {
    disabled: disabled,
    variant: "text",
    tone: "neutral",
    onClick: onExpand,
    tooltip: "Edit the Expression in UI",
    marginLeft: 1
  }), React.createElement(Input, {
    onKeyDown: handleEnter,
    disabled: disabled,
    type: "text",
    "data-name": "grid-filter-input",
    placeholder: "Grid Filter",
    spellCheck: false,
    value: expression,
    onChange: x => setExpression(x.target.value),
    style: {
      fontFamily: 'monospace',
      fontSize: 12
    }
  }), isExpressionValid && React.createElement(ButtonPlay, {
    onClick: () => runQuery(),
    tooltip: '',
    accessLevel: gridFilterAccessLevel,
    variant: "text",
    tone: "neutral",
    disabled: disabled || expression == '' || expression == (gridFilter === null || gridFilter === void 0 ? void 0 : gridFilter.Expression),
    marginRight: 1
  }), gridFilter && !isExpressionValid && React.createElement(ButtonInvalid, {
    variant: "text",
    tone: "neutral",
    tooltip: "Invalid Grid Filter",
    marginRight: 1
  }), ' ', StringExtensions.IsNotNullOrEmpty(expression) && React.createElement(ButtonClear, {
    onClick: () => clearQuery(),
    tooltip: "Clear Grid Filter",
    accessLevel: gridFilterAccessLevel
  }))), React.createElement(FormRow, {
    label: ""
  }, React.createElement(Flex, {
    justifyContent: "space-around"
  }, React.createElement(ButtonSave, {
    onClick: () => saveQuery(),
    tooltip: "Save as Named Query",
    accessLevel: namedQueryModuleAccessLevel,
    disabled: disabled || !isExpressionValid || isExpressionNamedQuery || expression == '',
    variant: "text",
    tone: "neutral",
    marginRight: 1
  }, "Save"), isSuspended ? React.createElement(ButtonUnsuspend, {
    onClick: () => unSuspendGridFilter(),
    tooltip: "Unsuspend Grid Filter",
    accessLevel: gridFilterAccessLevel,
    disabled: disabled || !isExpressionValid,
    variant: "text",
    tone: "neutral",
    marginRight: 1
  }, "Resume") : React.createElement(ButtonPause, {
    onClick: () => suspendGridFilter(),
    tooltip: "Suspend Grid Filter",
    accessLevel: gridFilterAccessLevel,
    disabled: disabled || !isExpressionValid,
    variant: "text",
    tone: "neutral",
    marginRight: 1
  }, "Suspend"), React.createElement(DropdownButton, {
    disabled: disabled,
    variant: "text",
    items: availableColumns,
    marginRight: 1,
    tooltip: "Pick Columns",
    icon: "list",
    iconPosition: "start"
  }, "Columns"), React.createElement(NamedQuerySelector, {
    disabled: disabled,
    namedQueries: namedQueries,
    cachedQueries: cachedQueries,
    currentQuery: gridFilter === null || gridFilter === void 0 ? void 0 : gridFilter.Expression,
    onSelect: query => runQuery(query),
    setGridFilterExpression: query => setGridFilterExpression(query)
  }, "Named Queries"))))));
};