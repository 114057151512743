import * as React from 'react';
import { connect } from 'react-redux';
import { Flex } from 'rebass';
import { Select } from '../../components/Select';
import join from '../../components/utils/join';
import * as GridRedux from '../../Redux/ActionsReducers/GridRedux';
import * as LayoutRedux from '../../Redux/ActionsReducers/LayoutRedux';
import * as GeneralConstants from '../../Utilities/Constants/GeneralConstants';
import AdaptableHelper from '../../Utilities/Helpers/AdaptableHelper';
import { ButtonClone } from '../Components/Buttons/ButtonClone';
import { ButtonDelete } from '../Components/Buttons/ButtonDelete';
import { ButtonEdit } from '../Components/Buttons/ButtonEdit';
import { ButtonNew } from '../Components/Buttons/ButtonNew';
import { ButtonSave } from '../Components/Buttons/ButtonSave';
export const COMPONENT_LAYOUT_POPUP_NAME = 'LayoutEditorStandalonePopup';
class LayoutViewPanelComponent extends React.Component {
  render() {
    let layoutEntity = this.props.Layouts.find(x => x.Name == this.props.CurrentLayoutName || x.Uuid == this.props.CurrentLayoutName);
    // the global access level (Layout Entitlement)
    const cloneAccessLevel = this.props.accessLevel;
    const newAccessLevel = this.props.accessLevel;
    const accessLevel = AdaptableHelper.getAccessLevelForObject(layoutEntity, this.props.accessLevel);
    const isManualSaveLayout = !this.props.api.layoutApi.shouldAutoSaveLayouts();
    const availableLayoutOptions = this.props.Layouts.map((layout, index) => {
      return Object.assign(Object.assign({}, layout), {
        label: layout.Name,
        value: layout.Name,
        onClick: () => this.props.onSelectLayout(layout.Name)
      });
    });
    const elementType = this.props.viewType === 'Toolbar' ? 'DashboardToolbar' : 'ToolPanel';
    let layoutSelectStyle = elementType === 'ToolPanel' ? {
      minWidth: '100%'
    } : {};
    return React.createElement(Flex, {
      flexDirection: "row",
      className: `ab-${elementType}__Layout__wrap`,
      flexWrap: this.props.viewType === 'ToolPanel' ? 'wrap' : 'nowrap'
    }, React.createElement(Flex, {
      style: layoutSelectStyle,
      flex: 1
    }, React.createElement(Select, {
      style: {
        width: '100%'
      },
      options: availableLayoutOptions,
      className: `ab-${elementType}__Layout__select`,
      value: layoutEntity ? layoutEntity.Name : null,
      onChange: layout => this.props.onSelectLayout(layout)
    })), React.createElement(Flex, {
      flexDirection: "row",
      className: join(this.props.accessLevel == 'ReadOnly' ? GeneralConstants.READ_ONLY_STYLE : '', `ab-${elementType}__Layout__wrap`)
    }, isManualSaveLayout && React.createElement(ButtonSave, {
      className: `ab-${elementType}__Layout__save`,
      onClick: () => this.onSaveLayout(),
      tooltip: "Save Changes to Current Layout",
      disabled: !this.props.CanSave,
      accessLevel: accessLevel
    }), React.createElement(ButtonEdit, {
      onClick: () => this.props.api.layoutApi.showLayoutEditor(layoutEntity.Name),
      tooltip: "Edit Layout",
      className: `ab-${elementType}__Layout__edit`,
      accessLevel: accessLevel
    }), React.createElement(ButtonClone, {
      onClick: () => this.props.api.layoutApi.showLayoutEditor(layoutEntity.Name, 'Clone'),
      tooltip: "Clone Layout",
      className: `ab-${elementType}__Layout__clone`,
      tone: "neutral",
      variant: "text",
      children: null,
      accessLevel: cloneAccessLevel
    }), React.createElement(ButtonNew, {
      children: null,
      tone: "neutral",
      variant: "text",
      className: `ab-${elementType}__Layout__new`,
      onClick: () => this.props.api.layoutApi.showLayoutEditor(null, 'New'),
      tooltip: "Create a new Layout",
      accessLevel: newAccessLevel
    }), React.createElement(ButtonDelete, {
      tooltip: "Delete Layout",
      disabled: this.props.Layouts.length <= 1,
      className: `ab-${elementType}__Layout__delete`,
      ConfirmAction: LayoutRedux.LayoutDelete(layoutEntity),
      ConfirmationMsg: "Are you sure you want to delete '" + this.props.CurrentLayoutName + "'?",
      ConfirmationTitle: 'Delete Layout',
      accessLevel: accessLevel
    })));
  }
  onSaveLayout() {
    const currentLayoutObject = this.props.CurrentDraftLayout;
    if (currentLayoutObject) {
      this.props.onSaveLayout(currentLayoutObject);
    }
  }
}
function mapStateToProps(state, ownProps) {
  const CurrentLayoutName = state.Layout.CurrentLayout;
  const Layouts = state.Layout.Layouts || [];
  const selectedLayout = Layouts.find(l => l.Name === CurrentLayoutName);
  const api = ownProps.api;
  return {
    CurrentLayoutName,
    CurrentDraftLayout: state.Grid.CurrentLayout || selectedLayout,
    Layouts,
    CanSave: state.Grid.CurrentLayout && !api.layoutApi.internalApi.areDraftAndCurrentLayoutEqual()
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onSelectLayout: layoutName => dispatch(LayoutRedux.LayoutSelect(layoutName)),
    onSaveLayout: layout => {
      dispatch(LayoutRedux.LayoutSave(layout));
      // Cannot be se to null, because it needs to be up to date when it is updated
      dispatch(GridRedux.LayoutUpdateCurrentDraft(layout));
    }
  };
}
export let LayoutViewPanelControl = connect(mapStateToProps, mapDispatchToProps)(LayoutViewPanelComponent);