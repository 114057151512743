import { EMPTY_ARRAY } from '../../Utilities/Constants/GeneralConstants';
/**
 * @ReduxAction An Application Data Entry has been added
 */
export const APPLICATION_DATA_ENTRY_ADD = 'APPLICATION_DATA_ENTRY_ADD';
/**
 * @ReduxAction An Application Data Entry has been edited
 */
export const APPLICATION_DATA_ENTRY_EDIT = 'APPLICATION_DATA_ENTRY_EDIT';
/**
 * @ReduxAction An Application Data Entry has been deleted
 */
export const APPLICATION_DATA_ENTRY_DELETE = 'APPLICATION_DATA_ENTRY_DELETE';
export const ApplicationDataEntryAdd = applicationDataEntry => ({
  type: APPLICATION_DATA_ENTRY_ADD,
  applicationDataEntry
});
export const ApplicationDataEntryEdit = applicationDataEntry => ({
  type: APPLICATION_DATA_ENTRY_EDIT,
  applicationDataEntry
});
export const ApplicationDataEntryDelete = applicationDataEntry => ({
  type: APPLICATION_DATA_ENTRY_DELETE,
  applicationDataEntry
});
const initialState = {
  ApplicationDataEntries: EMPTY_ARRAY
};
export const ApplicationReducer = (state = initialState, action) => {
  let applicationDataEntries;
  let index;
  switch (action.type) {
    case APPLICATION_DATA_ENTRY_ADD:
      const actionTypedAdd = action;
      applicationDataEntries = [].concat(state.ApplicationDataEntries);
      applicationDataEntries.push(actionTypedAdd.applicationDataEntry);
      return Object.assign({}, state, {
        ApplicationDataEntries: applicationDataEntries
      });
    case APPLICATION_DATA_ENTRY_EDIT:
      const actionTypedUpdate = action;
      applicationDataEntries = [].concat(state.ApplicationDataEntries);
      index = applicationDataEntries.findIndex(fe => fe.Key == actionTypedUpdate.applicationDataEntry.Key);
      applicationDataEntries[index] = actionTypedUpdate.applicationDataEntry;
      return Object.assign({}, state, {
        ApplicationDataEntries: applicationDataEntries
      });
    case APPLICATION_DATA_ENTRY_DELETE:
      const actionTypedDelete = action;
      applicationDataEntries = [].concat(state.ApplicationDataEntries);
      index = applicationDataEntries.findIndex(a => a.Key == actionTypedDelete.applicationDataEntry.Key);
      applicationDataEntries.splice(index, 1);
      return Object.assign({}, state, {
        ApplicationDataEntries: applicationDataEntries
      });
    default:
      return state;
  }
};