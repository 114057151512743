import * as React from 'react';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
import { EntityRulesEditor, EntityRulesSummary } from '../../Components/EntityRulesEditor';
import { CodeBlock } from '../../../components/CodeBlock';
export function PlusMinusRuleSummary() {
  const {
    data
  } = useOnePageAdaptableWizardContext();
  return React.createElement(EntityRulesSummary, {
    data: data,
    renderPredicate: contents => {
      return React.createElement(React.Fragment, null, "Apply Plus Minus on ", React.createElement(CodeBlock, null, contents));
    },
    renderQueryExpression: contents => {
      return React.createElement(React.Fragment, null, "Apply Plus Minus when", ' ', React.createElement("b", null, React.createElement(CodeBlock, null, contents)));
    }
  });
}
export function PlusMinusRuleWizardSection(props) {
  var _a;
  const {
    data,
    moduleInfo
  } = useOnePageAdaptableWizardContext();
  const plusMinusNudge = Object.assign(Object.assign({}, data), {
    Rule: (_a = data === null || data === void 0 ? void 0 : data.Rule) !== null && _a !== void 0 ? _a : {
      BooleanExpression: ''
    }
  });
  return React.createElement(EntityRulesEditor, {
    module: moduleInfo.ModuleName,
    // min-height 0 so the container does not min-size from it's children
    // https://stackoverflow.com/questions/33605552/how-to-prevent-a-flex-item-height-to-overflow-due-to-its-content
    flexProps: {
      style: {
        minHeight: 0
      }
    },
    defaultPredicateId: props.defaultPredicateId,
    predicateDefs: [],
    showAggregation: false,
    showObservable: false,
    showPredicate: false,
    data: plusMinusNudge,
    // the cast is to convince TS that it still a PlusMinusNudge
    // even when Rule is not optional, the type is inferred from `plusMinusNudge`
    onChange: plusMinus => props.onChange(plusMinus),
    descriptions: {
      selectPredicate: null,
      useBooleanQuery: React.createElement(React.Fragment, null, "Use an BooleanQuery if ", React.createElement("i", null, "Scope"), " is 'All Columns' - so any data change may be evaluated in a complex BooleanExpression"),
      useObservableQuery: null,
      useAggregationQuery: null
    }
  });
}