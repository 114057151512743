import * as React from 'react';
import { ToggleButton } from '../../../../components/ToggleButton';
export const SuspendToggleButton = React.memo(({
  suspendableObject,
  onUnSuspend,
  onSuspend,
  style,
  disabled,
  accessLevel
}) => {
  const handleOnChange = React.useCallback(checked => {
    if (checked) {
      onUnSuspend(suspendableObject);
    } else {
      onSuspend(suspendableObject);
    }
  }, [onUnSuspend, onSuspend]);
  const preparedDisabled = accessLevel && accessLevel === 'ReadOnly' || disabled;
  const isSuspended = suspendableObject.IsSuspended;
  return React.createElement(ToggleButton, {
    className: "ab-SuspendButton",
    "data-name": suspendableObject.IsSuspended ? 'un-suspend' : 'suspend',
    disabled: preparedDisabled,
    style: style,
    onChange: handleOnChange,
    checked: !isSuspended
  }, isSuspended ? 'inactive' : 'active');
});