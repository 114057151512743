import * as React from 'react';
import { connect } from 'react-redux';
import * as SmartEditRedux from '../../Redux/ActionsReducers/SmartEditRedux';
import * as SystemRedux from '../../Redux/ActionsReducers/SystemRedux';
import { MathOperation } from '../../PredefinedConfig/Common/Enums';
import { PanelWithImage } from '../Components/Panels/PanelWithImage';
import { AdaptablePopover } from '../AdaptablePopover';
import { EnumExtensions } from '../../Utilities/Extensions/EnumExtensions';
import { PreviewResultsPanel } from '../Components/PreviewResultsPanel';
import { PreviewHelper } from '../../Utilities/Helpers/PreviewHelper';
import { StringExtensions } from '../../Utilities/Extensions/StringExtensions';
import Input from '../../components/Input';
import { Flex } from 'rebass';
import DropdownButton from '../../components/DropdownButton';
import SimpleButton from '../../components/SimpleButton';
const preventDefault = e => e.preventDefault();
class SmartEditPopupComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowingError: false,
      errorText: ''
    };
  }
  componentDidMount() {
    this.props.onSmartEditCheckSelectedCells();
  }
  render() {
    let col;
    if (this.props.PreviewInfo) {
      col = this.props.PreviewInfo.column;
    }
    let globalValidationMessage = PreviewHelper.GetValidationMessage(this.props.PreviewInfo, `${this.props.SmartEditValue}`);
    let showPanel = this.props.PreviewInfo && StringExtensions.IsNotNullOrEmpty(`${this.props.SmartEditValue}`);
    let previewPanel = showPanel ? React.createElement(PreviewResultsPanel, {
      style: {
        flex: '1 1 100%',
        overflow: 'initial',
        height: '100%'
      },
      previewInfo: this.props.PreviewInfo,
      api: this.props.api,
      selectedColumn: col,
      showPanel: showPanel,
      showHeader: true
    }) : null;
    const operationMenuItems = EnumExtensions.getNames(MathOperation).map((mathOperation, index) => {
      return {
        label: mathOperation,
        onClick: () => {
          this.props.onSmartEditOperationChange(mathOperation);
        }
      };
    });
    const customOperations = this.props.api.smartEditApi.getSmartEditCustomOperations();
    if (customOperations === null || customOperations === void 0 ? void 0 : customOperations.length) {
      operationMenuItems.push(...customOperations.map(operation => {
        return {
          onClick: () => this.props.onSmartEditOperationChange(operation),
          label: operation.name
        };
      }));
    }
    return React.createElement(PanelWithImage, {
      flex: 1,
      style: {
        height: '100%'
      },
      variant: "primary",
      bodyProps: {
        style: {
          display: 'flex',
          flexFlow: 'column'
        }
      },
      bodyScroll: true,
      glyphicon: this.props.moduleInfo.Glyph,
      header: this.props.moduleInfo.FriendlyName,
      infoLink: this.props.moduleInfo.HelpPage,
      infoLinkDisabled: !this.props.api.internalApi.isDocumentationLinksDisplayed(),
      onKeyDown: e => {
        if (e.key === 'Enter') {
          this.submit();
        }
      }
    }, React.createElement(Flex, {
      flexDirection: "row",
      padding: 2,
      "data-name": "smart-edit-operation"
    }, React.createElement(DropdownButton, {
      "data-name": "smart-edit-operation-dropdown",
      items: operationMenuItems,
      columns: ['label'],
      onMouseDown: preventDefault
    }, typeof this.props.SmartEditOperation === 'object' ? this.props.SmartEditOperation.name : this.props.SmartEditOperation), React.createElement(Input, {
      "data-name": "smart-edit-value",
      value: this.props.SmartEditValue.toString(),
      marginLeft: 2,
      marginRight: 2,
      type: "number",
      placeholder: "Enter a Number",
      onChange: e => this.onSmartEditValueChange(e)
    }), React.createElement(SimpleButton, {
      "data-name": "smart-edit-apply-button",
      tone: this.getButtonStyle(),
      variant: "raised",
      disabled: StringExtensions.IsNullOrEmpty(`${this.props.SmartEditValue}`) || this.props.PreviewInfo && this.props.PreviewInfo.previewValidationSummary.validationResult == 'All',
      onClick: () => {
        this.submit();
      },
      marginRight: 2
    }, "Apply Smart Edit"), ' ', this.props.PreviewInfo && this.props.PreviewInfo.previewValidationSummary.validationResult != 'None' && React.createElement(AdaptablePopover, {
      headerText: 'Validation Error',
      bodyText: [globalValidationMessage],
      MessageType: 'Error'
    })), previewPanel);
  }
  submit() {
    this.onApplySmartEdit();
  }
  onSmartEditValueChange(event) {
    const e = event.target;
    this.props.onSmartEditValueChange(Number(e.value));
  }
  onApplySmartEdit() {
    this.props.onRunSmartEdit();
  }
  getButtonStyle() {
    if (this.props.PreviewInfo) {
      if (this.props.PreviewInfo.previewValidationSummary.validationResult == 'All') {
        return 'neutral';
      }
      if (this.props.PreviewInfo.previewValidationSummary.validationResult == 'Some') {
        return 'error';
      }
    }
    return 'success';
  }
}
function mapStateToProps(state, ownProps) {
  return {
    SmartEditValue: state.System.SmartEditValue,
    SmartEditOperation: state.System.SmartEditOperation,
    PreviewInfo: state.System.SmartEditPreviewInfo
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onSmartEditValueChange: value => dispatch(SystemRedux.SmartEditChangeValue(value)),
    onSmartEditOperationChange: SmartEditOperation => dispatch(SystemRedux.SmartEditChangeOperation(SmartEditOperation)),
    onSmartEditCheckSelectedCells: () => dispatch(SystemRedux.SmartEditCheckCellSelection()),
    onRunSmartEdit: () => dispatch(SmartEditRedux.SmartEditRun(false))
  };
}
export let SmartEditPopup = connect(mapStateToProps, mapDispatchToProps)(SmartEditPopupComponent);