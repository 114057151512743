import * as React from 'react';
import { Box, Flex } from 'rebass';
import FormLayout, { FormRow } from '../../../../components/FormLayout';
import Input from '../../../../components/Input';
import Radio from '../../../../components/Radio';
import { Tabs } from '../../../../components/Tabs';
import { Tag } from '../../../../components/Tag';
import { useOnePageAdaptableWizardContext } from '../../../Wizard/OnePageAdaptableWizard';
export const SettingsSectionSummary = () => {
  const {
    data: layout
  } = useOnePageAdaptableWizardContext();
  return React.createElement(Box, null, React.createElement(Tag, {
    mr: 2
  }, "Layout Name: ", layout.Name), React.createElement(Tag, {
    mr: 2
  }, "Layout Grid Type: ", layout.EnablePivot ? 'Pivot' : 'Table'), React.createElement(Tag, {
    mr: 2
  }, "Suppress Aggregation Function in Header: ", layout.SuppressAggFuncInHeader ? 'Yes' : 'No'));
};
export const SettingsSection = props => {
  var _a;
  const {
    data: layout
  } = useOnePageAdaptableWizardContext();
  const onNameChange = event => {
    props.onChange(Object.assign(Object.assign({}, layout), {
      Name: event.target.value
    }));
  };
  const handlePivotChange = checked => {
    props.onChange(Object.assign(Object.assign({}, layout), {
      EnablePivot: checked
    }));
  };
  return React.createElement(React.Fragment, null, React.createElement(Tabs, {
    mb: 3
  }, React.createElement(Tabs.Tab, null, "Settings"), React.createElement(Tabs.Content, null, React.createElement(Flex, {
    flexDirection: "row"
  }, React.createElement(FormLayout, {
    width: "100%",
    style: {
      maxWidth: 300
    }
  }, React.createElement(FormRow, {
    label: "Name"
  }, React.createElement(Input, {
    width: "100%",
    "data-name": "layout-name",
    onChange: onNameChange,
    value: (_a = layout === null || layout === void 0 ? void 0 : layout.Name) !== null && _a !== void 0 ? _a : ''
  })))))), React.createElement(Tabs, {
    mb: 2
  }, React.createElement(Tabs.Tab, null, "Grid Type"), React.createElement(Tabs.Content, null, React.createElement(Flex, {
    flexDirection: "column"
  }, React.createElement(Radio, {
    "data-name": "layout-type-table",
    onClick: () => handlePivotChange(false),
    checked: !Boolean(layout.EnablePivot)
  }, "Table"), React.createElement(Radio, {
    "data-name": "layout-type-pivot",
    onClick: () => handlePivotChange(true),
    checked: Boolean(layout.EnablePivot)
  }, "Pivot")))));
};