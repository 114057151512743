import * as React from 'react';
import OverlayTrigger from '../OverlayTrigger';
const Tooltip = ({
  label,
  children
}) => {
  return React.createElement(OverlayTrigger, {
    defaultZIndex: 2000000,
    className: "ab-Tooltip",
    render: () => label
  }, children);
};
export default Tooltip;