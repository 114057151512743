import { __rest } from "tslib";
import * as React from 'react';
import join from '../utils/join';
const HUNDRED_PERCENT_SIZE = 24;
const DEFAULT_SIZE = 20;
export const getSize = size => Math.round(size * (DEFAULT_SIZE / HUNDRED_PERCENT_SIZE));
export default (_a => {
  var {
      children,
      size = DEFAULT_SIZE,
      name,
      tabIndex,
      color
    } = _a,
    props = __rest(_a, ["children", "size", "name", "tabIndex", "color"]);
  size = getSize(size);
  return React.createElement("svg", Object.assign({
    width: size,
    height: size,
    viewBox: "0 0 24 24",
    tabIndex: tabIndex,
    "data-id": name,
    "data-name": "AdaptableIcon"
  }, props, {
    className: join(props.className, `ab-Icon ab-Icon--${name}`),
    style: Object.assign({
      verticalAlign: 'middle',
      fill: color !== null && color !== void 0 ? color : 'currentColor'
    }, props.style)
  }), children);
});