import { __rest } from "tslib";
import * as React from 'react';
import { Datepicker } from '../../../components/Datepicker';
import { useAdaptable } from '../../AdaptableContext';
import useProperty from '../../../components/utils/useProperty';
import { dateToISO, parseDateValue } from '../../../Utilities/Helpers/DateHelper';
const AdaptableDateInput = React.forwardRef((props, ref) => {
  const dateInputOptions = useAdaptable().adaptableOptions.userInterfaceOptions.dateInputOptions;
  const {
      value: _,
      defaultValue: __,
      onChange,
      required,
      disabled
    } = props,
    inputProps = __rest(props, ["value", "defaultValue", "onChange", "required", "disabled"]);
  const [value, setValue] = useProperty(props, 'value', undefined, {
    onChange: dateString => {
      var _a;
      // wrap date value in FormEvent in order to keep the external API unchanged
      return (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, {
        target: {
          // ALWAYS trigger onChange with the ISO format
          value: dateString
        }
      });
    }
  });
  const dateValue = value ? parseDateValue(value) : null;
  const datepickerProps = Object.assign({
    // this is OK as long as we do not support range datepicker
    value: dateValue,
    onChange: dateValue => {
      var _a;
      // convert to ISO8601 string format
      // see https://developer.mozilla.org/en-US/docs/Web/HTML/Date_and_time_formats#date_strings
      return setValue((_a = dateToISO(dateValue)) !== null && _a !== void 0 ? _a : '');
    },
    required,
    disabled,
    dateProps: {
      format: dateInputOptions.dateFormat,
      locale: dateInputOptions.locale
    },
    showWeekNumber: dateInputOptions.showWeekNumber,
    showOutsideDays: dateInputOptions.showOutsideDays,
    datepickerButtons: dateInputOptions.datepickerButtons
  }, inputProps);
  return React.createElement(Datepicker, Object.assign({
    ref: ref
  }, datepickerProps));
});
export default AdaptableDateInput;