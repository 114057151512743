import * as React from 'react';
import { Box, Flex, Text } from 'rebass';
import Radio from '../../../components/Radio';
import { Tabs } from '../../../components/Tabs';
import { NewScopeComponent, renderScopeSummary } from '../../Components/NewScopeComponent';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
export const isValidReportColumnsScope = report => {
  if (report.ReportColumnScope === 'ScopeColumns') {
    return report.Scope != null ? true : 'Column scope is not defined';
  }
  return true;
};
export const renderReportColumnsSummary = report => {
  return React.createElement(Box, {
    fontSize: 2
  }, report.ReportColumnScope === 'AllColumns' ? React.createElement(React.Fragment, null, "Include ", React.createElement("b", null, "all Columns"), " in the Report") : null, report.ReportColumnScope === 'VisibleColumns' ? React.createElement(React.Fragment, null, "Include ", React.createElement("b", null, "only visible Columns"), " in the Report") : null, report.ReportColumnScope === 'ScopeColumns' ? React.createElement(React.Fragment, null, renderScopeSummary(report.Scope, {
    scopeColumns: 'Report Columns',
    scopeWholeRow: 'not available',
    scopeDataTypes: 'Report will include columns with the following types'
  })) : null);
};
export const ReportColumnsWizardSection = props => {
  const {
    api,
    data
  } = useOnePageAdaptableWizardContext();
  return React.createElement(Flex, {
    flexDirection: "column",
    style: {
      height: '100%'
    },
    padding: 2
  }, React.createElement(Tabs, {
    m: 2,
    value: data.ReportColumnScope,
    onValueChange: ReportColumnScope => {
      const report = Object.assign(Object.assign({}, data), {
        ReportColumnScope
      });
      if (report.ReportColumnScope !== 'ScopeColumns') {
        delete report.Scope;
      } else {
        if (!report.Scope) {
          report.Scope = {
            ColumnIds: []
          };
        }
      }
      props.onChange(report);
    }
  }, React.createElement(Tabs.Tab, {
    value: "AllColumns"
  }, React.createElement(Radio, {
    margin: 0,
    checked: data.ReportColumnScope == 'AllColumns',
    tabIndex: -1
  }, "All Columns")), React.createElement(Tabs.Tab, {
    value: "VisibleColumns"
  }, React.createElement(Radio, {
    margin: 0,
    checked: data.ReportColumnScope == 'VisibleColumns',
    tabIndex: -1
  }, "Visible Columns")), React.createElement(Tabs.Tab, {
    value: "ScopeColumns"
  }, React.createElement(Radio, {
    margin: 0,
    checked: data.ReportColumnScope == 'ScopeColumns',
    tabIndex: -1
  }, "Bespoke Columns")), React.createElement(Tabs.Content, {
    value: "AllColumns"
  }, React.createElement(Text, {
    fontSize: 2,
    padding: 2
  }, "All Columns in the datasource will be included in the Report, whether visible or not at time of export")), React.createElement(Tabs.Content, {
    value: "VisibleColumns"
  }, React.createElement(Text, {
    fontSize: 2,
    padding: 2
  }, "Only Columns that are visible at the time the Report is exported will be included")), React.createElement(Tabs.Content, {
    value: "BespokeColumns"
  }, React.createElement(Text, {
    fontSize: 2,
    padding: 2
  }, "Only selected Columns (which you will choose in the next step) will be exported - whether visible or not"))), data.ReportColumnScope === 'ScopeColumns' ? React.createElement(Box, {
    padding: 2,
    flex: 1,
    style: {
      overflow: 'auto'
    }
  }, React.createElement(NewScopeComponent, {
    hideWholeRow: true,
    isColumnAvailable: column => Boolean(column.exportable),
    descriptions: {
      rowScope: 'Apply Scope for: Row, or one or more Columns, or one or more Data Types',
      columnScope: 'Selected columns will be included in the report'
    },
    scope: data.Scope,
    updateScope: Scope => {
      const report = Object.assign(Object.assign({}, data), {
        Scope
      });
      props.onChange(report);
    }
  })) : null);
};