import { __rest } from "tslib";
import * as React from 'react';
import { Box, Flex } from 'rebass';
import SimpleButton from '../../components/SimpleButton';
import ArrayExtensions from '../../Utilities/Extensions/ArrayExtensions';
import { AdaptablePopover } from '../AdaptablePopover';
/**
 * Statusbar sub panel are similar to vs code:
 * - text
 * - action
 * - icon
 */
export const StatusBarPanel = props => {
  var _a;
  const {
      icon,
      content,
      popover,
      popoverMinWidth,
      view,
      onAction,
      extraActions,
      triggerActionOnWrapperClick = true,
      tooltip
    } = props,
    flexProps = __rest(props, ["icon", "content", "popover", "popoverMinWidth", "view", "onAction", "extraActions", "triggerActionOnWrapperClick", "tooltip"]);
  const handleAction = () => onAction();
  if (view) {
    const View = view;
    return React.createElement(View, null);
  }
  const baseClassName = 'ab-StatusBar__SubPanel';
  const handleIconClick = React.useCallback(() => {
    handleAction();
  }, []);
  const shouldTriggerActionOnWrapperClick = triggerActionOnWrapperClick && !popover;
  const handleWrapperClick = React.useCallback(() => {
    // handle action only if there is no popover
    if (shouldTriggerActionOnWrapperClick) {
      handleAction();
    }
  }, [popover, triggerActionOnWrapperClick]);
  // popover
  const popoverContent = typeof popover === 'function' ? React.createElement(popover) : popover;
  const preparedContent = typeof content === 'function' ? React.createElement(content) : content;
  return React.createElement(Flex, Object.assign({}, flexProps, {
    alignItems: "center",
    justifyContent: "center",
    className: baseClassName,
    onClick: handleWrapperClick,
    style: {
      cursor: shouldTriggerActionOnWrapperClick ? 'pointer' : 'default'
    }
  }), props.icon && React.createElement(SimpleButton, {
    tooltip: tooltip,
    color: props.color,
    icon: props.icon,
    iconSize: 15,
    variant: "text",
    onClick: handleIconClick,
    mr: preparedContent ? 1 : 0
  }), popover ? React.createElement(AdaptablePopover, {
    alignPosition: [['BottomCenter', 'TopCenter']],
    tooltipText: tooltip,
    showIcon: false,
    bodyText: [popoverContent],
    MessageType: 'Info',
    showEvent: 'focus',
    hideEvent: "blur",
    popoverMinWidth: popoverMinWidth
  }, preparedContent) : preparedContent, ArrayExtensions.IsNotNullOrEmpty(extraActions) && React.createElement(Box, {
    ml: 1
  }, (_a = extraActions === null || extraActions === void 0 ? void 0 : extraActions.map) === null || _a === void 0 ? void 0 : _a.call(extraActions, (action, index) => React.createElement(action, {
    key: index
  }))));
};