import * as React from 'react';
import { Box, Flex, Text } from 'rebass';
import { ColorPicker } from '../../components/ColorPicker';
import clamp from 'lodash/clamp';
import { CheckBox } from '../../components/CheckBox';
import FormLayout, { FormRow } from '../../components/FormLayout';
import { NumberInput } from '../../components/Input/NumberInput';
import SimpleButton from '../../components/SimpleButton';
import { getHexForName, GRAY, DARK_GREEN } from '../UIHelper';
import { ColumnSelector } from './Selectors/ColumnSelector';
import { Tabs } from '../../components/Tabs';
import Radio from '../../components/Radio';
export class RangesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rangesType: this.props.columnComparison ? 'ColumnComparison' : 'Standard'
    };
  }
  render() {
    var _a, _b;
    let comparisonMinValueNumber = this.props.columnComparison != null && this.props.columnComparison.MinValue != null && !isNaN(Number(this.props.columnComparison.MinValue)) ? Number(this.props.columnComparison.MinValue) : '';
    let comparisonMaxValueNumber = this.props.columnComparison != null && this.props.columnComparison.MaxValue != null && !isNaN(Number(this.props.columnComparison.MaxValue)) ? Number(this.props.columnComparison.MaxValue) : '';
    let comparisonMinValueColumnId = this.props.columnComparison != null && this.props.columnComparison.MinValue != null && isNaN(Number(this.props.columnComparison.MinValue)) ? String(this.props.columnComparison.MinValue) : undefined;
    let comparisonMaxValueColumnId = this.props.columnComparison != null && this.props.columnComparison.MaxValue != null && isNaN(Number(this.props.columnComparison.MaxValue)) ? String(this.props.columnComparison.MaxValue) : undefined;
    let comparisonColor = this.props.columnComparison != null && this.props.columnComparison.Color != null ? this.props.columnComparison.Color : undefined;
    const isRangeValueTypeNumber = ((_a = this.props.rangeValueType) !== null && _a !== void 0 ? _a : 'Number') === 'Number';
    return React.createElement(React.Fragment, null, React.createElement(Flex, {
      flexDirection: "row"
    }, React.createElement(CheckBox, {
      marginTop: 3,
      checked: this.state.rangesType == 'ColumnComparison',
      onChange: checked => this.onRangesTypeChanged(checked)
    }, "Use a Column Comparison")), this.state.rangesType == 'Standard' && React.createElement(Tabs, {
      autoFocus: false,
      marginTop: 2
    }, React.createElement(Tabs.Tab, null, "Ranges"), React.createElement(Tabs.Content, null, React.createElement(Flex, {
      alignItems: "center",
      mb: 2
    }, React.createElement(Text, {
      mr: 3
    }, "Range Type"), React.createElement(Radio, {
      onChange: () => this.handleRangeValueTypeChange('Number'),
      checked: isRangeValueTypeNumber,
      mr: 2
    }, "Number"), React.createElement(Radio, {
      onChange: () => this.handleRangeValueTypeChange('Percentage'),
      checked: !isRangeValueTypeNumber
    }, "Percentage")), (_b = this.props.ranges) === null || _b === void 0 ? void 0 : _b.map((range, index, list) => React.createElement(Flex, {
      key: index,
      alignItems: "end",
      mb: 2,
      "data-name": "percent-bar-range"
    }, React.createElement(Flex, {
      flexDirection: "column"
    }, isRangeValueTypeNumber && index === 0 && React.createElement(CheckBox, {
      onClick: () => this.setRangeColMin(range),
      checked: range.Min === 'Col-Min',
      mt: 0,
      mb: 1,
      fontSize: 3,
      disabled: this.props.disabled
    }, "Col Min"), React.createElement(NumberInput, {
      disabled: this.props.disabled || range.Min === 'Col-Min' || index === 0 && !isRangeValueTypeNumber,
      value: range.Min,
      onChange: value => {
        this.changeRangeMin(index, value);
      },
      mr: 2
    })), React.createElement(Flex, {
      flexDirection: "column"
    }, isRangeValueTypeNumber && index === list.length - 1 && React.createElement(CheckBox, {
      mt: 0,
      mb: 1,
      onClick: () => this.setRangeColMax(range),
      checked: range.Max === 'Col-Max',
      fontSize: 3,
      disabled: this.props.disabled
    }, "Col Max"), React.createElement(NumberInput, {
      value: range.Max,
      disabled: this.props.disabled || range.Max === 'Col-Max' || index === list.length - 1 && !isRangeValueTypeNumber,
      onChange: value => {
        this.changeRangeMax(index, value);
      },
      mr: 2
    })), React.createElement(Flex, {
      alignItems: "center",
      height: "100%"
    }, React.createElement(ColorPicker, {
      api: this.props.api,
      value: range.Color,
      onChange: color => {
        this.changeRangeColor(index, color);
      },
      mr: 2,
      height: 33
    })), this.props.showRangeDirection && React.createElement(CheckBox, {
      disabled: this.props.disabled,
      checked: range.ReverseGradient && range.ReverseGradient == true,
      onChange: checked => this.changeRangeDirectionUp(index, checked),
      ml: 2
    }, "Reverse Gradient"), React.createElement(SimpleButton, {
      ml: 2,
      mb: 1,
      icon: "delete",
      disabled: this.props.disabled || this.props.ranges && this.props.ranges.length == 1,
      onClick: () => this.removeRange(index)
    }))), React.createElement("div", null, React.createElement(SimpleButton, {
      disabled: this.props.disabled,
      variant: "raised",
      "data-name": "add",
      onClick: () => this.addRange()
    }, "Add New Range")))), this.state.rangesType == 'ColumnComparison' && React.createElement(Tabs, {
      autoFocus: false,
      marginTop: 2
    }, React.createElement(Tabs.Tab, null, "Column Comparison"), React.createElement(Tabs.Content, null, React.createElement(Flex, {
      flexDirection: "column",
      key: 'cc',
      "data-name": "percent-bar-column-comparison"
    }, React.createElement(Text, {
      padding: 2,
      fontSize: 2
    }, "Create Minimum and Maximum Values by providing a value or selecting another Column, and choose a", ' ', this.props.api.internalApi.getCorrectEnglishVariant('Colour'), " for the Bar."), React.createElement(FormLayout, {
      columns: ['label', 'first', 'or', 'second'],
      sizes: ['auto', 'auto', 'auto', '1fr']
    }, React.createElement(FormRow, {
      label: "Min Value:",
      first: React.createElement(NumberInput, {
        disabled: this.props.disabled,
        type: "number",
        value: comparisonMinValueNumber,
        onChange: value => {
          this.changeColumnComparisonMinValue(value);
        }
      }),
      or: "OR",
      second: React.createElement(Box, {
        maxWidth: '15rem'
      }, React.createElement(ColumnSelector, {
        menuPosition: "fixed",
        disabled: this.props.disabled,
        value: comparisonMinValueColumnId,
        type: "Number",
        onChange: columnId => {
          this.onMinColumnSelectedChanged(columnId);
        }
      }))
    }), ' ', React.createElement(FormRow, {
      label: "Max Value:",
      first: React.createElement(NumberInput, {
        disabled: this.props.disabled,
        type: "number",
        value: comparisonMaxValueNumber,
        onChange: value => {
          this.changeColumnComparisonMaxValue(value);
        }
      }),
      or: "OR",
      second: React.createElement(Box, {
        maxWidth: '15rem'
      }, React.createElement(ColumnSelector, {
        menuPosition: "fixed",
        disabled: this.props.disabled,
        value: comparisonMaxValueColumnId,
        type: "Number",
        onChange: columnId => this.onMaxColumnSelectedChanged(columnId)
      }))
    }), React.createElement(FormRow, {
      label: `Bar ${this.props.api.internalApi.getCorrectEnglishVariant('Colour')}`,
      first: React.createElement(ColorPicker, {
        disabled: this.props.disabled,
        api: this.props.api,
        value: comparisonColor,
        onChange: color => {
          this.changeColumnComparisonColor(color);
        }
      })
    }))))));
  }
  onRangesTypeChanged(checked) {
    if (checked) {
      let columnComparison = {
        Color: getHexForName(DARK_GREEN),
        MinValue: undefined,
        MaxValue: undefined
      };
      this.setState({
        rangesType: 'ColumnComparison'
      });
      this.props.updateColumnComparison(columnComparison);
    } else {
      const ranges = this.props.api.columnScopeApi.createCellColorRangesForScope(this.props.scope);
      this.setState({
        rangesType: 'Standard'
      });
      this.props.updateRanges(ranges);
    }
  }
  handleRangeValueTypeChange(rangeValueType) {
    this.props.onRangeValueTypeChange(rangeValueType);
  }
  changeRangeMin(index, value) {
    const {
      ranges
    } = this.props;
    let newMin = Number(value);
    if (this.props.rangeValueType === 'Percentage') {
      newMin = clamp(newMin, 0, 100);
    }
    ranges[index].Min = newMin;
    if (ranges[index - 1]) {
      ranges[index - 1].Max = newMin;
    }
    this.props.updateRanges(ranges);
  }
  changeRangeMax(index, value) {
    const {
      ranges
    } = this.props;
    let newMax = Number(value);
    if (this.props.rangeValueType === 'Percentage') {
      newMax = clamp(newMax, 0, 100);
    }
    ranges[index].Max = newMax;
    if (ranges[index + 1]) {
      ranges[index + 1].Min = newMax;
    }
    this.props.updateRanges(ranges);
  }
  changeRangeColor(index, value) {
    const {
      ranges
    } = this.props;
    ranges[index].Color = value;
    this.props.updateRanges(ranges);
  }
  changeRangeDirectionUp(index, checked) {
    const {
      ranges
    } = this.props;
    ranges[index].ReverseGradient = checked;
    this.props.updateRanges(ranges);
  }
  removeRange(index) {
    let ranges = [...this.props.ranges];
    ranges.splice(index, 1);
    // Max 100 is always disabled, this prevents from having a Max range lower than 100
    if (this.props.rangeValueType === 'Percentage' && ranges.length === 1) {
      ranges[0] = Object.assign(Object.assign({}, ranges[0]), {
        Max: 100
      });
    }
    this.props.updateRanges(ranges);
  }
  setRangeColMin(range) {
    const newRanges = this.props.ranges.map(rangeItem => {
      if (rangeItem === range) {
        let newMin = rangeItem.Min === 'Col-Min' ? this.props.minMaxRangeValues.min : 'Col-Min';
        return Object.assign(Object.assign({}, rangeItem), {
          Min: newMin
        });
      }
      return rangeItem;
    });
    this.props.updateRanges(newRanges);
  }
  setRangeColMax(range) {
    const newRanges = this.props.ranges.map(rangeItem => {
      if (rangeItem === range) {
        let newMax = rangeItem.Max === 'Col-Max' ? this.props.minMaxRangeValues.max : 'Col-Max';
        return Object.assign(Object.assign({}, rangeItem), {
          Max: newMax
        });
      }
      return rangeItem;
    });
    this.props.updateRanges(newRanges);
  }
  addRange() {
    const lastRange = this.props.ranges[this.props.ranges.length - 1];
    let previousRange = lastRange;
    let Min = null;
    if (lastRange.Max === 'Col-Max') {
      Min = this.props.minMaxRangeValues.max;
      previousRange = Object.assign(Object.assign({}, previousRange), {
        Max: this.props.minMaxRangeValues.max
      });
    } else {
      Min = lastRange.Max;
    }
    const newRange = {
      Min,
      Max: lastRange.Max,
      Color: getHexForName(GRAY)
    };
    const newRanges = [...this.props.ranges.slice(0, -1), previousRange, newRange];
    this.props.updateRanges(newRanges);
  }
  changeColumnComparisonMinValue(value) {
    const {
      columnComparison
    } = this.props;
    columnComparison.MinValue = value;
    this.props.updateColumnComparison(columnComparison);
  }
  onMinColumnSelectedChanged(columnId) {
    const {
      columnComparison
    } = this.props;
    columnComparison.MinValue = columnId || undefined;
    this.props.updateColumnComparison(columnComparison);
  }
  changeColumnComparisonMaxValue(value) {
    const {
      columnComparison
    } = this.props;
    columnComparison.MaxValue = value;
    this.props.updateColumnComparison(columnComparison);
  }
  onMaxColumnSelectedChanged(columnId) {
    const {
      columnComparison
    } = this.props;
    columnComparison.MaxValue = columnId || undefined;
    this.props.updateColumnComparison(columnComparison);
  }
  changeColumnComparisonColor(value) {
    const {
      columnComparison
    } = this.props;
    columnComparison.Color = value;
    this.props.updateColumnComparison(columnComparison);
  }
}