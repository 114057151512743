import * as React from 'react';
import { useState } from 'react';
import { OnePageAdaptableWizard, OnePageWizardSummary } from '../../Wizard/OnePageAdaptableWizard';
import { cloneObject } from '../../../Utilities/Helpers/Helper';
import { Box } from 'rebass';
import { renderReportColumnsSummary, ReportColumnsWizardSection } from './ReportColumnsWizardSection';
import { isValidReportRowsQuery, renderReportRowsSummary, ReportRowsWizardSection } from './ReportRowsWizardSection';
import { isValidReportName, renderReportNameSummary, ReportNameWizardSection } from './ReportNameWizardSection';
import ObjectFactory from '../../../Utilities/ObjectFactory';
import { useDispatch, useSelector } from 'react-redux';
import * as ExportRedux from '../../../Redux/ActionsReducers/ExportRedux';
import { ObjectTagsWizardSection, renderObjectTagsSummary } from '../../Wizard/ObjectTagsWizardSection';
export const NewReportWizard = props => {
  var _a, _b;
  const isEdit = ((_a = props.popupParams) === null || _a === void 0 ? void 0 : _a.action) ? props.popupParams.action === 'Edit' : Boolean(props.data);
  const currentReport = useSelector(state => {
    if (!isEdit) {
      return;
    }
    return state.Export.Reports.find(report => report.Name === state.Export.CurrentReport);
  });
  const data = (_b = props.data) !== null && _b !== void 0 ? _b : currentReport;
  const [report, setReport] = useState(() => {
    return data ? cloneObject(data) : ObjectFactory.CreateEmptyReport();
  });
  const dispatch = useDispatch();
  const handleFinish = () => {
    if (isEdit) {
      dispatch(ExportRedux.ReportEdit(report));
    } else {
      dispatch(ExportRedux.ReportAdd(report));
    }
    props.onFinishWizard(report);
  };
  return React.createElement(OnePageAdaptableWizard, {
    defaultCurrentSectionName: props.defaultCurrentSectionName,
    moduleInfo: props.moduleInfo,
    data: report,
    onHide: props.onCloseWizard,
    onFinish: handleFinish,
    sections: [{
      title: 'Columns',
      details: 'Choose the Columns you want to include in the Report',
      renderSummary: renderReportColumnsSummary,
      render: () => React.createElement(ReportColumnsWizardSection, {
        onChange: setReport
      })
    }, {
      title: 'Rows',
      details: 'Choose the Rows you want to include in the Report',
      isValid: isValidReportRowsQuery,
      renderSummary: renderReportRowsSummary,
      render: () => React.createElement(ReportRowsWizardSection, {
        onChange: setReport
      })
    }, {
      title: 'Name',
      details: 'Enter the name of the Report',
      isValid: isValidReportName,
      renderSummary: renderReportNameSummary,
      render: () => React.createElement(Box, {
        padding: 2
      }, React.createElement(ReportNameWizardSection, {
        onChange: setReport
      }))
    }, {
      details: 'Select Report Tags',
      title: 'Tags',
      isVisible: (_, api) => api.internalApi.shouldDisplayTagSections(),
      render: () => React.createElement(Box, {
        padding: 2
      }, React.createElement(ObjectTagsWizardSection, {
        onChange: setReport
      })),
      renderSummary: renderObjectTagsSummary
    }, '-', {
      title: 'Summary',
      render: () => {
        return React.createElement(Box, {
          padding: 2
        }, React.createElement(OnePageWizardSummary, null));
      }
    }]
  });
};