import { __rest } from "tslib";
import * as React from 'react';
import { Box, Flex } from 'rebass';
import Input from '../Input';
import { getVariableColor } from '../../Utilities/Helpers/StyleHelper';
import tinycolor from 'tinycolor2';
export const ColorPicker = React.forwardRef((props, ref) => {
  const ColorPalette = props.api.userInterfaceApi.getColorPalette();
  let {
      api,
      value,
      includeAlpha = true
    } = props,
    restProps = __rest(props, ["api", "value", "includeAlpha"]);
  const optionsMap = ColorPalette.reduce((acc, colorItem) => Object.assign(Object.assign({}, acc), {
    [getVariableColor(colorItem)]: colorItem
  }), {});
  const ABcolorChoicesOptions = Object.keys(optionsMap).map(x => {
    return React.createElement("option", {
      key: x,
      value: x
    }, x);
  });
  const ABcolorChoices = React.createElement("datalist", {
    id: 'ABcolorChoices'
  }, ABcolorChoicesOptions);
  const preparedValue = React.useMemo(() => {
    const color = getVariableColor(value);
    return tinycolor(color).toHexString();
  }, [value]);
  const preparedAlphaColor = React.useMemo(() => {
    const color = getVariableColor(value);
    return [tinycolor(color).setAlpha(0).toRgbString(), tinycolor(color).setAlpha(1).toRgbString()];
  }, [value]);
  const rangeBackround = `linear-gradient(90deg, ${preparedAlphaColor[0]} 0%, ${preparedAlphaColor[1]} 100%)`;
  const alpha = tinycolor(value).getAlpha();
  return React.createElement(Flex, {
    className: 'ColorPicker'
  }, React.createElement(Input, Object.assign({}, restProps, {
    mr: 2,
    onChange: event => {
      var _a;
      /**
       * The value is not in the map when the color is not in the palette.
       */
      const color = (_a = optionsMap[event.target.value]) !== null && _a !== void 0 ? _a : event.target.value;
      props.onChange(color);
    },
    value: preparedValue,
    ref: ref,
    type: "color",
    style: {
      width: 70,
      padding: 0 /* we need this to be 0, since otherwise on Windows browsers, the chosen color cannot be seen */
    },
    list: "ABcolorChoices"
  })), ABcolorChoices, includeAlpha && React.createElement(Flex, {
    alignItems: "center"
  }, React.createElement(Box, {
    mr: 1
  }, "Opacity"), React.createElement(Input, {
    className: "ab-ColorPicker-range",
    style: {
      background: rangeBackround
    },
    value: alpha,
    onChange: event => {
      const color = tinycolor(value).setAlpha(event.target.value).toRgbString();
      props.onChange(color);
    },
    min: 0,
    max: 1,
    step: 0.01,
    type: "range"
  })));
});