import { ApiBase } from './ApiBase';
export class CalendarApiImpl extends ApiBase {
  getNextWorkingDay() {
    let counterDate = new Date();
    let isWorkingDay = false;
    while (!isWorkingDay) {
      counterDate.setDate(counterDate.getDate() + 1);
      isWorkingDay = this.isWorkingDay(counterDate);
    }
    return counterDate;
  }
  getPreviousWorkingDay() {
    let counterDate = new Date();
    let isWorkingDay = false;
    while (!isWorkingDay) {
      counterDate.setDate(counterDate.getDate() - 1);
      isWorkingDay = this.isWorkingDay(counterDate);
    }
    return counterDate;
  }
  isWorkingDay(dateToCheck) {
    const holidays = this.getHolidays();
    if (!holidays) {
      return dateToCheck.getDay() != 0 && dateToCheck.getDay() != 6;
    }
    for (let holiday of holidays) {
      if (holiday.setHours(0, 0, 0, 0) == dateToCheck.setHours(0, 0, 0, 0)) {
        return false;
      }
    }
    return dateToCheck.getDay() != 0 && dateToCheck.getDay() != 6;
  }
  isHoliday(dateToCheck) {
    return !this.isWorkingDay(dateToCheck);
  }
  isSameDay(firstDateToCheck, secondDateToCheck) {
    return firstDateToCheck.getFullYear() === secondDateToCheck.getFullYear() && firstDateToCheck.getMonth() === secondDateToCheck.getMonth() && firstDateToCheck.getDate() === secondDateToCheck.getDate();
  }
  getHolidays() {
    const holidays = this.getCalendarOptions().holidays;
    if (holidays) {
      if (typeof holidays === 'function') {
        return holidays(this.getAdaptableApi().internalApi.buildBaseContext());
      } else {
        return holidays;
      }
    }
  }
}