import * as React from 'react';
import { useSelector } from 'react-redux';
import { Box, Flex, Text } from 'rebass';
import { Icon } from '../../components/icons';
import Panel from '../../components/Panel';
import SimpleButton from '../../components/SimpleButton';
import * as CommentsRedux from '../../Redux/ActionsReducers/CommentsRedux';
import { SystemCommentsAndNotesSelector } from '../../Redux/ActionsReducers/SystemRedux';
import { formatDate } from '../../Utilities/ExpressionFunctions/dateUtils';
import { useAdaptable } from '../AdaptableContext';
import AdaptableInput from '../Components/AdaptableInput';
// Edit Mode
// [author] [edit, delete]
// [text-editor] * this is different
// [show-all-comments-button]
// View Mode
// [author] [edit, delete]
// [text]
// [show-all-comments-button]
export const CommentsEditor = props => {
  var _a, _b;
  const adaptable = useAdaptable();
  const showCloseButton = (_b = (_a = adaptable.adaptableOptions.commentOptions) === null || _a === void 0 ? void 0 : _a.showPopupCloseButton) !== null && _b !== void 0 ? _b : true;
  const cellAddress = useSelector(state => SystemCommentsAndNotesSelector(state.System));
  const userId = React.useMemo(() => {
    return adaptable.api.optionsApi.getUserName();
  }, []);
  const commentThread = useSelector(state => CommentsRedux.GetCellCommentSelector(state.Comment, cellAddress));
  const isReadOnlyModule = adaptable.api.entitlementApi.getEntitlementAccessLevelForModule('Comment') === 'ReadOnly';
  const [activeEditingComment, setActiveEditingComment] = React.useState(() => {
    /**
     * When opening the popup and there is only one comment, we want to open it in edit mode.
     * This happens usualy when a new comment is added via cell menu.
     */
    // if (cellComments?.Comments?.length === 1 && cellComments?.Comments[0].Value === '') {
    //   return cellComments?.Comments[0].Uuid;
    // }
    return null;
  });
  const [newCommentText, setNewCommentText] = React.useState('');
  const commentsWrapperRef = React.useRef(null);
  const comments = commentThread === null || commentThread === void 0 ? void 0 : commentThread.Comments;
  const scrollToBottom = () => {
    var _a, _b;
    (_a = commentsWrapperRef === null || commentsWrapperRef === void 0 ? void 0 : commentsWrapperRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo(0, (_b = commentsWrapperRef === null || commentsWrapperRef === void 0 ? void 0 : commentsWrapperRef.current) === null || _b === void 0 ? void 0 : _b.scrollHeight);
  };
  React.useEffect(() => {
    scrollToBottom();
  }, []);
  if (!commentThread) {
    return null;
  }
  return React.createElement(Panel, {
    color: "var(--ab-color-text-on-primary)",
    onClick: () => props.enableEditMode(),
    minWidth: 250,
    className: "ab-CommentPopup",
    onKeyDown: event => {
      if (event.key === 'Escape') {
        adaptable.api.commentApi.hideCommentsPopup();
      }
    }
  }, showCloseButton && React.createElement(Flex, null, React.createElement(Box, {
    flex: 1
  }), React.createElement(SimpleButton, {
    onClick: event => {
      event.stopPropagation();
      adaptable.api.commentApi.hideCommentsPopup();
    },
    variant: "text",
    icon: "close"
  })), React.createElement(Flex, {
    style: {
      overflow: 'auto',
      maxHeight: 300
    },
    flexDirection: "column",
    ref: commentsWrapperRef
  }, comments && comments.length > 0 && comments.map((comment, index) => {
    var _a, _b, _c, _d;
    if (!comment) {
      return null;
    }
    const isOwnComment = ((_a = comment === null || comment === void 0 ? void 0 : comment.Author) === null || _a === void 0 ? void 0 : _a.UserName) ? ((_b = comment === null || comment === void 0 ? void 0 : comment.Author) === null || _b === void 0 ? void 0 : _b.UserName) === userId : true; // no owner means it's the current user
    return React.createElement(Box, {
      p: 2,
      key: (_c = comment.Uuid) !== null && _c !== void 0 ? _c : index,
      className: "ab-Comment"
    }, React.createElement(Flex, {
      mb: 2,
      alignItems: "center"
    }, React.createElement(Box, null, React.createElement(Box, {
      "data-name": "comment-username",
      fontSize: 3,
      fontWeight: "bold"
    }, (_d = comment === null || comment === void 0 ? void 0 : comment.Author) === null || _d === void 0 ? void 0 : _d.UserName), comment.Timestamp && React.createElement(Box, {
      "data-name": "comment-timestamp",
      fontSize: 2
    }, formatDate(comment.Timestamp, 'MM.DD.YYYY HH:mm'))), React.createElement(Box, {
      flex: 1
    }), React.createElement(SimpleButton, {
      variant: "text",
      icon: "edit",
      disabled: !isOwnComment || isReadOnlyModule,
      onClick: () => setActiveEditingComment(comment.Uuid)
    }), React.createElement(SimpleButton, {
      variant: "text",
      icon: "delete",
      disabled: !isOwnComment || isReadOnlyModule,
      onClick: () => {
        adaptable.api.commentApi.deleteComment(comment, cellAddress);
        requestAnimationFrame(() => {
          props.onRefreshContent();
        });
      }
    })), React.createElement(Box, {
      onClick: () => setActiveEditingComment(comment.Uuid)
    }, comment.Uuid === activeEditingComment ? React.createElement(AdaptableInput, {
      autoFocus: true,
      width: "100%",
      defaultValue: comment.Value,
      disabled: isReadOnlyModule,
      onBlur: () => {
        if (comment.Uuid === activeEditingComment) {
          setActiveEditingComment(null);
        }
      },
      onChange: event => {
        adaptable.api.commentApi.editComment(Object.assign(Object.assign({}, comment), {
          Value: event.target.value
        }), cellAddress);
      }
    }) : React.createElement(Text, {
      "data-name": "comment-text"
    }, comment.Value)));
  })), React.createElement(AdaptableInput, {
    autoFocus: !comments || comments.length === 0,
    value: newCommentText,
    disabled: isReadOnlyModule,
    onChange: event => {
      setNewCommentText(event.target.value);
    },
    onKeyDown: event => {
      if (event.key === 'Enter') {
        adaptable.api.commentApi.addComment(newCommentText, cellAddress);
        setNewCommentText('');
        requestAnimationFrame(() => {
          props.onRefreshContent();
          scrollToBottom();
        });
      }
    },
    mt: 2,
    mb: "2",
    width: "100%",
    placeholder: "Write new Comment"
  }), React.createElement(SimpleButton, {
    width: "100%",
    variant: "raised",
    onClick: () => {
      adaptable.api.settingsPanelApi.openSettingsPanel('Comment');
      adaptable.api.internalApi.getCellPopupService().hidePopup();
    }
  }, React.createElement(Box, {
    mr: 2
  }, React.createElement(Icon, {
    name: "folder"
  })), ' ', "Open all Comments"));
};