import * as React from 'react';
import { DataSource, InfiniteTable } from '../../../components/InfiniteTable';
import join from '../../utils/join';
const baseClassName = 'ab-GridList';
const columnsMap = new Map([['item', {
  field: 'item',
  header: null,
  defaultFlex: 1
}]]);
const GridList = props => {
  var _a;
  const data = React.Children.map(props.children, (c, index) => {
    return {
      item: c,
      id: index
    };
  }).filter(data => !!data.item);
  return React.createElement(DataSource, {
    data: data,
    primaryKey: "id"
  }, React.createElement(InfiniteTable, {
    header: false,
    showZebraRows: props.showZebraRows,
    rowHeight: (_a = props.rowHeight) !== null && _a !== void 0 ? _a : '--ab-grid-row-height',
    domProps: {
      className: join(baseClassName, props.className),
      style: {
        minHeight: '15rem'
      }
    },
    columns: columnsMap
  }));
};
export default GridList;