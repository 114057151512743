import * as React from 'react';
import { useState } from 'react';
import { Box } from 'rebass';
import Input from '../../../components/Input';
import ErrorBox from '../../../components/ErrorBox';
import Radio from '../../../components/Radio';
import FormLayout, { FormRow } from '../../../components/FormLayout';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
import AdaptableInput from '../../Components/AdaptableInput';
import { CheckBox } from '../../../components/CheckBox';
import { DateFormatter } from '../../../Utilities/Helpers/FormatHelper';
import { parseToISO } from '../../../Utilities/Helpers/DateHelper';
import { Tag } from '../../../components/Tag';
import { SpecialColumnSettingsWizardStep } from '../../SpecialColumnSettingsWizardStep';
import { Select } from '../../../components/Select';
const options = [{
  value: 'Number',
  label: 'Number'
}, {
  value: 'String',
  label: 'String'
}, {
  value: 'Date',
  label: 'Date'
}, {
  value: 'Boolean',
  label: 'Boolean'
}];
export const renderFreeTextColumnSummary = data => {
  var _a;
  return React.createElement(Box, {
    fontSize: 2
  }, React.createElement(Box, null, "Column Identifier: ", React.createElement(Tag, null, data.ColumnId)), data.FriendlyName ? React.createElement(Box, {
    mt: 3
  }, "Column Name: ", React.createElement(Tag, null, (_a = data.FriendlyName) !== null && _a !== void 0 ? _a : data.ColumnId)) : null, React.createElement(Box, {
    mt: 3
  }, "Data Type: ", React.createElement(Tag, null, data.FreeTextColumnSettings.DataType)), data.DefaultValue !== undefined ? React.createElement(Box, {
    mt: 3
  }, "Default Value: ", React.createElement(Tag, null, data.DefaultValue)) : null, data.TextEditor !== undefined ? React.createElement(Box, {
    mt: 3
  }, "Editor Type: ", React.createElement(Tag, null, data.TextEditor)) : null);
};
export const isValidFreeTextColumn = (data, api) => {
  const columns = api.columnApi.getColumns();
  if (!data.ColumnId) {
    return 'Column Id cannot be empty';
  }
  const columnsWithSameIdCount = columns.filter(c => c.columnId === data.ColumnId).length;
  const hasAlreadyExistingId = data.Uuid ? columnsWithSameIdCount > 1 : columnsWithSameIdCount > 0;
  if (hasAlreadyExistingId) {
    return 'A Column already exists with that id';
  }
  if (!data.FreeTextColumnSettings.DataType) {
    return 'No data type specified for column';
  }
  return true;
};
export const FreeTextColumnSettingsWizardSection = props => {
  var _a;
  const {
    data,
    api
  } = useOnePageAdaptableWizardContext();
  const Pattern = api.optionsApi.getUserInterfaceOptions().dateInputOptions.dateFormat;
  const [ColumnNameFocused, setColumnNameFocused] = useState(false);
  const inEdit = props.isEdit;
  const validCheck = isValidFreeTextColumn(data, api);
  const ErrorMessage = validCheck === true ? null : validCheck;
  const handleColumnIdChange = event => {
    let e = event.target;
    const ColumnId = e.value;
    props.onChange(Object.assign(Object.assign({}, data), {
      ColumnId
    }));
  };
  const handleColumnNameChange = event => {
    let e = event.target;
    const ColumnName = e.value;
    props.onChange(Object.assign(Object.assign({}, data), {
      FriendlyName: ColumnName
    }));
  };
  const handleDataTypeChange = DataType => {
    var _a;
    const newData = Object.assign(Object.assign({}, data), {
      FreeTextColumnSettings: Object.assign(Object.assign({}, data.FreeTextColumnSettings), {
        DataType
      })
    });
    delete newData.DefaultValue;
    if (!DataType) {
      (_a = newData.FreeTextColumnSettings) === null || _a === void 0 ? true : delete _a.DataType;
    }
    props.onChange(newData);
  };
  const onDynamicSelectChanged = event => {
    let e = event.target;
    const TextEditor = e.value;
    props.onChange(Object.assign(Object.assign({}, data), {
      TextEditor
    }));
  };
  const handleDefaultValueChange = event => {
    let e = event.target;
    let DefaultValue = e.value;
    if (data.FreeTextColumnSettings.DataType === 'Date') {
      DefaultValue = DateFormatter(DefaultValue, {
        Pattern
      });
    }
    props.onChange(Object.assign(Object.assign({}, data), {
      DefaultValue
    }));
  };
  const handleSpecialColumnSettingsChange = (
  //  settings: SpecialColumnSettings
  settings) => {
    props.onChange(Object.assign(Object.assign({}, data), {
      FreeTextColumnSettings: Object.assign(Object.assign({}, data === null || data === void 0 ? void 0 : data.FreeTextColumnSettings), settings)
    }));
  };
  return React.createElement(React.Fragment, null, React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Column Id"
  }, React.createElement(Input, {
    "data-name": "column-id",
    autoFocus: !inEdit,
    value: data.ColumnId || '',
    style: {
      width: '100%',
      maxWidth: 500
    },
    disabled: inEdit,
    type: "text",
    placeholder: "Enter an id",
    onChange: e => handleColumnIdChange(e)
  })), React.createElement(FormRow, {
    label: "Column Name"
  }, React.createElement(Input, {
    "data-name": "column-name",
    autoFocus: inEdit,
    onFocus: () => {
      setColumnNameFocused(true);
    },
    onBlur: () => {
      setColumnNameFocused(false);
    },
    value: ColumnNameFocused ? data.FriendlyName || '' : data.FriendlyName || data.ColumnId || '',
    style: {
      width: '100%',
      maxWidth: 500
    },
    type: "text",
    placeholder: "Enter a name",
    onChange: e => handleColumnNameChange(e)
  })), React.createElement(FormRow, {
    label: "Data Type"
  }, React.createElement(Box, {
    style: {
      maxWidth: 500
    }
  }, React.createElement(Select, {
    "data-name": "column-type-dropdown",
    options: options,
    placeholder: "Select Data Type",
    value: data.FreeTextColumnSettings.DataType,
    onChange: value => handleDataTypeChange(value)
  }))), React.createElement(FormRow, {
    label: "Default Value"
  }, data.FreeTextColumnSettings.DataType === 'Boolean' ? React.createElement(CheckBox, {
    "data-name": "column-default-value-checkbox",
    checked: !!data.DefaultValue,
    onChange: DefaultValue => {
      props.onChange(Object.assign(Object.assign({}, data), {
        DefaultValue
      }));
    }
  }) : React.createElement(AdaptableInput, {
    "data-name": "column-default-value",
    value: data.FreeTextColumnSettings.DataType === 'Date' && data.DefaultValue ? parseToISO(data.DefaultValue, Pattern) || '' : data.DefaultValue || '',
    style: {
      width: '100%',
      maxWidth: 500
    },
    type: data.FreeTextColumnSettings.DataType === 'Number' ? 'number' : data.FreeTextColumnSettings.DataType === 'Date' ? 'date' : 'text',
    placeholder: "Default Column Value (optional)",
    onChange: e => handleDefaultValueChange(e)
  })), React.createElement(FormRow, {
    label: "Header Tooltip"
  }, React.createElement(Input, {
    "data-name": "header-tooltip",
    type: "text",
    style: {
      width: '100%',
      maxWidth: 500
    },
    value: ((_a = data.FreeTextColumnSettings) === null || _a === void 0 ? void 0 : _a.HeaderToolTip) || '',
    onChange: e => handleSpecialColumnSettingsChange({
      HeaderToolTip: e.target.value
    })
  })), ' ', data.FreeTextColumnSettings.DataType == 'String' && React.createElement(FormRow, {
    label: "Cell Editor"
  }, React.createElement(Radio, {
    "data-name": "inline-editor",
    value: "Inline",
    checked: data.TextEditor == 'Inline',
    onChange: (_, e) => onDynamicSelectChanged(e),
    marginRight: 2
  }, "Inline Editor"), React.createElement(Radio, {
    "data-name": "large-editor",
    value: "Large",
    checked: data.TextEditor == 'Large',
    onChange: (_, e) => onDynamicSelectChanged(e)
  }, "Large Editor"))), ErrorMessage ? React.createElement(ErrorBox, {
    mt: 2,
    mb: 2
  }, ErrorMessage) : null, React.createElement(Box, {
    mt: 2
  }, React.createElement(SpecialColumnSettingsWizardStep, {
    isEditable: true,
    settings: data.FreeTextColumnSettings,
    onChange: handleSpecialColumnSettingsChange
  })));
};