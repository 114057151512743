import * as React from 'react';
import SimpleButton from '../../components/SimpleButton';
import { useAdaptable } from '../AdaptableContext';
export const EditCurrentLayoutButton = () => {
  const adaptable = useAdaptable();
  const handleClick = React.useCallback(() => {
    adaptable.api.layoutApi.showLayoutEditor();
  }, []);
  return React.createElement(SimpleButton, {
    ml: 1,
    variant: "text",
    iconSize: 15,
    icon: "edit",
    onClick: handleClick
  });
};