import { ApiBase } from '../Implementation/ApiBase';
export class CustomSortInternalApi extends ApiBase {
  getCustomSortComparer(columnId) {
    var _a;
    const column = this.getColumnApi().getColumnWithColumnId(columnId);
    return (_a = this.getCustomSortOptions().customSortComparers) === null || _a === void 0 ? void 0 : _a.find(csc => this.getAdaptableApi().columnScopeApi.isColumnInScope(column, csc.scope));
  }
  getDefaultCustomSortComparer(columnId, columnValues) {
    // have to return a function that may not have access to this
    const adaptable = this.adaptable;
    return function compareItemsOfCustomSort(valueA, valueB, nodeA, nodeB) {
      var _a, _b;
      let firstElementValueString = (_a = nodeA && adaptable.getDisplayValueFromRowNode(nodeA, columnId)) !== null && _a !== void 0 ? _a : valueA;
      let secondElementValueString = (_b = nodeB && adaptable.getDisplayValueFromRowNode(nodeB, columnId)) !== null && _b !== void 0 ? _b : valueB;
      let indexFirstElement = columnValues.indexOf(firstElementValueString);
      let containsFirstElement = indexFirstElement >= 0;
      let indexSecondElement = columnValues.indexOf(secondElementValueString);
      let containsSecondElement = indexSecondElement >= 0;
      //if none of the element are in the list we jsut return normal compare
      if (!containsFirstElement && !containsSecondElement) {
        if (valueA == valueB) {
          return 0;
        }
        return valueA < valueB ? -1 : 1;
      }
      //if first item not in the list make sure we put it after the second item
      if (!containsFirstElement) {
        return 1;
      }
      //if second item not in the list make sure we put it after the first item
      if (!containsSecondElement) {
        return -1;
      }
      //return the comparison from the list if the two items are in the list
      return indexFirstElement - indexSecondElement;
    };
  }
}