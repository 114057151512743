import * as React from 'react';
import DefaultIcon from './DefaultIcon';
export default (props => React.createElement(DefaultIcon, Object.assign({}, props), React.createElement("defs", null, React.createElement("path", {
  id: "a",
  d: "M0 0h24v24H0V0z"
})), React.createElement("clipPath", {
  id: "b"
}, React.createElement("use", {
  xlinkHref: "#a",
  overflow: "visible"
})), React.createElement("path", {
  clipPath: "url(#b)",
  d: "M21.7,13.35L20.7,14.35L18.65,12.3L19.65,11.3C19.86,11.08 20.21,11.08 20.42,11.3L21.7,12.58C21.92,12.79 21.92,13.14 21.7,13.35M12,18.94L18.07,12.88L20.12,14.93L14.06,21H12V18.94M4,2H18A2,2 0 0,1 20,4V8.17L16.17,12H12V16.17L10.17,18H4A2,2 0 0,1 2,16V4A2,2 0 0,1 4,2M4,6V10H10V6H4M12,6V10H18V6H12M4,12V16H10V12H4Z"
})));