import * as React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Box } from 'rebass';
import { DragAndDropContext } from './DragAndDropContext';
export function UnusedPanel({
  items,
  disabled,
  title,
  dragItemText
}) {
  return React.createElement(Box, {
    pb: 1,
    mb: 2,
    className: "ab-ModuleSelector__UnusedPanel",
    "data-name": "unusedpanel-items"
  }, React.createElement(Box, {
    p: 2
  }, React.createElement("b", null, title), " (", dragItemText, ")"), React.createElement(UnusedItemList, {
    disabled: disabled,
    items: items
  }));
}
function UnusedItemList({
  items,
  disabled
}) {
  return React.createElement(Droppable, {
    droppableId: "UNUSED",
    type: "TOOLBAR",
    isDropDisabled: true,
    direction: "horizontal"
  }, provided => React.createElement("div", Object.assign({
    ref: provided.innerRef,
    "data-name": "unusedpanel-items-list"
  }, provided.droppableProps, {
    style: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      paddingLeft: 'var(--ab-space-2)',
      paddingRight: 'var(--ab-space-2)'
    }
  }), items.map((unusedItem, unusedItemIndex) => React.createElement(UnusedItem, {
    disabled: disabled,
    key: unusedItem,
    unusedItem: unusedItem,
    unusedItemIndex: unusedItemIndex
  })), provided.placeholder));
}
function UnusedItem({
  unusedItem,
  unusedItemIndex,
  disabled
}) {
  const {
    availableItems
  } = React.useContext(DragAndDropContext);
  let currentItem = availableItems.find(t => t.Id === unusedItem);
  const title = currentItem ? currentItem.Title : unusedItem;
  return React.createElement(Draggable, {
    isDragDisabled: disabled,
    draggableId: unusedItem,
    index: unusedItemIndex
  }, (provided, snapshot) => {
    const eventHandlers = disabled ? {} : Object.assign(Object.assign({}, provided.draggableProps), provided.dragHandleProps);
    const dragStyle = disabled ? {} : provided.draggableProps.style;
    return React.createElement("div", Object.assign({
      "data-name": "unused-item",
      ref: provided.innerRef
    }, eventHandlers, {
      style: Object.assign(Object.assign({}, dragStyle), {
        border: '1px solid var(--ab-color-primary)',
        backgroundColor: snapshot.isDragging ? 'var(--ab-dashboard-toolbar-drag__background)' : 'var(--ab-color-primarylight)',
        padding: 'var(--ab-space-1) var(--ab-space-2)',
        marginRight: 'var(--ab-space-1)',
        marginBottom: 'var(--ab-space-1)'
      })
    }), title);
  });
}