import * as React from 'react';
import { useSelector } from 'react-redux';
import { Box } from 'rebass';
import { useAdaptable } from '../../AdaptableContext';
import { AdaptableObjectList } from '../../Components/AdaptableObjectList';
export const AdaptableObjectsSummary = () => {
  const state = useSelector(state => state);
  const adaptable = useAdaptable();
  const modules = adaptable.ModuleService.getModuleCollection();
  const baseClassName = 'ab-AdaptableObjectsSummary';
  return React.createElement(Box, {
    className: baseClassName
  }, [...modules].map(([moduleName, module]) => {
    var _a;
    if (!module.isModuleAvailable()) {
      return null;
    }
    const items = (_a = module === null || module === void 0 ? void 0 : module.toViewAll) === null || _a === void 0 ? void 0 : _a.call(module);
    if (!(items === null || items === void 0 ? void 0 : items.length)) {
      return null;
    }
    return React.createElement(Box, {
      "data-name": module.moduleInfo.ModuleName,
      className: `${baseClassName}__module-list`,
      key: module.moduleInfo.ModuleName
    }, React.createElement("b", {
      className: `${baseClassName}__header`
    }, module.moduleInfo.FriendlyName), (items === null || items === void 0 ? void 0 : items.length) && React.createElement(AdaptableObjectList, {
      items: items,
      module: module
    }));
  }));
};