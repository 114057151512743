import * as React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import theme from '../theme';
import AdaptableContext from './AdaptableContext';
export const renderWithAdaptableContext = (children, adaptable) => {
  return React.createElement(Provider, {
    store: adaptable.adaptableStore.TheStore
  }, React.createElement(ThemeProvider, {
    theme: theme
  }, React.createElement(AdaptableContext.Provider, {
    value: adaptable
  }, children)));
};