import * as React from 'react';
import { Box, Flex } from 'rebass';
import DropdownButton from '../../../components/DropdownButton';
import ErrorBox from '../../../components/ErrorBox';
import FormLayout, { FormRow } from '../../../components/FormLayout';
import HelpBlock from '../../../components/HelpBlock';
import SimpleButton from '../../../components/SimpleButton';
import { Tabs } from '../../../components/Tabs';
import ObjectFactory from '../../../Utilities/ObjectFactory';
import { AdaptableIconSelector } from '../../Components/AdaptableIconSelector';
import { PredicateEditor } from '../../Components/PredicateEditor/PredicateEditor';
import { StyleComponent } from '../../Components/StyleComponent';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
import { StyledColumnBadgePreview } from './StyledColumnWizardStyleSection/Components/StyledColumnBadgePreview';
import { Badge } from '../../Components/Badge';
import { AdaptableIconComponent } from '../../Components/AdaptableIconComponent';
import { DEFAULT_INTEGER_DISPLAY_VALUE, DEFAULT_STRING_DISPLAY_VALUE } from '../../../Utilities/Constants/GeneralConstants';
import { Accordion } from '../../../components/Accordion';
const BadgeEditor = props => {
  var _a, _b;
  const {
    api
  } = useOnePageAdaptableWizardContext();
  const {
    badge,
    columnId,
    onChange
  } = props;
  // badges evaluate predicate per value
  const predicateDefs = api.styledColumnApi.internalApi.getBadgePredicateDefsForColumn(columnId);
  const dataType = api.columnApi.getColumnDataTypeForColumnId(columnId);
  const previewValue = dataType === 'Number' || dataType == 'NumberArray' ? DEFAULT_INTEGER_DISPLAY_VALUE : DEFAULT_STRING_DISPLAY_VALUE;
  return React.createElement(Flex, {
    className: "ab-Badge-Definition-Editor",
    mb: 15,
    p: 3
  }, React.createElement(Box, {
    flex: 1
  }, React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Preview"
  }, React.createElement(Badge, {
    adaptableStyle: badge.Style,
    icon: badge.Icon,
    iconPosition: badge.IconPosition
  }, previewValue)), React.createElement(FormRow, {
    label: "Style"
  }, React.createElement(HelpBlock, {
    fontSize: 2,
    mb: 1,
    mt: 2
  }, "Select the Style for the Badge"), ' ', React.createElement(Accordion, {
    title: "Edit Badge Style"
  }, React.createElement(StyleComponent, {
    showFontSizeAs: "radio",
    headless: true,
    hidePreview: true,
    api: api,
    Style: (_a = badge.Style) !== null && _a !== void 0 ? _a : {},
    UpdateStyle: Style => {
      onChange(Object.assign(Object.assign({}, badge), {
        Style
      }));
    }
  }))), !api.columnApi.hasArrayDataType(columnId) &&
  // dont show predicates for arrays
  React.createElement(FormRow, {
    label: 'Predicate'
  }, columnId ? React.createElement(React.Fragment, null, React.createElement(HelpBlock, {
    fontSize: 2,
    mb: 1,
    mt: 2
  }, "Create (optional) Rule for when the Badge is displayed; if no Rule provided Badge will always display (unless overriden by another Rule)"), React.createElement(PredicateEditor, {
    columnId: columnId,
    predicate: badge.Predicate,
    predicateDefs: predicateDefs,
    placeholder: "No Predicate Selected",
    onChange: predicate => {
      onChange(Object.assign(Object.assign({}, badge), {
        Predicate: predicate
      }));
    },
    onClear: () => onChange(Object.assign(Object.assign({}, badge), {
      Predicate: undefined
    }))
  })) : React.createElement(ErrorBox, null, "Select a column first")), React.createElement(FormRow, {
    label: "Icon"
  }, React.createElement(HelpBlock, {
    fontSize: 2,
    mb: 1,
    mt: 2
  }, "Select (optional) Icon (and Icon position) to display in the Badge"), ' ',
  // for now, only show for system icons the
  !badge.Icon || 'name' in badge.Icon ? React.createElement(AdaptableIconSelector, {
    value: (badge === null || badge === void 0 ? void 0 : badge.Icon) && 'name' in (badge === null || badge === void 0 ? void 0 : badge.Icon) ? badge.Icon.name : undefined,
    onChange: iconName => {
      if (iconName) {
        onChange(Object.assign(Object.assign({}, badge), {
          Icon: {
            name: iconName
          }
        }));
      } else {
        onChange(Object.assign(Object.assign({}, badge), {
          Icon: undefined
        }));
      }
    }
  }) : React.createElement(AdaptableIconComponent, {
    icon: badge.Icon
  })), badge.Icon && React.createElement(FormRow, {
    label: "Icon Position"
  }, React.createElement(DropdownButton, {
    columns: ['label'],
    items: [{
      label: 'Start',
      onClick: () => onChange(Object.assign(Object.assign({}, badge), {
        IconPosition: 'Start'
      }))
    }, {
      label: 'End',
      onClick: () => onChange(Object.assign(Object.assign({}, badge), {
        IconPosition: 'End'
      }))
    }]
  }, (_b = badge.IconPosition) !== null && _b !== void 0 ? _b : 'Start')))), React.createElement(Box, {
    justifyContent: "end"
  }, React.createElement(SimpleButton, {
    icon: "delete",
    onClick: props.onDelete
  })));
};
export const renderBadgeSummary = styledColumn => {
  return React.createElement(StyledColumnBadgePreview, {
    data: styledColumn
  });
};
export const StyledColumnBadgeSection = props => {
  var _a, _b;
  const {
    data
  } = useOnePageAdaptableWizardContext();
  const badges = (_b = (_a = data === null || data === void 0 ? void 0 : data.BadgeStyle) === null || _a === void 0 ? void 0 : _a.Badges) !== null && _b !== void 0 ? _b : [];
  return React.createElement(Tabs, {
    style: {
      height: '100%'
    }
  }, React.createElement(Tabs.Tab, null, "Badges"), React.createElement(Tabs.Content, null, React.createElement(Flex, {
    justifyContent: "end"
  }, React.createElement(SimpleButton, {
    icon: "plus",
    onClick: () => {
      props.onChange(Object.assign(Object.assign({}, data), {
        BadgeStyle: {
          Badges: [...badges, ObjectFactory.CreateDefaultStyledColumnBadge()]
        }
      }));
    }
  }, "Add Badge")), React.createElement(Flex, {
    flexDirection: "column",
    p: 2
  }, badges.map((badge, index) => {
    const handleEditBadge = badge => {
      const newBadges = [...badges];
      newBadges[index] = badge;
      props.onChange(Object.assign(Object.assign({}, data), {
        BadgeStyle: Object.assign(Object.assign({}, data.BadgeStyle), {
          Badges: newBadges
        })
      }));
    };
    return React.createElement(BadgeEditor, {
      key: index,
      badge: badge,
      columnId: data.ColumnId,
      onChange: handleEditBadge,
      onDelete: () => {
        const newBadges = [...badges];
        newBadges.splice(index, 1);
        props.onChange(Object.assign(Object.assign({}, data), {
          BadgeStyle: Object.assign(Object.assign({}, data.BadgeStyle), {
            Badges: newBadges
          })
        }));
      }
    });
  }))));
};