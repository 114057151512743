import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex } from 'rebass';
import { AdaptableFormComponent } from '../../../../components/AdaptableFormComponent';
import Dialog from '../../../../components/Dialog';
import { getDefaultAdaptableFormData } from '../../../../PredefinedConfig/Common/AdaptableForm';
import { PopupHideForm } from '../../../../Redux/ActionsReducers/PopupRedux';
import { useAdaptable } from '../../../AdaptableContext';
export const FormDialog = ({
  id,
  formProps,
  form,
  prepareContext
}) => {
  const dispatch = useDispatch();
  const adaptable = useAdaptable();
  const [data, setData] = React.useState(() => {
    return getDefaultAdaptableFormData(form);
  });
  const handleDismiss = () => {
    dispatch(PopupHideForm(id));
  };
  const context = Object.assign({
    adaptableApi: adaptable.api,
    formData: data
  }, formProps);
  return React.createElement(Dialog, {
    isOpen: true,
    showCloseButton: false,
    style: {
      minHeight: 'auto',
      minWidth: 400,
      maxHeight: '80vh'
    },
    onDismiss: handleDismiss
  }, React.createElement(Flex, {
    flexDirection: 'column',
    "data-name": "form-dialog",
    padding: 4,
    height: '100%'
  }, React.createElement(AdaptableFormComponent, {
    formDef: form,
    data: data,
    context: context,
    onChange: setData,
    api: adaptable.api,
    displayTitle: true,
    onButtonClick: async button => {
      var _a;
      handleDismiss();
      const preparedContext = typeof prepareContext === 'function' ? await prepareContext(context) : context;
      (_a = button.onClick) === null || _a === void 0 ? void 0 : _a.call(button, button, preparedContext);
    }
  })));
};
export const FormPopups = () => {
  const forms = useSelector(state => state.Popup.FormPopup.FormList);
  return React.createElement(React.Fragment, null, forms.map(form => {
    return React.createElement(FormDialog, {
      key: form.Id,
      prepareContext: form.prepareContext,
      id: form.Id,
      formProps: form.FormProps,
      form: form.Form
    });
  }));
};