import Helper from '../../Utilities/Helpers/Helper';
export const handleExportState = (type, name, state) => {
  switch (type) {
    case 'Clipboard':
      let stringifiedState = JSON.stringify(state);
      Helper.copyToClipboard(stringifiedState);
      break;
    case 'Console':
      console.log('Adaptable State: ', state);
      break;
    case 'JSON':
      const jsonFileName = name + '.json';
      const jsonContent = JSON.stringify(state);
      Helper.createDownloadedFile(jsonContent, jsonFileName, 'application/json');
      break;
  }
};