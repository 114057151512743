import * as React from 'react';
import { Box, Flex } from 'rebass';
import { CheckBox } from '../../../../components/CheckBox';
import HelpBlock from '../../../../components/HelpBlock';
export const UIOptionsActionColumnsForm = props => {
  var _a, _b, _c, _d, _e;
  const options = [{
    value: 'clone',
    label: 'Clone'
  }, {
    value: 'create',
    label: 'Create'
  }, {
    value: 'edit',
    label: 'Edit'
  }, {
    value: 'delete',
    label: 'Delete'
  }];
  const actionRowButtons = Array.isArray((_b = (_a = props.abOptions) === null || _a === void 0 ? void 0 : _a.actionRowOptions) === null || _b === void 0 ? void 0 : _b.actionRowButtons) ? (_d = (_c = props.abOptions) === null || _c === void 0 ? void 0 : _c.actionRowOptions) === null || _d === void 0 ? void 0 : _d.actionRowButtons : (_e = []) !== null && _e !== void 0 ? _e : [];
  const handleCheckChange = (type, checked) => {
    var _a;
    let newActionRowButtons = actionRowButtons;
    if (checked) {
      newActionRowButtons = [...newActionRowButtons, type];
    } else {
      newActionRowButtons = newActionRowButtons.filter(item => item !== type);
    }
    props.onChange(Object.assign(Object.assign({}, props.abOptions), {
      actionRowOptions: Object.assign(Object.assign({}, (_a = props.abOptions) === null || _a === void 0 ? void 0 : _a.actionRowOptions), {
        actionRowButtons: newActionRowButtons
      })
    }));
  };
  return React.createElement(Box, null, React.createElement(HelpBlock, null, "Action Row Buttons"), React.createElement(Flex, {
    width: "100%",
    m: 2
  }, options.map(option => React.createElement(CheckBox, {
    mr: 3,
    key: option.value,
    checked: actionRowButtons.some(buttonName => buttonName === option.value),
    onChange: check => handleCheckChange(option.value, check)
  }, option.label))));
};