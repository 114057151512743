import { __rest } from "tslib";
import * as React from 'react';
import { Flex, Text } from 'rebass';
import { useAdaptable } from '../../../AdaptableContext';
export const ColumnLabels = props => {
  const {
      children,
      showBoth,
      showTitle,
      flexDirection = 'row',
      labels: labelsProp
    } = props,
    columnProperties = __rest(props, ["children", "showBoth", "showTitle", "flexDirection", "labels"]);
  const labelNames = ['Aggregatable', 'Filterable', 'Groupable', 'Moveable', 'Pivotable', 'Sortable'];
  const labels = labelNames.map(name => columnProperties[name.toLocaleLowerCase()] ? name.charAt(0) : '');
  const flexProps = {
    [flexDirection === 'row' ? 'alignItems' : 'justifyContent']: 'center'
  };
  return React.createElement(Flex, Object.assign({
    className: "ab-LayoutEditor__Legend",
    flexDirection: flexDirection
  }, flexProps, {
    width: "100%"
  }), showTitle ? React.createElement(Text, {
    mr: 2
  }, useAdaptable().api.internalApi.getCorrectEnglishVariant('Behaviours:')) : null, labels.map((l, index) => {
    const enabled = !!l;
    const labelName = labelNames[index];
    const letter = labelName.charAt(0);
    const renderBox = (enabled, key) => {
      return React.createElement(Flex, {
        className: "ab-LayoutEditor__LegendLetter",
        width: '20px',
        height: '20px',
        alignItems: "center",
        justifyContent: "center",
        fontSize: 2,
        ml: 2,
        backgroundColor: "defaultbackground",
        title: enabled ? labelName : `Not ${labelName}`,
        style: {
          overflow: 'hidden',
          cursor: 'auto',
          position: 'relative',
          fontWeight: 600,
          lineHeight: 0,
          border: `1px solid var(--ab-color-${enabled ? 'accent' : 'inputborder'})`,
          borderRadius: 'var(--ab__border-radius)',
          opacity: enabled ? 1 : 0.5
        },
        key: `${letter}${key ? key : ''}`
      }, !enabled ? React.createElement("div", {
        style: {
          background: 'currentColor',
          position: 'absolute',
          bottom: '-100%',
          right: 0,
          width: 2,
          height: '200%',
          transform: 'skewX(45deg)'
        }
      }) : null, letter);
    };
    return React.createElement(Flex, {
      key: letter,
      mt: flexDirection === 'row' ? 0 : 2,
      flexDirection: "row",
      alignItems: "center"
    }, renderBox(enabled), showBoth ? renderBox(!enabled, 'label') : null, labelsProp ? React.createElement(Flex, {
      key: "labelProps",
      flex: 1,
      ml: 2,
      className: "ab-LayoutEditor__LegendLetterDescription"
    }, labelsProp[labelName]) : null);
  }), children);
};