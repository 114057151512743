import * as React from 'react';
import SimpleButton from '../../../components/SimpleButton';
export class ButtonPause extends React.Component {
  render() {
    const buttonIcon = this.props.fillColor === 'red' ? {
      name: 'pause',
      style: {
        fill: 'red'
      }
    } : {
      name: 'pause'
    };
    return React.createElement(SimpleButton, Object.assign({
      "data-name": "pause",
      tooltip: "Pause",
      iconSize: 20,
      icon: buttonIcon,
      variant: "text"
    }, this.props));
  }
}