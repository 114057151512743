import * as ExportRedux from '../../Redux/ActionsReducers/ExportRedux';
import { ApiBase } from './ApiBase';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import { ExportDestination } from '../../PredefinedConfig/Common/Enums';
import ObjectFactory from '../../Utilities/ObjectFactory';
import ArrayExtensions from '../../Utilities/Extensions/ArrayExtensions';
import { ExportInternalApi } from '../Internal/ExportInternalApi';
export class ExportApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.internalApi = new ExportInternalApi(adaptable);
  }
  getExportState() {
    return this.getAdaptableState().Export;
  }
  getCurrentReportName() {
    return this.getExportState().CurrentReport;
  }
  getCurrentReport() {
    const reportName = this.getCurrentReportName();
    return this.getReportByName(reportName);
  }
  getReportByName(reportName) {
    // if system report then create
    if (this.internalApi.isSystemReport(reportName)) {
      return this.internalApi.createSystemReport(reportName);
    } else {
      return this.getReports().find(r => r.Name == reportName);
    }
  }
  getReportById(id) {
    var _a;
    return (_a = this.getReports()) === null || _a === void 0 ? void 0 : _a.find(report => report.Uuid === id);
  }
  getDestinationByName(destinationName) {
    if (Object.values(ExportDestination).some(val => val === destinationName)) {
      return destinationName;
    }
    return this.getCustomDestinations().find(destination => destination.name === destinationName);
  }
  getCurrentDestination() {
    const currentDestination = this.getExportState().CurrentDestination;
    return currentDestination && this.getDestinationByName(currentDestination);
  }
  getAvailableSystemReports() {
    return this.getExportOptions().systemReportNames.filter(s => this.internalApi.isSystemReportActive(s));
  }
  getAvailableExportDestinations() {
    let systemDestinations = [];
    this.getExportOptions().systemExportDestinations.forEach(exportDestination => {
      if (this.internalApi.isSystemExportDestinationActive(exportDestination)) {
        systemDestinations.push(exportDestination);
      }
    });
    return systemDestinations;
  }
  getReports() {
    const reports = this.getAvailableSystemReports().map(s => this.getReportByName(s)).concat(this.getExportState().Reports);
    const externalReports = this.getExternalReports();
    if (ArrayExtensions.IsNotNullOrEmpty(externalReports)) {
      reports.push(...externalReports.map(c => {
        return ObjectFactory.CreateExternalReport(c.name);
      }));
    }
    return reports;
  }
  getReportSchedules() {
    return this.getAdaptableState().Schedule.ReportSchedules;
  }
  runReport(reportName, destination) {
    let report = this.getReportByName(reportName);
    if (this.checkItemExists(report, reportName, 'Report')) {
      this.dispatchAction(ExportRedux.ExportApply(report, destination));
    }
  }
  runSystemReport(systemReportName, destination) {
    this.runReport(systemReportName, destination);
  }
  selectReport(reportName) {
    this.dispatchAction(ExportRedux.ReportSelect(reportName));
  }
  selectDestination(destinationName) {
    this.dispatchAction(ExportRedux.DestinationSelect(destinationName));
  }
  getCustomDestinations() {
    var _a;
    return (_a = this.getExportOptions().customDestinations) !== null && _a !== void 0 ? _a : [];
  }
  isExportDestinationCustom(destinationName) {
    return this.getCustomDestinations().some(destination => destination.name === destinationName);
  }
  getExportDestinationForm(destinationName) {
    var _a;
    return (_a = this.getCustomDestinations().find(destination => destination.name === destinationName)) === null || _a === void 0 ? void 0 : _a.form;
  }
  canExportToExcel() {
    return this.adaptable.canExportToExcel();
  }
  exportDataToExcel(reportData, fileName) {
    this.adaptable.exportToExcel(reportData, fileName);
  }
  openExportSettingsPanel() {
    this.showModulePopup(ModuleConstants.ExportModuleId);
  }
  editReport(report) {
    this.dispatchAction(ExportRedux.ReportEdit(report));
    return this.getReportById(report.Uuid);
  }
  editReports(reports) {
    reports.forEach(report => {
      this.editReport(report);
    });
    return reports === null || reports === void 0 ? void 0 : reports.map(report => this.getReportById(report.Uuid));
  }
  exportVisualDataToExcel() {
    this.adaptable.exportVisualDataToExcel();
  }
  getExternalReports() {
    var _a;
    return (_a = this.getExportOptions().externalReports) !== null && _a !== void 0 ? _a : [];
  }
  isExternalReport(report) {
    var _a;
    return ((_a = this.getExternalReports()) === null || _a === void 0 ? void 0 : _a.find(cr => cr.name == report.Name)) != null;
  }
  runExternalReport(externalReportName) {
    var _a;
    const externalReport = (_a = this.getExternalReports()) === null || _a === void 0 ? void 0 : _a.find(cr => cr.name == externalReportName);
    if (!externalReport) {
      this.logWarn(`External Report '${externalReportName}' not found!`);
      return undefined;
    }
    return externalReport.onRunReport();
  }
  getReportDataForReport(reportName) {
    const report = this.getReportByName(reportName);
    if (!report) {
      this.logWarn(`Report '${reportName}' not found!`);
      return undefined;
    }
    return this.internalApi.getReportData(report);
  }
  isColumnExportable(adaptableColumn) {
    const isExportableFn = this.getExportOptions().isColumnExportable;
    if (typeof isExportableFn === 'function') {
      return isExportableFn(Object.assign(Object.assign({}, this.getAdaptableApi().internalApi.buildBaseContext()), {
        adaptableColumn
      }));
    }
    return true;
  }
}