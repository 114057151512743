import * as React from 'react';
import { useSelector } from 'react-redux';
import { useAdaptable } from '../AdaptableContext';
import { getAlertButtonStyle } from './Utilities/getAlertButtonStyle';
import { StatusBarPanel } from '../StatusBar/StatusBarPanel';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import { ActiveAlertsPanel } from './ActiveAlertsPanel';
const initialStyle = {
  color: '',
  background: ''
};
export const AlertStatusPanel = () => {
  const adaptable = useAdaptable();
  const [style, setStyle] = React.useState(initialStyle);
  const module = adaptable.ModuleService.getModuleById(ModuleConstants.AlertModuleId);
  const statusbarHighlightDuration = adaptable.adaptableOptions.alertOptions.statusbarHighlightDuration;
  /**
   * Using a selector to so the component updates each time an alert is triggered.
   */
  const alerts = useSelector(state => state.System.AdaptableAlerts);
  const text = alerts.length == 0 ? '0 Alerts' : alerts.length == 1 ? '1 Alert' : alerts.length + ' Alerts';
  const handleAction = React.useCallback(() => adaptable.api.alertApi.openAlertSettingsPanel(), []);
  // blinking logic
  const previousAlerts = React.useRef(alerts);
  const timeoutId = React.useRef();
  React.useEffect(() => {
    const noNewAlerts = previousAlerts.current.length === alerts.length;
    previousAlerts.current = alerts;
    if (!alerts.length) {
      return;
    }
    if (noNewAlerts) {
      return;
    }
    const {
      color,
      background
    } = getAlertButtonStyle(alerts);
    setStyle({
      color,
      background
    });
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
    }
    /**
     * Cannot clear timeout in the useEffect cleanup function, because this logic is not
     * called for each case. e.g. it is skipped for when alerts are not added.
     */
    timeoutId.current = setTimeout(() => {
      setStyle(initialStyle);
    }, statusbarHighlightDuration);
  }, [alerts]);
  let alertsPanel = React.createElement(ActiveAlertsPanel, null);
  return React.createElement(StatusBarPanel, {
    tooltip: module.moduleInfo.ModuleName,
    "data-name": module.moduleInfo.ModuleName,
    color: style.color,
    backgroundColor: style.background,
    onAction: handleAction,
    icon: module.moduleInfo.Glyph,
    popover: alerts.length ? alertsPanel : null,
    content: text
  });
};