import { AdaptableModuleBase } from './AdaptableModuleBase';
import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
export class StatusBarModule extends AdaptableModuleBase {
  constructor(api) {
    super(ModuleConstants.StatusBarModuleId, ModuleConstants.StatusBarFriendlyName, 'statusbar', 'StatusBarPopup', 'Configure AdapTable Status Bar', api);
  }
  syncStateWithOptions(agGridAdaptablePanels) {
    const statusPanelsState = this.api.statusBarApi.getAdaptableStatusBars();
    /**
     * Grid config determines how many adaptable status panels are rendered.
     * This dictates the state.
     * - if a status panel is removed from ag-grid, it's sub-panels are made available;
     * - if a new status panel is added it is added to state with an empty list
     */
    const newStatusPanelsState = agGridAdaptablePanels.map(agGridStatusPanel => {
      let statusPanelState = statusPanelsState.find(statusPanel => statusPanel.Key === agGridStatusPanel.key);
      // no state found
      if (!statusPanelState) {
        statusPanelState = {
          Key: agGridStatusPanel.key,
          StatusBarPanels: []
        };
      }
      return statusPanelState;
    });
    this.api.statusBarApi.setStatusBarPanels(newStatusPanelsState);
  }
  isModuleAvailable() {
    return super.isModuleAvailable() && this.api.statusBarApi.getAgGridStatusPanels().length > 0;
  }
}