import { __rest } from "tslib";
import * as React from 'react';
import { Button } from 'rebass';
import join from '../utils/join';
import { allIcons } from '../icons';
import Tooltip from '../Tooltip';
import theme from '../../theme';
import { IconComponent, isAdaptableElementIcon, isAdaptableCustomIcon, isAdaptableSystemIcon } from '../Icon';
export const baseClassName = 'ab-SimpleButton';
const defaultProps = {
  px: null,
  py: null,
  fontWeight: 'normal',
  m: null,
  //@ts-ignore
  borderRadius: null
};
const SimpleButton = React.forwardRef((givenProps, theRef) => {
  var _a, _b;
  const props = Object.assign(Object.assign({}, defaultProps), givenProps);
  let {
      children,
      disabled,
      variant = 'outlined',
      tone = 'neutral',
      iconPosition = 'start',
      iconSize,
      className,
      icon,
      tooltip,
      accessLevel: accessLevel,
      type
    } = props,
    buttonProps = __rest(props, ["children", "disabled", "variant", "tone", "iconPosition", "iconSize", "className", "icon", "tooltip", "accessLevel", "type"]);
  let adaptableSystemIcon;
  if (isAdaptableSystemIcon(icon)) {
    adaptableSystemIcon = icon;
  }
  if (typeof icon === 'string' && allIcons[icon]) {
    adaptableSystemIcon = {
      name: icon
    };
  }
  if (adaptableSystemIcon) {
    const iconProps = {
      icon: adaptableSystemIcon
    };
    if (iconSize) {
      iconProps.icon = Object.assign(Object.assign({}, iconProps.icon), {
        size: iconSize
      });
    }
    if (typeof buttonProps.color === 'string') {
      iconProps.icon = Object.assign(Object.assign({}, iconProps.icon), {
        style: {
          color: buttonProps.color
        }
      });
    }
    icon = React.createElement(IconComponent, Object.assign({}, iconProps));
  }
  if (isAdaptableCustomIcon(icon) || isAdaptableElementIcon(icon)) {
    icon = React.createElement(IconComponent, {
      icon: icon
    });
  }
  if (icon) {
    children = iconPosition === 'start' ? React.createElement(React.Fragment, null, icon, children) : React.createElement(React.Fragment, null, children, icon);
  }
  if (buttonProps.as == 'div') {
    // we have some cases when we want to nest a SimpleButton inside an html Button
    // so the SimpleButton cannot render a <button> tag
    // so we want it to be a DIV tag
    // but still keep the same keyboard accessibility
    buttonProps.tabIndex = buttonProps.tabIndex === undefined ? 0 : buttonProps.tabIndex;
    buttonProps.role = buttonProps.role || 'button';
    const onKeyDown = buttonProps.onKeyDown;
    buttonProps.onKeyDown = e => {
      const key = e.key;
      if (buttonProps.onClick && key === 'Enter') {
        buttonProps.onClick(e);
      }
      if (onKeyDown) {
        onKeyDown(e);
      }
    };
  }
  if (!buttonProps.as || buttonProps.as === 'button') {
    buttonProps.type = type ? type : 'button';
  }
  if (accessLevel === 'Hidden') {
    return null;
  }
  if (accessLevel === 'ReadOnly') {
    disabled = true;
  }
  // for whatever reason, even if fontWeight is present in buttonProps
  // and sent to rebass, styled-components/rebass does not honour it
  // so we had to send it via css
  const fontWeight = buttonProps.fontWeight ? (_b = (_a = theme[buttonProps.fontWeight]) !== null && _a !== void 0 ? _a : buttonProps.fontWeight) !== null && _b !== void 0 ? _b : 'normal' : 'normal';
  const btn = React.createElement(Button, Object.assign({}, buttonProps, {
    disabled: disabled,
    className: join(className, baseClassName, disabled ? `${baseClassName}--disabled` : '', fontWeight ? `${baseClassName}--font-weight=${fontWeight}` : '', `${baseClassName}--variant-${variant}`, `${baseClassName}--tone-${tone}`),
    ref: theRef
  }), children);
  return tooltip ? React.createElement(Tooltip, {
    label: tooltip
  }, btn) : btn;
});
export default SimpleButton;