import { EMPTY_ARRAY } from '../../Utilities/Constants/GeneralConstants';
import AdaptableHelper from '../../Utilities/Helpers/AdaptableHelper';
import { changeIsSuspendInList, suspendAllInList, unsuspendAllInList } from './utils';
/**
 * @ReduxAction A Shortcut has been added
 */
export const SHORTCUT_ADD = 'SHORTCUT_ADD';
/**
 * @ReduxAction A Shortcut has been edited
 */
export const SHORTCUT_EDIT = 'SHORTCUT_EDIT';
/**
 * @ReduxAction A Shortcut has been deleted
 */
export const SHORTCUT_DELETE = 'SHORTCUT_DELETE';
/**
 * @ReduxAction A Shortcut has been suspended
 */
export const SHORTCUT_SUSPEND = 'SHORTCUT_SUSPEND';
/**
 * @ReduxAction A Shortcut has been unsuspended or activated
 */
export const SHORTCUT_UNSUSPEND = 'SHORTCUT_UNSUSPEND';
/**
 * @ReduxAction All Shortcuts have been suspended
 */
export const SHORTCUT_SUSPEND_ALL = 'SHORTCUT_SUSPEND_ALL';
/**
 * @ReduxAction All Shortcuts have been unsuspended or activated
 */
export const SHORTCUT_UNSUSPEND_ALL = 'SHORTCUT_UNSUSPEND_ALL';
/**
 * @ReduxAction Shortcut Module is ready
 */
export const SHORTCUT_READY = 'SHORTCUT_READY';
export const ShortcutAdd = shortcut => ({
  type: SHORTCUT_ADD,
  shortcut
});
export const ShortcutEdit = shortcut => ({
  type: SHORTCUT_EDIT,
  shortcut
});
export const ShortcutDelete = shortcut => ({
  type: SHORTCUT_DELETE,
  shortcut
});
export const ShortcutSuspend = shortcut => ({
  type: SHORTCUT_SUSPEND,
  shortcut
});
export const ShortcutUnSuspend = shortcut => ({
  type: SHORTCUT_UNSUSPEND,
  shortcut
});
export const ShortcutSuspendAll = () => ({
  type: SHORTCUT_SUSPEND_ALL
});
export const ShortcutUnSuspendAll = () => ({
  type: SHORTCUT_UNSUSPEND_ALL
});
export const ShortcutReady = shortcutState => ({
  type: SHORTCUT_READY,
  shortcutState
});
const initialState = {
  Shortcuts: EMPTY_ARRAY
};
export const ShortcutReducer = (state = initialState, action) => {
  let shortcuts;
  switch (action.type) {
    case SHORTCUT_ADD:
      {
        const actionShortcut = action.shortcut;
        AdaptableHelper.addUuidAndSource(actionShortcut);
        shortcuts = [].concat(state.Shortcuts);
        shortcuts.push(actionShortcut);
        return Object.assign(Object.assign({}, state), {
          Shortcuts: shortcuts
        });
      }
    case SHORTCUT_EDIT:
      {
        const actionShortcut = action.shortcut;
        return Object.assign(Object.assign({}, state), {
          Shortcuts: state.Shortcuts.map(abObject => abObject.Uuid === actionShortcut.Uuid ? actionShortcut : abObject)
        });
      }
    case SHORTCUT_DELETE:
      {
        const actionShortcut = action.shortcut;
        return Object.assign(Object.assign({}, state), {
          Shortcuts: state.Shortcuts.filter(abObject => abObject.Uuid !== actionShortcut.Uuid)
        });
      }
    case SHORTCUT_SUSPEND:
      {
        return Object.assign(Object.assign({}, state), {
          Shortcuts: changeIsSuspendInList(action.shortcut, state.Shortcuts, true)
        });
      }
    case SHORTCUT_UNSUSPEND:
      {
        return Object.assign(Object.assign({}, state), {
          Shortcuts: changeIsSuspendInList(action.shortcut, state.Shortcuts, false)
        });
      }
    case SHORTCUT_SUSPEND_ALL:
      {
        return Object.assign(Object.assign({}, state), {
          Shortcuts: suspendAllInList(state.Shortcuts)
        });
      }
    case SHORTCUT_UNSUSPEND_ALL:
      {
        return Object.assign(Object.assign({}, state), {
          Shortcuts: unsuspendAllInList(state.Shortcuts)
        });
      }
    default:
      return state;
  }
};