import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import * as SystemRedux from '../../Redux/ActionsReducers/SystemRedux';
import { ApiBase } from './ApiBase';
import { DataSetInternalApi } from '../Internal/DataSetInternalApi';
export class DataSetApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.internalApi = new DataSetInternalApi(adaptable);
  }
  getDataSets() {
    var _a, _b, _c;
    return (_c = (_b = (_a = this.adaptable.adaptableOptions) === null || _a === void 0 ? void 0 : _a.dataSetOptions) === null || _b === void 0 ? void 0 : _b.dataSets) !== null && _c !== void 0 ? _c : [];
  }
  getCurrentDataSet() {
    let currentDataSetName = this.getAdaptableState().System.CurrentDataSet;
    return this.getDataSetByName(currentDataSetName);
  }
  getDataSetByName(dataSetName) {
    return this.getDataSets().find(a => a.name == dataSetName);
  }
  setDataSet(dataSetName) {
    const dataSet = this.getDataSetByName(dataSetName);
    if (this.checkItemExists(dataSet, dataSetName, 'Data Source')) {
      this.dispatchAction(SystemRedux.SystemDataSetSelect(dataSet));
    }
  }
  clearCurrentDataSet() {
    this.dispatchAction(SystemRedux.SystemDataSetSelect(null));
  }
  openDataSetSettingsPanel() {
    this.showModulePopup(ModuleConstants.DataSetModuleId);
  }
}