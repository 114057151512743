import * as React from 'react';
import SimpleButton from '../../components/SimpleButton';
import { useAdaptable } from '../AdaptableContext';
import DropdownButton from '../../components/DropdownButton';
import { AG_GRID_CHART_WINDOW } from '../../Utilities/Constants/GeneralConstants';
import { isAgChartDefinition, isExternalChartDefinition } from '../../PredefinedConfig/ChartingState';
import { useAgChartState } from './useAgChartState';
import { useExternalChartState } from './useExternalChartState';
const BaseShowChartButton = props => {
  var _a;
  const adaptable = useAdaptable();
  const chartingOptions = (_a = adaptable.adaptableOptions) === null || _a === void 0 ? void 0 : _a.chartingOptions;
  const chartContainers = chartingOptions === null || chartingOptions === void 0 ? void 0 : chartingOptions.chartContainers;
  if (props.chartOpened) {
    return React.createElement(SimpleButton, {
      "data-name": "close-chart-button",
      onClick: props.onCloseChart,
      variant: "text",
      tone: "error",
      icon: "visibility-off",
      tooltip: "Hide Chart"
    });
  }
  if (!(chartContainers === null || chartContainers === void 0 ? void 0 : chartContainers.length)) {
    return React.createElement(SimpleButton, {
      "data-name": "show-chart-button",
      onClick: () => props.onShowChart(),
      variant: "text",
      tone: "success",
      icon: "visibility-on",
      tooltip: "Show Chart"
    });
  }
  return React.createElement(DropdownButton, {
    "data-name": "show-chart-dropdown",
    variant: "text",
    columns: ['label'],
    items: props.containerOptions
  }, React.createElement(SimpleButton, {
    variant: "raised",
    tone: "success"
  }, "Open"));
};
const ShowAgChartButton = props => {
  var _a, _b;
  const adaptable = useAdaptable();
  const chartingOptions = (_a = adaptable.adaptableOptions) === null || _a === void 0 ? void 0 : _a.chartingOptions;
  const chartContainers = chartingOptions === null || chartingOptions === void 0 ? void 0 : chartingOptions.chartContainers;
  const {
    isOpen,
    showChart,
    closeChart
  } = useAgChartState(props.chartDefinition);
  const containerOptions = [Object.assign({
    label: (_b = chartingOptions.agGridContainerName) !== null && _b !== void 0 ? _b : AG_GRID_CHART_WINDOW,
    onClick: () => {
      showChart(null);
    }
  }, chartContainers ? chartContainers === null || chartContainers === void 0 ? void 0 : chartContainers.map(contianerDef => ({
    label: contianerDef.name,
    onClick: () => showChart(contianerDef)
  })) : [])];
  return React.createElement(BaseShowChartButton, {
    chartOpened: isOpen,
    onShowChart: showChart,
    onCloseChart: closeChart,
    containerOptions: containerOptions
  });
};
const ShowExternalChartButton = props => {
  var _a;
  const adaptable = useAdaptable();
  const chartingOptions = (_a = adaptable.adaptableOptions) === null || _a === void 0 ? void 0 : _a.chartingOptions;
  const chartContainers = chartingOptions === null || chartingOptions === void 0 ? void 0 : chartingOptions.chartContainers;
  /**
   * Need to refresh after the chart is closed/opened
   */
  const {
    isOpen,
    showChart: onShow,
    hideChart: onHide
  } = useExternalChartState(props.chartDefinition);
  const containerOptions = chartContainers ? chartContainers === null || chartContainers === void 0 ? void 0 : chartContainers.map(contianerDef => ({
    label: contianerDef.name,
    onClick: () => onShow(contianerDef)
  })) : [];
  return React.createElement(BaseShowChartButton, {
    chartOpened: isOpen,
    onShowChart: onShow,
    onCloseChart: onHide,
    containerOptions: containerOptions
  });
};
export const ShowChartButton = props => {
  if (isAgChartDefinition(props.data)) {
    return React.createElement(ShowAgChartButton, {
      chartDefinition: props.data
    });
  }
  if (isExternalChartDefinition(props.data)) {
    return React.createElement(ShowExternalChartButton, {
      chartDefinition: props.data
    });
  }
  return React.createElement(React.Fragment, null);
};