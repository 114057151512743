import * as React from 'react';
import { PopupPanel } from '../Components/Popups/AdaptablePopup/PopupPanel';
import HelpBlock from '../../components/HelpBlock';
import { LoadButton } from './components/LoadButton';
import { ClearButton } from './components/ClearButton';
import { ExportDropdown } from './components/ExportDropdown';
import Panel from '../../components/Panel';
export const StateManagementPopup = props => {
  const baseClassName = 'ab-ManageState';
  return React.createElement(PopupPanel, {
    className: baseClassName,
    headerText: props.moduleInfo.FriendlyName,
    glyphicon: props.moduleInfo.Glyph,
    infoLink: props.moduleInfo.HelpPage,
    infoLinkDisabled: !props.api.internalApi.isDocumentationLinksDisplayed()
  }, React.createElement(Panel, {
    className: `${baseClassName}__panel`,
    "data-name": "user-state",
    header: 'User State',
    style: {
      height: 'auto'
    },
    variant: "default",
    borderRadius: "none",
    marginRight: 2,
    mb: 2
  }, React.createElement(HelpBlock, {
    marginBottom: 2
  }, 'Clear all current user state that has been persisted'), React.createElement(HelpBlock, {
    style: {
      color: 'var(--ab-color-warn)'
    }
  }, 'This will cause this window to close and any State you have previously created will be lost, and the initial Predefined Config will be reapplied'), React.createElement(ClearButton, {
    "data-name": "clear-user-state-button",
    tone: "info",
    variant: "raised",
    marginTop: 2,
    accessLevel: props.accessLevel,
    onClick: () => props.api.configApi.reloadPredefinedConfig()
  }, "Clear User State")), React.createElement(Panel, {
    className: `${baseClassName}__panel`,
    "data-name": "predefined-config",
    header: 'Predefined Config',
    style: {
      height: 'auto'
    },
    variant: "default",
    borderRadius: "none",
    marginTop: 3,
    marginRight: 2,
    mb: 2
  }, React.createElement(HelpBlock, {
    marginTop: 2
  }, 'Load Predefined Config (from a .json file) - this will cause this window to close'), ' ', React.createElement(LoadButton, {
    tone: "info",
    variant: "raised",
    marginTop: 2,
    accessLevel: props.accessLevel,
    onLoad: json => props.api.configApi.reloadPredefinedConfig(json),
    "data-name": "load-predefined-config-button"
  }, "Load Predefined Config")), React.createElement(Panel, {
    className: `${baseClassName}__panel`,
    "data-name": "adaptable-state",
    header: 'Export Adaptable State',
    style: {
      height: 'auto'
    },
    variant: "default",
    borderRadius: "none",
    marginTop: 3,
    marginRight: 2,
    mb: 2
  }, React.createElement(HelpBlock, {
    marginTop: 2,
    marginBottom: 2
  }, 'Exports the currently persisted Adaptable State'), ' ', React.createElement(ExportDropdown, {
    api: props.api,
    type: "adaptableState",
    marginRight: 3,
    style: {
      flex: 1,
      maxWidth: 'none'
    },
    columns: ['label'],
    "data-name": "export-adaptable-state-dropdown"
  }, "Select Export Destination")), React.createElement(Panel, {
    className: `${baseClassName}__panel`,
    "data-name": "initial-predefined-config",
    header: 'Export Initial Predefined Config',
    style: {
      height: 'auto'
    },
    variant: "default",
    borderRadius: "none",
    marginTop: 3,
    marginRight: 2,
    mb: 2
  }, React.createElement(HelpBlock, {
    marginTop: 2,
    marginBottom: 2
  }, 'Exports the Predefined Config which was provided at design time'), ' ', React.createElement(ExportDropdown, {
    api: props.api,
    type: "predefinedConfig",
    marginRight: 3,
    style: {
      flex: 1,
      maxWidth: 'none'
    },
    columns: ['label'],
    "data-name": "export-initial-predefined-config-dropdown"
  }, "Select Export Destination")));
};