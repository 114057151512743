import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAdaptable } from '../AdaptableContext';
import { ButtonSave } from '../Components/Buttons/ButtonSave';
import * as GridRedux from '../../Redux/ActionsReducers/GridRedux';
import * as LayoutRedux from '../../Redux/ActionsReducers/LayoutRedux';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
export const SaveLayoutButton = () => {
  const adaptable = useAdaptable();
  const accessLevel = adaptable.api.entitlementApi.getEntitlementAccessLevelForModule(ModuleConstants.LayoutModuleId);
  const dispatch = useDispatch();
  const currentDraftLayout = useSelector(state => state.Grid.CurrentLayout);
  const isManualSaveLayout = !adaptable.api.layoutApi.shouldAutoSaveLayouts();
  const handleSave = React.useCallback(layout => {
    dispatch(LayoutRedux.LayoutSave(layout));
    // Cannot be se to null, because it needs to be up to date when it is updated
    dispatch(GridRedux.LayoutUpdateCurrentDraft(layout));
  }, []);
  const isSaveEnabled = currentDraftLayout && !adaptable.api.layoutApi.internalApi.areDraftAndCurrentLayoutEqual();
  if (!isManualSaveLayout) {
    return React.createElement(React.Fragment, null);
  }
  return React.createElement(ButtonSave, {
    ml: 1,
    variant: "text",
    iconSize: 15,
    onClick: () => handleSave(currentDraftLayout),
    tooltip: "Save Changes to Current Layout",
    disabled: !isSaveEnabled,
    accessLevel: accessLevel
  });
};