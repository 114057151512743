import * as React from 'react';
import { useState } from 'react';
import { Box, Flex } from 'rebass';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
import FormLayout, { FormRow } from '../../../components/FormLayout';
import Input from '../../../components/Input';
import { CheckBox } from '../../../components/CheckBox';
import ErrorBox from '../../../components/ErrorBox';
import { Tabs } from '../../../components/Tabs';
import { Tag } from '../../../components/Tag';
export const renderCalculatedColumnDefinitionSummary = data => {
  var _a;
  return React.createElement(Box, {
    fontSize: 2
  }, React.createElement(Box, null, "Column Identifier: ", React.createElement(Tag, null, data.ColumnId)), data.FriendlyName ? React.createElement(Box, {
    mt: 3
  }, "Column Name: ", React.createElement(Tag, null, (_a = data.FriendlyName) !== null && _a !== void 0 ? _a : data.ColumnId)) : null);
};
export const isValidCalculatedColumnDefinition = (data, api) => {
  const columns = api.columnApi.getColumns();
  if (!data.ColumnId) {
    return 'Column Id cannot be empty';
  }
  const columnsWithSameIdCount = columns.filter(c => c.columnId === data.ColumnId).length;
  const hasAlreadyExistingId = data.Uuid ? columnsWithSameIdCount > 1 : columnsWithSameIdCount > 0;
  return hasAlreadyExistingId ? 'A Column already exists with that id' : true;
};
export const CalculatedColumnDefinitionWizardSection = props => {
  var _a;
  const {
    data,
    api
  } = useOnePageAdaptableWizardContext();
  const handleColumnIdChange = event => {
    let e = event.target;
    props.onChange(Object.assign(Object.assign({}, data), {
      ColumnId: e.value
    }));
  };
  const handleColumnNameChange = event => {
    let e = event.target;
    props.onChange(Object.assign(Object.assign({}, data), {
      FriendlyName: e.value
    }));
  };
  const inEdit = props.isEdit;
  const validCheck = isValidCalculatedColumnDefinition(data, api);
  const ErrorMessage = validCheck === true ? null : validCheck;
  const [ColumnNameFocused, setColumnNameFocused] = useState(false);
  const ColumnName = data.FriendlyName;
  const ColumnId = data.ColumnId;
  const {
    ShowToolTip,
    HeaderToolTip
  } = (_a = data.CalculatedColumnSettings) !== null && _a !== void 0 ? _a : {};
  const handleSpecialColumnSettingsChange = settings => {
    props.onChange(Object.assign(Object.assign({}, data), {
      CalculatedColumnSettings: Object.assign(Object.assign({}, data.CalculatedColumnSettings), settings)
    }));
  };
  return React.createElement(Box, {
    "data-name": 'calculated-column-definition'
  }, React.createElement(Tabs, {
    autoFocus: false
  }, React.createElement(Tabs.Tab, null, "Column Details"), React.createElement(Tabs.Content, null, React.createElement(Flex, {
    flexDirection: "row"
  }, React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Column Id"
  }, React.createElement(Input, {
    "data-name": "column-id",
    value: data.ColumnId || '',
    width: 300,
    autoFocus: !inEdit,
    disabled: inEdit,
    type: "text",
    placeholder: "Enter an Id for the column",
    onChange: handleColumnIdChange
  })), React.createElement(FormRow, {
    label: "Name"
  }, React.createElement(Input, {
    "data-name": "column-name",
    autoFocus: inEdit,
    onFocus: () => {
      setColumnNameFocused(true);
    },
    onBlur: () => {
      setColumnNameFocused(false);
    },
    value: ColumnNameFocused ? ColumnName || '' : ColumnName || ColumnId || '',
    width: 300,
    type: "text",
    placeholder: "Enter column name",
    onChange: handleColumnNameChange
  })), React.createElement(FormRow, {
    label: "Header Tooltip"
  }, React.createElement(Input, {
    "data-name": "header-tooltip",
    type: "text",
    width: 300,
    value: HeaderToolTip,
    onChange: e => handleSpecialColumnSettingsChange({
      HeaderToolTip: e.target.value
    })
  })), ' ', React.createElement(FormRow, {
    label: ""
  }, React.createElement(CheckBox, {
    "data-name": "column-show-tooltip",
    onChange: checked => handleSpecialColumnSettingsChange({
      ShowToolTip: checked
    }),
    checked: ShowToolTip
  }, "Show Expression as Cell Tooltip")))))), ErrorMessage ? React.createElement(ErrorBox, {
    marginTop: 2
  }, ErrorMessage) : null);
};