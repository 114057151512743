export class AgGridOptionsService {
  constructor(adaptableInstance) {
    this.adaptableInstance = adaptableInstance;
    this.gridOptionsPropertyCache = new Map();
    this.colDefPropertyCache = new Map();
  }
  destroy() {
    this.gridOptionsPropertyCache.clear();
    this.colDefPropertyCache.clear();
    this.gridOptionsPropertyCache = null;
    this.colDefPropertyCache = null;
  }
  setGridOptionsProperty(gridOptions, propertyName, propertyGetter) {
    if (this.adaptableInstance.lifecycleState === 'preDestroyed') {
      return;
    }
    const userKey = `user.${propertyName}`;
    const adaptableKey = `adaptable.${propertyName}`;
    const value = gridOptions[propertyName];
    const isUserDefined = value !== this.gridOptionsPropertyCache.get(adaptableKey);
    if (isUserDefined) {
      this.gridOptionsPropertyCache.set(userKey, value);
    }
    const userValue = this.gridOptionsPropertyCache.get(userKey);
    let adaptableValue = propertyGetter(userValue);
    if (typeof adaptableValue === 'function') {
      const previousValue = adaptableValue;
      // @ts-ignore we know that adaptableValue is a function
      adaptableValue = (...args) => {
        if (this.adaptableInstance.isDestroyed) {
          return;
        }
        this.agGridAdapter.grabAgGridApiOnTheFly(args);
        return previousValue(...args);
      };
    }
    if (adaptableValue != null) {
      this.gridOptionsPropertyCache.set(adaptableKey, adaptableValue);
    }
    gridOptions[propertyName] = adaptableValue !== null && adaptableValue !== void 0 ? adaptableValue : userValue;
    return gridOptions;
  }
  getUserGridOptionsProperty(propertyName) {
    const userKey = `user.${propertyName}`;
    return this.gridOptionsPropertyCache.get(userKey);
  }
  revertGridOptionsPropertyToUserValue(propertyName) {
    const userKey = `user.${propertyName}`;
    const userValue = this.gridOptionsPropertyCache.get(userKey);
    this.agGridAdapter.setGridOption(propertyName, userValue);
  }
  revertGridOptionsPropertiesToUserValue(gridOptions, propertyNames) {
    for (const propertyName of propertyNames) {
      // see this.setGridOptionsProperty(...)
      const userKey = `user.${propertyName}`;
      const userValue = this.gridOptionsPropertyCache.get(userKey);
      gridOptions[propertyName] = userValue;
    }
  }
  get agGridAdapter() {
    return this.adaptableInstance.agGridAdapter;
  }
}