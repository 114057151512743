import * as React from 'react';
import { AdaptableObjectRow } from '../Components/AdaptableObjectRow';
import { Flex, Box } from 'rebass';
import UIHelper from '../UIHelper';
import { cloneObject } from '../../Utilities/Helpers/Helper';
import join from '../../components/utils/join';
export class SystemStatusEntityRow extends React.Component {
  render() {
    var _a;
    let systemStatus = this.props.adaptableObject;
    let messageTypeColor = UIHelper.getColorByMessageType((_a = systemStatus === null || systemStatus === void 0 ? void 0 : systemStatus.statusType) !== null && _a !== void 0 ? _a : 'Success');
    let colItems = cloneObject(this.props.colItems);
    colItems[0].Content = systemStatus ? React.createElement("details", null, React.createElement(Flex, {
      title: systemStatus.statusFurtherInformation ? 'Click to see more' : '',
      className: join('ab-DashboardToolbar__SystemStatus__text', systemStatus.statusFurtherInformation && 'ab-DashboardToolbar__SystemStatus__text--expandable'),
      backgroundColor: messageTypeColor,
      alignItems: "center",
      as: "summary"
    }, systemStatus.statusMessage, " ", systemStatus.statusFurtherInformation ? '...' : ''), systemStatus.statusFurtherInformation ? React.createElement(Box, {
      className: "ab-DashboardToolbar__SystemStatus__further-information",
      margin: 2
    }, systemStatus.statusFurtherInformation) : null) : null;
    colItems[1].Content = systemStatus.timestamp.toLocaleString();
    return React.createElement(AdaptableObjectRow, {
      colItems: colItems
    });
  }
}