import { __rest } from "tslib";
import * as React from 'react';
import { Box } from 'rebass';
import join from '../utils/join';
export const baseClassName = 'ab-Textarea';
const Textarea = React.forwardRef((props, ref) => {
  const internalRef = React.useRef(null);
  let {
      disabled,
      autoResizeOnFocus,
      className
    } = props,
    textareaProps = __rest(props, ["disabled", "autoResizeOnFocus", "className"]);
  const initialHeight = React.useRef(null);
  let type = 'text';
  if (textareaProps && textareaProps.type) {
    type = textareaProps.type;
  }
  if (type === 'string') {
    type = 'text';
  }
  const expandToFitContent = () => {
    if (autoResizeOnFocus && initialHeight.current !== null && internalRef.current) {
      internalRef.current.style.height = `0px`; // makes sure it shrinks when needed
      const height = internalRef.current.scrollHeight;
      internalRef.current.style.height = `${height}px`;
    }
  };
  const shrinkToInitialHeight = () => {
    if (autoResizeOnFocus) {
      internalRef.current.style.height = `${initialHeight.current}px`;
    }
  };
  return React.createElement(Box, Object.assign({}, textareaProps, {
    onFocus: e => {
      var _a;
      expandToFitContent();
      (_a = props.onFocus) === null || _a === void 0 ? void 0 : _a.call(props, e);
      if (internalRef.current && initialHeight.current === null) {
        const height = internalRef.current.getBoundingClientRect().height;
        initialHeight.current = height;
      }
    },
    onBlur: e => {
      var _a;
      shrinkToInitialHeight();
      (_a = props.onBlur) === null || _a === void 0 ? void 0 : _a.call(props, e);
    },
    ref: elRef => {
      // SAVE INITIAL HEIGHT
      // if (elRef && initialHeight.current === null) {
      //   const height = elRef.getBoundingClientRect().height;
      //   initialHeight.current = isNaN(height) ? 'auto' : height;
      // }
      // SAVE REF
      if (internalRef) {
        internalRef.current = elRef;
      }
      // CALL EXTERNAL REF
      if (ref) {
        if (typeof ref === 'function') {
          ref(elRef);
        } else {
          ref.current = elRef;
        }
      }
    },
    onChange: e => {
      expandToFitContent();
      props.onChange && props.onChange(e);
    },
    as: "textarea",
    type: type,
    disabled: disabled,
    className: join(className, baseClassName, disabled ? `${baseClassName}--disabled` : ''),
    spellCheck: false
  }));
});
export default Textarea;