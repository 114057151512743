export function times100(value) {
  // the simple solution does not work
  // return value * 100;
  // needed because 0.12676 * 100 = 12.676000000000002
  // but we dont want that to happen
  // this solution does not handle  -0 correctly
  // also 1267.6 * 100 is 126759.99999999999
  // which is not what we want
  // return parseFloat((value * 100).toFixed(12));
  if (isNaN(value) || !value) {
    return value;
  }
  const valueAsStr = value.toPrecision();
  const separator = Number(1.1).toLocaleString()[1];
  const indexOfSeparator = valueAsStr.indexOf(separator);
  if (indexOfSeparator === -1) {
    return value * 100;
  }
  const [integer, decimal] = valueAsStr.split(separator);
  const num = [...`${integer}${decimal !== null && decimal !== void 0 ? decimal : ''}00`];
  num.splice(indexOfSeparator + 2, 0, separator);
  return Number(num.join(''));
}