import * as React from 'react';
import { PanelWithImage } from '../Panels/PanelWithImage';
import { UIHelper } from '../../UIHelper';
import { Flex, Text, Box } from 'rebass';
import Dialog from '../../../components/Dialog';
import { AdaptableFormComponent } from '../../../components/AdaptableFormComponent';
import { useState } from 'react';
import { useAdaptable } from '../../AdaptableContext';
import { AdaptableIconComponent } from '../AdaptableIconComponent';
export const AdaptablePopupAlert = props => {
  var _a;
  const messageType = props.adaptableAlert.alertDefinition.MessageType || 'Error';
  const msg = (_a = props.adaptableAlert.message) !== null && _a !== void 0 ? _a : '';
  const headerColor = UIHelper.getColorByMessageType(messageType);
  let header = messageType;
  const {
    api,
    adaptableOptions
  } = useAdaptable();
  if (props.adaptableAlert.header) {
    header = `${header}: ${props.adaptableAlert.header}`;
  }
  let glyph = UIHelper.getGlyphByMessageType(messageType);
  // we still need the defauld
  const defaultForm = React.useMemo(() => {
    return api.alertApi.internalApi.getDefaultAlertNotificationForm();
  }, []);
  const [formData, setFormData] = useState({});
  const context = React.useMemo(() => {
    return Object.assign(Object.assign({
      alert: props.adaptableAlert
    }, api.internalApi.buildBaseContext()), {
      formData: formData
    });
  }, [JSON.stringify(formData), props.adaptableAlert]);
  const adaptableForm = React.useMemo(() => {
    if (api.isDestroyed()) {
      return null;
    }
    return api.alertApi.internalApi.getAdaptableFormFromAlertForm(props.adaptableAlert.alertDefinition.AlertForm || defaultForm, context, messageType);
  }, [props.adaptableAlert.alertDefinition.AlertForm, defaultForm, messageType, context]);
  const renderApplicationIcon = icon => {
    if (!icon) {
      return null;
    }
    return React.createElement(Flex, {
      flexDirection: "column",
      alignSelf: "end",
      alignItems: "right"
    }, ' ', React.createElement(AdaptableIconComponent, {
      icon: icon,
      iconClassName: 'alert-popup__application-icon'
    }));
  };
  const content = React.createElement(PanelWithImage, {
    header: header,
    headerColor: headerColor,
    glyphicon: glyph,
    bodyProps: {
      padding: 2
    }
  }, React.createElement("div", null, React.createElement(Flex, {
    flexDirection: "column",
    alignItems: "flex-start"
  }, renderApplicationIcon(adaptableOptions.notificationsOptions.showApplicationIcon && adaptableOptions.userInterfaceOptions.applicationIcon), typeof msg === 'string' ? msg.split('\n').map(function (item, index) {
    return React.createElement(Flex, {
      flexDirection: "row",
      key: index
    }, React.createElement(Text, {
      key: index,
      margin: 2
    }, item, React.createElement("br", null)));
  }) : msg), React.createElement(Box, {
    margin: 2
  }, adaptableForm ? React.createElement(AdaptableFormComponent, {
    focusFirstButton: props.focusFirstButton,
    data: formData,
    formDef: adaptableForm,
    displayTitle: true,
    onChange: setFormData,
    api: api,
    context: context,
    onButtonClick: button => {
      props.onClose();
      // we want to give the current popup time to close
      // and we reopen with a delay in case this button action causes another popup
      setTimeout(() => {
        var _a;
        (_a = button.onClick) === null || _a === void 0 ? void 0 : _a.call(button, button, context);
      }, 20);
    }
  }) : null)));
  return props.headless ? content : React.createElement(Dialog, {
    "data-name": `alert-popup alert-popup-${messageType.toLowerCase()}`,
    showCloseButton: false,
    isOpen: true,
    onDismiss: props.onClose,
    style: {
      minHeight: 'auto',
      minWidth: '20vw',
      maxWidth: '70vw'
    }
  }, content);
};