import * as React from 'react';
import SimpleButton from '../../components/SimpleButton';
import * as PlusMinusRedux from '../../Redux/ActionsReducers/PlusMinusRedux';
import { useDispatch, useSelector } from 'react-redux';
import { useAdaptable } from '../AdaptableContext';
export const MovePlusMinus = props => {
  const dispatch = useDispatch();
  const adaptable = useAdaptable();
  const plusMinusNudges = useSelector(state => state.PlusMinus.PlusMinusNudges);
  const handleMoveUp = React.useCallback(() => {
    dispatch(PlusMinusRedux.PlusMinusMoveUp(props.data));
  }, []);
  const handleMoveDown = React.useCallback(() => {
    dispatch(PlusMinusRedux.PlusMinusMoveDown(props.data));
  }, []);
  if ((plusMinusNudges === null || plusMinusNudges === void 0 ? void 0 : plusMinusNudges.length) <= 1) {
    return React.createElement(React.Fragment, null);
  }
  return React.createElement(React.Fragment, null, React.createElement(SimpleButton, {
    "data-id": "move-up",
    title: "move up",
    onClick: handleMoveUp,
    variant: "text",
    icon: "arrow-up"
  }), React.createElement(SimpleButton, {
    "data-name": "move-down",
    title: "move down",
    onClick: handleMoveDown,
    variant: "text",
    icon: "arrow-down"
  }));
};