import * as React from 'react';
import join from '../../components/utils/join';
import * as GeneralConstants from '../../Utilities/Constants/GeneralConstants';
import { LoadButton } from './components/LoadButton';
import { ClearButton } from './components/ClearButton';
import { Flex } from 'rebass';
import { ExportDropdown } from './components/ExportDropdown';
export const StateManagementViewPanel = props => {
  const isToolbar = props.viewType === 'Toolbar';
  const elementType = isToolbar ? 'DashboardToolbar' : 'ToolPanel';
  return React.createElement(Flex, {
    flexDirection: isToolbar ? 'row' : 'column',
    className: join(props.accessLevel == 'ReadOnly' ? GeneralConstants.READ_ONLY_STYLE : '', `ab-${elementType}__StateManagement__wrap`)
  }, React.createElement(Flex, {
    flexDirection: "row"
  }, React.createElement(LoadButton, {
    tooltip: "Load Predefined Config",
    variant: "text",
    icon: "upload",
    onLoad: json => props.api.configApi.reloadPredefinedConfig(json),
    accessLevel: props.accessLevel
  }, !isToolbar && 'Load'), React.createElement(ClearButton, {
    icon: "delete",
    tooltip: "Clear User State",
    variant: "text",
    onClick: () => props.api.configApi.reloadPredefinedConfig(),
    accessLevel: props.accessLevel
  }, !isToolbar && 'Clear')), React.createElement(ExportDropdown, {
    api: props.api,
    type: "adaptableState"
  }, "All State"), React.createElement(ExportDropdown, {
    api: props.api,
    type: "predefinedConfig"
  }, "Predefined Config"));
};