import { EnumExtensions } from '../Extensions/EnumExtensions';
// required for hacky fix for https://github.com/AdaptableTools/adaptable/issues/2119
export const AgGridCellStyleProperties = ['backgroundColor', 'color', 'fontWeight', 'fontStyle', 'fontSize', 'borderColor'];
// see https://github.com/AdaptableTools/adaptable/issues/2119
// since v29 AG Grid ignores style properties with null or undefined values
// Fix: pass empty string instead of null/undefined
export const normalizeStyleForAgGrid = style => {
  const normalizedStyle = Object.assign({}, style);
  AgGridCellStyleProperties.forEach(cellStylePropKey => {
    if (normalizedStyle[cellStylePropKey] == null) {
      normalizedStyle[cellStylePropKey] = '';
    }
  });
  return normalizedStyle;
};
// the inverse of normalizeStyleForAgGrid, see comments above
export const sanitizeStyle = style => {
  const sanitizedStyle = {};
  Object.keys(style).forEach(key => {
    if (style[key] != null && style[key] !== '') {
      sanitizedStyle[key] = style[key];
    }
  });
  return sanitizedStyle;
};
export const convertAdaptableStyleToCSS = style => {
  let result = {};
  if (style.BackColor !== undefined && style.BackColor !== null) {
    result.backgroundColor = style.BackColor;
  }
  if (style.ForeColor !== undefined && style.ForeColor !== null) {
    result.color = style.ForeColor;
  }
  if (style.FontWeight !== undefined && style.FontWeight !== null) {
    result.fontWeight = style.FontWeight.toLocaleLowerCase();
  }
  if (style.FontStyle !== undefined && style.FontStyle !== null) {
    result.fontStyle = style.FontStyle.toLocaleLowerCase();
  }
  if (style.FontSize !== undefined && style.FontSize !== null) {
    result.fontSize = EnumExtensions.getCssFontSizeFromFontSizeEnum(style.FontSize);
  }
  if (style.BorderColor !== undefined && style.BorderColor !== null) {
    result.borderColor = style.BorderColor;
  }
  if (style.BorderRadius !== undefined && style.BorderRadius !== null) {
    const preparedRadious = typeof style.BorderRadius === 'number' ? `${style.BorderRadius}px` : style.BorderRadius;
    result.borderRadius = preparedRadious;
  }
  if (style.TextDecoration !== undefined && style.TextDecoration !== null) {
    switch (style.TextDecoration) {
      case 'None':
        break;
      case 'Underline':
        result.textDecoration = 'underline';
        break;
      case 'Overline':
        result.textDecoration = 'overline';
        break;
      case 'LineThrough':
        result.textDecoration = 'line-through';
        break;
    }
  }
  // assertion added to comply with the ag-Grid types
  return result;
};
// based on http://jerekdain.com/fontconversion.html
export const convertCSSAbsoluteFontSizeToPt = fontSize => {
  switch (fontSize) {
    case 'x-large':
      return 18;
    case 'large':
      return 13.5;
    case 'medium':
      return 12;
    case 'small':
      return 10;
    case 'x-small':
      return 7;
    default:
      return 12;
  }
};
export const toStyle = style => {
  return Object.assign(Object.assign({}, convertAdaptableStyleToCSS(style !== null && style !== void 0 ? style : {})), {
    borderWidth: (style === null || style === void 0 ? void 0 : style.BorderColor) ? 2 : 0,
    borderStyle: 'solid'
  });
};
export const getVariableColor = variable => {
  var _a;
  const isVariable = (_a = variable === null || variable === void 0 ? void 0 : variable.startsWith) === null || _a === void 0 ? void 0 : _a.call(variable, 'var(');
  if (!isVariable) {
    return variable;
  }
  const match = /\((.+)\)/.exec(variable);
  if (!match) {
    return variable;
  }
  return getComputedStyle(document.documentElement).getPropertyValue(match[1]).trim();
};