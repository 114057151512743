import { EMPTY_ARRAY } from '../../Utilities/Constants/GeneralConstants';
/**
 * @ReduxAction The Module Buttons have been set in the Dashboard
 */
export const DASHBOARD_SET_MODULE_BUTTONS = 'DASHBOARD_SET_MODULE_BUTTONS';
/**
 * @ReduxAction Active tab has changed
 */
export const DASHBOARD_ACTIVE_TAB_INDEX_CHANGE = 'DASHBOARD_ACTIVE_TAB_INDEX_CHANGE';
/**
 * @ReduxAction Collapsed Mode has changed
 */
export const DASHBOARD_SET_IS_COLLAPSED = 'DASHBOARD_SET_IS_COLLAPSED';
/**
 * @ReduxAction Floating Mode has changed
 */
export const DASHBOARD_SET_IS_FLOATING = 'DASHBOARD_SET_IS_FLOATING';
/**
 * @ReduxAction Inline Mode has changed
 */
export const DASHBOARD_SET_IS_INLINE = 'DASHBOARD_SET_IS_INLINE';
/**
 * @ReduxAction Visibility has changed
 */
export const DASHBOARD_SET_IS_HIDDEN = 'DASHBOARD_SET_IS_HIDDEN';
/**
 * @ReduxAction Floating position has changed
 */
export const DASHBOARD_SET_FLOATING_POSITION = 'DASHBOARD_SET_FLOATING_POSITION';
/**
 * @ReduxAction New Dashboard Tabs have been set
 */
export const DASHBOARD_SET_TABS = 'DASHBOARD_SET_TABS';
/**
 * @ReduxAction Set Pinned Toolbars Contents
 */
export const DASHBOARD_SET_PINNED_TOOLBARS = 'DASHBOARD_SET_PINNED_TOOLBARS';
/**
 * @ReduxAction A Toolbar has been closed
 */
export const DASHBOARD_CLOSE_TOOLBAR = 'DASHBOARD_CLOSE_TOOLBAR';
/**
 * @ReduxAction Dashboard Title has been set
 */
export const DASHBOARD_SET_TITLE = 'DASHBOARD_SET_TITLE';
/**
 * @ReduxAction Dashboard is ready
 */
export const DASHBOARD_READY = 'DASHBOARD_READY';
export const DashboardSetModuleButtons = moduleButtons => ({
  type: DASHBOARD_SET_MODULE_BUTTONS,
  moduleButtons: moduleButtons
});
export const DashboardSetActiveTabIndex = ActiveTabIndex => ({
  type: DASHBOARD_ACTIVE_TAB_INDEX_CHANGE,
  ActiveTabIndex
});
export const DashboardSetIsCollapsed = IsCollapsed => ({
  type: DASHBOARD_SET_IS_COLLAPSED,
  IsCollapsed
});
export const DashboardSetIsFloating = IsFloating => ({
  type: DASHBOARD_SET_IS_FLOATING,
  IsFloating
});
export const DashboardSetIsHidden = IsHidden => ({
  type: DASHBOARD_SET_IS_HIDDEN,
  IsHidden
});
export const DashboardSetIsInline = IsInline => ({
  type: DASHBOARD_SET_IS_INLINE,
  IsInline
});
export const DashboardSetFloatingPosition = FloatingPosition => ({
  type: DASHBOARD_SET_FLOATING_POSITION,
  FloatingPosition
});
export const DashboardSetTabs = Tabs => ({
  type: DASHBOARD_SET_TABS,
  Tabs
});
export const DashboardCloseToolbar = toolbar => ({
  toolbar,
  type: DASHBOARD_CLOSE_TOOLBAR
});
export const DashboardSetTitle = title => ({
  type: DASHBOARD_SET_TITLE,
  title
});
export const DashboardReady = dashboardState => ({
  type: DASHBOARD_READY,
  dashboardState
});
export const DashboardSetPinnedToolbars = pinnedToolbars => ({
  type: DASHBOARD_SET_PINNED_TOOLBARS,
  pinnedToolbars
});
const initialState = {
  Tabs: EMPTY_ARRAY,
  ActiveTabIndex: 0,
  IsCollapsed: false,
  IsFloating: false,
  IsHidden: false,
  IsInline: false,
  FloatingPosition: {
    x: 100,
    y: 100
  },
  ModuleButtons: ['SettingsPanel'],
  DashboardTitle: ''
};
export const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_SET_MODULE_BUTTONS:
      {
        const actionTyped = action;
        const dashboardModuleButtons = actionTyped.moduleButtons;
        return Object.assign({}, state, {
          ModuleButtons: dashboardModuleButtons,
          VisibleButtons: undefined
        });
      }
    case DASHBOARD_ACTIVE_TAB_INDEX_CHANGE:
      {
        const {
          ActiveTabIndex
        } = action;
        return Object.assign(Object.assign({}, state), {
          ActiveTabIndex
        });
      }
    case DASHBOARD_SET_IS_COLLAPSED:
      {
        const {
          IsCollapsed
        } = action;
        return Object.assign(Object.assign({}, state), {
          IsCollapsed
        });
      }
    case DASHBOARD_SET_IS_FLOATING:
      {
        const {
          IsFloating
        } = action;
        return Object.assign(Object.assign({}, state), {
          IsFloating
        });
      }
    case DASHBOARD_SET_IS_HIDDEN:
      {
        const {
          IsHidden
        } = action;
        return Object.assign(Object.assign({}, state), {
          IsHidden
        });
      }
    case DASHBOARD_SET_IS_INLINE:
      {
        const {
          IsInline
        } = action;
        return Object.assign(Object.assign({}, state), {
          IsInline
        });
      }
    case DASHBOARD_SET_FLOATING_POSITION:
      {
        const {
          FloatingPosition
        } = action;
        return Object.assign(Object.assign({}, state), {
          FloatingPosition
        });
      }
    case DASHBOARD_SET_TABS:
      {
        const {
          Tabs
        } = action;
        return Object.assign(Object.assign({}, state), {
          Tabs
        });
      }
    case DASHBOARD_CLOSE_TOOLBAR:
      {
        const actionTyped = action;
        const tabs = [].concat(state.Tabs);
        const currentTab = tabs[state.ActiveTabIndex];
        if (currentTab) {
          const toolbars = [].concat(currentTab.Toolbars);
          const index = toolbars.findIndex(t => t == actionTyped.toolbar);
          toolbars.splice(index, 1);
          currentTab.Toolbars = toolbars;
          return Object.assign(Object.assign({}, state), {
            Tabs: tabs
          });
        } else {
          return Object.assign({}, state);
        }
      }
    case DASHBOARD_SET_TITLE:
      {
        const actionTyped = action;
        return Object.assign({}, state, {
          DashboardTitle: actionTyped.title
        });
      }
    case DASHBOARD_SET_PINNED_TOOLBARS:
      {
        const actionTyped = action;
        return Object.assign({}, state, {
          PinnedToolbars: actionTyped.pinnedToolbars
        });
      }
    default:
      return state;
  }
};