/**
 * @ReduxAction Smart Edit has been completed
 */
export const SMART_EDIT_COMPLETE = 'SMART_EDIT_COMPLETE';
/**
 * @ReduxAction Smart Editis being applied
 */
export const SMART_EDIT_APPLY = 'SMART_EDIT_APPLY';
/**
 * @ReduxAction SmartEdit Module is ready
 */
export const SMART_EDIT_READY = 'SMART_EDIT_READY';
export const SmartEditRun = bypassValidationWarnings => ({
  type: SMART_EDIT_COMPLETE,
  bypassValidationWarnings: bypassValidationWarnings
});
export const SmartEditApply = cellUpdateRequests => ({
  type: SMART_EDIT_APPLY,
  cellUpdateRequests
});
export const SmartEditReady = () => ({
  type: SMART_EDIT_READY
});