/**
 * @ReduxAction A System Status Message is set
 */
export const SYSTEM_STATUS_MESSAGE_SET = 'SYSTEM_STATUS_MESSAGE_SET';
/**
 * @ReduxAction SystemStatus Module is ready
 */
export const SYSTEM_STATUS_READY = 'SYSTEM_STATUS_READY';
export const SystemStatusSetMesage = systemStatusMessageInfo => ({
  type: SYSTEM_STATUS_MESSAGE_SET,
  systemStatusMessageInfo
});
export const SystemStatusReady = () => ({
  type: SYSTEM_STATUS_READY
});