import { EMPTY_ARRAY } from '../../Utilities/Constants/GeneralConstants';
/**
 * @ReduxAction Sets Status Bar panels
 */
export const SET_STATUS_BAR_PANELS = 'SET_STATUS_BAR_PANELS';
/**
 * @ReduxAction AdapTable Status Bar is ready
 */
export const STATUS_BAR_READY = 'STATUS_BAR_READY';
export const StatusBarSetPanels = statusPanels => ({
  statusPanels: statusPanels,
  type: SET_STATUS_BAR_PANELS
});
export const initialState = {
  StatusBars: EMPTY_ARRAY
};
export const getStatusPanelsSelector = state => state.StatusBar.StatusBars;
export const StatusBarReady = statusBarState => ({
  type: STATUS_BAR_READY,
  statusBarState
});
export const StatusBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STATUS_BAR_PANELS:
      const newPanels = action.statusPanels;
      return Object.assign(Object.assign({}, state), {
        StatusBars: newPanels
      });
  }
  return state;
};