import * as React from 'react';
import { useMemo } from 'react';
import { Text } from 'rebass';
import { ExpressionEditor } from '../../../components/ExpressionEditor';
import { Tag } from '../../../components/Tag';
import { NamedQueryModuleId } from '../../../Utilities/Constants/ModuleConstants';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
export const isValidNamedQueryExpression = (data, api) => {
  if (!data.BooleanExpression) {
    return 'Expression is empty';
  }
  const valid = api.expressionApi.isValidBooleanExpression(data.BooleanExpression, NamedQueryModuleId);
  if (!valid) {
    return 'Query is not a valid Boolean Query';
  }
  return valid;
};
export const renderNamedQueryExpressionSummary = data => {
  const {
    api
  } = useOnePageAdaptableWizardContext();
  return React.createElement(Text, {
    fontSize: 2
  }, "Expression: ", React.createElement(Tag, null, api.internalApi.getAdaptableQueryExpressionText(data)));
};
export const NamedQueryExpressionWizardSection = props => {
  const {
    data,
    moduleInfo,
    api
  } = useOnePageAdaptableWizardContext();
  const initialData = useMemo(() => api.internalApi.getQueryPreviewData(), []);
  return React.createElement(ExpressionEditor, {
    allowSaveNamedQuery: false,
    showQueryBuilder: true,
    type: 'boolean',
    module: moduleInfo.ModuleName,
    value: data.BooleanExpression,
    onChange: BooleanExpression => {
      props.onChange(Object.assign(Object.assign({}, data), {
        BooleanExpression
      }));
    },
    initialData: initialData,
    columns: api.columnApi.getQueryableColumns(),
    fields: api.expressionApi.internalApi.getAvailableFields(),
    namedQueries: api.namedQueryApi.getNamedQueries(),
    api: api
  });
};