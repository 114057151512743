import * as React from 'react';
import { useState } from 'react';
import { Box } from 'rebass';
import { OnePageAdaptableWizard, OnePageWizardSummary } from '../../Wizard/OnePageAdaptableWizard';
import { cloneObject } from '../../../Utilities/Helpers/Helper';
import { FreeTextColumnSettingsWizardSection, isValidFreeTextColumn, renderFreeTextColumnSummary } from './FreeTextColumnSettingsWizardSection';
import ObjectFactory from '../../../Utilities/ObjectFactory';
import * as FreeTextColumnRedux from '../../../Redux/ActionsReducers/FreeTextColumnRedux';
import { useDispatch, useSelector } from 'react-redux';
import { ObjectTagsWizardSection, renderObjectTagsSummary } from '../../Wizard/ObjectTagsWizardSection';
import { useAdaptable } from '../../AdaptableContext';
export const FreeTextColumnWizard = props => {
  var _a;
  const adaptable = useAdaptable();
  const allFreeTextColumns = useSelector(state => state.FreeTextColumn.FreeTextColumns);
  const [freeTextColumn, setFreeTextColumn] = useState(() => {
    var _a, _b;
    if (props.data) {
      return cloneObject(props.data);
    }
    if (((_a = props.popupParams) === null || _a === void 0 ? void 0 : _a.action) === 'Edit' && ((_b = props.popupParams) === null || _b === void 0 ? void 0 : _b.column)) {
      return allFreeTextColumns.find(column => column.ColumnId === props.popupParams.column.columnId);
    }
    return ObjectFactory.CreateEmptyFreeTextColumn(adaptable.api.gridApi.internalApi.deriveSpecialColumnSettingsFromAgGridDefaultColDef());
  });
  const dispatch = useDispatch();
  const isEdit = props.data || ((_a = props.popupParams) === null || _a === void 0 ? void 0 : _a.action) === 'Edit';
  const handleFinish = () => {
    if (isEdit) {
      dispatch(FreeTextColumnRedux.FreeTextColumnEdit(freeTextColumn));
    } else {
      dispatch(FreeTextColumnRedux.FreeTextColumnAdd(freeTextColumn));
    }
    props.onFinishWizard(freeTextColumn);
  };
  return React.createElement(OnePageAdaptableWizard, {
    defaultCurrentSectionName: props.defaultCurrentSectionName,
    moduleInfo: props.moduleInfo,
    data: freeTextColumn,
    onHide: props.onCloseWizard,
    onFinish: handleFinish,
    sections: [{
      title: 'Settings',
      details: 'Configure the Free Text Column',
      isValid: isValidFreeTextColumn,
      render: () => {
        return React.createElement(Box, {
          padding: 2
        }, React.createElement(FreeTextColumnSettingsWizardSection, {
          isEdit: Boolean(props.data),
          onChange: setFreeTextColumn
        }));
      }
    }, {
      details: 'Select Free Text Column Tags',
      title: 'Tags',
      isVisible: (_, api) => api.internalApi.shouldDisplayTagSections(),
      render: () => React.createElement(Box, {
        padding: 2
      }, React.createElement(ObjectTagsWizardSection, {
        onChange: setFreeTextColumn
      })),
      renderSummary: renderObjectTagsSummary
    }, '-', {
      details: 'Review the Free Text Column',
      renderSummary: renderFreeTextColumnSummary,
      render: () => {
        return React.createElement(Box, {
          padding: 2
        }, React.createElement(OnePageWizardSummary, null));
      },
      title: 'Summary'
    }]
  });
};