import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as QuickSearchRedux from '../../Redux/ActionsReducers/QuickSearchRedux';
import { AdaptableFormControlTextClear } from '../Components/Forms/AdaptableFormControlTextClear';
import { useQuickSearchDebounced } from './useQuickSearchDebounced';
import { useAdaptable } from '../AdaptableContext';
export const QuickSearchInput = props => {
  var _a;
  const adaptable = useAdaptable();
  const QuickSearchText = useSelector(state => state.QuickSearch.QuickSearchText);
  const dispatch = useDispatch();
  const onRunQuickSearch = React.useCallback(newQuickSearchText => dispatch(QuickSearchRedux.QuickSearchRun(newQuickSearchText)), []);
  const [searchText, search] = useQuickSearchDebounced({
    QuickSearchText,
    onRunQuickSearch
  });
  return React.createElement(AdaptableFormControlTextClear, {
    type: "text",
    autoFocus: props.autoFocus,
    placeholder: adaptable.api.optionsApi.getQuickSearchOptions().quickSearchPlaceholder,
    className: props.className,
    value: searchText,
    OnTextChange: search,
    style: {
      height: '100%'
    },
    inputStyle: {
      width: (_a = props.width) !== null && _a !== void 0 ? _a : '10rem'
    }
  });
};