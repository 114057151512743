import * as React from 'react';
import { Box, Text } from 'rebass';
import ErrorBox from '../../../components/ErrorBox';
import FormLayout, { FormRow } from '../../../components/FormLayout';
import Input from '../../../components/Input';
import { Tag } from '../../../components/Tag';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
export const renderReportNameSummary = report => {
  return React.createElement(Box, {
    fontSize: 2
  }, ' ', "Report Name: ", React.createElement(Tag, null, report.Name));
};
export const isValidReportName = (report, api) => {
  if (!report.Name) {
    return 'Report name cannot be empty';
  }
  const reportsWithSameNameCount = api.exportApi.getReports().filter(r => report.Name === r.Name).length;
  const hasAlreadyExistingName = report.Uuid ? reportsWithSameNameCount > 1 : reportsWithSameNameCount > 0;
  return hasAlreadyExistingName ? 'A Report already exists with that name' : true;
};
export const ReportNameWizardSection = props => {
  const {
    api,
    data
  } = useOnePageAdaptableWizardContext();
  const validCheck = isValidReportName(data, api);
  const ErrorMessage = validCheck === true ? null : validCheck;
  return React.createElement(Box, null, React.createElement(FormLayout, {
    columns: [1, 2]
  }, React.createElement(FormRow, null, React.createElement(Text, {
    marginRight: 2
  }, "Name:"), React.createElement(Input, {
    "data-name": "export-name",
    width: "100%",
    type: "text",
    autoFocus: true,
    placeholder: "Name",
    value: data.Name,
    onChange: e => {
      props.onChange(Object.assign(Object.assign({}, data), {
        Name: e.target.value
      }));
    }
  })), React.createElement(FormRow, null, React.createElement(React.Fragment, null), ErrorMessage ? React.createElement(ErrorBox, null, ErrorMessage) : null)));
};