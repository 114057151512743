import kebabCase from 'lodash/kebabCase';
import * as React from 'react';
import { connect } from 'react-redux';
import { Box } from 'rebass';
import { Dashboard as DashboardUI, DashboardTab as DashboardTabUI } from '../../components/Dashboard';
import * as DashboardRedux from '../../Redux/ActionsReducers/DashboardRedux';
import * as PopupRedux from '../../Redux/ActionsReducers/PopupRedux';
import * as QuickSearchRedux from '../../Redux/ActionsReducers/QuickSearchRedux';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import { QuickSearchModuleId } from '../../Utilities/Constants/ModuleConstants';
import { AdaptableViewPanelFactory } from '../AdaptableViewFactory';
import { AdaptableButtonComponent } from '../Components/AdaptableButton';
import { AdaptableIconComponent } from '../Components/AdaptableIconComponent';
import { AdaptableFormControlTextClear } from '../Components/Forms/AdaptableFormControlTextClear';
import { useQuickSearchDebounced } from '../QuickSearch/useQuickSearchDebounced';
import UIHelper from '../UIHelper';
import { CustomDashboardButton } from './CustomDashboardButton';
import { CustomToolbarWrapper } from './CustomToolbar';
import { DashboardToolbarFactory } from './DashboardToolbarFactory';
import { PinnedDashboard } from './PinnedDashboard';
import { ModuleToolbarWrapper } from './ModuleToolbarWrapper';
const DashboardComponent = props => {
  const [searchText, search] = useQuickSearchDebounced(props);
  const dashboardAccessLevel = props.api.entitlementApi.getEntitlementAccessLevelForModule('Dashboard');
  const renderTab = tab => {
    const visibleToolbarNames = tab.Toolbars.filter(vt => vt);
    const customToolbars = props.api.dashboardApi.getCustomToolbars();
    let visibleDashboardElements = visibleToolbarNames.map(visibleToolbarName => {
      let customToolbar = customToolbars && customToolbars.find(ct => ct.name == visibleToolbarName);
      if (customToolbar) {
        if (dashboardAccessLevel != 'Hidden') {
          return React.createElement(Box, {
            key: customToolbar.name,
            className: `ab-Dashboard__container ab-Dashboard__container--customToolbar`
          }, React.createElement(CustomToolbarWrapper, {
            customToolbar: customToolbar,
            dashboardRevision: props.DashboardRevision
          }));
        }
      } else {
        const moduleToolbarName = visibleToolbarName;
        if (props.api.internalApi.getModuleService().isModuleAvailable(moduleToolbarName)) {
          let moduleToolbarView = AdaptableViewPanelFactory.get(moduleToolbarName);
          if (moduleToolbarView) {
            let moduleInfo = props.api.internalApi.getModuleService().getModuleInfoByModule(moduleToolbarName);
            return React.createElement(Box, {
              key: moduleToolbarName,
              className: `ab-Dashboard__container ab-Dashboard__container--${moduleToolbarName}`
            }, React.createElement(ModuleToolbarWrapper, {
              moduleInfo: moduleInfo,
              accessLevel: dashboardAccessLevel
            }, React.createElement(DashboardToolbarFactory, {
              toolbarName: moduleToolbarName
            })));
          } else {
            props.api.logError('Cannot find Dashboard Control for ' + visibleToolbarName);
          }
        }
      }
    });
    return visibleDashboardElements;
  };
  const renderModuleButtons = () => {
    let shortcutsArray = props.DashboardState.ModuleButtons;
    let shortcuts = null;
    const alwaysShowInDashboard = props.api.optionsApi.getSettingsPanelOptions().alwaysShowInDashboard;
    const shouldAddSettingsPanel = alwaysShowInDashboard && !props.api.entitlementApi.isModuleHiddenEntitlement('SettingsPanel');
    if (shouldAddSettingsPanel && !shortcutsArray.includes('SettingsPanel')) {
      shortcutsArray.push('SettingsPanel');
    }
    if (shortcutsArray) {
      shortcuts = shortcutsArray.map((x, index) => {
        var _a, _b, _c;
        let menuItem = props.GridState.ModuleButtonMenuItems.find(y => y.isVisible && y.module == x);
        if (menuItem) {
          return React.createElement(AdaptableButtonComponent, {
            "data-name": `dashboard-module-button-${(_a = menuItem.module) !== null && _a !== void 0 ? _a : 'custom-' + index}`,
            key: menuItem.label,
            variant: menuItem.module === 'SystemStatus' ? 'outlined' : 'text',
            tone: menuItem.module === 'SystemStatus' ? 'neutral' : 'none',
            className: `ab-DashboardToolbar__Home__${kebabCase(menuItem.label)}`,
            icon: menuItem.icon,
            tooltip: menuItem.label,
            disabled: props.accessLevel == 'ReadOnly',
            onClick: () => props.dispatch(menuItem.reduxAction),
            accessLevel: 'Full',
            style: menuItem.module === 'SystemStatus' ? Object.assign(Object.assign({}, UIHelper.getStyleForMessageType((_c = (_b = props.api.systemStatusApi.getCurrentSystemStatusMessageInfo()) === null || _b === void 0 ? void 0 : _b.statusType) !== null && _c !== void 0 ? _c : 'Success')), {
              border: 0
            }) : {}
          });
        }
      });
    }
    return shortcuts;
  };
  const renderDashboardButtons = () => {
    let dashboardButtonsArray = props.api.dashboardApi.getCustomDashboardButtons().filter(Boolean);
    let customDashboardButtons = null;
    if (dashboardButtonsArray === null || dashboardButtonsArray === void 0 ? void 0 : dashboardButtonsArray.length) {
      customDashboardButtons = dashboardButtonsArray.map((button, index) => React.createElement(CustomDashboardButton, {
        key: `${button.label}-${index}`,
        button: button,
        api: props.api,
        accessLevel: props.accessLevel
      }));
    }
    return customDashboardButtons;
  };
  const renderQuickSearch = () => {
    return React.createElement(Box, {
      ml: 2
    }, React.createElement(AdaptableFormControlTextClear, {
      type: "text",
      placeholder: props.api.optionsApi.getQuickSearchOptions().quickSearchPlaceholder,
      className: "ab-DashboardToolbar__QuickSearch__text",
      value: searchText,
      OnTextChange: search,
      style: {
        width: 'auto'
      },
      inputStyle: {
        width: '7rem'
      }
    }));
  };
  let instanceName = props.api.internalApi.getToolbarTitle();
  let dashboardOptions = props.api.optionsApi.getDashboardOptions();
  const shouldRenderQuickSearchHeader = dashboardOptions.showQuickSearchInHeader && props.api.internalApi.getModuleService().getModuleById(QuickSearchModuleId).isModuleAvailable();
  const applicationIcon = props.api.optionsApi.getUserInterfaceOptions().applicationIcon;
  if (props.DashboardState.IsHidden) {
    return React.createElement("div", null);
  }
  return React.createElement(DashboardUI, {
    title: instanceName,
    canFloat: props.api.optionsApi.getDashboardOptions().canFloat,
    footer: React.createElement(PinnedDashboard, null),
    activeTabIndex: props.DashboardState.ActiveTabIndex,
    onActiveTabIndexChange: ActiveTabIndex => {
      props.onSetActiveTabIndex(ActiveTabIndex);
    },
    collapsed: props.DashboardState.IsCollapsed,
    onCollapsedChange: IsCollapsed => {
      props.onSetIsCollapsed(IsCollapsed);
    },
    floating: props.DashboardState.IsFloating,
    onFloatingChange: IsFloating => {
      props.onSetIsFloating(IsFloating);
    },
    inline: props.DashboardState.IsInline,
    onInlineChange: IsInline => {
      props.onSetIsInline(IsInline);
    },
    position: props.DashboardState.FloatingPosition,
    onPositionChange: FloatingPositionCallback => {
      if (typeof FloatingPositionCallback === 'function') {
        const FloatingPosition = FloatingPositionCallback(props.DashboardState.FloatingPosition);
        props.onSetFloatingPosition(FloatingPosition);
      } else {
        props.onSetFloatingPosition(FloatingPositionCallback);
      }
    },
    left: React.createElement(React.Fragment, null, React.createElement(AdaptableIconComponent, {
      icon: applicationIcon,
      iconClassName: "ab-Dashboard__application-icon"
    }), dashboardOptions.buttonsLocation === 'left' && renderModuleButtons(), dashboardOptions.buttonsLocation === 'left' && renderDashboardButtons()),
    right: React.createElement(React.Fragment, null, dashboardOptions.buttonsLocation === 'right' && renderDashboardButtons(), dashboardOptions.buttonsLocation === 'right' && renderModuleButtons(), shouldRenderQuickSearchHeader && renderQuickSearch()),
    onShowDashboardPopup: props.onShowDashboardPopup
  }, props.DashboardState.Tabs && props.DashboardState.Tabs.map((tab, index) => React.createElement(DashboardTabUI, {
    key: index,
    title: tab.Name
  }, renderTab(tab))));
};
function mapStateToProps(state, ownProps) {
  return {
    DashboardRevision: state.System.DashboardRevision,
    DashboardState: state.Dashboard,
    GridState: state.Grid,
    SystemStatusMessageInfos: state.System.SystemStatusMessages,
    QuickSearchText: state.QuickSearch.QuickSearchText
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch: action => dispatch(action),
    onSetActiveTabIndex: ActiveTabIndex => dispatch(DashboardRedux.DashboardSetActiveTabIndex(ActiveTabIndex)),
    onSetIsCollapsed: IsCollapsed => dispatch(DashboardRedux.DashboardSetIsCollapsed(IsCollapsed)),
    onSetIsFloating: IsFloating => dispatch(DashboardRedux.DashboardSetIsFloating(IsFloating)),
    onSetFloatingPosition: FloatingPosition => dispatch(DashboardRedux.DashboardSetFloatingPosition(FloatingPosition)),
    onRunQuickSearch: newQuickSearchText => dispatch(QuickSearchRedux.QuickSearchRun(newQuickSearchText)),
    onShowQuickSearchPopup: () => dispatch(PopupRedux.PopupShowScreen(ModuleConstants.QuickSearchModuleId, 'QuickSearchPopup')),
    onShowDashboardPopup: () => dispatch(PopupRedux.PopupShowScreen(ModuleConstants.DashboardModuleId, 'DashboardPopup'))
  };
}
export let Dashboard = connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);