import * as React from 'react';
import * as LayoutRedux from '../../../Redux/ActionsReducers/LayoutRedux';
import { OnePageAdaptableWizard, OnePageWizardSummary } from '../../Wizard/OnePageAdaptableWizard';
import ObjectFactory from '../../../Utilities/ObjectFactory';
import { cloneObject } from '../../../Utilities/Helpers/Helper';
import { SettingsSection, SettingsSectionSummary } from './sections/SettingsSection';
import { Box } from 'rebass';
import { useAdaptable } from '../../AdaptableContext';
import { useDispatch } from 'react-redux';
import { ColumnsSection, ColumnsSectionSummary } from './sections/ColumnsSection';
import { PivotColumnsSection, PivotColumnsSectionSummary } from './sections/PivotColumnsSection';
import { RowGroupingSection, RowGroupingSectionSummary } from './sections/RowGroupingSection';
import { AggregationsSection, AggregationsSectionSummary, isAggregationsSectionValid } from './sections/AggregationsSection';
import { SortSection, SortSectionSummary } from './sections/SortSection';
import { FilterSection, FilterSectionSummary, isColumnFiltersValid } from './sections/FilterSection';
import { GridFilterSection, GridFilterSectionSummary, isGridFiltersValid } from './sections/GridFilterSection';
import { areSummaryRowsValid, RowSummarySection } from './sections/RowSummarySection';
import { WEIGHTED_AVERAGE_AGGREATED_FUNCTION } from '../../../PredefinedConfig/Common/RowSummary';
export const LayoutWizard = props => {
  var _a, _b;
  const dispatch = useDispatch();
  const adaptable = useAdaptable();
  const allLayouts = adaptable.api.layoutApi.getLayouts();
  const initialLayout = (_a = props.data) !== null && _a !== void 0 ? _a : (_b = props.popupParams) === null || _b === void 0 ? void 0 : _b.value;
  const [layout, setLayout] = React.useState(() => {
    var _a;
    let preparedLayout = null;
    if (initialLayout) {
      preparedLayout = cloneObject(initialLayout);
      if (((_a = props === null || props === void 0 ? void 0 : props.popupParams) === null || _a === void 0 ? void 0 : _a.action) === 'Clone') {
        preparedLayout.Name = '';
        delete preparedLayout.Uuid;
      }
    } else {
      preparedLayout = ObjectFactory.CreateEmptyLayout({
        Name: ''
      });
    }
    if (preparedLayout.SuppressAggFuncInHeader === undefined) {
      preparedLayout.SuppressAggFuncInHeader = !!adaptable.agGridAdapter.getAgGridApi().getGridOption('suppressAggFuncInHeader');
    }
    return preparedLayout;
  });
  const handleFinish = () => {
    var _a;
    let action = (_a = props === null || props === void 0 ? void 0 : props.popupParams) === null || _a === void 0 ? void 0 : _a.action;
    if (!action) {
      action = initialLayout ? 'Edit' : 'New';
    }
    switch (action) {
      case 'Edit':
        const currentLayout = adaptable.api.layoutApi.getCurrentLayout();
        dispatch(LayoutRedux.LayoutSave(layout));
        if (currentLayout.Uuid === layout.Uuid) {
          dispatch(LayoutRedux.LayoutSelect(layout.Name));
        }
        break;
      case 'Clone':
        const clonedLayout = Object.assign({}, layout);
        delete clonedLayout.Uuid;
        dispatch(LayoutRedux.LayoutAdd(clonedLayout));
        dispatch(LayoutRedux.LayoutSelect(clonedLayout.Name));
        break;
      case 'New':
        dispatch(LayoutRedux.LayoutAdd(layout));
        dispatch(LayoutRedux.LayoutSelect(layout.Name));
    }
    props.onFinishWizard(layout);
  };
  const layoutSupportedFeatures = adaptable.api.layoutApi.internalApi.getLayoutSupportedFeatures();
  return React.createElement(OnePageAdaptableWizard, {
    titleContainerStyle: {
      width: 180
    },
    defaultCurrentSectionName: props.defaultCurrentSectionName,
    moduleInfo: props.moduleInfo,
    data: layout,
    onHide: props.onCloseWizard,
    onFinish: handleFinish,
    sections: [{
      title: 'Settings',
      details: 'Configure Layout',
      isValid: () => {
        if (!layout.Name) {
          return 'Layout name cannot be blank';
        }
        if (allLayouts.some(layoutItem => layoutItem.Name === layout.Name && layoutItem.Uuid !== layout.Uuid)) {
          return 'A Layout already exists with that name';
        }
        return true;
      },
      renderSummary: () => React.createElement(SettingsSectionSummary, null),
      render: () => React.createElement(Box, {
        padding: 2
      }, React.createElement(SettingsSection, {
        onChange: newLayout => setLayout(newLayout)
      }))
    }, {
      title: 'Columns',
      details: 'Select Columns',
      isVisible: () => !(layout === null || layout === void 0 ? void 0 : layout.EnablePivot),
      renderSummary: () => React.createElement(ColumnsSectionSummary, null),
      render: () => React.createElement(Box, {
        p: 2,
        style: {
          height: '100%'
        }
      }, React.createElement(ColumnsSection, {
        onChange: setLayout
      }))
    }, {
      title: 'Pivot Columns',
      details: 'Select Pivot Columns',
      isVisible: () => (layout === null || layout === void 0 ? void 0 : layout.EnablePivot) && layoutSupportedFeatures.PivotColumns,
      renderSummary: () => React.createElement(PivotColumnsSectionSummary, null),
      render: () => React.createElement(Box, {
        p: 2,
        style: {
          height: '100%'
        }
      }, React.createElement(PivotColumnsSection, {
        onChange: newLayout => setLayout(newLayout)
      }))
    }, {
      title: 'Row Groups',
      isVisible: () => layoutSupportedFeatures.RowGroupedColumns,
      isValid: areSummaryRowsValid,
      details: 'Configure Row Grouping',
      renderSummary: () => React.createElement(RowGroupingSectionSummary, null),
      render: () => React.createElement(Box, {
        p: 2,
        style: {
          height: '100%'
        }
      }, React.createElement(RowGroupingSection, {
        onChange: setLayout
      }))
    }, {
      title: 'Aggregations',
      isVisible: () => layoutSupportedFeatures.AggregationColumns,
      details: 'Select Column Aggregations',
      renderSummary: () => React.createElement(AggregationsSectionSummary, null),
      isValid: data => isAggregationsSectionValid(data),
      render: () => React.createElement(Box, {
        p: 2,
        style: {
          height: '100%'
        }
      }, React.createElement(AggregationsSection, {
        onChange: layout => {
          let newLayout = cloneObject(layout);
          // if we do not have an weighted avg col, we need to clear the row summary if one exists
          if (newLayout.RowSummaries) {
            newLayout.RowSummaries = newLayout.RowSummaries.map(rowSummary => {
              return Object.assign(Object.assign({}, rowSummary), {
                ColumnsMap: Object.entries(rowSummary.ColumnsMap).reduce((acc, [columnId, aggFunc]) => {
                  if (
                  // see if it is weighted avg
                  aggFunc === WEIGHTED_AVERAGE_AGGREATED_FUNCTION &&
                  // see if we have a weight in the agg columns
                  newLayout.AggregationColumns[columnId] && (typeof newLayout.AggregationColumns[columnId] !== 'object' || newLayout.AggregationColumns[columnId].weightColumnId)) {
                    // need to remove the row summary
                    return acc;
                  }
                  acc[columnId] = aggFunc;
                  return acc;
                }, {})
              });
            });
          }
          setLayout(newLayout);
        }
      }))
    }, {
      title: 'Row Summaries',
      details: 'Configure Row Summaries',
      renderSummary: () => React.createElement(RowGroupingSectionSummary, null),
      isVisible: () => layoutSupportedFeatures.RowSummaries,
      render: () => React.createElement(Box, {
        p: 2,
        style: {
          height: '100%'
        }
      }, React.createElement(RowSummarySection, {
        onChange: setLayout
      }))
    }, {
      title: 'Sort',
      isVisible: () => layoutSupportedFeatures.ColumnSorts,
      renderSummary: () => React.createElement(SortSectionSummary, null),
      details: 'Configure Column Sorts',
      render: () => React.createElement(Box, {
        p: 2,
        style: {
          height: '100%'
        }
      }, React.createElement(SortSection, {
        onChange: newLayout => setLayout(newLayout)
      }))
    }, {
      title: 'Column Filters',
      isVisible: () => layoutSupportedFeatures.ColumnFilters,
      isValid: layout => isColumnFiltersValid(layout),
      details: 'View Column Filters',
      renderSummary: () => React.createElement(FilterSectionSummary, null),
      render: () => React.createElement(Box, {
        p: 2,
        style: {
          height: '100%'
        }
      }, React.createElement(FilterSection, {
        onChange: newLayout => setLayout(newLayout)
      }))
    }, {
      title: 'Grid Filter',
      isVisible: () => layoutSupportedFeatures.GridFilter,
      isValid: layout => isGridFiltersValid(layout, adaptable.api),
      details: 'View Grid Filter',
      renderSummary: () => React.createElement(GridFilterSectionSummary, null),
      render: () => React.createElement(Box, {
        p: 2,
        style: {
          height: '100%'
        }
      }, React.createElement(GridFilterSection, {
        onChange: newLayout => setLayout(newLayout)
      }))
    }, '-', {
      details: 'Review your Layout',
      render: () => React.createElement(Box, {
        padding: 2
      }, React.createElement(OnePageWizardSummary, null)),
      title: 'Summary'
    }]
  });
};