import { FontSize } from '../../PredefinedConfig/Common/Enums';
export function getNames(e) {
  const vals = Object.keys(e).map(k => e[k]);
  return vals.filter(v => typeof v === 'string');
}
export function getCssFontSizeFromFontSizeEnum(fontSize) {
  switch (fontSize) {
    case FontSize.XLarge:
      return 'x-large';
    case FontSize.Large:
      return 'large';
    case FontSize.Medium:
      return 'medium';
    case FontSize.Small:
      return 'small';
    case FontSize.XSmall:
      return 'x-small';
  }
}
export const EnumExtensions = {
  getNames,
  getCssFontSizeFromFontSizeEnum
};
export default EnumExtensions;