import * as React from 'react';
import SimpleButton from '../../../components/SimpleButton';
export class ButtonApply extends React.Component {
  render() {
    return React.createElement(SimpleButton, Object.assign({
      "data-name": "apply",
      tooltip: "Apply",
      iconSize: 20,
      icon: "check",
      variant: "text"
    }, this.props));
  }
}