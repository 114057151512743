import { AdaptableModuleBase } from './AdaptableModuleBase';
import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
export class ColumnInfoModule extends AdaptableModuleBase {
  constructor(api) {
    super(ModuleConstants.ColumnInfoModuleId, ModuleConstants.ColumnInfoFriendlyName, 'columns', 'ColumnInfoPopup', 'Display information about Columns', api);
  }
  getViewAccessLevel() {
    return 'Full';
  }
  createColumnMenuItems(column) {
    if (this.isModuleAvailable()) {
      const columnPopUpParams = {
        source: 'ColumnMenu',
        column: column
      };
      return [this.createMainMenuItemShowPopup({
        Name: 'column-info-show',
        Label: 'Column Info',
        ComponentName: this.moduleInfo.Popup,
        Icon: 'info',
        PopupParams: columnPopUpParams
      })];
    }
  }
  createContextMenuItems(menuContext) {
    if (this.isModuleAvailable()) {
      const columnPopUpParams = {
        source: 'ColumnMenu',
        column: menuContext.adaptableColumn
      };
      return [this.createMainMenuItemShowPopup({
        Name: 'column-info-show',
        Label: 'Column Info',
        ComponentName: this.moduleInfo.Popup,
        Icon: 'info',
        PopupParams: columnPopUpParams
      })];
    }
  }
}