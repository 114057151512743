import * as SystemStatusRedux from '../../Redux/ActionsReducers/SystemStatusRedux';
import * as SystemRedux from '../../Redux/ActionsReducers/SystemRedux';
import { ApiBase } from './ApiBase';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import ObjectFactory from '../../Utilities/ObjectFactory';
import { createElement } from 'react';
import UIHelper from '../../View/UIHelper';
import StringExtensions from '../../Utilities/Extensions/StringExtensions';
import { SystemStatusInternalApi } from '../Internal/SystemStatusInternalApi';
export class SystemStatusApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.internalApi = new SystemStatusInternalApi(adaptable);
  }
  setSystemStatus(statusMessage, messageType, statusFurtherInformation) {
    let systemStatusMessageInfo = ObjectFactory.CreateSystemStatusMessageInfo(statusMessage, messageType, statusFurtherInformation ? statusFurtherInformation : '');
    this.internalApi.addSystemStatusMessageInfo(systemStatusMessageInfo);
    // publish the event
    this.internalApi.fireSystemStatusMessageDisplayedEvent(systemStatusMessageInfo);
    if (this.getNotificationsOptions().showSystemStatusMessageNotifications) {
      let fullMessage = statusMessage;
      if (StringExtensions.IsNotNullOrEmpty(statusFurtherInformation)) {
        fullMessage += '\n' + statusFurtherInformation;
      }
      switch (messageType) {
        case 'Success':
          this.getAdaptableApi().alertApi.showAlertSuccess('System Status Success', fullMessage);
          return;
        case 'Info':
          this.getAdaptableApi().alertApi.showAlertInfo('System Status Info', fullMessage);
          return;
        case 'Warning':
          this.getAdaptableApi().alertApi.showAlertWarning('System Status Warning', fullMessage);
          return;
        case 'Error':
          this.getAdaptableApi().alertApi.showAlertError('System Status Error', fullMessage);
          return;
      }
    }
    if (this.getContainerOptions().systemStatusContainer != null) {
      this.displayMessageInDiv(statusMessage, statusFurtherInformation, messageType);
    }
    // doesnt do anything but useful for Audit
    this.dispatchAction(SystemStatusRedux.SystemStatusSetMesage(systemStatusMessageInfo));
  }
  displayMessageInDiv(statusMessage, statusFurtherInformation, messageType) {
    if (!this.systemStatusDiv) {
      let optionsDiv = this.getContainerOptions().systemStatusContainer;
      if (optionsDiv) {
        this.systemStatusDiv = typeof optionsDiv === 'string' ? document.getElementById(optionsDiv) : optionsDiv;
      }
    }
    if (this.systemStatusDiv) {
      if (StringExtensions.IsNotNullOrEmpty(statusFurtherInformation)) {
        statusMessage += statusFurtherInformation;
      }
      const foreColor = messageType ? UIHelper.getColorByMessageType(messageType) : 'black';
      this.adaptable.renderReactRoot(createElement('span', {
        style: {
          color: foreColor
        }
      }, statusMessage), this.systemStatusDiv);
    }
  }
  setErrorSystemStatus(statusMessage, statusFurtherInformation) {
    this.setSystemStatus(statusMessage, 'Error', statusFurtherInformation);
  }
  setWarningSystemStatus(statusMessage, statusFurtherInformation) {
    this.setSystemStatus(statusMessage, 'Warning', statusFurtherInformation);
  }
  setSuccessSystemStatus(statusMessage, statusFurtherInformation) {
    this.setSystemStatus(statusMessage, 'Success', statusFurtherInformation);
  }
  setInfoSystemStatus(statusMessage, statusFurtherInformation) {
    this.setSystemStatus(statusMessage, 'Info', statusFurtherInformation);
  }
  openSystemStatusSettingsPanel() {
    this.showModulePopup(ModuleConstants.SystemStatusModuleId);
  }
  getCurrentSystemStatusMessageInfo() {
    const messageInfos = this.internalApi.getSystemStatusMessageInfos() || [];
    const orderedArray = messageInfos.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
    return orderedArray[0];
  }
  deleteAllSystemStatusMessages() {
    this.dispatchAction(SystemRedux.SystemStatusMessageInfoDeleteAll());
  }
  destroy() {
    this.systemStatusDiv = null;
    super.destroy();
  }
}