import { ApiBase } from '../Implementation/ApiBase';
export class TeamSharingInternalApi extends ApiBase {
  /**
   * Fires the Team Sharing Entity Changed Event
   * @param sharedEntity Entity that has been changed
   */
  fireTeamSharingEntityChangedEvent(sharedEntity) {
    if (this.adaptable.isReady) {
      const teamSharingEntityChangedInfo = Object.assign(Object.assign({}, this.getAdaptableApi().internalApi.buildBaseContext()), {
        sharedEntity: sharedEntity
      });
      this.getAdaptableApi().eventApi.emit('TeamSharingEntityChanged', teamSharingEntityChangedInfo);
    }
  }
  /**
   * Retrieves shared entry dependencies.
   */
  getSharedEntryDependencies(sharedEntity) {
    var _a;
    const allEntities = this.getTeamSharingApi().getLoadedAdaptableSharedEntities();
    return ((_a = sharedEntity === null || sharedEntity === void 0 ? void 0 : sharedEntity.EntityDependencyIds) !== null && _a !== void 0 ? _a : []).map(id => allEntities.find(entity => entity.Uuid === id));
  }
  waitForTeamSharingImportEnd() {
    // we have no deterministic means to ensure the teamsharing import is finished (because the import actions are opaque and may have side-effects)
    // therefore we will listen for state changes and
    // if the state was unchanged in the last `waitForStableState` ms, we assume/hope the import is finished
    const waitForStableState = 1000;
    const waitThreshold = 5000;
    return new Promise((resolve, reject) => {
      let pollTimer;
      // stop the poll after `waitThreshold` ms, if the state hasn't stabilised until now, it probably never will...
      let breakTimer = setTimeout(() => {
        unsubscribe === null || unsubscribe === void 0 ? void 0 : unsubscribe();
        clearTimeout(pollTimer);
        reject(false);
      }, waitThreshold);
      // poll state for changes:
      const unsubscribe = this.getAdaptableApi().eventApi.on('AdaptableStateChanged', () => {
        clearTimeout(pollTimer);
        pollTimer = setTimeout(() => {
          // state is stable, cleanup all subscriptions before returning
          unsubscribe();
          clearTimeout(breakTimer);
          resolve(true);
        }, waitForStableState);
      });
    });
  }
}