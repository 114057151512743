import * as React from 'react';
import { CellSummaryDetails } from './CellSummaryDetails';
import { useSelector } from 'react-redux';
export const CellSummaryStatusBarSubPanelPopover = () => {
  const cellSummary = useSelector(state => state.Grid.CellSummary);
  const summary = React.createElement(CellSummaryDetails, {
    CellSummary: cellSummary
  });
  return cellSummary && cellSummary.Count ? summary :
  // the extra wrapper is added because the empty text is rendered
  // inside a  EmptyContent, hat has position absolute
  React.createElement("div", {
    style: {
      position: 'relative',
      width: '100%',
      height: 80
    }
  }, summary);
};