import * as React from 'react';
import { Box, Flex, Text } from 'rebass';
import { CheckBox } from '../../../components/CheckBox';
import FormLayout, { FormRow } from '../../../components/FormLayout';
import { Tabs } from '../../../components/Tabs';
import { Tag } from '../../../components/Tag';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
export const renderStyledColumnWizardSettingsSummary = data => {
  var _a, _b, _c;
  const badgeStyle = data;
  return React.createElement(Box, {
    padding: 2
  }, React.createElement(Text, {
    mt: 3
  }, "Data Rows ", React.createElement(Tag, null, ((_a = badgeStyle.RowScope) === null || _a === void 0 ? void 0 : _a.ExcludeDataRows) ? 'no' : 'yes')), ' ', React.createElement(Text, {
    mt: 3
  }, "Group Rows ", React.createElement(Tag, null, ((_b = badgeStyle.RowScope) === null || _b === void 0 ? void 0 : _b.ExcludeGroupRows) ? 'no' : 'yes')), React.createElement(Text, {
    mt: 3
  }, "Summary Rows ", React.createElement(Tag, null, ((_c = badgeStyle.RowScope) === null || _c === void 0 ? void 0 : _c.ExcludeSummaryRows) ? 'no' : 'yes')));
};
export const StyledColumnWizardSettingsSection = props => {
  var _a, _b, _c;
  const {
    data
  } = useOnePageAdaptableWizardContext();
  const onExcludeDataRowsChanged = ExcludeDataRows => {
    props.onChange(Object.assign(Object.assign({}, data), {
      BadgeStyle: Object.assign(Object.assign({}, data.BadgeStyle), {
        RowScope: Object.assign(Object.assign({}, data.BadgeStyle.RowScope), {
          ExcludeDataRows
        })
      })
    }));
  };
  const onExcludeGroupedRowsChanged = ExcludeGroupedRows => {
    props.onChange(Object.assign(Object.assign({}, data), {
      BadgeStyle: Object.assign(Object.assign({}, data.BadgeStyle), {
        RowScope: Object.assign(Object.assign({}, data.BadgeStyle.RowScope), {
          ExcludeGroupRows: ExcludeGroupedRows
        })
      })
    }));
  };
  const onExcludeSummaryRowsChanged = ExcludeSummaryRows => {
    props.onChange(Object.assign(Object.assign({}, data), {
      BadgeStyle: Object.assign(Object.assign({}, data.BadgeStyle), {
        RowScope: Object.assign(Object.assign({}, data.BadgeStyle.RowScope), {
          ExcludeSummaryRows
        })
      })
    }));
  };
  return React.createElement(Tabs, null, React.createElement(Tabs.Tab, null, "Settings"), React.createElement(Tabs.Content, null, React.createElement(Flex, {
    flexDirection: "row"
  }, React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Exclude Data Rows:"
  }, React.createElement(Flex, {
    alignItems: "center",
    marginLeft: 2
  }, React.createElement(CheckBox, {
    "data-name": "exclude-data-rows-checkbox",
    checked:
    // @ts-ignore
    (_a = data.BadgeStyle.RowScope) === null || _a === void 0 ? void 0 : _a.ExcludeDataRows,
    onChange: onExcludeDataRowsChanged,
    mr: 2
  }))), React.createElement(FormRow, {
    label: "Exclude Group Rows:"
  }, React.createElement(Flex, {
    alignItems: "center",
    marginLeft: 2
  }, React.createElement(CheckBox, {
    "data-name": "exclude-grouped-rows-checkbox",
    checked:
    // @ts-ignore
    (_b = data.BadgeStyle.RowScope) === null || _b === void 0 ? void 0 : _b.ExcludeGroupRows,
    onChange: onExcludeGroupedRowsChanged,
    mr: 2
  }))), React.createElement(FormRow, {
    label: "Exclude Row Summaries:"
  }, React.createElement(Flex, {
    alignItems: "center",
    marginLeft: 2
  }, React.createElement(CheckBox, {
    "data-name": "exclude-summary-rows-checkbox",
    checked:
    // @ts-ignore
    (_c = data.BadgeStyle.RowScope) === null || _c === void 0 ? void 0 : _c.ExcludeSummaryRows,
    onChange: onExcludeSummaryRowsChanged,
    mr: 2
  }))), ' '))));
};