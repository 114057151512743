import * as React from 'react';
import { Box } from 'rebass';
import join from '../utils/join';
export const baseClassName = 'ab-CodeBlock';
export const CodeBlock = props => {
  return React.createElement(Box, Object.assign({
    as: "code"
  }, props, {
    className: join(props.className, baseClassName)
  }));
};