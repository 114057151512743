import * as FormatColumnRedux from '../../Redux/ActionsReducers/FormatColumnRedux';
import { ApiBase } from './ApiBase';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import { FormatColumnInternalApi } from '../Internal/FormatColumnInternalApi';
export class FormatColumnApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.internalApi = new FormatColumnInternalApi(adaptable);
  }
  getFormatColumnState() {
    return this.getAdaptableState().FormatColumn;
  }
  getFormatColumns(config) {
    var _a;
    return (_a = this.handleLayoutAssociatedObjects(this.getFormatColumnState().FormatColumns, 'FormatColumn', config)) !== null && _a !== void 0 ? _a : [];
  }
  getFormatColumnById(id) {
    return this.getFormatColumns({
      includeLayoutNotAssociatedObjects: true
    }).find(formatColumn => formatColumn.Uuid === id);
  }
  getActiveFormatColumns(config) {
    return this.getFormatColumns(config).filter(formatColumn => !formatColumn.IsSuspended);
  }
  getSuspendedFormatColumns(config) {
    return this.getFormatColumns(config).filter(formatColumn => formatColumn.IsSuspended);
  }
  addFormatColumn(formatColumn) {
    this.addUidToAdaptableObject(formatColumn);
    this.dispatchAction(FormatColumnRedux.FormatColumnAdd(formatColumn));
    return this.getFormatColumnById(formatColumn.Uuid);
  }
  addFormatColumns(formatColumns) {
    formatColumns.forEach(fc => {
      this.addFormatColumn(fc);
    });
    return formatColumns === null || formatColumns === void 0 ? void 0 : formatColumns.map(formatColumn => this.getFormatColumnById(formatColumn.Uuid));
  }
  editFormatColumn(formatColumn) {
    this.dispatchAction(FormatColumnRedux.FormatColumnEdit(formatColumn));
    return this.getFormatColumnById(formatColumn.Uuid);
  }
  addFormatColumnStyle(formatColumn, style) {
    formatColumn.Style = style;
    this.addFormatColumn(formatColumn);
  }
  updateFormatColumnStyle(formatColumn, style) {
    formatColumn.Style = style;
    this.dispatchAction(FormatColumnRedux.FormatColumnEdit(formatColumn));
  }
  deleteFormatColumn(formatColumn) {
    this.dispatchAction(FormatColumnRedux.FormatColumnDelete(formatColumn));
  }
  deleteAllFormatColumns() {
    this.dispatchAction(FormatColumnRedux.FormatColumnDeleteAll());
  }
  suspendFormatColumn(formatColumn) {
    this.dispatchAction(FormatColumnRedux.FormatColumnSuspend(formatColumn));
    return this.getFormatColumnById(formatColumn.Uuid);
  }
  unSuspendFormatColumn(formatColumn) {
    this.dispatchAction(FormatColumnRedux.FormatColumnUnSuspend(formatColumn));
    return this.getFormatColumnById(formatColumn.Uuid);
  }
  getFormatColumnsForColumn(column, config) {
    const formatColumns = this.getFormatColumns().filter(formatColumn => (config === null || config === void 0 ? void 0 : config.includeSuspended) || !formatColumn.IsSuspended);
    return this.internalApi.getFormatColumnWithColumnInScope(formatColumns, column);
  }
  getFormatColumnsForColumnId(columnId, config) {
    const abColumn = this.getAdaptableApi().columnApi.getColumnWithColumnId(columnId);
    if (!abColumn) {
      return [];
    }
    return this.getFormatColumnsForColumn(abColumn, config);
  }
  openFormatColumnSettingsPanel() {
    this.showModulePopup(ModuleConstants.FormatColumnModuleId);
  }
  getFormatColumnsWithAllScope(formatColumns) {
    return formatColumns.filter(fc => this.getAdaptableApi().columnScopeApi.scopeIsAll(fc.Scope));
  }
  getFormatColumnsWithDataTypeScope(formatColumns) {
    return formatColumns.filter(fc => this.getAdaptableApi().columnScopeApi.scopeHasDataType(fc.Scope));
  }
  getFormatColumnsWithColumnScope(formatColumns) {
    return formatColumns.filter(fc => this.getAdaptableApi().columnScopeApi.scopeHasColumns(fc.Scope));
  }
  hasCustomDisplayFormatter(formatColumn) {
    var _a;
    if (!formatColumn || !formatColumn.DisplayFormat || !formatColumn.DisplayFormat.Options) {
      return false;
    }
    const options = formatColumn.DisplayFormat.Options;
    const columnCustomFormatters = (_a = options === null || options === void 0 ? void 0 : options.CustomDisplayFormats) !== null && _a !== void 0 ? _a : [];
    if (!(columnCustomFormatters === null || columnCustomFormatters === void 0 ? void 0 : columnCustomFormatters.length)) {
      return false;
    }
    return true;
  }
  incrementFormatColumnPrecedence(formatColumn) {
    this.dispatchAction(FormatColumnRedux.FormatColumnMoveUp(formatColumn));
  }
  decrementFormatColumnPrecedence(formatColumn) {
    this.dispatchAction(FormatColumnRedux.FormatColumnMoveDown(formatColumn));
  }
  findFormatColumns(criteria) {
    return this.getAdaptableApi().internalApi.findAdaptableObjectsByLookupCriteria(criteria, this.getFormatColumns({
      includeLayoutNotAssociatedObjects: true
    }));
  }
}