import { AdaptableModuleBase } from './AdaptableModuleBase';
import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
import * as FormatColumnRedux from '../Redux/ActionsReducers/FormatColumnRedux';
import * as PopupRedux from '../Redux/ActionsReducers/PopupRedux';
import { FormatColumnWizard } from '../View/FormatColumn/Wizard/FormatColumnWizard';
import { getScopeViewItems } from './Utilities/getScopeViewItems';
import { getFormatColumnFormatSummaryValue } from '../View/FormatColumn/Wizard/FormatColumnFormatWizardSection';
import { getFormatColumnSettingsViewItems } from './Utilities/FormatColumn/getFormatColumnSettingsViewItems';
import { getFormatColumnStyleViewItems } from './Utilities/FormatColumn/getFormatColumnStyleViewItems';
import { getObjectTagsViewItems } from './Utilities/getObjectTagsViewItems';
import { getRuleViewItems } from './Utilities/getRuleViewItems';
import { MoveFormatColumn } from '../View/FormatColumn/MoveFormatColumn';
export class FormatColumnModule extends AdaptableModuleBase {
  constructor(api) {
    super(ModuleConstants.FormatColumnModuleId, ModuleConstants.FormatColumnFriendlyName, 'color-palette', 'FormatColumnPopup', 'Create a column style, display format or cell alignment', api);
  }
  getModuleAdaptableObjects(config) {
    return this.api.formatColumnApi.getFormatColumns(config);
  }
  getExplicitlyReferencedColumnIds(formatColumn) {
    const queryExpression = formatColumn.Rule && this.api.expressionApi.getAdaptableQueryExpression(formatColumn.Rule);
    if (queryExpression) {
      return this.api.expressionApi.getColumnsFromExpression(queryExpression);
    } else if (this.api.columnScopeApi.scopeHasColumns(formatColumn.Scope)) {
      return this.api.columnScopeApi.getColumnsForScope(formatColumn.Scope).map(adaptableColumn => adaptableColumn.columnId);
    }
    return [];
  }
  getReferencedNamedQueryNames(formatColumn) {
    const queryExpression = formatColumn.Rule && this.api.expressionApi.getAdaptableQueryExpression(formatColumn.Rule);
    if (!queryExpression) {
      return [];
    }
    return this.api.namedQueryApi.internalApi.getReferencedNamedQueryNames(queryExpression);
  }
  createColumnMenuItems(column) {
    if (!column || !this.isModuleEditable() || column.isSparkline) {
      return;
    }
    let returnColumnMenuItems = [];
    let createPopupParam = {
      column: column,
      action: 'New',
      source: 'ColumnMenu'
    };
    returnColumnMenuItems.push(this.createMenuItemShowPopup('format-column-add', 'Create Format Column', this.moduleInfo.Popup, this.moduleInfo.Glyph, createPopupParam));
    const formatColumnsForColumn = this.api.formatColumnApi.getFormatColumnsForColumnId(column.columnId);
    if (formatColumnsForColumn.length === 1) {
      let editPopupParam = {
        column: column,
        action: 'Edit',
        value: formatColumnsForColumn[0],
        source: 'ColumnMenu'
      };
      const editFormatColumnMenuItem = this.createMenuItemShowPopup('format-column-edit', 'Edit', this.moduleInfo.Popup, 'edit', editPopupParam);
      returnColumnMenuItems.push(editFormatColumnMenuItem);
    } else if (formatColumnsForColumn.length > 1) {
      // if more than one then display a Create and a Show
      // we should probably do the Show better - perhaps use Column Info in some way?
      // or to filter the format columns ?
      const moduleInfo = this.api.internalApi.getModuleService().getModuleInfoByModule('FormatColumn');
      const editFormatColumnMenuItem = this.createMenuItemReduxAction('format-column-edit', 'Show Current', this.moduleInfo.Glyph, PopupRedux.PopupShowScreen('FormatColumn', moduleInfo.Popup));
      returnColumnMenuItems.push(editFormatColumnMenuItem);
    }
    return returnColumnMenuItems;
  }
  getTeamSharingAction() {
    return {
      ModuleEntities: this.api.formatColumnApi.getFormatColumns(),
      AddAction: FormatColumnRedux.FormatColumnAdd,
      EditAction: FormatColumnRedux.FormatColumnEdit
    };
  }
  toView(formatColumn) {
    return {
      abObject: formatColumn,
      items: [getScopeViewItems(formatColumn.Scope, this.api), formatColumn.Rule && getRuleViewItems(formatColumn.Rule, this.api), {
        name: 'Style',
        view: () => getFormatColumnStyleViewItems(formatColumn, this.api)
      }, {
        name: 'Display Format',
        values: [getFormatColumnFormatSummaryValue(formatColumn)]
      }, getFormatColumnSettingsViewItems(formatColumn), getObjectTagsViewItems(formatColumn, this.api)].filter(Boolean)
    };
  }
  toViewAll() {
    return this.getModuleAdaptableObjects({
      includeLayoutNotAssociatedObjects: this.showLayoutNotAssociatedObjects()
    }).map(formatColumn => this.toView(formatColumn));
  }
  getViewProperties() {
    return {
      actions: [MoveFormatColumn],
      getEditAction: FormatColumnRedux.FormatColumnEdit,
      getDeleteAction: FormatColumnRedux.FormatColumnDelete,
      getSuspendAction: FormatColumnRedux.FormatColumnSuspend,
      getUnSuspendAction: FormatColumnRedux.FormatColumnUnSuspend,
      getSuspendAllAction: FormatColumnRedux.FormatColumnSuspendAll,
      getUnSuspendAllAction: FormatColumnRedux.FormatColumnUnSuspendAll,
      getEditWizard() {
        return FormatColumnWizard;
      }
    };
  }
  canBeAssociatedWithLayouts() {
    return true;
  }
}