import { __rest } from "tslib";
import * as React from 'react';
import { Box, Flex } from 'rebass';
import join from '../utils/join';
export const baseClassName = 'ab-Panel';
const Header = _a => {
  var {
      children,
      variant = 'default'
    } = _a,
    headerProps = __rest(_a, ["children", "variant"]);
  if (!children) {
    return null;
  }
  const style = {};
  return React.createElement(Flex, Object.assign({
    flexDirection: "row",
    alignItems: "center"
  }, headerProps, {
    style: Object.assign(Object.assign({}, style), headerProps.style),
    className: join(`${baseClassName}__header`, `${baseClassName}__header--variant-${variant}`)
  }), children);
};
const Body = _a => {
  var {
      children,
      bodyScroll,
      variant
    } = _a,
    bodyProps = __rest(_a, ["children", "bodyScroll", "variant"]);
  if (!children) {
    return null;
  }
  if (bodyScroll === true) {
    bodyScroll = 'auto';
  }
  return React.createElement(Box, Object.assign({}, bodyProps, {
    className: join(`${baseClassName}__body`, variant && `${baseClassName}__body--variant-${variant}`, bodyScroll ? `${baseClassName}__body--scroll-${bodyScroll}` : null)
  }), children);
};
const Panel = props => {
  const {
      borderRadius,
      border,
      className,
      header,
      children,
      headerProps,
      bodyProps,
      bodyScroll,
      variant = 'default'
    } = props,
    boxProps = __rest(props, ["borderRadius", "border", "className", "header", "children", "headerProps", "bodyProps", "bodyScroll", "variant"]);
  const style = {};
  if (borderRadius !== undefined) {
    style['--ab-cmp-panel__border-radius'] = typeof borderRadius == 'number' ? `var(--ab-space-${borderRadius})` : borderRadius;
  }
  const headerStyle = Object.assign({
    border
  }, headerProps ? headerProps.style : null);
  const bodyStyle = Object.assign({
    border
  }, bodyProps ? bodyProps.style : null);
  return React.createElement(Box, Object.assign({}, boxProps, {
    style: Object.assign(Object.assign({}, style), boxProps.style),
    className: join(className, baseClassName, `${baseClassName}--variant-${variant}`, !header ? `${baseClassName}--no-header` : `${baseClassName}--with-header`)
  }), React.createElement(Header, Object.assign({}, headerProps, {
    style: headerStyle,
    variant: variant
  }), header), React.createElement(Body, Object.assign({}, bodyProps, {
    style: bodyStyle,
    bodyScroll: bodyScroll,
    variant: variant
  }), children));
};
export default Panel;