import * as React from 'react';
import { DataSource, InfiniteTable } from '../../components/InfiniteTable';
import { useAdaptable } from '../AdaptableContext';
const tableDOMProps = {
  style: {
    height: '100%',
    minWidth: '10rem',
    minHeight: 300
  }
};
export const ExportTablePopup = props => {
  const adaptable = useAdaptable();
  const adaptableApi = adaptable.api;
  const primaryKey = '_rowIndex';
  const {
    reportData
  } = props.popupProps;
  const data = reportData.rows.map((row, index) => Object.assign(Object.assign({}, row), {
    [primaryKey]: index + 1
  }));
  const columns = React.useMemo(() => {
    const columns = {
      [primaryKey]: {
        field: primaryKey,
        header: 'Index'
      }
    };
    for (let column of reportData.columns) {
      columns[column.columnId] = {
        field: column.columnId,
        header: column.friendlyName,
        dataType: column.dataType
      };
      if (column.dataType === 'Date') {
        columns[column.columnId].valueFormatter = params => {
          var _a;
          if (typeof params.value === 'string') {
            return params.value;
          }
          return ((_a = params.value) === null || _a === void 0 ? void 0 : _a.toString) ? params.value.toString() : '';
        };
      }
      if (column.dataType === 'Boolean') {
        columns[column.columnId].valueFormatter = params => {
          if (typeof params.value === 'boolean') {
            return params.value ? 'true' : 'false';
          }
          return '';
        };
      }
    }
    return columns;
  }, [props.popupProps]);
  const columnOrder = React.useMemo(() => {
    return Object.keys(columns);
  }, [columns]);
  return React.createElement(DataSource, {
    data: data,
    primaryKey: primaryKey
  }, React.createElement(InfiniteTable, {
    columnTypes: {
      default: {
        defaultFlex: 1
      }
    },
    domProps: tableDOMProps,
    columns: columns
  }));
};