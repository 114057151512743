import { EMPTY_ARRAY, THEME_DEFAULT_CURRENT_THEME, SYSTEM_THEMES } from '../../Utilities/Constants/GeneralConstants';
/**
 * @ReduxAction System Themes have been set
 */
export const THEME_SET_SYSTEM_THEMES = 'THEME_SET_SYSTEM_THEMES';
/**
 * @ReduxAction User Themes have been set
 */
export const THEME_SET_USER_THEMES = 'THEME_SET_USER_THEMES';
/**
 * @ReduxAction A theme has been selected
 */
export const THEME_SELECT = 'THEME_SELECT';
/**
 * @ReduxAction Theme Module is ready
 */
export const THEME_READY = 'THEME_READY';
/**
 * @ReduxAction Theme Module is edited
 */
export const THEME_EDIT = 'THEME_EDIT';
/**
 * @ReduxAction A new theme is added
 */
export const THEME_ADD = 'THEME_ADD';
/**
 * @ReduxAction A theme is deleted
 */
export const THEME_DELETE = 'THEME_DELETE';
export const ThemeSetSystemThemes = SystemThemes => ({
  type: THEME_SET_SYSTEM_THEMES,
  SystemThemes
});
export const ThemeSetUserThemes = UserThemes => ({
  type: THEME_SET_USER_THEMES,
  UserThemes
});
export const ThemeSelect = Theme => ({
  type: THEME_SELECT,
  Theme
});
export const ThemeReady = themeState => ({
  type: THEME_READY,
  themeState
});
export const ThemeEdit = theme => ({
  type: THEME_EDIT,
  theme
});
export const ThemeAdd = theme => ({
  type: THEME_ADD,
  theme
});
export const ThemeDelete = theme => ({
  type: THEME_DELETE,
  theme
});
const initialState = {
  CurrentTheme: THEME_DEFAULT_CURRENT_THEME,
  SystemThemes: SYSTEM_THEMES,
  UserThemes: EMPTY_ARRAY
};
export const ThemeReducer = (state = initialState, action) => {
  switch (action.type) {
    case THEME_SET_SYSTEM_THEMES:
      return Object.assign({}, state, {
        SystemThemes: action.SystemThemes
      });
    case THEME_SET_USER_THEMES:
      return Object.assign({}, state, {
        UserThemes: action.UserThemes
      });
    case THEME_SELECT:
      return Object.assign({}, state, {
        CurrentTheme: action.Theme
      });
    case THEME_EDIT:
      let newThemes = null;
      // find by UUID, or by name
      const themeToBeReplaced = action.theme;
      let replaceIndex = state.UserThemes.findIndex(theme => themeToBeReplaced.Uuid === theme.Uuid);
      // try by name
      if (replaceIndex === -1) {
        replaceIndex = state.UserThemes.findIndex(theme => themeToBeReplaced.Name === theme.Name);
      }
      // fond one, either by name or by uuid
      if (replaceIndex !== -1) {
        newThemes = [...state.UserThemes];
        newThemes[replaceIndex] = themeToBeReplaced;
      }
      if (newThemes === null) {
        return state;
      }
      // if name changed, need to update the current name
      let currentTheme = state.CurrentTheme;
      if (currentTheme !== themeToBeReplaced.Name) {
        // need to update the current theme if it name has changed
        currentTheme = themeToBeReplaced.Name;
      }
      return Object.assign({}, state, {
        CurrentTheme: currentTheme,
        UserThemes: newThemes
      });
    case THEME_ADD:
      return Object.assign({}, state, {
        UserThemes: [...state.UserThemes, action.theme]
      });
    case THEME_DELETE:
      return Object.assign({}, state, {
        UserThemes: state.UserThemes.filter(theme => theme.Uuid !== action.theme.Uuid)
      });
    default:
      return state;
  }
};