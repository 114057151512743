import * as React from 'react';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
import { NewScopeComponent } from '../../Components/NewScopeComponent';
export const PlusMinusScopeWizardSection = props => {
  const {
    data,
    api
  } = useOnePageAdaptableWizardContext();
  const availableColumns = React.useMemo(() => api.columnApi.getNumericColumns(), []);
  return React.createElement(NewScopeComponent, {
    availableDataTypes: ['Number'],
    scopeColumns: availableColumns,
    scope: data.Scope,
    descriptions: {
      rowScope: 'Matching rows will have the plus/minus applied',
      columnScope: 'Matching Columns will have the plus/minus applied'
    },
    updateScope: Scope => {
      let preparedScope = Scope;
      if (Scope && 'DataTypes' in Scope && Scope.DataTypes.length === 0) {
        // default to checked
        preparedScope = Object.assign(Object.assign({}, Scope), {
          DataTypes: ['Number']
        });
      }
      props.onChange(Object.assign(Object.assign({}, data), {
        Scope: preparedScope
      }));
    }
  });
};