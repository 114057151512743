import * as React from 'react';
import { Box, Text } from 'rebass';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
import { ValueOptionsTags } from '../../Components/ValueSelector';
export const renderScopeSummary = data => {
  const {
    api: {
      columnScopeApi: scopeApi
    }
  } = useOnePageAdaptableWizardContext();
  const columnsInScope = scopeApi.getColumnsForScope(data.Scope);
  return React.createElement(React.Fragment, null, React.createElement(Box, null, React.createElement(Text, {
    fontSize: 2
  }, scopeApi.scopeIsAll(data.Scope) ? 'Changes to any Column in the row will trigger an Alert' : null)), React.createElement(Box, {
    style: {
      overflow: 'hidden'
    }
  }, 'ColumnIds' in data.Scope ? React.createElement(React.Fragment, null, React.createElement(Text, {
    fontSize: 2,
    mb: columnsInScope.length ? 2 : 0
  }, "Changes in selected columns will trigger an alert"), columnsInScope.length ? React.createElement(ValueOptionsTags, {
    options: columnsInScope,
    value: columnsInScope.map(c => c.columnId),
    toLabel: c => c.friendlyName,
    allowWrap: true,
    readOnly: true,
    renderLabel: c => React.createElement(React.Fragment, null, c),
    toIdentifier: c => c.columnId
  }) : null) : null), React.createElement(Box, null, 'DataTypes' in data.Scope ? React.createElement(React.Fragment, null, React.createElement(Text, {
    fontSize: 2,
    mb: 2
  }, "Changes in Columns with these Data Types trigger an Alert"), React.createElement(ValueOptionsTags, {
    readOnly: true,
    options: data.Scope.DataTypes,
    value: data.Scope.DataTypes,
    toLabel: c => c,
    allowWrap: true,
    renderLabel: c => React.createElement(React.Fragment, null, `DataType: ${c}`),
    toIdentifier: c => c
  })) : null));
};