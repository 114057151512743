import AdaptableHelper from '../../Utilities/Helpers/AdaptableHelper';
import { changeIsSuspendInList, suspendAllInList, unsuspendAllInList } from './utils';
/**
 * @ReduxAction A Flashing Cell Definition has been added
 */
export const FLASHING_CELL_DEFINITION_ADD = 'FLASHING_CELL_DEFINITION_ADD';
/**
 * @ReduxAction A Flashing Cell Definition has been edited
 */
export const FLASHING_CELL_DEFINITION_EDIT = 'FLASHING_CELL_DEFINITION_EDIT';
/**
 * @ReduxAction A Flashing Cell Definition has been deleted
 */
export const FLASHING_CELL_DEFINITION_DELETE = 'FLASHING_CELL_DEFINITION_DELETE';
/**
 * @ReduxAction Flashing Cell Definition Style is suspended
 */
export const FLASHING_CELL_DEFINITION_SUSPEND = 'FLASHING_CELL_DEFINITION_SUSPEND';
/**
 * @ReduxAction Flashing Cell Definition is unsuspended, or activated
 */
export const FLASHING_CELL_DEFINITION_UNSUSPEND = 'FLASHING_CELL_DEFINITION_UNSUSPEND';
/**
 * @ReduxAction All Flashing Cell Definitions are suspended
 */
export const FLASHING_CELL_DEFINITION_SUSPEND_ALL = 'FLASHING_CELL_DEFINITION_SUSPEND_ALL';
/**
 * @ReduxAction All Flashing Cell Definitions are unsuspended, or activated
 */
export const FLASHING_CELL_DEFINITION_UNSUSPEND_ALL = 'FLASHING_CELL_DEFINITION_UNSUSPEND_ALL';
/**
 * @ReduxAction Flashing Cell Module is ready
 */
export const FLASHING_CELL_READY = 'FLASHING_CELL_READY';
export const FLASHING_CELL_DEFINITION_SET = 'FLASHING_CELL_DEFINITION_SET';
export const FlashingCellDefinitionAdd = flashingCellDefinition => ({
  type: FLASHING_CELL_DEFINITION_ADD,
  flashingCellDefinition
});
export const FlashingCellDefinitionSet = flashingCellDefinitions => ({
  type: FLASHING_CELL_DEFINITION_SET,
  flashingCellDefinitions
});
export const FlashingCellDefinitionEdit = flashingCellDefinition => ({
  type: FLASHING_CELL_DEFINITION_EDIT,
  flashingCellDefinition
});
export const FlashingCellDefinitionDelete = flashingCellDefinition => ({
  type: FLASHING_CELL_DEFINITION_DELETE,
  flashingCellDefinition
});
export const FlashingCellDefinitionSuspend = flashingCellDefinition => ({
  type: FLASHING_CELL_DEFINITION_SUSPEND,
  flashingCellDefinition
});
export const FlashingCellDefinitionUnSuspend = flashingCellDefinition => ({
  type: FLASHING_CELL_DEFINITION_UNSUSPEND,
  flashingCellDefinition
});
export const FlashingCellDefinitionSuspendAll = () => ({
  type: FLASHING_CELL_DEFINITION_SUSPEND_ALL
});
export const FlashingCellDefinitionUnSuspendAll = () => ({
  type: FLASHING_CELL_DEFINITION_UNSUSPEND_ALL
});
export const FlashingCellReady = alertState => ({
  type: FLASHING_CELL_READY,
  alertState
});
const initialState = {
  FlashingCellDefinitions: []
};
export const FlashingCellReducer = (state = initialState, action) => {
  let flashingCellDefinitions;
  switch (action.type) {
    case FLASHING_CELL_DEFINITION_SET:
      {
        const actionFlashingCellDefinition = action.flashingCellDefinitions;
        return Object.assign(Object.assign({}, state), {
          FlashingCellDefinitions: actionFlashingCellDefinition
        });
      }
    case FLASHING_CELL_DEFINITION_ADD:
      {
        const actionFlashingCellDefinition = action.flashingCellDefinition;
        AdaptableHelper.addUuidAndSource(actionFlashingCellDefinition);
        flashingCellDefinitions = [].concat(state.FlashingCellDefinitions);
        flashingCellDefinitions.push(actionFlashingCellDefinition);
        return Object.assign(Object.assign({}, state), {
          FlashingCellDefinitions: flashingCellDefinitions
        });
      }
    case FLASHING_CELL_DEFINITION_EDIT:
      {
        const actionFlashingCellDefinition = action.flashingCellDefinition;
        return Object.assign(Object.assign({}, state), {
          FlashingCellDefinitions: state.FlashingCellDefinitions.map(abObject => abObject.Uuid === actionFlashingCellDefinition.Uuid ? actionFlashingCellDefinition : abObject)
        });
      }
    case FLASHING_CELL_DEFINITION_DELETE:
      {
        const actionFlashingCellDefinition = action.flashingCellDefinition;
        return Object.assign(Object.assign({}, state), {
          FlashingCellDefinitions: state.FlashingCellDefinitions.filter(abObject => abObject.Uuid !== actionFlashingCellDefinition.Uuid)
        });
      }
    case FLASHING_CELL_DEFINITION_SUSPEND:
      {
        return Object.assign(Object.assign({}, state), {
          FlashingCellDefinitions: changeIsSuspendInList(action.flashingCellDefinition, state.FlashingCellDefinitions, true)
        });
      }
    case FLASHING_CELL_DEFINITION_UNSUSPEND:
      {
        return Object.assign(Object.assign({}, state), {
          FlashingCellDefinitions: changeIsSuspendInList(action.flashingCellDefinition, state.FlashingCellDefinitions, false)
        });
      }
    case FLASHING_CELL_DEFINITION_SUSPEND_ALL:
      {
        return Object.assign(Object.assign({}, state), {
          FlashingCellDefinitions: suspendAllInList(state.FlashingCellDefinitions)
        });
      }
    case FLASHING_CELL_DEFINITION_UNSUSPEND_ALL:
      {
        return Object.assign(Object.assign({}, state), {
          FlashingCellDefinitions: unsuspendAllInList(state.FlashingCellDefinitions)
        });
      }
    default:
      return state;
  }
};