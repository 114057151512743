import * as React from 'react';
import { Box } from 'rebass';
import { useAdaptable } from '../AdaptableContext';
import { getStatusItemStyle } from './Utilities/getStatusItemStyle';
export const SystemStatusStatusBarContent = () => {
  const adaptable = useAdaptable();
  const currentSystemStatusMessageInfo = adaptable.api.systemStatusApi.getCurrentSystemStatusMessageInfo();
  const {
    color,
    background
  } = getStatusItemStyle(currentSystemStatusMessageInfo);
  const message = currentSystemStatusMessageInfo === null || currentSystemStatusMessageInfo === void 0 ? void 0 : currentSystemStatusMessageInfo.statusMessage;
  return React.createElement(Box, {
    style: {
      borderRadius: 'var(--ab__border-radius)'
    },
    padding: 1,
    color: message && color,
    backgroundColor: message && background
  }, message || '0 Messages');
};