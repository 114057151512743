import * as React from 'react';
import { Box } from 'rebass';
import { OnePageAdaptableWizard, OnePageWizardSummary } from '../../../View/Wizard/OnePageAdaptableWizard';
import { cloneObject } from '../../../Utilities/Helpers/Helper';
import { renderScopeSummary, isScopeValid } from '../../Components/NewScopeComponent';
import { ShortcutScopeWizardSection } from './ShortcutScopeWizardSection';
import { ShortcutSettingsSummary, ShortcutSettingsWizard, isSettingsValid } from './ShortcutSettingsWizard';
import ObjectFactory from '../../../Utilities/ObjectFactory';
import { useDispatch } from 'react-redux';
import * as ShortcutRedux from '../../../Redux/ActionsReducers/ShortcutRedux';
import { shortcutKeys } from '../shortcutKeys';
import { useAdaptable } from '../../AdaptableContext';
import { ObjectTagsWizardSection, renderObjectTagsSummary } from '../../Wizard/ObjectTagsWizardSection';
export const ShortcutWizard = props => {
  const [shortcut, setShortcut] = React.useState(() => {
    var _a, _b, _c;
    const shortcut = props.data ? cloneObject(props.data) : ObjectFactory.CreateEmptyShortcut();
    shortcut.Scope = (_a = shortcut.Scope) !== null && _a !== void 0 ? _a : {
      All: true
    };
    if (((_b = props.popupParams) === null || _b === void 0 ? void 0 : _b.column) && ((_c = props.popupParams) === null || _c === void 0 ? void 0 : _c.action) === 'New') {
      shortcut.Scope = {
        ColumnIds: [props.popupParams.column.columnId]
      };
    }
    return shortcut;
  });
  const adaptable = useAdaptable();
  const availableKeys = React.useMemo(() => {
    const availableKeys = shortcutKeys.filter(key => adaptable.api.shortcutApi.getShortcuts().every(shortcut => shortcut.ShortcutKey !== key));
    if (shortcut) {
      availableKeys.push(shortcut.ShortcutKey);
    }
    availableKeys.sort();
    return availableKeys;
  }, []);
  const dispatch = useDispatch();
  const handleFinish = () => {
    if (props.data) {
      dispatch(ShortcutRedux.ShortcutEdit(shortcut));
    } else {
      dispatch(ShortcutRedux.ShortcutAdd(shortcut));
    }
    props.onFinishWizard(shortcut);
  };
  return React.createElement(OnePageAdaptableWizard, {
    defaultCurrentSectionName: props.defaultCurrentSectionName,
    moduleInfo: props.moduleInfo,
    data: shortcut,
    onHide: props.onCloseWizard,
    onFinish: handleFinish,
    sections: [{
      title: 'Target',
      isValid: isScopeValid,
      details: 'Specify where Shortcut should be applied',
      renderSummary: () => renderScopeSummary(shortcut.Scope, {
        scopeWholeRow: 'Shortcut is triggered for all numeric cells',
        scopeColumns: 'Shortcut is triggered for cells in selected columns',
        scopeDataTypes: 'Shortcut is triggered for all numeric cells'
      }),
      render: () => React.createElement(Box, {
        padding: 2
      }, React.createElement(ShortcutScopeWizardSection, {
        onChange: setShortcut
      }))
    }, {
      title: 'Settings',
      isValid: isSettingsValid,
      details: React.createElement(React.Fragment, null, "A Shortcut is a mathematical calculation (", React.createElement("b", null, "Operation"), ") which is applied with the defined operand ", React.createElement("b", null, "Value"), " on the edited cell content when the corresponding Shortcut ", React.createElement("b", null, "Key"), " is pressed."),
      renderSummary: () => React.createElement(ShortcutSettingsSummary, null),
      render: () => {
        var _a;
        return React.createElement(Box, {
          padding: 2
        }, React.createElement(ShortcutSettingsWizard, {
          availableKeys: (_a = props.availableKeys) !== null && _a !== void 0 ? _a : availableKeys,
          onChange: setShortcut
        }));
      }
    }, {
      details: 'Select Shortcut Tags',
      title: 'Tags',
      isVisible: (_, api) => api.internalApi.shouldDisplayTagSections(),
      render: () => React.createElement(Box, {
        padding: 2
      }, React.createElement(ObjectTagsWizardSection, {
        onChange: setShortcut
      })),
      renderSummary: renderObjectTagsSummary
    }, '-', {
      details: 'Review the Shortcut',
      render: () => {
        return React.createElement(Box, {
          padding: 2
        }, React.createElement(OnePageWizardSummary, null));
      },
      title: 'Summary'
    }]
  });
};