import throttle from 'lodash/throttle';
import * as React from 'react';
import Textarea from '../../components/Textarea';
import SimpleButton from '../../components/SimpleButton';
import { useAdaptable } from '../AdaptableContext';
export const NoteEditor = ({
  note,
  onNoteChange,
  onClose,
  editMode,
  isReadonly
}) => {
  var _a, _b;
  const {
    api
  } = useAdaptable();
  const showPopupCloseButton = (_b = (_a = api.optionsApi.getNoteOptions()) === null || _a === void 0 ? void 0 : _a.showPopupCloseButton) !== null && _b !== void 0 ? _b : true;
  const textAreaRef = React.useRef(null);
  const [liveValue, setLiveValue] = React.useState(note || '');
  const throttledOnChange = React.useMemo(() => {
    const throttled = throttle(value => onNoteChange(value), 300);
    return value => {
      setLiveValue(value);
      throttled(value);
    };
  }, []);
  React.useEffect(() => {
    var _a;
    if (editMode) {
      (_a = textAreaRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }
  }, [editMode]);
  if (note === undefined || note === null) {
    return null;
  }
  return React.createElement(React.Fragment, null, React.createElement(Textarea, {
    readOnly: isReadonly,
    ref: textAreaRef,
    width: "100%",
    minWidth: 180,
    minHeight: 120,
    style: {
      paddingRight: showPopupCloseButton ? 30 : 0
    },
    value: liveValue,
    onBlur: () => onClose(),
    onKeyDown: event => {
      if (event.key === 'Escape') {
        onClose();
      }
    },
    onChange: event => throttledOnChange(event.target.value)
  }), showPopupCloseButton && React.createElement(SimpleButton, {
    variant: "text",
    style: {
      position: 'absolute',
      right: 5,
      top: 5
    },
    icon: "close"
  }));
};