import { ApiBase } from '../Implementation/ApiBase';
import { CreateEmptyFreeTextColumn } from '../../Utilities/ObjectFactory';
import { AdaptableNumberEditor } from '../../agGrid/editors/AdaptableNumberEditor';
import { AdaptableDateEditor } from '../../agGrid/editors/AdaptableDateEditor';
import { AB_SPECIAL_COLUMN } from '../../Utilities/Constants/GeneralConstants';
import ArrayExtensions from '../../Utilities/Extensions/ArrayExtensions';
export class FreeTextColumnInternalApi extends ApiBase {
  /**
   * Checks if a User Edit was in a Free Text Column
   * @param cellDataChangedInfo change to check
   */
  handleFreeTextColumnDataChange(cellDataChangedInfo) {
    let freeTextColumn = this.getFreeTextColumnApi().getFreeTextColumns().find(fc => fc.ColumnId == cellDataChangedInfo.column.columnId);
    if (freeTextColumn) {
      let freeTextStoredValue = {
        PrimaryKey: cellDataChangedInfo.primaryKeyValue,
        FreeText: cellDataChangedInfo.newValue
      };
      this.getFreeTextColumnApi().setStoredValue(freeTextColumn.ColumnId, freeTextStoredValue);
      var colsToRefresh = [cellDataChangedInfo.column.columnId];
      const refencedCalcColumns = this.getAdaptableApi().calculatedColumnApi.internalApi.getCalculatedColumnsReferencingColumnId(cellDataChangedInfo.column.columnId);
      refencedCalcColumns.forEach(calculatedColumn => {
        colsToRefresh.push(calculatedColumn.ColumnId);
      });
      this.adaptable.refreshCells([cellDataChangedInfo.rowNode], colsToRefresh, false);
    }
  }
  /**
   * Returns all the references in the AdaptableState of the given FreeTextColumn
   * @param freeTextColumn FreeTextColumn to be checked
   */
  getFreeTextColumnModuleReferences(freeTextColumn) {
    const references = [];
    this.adaptable.adaptableModules.forEach(module => {
      if (module.getModuleFreeTextColumnReferences().some(moduleReference => moduleReference.Uuid === freeTextColumn.Uuid)) {
        references.push(module.moduleInfo.FriendlyName);
      }
    });
    return references;
  }
  getColDefsForFreeTextColumns() {
    const defaultSpecialColumnSettings = this.getGridApi().internalApi.deriveSpecialColumnSettingsFromAgGridDefaultColDef();
    const defaultFreeTextColumnSettings = CreateEmptyFreeTextColumn(defaultSpecialColumnSettings).FreeTextColumnSettings;
    return this.getFreeTextColumnApi().getFreeTextColumns().map(freeTextColumn => {
      var _a;
      const freeTextColumnSettings = Object.assign(Object.assign({}, defaultFreeTextColumnSettings), freeTextColumn.FreeTextColumnSettings);
      const dataTypeEditor = freeTextColumnSettings.DataType === 'Number' ? AdaptableNumberEditor : freeTextColumnSettings.DataType === 'Date' ? AdaptableDateEditor : null;
      const columnTypes = [AB_SPECIAL_COLUMN, this.getGridApi().internalApi.getAgGridDataType(freeTextColumnSettings.DataType)];
      if (ArrayExtensions.IsNotNullOrEmpty((_a = freeTextColumn === null || freeTextColumn === void 0 ? void 0 : freeTextColumn.FreeTextColumnSettings) === null || _a === void 0 ? void 0 : _a.ColumnTypes)) {
        columnTypes.push(...freeTextColumn.FreeTextColumnSettings.ColumnTypes);
      }
      const newColDef = {
        headerName: freeTextColumn.FriendlyName || freeTextColumn.ColumnId,
        colId: freeTextColumn.ColumnId,
        editable: true,
        hide: false,
        width: freeTextColumnSettings.Width,
        enableValue: freeTextColumnSettings.Aggregatable,
        filter: freeTextColumnSettings.Filterable,
        floatingFilter: freeTextColumnSettings.Filterable,
        resizable: freeTextColumnSettings.Resizable,
        enableRowGroup: freeTextColumnSettings.Groupable,
        sortable: freeTextColumnSettings.Sortable,
        enablePivot: freeTextColumnSettings.Pivotable,
        suppressHeaderMenuButton: freeTextColumnSettings.SuppressMenu,
        suppressMovable: freeTextColumnSettings.SuppressMovable,
        cellEditor: dataTypeEditor !== null && dataTypeEditor !== void 0 ? dataTypeEditor : freeTextColumn.TextEditor && freeTextColumn.TextEditor == 'Large' ? 'agLargeTextCellEditor' : 'agTextCellEditor',
        type: columnTypes,
        valueSetter: params => {
          return params.data[freeTextColumn.ColumnId] = params.newValue;
        },
        // use a Boolean Cell Data Type if the Free Text Column is boolean
        cellDataType: freeTextColumnSettings.DataType && freeTextColumnSettings.DataType == 'Boolean' ? 'boolean' : undefined,
        valueGetter: params => this.getFreeTextColumnApi().getFreeTextColumnValueForRowNode(freeTextColumn, params.node)
      };
      if (freeTextColumnSettings.HeaderToolTip) {
        newColDef.headerTooltip = freeTextColumnSettings.HeaderToolTip;
      }
      this.getAdaptableApi().logInfo('Setting up FreeText Column: ' + freeTextColumn.ColumnId);
      return newColDef;
    });
  }
}