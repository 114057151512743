import * as NamedQueryRedux from '../Redux/ActionsReducers/NamedQueryRedux';
import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
import { EditGridFilterButton } from '../View/NamedQuery/EditCurrentQueryButton';
import { NamedQueryWizard } from '../View/NamedQuery/Wizard/NamedQueryWizard';
import { AdaptableModuleBase } from './AdaptableModuleBase';
import { getObjectTagsViewItems } from './Utilities/getObjectTagsViewItems';
export class NamedQueryModule extends AdaptableModuleBase {
  constructor(api) {
    super(ModuleConstants.NamedQueryModuleId, ModuleConstants.NamedQueryFriendlyName, 'grid-filter', 'NamedQueryPopup', 'Creates Named Queries', api);
  }
  getModuleAdaptableObjects() {
    return this.api.namedQueryApi.getNamedQueries();
  }
  getTeamSharingAction() {
    return {
      ModuleEntities: this.api.namedQueryApi.getNamedQueries(),
      AddAction: NamedQueryRedux.NamedQueryAdd,
      EditAction: NamedQueryRedux.NamedQueryEdit
    };
  }
  getExplicitlyReferencedColumnIds(namedQuery) {
    return this.api.expressionApi.getColumnsFromExpression(namedQuery.BooleanExpression);
  }
  getReferencedNamedQueryNames(namedQuery) {
    return this.api.namedQueryApi.internalApi.getReferencedNamedQueryNames(namedQuery.BooleanExpression);
  }
  toView(query) {
    return {
      abObject: query,
      items: [{
        name: 'Query Name',
        values: [query.Name]
      }, {
        name: 'Expression',
        values: [this.api.internalApi.getAdaptableQueryExpressionText(query)]
      }, getObjectTagsViewItems(query, this.api)]
    };
  }
  toViewAll() {
    return this.getModuleAdaptableObjects().map(query => this.toView(query));
  }
  getViewProperties() {
    return {
      getDeleteAction: NamedQueryRedux.NamedQueryDelete,
      newTooltipText: 'Create New Named Query',
      emptyView: "Click 'New' to create a Named Query that can be referenced in other Expressions",
      getEditWizard: () => NamedQueryWizard,
      getStatusBarPanelProps: () => {
        const queryMaxLength = 15;
        const query = this.api.gridFilterApi.getCurrentGridFilterExpression();
        let text = query;
        let popover = null;
        if (query.length > queryMaxLength) {
          text = query.substring(0, queryMaxLength - 3) + '...';
          popover = query;
        }
        return {
          content: text || 'No Query',
          extraActions: [EditGridFilterButton],
          popover
        };
      }
    };
  }
}