import * as React from 'react';
import SimpleButton from '../../components/SimpleButton';
import { useAdaptable } from '../AdaptableContext';
export const EditGridFilterButton = () => {
  const adaptable = useAdaptable();
  const handleClick = React.useCallback(event => {
    event.stopPropagation();
    adaptable.api.gridFilterApi.openUIEditorForGridFilter();
  }, []);
  return React.createElement(SimpleButton, {
    variant: "text",
    iconSize: 15,
    onClick: handleClick,
    icon: "edit"
  });
};