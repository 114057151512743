import * as SystemRedux from '../../Redux/ActionsReducers/SystemRedux';
import ObjectFactory from '../ObjectFactory';
export class ChartingService {
  constructor(api) {
    this.api = api;
  }
  onChartModelChange(models, eventType, params) {
    const allChartDefinitions = this.api.chartingApi.getChartDefinitions();
    const currentChartModel = models.find(model => model.chartId === params.chartId);
    const currentChartDefinition = allChartDefinitions.find(chartDefinition => chartDefinition.Model.chartId === params.chartId);
    switch (eventType) {
      case 'chartCreated':
        const createParams = params;
        if (allChartDefinitions.some(chartDefinition => chartDefinition.Model.chartId === createParams.chartId)) {
          // chart already exists
        } else {
          const chartModel = models.find(model => model.chartId === createParams.chartId);
          this.saveNewChart(chartModel);
        }
        break;
      case 'chartOptionsChanged':
      case 'chartRangeSelectionChanged':
        if (!currentChartDefinition) {
          // editing a non-saved chart
          break;
        }
        const updatedChartDefinition = Object.assign(Object.assign({}, currentChartDefinition), {
          Model: currentChartModel
        });
        this.api.chartingApi.editChartDefinition(updatedChartDefinition);
        break;
    }
    this.api.internalApi.dispatchReduxAction(SystemRedux.SystemChartingSetCurrentChartModels(models));
    this.emitAdaptableChartDefinitonChange();
  }
  /**
   * Emit events only for adaptable charting definition changes
   * - an adaptable chart opens
   * - an adaptable chart closes
   * - an adaptable chart is created
   * - an adaptable chart is deleted
   */
  emitAdaptableChartDefinitonChange() {
    const chartingOpenState = this.api.chartingApi.getChartingOpenState();
    const chartChangedInfo = Object.assign({
      chartingOpenState: chartingOpenState
    }, this.api.internalApi.buildBaseContext());
    this.api.eventApi.emit('ChartChanged', chartChangedInfo);
  }
  saveNewChart(chartModel) {
    var _a, _b;
    const chartingOptions = (_a = this.api.optionsApi.getChartingOptions()) !== null && _a !== void 0 ? _a : {};
    const saveStragegy = (_b = chartingOptions.saveChartBehaviour) !== null && _b !== void 0 ? _b : 'manual';
    if (saveStragegy === 'none') {
      return;
    }
    if (saveStragegy === 'manual') {
      const alert = {
        alertType: 'generic',
        header: 'Save chart',
        message: 'Do you want to save this Chart?',
        alertDefinition: Object.assign(Object.assign({}, ObjectFactory.CreateEmptyAlertDefinition()), {
          MessageType: 'Info',
          AlertProperties: {
            DisplayNotification: true,
            NotificationDuration: 'always'
          },
          AlertForm: {
            Buttons: [{
              Label: 'Save',
              Action: context => {
                const chartName = context.formData.name || chartModel.chartId;
                this.api.chartingApi.addChartDefinition(ObjectFactory.CreateEmptyChartDefinition({
                  Name: chartName,
                  Model: chartModel
                }));
              },
              ButtonStyle: {
                tone: 'info',
                variant: 'raised'
              }
            }, {
              Label: 'Dismiss',
              ButtonStyle: {
                tone: 'neutral',
                variant: 'raised'
              }
            }],
            fields: [{
              name: 'name',
              label: 'Name',
              fieldType: 'text'
            }]
          }
        })
      };
      this.api.alertApi.showAdaptableAlert(alert);
    } else if (saveStragegy === 'auto') {
      this.api.chartingApi.addChartDefinition(ObjectFactory.CreateEmptyChartDefinition({
        Name: chartModel.chartId,
        Model: chartModel
      }));
    }
  }
  destroy() {
    this.api = null;
  }
}