import * as React from 'react';
import { useDispatch } from 'react-redux';
import join from '../../../../components/utils/join';
import { useGlobalEvent } from '../../../../components/utils/useGlobalEvent';
import { AdaptableIconComponent } from '../../AdaptableIconComponent';
export const Navigation = props => {
  const dispatch = useDispatch();
  const handleKeyPress = React.useCallback(event => {
    if ((event.ctrlKey || event.metaKey) && (event.code === 'ArrowUp' || event.code === 'ArrowDown')) {
      const isKeyUp = event.code === 'ArrowUp';
      const currentIndex = props.menuItems.findIndex(item => item !== '-' && (item.module === props.activeItem || item.label === props.activeItem));
      let nextMenuItem = null;
      if (isKeyUp) {
        let candidate = props.menuItems[currentIndex - 1] !== '-' ? props.menuItems[currentIndex - 1] : props.menuItems[currentIndex - 2];
        nextMenuItem = candidate ? candidate : props.menuItems[props.menuItems.length - 1];
      } else {
        let candidate = props.menuItems[currentIndex + 1] !== '-' ? props.menuItems[currentIndex + 1] : props.menuItems[currentIndex + 2];
        nextMenuItem = candidate ? candidate : props.menuItems[0];
      }
      if (nextMenuItem && nextMenuItem !== '-') {
        dispatch(nextMenuItem.reduxAction);
      }
    }
  }, [props.activeItem, props.menuItems]);
  useGlobalEvent('keydown', handleKeyPress);
  return React.createElement("nav", {
    className: "ab-Adaptable-Popup__Navigation"
  }, React.createElement("ul", {
    className: "ab-Adaptable-Popup__Navigation__List"
  }, props.menuItems.map((menuItem, index) => {
    var _a, _b, _c, _d, _e;
    const baseClassName = 'ab-Adaptable-Popup__Navigation__List__Item';
    if (menuItem === '-') {
      return React.createElement("li", {
        key: `separator-${index}`,
        className: `${baseClassName}__Separator`
      });
    }
    const isActive = props.activeItem === menuItem.module || menuItem.label === props.activeItem;
    const className = join(baseClassName, isActive && `${baseClassName}--active`);
    let customIcon = (_d = (_c = (_b = (_a = props.customSettingsPanels) === null || _a === void 0 ? void 0 : _a.find) === null || _b === void 0 ? void 0 : _b.call(_a, customSettingPanel => customSettingPanel.name === menuItem.label)) === null || _c === void 0 ? void 0 : _c.icon) !== null && _d !== void 0 ? _d : null;
    const dataName = menuItem.module === 'CustomSettingsPanel' ? `CSP-${menuItem.label}` : (_e = menuItem.module) !== null && _e !== void 0 ? _e : menuItem.label;
    return React.createElement("li", {
      className: className,
      key: menuItem.label
    }, React.createElement("button", {
      type: "button",
      className: `${baseClassName}__Button`,
      onClick: () => {
        dispatch(menuItem.reduxAction);
      },
      "data-name": dataName
    }, menuItem.icon && React.createElement(AdaptableIconComponent, {
      icon: menuItem.icon,
      iconClassName: `${baseClassName}__Icon`
    }), customIcon && React.createElement(AdaptableIconComponent, {
      icon: customIcon
    }), menuItem.label));
  })));
};