import * as CalculatedColumnRedux from '../../Redux/ActionsReducers/CalculatedColumnRedux';
import { ApiBase } from './ApiBase';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import { CalculatedColumnInternalApi } from '../Internal/CalculatedColumnInternalApi';
export class CalculatedColumnApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.internalApi = new CalculatedColumnInternalApi(adaptable);
  }
  getCalculatedColumnState() {
    return this.getAdaptableState().CalculatedColumn;
  }
  getCalculatedColumns() {
    var _a;
    return (_a = this.getCalculatedColumnState().CalculatedColumns) !== null && _a !== void 0 ? _a : [];
  }
  getCalculatedColumnById(id) {
    return this.getCalculatedColumns().find(column => (column === null || column === void 0 ? void 0 : column.Uuid) === id);
  }
  addCalculatedColumn(calculatedColumn) {
    this.addUidToAdaptableObject(calculatedColumn);
    this.dispatchAction(CalculatedColumnRedux.CalculatedColumnAdd(calculatedColumn));
    return this.getCalculatedColumnById(calculatedColumn.Uuid);
  }
  getCalculatedColumnForColumnId(columnId) {
    return this.getCalculatedColumns().find(column => column.ColumnId === columnId);
  }
  editCalculatedColumn(calculatedColumn) {
    this.dispatchAction(CalculatedColumnRedux.CalculatedColumnEdit(calculatedColumn));
    return this.getCalculatedColumnById(calculatedColumn.Uuid);
  }
  deleteCalculatedColumn(column) {
    let calcColumn = this.getCalculatedColumns().find(cc => cc.ColumnId == column);
    this.dispatchAction(CalculatedColumnRedux.CalculatedColumnDelete(calcColumn));
  }
  openCalculatedColumnSettingsPanel() {
    this.showModulePopup(ModuleConstants.CalculatedColumnModuleId);
  }
  refreshAggregatedCalculatedColumns() {
    const aggregatedCalculatedColumns = this.getCalculatedColumns().filter(calculatedColumn => {
      var _a;
      return (_a = calculatedColumn.Query) === null || _a === void 0 ? void 0 : _a.AggregatedScalarExpression;
    });
    aggregatedCalculatedColumns.forEach(calculatedColumn => {
      this.getAdaptableApi().internalApi.getCalculatedColumnExpressionService().createAggregatedScalarLiveValue(calculatedColumn);
    });
    // refresh calculated columns
    const aggCalculatedColumnIds = aggregatedCalculatedColumns.map(calculatedColumn => calculatedColumn.ColumnId);
    if (this.getLayoutApi().internalApi.isLayoutGrouped()) {
      this.getGridApi().refreshGroupRowNodes();
    } else {
      this.getGridApi().refreshCells(null, aggCalculatedColumnIds);
    }
  }
  refreshAggregatedCalculatedColumn(columnId) {
    var _a;
    const aggregatedCalculatedColumn = this.getCalculatedColumnForColumnId(columnId);
    if (!((_a = aggregatedCalculatedColumn === null || aggregatedCalculatedColumn === void 0 ? void 0 : aggregatedCalculatedColumn.Query) === null || _a === void 0 ? void 0 : _a.AggregatedScalarExpression)) {
      this.logWarn(`Aggregated calculated column with ID=${columnId} was NOT found (and refreshed)!`);
      return;
    }
    this.getAdaptableApi().internalApi.getCalculatedColumnExpressionService().createAggregatedScalarLiveValue(aggregatedCalculatedColumn);
    this.getGridApi().refreshCells(null, [columnId]);
    if (this.getLayoutApi().internalApi.isLayoutGrouped()) {
      this.getGridApi().refreshGroupRowNodes();
    } else {
      this.getGridApi().refreshCells(null, [columnId]);
    }
  }
}