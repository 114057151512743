import * as SystemRedux from '../../Redux/ActionsReducers/SystemRedux';
import * as FlashingCellRedux from '../../Redux/ActionsReducers/FlashingCellRedux';
import { ApiBase } from './ApiBase';
import { FlashingCellInternalApi } from '../Internal/FlashingCellInternalApi';
export class FlashingCellApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.internalApi = new FlashingCellInternalApi(adaptable);
  }
  getFlashingCellState() {
    return this.getAdaptableState().FlashingCell;
  }
  getFlashingCellDefinitions(config) {
    var _a;
    const flashingCellDefinitions = (_a = this.handleLayoutAssociatedObjects(this.getFlashingCellState().FlashingCellDefinitions, 'FlashingCell', config)) !== null && _a !== void 0 ? _a : [];
    return flashingCellDefinitions.map(flashingCellDefinition => {
      return this.internalApi.mergeFlashingCellDefinitionWithDefaults(flashingCellDefinition);
    });
  }
  getFlashingCellDefinitionById(id) {
    var _a;
    return (_a = this.getFlashingCellDefinitions()) === null || _a === void 0 ? void 0 : _a.find(fc => (fc === null || fc === void 0 ? void 0 : fc.Uuid) === id);
  }
  getActiveFlashingCellDefinitions() {
    return this.getFlashingCellDefinitions().filter(fc => !fc.IsSuspended);
  }
  getFlashingCellFlashTarget(flashingCellDefinition) {
    var _a;
    return (_a = flashingCellDefinition.FlashTarget) !== null && _a !== void 0 ? _a : 'cell';
  }
  showFlashingCell(flashingCellToShow) {
    this.addUidToAdaptableObject(flashingCellToShow);
    const flashingCellFiredInfo = Object.assign(Object.assign({}, this.getAdaptableApi().internalApi.buildBaseContext()), {
      flashingCell: flashingCellToShow
    });
    const results = this.getAdaptableApi().eventApi.emitSync('FlashingCellDisplayed', flashingCellFiredInfo);
    const shouldShow = results.reduce((endResult, aResult) => {
      return endResult && aResult !== false;
    }, true);
    if (shouldShow) {
      this.dispatchAction(SystemRedux.SystemFlashingCellAdd(flashingCellToShow));
      const {
        FlashDuration: FlashDuration
      } = flashingCellToShow.flashingCellDefinition;
      if (FlashDuration && FlashDuration !== 'always') {
        setTimeout(() => {
          this.dispatchAction(SystemRedux.SystemFlashingCellDelete(flashingCellToShow));
        }, FlashDuration);
      }
    }
  }
  addFlashingCellDefinition(flashingCellDefinition) {
    this.dispatchAction(FlashingCellRedux.FlashingCellDefinitionAdd(flashingCellDefinition));
    return this.getFlashingCellDefinitionById(flashingCellDefinition.Uuid);
  }
  setFlashingCellDefinitions(flashingCellDefinitions) {
    this.dispatchAction(FlashingCellRedux.FlashingCellDefinitionSet(flashingCellDefinitions));
  }
  editFlashingCellDefinition(flashingCellDefinition) {
    this.dispatchAction(FlashingCellRedux.FlashingCellDefinitionEdit(flashingCellDefinition));
    return this.getFlashingCellDefinitionById(flashingCellDefinition.Uuid);
  }
  suspendFlashingCellDefinition(flashingCellDefinition) {
    this.dispatchAction(FlashingCellRedux.FlashingCellDefinitionSuspend(flashingCellDefinition));
    return this.getFlashingCellDefinitionById(flashingCellDefinition.Uuid);
  }
  unSuspendFlashingCellDefinition(flashingCellDefinition) {
    this.dispatchAction(FlashingCellRedux.FlashingCellDefinitionUnSuspend(flashingCellDefinition));
    return this.getFlashingCellDefinitionById(flashingCellDefinition.Uuid);
  }
  addFlashingCellDefinitions(flashingCellDefinitions) {
    flashingCellDefinitions.forEach(fad => {
      this.addFlashingCellDefinition(fad);
    });
    return flashingCellDefinitions === null || flashingCellDefinitions === void 0 ? void 0 : flashingCellDefinitions.map(flashingCell => this.getFlashingCellDefinitionById(flashingCell === null || flashingCell === void 0 ? void 0 : flashingCell.Uuid));
  }
  editFlashingCellDefinitions(flashingCellDefinitions) {
    flashingCellDefinitions.forEach(fad => {
      this.editFlashingCellDefinition(fad);
    });
    return flashingCellDefinitions === null || flashingCellDefinitions === void 0 ? void 0 : flashingCellDefinitions.map(flashingCell => this.getFlashingCellDefinitionById(flashingCell === null || flashingCell === void 0 ? void 0 : flashingCell.Uuid));
  }
  getFlashingCellPredicateDefsForScope(scope) {
    return this.getAdaptableApi().predicateApi.internalApi.getFlashingCellPredicateDefs(scope).filter(predicateDef => this.getAdaptableApi().columnScopeApi.isScopeInScope(scope, predicateDef.columnScope));
  }
  clearAllFlashingCells() {
    this.dispatchAction(SystemRedux.SystemFlashingCellDeleteAll());
  }
  isAnyFlashingCellActive() {
    const currentFlashingCells = this.getAdaptableState().System.AdaptableFlashingCells;
    return currentFlashingCells.keys != null;
  }
  findFlashingCellDefinitions(criteria) {
    return this.getAdaptableApi().internalApi.findAdaptableObjectsByLookupCriteria(criteria, this.getFlashingCellDefinitions({
      includeLayoutNotAssociatedObjects: true
    }));
  }
}