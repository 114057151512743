import * as React from 'react';
import { Tabs } from '../../../components/Tabs';
import Radio, { RadioGroup } from '../../../components/Radio';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
import { Text } from 'rebass';
import { CodeBlock } from '../../../components/CodeBlock';
import FormLayout, { FormRow } from '../../../components/FormLayout';
import { ButtonInfo } from '../../Components/Buttons/ButtonInfo';
import { AlertMessageDocsLink } from '../../../Utilities/Constants/DocumentationLinkConstants';
import Textarea from '../../../components/Textarea';
import HelpBlock from '../../../components/HelpBlock';
export const renderAlertMessageSummary = () => {
  const {
    data
  } = useOnePageAdaptableWizardContext();
  return React.createElement(Text, {
    fontSize: 2
  }, "Message Type ", React.createElement(CodeBlock, null, data.MessageType));
};
export const AlertMessageWizardSection = props => {
  const {
    data,
    api
  } = useOnePageAdaptableWizardContext();
  const messageType = data.MessageType;
  const messageText = api.alertApi.internalApi.getAlertDescriptionForDataChange(data);
  const messageHeader = data.MessageHeader;
  const showDocumentationLinks = api.internalApi.isDocumentationLinksDisplayed();
  const onMessageTextChange = e => {
    const {
      value
    } = e.target;
    props.onChange(Object.assign(Object.assign({}, data), {
      MessageText: value
    }));
  };
  const onMessageHeaderChange = e => {
    const {
      value
    } = e.target;
    props.onChange(Object.assign(Object.assign({}, data), {
      MessageHeader: value
    }));
  };
  return React.createElement(React.Fragment, null, React.createElement(Tabs, {
    "data-name": "message-type"
  }, React.createElement(Tabs.Tab, null, "Message Type"), React.createElement(Tabs.Content, null, React.createElement(RadioGroup, {
    value: messageType,
    name: "messageType",
    orientation: "horizontal",
    onRadioChange: MessageType => {
      props.onChange(Object.assign(Object.assign({}, data), {
        MessageType
      }));
    }
  }, React.createElement(Radio, {
    marginLeft: 1,
    value: "Info"
  }, "Info"), React.createElement(Radio, {
    marginLeft: 4,
    value: "Success"
  }, "Success"), React.createElement(Radio, {
    marginLeft: 4,
    value: "Warning"
  }, "Warning"), React.createElement(Radio, {
    marginLeft: 4,
    value: "Error"
  }, "Error")))), React.createElement(Tabs, {
    "data-name": "message-text",
    mt: 2,
    mb: 3,
    autoFocus: false
  }, React.createElement(Tabs.Tab, null, "Message Text"), React.createElement(Tabs.Content, null, React.createElement(Text, {
    fontSize: 2,
    mt: 3,
    mb: 2
  }, "Text to display as the Alert Message (leave blank to show automated Message based on Alert Type)"), ' ', React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Header"
  }, React.createElement(Textarea, {
    minWidth: 300,
    rows: 3,
    placeholder: "use defaults",
    marginTop: 2,
    type: 'text',
    autoFocus: false,
    value: messageHeader !== null && messageHeader !== void 0 ? messageHeader : '',
    // placeholder="defaults to column name"
    onChange: e => onMessageHeaderChange(e)
  })), React.createElement(FormRow, {
    label: "Message"
  }, React.createElement(Textarea, {
    minWidth: 300,
    rows: 3,
    marginTop: 2,
    type: 'text',
    autoFocus: false,
    value: messageText !== null && messageText !== void 0 ? messageText : '',
    onChange: e => onMessageTextChange(e)
  }))), showDocumentationLinks && React.createElement(HelpBlock, {
    "data-name": "alert-message-documentation",
    mt: 3,
    mb: 2,
    style: {
      fontSize: 'var(--ab-font-size-3)',
      padding: 0
    }
  }, React.createElement(ButtonInfo, {
    mr: 2,
    onClick: () => window.open(AlertMessageDocsLink, '_blank')
  }), "See how to create dynamic Alert Messages using placeholders"))));
};