import { DataSource, InfiniteTable } from '../../../../components/InfiniteTable';
import * as React from 'react';
import { Box, Flex, Text } from 'rebass';
import DropdownButton from '../../../../components/DropdownButton';
import FormLayout, { FormRow } from '../../../../components/FormLayout';
import Input from '../../../../components/Input';
import SimpleButton from '../../../../components/SimpleButton';
import { Tabs } from '../../../../components/Tabs';
import { Tag } from '../../../../components/Tag';
import { isValidOrderForColumnGroups } from '../../../../PredefinedConfig/Common/AdaptableColumn';
import { useAdaptable } from '../../../AdaptableContext';
import { ValueSelector } from '../../../Components/ValueSelector';
import { useOnePageAdaptableWizardContext } from '../../../Wizard/OnePageAdaptableWizard';
import { columnFilter } from './Utilities';
import { Icon } from '../../../../components/icons';
import { CheckBox } from '../../../../components/CheckBox';
import { clamp } from '../../../../Utilities/Helpers/Helper';
import { ColumnLabels } from '../Components/ColumnLabels';
import ArrayExtensions from '../../../../Utilities/Extensions/ArrayExtensions';
const PropertyOrderText = props => React.createElement(Text, {
  fontWeight: 600,
  fontSize: 2
}, props.children);
export const ColumnsSectionSummary = () => {
  const adaptable = useAdaptable();
  const {
    data: layout
  } = useOnePageAdaptableWizardContext();
  const rowHeight = 40;
  const headerHeight = 40;
  const tableHeight = headerHeight + clamp(rowHeight * layout.Columns.length, 100, 360);
  const data = React.useMemo(() => {
    return layout.Columns.map(columnId => {
      var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
      if (adaptable.api.columnApi.isAutoPivotColumn(columnId) || adaptable.api.columnApi.isAutoRowGroupColumn(columnId)) {
        return null;
      }
      const friendlyName = adaptable.api.columnApi.getFriendlyNameForColumnId(columnId);
      const header = (_b = (_a = layout.ColumnHeadersMap) === null || _a === void 0 ? void 0 : _a[columnId]) !== null && _b !== void 0 ? _b : '';
      const columnWidth = (_c = layout.ColumnWidthMap) === null || _c === void 0 ? void 0 : _c[columnId];
      const columnPinning = (_d = layout.PinnedColumnsMap) === null || _d === void 0 ? void 0 : _d[columnId];
      let agg = (_e = layout.AggregationColumns) === null || _e === void 0 ? void 0 : _e[columnId];
      agg = typeof agg === 'object' ? agg.type : agg;
      const sortIndex = ((_f = layout === null || layout === void 0 ? void 0 : layout.ColumnSorts) !== null && _f !== void 0 ? _f : []).findIndex(sort => sort.ColumnId === columnId);
      const sortOrder = sortIndex != -1 ? {
        index: sortIndex + 1,
        sort: (_h = (_g = layout.ColumnSorts[sortIndex]) === null || _g === void 0 ? void 0 : _g.SortOrder) !== null && _h !== void 0 ? _h : null
      } : {};
      let grouping = (_k = (_j = layout.RowGroupedColumns) === null || _j === void 0 ? void 0 : _j.indexOf) === null || _k === void 0 ? void 0 : _k.call(_j, columnId);
      grouping = grouping != -1 ? grouping + 1 : null;
      const filter = (_l = layout.ColumnFilters) === null || _l === void 0 ? void 0 : _l.find(colFilter => colFilter.ColumnId === columnId);
      return {
        columnId,
        agg,
        friendlyName,
        header,
        columnWidth,
        columnPinning,
        sortOrder,
        grouping,
        filter
      };
    }).filter(Boolean);
  }, [layout]);
  const columns = React.useMemo(() => {
    return {
      name: {
        header: 'Name',
        field: 'friendlyName',
        minWidth: 100
      },
      header: {
        header: 'Header',
        field: 'header',
        minWidth: 120
      },
      colId: {
        header: 'ID',
        field: 'columnId'
      },
      agg: {
        header: 'Agg',
        field: 'agg',
        minWidth: 70
      },
      width: {
        header: 'Width',
        field: 'columnWidth',
        minWidth: 70
      },
      pin: {
        header: 'Pin',
        field: 'columnPinning',
        minWidth: 80
      },
      sort: {
        header: 'Sort',
        field: 'sortOrder',
        minWidth: 50,
        align: 'center',
        render: params => {
          var _a, _b, _c, _d, _e, _f, _g;
          if (!((_b = (_a = params.data) === null || _a === void 0 ? void 0 : _a.sortOrder) === null || _b === void 0 ? void 0 : _b.sort)) {
            return React.createElement(React.Fragment, null);
          }
          return React.createElement(Flex, null, React.createElement(Icon, {
            name: ((_d = (_c = params.data) === null || _c === void 0 ? void 0 : _c.sortOrder) === null || _d === void 0 ? void 0 : _d.sort) === 'Asc' ? 'arrow-up-long' : 'arrow-down-long'
          }), ' ', React.createElement(PropertyOrderText, null, (_g = (_f = (_e = params.data) === null || _e === void 0 ? void 0 : _e.sortOrder) === null || _f === void 0 ? void 0 : _f.index) !== null && _g !== void 0 ? _g : ''));
        }
      },
      filter: {
        header: 'Filter',
        field: 'filter',
        align: 'center',
        minWidth: 50,
        render: params => {
          var _a;
          if (!((_a = params.data) === null || _a === void 0 ? void 0 : _a.filter)) {
            return React.createElement(React.Fragment, null);
          }
          return React.createElement(CheckBox, {
            checked: true
          });
        }
      },
      group: {
        header: 'Group',
        minWidth: 50,
        field: 'columnGroupping',
        align: 'center',
        render: params => {
          var _a;
          if (!params.data.grouping) {
            return React.createElement(React.Fragment, null);
          }
          return React.createElement(Flex, null, React.createElement(CheckBox, {
            margin: 0,
            mr: 1,
            checked: true
          }), React.createElement(PropertyOrderText, null, (_a = params.data) === null || _a === void 0 ? void 0 : _a.grouping));
        }
      }
    };
  }, []);
  const tableDOMProps = {
    style: {
      height: '100%',
      minWidth: '10rem',
      minHeight: tableHeight
    }
  };
  return React.createElement(DataSource, {
    data: data,
    primaryKey: "columnId"
  }, React.createElement(InfiniteTable, {
    columnTypes: {
      default: {
        defaultFlex: 1
      }
    },
    rowHeight: rowHeight,
    domProps: tableDOMProps,
    columns: columns
  }));
};
const ColumnRow = props => {
  var _a, _b, _c, _d;
  const [isExpanded, setIsExpanded] = React.useState(false);
  const adaptable = useAdaptable();
  // headers
  const initialHeader = adaptable.api.columnApi.getFriendlyNameForColumnId(props.column.columnId);
  const customHeader = (_b = (_a = props.layout.ColumnHeadersMap) === null || _a === void 0 ? void 0 : _a[props.column.columnId]) !== null && _b !== void 0 ? _b : initialHeader;
  // column pinning
  const columnPinning = ((_c = props.layout.PinnedColumnsMap) === null || _c === void 0 ? void 0 : _c[props.column.columnId]) || 'None';
  const pinningOptions = [{
    value: 'None',
    label: 'None',
    onClick: () => props.onPinChange(props.column.columnId, null)
  }, {
    value: 'left',
    label: 'Left',
    onClick: () => props.onPinChange(props.column.columnId, 'left')
  }, {
    value: 'right',
    label: 'Right',
    onClick: () => props.onPinChange(props.column.columnId, 'right')
  }];
  // behaviour
  const behaviourHeader = 'Column ' + adaptable.api.internalApi.getCorrectEnglishVariant('Behaviours');
  // width
  const columnWidth = (_d = props.layout.ColumnWidthMap) === null || _d === void 0 ? void 0 : _d[props.column.columnId];
  return React.createElement(Box, {
    "data-name": props.column.columnId,
    className: "ab-Layout-Wizard__ColumnRow"
  }, React.createElement(Flex, {
    className: "ab-Layout-Wizard__ColumnRow__Header",
    mt: 1,
    mb: 1,
    onClick: () => setIsExpanded(!isExpanded)
  }, React.createElement(Flex, {
    mr: 2,
    alignItems: "center"
  }, initialHeader), props.column.columnGroup && props.column.columnGroup.groupCount > 1 ? React.createElement(Box, {
    className: "ab-Layout-Wizard__ColumnRow__Title",
    ml: 2,
    mr: 2,
    padding: 1
  }, "Column Group: ", props.column.columnGroup.friendlyName) : null, React.createElement(Flex, {
    mr: 2
  }, React.createElement(ColumnLabels, {
    showTitle: false,
    sortable: props.column.sortable,
    filterable: props.column.filterable,
    pivotable: props.column.pivotable,
    moveable: props.column.moveable,
    groupable: props.column.groupable,
    aggregatable: props.column.aggregatable
  })), React.createElement(Box, {
    flex: 1
  }), React.createElement(SimpleButton, {
    "data-name": "expand-collapse",
    "data-value": isExpanded ? 'expanded' : 'collapsed',
    ml: 10,
    padding: 0,
    iconSize: 24,
    variant: "text",
    onClick: () => setIsExpanded(!isExpanded),
    icon: isExpanded ? 'triangle-up' : 'triangle-down'
  })), isExpanded && React.createElement(Box, {
    className: "ab-Layout-Wizard__ColumnRow__Expanded-Container",
    mb: 2,
    padding: 2,
    mt: 2
  }, React.createElement(Flex, {
    mb: 2
  }, React.createElement(FormLayout, {
    width: "100%",
    mr: 3
  }, React.createElement(FormRow, {
    label: "ColumnId"
  }, React.createElement(Tag, null, props.column.columnId)), React.createElement(FormRow, {
    label: "Header"
  }, React.createElement(Input, {
    "data-name": "column-header",
    className: "ab-Layout-Wizard__ColumnRow__Input",
    placeholder: "Custom name (optional)",
    onChange: () => {
      props.onColumnNameChange(props.column.columnId, event.target.value);
    },
    value: customHeader
  })), React.createElement(FormRow, {
    label: "Width"
  }, React.createElement(Input, {
    "data-name": "column-width",
    className: "ab-Layout-Wizard__ColumnRow__Input",
    type: "number",
    placeholder: "Column width",
    onChange: event => {
      let value = parseFloat(event.target.value);
      value = typeof value === 'number' && !isNaN(value) ? value : void 0;
      props.onColumnWidthChange(props.column.columnId, value);
    },
    value: columnWidth
  })), React.createElement(FormRow, {
    label: "Pinning"
  }, React.createElement(DropdownButton, {
    "data-name": "column-pinning",
    columns: ['label'],
    items: pinningOptions
  }, pinningOptions.find(option => option.value === columnPinning).label))))));
};
export const ColumnsSection = props => {
  const adaptable = useAdaptable();
  const {
    data: layout
  } = useOnePageAdaptableWizardContext();
  const allColumns = adaptable.api.columnApi.getColumns();
  const sortedColumns = React.useMemo(() => {
    return ArrayExtensions.sortArrayWithOrder(allColumns.map(col => col.columnId), layout.Columns, {
      sortUnorderedItems: false
    }).map(colId => adaptable.api.columnApi.getColumnWithColumnId(colId));
  }, [layout, allColumns]);
  const handlePinChange = (columnId, pinning) => {
    props.onChange(Object.assign(Object.assign({}, layout), {
      PinnedColumnsMap: Object.assign(Object.assign({}, layout.PinnedColumnsMap), {
        [columnId]: pinning
      })
    }));
  };
  const handleColumnNameChange = (columnId, headerName) => {
    props.onChange(Object.assign(Object.assign({}, layout), {
      ColumnHeadersMap: Object.assign(Object.assign({}, layout.ColumnHeadersMap), {
        [columnId]: headerName
      })
    }));
  };
  const handleColumnsChange = columnIds => {
    var _a, _b;
    const oldColumns = (_a = layout.Columns.map(colId => adaptable.api.columnApi.getColumnWithColumnId(colId))) !== null && _a !== void 0 ? _a : [];
    const newColumns = (_b = columnIds.map(colId => adaptable.api.columnApi.getColumnWithColumnId(colId))) !== null && _b !== void 0 ? _b : [];
    if (!isValidOrderForColumnGroups({
      oldColumns,
      newColumns
    })) {
      return;
    }
    props.onChange(Object.assign(Object.assign({}, layout), {
      Columns: columnIds
    }));
  };
  const handleColumnWidthChange = (columnId, width) => {
    props.onChange(Object.assign(Object.assign({}, layout), {
      ColumnWidthMap: Object.assign(Object.assign({}, layout.ColumnWidthMap), {
        [columnId]: width
      })
    }));
  };
  return React.createElement(Tabs, {
    style: {
      height: '100%'
    }
  }, React.createElement(Tabs.Tab, null, "Columns"), React.createElement(Tabs.Content, null, React.createElement(Flex, {
    mt: 2,
    mb: 3
  }, React.createElement(ColumnLabels, {
    flexDirection: "row",
    showBoth: false,
    labels: {
      Sortable: 'Sortable',
      Filterable: 'Filterable',
      Aggregatable: 'Aggregatable',
      Groupable: 'Groupable',
      Moveable: 'Moveable',
      Pivotable: 'Pivotable'
    },
    sortable: true,
    filterable: true,
    moveable: true,
    pivotable: true,
    groupable: true,
    aggregatable: true
  })), React.createElement(ValueSelector, {
    showSelectedOnlyPosition: "top",
    showFilterInput: true,
    filter: columnFilter,
    toIdentifier: option => `${option.columnId}`,
    toLabel: option => {
      var _a;
      return (_a = option.friendlyName) !== null && _a !== void 0 ? _a : option.columnId;
    },
    toListLabel: option => React.createElement(ColumnRow, {
      onColumnNameChange: handleColumnNameChange,
      onColumnWidthChange: handleColumnWidthChange,
      onPinChange: handlePinChange,
      layout: layout,
      column: option
    }),
    options: sortedColumns,
    value: layout.Columns,
    allowReorder: true,
    xSelectedLabel: () => {
      return `Selected Columns:`;
    },
    onChange: handleColumnsChange
  })));
};