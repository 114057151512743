import * as React from 'react';
import { useGlobalEvent } from '../../components/utils/useGlobalEvent';
export const useKeyboardNavigation = (setCurrentSection, sections) => {
  const handleKeyPress = React.useCallback(event => {
    if ((event.ctrlKey || event.metaKey) && (event.code === 'ArrowUp' || event.code === 'ArrowDown')) {
      const isKeyUp = event.code === 'ArrowUp';
      const validIndexes = sections.reduce((acc, item, index) => {
        if (item !== '-') {
          acc.push(index);
        }
        return acc;
      }, []);
      setCurrentSection(currentActiveSection => {
        let nextActiveSection = null;
        const currentActiveInList = validIndexes.indexOf(currentActiveSection);
        if (isKeyUp) {
          const candidate = validIndexes[currentActiveInList - 1];
          nextActiveSection = typeof candidate === 'number' ? candidate : validIndexes[validIndexes.length - 1];
        } else {
          const candidate = validIndexes[currentActiveInList + 1];
          nextActiveSection = typeof candidate === 'number' ? candidate : validIndexes[0];
        }
        return nextActiveSection;
      });
    }
  }, [sections, setCurrentSection]);
  useGlobalEvent('keydown', handleKeyPress);
};