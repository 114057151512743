import * as React from 'react';
import { Box, Flex } from 'rebass';
import OverlayTrigger from '../../../components/OverlayTrigger';
import SimpleButton from '../../../components/SimpleButton';
import { useAdaptable } from '../../AdaptableContext';
import { ListBoxFilterForm } from '../FilterForm/ListBoxFilterForm';
export const PermitedValuesSelector = props => {
  var _a, _b, _c;
  const adaptable = useAdaptable();
  const columnId = props.columnId;
  const column = adaptable.api.columnApi.getColumnWithColumnId(columnId);
  const distinctValues = ((_a = adaptable.api.gridApi.internalApi.getDistinctDisplayValuesForColumn(columnId)) !== null && _a !== void 0 ? _a : []).map(value => {
    var _a;
    return {
      value: value,
      label: (value === null || value === void 0 ? void 0 : value.toString) ? (_a = value.toString) === null || _a === void 0 ? void 0 : _a.call(value) : value
    };
  });
  const predicateInputs = (_b = props.predicate.Inputs) !== null && _b !== void 0 ? _b : [];
  const isClearDisabled = ((_c = predicateInputs === null || predicateInputs === void 0 ? void 0 : predicateInputs.filter) === null || _c === void 0 ? void 0 : _c.call(predicateInputs, item => item !== undefined || item !== null || item !== '').length) === 0;
  const popupContent = React.createElement(Flex, {
    className: "ab-PermitedValuesSelector__PopupContent",
    padding: 1,
    flexDirection: "column"
  }, React.createElement(ListBoxFilterForm, {
    currentColumn: column,
    columns: [],
    columnDistinctValues: distinctValues,
    dataType: column.dataType,
    uiSelectedColumnValues: props.predicate.Inputs,
    useAgGridStyle: true,
    onColumnValueSelectedChange: list => props.onPredicateValuesChange(list)
  }));
  return React.createElement(Box, {
    className: "ab-PermitedValuesSelector",
    mt: 2
  }, React.createElement(Box, {
    flex: 1
  }, React.createElement(OverlayTrigger, {
    render: () => popupContent
  }, React.createElement(SimpleButton, {
    width: "100%",
    mb: 2
  }, (predicateInputs === null || predicateInputs === void 0 ? void 0 : predicateInputs.join(', ')) || 'No Selected values'))), React.createElement(SimpleButton, {
    disabled: isClearDisabled,
    onClick: () => props.onPredicateValuesChange([])
  }, "Clear Values"));
};