import * as React from 'react';
import { useMemo, useEffect, useState } from 'react';
import { Text } from 'rebass';
import { Loader } from '../../../components/Loader';
import { Tabs } from '../../../components/Tabs';
import join from '../../../components/utils/join';
import { runIfNotResolvedIn } from '../../../Utilities/runIfNotResolvedIn';
import { ValueOptionsTags, ValueSelector } from '../../Components/ValueSelector';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
import ArrayExtensions from '../../.././Utilities/Extensions/ArrayExtensions';
export const isValidCustomSortOrder = data => {
  if (!data.SortedValues || !data.SortedValues.length) {
    return 'You have to specify at least one value for Custom Sort order';
  }
  return true;
};
export const renderCustomSortValuesSummary = data => {
  return data.SortedValues.length ? React.createElement(Text, {
    fontSize: 2
  }, React.createElement(ValueOptionsTags, {
    options: data.SortedValues,
    value: data.SortedValues,
    toIdentifier: x => `${x}`,
    toLabel: x => `${x}`,
    allowWrap: true,
    readOnly: true
  })) : null;
};
export const CustomSortValuesWizardSection = props => {
  const {
    data,
    api
  } = useOnePageAdaptableWizardContext();
  const [distinctValues, setDistinctValues] = useState([]);
  const [isDistinctValuesLoading, setIsDistinctValuesLoading] = useState(false);
  useEffect(() => {
    let isMounted = true;
    (async () => {
      // for sync and very fast resolving promises does not make sense to show a loader
      setDistinctValues([]);
      const newValues = await runIfNotResolvedIn(api.gridApi.internalApi.getDistinctCustomSortDisplayValuesForColumn(data.ColumnId), () => isMounted && setIsDistinctValuesLoading(true));
      if (!isMounted) {
        return;
      }
      setIsDistinctValuesLoading(false);
      setDistinctValues(newValues);
    })();
    return () => {
      isMounted = false;
    };
  }, [data.ColumnId]);
  const sortedValuesMap = useMemo(() => {
    if (!data.SortedValues || !data.SortedValues.length) {
      return new Map();
    }
    return data.SortedValues.reduce((acc, val) => {
      acc.set(val, true);
      return acc;
    }, new Map());
  }, [data.SortedValues]);
  const allowReorder = React.useCallback(option => {
    return sortedValuesMap.has(option);
  }, [sortedValuesMap]);
  const options = useMemo(() => {
    return ArrayExtensions.sortArrayWithOrder(distinctValues, data.SortedValues, {
      sortUnorderedItems: false
    });
  }, [allowReorder, distinctValues, data.SortedValues]);
  const baseClassName = 'ab-CustomSortWizard__SortOrder';
  const className = join(baseClassName, isDistinctValuesLoading && `${baseClassName}--loading`);
  return React.createElement(Tabs, {
    style: {
      height: '100%'
    },
    className: className
  }, React.createElement(Tabs.Tab, null, "Sort Order"), React.createElement(Tabs.Content, null, isDistinctValuesLoading && React.createElement(Loader, null, "Loading"), React.createElement(ValueSelector, {
    toIdentifier: x => `${x}`,
    toLabel: x => `${x}`,
    options: options,
    value: data.SortedValues,
    allowReorder: allowReorder,
    xSelectedLabel: () => {
      return `Your Sort Order:`;
    },
    onChange: SortedValues => {
      props.onChange(Object.assign(Object.assign({}, data), {
        SortedValues
      }));
    }
  })));
};