import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import * as CustomSortRedux from '../../Redux/ActionsReducers/CustomSortRedux';
import { ApiBase } from './ApiBase';
import { CustomSortInternalApi } from '../Internal/CustomSortInternalApi';
export class CustomSortApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.internalApi = new CustomSortInternalApi(adaptable);
  }
  getCustomSortState() {
    return this.getAdaptableState().CustomSort;
  }
  getCustomSorts(config) {
    var _a;
    return (_a = this.handleLayoutAssociatedObjects(this.getCustomSortState().CustomSorts, 'CustomSort', config)) !== null && _a !== void 0 ? _a : [];
  }
  getCustomSortById(id) {
    return this.getCustomSorts().find(customSort => customSort.Uuid === id);
  }
  getActiveCustomSorts(config) {
    return this.getCustomSorts(config).filter(customSort => !customSort.IsSuspended);
  }
  getSuspendedCustomSorts(config) {
    return this.getCustomSorts(config).filter(customSort => customSort.IsSuspended);
  }
  getCustomSortByColumn(column) {
    return this.getCustomSorts().find(cs => cs.ColumnId == column);
  }
  getCustomSortForColumn(columnId) {
    return this.getCustomSorts().find(cs => cs.ColumnId == columnId);
  }
  addCustomSort(customSort) {
    this.addUidToAdaptableObject(customSort);
    this.dispatchAction(CustomSortRedux.CustomSortAdd(customSort));
    return this.getCustomSortById(customSort.Uuid);
  }
  createCustomSort(columnId, values) {
    let customSort = {
      ColumnId: columnId,
      SortedValues: values
    };
    return this.addCustomSort(customSort);
  }
  editCustomSort(columnId, values) {
    const previousCustomSort = this.getCustomSortForColumn(columnId);
    if (!previousCustomSort) {
      this.logWarn(`No custom sort defined for ${columnId}`);
      return;
    }
    const newCustomSort = Object.assign(Object.assign({}, previousCustomSort), {
      SortedValues: values
    });
    this.dispatchAction(CustomSortRedux.CustomSortEdit(newCustomSort));
    return this.getCustomSortByColumn(columnId);
  }
  deleteCustomSort(column) {
    let customSort = this.getCustomSortForColumn(column);
    this.dispatchAction(CustomSortRedux.CustomSortDelete(customSort));
  }
  suspendCustomSort(customSort) {
    this.dispatchAction(CustomSortRedux.CustomSortSuspend(customSort));
    return this.getCustomSortById(customSort.Uuid);
  }
  unSuspendCustomSort(customSort) {
    this.dispatchAction(CustomSortRedux.CustomSortUnSuspend(customSort));
    return this.getCustomSortById(customSort.Uuid);
  }
  openCustomSortSettingsPanel() {
    this.showModulePopup(ModuleConstants.CustomSortModuleId);
  }
}