import { __rest } from "tslib";
import * as React from 'react';
import * as ReactDOMServer from 'react-dom/server';
import ArrowRight from './arrow-right';
import ArrowDown from './arrow-down';
import ArrowUp from './arrow-up';
import ArrowUpLong from './arrow-up-long';
import ArrowDownLong from './arrow-down-long';
import ArrowLeft from './arrow-left';
import TriangleUp from './triangle-up';
import TriangleDown from './triangle-down';
import Check from './check';
import AttachFile from './attach-file';
import Plus from './plus';
import Person from './person';
import Clone from './clone';
import Chat from './chat';
import Query from './query';
import Info from './info';
import Alert from './alert';
import List from './list';
import Home from './home';
import Call from './call';
import Justify from './justify';
import Err from './error';
import Export from './export';
import FDC3 from './fdc3';
import Campaign from './campaign';
import Build from './build';
import Warning from './warning';
import Dashboard from './dashboard';
import Undo from './undo';
import FastForward from './fast-forward';
import FastBackward from './fast-backward';
import SortAsc from './sort-asc';
import SortDesc from './sort-desc';
import Calendar from './calendar';
import Delete from './delete';
import CSV from './csv';
import Excel from './excel';
import CheckCircle from './check-circle';
import Refresh from './refresh';
import Save from './save';
import Chart from './chart';
import Application from './application';
import BulkUpdate from './bulk-update';
import ImportExport from './import-export';
import Import from './import';
import CalculatedColumn from './calculated-column';
import CellSummary from './cell-summary';
import ColumnChooser from './column-chooser';
import ColumnAdd from './column-add';
import CellValidation from './cell-validation';
import ColumnFilter from './column-filter';
import ColumnOutline from './column-outline';
import SystemStatus from './system-status';
import DataSet from './data-set';
import DataObject from './data-object';
import FlashingCell from './flashing-cell';
import edit from './edit';
import Equation from './equation';
import FormatColumn from './format-column';
import ExportData from './table-arrow-right';
import FreetextColumn from './freetext-column';
import comment from './comment';
import Layout from './layout';
import StateManagement from './state-management';
import ToolPanel from './tool-panel';
import PercentBar from './percent-bar';
import Sparkline from './spark-line';
import Resume from './resume';
import PieChart from './pie-chart';
import PlusMinus from './plus-minus';
import QuickSearch from './quick-search';
import Reminder from './reminder';
import Schedule from './schedule';
import Shortcut from './shortcut';
import UpdatedRow from './updated-row';
import logout from './logout';
import Theme from './theme';
import checkBox from './check-box';
import checkBoxOutline from './check-box-outline';
import booleanList from './boolean-list';
import tabUnselected from './tab-unselected';
import CloudUpload from './cloud-upload';
import Upload from './upload';
import ipushpull from './ipushpull';
import FolderOpen from './folder-open';
import OpenInNew from './open-in-new';
import Folder from './folder';
import FolderShared from './folder-shared';
import Play from './play';
import Stop from './stop';
import Pause from './pause';
import NewPage from './newpage';
import Drag from './drag';
import HideColumn from './hide-column';
import ShowColumn from './show-column';
import DateRange from './date-range';
import Settings from './settings';
import Dock from './dock';
import Collapse from './collapse';
import Expand from './expand';
import ArrowExpand from './arrow-expand';
import History from './history';
import Filter from './filter';
import FilterOff from './filter-off';
import Close from './close';
import Function from './function';
import GreaterThanOrEqual from './greater-than-or-equal';
import GreaterThan from './greater-than';
import LessThanOrEqual from './less-than-or-equal';
import LessThan from './less-than';
import Minus from './minus';
import Multiplication from './multiplication';
import Division from './division';
import Percent from './percent';
import Exponent from './exponent';
import Equal from './equal';
import NotEqual from './not-equal';
import EndsWith from './ends-with';
import StartsWith from './starts-with';
import Blanks from './blanks';
import NonBlanks from './non-blanks';
import Contains from './contains';
import NotContains from './not-contains';
import Regex from './regex';
import TrackChanges from './track-changes';
import Menu from './menu';
import StatusBar from './statusbar';
import AddRow from './add-row';
import Brush from './brush';
import Gradient from './gradient';
import Badge from './badge';
import Italic from './italic';
import Bold from './bold';
import AlignLeft from './align-left';
import AlignRight from './align-right';
import AlignCenter from './align-center';
import Overline from './overline';
import Underline from './underline';
import Strikethrough from './strikethrough';
import Lowercase from './case-lower';
import Uppercase from './case-upper';
import SentenceCase from './case-sentence';
import GridFilter from './grid-filter';
import GridInfo from './grid-info';
import Rows from './rows';
import Quote from './quote';
import News from './news';
import Instrument from './instrument';
import Analysis from './analysis';
import Visibility from './visibility';
import VisibilityOff from './visibility-off';
import Mail from './mail';
import Dollar from './dollar';
import Organisation from './organisation';
import Science from './science';
import Interactions from './interactions';
import Money from './money';
import Order from './order';
import Contact from './contact';
import Note from './note';
import Comments from './comments';
import SelectAll from './select-all';
import SelectOff from './select-off';
import SelectFwd from './select-fwd';
import Copy from './copy';
import ExpandAll from './expand-all';
import CollapseAll from './collapse-all';
import Sync from './sync';
import { IconComponent } from '../Icon';
import { AdaptableLogger } from '../../agGrid/AdaptableLogger';
export const allIcons = {
  search: Query,
  grid: Layout,
  cells: CellSummary,
  columns: ColumnChooser,
  copy: Copy,
  'calculated-column': CalculatedColumn,
  laptop: Application,
  alert: Alert,
  building: Analysis,
  'edit-table': BulkUpdate,
  chart: Chart,
  'column-add': ColumnAdd,
  person: Person,
  quote: Quote,
  call: Call,
  news: News,
  brush: Brush,
  'data-set': DataSet,
  excel: Excel,
  json: DataObject,
  export: Export,
  broadcast: Campaign,
  theme: Theme,
  brain: Instrument,
  calendar: Calendar,
  chat: Chat,
  'filter-list': ColumnFilter,
  'column-outline': ColumnOutline,
  'grid-filter': GridFilter,
  'grid-info': GridInfo,
  filter: Filter,
  'filter-off': FilterOff,
  'search-table': QuickSearch,
  lightning: FlashingCell,
  'color-palette': FormatColumn,
  'export-data': ExportData,
  'horizontal-lines': FreetextColumn,
  'percent-tag': PercentBar,
  'spark-line': Sparkline,
  resume: Resume,
  csv: CSV,
  'pie-chart': PieChart,
  'add-circle': PlusMinus,
  reminder: Reminder,
  schedule: Schedule,
  link: Shortcut,
  assignment: StateManagement,
  clipboard: ToolPanel,
  'traffic-lights': SystemStatus,
  add: AddRow,
  downloaded: UpdatedRow,
  // others
  edit: edit,
  undo: Undo,
  clone: Clone,
  save: Save,
  refresh: Refresh,
  delete: Delete,
  'tab-unselected': tabUnselected,
  'select-all': SelectAll,
  'select-off': SelectOff,
  'select-fwd': SelectFwd,
  checked: checkBox,
  unchecked: checkBoxOutline,
  'boolean-list': booleanList,
  'arrow-right': ArrowRight,
  'arrow-left': ArrowLeft,
  'arrow-up': ArrowUp,
  'arrow-down': ArrowDown,
  'arrow-up-long': ArrowUpLong,
  'arrow-down-long': ArrowDownLong,
  'triangle-up': TriangleUp,
  'triangle-down': TriangleDown,
  'check-circle': CheckCircle,
  comment,
  list: List,
  spanner: Build,
  error: Err,
  play: Play,
  stop: Stop,
  pause: Pause,
  'fast-forward': FastForward,
  'fast-backward': FastBackward,
  warning: Warning,
  check: Check,
  'open-in-new': OpenInNew,
  home: Home,
  'sort-asc': SortAsc,
  'sort-desc': SortDesc,
  logout: logout,
  'import-export': ImportExport,
  import: Import,
  paperclip: AttachFile,
  info: Info,
  'cloud-upload': CloudUpload,
  upload: Upload,
  download: ipushpull,
  'folder-open': FolderOpen,
  'folder-shared': FolderShared,
  folder: Folder,
  newpage: NewPage,
  drag: Drag,
  'date-range': DateRange,
  settings: Settings,
  dock: Dock,
  collapse: Collapse,
  expand: Expand,
  'arrow-expand': ArrowExpand,
  history: History,
  close: Close,
  function: Function,
  equation: Equation,
  equals: Equal,
  'not-equal': NotEqual,
  'greater-than-or-equal': GreaterThanOrEqual,
  'greater-than': GreaterThan,
  'less-than-or-equal': LessThanOrEqual,
  'less-than': LessThan,
  plus: Plus,
  minus: Minus,
  multiplication: Multiplication,
  division: Division,
  percent: Percent,
  exponent: Exponent,
  'ends-with': EndsWith,
  'starts-with': StartsWith,
  'unfilled-circle': Blanks,
  'filled-circle': NonBlanks,
  'not-contains': NotContains,
  contains: Contains,
  regex: Regex,
  target: TrackChanges,
  menu: Menu,
  statusbar: StatusBar,
  dashboard: Dashboard,
  'visibility-on': Visibility,
  'visibility-off': VisibilityOff,
  'visibility-off-bold': HideColumn,
  'visibility-on-bold': ShowColumn,
  gradient: Gradient,
  badge: Badge,
  bold: Bold,
  italic: Italic,
  'align-left': AlignLeft,
  'align-right': AlignRight,
  'align-center': AlignCenter,
  'align-justify': Justify,
  underline: Underline,
  overline: Overline,
  strikethrough: Strikethrough,
  'case-lower': Lowercase,
  'case-upper': Uppercase,
  'case-sentence': SentenceCase,
  flag: CellValidation,
  fdc3: FDC3,
  mail: Mail,
  dollar: Dollar,
  organisation: Organisation,
  science: Science,
  interactions: Interactions,
  money: Money,
  order: Order,
  contact: Contact,
  note: Note,
  comments: Comments,
  rows: Rows,
  'expand-all': ExpandAll,
  'collapse-all': CollapseAll,
  sync: Sync
};
Object.keys(allIcons).forEach(name => {
  const ReactCmp = allIcons[name];
  allIcons[name] = props => React.createElement(ReactCmp, Object.assign({}, props, {
    name: name
  }));
});
export const Icon = _a => {
  var {
      name,
      style,
      className,
      tabIndex
    } = _a,
    props = __rest(_a, ["name", "style", "className", "tabIndex"]);
  const IconCmp = allIcons[name] || null;
  if (!IconCmp) {
    AdaptableLogger.consoleWarnBase('NO icon found for ' + name);
    return React.createElement("svg", {
      height: "17",
      width: "17"
    });
  }
  return React.createElement(IconCmp, Object.assign({
    tabIndex: tabIndex
  }, props, {
    style: style,
    name: name,
    className: className
  }));
};
export const iconToString = (icon, style) => {
  if (!icon) {
    return;
  }
  return ReactDOMServer.renderToString(React.createElement(IconComponent, {
    icon: Object.assign(Object.assign({}, icon), {
      style: Object.assign(Object.assign({}, icon.style), style)
    })
  }));
};