import { SystemLicenseDisablePersistence as e, SystemLicenseShowWatermark as a } from "../../../Redux/ActionsReducers/SystemRedux";
import { PopupShowAlert as t } from "../../../Redux/ActionsReducers/PopupRedux";
import i from "../../ObjectFactory";
import s from "lodash/clamp";
import { LicenseDocsLink as o } from "../../Constants/DocumentationLinkConstants";
import { decode as n, GENERIC_APP_NAME as r } from "../../license/decode";
import { shouldLogThankYouMessage as l } from "./shouldLogThankYouMessage";
const c = "sales@adaptabletools.com",
  d = 10,
  p = 864e5;
export var LicenseValidityType;
!function (e) {
  e.INVALID_LICENSE = "INVALID_LICENSE", e.NO_LICENSE = "NO_LICENSE", e.NON_PRODUCTION_VALID = "NON_PRODUCTION_VALID", e.NON_PRODUCTION_EXPIRED_IN_SCOPE = "NON_PRODUCTION_EXPIRED_IN_SCOPE", e.NON_PRODUCTION_EXPIRED_OUT_OF_SCOPE = "NON_PRODUCTION_EXPIRED_OUT_OF_SCOPE", e.COMMERCIAL_VALID = "COMMERCIAL_VALID", e.COMMERCIAL_EXPIRED_IN_SCOPE = "COMMERCIAL_EXPIRED_IN_SCOPE", e.COMMERCIAL_EXPIRED_OUT_OF_SCOPE = "COMMERCIAL_EXPIRED_OUT_OF_SCOPE";
}(LicenseValidityType || (LicenseValidityType = {}));
const h = () => "undefined" != typeof window ? window.location.origin : "",
  E = () => {
    const e = h(),
      [a, t, i] = Array.from(/(https):\/\/\d+\-\d+\-\d+\-(sandpack\.codesandbox\.io)/g.exec(e) || []);
    return "https" === t && "sandpack.codesandbox.io" === i;
  },
  I = () => {
    const e = h(),
      [a, t, i] = Array.from(/(https):\/\/\S+(\.csb\.app)/g.exec(e) || []);
    return "https" === t && ".csb.app" === i;
  },
  _ = () => {
    const e = h(),
      [a, t, i] = Array.from(/(https):\/\/\S+(\.adaptabletools\.com)/g.exec(e) || []);
    return "https" === t && ".adaptabletools.com" === i;
  };
export class LicenseService {
  constructor(e, a, t) {
    this.adaptable = e, this.licenseKey = a, this.packageDetails = t, this.adaptable = e, this.adaptable.api.eventApi.on("AdaptableReady", () => {
      requestAnimationFrame(() => {
        this.init();
      });
    });
  }
  init() {
    let e = null;
    if (this.licenseKey) try {
      e = n(this.licenseKey);
    } catch (a) {
      e = a;
    }
    E() || I() || _() || this.handleLicenseValidation(e, this.getValidityType(e, this.packageDetails));
  }
  getValidityType(e, a) {
    if (!e) return LicenseValidityType.NO_LICENSE;
    if (e instanceof Error) return LicenseValidityType.INVALID_LICENSE;
    const t = new Date(a.publishedAt),
      i = new Date(e.end),
      s = i < new Date(),
      o = e.trial;
    let n = null;
    return n = s ? i > t ? o ? LicenseValidityType.NON_PRODUCTION_EXPIRED_IN_SCOPE : LicenseValidityType.COMMERCIAL_EXPIRED_IN_SCOPE : o ? LicenseValidityType.NON_PRODUCTION_EXPIRED_OUT_OF_SCOPE : LicenseValidityType.COMMERCIAL_EXPIRED_OUT_OF_SCOPE : o ? LicenseValidityType.NON_PRODUCTION_VALID : LicenseValidityType.COMMERCIAL_VALID, n;
  }
  handleLicenseValidation(e, a) {
    var t;
    const i = new Date();
    i.setHours(0, 0, 0, 0);
    let n = 0;
    e instanceof Error || !(null == e ? void 0 : e.end) || (n = Math.floor(((null === (t = null == e ? void 0 : e.end) || void 0 === t ? void 0 : t.getTime()) - i.getTime()) / p), n = s(n, 0, 1 / 0));
    let h = "",
      E = "";
    !e || e instanceof Error || !e.appName || e.appName == r || (h = e.appName, E = " for application [APP_NAME]");
    const I = (e, a = o, t = c, i = n, s = h) => e.replace("[LINK]", a).replace("[EMAIL]", t).replace("[APP_NAME]", s).replace("[DAYS]", `${i}`);
    switch (a) {
      case "NO_LICENSE":
      case "NON_PRODUCTION_EXPIRED_OUT_OF_SCOPE":
        this.adaptable.logger.consoleLogByMessageType(I("This instance of AdapTable does not have a license, and some functionality has therefore been removed. In order to use a fully-featured version of AdapTable, please contact [EMAIL]. You can learn more about the different AdapTable license options at [LINK]."), "Error"), this.showNotification("No AdapTable License found."), this.showWatermark("This instance of AdapTable does not have a license, and some functionality has therefore been removed."), this.disableStatePersistence();
        break;
      case "INVALID_LICENSE":
        this.adaptable.logger.consoleLogByMessageType(I("This instance of AdapTable seems to use a corrupted License, and some functionality has therefore been removed. In order to use a fully-featured version of AdapTable, please contact [EMAIL]. You can learn more about the different AdapTable license options at [LINK]."), "Error"), this.showNotification("Corrupted AdapTable License found."), this.showWatermark("This instance of AdapTable has a corrupted License, and some functionality has therefore been removed."), this.disableStatePersistence();
        break;
      case "NON_PRODUCTION_VALID":
        this.adaptable.logger.consoleLogByMessageType(I("This AdapTable trial license expires in [DAYS] days. Please contact [EMAIL] to upgrade to a commercial version of AdapTable. You can learn more about the different AdapTable license options at [LINK]."), "Info");
        break;
      case "NON_PRODUCTION_EXPIRED_IN_SCOPE":
        this.adaptable.logger.consoleLogByMessageType(I("This AdapTable trial license has now expired. Please contact [EMAIL] to upgrade to a commercial version of AdapTable. You can learn more about the different AdapTable license options at [LINK]."), "Warning"), this.showWatermark("AdapTable License has expired");
        break;
      case "COMMERCIAL_VALID":
        if (n <= d) this.adaptable.logger.consoleLogByMessageType(I(`This AdapTable license${E} expires in [DAYS] days.  Please contact [EMAIL] to renew (giving you access to Support and Updates)`), "Info");else try {
          l() && this.adaptable.logger.consoleLogByMessageType(I(`Thank you for using a valid AdapTable license${E}. Your license will expire in [DAYS] days.`), "Info");
        } catch (e) {}
        break;
      case "COMMERCIAL_EXPIRED_IN_SCOPE":
        this.adaptable.logger.consoleLogByMessageType(I(`This AdapTable license${E} has expired.  Please contact [EMAIL] if you wish to renew (giving you access to Support and Updates)`), "Warning");
        break;
      case "COMMERCIAL_EXPIRED_OUT_OF_SCOPE":
        this.adaptable.logger.consoleLogByMessageType(I(`This AdapTable license${E} has expired. Adaptable version was published after the license expired. Please contact [EMAIL] if you wish to renew your license.`), "Error"), this.showNotification("Adaptable License has expired"), this.showWatermark("Adaptable License has expired");
    }
  }
  showNotification(e) {
    this.adaptable.api.internalApi.dispatchReduxAction(t({
      alertType: "generic",
      header: "License Error",
      message: e,
      alertDefinition: i.CreateInternalAlertDefinitionForMessages("Error")
    }));
  }
  showWatermark(e) {
    this.adaptable.api.internalApi.dispatchReduxAction(a(e));
  }
  disableStatePersistence() {
    this.adaptable.api.internalApi.dispatchReduxAction(e());
  }
  destroy() {}
}