import { ApiBase } from './ApiBase';
import * as PopupRedux from '../../Redux/ActionsReducers/PopupRedux';
export class SettingsPanelApiImpl extends ApiBase {
  openSettingsPanel(moduleName) {
    const moduleInfo = this.adaptable.ModuleService.getModuleInfoByModule(moduleName);
    if (!moduleInfo) {
      this.logError(`${moduleName} module does not exist`);
      return;
    }
    this.dispatchAction(PopupRedux.PopupShowScreen(moduleName, moduleInfo.Popup));
  }
  openCustomSettingsPanel(name) {
    this.dispatchAction(PopupRedux.PopupShowScreen(null, name));
  }
}