import * as React from 'react';
import { Box } from 'rebass';
import { Tabs } from '../../../../components/Tabs';
import FormLayout, { FormRow } from '../../../../components/FormLayout';
import DropdownButton from '../../../../components/DropdownButton';
export const ScheduleSettingsOpenFin = props => {
  var _a, _b;
  const reportOptions = props.allReports.map(report => ({
    label: report.Name,
    value: report.Name,
    onClick: () => props.onChange(Object.assign(Object.assign({}, props.openFin), {
      OpenFinReport: Object.assign(Object.assign({}, props.openFin.OpenFinReport), {
        ReportName: report.Name
      })
    }))
  }));
  return React.createElement(Box, {
    "data-name": "schedule-settings-openfin"
  }, React.createElement(Tabs, {
    autoFocus: false
  }, React.createElement(Tabs.Tab, null, "OpenFin Schedule Settings"), React.createElement(Tabs.Content, null, React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Select Report"
  }, React.createElement(DropdownButton, {
    "data-name": "select-report",
    columns: ['label'],
    style: {
      width: 300
    },
    disabled: props.allReports.length == 0,
    items: reportOptions
  }, ((_b = (_a = props === null || props === void 0 ? void 0 : props.openFin) === null || _a === void 0 ? void 0 : _a.OpenFinReport) === null || _b === void 0 ? void 0 : _b.ReportName) || 'Select Report'))))));
};