import * as React from 'react';
import { useSelector } from 'react-redux';
import { ALL_MODULES } from '../../PredefinedConfig/Common/Types';
import { useAdaptable } from '../AdaptableContext';
import { AdaptableViewPanelFactory } from '../AdaptableViewFactory';
import { CustomToolbarCmp } from './CustomToolbar';
const SystemDashboardToolbarFactory = props => {
  const adaptable = useAdaptable();
  const moduleInfo = adaptable.api.internalApi.getModuleService().getModuleInfoByModule(props.toolbarName);
  const accessLevel = adaptable.api.entitlementApi.getEntitlementAccessLevelForModule(props.toolbarName);
  const moduleToolbarView = AdaptableViewPanelFactory.get(props.toolbarName);
  if (!moduleToolbarView) {
    return React.createElement(React.Fragment, null, "adasdasdas");
  }
  const moduleToolbarElement = React.createElement(moduleToolbarView, {
    viewType: 'Toolbar',
    api: adaptable.api,
    accessLevel,
    moduleInfo
  });
  return moduleToolbarElement;
};
const CustomDashboardToolbarFactory = props => {
  const revision = useSelector(state => state.System.DashboardRevision);
  const adaptable = useAdaptable();
  const customToolbars = adaptable.api.dashboardApi.getCustomToolbars();
  const customToolbar = customToolbars && customToolbars.find(ct => ct.name == props.toolbarName);
  if (customToolbar) {
    return React.createElement(CustomToolbarCmp, {
      customToolbar: customToolbar,
      dashboardRevision: revision
    });
  }
  return React.createElement(React.Fragment, null);
};
export const DashboardToolbarFactory = props => {
  if (ALL_MODULES.includes(props.toolbarName)) {
    return React.createElement(SystemDashboardToolbarFactory, {
      toolbarName: props.toolbarName
    });
  } else {
    return React.createElement(CustomDashboardToolbarFactory, {
      toolbarName: props.toolbarName
    });
  }
};