import { __rest } from "tslib";
import * as React from 'react';
import join from '../../utils/join';
import { Box } from 'rebass';
const baseClassName = 'ab-ListGroup';
const ListGroup = props => {
  const {
      className
    } = props,
    domProps = __rest(props, ["className"]);
  return React.createElement(Box, Object.assign({}, domProps, {
    className: join(className, baseClassName)
  }));
};
export default ListGroup;