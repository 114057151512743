import * as React from 'react';
import SimpleButton from '../../../components/SimpleButton';
export class ButtonExport extends React.Component {
  render() {
    return React.createElement(SimpleButton, Object.assign({
      "data-name": "export",
      tooltip: "Export",
      iconSize: 20,
      icon: "export-data",
      variant: "text"
    }, this.props));
  }
}