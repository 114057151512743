import { AdaptableModuleBase } from './AdaptableModuleBase';
import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
import { ShowChartButton } from '../View/Charting/ShowChartButton';
import { ChartingWizard } from '../View/Charting/ChartingWizard';
import * as ChartingRedux from '../Redux/ActionsReducers/ChartingRedux';
import { ChartingStatusBarPopover } from '../View/Charting/ChartingStatusBarPopover';
import { getObjectTagsViewItems } from './Utilities/getObjectTagsViewItems';
import { isAgChartDefinition, isExternalChartDefinition } from '../PredefinedConfig/ChartingState';
import Helper from '../Utilities/Helpers/Helper';
export class ChartingModule extends AdaptableModuleBase {
  constructor(api) {
    super(ModuleConstants.ChartingModuleId, ModuleConstants.ChartingFriendlyName, 'chart', 'ChartPopup', 'Create AG Grid Charts in order to see Adaptable Data visually', api);
  }
  isModuleAvailable() {
    const agChartsAvailable = this.api.chartingApi.isChartingEnabled() && super.isModuleAvailable();
    const externalChartsAvailable = Helper.objectHasKeys(this.api.optionsApi.getChartingOptions().externalChartingOptions);
    return Boolean(agChartsAvailable || externalChartsAvailable);
  }
  getModuleAdaptableObjects() {
    return [...this.api.chartingApi.getChartDefinitions(), ...this.api.chartingApi.getExternalChartDefinitions()];
  }
  toViewAll() {
    return this.getModuleAdaptableObjects().map(chartDef => this.toView(chartDef));
  }
  toView(chart) {
    if (isExternalChartDefinition(chart)) {
      return this.toViewExternalChartDefinition(chart);
    } else {
      return this.toViewChartDefinition(chart);
    }
  }
  toViewExternalChartDefinition(chart) {
    return {
      items: [{
        name: 'Name',
        values: [chart.Name]
      }],
      abObject: chart
    };
  }
  toViewChartDefinition(chart) {
    var _a;
    return {
      items: [{
        name: 'Name',
        values: [chart.Name]
      }, {
        name: 'About',
        values: [`Chart Type: ${chart.Model.chartType}`, chart.Model.aggFunc && `Aggregation Function: ${chart.Model.aggFunc}`].filter(Boolean)
      }, {
        name: 'Settings',
        values: [`Unlink from data: ${chart.Model.unlinkChart ? 'Yes' : 'No'}`, `Suppress Chart Ranges: ${chart.Model.suppressChartRanges ? 'Yes' : 'No'}`]
      }, {
        name: 'Range',
        values: [`Row start index: ${chart.Model.cellRange.rowStartIndex}`, `Row end index: ${chart.Model.cellRange.rowEndIndex}`, chart.Model.cellRange.columnStart && `Column start: ${typeof chart.Model.cellRange.columnStart === 'string' ? this.api.columnApi.getFriendlyNameForColumnId(chart.Model.cellRange.columnStart) : this.api.columnApi.getFriendlyNameForColumnId(chart.Model.cellRange.columnStart.getColId())}`, chart.Model.cellRange.columnEnd && `Column start: ${typeof chart.Model.cellRange.columnEnd === 'string' ? this.api.columnApi.getFriendlyNameForColumnId(chart.Model.cellRange.columnEnd) : this.api.columnApi.getFriendlyNameForColumnId(chart.Model.cellRange.columnEnd.getColId())}`, ((_a = chart.Model.cellRange.columns) === null || _a === void 0 ? void 0 : _a.length) && `Columns ${chart.Model.cellRange.columns.map(column => typeof column === 'string' ? this.api.columnApi.getFriendlyNameForColumnId(column) : this.api.columnApi.getFriendlyNameForColumnId(column.getColId())).join(', ')}`].filter(Boolean)
      }, getObjectTagsViewItems(chart, this.api)].filter(Boolean),
      abObject: chart
    };
  }
  getViewProperties() {
    return {
      actions: [ShowChartButton],
      getEditWizard: () => ChartingWizard,
      // not sure if charts should be created via adaptable wizard
      hideNewButton: true,
      getDeleteAction: chartDefinition => {
        return isAgChartDefinition(chartDefinition) ? ChartingRedux.ChartingDeleteChart(chartDefinition) : ChartingRedux.ChartingDeleteExternalChart(chartDefinition);
      },
      emptyView: 'To create a chart select a few cells and right click. Then select "Chart Range" from the context menu.',
      getStatusBarPanelProps: () => {
        const content = ChartingStatusBarPopover;
        return {
          content,
          triggerActionOnWrapperClick: false
        };
      }
    };
  }
}