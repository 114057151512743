const getAvailableSizeInfo = ({
  targetRect,
  constrainRect,
  maxSizeOffset
}) => {
  let maxHeight;
  let maxWidth;
  const topAvailableSpace = Math.round(targetRect.top - constrainRect.top);
  const leftAvailableSpace = Math.round(targetRect.left - constrainRect.left);
  const bottomAvailableSpace = Math.round(constrainRect.bottom - targetRect.bottom);
  const rightAvailableSpace = Math.round(constrainRect.right - targetRect.right);
  let horizontalPosition = 'right';
  let verticalPosition = 'bottom';
  if (leftAvailableSpace > rightAvailableSpace) {
    horizontalPosition = 'left';
    maxWidth = Math.round(targetRect.left - constrainRect.left);
  } else {
    maxWidth = rightAvailableSpace;
  }
  if (topAvailableSpace > bottomAvailableSpace) {
    verticalPosition = 'top';
    maxHeight = Math.round(targetRect.top - constrainRect.top);
  } else {
    maxHeight = bottomAvailableSpace;
  }
  if (maxSizeOffset != null) {
    maxWidth -= maxSizeOffset;
    maxHeight -= maxSizeOffset;
  }
  return {
    horizontalPosition,
    verticalPosition,
    maxWidth,
    maxHeight
  };
};
export default getAvailableSizeInfo;