import { __rest } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import { Box, Flex } from 'rebass';
import useProperty from '../utils/useProperty';
const RadioContext = React.createContext({
  value: null,
  name: '',
  onChange: v => {}
});
export const useRadioContext = () => {
  return React.useContext(RadioContext);
};
const Radio = _a => {
  var {
      children,
      checked,
      onChange,
      value,
      name,
      gapDistance = 10,
      childrenPosition = 'end',
      as = 'label',
      id,
      tabIndex,
      disabled
    } = _a,
    props = __rest(_a, ["children", "checked", "onChange", "value", "name", "gapDistance", "childrenPosition", "as", "id", "tabIndex", "disabled"]);
  const context = useRadioContext();
  const {
    value: contextValue,
    onChange: contextOnChange,
    name: contextName
  } = context;
  const [stateChecked, setStateChecked] = useState(false);
  const computedChecked = checked !== undefined ? checked : contextValue !== undefined ? contextValue === value : stateChecked;
  const onInputChange = event => {
    const newChecked = event.target.checked;
    if (checked === undefined) {
      setStateChecked(newChecked);
    }
    if (onChange) {
      onChange(newChecked, event);
    }
    if (newChecked) {
      contextOnChange(value);
    }
  };
  const gap = React.createElement("div", {
    style: {
      marginLeft: gapDistance,
      display: 'inline-block'
    }
  });
  const before = childrenPosition === 'start' ? children : null;
  const beforeGap = childrenPosition === 'start' ? gap : null;
  const after = childrenPosition === 'end' ? children : null;
  const afterGap = childrenPosition === 'end' ? gap : null;
  return React.createElement(Box, Object.assign({
    className: "ab-Radio",
    my: 2
  }, props, {
    style: Object.assign({
      display: 'inline-flex',
      flexFlow: 'row',
      alignItems: 'center',
      cursor: 'pointer',
      position: 'relative'
    }, props.style),
    as: as
  }), before, beforeGap, React.createElement("input", {
    disabled: disabled,
    className: "ab-Radio-input",
    id: id,
    checked: computedChecked,
    type: "radio",
    name: name !== null && name !== void 0 ? name : contextName,
    value: value,
    tabIndex: tabIndex,
    style: {
      verticalAlign: 'middle',
      borderRadius: '50%',
      cursor: 'pointer',
      position: 'relative'
    },
    onChange: onInputChange
  }), afterGap, after);
};
export const RadioGroup = props => {
  const {
      orientation,
      value: _value,
      name,
      onRadioChange
    } = props,
    flexProps = __rest(props, ["orientation", "value", "name", "onRadioChange"]);
  const [value, setValue] = useProperty(props, 'value', undefined, {
    onChange: value => {
      onRadioChange(value);
    }
  });
  return React.createElement(RadioContext.Provider, {
    value: {
      value,
      onChange: setValue,
      name
    }
  }, React.createElement(Flex, Object.assign({
    className: "ab-RadioGroup",
    flexDirection: orientation == 'horizontal' ? 'row' : 'column',
    alignItems: orientation == 'horizontal' ? 'center' : 'flex=start'
  }, flexProps)));
};
export default Radio;