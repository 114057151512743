import * as React from 'react';
import { StringExtensions } from '../../../Utilities/Extensions/StringExtensions';
import ListGroupItem from '../../../components/List/ListGroupItem';
import { Box } from 'rebass';
import { CheckBox } from '../../../components/CheckBox';
import join from '../../../components/utils/join';
import { AdaptableFormControlTextClear } from '../Forms/AdaptableFormControlTextClear';
import GridList from '../../../components/List/GridList';
import { useState, useEffect } from 'react';
import { Loader } from '../../../components/Loader';
import isDatesEqual from 'date-fns/isEqual';
import { parseDateValue } from '../../../Utilities/Helpers/DateHelper';
export const ListBoxFilterForm = props => {
  const [FilterValue, setFilterValue] = useState('');
  const handleFilterValueChange = React.useCallback(newValue => {
    setFilterValue(newValue);
    props === null || props === void 0 ? void 0 : props.onFilterChange(newValue);
  }, [props.onFilterChange]);
  const [UiSelectedColumnValues, internalUpdateUISelectedColumnValues] = useState(props.uiSelectedColumnValues);
  const setUiSelectedColumnValues = values => {
    internalUpdateUISelectedColumnValues(values);
    props.onColumnValueSelectedChange(values);
  };
  useEffect(() => {
    internalUpdateUISelectedColumnValues(props.uiSelectedColumnValues);
  }, [props.uiSelectedColumnValues]);
  const renderItemForAgGridStyle = props => {
    return React.createElement(CheckBox, Object.assign({}, props, {
      variant: "agGrid",
      fontSize: 2,
      marginTop: 1,
      marginBottom: 1,
      marginLeft: 1,
      marginRight: 1,
      title: typeof props.children === 'string' ? props.children : null
    }));
  };
  const onClickItemColumnValue = item => {
    let index = UiSelectedColumnValues.indexOf(item);
    if (item instanceof Date) {
      index = UiSelectedColumnValues.findIndex(selectedValue => isDatesEqual(parseDateValue(selectedValue), parseDateValue(item)));
    }
    if (index >= 0) {
      const newArray = [...UiSelectedColumnValues];
      newArray.splice(index, 1);
      setUiSelectedColumnValues(newArray);
    } else {
      setUiSelectedColumnValues([...UiSelectedColumnValues, item]);
    }
  };
  const permittedValuesOptions = props.columnDistinctValues;
  const columnValuesItemsElements = permittedValuesOptions.map((distinctValue, index) => {
    let isActive = UiSelectedColumnValues.indexOf(distinctValue.value) >= 0;
    // special case for date objects, need to check against string values
    if (!isActive && distinctValue.value && distinctValue.value instanceof Date) {
      isActive = UiSelectedColumnValues.some(dateStr => isDatesEqual(parseDateValue(dateStr), parseDateValue(distinctValue.value)));
    }
    const columnLabel = distinctValue.label;
    if (StringExtensions.IsNullOrEmpty(columnLabel)) {
      return null;
    }
    const noMatch = StringExtensions.IsNullOrEmpty(FilterValue.toLocaleLowerCase()) || StringExtensions.IsNullOrEmpty(columnLabel === null || columnLabel === void 0 ? void 0 : columnLabel.toLocaleLowerCase()) || columnLabel.toLocaleLowerCase().indexOf(FilterValue.toLocaleLowerCase()) < 0;
    if (!props.suppressClientSideFilter && StringExtensions.IsNotNullOrEmpty(FilterValue) && noMatch) {
      return null;
    }
    if (props.useAgGridStyle) {
      return renderItemForAgGridStyle({
        key: 'columnValue' + index,
        children: distinctValue.label,
        checked: isActive,
        onChange: () => onClickItemColumnValue(distinctValue.value),
        disabled: props.disabled,
        style: {
          width: '100%'
        }
      });
    }
    return React.createElement(ListGroupItem, {
      noZebra: props.useAgGridStyle,
      key: 'columnValue' + index,
      onClick: () => onClickItemColumnValue(distinctValue.value),
      active: isActive,
      value: distinctValue.value
    }, distinctValue.label);
  });
  const textClear = React.createElement(AdaptableFormControlTextClear, {
    autoFocus: true,
    type: "text",
    placeholder: "Search Column Values",
    value: FilterValue,
    style: {
      paddingTop: 'var(--ab-space-1)',
      paddingBottom: 'var(--ab-space-1)'
    },
    inputStyle: {
      padding: 'var(--ab-space-1)'
    },
    OnTextChange: handleFilterValueChange
  });
  const baseClassName = 'ab-ListBoxFilterForm';
  return React.createElement("div", {
    className: join(baseClassName, props.useAgGridStyle ? `${baseClassName}--aggrid-style` : null, props.isLoading && `${baseClassName}--loading`)
  }, React.createElement(Box, {
    px: 2
  }, React.createElement(Box, {
    mx: props.useAgGridStyle ? 0 : '2px',
    marginBottom: 2
  }, textClear, props.isLoading && React.createElement(Loader, null, "Loading"))), !props.isLoading && React.createElement(GridList, {
    showZebraRows: false,
    className: !props.useAgGridStyle ? 'ab-padding-horizontal-0' : ''
  }, columnValuesItemsElements));
};