import * as React from 'react';
import { Box, Flex, Text } from 'rebass';
import FormLayout, { FormRow } from '../../../components/FormLayout';
import { CheckBox } from '../../../components/CheckBox';
import { Tabs } from '../../../components/Tabs';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
import { Tag } from '../../../components/Tag';
import { ToggleGroup } from '../../../components/Toggle/ToggleGroup';
import { Toggle } from '../../../components/Toggle/Toggle';
export const renderFormatColumnSettingsSummary = data => {
  var _a, _b, _c, _d;
  return React.createElement(Box, {
    padding: 2
  }, React.createElement(Text, null, "Cell alignment ", React.createElement(Tag, null, (_a = data.CellAlignment) !== null && _a !== void 0 ? _a : 'default')), React.createElement(Text, {
    mt: 3
  }, "Data Rows ", React.createElement(Tag, null, ((_b = data.RowScope) === null || _b === void 0 ? void 0 : _b.ExcludeDataRows) ? 'no' : 'yes')), ' ', React.createElement(Text, {
    mt: 3
  }, "Group Rows ", React.createElement(Tag, null, ((_c = data.RowScope) === null || _c === void 0 ? void 0 : _c.ExcludeGroupRows) ? 'no' : 'yes')), React.createElement(Text, {
    mt: 3
  }, "Summary Rows ", React.createElement(Tag, null, ((_d = data.RowScope) === null || _d === void 0 ? void 0 : _d.ExcludeSummaryRows) ? 'no' : 'yes')));
};
export const FormatColumnSettingsWizardSection = props => {
  var _a, _b, _c;
  const {
    data
  } = useOnePageAdaptableWizardContext();
  const onCellAlignmentSelectChanged = CellAlignment => {
    const newData = Object.assign(Object.assign({}, data), {
      CellAlignment
    });
    if (CellAlignment === null) {
      delete newData.CellAlignment;
    }
    props.onChange(newData);
  };
  const onExcludeDataRowsChanged = ExcludeDataRows => {
    props.onChange(Object.assign(Object.assign({}, data), {
      RowScope: Object.assign(Object.assign({}, data.RowScope), {
        ExcludeDataRows
      })
    }));
  };
  const onExcludeGroupedRowsChanged = ExcludeGroupedRows => {
    props.onChange(Object.assign(Object.assign({}, data), {
      RowScope: Object.assign(Object.assign({}, data.RowScope), {
        ExcludeGroupRows: ExcludeGroupedRows
      })
    }));
  };
  const onExcludeSummaryRowsChanged = ExcludeSummaryRows => {
    props.onChange(Object.assign(Object.assign({}, data), {
      RowScope: Object.assign(Object.assign({}, data.RowScope), {
        ExcludeSummaryRows
      })
    }));
  };
  return React.createElement(Tabs, null, React.createElement(Tabs.Tab, null, "Settings"), React.createElement(Tabs.Content, null, React.createElement(Flex, {
    flexDirection: "row"
  }, React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Cell Alignment"
  }, React.createElement(ToggleGroup, null, React.createElement(Toggle, {
    icon: "align-left",
    pressed: data.CellAlignment === 'Left',
    onPressedChange: pressed => pressed ? onCellAlignmentSelectChanged('Left') : onCellAlignmentSelectChanged(null)
  }), React.createElement(Toggle, {
    icon: "align-center",
    pressed: data.CellAlignment === 'Center',
    onPressedChange: pressed => pressed ? onCellAlignmentSelectChanged('Center') : onCellAlignmentSelectChanged(null)
  }), React.createElement(Toggle, {
    icon: "align-right",
    pressed: data.CellAlignment === 'Right',
    onPressedChange: pressed => pressed ? onCellAlignmentSelectChanged('Right') : onCellAlignmentSelectChanged(null)
  }))), React.createElement(FormRow, {
    label: "Exclude Data Rows:"
  }, React.createElement(Flex, {
    alignItems: "center",
    marginLeft: 2
  }, React.createElement(CheckBox, {
    "data-name": "exclude-data-rows-checkbox",
    checked: (_a = data.RowScope) === null || _a === void 0 ? void 0 : _a.ExcludeDataRows,
    onChange: onExcludeDataRowsChanged,
    mr: 2
  }))), React.createElement(FormRow, {
    label: "Exclude Group Rows:"
  }, React.createElement(Flex, {
    alignItems: "center",
    marginLeft: 2
  }, React.createElement(CheckBox, {
    "data-name": "exclude-grouped-rows-checkbox",
    checked: (_b = data.RowScope) === null || _b === void 0 ? void 0 : _b.ExcludeGroupRows,
    onChange: onExcludeGroupedRowsChanged,
    mr: 2
  }))), React.createElement(FormRow, {
    label: "Exclude Row Summaries:"
  }, React.createElement(Flex, {
    alignItems: "center",
    marginLeft: 2
  }, React.createElement(CheckBox, {
    "data-name": "exclude-summary-rows-checkbox",
    checked: (_c = data.RowScope) === null || _c === void 0 ? void 0 : _c.ExcludeSummaryRows,
    onChange: onExcludeSummaryRowsChanged,
    mr: 2
  })))))));
};