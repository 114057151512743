import { ApiBase } from './ApiBase';
import * as ApplicationRedux from '../../Redux/ActionsReducers/ApplicationRedux';
export class ApplicationApiImpl extends ApiBase {
  getApplicationState() {
    return this.getAdaptableState().Application;
  }
  getApplicationDataEntries() {
    return this.getApplicationState().ApplicationDataEntries;
  }
  addApplicationDataEntry(keyValuePair) {
    this.dispatchAction(ApplicationRedux.ApplicationDataEntryAdd(keyValuePair));
  }
  createApplicationDataEntry(key, value) {
    let applicationDataEntry = {
      Key: key,
      Value: value
    };
    this.addApplicationDataEntry(applicationDataEntry);
  }
  editApplicationDataEntry(applicationDataEntry) {
    this.dispatchAction(ApplicationRedux.ApplicationDataEntryEdit(applicationDataEntry));
  }
  deleteApplicationDataEntry(applicationDataEntry) {
    this.dispatchAction(ApplicationRedux.ApplicationDataEntryDelete(applicationDataEntry));
  }
  getApplicationDataEntryByKey(key) {
    let entries = this.getApplicationState().ApplicationDataEntries;
    return entries.find(e => e.Key === key);
  }
  getApplicationDataEntriesByValue(value) {
    let entries = this.getApplicationState().ApplicationDataEntries;
    return entries.filter(e => e.Value === value);
  }
}