import { ApiBase } from './ApiBase';
import * as GeneralConstants from '../../Utilities/Constants/GeneralConstants';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import { ColumnInternalApi } from '../Internal/ColumnInternalApi';
import { createBaseContext } from '../../Utilities/ObjectFactory';
import ArrayExtensions from '../../Utilities/Extensions/ArrayExtensions';
export function isAutoRowGroupColumn(columnId) {
  // put this here as there might be other indicators we are not aware of
  // perhaps with non auto groups ?
  //https://www.ag-grid.com/javascript-grid-grouping/
  return columnId === null || columnId === void 0 ? void 0 : columnId.startsWith(GeneralConstants.AG_GRID_GROUPED_COLUMN);
}
export function isAutoPivotColumn(columnId) {
  // put this here as there might be other indicators we are not aware of?
  return columnId === null || columnId === void 0 ? void 0 : columnId.startsWith(GeneralConstants.AG_GRID_PIVOT_COLUMN);
}
export class ColumnApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.internalApi = new ColumnInternalApi(adaptable);
  }
  getColumns() {
    var _a;
    return (_a = this.getAdaptableApi().gridApi.getGridState().Columns) !== null && _a !== void 0 ? _a : [];
  }
  getVisibleColumns() {
    const layout = this.getAdaptableApi().layoutApi.getCurrentLayout();
    const visibleCols = layout.Columns.reduce((acc, colId) => {
      acc[colId] = true;
      return acc;
    }, {});
    return this.getColumns().filter(c => visibleCols[c.columnId]);
  }
  selectColumn(columnId) {
    this.adaptable.selectColumn(columnId);
  }
  selectColumns(columnIds) {
    this.adaptable.selectColumns(columnIds);
  }
  addColumnToSelection(columnId) {
    this.adaptable.selectColumn(columnId, {
      keepExistingSelection: true
    });
  }
  addColumnsToSelection(columnIds) {
    this.adaptable.selectColumns(columnIds, {
      keepExistingSelection: true
    });
  }
  selectAllColumns() {
    this.adaptable.selectAll();
  }
  autosizeColumn(columnId) {
    this.adaptable.autoSizeColumn(columnId);
  }
  autosizeColumns(columnIds) {
    this.adaptable.autoSizeColumns(columnIds);
  }
  autosizeAllColumns() {
    this.adaptable.autoSizeAllColumns();
  }
  hideColumn(columnId) {
    this.adaptable.hideColumn(columnId);
  }
  showColumn(columnId) {
    this.adaptable.showColumn(columnId);
  }
  isAutoRowGroupColumn(columnId) {
    return isAutoRowGroupColumn(columnId);
  }
  isAutoPivotColumn(columnId) {
    return isAutoPivotColumn(columnId);
  }
  isCalculatedColumn(columnId) {
    return this.getAdaptableApi().calculatedColumnApi.getCalculatedColumns().find(cc => cc.ColumnId == columnId) != null;
  }
  isFreeTextColumn(columnId) {
    return this.getAdaptableApi().freeTextColumnApi.getFreeTextColumns().find(cc => cc.ColumnId == columnId) != null;
  }
  isActionColumn(columnId) {
    var _a;
    return ((_a = this.getAdaptableApi().actionColumnApi.getActionColumns()) === null || _a === void 0 ? void 0 : _a.find(cc => cc.columnId == columnId)) != null;
  }
  getColumnWithColumnId(columnId, logWarning) {
    if (columnId == undefined) {
      return;
    }
    // just return null if no columns rather than logging a warning - otherwise get lots at startup
    if (ArrayExtensions.IsNullOrEmpty(this.getColumns())) {
      return;
    }
    if (this.isAutoPivotColumn(columnId)) {
      return;
    }
    const foundColumn = this.getColumns().find(c => c.columnId == columnId);
    if (foundColumn) {
      return foundColumn;
    }
    if (logWarning) {
      this.logMissingColumnWarning(columnId);
    }
    return undefined;
  }
  hasNumberDataType(columnId) {
    const column = this.getColumnWithColumnId(columnId);
    if (!column) {
      return false;
    }
    return (column === null || column === void 0 ? void 0 : column.dataType) == 'Number';
  }
  hasBooleanDataType(columnId) {
    const column = this.getColumnWithColumnId(columnId);
    if (!column) {
      return false;
    }
    return (column === null || column === void 0 ? void 0 : column.dataType) == 'Boolean';
  }
  hasArrayDataType(columnId) {
    return this.hasStringArrayDataType(columnId) || this.hasNumericArrayDataType(columnId);
  }
  hasStringArrayDataType(columnId) {
    const column = this.getColumnWithColumnId(columnId);
    if (!column) {
      return false;
    }
    return (column === null || column === void 0 ? void 0 : column.dataType) == 'StringArray';
  }
  hasNumericArrayDataType(columnId) {
    const column = this.getColumnWithColumnId(columnId);
    if (!column) {
      return false;
    }
    return (column === null || column === void 0 ? void 0 : column.dataType) == 'NumberArray' || (column === null || column === void 0 ? void 0 : column.dataType) == 'TupleNumberArray' || (column === null || column === void 0 ? void 0 : column.dataType) == 'ObjectNumberArray';
  }
  hasDateDataType(columnId) {
    const column = this.getColumnWithColumnId(columnId);
    if (!column) {
      return false;
    }
    return (column === null || column === void 0 ? void 0 : column.dataType) == 'Date';
  }
  getColumnDataTypeForColumnId(columnId) {
    const column = this.getColumnWithColumnId(columnId); // this.getColumns().find(c => c.ColumnId == columnId);
    if (!column) {
      return undefined;
    }
    return column.dataType;
  }
  getFriendlyNameForColumnId(columnId) {
    if (this.isAutoPivotColumn(columnId)) {
      return GeneralConstants.EMPTY_STRING;
    }
    const foundColumn = this.getColumns().find(c => c.columnId == columnId);
    if (foundColumn) {
      return foundColumn.friendlyName;
    }
    let result = columnId + GeneralConstants.MISSING_COLUMN;
    if (this.isAutoRowGroupColumn(columnId)) {
      const currentLayout = this.getAdaptableApi().layoutApi.getCurrentLayout();
      if (currentLayout === null || currentLayout === void 0 ? void 0 : currentLayout.RowGroupedColumns) {
        result = `[Grouped column: ${currentLayout.RowGroupedColumns.join(', ')}]`;
      }
    }
    this.logMissingColumnWarning(columnId);
    return result;
  }
  doesColumnExist(columnId) {
    const foundColumn = this.getColumns().find(c => c.columnId == columnId);
    return foundColumn ? true : false;
  }
  getFriendlyNamesForColumnIds(columnIds) {
    const friendlyNames = [];
    if (ArrayExtensions.IsNullOrEmpty(columnIds)) {
      return friendlyNames;
    }
    columnIds.forEach(c => {
      friendlyNames.push(this.getFriendlyNameForColumnId(c));
    });
    return friendlyNames;
  }
  getColumnIdForFriendlyName(friendlyName) {
    if (friendlyName.includes(GeneralConstants.MISSING_COLUMN)) {
      return friendlyName.replace(GeneralConstants.MISSING_COLUMN, ''); // Ids should stay "pure"
    }
    const foundColumn = this.getColumns().find(c => c.friendlyName == friendlyName);
    if (foundColumn) {
      return foundColumn.columnId;
    }
    this.logMissingColumnWarning(friendlyName);
    return friendlyName + GeneralConstants.MISSING_COLUMN;
  }
  getColumnIdsForFriendlyNames(friendlyNames) {
    const columnIds = [];
    if (ArrayExtensions.IsNullOrEmpty(friendlyNames)) {
      return columnIds;
    }
    friendlyNames.forEach(c => {
      columnIds.push(this.getColumnIdForFriendlyName(c));
    });
    return columnIds;
  }
  getColumnsWithFriendlyNames(friendlyNames) {
    return friendlyNames.map(friendlyName => this.getColumns().find(x => x.friendlyName == friendlyName)).filter(Boolean);
  }
  getColumnsWithColumnIds(columnIds, logWarning = true) {
    let returnCols = [];
    columnIds.forEach(c => {
      const column = this.getColumnWithColumnId(c, logWarning);
      if (column) {
        returnCols.push(column);
      }
    });
    return returnCols;
  }
  isColumnInGrid(columnId) {
    return !!this.getColumnWithColumnId(columnId, false);
  }
  getColumnWithFriendlyName(columnFriendlyName, logWarning = true) {
    // just return null if no columns rather than logging a warning - otherwise get lots at startup
    if (ArrayExtensions.IsNullOrEmpty(this.getColumns())) {
      return null;
    }
    const foundColumn = this.getColumns().find(c => c.friendlyName == columnFriendlyName);
    if (foundColumn) {
      return foundColumn;
    }
    if (logWarning) {
      this.logMissingColumnWarning(columnFriendlyName);
    }
    return null;
  }
  getColumnsOfType(dataType) {
    switch (dataType) {
      case 'Boolean':
        return this.getBooleanColumns();
      case 'Date':
        return this.getDateColumns();
      case 'Number':
        return this.getNumericColumns();
      case 'String':
        return this.getStringColumns();
      case 'StringArray':
        return this.getStringArrayColumns();
      case 'NumberArray':
        return this.getNumberArrayColumns();
      case 'ObjectNumberArray':
        return this.getObjectNumberArrayColumns();
      case 'TupleNumberArray':
        return this.getTupleNumberArrayColumns();
      default:
        return this.getColumns();
    }
  }
  getNumericColumns() {
    return this.getColumns().filter(c => c.dataType == 'Number');
  }
  getNumericArrayColumns() {
    return this.getNumberArrayColumns().concat(this.getTupleNumberArrayColumns()).concat(this.getObjectNumberArrayColumns());
  }
  getNumberArrayColumns() {
    return this.getColumns().filter(c => c.dataType == 'NumberArray');
  }
  getTupleNumberArrayColumns() {
    return this.getColumns().filter(c => c.dataType == 'TupleNumberArray');
  }
  getObjectNumberArrayColumns() {
    return this.getColumns().filter(c => c.dataType == 'ObjectNumberArray');
  }
  getStringColumns() {
    return this.getColumns().filter(c => c.dataType == 'String');
  }
  getStringArrayColumns() {
    return this.getColumns().filter(c => c.dataType == 'StringArray');
  }
  getDateColumns() {
    return this.getColumns().filter(c => c.dataType == 'Date');
  }
  getBooleanColumns() {
    return this.getColumns().filter(c => c.dataType == 'Boolean');
  }
  getArrayColumns() {
    return this.getColumns().filter(c => c.dataType == 'NumberArray' || c.dataType == 'TupleNumberArray' || c.dataType == 'ObjectNumberArray' || c.dataType == 'StringArray');
  }
  getSortableColumns() {
    return this.getColumns().filter(c => c.sortable);
  }
  getFilterableColumns() {
    return this.getColumns().filter(c => c.filterable);
  }
  getGroupableColumns() {
    return this.getColumns().filter(c => c.groupable);
  }
  getPivotableColumns() {
    return this.getColumns().filter(c => c.pivotable);
  }
  getAggregatableColumns() {
    return this.getColumns().filter(c => c.aggregatable && c.dataType == 'Number');
  }
  getQueryableColumns() {
    return this.getColumns().filter(c => c.queryable);
  }
  getExportableColumns() {
    return this.getColumns().filter(c => c.exportable);
  }
  logMissingColumnWarning(columnId) {
    if (this.adaptable.adaptableOptions.columnOptions.showMissingColumnsWarning === true) {
      if (!this.isAutoRowGroupColumn(columnId) && !this.isCalculatedColumn(columnId) && !this.isFreeTextColumn(columnId) && !this.isActionColumn(columnId)) {
        this.logWarn(`No column found named '${columnId}'`);
      }
    }
  }
  getPrimaryKeyColumn() {
    return this.getColumnWithColumnId(this.adaptable.adaptableOptions.primaryKey);
  }
  getDefaultAggFunc(columnId) {
    var _a, _b, _c;
    const abColumn = this.getColumnWithColumnId(columnId);
    const availableAggregationFunctions = abColumn.availableAggregationFunctions;
    const agColumn = (_b = (_a = this.internalApi.getAgGridColumnForAdaptableColumn(columnId)) === null || _a === void 0 ? void 0 : _a.getColDef) === null || _b === void 0 ? void 0 : _b.call(_a);
    return (_c = agColumn === null || agColumn === void 0 ? void 0 : agColumn.defaultAggFunc) !== null && _c !== void 0 ? _c : availableAggregationFunctions[0];
  }
  isSpecialColumn(columnId) {
    return this.isCalculatedColumn(columnId) || this.isFreeTextColumn(columnId) || this.isActionColumn(columnId);
  }
  openColumnInfoSettingsPanel() {
    this.showModulePopup(ModuleConstants.ColumnInfoModuleId);
  }
  getColumnTypes() {
    const columnTypes = this.getOptions().columnOptions.columnTypes;
    if (typeof columnTypes === 'function') {
      return columnTypes(createBaseContext(this.getAdaptableApi()));
    } else {
      return columnTypes;
    }
  }
  getColumnsByColumnType(columnType) {
    const abColumns = this.getColumns();
    return abColumns.filter(c => {
      var _a;
      return ((_a = c.columnTypes) !== null && _a !== void 0 ? _a : []).includes(columnType);
    });
  }
  getRowGroupedColumns() {
    var _a;
    return (_a = this.getLayoutApi().getCurrentRowGroupsColumnIds()) === null || _a === void 0 ? void 0 : _a.map(n => {
      return this.getColumnWithColumnId(n);
    });
  }
  updateColumnConfiguration(columnConfig) {
    const columnExists = this.doesColumnExist(columnConfig.colId);
    if (!columnExists) {
      this.logWarn(`Column with id ${columnConfig.colId} does not exist, could NOT update configuration`);
      return;
    }
    const columnState = columnConfig;
    const agGridApi = this.adaptable.agGridAdapter.getAgGridApi();
    agGridApi.applyColumnState({
      state: [columnState]
    });
  }
  updateColumnConfigurations(columnConfigs) {
    const existingColumnConfigs = columnConfigs.filter(cc => this.doesColumnExist(cc.colId));
    const notExistingColumnIds = columnConfigs.filter(cc => !this.doesColumnExist(cc.colId)).map(cc => cc.colId);
    notExistingColumnIds.forEach(colId => {
      this.logWarn(`Column with id ${colId} does not exist, could NOT update configuration`);
    });
    const columnStates = existingColumnConfigs;
    const agGridApi = this.adaptable.agGridAdapter.getAgGridApi();
    agGridApi.applyColumnState({
      state: columnStates
    });
  }
  setColumnDefinitions(columnDefinitions) {
    const agGridApi = this.adaptable.agGridAdapter.getAgGridApi();
    agGridApi.setGridOption('columnDefs', columnDefinitions);
  }
  getDistinctDisplayValuesForColumn(columnId) {
    return this.getAdaptableApi().gridApi.internalApi.getDistinctDisplayValuesForColumn(columnId);
  }
  setColumnCaption(columnId, caption) {
    return this.getAdaptableApi().layoutApi.setColumnCaption(columnId, caption);
  }
}