import * as React from 'react';
import { StringExtensions } from '../../Utilities/Extensions/StringExtensions';
import { UIHelper } from '../UIHelper';
import { ButtonInfo } from '../Components/Buttons/ButtonInfo';
import { Icon } from '../../components/icons';
import OverlayTrigger from '../../components/OverlayTrigger';
import { Flex, Box, Text } from 'rebass';
export class AdaptablePopover extends React.Component {
  render() {
    var _a, _b, _c;
    let messageType = this.props.MessageType != null ? this.props.MessageType : 'Info';
    const showIcon = (_a = this.props.showIcon) !== null && _a !== void 0 ? _a : true;
    let useButton = this.props.useButton != null ? this.props.useButton : false;
    let popoverMinWidth = this.props.popoverMinWidth != null ? this.props.popoverMinWidth.toString() + 'px' : 'auto';
    const title = StringExtensions.IsNotNullOrEmpty(this.props.headerText) ? this.props.headerText : '';
    const popoverClickRootClose = React.createElement(Box, {
      className: "ab-Popover",
      style: {
        margin: '0px',
        padding: '0px',
        minWidth: popoverMinWidth,
        maxWidth: (_b = this.props.popoverMaxWidth) !== null && _b !== void 0 ? _b : 300
      }
    }, title ? React.createElement(Text, {
      fontSize: 4,
      padding: 2
    }, title) : null, React.createElement(Box, {
      className: "ab-Popover__body",
      padding: (_c = this.props.popupPadding) !== null && _c !== void 0 ? _c : 2
    }, this.props.bodyText.map((textOrHTML, index) => React.createElement("span", {
      key: index
    }, textOrHTML))));
    const icon = 'info'; // - think this is wrong - UIHelper.getGlyphByMessageType(messageType);
    const color = UIHelper.getColorByMessageType(messageType);
    const iconStyle = {
      color,
      fill: 'currentColor'
    };
    return React.createElement(Flex, {
      alignItems: "center",
      className: this.props.className
    }, React.createElement(OverlayTrigger
    // showTriangle
    , {
      // showTriangle
      visible: this.props.visible,
      render: () => popoverClickRootClose,
      showEvent: this.props.showEvent || 'mouseenter',
      hideEvent: this.props.hideEvent || 'mouseleave',
      style: {
        overflow: 'visible'
      },
      defaultZIndex: 100000,
      alignPosition: this.props.alignPosition
    }, useButton ? React.createElement(ButtonInfo, {
      style: iconStyle,
      variant: "text",
      onClick: () => null,
      icon: showIcon && icon,
      tooltip: this.props.tooltipText
    }, this.props.children) : React.createElement("div", {
      title: this.props.tooltipText,
      tabIndex: 0,
      style: {
        cursor: 'pointer',
        display: 'inline-block'
      }
    }, this.props.children, showIcon && React.createElement(Icon, {
      name: icon,
      style: iconStyle
    }))));
  }
}