import { EMPTY_ARRAY } from '../../Utilities/Constants/GeneralConstants';
import AdaptableHelper from '../../Utilities/Helpers/AdaptableHelper';
/**
 * @ReduxAction Export has been applied
 */
export const EXPORT_APPLY = 'EXPORT_APPLY';
/**
 * @ReduxAction A Report has been selected
 */
export const REPORT_SELECT = 'REPORT_SELECT';
/**
 * @ReduxAction A Report has been added
 */
export const REPORT_ADD = 'REPORT_ADD';
/**
 * @ReduxAction A Report has been edited
 */
export const REPORT_EDIT = 'REPORT_EDIT';
/**
 * @ReduxAction A Report has been deleted
 */
export const REPORT_DELETE = 'REPORT_DELETE';
/**
 * @ReduxAction Export Module is ready
 */
export const EXPORT_READY = 'EXPORT_READY';
/**
 * @ReduxAction A report Destination has been selected
 */
export const DESTINATION_SELECT = 'DESTINATION_SELECT';
export const ReportSelect = SelectedReport => ({
  type: REPORT_SELECT,
  SelectedReport
});
export const ReportAdd = report => ({
  type: REPORT_ADD,
  report
});
export const ReportEdit = report => ({
  type: REPORT_EDIT,
  report
});
export const ReportDelete = report => ({
  type: REPORT_DELETE,
  report
});
export const ExportReady = exportState => ({
  type: EXPORT_READY,
  exportState
});
export const ExportApply = (Report, ExportDestination) => ({
  type: EXPORT_APPLY,
  Report,
  ExportDestination
});
export const DestinationSelect = SelectedDestination => ({
  type: DESTINATION_SELECT,
  SelectedDestination
});
const initialState = {
  Reports: EMPTY_ARRAY
};
export const ExportReducer = (state = initialState, action) => {
  let reports;
  switch (action.type) {
    case REPORT_SELECT:
      return Object.assign({}, state, {
        CurrentReport: action.SelectedReport
      });
    case REPORT_ADD:
      {
        const actionReport = action.report;
        AdaptableHelper.addUuidAndSource(actionReport);
        reports = [].concat(state.Reports);
        reports.push(actionReport);
        return Object.assign(Object.assign({}, state), {
          Reports: reports
        });
      }
    case REPORT_EDIT:
      const actionReport = action.report;
      return Object.assign(Object.assign({}, state), {
        Reports: state.Reports.map(abObject => abObject.Uuid === actionReport.Uuid ? actionReport : abObject)
      });
    case REPORT_DELETE:
      {
        const actionReport = action.report;
        return Object.assign(Object.assign({}, state), {
          Reports: state.Reports.filter(abObject => abObject.Uuid !== actionReport.Uuid)
        });
      }
    case DESTINATION_SELECT:
      return Object.assign({}, state, {
        CurrentDestination: action.SelectedDestination
      });
    default:
      return state;
  }
};