import * as React from 'react';
import ColumnsList from './ColumnsList';
export const ConfigurationWizardColumnsStep = props => {
  var _a, _b;
  let theme = 'light';
  if (typeof props.adaptableConfig.adaptableOptions.predefinedConfig === 'object' && ((_a = props.adaptableConfig.adaptableOptions.predefinedConfig.Theme) === null || _a === void 0 ? void 0 : _a.CurrentTheme)) {
    theme = props.adaptableConfig.adaptableOptions.predefinedConfig.Theme.CurrentTheme;
  }
  return React.createElement(ColumnsList, {
    theme: theme,
    handle: props.columnsHandle,
    onValidityChange: valid => {
      props.setPrimaryKeyIsNotSelected(valid);
    },
    selectedColumns: props.selectedColumns,
    onChange: props.onColumnChange,
    onSelectionChange: props.onSelectionChange,
    columns: (_b = props.adaptableConfig.gridOptions) === null || _b === void 0 ? void 0 : _b.columnDefs
  });
};