import { ApiBase } from './ApiBase';
export class OptionsApiImpl extends ApiBase {
  getAdaptableId() {
    return this.adaptable.adaptableOptions.adaptableId;
  }
  getAdaptableStateKey() {
    return this.adaptable.adaptableOptions.adaptableStateKey;
  }
  getAutogeneratePrimaryKey() {
    return this.adaptable.adaptableOptions.autogeneratePrimaryKey;
  }
  getLicenseKey() {
    return this.adaptable.adaptableOptions.licenseKey;
  }
  getPrimaryKey() {
    return this.adaptable.adaptableOptions.primaryKey;
  }
  getUserName() {
    return this.adaptable.adaptableOptions.userName;
  }
  getAdaptableOptions() {
    return this.getOptions();
  }
  getActionColumnOptions() {
    return this.getOptions().actionColumnOptions;
  }
  getActionRowOptions() {
    return this.getOptions().actionRowOptions;
  }
  getCellSummaryOptions() {
    return this.getOptions().cellSummaryOptions;
  }
  getCalendarOptions() {
    return this.getOptions().calendarOptions;
  }
  getColumnOptions() {
    return this.getOptions().columnOptions;
  }
  getNoteOptions() {
    return this.getOptions().noteOptions;
  }
  getCommentOptions() {
    return this.getOptions().commentOptions;
  }
  getCustomSortOptions() {
    return this.getOptions().customSortOptions;
  }
  getContainerOptions() {
    return this.getOptions().containerOptions;
  }
  getDataSetOptions() {
    return this.getOptions().dataSetOptions;
  }
  getNotificationsOptions() {
    return this.getOptions().notificationsOptions;
  }
  getAlertOptions() {
    return this.getOptions().alertOptions;
  }
  getDashboardOptions() {
    return this.getOptions().dashboardOptions;
  }
  getEditOptions() {
    return this.getOptions().editOptions;
  }
  getExportOptions() {
    return this.getOptions().exportOptions;
  }
  getGroupingOptions() {
    return this.getOptions().groupingOptions;
  }
  getLayoutOptions() {
    return this.getOptions().layoutOptions;
  }
  getColumnMenuOptions() {
    return this.getOptions().columnMenuOptions;
  }
  getContextMenuOptions() {
    return this.getOptions().contextMenuOptions;
  }
  getColumnFilterOptions() {
    return this.getOptions().columnFilterOptions;
  }
  getGridFilterOptions() {
    return this.getOptions().gridFilterOptions;
  }
  getExpressionOptions() {
    return this.getOptions().expressionOptions;
  }
  getQuickSearchOptions() {
    return this.getOptions().quickSearchOptions;
  }
  getFormatColumnOptions() {
    return this.getOptions().formatColumnOptions;
  }
  getStateOptions() {
    return this.getOptions().stateOptions;
  }
  getTeamSharingOptions() {
    return this.getOptions().teamSharingOptions;
  }
  getUserInterfaceOptions() {
    return this.getOptions().userInterfaceOptions;
  }
  getToolPanelOptions() {
    return this.getOptions().toolPanelOptions;
  }
  getChartingOptions() {
    return this.getOptions().chartingOptions;
  }
  getDataChangeHistoryOptions() {
    return this.getOptions().dataChangeHistoryOptions;
  }
  getEntitlementOptions() {
    return this.getOptions().entitlementOptions;
  }
  getFlashingCellOptions() {
    return this.getOptions().flashingCellOptions;
  }
  getSettingsPanelOptions() {
    return this.getOptions().settingsPanelOptions;
  }
  getFdc3Options() {
    return this.adaptable.adaptableOptions.fdc3Options;
  }
  getDataImportOptions() {
    return this.getOptions().dataImportOptions;
  }
}