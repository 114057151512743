import { isAdaptableRuleValid } from '../../Components/EntityRulesEditor/Utilities';
export const isValidAlertRules = (alert, api, context) => {
  var _a;
  if (!((_a = alert.Rule.Predicates) === null || _a === void 0 ? void 0 : _a.length) && !alert.Rule.BooleanExpression && !alert.Rule.ObservableExpression && !alert.Rule.AggregatedBooleanExpression) {
    return 'No Rule defined for Alert';
  }
  const isRuleValid = isAdaptableRuleValid(alert, api, context);
  if (typeof isRuleValid === 'string') {
    return isRuleValid;
  }
  // boolean expressions may also be saved as named queries
  if (context.namedQuery != false) {
    const isValidName = api.namedQueryApi.isValidNamedQuery(context.namedQuery);
    if (!isValidName.valid) {
      return isValidName.message;
    }
  }
  return true;
};