/**
 * Updates IsSuspended in a list of adaptable objects
 *
 * @param abObjectToChange object to suspend
 * @param list list of ab objects
 */
export function changeIsSuspendInList(abObjectToChange, list, IsSuspended) {
  return list.map(abObject => {
    if (!abObject.IsReadOnly && (abObject === null || abObject === void 0 ? void 0 : abObject.Uuid) === (abObjectToChange === null || abObjectToChange === void 0 ? void 0 : abObjectToChange.Uuid)) {
      return Object.assign(Object.assign({}, abObject), {
        IsSuspended: IsSuspended
      });
    }
    return abObject;
  });
}
export function suspendAllInList(list) {
  return list.map(abObject => {
    if (!abObject.IsReadOnly) {
      return Object.assign(Object.assign({}, abObject), {
        IsSuspended: true
      });
    }
    return abObject;
  });
}
export function unsuspendAllInList(list) {
  return list.map(abObject => {
    if (!abObject.IsReadOnly) {
      return Object.assign(Object.assign({}, abObject), {
        IsSuspended: false
      });
    }
    return abObject;
  });
}