import * as React from 'react';
import DefaultIcon from './DefaultIcon';
export default (props => React.createElement(DefaultIcon, Object.assign({}, props, {
  viewBox: "0 0 17 17"
}), React.createElement("path", {
  fill: "currentColor",
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M4.10889 3.11667C4.34607 3.11667 4.55816 3.26439 4.6404 3.48686L8.12582 12.9157C8.23433 13.2092 8.08433 13.5352 7.79078 13.6437C7.49724 13.7522 7.1713 13.6023 7.06279 13.3086L5.92037 10.2182H2.29739L1.15498 13.3086C1.04646 13.6023 0.720532 13.7522 0.426985 13.6437C0.133437 13.5352 -0.0165648 13.2092 0.0919466 12.9157L3.57737 3.48686C3.65961 3.26439 3.87169 3.11667 4.10889 3.11667ZM4.10889 5.31768L5.56427 9.25484H2.6535L4.10889 5.31768Z"
}), React.createElement("path", {
  fill: "currentColor",
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M12.2389 5.90131C10.3793 5.90131 8.96375 7.57712 8.96375 9.75769C8.96375 12.0515 10.4926 13.6141 12.2389 13.6141C13.171 13.6141 14.1376 13.2251 14.7949 12.4914V13.0673C14.7949 13.349 15.0232 13.5773 15.3049 13.5773C15.5866 13.5773 15.8149 13.349 15.8149 13.0673V6.48267C15.8149 6.201 15.5866 5.97267 15.3049 5.97267C15.0232 5.97267 14.7949 6.201 14.7949 6.48267V7.05294C14.1729 6.34775 13.2514 5.90131 12.2389 5.90131ZM14.7949 11.1047V8.27556C14.3525 7.4585 13.4204 6.86525 12.4517 6.86525C11.3434 6.86525 10.1264 7.89459 10.1264 9.7575C10.1264 11.5071 11.2301 12.6497 12.4517 12.6497C13.4529 12.6497 14.3616 11.8946 14.7949 11.1047Z"
})));