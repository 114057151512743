import * as React from 'react';
import { Flex, Text } from 'rebass';
import { Tabs } from '../../../components/Tabs';
import { Tag } from '../../../components/Tag';
import ObjectFactory from '../../../Utilities/ObjectFactory';
import { useAdaptable } from '../../AdaptableContext';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
import { TypeRadio } from '../../Wizard/TypeRadio';
export const renderStyledColumnTypeSummary = data => {
  let type = null;
  if (data.GradientStyle) {
    type = 'Gradient Column';
  } else if (data.PercentBarStyle) {
    type = 'Percent Bar';
  } else if (data.SparkLineStyle) {
    type = 'Spark Line';
  } else if (data.BadgeStyle) {
    type = 'Badge';
  }
  return React.createElement(Text, {
    pr: 2,
    py: 2
  }, "Type ", React.createElement(Tag, null, type));
};
export const StyledColumnWizardTypeSection = props => {
  const {
    data
  } = useOnePageAdaptableWizardContext();
  const adaptable = useAdaptable();
  const handleTypeChange = type => {
    const newStyledColumn = Object.assign({}, data);
    delete newStyledColumn.GradientStyle;
    delete newStyledColumn.PercentBarStyle;
    delete newStyledColumn.SparkLineStyle;
    delete newStyledColumn.BadgeStyle;
    switch (type) {
      case 'gradient':
        newStyledColumn.GradientStyle = {};
        break;
      case 'percent':
        newStyledColumn.PercentBarStyle = {};
        break;
      case 'sparkline':
        newStyledColumn.SparkLineStyle = {
          options: {
            type: 'line'
          }
        };
        break;
      case 'badge':
        newStyledColumn.BadgeStyle = {
          Badges: [ObjectFactory.CreateDefaultStyledColumnBadge()]
        };
        break;
    }
    newStyledColumn.ColumnId = null;
    props.onChange(newStyledColumn);
  };
  return React.createElement(Flex, {
    height: "100%",
    flexDirection: "column",
    "data-name": "styled-column-type"
  }, React.createElement(Tabs, {
    autoFocus: false,
    padding: 2
  }, React.createElement(Tabs.Tab, null, "Type"), React.createElement(Tabs.Content, null, React.createElement(TypeRadio, {
    text: "Gradient",
    description: "Colour each cell in the column using a Gradient value (Numeric Columns)",
    checked: Boolean(data.GradientStyle),
    onClick: () => handleTypeChange('gradient')
  }), React.createElement(TypeRadio, {
    text: "Percent Bar",
    description: "Display a coloured Bar where the width is based on the cell (Numeric Columns)",
    checked: Boolean(data.PercentBarStyle),
    onClick: () => handleTypeChange('percent')
  }), adaptable.api.styledColumnApi.canDisplaySparklines() && React.createElement(TypeRadio, {
    text: "Sparkline",
    description: "Render the column as a Sparkline (Numeric Array Columns)",
    checked: Boolean(data.SparkLineStyle),
    onClick: () => handleTypeChange('sparkline')
  }), React.createElement(TypeRadio, {
    text: "Badge",
    description: "Display the column's values as Badges (All Columns)",
    checked: Boolean(data.BadgeStyle),
    onClick: () => handleTypeChange('badge')
  }))));
};