import * as React from 'react';
import HelpBlock from '../../../components/HelpBlock';
import { ALL_MODULES } from '../../../PredefinedConfig/Common/Types';
import { Box, Flex } from 'rebass';
import Radio from '../../../components/Radio';
import FormLayout, { FormRow } from '../../../components/FormLayout';
import { ADAPTABLE_MODULE_MAP } from '../../../Utilities/Constants/ModuleConstants';
import { DataSource, InfiniteTable } from '../../../components/InfiniteTable';
const ALL_ENTITLEMENTS_MODULES = ALL_MODULES;
const tableDOMProps = {
  style: {
    height: '100%',
    minWidth: '10rem',
    minHeight: 600
  }
};
const EntitlementsListForm = props => {
  var _a, _b, _c;
  let abOptions = props.adaptableOptions;
  const entitlements = (_b = (_a = abOptions === null || abOptions === void 0 ? void 0 : abOptions.entitlementOptions) === null || _a === void 0 ? void 0 : _a.moduleEntitlements) !== null && _b !== void 0 ? _b : [];
  if (typeof entitlements === 'function') {
    return React.createElement(HelpBlock, {
      mt: 2
    }, "Entitlements cannot be customized, they are handled by a custom function.");
  }
  const entitlementsMap = React.useMemo(() => {
    return entitlements.reduce((acc, entitlement) => {
      acc[entitlement.adaptableModule] = entitlement.accessLevel;
      return acc;
    }, {});
  }, [abOptions === null || abOptions === void 0 ? void 0 : abOptions.entitlementOptions]);
  const handleOnChange = React.useCallback((module, accessLevel) => {
    let newEntitlements = entitlements;
    // change
    if (entitlementsMap[module]) {
      newEntitlements = newEntitlements.map(entitlement => {
        if (entitlement.adaptableModule === module) {
          entitlement.accessLevel = accessLevel;
        }
        return entitlement;
      });
    } else {
      // new
      newEntitlements = [...newEntitlements, {
        adaptableModule: module,
        accessLevel: accessLevel
      }];
    }
    props.onChangedAptableOptions(Object.assign(Object.assign({}, abOptions), {
      entitlementOptions: Object.assign(Object.assign({}, abOptions.entitlementOptions), {
        moduleEntitlements: newEntitlements
      })
    }));
  }, [abOptions === null || abOptions === void 0 ? void 0 : abOptions.entitlementOptions]);
  if (typeof entitlements === 'function') {
    return React.createElement(HelpBlock, {
      mb: 2
    }, "Entitlements cannot be customized, they are handled by a custom function.");
  }
  const columnsMap = {
    name: {
      header: 'Module Name',
      align: 'start',
      renderValue: params => {
        return React.createElement(React.Fragment, null, params.data.name);
      }
    },
    full: {
      header: 'Full',
      render: params => {
        const module = params.data.name;
        const accessLevel = entitlementsMap[module];
        return React.createElement(Radio, {
          onClick: () => handleOnChange(module, 'Full'),
          checked: accessLevel === 'Full'
        });
      }
    },
    readonly: {
      header: 'Read Only',
      render: params => {
        const module = params.data.name;
        const accessLevel = entitlementsMap[module];
        return React.createElement(Radio, {
          onClick: () => handleOnChange(module, 'ReadOnly'),
          checked: accessLevel === 'ReadOnly'
        });
      }
    },
    hidden: {
      header: 'Hidden',
      render: params => {
        const module = params.data.name;
        const accessLevel = entitlementsMap[module];
        return React.createElement(Radio, {
          onClick: () => handleOnChange(module, 'Hidden'),
          checked: accessLevel === 'Hidden'
        });
      }
    }
  };
  const data = ALL_ENTITLEMENTS_MODULES.map(module => ({
    name: ADAPTABLE_MODULE_MAP[module]
  }));
  let theme = 'light';
  if (typeof props.adaptableOptions.predefinedConfig === 'object' && ((_c = props.adaptableOptions.predefinedConfig.Theme) === null || _c === void 0 ? void 0 : _c.CurrentTheme)) {
    theme = props.adaptableOptions.predefinedConfig.Theme.CurrentTheme;
  }
  return React.createElement(Box, {
    flex: 1,
    height: "100%",
    className: theme
  }, React.createElement(DataSource, {
    data: data,
    primaryKey: "name"
  }, React.createElement(InfiniteTable, {
    columnTypes: {
      default: {
        defaultFlex: 1,
        align: 'center',
        defaultSortable: false
      }
    },
    headerOptions: {
      alwaysReserveSpaceForSortIcon: false
    },
    columnHeaderHeight: 50,
    domProps: tableDOMProps,
    columns: columnsMap
  })));
};
const DefaultEntitlementForm = props => {
  var _a, _b;
  const abOptions = props.adaptableOptions;
  const entitlementOptions = (_a = props.adaptableOptions) === null || _a === void 0 ? void 0 : _a.entitlementOptions;
  const defaultEntitlement = entitlementOptions === null || entitlementOptions === void 0 ? void 0 : entitlementOptions.defaultAccessLevel;
  if (typeof defaultEntitlement === 'function') {
    return React.createElement(HelpBlock, {
      mb: 2
    }, "Default Entitlement is controlled by a custom function");
  }
  const handleDefaultEntitlementChange = React.useCallback(accessLevel => {
    props.onChangedAptableOptions(Object.assign(Object.assign({}, abOptions), {
      entitlementOptions: Object.assign(Object.assign({}, abOptions.entitlementOptions), {
        defaultAccessLevel: accessLevel
      })
    }));
  }, [(_b = abOptions === null || abOptions === void 0 ? void 0 : abOptions.entitlementOptions) === null || _b === void 0 ? void 0 : _b.defaultAccessLevel]);
  return React.createElement(FormLayout, {
    mb: 2
  }, React.createElement(FormRow, {
    label: "Default Entitlement:"
  }, React.createElement(Radio, {
    onClick: () => handleDefaultEntitlementChange('Full'),
    checked: defaultEntitlement === 'Full',
    mr: 3
  }, "Visible"), React.createElement(Radio, {
    onClick: () => handleDefaultEntitlementChange('ReadOnly'),
    checked: defaultEntitlement === 'ReadOnly',
    mr: 3
  }, "Read Only"), React.createElement(Radio, {
    onClick: () => handleDefaultEntitlementChange('Hidden'),
    checked: defaultEntitlement === 'Hidden'
  }, "Hidden")));
};
const EntitlementsForm = props => {
  let abOptions = props.adaptableOptions;
  return React.createElement(Flex, {
    height: "100%",
    flexDirection: "column",
    p: 2
  }, React.createElement(DefaultEntitlementForm, Object.assign({}, props)), React.createElement(EntitlementsListForm, Object.assign({}, props)));
};
export default EntitlementsForm;