import * as React from 'react';
import { Box, Flex } from 'rebass';
import { Icon } from '../../components/icons';
import Panel from '../../components/Panel';
import SimpleButton from '../../components/SimpleButton';
import { Tag } from '../../components/Tag';
import { SortOrder } from '../../PredefinedConfig/Common/Enums';
import ArrayExtensions from '../../Utilities/Extensions/ArrayExtensions';
import { AdaptablePopover } from '../AdaptablePopover';
import { ButtonOpen } from '../Components/Buttons/ButtonOpen';
export const AvailableSearchLabel = props => {
  return React.createElement(Flex, {
    flexDirection: "column",
    textAlign: "start",
    width: "100%",
    justifyContent: "space-around"
  }, React.createElement(Box, {
    mb: 2,
    flex: 1
  }, props.label), React.createElement(Tag, null, props.expression));
};
export const NamedQuerySelector = props => {
  var _a;
  const sortedNamedQueries = (_a = ArrayExtensions.sortArrayWithProperty(SortOrder.Asc, props.namedQueries, 'Name')) !== null && _a !== void 0 ? _a : [];
  const availableSearches = sortedNamedQueries.map(expression => {
    return {
      label: React.createElement(AvailableSearchLabel, {
        label: expression.Name,
        expression: expression.BooleanExpression
      }),
      icon: expression.BooleanExpression === props.currentQuery ? React.createElement(Icon, {
        name: 'check'
      }) : null,
      onClick: () => props.onSelect(expression.BooleanExpression)
    };
  });
  const cachedQueries = ArrayExtensions.IsNotNullOrEmpty(props.cachedQueries) ? props.cachedQueries.slice(-3).reverse().map(item => ({
    label: React.createElement(AvailableSearchLabel, {
      label: `Grid Filter at ${item.time.toLocaleTimeString('en-US')}`,
      expression: item.expression
    }),
    icon: React.createElement(Icon, {
      name: 'history'
    }),
    onClick: () => props.setGridFilterExpression(item.expression)
  })) : null;
  return ArrayExtensions.IsNotNullOrEmpty(cachedQueries) || ArrayExtensions.IsNotNullOrEmpty(availableSearches) ? React.createElement(AdaptablePopover, {
    tooltipText: "Load Named Query",
    showIcon: false,
    popoverMinWidth: 300,
    bodyText: [React.createElement(Flex, {
      justifyContent: "stretch",
      flexDirection: "column"
    }, React.createElement(Panel, {
      header: "Named Queries"
    }, React.createElement(Flex, {
      flexDirection: "column"
    }, availableSearches.map((item, index) => {
      return React.createElement(SimpleButton, {
        mb: 2,
        variant: "text",
        key: index,
        onClick: item.onClick
      }, item.label);
    }))), cachedQueries ? React.createElement(React.Fragment, null, React.createElement(Panel, {
      mt: 2,
      header: "Recent Grid Filters"
    }, React.createElement(Flex, {
      flexDirection: "column"
    }, cachedQueries.map((cachedQuery, index) => {
      return React.createElement(SimpleButton, {
        disabled: props.disabled,
        mb: 2,
        variant: "text",
        key: index,
        onClick: cachedQuery.onClick
      }, cachedQuery.label);
    })))) : null)]
  }, React.createElement(SimpleButton, {
    disabled: props.disabled,
    variant: "text",
    icon: "folder-open"
  }, props.children)) : React.createElement(ButtonOpen, {
    tooltip: "Load Named Query",
    disabled: true
  });
};