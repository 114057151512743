export const mapColumnDataTypeToExpressionFunctionType = dataType => {
  if (dataType === 'Number') {
    return 'number';
  }
  if (dataType === 'Boolean') {
    return 'boolean';
  }
  if (dataType === 'String') {
    return 'text';
  }
  if (dataType === 'Date') {
    return 'date';
  }
};