import Helper from '../Helpers/Helper';
import { HOST_URL_DOCS } from '../Constants/DocumentationLinkConstants';
import { ALL_MODULES } from '../../PredefinedConfig/Common/Types';
export class ModuleService {
  constructor(adaptableApi) {
    this.adaptableApi = adaptableApi;
    this.adaptableApi = adaptableApi;
  }
  createModuleMenus() {
    const settingsPanelItems = [];
    const adaptableMenuItems = [];
    this.getModuleCollection().forEach(module => {
      const settingsPanelMenuItem = module.createModuleMenuItem('ModuleMenu');
      if (Helper.objectExists(settingsPanelMenuItem)) {
        if (settingsPanelItems.findIndex(m => m.module == settingsPanelMenuItem.module) == -1) {
          settingsPanelItems.push(settingsPanelMenuItem);
        }
      }
      const adaptableMenuItem = module.createModuleMenuItem('ModuleButton');
      if (Helper.objectExists(adaptableMenuItem)) {
        if (adaptableMenuItems.findIndex(m => m.module == adaptableMenuItem.module) == -1) {
          adaptableMenuItems.push(adaptableMenuItem);
        }
      }
    });
    // store the main menu as we will re-use (and it never changes)
    this.adaptableApi.internalApi.setSettingsPanelItems(settingsPanelItems);
    this.adaptableApi.internalApi.setMenuModuleItems(adaptableMenuItems);
  }
  isModuleAvailable(adaptableModule) {
    let module = this.getModuleCollection().get(adaptableModule);
    if (!module) {
      return false;
    }
    return module.isModuleAvailable();
  }
  isModuleName(adaptableModule) {
    return ALL_MODULES.includes(adaptableModule);
  }
  isModuleEditable(adaptableModule) {
    let module = this.getModuleCollection().get(adaptableModule);
    if (!module) {
      return false;
    }
    return module.isModuleEditable();
  }
  getTeamSharingAction(adaptableModule) {
    let module = this.getModuleCollection().get(adaptableModule);
    if (!module) {
      return undefined;
    }
    return module.getTeamSharingAction();
  }
  getModuleById(adaptableModule) {
    // @ts-ignore TODO: make modules generic
    return this.getModuleCollection().get(adaptableModule);
  }
  getModuleInfoByModule(adaptableModule) {
    const module = this.getModuleById(adaptableModule);
    return module ? module.moduleInfo : undefined;
  }
  getModuleInfoByFriendlyName(friendlyName) {
    for (let module of this.getModuleCollection().values()) {
      let moduleInfo = module.moduleInfo;
      if (moduleInfo && moduleInfo.FriendlyName == friendlyName) {
        return moduleInfo;
      }
    }
    return undefined;
  }
  getPopupMaxWidth(adaptableModule) {
    var _a;
    return (_a = this.getModuleById(adaptableModule)) === null || _a === void 0 ? void 0 : _a.getPopupMaxWidth();
  }
  getModuleCollection() {
    return this.adaptableApi.internalApi.getModules();
  }
  getModuleDocumentationPageByModule(adaptableModule) {
    let url = `${HOST_URL_DOCS}/`;
    let learnUrl = url + 'guide/';
    switch (adaptableModule) {
      case 'Alert':
        return learnUrl + 'handbook-alerting';
      case 'BulkUpdate':
        return url + 'handbook-editing-bulk-update';
      case 'CalculatedColumn':
        return learnUrl + 'handbook-calculated-column';
      case 'CellSummary':
        return learnUrl + 'handbook-summarising';
      case 'Charting':
        return learnUrl + 'handbook-charts';
      case 'ColumnFilter':
        return learnUrl + 'handbook-column-filter';
      case 'ColumnInfo':
        return learnUrl + 'dev-guide-tutorial-grid-column-info';
      case 'Comment':
        return learnUrl + 'handbook-comments';
      case 'CustomSort':
        return learnUrl + 'handbook-sorting-custom';
      case 'Dashboard':
        return learnUrl + 'ui-dashboard';
      case 'DataChangeHistory':
        return learnUrl + 'handbook-monitoring-data-change-history';
      case 'DataImport':
        return learnUrl + 'handbook-importing';
      case 'DataSet':
        return learnUrl + 'handbook-data-sets';
      case 'Export':
        return learnUrl + 'handbook-exporting';
      case 'Fdc3':
        return learnUrl + 'handbook-fdc3';
      case 'FlashingCell':
        return learnUrl + 'handbook-flashing-cell';
      case 'FormatColumn':
        return learnUrl + 'handbook-column-formatting';
      case 'FreeTextColumn':
        return learnUrl + 'handbook-freetext-column';
      case 'GridFilter':
        return learnUrl + 'handbook-grid-filter';
      case 'GridInfo':
        return learnUrl + 'dev-guide-tutorial-grid-column-info';
      case 'Layout':
        return learnUrl + 'handbook-layouts';
      case 'NamedQuery':
        return learnUrl + 'handbook-named-queries';
      case 'Note':
        return learnUrl + 'handbook-notes';
      case 'PlusMinus':
        return learnUrl + 'handbook-editing-plus-minus';
      case 'QuickSearch':
        return learnUrl + 'handbook-quick-search';
      case 'Schedule':
        return learnUrl + 'handbook-scheduling';
      case 'SettingsPanel':
        return learnUrl + 'ui-settings-panel';
      case 'Shortcut':
        return learnUrl + 'handbook-editing-shortcut';
      case 'SmartEdit':
        return learnUrl + 'handbook-editing-smart-edit';
      case 'StateManagement':
        return learnUrl + 'dev-guide-adaptable-state-management';
      case 'StatusBar':
        return learnUrl + 'ui-status-bar';
      case 'StyledColumn':
        return learnUrl + 'handbook-styled-column-overview';
      case 'SystemStatus':
        return learnUrl + 'handbook-system-status-message';
      case 'TeamSharing':
        return learnUrl + 'handbook-team-sharing';
      case 'Theme':
        return learnUrl + 'handbook-theming';
      case 'ToolPanel':
        return learnUrl + 'ui-tool-panel';
      case 'IPushPull':
        return learnUrl + 'integrations-ipushpull';
      case 'OpenFin':
        return learnUrl + 'integrations-openfin';
      default:
        return 'good';
    }
  }
  destroy() {
    // TO DO
  }
}