import { __rest } from "tslib";
import * as React from 'react';
import { Box, Flex } from 'rebass';
import join from '../utils/join';
const baseClassName = 'ab-EmptyContent';
const EmptyContent = _a => {
  var {
      children,
      className,
      style
    } = _a,
    flexProps = __rest(_a, ["children", "className", "style"]);
  if (typeof children === 'string') {
    children = React.createElement("p", null, children);
  }
  return React.createElement(Box, {
    flex: 1,
    className: `${baseClassName}__wrapper`
  }, React.createElement(Flex, Object.assign({
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  }, flexProps, {
    className: join(baseClassName, className),
    style: style
  }), children));
};
export default EmptyContent;