import * as React from 'react';
import { ExpressionPreview } from '../../../components/ExpressionEditor/ExpressionPreview';
import { Tag } from '../../../components/Tag';
export const getExportRowsViewItems = (report, api) => {
  let element = null;
  if (report.ReportRowScope === 'ExpressionRows') {
    element = React.createElement(ExpressionPreview, {
      query: report.Query
    });
  } else {
    element = api.exportApi.internalApi.getReportExpressionDescription(report, api.columnApi.getColumns());
  }
  return {
    name: 'Rows',
    view: React.createElement(Tag, null, element)
  };
};