import * as React from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useState, useLayoutEffect } from 'react';
export const baseClassName = 'ab-Modal';
const Backdrop = props => {
  const {
    uuid,
    timestamp
  } = props;
  const [backdropVisible, setBackdropVisible] = React.useState(false);
  const [zIndex, setZIndex] = useState(-1);
  useLayoutEffect(() => {
    updatePositionInStack(uuid, {
      timestamp,
      baseZIndex: props.zIndex,
      setBackdropOrder: (visible, zIndex) => {
        unstable_batchedUpdates(() => {
          setBackdropVisible(visible);
          setZIndex(zIndex);
          if (visible && props.onBringToFront) {
            props.onBringToFront();
          }
        });
      }
    });
    return () => updatePositionInStack(uuid, null);
  }, []);
  return backdropVisible ? React.createElement("div", {
    "data-id": uuid,
    style: {
      zIndex
    },
    className: `${baseClassName}-backdrop`
  }) : null;
};
const stack = {};
export const updatePositionInStack = (id, data) => {
  stack[id] = data;
  if (!data) {
    delete stack[id];
  }
  const pairs = Object.keys(stack).map(key => {
    const data = stack[key];
    return Object.assign({
      key
    }, data);
  });
  // sort pairs in ascending order
  pairs.sort((p1, p2) => p1.timestamp - p2.timestamp);
  const last = pairs.pop();
  pairs.forEach(data => {
    data.setBackdropOrder(false, -1);
  });
  if (last) {
    last.setBackdropOrder(true, last.baseZIndex);
  }
};
export default Backdrop;