import * as React from 'react';
import { Flex } from 'rebass';
import { Tabs } from '../../../components/Tabs';
import { TypeRadio } from '../../Wizard/TypeRadio';
export const CalculatedColumnTypeWizardSection = props => {
  return React.createElement(Tabs, null, React.createElement(Tabs.Tab, null, "Calculated Column Expression Type"), React.createElement(Tabs.Content, null, React.createElement(Flex, {
    flexDirection: "column"
  }, React.createElement(TypeRadio, {
    text: 'Standard',
    description: "The calculated value is derived from other cells in the row",
    checked: props.type === 'ScalarExpression',
    onClick: () => props.onTypeChange('ScalarExpression')
  }), React.createElement(TypeRadio, {
    text: 'Aggregated',
    description: "The calculated value is derived from other rows",
    checked: props.type === 'AggregatedScalarExpression',
    onClick: () => props.onTypeChange('AggregatedScalarExpression')
  }), React.createElement(TypeRadio, {
    text: 'Cumulative',
    description: "The calculated value is derived cumulatively from other rows",
    checked: props.type === 'CumulativeAggregatedExpression',
    onClick: () => props.onTypeChange('CumulativeAggregatedExpression')
  }), React.createElement(TypeRadio, {
    text: 'Quantile',
    description: "The calculated value is derived from other rows using quantile aggregations",
    checked: props.type === 'QuantileAggregatedExpression',
    onClick: () => props.onTypeChange('QuantileAggregatedExpression')
  }))));
};