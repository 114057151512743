import * as React from 'react';
import SimpleButton from '../../components/SimpleButton';
import * as FormatColumnRedux from '../../Redux/ActionsReducers/FormatColumnRedux';
import { useDispatch } from 'react-redux';
import { useAdaptable } from '../AdaptableContext';
export const MoveFormatColumn = props => {
  const dispatch = useDispatch();
  const adaptable = useAdaptable();
  const formatColumn = adaptable.api.formatColumnApi.getFormatColumns();
  const handleMoveUp = React.useCallback(() => {
    dispatch(FormatColumnRedux.FormatColumnMoveUp(props.data));
  }, []);
  const handleMoveDown = React.useCallback(() => {
    dispatch(FormatColumnRedux.FormatColumnMoveDown(props.data));
  }, []);
  if ((formatColumn === null || formatColumn === void 0 ? void 0 : formatColumn.length) <= 1) {
    return React.createElement(React.Fragment, null);
  }
  return React.createElement(React.Fragment, null, React.createElement(SimpleButton, {
    "data-id": "move-up",
    title: "move up",
    onClick: handleMoveUp,
    variant: "text",
    icon: "arrow-up"
  }), React.createElement(SimpleButton, {
    "data-name": "move-down",
    title: "move down",
    onClick: handleMoveDown,
    variant: "text",
    icon: "arrow-down"
  }));
};