import * as React from 'react';
import { Box } from 'rebass';
import { ModuleManager } from '../../../../components/DragAndDropContext/ModuleManager';
import HelpBlock from '../../../../components/HelpBlock';
import FormLayout, { FormRow } from '../../../../components/FormLayout';
import Input from '../../../../components/Input';
import SimpleButton from '../../../../components/SimpleButton';
import DropdownButton from '../../../../components/DropdownButton';
import { NocodeWizardFormBox } from '../Components/FormBox';
export const UIOptionsStatusbarForm = props => {
  var _a, _b, _c, _d;
  const allPanels = (_c = (_b = (_a = props.gridOptions) === null || _a === void 0 ? void 0 : _a.statusBar) === null || _b === void 0 ? void 0 : _b.statusPanels) !== null && _c !== void 0 ? _c : [];
  const panelIdtoPanel = (panelsIds, align) => panelsIds.map(panelId => {
    const previousItem = allPanels.find(panel => panel.key === panelId || panel.statusPanel === panelId);
    if (previousItem) {
      return Object.assign(Object.assign({}, previousItem), {
        align
      });
    } else {
      const newPanel = {
        statusPanel: panelId,
        align
      };
      return newPanel;
    }
  });
  const leftPanels = allPanels.filter(panel => !(panel === null || panel === void 0 ? void 0 : panel.align) || panel.align === 'left');
  const centerPanels = allPanels.filter(panel => panel.align === 'center');
  const rightPanels = allPanels.filter(panel => panel.align === 'right');
  const handleTabChange = tabs => {
    const leftPanels = panelIdtoPanel(tabs[0].Items, 'left');
    const centerPanels = panelIdtoPanel(tabs[1].Items, 'center');
    const rightPanels = panelIdtoPanel(tabs[2].Items, 'right');
    const newStatusbarPanels = [...leftPanels, ...centerPanels, ...rightPanels];
    props.onChange(Object.assign(Object.assign({}, props.gridOptions), {
      statusBar: Object.assign(Object.assign({}, props.gridOptions.statusBar), {
        statusPanels: newStatusbarPanels
      })
    }));
  };
  const disabled = false;
  const panelToTabItemId = panel => {
    var _a;
    return (_a = panel.key) !== null && _a !== void 0 ? _a : panel.statusPanel;
  };
  const tabs = [{
    Name: 'Left',
    Items: leftPanels.map(panelToTabItemId)
  }, {
    Name: 'Center',
    Items: centerPanels.map(panelToTabItemId)
  }, {
    Name: 'Right',
    Items: rightPanels.map(panelToTabItemId)
  }];
  const allOptions = [{
    Id: 'agTotalRowCountComponent',
    Title: 'Row Count'
  }, {
    Id: 'agTotalAndFilteredRowCountComponent',
    Title: 'Totals and Filtered Row Count'
  }, {
    Id: 'agFilteredRowCountComponent',
    Title: 'Filtered Row Count'
  }, {
    Id: 'agSelectedRowCountComponent',
    Title: 'Selected Row Count'
  }, {
    Id: 'agAggregationComponent',
    Title: 'Aggregation'
  }];
  const availableItems = allOptions;
  const [adaptablePanelTitle, setAdaptablePanelTitle] = React.useState('');
  const [adaptablePanelAlign, setAdaptablePanelAlign] = React.useState('left');
  const isAdaptablePanelTitleAvailable = !allPanels.some(panel => panel.key === adaptablePanelTitle);
  const alignOptions = [{
    onClick: () => setAdaptablePanelAlign('left'),
    value: 'left',
    label: 'Left'
  }, {
    onClick: () => setAdaptablePanelAlign('center'),
    value: 'center',
    label: 'Center'
  }, {
    onClick: () => setAdaptablePanelAlign('right'),
    value: 'right',
    label: 'Right'
  }];
  const handleNewAdaptablePanel = () => {
    const newAdaptablePanel = {
      statusPanel: 'AdaptableStatusPanel',
      align: adaptablePanelAlign,
      key: adaptablePanelTitle
    };
    const newStatusbarPanels = [...allPanels, newAdaptablePanel];
    setAdaptablePanelTitle('');
    setAdaptablePanelAlign('left');
    props.onChange(Object.assign(Object.assign({}, props.gridOptions), {
      statusBar: Object.assign(Object.assign({}, props.gridOptions.statusBar), {
        statusPanels: newStatusbarPanels
      })
    }));
  };
  return React.createElement(Box, null, React.createElement(HelpBlock, null, "Status Bar"), React.createElement(Box, {
    p: 2
  }, React.createElement(NocodeWizardFormBox, null, React.createElement(Box, {
    as: "b"
  }, "Create Adaptable Statusbar Panel"), React.createElement(FormLayout, {
    mt: 2
  }, React.createElement(FormRow, {
    label: "Panel Name"
  }, React.createElement(Input, {
    value: adaptablePanelTitle,
    placeholder: 'Adaptable Panel Name',
    onChange: event => setAdaptablePanelTitle(event.target.value)
  })), React.createElement(FormRow, {
    label: "Align"
  }, React.createElement(DropdownButton, {
    columns: ['label'],
    items: alignOptions
  }, (_d = alignOptions.find(option => option.value === adaptablePanelAlign)) === null || _d === void 0 ? void 0 : _d.label)), React.createElement(FormRow, {
    label: ""
  }, React.createElement(SimpleButton, {
    disabled: !isAdaptablePanelTitleAvailable,
    onClick: handleNewAdaptablePanel,
    icon: "plus",
    variant: "raised"
  }, "Add"), !isAdaptablePanelTitleAvailable && React.createElement(Box, {
    mt: 2,
    color: "var(--ab-color-error)"
  }, "Adaptable panel name must be unique.")))), React.createElement(ModuleManager, {
    permittedActions: {
      createTab: false,
      dragAndDropTab: false,
      deleteTab: false,
      editTabName: false
    },
    onTabsChange: handleTabChange,
    disabled: disabled,
    tabs: tabs,
    availableItems: availableItems,
    tabsTitle: 'Status Bar Panels',
    unusedPanelTitle: "Ag Grid Status Bar Panels",
    dragItemText: "Drag into a Status Bar Panel below"
  })));
};