export class RowEditService {
  constructor(adaptableApi) {
    this.adaptableApi = adaptableApi;
    const adaptableInstance = this.adaptableApi.internalApi.getAdaptableInstance();
    if (adaptableInstance.adaptableOptions.actionRowOptions.autoHandle) {
      adaptableInstance.api.eventApi.on('ActionRowSubmitted', eventInfo => this.autoHandleActionRowButtons(eventInfo));
    }
  }
  autoHandleActionRowButtons(actionRowSubmittedInfo) {
    const {
      adaptableApi
    } = actionRowSubmittedInfo;
    if (actionRowSubmittedInfo.type === 'rowEdited') {
      const {
        rowNode,
        formData
      } = actionRowSubmittedInfo;
      const newRowData = Object.assign(Object.assign({}, rowNode.data), formData);
      adaptableApi.gridApi.updateGridData([newRowData]);
    }
    if (actionRowSubmittedInfo.type === 'rowDeleted') {
      const {
        rowNode
      } = actionRowSubmittedInfo;
      const deletedRowData = rowNode.data;
      adaptableApi.gridApi.deleteGridData([deletedRowData]);
    }
    if (actionRowSubmittedInfo.type === 'rowCreated') {
      const {
        formData
      } = actionRowSubmittedInfo;
      const setPrimaryKeyValueFunction = adaptableApi.internalApi.getAdaptableInstance().adaptableOptions.actionRowOptions.setPrimaryKeyValue;
      const newRowData = typeof setPrimaryKeyValueFunction === 'function' ? setPrimaryKeyValueFunction(Object.assign({
        rowData: formData
      }, adaptableApi.internalApi.buildBaseContext())) : Object.assign({}, formData);
      const config = {
        addIndex: 0
      };
      adaptableApi.gridApi.addGridData([newRowData], config);
    }
  }
  destroy() {
    // nothing yet
  }
}