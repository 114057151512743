import { AdaptableModuleBase } from './AdaptableModuleBase';
import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
import * as DashboardRedux from '../Redux/ActionsReducers/DashboardRedux';
import ArrayExtensions from '../Utilities/Extensions/ArrayExtensions';
export class DashboardModule extends AdaptableModuleBase {
  constructor(api) {
    super(ModuleConstants.DashboardModuleId, ModuleConstants.DashboardFriendlyName, 'dashboard', 'DashboardPopup', 'The Dashboard - usually placed above the Grid - is designed to provide quick access to commonly required AdapTable functionalty', api);
  }
  onAdaptableReady() {
    // we used to create a default tab here but we no longer do // this.api.internalApi.setDefaultDashboardTab();
    // this handler reacts to the 'AdaptableReady' event,
    // but so do all the other possible handlers of the 'DashboardChanged' event
    // therefore, we give them a head start to subscribe to 'DashboardChanged' before emitting it
    setTimeout(() => {
      if (this.api.isDestroyed()) {
        return;
      }
      this.api.dashboardApi.internalApi.fireDashboardChangedEvent('ADAPTABLE_READY', null, this.api.dashboardApi.getDashboardState());
    }, 20);
  }
  createColumnMenuItems() {
    return this.buildMenuItems();
  }
  createContextMenuItems(menuContext) {
    return this.buildMenuItems();
  }
  buildMenuItems() {
    if (this.isModuleAvailable()) {
      let menuItems = [];
      const dashboardState = this.api.dashboardApi.getDashboardState();
      if (this.isModuleEditable()) {
        menuItems.push(this.createMenuItemClickFunction('dashboard-configure', 'Configure', 'settings', () => this.api.dashboardApi.openDashboardSettingsPanel()));
      }
      if (ArrayExtensions.IsNotNullOrEmpty(dashboardState.Tabs)) {
        if (dashboardState.IsCollapsed) {
          menuItems.push(this.createMenuItemReduxAction('dashboard-expand', 'Expand', 'expand', DashboardRedux.DashboardSetIsCollapsed(false)));
        } else {
          menuItems.push(this.createMenuItemReduxAction('dashboard-collapse', 'Collapse', 'collapse', DashboardRedux.DashboardSetIsCollapsed(true)));
        }
      }
      if (dashboardState.IsFloating) {
        menuItems.push(this.createMenuItemReduxAction('dashboard-dock', 'Dock', 'dock', DashboardRedux.DashboardSetIsFloating(false)));
      } else if (this.api.optionsApi.getDashboardOptions().canFloat) {
        menuItems.push(this.createMenuItemReduxAction('dashboard-float', 'Float', 'dock', DashboardRedux.DashboardSetIsFloating(true)));
      }
      if (dashboardState.IsHidden) {
        menuItems.push(this.createMenuItemReduxAction('dashboard-show', 'Show', 'visibility-on-bold', DashboardRedux.DashboardSetIsHidden(false)));
      } else {
        menuItems.push(this.createMenuItemReduxAction('dashboard-hide', 'Hide', 'visibility-off-bold', DashboardRedux.DashboardSetIsHidden(true)));
      }
      return menuItems;
    }
  }
}