import { Box } from 'rebass';
import * as React from 'react';
import { Tag } from '../Tag';
export const ExpressionFunctionDocumentation = props => {
  return props.expressionFunction ? React.createElement(Box, {
    "data-name": "expression-editor-documentation",
    my: 2,
    p: 2,
    style: {
      background: 'var(--ab-color-primary)',
      borderRadius: 'var(--ab__border-radius)'
    }
  }, props.expressionFunction.description && React.createElement(Box, {
    mb: 3,
    style: {
      fontWeight: 'bold',
      whiteSpace: 'pre-wrap'
    },
    "data-name": "expression-editor-documentation-description"
  }, props.expressionFunction.description), props.expressionFunction.signatures && React.createElement(Box, {
    "data-name": "expression-editor-documentation-signatures"
  }, React.createElement("b", null, "Signatures"), React.createElement("ul", null, props.expressionFunction.signatures.map((signature, index) => React.createElement("li", {
    key: index
  }, React.createElement("pre", {
    style: {
      whiteSpace: 'pre-wrap',
      margin: 0
    }
  }, signature))))), props.expressionFunction.examples && React.createElement(Box, {
    "data-name": "expression-editor-documentation-examples"
  }, React.createElement("b", null, "Examples"), React.createElement("ul", null, props.expressionFunction.examples.map((example, index) => React.createElement("li", {
    key: index
  }, React.createElement("pre", {
    style: {
      whiteSpace: 'pre-wrap',
      margin: 0
    }
  }, example))))), props.expressionFunction.returnType && React.createElement(Box, {
    "data-name": "expression-editor-documentation-examples"
  }, React.createElement("b", null, "Return Type"), React.createElement(Tag, null, props.expressionFunction.returnType))) : null;
};