import * as React from 'react';
import SimpleButton from '../../../components/SimpleButton';
export class ButtonClear extends React.Component {
  render() {
    return React.createElement(SimpleButton, Object.assign({
      "data-name": "clear",
      tooltip: "Clear",
      iconSize: 20,
      icon: this.props.showIcon == false ? '' : 'close'
    }, this.props, {
      variant: "text"
    }), this.props.showText ? 'Clear' : '');
  }
}