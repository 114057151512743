import * as React from 'react';
import { Flex } from 'rebass';
import { CheckBox } from '../../../../components/CheckBox';
import FormLayout, { FormRow } from '../../../../components/FormLayout';
import { Tabs } from '../../../../components/Tabs';
import { DataPreview } from './DataPreview';
const getRowErrors = (errors, primaryKeyValue) => {
  if (!errors) {
    return null;
  }
  if (!errors[primaryKeyValue]) {
    return null;
  }
  return errors[primaryKeyValue];
};
export const ValidationSection = props => {
  return React.createElement(Flex, {
    flexDirection: "column",
    p: 2,
    height: "100%"
  }, React.createElement(Tabs, {
    mb: 2
  }, React.createElement(Tabs.Tab, null, "Import Options"), React.createElement(Tabs.Content, null, React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Exclude Invalid Rows"
  }, React.createElement(CheckBox, {
    onChange: () => {
      props.onSkipInvalidRowsChange(!props.skipInvalidRows);
    },
    checked: props.skipInvalidRows
  }))))), React.createElement(Tabs, {
    flex: 1
  }, React.createElement(Tabs.Tab, null, "Preview"), React.createElement(Tabs.Content, null, React.createElement(DataPreview, {
    columnsMap: props.columnsMap,
    data: props.data,
    errors: props.errors,
    editable: true,
    onDataChange: props.onDataChange
  }))));
};