import * as React from 'react';
import { useOnePageAdaptableWizardContext } from '../../../Wizard/OnePageAdaptableWizard';
import { ScheduleType } from '../../../../PredefinedConfig/Common/Enums';
import { ScheduleSettingsReminder } from './ScheduleSettingsReminder';
import { ScheduleSettingsReport } from './ScheduleSettingsReport';
import { ScheduleSettingsIPushPull } from './ScheduleSettingsIPushPull';
import { ScheduleSettingsOpenFin } from './ScheduleSettingsOpenFin';
export const ScheduleSettingsWizard = props => {
  const {
    data,
    api
  } = useOnePageAdaptableWizardContext();
  const allReports = api.exportApi.getReports();
  if ((data === null || data === void 0 ? void 0 : data.ScheduleType) === ScheduleType.Reminder) {
    return React.createElement(ScheduleSettingsReminder, {
      reminderSchedule: data,
      onChange: props.onChange
    });
  }
  if ((data === null || data === void 0 ? void 0 : data.ScheduleType) === ScheduleType.Report) {
    const customDestinations = api.exportApi.getCustomDestinations();
    return React.createElement(ScheduleSettingsReport, {
      allReports: allReports !== null && allReports !== void 0 ? allReports : [],
      customDestinations: customDestinations || [],
      report: data,
      onChange: props.onChange
    });
  }
  if ((data === null || data === void 0 ? void 0 : data.ScheduleType) === ScheduleType.ipushpull) {
    const ippApi = api.pluginsApi.getipushpullPluginApi();
    const allFolders = ippApi && ippApi.getIPushPullDomains();
    const folderName = data.IPushPullReport.Folder;
    const availablePages = ippApi && folderName && ippApi.getPagesForIPushPullDomain(folderName);
    return React.createElement(ScheduleSettingsIPushPull, {
      allReports: allReports || [],
      allFolders: allFolders || [],
      availablePages: availablePages || [],
      iPushPull: data,
      onChange: props.onChange
    });
  }
  if ((data === null || data === void 0 ? void 0 : data.ScheduleType) === ScheduleType.OpenFin) {
    return React.createElement(ScheduleSettingsOpenFin, {
      allReports: allReports || [],
      openFin: data,
      onChange: props.onChange
    });
  }
  return React.createElement(React.Fragment, null);
};