export class VersionUpgrade {
  constructor(logger) {
    this.logger = logger;
  }
  migrateStateWithMethods(state, methods) {
    for (let method of methods) {
      state = method.call(this, state);
    }
    return state;
  }
}