import * as React from 'react';
import { Box } from 'rebass';
import join from '../utils/join';
export const Tag = React.forwardRef((props, ref) => {
  var _a, _b, _c, _d;
  return React.createElement(Box, Object.assign({}, props, {
    ref: ref,
    className: join('ab-Tag', props.className),
    backgroundColor: "primary",
    paddingTop: (_a = props.paddingTop) !== null && _a !== void 0 ? _a : 1,
    paddingBottom: (_b = props.paddingBottom) !== null && _b !== void 0 ? _b : 1,
    paddingLeft: (_c = props.paddingLeft) !== null && _c !== void 0 ? _c : 2,
    paddingRight: (_d = props.paddingRight) !== null && _d !== void 0 ? _d : 2,
    style: Object.assign({
      borderRadius: 'var(--ab__border-radius)'
    }, props.style)
  }));
});