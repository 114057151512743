import * as React from 'react';
export const Logo = props => React.createElement("svg", Object.assign({
  width: "45",
  height: "45",
  viewBox: "0 0 45 45",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), React.createElement("path", {
  d: "M0 8C0 3.58172 3.58172 0 8 0H12.6699V12.6H0V8Z",
  fill: "#004C79"
}), React.createElement("path", {
  d: "M16.165 0H36.9999C41.4182 0 45 3.58172 45 8V12.6H16.165V0Z",
  fill: "#004C79"
}), React.createElement("path", {
  d: "M0 32.4H28.8349V45H8C3.58173 45 0 41.4183 0 37V32.4Z",
  fill: "#004C79"
}), React.createElement("path", {
  d: "M32.3301 32.4H45V37C45 41.4183 41.4182 45 37 45H32.3301V32.4Z",
  fill: "#004C79"
}), React.createElement("rect", {
  x: "32.3301",
  y: "16.2001",
  width: "12.6699",
  height: "12.6",
  fill: "#004C79"
}), React.createElement("rect", {
  x: "16.165",
  y: "16.2001",
  width: "12.6699",
  height: "12.6",
  fill: "#00CAF2"
}), React.createElement("rect", {
  y: "16.2001",
  width: "12.6699",
  height: "12.6",
  fill: "#004C79"
}));