import * as React from 'react';
import { AgGridModulesDocsLink } from '../Utilities/Constants/DocumentationLinkConstants';
import { AdaptableLogger } from './AdaptableLogger';
import { AdaptableAgGrid } from './AdaptableAgGrid';
import { ModuleRegistry } from '@ag-grid-community/core';
import { getDefaultAdaptableOptions } from './defaultAdaptableOptions';
import { renderReactRoot as defaultRenderReactRoot } from '../renderReactRoot';
import AdaptableWizardView from '../View/AdaptableWizardView';
export class Adaptable {
  /**
   * Initializer for Adaptable
   *
   * @param adaptableOptions the AdaptableOptions
   * @param agGridConfig AG Grid
   * @returns a Promise containing the AdaptableAPI object in order to enable run-time access to Adaptable's properties and functions
   */
  static init(adaptableOptions, agGridConfig) {
    var _a;
    if (!((_a = agGridConfig === null || agGridConfig === void 0 ? void 0 : agGridConfig.modules) === null || _a === void 0 ? void 0 : _a.length)) {
      AdaptableLogger.consoleErrorBase(`No AG Grid modules were provided, thus limiting AdapTables features significantly!\n\nRead further info here:${AgGridModulesDocsLink}`);
    }
    return AdaptableAgGrid._initInternal(Object.assign(Object.assign({}, agGridConfig), {
      adaptableOptions,
      variant: 'vanilla'
    }));
  }
}
export class AdaptableNoCodeWizard {
  /**
   * @param adaptableOptions
   */
  constructor(adaptableOptions, extraOptions = {}, agGridOptons) {
    const defaultInit = async ({
      gridOptions,
      adaptableOptions,
      agGridModules
    }) => {
      return await Adaptable.init(adaptableOptions, {
        gridOptions,
        modules: agGridModules
      });
    };
    this.adaptableOptions = adaptableOptions;
    this.init = extraOptions.onInit || defaultInit;
    this.extraOptions = extraOptions;
    this.agGridModules = ModuleRegistry.__getRegisteredModules(agGridOptons === null || agGridOptons === void 0 ? void 0 : agGridOptons.gridOptions.gridId);
    this.render();
  }
  render(container) {
    var _a;
    let id = getDefaultAdaptableOptions().containerOptions.adaptableContainer || 'adaptable';
    if (!container) {
      let containerOptionsContainer = (_a = this.adaptableOptions.containerOptions) === null || _a === void 0 ? void 0 : _a.adaptableContainer;
      if (containerOptionsContainer) {
        if (typeof containerOptionsContainer === 'string') {
          id = containerOptionsContainer !== null && containerOptionsContainer !== void 0 ? containerOptionsContainer : id;
        } else {
          container = containerOptionsContainer;
        }
      }
    }
    container = container || document.getElementById(id);
    if (!container) {
      throw new Error('Cannot find container in which to render Adaptable No Code Wizard');
    }
    // this allows people to customize the wizard dimensions & styling
    // when it's visible
    container.classList.add('adaptable--in-wizard');
    const unmount = defaultRenderReactRoot(React.createElement(AdaptableWizardView, Object.assign(Object.assign({
      adaptableOptions: this.adaptableOptions
    }, this.extraOptions), {
      onInit: (adaptableOptions, gridOptions) => {
        container.classList.remove('adaptable--in-wizard');
        unmount();
        this.init({
          adaptableOptions,
          gridOptions: gridOptions,
          agGridModules: [...this.agGridModules]
        });
      }
    })), container);
  }
}