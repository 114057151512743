const r = 3988292384;
function n(n = r) {
  var t,
    o,
    u,
    e = new Array();
  for (t = 0; t < 256; t++) {
    for (u = t, o = 8; o > 0; o--) 1 & ~u ? u >>>= 1 : u = u >>> 1 ^ n;
    e[t] = u;
  }
  return e;
}
function t() {
  return 4294967295;
}
function o(r, n, t) {
  return n = n >>> 8 ^ r[t ^ 255 & n];
}
function u(r) {
  return r = (r = ~r) < 0 ? 4294967295 + r + 1 : r;
}
export function compute_string(e, c = r) {
  var f,
    i = n(c),
    a = 0;
  for (a = t(), f = 0; f < e.length; f++) a = o(i, a, e.charCodeAt(f));
  return `${a = u(a)}`;
}