import * as React from 'react';
import { Flex, Box } from 'rebass';
import join from '../../../../components/utils/join';
import { ButtonInfo } from '../../Buttons/ButtonInfo';
const Noop = () => React.createElement(React.Fragment, null);
export const PopupPanel = props => {
  const baseClassName = 'ab-Adaptable-Popup__Panel';
  return React.createElement(Flex, {
    flexDirection: "column",
    flex: "1 1 0",
    className: join(props.className, baseClassName)
  }, React.createElement("div", {
    className: `${baseClassName}__Header`
  }, React.createElement(Flex, {
    alignItems: "center"
  }, React.createElement(Box, {
    marginRight: 0,
    "data-name": "space-before"
  }), React.createElement("div", {
    className: `${baseClassName}__Header__Title`
  }, props.headerText), React.createElement(Box, {
    marginRight: 2,
    "data-name": "space-after"
  }), props.infoLink != null && !props.infoLinkDisabled && React.createElement(ButtonInfo, {
    tone: "none",
    variant: "text",
    iconSize: 24,
    onClick: () => window.open(props.infoLink, '_blank' // <- opens the link in a new tab or window.
    )
  })), React.createElement(Box, {
    style: {
      flex: 1
    }
  }), props.button), React.createElement(Box, {
    className: `${baseClassName}__Body`,
    width: "100%",
    height: "100%",
    flex: 1
  }, props.children));
};