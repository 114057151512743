import * as PluginsRedux from '../../Redux/ActionsReducers/PluginsRedux';
import { ApiBase } from './ApiBase';
export class PluginsApiImpl extends ApiBase {
  getPluginsState() {
    return this.getAdaptableState().Plugins;
  }
  getPluginState(pluginId) {
    return this.getPluginsState()[pluginId];
  }
  registerPlugin(pluginId, initialPluginState) {
    this.setPluginState(pluginId, initialPluginState);
  }
  setPluginState(pluginId, newPluginState) {
    this.dispatchAction(PluginsRedux.PluginsSetPluginState(pluginId, newPluginState));
  }
  getPluginApi(pluginId) {
    try {
      const api = this.adaptable.getPluginProperty(pluginId, 'api');
      if (pluginId === 'ipushpull') {
        return api;
      }
      if (pluginId === 'openfin') {
        return api;
      }
      if (pluginId === 'interopio') {
        return api;
      }
      return api;
    } catch (ex) {
      return null;
    }
  }
  getipushpullPluginApi() {
    return this.getPluginApi('ipushpull');
  }
  getInteropioPluginApi() {
    return this.getPluginApi('interopio');
  }
  getOpenFinPluginApi() {
    return this.getPluginApi('openfin');
  }
}