import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAdaptable } from '../AdaptableContext';
import { AdaptableFormControlTextClear } from '../Components/Forms/AdaptableFormControlTextClear';
import * as QuickSearchRedux from '../../Redux/ActionsReducers/QuickSearchRedux';
export const QuickSearchStatusBarContent = () => {
  const text = useSelector(state => state.QuickSearch.QuickSearchText);
  const dispatch = useDispatch();
  const adaptable = useAdaptable();
  const handleTextChange = React.useCallback(text => dispatch(QuickSearchRedux.QuickSearchRun(text)), []);
  return React.createElement(AdaptableFormControlTextClear, {
    onClick: event => event.stopPropagation(),
    type: "text",
    inputStyle: {
      padding: '2px 3px',
      width: 90
    },
    placeholder: adaptable.api.optionsApi.getQuickSearchOptions().quickSearchPlaceholder,
    value: text,
    OnTextChange: handleTextChange
  });
};