import { AdaptableModuleBase } from './AdaptableModuleBase';
import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
import * as CustomSortRedux from '../Redux/ActionsReducers/CustomSortRedux';
import { CustomSortWizard } from '../View/CustomSort/Wizard/CustomSortWizard';
import { getCustomSortColumnViewItems } from './Utilities/CustomSort/getCustomSortColumnViewItems';
import { getCustomSortSortOrderViewItems } from './Utilities/CustomSort/getCustomSortSortOrderViewItems';
import { getObjectTagsViewItems } from './Utilities/getObjectTagsViewItems';
export class CustomSortModule extends AdaptableModuleBase {
  constructor(api) {
    super(ModuleConstants.CustomSortModuleId, ModuleConstants.CustomSortFriendlyName, 'import-export', 'CustomSortPopup', 'Provide bespoke sorting information for Columns (in place of alphabetical sorting)', api);
  }
  getModuleAdaptableObjects(config) {
    return this.api.customSortApi.getCustomSorts(config);
  }
  getExplicitlyReferencedColumnIds(customSort) {
    return [customSort.ColumnId];
  }
  hasNamedQueryReferences() {
    return false;
  }
  createColumnMenuItems(column) {
    if (column && this.isModuleEditable() && column.sortable) {
      let customSort = this.api.customSortApi.getCustomSorts().find(x => x.ColumnId == column.columnId);
      let label = customSort ? 'Edit ' : 'Create ';
      // dont show a menu item if there is a custom sort that uses a comparer function
      const columnSortComparer = this.api.customSortApi.internalApi.getCustomSortComparer(column.columnId);
      if (columnSortComparer) {
        return undefined;
      }
      let popupParam = {
        column: column,
        action: customSort ? 'Edit' : 'New',
        source: 'ColumnMenu'
      };
      let name = customSort ? 'custom-sort-edit' : 'custom-sort-add';
      return [this.createMenuItemShowPopup(name, label + 'Custom Sort', this.moduleInfo.Popup, this.moduleInfo.Glyph, popupParam)];
    }
  }
  getTeamSharingAction() {
    return {
      ModuleEntities: this.api.customSortApi.getCustomSorts(),
      AddAction: CustomSortRedux.CustomSortAdd,
      EditAction: CustomSortRedux.CustomSortEdit
    };
  }
  toView(customSort) {
    return {
      items: [getCustomSortColumnViewItems(customSort, this.api), getCustomSortSortOrderViewItems(customSort), getObjectTagsViewItems(customSort, this.api)],
      abObject: customSort
    };
  }
  toViewAll() {
    return this.getModuleAdaptableObjects({
      includeLayoutNotAssociatedObjects: this.showLayoutNotAssociatedObjects()
    }).map(customSort => this.toView(customSort));
  }
  getViewProperties() {
    return {
      getEditAction: CustomSortRedux.CustomSortEdit,
      getDeleteAction: CustomSortRedux.CustomSortDelete,
      getSuspendAction: CustomSortRedux.CustomSortSuspend,
      getUnSuspendAction: CustomSortRedux.CustomSortUnSuspend,
      getSuspendAllAction: CustomSortRedux.CustomSortSuspendAll,
      getUnSuspendAllAction: CustomSortRedux.CustomSortUnSuspendAll,
      emptyView: "Click 'New' to create a custom Sort Order for a selected Column",
      getEditWizard() {
        return CustomSortWizard;
      }
    };
  }
  canBeAssociatedWithLayouts() {
    return true;
  }
}