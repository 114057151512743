import { SmartEditPopup } from './SmartEdit/SmartEditPopup';
import { QuickSearchPopup } from './QuickSearch/QuickSearchPopup';
import { ThemePopup } from './Theme/ThemePopup';
import { ToolPanelPopup } from './Components/ToolPanel/ToolPanelPopup';
import { GridInfoPopup } from './GridInfo/GridInfoPopup/GridInfoPopup';
import { DashboardPopup } from './Dashboard/DashboardPopup';
import { StateManagementPopup } from './StateManagement/StateManagementPopup';
import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
import { BulkUpdatePopup } from './BulkUpdate/BulkUpdatePopup';
import { CellSummaryPopup } from './CellSummary/CellSummaryPopup';
import { SystemStatusPopup } from './SystemStatus/SystemStatusPopup';
import { AlertViewPanelControl } from './Alert/AlertViewPanel';
import { BulkUpdateViewPanelControl } from './BulkUpdate/BulkUpdateViewPanel';
import { CellSummaryViewPanelControl } from './CellSummary/CellSummaryViewPanel';
import { FilterViewPanelControl } from './Filter/FilterViewPanel';
import { DashboardViewPanelControl } from './Dashboard/DashboardViewPanel';
import { ExportViewPanelControl } from './Export/ExportViewPanel';
import { LayoutViewPanelControl } from './Layout/LayoutViewPanel';
import { GridFilterPanelControl } from './GridFilter/GridFilterViewPanel';
import { QuickSearchViewPanelControl } from './QuickSearch/QuickSearchViewPanel';
import { SmartEditViewPanelControl } from './SmartEdit/SmartEditViewPanel';
import { SystemStatusViewPanel } from './SystemStatus/SystemStatusViewPanel';
import { ThemeViewPanelControl } from './Theme/ThemeViewPanel';
import { DataSetViewPanelControl } from './DataSet/DataSetViewPanel';
import { DataChangeHistoryPopup } from './DataChangeHistory/DataChangeHistoryPopup';
import { DataChangeHistoryViewPanelControl } from './DataChangeHistory/DataChangeHistoryViewPanel';
import { StateManagementViewPanel } from './StateManagement/StateManagementViewPanel';
import { StatusBarPopup } from './StatusBar/StatusBarPopup';
import { ChartingViewPanel } from './Charting/ChartingViewPanel';
import { GridFilterPopup } from './GridFilter/GridFilterPopup';
import { ColumnInfoPopup } from './ColumnInfo/ColumnInfoPopup';
import { NotePopup } from './Note/NotePopup';
import { CommentsPopup } from './Comments/CommentsPopup';
import { DataImportPopup } from './DataImport/DataImportPopup';
export const AdaptableViewFactory = {
  BulkUpdatePopup,
  DashboardPopup,
  StateManagementPopup,
  GridInfoPopup,
  ColumnInfoPopup,
  StatusBarPopup,
  QuickSearchPopup,
  CellSummaryPopup,
  SmartEditPopup,
  ThemePopup,
  ToolPanelPopup,
  SystemStatusPopup,
  DataChangeHistoryPopup,
  NotePopup,
  CommentsPopup,
  DataImportPopup,
  GridFilterPopup
};
export const AdaptableViewPanelFactory = new Map([[ModuleConstants.AlertModuleId, AlertViewPanelControl], [ModuleConstants.BulkUpdateModuleId, BulkUpdateViewPanelControl], [ModuleConstants.CellSummaryModuleId, CellSummaryViewPanelControl], [ModuleConstants.ColumnFilterModuleId, FilterViewPanelControl], [ModuleConstants.DashboardModuleId, DashboardViewPanelControl], [ModuleConstants.ExportModuleId, ExportViewPanelControl], [ModuleConstants.LayoutModuleId, LayoutViewPanelControl], [ModuleConstants.GridFilterModuleId, GridFilterPanelControl], [ModuleConstants.QuickSearchModuleId, QuickSearchViewPanelControl], [ModuleConstants.SmartEditModuleId, SmartEditViewPanelControl], [ModuleConstants.SystemStatusModuleId, SystemStatusViewPanel], [ModuleConstants.ThemeModuleId, ThemeViewPanelControl], [ModuleConstants.DataSetModuleId, DataSetViewPanelControl], [ModuleConstants.DataChangeHistoryModuleId, DataChangeHistoryViewPanelControl], [ModuleConstants.StateManagementModuleId, StateManagementViewPanel], [ModuleConstants.ChartingModuleId, ChartingViewPanel]]);