export const getCalculatedColumnSettingsTags = settings => {
  const {
    DataType,
    Width,
    Filterable,
    Resizable,
    Groupable,
    Sortable,
    Pivotable,
    Aggregatable
  } = settings !== null && settings !== void 0 ? settings : {};
  const colTypes = (settings === null || settings === void 0 ? void 0 : settings.ColumnTypes) ? settings.ColumnTypes.join(', ') : '';
  return [DataType ? `DataType: ${DataType}` : null, Width ? `Width: ${Width}px` : null, Pivotable ? 'Pivotable' : null, Filterable ? 'Filterable' : null, Resizable ? 'Resizable' : null, Groupable ? 'Groupable' : null, Sortable ? 'Sortable' : null, Aggregatable ? 'Aggregatable' : null, colTypes ? `Column Types: ${colTypes}` : null].filter(Boolean);
};