import * as React from 'react';
import { Box, Flex } from 'rebass';
import { useChartingElements } from './useChartingElements';
export const ChartingViewPanel = props => {
  const elementType = props.viewType === 'Toolbar' ? 'DashboardToolbar' : 'ToolPanel';
  const {
    chartSelector,
    containerSelector,
    chartButton,
    deleteButton,
    editButton
  } = useChartingElements({
    elementType,
    accessLevel: props.accessLevel
  });
  if (elementType === 'DashboardToolbar') {
    return React.createElement(Flex, {
      width: "100%",
      alignItems: "center"
    }, React.createElement(Box, null, chartSelector), containerSelector && React.createElement(Box, {
      ml: 1
    }, containerSelector), React.createElement(Box, {
      ml: 1
    }, chartButton, deleteButton, editButton));
  }
  return React.createElement(Flex, {
    flexDirection: "column",
    width: "100%"
  }, React.createElement(Box, {
    mb: 1
  }, chartSelector), containerSelector && React.createElement(Box, {
    mb: 1
  }, containerSelector), React.createElement(Box, null, chartButton, " ", deleteButton, " ", editButton));
};