import * as PopupRedux from '../Redux/ActionsReducers/PopupRedux';
// A menu item which performs a Redux Action when it is clicke
export class MenuItemDoReduxAction {
  constructor(name, label, module, reduxAction, icon, isVisible) {
    this.name = name;
    this.label = label;
    this.module = module;
    this.isVisible = isVisible;
    this.icon = {
      name: icon
    };
    this.reduxAction = reduxAction;
  }
}
export class MenuItemDoClickFunction {
  constructor(name, label, module, clickFunction, icon, isVisible) {
    this.name = name;
    this.label = label;
    this.module = module;
    this.isVisible = isVisible;
    this.icon = {
      name: icon
    };
    this.onClick = clickFunction;
  }
}
// A menu item which shows a popup screen when it is clieked
export class MenuItemShowPopup {
  constructor(name, label, module, componentName, icon, isVisible, popupParams) {
    this.name = name;
    this.label = label;
    this.module = module;
    this.isVisible = isVisible;
    this.icon = {
      name: icon
    };
    this.reduxAction = PopupRedux.PopupShowScreen(module, componentName, popupParams);
  }
}