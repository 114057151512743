import * as React from 'react';
import { Flex } from 'rebass';
import { NewScopeComponent } from '../../Components/NewScopeComponent';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
export const FlashingAlertScopeWizardSection = props => {
  const {
    data,
    api
  } = useOnePageAdaptableWizardContext();
  return React.createElement(Flex, {
    flexDirection: "column",
    style: {
      height: '100%'
    },
    padding: 2
  }, React.createElement(NewScopeComponent, {
    descriptions: {
      rowScope: 'Changes anywhere in the row will trigger an Flashing Cell',
      columnScope: 'Changes in selected columns will trigger an Flashing Cell'
    },
    scope: data.Scope,
    updateScope: Scope => {
      const newData = Object.assign(Object.assign({}, data), {
        Scope
      });
      if (newData.Rule.Predicates) {
        // when scope is changed, reset the rule to predicate of AnyChange
        // if it was set to a predicate before
        newData.Rule = {
          Predicates: [{
            PredicateId: 'AnyChange'
          }]
        };
      }
      props.onChange(newData);
    }
  }));
};