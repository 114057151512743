import * as React from 'react';
import { Box } from 'rebass';
import FormLayout, { FormRow } from '../../../../components/FormLayout';
import { Select } from '../../../../components/Select';
import { Tabs } from '../../../../components/Tabs';
const DESTINATIONS = ['Excel', 'CSV', 'Clipboard', 'JSON'];
export const ScheduleSettingsReport = props => {
  var _a, _b;
  const reportOptions = props.allReports.map(report => ({
    label: report.Name,
    value: report.Name
  }));
  const customDestinationNames = props.customDestinations.map(destination => destination.name);
  const destinationOptions = [...DESTINATIONS, ...customDestinationNames].map(destination => ({
    label: destination,
    value: destination,
    onClick: () => {
      props.onChange(Object.assign(Object.assign({}, props.report), {
        ExportDestination: destination
      }));
    }
  }));
  return React.createElement(Box, {
    "data-name": "schedule-settings-report"
  }, React.createElement(Tabs, {
    autoFocus: false,
    mb: 3
  }, React.createElement(Tabs.Tab, null, "Report Schedule Settings"), React.createElement(Tabs.Content, null, React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Export"
  }, React.createElement(Box, {
    maxWidth: 300
  }, React.createElement(Select, {
    "data-name": "select-export",
    options: reportOptions,
    value: (_a = props === null || props === void 0 ? void 0 : props.report) === null || _a === void 0 ? void 0 : _a.ReportName,
    placeholder: "Select Export",
    onChange: value => props.onChange(Object.assign(Object.assign({}, props.report), {
      ReportName: value
    }))
  }))), React.createElement(FormRow, {
    label: "Destination"
  }, React.createElement(Box, {
    maxWidth: 300
  }, React.createElement(Select, {
    "data-name": "select-destination",
    options: destinationOptions,
    value: (_b = props === null || props === void 0 ? void 0 : props.report) === null || _b === void 0 ? void 0 : _b.ExportDestination,
    placeholder: "Select Destination",
    onChange: value => props.onChange(Object.assign(Object.assign({}, props.report), {
      ExportDestination: value
    }))
  })))))));
};