import * as FreeTextColumnRedux from '../../Redux/ActionsReducers/FreeTextColumnRedux';
import { ApiBase } from './ApiBase';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import ArrayExtensions from '../../Utilities/Extensions/ArrayExtensions';
import { FreeTextColumnInternalApi } from '../Internal/FreeTextColumnInternalApi';
export class FreeTextColumnApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.internalApi = new FreeTextColumnInternalApi(adaptable);
  }
  getFreeTextColumnState() {
    return this.getAdaptableState().FreeTextColumn;
  }
  getFreeTextColumns() {
    var _a;
    return (_a = this.getFreeTextColumnState().FreeTextColumns) !== null && _a !== void 0 ? _a : [];
  }
  getFreeTextColumnById(technicalId) {
    return this.getFreeTextColumns().find(ftc => ftc.Uuid === technicalId);
  }
  getFreeTextColumnForColumnId(columnId) {
    return this.getFreeTextColumns().find(ftc => ftc.ColumnId === columnId);
  }
  addFreeTextColumn(freeTextColumn) {
    this.addUidToAdaptableObject(freeTextColumn);
    this.dispatchAction(FreeTextColumnRedux.FreeTextColumnAdd(freeTextColumn));
    return this.getFreeTextColumnById(freeTextColumn === null || freeTextColumn === void 0 ? void 0 : freeTextColumn.Uuid);
  }
  editFreeTextColumn(freeTextColumn) {
    this.dispatchAction(FreeTextColumnRedux.FreeTextColumnEdit(freeTextColumn));
    return this.getFreeTextColumnById(freeTextColumn === null || freeTextColumn === void 0 ? void 0 : freeTextColumn.Uuid);
  }
  setStoredValue(columnId, storedValue) {
    const freeTextColumn = this.getFreeTextColumnForColumnId(columnId);
    if (!freeTextColumn) {
      this.logWarn(`No FreeTextColumn with columnId=${columnId} was found!`);
      return;
    }
    this.dispatchAction(FreeTextColumnRedux.FreeTextColumnAddEditStoredValue(freeTextColumn, storedValue));
    return this.getFreeTextColumnById(freeTextColumn === null || freeTextColumn === void 0 ? void 0 : freeTextColumn.Uuid);
  }
  setStoredValues(columnId, storedValues, replaceAction) {
    const freeTextColumn = this.getFreeTextColumnForColumnId(columnId);
    if (freeTextColumn) {
      if (replaceAction == 'All') {
        freeTextColumn.FreeTextStoredValues = storedValues;
      } else {
        const existingPKValues = ArrayExtensions.IsNotNullOrEmpty(freeTextColumn.FreeTextStoredValues) ? freeTextColumn.FreeTextStoredValues.map(fts => {
          return fts.PrimaryKey;
        }) : [];
        storedValues.forEach(sv => {
          // do summat
          const isExistingPKValue = existingPKValues.find(epk => epk == sv.PrimaryKey);
          if (isExistingPKValue) {
            if (replaceAction == 'Conflicting') {
              let existingItem = freeTextColumn.FreeTextStoredValues.find(fsv => fsv.PrimaryKey == sv.PrimaryKey);
              if (existingItem) {
                existingItem.FreeText = sv.FreeText;
              }
            } else {
              freeTextColumn.FreeTextStoredValues.push(sv);
            }
          } else {
            // no existing value so just add it
            freeTextColumn.FreeTextStoredValues.push(sv);
          }
        });
      }
      this.dispatchAction(FreeTextColumnRedux.FreeTextColumnEdit(freeTextColumn));
    }
  }
  deleteFreeTextColumn(columnId) {
    let freeTextColumn = this.getFreeTextColumns().find(ftc => ftc.ColumnId == columnId);
    if (this.checkItemExists(freeTextColumn, columnId, ModuleConstants.FreeTextColumnModuleId)) {
      this.dispatchAction(FreeTextColumnRedux.FreeTextColumnDelete(freeTextColumn));
    }
  }
  openFreeTextColumnSettingsPanel() {
    this.showModulePopup(ModuleConstants.FreeTextColumnModuleId);
  }
  getFreeTextColumnValueForRowNode(freeTextColumn, rowNode) {
    try {
      if (this.getAdaptableApi().gridApi.isGroupRowNode(rowNode)) {
        return null;
      }
      if (ArrayExtensions.IsNotNullOrEmpty(freeTextColumn.FreeTextStoredValues)) {
        let pkValue = this.adaptable.getPrimaryKeyValueFromRowNode(rowNode);
        let freeTextStoredValue = freeTextColumn.FreeTextStoredValues.find(fdx => fdx.PrimaryKey == pkValue);
        if (freeTextStoredValue) {
          return freeTextStoredValue.FreeText;
        }
      }
      return freeTextColumn.DefaultValue;
    } catch (e) {
      this.logError(e);
      return null;
    }
  }
}