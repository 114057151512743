import * as React from 'react';
import { ExpressionPreview } from '../../../components/ExpressionEditor/ExpressionPreview';
import { Tag } from '../../../components/Tag';
export const getGridFilterViewItems = layout => {
  var _a;
  return {
    name: 'Grid Filters',
    view: React.createElement(Tag, null, React.createElement(ExpressionPreview, {
      query: {
        BooleanExpression: (_a = layout.GridFilter) === null || _a === void 0 ? void 0 : _a.Expression
      }
    }))
  };
};