import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex } from 'rebass';
import { getStatusPanelsSelector } from '../../Redux/ActionsReducers/StatusBarRedux';
import { useAdaptable } from '../AdaptableContext';
import { StatusBarPanel } from './StatusBarPanel';
export const AdaptableStatusBar = props => {
  var _a;
  /**
   * All state is selected so the Status Bar is re-rendered for all adaptable state changes
   */
  const state = useSelector(state => state);
  const statusPanels = getStatusPanelsSelector(state);
  const adaptable = useAdaptable();
  const dispatch = useDispatch();
  const statusSubPanels = statusPanels.find(statusPanel => statusPanel.Key === props.context.Key);
  const allMenuItems = useSelector(state => {
    var _a;
    return (_a = state === null || state === void 0 ? void 0 : state.Grid) === null || _a === void 0 ? void 0 : _a.SettingPanelModuleMenuItems;
  });
  return React.createElement(Flex, {
    className: "ab-StatusBar-Panel",
    "data-id": props.context.Key
  }, (_a = statusSubPanels === null || statusSubPanels === void 0 ? void 0 : statusSubPanels.StatusBarPanels) === null || _a === void 0 ? void 0 : _a.map(subPanel => {
    var _a, _b, _c, _d, _e, _f;
    const module = adaptable.ModuleService.getModuleById(subPanel);
    if (!module) {
      return React.createElement(React.Fragment, {
        key: subPanel
      });
    }
    if (!module.isModuleAvailable()) {
      return React.createElement(React.Fragment, {
        key: subPanel
      });
    }
    /**
     * Both view properties and status panel are optional.
     */
    const statusBarPanelProps = (_d = (_c = (_b = (_a = module.getViewProperties) === null || _a === void 0 ? void 0 : _a.call(module)) === null || _b === void 0 ? void 0 : _b.getStatusBarPanelProps) === null || _c === void 0 ? void 0 : _c.call(_b)) !== null && _d !== void 0 ? _d : {};
    const moduleInfo = module.moduleInfo;
    const onAction = (_e = statusBarPanelProps.onAction) !== null && _e !== void 0 ? _e : () => {
      const menuItem = allMenuItems.find(menuItem => menuItem.module === subPanel);
      if (menuItem === null || menuItem === void 0 ? void 0 : menuItem.reduxAction) {
        dispatch(menuItem.reduxAction);
      }
    };
    return React.createElement(StatusBarPanel, Object.assign({
      "data-name": subPanel
    }, statusBarPanelProps, {
      tooltip: moduleInfo.FriendlyName,
      onAction: onAction,
      icon: (_f = statusBarPanelProps.icon) !== null && _f !== void 0 ? _f : moduleInfo.Glyph,
      key: subPanel
    }));
  }));
};