import * as React from 'react';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { LoaderSpinner } from '../Loader';
import { useAdaptable } from '../../View/AdaptableContext';
import { ExternalRenderer } from '../../View/Components/ExternalRenderer';
import Dialog from '../Dialog';
import { Box, Flex } from 'rebass';
export const ProgressIndicator = () => {
  const adaptable = useAdaptable();
  const {
    active,
    text,
    render,
    frameworkComponent
  } = useSelector(state => state.System.ProgressIndicator);
  const [visible, setVisible] = useState(false);
  const [progressIndicatorCoordinates, setProgressIndicatorCoordinates] = useState({
    top: 0,
    left: 0,
    height: 0,
    width: 0
  });
  const disableAdaptableGrid = (containerElement, disabled) => {
    const DISABLING_CSS_CLASS = 'ab-wait-for-progress-indicator';
    if (!containerElement) {
      return;
    }
    if (disabled) {
      containerElement.classList.add(DISABLING_CSS_CLASS);
    } else {
      containerElement.classList.remove(DISABLING_CSS_CLASS);
    }
  };
  const updateGridContainerCoordinates = containerElement => {
    if (!containerElement) {
      return;
    }
    const {
      top,
      left,
      height,
      width
    } = containerElement.getBoundingClientRect();
    setProgressIndicatorCoordinates({
      top,
      left,
      height,
      width
    });
  };
  useEffect(() => {
    disableAdaptableGrid(adaptable.getAdaptableContainerElement(), active);
    disableAdaptableGrid(adaptable.getAgGridContainerElement(), active);
    updateGridContainerCoordinates(adaptable.getAgGridContainerElement());
    // without rAF the progress indicator would be rendered instantly, without the 'transition-delay' defined via CSS
    requestAnimationFrame(() => {
      setVisible(active);
    });
  }, [active]);
  let customEl = null;
  if (render || frameworkComponent) {
    customEl = React.createElement(ExternalRenderer, {
      render: render,
      frameworkComponent: frameworkComponent
    });
  }
  return React.createElement(React.Fragment, null, active && React.createElement(Dialog, {
    modal: true,
    isOpen: true,
    showCloseButton: false,
    className: `ab-ProgressIndicator ${visible ? 'ab-ProgressIndicator--visible' : ''}`
  }, React.createElement(Flex, {
    alignItems: "center",
    flexDirection: "column",
    p: 3,
    className: "ab-ProgressIndicator-body"
  }, React.createElement(LoaderSpinner, null), React.createElement(Box, {
    mt: 3
  }, text && React.createElement(Box, null, text), customEl))));
};