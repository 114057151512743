import * as React from 'react';
import { useLayoutEffect, useRef, useState } from 'react';
import { useAdaptable } from '../AdaptableContext';
import { AdaptableButtonComponent } from '../Components/AdaptableButton';
import { PanelDashboard } from '../Components/Panels/PanelDashboard';
export const CustomToolbarCmp = props => {
  const {
    api
  } = useAdaptable();
  // dummy object which is used to force a re-render of the toolbar
  const [componentRevision, setComponentRevision] = useState(1);
  const renderContentContainerId = api.dashboardApi.internalApi.getCustomToolbarRenderContainerId(props.customToolbar.name);
  const renderContentContainerRef = useRef(null);
  const [renderedContentHTML, setRenderedContentHTML] = useState('');
  const buttonsContainerId = api.dashboardApi.internalApi.getCustomToolbarButtonsContainerId(props.customToolbar.name);
  const componentContainerId = api.dashboardApi.internalApi.getCustomToolbarComponentContainerId(props.customToolbar.name);
  const componentContainerRef = useRef(null);
  useLayoutEffect(() => {
    const element = renderContentContainerRef.current;
    const adaptableApi = api;
    if (props.customToolbar.render) {
      const html = props.customToolbar.render({
        visible: true,
        phase: 'onMount',
        element,
        adaptableApi,
        userName: adaptableApi.optionsApi.getUserName(),
        adaptableId: adaptableApi.optionsApi.getAdaptableId()
      });
      setRenderedContentHTML(html);
    }
  }, [componentRevision, props.dashboardRevision]);
  useLayoutEffect(() => {
    const element = renderContentContainerRef.current;
    const adaptableApi = api;
    // cleanup runs in a separate effect, this way it's executed only once on unmount
    return () => {
      if (props.customToolbar.render) {
        props.customToolbar.render({
          visible: false,
          phase: 'onDestroy',
          element,
          adaptableApi,
          userName: adaptableApi.optionsApi.getUserName(),
          adaptableId: adaptableApi.optionsApi.getAdaptableId()
        });
      }
    };
  }, []);
  useLayoutEffect(() => {
    const containerElement = componentContainerRef.current;
    const adaptableApi = api;
    if (props.customToolbar.frameworkComponent) {
      adaptableApi.internalApi.createFrameworkComponent(containerElement, props.customToolbar.frameworkComponent, 'toolbar');
    }
    const destroyUnsubscribe = adaptableApi.eventApi.on('AdaptableDestroy', () => {
      var _a;
      (_a = adaptableApi === null || adaptableApi === void 0 ? void 0 : adaptableApi.internalApi) === null || _a === void 0 ? void 0 : _a.destroyFrameworkComponent(containerElement, props.customToolbar.frameworkComponent, 'toolbar');
    });
    return () => {
      var _a;
      destroyUnsubscribe();
      if (props.customToolbar.frameworkComponent) {
        (_a = adaptableApi === null || adaptableApi === void 0 ? void 0 : adaptableApi.internalApi) === null || _a === void 0 ? void 0 : _a.destroyFrameworkComponent(containerElement, props.customToolbar.frameworkComponent, 'toolbar');
      }
    };
  }, []);
  const CustomReactFrameworkComponent = props.customToolbar.frameworkComponent;
  return React.createElement(React.Fragment, null, props.customToolbar.render && React.createElement("div", {
    id: renderContentContainerId,
    ref: renderContentContainerRef,
    className: `ab-CustomToolbar__rendered-content ab-CustomToolbar--${props.customToolbar.name}`,
    style: {
      minHeight: 22
    },
    dangerouslySetInnerHTML: {
      __html: renderedContentHTML
    }
  }), props.customToolbar.toolbarButtons && React.createElement("div", {
    id: buttonsContainerId,
    className: `ab-CustomToolbar__buttons ab-CustomToolbar--${props.customToolbar.name}`,
    style: {
      minHeight: 22
    }
  }, props.customToolbar.toolbarButtons.map((button, index) => {
    const dashboardContext = {
      customToolbar: props.customToolbar,
      adaptableApi: api,
      userName: api.optionsApi.getUserName(),
      adaptableId: api.optionsApi.getAdaptableId(),
      dashboardState: api.dashboardApi.getDashboardState()
    };
    const buttonIcon = api.internalApi.getIconForButton(button, dashboardContext);
    let buttonStyle = api.internalApi.getStyleForButton(button, dashboardContext);
    let buttonLabel = api.internalApi.getLabelForButton(button, dashboardContext);
    let buttonTooltip = api.internalApi.getTooltipForButton(button, dashboardContext);
    if (button.hidden && button.hidden(button, dashboardContext)) {
      return null;
    }
    const disabled = button.disabled && button.disabled(button, dashboardContext);
    let buttonVariant = buttonStyle && buttonStyle.variant ? buttonStyle.variant : 'outlined';
    let buttonTone = buttonStyle && buttonStyle.tone ? buttonStyle.tone : 'neutral';
    return React.createElement(AdaptableButtonComponent, {
      style: {
        marginLeft: index ? 'var(--ab-space-1)' : 0
      },
      key: index,
      disabled: disabled,
      tooltip: buttonTooltip,
      icon: buttonIcon,
      variant: buttonVariant,
      tone: buttonTone,
      className: (buttonStyle === null || buttonStyle === void 0 ? void 0 : buttonStyle.className) || '',
      onClick: () => {
        button.onClick ? button.onClick(button, dashboardContext) : null;
        setTimeout(() => {
          // mutate state to force a re-rendering
          setComponentRevision(componentRevision + 1);
        }, 16);
      }
    }, buttonLabel);
  })), CustomReactFrameworkComponent && React.createElement("div", {
    id: componentContainerId,
    ref: componentContainerRef,
    style: {
      minHeight: 22
    },
    className: `ab-CustomToolbar__component ab-CustomToolbar--${props.customToolbar.name}`
  }, api.gridApi.getVariant() === 'react' ? React.createElement(CustomReactFrameworkComponent, {
    adaptableApi: api
  }) : null));
};
export const CustomToolbarWrapper = props => {
  const {
    api
  } = useAdaptable();
  return React.createElement(PanelDashboard, {
    headerText: props.customToolbar.title ? props.customToolbar.title : '',
    showConfigureButton: props.customToolbar.showConfigureButton != null && props.customToolbar.showConfigureButton == true,
    showCloseButton: props.customToolbar.showCloseButton != null && props.customToolbar.showCloseButton == true,
    onConfigure: () => {
      const customToolbarConfiguredInfo = Object.assign(Object.assign({}, api.internalApi.buildBaseContext()), {
        customToolbar: props.customToolbar
      });
      api.eventApi.emit('CustomToolbarConfigured', customToolbarConfiguredInfo);
    },
    accessLevel: api.entitlementApi.getEntitlementAccessLevelForModule('Dashboard')
  }, React.createElement(CustomToolbarCmp, Object.assign({}, props)));
};