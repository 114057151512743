import isEqual from 'lodash/isEqual';
import { ALL_TOOLBARS } from '../../PredefinedConfig/Common/Types';
import { ApiBase } from '../Implementation/ApiBase';
export class DashboardInternalApi extends ApiBase {
  /**
   * Fires Dashboard Changed Event
   */
  fireDashboardChangedEvent(trigger, oldDashboardState, newDashboardState) {
    const isToolbarStateChangedToVisible = toolbarName => {
      const visibleInNewState = this.isToolbarInActiveTab(toolbarName, newDashboardState);
      const visibleInOldState = this.isToolbarInActiveTab(toolbarName, oldDashboardState);
      if (visibleInNewState && visibleInOldState) {
        // check for dashboard collapse/expand/hidden changes
        return oldDashboardState.IsCollapsed && !newDashboardState.IsCollapsed || oldDashboardState.IsHidden && !newDashboardState.IsHidden;
      }
      return visibleInNewState && !visibleInOldState;
    };
    const isToolbarStateChangedToHidden = toolbarName => {
      const visibleInNewState = this.isToolbarInActiveTab(toolbarName, newDashboardState);
      const visibleInOldState = this.isToolbarInActiveTab(toolbarName, oldDashboardState);
      if (visibleInNewState && visibleInOldState) {
        // check for dashboard collapse/expand/hidden changes
        return !oldDashboardState.IsCollapsed && newDashboardState.IsCollapsed || !oldDashboardState.IsHidden && newDashboardState.IsHidden;
      }
      return visibleInOldState && !visibleInNewState;
    };
    const dashboardChangedInfo = Object.assign(Object.assign({}, this.getAdaptableApi().internalApi.buildBaseContext()), {
      actionName: trigger,
      oldDashboardState,
      newDashboardState,
      isToolbarStateChangedToVisible,
      isToolbarStateChangedToHidden
    });
    if (!isEqual(oldDashboardState, newDashboardState)) {
      setTimeout(() => this.getAdaptableApi().eventApi.emit('DashboardChanged', dashboardChangedInfo));
    }
  }
  isToolbarInActiveTab(toolbarName, dashboardState) {
    var _a;
    if (!dashboardState) {
      return false;
    }
    const tabIndex = dashboardState.ActiveTabIndex;
    if (tabIndex != undefined) {
      if ((dashboardState === null || dashboardState === void 0 ? void 0 : dashboardState.Tabs) && ((_a = dashboardState === null || dashboardState === void 0 ? void 0 : dashboardState.Tabs) === null || _a === void 0 ? void 0 : _a.length) > 0) {
        const newTab = dashboardState === null || dashboardState === void 0 ? void 0 : dashboardState.Tabs[tabIndex];
        if (newTab && newTab.Toolbars) {
          return newTab.Toolbars.find(c => c == toolbarName) != undefined;
        }
      }
    }
    return false;
  }
  isToolbarCustom(toolbarName) {
    return this.getAdaptableApi().dashboardApi.getCustomToolbars().some(tb => tb.name === toolbarName);
  }
  getCustomToolbarRenderContainerId(customToolbarName) {
    return `ab-CustomToolbar__${customToolbarName}__renderContent`;
  }
  getCustomToolbarButtonsContainerId(customToolbarName) {
    return `ab-CustomToolbar__${customToolbarName}__buttons`;
  }
  getCustomToolbarComponentContainerId(customToolbarName) {
    return `ab-CustomToolbar__${customToolbarName}__component`;
  }
  getModuleToolbars() {
    return ALL_TOOLBARS.filter(moduleName => {
      return this.adaptable.ModuleService.isModuleAvailable(moduleName);
    }).map(moduleName => {
      var _a, _b;
      return {
        id: moduleName,
        friendlyName: (_b = (_a = this.adaptable.ModuleService.getModuleInfoByModule(moduleName)) === null || _a === void 0 ? void 0 : _a.FriendlyName) !== null && _b !== void 0 ? _b : moduleName
      };
    });
  }
}