import * as React from 'react';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { FilterFormReact } from '../View/Components/FilterForm/FilterForm';
export const FilterWrapperFactory = adaptable => {
  function isFilterActive(colId) {
    // we need this here
    if (adaptable.isDestroyed) {
      return false;
    }
    //make the small filter icon to appear when there is a filter
    return adaptable.api.columnFilterApi.getColumnFilters().findIndex(x => x.ColumnId == colId) > -1;
  }
  function getFilterProps(colId, hidePopup) {
    let column = adaptable.api.columnApi.getColumnWithColumnId(colId);
    let columnFilterProps = {
      Column: column,
      Adaptable: adaptable,
      ShowCloseButton: hidePopup
    };
    return columnFilterProps;
  }
  function getContainerId(colId) {
    return 'filter_' + colId + '_' + adaptable.adaptableOptions.adaptableId;
  }
  if (adaptable.variant === 'react') {
    return forwardRef(function ReactFilterWrapper(props, ref) {
      const [filterProps, setFilterProps] = useState(null);
      const colId = props.column.getId();
      const column = adaptable.api.columnApi.getColumnWithColumnId(colId);
      const afterGuidAttached = React.useCallback(params => {
        var _a;
        const hidePopup = (_a = params === null || params === void 0 ? void 0 : params.hidePopup) !== null && _a !== void 0 ? _a : null;
        const filterProps = getFilterProps(colId, !!hidePopup);
        adaptable.hideFilterFormPopup = hidePopup;
        setFilterProps(filterProps);
      }, [colId]);
      React.useEffect(() => {
        afterGuidAttached();
      }, []);
      useImperativeHandle(ref, () => {
        return {
          isFilterActive: () => isFilterActive(colId),
          // left here for backward compatibility
          afterGuidAttached,
          getModel: () => {},
          setModel: () => {},
          //we do not filter here.... we filter using the doesExternalFilterPass. Not sure there is a difference....
          doesFilterPass: () => true
        };
      });
      if (!column || !filterProps) {
        return null;
      }
      return React.createElement("div", {
        id: getContainerId(colId)
      }, FilterFormReact(filterProps));
    });
  }
  return class FilterWrapper {
    init(params) {
      this.params = params;
      this.column = params.column;
      this.filterContainer = document.createElement('div');
      this.filterContainer.id = getContainerId(this.params.column.getColId());
    }
    doesFilterPass(params) {
      //we do not filter here.... we filter using the doesExternalFilterPass. Not sure there is a difference....
      return true;
    }
    isFilterActive() {
      return isFilterActive(this.params.column.getColId());
    }
    getModel() {
      //
    }
    setModel(model) {
      //
    }
    getGui() {
      return this.filterContainer;
    }
    afterGuiAttached(params) {
      var _a, _b;
      //we always unmount first so the autofocus from the form works... in other grids we unmount when hidden
      (_a = this.unmountReactRoot) === null || _a === void 0 ? void 0 : _a.call(this);
      let column = adaptable.api.columnApi.getColumnWithColumnId(this.column.getColId());
      if (column) {
        const hidePopup = (_b = params === null || params === void 0 ? void 0 : params.hidePopup) !== null && _b !== void 0 ? _b : null;
        const filterProps = getFilterProps(this.column.getColId(), !!hidePopup);
        adaptable.hideFilterFormPopup = hidePopup;
        this.unmountReactRoot = adaptable.renderReactRoot(FilterFormReact(filterProps), this.filterContainer);
      }
    }
    destroy() {
      var _a;
      (_a = this.unmountReactRoot) === null || _a === void 0 ? void 0 : _a.call(this);
      this.filterContainer = null;
    }
  };
};