import * as React from 'react';
import { Flex } from 'rebass';
import { connect } from 'react-redux';
import { getStatusItemStyle } from './Utilities/getStatusItemStyle';
class SystemStatusViewPanelComponent extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const currentSystemStatusMessageInfo = this.props.api.systemStatusApi.getCurrentSystemStatusMessageInfo();
    const {
      color,
      background
    } = getStatusItemStyle(currentSystemStatusMessageInfo);
    const message = currentSystemStatusMessageInfo === null || currentSystemStatusMessageInfo === void 0 ? void 0 : currentSystemStatusMessageInfo.statusMessage;
    const elementType = this.props.viewType === 'Toolbar' ? 'DashboardToolbar' : 'ToolPanel';
    return React.createElement(Flex, {
      alignItems: "stretch",
      className: `ab-${elementType}__SystemStatus__wrap`
    }, React.createElement(Flex, {
      style: {
        borderRadius: 'var(--ab__border-radius)'
      },
      className: `ab-${elementType}__SystemStatus__text`,
      marginRight: 2,
      padding: 2,
      color: color,
      backgroundColor: message ? background : '',
      fontSize: 'var( --ab-font-size-2)',
      alignItems: "center",
      "data-name": "system-status-message"
    }, message || '0 Messages'));
  }
}
function mapStateToProps(state) {
  return {
    SystemStatusMessageInfos: state.System.SystemStatusMessages
  };
}
function mapDispatchToProps(dispatch) {
  return {};
}
export let SystemStatusViewPanel = connect(mapStateToProps, mapDispatchToProps)(SystemStatusViewPanelComponent);