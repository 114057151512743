import * as React from 'react';
import { Tag } from '../../../../components/Tag';
import { ScheduleType } from '../../../../PredefinedConfig/Common/Enums';
import { SummaryText, useOnePageAdaptableWizardContext } from '../../../Wizard/OnePageAdaptableWizard';
export const getScheduleSettingsValues = data => {
  if (data.ScheduleType === ScheduleType.Reminder) {
    const reminder = data;
    return [{
      label: 'Header',
      value: (reminder === null || reminder === void 0 ? void 0 : reminder.Header) || 'Not Specified'
    }, {
      label: 'Message',
      value: (reminder === null || reminder === void 0 ? void 0 : reminder.Message) || 'Not Specified'
    }, {
      label: 'Message Type',
      value: (reminder === null || reminder === void 0 ? void 0 : reminder.MessageType) || 'Not Specified'
    }, {
      label: 'Display Toast Notification',
      value: (reminder === null || reminder === void 0 ? void 0 : reminder.DisplayNotification) ? 'True' : 'False'
    }, {
      label: 'Show System Status Message',
      value: (reminder === null || reminder === void 0 ? void 0 : reminder.DisplaySystemStatusMessage) ? 'True' : 'False'
    }];
  }
  if (data.ScheduleType === ScheduleType.Report) {
    const report = data;
    return [{
      label: 'Report Name',
      value: (report === null || report === void 0 ? void 0 : report.ReportName) || 'Not Specified'
    }, {
      label: 'Export Destination',
      value: (report === null || report === void 0 ? void 0 : report.ExportDestination) || 'Not Specified'
    }];
  }
  if (data.ScheduleType === ScheduleType.ipushpull) {
    const report = data;
    return [{
      label: 'Report',
      value: (report === null || report === void 0 ? void 0 : report.IPushPullReport.ReportName) || 'Not Specified'
    }, {
      label: 'Folder',
      value: (report === null || report === void 0 ? void 0 : report.IPushPullReport.Folder) || 'Not Specified'
    }, {
      label: 'Page',
      value: (report === null || report === void 0 ? void 0 : report.IPushPullReport.Page) || 'Not Specified'
    }, {
      label: 'Export As',
      value: (report === null || report === void 0 ? void 0 : report.Transmission) || 'Not Specified'
    }];
  }
  if (data.ScheduleType === ScheduleType.OpenFin) {
    const report = data;
    return [{
      label: 'Report',
      value: (report === null || report === void 0 ? void 0 : report.OpenFinReport.ReportName) || 'Not Specified'
    }];
  }
  return [];
};
export const ScheduleSettingsSummary = () => {
  const {
    data
  } = useOnePageAdaptableWizardContext();
  return React.createElement(React.Fragment, null, getScheduleSettingsValues(data).map((description, index) => React.createElement(SummaryText, {
    key: index
  }, description.label, ": ", React.createElement(Tag, null, description.value))));
};