import * as React from 'react';
import DefaultIcon from './DefaultIcon';
export default (props => React.createElement(DefaultIcon, Object.assign({}, props, {
  viewBox: "0 0 612 792"
}), React.createElement("path", {
  d: "M545.58,533.27V271.23L334.25,393.24c0.13,1.11,0.65,2.07,0.65,3.22c0,13.13-8.85,23.8-20.8,27.43v243.03   L545.58,533.27L545.58,533.27z"
}), React.createElement("path", {
  d: "M328.46,378.97l208.38-120.31v-0.65L305.79,126L74.74,258.01v0.65l208.38,120.31   c5.32-6.89,13.29-11.62,22.67-11.62C315.17,367.34,323.14,372.08,328.46,378.97L328.46,378.97z"
}), React.createElement("path", {
  d: "M277.33,393.24L66,271.23v262.04l231.48,133.64V423.88c-11.94-3.63-20.8-14.3-20.8-27.43   C276.68,395.31,277.2,394.35,277.33,393.24L277.33,393.24z"
})));