import * as React from 'react';
import { Flex } from 'rebass';
import format from 'date-fns/format';
import SimpleButton from '../SimpleButton';
import { useMemo } from 'react';
import { useDayPicker } from 'react-day-picker';
export function CaptionLabel(props) {
  const {
    locale,
    classNames,
    styles
  } = useDayPicker();
  const {
    onPreviousYearClick,
    onNextYearClick,
    onNextMonthClick,
    onPreviousMonthClick
  } = props;
  const captionStyles = useMemo(() => Object.assign(Object.assign({}, styles.caption_label), {
    width: '100%'
  }), []);
  return React.createElement("div", {
    key: "caption",
    className: classNames.caption_label,
    style: captionStyles,
    "aria-live": "polite",
    "aria-atomic": "true"
  }, React.createElement(SimpleButton, {
    padding: 1,
    mr: 1,
    variant: "text",
    icon: "arrow-left",
    onClick: onPreviousMonthClick
  }), React.createElement(Flex, {
    justifyContent: "center",
    style: {
      minWidth: 80,
      flex: 2
    }
  }, format(props.displayMonth, 'LLLL', {
    locale
  })), React.createElement(SimpleButton, {
    padding: 1,
    ml: 1,
    variant: "text",
    icon: "arrow-right",
    onClick: onNextMonthClick
  }), React.createElement(SimpleButton, {
    padding: 1,
    mr: 1,
    variant: "text",
    icon: "arrow-left",
    onClick: onPreviousYearClick
  }), React.createElement(Flex, {
    justifyContent: "center",
    style: {
      minWidth: 40,
      flex: 1
    }
  }, format(props.displayMonth, 'y', {
    locale
  }), ' '), React.createElement(SimpleButton, {
    padding: 1,
    ml: 1,
    variant: "text",
    icon: "arrow-right",
    onClick: onNextYearClick
  }));
}