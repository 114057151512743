export const HOST_URL_DOCS = 'https://docs.adaptabletools.com';
//export const HOST_URL_DOCS = 'http://localhost:3000';
export const ExpressionEditorDocsLink = `${HOST_URL_DOCS}/guide/ui-expression-editor`;
export const BooleanQueryDocsLink = `${HOST_URL_DOCS}/guide/adaptable-ql-expression-standard`;
export const ScalarQueryDocsLink = `${HOST_URL_DOCS}/guide/adaptable-ql-expression-standard`;
export const ObservableQueryDocsLink = `${HOST_URL_DOCS}/guide/adaptable-ql-expression-observable`;
export const AggregatedBooleanQueryDocsLink = `${HOST_URL_DOCS}/guide/adaptable-ql-expression-aggregation`;
export const AggregatedScalarQueryDocsLink = `${HOST_URL_DOCS}/guide/adaptable-ql-expression-aggregation`;
export const CumulativeAggregatedScalarQueryDocsLink = `${HOST_URL_DOCS}/guide/adaptable-ql-expression-cumulative`;
export const QuantileAggregatedScalarQueryDocsLink = `${HOST_URL_DOCS}/guide/adaptable-ql-expression-quantile`;
export const PredicateDocsLink = `${HOST_URL_DOCS}/guide/adaptable-predicate`;
export const PrimaryKeyDocsLink = `${HOST_URL_DOCS}/guide/getting-started-primary-key`;
export const LicenseDocsLink = `${HOST_URL_DOCS}/guide/licensing`;
export const AdaptableOptionsDocsLink = `${HOST_URL_DOCS}/guide/reference-options-overview`;
export const AgGridModulesDocsLink = `${HOST_URL_DOCS}/guide/dev-guide-aggrid-modules-overview`;
export const AlertMessageDocsLink = `${HOST_URL_DOCS}/guide/handbook-alerting-message`;
export const FormatColumnPlaceholderDocsLink = `${HOST_URL_DOCS}/guide/handbook-column-formatting-display-format-placeholder`;