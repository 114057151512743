import * as React from 'react';
import DefaultIcon from './DefaultIcon';
export default (props => React.createElement(DefaultIcon, Object.assign({}, props), React.createElement("g", {
  transform: "scale(0.021 0.021)"
}, React.createElement("path", {
  d: "M320 768v-768h-128v768h-160l224 224 224-224h-160z"
}), React.createElement("path", {
  d: "M928 1024h-256c-11.8 0-22.644-6.496-28.214-16.9-5.566-10.404-4.958-23.030 1.59-32.85l222.832-334.25h-196.208c-17.672 0-32-14.328-32-32s14.328-32 32-32h256c11.8 0 22.644 6.496 28.214 16.9 5.566 10.404 4.958 23.030-1.59 32.85l-222.83 334.25h196.206c17.672 0 32 14.328 32 32s-14.328 32-32 32z"
}), React.createElement("path", {
  d: "M1020.622 401.686l-192.002-384c-5.42-10.842-16.502-17.69-28.622-17.69-12.122 0-23.202 6.848-28.624 17.69l-191.996 384c-7.904 15.806-1.496 35.030 14.31 42.932 4.594 2.296 9.476 3.386 14.288 3.386 11.736 0 23.040-6.484 28.644-17.698l55.156-110.31h216.446l55.156 110.31c7.902 15.806 27.124 22.21 42.932 14.31 15.808-7.902 22.216-27.124 14.312-42.93zM723.778 255.996l76.22-152.446 76.224 152.446h-152.444z"
}))));