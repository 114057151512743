import * as React from 'react';
import { Box } from 'rebass';
import { OnePageAdaptableWizard, OnePageWizardSummary } from '../../../View/Wizard/OnePageAdaptableWizard';
import { renderScopeSummary, isScopeValid } from '../../Components/NewScopeComponent';
import { PlusMinusScopeWizardSection } from './PlusMinusScopeWizardSection';
import { PlusMinusSettingsWizardSection, PlusMinusSettingsSummary, isSettingsValid } from './PlusMinusSettingsWizardSection';
import ObjectFactory from '../../../Utilities/ObjectFactory';
import { useDispatch } from 'react-redux';
import * as PlusMinusRedux from '../../../Redux/ActionsReducers/PlusMinusRedux';
import { ObjectTagsWizardSection, renderObjectTagsSummary } from '../../Wizard/ObjectTagsWizardSection';
export const PlusMinusWizard = props => {
  const [plusMinus, setPlusMinus] = React.useState(() => {
    var _a, _b, _c, _d, _e;
    const newPlusMinus = Object.assign(Object.assign({}, props.data ? props.data : ObjectFactory.CreateEmptyPlusMinusNudge()), {
      Scope: (_b = (_a = props === null || props === void 0 ? void 0 : props.data) === null || _a === void 0 ? void 0 : _a.Scope) !== null && _b !== void 0 ? _b : {
        All: true
      },
      NudgeValue: (_d = (_c = props === null || props === void 0 ? void 0 : props.data) === null || _c === void 0 ? void 0 : _c.NudgeValue) !== null && _d !== void 0 ? _d : 20
    });
    if ((_e = props === null || props === void 0 ? void 0 : props.popupParams) === null || _e === void 0 ? void 0 : _e.column) {
      newPlusMinus.Scope = {
        ColumnIds: [props.popupParams.column.columnId]
      };
    }
    return newPlusMinus;
  });
  const [hasCondition, setHasCondition] = React.useState(() => {
    var _a;
    // true if on edit and it has a condition
    return Boolean((_a = props === null || props === void 0 ? void 0 : props.data) === null || _a === void 0 ? void 0 : _a.Rule);
  });
  React.useEffect(() => {
    var _a;
    const preparedData = Object.assign({}, plusMinus);
    if (hasCondition) {
      preparedData.Rule = (_a = preparedData.Rule) !== null && _a !== void 0 ? _a : {
        BooleanExpression: ''
      };
    } else {
      delete preparedData.Rule;
    }
    setPlusMinus(preparedData);
  }, [hasCondition]);
  const dispatch = useDispatch();
  const handleFinish = () => {
    if (props.data) {
      dispatch(PlusMinusRedux.PlusMinusNudgeEdit(plusMinus));
    } else {
      dispatch(PlusMinusRedux.PlusMinusNudgeAdd(plusMinus));
    }
    props.onFinishWizard(plusMinus);
  };
  return React.createElement(OnePageAdaptableWizard, {
    defaultCurrentSectionName: props.defaultCurrentSectionName,
    moduleInfo: props.moduleInfo,
    data: plusMinus,
    onHide: props.onCloseWizard,
    onFinish: handleFinish,
    sections: [{
      title: 'Target',
      isValid: isScopeValid,
      details: 'Specify where Plus/Minus should be applied',
      renderSummary: () => renderScopeSummary(plusMinus.Scope, {
        scopeWholeRow: 'Plus/Minus is applied for matching rows',
        scopeColumns: 'Plus/Minus is applied in cells in selected columns',
        scopeDataTypes: 'Plus/Minus is applied for cells with selected data type'
      }),
      render: () => React.createElement(Box, {
        padding: 2
      }, React.createElement(PlusMinusScopeWizardSection, {
        onChange: setPlusMinus
      }))
    }, {
      title: 'Settings',
      isValid: isSettingsValid(hasCondition),
      renderSummary: () => React.createElement(PlusMinusSettingsSummary, {
        hasCondition: hasCondition
      }),
      render: () => React.createElement(PlusMinusSettingsWizardSection, {
        hasCondition: hasCondition,
        onConditionChange: setHasCondition,
        onChange: setPlusMinus
      })
    }, {
      details: 'Select Plus/Minus Tags',
      title: 'Tags',
      isVisible: (_, api) => api.internalApi.shouldDisplayTagSections(),
      render: () => React.createElement(Box, {
        padding: 2
      }, React.createElement(ObjectTagsWizardSection, {
        onChange: setPlusMinus
      })),
      renderSummary: renderObjectTagsSummary
    }, '-', {
      render: () => {
        return React.createElement(Box, {
          padding: 2
        }, React.createElement(OnePageWizardSummary, null));
      },
      title: 'Summary'
    }]
  });
};