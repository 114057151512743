import { VersionUpgrade17 } from './VersionUpgrade17';
import { VersionUpgrade18 } from './VersionUpgrade18';
import { ADAPTABLE_VERSION } from '../EnvVars';
export class AdaptableUpgradeHelper {
  constructor(logger) {
    this.currentMajorVersion = this.getCurrentMajorVersion(ADAPTABLE_VERSION);
    this.logger = logger || this.getConsoleLogger();
    this.versionUpgrades = new Map();
    this.versionUpgrades.set(17, new VersionUpgrade17(this.logger));
    this.versionUpgrades.set(18, new VersionUpgrade18(this.logger));
  }
  static migrateAdaptableState(state, config) {
    const upgradeHelper = new AdaptableUpgradeHelper(config.logger);
    let fromVersion = (config === null || config === void 0 ? void 0 : config.fromVersion) || 16;
    const toVersion = (config === null || config === void 0 ? void 0 : config.toVersion) || upgradeHelper.currentMajorVersion;
    if (fromVersion >= toVersion) {
      console.warn(`The fromVersion (${fromVersion}) should be less than toVersion (${toVersion})!`);
      return state;
    }
    if (fromVersion < 16) {
      // until version 16 we didn't have versioning
      fromVersion = 16;
    }
    const versionUpgrades = Array.from(upgradeHelper.versionUpgrades.keys());
    let updatedState = structuredClone(state);
    for (const version of versionUpgrades) {
      if (version > fromVersion && version <= toVersion) {
        upgradeHelper.logger.info(`Migration to ${version} started...`);
        updatedState = upgradeHelper.versionUpgrades.get(version).migrateState(updatedState);
        upgradeHelper.logger.info(`Migration to ${version} finished!`);
      }
    }
    return updatedState;
  }
  getCurrentMajorVersion(version) {
    // is good enough for now, we just need to make sure we don't forget to update it
    // TODO AFL is there a better way to do this for local development?
    return parseInt(version.split('.')[0]) || 18;
  }
  getConsoleLogger() {
    return {
      success: console.log,
      info: console.info,
      warn: console.warn,
      error: console.error
    };
  }
}