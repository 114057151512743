import * as React from 'react';
/// <reference path="../../typings/.d.ts" />
import { EnumExtensions } from '../../Utilities/Extensions/EnumExtensions';
import { FontWeight, FontStyle } from '../../PredefinedConfig/Common/Enums';
import StringExtensions from '../../Utilities/Extensions/StringExtensions';
export class StyleVisualItem extends React.Component {
  render() {
    if (!this.props.Style) {
      return null;
    }
    let styleText = StringExtensions.IsNotNullOrEmpty(this.props.Text) ? this.props.Text : 'Style';
    let styleVisualisation;
    if (this.props.Style.ClassName) {
      styleVisualisation = React.createElement("div", null, 'CSS Class: ' + this.props.Style.ClassName);
    } else {
      let backColorForStyle = this.props.Style.BackColor != undefined ? this.props.Style.BackColor : null;
      let foreColorForStyle = this.props.Style.ForeColor != undefined ? this.props.Style.ForeColor : 'black';
      let borderColorForStyle = this.props.Style.BorderColor != undefined ? this.props.Style.BorderColor : null;
      let fontWeightForStyle = this.props.Style.FontWeight == FontWeight.Bold ? 'bold' : 'normal';
      let fontStyleForStyle = this.props.Style.FontStyle == FontStyle.Italic ? 'italic' : 'normal';
      let fontSizeForStyle = EnumExtensions.getCssFontSizeFromFontSizeEnum(this.props.Style.FontSize);
      styleVisualisation = React.createElement("div", {
        className: this.props.Style.BackColor != undefined ? '' : 'ab_white_grey_stripes',
        style: {
          textAlign: 'center',
          margin: '2px',
          padding: '3px',
          background: backColorForStyle,
          color: foreColorForStyle,
          borderColor: borderColorForStyle,
          borderStyle: borderColorForStyle ? 'solid' : 'none',
          fontWeight: fontWeightForStyle,
          fontStyle: fontStyleForStyle,
          fontSize: fontSizeForStyle
        }
      }, styleText);
    }
    return styleVisualisation;
  }
}