import * as React from 'react';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
import { EntityRulesEditor } from '../../Components/EntityRulesEditor';
export function FormatColumnRuleWizardSection(props) {
  const {
    data,
    api,
    moduleInfo
  } = useOnePageAdaptableWizardContext();
  return React.createElement(EntityRulesEditor, {
    module: moduleInfo.ModuleName,
    defaultPredicateId: props.defaultPredicateId,
    // TODO see what is this
    predicateDefs: api.formatColumnApi.internalApi.getFormatColumnDefsForScope(data.Scope),
    getPredicateDefsForColId: colId => api.formatColumnApi.internalApi.getFormatColumnDefsForScope({
      ColumnIds: [colId]
    }),
    showNoRule: true,
    showBoolean: true,
    showAggregation: false,
    showObservable: false,
    showQueryBuilder: true,
    showPredicate: !api.columnScopeApi.scopeIsAll(data.Scope),
    data: data,
    onChange: formatColumn => props.onChange(formatColumn),
    descriptions: {
      selectPredicate: 'Create a Format Column Rule - to be applied when data changes',
      useBooleanQuery: React.createElement(React.Fragment, null, "Use an BooleanQuery if ", React.createElement("i", null, "Scope"), " is 'All Columns' - so any data change may be evaluated in a complex BooleanExpression"),
      useObservableQuery: React.createElement(React.Fragment, null, "Use an ObservableQuery if ", React.createElement("i", null, "Scope"), " is 'All Columns' - so any data change may be evaluated in a complex ObservableExpression"),
      useAggregationQuery: React.createElement(React.Fragment, null, "Use an AggregatedBooleanQuery if ", React.createElement("i", null, "Scope"), " is 'All Columns' - so any data change may be evaluated in a complex AggregatedBooleanExpression")
    }
  });
}