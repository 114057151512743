import { ApiBase } from './ApiBase';
import * as parser from '../../parser/src';
import { ExpressionInternalApi } from '../Internal/ExpressionInternalApi';
export class ExpressionApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.internalApi = new ExpressionInternalApi(adaptable);
  }
  isValidBooleanExpression(query, module, validationErrorMessage) {
    const {
      isValid,
      errorMessage
    } = this.getAdaptableApi().internalApi.getQueryLanguageService().validateBoolean(query, module, {
      force: true
    });
    if (!isValid && validationErrorMessage) {
      this.logWarn(`${validationErrorMessage} :: ${errorMessage}`);
    }
    return isValid;
  }
  isValidObservableExpression(expression, module, validationErrorMessage) {
    const {
      isValid,
      errorMessage
    } = this.getAdaptableApi().internalApi.getQueryLanguageService().validateObservable(expression, module);
    if (!isValid && validationErrorMessage) {
      this.logWarn(`${validationErrorMessage} :: ${errorMessage}`);
    }
    return isValid;
  }
  isValidAggregatedBooleanExpression(expression, module, validationErrorMessage) {
    const {
      isValid,
      errorMessage
    } = this.getAdaptableApi().internalApi.getQueryLanguageService().validateAggregatedBoolean(expression, module);
    if (!isValid && validationErrorMessage) {
      this.logWarn(`${validationErrorMessage} :: ${errorMessage}`);
    }
    return isValid;
  }
  isValidAggregatedScalarExpression(expression, module, validationErrorMessage) {
    const {
      isValid,
      errorMessage
    } = this.getAdaptableApi().internalApi.getQueryLanguageService().validateAggregatedScalar(expression, module);
    if (!isValid && validationErrorMessage) {
      this.logWarn(`${validationErrorMessage} :: ${errorMessage}`);
    }
    return isValid;
  }
  getASTForExpression(query) {
    return parser.getAbstractSyntaxTree(query);
  }
  getColumnsFromExpression(expression) {
    try {
      const columnIds = this.getAdaptableApi().internalApi.getQueryLanguageService().getColumnsFromExpression(expression);
      columnIds.forEach(columnId => {
        const calculatedColumn = this.getAdaptableApi().calculatedColumnApi.getCalculatedColumnForColumnId(columnId);
        if (calculatedColumn) {
          const transitiveColumnIds = this.getColumnsFromExpression(this.getAdaptableQueryExpression(calculatedColumn.Query));
          columnIds.push(...transitiveColumnIds);
        }
      });
      return Array.from(new Set(columnIds));
    } catch (error) {
      // defensive programing, this should happen only if someone botched the predefined configs
      this.logWarn(`QueryApi.getColumnsFromExpressionInvalid received an invalid expression: ${expression}
                  Error: ${error}`);
      return [];
    }
  }
  isColumnQueryable(adaptableColumnBase) {
    let queryableColumnFn = this.getExpressionOptions().isColumnQueryable;
    if (queryableColumnFn) {
      const queryableColumnContext = Object.assign({
        adaptableColumn: adaptableColumnBase
      }, this.getAdaptableApi().internalApi.buildBaseContext());
      return queryableColumnFn(queryableColumnContext);
    }
    return true;
  }
  getAdaptableQueryExpression(query) {
    var _a, _b, _c, _d;
    return (_d = (_c = (_b = (_a = query.BooleanExpression) !== null && _a !== void 0 ? _a : query.ScalarExpression) !== null && _b !== void 0 ? _b : query.ObservableExpression) !== null && _c !== void 0 ? _c : query.AggregatedBooleanExpression) !== null && _d !== void 0 ? _d : query.AggregatedScalarExpression;
  }
  getAdaptableQueryExpressionWithColumnFriendlyNames(query) {
    return this.getAdaptableApi().internalApi.getQueryLanguageService().getExpressionWithColumnFriendlyNames(this.getAdaptableQueryExpression(query));
  }
  useCaseSensitivity() {
    return this.adaptable.adaptableOptions.expressionOptions.caseSensitiveExpressions;
  }
}