// TODO: move to named query
import * as React from 'react';
import { Text } from 'rebass';
import WizardPanel from '../../../components/WizardPanel';
import FormLayout, { FormRow } from '../../../components/FormLayout';
import ErrorBox from '../../../components/ErrorBox';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
import AdaptableInput from '../../Components/AdaptableInput';
import { Tag } from '../../../components/Tag';
export const isValidNamedQuerySettings = (data, api) => {
  const validationResult = api.namedQueryApi.isValidNamedQuery(data);
  const ErrorMessage = !validationResult.valid ? validationResult.message : null;
  if (!ErrorMessage) {
    return true;
  }
  return ErrorMessage;
};
export const renderNamedQuerySettingsSummary = data => {
  return React.createElement(Text, {
    fontSize: 2
  }, "Name: ", React.createElement(Tag, null, data.Name));
};
export const NamedQuerySettingsWizardSection = props => {
  const {
    data,
    api
  } = useOnePageAdaptableWizardContext();
  const valid = isValidNamedQuerySettings(data, api);
  const errorMessage = valid === true ? null : valid;
  const handleColumnNameChange = event => {
    let e = event.target;
    props.onChange(Object.assign(Object.assign({}, data), {
      Name: e.value
    }));
  };
  return React.createElement(WizardPanel, {
    "data-name": 'named-query-settings'
  }, React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Name"
  }, React.createElement(AdaptableInput, {
    "data-name": "query-name",
    value: data.Name,
    autoFocus: true,
    width: 300,
    type: "text",
    placeholder: "Enter Named Query Name",
    onChange: e => handleColumnNameChange(e)
  })), ' ', errorMessage ? React.createElement(FormRow, {
    label: ""
  }, React.createElement(ErrorBox, null, errorMessage)) : null));
};