import { AdaptableModuleBase } from './AdaptableModuleBase';
import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
import { SystemStatusStatusBarContent } from '../View/SystemStatus/SystemStatusStatusBarContent';
export class SystemStatusModule extends AdaptableModuleBase {
  constructor(api) {
    super(ModuleConstants.SystemStatusModuleId, ModuleConstants.SystemStatusFriendlyName, 'traffic-lights', 'SystemStatusPopup', 'Provide messages about the Status of your application', api);
  }
  createColumnMenuItems(column) {
    if (this.isModuleAvailable()) {
      return [this.createMenuItemShowPopup('system-status-show', 'Show System Status', this.moduleInfo.Popup, this.moduleInfo.Glyph, {
        source: 'ColumnMenu'
      })];
    }
  }
  createContextMenuItems(menuContext) {
    if (this.isModuleAvailable()) {
      let popUpParams = {
        source: 'ContextMenu'
      };
      return [this.createMainMenuItemShowPopup({
        Name: 'system-status-show',
        Label: 'Show System Status',
        ComponentName: this.moduleInfo.Popup,
        Icon: this.moduleInfo.Glyph,
        PopupParams: popUpParams
      })];
    }
  }
  getViewProperties() {
    return {
      getStatusBarPanelProps: () => {
        return {
          content: SystemStatusStatusBarContent
        };
      }
    };
  }
}