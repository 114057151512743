import * as React from 'react';
import SimpleButton from './SimpleButton';
export const SelectList = props => {
  return React.createElement(React.Fragment, null, props.options.map(option => React.createElement(SimpleButton, {
    p: 2,
    width: '100%',
    variant: "text",
    onClick: () => props.onChange(option),
    key: option.label
  }, option.label)));
};