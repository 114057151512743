import { useEffect, useState, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { QUICK_SEARCH_DEBOUNCE_TIME } from '../../Utilities/Constants/GeneralConstants';
export const useQuickSearchDebounced = props => {
  var _a;
  const [searchText, setSearchText] = useState((_a = props.QuickSearchText) !== null && _a !== void 0 ? _a : '');
  const debouncedRunQuickSearch = useMemo(() => debounce(props.onRunQuickSearch, QUICK_SEARCH_DEBOUNCE_TIME), [props.onRunQuickSearch]);
  useEffect(() => {
    setSearchText(props.QuickSearchText);
  }, [props.QuickSearchText]);
  const executeSearch = searchText => {
    setSearchText(searchText);
    debouncedRunQuickSearch(searchText);
  };
  return [searchText, executeSearch];
};