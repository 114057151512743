import * as React from 'react';
import { createUuid } from '../utils/uuid';
const setZIndexMap = {};
const zIndexMap = [];
const BASE_Z_INDEX = 1000;
/**
 * Used to facilitate the stacking between multiple opened windows.
 * When calling 'bringToFront' brings caller to top.
 * This is done by changing the zIndex of all registered window-modals.
 */
export const useStacking = () => {
  const modalZIndexId = React.useMemo(() => createUuid(), []);
  const [zIndex, setZIndex] = React.useState(BASE_Z_INDEX);
  React.useEffect(() => {
    setZIndexMap[modalZIndexId] = setZIndex;
    return () => {
      // clean-up
      delete setZIndexMap[modalZIndexId];
      const indexInMap = zIndexMap.indexOf(modalZIndexId);
      zIndexMap.splice(indexInMap, 1);
    };
  }, [modalZIndexId]);
  const bringInFront = React.useCallback(() => {
    if (zIndexMap.includes(modalZIndexId)) {
      const indexInMap = zIndexMap.indexOf(modalZIndexId);
      zIndexMap.splice(indexInMap, 1);
      zIndexMap.push(modalZIndexId);
    } else {
      zIndexMap.push(modalZIndexId);
    }
    zIndexMap.forEach((id, index) => {
      var _a;
      (_a = setZIndexMap === null || setZIndexMap === void 0 ? void 0 : setZIndexMap[id]) === null || _a === void 0 ? void 0 : _a.call(setZIndexMap, BASE_Z_INDEX + index * 10);
    });
  }, []);
  return {
    zIndex,
    bringInFront
  };
};