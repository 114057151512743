import * as React from 'react';
import { Flex } from 'rebass';
import { useAdaptable } from '../../View/AdaptableContext';
import { CheckBox } from '../CheckBox';
import EditorButton from './EditorButton';
export const NamedQueryEditor = props => {
  const adaptable = useAdaptable();
  const [showColumnIds, setShowColumnIds] = React.useState(false);
  const [inlineQuery, setInlineQuery] = React.useState(false);
  return React.createElement(Flex, {
    flexDirection: "column",
    alignItems: "start",
    style: {
      marginTop: 2
    }
  }, React.createElement(CheckBox, {
    checked: showColumnIds,
    onChange: checked => setShowColumnIds(checked)
  }, "Show Column IDs"), React.createElement(CheckBox, {
    checked: inlineQuery,
    onChange: checked => setInlineQuery(checked)
  }, "Inline Query"), props.namedQueries.map(namedQuery => React.createElement(Flex, {
    key: namedQuery.Uuid,
    flexDirection: "column",
    alignItems: "start",
    style: {
      padding: 3,
      marginTop: 'var(--ab-space-2)',
      marginBottom: 'var(--ab-space-2)',
      width: '100%'
    },
    backgroundColor: "primarylight"
  }, ' ', React.createElement(EditorButton, {
    width: "100%",
    height: "100%",
    style: {
      background: 'var(--ab-color-primary)',
      cursor: 'grab'
    },
    data: inlineQuery ? namedQuery.BooleanExpression : `QUERY("${namedQuery.Name}")`,
    "data-name": "column",
    icon: "drag"
  }, React.createElement(Flex, {
    flexDirection: "column",
    alignItems: "start"
  }, namedQuery.Name)), React.createElement(Flex, {
    alignItems: "start",
    style: {
      padding: 5
    }
  }, showColumnIds ? namedQuery.BooleanExpression : adaptable.api.expressionApi.getAdaptableQueryExpressionWithColumnFriendlyNames(namedQuery)))));
};