import * as React from 'react';
import { useAdaptable } from '../../View/AdaptableContext';
export const ExpressionPreview = props => {
  var _a;
  const adaptable = useAdaptable();
  const displayColumnFriendlyNames = (_a = adaptable.api.optionsApi.getExpressionOptions()) === null || _a === void 0 ? void 0 : _a.displayColumnFriendlyNamesForExpressions;
  if (!displayColumnFriendlyNames) {
    return React.createElement(React.Fragment, null, adaptable.api.expressionApi.getAdaptableQueryExpression(props.query));
  }
  const expressionWithFriendlyNames = adaptable.api.expressionApi.getAdaptableQueryExpressionWithColumnFriendlyNames(props.query);
  // '[Column Name] > 2' => ['[Column Name]', '>', '2']
  // not the prettiest
  const strWithMarkedGroupes = expressionWithFriendlyNames.replace(/\]/gi, '],').split(',');
  return (
    /**
     * The etra wrapper is neede because the tag has display inline-flex and makes the content inside <i> to be displayed on
     * multiple lines when the expression is too long.
     *
     * The div allows the text to flow normally.
     */
    React.createElement("div", null, strWithMarkedGroupes.map((part, index) => {
      const partEl = part.includes('[') ? React.createElement("i", null, part.replace(/[\[\]]/g, '')) : part;
      return React.createElement(React.Fragment, {
        key: index
      }, partEl);
    }))
  );
};