import { SummaryOperation } from '../../PredefinedConfig/Common/Enums';
import { LIGHT_YELLOW, BLACK, getHexForName } from '../../View/UIHelper';
export const AUTOGENERATED_PK_COLUMN = '__ADAPTABLE_PK__';
export const MISSING_COLUMN = ' [MISSING]';
export const DEFAULT_LAYOUT = 'Default Layout';
export const LIGHT_THEME = 'light';
export const DARK_THEME = 'dark';
export const OS_THEME = 'os';
export const USER_NAME = 'anonymous';
export const ADAPTABLE_ID = 'adaptable_id';
export const ADAPTABLE = 'AdapTable';
export const ADAPTABLE_CONTAINER_ID = 'adaptable';
export const AG_GRID_CONTAINER_ID = 'grid';
export const ADAPTABLE_TOOLPANEL_ID = 'adaptable';
export const ADAPTABLE_TOOLPANEL_COMPONENT = 'AdaptableToolPanel';
// from https://www.ag-grid.com/javascript-data-grid/side-bar/#string-configuration
export const AGGRID_TOOLPANEL_FILTERS = 'filters';
export const AGGRID_TOOLPANEL_COLUMNS = 'columns';
export const MENU_PREFIX = 'ab_';
export const READ_ONLY_STYLE = 'ab_readonly';
export const ALL_COLUMN_VALUES = 'ALL_COLUMN_VALUES';
export const EMPTY_STRING = '';
export const EMPTY_ARRAY = [];
export const AB_SPECIAL_COLUMN = 'abSpecialColumn';
export const AB_ROW_ACTIONS_COLUMN = 'abRowActionsColumn';
export const AB_FDC3_COLUMN = 'abFdc3Column';
export const HALF_SECOND = 500;
export const GROUP_PATH_SEPARATOR = '/';
export const AG_GRID_GROUPED_COLUMN = 'ag-Grid-AutoColumn';
export const AG_GRID_PIVOT_COLUMN = 'pivot_';
export const AG_GRID_CHART_WINDOW = 'AG Grid Window';
export const ADAPTABLE_ROW_ACTION_BUTTONS = 'adaptableRowActionButtons';
export const ADAPTABLE_ROW_ACTION_BUTTONS_FRIENDLY_NAME = '(ActionRowButtons)';
export const ADAPTABLE_FDC3_ACTION_COLUMN_FRIENDLY_NAME = '(FDC3ActionColumn)';
export const DEFAULT_DATE_FORMAT_PATTERN = 'dd-MM-yyyy';
export const DEFAULT_DATE_FORMAT_PATTERN_WITH_TIME = 'dd-MM-yyyy HH:mm:ss';
export const BLANK_DISTINCT_COLUMN_VALUE = '[BLANKS]';
export const DEFAULT_STRING_DISPLAY_VALUE = 'Hello World';
export const DEFAULT_INTEGER_DISPLAY_VALUE = 12345;
export const DEFAULT_DOUBLE_DISPLAY_VALUE = 12345.6789;
export const RAW_VALUE_DATA_CHANGE_DETECTION_POLICY = 'rawValue';
export const TOAST_POSITIONS_BOTTOM_RIGHT = 'BottomRight';
export const TOAST_TRANSITIONS_SLIDE = 'Slide';
export const NOTIFICATION_DURATION = 3000;
export const NOTIFICATION_MAX_SYSTEM_MESSAGES = 100;
export const QUICK_SEARCH_PLACEHOLDER = 'Search';
export const DASHBOARD_BUTTONS_LOCATION_LEFT = 'left';
export const DASHBOARD_BUTTONS_LOCATION_RIGHT = 'right';
// User Interface
export const ENGLISH_VARIANT = 'GB';
export const LOADING_SCREEN_DELAY = 200;
export const LOADING_SCREEN_TITLE = 'Initialising Grid';
export const LOADING_SCREEN_TEXT = 'Retrieving your settings and setting up the grid...';
export const DATE_INPUT_DATE_FORMAT = 'yyyy-MM-dd';
// Flashing Cell
export const FLASHING_TARGET_CELL = 'cell';
export const FLASHING_TARGET_ROW = 'row';
export const ICON_CONFIGURATION = 'ConfigurationIcon';
export const POPUP_TYPE_MODAL = 'modal';
export const POPUP_TYPE_WINDOW = 'window';
export const SAVE_CHART_BEHAVIOUR_AUTO = 'auto';
export const SAVE_CHART_BEHAVIOUR_MANUAL = 'manual';
export const SAVE_CHART_BEHAVIOUR_NONE = 'none';
// Layout
export const DISPLAY_ROW_GROUPS_DYNAMIC = 'dynamic';
export const DISPLAY_ROW_GROUPS_EXPANDED = 'expanded';
export const DISPLAY_ROW_GROUPS_CLOSED = 'closed';
// FDC3 defaults
export const FDC3_ACTION_COLUMN_DEFAULT_COLUMN_ID = 'fdc3ActionColumn';
export const FDC3_ACTION_COLUMN_DEFAULT_HEADER_NAME = 'FDC3 Actions';
// Entitlement Defaults
export const ACCESS_LEVEL_READONLY = 'ReadOnly';
export const ACCESS_LEVEL_HIDDEN = 'Hidden';
export const ACCESS_LEVEL_FULL = 'Full';
// Standalone Popups
export const STANDALONE_MODULE_POPUPS = ['BulkUpdate', 'SmartEdit', 'CellSummary'];
/*
Redux / State Defaults
Try to put all our Redux / State defaults here and ONLY reference from here - avoid magic numbers / strings.
Please!!!
*/
//System Status
export const SYSTEM_STATUS_DEFAULT_MAX_MESSAGES_IN_STORE = 100;
// Quick Search
export const QUICK_SEARCH_DEFAULT_BACK_COLOR = getHexForName(LIGHT_YELLOW);
export const QUICK_SEARCH_DEFAULT_FORE_COLOR = getHexForName(BLACK);
export const QUICK_SEARCH_DEBOUNCE_TIME = 350;
// Cells Summary
export const CELL_SUMMARY_DEFAULT_OPERATION = SummaryOperation.Sum;
// export const SYSTEM_DEFAULT_SYSTEM_STATUS_COLOUR: 'Red' | 'Amber' | 'Green' | 'Blue' =
//  StatusColour.Green;
export const SYSTEM_DEFAULT_SYSTEM_STATUS_TYPE = 'Info';
// theme
export const THEME_DEFAULT_CURRENT_THEME = LIGHT_THEME;
// Live Report Throttle Time (used in OpenFin, IPushPull etc.)
export const DEFAULT_LIVE_REPORT_THROTTLE_TIME = 2000;
export const SERVER_VALIDATION_HEADER = 'Server Validation Message';
export const SERVER_VALIDATION_MESSAGE_TYPE = 'Info';
/**
 * Object Factory
 */
export const PLUS_MINUS_DEFAULT_NUDGE_VALUE = 1;
export const ALERT_DEFAULT_MESSAGE_TYPE = 'Error';
export const ALERT_DEFAULT_SHOW_POPUP = true;
/**
 * Constants for State (primarily for audit property events) - good idea?
 */
export const SUMMARY_OPERATION_STATE_PROPERTY = 'SummaryOperation';
export const CURRENT_LAYOUT_STATE_PROPERTY = 'CurrentLayout';
export const CURRENT_REPORT_STATE_PROPERTY = 'CurrentReport';
export const FLASHING_CELL_DEFAULT_UP_COLOR_STATE_PROPERTY = 'DefaultUpColor';
export const FLASHING_CELL_DEFAULT_DOWN_COLOR_STATE_PROPERTY = 'DefautDownColor';
export const FLASHING_CELL_DEFAULT_DURATION_STATE_PROPERTY = 'DefaultDuration';
export const CURRENT_THEME_STATE_PROPERTY = 'CurrentTheme';
export const QUICK_SEARCH_TEXT_STATE_PROPERTY = 'QuickSearchText';
export const QUICK_SEARCH_STYLE_STATE_PROPERTY = 'Style';
export const SMART_EDIT_VALUE_STATE_PROPERTY = 'SmartEditValue';
export const SMART_EDIT_MATH_OPERATION_STATE_PROPERTY = 'MathOperationz';
// consts for Themes
export const SYSTEM_THEMES = [{
  Name: LIGHT_THEME,
  Description: 'Light Theme'
}, {
  Name: DARK_THEME,
  Description: 'Dark Theme'
}, {
  Name: OS_THEME,
  Description: 'OS Theme'
}];
export const VISUAL_DATA_REPORT = 'Visual Data';
export const ALL_DATA_REPORT = 'All Data';
export const CURRENT_DATA_REPORT = 'Current Data';
export const SELECTED_CELLS_REPORT = 'Selected Cells';
export const SELECTED_ROWS_REPORT = 'Selected Rows';
export const THEME_STYLE = `ab--theme-`;