import * as React from 'react';
import { ButtonDelete } from '../Components/Buttons/ButtonDelete';
import * as ChartingRedux from '../../Redux/ActionsReducers/ChartingRedux';
import { isAgChartDefinition } from '../../PredefinedConfig/ChartingState';
export const DeleteChartButton = props => {
  return React.createElement(ButtonDelete, {
    iconSize: props.iconSize,
    disabled: !props.chart,
    ConfirmAction: isAgChartDefinition(props.chart) ? ChartingRedux.ChartingDeleteChart(props.chart) : ChartingRedux.ChartingDeleteExternalChart(props.chart),
    ConfirmationMsg: `Are you sure you want to delete this Charts?`,
    ConfirmationTitle: 'Delete Charts',
    accessLevel: props.accessLevel,
    tooltip: "Delete Chart"
  });
};