import * as React from 'react';
import { Box } from 'rebass';
import { Tabs } from '../../../../components/Tabs';
import { useAdaptable } from '../../../AdaptableContext';
import { useOnePageAdaptableWizardContext } from '../../../Wizard/OnePageAdaptableWizard';
import { Tag } from '../../../../components/Tag';
import { ExpressionEditor } from '../../../../components/ExpressionEditor';
import { GridFilterModuleId } from '../../../../Utilities/Constants/ModuleConstants';
import StringExtensions from '../../../../Utilities/Extensions/StringExtensions';
import { useGridFilterOptionsForExpressionEditorProps } from '../../../GridFilter/useGridFilterOptionsForExpressionEditor';
import Panel from '../../../../components/Panel';
export const isGridFiltersValid = (layout, api) => {
  var _a;
  const expression = (_a = layout === null || layout === void 0 ? void 0 : layout.GridFilter) === null || _a === void 0 ? void 0 : _a.Expression;
  if (StringExtensions.IsNullOrEmpty(expression)) {
    return true;
  }
  return api.internalApi.getQueryLanguageService().validateBoolean(expression, GridFilterModuleId).isValid || 'Invalid Expression';
};
export const GridFilterSectionSummary = () => {
  var _a, _b;
  const {
    data: layout
  } = useOnePageAdaptableWizardContext();
  return React.createElement(Box, null, React.createElement(Tag, {
    mb: 1,
    mr: 1
  }, (_b = (_a = layout === null || layout === void 0 ? void 0 : layout.GridFilter) === null || _a === void 0 ? void 0 : _a.Expression) !== null && _b !== void 0 ? _b : 'No Grid Filter'));
};
export const GridFilterSection = props => {
  var _a, _b;
  const {
    api
  } = useAdaptable();
  const {
    data: layout
  } = useOnePageAdaptableWizardContext();
  const initialData = React.useMemo(() => api.internalApi.getQueryPreviewData(), []);
  const expressionEditorProps = useGridFilterOptionsForExpressionEditorProps();
  const expressionEditorContent = React.createElement(ExpressionEditor, Object.assign({}, expressionEditorProps, {
    allowSaveNamedQuery: false,
    type: 'boolean',
    module: GridFilterModuleId,
    value: (_b = (_a = layout === null || layout === void 0 ? void 0 : layout.GridFilter) === null || _a === void 0 ? void 0 : _a.Expression) !== null && _b !== void 0 ? _b : '',
    onChange: expression => {
      props.onChange(Object.assign(Object.assign({}, layout), {
        GridFilter: Object.assign(Object.assign({}, layout.GridFilter), {
          Expression: expression
        })
      }));
    },
    initialData: initialData,
    columns: api.columnApi.getQueryableColumns(),
    fields: api.expressionApi.internalApi.getAvailableFields(),
    namedQueries: api.namedQueryApi.getNamedQueries(),
    api: api
  }));
  return React.createElement(Tabs, {
    style: {
      height: '100%'
    }
  }, React.createElement(Tabs.Tab, null, "Grid Filters"), React.createElement(Tabs.Content, null, React.createElement(Panel, null, expressionEditorContent)));
};