import { __rest } from "tslib";
import * as React from 'react';
import SimpleButton from '../SimpleButton';
import { useExpressionEditor } from './EditorContext';
let dragImage;
const DRAG_IMAGE_SRC = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
function EditorButton(props) {
  const {
      data
    } = props,
    buttonProps = __rest(props, ["data"]);
  const {
    textAreaRef
  } = useExpressionEditor();
  return React.createElement(SimpleButton, Object.assign({
    "data-name": 'operator-or-fn',
    variant: "text",
    draggable: true,
    onDragStart: event => {
      if (!dragImage) {
        // we want to lazy init the image since otherwise it fails in SSR environments if it is declared outside the cmp - ReferenceError: Image is not defined
        dragImage = new Image(0, 0);
        dragImage.src = DRAG_IMAGE_SRC;
      }
      document.getSelection().empty();
      event.dataTransfer.setData('text', data + ' ');
      event.dataTransfer.setDragImage(dragImage, 0, 0);
    },
    onDragEnd: () => {
      var _a;
      try {
        (_a = document.getSelection()) === null || _a === void 0 ? void 0 : _a.collapseToEnd();
      } catch (err) {
        console.error(err);
      }
    },
    onClick: () => {
      var _a, _b;
      (_a = textAreaRef.current) === null || _a === void 0 ? void 0 : _a.focus();
      try {
        document.execCommand('insertText', false, data + ' ');
        (_b = document.getSelection()) === null || _b === void 0 ? void 0 : _b.collapseToEnd();
      } catch (err) {
        console.error(err);
      }
    },
    style: {
      cursor: 'grab'
    }
  }, buttonProps));
}
export default EditorButton;