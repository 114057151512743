import { __rest } from "tslib";
import * as React from 'react';
import { useRef, useState } from 'react';
import { Box, Flex } from 'rebass';
import join from '../utils/join';
const Square = props => {
  return React.createElement("div", {
    className: `ab-CheckBox__square ab-CheckBox__square--${props.type}`
  });
};
const CheckBox = _a => {
  var {
      children,
      checked,
      onChange,
      value,
      name,
      disabled,
      readOnly,
      variant = 'default',
      gapDistance = 'var(--ab-space-1)',
      childrenPosition = 'end',
      as = 'label'
    } = _a,
    props = __rest(_a, ["children", "checked", "onChange", "value", "name", "disabled", "readOnly", "variant", "gapDistance", "childrenPosition", "as"]);
  const [stateChecked, setStateChecked] = useState(false);
  const computedChecked = checked !== undefined ? checked : stateChecked;
  const onInputChange = event => {
    if (readOnly) {
      return;
    }
    const newChecked = event.target.checked;
    if (checked === undefined) {
      setStateChecked(newChecked);
    }
    if (onChange) {
      onChange(newChecked, event);
    }
  };
  const type = computedChecked === true ? 'checked' : computedChecked === false ? 'unchecked' : 'indeterminate';
  const gap = React.createElement("div", {
    style: {
      marginLeft: gapDistance,
      display: 'inline-block'
    }
  });
  children = children ? React.createElement("div", {
    style: {
      display: 'inline-block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }, children) : null;
  const before = childrenPosition === 'start' ? children : null;
  const beforeGap = childrenPosition === 'start' && children ? gap : null;
  const after = childrenPosition === 'end' ? children : null;
  const afterGap = childrenPosition === 'end' && children ? gap : null;
  let indeterminate = computedChecked === null;
  const checkboxRef = useRef();
  React.useEffect(() => {
    checkboxRef.current.indeterminate = indeterminate;
  }, [indeterminate]);
  let input = React.createElement("input", {
    className: `ab-CheckBox-input`,
    ref: checkboxRef,
    disabled: disabled,
    readOnly: readOnly,
    checked: !!computedChecked,
    type: "checkbox",
    name: name,
    value: value,
    style: {
      verticalAlign: 'middle',
      opacity: 0,
      cursor: 'pointer'
    },
    onChange: onInputChange
  });
  return React.createElement(Box, Object.assign({
    my: 2
  }, props, {
    className: join('ab-CheckBox', `ab-CheckBox--${type}`, `ab-CheckBox--variant-${variant}`, disabled ? 'ab-CheckBox--disabled' : '', readOnly ? 'ab-CheckBox--readonly' : '', props.className),
    style: props.style,
    as: as
  }), before, beforeGap, input, React.createElement(Square, {
    type: type
  }), afterGap, after);
};
export const CheckBoxGroup = props => {
  const {
      orientation
    } = props,
    flexProps = __rest(props, ["orientation"]);
  return React.createElement(Flex, Object.assign({
    className: "ab-CheckBoxGroup",
    flexDirection: orientation == 'horizontal' ? 'row' : 'column',
    alignItems: orientation == 'horizontal' ? 'center' : 'flex=start'
  }, flexProps));
};
export { CheckBox };