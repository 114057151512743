import { ColDef } from '@ag-grid-community/core';

export const defaultColDef: ColDef = {
  autoHeaderHeight: true,
  editable: false,
  enableRowGroup: true,
  filter: true,
  floatingFilter: false,
  resizable: true,
  sortable: true,
  suppressHeaderMenuButton: true,
  unSortIcon: true,
  wrapHeaderText: true
};
