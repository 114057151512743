import * as React from 'react';
import { Box } from 'rebass';
import * as ModuleConstants from '../../../../Utilities/Constants/ModuleConstants';
import { Tabs } from '../../../../components/Tabs';
import { useAdaptable } from '../../../AdaptableContext';
import { AdaptableObjectListItemView } from '../../../Components/AdaptableObjectList/AdaptableObjectList';
import { useOnePageAdaptableWizardContext } from '../../../Wizard/OnePageAdaptableWizard';
import { getLayoutFilterViewItems } from '../../../../Strategy/Utilities/Layout/getLayoutFilterViewItems';
import { Tag } from '../../../../components/Tag';
import { PredicateEditor } from '../../../Components/PredicateEditor/PredicateEditor';
import FormLayout, { FormRow } from '../../../../components/FormLayout';
import { ColumnSelector } from '../../../Components/Selectors/ColumnSelector';
export const isColumnFiltersValid = layout => {
  var _a, _b;
  const invalidColumnFilters = (_b = (_a = layout.ColumnFilters) !== null && _a !== void 0 ? _a : []) === null || _b === void 0 ? void 0 : _b.filter(columnFilter => {
    var _a;
    return !((_a = columnFilter === null || columnFilter === void 0 ? void 0 : columnFilter.Predicate) === null || _a === void 0 ? void 0 : _a.PredicateId);
  });
  if (invalidColumnFilters.length > 0) {
    return 'Please select a Predicate for each Column Filter';
  }
  return true;
};
export const FilterSectionSummary = () => {
  const {
    data: layout
  } = useOnePageAdaptableWizardContext();
  const adaptable = useAdaptable();
  const fitlerViewItems = getLayoutFilterViewItems(layout, adaptable.api);
  return React.createElement(Box, null, fitlerViewItems.values.length ? fitlerViewItems.values.map(value => React.createElement(Tag, {
    key: value,
    mb: 1,
    mr: 1
  }, value)) : React.createElement(Tag, null, "No Column Filters"));
};
export const FilterSection = props => {
  var _a;
  const adaptable = useAdaptable();
  const filterModule = adaptable.ModuleService.getModuleById(ModuleConstants.ColumnFilterModuleId);
  const {
    data: layout
  } = useOnePageAdaptableWizardContext();
  const layoutFilters = (_a = layout.ColumnFilters) !== null && _a !== void 0 ? _a : [];
  const handleDelete = React.useCallback(columnFilter => {
    props.onChange(Object.assign(Object.assign({}, layout), {
      ColumnFilters: layoutFilters.filter(layoutFilter => layoutFilter.ColumnId !== columnFilter.ColumnId)
    }));
  }, [layout]);
  const handlePredicateEdit = React.useCallback((predicate, columnFilter) => {
    props.onChange(Object.assign(Object.assign({}, layout), {
      ColumnFilters: layoutFilters.map(layoutFilter => layoutFilter.ColumnId === columnFilter.ColumnId ? Object.assign(Object.assign({}, columnFilter), {
        Predicate: predicate
      }) : layoutFilter)
    }));
  }, [layout.ColumnFilters]);
  const columnsOptions = React.useMemo(() => adaptable.api.columnApi.getFilterableColumns().filter(abColumn => {
    var _a;
    return !((_a = layout.ColumnFilters) === null || _a === void 0 ? void 0 : _a.some(columnFilter => abColumn.columnId === columnFilter.ColumnId));
  }).map(({
    columnId
  }) => ({
    label: adaptable.api.columnApi.getFriendlyNameForColumnId(columnId),
    value: columnId
  })), [layout, layoutFilters]);
  return React.createElement(Tabs, {
    style: {
      height: '100%'
    }
  }, React.createElement(Tabs.Tab, null, "Column Filters"), React.createElement(Tabs.Content, null, React.createElement(FormLayout, {
    mb: 2
  }, React.createElement(FormRow, {
    label: "Add Column Filter"
  }, React.createElement(ColumnSelector, {
    filterColumn: column => column.filterable && !layoutFilters.some(f => f.ColumnId === column.columnId),
    placeholder: "Select Column to create Filter",
    onChange: option => {
      props.onChange(Object.assign(Object.assign({}, layout), {
        ColumnFilters: [...layoutFilters, {
          ColumnId: option,
          Predicate: null
        }]
      }));
    }
  }))), (layoutFilters === null || layoutFilters === void 0 ? void 0 : layoutFilters.length) > 0 && React.createElement(Box, {
    p: 0,
    as: "ul"
  }, layoutFilters.map(columnFilter => {
    const predicateDefs = adaptable.api.columnFilterApi.getFilterPredicateDefsForColumnId(columnFilter.ColumnId);
    const items = [...filterModule.toView(columnFilter).items, {
      name: 'Column Filter',
      view: React.createElement(PredicateEditor, {
        columnId: columnFilter.ColumnId,
        predicate: columnFilter.Predicate,
        predicateDefs: predicateDefs,
        onChange: predicate => handlePredicateEdit(predicate, columnFilter),
        onClear: () => handlePredicateEdit(null, columnFilter)
      })
    }];
    return React.createElement(AdaptableObjectListItemView, {
      module: filterModule,
      abObject: columnFilter,
      entityType: "Column Filter",
      showActions: true,
      showEditButton: false,
      items: items,
      onDelete: () => handleDelete(columnFilter),
      key: columnFilter.ColumnId
    });
  }))));
};