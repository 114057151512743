import { AdaptableModuleBase } from './AdaptableModuleBase';
import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
import * as LayoutRedux from '../Redux/ActionsReducers/LayoutRedux';
import * as SystemRedux from '../Redux/ActionsReducers/SystemRedux';
import { FilterStatusBarSubPanelPopover } from '../View/Filter/FilterStatusBarSubPanelPopover';
import { getFilterPreview } from './Utilities/Layout/getLayoutFilterViewItems';
export class ColumnFilterModule extends AdaptableModuleBase {
  constructor(api) {
    super(ModuleConstants.ColumnFilterModuleId, ModuleConstants.ColumnFilterFriendlyName, 'filter', 'FilterPopup', 'Advanced filtering capability allows users to see precisely the data they want', api);
  }
  setModuleEntitlement() {
    if (!this.api.optionsApi.getColumnFilterOptions().useAdaptableColumnFiltering) {
      this.AccessLevel = 'Hidden';
    } else {
      this.AccessLevel = this.api.entitlementApi.getEntitlementAccessLevelForModule(this.moduleInfo.ModuleName);
    }
  }
  getModuleAdaptableObjects() {
    return this.api.columnFilterApi.getColumnFilters();
  }
  getExplicitlyReferencedColumnIds(columnFilter) {
    return [columnFilter.ColumnId];
  }
  hasNamedQueryReferences() {
    return false;
  }
  onAdaptableReady() {
    if (this.api.optionsApi.getColumnFilterOptions().quickFilterOptions.showQuickFilter == false) {
      this.api.columnFilterApi.hideQuickFilterBar();
    }
    const columnFilters = this.api.columnFilterApi.getColumnFilters();
    columnFilters.forEach(cf => {
      if (!this.api.columnApi.isColumnInGrid(cf.ColumnId)) {
        this.api.logError('Column Filter contains missing column: ' + cf.ColumnId);
      }
    });
  }
  createColumnMenuItems(column) {
    let columnFilterMenuItems = [];
    if (column && column.filterable && this.api.optionsApi.getColumnFilterOptions().useAdaptableColumnFiltering) {
      if (this.api.gridApi.isQuickFilterAvailable()) {
        const isFilterVisible = this.api.columnFilterApi.isQuickFilterVisible();
        if (this.isModuleEditable() && this.api.optionsApi.getColumnFilterOptions().useAdaptableColumnFiltering) {
          columnFilterMenuItems.push(this.createMenuItemReduxAction(isFilterVisible ? 'column-filter-bar-hide' : 'column-filter-bar-show', isFilterVisible ? 'Hide Filter Bar' : 'Show Filter Bar', isFilterVisible ? 'filter-off' : 'filter', isFilterVisible ? SystemRedux.SystemQuickFilterBarHide() : SystemRedux.SystemQuickFilterBarShow()));
        }
      }
      const existingColumnFilter = this.getExistingColumnFilter(column);
      if (existingColumnFilter) {
        columnFilterMenuItems.push(this.createMenuItemReduxAction('column-filter-clear', 'Clear Filter', 'close', LayoutRedux.LayoutColumnFilterClear(existingColumnFilter)));
        columnFilterMenuItems.push(this.createMenuItemReduxAction(existingColumnFilter.IsSuspended ? 'column-filter-unsuspend' : 'column-filter-suspend', existingColumnFilter.IsSuspended ? 'Unsuspend Filter' : 'Suspend Filter', existingColumnFilter.IsSuspended ? 'resume' : 'pause', existingColumnFilter.IsSuspended ? LayoutRedux.LayoutColumnFilterUnSuspend(existingColumnFilter) : LayoutRedux.LayoutColumnFilterSuspend(existingColumnFilter)));
      }
      return columnFilterMenuItems;
    }
  }
  createContextMenuItems(menuContext) {
    if (!menuContext.isRowGroupColumn && this.isModuleAvailable()) {
      if (menuContext.adaptableColumn && menuContext.adaptableColumn.filterable && menuContext.isSingleSelectedColumn && menuContext.gridCell != null && this.api.optionsApi.getColumnFilterOptions().useAdaptableColumnFiltering) {
        const existingColumnFilter = this.getExistingColumnFilter(menuContext.adaptableColumn);
        if (!existingColumnFilter) {
          if (!menuContext.isRowGroupColumn && !menuContext.isGroupedNode) {
            let isMultiple = menuContext.selectedCellInfo.gridCells.length > 1;
            let clickFunction = isMultiple ? () => {
              this.api.columnFilterApi.internalApi.createValuesColumnFilterForCells(menuContext.selectedCellInfo.gridCells);
            } : () => {
              this.api.columnFilterApi.internalApi.createEqualityColumnFilterForCell(menuContext.selectedCellInfo.gridCells[0]);
            };
            return [this.createMenuItemClickFunction('column-filter-on-cell-value', isMultiple ? 'Filter on Cell Values' : 'Filter on Cell Value', this.moduleInfo.Glyph, clickFunction)];
          }
        } else {
          return [this.createMenuItemReduxAction('column-filter-clear', 'Clear Filter', 'close', LayoutRedux.LayoutColumnFilterClear(existingColumnFilter)), this.createMenuItemClickFunction(existingColumnFilter.IsSuspended ? 'column-filter-unsuspend' : 'column-filter-suspend', existingColumnFilter.IsSuspended ? 'Unsuspend Filter' : 'Suspend Filter', existingColumnFilter.IsSuspended ? 'resume' : 'pause', existingColumnFilter.IsSuspended ? () => {
            this.api.columnFilterApi.unSuspendColumnFilter(existingColumnFilter);
          } : () => {
            this.api.columnFilterApi.suspendColumnFilter(existingColumnFilter);
          })];
        }
      }
    }
  }
  getExistingColumnFilter(column) {
    return this.api.columnFilterApi.getColumnFilters().find(x => x.ColumnId == column.columnId);
  }
  getTeamSharingAction() {
    return {
      ModuleEntities: this.api.columnFilterApi.getColumnFilters(),
      AddAction: LayoutRedux.LayoutColumnFilterAdd,
      EditAction: LayoutRedux.LayoutColumnFilterEdit //TODO need to change this to ColumnFilterSet
    };
  }
  toViewCompact(filter) {
    return {
      item: {
        values: [this.api.columnFilterApi.internalApi.columnFilterToString(filter)]
      },
      abObject: filter
    };
  }
  toView(filter) {
    return {
      items: [{
        name: 'Column',
        values: [this.api.columnApi.getFriendlyNameForColumnId(filter.ColumnId)]
      }, {
        name: 'Filter',
        values: [getFilterPreview(filter, this.api)]
      }],
      abObject: filter
    };
  }
  toViewAll() {
    return this.getModuleAdaptableObjects().map(filter => this.toView(filter));
  }
  getViewProperties() {
    const api = this.api;
    return {
      getSuspendAction: columnFilter => LayoutRedux.LayoutColumnFilterSuspend(columnFilter),
      getUnSuspendAction: columnFilter => LayoutRedux.LayoutColumnFilterUnSuspend(columnFilter),
      getCompactSuspendAction: columnFilter => LayoutRedux.LayoutColumnFilterSuspend(columnFilter),
      getCompactUnSuspendAction: columnFilter => LayoutRedux.LayoutColumnFilterUnSuspend(columnFilter),
      getSuspendAllAction: () => LayoutRedux.LayoutColumnFilterSuspendAll(),
      getUnSuspendAllAction: () => LayoutRedux.LayoutColumnFilterUnSuspendAll(),
      getDeleteAction: LayoutRedux.LayoutColumnFilterClear,
      getCompactDeleteAction: LayoutRedux.LayoutColumnFilterClear,
      getDeleteAllAction: LayoutRedux.LayoutColumnFilterClearAll,
      emptyView: `
        There are currently no Column Filters applied. \n

        Create Column Filters by using the Filter Bar, the Filter Form (in the Column Header Menu) or by editing a Layout.
      `,
      getStatusBarPanelProps() {
        let text;
        const filters = api.columnFilterApi.getColumnFilters();
        if (filters.length === 0) {
          text = '0 Filters';
        } else if (filters.length === 1) {
          text = '1 Filter';
        } else {
          text = filters.length + ' Filters';
        }
        const popover = filters.length ? FilterStatusBarSubPanelPopover : null;
        return {
          content: text,
          popover,
          popoverMinWidth: 320
        };
      }
    };
  }
}