import * as React from 'react';
import FileDroppable from '../../../../../components/FileDroppable/index';
import HelpBlock from '../../../../../components/HelpBlock/index';
export const ImportFileSection = props => {
  return React.createElement(FileDroppable, {
    loadingText: React.createElement(HelpBlock, {
      mb: 2
    }, "File loaded , file ready to be imported."),
    message: props.message,
    helpText: "Data Import",
    defaultText: "Click here to select a file to load, or drag it here",
    dragOverText: "Drop file here to start Data Import",
    height: "100%",
    fileAccept: props.supportedFileFormats,
    readFile: props.readFile
  });
};