import { useMemo } from 'react';
import { useAdaptable } from '../../View/AdaptableContext';
const useAgGridClassName = (deps = []) => {
  const adaptable = useAdaptable();
  return useMemo(() => {
    if (adaptable) {
      return adaptable.getAgGridCurrentThemeName();
    }
    return '';
  }, deps);
};
export default useAgGridClassName;