import { AdaptableModuleBase } from './AdaptableModuleBase';
import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
import * as StyledColumnRedux from '../Redux/ActionsReducers/StyledColumnRedux';
import { StyledColumnWizard } from '../View/StyledColumn/Wizard/StyledColumnWizard';
import { renderStyledColumnStyleSummary } from '../View/StyledColumn/Wizard/StyledColumnWizardStyleSection/StyledColumnWizardStyleSection';
import ObjectFactory from '../Utilities/ObjectFactory';
import { getObjectTagsViewItems } from './Utilities/getObjectTagsViewItems';
import { StyledColumnBadgePreview } from '../View/StyledColumn/Wizard/StyledColumnWizardStyleSection/Components/StyledColumnBadgePreview';
export class StyledColumnModule extends AdaptableModuleBase {
  constructor(api) {
    super(ModuleConstants.StyledColumnModuleId, ModuleConstants.StyledColumnFriendlyName, 'brush', 'StyledColumnPopup',
    // to change
    'Create a Special Column Style e.g Gradient or Percent Bar', api);
  }
  getModuleAdaptableObjects(config) {
    return this.api.styledColumnApi.getStyledColumns(config);
  }
  getExplicitlyReferencedColumnIds(formatColumn) {
    if (this.api.columnScopeApi.scopeHasColumns(formatColumn.Scope)) {
      return this.api.columnScopeApi.getColumnsForScope(formatColumn.Scope).map(adaptableColumn => adaptableColumn.columnId);
    }
    return [];
  }
  hasNamedQueryReferences() {
    return false;
  }
  createColumnMenuItems(column) {
    let returnColumnMenuItems = [];
    // Need a Column and an editable Module
    if (column && this.isModuleEditable()) {
      let styledColumn = this.api.styledColumnApi.getStyledColumnForColumnId(column.columnId);
      // First look at Sparkline Columns - needs to be editable array type
      if (this.api.columnApi.hasNumericArrayDataType(column.columnId) && column.IsReadOnly !== true && this.api.styledColumnApi.canDisplaySparklines()) {
        let sparklineColumnExists = column.isSparkline && Boolean(styledColumn);
        if (column.isSparkline && !sparklineColumnExists) {
          // do nothing as we only want to continue if there is no sparkline column or if its in state
          // because if just one then it was created in AG Grid and we dont want to edit it
        } else {
          const hasSparklineStyle = styledColumn != null && styledColumn.SparkLineStyle != null;
          if (!hasSparklineStyle) {
            styledColumn = Object.assign(Object.assign({}, ObjectFactory.CreateEmptyStyledColumn()), {
              ColumnId: column.columnId,
              SparkLineStyle: {}
            });
          }
          let label = hasSparklineStyle ? 'Edit ' : 'Create ';
          let name = hasSparklineStyle ? 'styled-column-sparkline-edit' : 'styled-column-sparkline-add';
          let popupParam = {
            action: sparklineColumnExists ? 'Edit' : 'New',
            source: 'ColumnMenu',
            value: styledColumn,
            config: {
              defaultCurrentSectionName: 'Settings'
            }
          };
          returnColumnMenuItems.push(this.createMenuItemShowPopup(name, label + 'Sparkline Column', this.moduleInfo.Popup, this.getGlyphForStyledColumn(styledColumn), popupParam));
        }
      }
      // Next do non sparkline
      else if (!column.isSparkline) {
        // Edit is common
        if (styledColumn) {
          if (!styledColumn.IsReadOnly) {
            let defaultCurrentSectionName = undefined;
            if (styledColumn.BadgeStyle) {
              defaultCurrentSectionName = 'Badges';
            } else {
              defaultCurrentSectionName = 'Style';
            }
            const popupParam = {
              action: 'Edit',
              source: 'ColumnMenu',
              value: styledColumn,
              config: {
                defaultCurrentSectionName
              }
            };
            const icon = this.getGlyphForStyledColumn(styledColumn);
            const typeLabel = this.getTypeLabel(styledColumn);
            const label = `Edit ${typeLabel}`;
            const name = this.getTypeName(styledColumn);
            returnColumnMenuItems.push(this.createMenuItemShowPopup(name, label, this.moduleInfo.Popup, icon, popupParam));
          }
        } else {
          const newStyledColumn = Object.assign(Object.assign({}, ObjectFactory.CreateEmptyStyledColumn()), {
            ColumnId: column.columnId
          });
          const popupParam = {
            action: 'New',
            source: 'ColumnMenu',
            value: newStyledColumn,
            config: {}
          };
          const badgeLabel = 'Create Badge Style';
          const newBadgeButton = this.createMenuItemShowPopup('styled-column-badge-add', badgeLabel, this.moduleInfo.Popup, 'badge', Object.assign(Object.assign({}, popupParam), {
            value: Object.assign(Object.assign({}, newStyledColumn), {
              BadgeStyle: {
                Badges: [ObjectFactory.CreateDefaultStyledColumnBadge()]
              }
            }),
            config: {
              defaultCurrentSectionName: 'Badges'
            }
          }));
          switch (column.dataType) {
            // Number
            // Create Styled Column - Gradient | Percent Bar | Badge
            case 'Number':
              const emptyRanges = this.api.columnScopeApi.createCellColorRangesForScope({
                ColumnIds: [column.columnId]
              });
              const newGrandientButton = this.createMenuItemShowPopup('styled-column-gradient-add', 'Create Gradient Column', this.moduleInfo.Popup, 'gradient', Object.assign(Object.assign({}, popupParam), {
                value: Object.assign(Object.assign({}, newStyledColumn), {
                  GradientStyle: {
                    CellRanges: emptyRanges
                  }
                }),
                config: {
                  defaultCurrentSectionName: 'Style'
                }
              }));
              const newPercentBarButton = this.createMenuItemShowPopup('styled-column-percent-bar-add', 'Create Percent Bar', this.moduleInfo.Popup, 'percent', Object.assign(Object.assign({}, popupParam), {
                value: Object.assign(Object.assign({}, newStyledColumn), {
                  PercentBarStyle: {
                    CellRanges: emptyRanges
                  }
                }),
                config: {
                  defaultCurrentSectionName: 'Style'
                }
              }));
              returnColumnMenuItems.push(...[newGrandientButton, newPercentBarButton, newBadgeButton]);
              break;
            case 'String':
              returnColumnMenuItems.push(newBadgeButton);
              break;
            case 'StringArray':
              returnColumnMenuItems.push(newBadgeButton);
              break;
          }
        }
      }
    }
    return returnColumnMenuItems;
  }
  getGlyphForStyledColumn(styledColumn) {
    if (styledColumn.GradientStyle) {
      return 'gradient';
    } else if (styledColumn.PercentBarStyle) {
      return 'percent';
    } else if (styledColumn.SparkLineStyle) {
      return 'spark-line';
    } else if (styledColumn.BadgeStyle) {
      return 'badge';
    }
    return this.moduleInfo.Glyph;
  }
  getTeamSharingAction() {
    return {
      ModuleEntities: this.api.styledColumnApi.getStyledColumns(),
      AddAction: StyledColumnRedux.StyledColumnAdd,
      EditAction: StyledColumnRedux.StyledColumnEdit
    };
  }
  toView(styledColumn) {
    var _a, _b, _c, _d, _e, _f;
    const specificTypeItems = [];
    if (styledColumn.GradientStyle || styledColumn.PercentBarStyle) {
      specificTypeItems.push({
        name: 'Style',
        view: () => {
          return renderStyledColumnStyleSummary(styledColumn, this.api);
        }
      });
    }
    if (styledColumn.BadgeStyle) {
      specificTypeItems.push({
        name: 'Settings',
        values: [`Data Rows: ${((_b = (_a = styledColumn.BadgeStyle) === null || _a === void 0 ? void 0 : _a.RowScope) === null || _b === void 0 ? void 0 : _b.ExcludeDataRows) ? 'No' : 'Yes'}`, `Group Rows: ${((_d = (_c = styledColumn.BadgeStyle) === null || _c === void 0 ? void 0 : _c.RowScope) === null || _d === void 0 ? void 0 : _d.ExcludeGroupRows) ? 'No' : 'Yes'}`, `Summary Rows: ${((_f = (_e = styledColumn.BadgeStyle) === null || _e === void 0 ? void 0 : _e.RowScope) === null || _f === void 0 ? void 0 : _f.ExcludeSummaryRows) ? 'No' : 'Yes'}`]
      });
    }
    if (styledColumn.BadgeStyle) {
      specificTypeItems.push({
        name: 'Preview',
        view: StyledColumnBadgePreview
      });
    }
    return {
      abObject: styledColumn,
      items: [{
        name: 'Type',
        values: [this.getTypeLabel(styledColumn)]
      }, {
        name: 'Column',
        values: [this.api.columnApi.getFriendlyNameForColumnId(styledColumn.ColumnId)]
      }, ...specificTypeItems,, getObjectTagsViewItems(styledColumn, this.api)].filter(Boolean)
    };
  }
  toViewAll() {
    return this.getModuleAdaptableObjects({
      includeLayoutNotAssociatedObjects: this.showLayoutNotAssociatedObjects()
    }).map(styledColumn => this.toView(styledColumn));
  }
  getViewProperties() {
    return {
      getEditAction: StyledColumnRedux.StyledColumnEdit,
      getDeleteAction: StyledColumnRedux.StyledColumnDelete,
      getSuspendAction: StyledColumnRedux.StyledColumnSuspend,
      getUnSuspendAction: StyledColumnRedux.StyledColumnUnSuspend,
      getSuspendAllAction: StyledColumnRedux.StyledColumnSuspendAll,
      getUnSuspendAllAction: StyledColumnRedux.StyledColumnUnSuspendAll,
      getEditWizard() {
        return StyledColumnWizard;
      }
    };
  }
  canBeAssociatedWithLayouts() {
    return true;
  }
  getTypeLabel(styledColumn) {
    let type = null;
    if (styledColumn.GradientStyle) {
      type = 'Gradient Column';
    } else if (styledColumn.PercentBarStyle) {
      type = 'Percent Bar';
    } else if (styledColumn.SparkLineStyle) {
      type = 'Spark Line';
    } else if (styledColumn.BadgeStyle) {
      type = 'Badge Style';
    }
    return type;
  }
  getTypeName(styledColumn) {
    let type = null;
    if (styledColumn.GradientStyle) {
      type = 'styled-column-gradient-edit';
    } else if (styledColumn.PercentBarStyle) {
      type = 'styled-column-percent-bar-edit';
    } else if (styledColumn.SparkLineStyle) {
      type = 'styled-column-sparkline-edit';
    } else if (styledColumn.BadgeStyle) {
      type = 'styled-column-badge-edit';
    }
    return type;
  }
}