import * as React from 'react';
import { useMemo } from 'react';
import { BaseEditorInput } from './BaseEditorInput';
import { editorButtonsSearch } from './editorButtonsSearch';
import { useExpressionEditor } from './EditorContext';
import StringExtensions from '../../Utilities/Extensions/StringExtensions';
import { editorButtonsAggregatedScalar } from './editorButtonsAggregatedScalar';
import { cumulativeAggregatedExpressionFunctions, quantileAggregatedExpressionFunctions } from '../../Utilities/ExpressionFunctions/aggregatedScalarExpressionFunctions';
import { editorButtonsCumulativeAggregatedScalar } from './editorButtonsCumulativeAggregatedScalar';
import { editorButtonsQuantileAggregatedScalar } from './editorButtonsQuantileAggregatedScalar';
function EditorInput(props) {
  const moduleExpressionFunctions = props.api.internalApi.getQueryLanguageService().getModuleExpressionFunctionsMap(props.module);
  const getFilteredAggregatedExpressionFunctions = (availableAggregatedExpressionFunctions, type) => {
    let filteredCollection = [];
    if (type === 'aggregatedScalar') {
      const excludeSet = new Set([
      // we cannot have a hardcodede set of functions,
      // we need to allow custom expressions
      ...cumulativeAggregatedExpressionFunctions, ...quantileAggregatedExpressionFunctions]);
      filteredCollection = Object.keys(availableAggregatedExpressionFunctions).filter(key =>
      //  need to allow standard ones
      cumulativeAggregatedExpressionFunctions.includes(key) ||
      // and anything else that is not cumul or quantile
      !excludeSet.has(key));
    } else {
      const includeExpressionFunctions = type === 'cumulativeAggregatedScalar' ? cumulativeAggregatedExpressionFunctions : quantileAggregatedExpressionFunctions;
      filteredCollection = Object.keys(availableAggregatedExpressionFunctions).filter(key => includeExpressionFunctions.includes(key));
    }
    return filteredCollection.reduce((obj, key) => {
      obj[key] = availableAggregatedExpressionFunctions[key];
      return obj;
    }, {});
  };
  const expressionFunctions = useMemo(() => {
    return props.type === 'aggregatedScalar' || props.type === 'cumulativeAggregatedScalar' || props.type === 'quantileAggregatedScalar' ? getFilteredAggregatedExpressionFunctions(moduleExpressionFunctions.aggregatedScalarFunctions, props.type) : Object.assign(Object.assign({}, moduleExpressionFunctions.booleanFunctions), moduleExpressionFunctions.scalarFunctions);
  }, [props.type]);
  const {
    setSelectedFunction
  } = useExpressionEditor();
  const queryName = `${StringExtensions.CapitaliseFirstLetter(props.type)}Query`;
  return React.createElement(BaseEditorInput, {
    type: 'main',
    value: props.value,
    placeholder: `Create ${queryName}`,
    onChange: props.onChange,
    onSelectedFunctionChange: setSelectedFunction,
    expressionFunctions: expressionFunctions,
    editorButtons: props.type === 'aggregatedScalar' ? editorButtonsAggregatedScalar : props.type === 'cumulativeAggregatedScalar' ? editorButtonsCumulativeAggregatedScalar : props.type === 'quantileAggregatedScalar' ? editorButtonsQuantileAggregatedScalar : editorButtonsSearch,
    testData: props.testData,
    isFullExpression: props.isFullExpression,
    hideResultPreview: props.type === 'aggregatedScalar' || props.type === 'cumulativeAggregatedScalar' || props.type === 'quantileAggregatedScalar',
    api: props.api
  });
}
export default EditorInput;