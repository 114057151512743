/**
 * Calls the callback if the promise does not resolve in a amount of time.
 *
 * @param promise
 * @param callback Called if promise does not resolve in the time defined by 'delay'
 * @param delay Time after which if the promise was not resolved the callback (fn) is called.
 */
export const runIfNotResolvedIn = async (promise, callback, delay = 100) => {
  let isFulfilled = false;
  promise === null || promise === void 0 ? void 0 : promise.then(() => {
    isFulfilled = true;
  });
  (async () => {
    await new Promise(resolve => setTimeout(resolve, delay));
    if (!isFulfilled) {
      callback();
    }
  })();
  return promise;
};