/**
 * @ReduxAction Plugins have been instantiated
 */
export const PLUGINS_SET_PLUGIN_STATE = 'PLUGINS_SET_PLUGIN_STATE';
/**
 * @ReduxAction Throttle time for live reports has been set
 */
export const IPUSHPULL_SET_THROTTLE_TIME = 'IPUSHPULL_SET_THROTTLE_TIME';
/**
 * @ReduxAction A snapshot report has been sent
 */
export const IPUSHPULL_SEND_SNAPSHOT = 'IPUSHPULL_SEND_SNAPSHOT';
/**
 * @ReduxAction An ipushpull report has been selected
 */
export const IPUSHPULL_REPORT_SELECT = 'IPUSHPULL_REPORT_SELECT';
/**
 * @ReduxAction Live data has been sent to ipushpull
 */
export const IPUSHPULL_START_LIVE_DATA = 'IPUSHPULL_START_LIVE_DATA';
/**
 * @ReduxAction Live data to ipushpull has been stopped
 */
export const IPUSHPULL_STOP_LIVE_DATA = 'IPUSHPULL_STOP_LIVE_DATA';
/**
 * @ReduxAction A new ipushpull page has been added
 */
export const IPUSHPULL_ADD_PAGE = 'IPUSHPULL_ADD_PAGE';
/**
 * @ReduxAction Logged in to ipushpull
 */
export const IPUSHPULL_LOGIN = 'IPUSHPULL_LOGIN';
/**
 * @ReduxAction Logged out from ipushpull
 */
export const IPUSHPULL_LOGOUT = 'IPUSHPULL_LOGOUT';
/**
 * @ReduxAction Error message set from failed login
 */
export const IPUSHPULL_SET_LOGIN_ERROR_MESSAGE = 'IPUSHPULL_SET_LOGIN_ERROR_MESSAGE';
/**
 * @ReduxAction Login details to ipushpull have been set
 */
export const IPUSHPULL_SET_LOGIN_DETAILS = 'IPUSHPULL_SET_LOGIN_DETAILS';
/**
 * @ReduxAction ipushpull has been made available
 */
export const IPUSHPULL_SET_AVAILABLE_ON = 'IPUSHPULL_SET_AVAILABLE_ON';
/**
 * @ReduxAction ipushpull has been made unavailable
 */
export const IPUSHPULL_SET_AVAILABLE_OFF = 'SET_IPUSHPULL_AVAILABLE_OFF';
/**
 * @ReduxAction ipushpull is now running
 */
export const IPUSHPULL_SET_RUNNING_ON = 'IPUSHPULL_SET_RUNNING_ON';
/**
 * @ReduxAction ipushpull is no longer running
 */
export const IPUSHPULL_SET_RUNNING_OFF = 'IPUSHPULL_SET_RUNNING_OFF';
/**
 * @ReduxAction A live report to ipuspull has been set
 */
export const IPUSHPULL_LIVE_REPORT_SET = 'IPUSHPULL_LIVE_REPORT_SET';
/**
 * @ReduxAction The live report to ipuspull has been cleared
 */
export const IPUSHPULL_LIVE_REPORT_CLEAR = 'IPUSHPULL_LIVE_REPORT_CLEAR';
/**
 * @ReduxAction ipushpull domain pages have been set
 */
export const IPUSHPULL_DOMAIN_PAGES_SET = 'IPUSHPULL_DOMAIN_PAGES_SET';
/**
 * @ReduxAction ipushpull domain pages have been cleared
 */
export const IPUSHPULL_DOMAIN_PAGES_CLEAR = 'IPUSHPULL_DOMAIN_PAGES_CLEAR';
/**
 * @ReduxAction ipushpull set current(selected) report name
 */
export const IPUSHPULL_SET_CURRENT_REPORTNAME = 'IPUSHPULL_SET_CURRENT_REPORTNAME';
/**
 * @ReduxAction ipushpull set current(selected) folder
 */
export const IPUSHPULL_SET_CURRENT_FOLDER = 'IPUSHPULL_SET_CURRENT_FOLDER';
/**
 * @ReduxAction ipushpull set current(selected) page
 */
export const IPUSHPULL_SET_CURRENT_PAGE = 'IPUSHPULL_SET_CURRENT_PAGE';
/**
 * @ReduxAction ipushpull set available pages (depending on current folder)
 */
export const IPUSHPULL_SET_AVAILABLE_PAGES = 'IPUSHPULL_SET_AVAILABLE_PAGES';
/**
 * @ReduxAction Live data has been sent to OpenFin
 */
export const OPENFIN_START_LIVE_DATA = 'OPENFIN_START_LIVE_DATA';
/**
 * @ReduxAction Live data is not longer sent to OpenFin
 */
export const OPENFIN_STOP_LIVE_DATA = 'OPENFIN_STOP_LIVE_DATA';
/**
 * @ReduxAction An OpenFin live report has started
 */
export const OPENFIN_LIVE_REPORT_SET = 'OPENFIN_LIVE_REPORT_SET';
/**
 * @ReduxAction An OpenFin live report has stopped
 */
export const OPENFIN_LIVE_REPORT_CLEAR = 'OPENFIN_LIVE_REPORT_CLEAR';
/**
 * @ReduxAction OpenFin is now available
 */
export const OPENFIN_SET_AVAILABLE_ON = 'OPENFIN_SET_AVAILABLE_ON';
/**
 * @ReduxAction OpenFin is no longer available
 */
export const OPENFIN_SET_AVAILABLE_OFF = 'OPENFIN_SET_AVAILABLE_OFF';
/**
 * @ReduxAction OpenFin is now running
 */
export const OPENFIN_SET_RUNNING_ON = 'OPENFIN_SET_RUNNING_ON';
/**
 * @ReduxAction OpenFin is no longer running
 */
export const OPENFIN_SET_RUNNING_OFF = 'OPENFIN_SET_RUNNING_OFF';
/**
 * @ReduxAction set current(selected) report name
 */
export const OPENFIN_SET_CURRENT_REPORTNAME = 'OPENFIN_SET_CURRENT_REPORTNAME';
export const PluginsSetPluginState = (pluginId, pluginState) => ({
  type: PLUGINS_SET_PLUGIN_STATE,
  pluginId,
  pluginState
});
const initialState = {};
export const PluginsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PLUGINS_SET_PLUGIN_STATE:
      {
        return Object.assign(Object.assign({}, state), {
          [action.pluginId]: action.pluginState
        });
      }
    default:
      return state;
  }
};