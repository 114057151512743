import * as React from 'react';
import { CheckBox } from '../../../../components/CheckBox';
import DropdownButton from '../../../../components/DropdownButton';
import FormLayout, { FormRow } from '../../../../components/FormLayout';
import AdaptableInput from '../../../Components/AdaptableInput';
export const isSettingsValid = (chartDefinition, api) => {
  if (!chartDefinition.Name) {
    return 'Name is mandatory';
  }
  const allChartDefinitions = api.chartingApi.getChartDefinitions();
  if (allChartDefinitions.some(chartDefinitionLoopItem => chartDefinitionLoopItem.Uuid !== chartDefinition.Uuid && chartDefinitionLoopItem.Name === chartDefinition.Name)) {
    return 'There is already a chart with this name';
  }
  return true;
};
export const SettingsSection = props => {
  const handleNameChange = React.useCallback(event => {
    props.onChange(Object.assign(Object.assign({}, props.chartDefinition), {
      Name: event.target.value,
      Model: props.chartDefinition.Model
    }));
  }, [props.chartDefinition]);
  const handleUnLinkChange = React.useCallback(() => {
    props.onChange(Object.assign(Object.assign({}, props.chartDefinition), {
      Model: Object.assign(Object.assign({}, props.chartDefinition.Model), {
        unlinkChart: !props.chartDefinition.Model.unlinkChart
      })
    }));
  }, [props.chartDefinition]);
  const handleSuppressChartRanges = React.useCallback(() => {
    props.onChange(Object.assign(Object.assign({}, props.chartDefinition), {
      Model: Object.assign(Object.assign({}, props.chartDefinition.Model), {
        suppressChartRanges: !props.chartDefinition.Model.suppressChartRanges
      })
    }));
  }, [props.chartDefinition]);
  const aggFuncs = ['sum', 'min', 'max', 'count', 'avg', 'first', 'last'];
  const aggFuncsOptions = aggFuncs.map(aggFunc => ({
    label: aggFunc,
    onClick: () => {
      props.onChange(Object.assign(Object.assign({}, props.chartDefinition), {
        Model: Object.assign(Object.assign({}, props.chartDefinition.Model), {
          aggFunc
        })
      }));
    }
  }));
  const aggFunc = props.chartDefinition.Model.aggFunc;
  const handleAggFuncClear = React.useCallback(() => {
    props.onChange(Object.assign(Object.assign({}, props.chartDefinition), {
      Model: Object.assign(Object.assign({}, props.chartDefinition.Model), {
        aggFunc: ''
      })
    }));
  }, [props.chartDefinition]);
  return React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Name"
  }, React.createElement(AdaptableInput, {
    onChange: handleNameChange,
    value: props.chartDefinition.Name
  })), React.createElement(FormRow, {
    label: "Unlink Chart"
  }, React.createElement(CheckBox, {
    onClick: handleUnLinkChange,
    checked: props.chartDefinition.Model.unlinkChart
  })), React.createElement(FormRow, {
    label: "Suppress Chart Ranges"
  }, React.createElement(CheckBox, {
    onClick: handleSuppressChartRanges,
    checked: props.chartDefinition.Model.suppressChartRanges
  })), props.chartDefinition.Model.modelType === 'range' && typeof aggFunc !== 'function' && React.createElement(FormRow, {
    label: "Agg Func"
  }, React.createElement(DropdownButton, {
    columns: ['label'],
    items: aggFuncsOptions,
    onClear: handleAggFuncClear
  }, aggFunc ? aggFunc : 'Select ')));
};