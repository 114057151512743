import { ApiBase } from './ApiBase';
import * as ChartingRedux from './../../Redux/ActionsReducers/ChartingRedux';
import { ChartingGetChartModels } from './../../Redux/ActionsReducers/ChartingRedux';
import ObjectFactory from '../../Utilities/ObjectFactory';
import StringExtensions from '../../Utilities/Extensions/StringExtensions';
import { ChartingInternalApi } from '../Internal/ChartingInternalApi';
export class ChartingApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.internalApi = new ChartingInternalApi(adaptable);
  }
  isChartingEnabled() {
    return this.adaptable.canGenerateCharts();
  }
  getCurrentChartModels() {
    return this.adaptable.getChartModels();
  }
  getChartRef(chartId) {
    return this.adaptable.getChartRef(chartId);
  }
  saveCurrentCharts() {
    const currentCharts = this.getCurrentChartModels();
    const chartDefinitions = currentCharts.map(chart => ObjectFactory.CreateEmptyChartDefinition({
      Name: chart.chartId,
      Model: chart
    }));
    this.dispatchAction(ChartingRedux.ChartingSetChartModels(chartDefinitions));
  }
  getPersistedCharts() {
    return this.getChartDefinitions().map(chart => chart.Model);
  }
  getChartDefinitions() {
    var _a;
    return (_a = ChartingGetChartModels(this.getAdaptableState())) !== null && _a !== void 0 ? _a : [];
  }
  getChartDefinitionByUuid(uuid) {
    return this.getChartDefinitions().find(c => c.Uuid == uuid);
  }
  getChartDefinitionByName(name) {
    if (StringExtensions.IsNotNullOrEmpty(name)) {
      let chartDefinition = this.getChartDefinitions().find(c => c.Name == name);
      if (this.checkItemExists(chartDefinition, name, 'Chart')) {
        return chartDefinition;
      }
    }
  }
  showPersistedCharts() {
    return this.adaptable.showCharts(this.getChartDefinitions());
  }
  showChartDefinition(chartDefiniton, container) {
    let containerElement = container;
    if (typeof containerElement === 'string') {
      containerElement = this.internalApi.getContainerElemetnByName(containerElement);
    }
    return this.adaptable.showChart(chartDefiniton, containerElement);
  }
  showChartDefinitionOnce(chartDefinition, container) {
    var _a;
    const chartRef = this.getAdaptableApi().chartingApi.getChartRef((_a = chartDefinition === null || chartDefinition === void 0 ? void 0 : chartDefinition.Model) === null || _a === void 0 ? void 0 : _a.chartId);
    if (chartRef) {
      return;
    }
    return this.showChartDefinition(chartDefinition, container);
  }
  closeChartDefinition(chartDefinition) {
    const chartRef = this.getChartRef(chartDefinition.Model.chartId);
    if (chartRef) {
      chartRef.destroyChart();
    }
  }
  editChartDefinition(editedChartDefinition) {
    this.dispatchAction(ChartingRedux.ChartingEditChart(editedChartDefinition));
    this.updateAgChart(editedChartDefinition);
  }
  updateAgChart(chartDefinition) {
    this.adaptable.updateChart(chartDefinition);
  }
  addChartDefinition(chart) {
    this.dispatchAction(ChartingRedux.ChartingAddChart(chart));
  }
  getChartingOpenState() {
    return {
      charts: this.getChartDefinitions().map(chartDefinition => {
        var _a, _b;
        return {
          chartDefinition,
          isOpen: this.getChartRef((_a = chartDefinition === null || chartDefinition === void 0 ? void 0 : chartDefinition.Model) === null || _a === void 0 ? void 0 : _a.chartId) != null,
          containerName: (_b = this.getOpenChartContainer(chartDefinition)) === null || _b === void 0 ? void 0 : _b.name
        };
      })
    };
  }
  getOpenChartContainer(chartDefinition) {
    var _a, _b, _c;
    const containerOptions = (_b = (_a = this.getAdaptableApi().optionsApi.getAdaptableOptions()) === null || _a === void 0 ? void 0 : _a.chartingOptions) === null || _b === void 0 ? void 0 : _b.chartContainers;
    if (!containerOptions || !containerOptions.length) {
      return null;
    }
    return (_c = containerOptions.find(container => {
      const chartRef = this.getChartRef(chartDefinition.Model.chartId);
      const containerElement = this.internalApi.getContainerElement(container);
      if (!(chartRef === null || chartRef === void 0 ? void 0 : chartRef.chartElement) || !containerElement) {
        return false;
      }
      return containerElement.contains(chartRef.chartElement);
    })) !== null && _c !== void 0 ? _c : null;
  }
  setChartReadOnly(chartDefinition) {
    this.dispatchAction(ChartingRedux.ChartingEditChart(Object.assign(Object.assign({}, chartDefinition), {
      IsReadOnly: true
    })));
  }
  setChartEditable(chartDefinition) {
    this.dispatchAction(ChartingRedux.ChartingEditChart(Object.assign(Object.assign({}, chartDefinition), {
      IsReadOnly: false
    })));
  }
  addExternalChartDefinition(chartDefinition, options) {
    var _a, _b, _c;
    const saveChartBehaviour = (_a = options === null || options === void 0 ? void 0 : options.saveChartBehaviour) !== null && _a !== void 0 ? _a : 'manual';
    if (saveChartBehaviour === 'auto') {
      this.dispatchAction(ChartingRedux.ChartingAddExternalChart(Object.assign(Object.assign({}, chartDefinition), {
        Name: (_b = chartDefinition.Name) !== null && _b !== void 0 ? _b : this.internalApi.getUniqueChartName()
      })));
    } else {
      const alert = {
        alertType: 'generic',
        header: 'Save chart',
        message: 'Do you want to save this Chart?',
        alertDefinition: Object.assign(Object.assign({}, ObjectFactory.CreateEmptyAlertDefinition()), {
          MessageType: 'Info',
          AlertProperties: {
            DisplayNotification: true,
            NotificationDuration: 'always'
          },
          AlertForm: {
            Buttons: [{
              Label: 'Save',
              Action: context => {
                this.dispatchAction(ChartingRedux.ChartingAddExternalChart(Object.assign(Object.assign({}, chartDefinition), {
                  Name: context.formData.name || this.internalApi.getUniqueChartName()
                })));
              },
              ButtonStyle: {
                tone: 'info',
                variant: 'raised'
              }
            }, {
              Label: 'Dismiss',
              ButtonStyle: {
                tone: 'neutral',
                variant: 'raised'
              }
            }],
            fields: [{
              name: 'name',
              label: 'Name',
              fieldType: 'text',
              defaultValue: (_c = chartDefinition.Name) !== null && _c !== void 0 ? _c : ''
            }]
          }
        })
      };
      this.getAdaptableApi().alertApi.showAdaptableAlert(alert);
    }
  }
  editExternalChartDefinition(chartDefinition) {
    this.dispatchAction(ChartingRedux.ChartingEditExternalChart(chartDefinition));
  }
  deleteExternalChartDefinition(chartDefinition) {
    this.dispatchAction(ChartingRedux.ChartingDeleteExternalChart(chartDefinition));
  }
  getExternalChartDefinitions() {
    return this.getAdaptableState().Charting.ExternalChartDefinitions;
  }
  getExternalChartDefinitionByName(name) {
    if (StringExtensions.IsNotNullOrEmpty(name)) {
      let chartDefinition = this.getExternalChartDefinitions().find(c => c.Name == name);
      if (this.checkItemExists(chartDefinition, name, 'Chart')) {
        return chartDefinition;
      }
    }
  }
}