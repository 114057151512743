import * as React from 'react';
import { Tabs } from '../../../components/Tabs';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
import { CheckBox } from '../../../components/CheckBox';
import { CodeBlock } from '../../../components/CodeBlock';
import { Box, Flex, Text } from 'rebass';
import { ValueOptionsTags } from '../../Components/ValueSelector';
import { StyleComponent } from '../../Components/StyleComponent';
import ObjectFactory from '../../../Utilities/ObjectFactory';
import { useAdaptable } from '../../AdaptableContext';
import { StylePreview } from '../../../components/StylePreview';
import Panel from '../../../components/Panel';
import { Select } from '../../../components/Select';
const HighlightStyle = props => {
  const adaptable = useAdaptable();
  const options = [{
    label: 'Use Message Type',
    onClick: () => {
      props.onChange(true);
    }
  }, {
    label: 'Custom Style',
    onClick: () => {
      const initialStyle = ObjectFactory.CreateEmptyStyle();
      props.onChange(initialStyle);
    }
  }];
  const options2 = [{
    label: 'Use Message Type',
    value: 'system'
  }, {
    label: 'Custom Style',
    value: 'custom'
  }];
  return React.createElement(Box, {
    "data-name": props.dataName
  }, React.createElement(Flex, null, React.createElement(CheckBox, {
    mr: 2,
    style: {
      alignItems: 'flex-start'
    },
    checked: Boolean(props.highlight),
    onChange: checked => {
      props.onChange(checked);
    }
  }, props.label), Boolean(props.highlight) && React.createElement(Select, {
    options: options2,
    value: typeof props.highlight === 'boolean' ? 'system' : 'custom',
    onChange: value => {
      if (value === 'system') {
        props.onChange(true);
      } else {
        const initialStyle = ObjectFactory.CreateEmptyStyle();
        props.onChange(initialStyle);
      }
    }
  })), typeof props.highlight === 'object' && React.createElement(Panel, {
    margin: 2
  }, React.createElement(Box, {
    paddingLeft: 3
  }, React.createElement(StyleComponent, {
    headless: true,
    api: adaptable.api,
    Style: props.highlight,
    UpdateStyle: style => {
      props.onChange(style);
    }
  }))));
};
export const renderAlertBehaviourWizardSummary = alertDefinition => {
  const {
    api
  } = useOnePageAdaptableWizardContext();
  return renderAlertBehaviourSummary(alertDefinition, api);
};
export const renderAlertBehaviourSummary = (alert, api, allowWrap = false) => {
  const {
    AlertProperties = {}
  } = alert;
  const values = [AlertProperties.PreventEdit ? {
    label: 'Prevent Cell Edit',
    id: 'preventCellEdit'
  } : null, AlertProperties.HighlightCell ? {
    label: 'Highlight Cell',
    id: 'highlightCell'
  } : null, AlertProperties.HighlightRow ? {
    label: 'Highlight Row',
    id: 'highlightRow'
  } : null, AlertProperties.JumpToCell ? {
    label: 'Jump To Cell',
    id: 'jumptocell'
  } : null, AlertProperties.JumpToRow ? {
    label: 'Jump To Row',
    id: 'jumptorow'
  } : null, AlertProperties.ShowInDiv ? {
    label: React.createElement(React.Fragment, null, "Show in ", React.createElement(CodeBlock, {
      ml: 1,
      p: 0
    }, `<div />`)),
    id: 'showInDiv'
  } : null, AlertProperties.LogToConsole ? {
    label: 'Log To Console',
    id: 'logtoconsole'
  } : null].filter(Boolean);
  if (!values.length) {
    values.push({
      label: 'No ' + api.internalApi.getCorrectEnglishVariant('Behaviour') + ' Selected',
      id: 'none'
    });
  }
  return React.createElement(ValueOptionsTags, {
    allowWrap: allowWrap,
    options: values,
    value: values.map(v => v.id),
    readOnly: true,
    toIdentifier: x => x.id,
    toLabel: x => {
      if (x.id === 'highlightCell' && typeof AlertProperties.HighlightCell === 'object') {
        return React.createElement(StylePreview, {
          padding: 0,
          mt: 0,
          styleObject: AlertProperties.HighlightCell
        }, "Highlight Cell");
      }
      if (x.id === 'highlightRow' && typeof AlertProperties.HighlightRow === 'object') {
        return React.createElement(StylePreview, {
          padding: 0,
          mt: 0,
          styleObject: AlertProperties.HighlightRow
        }, "Highlight Row");
      }
      return React.createElement(React.Fragment, null, x.label);
    }
  });
};
export const AlertBehaviourWizardSection = props => {
  const {
    data,
    api
  } = useOnePageAdaptableWizardContext();
  const {
    AlertProperties = {}
  } = data;
  const rowRemovedAlert = api.alertApi.internalApi.isAlertDefinitionForRemovedRowChangeEvent(data);
  // data change only
  const hasPreventCellEdit = props.alertType === 'Validation';
  // except row change
  const hasHighlightCell = props.alertType !== 'RowChange';
  // except row removed
  const hasHighlightRow = !rowRemovedAlert;
  // except row changed
  const hasJumpToCell = props.alertType !== 'RowChange';
  // for all
  const showInSeparateDiv = true;
  // for all
  const logToConsole = true;
  // except row removed
  const hasJumpToRow = !rowRemovedAlert;
  const onChange = AlertProperties => {
    props.onChange(Object.assign(Object.assign({}, data), {
      AlertProperties: Object.assign(Object.assign({}, data.AlertProperties), AlertProperties)
    }));
  };
  return React.createElement(Tabs, null, React.createElement(Tabs.Tab, null, api.internalApi.getCorrectEnglishVariant('Behaviour')), React.createElement(Tabs.Content, null, hasPreventCellEdit && React.createElement(CheckBox, {
    "data-name": "prevent-cell-edit",
    style: {
      alignItems: 'flex-start'
    },
    checked: AlertProperties.PreventEdit,
    disabled: true
  }, "Prevent Cell Edit", React.createElement(Text, {
    fontSize: 2,
    mt: 1
  }, "(automatically undo data change which triggered Alert)")), hasHighlightCell && React.createElement(HighlightStyle, {
    dataName: "highlight-cell",
    highlight: AlertProperties.HighlightCell,
    label: "Highlight Cell",
    onChange: HighlightCell => {
      onChange({
        HighlightCell
      });
    }
  }), hasHighlightRow && React.createElement(HighlightStyle, {
    dataName: "highlight-row",
    highlight: AlertProperties.HighlightRow,
    label: "Highlight Row",
    onChange: HighlightRow => {
      onChange({
        HighlightRow
      });
    }
  }), hasJumpToCell && React.createElement(CheckBox, {
    "data-name": "jump-to-cell",
    checked: AlertProperties.JumpToCell,
    onChange: JumpToCell => {
      onChange({
        JumpToCell
      });
    }
  }, "Jump To Cell"), hasJumpToRow && React.createElement(CheckBox, {
    "data-name": "jump-to-row",
    checked: AlertProperties.JumpToRow,
    onChange: JumpToRow => {
      onChange({
        JumpToRow
      });
    }
  }, "Jump To Row"), showInSeparateDiv && React.createElement(CheckBox, {
    "data-name": "show-in-div",
    checked: AlertProperties.ShowInDiv,
    onChange: ShowInDiv => {
      onChange({
        ShowInDiv
      });
    }
  }, "Show in separate ", React.createElement(CodeBlock, null, `<div />`), " element"), logToConsole && React.createElement(CheckBox, {
    "data-name": "log-to-console",
    checked: AlertProperties.LogToConsole,
    onChange: LogToConsole => {
      onChange({
        LogToConsole
      });
    }
  }, "Log To Console")));
};