import * as React from 'react';
import { Flex, Text } from 'rebass';
import Panel from '../../../components/Panel';
// We cannot destructure this.props using the react way in typescript which is a real pain as you
// need to transfer props individually as a consequence
// let { buttonContent, ...other } = this.props
export class PanelWithRow extends React.Component {
  render() {
    const headerItems = this.props.colItems.map((colItem, index) => React.createElement(Text, {
      key: colItem.key || colItem.Content || index,
      fontWeight: "bold",
      fontSize: 'var(--ab-font-size-3)',
      paddingLeft: 1,
      paddingRight: 1,
      style: {
        flex: colItem.Size
      }
    }, colItem.Content));
    const header = React.createElement(Flex, {
      alignItems: this.props.headerAlign || 'center',
      style: {
        width: '100%'
      }
    }, headerItems);
    return React.createElement("div", {
      style: this.props.style,
      className: this.props.className
    }, React.createElement(Panel, {
      bodyScroll: true,
      header: header,
      border: this.props.border,
      bodyProps: this.props.bodyProps
    }, this.props.children));
  }
}