import Helper from '../../Utilities/Helpers/Helper';
import { createUuid } from '../../PredefinedConfig/Uuid';
// Base class for the Api - provides checking dispatching methods
export class ApiBase {
  /**
   * Constructor for all the api classes which simply takes and assigns an instance of Adaptable
   * @param adaptable the core IAdaptable object
   */
  constructor(adaptable) {
    this.adaptable = adaptable;
  }
  logInfo(message, ...optionalParams) {
    this.adaptable.logger.info(message, ...optionalParams);
  }
  logSuccess(message, ...optionalParams) {
    this.adaptable.logger.success(message, ...optionalParams);
  }
  logWarn(message, ...optionalParams) {
    this.adaptable.logger.warn(message, ...optionalParams);
  }
  logError(message, ...optionalParams) {
    this.adaptable.logger.error(message, ...optionalParams);
  }
  getAdaptableVariant() {
    return this.adaptable.variant;
  }
  /**
   * Base api helper method which ensure that Adaptable Object being used in the function is not null or undefined
   *
   * If it does not exist then we log an error and the api method should stop
   * @param item AdaptableObject being checked
   * @param name the name of the object if it has one (e.g. if its a search)
   * @param type the actual type of the object being checked
   */
  checkItemExists(item, name, type) {
    if (Helper.objectNotExists(item)) {
      this.logError(`No ${type} found with the name: ${name}`);
      return false;
    }
    return true;
  }
  checkArrayExists(array) {
    if (!Array.isArray(array)) {
      this.logError(`Item passed to Api function was not an array`);
      return false;
    }
    return true;
  }
  handleLayoutAssociatedObjects(objectList = [], module, config) {
    if (config === null || config === void 0 ? void 0 : config.includeLayoutNotAssociatedObjects) {
      return objectList;
    }
    const associatedLayout = (config === null || config === void 0 ? void 0 : config.associatedWithLayout) ? this.adaptable.api.layoutApi.getLayoutByName(config.associatedWithLayout) : this.getAdaptableApi().layoutApi.getCurrentLayout();
    return objectList.filter(objectItem => this.adaptable.api.layoutApi.internalApi.isObjectAvailableInLayout(objectItem, module, associatedLayout));
  }
  /**
   * Base api Helper method that dispatches a *Redux Action* to the Store
   * @param action the Redux Action to be dispatched
   */
  dispatchAction(action) {
    if (this.adaptable.isDestroyed) {
      return;
    }
    this.adaptable.adaptableStore.TheStore.dispatch(action);
  }
  /**
   * Returns the entire State from the Store
   *
   * This is a simple *getState()* call
   */
  getAdaptableState() {
    return this.adaptable.adaptableStore.TheStore.getState();
  }
  // Add the Uuid to the object if not present;
  // It's generally done in Redux also but no harm checking in Api also in case we missed it.
  addUidToAdaptableObject(adaptableObject) {
    if (!adaptableObject.Uuid) {
      adaptableObject.Uuid = createUuid();
    }
  }
  getModuleInfoByModuleId(module) {
    return this.adaptable.ModuleService.getModuleInfoByModule(module);
  }
  showModulePopup(module, moduleParams) {
    let moduleInfo = this.getModuleInfoByModuleId(module);
    if (moduleInfo) {
      this.adaptable.api.internalApi.showPopupScreen(moduleInfo.ModuleName, moduleInfo.Popup, moduleParams);
    }
  }
  getOptions() {
    return this.adaptable.adaptableOptions;
  }
  getActionColumnOptions() {
    return this.getOptions().actionColumnOptions;
  }
  getActionRowOptions() {
    return this.getOptions().actionRowOptions;
  }
  getColumnOptions() {
    return this.getOptions().columnOptions;
  }
  getCommentOptions() {
    return this.getOptions().commentOptions;
  }
  getNoteOptions() {
    return this.getOptions().noteOptions;
  }
  getCustomSortOptions() {
    return this.getOptions().customSortOptions;
  }
  getDataSetOptions() {
    return this.getOptions().dataSetOptions;
  }
  getCalendarOptions() {
    return this.getOptions().calendarOptions;
  }
  getCellSummaryOptions() {
    return this.getOptions().cellSummaryOptions;
  }
  getContainerOptions() {
    return this.getOptions().containerOptions;
  }
  getNotificationsOptions() {
    return this.getOptions().notificationsOptions;
  }
  getAlertOptions() {
    return this.getOptions().alertOptions;
  }
  getDashboardOptions() {
    return this.getOptions().dashboardOptions;
  }
  getEditOptions() {
    return this.getOptions().editOptions;
  }
  getExportOptions() {
    return this.getOptions().exportOptions;
  }
  getGroupingOptions() {
    return this.getOptions().groupingOptions;
  }
  getLayoutOptions() {
    return this.getOptions().layoutOptions;
  }
  getColumnMenuOptions() {
    return this.getOptions().columnMenuOptions;
  }
  getContextMenuOptions() {
    return this.getOptions().contextMenuOptions;
  }
  getColumnFilterOptions() {
    return this.getOptions().columnFilterOptions;
  }
  getExpressionOptions() {
    return this.getOptions().expressionOptions;
  }
  getPredicateOptions() {
    return this.getOptions().predicateOptions;
  }
  getQuickSearchOptions() {
    return this.getOptions().quickSearchOptions;
  }
  getFormatColumnOptions() {
    return this.getOptions().formatColumnOptions;
  }
  getStateOptions() {
    return this.getOptions().stateOptions;
  }
  getTeamSharingOptions() {
    return this.getOptions().teamSharingOptions;
  }
  getUserInterfaceOptions() {
    return this.getOptions().userInterfaceOptions;
  }
  getToolPanelOptions() {
    return this.getOptions().toolPanelOptions;
  }
  getChartingOptions() {
    return this.adaptable.adaptableOptions.chartingOptions;
  }
  getDataChangeHistoryOptions() {
    return this.adaptable.adaptableOptions.dataChangeHistoryOptions;
  }
  getEntitlementOptions() {
    return this.adaptable.adaptableOptions.entitlementOptions;
  }
  getFlashingCellOptions() {
    return this.adaptable.adaptableOptions.flashingCellOptions;
  }
  getSettingsPanelOptions() {
    return this.adaptable.adaptableOptions.settingsPanelOptions;
  }
  getFdc3Options() {
    return this.adaptable.adaptableOptions.fdc3Options;
  }
  getAdaptableApi() {
    return this.adaptable.api;
  }
  getActionColumnApi() {
    return this.getAdaptableApi().actionColumnApi;
  }
  getActionRowApi() {
    return this.getAdaptableApi().actionRowApi;
  }
  getApplicationApi() {
    return this.getAdaptableApi().applicationApi;
  }
  getAlertApi() {
    return this.getAdaptableApi().alertApi;
  }
  getFlashingCellApi() {
    return this.getAdaptableApi().flashingCellApi;
  }
  getPredicateApi() {
    return this.getAdaptableApi().predicateApi;
  }
  getBulkUpdateApi() {
    return this.getAdaptableApi().bulkUpdateApi;
  }
  getCalendarApi() {
    return this.getAdaptableApi().calendarApi;
  }
  getCalculatedColumnApi() {
    return this.getAdaptableApi().calculatedColumnApi;
  }
  getCellSummaryApi() {
    return this.getAdaptableApi().cellSummaryApi;
  }
  getColumnApi() {
    return this.getAdaptableApi().columnApi;
  }
  getConfigApi() {
    return this.getAdaptableApi().configApi;
  }
  getCustomSortApi() {
    return this.getAdaptableApi().customSortApi;
  }
  getDashboardApi() {
    return this.getAdaptableApi().dashboardApi;
  }
  getDataSetApi() {
    return this.getAdaptableApi().dataSetApi;
  }
  getEntitlementApi() {
    return this.getAdaptableApi().entitlementApi;
  }
  getEventApi() {
    return this.getAdaptableApi().eventApi;
  }
  getExportApi() {
    return this.getAdaptableApi().exportApi;
  }
  getFormatColumnApi() {
    return this.getAdaptableApi().formatColumnApi;
  }
  getFreeTextColumnApi() {
    return this.getAdaptableApi().freeTextColumnApi;
  }
  getGridApi() {
    return this.getAdaptableApi().gridApi;
  }
  getLayoutApi() {
    return this.getAdaptableApi().layoutApi;
  }
  getGridFilterApi() {
    return this.getAdaptableApi().gridFilterApi;
  }
  getColumnFilterApi() {
    return this.getAdaptableApi().columnFilterApi;
  }
  getPlusMinusApi() {
    return this.getAdaptableApi().plusMinusApi;
  }
  getQuickSearchApi() {
    return this.getAdaptableApi().quickSearchApi;
  }
  getScheduleApi() {
    return this.getAdaptableApi().scheduleApi;
  }
  getShortcutApi() {
    return this.getAdaptableApi().shortcutApi;
  }
  getSmartEditApi() {
    return this.getAdaptableApi().smartEditApi;
  }
  getSystemStatusApi() {
    return this.getAdaptableApi().systemStatusApi;
  }
  getThemeApi() {
    return this.getAdaptableApi().themeApi;
  }
  getUserInterfaceApi() {
    return this.getAdaptableApi().userInterfaceApi;
  }
  getNamedQueryApi() {
    return this.getAdaptableApi().namedQueryApi;
  }
  getExpressionApi() {
    return this.getAdaptableApi().expressionApi;
  }
  getPluginsApi() {
    return this.getAdaptableApi().pluginsApi;
  }
  getSettingsPanelApi() {
    return this.getAdaptableApi().settingsPanelApi;
  }
  getStyledColumnApi() {
    return this.getAdaptableApi().styledColumnApi;
  }
  getToolPanelApi() {
    return this.getAdaptableApi().toolPanelApi;
  }
  getTeamSharingApi() {
    return this.getAdaptableApi().teamSharingApi;
  }
  getColumnScopeApi() {
    return this.getAdaptableApi().columnScopeApi;
  }
  getDataChangeHistoryApi() {
    return this.getAdaptableApi().dataChangeHistoryApi;
  }
  getChartingApi() {
    return this.getAdaptableApi().chartingApi;
  }
  getStatusBarApi() {
    return this.getAdaptableApi().statusBarApi;
  }
  getFdc3Api() {
    return this.getAdaptableApi().fdc3Api;
  }
  destroy() {
    delete this.adaptable;
  }
}