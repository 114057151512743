import { AdaptableModuleBase } from './AdaptableModuleBase';
import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
import * as FreeTextColumnRedux from '../Redux/ActionsReducers/FreeTextColumnRedux';
import { FreeTextColumnWizard } from '../View/FreeTextColumn/Wizard/FreeTextColumnWizard';
import { getObjectTagsViewItems } from './Utilities/getObjectTagsViewItems';
export class FreeTextColumnModule extends AdaptableModuleBase {
  constructor(api) {
    super(ModuleConstants.FreeTextColumnModuleId, ModuleConstants.FreeTextColumnFriendlyName, 'horizontal-lines', 'FreeTextColumnPopup', 'Dynamic column showing custom content (stored in state and not with grid data)', api);
  }
  isModuleAvailable() {
    // FreeTextColumn module doesn't support autogenerated primary keys
    return super.isModuleAvailable() && !this.api.optionsApi.getAutogeneratePrimaryKey();
  }
  getModuleAdaptableObjects() {
    return this.api.freeTextColumnApi.getFreeTextColumns();
  }
  hasNamedQueryReferences() {
    return false;
  }
  createColumnMenuItems(column) {
    if (column && this.isModuleEditable()) {
      if (this.api.freeTextColumnApi.getFreeTextColumns().find(ftc => ftc.ColumnId == column.columnId)) {
        let popupParam = {
          column: column,
          action: 'Edit',
          source: 'ColumnMenu'
        };
        return [this.createMenuItemShowPopup('free-text-column-edit', 'Edit Free Text Column', this.moduleInfo.Popup, this.moduleInfo.Glyph, popupParam)];
      }
    }
  }
  getTeamSharingAction() {
    return {
      ModuleEntities: this.api.freeTextColumnApi.getFreeTextColumns(),
      AddAction: FreeTextColumnRedux.FreeTextColumnAdd,
      EditAction: FreeTextColumnRedux.FreeTextColumnEdit
    };
  }
  toView(freeTextColumn) {
    var _a;
    return {
      abObject: freeTextColumn,
      items: [{
        name: 'Settings',
        label: 'Column Id',
        values: [freeTextColumn.ColumnId]
      }, freeTextColumn.FriendlyName && {
        name: 'Settings',
        label: 'Column Name',
        values: [freeTextColumn.FriendlyName]
      }, {
        name: 'Settings',
        label: 'Data Type',
        values: [(_a = freeTextColumn.FreeTextColumnSettings.DataType) !== null && _a !== void 0 ? _a : 'Not specified']
      }, Boolean(freeTextColumn.DefaultValue) && {
        name: 'Settings',
        label: 'Default Value',
        values: [freeTextColumn.DefaultValue]
      }, freeTextColumn.TextEditor && {
        name: 'Editor Type',
        label: 'Editor',
        values: [freeTextColumn.TextEditor]
      }, getObjectTagsViewItems(freeTextColumn, this.api)].filter(Boolean)
    };
  }
  toViewAll() {
    return this.getModuleAdaptableObjects().map(freeTextColumn => this.toView(freeTextColumn));
  }
  getViewProperties() {
    return {
      getDeleteAction: FreeTextColumnRedux.FreeTextColumnDelete,
      getEditWizard() {
        return FreeTextColumnWizard;
      }
    };
  }
}