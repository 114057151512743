import * as React from 'react';
import SimpleButton from '../../components/SimpleButton';
import { useAdaptable } from '../AdaptableContext';
export const LayoutCloneButton = ({
  data,
  accessLevel
}) => {
  const adaptable = useAdaptable();
  const isDisabled = accessLevel === 'ReadOnly';
  const handleClick = React.useCallback(() => {
    adaptable.api.layoutApi.showLayoutEditor(data.Name, 'Clone');
  }, []);
  return React.createElement(SimpleButton, {
    onClick: handleClick,
    disabled: isDisabled,
    variant: "text",
    icon: "clone"
  });
};