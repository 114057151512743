/**
 * @ReduxAction Bulk Update has been completed
 */
export const BULK_UPDATE_COMPLETE = 'BULK_UPDATE_COMPLETE';
/**
 * @ReduxAction Bulk Update is being applied
 */
export const BULK_UPDATE_APPLY = 'BULK_UPDATE_APPLY';
/**
 * @ReduxAction Bulk Update Module is ready
 */
export const BULK_UPDATE_READY = 'BULK_UPDATE_READY';
export const BulkUpdateComplete = bypassValidationWarnings => ({
  type: BULK_UPDATE_COMPLETE,
  bypassValidationWarnings: bypassValidationWarnings
});
export const BulkUpdateApply = cellUpdateRequests => ({
  type: BULK_UPDATE_APPLY,
  cellUpdateRequests
});
export const BulkUpdateReady = () => ({
  type: BULK_UPDATE_READY
});