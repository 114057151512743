import * as PopupRedux from '../../Redux/ActionsReducers/PopupRedux';
import { SystemProgressIndicatorHide, SystemProgressIndicatorShow } from '../../Redux/ActionsReducers/SystemRedux';
import { ArrayExtensions } from '../../Utilities/Extensions/ArrayExtensions';
import { CUSTOM_WINDOW_FACTORY_ID } from '../../View/Components/Popups/WindowPopups/WindowPopups';
import { UserInterfaceInternalApi } from '../Internal/UserInterfaceInternalApi';
import { ApiBase } from './ApiBase';
export class UserInterfaceApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.showProgressIndicatorTimeout = null;
    this.internalApi = new UserInterfaceInternalApi(adaptable);
  }
  getColorPalette() {
    let colorPalette = this.getUserInterfaceOptions().colorPalette;
    // first do the function then get hardcoded items
    if (colorPalette != null && typeof colorPalette === 'function') {
      const currentTheme = this.getAdaptableApi().themeApi.getCurrentTheme();
      const colours = colorPalette(currentTheme);
      return colours;
    } else {
      let arr = colorPalette;
      if (arr && ArrayExtensions.IsNotNullOrEmpty(arr)) {
        return arr;
      }
    }
    return [];
  }
  getStyleClassNames() {
    return this.getUserInterfaceOptions().styleClassNames;
  }
  getPermittedValuesItems() {
    var _a, _b;
    return (_b = (_a = this.getUserInterfaceOptions()) === null || _a === void 0 ? void 0 : _a.permittedValues) !== null && _b !== void 0 ? _b : [];
  }
  getPermittedValuesForScope(column, permittedValues) {
    let permittedValuesItem;
    /**
     * The previous implementation prioritized column-id, data-types and all
     */
    // columns id
    permittedValuesItem = permittedValues.find(permittedValue => {
      return 'ColumnIds' in permittedValue.scope && this.getAdaptableApi().columnScopeApi.isColumnInScope(column, permittedValue.scope);
    });
    if (permittedValuesItem) {
      return permittedValuesItem;
    }
    permittedValuesItem = permittedValues.find(permittedValue => {
      return 'DataTypes' in permittedValue.scope && this.getAdaptableApi().columnScopeApi.isColumnInScope(column, permittedValue.scope);
    });
    if (permittedValuesItem) {
      return permittedValuesItem;
    }
    permittedValuesItem = permittedValues.find(permittedValue => {
      return 'All' in permittedValue.scope && this.getAdaptableApi().columnScopeApi.isColumnInScope(column, permittedValue.scope);
    });
    if (permittedValuesItem) {
      return permittedValuesItem;
    }
    return undefined;
  }
  getPermittedValuesForColumn(column) {
    const allPermittedValues = this.getPermittedValuesItems();
    if (ArrayExtensions.IsNullOrEmpty(allPermittedValues)) {
      return undefined;
    }
    let permittedValuesColumn = this.getPermittedValuesForScope(column, allPermittedValues);
    if (!permittedValuesColumn) {
      return undefined;
    }
    let permittedValues = permittedValuesColumn.values;
    // first do the function then get hardcoded items
    if (permittedValues != null && typeof permittedValues === 'function') {
      if (column) {
        const permittedValuesParams = Object.assign(Object.assign({}, this.getAdaptableApi().internalApi.buildBaseContext()), {
          column: column
        });
        return permittedValues(permittedValuesParams);
      }
    } else {
      let arr = permittedValues;
      if (arr && ArrayExtensions.IsNotNullOrEmpty(arr)) {
        return arr;
      }
    }
    return undefined;
  }
  getAllFilterPermittedValuesItems() {
    var _a, _b;
    return (_b = (_a = this.getUserInterfaceOptions()) === null || _a === void 0 ? void 0 : _a.filterPermittedValues) !== null && _b !== void 0 ? _b : [];
  }
  getFilterPermittedValuesForColumn(column) {
    const allFilterPermittedValuesItems = this.getAllFilterPermittedValuesItems();
    if (ArrayExtensions.IsNullOrEmpty(allFilterPermittedValuesItems)) {
      return undefined;
    }
    return this.getPermittedValuesForScope(column, allFilterPermittedValuesItems);
  }
  getAllCustomSortPermittedValuesItems() {
    var _a, _b;
    return (_b = (_a = this.getUserInterfaceOptions()) === null || _a === void 0 ? void 0 : _a.customSortPermittedValues) !== null && _b !== void 0 ? _b : [];
  }
  getCustomSortPermittedValuesForColumn(column) {
    const allFilterPermittedValuesItems = this.getAllCustomSortPermittedValuesItems();
    if (ArrayExtensions.IsNullOrEmpty(allFilterPermittedValuesItems)) {
      return undefined;
    }
    return this.getPermittedValuesForScope(column, allFilterPermittedValuesItems);
  }
  getAllBulkUpdatePermittedValuesItems() {
    var _a, _b;
    return (_b = (_a = this.getUserInterfaceOptions()) === null || _a === void 0 ? void 0 : _a.bulkUpdatePermittedValues) !== null && _b !== void 0 ? _b : [];
  }
  getBulkUpdatePermittedValuesForColumn(column) {
    const allFilterPermittedValuesItems = this.getAllBulkUpdatePermittedValuesItems();
    if (ArrayExtensions.IsNullOrEmpty(allFilterPermittedValuesItems)) {
      return undefined;
    }
    return this.getPermittedValuesForScope(column, allFilterPermittedValuesItems);
  }
  getEditLookUpItems() {
    return this.getUserInterfaceOptions().editLookUpItems || [];
  }
  getEditLookUpItemForColumn(column) {
    if (!column) {
      return undefined;
    }
    let editLookUpItems = this.getEditLookUpItems();
    if (!editLookUpItems) {
      return undefined;
    }
    return this.getPermittedValuesForScope(column, editLookUpItems);
  }
  getEditLookUpValuesForEditLookUpItem(editLookUpItem, gridCell) {
    if (!editLookUpItem || !gridCell || !gridCell.column) {
      return undefined;
    }
    return this.getAdaptableApi().gridApi.internalApi.getEditLookUpValuesForColumn(editLookUpItem, gridCell.column, gridCell);
  }
  getEditableCellStyle() {
    return this.getUserInterfaceOptions().editableCellStyle;
  }
  getReadOnlyCellStyle() {
    return this.getUserInterfaceOptions().readOnlyCellStyle;
  }
  getAdaptableObjectTags() {
    const objectTags = this.getUserInterfaceOptions().objectTags;
    if (objectTags != null && typeof objectTags === 'function') {
      return objectTags(this.getAdaptableApi().internalApi.buildBaseContext());
    } else {
      let arr = objectTags;
      if (arr && ArrayExtensions.IsNotNullOrEmpty(arr)) {
        return arr;
      }
    }
  }
  getCustomIconDefinition(iconName) {
    var _a, _b;
    let customIcon = undefined;
    const customIcons = (_b = (_a = this.getAdaptableApi().optionsApi.getAdaptableOptions().userInterfaceOptions) === null || _a === void 0 ? void 0 : _a.customIcons) !== null && _b !== void 0 ? _b : [];
    customIcon = customIcons.find(icon => icon.name === iconName);
    if (customIcon) {
      return customIcon.icon;
    }
    return customIcon;
  }
  showProgressIndicator(config) {
    if (config.delay) {
      this.showProgressIndicatorTimeout = setTimeout(() => {
        this.dispatchAction(SystemProgressIndicatorShow(config));
      }, config.delay);
    } else {
      this.dispatchAction(SystemProgressIndicatorShow(config));
    }
  }
  hideProgressIndicator() {
    if (this.showProgressIndicatorTimeout) {
      clearTimeout(this.showProgressIndicatorTimeout);
      this.showProgressIndicatorTimeout = null;
    }
    this.dispatchAction(SystemProgressIndicatorHide());
  }
  openCustomWindowPopup(config) {
    const close = () => {
      this.dispatchAction(PopupRedux.PopupHideWindow(config.id));
    };
    const action = PopupRedux.PopupShowWindow({
      Id: config.id,
      Title: config.title,
      FactoryId: CUSTOM_WINDOW_FACTORY_ID,
      PopupProps: {
        render: config.render,
        frameworkComponent: config.frameworkComponent,
        onFrameworkComponentDestroyed: config.onFrameworkComponentDestroyed
      },
      Icon: config.icon
    });
    this.dispatchAction(action);
    return {
      close
    };
  }
  closeCustomWindowPopup(windowId) {
    this.dispatchAction(PopupRedux.PopupHideWindow(windowId));
  }
}