import * as React from 'react';
import { Box, Flex } from 'rebass';
import StringExtensions from '../../Utilities/Extensions/StringExtensions';
import DropdownButton from '../../components/DropdownButton';
import FieldWrap from '../../components/FieldWrap';
import Textarea from '../../components/Textarea';
import { Icon } from '../../components/icons';
import { ButtonClear } from '../Components/Buttons/ButtonClear';
import { ButtonExpand } from '../Components/Buttons/ButtonExpand';
import { ButtonInvalid } from '../Components/Buttons/ButtonInvalid';
import { ButtonPause } from '../Components/Buttons/ButtonPause';
import { ButtonPlay } from '../Components/Buttons/ButtonPlay';
import { ButtonSave } from '../Components/Buttons/ButtonSave';
import { ButtonUnsuspend } from '../Components/Buttons/ButtonUnsuspend';
import { NamedQuerySelector } from './NamedQuerySelector';
import { useGridFilterExpressionEditor } from './useGridFilterExpressionEditor';
const QueryViewPanelComponent = props => {
  const {
    cachedQueries,
    expression,
    setExpression,
    isExpressionNamedQuery,
    isExpressionValid,
    isSuspended,
    gridFilter,
    isAdaptableReady,
    namedQueries,
    availableColumns,
    runQuery,
    onExpand,
    clearQuery,
    namedQueryModuleAccessLevel,
    saveQuery,
    suspendGridFilter,
    unSuspendGridFilter,
    setGridFilterExpression,
    gridFilterAccessLevel,
    isReadOnly
  } = useGridFilterExpressionEditor();
  if (!isAdaptableReady) {
    return null;
  }
  const elementType = props.viewType === 'Toolbar' ? 'DashboardToolbar' : 'ToolPanel';
  const disabled = isReadOnly || isSuspended || gridFilterAccessLevel === 'ReadOnly';
  const handleEnter = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      runQuery();
    }
  };
  const buttonExpand = React.createElement(ButtonExpand, {
    disabled: disabled,
    accessLevel: gridFilterAccessLevel,
    variant: "text",
    tone: "neutral",
    onClick: onExpand,
    tooltip: "Edit the Expression in UI",
    marginLeft: 1
  });
  const renderTextInput = () => {
    return props.viewType === 'Toolbar' ? React.createElement(FieldWrap, {
      marginRight: 1,
      width: 500,
      style: {
        overflow: 'visible'
      }
    }, buttonExpand, React.createElement(Box, {
      style: {
        position: 'relative',
        height: '100%'
      },
      flex: 1
    }, React.createElement(Textarea, {
      "data-name": "grid-filter-input",
      autoResizeOnFocus: true,
      style: {
        left: 0,
        top: 0,
        right: 0,
        border: 0,
        position: 'absolute',
        resize: 'none',
        fontFamily: 'monospace',
        fontSize: 12,
        maxHeight: 52,
        height: '100%',
        paddingTop: 6,
        overflow: 'hidden',
        zIndex: 100
      },
      placeholder: "Grid Filter",
      onChange: x => setExpression(x.target.value),
      value: expression
    })), isExpressionValid && React.createElement(ButtonPlay, {
      onClick: () => runQuery(),
      tooltip: '',
      accessLevel: gridFilterAccessLevel,
      variant: "text",
      tone: "neutral",
      disabled: disabled || expression == '' || expression == (gridFilter === null || gridFilter === void 0 ? void 0 : gridFilter.Expression),
      marginRight: 1
    }), gridFilter && !isExpressionValid && React.createElement(ButtonInvalid, {
      variant: "text",
      tone: "neutral",
      tooltip: "Invalid Grid Filter",
      marginRight: 1
    }), ' ', StringExtensions.IsNotNullOrEmpty(expression) && React.createElement(ButtonClear, {
      onClick: () => clearQuery(),
      tooltip: "Clear Grid Filter",
      accessLevel: gridFilterAccessLevel
    })) : React.createElement(FieldWrap, {
      width: "100%",
      overflow: "visible"
    }, ' ', buttonExpand, React.createElement(Box, {
      style: {
        position: 'relative',
        minHeight: 40,
        width: '100%'
      },
      flex: 1
    }, React.createElement(Textarea, {
      "data-name": "grid-filter-input",
      autoResizeOnFocus: true,
      style: {
        left: 0,
        top: 0,
        right: 0,
        border: 0,
        position: 'absolute',
        resize: 'none',
        fontFamily: 'monospace',
        fontSize: 12,
        maxHeight: 60,
        paddingTop: 7,
        zIndex: 100,
        height: '100%',
        overflow: 'hidden'
      },
      placeholder: "Grid Filter",
      onChange: x => setExpression(x.target.value),
      value: expression
    })));
  };
  const saveButton = React.createElement(ButtonSave, {
    onClick: () => saveQuery(),
    tooltip: "Save as Named Query",
    accessLevel: namedQueryModuleAccessLevel,
    disabled: disabled || !isExpressionValid || isExpressionNamedQuery || expression == '',
    variant: "text",
    tone: "neutral",
    marginRight: 1
  });
  const suspendButton = React.createElement(ButtonPause, {
    onClick: () => suspendGridFilter(),
    tooltip: "Suspend Grid Filter",
    accessLevel: gridFilterAccessLevel,
    disabled: disabled || !isExpressionValid,
    variant: "text",
    tone: "neutral",
    marginRight: 1
  });
  const unSuspendButton = React.createElement(ButtonUnsuspend, {
    onClick: () => unSuspendGridFilter(),
    tooltip: "Unsuspend Grid Filter",
    accessLevel: gridFilterAccessLevel,
    disabled: !isExpressionValid,
    variant: "text",
    tone: "neutral",
    marginRight: 1
  });
  const namedQuerySelector = React.createElement(NamedQuerySelector, {
    namedQueries: namedQueries,
    cachedQueries: cachedQueries,
    currentQuery: gridFilter === null || gridFilter === void 0 ? void 0 : gridFilter.Expression,
    onSelect: query => runQuery(query),
    setGridFilterExpression: query => setGridFilterExpression(query)
  });
  const columnsDropdown = React.createElement(DropdownButton, {
    disabled: disabled,
    accessLevel: gridFilterAccessLevel,
    variant: "text",
    items: availableColumns,
    marginRight: 1,
    tooltip: "Pick Columns"
  }, React.createElement(Icon, {
    name: 'list'
  }));
  const renderButtons = () => {
    return props.viewType === 'Toolbar' ? React.createElement(React.Fragment, null, saveButton, isSuspended ? unSuspendButton : suspendButton, columnsDropdown, namedQuerySelector, ' ') : React.createElement(React.Fragment, null, React.createElement(ButtonPlay, {
      onClick: () => runQuery(),
      tooltip: "Run Grid Filter",
      accessLevel: gridFilterAccessLevel,
      variant: "text",
      tone: "neutral",
      disabled: disabled || !isExpressionValid || expression == '' || expression == gridFilter,
      marginRight: 1
    }), ' ', React.createElement(ButtonClear, {
      onClick: () => clearQuery(),
      tooltip: "Clear Grid Filter",
      accessLevel: gridFilterAccessLevel,
      disabled: expression == ''
    }), ' ', saveButton, isSuspended ? unSuspendButton : suspendButton, namedQuerySelector);
  };
  return React.createElement(Flex, {
    onKeyDown: handleEnter,
    flexDirection: "row",
    className: `ab-${elementType}__Query__wrap`,
    flexWrap: props.viewType === 'ToolPanel' ? 'wrap' : 'nowrap'
  }, React.createElement(Flex, {
    width: props.viewType === 'ToolPanel' ? '100%' : 'auto'
  }, renderTextInput()), React.createElement(Flex, null, renderButtons()));
};
export const GridFilterPanelControl = QueryViewPanelComponent;