import { AdaptableModuleBase } from './AdaptableModuleBase';
import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
import { getObjectTagsViewItems } from './Utilities/getObjectTagsViewItems';
import { DataSetStatusPanelPopover } from '../View/DataSet/DataSetStatusPanelPopover';
import { createUuid } from '../PredefinedConfig/Uuid';
import { DataSetSelector } from '../View/DataSet/DataSetSelector';
export class DataSetModule extends AdaptableModuleBase {
  constructor(api) {
    super(ModuleConstants.DataSetModuleId, ModuleConstants.DataSetFriendlyName, 'data-set', 'DataSetPopup', 'Update the entire data source in AdapTable using pre-populated data sets', api);
  }
  onAdaptableReady() {
    this.api.dataSetApi.getDataSets().forEach(ds => {
      if (!ds.Uuid) {
        ds.Uuid = createUuid();
      }
    });
  }
  getModuleAdaptableObjects() {
    var _a, _b;
    return (_b = (_a = this.api.optionsApi.getDataSetOptions()) === null || _a === void 0 ? void 0 : _a.dataSets) !== null && _b !== void 0 ? _b : [];
  }
  hasNamedQueryReferences() {
    return false;
  }
  toView(dataSet) {
    var _a;
    return {
      items: [{
        name: 'Settings',
        label: 'Name',
        values: [dataSet.name]
      }, {
        name: 'Settings',
        label: 'Description',
        values: [(_a = dataSet.description) !== null && _a !== void 0 ? _a : 'Not Specified']
      }, getObjectTagsViewItems(dataSet, this.api)],
      abObject: dataSet
    };
  }
  toViewAll() {
    return this.getModuleAdaptableObjects().map(dataSet => this.toView(dataSet));
  }
  getViewProperties() {
    return {
      actions: [DataSetSelector],
      emptyView: 'No DataSets are provided',
      getStatusBarPanelProps: () => {
        var _a, _b;
        const content = (_b = (_a = this.api.dataSetApi.getCurrentDataSet()) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : this.moduleInfo.FriendlyName;
        return {
          content: content,
          popover: DataSetStatusPanelPopover
        };
      }
    };
  }
}