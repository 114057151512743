import * as React from 'react';
import { useState } from 'react';
import StringExtensions from '../../../Utilities/Extensions/StringExtensions';
import { OnePageWizard } from '../../Wizard/OnePageWizards';
import { ConfigurationWizardColumnsStep } from './ConfigurationWizardColumnsStep';
import GridOptionsForm from './GridOptionsForm';
import EntitlementsForm from './EntitlementsForm';
import { UIElementsForm } from './UIOptions/UiOptionsForm';
import { AdaptableOptionsForm } from './AdaptableOptionsForm';
import { applyDefaultAdaptableOptions } from '../../../agGrid/defaultAdaptableOptions';
export const ConfigurationWizard = props => {
  const [isPrimaryKeySelected, setIsValid] = React.useState(true);
  const [adaptableConfig, setAdaptableConfig] = useState(() => {
    const gridOptionDefaults = {
      sideBar: {
        toolPanels: ['columns', 'filters', 'adaptable']
      }
    };
    const gridOptions = Object.assign(Object.assign({}, gridOptionDefaults), props.adaptableConfig.gridOptions);
    const adaptableOptions = applyDefaultAdaptableOptions(props.adaptableConfig.adaptableOptions);
    return {
      gridOptions,
      adaptableOptions
    };
  });
  const [selectedColumns, setSelectedColumns] = useState(() => {
    var _a, _b;
    return (_b = (_a = props.adaptableConfig.gridOptions) === null || _a === void 0 ? void 0 : _a.columnDefs) === null || _b === void 0 ? void 0 : _b.reduce((acc, col) => {
      acc[col.field] = true;
      return acc;
    }, {});
  });
  const columnsHandle = React.useRef();
  const handleFinish = () => {
    const newAdaptableOptions = Object.assign({}, adaptableConfig.adaptableOptions);
    const newGridOptions = Object.assign({}, adaptableConfig.gridOptions);
    if (columnsHandle.current) {
      adaptableConfig.gridOptions.columnDefs = columnsHandle.current.getColumns().map(column => {
        const newColumn = Object.assign(Object.assign({}, column), {
          headerName: column.caption || StringExtensions.Humanize(column.field)
        });
        delete newColumn.caption;
        return newColumn;
      });
      newAdaptableOptions.primaryKey = columnsHandle.current.getPrimaryKey();
    }
    const newAdaptableConfig = {
      adaptableOptions: newAdaptableOptions,
      gridOptions: newGridOptions
    };
    setAdaptableConfig(newAdaptableConfig);
    props.onFinish(newAdaptableConfig);
  };
  const handlePrimaryKeyColumnIsNotSelected = React.useCallback(newIsValid => {
    setIsValid(newIsValid);
  }, []);
  const handleColumnsChange = React.useCallback(columns => {
    setAdaptableConfig(adaptableConfig => Object.assign(Object.assign({}, adaptableConfig), {
      gridOptions: Object.assign(Object.assign({}, adaptableConfig.gridOptions), {
        columnDefs: columns
      })
    }));
  }, []);
  const handleColumnSelectionChange = React.useCallback((col, flag) => {
    setSelectedColumns(selectedColumns => {
      const newSelectedColumns = selectedColumns !== null && selectedColumns !== void 0 ? selectedColumns : {};
      newSelectedColumns[col.field] = flag;
      return newSelectedColumns;
    });
  }, []);
  const handleAdaptableOptionsChange = React.useCallback(adaptableOptions => {
    setAdaptableConfig(adaptableConfig => Object.assign(Object.assign({}, adaptableConfig), {
      adaptableOptions: adaptableOptions
    }));
  }, []);
  const handleGridOptionsChange = React.useCallback(gridOptions => {
    setAdaptableConfig(adaptableConfig => Object.assign(Object.assign({}, adaptableConfig), {
      gridOptions: gridOptions
    }));
  }, []);
  let sections = [{
    title: 'Columns',
    details: 'Configure Columns',
    isValid: () => {
      if (!isPrimaryKeySelected) {
        return 'Primary key column must be included';
      }
      return true;
    },
    render: () => React.createElement(ConfigurationWizardColumnsStep, {
      adaptableConfig: adaptableConfig,
      setPrimaryKeyIsNotSelected: handlePrimaryKeyColumnIsNotSelected,
      onChange: setAdaptableConfig,
      selectedColumns: selectedColumns,
      onSelectionChange: handleColumnSelectionChange,
      onColumnChange: handleColumnsChange,
      columnsHandle: columnsHandle
    })
  }, {
    title: 'AdapTable Options',
    details: 'Configure Adaptable Options',
    isValid: () => adaptableConfig.adaptableOptions.adaptableId ? true : 'Adaptable ID is required',
    render: () => React.createElement(AdaptableOptionsForm, {
      adaptableOptions: adaptableConfig.adaptableOptions,
      onChangedAdaptableOptions: handleAdaptableOptionsChange
    })
  }, {
    title: 'AG Grid Options',
    details: 'Configure AG Grid Options',
    render: () => React.createElement(GridOptionsForm, {
      gridOptions: adaptableConfig.gridOptions,
      onChangedGridOptions: handleGridOptionsChange
    })
  }, {
    title: 'UI Elements',
    details: 'Configure UI Elements',
    render: () => React.createElement(UIElementsForm, {
      adaptableOptions: adaptableConfig.adaptableOptions,
      gridOptions: adaptableConfig.gridOptions,
      onChangedAdaptableOptions: handleAdaptableOptionsChange,
      onChangedGridOptions: handleGridOptionsChange
    })
  }, {
    title: 'Entitlements',
    details: 'Configure Entitlements',
    render: () => React.createElement(EntitlementsForm, {
      adaptableOptions: adaptableConfig.adaptableOptions,
      onChangedAptableOptions: handleAdaptableOptionsChange
    })
  }
  /**
  financePlugin && {
    title: 'Finance',
    details: 'Finance Options',
    render: () => (
      <FinanceForm
        adaptableConfig={adaptableConfig}
        onChangedAdaptableConfig={setAdaptableConfig}
      />
    ),
  },  */].filter(Boolean);
  if (props.startSections) {
    sections = [...props.startSections.map(section => {
      return {
        title: section.title,
        isValid: section.isValid,
        isVisible: section.isVisible,
        render: () => {
          return section.render(adaptableConfig, setAdaptableConfig, setSelectedColumns);
        }
      };
    }), ...sections];
  }
  return React.createElement(OnePageWizard, {
    data: adaptableConfig,
    name: "Configure",
    defaultCurrentSectionName: 'default section name',
    onHide: () => null,
    onFinish: handleFinish,
    sections: sections
  });
};