import { useEffect } from 'react';
import usePrevious from './usePrevious';
const useAutoFocus = (props, focusElementRef) => {
  const autoFocus = props.autoFocus === undefined ? true : props.autoFocus;
  const prevAutoFocus = usePrevious(props.previous ? props.previous({
    autoFocus
  }) : autoFocus, undefined);
  useEffect(() => {
    if (focusElementRef.current && focusElementRef.current.focus && autoFocus && (props.shouldFocus ? props.shouldFocus({
      autoFocus
    }) : true) && (prevAutoFocus === undefined || prevAutoFocus !== autoFocus)) {
      focusElementRef.current.focus();
    }
  }, [autoFocus, prevAutoFocus, props.isOpen]);
};
export default useAutoFocus;