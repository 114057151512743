import { __rest } from "tslib";
import * as React from 'react';
import { useAdaptable } from '../AdaptableContext';
export const ExternalRenderer = _a => {
  var {
      render,
      frameworkComponent,
      componentName = 'N/A',
      onDestroy
    } = _a,
    rest = __rest(_a, ["render", "frameworkComponent", "componentName", "onDestroy"]);
  const ref = React.useRef();
  const adaptable = useAdaptable();
  const [contentsHTML, setContentsHTML] = React.useState('');
  React.useLayoutEffect(() => {
    const element = ref.current;
    const adaptableApi = adaptable.api;
    if (render) {
      const html = render({
        visible: true,
        phase: 'onMount',
        element,
        adaptableApi,
        userName: adaptableApi.optionsApi.getUserName(),
        adaptableId: adaptableApi.optionsApi.getAdaptableId()
      });
      setContentsHTML(html);
    } else if (frameworkComponent) {
      adaptableApi.internalApi.createFrameworkComponent(element, frameworkComponent, 'toolbar');
    }
    return () => {
      if (render) {
        render({
          visible: false,
          phase: 'onDestroy',
          element: element,
          adaptableApi,
          userName: adaptableApi.optionsApi.getUserName(),
          adaptableId: adaptableApi.optionsApi.getAdaptableId()
        });
      } else if (frameworkComponent) {
        onDestroy === null || onDestroy === void 0 ? void 0 : onDestroy();
        adaptableApi.internalApi.destroyFrameworkComponent(element, frameworkComponent,
        // @ts-ignore
        componentName);
      }
    };
  }, []);
  const RFC = adaptable.variant === 'react' ? frameworkComponent : null;
  return RFC ? React.createElement("div", Object.assign({}, rest, {
    ref: ref
  }), React.createElement(RFC, {
    adaptableApi: adaptable.api
  })) : React.createElement("div", Object.assign({}, rest, {
    dangerouslySetInnerHTML: {
      __html: contentsHTML
    },
    ref: ref
  }));
};