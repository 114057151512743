import * as React from 'react';
import SimpleButton from '../../../components/SimpleButton';
import Panel from '../../../components/Panel';
import { Flex, Box } from 'rebass';
import { allIcons } from '../../../components/icons';
import { ButtonInfo } from '../Buttons/ButtonInfo';
//We cannot destructure this.props using the react way in typescript which is a real pain as you
//need to transfer props individually as a consequence
//let { buttonContent, ...other } = this.props
export class PanelWithButton extends React.Component {
  render() {
    let {
      buttonContent
    } = this.props;
    const IconCmp = allIcons[this.props.glyphicon];
    let header = React.createElement(Flex, {
      alignItems: "center",
      width: "100%"
    }, React.createElement(Flex, {
      alignItems: "center"
    }, this.props.glyphicon != null && (IconCmp ? React.createElement(IconCmp, null) : null), React.createElement(Box, {
      marginRight: 2,
      "data-name": "space-before"
    }), this.props.headerText, React.createElement(Box, {
      marginRight: 2,
      "data-name": "space-after"
    }), this.props.infoLink != null && !this.props.infoLinkDisabled && React.createElement(ButtonInfo, {
      onClick: () => window.open(this.props.infoLink, '_blank' // <- opens the link in a new tab or window.
      )
    })), React.createElement(Box, {
      style: {
        flex: 1
      }
    }), buttonContent ? React.createElement(SimpleButton, {
      variant: "raised",
      tone: "accent",
      disabled: this.props.buttonDisabled,
      onClick: () => this.props.buttonClick ? this.props.buttonClick() : null
    }, buttonContent) : null, this.props.button ? React.cloneElement(this.props.button) : null);
    return React.createElement(Panel, {
      flex: 1,
      className: this.props.className,
      bodyScroll: this.props.bodyScroll != null ? this.props.bodyScroll : true,
      bodyProps: this.props.bodyProps,
      variant: this.props.variant || 'primary',
      header: header,
      headerProps: this.props.headerProps,
      style: this.props.style,
      borderRadius: this.props.borderRadius || 'none',
      border: this.props.border || 'none'
    }, this.props.children);
  }
}