import * as React from 'react';
import { Flex } from 'rebass';
import { NewScopeComponent } from '../../Components/NewScopeComponent';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
export const AlertScopeWizardSection = props => {
  const {
    data,
    api
  } = useOnePageAdaptableWizardContext();
  let disableDataTypes = true;
  let disableColumns = true;
  if (props.alertType === 'DataChange' || props.alertType == 'Validation') {
    disableDataTypes = false;
    disableColumns = false;
  }
  return React.createElement(Flex, {
    flexDirection: "column",
    style: {
      height: '100%'
    },
    padding: 2
  }, React.createElement(NewScopeComponent, {
    disableColumns: disableColumns,
    disableDataTypes: disableDataTypes,
    descriptions: {
      rowScope: 'Changes in any Column in the row will trigger an Alert',
      columnScope: 'Changes in selected Columns will trigger an Alert',
      dataTypeScope: 'Changes in any Column which is of the selected Data Type(s) will trigger an Alert'
    },
    scope: data.Scope,
    updateScope: Scope => {
      const newData = Object.assign(Object.assign({}, data), {
        Scope
      });
      if (newData.Rule.Predicates) {
        // when scope is changed, reset the rule to predicate of any
        // if it was set to a predicate before
        newData.Rule = {
          Predicates: [{
            PredicateId: 'AnyChange'
          }]
        };
      }
      if (newData.Rule.ObservableExpression !== undefined && !api.columnScopeApi.scopeIsAll(Scope)) {
        // if it had observable expression and the scope is changed to partial
        // we need to reset to not be an observable expression, as observable is not supported
        // for partial scope
        delete newData.Rule.ObservableExpression;
        newData.Rule.BooleanExpression = '';
      }
      if (newData.Rule.AggregatedBooleanExpression !== undefined && !api.columnScopeApi.scopeIsAll(Scope)) {
        // if it had aggregation expression and the scope is changed to partial
        // we need to reset to not be an aggregation expression, as aggregation is not supported
        // for partial scope
        delete newData.Rule.AggregatedBooleanExpression;
        newData.Rule.BooleanExpression = '';
      }
      props.onChange(newData);
    }
  }));
};