import { ApiBase } from '../Implementation/ApiBase';
import { ADAPTABLE_ROW_ACTION_BUTTONS, AG_GRID_GROUPED_COLUMN } from '../../Utilities/Constants/GeneralConstants';
export function getAutoRowGroupColumnIdFor(columnId) {
  return `${AG_GRID_GROUPED_COLUMN}-${columnId}`;
}
export class ColumnInternalApi extends ApiBase {
  /**
   * Retrieves 'ColumnType' property for a given Column
   *   isCumulativeAggregate(input: string): boolean {
   *     return !!this.getNodesFromExpression(input, 'CUMUL')?.length;
   *   }
   * @param columnId Column to use
   */
  getAgGridColumnType(columnId) {
    return this.adaptable.getAgGridColumnType(columnId);
  }
  /**
   * Retrieves a list providing the Friendly Name / Caption for all columns
   */
  getAllColumnFriendlyNames() {
    return this.getColumnApi().getColumns().map(c => {
      return c.friendlyName;
    });
  }
  getAutoRowGroupColumnIdFor(columnId) {
    return getAutoRowGroupColumnIdFor(columnId);
  }
  /**
   * Retrieves AG Grid's field property for the column
   * @param columnId columnId to look up
   */
  getAgGridColumnFieldForAdaptableColumn(columnId) {
    const agGridColumn = this.getAgGridColumnForAdaptableColumn(columnId);
    return agGridColumn.getColDef().field || '';
  }
  /**
   * Retrieves AG Grid's column for an Adaptable column
   * @param columnId columnId to look up
   */
  getAgGridColumnForAdaptableColumn(columnId) {
    return this.adaptable.getAgGridColumnForColumnId(columnId);
  }
  isActionRowButtonColumn(columnId) {
    return columnId === ADAPTABLE_ROW_ACTION_BUTTONS;
  }
  getActiveColumnComparator(columnId, customSort, customSortComparer) {
    if ((!customSort || (customSort === null || customSort === void 0 ? void 0 : customSort.IsSuspended)) && !customSortComparer) {
      // defaults to AG-Grid column definition comparator if no CustomSort is defined&active
      const colDefComparator = this.adaptable.agGridColumnAdapter.getUserColDefProperty(columnId, 'comparator');
      return colDefComparator;
    }
    // CustomSort Comparer function takes precedence over CustomSort SortedValues
    const comparerFunction = customSortComparer ? customSortComparer.comparer : this.getCustomSortApi().internalApi.getDefaultCustomSortComparer(customSort.ColumnId, customSort.SortedValues);
    return comparerFunction;
  }
}