import * as React from 'react';
import DefaultIcon from './DefaultIcon';
export default (props => React.createElement(DefaultIcon, Object.assign({}, props), React.createElement("defs", null, React.createElement("path", {
  id: "a",
  d: "M0 0h24v24H0V0z"
})), React.createElement("clipPath", {
  id: "b"
}, React.createElement("use", {
  xlinkHref: "#a",
  overflow: "visible"
})), React.createElement("path", {
  d: "M4 9h16v2H4zm0 4h10v2H4z",
  clipPath: "url(#b)"
})));