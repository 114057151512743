import chunk from 'lodash/chunk';
import * as React from 'react';
import { Flex } from 'rebass';
import { CheckBox } from '../components/CheckBox';
import FormLayout, { FormRow } from '../components/FormLayout';
import { Tabs } from '../components/Tabs';
import { ColumnFilterModuleId } from '../Utilities/Constants/ModuleConstants';
import { useAdaptable } from './AdaptableContext';
export const SpecialColumnSettingsWizardStep = props => {
  var _a, _b, _c;
  const adaptable = useAdaptable();
  const possibleColumnTypes = (_a = adaptable.api.columnApi.getColumnTypes()) !== null && _a !== void 0 ? _a : [];
  const {
    Filterable,
    Resizable,
    Groupable,
    Sortable,
    Pivotable,
    Aggregatable,
    SuppressMenu,
    SuppressMovable
  } = (_b = props.settings) !== null && _b !== void 0 ? _b : {};
  const handleColumnTypeChange = (columnType, checked) => {
    var _a, _b, _c;
    const columnTypes = (_b = (_a = props.settings) === null || _a === void 0 ? void 0 : _a.ColumnTypes) !== null && _b !== void 0 ? _b : [];
    if (checked) {
      props.onChange(Object.assign(Object.assign({}, props.settings), {
        ColumnTypes: [...columnTypes, columnType]
      }));
    } else {
      props.onChange(Object.assign(Object.assign({}, props.settings), {
        ColumnTypes: (_c = columnTypes === null || columnTypes === void 0 ? void 0 : columnTypes.filter) === null || _c === void 0 ? void 0 : _c.call(columnTypes, item => item !== columnType)
      }));
    }
  };
  const onSettingsChange = values => {
    props.onChange(Object.assign(Object.assign({}, props.settings), values));
  };
  return React.createElement(React.Fragment, null, React.createElement(Tabs, {
    marginTop: 2,
    autoFocus: false
  }, React.createElement(Tabs.Tab, null, "Column Properties"), React.createElement(Tabs.Content, null, React.createElement(Flex, {
    flexDirection: "column"
  }, React.createElement(FormLayout, {
    style: {
      width: '100%'
    },
    columns: [{
      name: 'first',
      size: '30%'
    }, {
      size: '30%',
      name: 'second'
    }, {
      size: '30%',
      name: 'third'
    }]
  }, React.createElement(FormRow, null, React.createElement(CheckBox, {
    "data-name": "filterable",
    checked: Filterable,
    onChange: Filterable => onSettingsChange({
      Filterable
    }),
    disabled: !adaptable.api.optionsApi.getColumnFilterOptions().enableFilterOnSpecialColumns || adaptable.api.entitlementApi.getEntitlementAccessLevelForModule(ColumnFilterModuleId) == 'Hidden'
  }, "Filterable"), React.createElement(CheckBox, {
    "data-name": "resizable",
    checked: Resizable,
    onChange: Resizable => onSettingsChange({
      Resizable
    })
  }, "Resizable"), React.createElement(CheckBox, {
    "data-name": "groupable",
    checked: Groupable,
    onChange: Groupable => onSettingsChange({
      Groupable
    })
  }, "Groupable")), React.createElement(FormRow, null, React.createElement(CheckBox, {
    "data-name": "sortable",
    checked: Sortable,
    onChange: Sortable => onSettingsChange({
      Sortable
    })
  }, "Sortable"), React.createElement(CheckBox, {
    "data-name": "pivotable",
    checked: Pivotable,
    onChange: Pivotable => onSettingsChange({
      Pivotable
    })
  }, "Pivotable"), React.createElement(CheckBox, {
    "data-name": "aggregatable",
    checked: Aggregatable,
    onChange: Aggregatable => onSettingsChange({
      Aggregatable
    })
  }, "Aggregatable")), React.createElement(FormRow, null, React.createElement(CheckBox, {
    "data-name": "suppres-smenu",
    checked: SuppressMenu,
    onChange: SuppressMenu => onSettingsChange({
      SuppressMenu
    })
  }, "Suppress Menu"), React.createElement(CheckBox, {
    "data-name": "suppres-movable",
    checked: SuppressMovable,
    onChange: SuppressMovable => onSettingsChange({
      SuppressMovable
    })
  }, "Suppress Movable"), React.createElement(CheckBox, {
    disabled: true,
    checked: props.isEditable
  }, "Editable")))))), Boolean(possibleColumnTypes === null || possibleColumnTypes === void 0 ? void 0 : possibleColumnTypes.length) && React.createElement(Tabs, {
    marginTop: 2,
    autoFocus: false
  }, React.createElement(Tabs.Tab, null, "Column Types"), React.createElement(Tabs.Content, null, React.createElement(FormLayout, {
    columns: [{
      name: 'first',
      size: '30%'
    }, {
      name: 'second'
    }]
  }, (_c = chunk(possibleColumnTypes !== null && possibleColumnTypes !== void 0 ? possibleColumnTypes : [], 2)) === null || _c === void 0 ? void 0 : _c.map(([first, second]) => {
    var _a, _b, _c, _d, _e, _f;
    return React.createElement(FormRow, {
      key: first
    }, first && React.createElement(CheckBox, {
      "data-name": first,
      onChange: checked => handleColumnTypeChange(first, checked),
      checked: (_c = (_b = (_a = props.settings) === null || _a === void 0 ? void 0 : _a.ColumnTypes) === null || _b === void 0 ? void 0 : _b.includes) === null || _c === void 0 ? void 0 : _c.call(_b, first)
    }, first), second && React.createElement(CheckBox, {
      "data-name": second,
      onChange: checked => handleColumnTypeChange(second, checked),
      checked: (_f = (_e = (_d = props.settings) === null || _d === void 0 ? void 0 : _d.ColumnTypes) === null || _e === void 0 ? void 0 : _e.includes) === null || _f === void 0 ? void 0 : _f.call(_e, second)
    }, second));
  })))));
};