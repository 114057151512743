import { ExportDestination, MathOperation, ScheduleType } from '../PredefinedConfig/Common/Enums';
import { createUuid } from '../PredefinedConfig/Uuid';
import { ALERT_DEFAULT_MESSAGE_TYPE, ALERT_DEFAULT_SHOW_POPUP, EMPTY_STRING, PLUS_MINUS_DEFAULT_NUDGE_VALUE } from './Constants/GeneralConstants';
import { Bounce, Flip, Slide, Zoom } from '../components/Toastify';
import { BLACK, getHexForName, LIGHT_GRAY } from '../View/UIHelper';
export function CreateEmptyCustomSort() {
  return {
    Uuid: createUuid(),
    ColumnId: EMPTY_STRING,
    SortedValues: []
  };
}
export function CreateAdaptableComment(text, api) {
  const comment = {
    Value: text,
    AdaptableId: api.optionsApi.getAdaptableId(),
    Author: {
      UserName: api.optionsApi.getUserName()
    },
    Timestamp: Date.now(),
    Uuid: createUuid()
  };
  return comment;
}
export function CreateEmptyCalculatedColumn(defaultSpecialColumnSettings) {
  return {
    Uuid: createUuid(),
    ColumnId: EMPTY_STRING,
    Query: {
      ScalarExpression: EMPTY_STRING
    },
    // need to create some defaults - which we will change later
    CalculatedColumnSettings: Object.assign(Object.assign({}, defaultSpecialColumnSettings), {
      DataType: undefined
    })
  };
}
export function CreateEmptyNamedQuery(expression) {
  return {
    Uuid: createUuid(),
    Name: EMPTY_STRING,
    BooleanExpression: expression || EMPTY_STRING
  };
}
export function CreateEmptyPlusMinusNudge() {
  return {
    Uuid: createUuid(),
    Scope: undefined,
    Rule: undefined,
    NudgeValue: PLUS_MINUS_DEFAULT_NUDGE_VALUE
  };
}
export function CreateGenericAlert(alertHeader, alertMessage, alertDefinition) {
  return {
    alertType: 'generic',
    Uuid: createUuid(),
    header: alertHeader,
    message: alertMessage,
    alertDefinition: alertDefinition
  };
}
export function CreateCellChangedAlert(alertHeader, alertMessage, alertDefinition, cellDataChangedInfo) {
  return {
    alertType: 'cellChanged',
    Uuid: createUuid(),
    header: alertHeader,
    message: alertMessage,
    alertDefinition,
    cellDataChangedInfo: cellDataChangedInfo
  };
}
export function CreateRowChangedAlert(alertHeader, alertMessage, alertDefinition, gridDataChange) {
  return {
    alertType: 'rowChanged',
    Uuid: createUuid(),
    header: alertHeader,
    message: alertMessage,
    alertDefinition,
    gridDataChangedInfo: gridDataChange
  };
}
export function CreateEmptyAlertDefinition() {
  return {
    Uuid: createUuid(),
    Scope: {
      All: true
    },
    Rule: {
      Predicates: []
    },
    MessageType: ALERT_DEFAULT_MESSAGE_TYPE,
    AlertProperties: {}
  };
}
export function CreateEmptyFlashingCellDefinition() {
  return {
    Uuid: createUuid(),
    Scope: {
      ColumnIds: []
    },
    Rule: {
      Predicates: [{
        PredicateId: 'AnyChange'
      }]
    }
  };
}
export function CreateInternalAlertDefinitionForMessages(messageType, alertProperties) {
  alertProperties = alertProperties !== null && alertProperties !== void 0 ? alertProperties : {
    DisplayNotification: ALERT_DEFAULT_SHOW_POPUP
  };
  return {
    Uuid: createUuid(),
    Scope: undefined,
    Rule: {
      Predicates: []
    },
    MessageType: messageType,
    AlertProperties: alertProperties
  };
}
export function CreateEmptyReport() {
  return {
    Uuid: createUuid(),
    Name: EMPTY_STRING,
    Scope: undefined,
    ReportColumnScope: 'AllColumns',
    ReportRowScope: 'AllRows',
    Query: undefined
  };
}
export function CreateExternalReport(reportName) {
  return {
    Uuid: createUuid(),
    Name: reportName,
    Scope: undefined,
    ReportColumnScope: 'AllColumns',
    ReportRowScope: 'AllRows',
    Query: undefined
  };
}
export function CreateEmptyBaseSchedule(scheduleType) {
  return {
    Uuid: createUuid(),
    ScheduleType: scheduleType,
    Schedule: CreateEmptySchedule()
  };
}
export function CreateEmptyReminderSchedule() {
  return {
    Uuid: createUuid(),
    ScheduleType: ScheduleType.Reminder,
    Schedule: CreateEmptySchedule(),
    Header: EMPTY_STRING,
    Message: EMPTY_STRING,
    MessageType: 'Info',
    DisplayNotification: true,
    DisplaySystemStatusMessage: false
  };
}
export function CreateEmptyReportSchedule() {
  return {
    Uuid: createUuid(),
    ScheduleType: ScheduleType.Report,
    Schedule: CreateEmptySchedule(),
    ReportName: EMPTY_STRING,
    ExportDestination: ExportDestination.CSV
  };
}
export function CreateEmptyIPushPullReport() {
  return {
    Uuid: createUuid(),
    ReportName: EMPTY_STRING,
    Folder: EMPTY_STRING,
    Page: EMPTY_STRING
  };
}
export function CreateEmptyOpenFinReport() {
  return {
    Uuid: createUuid(),
    ReportName: EMPTY_STRING
  };
}
export function CreateEmptyIPushPullSchedule() {
  return {
    Uuid: createUuid(),
    ScheduleType: ScheduleType.ipushpull,
    Schedule: CreateEmptySchedule(),
    IPushPullReport: CreateEmptyIPushPullReport(),
    Transmission: 'Snapshot'
  };
}
export function CreateEmptyOpenFinSchedule() {
  return {
    Uuid: createUuid(),
    ScheduleType: ScheduleType.OpenFin,
    Schedule: CreateEmptySchedule(),
    OpenFinReport: CreateEmptyOpenFinReport(),
    Transmission: 'Live Data'
  };
}
export function CreateIPushPullSchedule(iPushPullReport) {
  return {
    Uuid: createUuid(),
    ScheduleType: ScheduleType.ipushpull,
    Schedule: CreateEmptySchedule(),
    IPushPullReport: iPushPullReport,
    Transmission: 'Snapshot'
  };
}
export function CreateReportSchedule(reportName) {
  return {
    Uuid: createUuid(),
    ScheduleType: ScheduleType.Report,
    Schedule: CreateEmptySchedule(),
    ReportName: reportName,
    ExportDestination: ExportDestination.CSV
  };
}
export function CreateEmptySchedule() {
  return {
    Uuid: createUuid(),
    OneOffDate: undefined,
    DaysOfWeek: [],
    Hour: 0,
    Minute: 0
  };
}
export function CreateEmptyShortcut() {
  return {
    Uuid: createUuid(),
    Scope: undefined,
    ShortcutKey: EMPTY_STRING,
    ShortcutValue: undefined,
    ShortcutOperation: MathOperation.Multiply
  };
}
export function CreateEmptyFormatColumn() {
  return {
    Scope: undefined,
    Style: CreateEmptyStyle(),
    DisplayFormat: undefined,
    CellAlignment: undefined,
    RowScope: undefined
  };
}
export function CreateEmptyFreeTextColumn(defaultSpecialColumnSettings) {
  return {
    Uuid: createUuid(),
    ColumnId: EMPTY_STRING,
    DefaultValue: EMPTY_STRING,
    FreeTextStoredValues: [],
    TextEditor: 'Inline',
    FreeTextColumnSettings: Object.assign(Object.assign({}, defaultSpecialColumnSettings), {
      DataType: 'String'
    })
  };
}
export function CreateEmptyLayout(layout, adaptableColumns) {
  return Object.assign(Object.assign({}, layout), {
    Uuid: createUuid(),
    Columns: layout.Columns || [],
    ColumnSorts: layout.ColumnSorts || [],
    ColumnFilters: layout.ColumnFilters || [],
    //  ColumnFlexMap: layout.ColumnFlexMap || {},
    ColumnWidthMap: layout.ColumnWidthMap || {},
    RowGroupedColumns: layout.RowGroupedColumns || (adaptableColumns ? adaptableColumns.filter(c => c.isGrouped).map(c => c.columnId) : [])
  });
}
export function CreateEmptyStyle() {
  return {
    BackColor: undefined,
    ForeColor: undefined,
    BorderColor: undefined,
    FontWeight: undefined,
    FontStyle: undefined,
    FontSize: undefined,
    ClassName: undefined
  };
}
export function CreateSystemStatusMessageInfo(message, type, furtherInfo) {
  return {
    Uuid: createUuid(),
    statusMessage: message,
    statusFurtherInformation: furtherInfo,
    statusType: type,
    timestamp: new Date()
  };
}
export function CreateEmptyCellSummmary() {
  return {
    Sum: undefined,
    Average: undefined,
    Median: undefined,
    Mode: undefined,
    Distinct: undefined,
    Max: undefined,
    Min: undefined,
    Count: undefined,
    Std_Deviation: undefined,
    Only: undefined
  };
}
export function CreateColumnFilter(ColumnId, PredicateId, Inputs) {
  return {
    ColumnId,
    Predicate: {
      PredicateId: PredicateId,
      Inputs
    }
  };
}
export function CreateEmptyStyledColumn() {
  return {
    Uuid: createUuid(),
    ColumnId: EMPTY_STRING
  };
}
export function CreateDefaultStyledColumnBadge() {
  return {
    Style: {
      BackColor: getHexForName(LIGHT_GRAY),
      ForeColor: getHexForName(BLACK),
      BorderRadius: 6
    }
  };
}
export function CreateEmptyChartDefinition(chartDefinition) {
  return Object.assign({
    Uuid: createUuid()
  }, chartDefinition);
}
export function CreateCustomDisplayFormatterContext(value, node, abColumn, api) {
  return {
    adaptableColumn: abColumn,
    cellValue: value,
    rowNode: node,
    adaptableApi: api,
    userName: api.optionsApi.getUserName(),
    adaptableId: api.optionsApi.getAdaptableId()
  };
}
export const createBaseContext = adaptableApi => {
  return {
    adaptableApi: adaptableApi,
    userName: adaptableApi.optionsApi.getUserName(),
    adaptableId: adaptableApi.optionsApi.getAdaptableId()
  };
};
export function CreateEmptyTheme(name) {
  return {
    Uuid: createUuid(),
    Name: name || 'Custom Theme',
    Description: name || 'Custom Theme'
  };
}
export function CreateEmptyCommentThread(cellAddress) {
  return Object.assign(Object.assign({}, cellAddress), {
    Comments: []
  });
}
function getToastPosition(position) {
  switch (position) {
    case 'BottomLeft':
      return 'bottom-left';
    case 'BottomCenter':
      return 'bottom-center';
    case 'BottomRight':
      return 'bottom-right';
    case 'TopLeft':
      return 'top-left';
    case 'TopCenter':
      return 'top-center';
    case 'TopRight':
      return 'top-right';
  }
}
function getToastTransition(transition) {
  switch (transition) {
    case 'Bounce':
      return Bounce;
    case 'Slide':
      return Slide;
    case 'Zoom':
      return Zoom;
    case 'Flip':
      return Flip;
    default:
      return Bounce;
  }
}
export function CreateToastOptions(notificationsOptions, {
  onClose,
  containerId
}, overides) {
  var _a;
  const adaptableToastPosition = notificationsOptions.position;
  const position = getToastPosition(adaptableToastPosition);
  const duration = (_a = overides.duration) !== null && _a !== void 0 ? _a : notificationsOptions.duration;
  const showProgressBar = notificationsOptions.showProgressBar;
  const closeWhenClicked = notificationsOptions.closeWhenClicked;
  const pauseWhenHovering = notificationsOptions.pauseWhenHovering;
  const isDraggable = notificationsOptions.isDraggable;
  const transition = getToastTransition(notificationsOptions.transition);
  const toastProps = {
    containerId,
    position,
    autoClose: duration == 'always' ? false : duration,
    hideProgressBar: !showProgressBar,
    closeOnClick: closeWhenClicked,
    pauseOnHover: pauseWhenHovering,
    draggable: isDraggable,
    transition: transition,
    draggablePercent: 75,
    onClose
    // progress: undefined,
  };
  return toastProps;
}
export const ObjectFactory = {
  CreateEmptyCustomSort,
  CreateEmptyCalculatedColumn,
  CreateEmptyNamedQuery,
  CreateEmptyPlusMinusNudge,
  CreateGenericAlert,
  CreateCellChangedAlert,
  CreateRowChangedAlert,
  CreateEmptyAlertDefinition,
  CreateEmptyFlashingCellDefinition,
  CreateInternalAlertDefinitionForMessages,
  CreateExternalReport,
  CreateEmptyReport,
  CreateEmptyBaseSchedule,
  CreateEmptyReminderSchedule,
  CreateEmptyReportSchedule,
  CreateEmptyIPushPullSchedule,
  CreateEmptyIPushPullReport,
  CreateIPushPullSchedule,
  CreateReportSchedule,
  CreateEmptyOpenFinReport,
  CreateEmptyOpenFinSchedule,
  CreateEmptySchedule,
  CreateEmptyShortcut,
  CreateEmptyFormatColumn,
  CreateEmptyFreeTextColumn,
  CreateEmptyLayout,
  CreateColumnFilter,
  CreateEmptyStyle,
  CreateEmptyCellSummmary,
  CreateSystemStatusMessageInfo,
  CreateToastOptions,
  CreateCustomDisplayFormatterContext,
  CreateEmptyStyledColumn,
  CreateDefaultStyledColumnBadge,
  CreateEmptyChartDefinition,
  CreateEmptyTheme,
  CreateEmptyCommentThread,
  CreateAdaptableComment
};
export default ObjectFactory;