import * as React from 'react';
import FormLayout, { FormRow } from '../../../components/FormLayout';
import { CheckBox } from '../../../components/CheckBox';
import HelpBlock from '../../../components/HelpBlock';
import { Box } from 'rebass';
const GridOptionsForm = props => {
  var _a;
  let {
    gridOptions
  } = props;
  return React.createElement(Box, {
    p: 2
  }, React.createElement(HelpBlock, null, "Grid Options"), React.createElement(FormLayout, {
    margin: 2,
    columns: [{
      name: 'children'
    }, {
      name: 'label',
      style: {
        textAlign: 'start'
      }
    }]
  }, React.createElement(FormRow, {
    label: "Enable Range Selection"
  }, React.createElement(CheckBox, {
    checked: gridOptions.enableRangeSelection,
    onChange: enableRangeSelection => {
      gridOptions = Object.assign(Object.assign({}, gridOptions), {
        enableRangeSelection: enableRangeSelection
      });
      props.onChangedGridOptions(gridOptions);
    }
  })), React.createElement(FormRow, {
    label: "Show Filter Bar"
  }, React.createElement(CheckBox, {
    checked: (_a = gridOptions.defaultColDef) === null || _a === void 0 ? void 0 : _a.floatingFilter,
    onChange: floatingFilter => {
      gridOptions = Object.assign(Object.assign({}, gridOptions), {
        defaultColDef: {
          floatingFilter
        }
      });
      props.onChangedGridOptions(gridOptions);
    }
  })), React.createElement(FormRow, {
    label: "Suppress Column Virtualisation"
  }, React.createElement(CheckBox, {
    checked: gridOptions.suppressColumnVirtualisation,
    onChange: suppressColumnVirtualisation => {
      gridOptions = Object.assign(Object.assign({}, gridOptions), {
        suppressColumnVirtualisation: suppressColumnVirtualisation
      });
      props.onChangedGridOptions(gridOptions);
    }
  })), React.createElement(FormRow, {
    label: "Show Column Menu Button"
  }, React.createElement(CheckBox, {
    checked: gridOptions.suppressMenuHide,
    onChange: suppressMenuHide => {
      gridOptions = Object.assign(Object.assign({}, gridOptions), {
        suppressMenuHide: suppressMenuHide
      });
      props.onChangedGridOptions(gridOptions);
    }
  }))));
};
export default GridOptionsForm;