import { EMPTY_ARRAY } from '../../Utilities/Constants/GeneralConstants';
export const TEAMSHARING_GET = 'TEAMSHARING_GET';
export const TEAMSHARING_SET = 'TEAMSHARING_SET';
export const TEAMSHARING_SHARE = 'TEAMSHARING_SHARE';
export const TEAMSHARING_SHARE_CUSTOM = 'TEAMSHARING_SHARE_CUSTOM';
export const TEAMSHARING_IMPORT_ITEM = 'TEAMSHARING_IMPORT_ITEM';
export const TEAMSHARING_PROCESS_IMPORT = 'TEAMSHARING_PROCESS_IMPORT';
export const TEAMSHARING_COMMIT_IMPORT = 'TEAMSHARING_COMMIT_IMPORT';
export const TEAMSHARING_REMOVE_ITEM = 'TEAMSHARING_REMOVE_ITEM';
export const TEAMSHARING_LINK_ITEM = 'TEAMSHARING_LINK_ITEM';
export const TEAMSHARING_UPDATE_ITEM = 'TEAMSHARING_UPDATE_ITEM';
export const TEAMSHARING_CHECK_FOR_UPDATES = 'TEAMSHARING_CHECK_FOR_UPDATES';
export const TeamSharingShare = (entity, module, configuration) => ({
  type: TEAMSHARING_SHARE,
  Entity: entity,
  Module: module,
  Configuration: configuration
});
export const TeamSharingShareCustom = (entity, configuration) => ({
  type: TEAMSHARING_SHARE_CUSTOM,
  Entity: entity,
  Configuration: configuration
});
export const TeamSharingSet = Entities => ({
  type: TEAMSHARING_SET,
  Entities
});
export const TeamSharingImportItem = SharedEntity => ({
  type: TEAMSHARING_IMPORT_ITEM,
  SharedEntity
});
export const TeamSharingProcessImport = ImportSteps => ({
  type: TEAMSHARING_PROCESS_IMPORT,
  ImportSteps
});
export const TeamSharingCommitImport = () => ({
  type: TEAMSHARING_COMMIT_IMPORT
});
export const TeamSharingRemoveItem = Uuid => ({
  type: TEAMSHARING_REMOVE_ITEM,
  Uuid
});
export const TeamSharingGet = () => ({
  type: TEAMSHARING_GET
});
export const TeamSharingLinkItem = (...SharedEntities) => ({
  type: TEAMSHARING_LINK_ITEM,
  SharedEntities
});
export const TeamSharingUpdateItem = (ChangedAdaptableObject, UserName) => ({
  type: TEAMSHARING_UPDATE_ITEM,
  ChangedAdaptableObject,
  UserName
});
export const TeamSharingCheckForUpdates = () => ({
  type: TEAMSHARING_CHECK_FOR_UPDATES
});
const initialTeamSharingState = {
  SharedEntities: EMPTY_ARRAY,
  ActiveSharedEntityMap: {},
  importProcessInProgress: false
};
export const TeamSharingReducer = (state = initialTeamSharingState, action) => {
  switch (action.type) {
    case TEAMSHARING_SET:
      {
        const actionTyped = action;
        const sharedEntities = actionTyped.Entities;
        // keep only the active entities which are still shared (some may have been removed by other users)
        const sharedEntityItemIds = sharedEntities.map(sharedEntity => sharedEntity.Entity.Uuid);
        const activeSharedEntityMap = {};
        Object.keys(state.ActiveSharedEntityMap).forEach(activeObjectId => {
          if (sharedEntityItemIds.includes(activeObjectId)) {
            activeSharedEntityMap[activeObjectId] = state.ActiveSharedEntityMap[activeObjectId];
          }
        });
        return Object.assign({}, state, {
          SharedEntities: sharedEntities,
          ActiveSharedEntityMap: activeSharedEntityMap
        });
      }
    case TEAMSHARING_LINK_ITEM:
      {
        const actionTyped = action;
        const newActiveEntities = actionTyped.SharedEntities;
        const activeSharedEntityMap = Object.assign({}, state.ActiveSharedEntityMap);
        newActiveEntities.forEach(newActiveEntity => activeSharedEntityMap[newActiveEntity.Entity.Uuid] = newActiveEntity);
        return Object.assign({}, state, {
          ActiveSharedEntityMap: activeSharedEntityMap
        });
      }
    case TEAMSHARING_PROCESS_IMPORT:
      {
        return Object.assign({}, state, {
          importProcessInProgress: true
        });
      }
    case TEAMSHARING_COMMIT_IMPORT:
      {
        return Object.assign({}, state, {
          importProcessInProgress: false
        });
      }
    default:
      return state;
  }
};