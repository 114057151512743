import * as React from 'react';
import { Icon } from '../../../components/icons';
import useProperty from '../../../components/utils/useProperty';
import { NumberInput } from '../../../components/Input/NumberInput';
const fillStyle = {
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0
};
const hostStyle = Object.assign(Object.assign({}, fillStyle), {
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
});
const inputStyle = {
  position: 'relative',
  background: 'transparent',
  boxSizing: 'border-box',
  color: 'currentcolor',
  minWidth: 0,
  outline: 'none',
  textAlign: 'right',
  width: '100%',
  border: 'none',
  boxShadow: 'none',
  paddingRight: '3ch'
};
export const InternalAdaptablePercentageEditor = React.forwardRef(function InternalAdaptablePercentageEditorFn(props, ref) {
  var _a;
  const inputRef = React.useRef(null);
  const focus = () => {
    var _a;
    (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
  };
  const [value, setValue] = useProperty(props, 'value', props.defaultValue, {
    onChange: value => {
      var _a;
      if (value !== '' && value == Number(value)) {
        value = Number(value);
      }
      (_a = props.onValueChange) === null || _a === void 0 ? void 0 : _a.call(props, value);
    }
  });
  React.useImperativeHandle(ref, () => {
    return {
      focus,
      setValue
    };
  });
  const showClear = props.showClearButton && props.emptyValue != null && value != props.emptyValue;
  return React.createElement("div", {
    style: hostStyle,
    className: "ab-PercentageEditor",
    onKeyDown: e => {
      if (e.key === 'Tab' && showClear && document.activeElement === inputRef.current) {
        e.stopPropagation();
      }
    }
  }, React.createElement(NumberInput, {
    type: (_a = props.type) !== null && _a !== void 0 ? _a : 'number',
    className: "ab-PercentageEditor-Input",
    value: value,
    noArrows: true,
    autoFocus: true,
    onChange: React.useCallback(value => {
      setValue(value);
    }, []),
    style: inputStyle,
    ref: inputRef
  }), showClear ? React.createElement("div", {
    style: {
      display: 'inline-block',
      marginRight: 'var(--ab-space-1)',
      marginLeft: 'var(--ab-space-1)'
    },
    onClick: e => {
      if (props.emptyValue != null) {
        setValue(props.emptyValue);
        e.stopPropagation();
        focus();
      }
    },
    onKeyDown: e => {
      if (props.emptyValue != null && e.key === 'Enter') {
        setValue(props.emptyValue);
        e.stopPropagation();
        focus();
      }
    }
  }, React.createElement(Icon, {
    size: 18,
    name: "close",
    tabIndex: 0
  })) : null, React.createElement("div", {
    style: {
      position: 'absolute',
      right: showClear ? 20 : 0,
      paddingInlineEnd: '0.5ch'
    }
  }, "%"));
});