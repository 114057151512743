import { __rest } from "tslib";
import * as React from 'react';
import { Box } from 'rebass';
import { createContext, useContext } from 'react';
import merge from 'lodash/merge';
import join from '../utils/join';
const FormLayoutContext = createContext(null);
const defaultColumns = {
  label: {
    className: 'ab-FormLayout_column--label',
    style: {
      textAlign: 'end'
    }
  }
};
const PLACEHOLDER = React.createElement("div", null);
const FormLayout = props => {
  let {
      placeholder = PLACEHOLDER,
      columns = ['label', 'children'],
      defaultComponent = Box,
      sizes = ['auto', '1fr'],
      gridColumnGap = 'var(--ab-space-2)',
      gridRowGap = 'var(--ab-space-2)',
      style,
      childrenToColumns = true
    } = props,
    boxProps = __rest(props, ["placeholder", "columns", "defaultComponent", "sizes", "gridColumnGap", "gridRowGap", "style", "childrenToColumns"]);
  columns = columns.map(c => {
    if (typeof c === 'string' || typeof c === 'number') {
      c = {
        name: `${c}`
      };
    }
    if (defaultColumns[c.name]) {
      c = merge({}, defaultColumns[c.name], c);
    }
    c.component = c.component === undefined ? defaultComponent : c.component;
    return c;
  });
  const formStyle = {
    gridTemplateColumns: columns.map((column, i) => {
      if (column.size) {
        return `${column.size}`;
      }
      if (sizes[i] !== undefined) {
        return `${sizes[i]}`;
      }
      return 'auto';
    }).join(' '),
    gridRowGap,
    gridColumnGap,
    rowGap: gridRowGap,
    columnGap: gridColumnGap
  };
  return React.createElement(FormLayoutContext.Provider, {
    value: {
      columns,
      currentRow: 0,
      childrenToColumns
    }
  }, React.createElement(Box, Object.assign({}, boxProps, {
    className: join(boxProps.className, 'ab-FormLayout'),
    style: Object.assign(Object.assign({}, style), formStyle)
  })));
};
export const FormRow = props => {
  const ctx = useContext(FormLayoutContext);
  const {
    columns,
    placeholder,
    childrenToColumns
  } = ctx;
  const rowIndex = ctx.currentRow;
  ctx.currentRow++;
  const children = React.Children.toArray(props.children);
  const columnValues = columns.map((column, columnIndex) => {
    const columnName = column.name;
    let item = props[columnName];
    if (item === undefined && childrenToColumns) {
      item = children.shift();
    }
    let value = item;
    if (item == null) {
      value = placeholder;
    }
    if (props.as != null || column.component != null) {
      const Cmp = column.component;
      value = React.createElement(Cmp, {
        "data-name": props.dataName,
        style: Object.assign(Object.assign({}, column.style), {
          gridColumn: columnIndex + 1,
          gridRow: rowIndex + 1
        }),
        className: column.className
      }, value);
    }
    return typeof value === 'string' ? value : React.cloneElement(value, {
      key: columnName
    });
  });
  return React.createElement(React.Fragment, null, columnValues);
};
export default FormLayout;