import { ApiBase } from './ApiBase';
import * as GridRedux from '../../Redux/ActionsReducers/GridRedux';
import { SystemHighlightCellAdd, SystemHighlightCellDelete, SystemHighlightCellDeleteAll, SystemHighlightRowAdd, SystemHighlightRowDelete, SystemHighlightRowDeleteAll, SystemHighlightRowsAdd, SystemHighlightRowsDelete } from '../../Redux/ActionsReducers/SystemRedux';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import { GridInternalApi } from '../Internal/GridInternalApi';
import ArrayExtensions from '../../Utilities/Extensions/ArrayExtensions';
import { WINDOW_SHOW_TRANSPOSED_VIEW } from '../../View/Components/Popups/WindowPopups/windowFactory';
import { ROW_SUMMARY_ROW_ID } from '../../PredefinedConfig/Common/RowSummary';
export class GridApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.internalApi = new GridInternalApi(adaptable);
  }
  getVariant() {
    return this.adaptable.variant;
  }
  getGridState() {
    return this.getAdaptableState().Grid;
  }
  loadGridData(dataSource) {
    // we intentionally set an initial empty array,
    // to prevent `cellchanged` events from being triggered for rows that have
    // the same primary key with existing rows
    // see #testprevent_cellchanged_event_on_load_grid_data
    this.adaptable.agGridAdapter.setGridOption('rowData', []);
    this.adaptable.setGridData(dataSource);
    const allRowNodes = this.getAllRowNodes();
    this.internalApi.fireGridDataChangedEvent(dataSource, allRowNodes, 'Load');
  }
  getGridData() {
    return this.adaptable.getGridData();
  }
  getFilteredData() {
    return this.adaptable.getFilteredData();
  }
  getVisibleData() {
    const data = [];
    this.adaptable.forAllVisibleRowNodesDo(rowNode => {
      if (!this.isGroupRowNode(rowNode)) {
        data.push(rowNode.data);
      }
    });
    return data;
  }
  async updateGridData(dataRows, dataUpdateConfig) {
    const rowNodes = await this.adaptable.updateRows(dataRows, dataUpdateConfig);
    this.internalApi.fireGridDataChangedEvent(dataRows, rowNodes, 'Update');
    return rowNodes;
  }
  async addOrUpdateGridData(dataRows, dataUpdateConfig) {
    const {
      added,
      updated
    } = await this.adaptable.addOrUpdateRows(dataRows, dataUpdateConfig);
    if (ArrayExtensions.IsNotNullOrEmpty(updated)) {
      this.internalApi.fireGridDataChangedEvent(dataRows, updated, 'Update');
    }
    if (ArrayExtensions.IsNotNullOrEmpty(added)) {
      this.internalApi.fireGridDataChangedEvent(dataRows, added, 'Add');
    }
    return {
      addedRows: added,
      updatedRows: updated
    };
  }
  async addGridData(dataRows, dataUpdateConfig) {
    const rowNodes = await this.adaptable.addRows(dataRows, dataUpdateConfig);
    this.internalApi.fireGridDataChangedEvent(dataRows, rowNodes, 'Add');
    return rowNodes;
  }
  undoCellEdit(cellDataChangedInfo) {
    // for the reason of this hacky solution see the comments in DataService
    this.getAdaptableApi().internalApi.getDataService().logUndoChange(cellDataChangedInfo);
    const cellUpdateRequest = {
      columnId: cellDataChangedInfo.column.columnId,
      newValue: cellDataChangedInfo.oldValue,
      primaryKeyValue: cellDataChangedInfo.primaryKeyValue,
      rowNode: cellDataChangedInfo.rowNode
    };
    this.setCellValue(cellUpdateRequest);
    return true;
  }
  async deleteGridData(dataRows, dataUpdateConfig) {
    if (this.checkArrayExists(dataRows)) {
      const rowNodes = await this.adaptable.deleteRows(dataRows, dataUpdateConfig);
      this.internalApi.fireGridDataChangedEvent(dataRows, rowNodes, 'Delete');
      return rowNodes;
    }
    return [];
  }
  setCellValue(cellUpdateRequest) {
    var _a;
    const abColumn = this.getAdaptableApi().columnApi.getColumnWithColumnId(cellUpdateRequest.columnId);
    if (!abColumn) {
      this.logWarn(`setCellValue() - column not found for columnId: ${cellUpdateRequest.columnId}`);
      return;
    }
    const rowNode = (_a = cellUpdateRequest.rowNode) !== null && _a !== void 0 ? _a : this.getRowNodeForPrimaryKey(cellUpdateRequest.primaryKeyValue);
    if (!rowNode) {
      this.logWarn(`setCellValue() - rowNode not found for primaryKeyValue: ${cellUpdateRequest.primaryKeyValue}`);
      return;
    }
    this.adaptable.setDataValue(cellUpdateRequest.newValue, abColumn, cellUpdateRequest.primaryKeyValue, rowNode);
  }
  setCellValues(cellUpdateRequests) {
    cellUpdateRequests === null || cellUpdateRequests === void 0 ? void 0 : cellUpdateRequests.forEach(cellUpdateRequest => this.setCellValue(cellUpdateRequest));
  }
  getSelectedCellInfo() {
    return this.getGridState().SelectedCellInfo;
  }
  getSelectedRowInfo() {
    return this.getGridState().SelectedRowInfo;
  }
  getCellDisplayValue(primaryKeyValue, columnId) {
    const rowNode = this.getRowNodeForPrimaryKey(primaryKeyValue);
    return rowNode ? this.getDisplayValueFromRowNode(rowNode, columnId) : undefined;
  }
  getCellRawValue(primaryKeyValue, columnId) {
    const rowNode = this.getRowNodeForPrimaryKey(primaryKeyValue);
    return rowNode ? this.getRawValueFromRowNode(rowNode, columnId) : undefined;
  }
  getCellNormalisedValue(primaryKeyValue, columnId) {
    const rowNode = this.getRowNodeForPrimaryKey(primaryKeyValue);
    return rowNode ? this.getNormalisedValueFromRowNode(rowNode, columnId) : undefined;
  }
  hideFilterForm() {
    this.adaptable.hideColumnFilterForm();
  }
  applyGridFiltering() {
    this.adaptable.applyColumnFiltering();
  }
  clearGridFiltering() {
    // slightly round the houses but we have to call ColumnFilterAPI as it does it properly
    this.getAdaptableApi().columnFilterApi.clearColumnFilters();
  }
  getColumnSorts() {
    return this.getAdaptableState().Grid.ColumnSorts;
  }
  getColumnSortForColumn(columnId) {
    let columnSorts = this.getColumnSorts();
    return columnSorts === null || columnSorts === void 0 ? void 0 : columnSorts.find(cs => cs.ColumnId == columnId);
  }
  setAdaptableSorting(columnSorts) {
    this.adaptable.setColumnSort(columnSorts);
    this.setColumnSorts(columnSorts);
  }
  clearAdaptableSorting() {
    this.adaptable.clearColumnSort();
    this.clearColumnSorts();
  }
  setColumnSorts(columnSorts) {
    this.dispatchAction(GridRedux.GridSetSort(columnSorts));
  }
  clearColumnSorts() {
    this.dispatchAction(GridRedux.GridClearSort());
  }
  selectRow(primaryKeyValue, clearSelection) {
    this.selectNode(this.getRowNodeForPrimaryKey(primaryKeyValue), clearSelection);
  }
  selectRows(primaryKeyValues, clearSelection) {
    let nodes = [];
    primaryKeyValues.forEach(pkValue => {
      nodes.push(this.getRowNodeForPrimaryKey(pkValue));
    });
    this.selectNodes(nodes, clearSelection);
  }
  selectNode(rowNode, clearSelection) {
    this.adaptable.selectNode(rowNode, clearSelection);
  }
  selectNodes(rowNodes, clearSelection) {
    this.adaptable.selectNodes(rowNodes, clearSelection);
  }
  deSelectRow(primaryKeyValue, clearSelection) {
    this.adaptable.deSelectNode(this.getRowNodeForPrimaryKey(primaryKeyValue), clearSelection);
  }
  deSelectRows(primaryKeyValues, clearSelection) {
    let nodes = [];
    primaryKeyValues.forEach(pkValue => {
      nodes.push(this.getRowNodeForPrimaryKey(pkValue));
    });
    this.deSelectNodes(nodes, clearSelection);
  }
  deSelectNode(rowNode, clearSelection) {
    this.adaptable.deSelectNode(rowNode, clearSelection);
  }
  deSelectNodes(rowNodes, clearSelection) {
    this.adaptable.deSelectNodes(rowNodes, clearSelection);
  }
  getSelectionStartEndNodes(gridCellRange) {
    let startNode;
    let endNode;
    startNode = gridCellRange.primaryKeyValueEnd ? this.adaptable.getRowNodeForPrimaryKey(gridCellRange.primaryKeyValueStart) : this.adaptable.getRowNodeByIndex(gridCellRange.rowIndexStart);
    if (gridCellRange.primaryKeyValueEnd) {
      endNode = this.adaptable.getRowNodeForPrimaryKey(gridCellRange.primaryKeyValueEnd);
    } else if (gridCellRange.rowIndexEnd) {
      endNode = this.adaptable.getRowNodeByIndex(gridCellRange.rowIndexEnd);
    } else {
      endNode = startNode;
    }
    return [startNode, endNode];
  }
  selectCellRange(gridCellRange, clearSelection) {
    if (gridCellRange == undefined) {
      return;
    }
    if (gridCellRange.primaryKeyValueStart == undefined && gridCellRange.rowIndexStart == undefined) {
      return;
    }
    const [startNode, endNode] = this.getSelectionStartEndNodes(gridCellRange);
    if (startNode && endNode) {
      this.adaptable.selectCells(gridCellRange.columnIds, startNode, endNode, clearSelection);
    }
  }
  selectCellRangeByQuery(query, gridCellRange, clearSelection) {
    const filteredRowNodes = [];
    const isRowNodeInQuery = rowNode => this.getAdaptableApi().internalApi.getQueryLanguageService().evaluateBooleanExpression(query, 'GridInfo', rowNode);
    if (gridCellRange) {
      const [startNode, endNode] = this.getSelectionStartEndNodes(gridCellRange);
      for (let rowIndex = startNode.rowIndex; rowIndex <= endNode.rowIndex; rowIndex++) {
        const rowNode = this.adaptable.getRowNodeByIndex(rowIndex);
        isRowNodeInQuery(rowNode) && filteredRowNodes.push(rowNode);
      }
    } else {
      // include all row
      this.adaptable.forAllRowNodesDo(rowNode => {
        isRowNodeInQuery(rowNode) && filteredRowNodes.push(rowNode);
      });
    }
    if (!filteredRowNodes.length) {
      return;
    }
    let preapredGridCellRange = gridCellRange;
    if (!preapredGridCellRange) {
      /**
       * Select all rows and all columns
       */
      const currentLayout = this.getAdaptableApi().layoutApi.getCurrentLayout();
      preapredGridCellRange = {
        columnIds: currentLayout.Columns
      };
    }
    // ranges of row with consecutive indexes
    const rowRanges = filteredRowNodes.reduce((acc, rowNode, index, collection) => {
      // add start if there is none
      const prevRange = acc[acc.length - 1];
      if (prevRange.startNode === undefined) {
        prevRange.startNode = rowNode;
      }
      // add end if next is not consecutive
      const nextNode = collection[index + 1];
      if (nextNode && nextNode.rowIndex - 1 !== rowNode.rowIndex) {
        prevRange.endNode = rowNode;
        // start a new one
        acc.push({});
      }
      // if at the end close last range
      if (index === collection.length - 1) {
        prevRange.endNode = rowNode;
      }
      return acc;
    }, [{}]);
    let preparedClearSelection = clearSelection;
    rowRanges.forEach(range => {
      // clear only on first range selection
      this.adaptable.selectCells(preapredGridCellRange.columnIds, range.startNode, range.endNode, preparedClearSelection);
      preparedClearSelection = false;
    });
  }
  selectColumn(columnId) {
    this.selectColumns([columnId]);
  }
  selectColumns(columnIds) {
    this.getAdaptableApi().columnApi.selectColumns(columnIds);
  }
  getFirstRowNode() {
    return this.adaptable.getFirstRowNode();
  }
  getFirstDisplayedRowNode() {
    return this.adaptable.getFirstDisplayedRowNode();
  }
  getVisibleRowNodes(config) {
    const rowNodes = [];
    this.adaptable.forAllVisibleRowNodesDo(rowNode => rowNodes.push(rowNode), config);
    return rowNodes;
  }
  getAllRowNodes(config) {
    return this.adaptable.getAllRowNodes(config);
  }
  getGroupRowNodes(config) {
    return this.adaptable.getGroupRowNodes(config);
  }
  getGridCellFromRowNode(rowNode, columnId) {
    return this.adaptable.getGridCellFromRowNode(rowNode, columnId);
  }
  getRawValueFromRowNode(rowNode, columnId) {
    return this.adaptable.getRawValueFromRowNode(rowNode, columnId);
  }
  getDisplayValueFromRowNode(rowNode, columnId) {
    return this.adaptable.getDisplayValueFromRowNode(rowNode, columnId);
  }
  getDisplayValueFromRawValue(rowNode, columnId, rawValue) {
    return this.adaptable.getDisplayValueFromRawValue(rowNode, columnId, rawValue);
  }
  getNormalisedValueFromRowNode(rowNode, columnId) {
    const rawValue = this.adaptable.getRawValueFromRowNode(rowNode, columnId);
    const abColumn = this.getAdaptableApi().columnApi.getColumnWithColumnId(columnId);
    return this.adaptable.getNormalisedValueFromRawValue(rawValue, abColumn);
  }
  getRowNodesForPrimaryKeys(primaryKeyValues) {
    return this.adaptable.getRowNodesForPrimaryKeys(primaryKeyValues);
  }
  getRowNodeForPrimaryKey(primaryKeyValue) {
    return this.adaptable.getRowNodeForPrimaryKey(primaryKeyValue);
  }
  getPrimaryKeyValueForRowNode(rowNode) {
    return this.adaptable.getPrimaryKeyValueFromRowNode(rowNode);
  }
  getRowNodeForIndex(index) {
    return this.adaptable.getRowNodeByIndex(index);
  }
  getPrimaryKeyValuesForRowNodes(rowNodes) {
    return rowNodes.map(rowNode => this.getPrimaryKeyValueForRowNode(rowNode));
  }
  setRowGroupColumns(columnIds) {
    this.adaptable.setRowGroupColumns(columnIds);
  }
  clearRowGroupColumns() {
    this.adaptable.clearRowGroupColumns();
  }
  expandAllRowGroups() {
    this.adaptable.expandAllRowGroups();
  }
  collapseAllRowGroups() {
    this.adaptable.collapseAllRowGroups();
  }
  getExpandRowGroupsKeys() {
    return this.adaptable.getExpandRowGroupsKeys();
  }
  expandRowGroupsForValues(columnValues) {
    this.adaptable.expandRowGroupsForValues(columnValues);
  }
  isGridPivotable() {
    return !this.getAdaptableApi().internalApi.isGridInTreeMode();
  }
  isGridGroupable() {
    return !this.getAdaptableApi().internalApi.isGridInTreeMode();
  }
  isGridRowSelectable() {
    return this.adaptable.isGridRowSelectable();
  }
  isGridRangeSelectable() {
    return this.adaptable.isGridRangeSelectable();
  }
  isGridRowGrouped() {
    return this.adaptable.isGridGroupingActive();
  }
  isGridInPivotMode() {
    return this.getLayoutApi().isCurrentLayoutPivot();
  }
  isGroupRowNode(rowNode) {
    return this.adaptable.isGroupRowNode(rowNode);
  }
  isSummaryNode(rowNode) {
    var _a;
    return !!((_a = rowNode === null || rowNode === void 0 ? void 0 : rowNode.data) === null || _a === void 0 ? void 0 : _a[ROW_SUMMARY_ROW_ID]);
  }
  isQuickFilterAvailable() {
    return this.adaptable.isQuickFilterAvailable();
  }
  redrawGrid() {
    this.adaptable.redrawBody();
    this.adaptable.refreshHeader();
  }
  getGridCellsForRawValue(columnId, rawValue) {
    const gridCells = this.adaptable.getGridCellsForColumn(columnId);
    if (!gridCells) {
      return undefined;
    }
    const returnValues = [];
    gridCells.forEach(gc => {
      if (gc.rawValue === rawValue) {
        returnValues.push(gc);
      }
    });
    return returnValues;
  }
  getCellRawValueCount(columnId, rawValue) {
    const gridCells = this.getGridCellsForRawValue(columnId, rawValue);
    return gridCells === null || gridCells === void 0 ? void 0 : gridCells.length;
  }
  getGridCellsForDisplayValue(columnId, displayValue) {
    const gridCells = this.adaptable.getGridCellsForColumn(columnId);
    if (!gridCells) {
      return undefined;
    }
    const returnGridCells = [];
    gridCells.forEach(gc => {
      if (gc.displayValue === displayValue) {
        returnGridCells.push(gc);
      }
    });
    return returnGridCells;
  }
  jumpToRow(primaryKeyValue) {
    const node = this.adaptable.getRowNodeForPrimaryKey(primaryKeyValue);
    this.adaptable.jumpToRow(node);
  }
  jumpToColumn(columnId) {
    this.adaptable.jumpToColumn(columnId);
  }
  jumpToCell(primaryKeyValue, columnId) {
    const node = this.adaptable.getRowNodeForPrimaryKey(primaryKeyValue);
    this.adaptable.jumpToCell(columnId, node);
  }
  highlightCell(cellHighlightInfo) {
    this.dispatchAction(SystemHighlightCellAdd(cellHighlightInfo));
    if (cellHighlightInfo.timeout) {
      setTimeout(() => {
        this.unHighlightCell(cellHighlightInfo.primaryKeyValue, cellHighlightInfo.columnId);
      }, cellHighlightInfo.timeout);
    }
  }
  unHighlightCell(primaryKeyValue, columnId) {
    this.dispatchAction(SystemHighlightCellDelete(primaryKeyValue, columnId));
  }
  unHighlightAllCells() {
    this.dispatchAction(SystemHighlightCellDeleteAll());
  }
  highlightRow(rowHighlightInfo) {
    this.dispatchAction(SystemHighlightRowAdd(rowHighlightInfo));
    if (rowHighlightInfo.timeout) {
      setTimeout(() => {
        this.unHighlightRow(rowHighlightInfo.primaryKeyValue);
      }, rowHighlightInfo.timeout);
    }
  }
  highlightRows(rowHighlightInfos) {
    this.dispatchAction(SystemHighlightRowsAdd(rowHighlightInfos));
    if (rowHighlightInfos.timeout) {
      setTimeout(() => {
        this.unHighlightRows(rowHighlightInfos.primaryKeyValues);
      }, rowHighlightInfos.timeout);
    }
  }
  unHighlightRow(primaryKeyValue) {
    this.dispatchAction(SystemHighlightRowDelete(primaryKeyValue));
  }
  unHighlightRows(primaryKeyValues) {
    this.dispatchAction(SystemHighlightRowsDelete(primaryKeyValues));
  }
  unHighlightAllRows() {
    this.dispatchAction(SystemHighlightRowDeleteAll());
  }
  refreshCells(rowNodes, columnIds) {
    this.adaptable.refreshCells(rowNodes, columnIds, true);
  }
  refreshColumn(columnId) {
    this.adaptable.refreshCells(null, [columnId], true);
  }
  refreshColumns(columnIds) {
    this.adaptable.refreshCells(null, columnIds, true);
  }
  refreshRowByPrimaryKey(primaryKey) {
    const rowNode = this.getRowNodeForPrimaryKey(primaryKey);
    this.refreshRowNode(rowNode);
  }
  refreshRowNode(rowNode) {
    this.adaptable.redrawRow(rowNode);
  }
  refreshRowNodes(rowNodes) {
    this.adaptable.redrawRows(rowNodes);
  }
  refreshGroupRowNodes() {
    // see https://www.ag-grid.com/javascript-data-grid/client-side-row-stages/#refreshing-the-client-side-model
    this.getAdaptableApi().agGridApi.refreshClientSideRowModel('group');
    this.adaptable.updateRowGroupsExpandedState();
  }
  isCellEditable(gridCell) {
    if (!gridCell) {
      return false;
    }
    if (!gridCell.column) {
      // GridCell.column may be undefined for cells from synthetic columns created by AG Grid (ex. autoGroup columns)
      return false;
    }
    if (gridCell.column.readOnly) {
      return false;
    }
    return this.adaptable.isCellEditable(gridCell.rowNode, this.adaptable.getAgGridColumnForColumnId(gridCell.column.columnId));
  }
  isEveryCellEditable(gridCells) {
    for (let gridCell of gridCells) {
      if (!this.isCellEditable(gridCell)) {
        return false;
      }
    }
    return true;
  }
  getRowCount() {
    return this.adaptable.getRowCount();
  }
  getRowsInViewport() {
    return this.adaptable.getRowsInViewport();
  }
  getColumnCount() {
    return this.adaptable.getColumnCount();
  }
  getVisibleColumnCount() {
    return this.adaptable.getVisibleColumnCount();
  }
  selectAll() {
    this.adaptable.selectAll();
  }
  deselectAll() {
    this.adaptable.deselectAll();
  }
  destroy() {
    this.adaptable.destroy();
  }
  getGridContainerElement() {
    return this.adaptable.getAgGridContainerElement();
  }
  openGridInfoSettingsPanel() {
    this.showModulePopup(ModuleConstants.GridInfoModuleId);
  }
  getAgGridRowModelType() {
    return this.adaptable.getAgGridRowModelType();
  }
  showTransposedView(transposeConfig = {}) {
    var _a, _b, _c, _d, _e;
    const transposedColumnId = (_a = transposeConfig.transposedColumnId) !== null && _a !== void 0 ? _a : this.getAdaptableApi().optionsApi.getPrimaryKey();
    const hideTransposedColumn = (_b = transposeConfig.hideTransposedColumn) !== null && _b !== void 0 ? _b : true;
    const visibleColumns = (_c = transposeConfig.visibleColumns) !== null && _c !== void 0 ? _c : false;
    const visibleRows = (_d = transposeConfig.visibleRows) !== null && _d !== void 0 ? _d : false;
    const autosize = (_e = transposeConfig.autosize) !== null && _e !== void 0 ? _e : true;
    this.getAdaptableApi().internalApi.showPopupWindow({
      id: WINDOW_SHOW_TRANSPOSED_VIEW,
      factoryId: WINDOW_SHOW_TRANSPOSED_VIEW,
      title: 'Transposed View',
      icon: 'grid',
      popupProps: {
        transposedColumnId,
        hideTransposedColumn,
        visibleColumns,
        visibleRows,
        autosize
      }
    });
  }
  getAllAgGridColumns() {
    return this.adaptable.getAllGridColumns();
  }
}