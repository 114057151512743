import * as React from 'react';
import { Text } from 'rebass';
import { Tabs } from '../../../components/Tabs';
import { Tag } from '../../../components/Tag';
import { NewColumnSelector } from '../../Components/ColumnSelector';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
export const renderStyledColumnColumnSummary = data => {
  const {
    api
  } = useOnePageAdaptableWizardContext();
  return React.createElement(Text, {
    pr: 2,
    py: 2
  }, "Styled Column column:", ' ', React.createElement(Tag, null, data.ColumnId ? api.columnApi.getFriendlyNameForColumnId(data.ColumnId) : 'No Column Selected'));
};
export const isValidStyledColumnColumn = data => {
  if (!data.ColumnId) {
    return 'You have to select a Column for Styled Column';
  }
  return true;
};
export const StyledColumnWizardColumnSection = props => {
  const {
    data,
    api
  } = useOnePageAdaptableWizardContext();
  const sortableCols = React.useMemo(() => {
    let sortableColumns = [];
    if (data.SparkLineStyle) {
      sortableColumns = api.columnApi.getNumericArrayColumns();
    } else if (data.BadgeStyle) {
      sortableColumns = [...api.columnApi.getNumericColumns(), ...api.columnApi.getStringColumns(), ...api.columnApi.getStringArrayColumns(), ...api.columnApi.getNumberArrayColumns()];
    } else {
      sortableColumns = api.columnApi.getNumericColumns();
    }
    const styledColumns = api.styledColumnApi.getStyledColumns();
    const usedColumnIds = styledColumns.map(styledColumn => {
      // filter out current styled column columnID
      if (styledColumn.Uuid === data.Uuid) {
        return null;
      }
      return styledColumn.ColumnId;
    }).filter(Boolean);
    return sortableColumns.filter(column => {
      return usedColumnIds.every(usedColumnId => {
        return column.columnId !== usedColumnId;
      });
    });
  }, [data]);
  const handleColumnsChange = columnIds => {
    const columnId = columnIds[0];
    const emptyRanges = api.columnScopeApi.createCellColorRangesForScope({
      ColumnIds: [columnId]
    });
    const newStyledColumn = Object.assign(Object.assign({}, data), {
      ColumnId: columnId
    });
    if (newStyledColumn.PercentBarStyle) {
      newStyledColumn.PercentBarStyle = Object.assign(Object.assign({}, newStyledColumn.PercentBarStyle), {
        CellRanges: emptyRanges
      });
    }
    if (newStyledColumn.GradientStyle) {
      newStyledColumn.GradientStyle = Object.assign(Object.assign({}, newStyledColumn.GradientStyle), {
        CellRanges: emptyRanges
      });
    }
    props.onChange(newStyledColumn);
  };
  return React.createElement(Tabs, {
    style: {
      height: '100%'
    }
  }, React.createElement(Tabs.Tab, null, "Column"), React.createElement(Tabs.Content, null, React.createElement(NewColumnSelector, {
    availableColumns: sortableCols,
    selected: data.ColumnId ? [data.ColumnId] : [],
    singleSelect: true,
    onChange: handleColumnsChange,
    allowReorder: false
  })));
};