import * as React from 'react';
import { useState } from 'react';
import { BaseEditorInput } from './BaseEditorInput';
import { Flex, Text } from 'rebass';
import { editorButtonsSearch } from './editorButtonsSearch';
import useProperty from '../utils/useProperty';
import { CapitaliseFirstLetter, IsNotNullOrEmptyOrWhiteSpace, IsNullOrEmptyOrWhiteSpace } from '../../Utilities/Extensions/StringExtensions';
import { editorButtonsObservable } from './editorButtonsObservable';
import { CheckBox } from '../CheckBox';
import { useExpressionEditor } from './EditorContext';
import { editorButtonsAggregatedBoolean } from './editorButtonsAggregatedBoolean';
function EditorInputWithWhereClause(props) {
  const moduleExpressionFunctions = props.api.internalApi.getQueryLanguageService().getModuleExpressionFunctionsMap(props.module);
  const reactiveExpressionFns = props.type === 'observable' ? moduleExpressionFunctions.observableFunctions : moduleExpressionFunctions.aggregatedBooleanFunctions;
  const whereClauseExpressionsFns = Object.assign(Object.assign({}, moduleExpressionFunctions.booleanFunctions), moduleExpressionFunctions.scalarFunctions);
  const [value, setValue] = useProperty(props, 'value', '', {
    onChange: props.onChange
  });
  const [reactiveValue, whereClauseValue] = value.split(' WHERE ');
  const updateReactiveValue = value => {
    let fullExpressionString = value;
    if (IsNotNullOrEmptyOrWhiteSpace(value) && IsNotNullOrEmptyOrWhiteSpace(whereClauseValue)) {
      fullExpressionString = `${value} WHERE ${whereClauseValue}`;
    }
    if (IsNullOrEmptyOrWhiteSpace(value)) {
      setShowWhereClause(false);
    }
    setValue(fullExpressionString);
  };
  const updateWhereClauseValue = value => {
    if (IsNullOrEmptyOrWhiteSpace(value)) {
      setValue(reactiveValue);
    } else {
      setValue(`${reactiveValue} WHERE ${value}`);
    }
  };
  const {
    setSelectedFunction
  } = useExpressionEditor();
  const [showWhereClause, setShowWhereClause] = useState(IsNotNullOrEmptyOrWhiteSpace(whereClauseValue));
  const queryName = `${CapitaliseFirstLetter(props.type)}Query`;
  return React.createElement(Flex, {
    flexDirection: "column",
    alignItems: "start"
  }, React.createElement(BaseEditorInput, {
    type: 'main',
    value: reactiveValue || '',
    placeholder: `Create ${queryName}`,
    onChange: updateReactiveValue,
    onSelectedFunctionChange: setSelectedFunction,
    expressionFunctions: reactiveExpressionFns,
    editorButtons: props.type === 'observable' ? editorButtonsObservable : editorButtonsAggregatedBoolean,
    testData: props.testData,
    isFullExpression: true,
    hideResultPreview: true,
    api: props.api,
    style: {
      height: '75px'
    }
  }), React.createElement(Text, {
    fontSize: 2,
    marginTop: 2,
    pl: 3
  }, "Add a ", React.createElement("i", null, "WHERE"), " Clause to narrow the scope of the Query"), React.createElement(CheckBox, {
    disabled: IsNullOrEmptyOrWhiteSpace(reactiveValue),
    checked: showWhereClause,
    style: {
      alignItems: 'center'
    },
    onChange: checked => {
      setShowWhereClause(checked);
      if (!checked) {
        // reset whereClause value if textarea is hidden
        updateWhereClauseValue(null);
      }
    },
    pl: 3
  }, "WHERE"), showWhereClause && React.createElement(BaseEditorInput, {
    type: 'secondary',
    disabled: IsNullOrEmptyOrWhiteSpace(reactiveValue),
    value: whereClauseValue || '',
    placeholder: `Create BooleanQuery which narrows down the scope of the ${queryName}`,
    onChange: updateWhereClauseValue,
    onSelectedFunctionChange: setSelectedFunction,
    expressionFunctions: whereClauseExpressionsFns,
    editorButtons: editorButtonsSearch,
    testData: props.testData,
    isFullExpression: true,
    hideResultPreview: true,
    api: props.api,
    style: {
      height: '75px'
    }
  }));
}
export default EditorInputWithWhereClause;