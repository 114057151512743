import * as React from 'react';
import { readJSONFile } from '../../../components/FileDroppable';
import SimpleButton from '../../../components/SimpleButton';
export const LoadButton = props => {
  const onLoadPredefinedConfig = e => {
    const nativeEvent = e.nativeEvent;
    let files;
    if (nativeEvent && nativeEvent.dataTransfer) {
      files = nativeEvent.dataTransfer.files;
    } else {
      files = e.target.files;
    }
    const file = files[0];
    readJSONFile(file).then(json => {
      props.onLoad(json);
    });
  };
  return React.createElement(SimpleButton, Object.assign({}, props, {
    className: "ab-StateManagement__Load-Button"
  }), React.createElement("input", {
    type: "file",
    onChange: json => onLoadPredefinedConfig(json),
    accept: '.json',
    style: {
      opacity: 0,
      position: 'absolute',
      cursor: 'pointer',
      fontSize: 0,
      lineHeight: 0,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1
    }
  }), ' ', props.children);
};