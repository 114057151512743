import * as React from 'react';
import { connect } from 'react-redux';
import { ProgressIndicator } from '../components/ProgressIndicator/ProgressIndicator';
import { ToastContainer } from '../components/Toastify';
import * as PopupRedux from '../Redux/ActionsReducers/PopupRedux';
import { AdaptablePopup } from './Components/Popups/AdaptablePopup';
import { AdaptablePopupConfirmation } from './Components/Popups/AdaptablePopupConfirmation';
import { AdaptablePopupPrompt } from './Components/Popups/AdaptablePopupPrompt';
import { FormPopups } from './Components/Popups/FormPopups/FormPopups';
import { GridCellPopup } from './Components/Popups/GridCellPopup';
import { WindowPopups } from './Components/Popups/WindowPopups/WindowPopups';
import { Dashboard } from './Dashboard/Dashboard';
import { LicenseWatermark } from './License';
import { QuickSearchDrawer } from './QuickSearch/FloatingQuickSearch/FloatingQuickSearch';
import { renderWithAdaptableContext } from './renderWithAdaptableContext';
class AdaptableView extends React.Component {
  render() {
    var _a, _b, _c, _d;
    const watermark = (_d = (_c = (_b = (_a = this.props.AdaptableApi) === null || _a === void 0 ? void 0 : _a.internalApi) === null || _b === void 0 ? void 0 : _b.getSystemState()) === null || _c === void 0 ? void 0 : _c.License) === null || _d === void 0 ? void 0 : _d.watermark;
    const adaptableOptions = this.props.AdaptableApi.optionsApi.getAdaptableOptions();
    return React.createElement("div", null, React.createElement(GridCellPopup, null), React.createElement(QuickSearchDrawer, null), this.props.AdaptableApi.internalApi.getModuleService().isModuleAvailable('Dashboard') && React.createElement(Dashboard, {
      api: this.props.AdaptableApi
    }), this.props.PopupState.PromptPopup.ShowPromptPopup && React.createElement(AdaptablePopupPrompt, {
      message: this.props.PopupState.PromptPopup.Message,
      header: this.props.PopupState.PromptPopup.Header,
      onClose: this.props.onClosePromptPopup,
      onConfirm: this.props.onConfirmPromptPopup,
      onConfirmActionCreator: this.props.PopupState.PromptPopup.ConfirmActionCreator,
      defaultValue: this.props.PopupState.PromptPopup.DefaultValue
    }), Boolean(this.props.PopupState.ConfirmationPopup.ShowConfirmationPopup) && React.createElement(AdaptablePopupConfirmation, {
      header: this.props.PopupState.ConfirmationPopup.Header,
      messsage: this.props.PopupState.ConfirmationPopup.Msg,
      showPopup: this.props.PopupState.ConfirmationPopup.ShowConfirmationPopup,
      cancelButtonText: this.props.PopupState.ConfirmationPopup.CancelButtonText,
      confirmButtonText: this.props.PopupState.ConfirmationPopup.ConfirmButtonText,
      onCancel: this.props.onCancelConfirmationPopup,
      onConfirm: this.props.onConfirmConfirmationPopup,
      showInputBox: this.props.PopupState.ConfirmationPopup.ShowInputBox,
      messageType: this.props.PopupState.ConfirmationPopup.MessageType,
      api: this.props.AdaptableApi
    }), Boolean(this.props.PopupState.ScreenPopup.ShowScreenPopup) && React.createElement(AdaptablePopup, {
      componentName: this.props.PopupState.ScreenPopup.ComponentName,
      componentModule: this.props.PopupState.ScreenPopup.ComponentModule,
      onHide: this.props.onCloseScreenPopup,
      api: this.props.AdaptableApi,
      onClearParams: () => this.props.onClearPopupParams(),
      moduleParams: this.props.PopupState.ScreenPopup.Params,
      moduleProps: this.props.PopupState.ScreenPopup.PopupProps
    }), React.createElement(ToastContainer
    // we NEEEEED!!!! this containerId, otherwise, toastify will have memory leaks when the adaptable
    // instance is destroyed and re-created many times
    , {
      // we NEEEEED!!!! this containerId, otherwise, toastify will have memory leaks when the adaptable
      // instance is destroyed and re-created many times
      containerId: `Toastify-${adaptableOptions.adaptableId}`,
      limit: adaptableOptions.notificationsOptions.maxNotifications,
      closeButton: false,
      icon: false,
      theme: "colored"
    }), (watermark === null || watermark === void 0 ? void 0 : watermark.show) && React.createElement(LicenseWatermark, null, watermark === null || watermark === void 0 ? void 0 : watermark.text), React.createElement(WindowPopups, null), React.createElement(FormPopups, null), React.createElement(ProgressIndicator, null));
  }
}
function mapStateToProps(state, ownProps) {
  return {
    PopupState: state.Popup,
    SystemState: state.System,
    AdaptableApi: ownProps.Adaptable.api
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onCloseScreenPopup: () => dispatch(PopupRedux.PopupHideScreen()),
    onClosePromptPopup: () => dispatch(PopupRedux.PopupHidePrompt()),
    onConfirmPromptPopup: inputText => dispatch(PopupRedux.PopupConfirmPrompt(inputText)),
    onConfirmConfirmationPopup: comment => dispatch(PopupRedux.PopupConfirmConfirmation(comment)),
    onCancelConfirmationPopup: () => dispatch(PopupRedux.PopupCancelConfirmation()),
    showPopup: (componentModule, componentName, params) => dispatch(PopupRedux.PopupShowScreen(componentModule, componentName, params)),
    onClearPopupParams: () => dispatch(PopupRedux.PopupClearParam())
  };
}
let AdaptableWrapper = connect(mapStateToProps, mapDispatchToProps)(AdaptableView);
export const AdaptableApp = ({
  Adaptable
}) => renderWithAdaptableContext(React.createElement(AdaptableWrapper, {
  Adaptable: Adaptable
}), Adaptable);