export const ContextConfiguration = {
  'fdc3.action': {
    label: 'Action',
    icon: 'fdc3'
  },
  'fdc3.chart': {
    label: 'Chart',
    icon: 'pie-chart'
  },
  'fdc3.chat.initSettings': {
    label: 'ChatInitSettings',
    icon: 'chat'
  },
  'fdc3.chat.message': {
    label: 'ChatMessage',
    icon: 'chat'
  },
  'fdc3.chat.room': {
    label: 'ChatRoom',
    icon: 'chat'
  },
  'fdc3.chat.searchCriteria': {
    label: 'ChatSearchCriteria',
    icon: 'chat'
  },
  'fdc3.contact': {
    label: 'Contact',
    icon: 'badge'
  },
  'fdc3.contactList': {
    label: 'ContactList',
    icon: 'badge'
  },
  'fdc3.country': {
    label: 'Country',
    icon: 'flag'
  },
  'fdc3.currency': {
    label: 'Currency',
    icon: 'dollar'
  },
  'fdc3.email': {
    label: 'Email',
    icon: 'mail'
  },
  'fdc3.instrument': {
    label: 'Instrument',
    icon: 'money'
  },
  'fdc3.instrumentList': {
    label: 'InstrumentList',
    icon: 'money'
  },
  'fdc3.interaction': {
    label: 'Interaction',
    icon: 'sync'
  },
  'fdc3.message': {
    label: 'Message',
    icon: 'mail'
  },
  'fdc3.organization': {
    label: 'Organization',
    icon: 'organisation'
  },
  'fdc3.portfolio': {
    label: 'Portfolio',
    icon: 'building'
  },
  'fdc3.position': {
    label: 'Position',
    icon: 'building'
  },
  'fdc3.timerange': {
    label: 'TimeRange',
    icon: 'date-range'
  },
  'fdc3.transactionResult': {
    label: 'TransactionResult',
    icon: 'fdc3'
  },
  'fdc3.valuation': {
    label: 'Valuation',
    icon: 'equation'
  },
  'fdc3.nothing': {
    label: 'Nothing',
    icon: 'fdc3'
  }
};