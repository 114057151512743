export const isAdaptableRuleValid = (abObject, api, context) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
  if ((_b = (_a = abObject === null || abObject === void 0 ? void 0 : abObject.Rule) === null || _a === void 0 ? void 0 : _a.Predicates) === null || _b === void 0 ? void 0 : _b.length) {
    if (!api.predicateApi.isEveryPredicateValid((_c = abObject === null || abObject === void 0 ? void 0 : abObject.Rule) === null || _c === void 0 ? void 0 : _c.Predicates)) {
      return `The Predicate${((_e = (_d = abObject === null || abObject === void 0 ? void 0 : abObject.Rule) === null || _d === void 0 ? void 0 : _d.Predicates) === null || _e === void 0 ? void 0 : _e.length) === 1 ? ' is' : 's are'} not valid`;
    }
  }
  if ((_f = abObject === null || abObject === void 0 ? void 0 : abObject.Rule) === null || _f === void 0 ? void 0 : _f.BooleanExpression) {
    if (!api.expressionApi.isValidBooleanExpression((_g = abObject === null || abObject === void 0 ? void 0 : abObject.Rule) === null || _g === void 0 ? void 0 : _g.BooleanExpression, context.moduleInfo.ModuleName)) {
      return 'The Expression is not a valid Boolean Expression';
    }
  }
  if ((_h = abObject === null || abObject === void 0 ? void 0 : abObject.Rule) === null || _h === void 0 ? void 0 : _h.ObservableExpression) {
    if (!api.expressionApi.isValidObservableExpression((_j = abObject === null || abObject === void 0 ? void 0 : abObject.Rule) === null || _j === void 0 ? void 0 : _j.ObservableExpression, context.moduleInfo.ModuleName)) {
      return 'The Expression is not a valid Observable Expression';
    }
  }
  if ((_k = abObject === null || abObject === void 0 ? void 0 : abObject.Rule) === null || _k === void 0 ? void 0 : _k.AggregatedBooleanExpression) {
    if (!api.expressionApi.isValidAggregatedBooleanExpression((_l = abObject === null || abObject === void 0 ? void 0 : abObject.Rule) === null || _l === void 0 ? void 0 : _l.AggregatedBooleanExpression, context.moduleInfo.ModuleName)) {
      return 'The Expression is not a valid Aggregated Boolean Expression';
    }
  }
  if ((_o = (_m = abObject === null || abObject === void 0 ? void 0 : abObject.Rule) === null || _m === void 0 ? void 0 : _m.Predicates) === null || _o === void 0 ? void 0 : _o.length) {
    const isAPredicateWithInvalidColumnId = (_q = (_p = abObject === null || abObject === void 0 ? void 0 : abObject.Rule) === null || _p === void 0 ? void 0 : _p.Predicates) === null || _q === void 0 ? void 0 : _q.some(predicate => predicate.ColumnId !== undefined && predicate.ColumnId === '');
    if (isAPredicateWithInvalidColumnId) {
      return 'Predicates with custom scope need a valid column';
    }
  }
  return true;
};