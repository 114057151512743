import * as React from 'react';
import { useSelector } from 'react-redux';
import { Select } from '../../components/Select';
import SimpleButton from '../../components/SimpleButton';
import { isAgChartDefinition } from '../../PredefinedConfig/ChartingState';
import * as ChartingRedux from '../../Redux/ActionsReducers/ChartingRedux';
import AdaptableHelper from '../../Utilities/Helpers/AdaptableHelper';
import { useAdaptable } from '../AdaptableContext';
import { DeleteChartButton } from './DeleteChartButton';
import { EditChartButton } from './EditChartButton';
import { useAgChartState } from './useAgChartState';
import { useExternalChartState } from './useExternalChartState';
export const useChartingElements = ({
  elementType,
  accessLevel,
  size = 'normal'
}) => {
  var _a;
  const adaptable = useAdaptable();
  const chartDefinitions = useSelector(ChartingRedux.ChartingGetChartModels);
  const extenralChartDefinitions = useSelector(ChartingRedux.ChartingGetExternalChartDefinitions);
  const allChartDefinitions = [...chartDefinitions, ...extenralChartDefinitions];
  const chartingOptions = (_a = adaptable.adaptableOptions) === null || _a === void 0 ? void 0 : _a.chartingOptions;
  const chartContainers = chartingOptions === null || chartingOptions === void 0 ? void 0 : chartingOptions.chartContainers;
  const [selectedChartId, setSelectedChartId] = React.useState(() => {
    // ID is kept in state becaus that does not change, the definition might change
    return allChartDefinitions.length > 0 ? allChartDefinitions[0].Uuid : null;
  });
  const selectedChart = allChartDefinitions.find(chart => chart.Uuid === selectedChartId);
  const isAgChart = isAgChartDefinition(selectedChart);
  const chartAccessLevel = AdaptableHelper.getAccessLevelForObject(selectedChart, accessLevel);
  const [selectedContainer, setSelectedContainer] = React.useState(null);
  // Need to always call as they the rule for hooks
  const {
    isOpen: isAgChartOpen,
    showChart: showAgChart,
    closeChart: hideAgChart
  } = useAgChartState(isAgChart ? selectedChart : undefined);
  // Need to always call as they the rule for hooks
  const {
    isOpen: isExternalChartOpened,
    showChart: showExternalChart,
    hideChart: hideExternalChart
  } = useExternalChartState(!isAgChart ? selectedChart : undefined);
  const isOpen = isAgChart ? isAgChartOpen : isExternalChartOpened;
  const showChart = isAgChart ? showAgChart : showExternalChart;
  const hideChart = isAgChart ? hideAgChart : hideExternalChart;
  const options = allChartDefinitions.map(chartDefinition => ({
    label: chartDefinition.Name,
    value: chartDefinition.Uuid,
    onClick: () => {
      setSelectedChartId(chartDefinition.Uuid);
    }
  }));
  const hasCharts = options.length > 0;
  const isSelectedChart = hasCharts && selectedChart != null;
  let selectedChartValue = '';
  if (hasCharts && selectedChart) {
    selectedChartValue = selectedChart.Name;
  } else if (hasCharts) {
    selectedChartValue = 'Select Chart';
  } else {
    selectedChartValue = 'No Charts';
  }
  React.useEffect(() => {
    // select first
    if (!selectedChart && chartDefinitions.length > 0) {
      setSelectedChartId(chartDefinitions[0].Uuid);
    }
  }, [chartDefinitions]);
  let containerOptions = chartContainers ? chartContainers === null || chartContainers === void 0 ? void 0 : chartContainers.map(contianerDef => ({
    label: contianerDef.name,
    value: contianerDef.name
  })) : [];
  if (isAgChartDefinition(selectedChart)) {
    containerOptions.unshift({
      label: chartingOptions.agGridContainerName,
      value: chartingOptions.agGridContainerName
    });
  }
  const style = {};
  let iconSize;
  if (size === 'small') {
    style.padding = 2;
    style.fontSize = 'small';
    iconSize = 15;
  }
  const chartSelector = React.createElement(Select, {
    className: `ab-${elementType}__Chart__select`,
    size: size,
    value: selectedChartId,
    options: options,
    placeholder: 'Select Chart',
    disabled: !hasCharts,
    onChange: chartUuid => setSelectedChartId(chartUuid)
  });
  const containerSelector = Boolean(chartContainers === null || chartContainers === void 0 ? void 0 : chartContainers.length) && React.createElement(Select, {
    value: selectedContainer === null ? chartingOptions.agGridContainerName : selectedContainer === null || selectedContainer === void 0 ? void 0 : selectedContainer.name,
    size: size,
    onChange: containerName => {
      if (containerName === chartingOptions.agGridContainerName) {
        setSelectedContainer(null);
      } else {
        setSelectedContainer(chartContainers.find(containerOption => containerOption.name === containerName));
      }
    },
    options: containerOptions,
    placeholder: "Select Container"
  });
  const chartButton = React.createElement(SimpleButton, {
    style: style,
    mr: 1,
    onClick: () => isOpen ? hideChart() : showChart(selectedContainer),
    disabled: !Boolean(selectedChart),
    variant: 'text',
    tone: 'neutral',
    icon: isOpen ? 'visibility-off' : 'visibility-on',
    tooltip: isOpen ? 'Hide Chart' : 'Show Chart'
  });
  const deleteButton = React.createElement(DeleteChartButton, {
    iconSize: iconSize,
    chart: selectedChart,
    accessLevel: chartAccessLevel
  });
  const editButton = React.createElement(EditChartButton, {
    iconSize: iconSize,
    chart: selectedChart,
    accessLevel: chartAccessLevel,
    isOpen: isOpen
  });
  return {
    chartSelector,
    containerSelector,
    chartButton,
    deleteButton,
    editButton,
    selectedChart
  };
};