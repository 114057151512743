import { __rest } from "tslib";
import * as React from 'react';
import OverlayTrigger from '../OverlayTrigger';
import { useState } from 'react';
import useProperty from '../utils/useProperty';
import FieldWrap from '../FieldWrap';
import SimpleButton from '../SimpleButton';
import { Flex } from 'rebass';
import { DateFormatter } from '../../Utilities/Helpers/FormatHelper';
import { Caption } from './Caption';
import { CaptionLabel } from './CaptionLabel';
import { useDatepickerContext } from './DatepickerContext';
import addDays from 'date-fns/addDays';
import addBusinessDays from 'date-fns/addBusinessDays';
import { DayPicker, useInput } from 'react-day-picker';
import { AdaptableDateInlineInput } from '../../View/Components/AdaptableInput/AdaptableDateInlineInput';
import { isValid } from 'date-fns';
const DatepickerOverlay = ({
  onHide,
  children,
  onKeyDown,
  onMouseDown
}) => {
  const domRef = React.useRef(null);
  React.useEffect(() => {
    var _a;
    (_a = domRef.current) === null || _a === void 0 ? void 0 : _a.focus();
  }, []);
  return React.createElement("div", {
    className: "ab-Datepicker-Overlay",
    ref: domRef,
    tabIndex: -1,
    onKeyDown: onKeyDown,
    onMouseDown: onMouseDown,
    onBlur: e => {
      var _a;
      const {
        relatedTarget
      } = e;
      const node = domRef.current;
      // relatedTarget is the newly focused element as a result of this blur event
      // so we close the overlay when the newly focused element is outside the overlay
      if (relatedTarget == null || !((_a = node.contains) === null || _a === void 0 ? void 0 : _a.call(node, relatedTarget))) {
        onHide();
      }
    }
  }, children);
};
export const Datepicker = React.forwardRef((props, ref) => {
  var _a;
  const {
      dateProps,
      required,
      disabled,
      style,
      placeholder,
      showWeekNumber,
      showOutsideDays,
      value: _,
      defaultValue: __,
      onChange: ___,
      datepickerButtons
    } = props,
    boxProps = __rest(props, ["dateProps", "required", "disabled", "style", "placeholder", "showWeekNumber", "showOutsideDays", "value", "defaultValue", "onChange", "datepickerButtons"]);
  const datepickerContext = useDatepickerContext();
  let [value, setValue] = useProperty(props, 'value', undefined, {
    onChange: props.onChange
  });
  const useInputOptions = Object.assign(Object.assign({}, dateProps), {
    required
  });
  if (value && value instanceof Date && !isNaN(+value)) {
    useInputOptions.defaultSelected = value;
  }
  const [month, setMonth] = useState(value !== null && value !== void 0 ? value : new Date());
  const {
    dayPickerProps,
    setSelected
  } = useInput(useInputOptions);
  const updateValue = value => {
    setValue(value);
    setSelected(value);
  };
  const inputValue = (_a = DateFormatter(value, {
    Pattern: dateProps.format
  })) !== null && _a !== void 0 ? _a : '';
  const [visible, doSetVisible] = useState(false);
  const setVisible = (visible, keyboardEventKey) => {
    var _a, _b;
    doSetVisible(visible);
    if (!visible) {
      (_a = datepickerContext === null || datepickerContext === void 0 ? void 0 : datepickerContext.onHide) === null || _a === void 0 ? void 0 : _a.call(datepickerContext, keyboardEventKey);
    } else {
      (_b = datepickerContext === null || datepickerContext === void 0 ? void 0 : datepickerContext.onShow) === null || _b === void 0 ? void 0 : _b.call(datepickerContext);
    }
  };
  const clearValue = () => {
    updateValue(undefined);
  };
  const renderButton = (label, onClick) => React.createElement(SimpleButton, {
    onClick: onClick,
    margin: '2px'
  }, label);
  const todayDate = new Date();
  const footerButtonsMap = {
    clear: renderButton('Clear', () => clearValue()),
    close: renderButton('Close', () => setVisible(false)),
    today: renderButton('Today', () => updateValue(todayDate)),
    tomorrow: renderButton('Tomorrow', () => updateValue(addDays(todayDate, 1))),
    yesterday: renderButton('Yesterday', () => updateValue(addDays(todayDate, -1))),
    nextWorkday: renderButton('Next Workday', () => updateValue(addBusinessDays(todayDate, 1))),
    prevWorkday: renderButton('Prev Workday', () => updateValue(addBusinessDays(todayDate, -1))),
    '-': React.createElement("div", {
      style: {
        flex: '1 1 1%'
      }
    }),
    '|': React.createElement("hr", {
      style: {
        width: '100%',
        height: 0,
        margin: 0,
        border: 'none'
      }
    })
  };
  const footerButtons = datepickerButtons.map((buttonKey, index) => React.cloneElement(footerButtonsMap[buttonKey], {
    key: index
  }));
  const clearButton = React.createElement(SimpleButton, {
    "data-name": "clear",
    variant: "text",
    tooltip: "Clear",
    iconSize: 20,
    padding: 0,
    icon: "close",
    onMouseDown: e => {
      e.preventDefault();
      clearValue();
    },
    accessLevel: 'Full'
  });
  const calendarButton = React.createElement(SimpleButton, {
    disabled: disabled,
    variant: "text",
    icon: "calendar",
    tooltip: "Date",
    iconSize: 20,
    px: 0,
    py: 0,
    onClick: () => setVisible(true)
  });
  return React.createElement(Flex, null, React.createElement(OverlayTrigger, {
    visible: visible,
    render: () => React.createElement(DatepickerOverlay, {
      onMouseDown: props.onMouseDown,
      onHide: () => setVisible(false),
      onKeyDown: e => {
        if (e.key === 'Escape' || e.key === 'Enter') {
          setVisible(false, e.key);
        }
      }
    }, React.createElement(DayPicker, Object.assign({
      fixedWeeks: true
    }, dayPickerProps, {
      showWeekNumber: showWeekNumber,
      showOutsideDays: showOutsideDays,
      mode: "single",
      month: isNaN(+month) ? new Date() : month,
      onMonthChange: setMonth,
      components: {
        Caption,
        CaptionLabel
      },
      onSelect: updateValue,
      footer: React.createElement(Flex, {
        justifyContent: "space-between",
        mt: 2,
        flexWrap: 'wrap'
      }, footerButtons)
    })))
  }, React.createElement(FieldWrap, Object.assign({}, boxProps, {
    style: {
      borderRadius: style === null || style === void 0 ? void 0 : style.borderRadius,
      width: style === null || style === void 0 ? void 0 : style.width,
      maxWidth: style === null || style === void 0 ? void 0 : style.maxWidth
    },
    className: "ab-Datepicker",
    onFocus: () => {
      if (!visible) {
        setVisible(true);
      }
    }
  }), React.createElement(AdaptableDateInlineInput, {
    ref: ref,
    value: inputValue,
    // We do not want to show the format when the date-picker is visible
    placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : '',
    onChange: value => {
      const date = new Date(value);
      if (isValid(date)) {
        updateValue(date);
      }
    },
    style: style,
    disabled: disabled
  }), !!inputValue ? clearButton : null, calendarButton)));
});