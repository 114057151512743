import * as DashboardRedux from '../../Redux/ActionsReducers/DashboardRedux';
import * as React from 'react';
import { CheckBox } from '../../components/CheckBox';
import { connect } from 'react-redux';
import { Flex } from 'rebass';
import ArrayExtensions from '../../Utilities/Extensions/ArrayExtensions';
class DashboardViewPanelComponent extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const elementType = this.props.viewType === 'Toolbar' ? 'DashboardToolbar' : 'ToolPanel';
    return React.createElement(Flex, {
      flexDirection: "row",
      className: `ab-${elementType}__Dashboard__wrap`,
      flexWrap: this.props.viewType === 'ToolPanel' ? 'wrap' : 'nowrap'
    }, ' ', ArrayExtensions.IsNotNullOrEmpty(this.props.api.dashboardApi.getDashboardState().Tabs) && React.createElement(CheckBox, {
      className: `ab-${elementType}__Dashboard__collapsed-check`,
      marginTop: 0,
      fontSize: 2,
      padding: 1,
      checked: this.props.IsCollapsed,
      onChange: checked => this.props.onSetDashboardCollapsed(checked)
    }, "Collapse"), '', this.props.api.optionsApi.getDashboardOptions().canFloat && React.createElement(CheckBox, {
      className: `ab-${elementType}__Dashboard__collapsed-check`,
      marginTop: 0,
      fontSize: 2,
      padding: 1,
      checked: this.props.IsFloating,
      onChange: checked => this.props.onSetDashboardFloating(checked)
    }, "Float"), ' ', React.createElement(CheckBox, {
      className: `ab-${elementType}__Dashboard__hidden-check`,
      marginTop: 0,
      fontSize: 2,
      padding: 1,
      checked: this.props.IsHidden,
      onChange: checked => this.props.onSetDashboardHidden(checked)
    }, "Hidden"));
  }
}
function mapStateToProps(state, ownProps) {
  return {
    IsCollapsed: state.Dashboard.IsCollapsed,
    IsHidden: state.Dashboard.IsHidden,
    IsFloating: state.Dashboard.IsFloating
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onSetDashboardCollapsed: isCollapsed => dispatch(DashboardRedux.DashboardSetIsCollapsed(isCollapsed)),
    onSetDashboardHidden: isHidden => dispatch(DashboardRedux.DashboardSetIsHidden(isHidden)),
    onSetDashboardFloating: isFloating => dispatch(DashboardRedux.DashboardSetIsFloating(isFloating))
  };
}
export let DashboardViewPanelControl = connect(mapStateToProps, mapDispatchToProps)(DashboardViewPanelComponent);