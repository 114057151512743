export const AlertModuleId = 'Alert';
export const AlertModuleFriendlyName = 'Alert';
export const FlashingCellModuleId = 'FlashingCell';
export const FlashingCellFriendlyName = 'Flashing Cell';
export const BulkUpdateModuleId = 'BulkUpdate';
export const BulkUpdateFriendlyName = 'Bulk Update';
export const CalculatedColumnModuleId = 'CalculatedColumn';
export const CalculatedColumnFriendlyName = 'Calculated Column';
export const CellSummaryModuleId = 'CellSummary';
export const CellSummaryFriendlyName = 'Cell Summary';
export const ChartingModuleId = 'Charting';
export const ChartingFriendlyName = 'Charts';
export const CustomSortModuleId = 'CustomSort';
export const CustomSortFriendlyName = 'Custom Sort';
export const DashboardModuleId = 'Dashboard';
export const DashboardFriendlyName = 'Dashboard';
export const DataChangeHistoryModuleId = 'DataChangeHistory';
export const DataChangeHistoryFriendlyName = 'Data Changes';
export const DataSetModuleId = 'DataSet';
export const DataSetFriendlyName = 'Data Set';
export const ExportModuleId = 'Export';
export const ExportFriendlyName = 'Export';
export const Fdc3ModuleId = 'Fdc3';
export const Fdc3FriendlyName = 'FDC3';
export const ColumnFilterModuleId = 'ColumnFilter';
export const ColumnFilterFriendlyName = 'Column Filter';
export const GridFilterModuleId = 'GridFilter';
export const GridFilterFriendlyName = 'Grid Filter';
export const NamedQueryModuleId = 'NamedQuery';
export const NamedQueryFriendlyName = 'Named Query';
export const FormatColumnModuleId = 'FormatColumn';
export const FormatColumnFriendlyName = 'Format Column';
export const FreeTextColumnModuleId = 'FreeTextColumn';
export const FreeTextColumnFriendlyName = 'Free Text Column';
export const GridInfoModuleId = 'GridInfo';
export const GridInfoFriendlyName = 'Grid Info';
export const ColumnInfoModuleId = 'ColumnInfo';
export const ColumnInfoFriendlyName = 'Column Info';
export const NoteModuleId = 'Note';
export const NoteFriendlyName = 'Note';
export const CommentModuleId = 'Comment';
export const CommentFriendlyName = 'Comment';
export const IPushPullModuleId = 'IPushPull';
export const IPushPullFriendlyName = 'IPushPull';
export const LayoutModuleId = 'Layout';
export const LayoutFriendlyName = 'Layout';
export const OpenFinModuleId = 'OpenFin';
export const OpenFinFriendlyName = 'OpenFin';
export const PlusMinusModuleId = 'PlusMinus';
export const PlusMinusFriendlyName = 'Plus Minus';
export const QuickSearchModuleId = 'QuickSearch';
export const QuickSearchFriendlyName = 'Quick Search';
export const ScheduleModuleId = 'Schedule';
export const ScheduleFriendlyName = 'Schedule';
export const ShortcutModuleId = 'Shortcut';
export const ShortcutFriendlyName = 'Shortcut';
export const SmartEditModuleId = 'SmartEdit';
export const SmartEditFriendlyName = 'Smart Edit';
export const StyledColumnModuleId = 'StyledColumn';
export const StyledColumnFriendlyName = 'Styled Column';
export const StateManagementModuleId = 'StateManagement';
export const StateManagementFriendlyName = 'Manage State';
export const SystemStatusModuleId = 'SystemStatus';
export const SystemStatusFriendlyName = 'System Status';
export const TeamSharingModuleId = 'TeamSharing';
export const TeamSharingFriendlyName = 'Team Sharing';
export const ThemeModuleId = 'Theme';
export const ThemeFriendlyName = 'Theme';
export const ToolPanelModuleId = 'ToolPanel';
export const ToolPanelFriendlyName = 'Tool Panel';
export const SettingsPanelModuleId = 'SettingsPanel';
export const SettingsPanelFriendlyName = 'Settings Panel';
export const StatusBarModuleId = 'StatusBar';
export const StatusBarFriendlyName = 'Status Bar';
export const DataImportModuleId = 'DataImport';
export const DataImportFriendyName = 'Data Import';
export const ADAPTABLE_MODULE_MAP = {
  [AlertModuleId]: AlertModuleFriendlyName,
  [FlashingCellModuleId]: FlashingCellFriendlyName,
  [BulkUpdateModuleId]: BulkUpdateFriendlyName,
  [CalculatedColumnModuleId]: CalculatedColumnFriendlyName,
  [CellSummaryModuleId]: CellSummaryFriendlyName,
  [ChartingModuleId]: ChartingFriendlyName,
  [CustomSortModuleId]: CustomSortFriendlyName,
  [DashboardModuleId]: DashboardFriendlyName,
  [DataChangeHistoryModuleId]: DataChangeHistoryFriendlyName,
  [DataSetModuleId]: DataSetFriendlyName,
  [ExportModuleId]: ExportFriendlyName,
  [Fdc3ModuleId]: Fdc3FriendlyName,
  [ColumnFilterModuleId]: ColumnFilterFriendlyName,
  [FormatColumnModuleId]: FormatColumnFriendlyName,
  [FreeTextColumnModuleId]: FreeTextColumnFriendlyName,
  [GridInfoModuleId]: GridInfoFriendlyName,
  [IPushPullModuleId]: IPushPullFriendlyName,
  [LayoutModuleId]: LayoutFriendlyName,
  [OpenFinModuleId]: OpenFinFriendlyName,
  [PlusMinusModuleId]: PlusMinusFriendlyName,
  [QuickSearchModuleId]: QuickSearchFriendlyName,
  [ScheduleModuleId]: ScheduleFriendlyName,
  [ShortcutModuleId]: ShortcutFriendlyName,
  [SmartEditModuleId]: SmartEditFriendlyName,
  [StyledColumnModuleId]: StyledColumnFriendlyName,
  [StateManagementModuleId]: StateManagementFriendlyName,
  [SystemStatusModuleId]: SystemStatusFriendlyName,
  [TeamSharingModuleId]: TeamSharingFriendlyName,
  [ThemeModuleId]: ThemeFriendlyName,
  [ToolPanelModuleId]: ToolPanelFriendlyName,
  [SettingsPanelModuleId]: SettingsPanelFriendlyName,
  [StatusBarModuleId]: StatusBarFriendlyName,
  [NoteModuleId]: NoteFriendlyName,
  [ColumnInfoModuleId]: ColumnInfoFriendlyName,
  [DataImportModuleId]: DataImportFriendyName,
  [NamedQueryModuleId]: NamedQueryFriendlyName,
  [GridFilterModuleId]: GridFilterFriendlyName,
  [CommentModuleId]: CommentFriendlyName,
  // Remove next version
  ['Query']: 'Query',
  ['Filter']: 'Filter'
};