import { AdaptableModuleBase } from './AdaptableModuleBase';
import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
import { PreviewHelper } from '../Utilities/Helpers/PreviewHelper';
import StringExtensions from '../Utilities/Extensions/StringExtensions';
import ArrayExtensions from '../Utilities/Extensions/ArrayExtensions';
import ObjectFactory from '../Utilities/ObjectFactory';
export class BulkUpdateModule extends AdaptableModuleBase {
  constructor(api) {
    super(ModuleConstants.BulkUpdateModuleId, ModuleConstants.BulkUpdateFriendlyName, 'edit-table', 'BulkUpdatePopup', 'Update multiple cell simultaneously with a new or existing value', api);
  }
  getViewAccessLevel() {
    return 'Full';
  }
  createContextMenuItems(menuContext) {
    let menuItemShowPopup = undefined;
    if (!menuContext.isRowGroupColumn && this.isModuleEditable()) {
      if (menuContext.adaptableColumn && !menuContext.adaptableColumn.readOnly && menuContext.isSelectedCell && menuContext.isSingleSelectedColumn && this.api.gridApi.isEveryCellEditable(menuContext.selectedCellInfo.gridCells)) {
        let popUpParams = {
          source: 'ContextMenu'
        };
        menuItemShowPopup = this.createMainMenuItemShowPopup({
          Name: 'bulk-update-apply',
          Label: 'Apply Bulk Update',
          ComponentName: this.moduleInfo.Popup,
          Icon: this.moduleInfo.Glyph,
          PopupParams: popUpParams
        });
      }
    }
    return [menuItemShowPopup];
  }
  checkCorrectCellSelection() {
    let selectedCellInfo = this.api.gridApi.getSelectedCellInfo();
    if (this.api.internalApi.isGridInPivotMode()) {
      return {
        IsValid: false,
        Alert: {
          alertType: 'generic',
          header: 'Bulk Update Error',
          message: 'Cannot edit while Grid is in Pivot Mode.',
          alertDefinition: ObjectFactory.CreateInternalAlertDefinitionForMessages('Error')
        }
      };
    }
    if (selectedCellInfo == null || ArrayExtensions.IsNullOrEmpty(selectedCellInfo.columns)) {
      return {
        IsValid: false,
        Alert: {
          alertType: 'generic',
          header: 'Bulk Update Error',
          message: 'No cells are selected.\nPlease select some cells.',
          alertDefinition: ObjectFactory.CreateInternalAlertDefinitionForMessages('Error')
        }
      };
    }
    if (ArrayExtensions.NotCorrectLength(selectedCellInfo.columns, 1)) {
      return {
        IsValid: false,
        Alert: {
          alertType: 'generic',
          header: 'Bulk Update Error',
          message: 'Bulk Update only supports single column edit.\nPlease adjust cell selection.',
          alertDefinition: ObjectFactory.CreateInternalAlertDefinitionForMessages('Error')
        }
      };
    }
    let selectedColumn = selectedCellInfo.columns[0];
    if (selectedColumn && selectedColumn.readOnly) {
      return {
        IsValid: false,
        Alert: {
          alertType: 'generic',
          header: 'Bulk Update Error',
          message: 'Bulk Update is not permitted on readonly columns.\nPlease adjust the cell selection.',
          alertDefinition: ObjectFactory.CreateInternalAlertDefinitionForMessages('Error')
        }
      };
    }
    if (ArrayExtensions.IsNotNullOrEmpty(selectedCellInfo.gridCells) && !this.api.gridApi.isEveryCellEditable(selectedCellInfo.gridCells)) {
      return {
        IsValid: false,
        Alert: {
          alertType: 'generic',
          header: 'Bulk Update Error',
          message: 'Bulk Update is not permitted on readonly cells.\nPlease adjust the cell selection.',
          alertDefinition: ObjectFactory.CreateInternalAlertDefinitionForMessages('Error')
        }
      };
    }
    return {
      IsValid: true,
      Column: selectedColumn
    };
  }
  buildPreviewValues(bulkUpdateValue) {
    let previewResults = [];
    if (StringExtensions.IsNullOrEmpty(String(bulkUpdateValue))) {
      return null;
    }
    let selectedCellInfo = this.api.gridApi.getSelectedCellInfo();
    let column;
    if (!this.api.internalApi.isGridInPivotMode()) {
      if (selectedCellInfo != null && selectedCellInfo.columns.length > 0) {
        column = this.api.columnApi.getColumnWithColumnId(selectedCellInfo.columns[0].columnId);
        let typedBulkUpdateValue;
        switch (column.dataType) {
          case 'Number':
            typedBulkUpdateValue = Number(bulkUpdateValue);
            break;
          case 'String':
            typedBulkUpdateValue = bulkUpdateValue;
            break;
          case 'Date':
            typedBulkUpdateValue = new Date(bulkUpdateValue);
            break;
        }
        selectedCellInfo.gridCells.forEach(selectedCell => {
          const cellDataChangedInfo = this.api.internalApi.buildDataChangedInfo({
            oldValue: selectedCell.rawValue,
            newValue: typedBulkUpdateValue,
            column: selectedCell.column,
            primaryKeyValue: selectedCell.primaryKeyValue,
            rowNode: selectedCell.rowNode,
            trigger: 'edit'
          });
          const validationRules = this.api.internalApi.getValidationService().getValidationRulesForDataChange(cellDataChangedInfo);
          const previewResult = {
            id: selectedCell.primaryKeyValue,
            initialValue: selectedCell.rawValue,
            computedValue: typedBulkUpdateValue,
            validationRules: validationRules,
            rowNode: selectedCell.rowNode
          };
          previewResults.push(previewResult);
        });
      }
    }
    return {
      column: column,
      previewResults: previewResults,
      previewValidationSummary: PreviewHelper.GetPreviewValidationSummary(previewResults)
    };
  }
}