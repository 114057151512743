import * as GeneralConstants from '../Utilities/Constants/GeneralConstants';
import * as FilterConstants from '../Utilities/Constants/FilterConstants';
import UIHelper, { getHexForName, GRAY, GREEN, RED } from '../View/UIHelper';
import { EMPTY_ARRAY } from '../Utilities/Constants/GeneralConstants';
import { ExportDestination } from '../PredefinedConfig/Common/Enums';
import { SystemAlertPredicateIds, SystemBadgeStylePredicateIds, SystemFilterPredicateIds, SystemFlashingCellPredicateIds, SystemFormatColumnPredicateIds } from '../PredefinedConfig/Common/AdaptablePredicate';
import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
import { initPredefinedConfigWithUuids } from '../Utilities/Helpers/AdaptableHelper';
/**
 * Default Adaptable Options values used if not set by the user
 * Try to avoid any hard coded strings or numbers - use General (or other) Constants files
 */
const DefaultAdaptableOptions = {
  adaptableId: GeneralConstants.ADAPTABLE_ID,
  adaptableStateKey: undefined,
  primaryKey: '',
  autogeneratePrimaryKey: false,
  userName: GeneralConstants.USER_NAME,
  predefinedConfig: undefined,
  alertOptions: {
    alertForms: undefined,
    maxAlertsInStore: 20,
    cellHighlightDuration: 2000,
    rowHighlightDuration: 4000,
    statusbarHighlightDuration: 2000,
    dataChangeDetectionPolicy: GeneralConstants.RAW_VALUE_DATA_CHANGE_DETECTION_POLICY,
    actionHandlers: undefined,
    alertMessageText: undefined,
    showMissingPrimaryKeyAlert: false
  },
  notificationsOptions: {
    maxNotifications: 3,
    position: GeneralConstants.TOAST_POSITIONS_BOTTOM_RIGHT,
    duration: GeneralConstants.NOTIFICATION_DURATION,
    showProgressBar: false,
    closeWhenClicked: false,
    pauseWhenHovering: false,
    isDraggable: false,
    transition: GeneralConstants.TOAST_TRANSITIONS_SLIDE,
    showApplicationIcon: false,
    showSystemStatusMessageNotifications: false,
    maxSystemMessagesInStore: GeneralConstants.NOTIFICATION_MAX_SYSTEM_MESSAGES
  },
  dataImportOptions: {
    fileHandlers: undefined
  },
  flashingCellOptions: {
    defaultFlashDuration: 500,
    defaultFlashTarget: GeneralConstants.FLASHING_TARGET_CELL,
    defaultDownChangeStyle: {
      BackColor: getHexForName(RED)
    },
    defaultUpChangeStyle: {
      BackColor: getHexForName(GREEN)
    },
    defaultNeutralChangeStyle: {
      BackColor: getHexForName(GRAY)
    }
  },
  expressionOptions: {
    evaluateAdaptableQLExternally: undefined,
    displayColumnFriendlyNamesForExpressions: true,
    systemBooleanFunctions: undefined,
    systemScalarFunctions: undefined,
    systemObservableFunctions: undefined,
    systemAggregatedBooleanFunctions: undefined,
    systemAggregatedScalarFunctions: undefined,
    moduleExpressionFunctions: undefined,
    performExpressionValidation: true,
    maxTimeframeSize: 28800000,
    caseSensitiveExpressions: false
  },
  calendarOptions: {
    holidays: undefined
  },
  cellSummaryOptions: {
    cellSummaryOperations: undefined
  },
  containerOptions: {
    adaptableContainer: GeneralConstants.ADAPTABLE_CONTAINER_ID,
    agGridContainer: GeneralConstants.AG_GRID_CONTAINER_ID,
    agGridContainerWaitTimeout: 60000,
    modalContainer: undefined,
    systemStatusContainer: undefined,
    alertContainer: undefined
  },
  columnOptions: {
    columnFriendlyName: undefined,
    columnTypes: EMPTY_ARRAY,
    showMissingColumnsWarning: true,
    addColumnGroupToColumnFriendlyName: false
  },
  dashboardOptions: {
    canFloat: true,
    showQuickSearchInHeader: true,
    customDashboardButtons: undefined,
    customToolbars: undefined,
    buttonsLocation: GeneralConstants.DASHBOARD_BUTTONS_LOCATION_RIGHT
  },
  actionColumnOptions: {
    actionColumns: undefined
  },
  actionRowOptions: {
    actionRowButtons: undefined,
    autoHandle: true,
    disableInlineEditing: false,
    setPrimaryKeyValue: undefined,
    actionRowButtonOptions: {
      customConfiguration: undefined,
      position: 'pinnedLeft'
    },
    actionRowFormOptions: {
      formTitle: undefined,
      formDescription: undefined,
      formFieldLabel: undefined,
      formButtons: undefined,
      onFormSubmit: undefined
    }
  },
  editOptions: {
    validateOnServer: undefined,
    displayServerValidationMessages: true,
    isCellEditable: undefined,
    smartEditCustomOperations: undefined
  },
  entitlementOptions: {
    defaultAccessLevel: GeneralConstants.ACCESS_LEVEL_FULL,
    moduleEntitlements: undefined
  },
  exportOptions: {
    exportFormatType: 'rawValue',
    exportDateFormat: undefined,
    customDestinations: undefined,
    externalReports: undefined,
    appendFileTimestamp: false,
    systemExportDestinations: [ExportDestination.Excel, ExportDestination.CSV, ExportDestination.Clipboard, ExportDestination.JSON, ExportDestination.Table],
    systemReportNames: [GeneralConstants.ALL_DATA_REPORT, GeneralConstants.CURRENT_DATA_REPORT, GeneralConstants.VISUAL_DATA_REPORT, GeneralConstants.SELECTED_CELLS_REPORT, GeneralConstants.SELECTED_ROWS_REPORT],
    reportFilename: undefined
  },
  customSortOptions: {
    customSortComparers: undefined
  },
  dataSetOptions: {
    dataSets: EMPTY_ARRAY
  },
  groupingOptions: {
    restoreUngroupedColumns: false,
    autoOrderGroupedColumns: true
  },
  formatColumnOptions: {
    customDisplayFormatters: undefined
  },
  layoutOptions: {
    displayRowGroups: GeneralConstants.DISPLAY_ROW_GROUPS_DYNAMIC,
    autoSaveLayouts: true,
    autoSizeColumnsInLayout: false,
    autoSizeColumnsInPivotLayout: false,
    createDefaultLayout: false,
    layoutViewOptions: {
      maxColumnsToDisplay: 10
    },
    layoutTagOptions: {
      autoGenerateTagsForLayouts: false,
      isObjectAvailableInLayout: undefined,
      autoCheckTagsForLayouts: false
    }
  },
  columnMenuOptions: {
    customColumnMenu: undefined
  },
  contextMenuOptions: {
    customContextMenu: undefined
  },
  columnFilterOptions: {
    quickFilterOptions: {
      hideQuickFilterDropdown: undefined,
      hideQuickFilterInput: undefined,
      quickFilterTrigger: FilterConstants.FILTER_TRIGGER_MOUSE_ENTER,
      quickFilterDebounce: 250,
      quickFilterHeight: null,
      showQuickFilter: true,
      quickFilterWildcards: {},
      quickFilterValuesWidth: 180
    },
    filterFormOptions: {
      useAgGridFilterFormStyle: true,
      defaultFilterFormTab: 'Values'
    },
    valuesFilterOptions: {
      valuesFilterTrigger: FilterConstants.FILTER_TRIGGER_MOUSE_ENTER,
      sortValuesFilter: false,
      filterValuesUsingTime: false,
      showCurrentlyFilteredValuesOnly: false,
      showCurrentlyFilteredValuesCount: false,
      maxFilterValuesToDisplay: undefined,
      showValuesCount: undefined,
      valuesFilterPredicateOptions: undefined
    },
    indicateFilteredColumns: true,
    useAdaptableColumnFiltering: true,
    clearColumnFiltersOnStartUp: false,
    filterActionOnUserDataChange: {
      applyFilter: FilterConstants.FILTER_ACTION_ALWAYS,
      throttleDelay: 0
    },
    filterActionOnExternalDataChange: {
      applyFilter: FilterConstants.FILTER_ACTION_NEVER,
      throttleDelay: 0
    },
    autoApplyFilter: true,
    defaultNumericColumnFilter: FilterConstants.PREDICATE_EQUALS,
    defaultStringColumnFilter: FilterConstants.PREDICATE_CONTAINS,
    defaultDateColumnFilter: FilterConstants.PREDICATE_ON,
    enableFilterOnSpecialColumns: true,
    showClearFilterButton: false,
    showSuspendFilterButton: false,
    showDatePicker: true
  },
  gridFilterOptions: {
    availableFilterEditors: ['ExpressionEditor', 'QueryBuilder'],
    clearGridFilterOnStartUp: false
  },
  predicateOptions: {
    customPredicateDefs: [],
    systemFilterPredicates: SystemFilterPredicateIds,
    systemAlertPredicates: SystemAlertPredicateIds,
    systemFormatColumnPredicates: SystemFormatColumnPredicateIds,
    systemFlashingCellPredicates: SystemFlashingCellPredicateIds,
    systemBadgeStylePredicates: SystemBadgeStylePredicateIds,
    caseSensitivePredicates: false
  },
  quickSearchOptions: {
    excludeColumnFromQuickSearch: undefined,
    runQuickSearchOnRowGroups: true,
    runQuickSearchOnPivotColumns: true,
    clearQuickSearchOnStartUp: false,
    quickSearchPlaceholder: GeneralConstants.QUICK_SEARCH_PLACEHOLDER,
    filterResultsAfterQuickSearch: false,
    runQuickSearchWithCaseSensitivity: false,
    runBespokeQuickSearch: undefined
  },
  stateOptions: {
    debounceStateDelay: 400,
    applyState: state => state,
    saveState: state => state,
    loadState: undefined,
    persistState: undefined,
    clearState: undefined,
    autoMigrateState: true
  },
  teamSharingOptions: {
    enableTeamSharing: false,
    persistSharedEntities: undefined,
    loadSharedEntities: undefined,
    applySharedEntities: sharedEntities => sharedEntities,
    saveSharedEntities: sharedEntities => sharedEntities,
    updateInterval: 0,
    updateNotification: undefined,
    suppressOverrideConfigWarning: false,
    showUpdateNotificationOncePerUpdate: false
  },
  userInterfaceOptions: {
    alternativeModuleNames: undefined,
    applicationIcon: undefined,
    useCustomMacLikeScrollbars: false,
    englishVariant: GeneralConstants.ENGLISH_VARIANT,
    showLoadingScreen: true,
    loadingScreenDelay: GeneralConstants.LOADING_SCREEN_DELAY,
    loadingScreenTitle: GeneralConstants.LOADING_SCREEN_TITLE,
    loadingScreenText: GeneralConstants.LOADING_SCREEN_TEXT,
    disableDeleteConfirmation: false,
    dateInputOptions: {
      useNativeInput: false,
      dateFormat: GeneralConstants.DATE_INPUT_DATE_FORMAT,
      // locale is not explicitly defaulted to 'en-US' as this is already done by the react-day-picker
      showWeekNumber: false,
      showOutsideDays: true,
      datepickerButtons: ['close', 'today']
    },
    colorPalette: UIHelper.getDefaultColors(),
    editableCellStyle: undefined,
    readOnlyCellStyle: undefined,
    styleClassNames: EMPTY_ARRAY,
    permittedValues: undefined,
    editLookUpItems: undefined,
    showDocumentationLinks: true,
    showAdapTableVersion: true
  },
  toolPanelOptions: {
    showToolPanelsDropdown: true,
    customButtons: undefined,
    customToolPanels: undefined
  },
  dataChangeHistoryOptions: {
    activeByDefault: false,
    showDataChange: undefined,
    changeHistoryButton: undefined,
    showLastDataChangeOnly: true
  },
  settingsPanelOptions: {
    popupType: GeneralConstants.POPUP_TYPE_WINDOW,
    title: ModuleConstants.SettingsPanelFriendlyName,
    icon: GeneralConstants.ICON_CONFIGURATION,
    navigation: {},
    alwaysShowInDashboard: false,
    alwaysShowInToolPanel: false
  },
  chartingOptions: {
    saveChartBehaviour: GeneralConstants.SAVE_CHART_BEHAVIOUR_NONE,
    agGridContainerName: GeneralConstants.AG_GRID_CHART_WINDOW,
    externalChartingOptions: {}
  },
  fdc3Options: {
    enableLogging: false,
    actionColumnDefaultConfiguration: {
      columnId: GeneralConstants.FDC3_ACTION_COLUMN_DEFAULT_COLUMN_ID,
      headerName: GeneralConstants.FDC3_ACTION_COLUMN_DEFAULT_HEADER_NAME,
      width: 200,
      resizable: true,
      movable: false
    },
    uiControlsDefaultConfiguration: {
      // default config is set in FDC3 Service, to make it easier to overview
      contexts: {},
      intents: {}
    }
  },
  commentOptions: {
    showPopupCloseButton: true
  },
  noteOptions: {
    showPopupCloseButton: true
  }
};
export function applyDefaultAdaptableOptions(adaptableOptions) {
  var _a;
  const adaptableOptsWithDefaults = Object.assign({}, DefaultAdaptableOptions, adaptableOptions);
  adaptableOptsWithDefaults.adaptableStateKey = (_a = adaptableOptsWithDefaults.adaptableStateKey) !== null && _a !== void 0 ? _a : adaptableOptsWithDefaults.adaptableId;
  adaptableOptsWithDefaults.dataImportOptions = Object.assign({}, DefaultAdaptableOptions.dataImportOptions, adaptableOptions.dataImportOptions);
  adaptableOptsWithDefaults.expressionOptions = Object.assign({}, DefaultAdaptableOptions.expressionOptions, adaptableOptions.expressionOptions);
  adaptableOptsWithDefaults.calendarOptions = Object.assign({}, DefaultAdaptableOptions.calendarOptions, adaptableOptions.calendarOptions);
  adaptableOptsWithDefaults.columnOptions = Object.assign({}, DefaultAdaptableOptions.columnOptions, adaptableOptions.columnOptions);
  adaptableOptsWithDefaults.customSortOptions = Object.assign({}, DefaultAdaptableOptions.customSortOptions, adaptableOptions.customSortOptions);
  adaptableOptsWithDefaults.dataSetOptions = Object.assign({}, DefaultAdaptableOptions.dataSetOptions, adaptableOptions.dataSetOptions);
  adaptableOptsWithDefaults.cellSummaryOptions = Object.assign({}, DefaultAdaptableOptions.cellSummaryOptions, adaptableOptions.cellSummaryOptions);
  adaptableOptsWithDefaults.predicateOptions = Object.assign({}, DefaultAdaptableOptions.predicateOptions, adaptableOptsWithDefaults.predicateOptions);
  adaptableOptsWithDefaults.layoutOptions = Object.assign({}, DefaultAdaptableOptions.layoutOptions, adaptableOptions.layoutOptions);
  adaptableOptsWithDefaults.notificationsOptions = Object.assign({}, DefaultAdaptableOptions.notificationsOptions, adaptableOptions.notificationsOptions);
  adaptableOptsWithDefaults.alertOptions = Object.assign({}, DefaultAdaptableOptions.alertOptions, adaptableOptions.alertOptions);
  adaptableOptsWithDefaults.flashingCellOptions = Object.assign({}, DefaultAdaptableOptions.flashingCellOptions, adaptableOptions.flashingCellOptions);
  adaptableOptsWithDefaults.entitlementOptions = Object.assign({}, DefaultAdaptableOptions.entitlementOptions, adaptableOptions.entitlementOptions);
  adaptableOptsWithDefaults.dashboardOptions = Object.assign({}, DefaultAdaptableOptions.dashboardOptions, adaptableOptions.dashboardOptions);
  adaptableOptsWithDefaults.editOptions = Object.assign({}, DefaultAdaptableOptions.editOptions, adaptableOptions.editOptions);
  adaptableOptsWithDefaults.actionColumnOptions = Object.assign({}, DefaultAdaptableOptions.actionColumnOptions, adaptableOptions.actionColumnOptions);
  // to do
  adaptableOptsWithDefaults.actionRowOptions = Object.assign({}, DefaultAdaptableOptions.actionRowOptions, adaptableOptions.actionRowOptions);
  adaptableOptsWithDefaults.actionRowOptions.actionRowButtonOptions = Object.assign({}, DefaultAdaptableOptions.actionRowOptions.actionRowButtonOptions, adaptableOptsWithDefaults.actionRowOptions.actionRowButtonOptions);
  adaptableOptsWithDefaults.actionRowOptions.actionRowFormOptions = Object.assign({}, DefaultAdaptableOptions.actionRowOptions.actionRowFormOptions, adaptableOptsWithDefaults.actionRowOptions.actionRowFormOptions);
  adaptableOptsWithDefaults.containerOptions = Object.assign({}, DefaultAdaptableOptions.containerOptions, adaptableOptions.containerOptions);
  adaptableOptsWithDefaults.groupingOptions = Object.assign({}, DefaultAdaptableOptions.groupingOptions, adaptableOptions.groupingOptions);
  adaptableOptsWithDefaults.quickSearchOptions = Object.assign({}, DefaultAdaptableOptions.quickSearchOptions, adaptableOptions.quickSearchOptions);
  adaptableOptsWithDefaults.columnFilterOptions = Object.assign({}, DefaultAdaptableOptions.columnFilterOptions, adaptableOptions.columnFilterOptions);
  adaptableOptsWithDefaults.columnFilterOptions.quickFilterOptions = Object.assign({}, DefaultAdaptableOptions.columnFilterOptions.quickFilterOptions, adaptableOptsWithDefaults.columnFilterOptions.quickFilterOptions);
  adaptableOptsWithDefaults.columnFilterOptions.filterFormOptions = Object.assign({}, DefaultAdaptableOptions.columnFilterOptions.filterFormOptions, adaptableOptsWithDefaults.columnFilterOptions.filterFormOptions);
  adaptableOptsWithDefaults.columnFilterOptions.valuesFilterOptions = Object.assign({}, DefaultAdaptableOptions.columnFilterOptions.valuesFilterOptions, adaptableOptsWithDefaults.columnFilterOptions.valuesFilterOptions);
  adaptableOptsWithDefaults.gridFilterOptions = Object.assign({}, DefaultAdaptableOptions.gridFilterOptions, adaptableOptions.gridFilterOptions);
  adaptableOptsWithDefaults.userInterfaceOptions = Object.assign({}, DefaultAdaptableOptions.userInterfaceOptions, adaptableOptions.userInterfaceOptions);
  adaptableOptsWithDefaults.userInterfaceOptions.dateInputOptions = Object.assign({}, DefaultAdaptableOptions.userInterfaceOptions.dateInputOptions, adaptableOptsWithDefaults.userInterfaceOptions.dateInputOptions);
  adaptableOptsWithDefaults.columnMenuOptions = Object.assign({}, DefaultAdaptableOptions.columnMenuOptions, adaptableOptions.columnMenuOptions);
  adaptableOptsWithDefaults.contextMenuOptions = Object.assign({}, DefaultAdaptableOptions.contextMenuOptions, adaptableOptions.contextMenuOptions);
  adaptableOptsWithDefaults.stateOptions = Object.assign({}, DefaultAdaptableOptions.stateOptions, adaptableOptions.stateOptions);
  adaptableOptsWithDefaults.exportOptions = Object.assign({}, DefaultAdaptableOptions.exportOptions, adaptableOptions.exportOptions);
  adaptableOptsWithDefaults.teamSharingOptions = Object.assign({}, DefaultAdaptableOptions.teamSharingOptions, adaptableOptions.teamSharingOptions);
  adaptableOptsWithDefaults.toolPanelOptions = Object.assign({}, DefaultAdaptableOptions.toolPanelOptions, adaptableOptions.toolPanelOptions);
  adaptableOptsWithDefaults.dataChangeHistoryOptions = Object.assign({}, DefaultAdaptableOptions.dataChangeHistoryOptions, adaptableOptions.dataChangeHistoryOptions);
  adaptableOptsWithDefaults.chartingOptions = Object.assign({}, DefaultAdaptableOptions.chartingOptions, adaptableOptions.chartingOptions);
  adaptableOptsWithDefaults.chartingOptions.externalChartingOptions = Object.assign({}, DefaultAdaptableOptions.chartingOptions.externalChartingOptions, adaptableOptsWithDefaults.chartingOptions.externalChartingOptions);
  adaptableOptsWithDefaults.settingsPanelOptions = Object.assign({}, DefaultAdaptableOptions.settingsPanelOptions, adaptableOptions.settingsPanelOptions);
  adaptableOptsWithDefaults.fdc3Options = Object.assign({}, DefaultAdaptableOptions.fdc3Options, adaptableOptions.fdc3Options);
  adaptableOptsWithDefaults.fdc3Options.actionColumnDefaultConfiguration = Object.assign({}, DefaultAdaptableOptions.fdc3Options.actionColumnDefaultConfiguration, adaptableOptsWithDefaults.fdc3Options.actionColumnDefaultConfiguration);
  adaptableOptsWithDefaults.fdc3Options.uiControlsDefaultConfiguration = Object.assign({}, DefaultAdaptableOptions.fdc3Options.uiControlsDefaultConfiguration, adaptableOptsWithDefaults.fdc3Options.uiControlsDefaultConfiguration);
  const {
    predefinedConfig
  } = adaptableOptsWithDefaults;
  if (predefinedConfig) {
    adaptableOptsWithDefaults.predefinedConfig = typeof predefinedConfig === 'string' ? predefinedConfig : initPredefinedConfigWithUuids(predefinedConfig);
  }
  return adaptableOptsWithDefaults;
}
export const getDefaultAdaptableOptions = () => DefaultAdaptableOptions;