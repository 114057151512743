import * as React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { PanelWithButton } from '../../View/Components/Panels/PanelWithButton';
import SimpleButton from '../SimpleButton';
import { DragAndDropContext } from './DragAndDropContext';
import { TabList } from './TabList';
import { UnusedPanel } from './UnusedPanel';
export const ModuleManager = props => {
  const {
    availableItems,
    tabs,
    onTabsChange,
    tabsTitle,
    disabled,
    unusedPanelTitle,
    dragItemText,
    permittedActions
  } = props;
  const contextValue = React.useMemo(() => {
    const preparedPermittedAction = Object.assign({
      createTab: true,
      editTabName: true,
      dragAndDropTab: true,
      deleteTab: true
    }, permittedActions);
    return {
      permittedActions: preparedPermittedAction,
      availableItems: props.availableItems
    };
  }, [props.availableItems, props.permittedActions]);
  const unusedItems = React.useMemo(() => availableItems.map(t => t.Id).filter(item => {
    return !tabs.some(tab => {
      var _a;
      return (_a = tab.Items) === null || _a === void 0 ? void 0 : _a.includes(item);
    });
  }), [tabs, availableItems]);
  const handleToolbarDragEnd = result => {
    const {
      source,
      destination,
      draggableId
    } = result;
    if (!source || !destination) {
      return;
    }
    if (source.droppableId !== 'UNUSED') {
      const sourceTabToolbars = tabs[Number(source.droppableId)].Items;
      sourceTabToolbars.splice(source.index, 1);
    }
    if (destination.droppableId !== 'UNUSED') {
      const destinationTabToolbars = tabs[Number(destination.droppableId)].Items;
      destinationTabToolbars.splice(destination.index, 0, draggableId);
    }
    onTabsChange([...tabs]);
  };
  const handleTabDragEnd = result => {
    const {
      source,
      destination
    } = result;
    if (!source || !destination) return;
    const [removed] = tabs.splice(source.index, 1);
    tabs.splice(destination.index, 0, removed);
    onTabsChange([...tabs]);
  };
  const handleRemoveTab = tabIndex => {
    onTabsChange(tabs.filter((_, index) => index !== tabIndex));
  };
  const handleTabAdd = () => {
    onTabsChange([...tabs, {
      Name: 'New Tab',
      Items: []
    }]);
  };
  const handleRemoveToolbar = (tabIndex, toolbarIndex) => {
    onTabsChange(tabs.map((tab, index) => {
      if (index !== tabIndex) return tab;
      return Object.assign(Object.assign({}, tab), {
        Items: tab.Items.filter((_, index) => index !== toolbarIndex)
      });
    }));
  };
  const handleChangeTabName = (tabIndex, tabName) => {
    onTabsChange(tabs.map((tab, index) => {
      if (index !== tabIndex) return tab;
      return Object.assign(Object.assign({}, tab), {
        Name: tabName
      });
    }));
  };
  return React.createElement(DragDropContext, {
    onDragEnd: result => {
      if (result.type === 'TAB') handleTabDragEnd(result);
      if (result.type === 'TOOLBAR') handleToolbarDragEnd(result);
    }
  }, React.createElement(DragAndDropContext.Provider, {
    value: contextValue
  }, React.createElement("div", {
    className: "ab-ModuleSelector",
    style: {
      display: 'flex',
      flexDirection: 'column'
    }
  }, React.createElement(UnusedPanel, {
    title: unusedPanelTitle,
    disabled: disabled,
    items: unusedItems,
    dragItemText: dragItemText
  }), React.createElement(PanelWithButton, {
    className: "ab-ModuleSelector-UsedPanel",
    headerText: tabsTitle,
    button: contextValue.permittedActions.createTab && React.createElement(SimpleButton, {
      disabled: disabled,
      onClick: handleTabAdd
    }, "Add Tab")
  }, React.createElement(TabList, {
    disabled: disabled,
    tabs: tabs,
    onRemoveTab: handleRemoveTab,
    onRemoveToolbar: handleRemoveToolbar,
    onChangeTabName: handleChangeTabName
  })))));
};