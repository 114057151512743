import * as React from 'react';
import { PanelDashboard } from '../Components/Panels/PanelDashboard';
import * as PopupRedux from '../../Redux/ActionsReducers/PopupRedux';
import * as DashboardRedux from '../../Redux/ActionsReducers/DashboardRedux';
import { useAdaptable } from '../AdaptableContext';
export const ModuleToolbarWrapper = props => {
  const moduleName = props.moduleInfo.ModuleName;
  const moduleFriendlyName = props.moduleInfo.FriendlyName;
  const {
    api
  } = useAdaptable();
  const isConfigurable = moduleName !== 'OpenFin' && moduleName !== 'IPushPull';
  return React.createElement(PanelDashboard, {
    className: `ab-DashboardToolbar__${moduleName}`,
    "data-name": `ab-DashboardToolbar__${moduleName}`,
    headerText: moduleFriendlyName,
    showConfigureButton: isConfigurable,
    onConfigure: () => {
      const maxWidth = api.internalApi.getModuleService().getPopupMaxWidth(moduleName);
      const popupParams = maxWidth ? {
        maxWidth,
        source: 'Toolbar'
      } : {
        source: 'Toolbar'
      };
      api.internalApi.dispatchReduxAction(PopupRedux.PopupShowScreen(moduleName, props.moduleInfo.Popup, popupParams));
    },
    onClose: () => api.internalApi.dispatchReduxAction(DashboardRedux.DashboardCloseToolbar(moduleName)),
    accessLevel: props.accessLevel
  }, props.children);
};