import { EMPTY_ARRAY } from '../../Utilities/Constants/GeneralConstants';
import ObjectFactory from '../../Utilities/ObjectFactory';
import { CellPopupService } from '../../Utilities/Services/CellPopupService';
/**
 * @ReduxAction A Comments has been added
 */
export const COMMENTS_ADD = 'COMMENTS_ADD';
/**
 * @ReduxAction Cell Comments has been added
 */
export const COMMENTS_CELL_ADD = 'COMMENTS_CELL_ADD';
/**
 * @ReduxAction Cell comments have been removed
 */
export const COMMENTS_CELL_DELETE = 'COMMENTS_CELL_DELETE';
/**
 * @ReduxAction A Comments has been edited
 */
export const COMMENTS_EDIT = 'COMMENTS_EDIT';
/**
 * @ReduxAction A Comments has been deleted
 */
export const COMMENTS_DELETE = 'COMMENTS_DELETE';
/**
 * @ReduxAction Comments Module is ready
 */
export const COMMENTS_READY = 'COMMENTS_READY';
export const COMMENTS_LOAD = 'COMMENTS_LOAD';
export const CommentsSet = ({
  commentThread
}) => ({
  type: COMMENTS_LOAD,
  commentThread
});
export const CommentsAdd = ({
  comment,
  cellAddress
}) => ({
  type: COMMENTS_ADD,
  comment,
  cellAddress
});
export const CommentsCellAdd = ({
  commentThread
}) => ({
  type: COMMENTS_CELL_ADD,
  commentThread
});
export const CommentsCellDelete = ({
  cellAddress: cellAddress
}) => ({
  type: COMMENTS_CELL_DELETE,
  cellAddress: cellAddress
});
export const CommentsEdit = ({
  comment,
  cellAddress
}) => ({
  type: COMMENTS_EDIT,
  comment,
  cellAddress
});
export const CommentsDelete = ({
  commentUuid,
  cellAddress
}) => ({
  type: COMMENTS_DELETE,
  cellAddress,
  commentUuid
});
export const GetCellCommentSelector = (state, address) => {
  var _a;
  if (!address) {
    return undefined;
  }
  return ((_a = state === null || state === void 0 ? void 0 : state.CommentThreads) !== null && _a !== void 0 ? _a : []).find(commentThread => {
    return CellPopupService.isSameAddress(commentThread, address);
  });
};
const initialState = {
  CommentThreads: EMPTY_ARRAY
};
export const CommentsReducer = (state = initialState, action) => {
  var _a, _b, _c, _d, _e;
  switch (action.type) {
    case COMMENTS_ADD:
      {
        const addAction = action;
        const commentThread = (_a = state.CommentThreads) !== null && _a !== void 0 ? _a : [];
        const cellCommentIndex = commentThread.findIndex(cellComments => {
          return CellPopupService.isSameAddress(cellComments, addAction.cellAddress);
        });
        let comments = [...commentThread];
        if (cellCommentIndex === -1) {
          // new comments
          comments.push(Object.assign(Object.assign({}, ObjectFactory.CreateEmptyCommentThread(addAction.cellAddress)), {
            Comments: [addAction.comment]
          }));
        } else {
          const existing = Object.assign(Object.assign({}, commentThread[cellCommentIndex]), {
            Comments: [...commentThread[cellCommentIndex].Comments, addAction.comment]
          });
          comments[cellCommentIndex] = existing;
        }
        return Object.assign(Object.assign({}, state), {
          CommentThreads: comments
        });
      }
    case COMMENTS_CELL_ADD:
      {
        return Object.assign(Object.assign({}, state), {
          CommentThreads: [...((_b = state.CommentThreads) !== null && _b !== void 0 ? _b : []), action.commentThread]
        });
      }
    case COMMENTS_CELL_DELETE:
      {
        const deleteAction = action;
        return Object.assign(Object.assign({}, state), {
          CommentThreads: ((_c = state.CommentThreads) !== null && _c !== void 0 ? _c : []).filter(cellComments => !CellPopupService.isSameAddress(cellComments, deleteAction.cellAddress))
        });
      }
    case COMMENTS_EDIT:
      {
        const editAction = action;
        let edited = false;
        const adaptableCellComments = ((_d = state.CommentThreads) !== null && _d !== void 0 ? _d : []).map(cellComments => {
          if (CellPopupService.isSameAddress(cellComments, editAction.cellAddress)) {
            // found the place
            edited = true;
            return Object.assign(Object.assign({}, cellComments), {
              Comments: cellComments.Comments.map(comment => {
                if (comment.Uuid === editAction.comment.Uuid) {
                  return editAction.comment;
                }
                return comment;
              })
            });
          }
        });
        return Object.assign(Object.assign({}, state), {
          CommentThreads: adaptableCellComments
        });
      }
    case COMMENTS_DELETE:
      {
        const deleteAction = action;
        const adaptableCellComments = ((_e = state.CommentThreads) !== null && _e !== void 0 ? _e : []).map(cellComments => {
          if (CellPopupService.isSameAddress(cellComments, deleteAction.cellAddress)) {
            // found the place
            const newComments = cellComments.Comments.filter(comment => comment.Uuid !== deleteAction.commentUuid);
            if (newComments.length === 0) {
              return null;
            }
            const newCellComments = Object.assign(Object.assign({}, cellComments), {
              Comments: newComments
            });
            return newCellComments;
          }
          return cellComments;
        }).filter(cellComments => cellComments !== null);
        return Object.assign(Object.assign({}, state), {
          CommentThreads: adaptableCellComments
        });
      }
    case COMMENTS_LOAD:
      {
        return Object.assign(Object.assign({}, state), {
          CommentThreads: action.commentThread
        });
      }
    default:
      return state;
  }
};