import { compute_string as e } from "./hashing";
const t = () => new Error("Invalid License");
export const GENERIC_APP_NAME = "GenericAdaptableApp";
export const fieldsToLicenseDetails = e => {
  var a;
  const r = e.reduce((e, t) => (e.set(t.name, t.value), e), new Map()),
    n = {
      start: new Date(r.get("StartDate")),
      end: new Date(r.get("EndDate")),
      owner: r.get("Owner"),
      appName: r.get("AppName") || GENERIC_APP_NAME,
      timestamp: r.get("TS") ? Number(r.get("TS")) : 0,
      trial: "true" === r.get("Trial"),
      ref: null !== (a = r.get("Ref")) && void 0 !== a ? a : ""
    };
  if (!(n.start && n.end && n.owner && "boolean" == typeof n.trial && n.ref)) throw t();
  return n;
};
export const decode = a => {
  let r = "",
    n = a.split("|").map(e => {
      let [t, a] = e.split("=");
      return "C" === t && (r = a), {
        name: t,
        value: a
      };
    });
  if (!r) throw t();
  const o = r.split(",").reverse(),
    s = o.pop();
  o.forEach((a, r) => {
    const o = n[r];
    if (e(o.value) !== a) throw t();
  });
  const i = [...n];
  i.pop();
  const p = i.map(e => `${e.name}=${e.value}`).join("|");
  if (e(p) !== s) throw t();
  return n = n.map(e => Object.assign(Object.assign({}, e), {
    value: decodeURI(e.value)
  })), fieldsToLicenseDetails(n);
};