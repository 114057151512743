import * as React from 'react';
import { Box } from 'rebass';
import { Tabs } from '../../../../components/Tabs';
import { Tag } from '../../../../components/Tag';
import { useAdaptable } from '../../../AdaptableContext';
import { ValueSelector } from '../../../Components/ValueSelector';
import { useOnePageAdaptableWizardContext } from '../../../Wizard/OnePageAdaptableWizard';
import { columnFilter } from './Utilities';
import ArrayExtensions from '../../../../Utilities/Extensions/ArrayExtensions';
export const PivotColumnsSectionSummary = () => {
  var _a;
  const adaptable = useAdaptable();
  const {
    data: layout
  } = useOnePageAdaptableWizardContext();
  return React.createElement(Box, {
    p: 2,
    style: {
      borderRadius: 'var(--ab__border-radius)'
    },
    backgroundColor: "var(--ab-color-defaultbackground)"
  }, ((_a = layout.PivotColumns) === null || _a === void 0 ? void 0 : _a.length) ? layout.PivotColumns.map(columnId => React.createElement(Tag, {
    mr: 1,
    key: columnId
  }, adaptable.api.columnApi.getFriendlyNameForColumnId(columnId))) : React.createElement(Tag, null, "No Column Pivoting"));
};
export const PivotColumnsSection = props => {
  var _a;
  const adaptable = useAdaptable();
  const {
    data: layout
  } = useOnePageAdaptableWizardContext();
  const allPivotColumns = adaptable.api.columnApi.getPivotableColumns();
  const sortedPivotColumns = React.useMemo(() => {
    var _a;
    return ArrayExtensions.sortArrayWithOrder(allPivotColumns.map(col => col.columnId), (_a = layout.PivotColumns) !== null && _a !== void 0 ? _a : [], {
      sortUnorderedItems: false
    }).map(colId => adaptable.api.columnApi.getColumnWithColumnId(colId));
  }, [layout, allPivotColumns]);
  const handleColumnsChange = columnIds => {
    props.onChange(Object.assign(Object.assign({}, layout), {
      PivotColumns: columnIds
    }));
  };
  return React.createElement(Tabs, {
    style: {
      height: '100%'
    }
  }, React.createElement(Tabs.Tab, null, "Pivot Columns"), React.createElement(Tabs.Content, null, React.createElement(ValueSelector, {
    showFilterInput: true,
    showSelectedOnlyPosition: "top",
    filter: columnFilter,
    toIdentifier: option => `${option.columnId}`,
    toLabel: option => {
      var _a;
      return (_a = option.friendlyName) !== null && _a !== void 0 ? _a : option.columnId;
    },
    options: sortedPivotColumns,
    value: (_a = layout.PivotColumns) !== null && _a !== void 0 ? _a : [],
    allowReorder: true,
    xSelectedLabel: () => {
      return `Selected Columns:`;
    },
    onChange: handleColumnsChange
  })));
};