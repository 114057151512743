import * as React from 'react';
import SimpleButton from '../../../components/SimpleButton';
export class ButtonOpen extends React.Component {
  render() {
    return React.createElement(SimpleButton, Object.assign({
      "data-name": "open",
      iconSize: 20,
      icon: "folder-open",
      tooltip: "Open",
      variant: "text"
    }, this.props));
  }
}