import { ApiBase } from '../Implementation/ApiBase';
export class DataSetInternalApi extends ApiBase {
  /**
   * Fires the DataSet Selected Event
   * @param dataSet Data Set which has been selected
   */
  fireDataSetSelectedEvent(dataSet) {
    if (this.adaptable.isReady) {
      const dataSetSelectedInfo = Object.assign(Object.assign({}, this.getAdaptableApi().internalApi.buildBaseContext()), {
        dataSet
      });
      this.getAdaptableApi().eventApi.emit('DataSetSelected', dataSetSelectedInfo);
    }
  }
}