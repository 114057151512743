import * as React from 'react';
import { useDispatch } from 'react-redux';
import DropdownButton from '../../components/DropdownButton';
import { Icon } from '../../components/icons';
import { ExportDestination } from '../../PredefinedConfig/Common/Enums';
import { ExportApply } from '../../Redux/ActionsReducers/ExportRedux';
import { VISUAL_DATA_REPORT } from '../../Utilities/Constants/GeneralConstants';
import { useAdaptable } from '../AdaptableContext';
export const ReportExportDropdown = props => {
  const report = props.data;
  const adaptable = useAdaptable();
  const dispatch = useDispatch();
  const handleExport = destination => {
    dispatch(ExportApply(report, destination));
  };
  const destinationItems = [...adaptable.api.exportApi.getAvailableExportDestinations().map(destination => ({
    label: destination,
    onClick: () => handleExport(destination),
    disabled: !adaptable.api.exportApi.internalApi.isDestinationEnabled(destination)
  })), ...adaptable.api.exportApi.getCustomDestinations().map(destination => ({
    label: destination.name,
    onClick: () => {
      handleExport(destination.name);
    }
  }))];
  if ((report === null || report === void 0 ? void 0 : report.Name) === VISUAL_DATA_REPORT) {
    destinationItems.forEach(item => item.disabled = item.label === ExportDestination.Excel ? false : true);
  }
  return React.createElement(DropdownButton, {
    "data-name": "export-dropdown",
    columns: ['label'],
    tooltip: "Export Report",
    variant: "text",
    items: destinationItems
  }, React.createElement(Icon, {
    name: "export"
  }));
};