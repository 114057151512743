import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DropdownButton from '../../../../components/DropdownButton';
import EmptyContent from '../../../../components/EmptyContent';
import { Icon } from '../../../../components/icons';
import { AdaptableObjectList } from '../../AdaptableObjectList';
import { ButtonNew } from '../../Buttons/ButtonNew';
import { PopupPanel } from './PopupPanel';
import { Flex } from 'rebass';
import { CheckBox } from '../../../../components/CheckBox';
import { SystemLayoutShowNotAssociatedObjects } from '../../../../Redux/ActionsReducers/SystemRedux';
import SimpleButton from '../../../../components/SimpleButton';
export const AdaptablePopupModuleView = props => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  /**
   * This triggers a render for each redux change.
   * Not sure yet how to trigger this component to render when an item is edited.
   */
  useSelector(state => state);
  const [abObjectType, setAbObjectType] = React.useState(null);
  const moduleInfo = props.module.moduleInfo;
  const items = (_a = props.module) === null || _a === void 0 ? void 0 : _a.toViewAll();
  const moduleViewProperties = (_d = (_c = (_b = props.module) === null || _b === void 0 ? void 0 : _b.getViewProperties) === null || _c === void 0 ? void 0 : _c.call(_b)) !== null && _d !== void 0 ? _d : {};
  const EditWizard = (_e = moduleViewProperties === null || moduleViewProperties === void 0 ? void 0 : moduleViewProperties.getEditWizard) === null || _e === void 0 ? void 0 : _e.call(moduleViewProperties);
  const [isWizardOpen, setIsWizardOpen] = React.useState(() => {
    var _a, _b, _c;
    return ((_a = props.popupParams) === null || _a === void 0 ? void 0 : _a.action) === 'New' || ((_b = props.popupParams) === null || _b === void 0 ? void 0 : _b.action) === 'Edit' || ((_c = props.popupParams) === null || _c === void 0 ? void 0 : _c.action) === 'Clone';
  });
  const handleOpenEditPopup = React.useCallback(() => {
    if (EditWizard) {
      setIsWizardOpen(true);
    }
    if (moduleViewProperties.onOpenEditPopup) {
      moduleViewProperties.onOpenEditPopup();
    }
  }, []);
  React.useEffect(() => {
    var _a;
    (_a = moduleViewProperties === null || moduleViewProperties === void 0 ? void 0 : moduleViewProperties.onMount) === null || _a === void 0 ? void 0 : _a.call(moduleViewProperties);
  }, []);
  React.useEffect(() => {
    var _a, _b;
    if (((_a = props.popupParams) === null || _a === void 0 ? void 0 : _a.action) === 'New' || ((_b = props.popupParams) === null || _b === void 0 ? void 0 : _b.action) === 'Edit') {
      handleOpenEditPopup();
    }
  }, [(_f = props.popupParams) === null || _f === void 0 ? void 0 : _f.action]);
  const emptyView = moduleViewProperties.emptyView;
  let emptyContent = null;
  if (typeof emptyView === 'function') {
    emptyContent = React.createElement(emptyView, {
      module: props.module
    });
  } else if (typeof emptyView === 'string') {
    emptyContent = emptyView;
  }
  const toolTipText = (_g = moduleViewProperties.newTooltipText) !== null && _g !== void 0 ? _g : `Create ${moduleInfo.FriendlyName}`;
  // Some modules do not have new button
  // e.g. filter
  let newButton = null;
  if ((_h = moduleViewProperties === null || moduleViewProperties === void 0 ? void 0 : moduleViewProperties.abObjectTypes) === null || _h === void 0 ? void 0 : _h.length) {
    const items = moduleViewProperties === null || moduleViewProperties === void 0 ? void 0 : moduleViewProperties.abObjectTypes.map(abObjectType => {
      var _a;
      return {
        disabled: (abObjectType === null || abObjectType === void 0 ? void 0 : abObjectType.accessLevel) === 'ReadOnly',
        onClick: () => {
          setAbObjectType(abObjectType);
          handleOpenEditPopup();
        },
        label: (_a = abObjectType.label) !== null && _a !== void 0 ? _a : abObjectType.name
      };
    });
    newButton = React.createElement(DropdownButton, {
      tone: "accent",
      variant: "raised",
      columns: ['label'],
      items: items,
      tooltip: toolTipText
    }, React.createElement(Icon, {
      name: "plus"
    }), " New");
  } else if (!moduleViewProperties.hideNewButton && (EditWizard || moduleViewProperties.onOpenEditPopup)) {
    newButton = React.createElement(ButtonNew, {
      onClick: () => handleOpenEditPopup(),
      tooltip: toolTipText,
      accessLevel: props.accessLevel
    });
  }
  let suspendButton = null;
  const editableObjects = items.filter(item => !item.abObject.IsReadOnly);
  if (editableObjects.length && (moduleViewProperties === null || moduleViewProperties === void 0 ? void 0 : moduleViewProperties.getSuspendAllAction) && (moduleViewProperties === null || moduleViewProperties === void 0 ? void 0 : moduleViewProperties.getUnSuspendAllAction)) {
    const isAtLeastOneAbObjectActive = editableObjects.some(item => {
      var _a;
      return !((_a = item.abObject) === null || _a === void 0 ? void 0 : _a.IsSuspended);
    });
    const handleSuspendUnsuspendAll = () => {
      if (isAtLeastOneAbObjectActive) {
        const suspendAllAction = moduleViewProperties.getSuspendAllAction();
        dispatch(suspendAllAction);
      } else {
        const unsuspendAllAction = moduleViewProperties.getUnSuspendAllAction();
        dispatch(unsuspendAllAction);
      }
    };
    suspendButton = React.createElement(SimpleButton, {
      mr: 2,
      onMouseDown: () => handleSuspendUnsuspendAll(),
      tone: isAtLeastOneAbObjectActive ? 'neutral' : 'success',
      variant: "raised",
      icon: isAtLeastOneAbObjectActive ? 'pause' : 'resume',
      accessLevel: props.accessLevel
    }, isAtLeastOneAbObjectActive ? 'Suspend All' : 'Unsuspend All');
  }
  const handleWizardClose = () => {
    var _a;
    setAbObjectType(null);
    setIsWizardOpen(false);
    if (['Toolbar', 'ContextMenu', 'ColumnMenu'].includes((_a = props === null || props === void 0 ? void 0 : props.popupParams) === null || _a === void 0 ? void 0 : _a.source)) {
      props.onClosePopup();
    }
  };
  const adaptableModule = props.api.internalApi.getModuleService().getModuleById(props.module.moduleInfo.ModuleName);
  const dispatch = useDispatch();
  const showLayoutNotAssociatedObjects = useSelector(state => state.System.ShowLayoutNotAssociatedObjects);
  const toggleButtonShowLayoutAssociatedObjects = () => {
    if (!(adaptableModule === null || adaptableModule === void 0 ? void 0 : adaptableModule.canBeAssociatedWithLayouts())) {
      return;
    }
    if (!props.api.layoutApi.internalApi.hasLayoutSpecificObjects()) {
      return;
    }
    return React.createElement(Flex, {
      justifyContent: "flex-start"
    }, React.createElement(CheckBox, {
      padding: 0,
      margin: 0,
      checked: showLayoutNotAssociatedObjects,
      onChange: checked => dispatch(SystemLayoutShowNotAssociatedObjects(checked))
    }, "Show ", moduleInfo.FriendlyName, "s not available in current Layout"));
  };
  return React.createElement(PopupPanel, {
    glyphicon: moduleInfo.Glyph,
    infoLink: moduleInfo.HelpPage,
    headerText: moduleInfo.FriendlyName,
    button: React.createElement(React.Fragment, null, suspendButton, " ", newButton),
    infoLinkDisabled: !props.api.internalApi.isDocumentationLinksDisplayed()
  }, moduleViewProperties.HeaderComponent && React.createElement(moduleViewProperties.HeaderComponent, null), toggleButtonShowLayoutAssociatedObjects(), (items === null || items === void 0 ? void 0 : items.length) ? React.createElement(AdaptableObjectList, {
    module: props.module,
    items: items
  }) : React.createElement(EmptyContent, null, emptyContent !== null && emptyContent !== void 0 ? emptyContent : `Click 'New' to create a new ${moduleInfo.FriendlyName}`), isWizardOpen && EditWizard && React.createElement(EditWizard, {
    abObjectType: abObjectType,
    moduleInfo: moduleInfo,
    data: null,
    popupParams: props.popupParams,
    configEntities: null,
    onCloseWizard: handleWizardClose,
    onFinishWizard: handleWizardClose
  }));
};