import * as React from 'react';
import useDraggable from '../utils/useDraggable';
import join from '../utils/join';
import useProperty from '../utils/useProperty';
import { DashboardToolbar } from './DashboardToolbar';
import DropdownButton from '../DropdownButton';
export function Dashboard(props) {
  const {
    title,
    children,
    left,
    right,
    onShowDashboardPopup
  } = props;
  const [activeTabIndex, setActiveTabIndex] = useProperty(props, 'activeTabIndex', 0);
  const [collapsed, setCollapsed] = useProperty(props, 'collapsed', false);
  const [floating, setFloating] = useProperty(props, 'floating', false);
  const [inline, setInline] = useProperty(props, 'inline', false);
  const [position, setPosition] = useProperty(props, 'position', {
    x: 0,
    y: 0
  });
  const expanded = !floating && !collapsed;
  const {
    handleRef,
    targetRef
  } = useDraggable({
    onDrop(dx, dy) {
      setPosition(({
        x,
        y
      }) => {
        return {
          x: x + dx,
          y: y + dy
        };
      });
    }
  });
  const floatingStyle = {
    position: 'absolute',
    left: position.x,
    top: position.y
  };
  const renderTabs = () => React.createElement("div", {
    className: "ab-Dashboard__tabs"
  }, children && React.Children.map(children, (child, index) => React.createElement("button", {
    type: "button",
    className: join('ab-Dashboard__tab', !collapsed && activeTabIndex === index ? 'ab-Dashboard__tab--active' : ''),
    key: index,
    onClick: () => {
      if (activeTabIndex === index) {
        setCollapsed(!collapsed);
      } else {
        setActiveTabIndex(index);
        setCollapsed(false);
      }
    }
  }, child.props.title)));
  const renderTabsDropdown = () => {
    var _a, _b, _c;
    if (children && children.length < 2) {
      return null;
    }
    const activeTabTitle = (_c = (_b = (_a = children === null || children === void 0 ? void 0 : children[activeTabIndex]) === null || _a === void 0 ? void 0 : _a.props) === null || _b === void 0 ? void 0 : _b.title) !== null && _c !== void 0 ? _c : 'Select Toolbar';
    return React.createElement(DropdownButton, {
      ml: 1,
      columns: ['label'],
      items: React.Children.map(children, (child, index) => ({
        value: String(index),
        label: child.props.title,
        onClick: () => setActiveTabIndex(index)
      }))
    }, activeTabTitle);
  };
  const renderBar = () => React.createElement("div", {
    className: "ab-Dashboard__header",
    onDoubleClick: event => {
      const target = event.target;
      // ignore double clicks on buttons, inputs and their children
      if (!props.canFloat) {
        return;
      }
      if (target.closest('button, input')) {
        return;
      }
      setFloating(!floating);
    }
  }, React.createElement("div", {
    className: "ab-Dashboard__header-left"
  }, left, !floating && renderTabs()), floating ? React.createElement("div", {
    className: "ab-Dashboard__title",
    ref: handleRef,
    key: "title-drag",
    style: {
      cursor: 'move'
    }
  }, title) : React.createElement("div", {
    className: "ab-Dashboard__title",
    key: "title"
  }, title), React.createElement("div", {
    className: "ab-Dashboard__header-right"
  }, right));
  const renderHomeToolbar = () => React.createElement(DashboardToolbar, {
    onConfigure: onShowDashboardPopup,
    className: "ab-Dashboard__home-toolbar",
    title: title,
    tooltip: `Configure Dashboard`,
    showConfigure: true,
    showClose: false,
    accessLevel: 'Full'
  }, left, right, children && children.length > 1, renderTabsDropdown());
  return React.createElement("div", {
    // @ts-ignore
    ref: targetRef,
    className: join(`ab-Dashboard`, collapsed ? 'ab-Dashboard--collapsed' : '', floating ? 'ab-Dashboard--floating' : '', inline ? 'ab-Dashboard--inline' : ''),
    style: floating ? floatingStyle : undefined
  }, expanded && inline ? renderHomeToolbar() : renderBar(), expanded && children && children.length ? React.createElement("div", {
    className: "ab-Dashboard__content"
  }, children[activeTabIndex] ? children[activeTabIndex].props.children : null) : null, props.footer);
}