import * as React from 'react';
import { Box } from 'rebass';
import { toStyle } from '../Utilities/Helpers/StyleHelper';
import join from './utils/join';
export const StylePreview = props => {
  var _a, _b, _c;
  return React.createElement(Box, Object.assign({}, props, {
    className: join('ab-StylePreview', props.className),
    padding: (_a = props.padding) !== null && _a !== void 0 ? _a : 2,
    mt: (_b = props.mt) !== null && _b !== void 0 ? _b : 2,
    style: Object.assign(Object.assign({}, toStyle(props.styleObject)), props.style)
  }), (_c = props.children) !== null && _c !== void 0 ? _c : 'Preview result');
};