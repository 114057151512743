import * as React from 'react';
import SimpleButton from '../../../components/SimpleButton';
export class ButtonStop extends React.Component {
  render() {
    return React.createElement(SimpleButton, Object.assign({
      "data-name": "stop",
      tooltip: "Stop",
      iconSize: 20,
      icon: "stop",
      variant: "text"
    }, this.props));
  }
}