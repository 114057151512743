import { EMPTY_ARRAY } from '../../Utilities/Constants/GeneralConstants';
import AdaptableHelper from '../../Utilities/Helpers/AdaptableHelper';
import { changeIsSuspendInList, suspendAllInList, unsuspendAllInList } from './utils';
/**
 * @ReduxAction A Format Column has been added
 */
export const FORMAT_COLUMN_ADD = 'FORMAT_COLUMN_ADD';
/**
 * @ReduxAction Format column was moved up
 */
export const FORMAT_COLUMN_MOVE_UP = 'FORMAT_COLUMN_MOVE_UP';
/**
 * @ReduxAction Format column was moved down
 */
export const FORMAT_COLUMN_MOVE_DOWN = 'FORMAT_COLUMN_MOVE_DOWN';
/**
 * @ReduxAction A Format Column has been edited
 */
export const FORMAT_COLUMN_EDIT = 'FORMAT_COLUMN_EDIT';
/**
 * @ReduxAction A Format Column has been deleted
 */
export const FORMAT_COLUMN_DELETE = 'FORMAT_COLUMN_DELETE';
/**
 * @ReduxAction Delete ALL Format Columns
 */
export const FORMAT_COLUMN_DELETE_ALL = 'FORMAT_COLUMN_DELETE_ALL';
/**
 * @ReduxAction FormatColumn Module is ready
 */
export const FORMAT_COLUMN_READY = 'FORMAT_COLUMN_READY';
/**
 * @ReduxAction FormatColumn Module is suspended
 */
export const FORMAT_COLUMN_SUSPEND = 'FORMAT_COLUMN_SUSPEND';
/**
 * @ReduxAction Suspend all FormatColumn Modules
 */
export const FORMAT_COLUMN_SUSPEND_ALL = 'FORMAT_COLUMN_SUSPEND_ALL';
/**
 * @ReduxAction FormatColumn Module is unsuspended, or activated
 */
export const FORMAT_COLUMN_UNSUSPEND = 'FORMAT_COLUMN_UNSUSPEND';
/**
 * @ReduxAction FormatColumn Module is unsuspended, or activated
 */
export const FORMAT_COLUMN_UNSUSPEND_ALL = 'FORMAT_COLUMN_UNSUSPEND_ALL';
export const FormatColumnAdd = formatColumn => ({
  type: FORMAT_COLUMN_ADD,
  formatColumn
});
export const FormatColumnEdit = formatColumn => ({
  type: FORMAT_COLUMN_EDIT,
  formatColumn
});
export const FormatColumnDelete = formatColumn => ({
  type: FORMAT_COLUMN_DELETE,
  formatColumn
});
export const FormatColumnDeleteAll = () => ({
  type: FORMAT_COLUMN_DELETE_ALL
});
export const FormatColumnMoveUp = formatColumn => ({
  type: FORMAT_COLUMN_MOVE_UP,
  formatColumn
});
export const FormatColumnMoveDown = formatColumn => ({
  type: FORMAT_COLUMN_MOVE_DOWN,
  formatColumn
});
export const FormatColumnSuspend = formatColumn => ({
  type: FORMAT_COLUMN_SUSPEND,
  formatColumn
});
export const FormatColumnUnSuspend = formatColumn => ({
  type: FORMAT_COLUMN_UNSUSPEND,
  formatColumn
});
export const FormatColumnSuspendAll = () => ({
  type: FORMAT_COLUMN_SUSPEND_ALL
});
export const FormatColumnUnSuspendAll = () => ({
  type: FORMAT_COLUMN_UNSUSPEND_ALL
});
export const FormatColumnReady = formatColumnState => ({
  type: FORMAT_COLUMN_READY,
  formatColumnState
});
const initialState = {
  FormatColumns: EMPTY_ARRAY
};
export const FormatColumnReducer = (state = initialState, action) => {
  let formatColumns;
  switch (action.type) {
    case FORMAT_COLUMN_ADD:
      {
        const actionFormatColumn = action.formatColumn;
        AdaptableHelper.addUuidAndSource(actionFormatColumn);
        formatColumns = [].concat(state.FormatColumns);
        formatColumns.push(actionFormatColumn);
        return Object.assign(Object.assign({}, state), {
          FormatColumns: formatColumns
        });
      }
    case FORMAT_COLUMN_EDIT:
      const actionFormatColumn = action.formatColumn;
      return Object.assign(Object.assign({}, state), {
        FormatColumns: state.FormatColumns.map(abObject => abObject.Uuid === actionFormatColumn.Uuid ? actionFormatColumn : abObject)
      });
    case FORMAT_COLUMN_DELETE:
      {
        const actionFormatColumn = action.formatColumn;
        return Object.assign(Object.assign({}, state), {
          FormatColumns: state.FormatColumns.filter(abObject => abObject.Uuid !== actionFormatColumn.Uuid)
        });
      }
    case FORMAT_COLUMN_DELETE_ALL:
      {
        return Object.assign(Object.assign({}, state), {
          FormatColumns: []
        });
      }
    case FORMAT_COLUMN_SUSPEND:
      {
        return Object.assign(Object.assign({}, state), {
          FormatColumns: changeIsSuspendInList(action.formatColumn, state.FormatColumns, true)
        });
      }
    case FORMAT_COLUMN_UNSUSPEND:
      {
        return Object.assign(Object.assign({}, state), {
          FormatColumns: changeIsSuspendInList(action.formatColumn, state.FormatColumns, false)
        });
      }
    case FORMAT_COLUMN_SUSPEND_ALL:
      {
        return Object.assign(Object.assign({}, state), {
          FormatColumns: suspendAllInList(state.FormatColumns)
        });
      }
    case FORMAT_COLUMN_UNSUSPEND_ALL:
      {
        return Object.assign(Object.assign({}, state), {
          FormatColumns: unsuspendAllInList(state.FormatColumns)
        });
      }
    case FORMAT_COLUMN_MOVE_UP:
      {
        const index = state.FormatColumns.findIndex(formatColumn => formatColumn.Uuid === action.formatColumn.Uuid);
        let swapIndex = index - 1;
        if (swapIndex < 0) {
          swapIndex = state.FormatColumns.length - 1;
        }
        const formatColumnToSwap = state.FormatColumns[swapIndex];
        const newFormatColumns = [...state.FormatColumns];
        newFormatColumns[swapIndex] = state.FormatColumns[index];
        newFormatColumns[index] = formatColumnToSwap;
        return Object.assign(Object.assign({}, state), {
          FormatColumns: newFormatColumns
        });
      }
    case FORMAT_COLUMN_MOVE_DOWN:
      {
        const index = state.FormatColumns.findIndex(formatColumn => formatColumn.Uuid === action.formatColumn.Uuid);
        let swapIndex = index + 1;
        if (swapIndex > state.FormatColumns.length - 1) {
          swapIndex = 0;
        }
        const formatColumnToSwap = state.FormatColumns[swapIndex];
        const newFormatColumns = [...state.FormatColumns];
        newFormatColumns[swapIndex] = state.FormatColumns[index];
        newFormatColumns[index] = formatColumnToSwap;
        return Object.assign(Object.assign({}, state), {
          FormatColumns: newFormatColumns
        });
      }
    default:
      return state;
  }
};