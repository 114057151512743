import { ApiBase } from './ApiBase';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import * as SystemRedux from '../../Redux/ActionsReducers/SystemRedux';
import { SummaryOperation } from '../../PredefinedConfig/Common/Enums';
export class CellSummaryApiImpl extends ApiBase {
  openCellSummaryPopupSettingsPanel() {
    this.showModulePopup(ModuleConstants.CellSummaryModuleId);
  }
  getCurrentCellSummaryOperation() {
    return this.getAdaptableState().System.CellSummaryOperation;
  }
  getCellSummaryOperationDefinitions() {
    var _a;
    let cellSummaryOperations = (_a = this.getCellSummaryOptions().cellSummaryOperations) !== null && _a !== void 0 ? _a : [];
    return cellSummaryOperations;
  }
  setCurrentCellSummaryOperation(operation) {
    this.dispatchAction(SystemRedux.SystemCellSummaryChangeOperation(operation));
  }
  getCellSummaryOperationValue(operation) {
    const cellSummary = this.getAdaptableState().Grid.CellSummary;
    if (!cellSummary) {
      return 'N/A';
    }
    switch (operation) {
      case SummaryOperation.Sum:
        return cellSummary.Sum;
      case SummaryOperation.Average:
        return cellSummary.Average;
      case SummaryOperation.Median:
        return cellSummary.Median;
      case SummaryOperation.Mode:
        return cellSummary.Mode;
      case SummaryOperation.Max:
        return cellSummary.Max;
      case SummaryOperation.Min:
        return cellSummary.Min;
      case SummaryOperation.Distinct:
        return cellSummary.Distinct;
      case SummaryOperation.Count:
        return cellSummary.Count;
      case SummaryOperation.Std_Deviation:
        return cellSummary.Std_Deviation;
      case SummaryOperation.Only:
        return cellSummary.Only;
      case SummaryOperation.Weighted_Average:
        return cellSummary.Weighted_Average;
      default:
        return cellSummary[operation];
    }
  }
  getCurrentCellSummaryOperationValue() {
    const cellSummaryOperation = this.getCurrentCellSummaryOperation();
    const summaryOperation = typeof cellSummaryOperation === 'string' ? cellSummaryOperation : cellSummaryOperation.operationName;
    return this.getCellSummaryOperationValue(summaryOperation);
  }
}