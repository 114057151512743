import * as React from 'react';
import { useSelector } from 'react-redux';
import { DataSource, InfiniteTable } from '../../components/InfiniteTable';
import { GetAllNotesSelector } from '../../Redux/ActionsReducers/NoteRedux';
import dateFnsFormat from 'date-fns/format';
import { useAdaptable } from '../AdaptableContext';
import { AdaptableButtonComponent } from '../Components/AdaptableButton';
import { PopupPanel } from '../Components/Popups/AdaptablePopup/PopupPanel';
import { DEFAULT_DATE_FORMAT_PATTERN_WITH_TIME } from '../../Utilities/Constants/GeneralConstants';
import StringExtensions from '../../Utilities/Extensions/StringExtensions';
const tableDOMProps = {
  style: {
    height: '100%',
    minWidth: '10rem'
    // minHeight: 600,
  }
};
export const NotePopup = props => {
  var _a, _b, _c, _d;
  const adaptable = useAdaptable();
  const primaryKeyHeader = adaptable.api.columnApi.getPrimaryKeyColumn() ? adaptable.api.columnApi.getFriendlyNameForColumnId(adaptable.api.columnApi.getPrimaryKeyColumn().columnId) : StringExtensions.Humanize(adaptable.adaptableOptions.primaryKey);
  const dateFormat = (_d = (_c = (_b = (_a = adaptable.api.optionsApi) === null || _a === void 0 ? void 0 : _a.getNoteOptions) === null || _b === void 0 ? void 0 : _b.call(_a)) === null || _c === void 0 ? void 0 : _c.dateFormat) !== null && _d !== void 0 ? _d : DEFAULT_DATE_FORMAT_PATTERN_WITH_TIME;
  const allNotes = useSelector(state => GetAllNotesSelector(state.Note));
  const isReadOnlyModule = adaptable.api.entitlementApi.getEntitlementAccessLevelForModule('Note') === 'ReadOnly';
  const columnsMap = React.useMemo(() => {
    const columns = {
      primaryKey: {
        field: 'PrimaryKeyValue',
        header: primaryKeyHeader
      },
      column: {
        field: 'ColumnId',
        header: 'Column',
        valueGetter: params => {
          return adaptable.api.columnApi.getFriendlyNameForColumnId(params.data.ColumnId);
        }
      },
      text: {
        field: 'Text',
        header: 'Text',
        defaultEditable: !isReadOnlyModule,
        defaultFlex: 3
      },
      timestamp: {
        field: 'Timestamp',
        valueGetter: params => {
          return dateFnsFormat(params.data.Timestamp, dateFormat);
        }
      },
      delete: {
        defaultWidth: 50,
        render: params => {
          return React.createElement(AdaptableButtonComponent, {
            disabled: isReadOnlyModule,
            variant: "text",
            icon: "delete",
            onClick: () => {
              const note = adaptable.api.noteApi.getNoteByUuid(params.data.Uuid);
              adaptable.api.noteApi.deleteNote(note);
            }
          });
        }
      }
    };
    return columns;
  }, []);
  return React.createElement(PopupPanel, {
    headerText: 'Notes',
    glyphicon: 'import',
    infoLink: props.moduleInfo.HelpPage
  }, React.createElement(DataSource, {
    data: allNotes,
    primaryKey: "Uuid",
    onDataMutations: params => {
      for (const mutation of params.mutations) {
        const [uuid, changes] = mutation;
        for (const change of changes) {
          if (change.type === 'update') {
            const data = change.data;
            const prevNote = allNotes.find(x => x.Uuid === uuid);
            if (prevNote.Text !== data.Text) {
              adaptable.api.noteApi.updateNoteText(data.Text, prevNote);
            }
          }
        }
      }
    }
  }, React.createElement(InfiniteTable, {
    columnTypes: {
      default: {
        align: 'start',
        defaultFlex: 1,
        defaultSortable: false,
        style: {
          fontSize: 'var(--ab-font-size-3)'
        }
      }
    },
    headerOptions: {
      alwaysReserveSpaceForSortIcon: false
    },
    rowHeight: 40,
    columnHeaderHeight: 65,
    domProps: tableDOMProps,
    columns: columnsMap
  })));
};