export const buildActionColumnButton = (options, parentAdapTableApi, undoRowNode, clearRow) => {
  let changeHistoryButtons = options.changeHistoryButton;
  if (!changeHistoryButtons || Array.isArray(changeHistoryButtons) && changeHistoryButtons.length === 0) {
    return null;
  }
  if (!Array.isArray(changeHistoryButtons)) {
    changeHistoryButtons = [changeHistoryButtons];
  }
  const isGroupNode = context => context.adaptableApi.gridApi.isGroupRowNode(context.rowNode);
  const onUndo = context => {
    var _a;
    const rowNode = context.rowNode;
    if (context.adaptableApi.gridApi.isGroupRowNode(rowNode)) {
      // undo all children
      const childrenNodes = (_a = context.rowNode) === null || _a === void 0 ? void 0 : _a.childrenAfterFilter;
      if (childrenNodes) {
        childrenNodes.forEach(childNode => undoRowNode(childNode));
      }
    } else {
      undoRowNode(rowNode);
    }
  };
  const onClearRow = context => {
    const rowNode = context.rowNode;
    clearRow(rowNode);
  };
  const buildContext = context => {
    const dataChangedInfo = context.rowNode.data.changeInfo;
    return Object.assign(Object.assign({}, context), {
      isGroupNode: isGroupNode(context),
      undoDataChange: () => onUndo(context),
      clearRow: () => onClearRow(context),
      dataChangedInfo,
      parentAdapTableApi
    });
  };
  return changeHistoryButtons.map(actionColumnOption => ({
    label: (button, context) => {
      if (typeof actionColumnOption.label !== 'function') {
        return actionColumnOption.label;
      }
      return actionColumnOption.label(button, buildContext(context));
    },
    buttonStyle: (button, context) => {
      if (typeof actionColumnOption.buttonStyle !== 'function') {
        return actionColumnOption.buttonStyle;
      }
      return actionColumnOption.buttonStyle(button, buildContext(context));
    },
    tooltip: (button, context) => {
      if (typeof actionColumnOption.tooltip !== 'function') {
        return actionColumnOption.tooltip;
      }
      return actionColumnOption.tooltip(button, buildContext(context));
    },
    disabled: (button, context) => {
      if (typeof actionColumnOption.disabled !== 'function') {
        return actionColumnOption.disabled;
      }
      return actionColumnOption.disabled(button, buildContext(context));
    },
    onClick: (button, context) => {
      if (actionColumnOption.action === 'undo') {
        onUndo(buildContext(context));
      }
      if (actionColumnOption.action === 'clear') {
        onClearRow(buildContext(context));
      }
      if (typeof actionColumnOption.onClick === 'function') {
        actionColumnOption.onClick(button, buildContext(context));
      }
    },
    hidden: (button, context) => {
      if (typeof actionColumnOption.hidden !== 'function') {
        return actionColumnOption.hidden;
      }
      return actionColumnOption.hidden(button, buildContext(context));
    },
    icon: actionColumnOption.icon
  }));
};