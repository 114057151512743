import { ApiBase } from './ApiBase';
export class ColumnMenuApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
  }
  showColumnMenu(columnKey) {
    this.adaptable.agGridAdapter.getAgGridApi().showColumnMenu(columnKey);
  }
  hideColumnMenu() {
    this.adaptable.agGridAdapter.getAgGridApi().hidePopupMenu();
  }
}