import * as React from 'react';
import { Box, Flex } from 'rebass';
import { isAdaptableSystemIcon } from '../../../components/Icon';
import HelpBlock from '../../../components/HelpBlock';
import { Tag } from '../../../components/Tag';
import AdaptableInput from '../AdaptableInput';
import { PermitedValuesSelector } from '../PermittedValuesSelector';
import { AdaptableIconComponent } from '../AdaptableIconComponent';
import { Select } from '../../../components/Select';
const isValuesPredicateDef = colDef => colDef && ['Values', 'ExcludeValues'].includes(colDef.id);
export const PredicateEditor = props => {
  var _a, _b;
  const baseClassName = 'ab-PredicateEditor';
  const predicateDefsOptions = React.useMemo(() => {
    var _a;
    return (_a = props === null || props === void 0 ? void 0 : props.predicateDefs) === null || _a === void 0 ? void 0 : _a.map(item => ({
      value: item.id,
      label: item.label,
      inputs: item.inputs
    }));
  }, [props.predicateDefs, props.onChange]);
  const handlePredicateInputChange = (e, index) => {
    var _a;
    const {
      value
    } = e.target;
    const newInputs = [...((_a = props.predicate.Inputs) !== null && _a !== void 0 ? _a : [])];
    newInputs[index] = value;
    props.onChange(Object.assign(Object.assign({}, props.predicate), {
      Inputs: newInputs
    }));
  };
  const handlePredicateValuesChange = inputs => {
    props.onChange(Object.assign(Object.assign({}, props.predicate), {
      Inputs: inputs
    }));
  };
  const currentPredicateDef = (_a = props === null || props === void 0 ? void 0 : props.predicateDefs) === null || _a === void 0 ? void 0 : _a.find(item => {
    var _a;
    return item.id === ((_a = props.predicate) === null || _a === void 0 ? void 0 : _a.PredicateId);
  });
  let icon = null;
  if (currentPredicateDef) {
    if (isAdaptableSystemIcon(currentPredicateDef === null || currentPredicateDef === void 0 ? void 0 : currentPredicateDef.icon)) {
      icon = React.createElement(AdaptableIconComponent, {
        icon: currentPredicateDef.icon
      });
    } else if ((currentPredicateDef === null || currentPredicateDef === void 0 ? void 0 : currentPredicateDef.icon) && 'text' in (currentPredicateDef === null || currentPredicateDef === void 0 ? void 0 : currentPredicateDef.icon)) {
      icon = React.createElement("span", null, currentPredicateDef.icon.text);
    }
  }
  return React.createElement(Box, {
    className: baseClassName
  }, React.createElement(Flex, {
    justifyContent: "stretch",
    alignItems: "center"
  }, icon && React.createElement(Tag, {
    mr: 2
  }, icon), React.createElement(Select, {
    isClearable: true,
    options: predicateDefsOptions,
    "data-name": "select-predicate",
    placeholder: "Select Rule",
    value: currentPredicateDef === null || currentPredicateDef === void 0 ? void 0 : currentPredicateDef.id,
    onChange: value => {
      var _a;
      const option = predicateDefsOptions.find(x => x.value === value);
      props.onChange(Object.assign(Object.assign({}, props.predicate), {
        PredicateId: option === null || option === void 0 ? void 0 : option.value,
        Inputs: ((_a = option === null || option === void 0 ? void 0 : option.inputs) !== null && _a !== void 0 ? _a : []).map(input => {
          var _a;
          return (_a = input.defaultValue) !== null && _a !== void 0 ? _a : '';
        })
      }));
    }
  })), React.createElement(Flex, null, (_b = currentPredicateDef === null || currentPredicateDef === void 0 ? void 0 : currentPredicateDef.inputs) === null || _b === void 0 ? void 0 : _b.map((predicateDefInput, index) => {
    var _a, _b;
    return React.createElement(React.Fragment, null, index > 0 && React.createElement(HelpBlock, {
      margin: 2
    }, "AND"), React.createElement(Flex, {
      key: index,
      flex: 1,
      flexDirection: "column"
    }, React.createElement(AdaptableInput, {
      "data-name": `predicate-input-${index}`,
      marginTop: 2,
      type: predicateDefInput.type,
      autoFocus: index === 0,
      value: (_b = (_a = props.predicate.Inputs) === null || _a === void 0 ? void 0 : _a[index]) !== null && _b !== void 0 ? _b : '',
      onChange: e => handlePredicateInputChange(e, index)
    })));
  })), isValuesPredicateDef(currentPredicateDef) && React.createElement(PermitedValuesSelector, {
    onPredicateValuesChange: handlePredicateValuesChange,
    predicate: props.predicate,
    columnId: props.columnId
  }));
};