import * as React from 'react';
import { Box, Flex } from 'rebass';
import { CheckBox } from '../../../../components/CheckBox';
import DropdownButton from '../../../../components/DropdownButton';
import FormLayout, { FormRow } from '../../../../components/FormLayout';
import HelpBlock from '../../../../components/HelpBlock';
import StringExtensions from '../../../../Utilities/Extensions/StringExtensions';
const ALL_SIDEBAR_OPTIONS = ['columns', 'filters', 'adaptable'];
const prepareSidebarDef = sideBar => {
  sideBar = sideBar;
  if (typeof sideBar === 'boolean') {
    sideBar = {
      toolPanels: ALL_SIDEBAR_OPTIONS
    };
  }
  if (typeof sideBar === 'string') {
    sideBar = {
      toolPanels: [sideBar]
    };
  }
  if (Array.isArray(sideBar)) {
    sideBar = {
      toolPanels: sideBar
    };
  }
  return sideBar !== null && sideBar !== void 0 ? sideBar : {
    toolPanels: []
  };
};
const isSidebarChecked = (sideBar, panelName) => {
  var _a;
  return ((_a = sideBar === null || sideBar === void 0 ? void 0 : sideBar.toolPanels) !== null && _a !== void 0 ? _a : []).some(panel => {
    const panelId = typeof panel === 'string' ? panel : panel.id;
    return panelId === panelName;
  });
};
export const UIOptionsSidebarForm = props => {
  var _a;
  const {
    gridOptions,
    onChange
  } = props;
  const sideBar = prepareSidebarDef(gridOptions.sideBar);
  const renderSidebarCheckbox = sidebarName => {
    return React.createElement(CheckBox, {
      mr: 3,
      key: sidebarName,
      checked: isSidebarChecked(sideBar, sidebarName),
      onChange: check => handleToolpanelsChange(sidebarName, check)
    }, StringExtensions.Humanize(sidebarName));
  };
  const handleToolpanelsChange = (panelName, check) => {
    var _a, _b;
    let newPanels = (_a = sideBar === null || sideBar === void 0 ? void 0 : sideBar.toolPanels) !== null && _a !== void 0 ? _a : [];
    if (check) {
      newPanels = [...newPanels, panelName];
    } else {
      newPanels = newPanels.filter(panel => {
        const panelId = typeof panel === 'string' ? panel : panel.id;
        return panelId !== panelName;
      });
    }
    let hiddenByDefault = (_b = sideBar.hiddenByDefault) !== null && _b !== void 0 ? _b : false;
    if (newPanels.length === 0) {
      hiddenByDefault = true;
    }
    const newGridOptions = Object.assign(Object.assign({}, gridOptions), {
      sideBar: Object.assign(Object.assign({}, sideBar), {
        toolPanels: newPanels,
        hiddenByDefault
      })
    });
    props.onChange(newGridOptions);
  };
  let sidebarPosition = (_a = sideBar === null || sideBar === void 0 ? void 0 : sideBar.position) !== null && _a !== void 0 ? _a : 'right';
  const handleSidebarPositionChange = position => {
    const newGridOptions = Object.assign(Object.assign({}, gridOptions), {
      sideBar: Object.assign(Object.assign({}, sideBar), {
        position
      })
    });
    props.onChange(newGridOptions);
  };
  return React.createElement(Box, null, React.createElement(HelpBlock, null, "Tool Panels"), React.createElement(Box, {
    m: 2
  }, React.createElement(Flex, {
    mb: 2
  }, ALL_SIDEBAR_OPTIONS.map(renderSidebarCheckbox)), React.createElement(FormLayout, {
    columns: [{
      name: 'label'
    }, {
      name: 'children'
    }]
  }, React.createElement(FormRow, {
    label: "Position"
  }, React.createElement(DropdownButton, {
    columns: ['label'],
    items: [{
      label: 'Left',
      onClick: () => handleSidebarPositionChange('left')
    }, {
      label: 'Right',
      onClick: () => handleSidebarPositionChange('right')
    }]
  }, sidebarPosition === 'left' ? 'Left' : 'Right')))));
};