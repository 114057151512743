import * as ReactDOMClient from 'react-dom/client';
// we need to keep track of the roots to ensure we create a single root per container
// otherwise we get: "You are calling ReactDOMClient.createRoot() on a container that has already been passed to createRoot() before. Instead, call root.render() on the existing root instead if you want to update it."
const renderWeakMap = new WeakMap();
export const renderReactRoot = (el, container) => {
  // Keeping a reference to previous roots causes an error.
  // Already (correctly) unmounted elements are not unmounted again, and triggers an error.
  let root = renderWeakMap.get(container);
  if (!root) {
    renderWeakMap.set(container, root = ReactDOMClient.createRoot(container));
  }
  // const root = ReactDOMClient.createRoot(container);
  root.render(el);
  return () => {
    // requestAnimationFrame(() => {
    //   // This pervents this warning:
    //   // Warning: Attempted to synchronously unmount a root while React was already rendering. React cannot finish unmounting the root until the current render has completed, which may lead to a race condition.
    //   // https:stackoverflow.com/questions/73459382/react-18-async-way-to-unmount-root
    // });
    // This needs to be synchronous, if not, in a raf it may be to late, the element may be gone.
    // To reproduce, move this in RAF and play with filters.
    root.unmount();
    renderWeakMap.delete(container);
  };
};