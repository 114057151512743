import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import { ApiBase } from './ApiBase';
import * as TeamSharingRedux from '../../Redux/ActionsReducers/TeamSharingRedux';
import { TeamSharingCheckForUpdates } from '../../Redux/ActionsReducers/TeamSharingRedux';
import { TeamSharingInternalApi } from '../Internal/TeamSharingInternalApi';
import { isAdaptableSharedEntity, isCustomSharedEntity } from '../../PredefinedConfig/TeamSharingState';
export class TeamSharingApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.internalApi = new TeamSharingInternalApi(adaptable);
  }
  async loadSharedEntities() {
    if (!this.isTeamSharingAvailable()) {
      this.logWarn('Team Sharing is not Activated so Entities cannot be loaded');
      return [];
    }
    const teamSharingOptions = this.adaptable.adaptableOptions.teamSharingOptions;
    const sharedEntitiesContext = this.getAdaptableApi().internalApi.buildBaseContext();
    let loadedSharedEntities = await teamSharingOptions.loadSharedEntities(sharedEntitiesContext);
    // with v15 we introduced 2 types of SharedEntity: AdaptableSharedEntity (basically the old one) and the new CustomSharedEntity
    // for backwards compatibility, we add default the 'EntityType' property to 'adaptableEntity'
    loadedSharedEntities = loadedSharedEntities.map(sharedEntity => {
      if (sharedEntity.EntityType !== 'adaptableEntity' && sharedEntity.EntityType !== 'customEntity') {
        // @ts-ignore
        sharedEntity.EntityType = 'adaptableEntity';
      }
      return sharedEntity;
    });
    const sharedEntities = teamSharingOptions.applySharedEntities(loadedSharedEntities, sharedEntitiesContext);
    return sharedEntities;
  }
  async persistSharedEntities(sharedEntities) {
    if (!this.isTeamSharingAvailable()) {
      this.logWarn('Team Sharing is not Activated so Entities cannot be shared');
      return false;
    }
    const teamSharingOptions = this.adaptable.adaptableOptions.teamSharingOptions;
    const sharedEntitiesContext = this.getAdaptableApi().internalApi.buildBaseContext();
    const sharedEntitiesAfterCustomisation = teamSharingOptions.saveSharedEntities(sharedEntities, sharedEntitiesContext);
    await teamSharingOptions.persistSharedEntities(sharedEntitiesAfterCustomisation, sharedEntitiesContext);
    return true;
  }
  isTeamSharingAvailable() {
    var _a, _b, _c;
    return !this.getAdaptableApi().entitlementApi.isModuleHiddenEntitlement(ModuleConstants.TeamSharingModuleId) && ((_a = this.adaptable.adaptableOptions.teamSharingOptions) === null || _a === void 0 ? void 0 : _a.enableTeamSharing) && !!((_b = this.adaptable.adaptableOptions.teamSharingOptions) === null || _b === void 0 ? void 0 : _b.loadSharedEntities) && !!((_c = this.adaptable.adaptableOptions.teamSharingOptions) === null || _c === void 0 ? void 0 : _c.persistSharedEntities);
  }
  hasTeamSharingFullRights() {
    return this.getAdaptableApi().entitlementApi.isModuleFullEntitlement(ModuleConstants.TeamSharingModuleId);
  }
  checkForUpdates() {
    this.dispatchAction(TeamSharingCheckForUpdates());
  }
  openTeamSharingSettingsPanel() {
    this.showModulePopup(ModuleConstants.TeamSharingModuleId);
  }
  shareAdaptableEntity(adaptableObject, module, sharedEntityConfig) {
    if (!this.isTeamSharingAvailable()) {
      this.logWarn('Team Sharing is not Activated so Entities cannot be shared');
      return;
    }
    this.dispatchAction(TeamSharingRedux.TeamSharingShare(adaptableObject, module, sharedEntityConfig));
  }
  shareCustomEntity(customObject, sharedEntityConfig) {
    if (!this.isTeamSharingAvailable()) {
      this.logWarn('Team Sharing is not Activated so Entities cannot be shared');
      return;
    }
    this.dispatchAction(TeamSharingRedux.TeamSharingShareCustom(customObject, sharedEntityConfig));
  }
  unshareEntity(entityId) {
    if (!this.isTeamSharingAvailable()) {
      this.logWarn('Team Sharing is not Activated so Entities cannot be unshared');
      return;
    }
    this.dispatchAction(TeamSharingRedux.TeamSharingRemoveItem(entityId));
  }
  importSharedEntry(sharedEntity) {
    if (isAdaptableSharedEntity(sharedEntity)) {
      this.dispatchAction(TeamSharingRedux.TeamSharingImportItem(sharedEntity));
    } else {
      const customSharedEntityImportHandler = this.getAdaptableApi().optionsApi.getTeamSharingOptions().handleCustomSharedEntityImport;
      if (!customSharedEntityImportHandler) {
        this.logError(`Could NOT import custom shared entity because 'TeamSharingOptions.handleCustomSharedEntityImport(...)' is NOT defined!`, sharedEntity);
        return;
      }
      customSharedEntityImportHandler(sharedEntity, this.getAdaptableApi().internalApi.buildBaseContext());
    }
  }
  getTeamSharingState() {
    return this.getAdaptableState().TeamSharing;
  }
  getLoadedSharedEntities() {
    return this.getTeamSharingState().SharedEntities;
  }
  getLoadedAdaptableSharedEntities() {
    return this.getLoadedSharedEntities().filter(isAdaptableSharedEntity);
  }
  getLoadedCustomSharedEntities() {
    return this.getLoadedSharedEntities().filter(isCustomSharedEntity);
  }
  refreshTeamSharing() {
    this.dispatchAction(TeamSharingRedux.TeamSharingGet());
  }
}