import toNumber from 'lodash/toNumber';
import { _agGridApi_getValue } from './AgGridAdapter';
export const getNumericValue = input => {
  if (typeof input === 'number') {
    return input;
  }
  const numericValue = toNumber(input);
  return isNaN(numericValue) ? null : numericValue;
};
export const weightedAverage = (params, columnId, weightColumnId) => {
  const {
    api: gridApi,
    rowNode: groupRowNode
  } = params;
  let weightedColumnValueSum = 0;
  let columnValueSum = 0;
  // TODO AFL: improve performance by using the intermediary aggregated values (for nested groups)
  groupRowNode.allLeafChildren.forEach(rowNode => {
    // when editing values might be converted to strings
    const rawColumnValue = _agGridApi_getValue(columnId, rowNode, gridApi);
    const columnValue = getNumericValue(rawColumnValue);
    const rawWeightedColumnValue = _agGridApi_getValue(weightColumnId, rowNode, gridApi);
    const weightedColumnValue = getNumericValue(rawWeightedColumnValue);
    if (weightedColumnValue !== null) {
      weightedColumnValueSum += weightedColumnValue;
    }
    if (columnValue !== null) {
      columnValueSum += columnValue * weightedColumnValue;
    }
  });
  return {
    toString: () => {
      const result = columnValueSum / weightedColumnValueSum;
      // 0 / 0 = NaN
      if (isNaN(result)) {
        return '';
      }
      return result;
    },
    valueOf: () => {
      const result = columnValueSum / weightedColumnValueSum;
      // 0 / 0 = NaN
      if (isNaN(result)) {
        return 0;
      }
      return result;
    },
    [columnId]: columnValueSum,
    [weightColumnId]: weightedColumnValueSum
  };
};