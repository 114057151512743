import * as React from 'react';
import { Box } from 'rebass';
import SimpleButton from '../../components/SimpleButton';
import { useAdaptable } from '../AdaptableContext';
import { AdaptableObjectListItem } from '../Components/AdaptableObjectList/AdaptableObjectList';
export const Dependencies = props => {
  const adaptable = useAdaptable();
  const dependencies = adaptable.api.teamSharingApi.internalApi.getSharedEntryDependencies(props.sharedEntity);
  return React.createElement(React.Fragment, null, dependencies.map(sharedEntity => {
    const module = adaptable.ModuleService.getModuleById('TeamSharing');
    const itemView = module.toView(sharedEntity);
    return React.createElement(Box, {
      as: "ul",
      padding: 0,
      key: sharedEntity.Uuid,
      className: "ab-Shared-Entity-Shared-Object",
      mb: 2
    }, React.createElement(AdaptableObjectListItem, {
      deleteTooltip: 'Cannot delete dependencies.',
      deleteDisabled: true,
      module: module,
      data: itemView
    }));
  }));
};
export const SharedEntityDependencies = props => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  return React.createElement(React.Fragment, null, React.createElement(Box, {
    mb: 3,
    mt: 2
  }, isExpanded ? React.createElement(SimpleButton, {
    onClick: () => setIsExpanded(false),
    icon: "arrow-up"
  }, "Collapse") : React.createElement(SimpleButton, {
    onClick: () => setIsExpanded(true),
    icon: "arrow-down"
  }, "Expand")), isExpanded && React.createElement(Dependencies, {
    sharedEntity: props.data
  }));
};