import * as React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Box, Flex } from 'rebass';
import { Icon } from '../icons';
import SimpleButton from '../SimpleButton';
import { DragAndDropContext } from './DragAndDropContext';
export function TabList({
  tabs,
  onRemoveTab,
  onRemoveToolbar,
  onChangeTabName,
  disabled
}) {
  return React.createElement(Droppable, {
    droppableId: "MAIN",
    type: "TAB",
    direction: "horizontal"
  }, provided => React.createElement("div", Object.assign({}, provided.droppableProps, {
    className: "ab-ModuleSelector__TabList",
    ref: provided.innerRef,
    style: {
      display: 'flex'
    },
    "data-name": "dashboard-tabs"
  }), tabs.map((tab, tabIndex) => React.createElement(TabItem, {
    disabled: disabled,
    key: tabIndex,
    tabIndex: tabIndex,
    tab: tab,
    onRemove: () => onRemoveTab(tabIndex),
    onRemoveToolbar: toolbarIndex => onRemoveToolbar(tabIndex, toolbarIndex),
    onChangeTabName: tabName => onChangeTabName(tabIndex, tabName)
  })), provided.placeholder));
}
export function TabItem({
  tab,
  tabIndex,
  onRemove,
  onRemoveToolbar,
  onChangeTabName,
  disabled
}) {
  const context = React.useContext(DragAndDropContext);
  const isDragDisabled = disabled || !context.permittedActions.dragAndDropTab;
  return React.createElement(Draggable, {
    isDragDisabled: isDragDisabled,
    draggableId: String(tabIndex),
    index: tabIndex
  }, provided => React.createElement("div", Object.assign({
    className: "ab-ModuleSelector__TabItem",
    "data-name": "dashboard-tab",
    ref: provided.innerRef
  }, provided.draggableProps, {
    style: Object.assign({}, isDragDisabled ? {} : provided.draggableProps.style)
  }), React.createElement("div", {
    className: "ab-ModuleSelector__TabItem__header"
  }, React.createElement("div", Object.assign({}, disabled ? {} : provided.dragHandleProps, {
    style: context.permittedActions.dragAndDropTab ? {} : {
      display: 'none'
    }
  }), React.createElement(Icon, {
    name: "drag"
  })), context.permittedActions.editTabName ? React.createElement("input", {
    className: "ab-ModuleSelector__TabItem__header-input",
    type: "text",
    disabled: disabled,
    value: tab.Name,
    onChange: event => {
      onChangeTabName(event.target.value);
    }
  }) : tab.Name, context.permittedActions.deleteTab && React.createElement(SimpleButton, {
    disabled: disabled,
    icon: "delete",
    variant: "text",
    onClick: onRemove
  })), React.createElement(ToolbarList, {
    disabled: disabled,
    toolbars: tab.Items,
    droppableId: String(tabIndex),
    onRemove: onRemoveToolbar
  })));
}
export function ToolbarList({
  toolbars,
  droppableId,
  onRemove,
  disabled
}) {
  return React.createElement(Droppable, {
    droppableId: droppableId,
    type: "TOOLBAR"
  }, (provided, snapshot) => React.createElement(Box, Object.assign({
    className: "ab-ModuleSelector__ToolbarList",
    ref: provided.innerRef
  }, disabled ? {} : provided.droppableProps, {
    "data-name": "dashboard-toolbar-drop-target",
    backgroundColor: snapshot.isDraggingOver ? 'var(--ab-dashboard-tab-drop-target__background)' : ''
  }), toolbars === null || toolbars === void 0 ? void 0 : toolbars.map((toolbar, toolbarIndex) => React.createElement(ToolbarItem, {
    disabled: disabled,
    key: toolbar,
    toolbar: toolbar,
    toolbarIndex: toolbarIndex,
    onRemove: () => onRemove(toolbarIndex)
  })), provided.placeholder));
}
export function ToolbarItem({
  toolbar,
  toolbarIndex,
  onRemove,
  disabled
}) {
  const {
    availableItems
  } = React.useContext(DragAndDropContext);
  let currentItem = availableItems.find(t => t.Id === toolbar);
  const title = currentItem ? currentItem.Title : toolbar;
  return React.createElement(Draggable, {
    isDragDisabled: disabled,
    draggableId: toolbar,
    index: toolbarIndex
  }, (provided, snapshot) => React.createElement(Flex, Object.assign({
    className: "ab-ModuleSelector__ToolbarItem",
    alignItems: "center",
    mb: 1,
    pl: 1,
    backgroundColor: snapshot.isDragging ? 'var(--ab-dashboard-toolbar-drag__background)' : 'var(--ab-color-primarylight)',
    ref: provided.innerRef
  }, provided.draggableProps, provided.dragHandleProps, {
    style: provided.draggableProps.style
  }), React.createElement("div", {
    style: {
      flex: 1
    }
  }, title), React.createElement(SimpleButton, {
    disabled: disabled,
    icon: "close",
    variant: "text",
    padding: 1,
    onClick: onRemove
  })));
}