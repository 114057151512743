import { ApiBase } from './ApiBase';
import * as NoteRedux from '../../Redux/ActionsReducers/NoteRedux';
import { NoteInternalApi } from '../Internal/NoteInternalApi';
export class NoteApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.internalApi = new NoteInternalApi(adaptable);
  }
  addNote(noteStr, primaryKeyValue, columnId) {
    const note = {
      Text: noteStr,
      PrimaryKeyValue: primaryKeyValue,
      ColumnId: columnId,
      Timestamp: Date.now()
    };
    this.dispatchAction(NoteRedux.NoteAdd(note));
  }
  editNote(note) {
    note.Timestamp = Date.now();
    this.dispatchAction(NoteRedux.NoteEdit(note));
  }
  updateNoteText(noteStr, note) {
    this.editNote(Object.assign(Object.assign({}, note), {
      Text: noteStr
    }));
  }
  deleteNote(note) {
    this.dispatchAction(NoteRedux.NoteDelete(note));
  }
  getNoteState() {
    return this.getAdaptableState().Note;
  }
  getAllNotes() {
    return this.getNoteState().Notes;
  }
  getNoteForCell(address) {
    return NoteRedux.GetNoteSelector(this.getAdaptableState().Note, address);
  }
  getNoteByUuid(uuid) {
    return this.getAllNotes().find(note => note.Uuid === uuid);
  }
}