import * as React from 'react';
import SimpleButton from '../../../components/SimpleButton';
export const ButtonNew = props => {
  return React.createElement(SimpleButton, Object.assign({
    "data-name": "new",
    tooltip: "New",
    tone: "accent",
    icon: "plus",
    variant: "raised"
  }, props), props.children === undefined ? 'New' : props.children);
};