import * as React from 'react';
import { connect } from 'react-redux';
import * as ThemeRedux from '../../Redux/ActionsReducers/ThemeRedux';
import { PopupPanel } from '../Components/Popups/AdaptablePopup/PopupPanel';
import FormLayout, { FormRow } from '../../components/FormLayout';
import DropdownButton from '../../components/DropdownButton';
import { ThemeEditor } from './ThemeEditor';
import { Box } from 'rebass';
import ObjectFactory from '../../Utilities/ObjectFactory';
import SimpleButton from '../../components/SimpleButton';
import { Icon } from '../../components/icons';
import { ThemeSelector } from './ThemeSelector';
class ThemePopupComponent extends React.Component {
  constructor() {
    super(...arguments);
    this.handleCreateNewTheme = variant => {
      let nthItem = this.props.UserThemes.length + 1;
      let name = 'Custom-Theme-' + nthItem;
      // make sure it is unique
      while (this.props.UserThemes.some(theme => theme.Name === name)) {
        nthItem++;
        name = 'Custom-Theme-' + nthItem;
      }
      const newTheme = ObjectFactory.CreateEmptyTheme(name);
      newTheme.Variant = variant;
      this.props.api.themeApi.addUserTheme(newTheme);
      this.props.api.themeApi.loadTheme(newTheme.Name);
    };
  }
  render() {
    const isCustomTheme = this.props.api.themeApi.getUserThemes().some(theme => theme.Name === this.props.CurrentTheme);
    const disabled = this.props.accessLevel === 'ReadOnly';
    const newButton = React.createElement(DropdownButton, {
      ml: 2,
      disabled: disabled,
      tone: "accent",
      variant: "raised",
      columns: ['label'],
      items: [{
        label: 'Dark',
        onClick: () => this.handleCreateNewTheme('dark')
      }, {
        label: 'Light',
        onClick: () => this.handleCreateNewTheme('light')
      }]
    }, React.createElement(Icon, {
      name: "plus"
    }), " New");
    return React.createElement(PopupPanel, {
      headerText: this.props.moduleInfo.FriendlyName,
      glyphicon: this.props.moduleInfo.Glyph,
      infoLink: this.props.moduleInfo.HelpPage,
      infoLinkDisabled: !this.props.api.internalApi.isDocumentationLinksDisplayed(),
      button: React.createElement(React.Fragment, null, !this.props.hideShowInWindow && React.createElement(SimpleButton, {
        icon: "open-in-new",
        onClick: () => {
          this.props.api.themeApi.internalApi.openInWindow();
        }
      }), newButton)
    }, React.createElement(FormLayout, null, React.createElement(FormRow, {
      label: "Current Theme:"
    }, React.createElement(Box, {
      maxWidth: 150
    }, React.createElement(ThemeSelector, null)))), isCustomTheme && React.createElement(Box, {
      mt: 3
    }, React.createElement(ThemeEditor, {
      accessLevel: this.props.accessLevel,
      theme: this.props.CurrentTheme
    })));
  }
  onChangeTheme(value) {
    this.props.SelectTheme(value);
  }
}
function mapStateToProps(state, ownProps) {
  return {
    CurrentTheme: state.Theme.CurrentTheme,
    UserThemes: state.Theme.UserThemes
  };
}
function mapDispatchToProps(dispatch) {
  return {
    SelectTheme: newTheme => dispatch(ThemeRedux.ThemeSelect(newTheme))
  };
}
export let ThemePopup = connect(mapStateToProps, mapDispatchToProps)(ThemePopupComponent);