import * as React from 'react';
import { useSelector } from 'react-redux';
import { Box } from 'rebass';
import { Select } from '../../components/Select';
import { useAdaptable } from '../AdaptableContext';
export const LayoutStatusbar = () => {
  const adaptable = useAdaptable();
  const layouts = adaptable.api.layoutApi.getLayouts();
  const options = layouts.map(layout => ({
    label: layout.Name,
    value: layout.Name
  }));
  const currentLayout = useSelector(state => state.Layout.CurrentLayout);
  return React.createElement(Box, {
    onClick: event => {
      event.stopPropagation();
    }
  }, React.createElement(Select, {
    size: "small",
    options: options,
    value: currentLayout,
    onChange: value => {
      adaptable.api.layoutApi.setLayout(value);
    }
  }));
};