import * as LayoutRedux from '../../Redux/ActionsReducers/LayoutRedux';
import { WINDOW_GRID_FILTER_EDITOR } from '../../View/Components/Popups/WindowPopups/windowFactory';
import { GridFilterInternalApi } from '../Internal/GridFilterInternalApi';
import { ApiBase } from './ApiBase';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
export class GridFilterApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.internalApi = new GridFilterInternalApi(adaptable);
  }
  getCurrentGridFilter() {
    var _a;
    return (_a = this.getLayoutApi().getCurrentLayout()) === null || _a === void 0 ? void 0 : _a.GridFilter;
  }
  getCurrentGridFilterExpression() {
    const gridFilter = this.getCurrentGridFilter();
    return gridFilter && !gridFilter.IsSuspended ? gridFilter.Expression : '';
  }
  setGridFilterExpression(filter) {
    this.dispatchAction(LayoutRedux.LayoutGridFilterSet(filter));
  }
  clearGridFilter() {
    this.dispatchAction(LayoutRedux.LayoutGridFilterClear());
  }
  openUIEditorForGridFilter(expression) {
    const preparedQuery = expression !== null && expression !== void 0 ? expression : this.getCurrentGridFilterExpression();
    this.getAdaptableApi().internalApi.showPopupWindow({
      id: WINDOW_GRID_FILTER_EDITOR,
      title: ModuleConstants.GridFilterFriendlyName,
      icon: 'filter-list',
      factoryId: WINDOW_GRID_FILTER_EDITOR,
      popupProps: {
        value: preparedQuery
      }
    });
  }
  suspendGridFilter() {
    const gridFilter = this.getCurrentGridFilter();
    if (gridFilter && !gridFilter.IsSuspended) {
      this.dispatchAction(LayoutRedux.LayoutGridFilterSuspend());
    }
  }
  unSuspendGridFilter() {
    const gridFilter = this.getCurrentGridFilter();
    if (gridFilter && gridFilter.IsSuspended) {
      this.dispatchAction(LayoutRedux.LayoutGridFilterUnSuspend());
    }
  }
}