import * as React from 'react';
import { toStyle } from '../../../Utilities/Helpers/StyleHelper';
import { AdaptableIconComponent } from '../AdaptableIconComponent';
export const Badge = props => {
  const {
    iconPosition = 'start',
    icon: iconProps
  } = props;
  const preparedBadgePosition = iconPosition.toLowerCase();
  const icon = iconProps && React.createElement(AdaptableIconComponent, {
    icon: iconProps
  });
  const style = toStyle(props.adaptableStyle);
  return React.createElement("div", {
    className: "ab-Badge",
    style: Object.assign(Object.assign({}, style), {
      marginRight: props.gap
    })
  }, preparedBadgePosition === 'start' && icon, props.children, preparedBadgePosition === 'end' && icon);
};