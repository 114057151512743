import * as React from 'react';
import SimpleButton from '../../../components/SimpleButton';
export class ButtonMinimise extends React.Component {
  render() {
    return React.createElement(SimpleButton, Object.assign({
      "data-name": "minimise",
      iconSize: 20,
      tooltip: "Minimise"
    }, this.props, {
      variant: "text",
      icon: "arrow-up"
    }));
  }
}