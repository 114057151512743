import { ApiBase } from './ApiBase';
import ArrayExtensions from '../../Utilities/Extensions/ArrayExtensions';
import { DARK_GREEN, getHexForName, GRAY, RED } from '../../View/UIHelper';
export class ColumnScopeApiImpl extends ApiBase {
  isColumnInScope(column, scope) {
    if (!column) {
      return false;
    }
    if ('All' in scope) {
      // do we need a true check here?
      return true;
    }
    if ('DataTypes' in scope && scope.DataTypes.includes(column.dataType)) {
      return true;
    }
    if ('ColumnTypes' in scope && scope.ColumnTypes.some(columnType => {
      var _a;
      return (_a = column.columnTypes) === null || _a === void 0 ? void 0 : _a.includes(columnType);
    })) {
      return true;
    }
    if ('ColumnIds' in scope && scope.ColumnIds.includes(column.columnId)) {
      return true;
    }
    return false;
  }
  getColumnsForScope(scope) {
    if (scope == undefined) {
      return [];
    }
    const columns = this.getAdaptableApi().columnApi.getColumns();
    if ('All' in scope) {
      return columns;
    }
    return columns.filter(c => {
      return this.isColumnInScope(c, scope);
    });
  }
  getScopeDescription(scope) {
    if (scope === undefined) {
      return 'Nothing';
    }
    if ('All' in scope) {
      // do we need a true check here?
      return 'Columns: All';
    }
    if ('ColumnTypes' in scope) {
      return (scope.ColumnTypes.length > 0 ? 'ColumnTypes' : 'ColumnType') + ': ' + scope.ColumnTypes.join(', ');
    }
    if ('DataTypes' in scope) {
      return (scope.DataTypes.length > 0 ? 'DataTypes' : 'DataType') + ': ' + scope.DataTypes.join(', ');
    }
    if ('ColumnIds' in scope) {
      return (scope.ColumnIds.length > 1 ? 'Columns' : 'Column') + ': ' + this.getAdaptableApi().columnApi.getFriendlyNamesForColumnIds(scope.ColumnIds).map(c => {
        return '"' + c + '"';
      }).join(', ');
    }
  }
  scopeIsEmpty(scope) {
    return scope === undefined;
  }
  scopeIsAll(scope) {
    return scope !== undefined && 'All' in scope;
  }
  scopeHasDataType(scope) {
    return scope !== undefined && 'DataTypes' in scope;
  }
  scopeHasColumnType(scope) {
    return scope !== undefined && 'ColumnTypes' in scope;
  }
  scopeHasOnlyBooleanDataType(scope) {
    var _a;
    return 'DataTypes' in scope && ((_a = scope.DataTypes) === null || _a === void 0 ? void 0 : _a.length) == 1 && scope.DataTypes[0] == 'Boolean';
  }
  scopeHasColumns(scope) {
    return scope !== undefined && 'ColumnIds' in scope;
  }
  isSingleColumnScope(scope) {
    return this.scopeHasColumns(scope) && !this.scopeHasDataType(scope) && !this.scopeIsAll(scope) && 'ColumnIds' in scope && scope.ColumnIds.length == 1;
  }
  getSingleColumnInScope(scope) {
    return this.isSingleColumnScope(scope) && 'ColumnIds' in scope ? scope.ColumnIds[0] : undefined;
  }
  isSingleNumericColumnScope(scope) {
    return this.scopeHasColumns(scope) && !this.scopeHasDataType(scope) && !this.scopeIsAll(scope) && 'ColumnIds' in scope && scope.ColumnIds.length == 1 && this.getAdaptableApi().columnApi.hasNumberDataType(scope.ColumnIds[0]);
  }
  isSingleBooleanColumnScope(scope) {
    return this.scopeHasColumns(scope) && !this.scopeHasDataType(scope) && !this.scopeIsAll(scope) && 'ColumnIds' in scope && scope.ColumnIds.length == 1 && this.getAdaptableApi().columnApi.hasBooleanDataType(scope.ColumnIds[0]);
  }
  areAllBooleanColumnsInScope(scope) {
    return this.scopeHasColumns(scope) && !this.scopeHasDataType(scope) && !this.scopeIsAll(scope) && 'ColumnIds' in scope && scope.ColumnIds.length && scope.ColumnIds.every(columnId => this.getAdaptableApi().columnApi.hasBooleanDataType(columnId));
  }
  isColumnInScopeColumns(column, scope) {
    return this.scopeHasColumns(scope) && this.isColumnInScope(column, scope);
  }
  isPrimaryKeyColumnInScopeColumns(scope) {
    return 'ColumnIds' in scope && scope.ColumnIds.length == 1 && scope.ColumnIds.find(c => c == this.getAdaptableApi().optionsApi.getPrimaryKey()) != null;
  }
  getScopeToString(scope) {
    if ('All' in scope) {
      return 'Columns: All';
    }
    if ('ColumnTypes' in scope) {
      return 'ColumnTypes: ' + scope.ColumnTypes.join(',');
    }
    if ('DataTypes' in scope) {
      return 'DataTypes: ' + scope.DataTypes.join(',');
    }
    if ('ColumnIds' in scope) {
      let text = scope.ColumnIds.length == 1 ? 'Column: ' : 'Columns: ';
      return text + this.getAdaptableApi().columnApi.getFriendlyNamesForColumnIds(scope.ColumnIds).map(c => {
        return '"' + c + '"';
      }).join(', ');
    }
  }
  getColumnIdsInScope(scope) {
    if (scope !== undefined && 'ColumnIds' in scope) {
      return scope.ColumnIds;
    }
    return undefined;
  }
  getColumnTypesInScope(scope) {
    if (scope !== undefined && 'ColumnTypes' in scope) {
      return scope.ColumnTypes;
    }
    return undefined;
  }
  getDataTypesInScope(scope) {
    if (scope !== undefined && 'DataTypes' in scope) {
      return scope.DataTypes;
    }
    return undefined;
  }
  isColumnInNumericScope(column, scope) {
    var _a, _b;
    // if column is not even numeric then return false
    if (column == null || column == undefined || column.dataType !== 'Number') {
      return false;
    }
    // if no scope then return false
    if (scope == undefined) {
      return false;
    }
    // check if the scope has ColumnIds and whether this column is contained
    if ('ColumnIds' in scope && scope.ColumnIds.includes(column.columnId)) {
      return true;
    }
    // check if the scope has ColumnIds and whether this column is contained
    if ('DataTypes' in scope && scope.DataTypes.includes('Number')) {
      return true;
    }
    if ('ColumnTypes' in scope && ((_b = (_a = scope.ColumnTypes) === null || _a === void 0 ? void 0 : _a.some) === null || _b === void 0 ? void 0 : _b.call(_a, scopeColumnType => {
      var _a, _b;
      return (_b = (_a = column.columnTypes) === null || _a === void 0 ? void 0 : _a.includes) === null || _b === void 0 ? void 0 : _b.call(_a, scopeColumnType);
    }))) {
      return true;
    }
    return false;
  }
  isColumnInStringsScope(column, scope) {
    var _a, _b;
    // if column is not even string then return false
    if (column == null || column == undefined || column.dataType !== 'String') {
      return false;
    }
    // if no scope then return false
    if (scope == undefined) {
      return false;
    }
    // check if the scope has ColumnIds and whether this column is contained
    if ('ColumnIds' in scope && scope.ColumnIds.includes(column.columnId)) {
      return true;
    }
    // check if the scope has ColumnIds and whether this column is contained
    if ('DataTypes' in scope && scope.DataTypes.includes('String')) {
      return true;
    }
    if ('ColumnTypes' in scope && ((_b = (_a = scope.ColumnTypes) === null || _a === void 0 ? void 0 : _a.some) === null || _b === void 0 ? void 0 : _b.call(_a, scopeColumnType => {
      var _a, _b;
      return (_b = (_a = column.columnTypes) === null || _a === void 0 ? void 0 : _a.includes) === null || _b === void 0 ? void 0 : _b.call(_a, scopeColumnType);
    }))) {
      return true;
    }
    return false;
  }
  isColumnInDateScope(column, scope) {
    var _a, _b;
    // if column is not even numeric then return false
    if (column == null || column == undefined || column.dataType !== 'Date') {
      return false;
    }
    // if no scope then return false
    if (scope == undefined) {
      return false;
    }
    // check if the scope has ColumnIds and whether this column is contained
    if ('ColumnIds' in scope && scope.ColumnIds.includes(column.columnId)) {
      return true;
    }
    // check if the scope has ColumnIds and whether this column is contained
    if ('DataTypes' in scope && scope.DataTypes.includes('Date')) {
      return true;
    }
    if ('ColumnTypes' in scope && ((_b = (_a = scope.ColumnTypes) === null || _a === void 0 ? void 0 : _a.some) === null || _b === void 0 ? void 0 : _b.call(_a, scopeColumnType => {
      var _a, _b;
      return (_b = (_a = column.columnTypes) === null || _a === void 0 ? void 0 : _a.includes) === null || _b === void 0 ? void 0 : _b.call(_a, scopeColumnType);
    }))) {
      return true;
    }
    return false;
  }
  isScopeInScope(a, b) {
    // AFL: Scope:'All' for object 'a' is NOT handled
    // this is probably wrong, but we will touch it when a bug is filed
    if ('All' in b) {
      return true;
    }
    if ('DataTypes' in a && 'DataTypes' in b && a.DataTypes.every(type => b.DataTypes.includes(type))) {
      return true;
    }
    if ('ColumnIds' in a && 'ColumnIds' in b && a.ColumnIds.every(columnId => b.ColumnIds.includes(columnId))) {
      return true;
    }
    if ('ColumnIds' in a && 'DataTypes' in b && a.ColumnIds.every(columnId => {
      var _a;
      return b.DataTypes.includes((_a = this.getAdaptableApi().columnApi.getColumnWithColumnId(columnId)) === null || _a === void 0 ? void 0 : _a.dataType);
    })) {
      return true;
    }
    if ('ColumnTypes' in a && 'ColumnTypes' in b && a.ColumnTypes.every(columnType => b.ColumnTypes.includes(columnType))) {
      return true;
    }
    // check if each colum in a has column types in b
    if ('ColumnIds' in a && 'ColumnTypes' in b) {
      const inScope = a.ColumnIds.every(columnId => {
        const column = this.getAdaptableApi().columnApi.getColumnWithColumnId(columnId);
        // check if at least one column-type is in scope
        return column.columnTypes.some(columnColumnType => b.ColumnTypes.includes(columnColumnType));
      });
      if (inScope) {
        return true;
      }
    }
    return false;
  }
  createCellColorRangesForScope(scope) {
    let distinctColumnsValues = this.getAdaptableApi().gridApi.internalApi.getDistinctRawValuesForColumn(this.getAdaptableApi().columnScopeApi.getSingleColumnInScope(scope));
    let minValue = Math.min(...distinctColumnsValues);
    let maxValue = Math.max(...distinctColumnsValues);
    const ranges = [];
    if (this.distinctColumnValuesAreEmpty(distinctColumnsValues)) {
      ranges.push({
        Min: 0,
        Max: 0,
        Color: getHexForName(GRAY)
      });
    }
    if (minValue < 0) {
      ranges.push({
        Min: minValue,
        Max: 0,
        Color: getHexForName(RED)
      });
    }
    if (maxValue > 0) {
      ranges.push({
        Min: 0,
        Max: maxValue,
        Color: getHexForName(DARK_GREEN)
      });
    }
    return ranges;
  }
  distinctColumnValuesAreEmpty(distinctColumnsValues) {
    if (ArrayExtensions.IsNullOrEmpty(distinctColumnsValues)) {
      return true;
    }
    if (distinctColumnsValues.length === 1 && String(distinctColumnsValues[0]) == '') {
      return true;
    }
    return false;
  }
}