import React from 'react';
import { Box, Flex } from 'rebass';
import AdaptableInput from '../../View/Components/AdaptableInput';
import { IconComponent } from '../Icon';
import { allIcons, Icon } from '../icons';
import OverlayTrigger from '../OverlayTrigger';
import SimpleButton from '../SimpleButton';
const IconButton = props => {
  return React.createElement(SimpleButton, {
    onClick: () => props.onClick(),
    p: 2,
    variant: props.active ? 'raised' : 'text',
    tone: props.active ? 'accent' : 'none',
    icon: props.icon
  }, props.children);
};
export const IconSelector = props => {
  var _a, _b, _c;
  const [query, setQuery] = React.useState('');
  const allIconsNames = Object.keys(allIcons);
  const filteredIcons = query ? allIconsNames.filter(iconName => {
    return iconName.toLowerCase().includes(query.toLowerCase());
  }) : allIconsNames;
  const iconsElements = filteredIcons.map(iconName => {
    return React.createElement(Flex, {
      key: iconName
    }, React.createElement(IconButton, {
      onClick: () => props.onChange(iconName),
      active: iconName === props.value,
      icon: iconName
    }));
  });
  const customIconsElements = (_a = props.customIcons) === null || _a === void 0 ? void 0 : _a.map(def => {
    return React.createElement(IconButton, {
      key: def.name,
      active: false,
      onClick: () => props.onChange(def.name)
    }, React.createElement(IconComponent, {
      key: def.name,
      icon: def.icon
    }));
  });
  const popup = React.createElement(Box, {
    className: "ab-IconSelector__Popup"
  }, React.createElement(Box, {
    mb: 2
  }, React.createElement(Box, {
    mb: 2,
    fontSize: 4
  }, "Select an Icon"), React.createElement(AdaptableInput, {
    value: query,
    onChange: event => {
      var _a;
      return setQuery((_a = event.target.value) !== null && _a !== void 0 ? _a : '');
    },
    width: "100%"
  })), React.createElement(Flex, {
    p: 2,
    style: {
      width: 300,
      maxHeight: 300,
      overflow: 'auto'
    },
    flexWrap: "wrap"
  }, iconsElements, " ", customIconsElements));
  let value = 'No Icon Selected';
  if (props.value in allIcons) {
    value = React.createElement(Icon, {
      style: {
        display: 'inline-block'
      },
      name: props.value
    });
  } else {
    const icon = (_b = props.customIcons) === null || _b === void 0 ? void 0 : _b.find(def => def.name === props.value);
    if (icon) value = React.createElement(IconComponent, {
      icon: icon.icon
    });
  }
  return React.createElement(Flex, {
    className: "ab-IconSelector"
  }, React.createElement(OverlayTrigger, {
    preventPortalEventPropagation: false,
    render: () => popup,
    showEvent: "mouseenter",
    hideEvent: "mouseleave",
    hideDelay: 300
  }, React.createElement(SimpleButton, {
    className: "ab-IconSelector__trigger",
    textAlign: "center",
    onClick: () => null,
    variant: "raised",
    width: 150,
    justifyContent: "center"
  }, value)), ((_c = props.clearable) !== null && _c !== void 0 ? _c : true) && React.createElement(SimpleButton, {
    disabled: !props.value,
    className: "ab-IconSelector__clear-button",
    ml: 2,
    onClick: event => {
      event.stopPropagation();
      event.preventDefault();
      props.onChange('');
    },
    variant: "text",
    icon: "close"
  }));
};