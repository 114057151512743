import * as React from 'react';
import SimpleButton from '../../../components/SimpleButton';
import StringExtensions from '../../../Utilities/Extensions/StringExtensions';
export class ButtonPlay extends React.Component {
  render() {
    return React.createElement(SimpleButton, Object.assign({
      "data-name": "play",
      tooltip: StringExtensions.IsNullOrEmpty(this.props.tooltip) ? 'Play' : this.props.tooltip,
      iconSize: 20,
      icon: "play",
      variant: "text"
    }, this.props));
  }
}