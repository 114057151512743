import mergeWith from 'lodash/mergeWith';
import merge from 'lodash/merge';
import isArray from 'lodash/isArray';
import extend from 'lodash/extend';
import isObject from 'lodash/isObject';
import { AdaptableLogger } from '../../agGrid/AdaptableLogger';
import AdaptableHelper from '../../Utilities/Helpers/AdaptableHelper';
function customizer(objValue, srcValue) {
  if (isArray(objValue)) {
    if (!Array.isArray(srcValue)) {
      // TODO AFL: clarify if this is still relevant, after the config/options refactoring
      /**
       * the new value might be a function: eg: UserInterface.ContextMenuItems defaults to an array
       * in the redux state, while the user might provide a function
       * so in this case, make the user provided value win
       */
      return srcValue;
    }
    const length = srcValue ? srcValue.length : null;
    const result = mergeWith(objValue, srcValue, customizer);
    if (length != null) {
      // when merging arrays, lodash result has the length of the
      // longest array, but we don't want that to happen
      // so we restrict to the current length
      result.length = length;
    }
    return result;
  }
}
export function AddStateSource(stateObject = {}, source) {
  const traverseStateObject = object => {
    Object.values(object).forEach(value => {
      // add a Source only to AdaptableObjects which do NOT already have a source defined
      if (AdaptableHelper.isAdaptableObject(value) && value.Source == null) {
        value.Source = source;
      }
      if (value !== null && typeof value === 'object') {
        traverseStateObject(value);
      }
    });
  };
  traverseStateObject(stateObject);
  return stateObject;
}
export function ProcessKeepUserDefinedRevision(configState, currentUserState) {
  const traverseStateObject = (configObject, stateObject) => {
    Object.entries(configObject).forEach(([key, configValue]) => {
      const stateValue = stateObject[key];
      if (Array.isArray(configValue) && Array.isArray(stateValue)) {
        const userDefinedItems = stateValue.filter(item => {
          return AdaptableHelper.isAdaptableObject(item) && item.Source !== 'Config';
        });
        configObject[key] = configValue.concat(userDefinedItems);
        // we probably don't need to call traverseStateObject on the array as well,
        // so we do the traversing on the else branch only
      } else {
        if (configValue !== null && typeof configValue === 'object' && typeof stateValue === 'object') {
          traverseStateObject(configValue, stateValue);
        }
      }
    });
  };
  traverseStateObject(configState, currentUserState);
  return configState;
}
function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}
export function MergeStateFunction(oldState, newState) {
  // return MergeState(oldState, newState);
  var _a, _b, _c;
  if (newState === '') {
    newState = {};
  }
  // add source 'Config' only to predefined objects
  const config = AddStateSource(oldState, 'Config');
  // source 'User' will be added to all other objects, after the merge (see bottom of this function)
  let state = newState;
  if (!state || typeof state !== 'object') {
    // in case loadState returns something different than an empty object
    AdaptableLogger.consoleWarnBase('State is something different than expected; expected an object, but received: ', state);
    state = {};
  }
  // any Module in config that doesn't exist in state will be added
  for (const configModuleName in config) {
    state[configModuleName] = (_a = state[configModuleName]) !== null && _a !== void 0 ? _a : config[configModuleName];
  }
  // any Module in state that has an older revision than the config will be replaced
  for (const stateModuleName in state) {
    if (config[stateModuleName] != undefined) {
      // explicitly use double equals, as we want to avoid null as well
      const stateRevision = (_b = state[stateModuleName].Revision) !== null && _b !== void 0 ? _b : 0;
      const configRevision = (_c = config[stateModuleName].Revision) !== null && _c !== void 0 ? _c : 0;
      const stateRevisionKey = typeof stateRevision === 'object' ? stateRevision.Key : stateRevision;
      const configRevisionBehaviour = typeof configRevision === 'object' ? configRevision.Behavior : 'Override';
      const configRevisionKey = typeof configRevision === 'object' ? configRevision.Key : configRevision;
      if (configRevisionKey > stateRevisionKey) {
        if (configRevisionBehaviour === 'Override') {
          state[stateModuleName] = config[stateModuleName];
        } else {
          state[stateModuleName] = ProcessKeepUserDefinedRevision(deepClone(config[stateModuleName]), state[stateModuleName]);
        }
      }
    }
  }
  // add 'User' source to all state objects which do NOT have 'Config' yet
  const finalState = AddStateSource(state, 'User');
  return finalState;
}
// main merge function
export function MergeState(oldState, newState) {
  const result = extend({}, oldState);
  for (const key in newState) {
    if (!newState.hasOwnProperty(key)) {
      continue;
    }
    const value = newState[key];
    // Assign if we don't need to merge at all
    if (!result.hasOwnProperty(key)) {
      result[key] = isObject(value) && !Array.isArray(value) ? merge({}, value) : value;
      continue;
    }
    const oldValue = result[key];
    if (isObject(value) && !Array.isArray(value)) {
      // use both lodash functions so that we can merge from State onto Predefined Config where it exists but from the former where it doesnt.
      result[key] = mergeWith({}, oldValue, value, customizer);
    } else {
      result[key] = value;
    }
  }
  return result;
}
let initialState;
export const mergeReducer = (rootReducer, LOAD_STATE_TYPE) => {
  let finalReducer = rootReducer;
  finalReducer = (state, action) => {
    if (action.type === LOAD_STATE_TYPE) {
      initialState = initialState !== null && initialState !== void 0 ? initialState : state;
      state = MergeState(initialState, action.State);
      // put this new state on the action, since the root reducer further copies
      // keys from action.State to the new state
      action.State = state;
    }
    return rootReducer(state, action);
  };
  return finalReducer;
};