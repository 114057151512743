import { ApiBase } from '../Implementation/ApiBase';
import { createBaseContext } from '../../Utilities/ObjectFactory';
export class ChartingInternalApi extends ApiBase {
  getContainerElement(chartContainer) {
    const element = typeof (chartContainer === null || chartContainer === void 0 ? void 0 : chartContainer.element) === 'string' ? document.querySelector(chartContainer.element) : chartContainer === null || chartContainer === void 0 ? void 0 : chartContainer.element;
    if (!element && typeof (chartContainer === null || chartContainer === void 0 ? void 0 : chartContainer.element) === 'string') {
      this.adaptable.logger.consoleLogByMessageType(`Chart container element not found: ${chartContainer.element}`, 'Error');
      return null;
    }
    return element;
  }
  getContainerElemetnByName(containerName) {
    var _a, _b, _c;
    const chartContainers = (_c = (_b = (_a = this.getAdaptableApi().optionsApi.getAdaptableOptions()) === null || _a === void 0 ? void 0 : _a.chartingOptions) === null || _b === void 0 ? void 0 : _b.chartContainers) !== null && _c !== void 0 ? _c : [];
    const chartContainer = chartContainers.find(container => container.name === containerName);
    return this.getContainerElement(chartContainer);
  }
  getUniqueChartName() {
    const charts = this.getAdaptableApi().chartingApi.getChartDefinitions();
    const externalCharts = this.getAdaptableApi().chartingApi.getExternalChartDefinitions();
    return `Chart ${charts.length + externalCharts.length + 1}`;
  }
  // External lib charting service
  isExternalChartOpened(chartDefinition) {
    const externalChartingOptions = this.getAdaptableApi().optionsApi.getChartingOptions().externalChartingOptions;
    if (!(externalChartingOptions === null || externalChartingOptions === void 0 ? void 0 : externalChartingOptions.isChartOpened)) {
      return false;
    }
    if (!chartDefinition) {
      return false;
    }
    return externalChartingOptions.isChartOpened(Object.assign({
      chartDefinition: chartDefinition
    }, createBaseContext(this.getAdaptableApi())));
  }
  onHideExternalChart(chartDefinition) {
    const externalChartingOptions = this.getAdaptableApi().optionsApi.getChartingOptions().externalChartingOptions;
    if (!(externalChartingOptions === null || externalChartingOptions === void 0 ? void 0 : externalChartingOptions.onHideChart)) {
      return;
    }
    externalChartingOptions.onHideChart(Object.assign({
      chartDefinition: chartDefinition
    }, createBaseContext(this.getAdaptableApi())));
  }
  onShowExternalChart(chartDefinition, container) {
    const externalChartingOptions = this.getAdaptableApi().optionsApi.getChartingOptions().externalChartingOptions;
    if (!(externalChartingOptions === null || externalChartingOptions === void 0 ? void 0 : externalChartingOptions.onShowChart)) {
      return;
    }
    externalChartingOptions.onShowChart(Object.assign({
      chartDefinition: chartDefinition,
      container: container
    }, createBaseContext(this.getAdaptableApi())));
  }
  onPreviewExternalChart(chartDefinition, container) {
    const externalChartingOptions = this.getAdaptableApi().optionsApi.getChartingOptions().externalChartingOptions;
    if (!(externalChartingOptions === null || externalChartingOptions === void 0 ? void 0 : externalChartingOptions.onPreviewChart)) {
      return;
    }
    return externalChartingOptions.onPreviewChart(Object.assign({
      chartDefinition: chartDefinition,
      container: container
    }, createBaseContext(this.getAdaptableApi())));
  }
  onDeleteExternalChart(chartDefinition) {
    const externalChartingOptions = this.getAdaptableApi().optionsApi.getChartingOptions().externalChartingOptions;
    if (!(externalChartingOptions === null || externalChartingOptions === void 0 ? void 0 : externalChartingOptions.onDeleteChart)) {
      return;
    }
    externalChartingOptions.onDeleteChart(Object.assign({
      chartDefinition: chartDefinition
    }, createBaseContext(this.getAdaptableApi())));
  }
}