export function getCurrentToken(tokens, offset) {
  return tokens.find(({
    range
  }) => range[0] <= offset && range[1] > offset) || tokens.find(({
    range
  }) => range[1] == offset);
}
export function walker(node, callback) {
  if (node == undefined || typeof node === 'boolean' || typeof node === 'number' || typeof node === 'string') {
    return;
  }
  if (Array.isArray(node)) {
    return node.map(n => walker(n, callback));
  }
  // isObject
  if (node.args) {
    node.args.map(n => walker(n, callback));
    callback(node);
  }
}
export function findPathTo(node, offset) {
  const path = [];
  if (offset === null) return path;
  walker(node, node => {
    if (node.range[0] <= offset && node.range[1] >= offset) {
      path.push(node);
    }
  });
  return path;
}