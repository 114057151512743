import { __rest } from "tslib";
import * as React from 'react';
import SimpleButton from '../../../components/SimpleButton';
import { AdaptablePopupTeamSharing } from '../Popups/AdaptablePopupTeamSharing';
export class ButtonShare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }
  render() {
    var _a;
    const _b = this.props,
      {
        onShare,
        Header
      } = _b,
      buttonProps = __rest(_b, ["onShare", "Header"]);
    return React.createElement(React.Fragment, null, React.createElement(SimpleButton, Object.assign({
      "data-name": "share",
      tooltip: "Share",
      variant: "text",
      icon: "folder-shared",
      iconSize: (_a = buttonProps.iconSize) !== null && _a !== void 0 ? _a : 24,
      onClick: () => this.setState({
        open: true
      })
    }, buttonProps)), this.state.open && React.createElement(AdaptablePopupTeamSharing, {
      header: Header,
      showPopup: true,
      onClose: () => this.setState({
        open: false
      }),
      onConfirm: config => {
        this.setState({
          open: false
        });
        onShare(config);
      }
    }));
  }
}