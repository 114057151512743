import * as React from 'react';
export const LoaderSpinner = props => {
  return React.createElement("div", {
    style: props.style,
    className: "ab-Loader__Spinner"
  });
};
export const Loader = props => {
  return React.createElement("div", {
    style: props.style,
    className: "ab-Loader"
  }, React.createElement(LoaderSpinner, null), React.createElement("span", {
    className: "ab-Loader__text"
  }, props.children));
};