import * as React from 'react';
import { Flex } from 'rebass';
import { ColumnMenuTab } from '../../../PredefinedConfig/Common/Enums';
import Panel from '../../../components/Panel';
import Radio from '../../../components/Radio';
import SimpleButton from '../../../components/SimpleButton';
export class FilterFormPanel extends React.Component {
  render() {
    const header = React.createElement(Flex, {
      alignItems: "center",
      flexDirection: "row",
      style: {
        width: '100%'
      }
    }, this.props.IsAlwaysFilter ? React.createElement(Flex, {
      flex: 6,
      marginRight: 2
    }, "Filter") : React.createElement(Flex, {
      flex: 6,
      flexDirection: "column"
    }, React.createElement(Flex, null, React.createElement(Flex, {
      flex: 6
    }, React.createElement(Radio, {
      value: "Menu",
      checked: this.props.ColumnMenuTab == ColumnMenuTab.Menu,
      onChange: () => this.onSelectMenu()
    }, "Menu")), React.createElement(Flex, {
      flex: 6
    }, React.createElement(Radio, {
      value: "Filter",
      checked: this.props.ColumnMenuTab == ColumnMenuTab.Filter,
      onChange: () => this.onSelectFilter()
    }, "Filter")))), this.props.clearFilterButton && this.props.ColumnMenuTab == ColumnMenuTab.Filter && this.props.clearFilterButton, this.props.saveButton && this.props.ColumnMenuTab == ColumnMenuTab.Filter && this.props.saveButton);
    return React.createElement(Flex, {
      flexDirection: "column"
    }, React.createElement(Panel, {
      className: "ab-FilterFormPanel",
      header: header,
      headerProps: {
        padding: 1
      },
      style: Object.assign(Object.assign({}, this.props.style), {
        flex: 1
      }),
      bodyProps: {
        padding: 0,
        style: {
          maxHeight: '50vh'
        }
      },
      bodyScroll: true,
      borderRadius: 0,
      border: "none"
    }, this.props.children), this.props.autoApplyFilter ? null : React.createElement(Flex, {
      flex: "none",
      flexDirection: "row",
      padding: 1,
      justifyContent: "flex-end"
    }, React.createElement(SimpleButton, {
      variant: "raised",
      tone: "accent",
      disabled: this.props.applyFilterButtonDisabled,
      onClick: () => this.onApplyFilterClicked()
    }, "Apply Filter")));
  }
  onApplyFilterClicked() {
    this.props.onColumnFilterApplied();
  }
  onSelectMenu() {
    this.props.ColumnMenuTabChanged(ColumnMenuTab.Menu);
  }
  onSelectFilter() {
    this.props.ColumnMenuTabChanged(ColumnMenuTab.Filter);
  }
}