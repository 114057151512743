import { ApiBase } from './ApiBase';
import { EntitlementInternalApi } from '../Internal/EntitlementInternalApi';
export class EntitlementApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.internalApi = new EntitlementInternalApi(adaptable);
  }
  getEntitlementAccessLevelForModule(adaptableModule) {
    let entitlementOptions = this.getEntitlementOptions();
    if (entitlementOptions) {
      const defaultAccessLevelContext = this.getAdaptableApi().internalApi.buildBaseContext();
      const defaultAccessLevel = typeof entitlementOptions.defaultAccessLevel === 'function' ? entitlementOptions.defaultAccessLevel(defaultAccessLevelContext) : entitlementOptions.defaultAccessLevel;
      const moduleEntitlements = entitlementOptions.moduleEntitlements;
      if (moduleEntitlements) {
        if (typeof moduleEntitlements === 'function') {
          const entitlementContext = Object.assign(Object.assign({
            adaptableModule
          }, this.getAdaptableApi().internalApi.buildBaseContext()), {
            defaultAccessLevel
          });
          const dynamicModuleEntitlement = moduleEntitlements(entitlementContext);
          return dynamicModuleEntitlement !== null && dynamicModuleEntitlement !== void 0 ? dynamicModuleEntitlement : defaultAccessLevel;
        } else {
          const entitlement = moduleEntitlements.find(f => f.adaptableModule === adaptableModule);
          if (entitlement) {
            return entitlement.accessLevel;
          }
        }
      }
      // if still here then return their default Access Level
      return defaultAccessLevel;
    }
    return 'Full';
  }
  isObjectReadonly(object) {
    return object.IsReadOnly;
  }
  isModuleHiddenEntitlement(adaptableModule) {
    return this.getEntitlementAccessLevelForModule(adaptableModule) == 'Hidden';
  }
  isModuleFullEntitlement(adaptableModule) {
    return this.getEntitlementAccessLevelForModule(adaptableModule) == 'Full';
  }
  isModuleReadOnlyEntitlement(adaptableModule) {
    return this.getEntitlementAccessLevelForModule(adaptableModule) == 'ReadOnly';
  }
}