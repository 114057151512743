import { ApiBase } from './ApiBase';
import { Fdc3InternalApi } from '../Internal/Fdc3InternalApi';
import { ContextConfiguration } from '../../PredefinedConfig/Common/Fdc3Context';
export class Fdc3ApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.internalApi = new Fdc3InternalApi(adaptable);
  }
  getDesktopAgent() {
    return this.getFdc3Service().getDesktopAgent();
  }
  buildContextDataFromRow(contextType, rowNode) {
    return this.internalApi.mapRowToContextData(contextType, rowNode);
  }
  buildContextDataForPrimaryKey(contextType, primaryKeyValue) {
    const rowNode = this.getGridApi().getRowNodeForPrimaryKey(primaryKeyValue);
    if (!rowNode) {
      this.logWarn(`No row found for primary key value '${primaryKeyValue}'`);
      return undefined;
    }
    return this.buildContextDataFromRow(contextType, rowNode);
  }
  raiseIntentFromPrimaryKey(primaryKeyValue, intent, contextType, appIdentifier) {
    const rowNode = this.getGridApi().getRowNodeForPrimaryKey(primaryKeyValue);
    if (!rowNode) {
      this.logWarn(`No row found for primary key value '${primaryKeyValue}'`);
      return undefined;
    }
    return this.raiseIntentFromRow(rowNode, intent, contextType, appIdentifier);
  }
  raiseIntentFromRow(rowNode, intent, contextType, appIdentifier) {
    const contextData = this.buildContextDataFromRow(contextType, rowNode);
    return this.getFdc3Service().raiseIntent(intent, contextData, appIdentifier);
  }
  raiseIntentForContextFromRow(rowNode, contextType, appIdentifier) {
    const contextData = this.buildContextDataFromRow(contextType, rowNode);
    return this.getFdc3Service().raiseIntentForContext(contextData, appIdentifier);
  }
  raiseIntentForContextFromPrimaryKey(primaryKeyValue, contextType, appIdentifier) {
    const rowNode = this.getGridApi().getRowNodeForPrimaryKey(primaryKeyValue);
    if (!rowNode) {
      this.logWarn(`No row found for primary key value '${primaryKeyValue}'`);
      return undefined;
    }
    return this.raiseIntentForContextFromRow(rowNode, contextType, appIdentifier);
  }
  broadcastFromRow(rowNode, contextType) {
    const contextData = this.buildContextDataFromRow(contextType, rowNode);
    return this.getFdc3Service().broadcast(contextData);
  }
  broadcastFromPrimaryKey(primaryKeyValue, contextType) {
    const rowNode = this.getGridApi().getRowNodeForPrimaryKey(primaryKeyValue);
    if (!rowNode) {
      this.logWarn(`No row found for primary key value '${primaryKeyValue}'`);
      return undefined;
    }
    return this.broadcastFromRow(rowNode, contextType);
  }
  getContextLabel(contextType) {
    var _a;
    return this.isStandardContextType(contextType) ? (_a = ContextConfiguration[contextType]) === null || _a === void 0 ? void 0 : _a.label : contextType;
  }
  isStandardContextType(contextType) {
    return this.internalApi.isStandardContextType(contextType);
  }
  isStandardIntentType(intentType) {
    return this.internalApi.isStandardIntentType(intentType);
  }
  getFdc3Service() {
    return this.getAdaptableApi().internalApi.getFdc3Service();
  }
  getPossibleContextTypes(intent) {
    this.logWarn('Fdc3Api.getPossibleContextTypes is NO longer relevant as of FDC3 v1.2 and will be removed in a future version');
    return ['fdc3.action', 'fdc3.chart', 'fdc3.chat.initSettings', 'fdc3.chat.message', 'fdc3.chat.room', 'fdc3.chat.searchCriteria', 'fdc3.contact', 'fdc3.contactList', 'fdc3.country', 'fdc3.currency', 'fdc3.email', 'fdc3.instrument', 'fdc3.instrumentList', 'fdc3.interaction', 'fdc3.message', 'fdc3.organization', 'fdc3.portfolio', 'fdc3.position', 'fdc3.nothing', 'fdc3.timerange', 'fdc3.transactionResult', 'fdc3.valuation'];
  }
  isContextCompatibleWithIntent(contextType, intent) {
    this.logWarn('Fdc3Api.isContextCompatibleWithIntent is NO longer relevant as of FDC3 v1.2 and will be removed in a future version');
    return true;
  }
}