import React from 'react';
import { Flex } from 'rebass';
import Radio from '../../../../../components/Radio/index';
import { Tabs } from '../../../../../components/Tabs/index';
import { ImportFileSection } from './UploadFileSection';
import { ImportTextSection } from './UploadTextSection';
export const UploadSection = props => {
  return React.createElement(Flex, {
    p: 2,
    height: "100%",
    flexDirection: "column"
  }, React.createElement(Tabs, {
    mb: 2
  }, React.createElement(Tabs.Tab, null, "Import Type"), React.createElement(Tabs.Content, null, React.createElement(Flex, {
    flexDirection: "column"
  }, React.createElement(Radio, {
    onClick: () => props.onImportTypeChange('file'),
    checked: props.importType === 'file'
  }, "File"), React.createElement(Radio, {
    onClick: () => props.onImportTypeChange('text'),
    checked: props.importType === 'text'
  }, "Text")))), React.createElement(Tabs, {
    flex: 1
  }, React.createElement(Tabs.Tab, null, props.importType === 'file' ? 'File Upload' : 'Text'), React.createElement(Tabs.Content, null, props.importType === 'file' ? React.createElement(ImportFileSection, {
    readFile: props.readFile,
    supportedFileFormats: props.supportedFileFormats,
    message: props.fileMessage
  }) : React.createElement(ImportTextSection, {
    message: props.textMessage,
    text: props.text,
    onTextChange: props.onTextChange
  }))));
};