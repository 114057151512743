import * as SystemRedux from '../../Redux/ActionsReducers/SystemRedux';
import * as PopupRedux from '../../Redux/ActionsReducers/PopupRedux';
import * as AlertRedux from '../../Redux/ActionsReducers/AlertRedux';
import { ApiBase } from './ApiBase';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import ObjectFactory from '../../Utilities/ObjectFactory';
import { isAdaptableCellChangedAlert, isAdaptableRowChangedAlert } from '../../PredefinedConfig/Common/AdaptableAlert';
import StringExtensions from '../../Utilities/Extensions/StringExtensions';
import { AlertInternalApi } from '../Internal/AlertInternalApi';
export class AlertApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.internalApi = new AlertInternalApi(adaptable);
  }
  getAlertState() {
    return this.getAdaptableState().Alert;
  }
  getAlertDefinitions(config) {
    var _a;
    return (_a = this.handleLayoutAssociatedObjects(this.getAlertState().AlertDefinitions, 'Alert', config)) !== null && _a !== void 0 ? _a : [];
  }
  getSuspendedAlertDefinitions(config) {
    return this.getAlertDefinitions(config).filter(alertDefinition => alertDefinition.IsSuspended);
  }
  getActiveAlertDefinitions(config) {
    return this.getAlertDefinitions(config).filter(alertDefinition => !alertDefinition.IsSuspended);
  }
  deleteAlertDefinition(alertDefinition) {
    this.dispatchAction(AlertRedux.AlertDefinitionDelete(alertDefinition));
  }
  getAlertDefinitionById(id) {
    return this.getAlertDefinitions().find(alert => (alert === null || alert === void 0 ? void 0 : alert.Uuid) === id);
  }
  addAlertDefinition(alertDefinition) {
    this.addUidToAdaptableObject(alertDefinition);
    this.dispatchAction(AlertRedux.AlertDefinitionAdd(alertDefinition));
    return this.getAlertDefinitionById(alertDefinition.Uuid);
  }
  async showAdaptableAlert(alertToShow) {
    var _a, _b;
    // 3 things we always do with an alert are:
    // 1. Dispatch the Alert (so it appears in the toolbar)
    this.addUidToAdaptableObject(alertToShow);
    this.dispatchAction(SystemRedux.SystemAlertAdd(alertToShow, this.getAlertOptions().maxAlertsInStore));
    // 2. Publish the Alert Fired Event
    const alertFiredInfo = Object.assign(Object.assign({}, this.getAdaptableApi().internalApi.buildBaseContext()), {
      alert: alertToShow
    });
    const results = await this.getAdaptableApi().eventApi.emit('AlertFired', alertFiredInfo);
    const showPopup = results.reduce((endResult, aResult) => {
      return endResult && aResult !== false;
    }, true);
    if (alertToShow.alertDefinition && alertToShow.alertDefinition.AlertProperties != undefined) {
      const alertDefinition = alertToShow.alertDefinition;
      const alertProperties = alertDefinition.AlertProperties;
      // There are 4 possible other actions that could happen
      // alertProperties.PreventEdit is handled separately, in the Adaptable.setupColumnValueSetter() function
      // 1. Show a Popup
      if (alertProperties.DisplayNotification && showPopup) {
        this.dispatchAction(PopupRedux.PopupShowAlert(alertToShow));
      }
      // 2. Log to console
      if (alertProperties.LogToConsole) {
        this.adaptable.logger.consoleLogByMessageType(alertToShow.header + ': ' + alertToShow.message, alertDefinition.MessageType);
      }
      // 3. Show it in a Div (if one has been set)
      if (alertProperties.ShowInDiv) {
        let alertDiv;
        let optionsDiv = this.getContainerOptions().alertContainer;
        if (optionsDiv) {
          alertDiv = typeof optionsDiv === 'string' ? document.getElementById(optionsDiv) : optionsDiv;
        }
        if (alertDiv) {
          let alertString = alertToShow.header + ': ' + alertToShow.message;
          alertDiv.innerHTML = alertString;
        }
      }
      // 4.JumpToCell and 5.JumpToRow are exclusive, based on alertType!
      // 4: Jump to the Cell
      if (alertProperties.JumpToCell && isAdaptableCellChangedAlert(alertToShow) && alertToShow.cellDataChangedInfo) {
        this.adaptable.jumpToCell(alertToShow.cellDataChangedInfo.column.columnId, alertToShow.cellDataChangedInfo.rowNode);
      }
      // 5: Jump to the Row
      else if (alertProperties.JumpToRow && isAdaptableRowChangedAlert(alertToShow) && ((_a = alertToShow.gridDataChangedInfo) === null || _a === void 0 ? void 0 : _a.rowTrigger) === 'Add') {
        const [firstRowNode] = alertToShow.gridDataChangedInfo.rowNodes;
        if (firstRowNode) {
          this.adaptable.jumpToRow(firstRowNode);
        }
      }
      // 6: For CellChanged Alerts either Highlight the cell or row
      // for former - we just refresh the cells to trigger a re-evaluation of the Adaptable.setupColumn[Style/Class]
      if (isAdaptableCellChangedAlert(alertToShow) && alertToShow.cellDataChangedInfo && (alertProperties.HighlightCell || alertProperties.HighlightRow)) {
        let alertNode = alertToShow.cellDataChangedInfo.rowNode;
        if (!alertNode) {
          alertNode = this.adaptable.getRowNodeForPrimaryKey(alertToShow.cellDataChangedInfo.primaryKeyValue);
        }
        if (alertNode) {
          if (alertProperties.HighlightRow) {
            this.getAdaptableApi().gridApi.refreshRowNodes([alertNode]);
            setTimeout(() => {
              this.dispatchAction(SystemRedux.SystemAlertRemoveRowHighlight(alertToShow));
            }, this.adaptable.adaptableOptions.alertOptions.rowHighlightDuration);
          }
          if (alertProperties.HighlightCell) {
            this.getAdaptableApi().gridApi.refreshCells([alertNode], [alertToShow.cellDataChangedInfo.column.columnId]);
            setTimeout(() => {
              this.dispatchAction(SystemRedux.SystemAlertRemoveCellHighlight(alertToShow));
            }, this.adaptable.adaptableOptions.alertOptions.cellHighlightDuration);
          }
        }
      }
      // 7. For RowChange Alerts only Highlight the row - we just refresh the rows to trigger a re-evaluation of the Adaptable.setupColumn[Style/Class]
      else if (alertProperties.HighlightRow && isAdaptableRowChangedAlert(alertToShow) && ((_b = alertToShow.gridDataChangedInfo) === null || _b === void 0 ? void 0 : _b.rowTrigger) === 'Add') {
        this.getAdaptableApi().gridApi.refreshRowNodes(alertToShow.gridDataChangedInfo.rowNodes);
        setTimeout(() => {
          this.dispatchAction(SystemRedux.SystemAlertRemoveRowHighlight(alertToShow));
        }, this.adaptable.adaptableOptions.alertOptions.rowHighlightDuration);
      }
    }
  }
  showAdaptableAlertAsToast(alert) {
    this.dispatchAction(PopupRedux.PopupShowAlert(alert));
  }
  async showAlert(alertHeader, alertMessage, messageType, alertProperties) {
    const alertDefinition = ObjectFactory.CreateInternalAlertDefinitionForMessages(messageType, alertProperties);
    let alertToShow = ObjectFactory.CreateGenericAlert(alertHeader, alertMessage, alertDefinition);
    await this.showAdaptableAlert(alertToShow);
  }
  async showAlertInfo(alertHeader, alertMessage, alertProperties) {
    await this.showAlert(alertHeader, alertMessage, 'Info', alertProperties);
  }
  async showAlertSuccess(alertHeader, alertMessage, alertProperties) {
    await this.showAlert(alertHeader, alertMessage, 'Success', alertProperties);
  }
  async showAlertWarning(alertHeader, alertMessage, alertProperties) {
    await this.showAlert(alertHeader, alertMessage, 'Warning', alertProperties);
  }
  async showAlertError(alertHeader, alertMessage, alertProperties) {
    await this.showAlert(alertHeader, alertMessage, 'Error', alertProperties);
  }
  openAlertSettingsPanel() {
    this.showModulePopup(ModuleConstants.AlertModuleId);
  }
  editAlertDefinition(alertDefinition) {
    this.dispatchAction(AlertRedux.AlertDefinitionEdit(alertDefinition));
    return this.getAlertDefinitionById(alertDefinition.Uuid);
  }
  suspendAlertDefinition(alertDefinition) {
    this.dispatchAction(AlertRedux.AlertDefinitionSuspend(alertDefinition));
    return this.getAlertDefinitionById(alertDefinition.Uuid);
  }
  unSuspendAlertDefinition(alertDefinition) {
    this.dispatchAction(AlertRedux.AlertDefinitionUnSuspend(alertDefinition));
    return this.getAlertDefinitionById(alertDefinition.Uuid);
  }
  evaluateAlertDefinitions(alertDefinitions) {
    const filterScopeAllDefinitions = alertDefinition => {
      if (this.getAdaptableApi().columnScopeApi.scopeIsAll(alertDefinition.Scope) && !this.getAdaptableApi().expressionApi.getAdaptableQueryExpression(alertDefinition.Rule)) {
        // we don't support scope ALL without an expression
        // the predicates for scope ALL do NOT make any sense for a programmatic evaluation
        return false;
      }
      return true;
    };
    const relevantAlertDefinitions = alertDefinitions.filter(alertDefinition => filterScopeAllDefinitions(alertDefinition));
    const relevantColumnIds = new Set();
    relevantAlertDefinitions.forEach(alertDefinition => {
      this.getRelevantColumnIdsForAlertDefinition(alertDefinition).forEach(relevantColumnId => relevantColumnIds.add(relevantColumnId));
    });
    if (!relevantColumnIds.size) {
      return;
    }
    const cellDataChangedInfos = this.createCellDataChangeInfoStubs(Array.from(relevantColumnIds));
    cellDataChangedInfos.forEach(cellDataChangedInfo => {
      const alertDefinitions = this.getAdaptableApi().alertApi.internalApi.getAlertDefinitionsForCellDataChange(cellDataChangedInfo).filter(alertDefinition => filterScopeAllDefinitions(alertDefinition))
      // some alert definitions might NOT be relevant for the given locator
      .filter(alertDefinition => relevantAlertDefinitions.some(relevantAlertDefinition => relevantAlertDefinition.Uuid === alertDefinition.Uuid));
      this.getAdaptableApi().alertApi.internalApi.showAlertForDefinitions(cellDataChangedInfo, alertDefinitions);
    });
  }
  findAlertDefinitions(criteria) {
    return this.getAdaptableApi().internalApi.findAdaptableObjectsByLookupCriteria(criteria, this.getAlertDefinitions({
      includeLayoutNotAssociatedObjects: true
    }));
  }
  getRelevantColumnIdsForAlertDefinition(alertDefinition) {
    //  if there is an Expression defined, extract only the columns from there (in that case the Scope would be 'All' anyway)
    // columns referenced in rule expression
    const ruleExpression = this.getAdaptableApi().expressionApi.getAdaptableQueryExpression(alertDefinition.Rule);
    if (this.getAdaptableApi().columnScopeApi.scopeIsAll(alertDefinition.Scope) && StringExtensions.IsNotNullOrEmpty(ruleExpression)) {
      // return only first referenced column (otherwise we would generate multiple alerts for the same row)
      const queryColumns = this.getAdaptableApi().internalApi.getQueryLanguageService().getColumnsFromExpression(ruleExpression);
      return queryColumns.length > 0 ? [queryColumns[0]] : [];
    } else {
      // otherwise return all columns for given scope
      return this.getAdaptableApi().columnScopeApi.getColumnsForScope(alertDefinition.Scope).map(adaptableColumn => adaptableColumn.columnId);
    }
  }
  createCellDataChangeInfoStubs(relevantColumnIds) {
    const cellDataChangeInfos = [];
    const relevantColumns = this.getAdaptableApi().columnApi.getColumnsWithColumnIds(relevantColumnIds).filter(Boolean);
    const changedAt = Date.now();
    this.adaptable.forAllRowNodesDo(rowNode => {
      relevantColumns.forEach(column => {
        const cellDataChangeInfoStub = {
          changedAt,
          rowNode,
          column,
          rowData: rowNode.data,
          oldValue: null,
          newValue: this.adaptable.getRawValueFromRowNode(rowNode, column.columnId),
          primaryKeyValue: this.adaptable.getPrimaryKeyValueFromRowNode(rowNode)
        };
        cellDataChangeInfos.push(cellDataChangeInfoStub);
      });
    });
    return cellDataChangeInfos;
  }
}