import * as React from 'react';
import { UIHelper } from '../../../UIHelper';
import { AdaptableViewFactory } from '../../../AdaptableViewFactory';
import { AdaptablePopupModuleView } from './AdaptablePopupModuleView';
export const AdaptablePopupBody = props => {
  var _a;
  const modalContainer = UIHelper.getModalContainer(props.api.optionsApi.getAdaptableOptions(), document);
  const moduleName = props.module.moduleInfo.ModuleName;
  const accessLevel = props.api.entitlementApi.getEntitlementAccessLevelForModule(moduleName);
  const moduleInfo = props.api.internalApi.getModuleService().getModuleInfoByModule(moduleName);
  const moduleProps = {
    popupParams: props.moduleParams,
    onClearPopupParams: () => props.onClearParams ? props.onClearParams() : null,
    onClosePopup: () => {
      if (props.onHide) {
        props.onHide();
      }
    },
    // show share button if TeamSharing is active & user has edit rights
    teamSharingActivated: props.api.teamSharingApi.isTeamSharingAvailable() && props.api.teamSharingApi.hasTeamSharingFullRights(),
    modalContainer: modalContainer,
    accessLevel: accessLevel,
    api: props.api,
    moduleInfo
  };
  const BodyComponent = (AdaptableViewFactory === null || AdaptableViewFactory === void 0 ? void 0 : AdaptableViewFactory[(_a = props === null || props === void 0 ? void 0 : props.componentName) !== null && _a !== void 0 ? _a : moduleInfo.Popup]) || AdaptablePopupModuleView;
  // key is to make sure state is reset between views
  // most of the views use the `AdaptablePopupModuleView` component
  return React.createElement(BodyComponent, Object.assign({
    key: props === null || props === void 0 ? void 0 : props.componentName,
    module: props.module
  }, moduleProps));
};