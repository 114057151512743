import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from 'rebass';
import * as SystemRedux from '../../../Redux/ActionsReducers/SystemRedux';
import SimpleButton from '../../../components/SimpleButton';
import join from '../../../components/utils/join';
import AdaptableHelper from '../../../Utilities/Helpers/AdaptableHelper';
import { useAdaptable } from '../../AdaptableContext';
import { ButtonDelete } from '../Buttons/ButtonDelete';
import { ButtonEdit } from '../Buttons/ButtonEdit';
import { ButtonShare } from '../Buttons/ButtonShare';
import { SuspendToggleButton } from '../Buttons/SuspendToggleButton/SuspendToggleButton';
import { ValueOptionsTags } from '../ValueSelector';
const ICON_SIZE = 26;
const LIST_BASE_CLASS_NAME = 'ab-Adaptable-Object-List';
const ITEM_BASE_CLASS_NAME = `${LIST_BASE_CLASS_NAME}__Item`;
export const AdaptableObjectListItemView = props => {
  var _a, _b, _c;
  const baseClassName = ITEM_BASE_CLASS_NAME;
  const dispatch = useDispatch();
  const deleteActionProps = {
    disabled: props.deleteDisabled,
    tooltip: props.deleteTooltip,
    iconSize: ICON_SIZE,
    ConfirmationMsg: `Are you sure you want to delete this ${props.entityType}?`,
    ConfirmationTitle: `Delete ${props.entityType}`,
    ConfirmAction: props.deleteAction,
    accessLevel: props.accessLevel
  };
  if (props.disableDeleteConfirmation) {
    deleteActionProps.ConfirmAction = null;
    deleteActionProps.onClickAction = () => {
      dispatch(props.deleteAction);
    };
  }
  const deleteActionButton = React.createElement(ButtonDelete, Object.assign({}, deleteActionProps));
  return React.createElement(Flex, {
    "data-name": "adaptable-object-list-item",
    "data-value": props.abObject.Uuid,
    as: "li",
    mb: 3,
    className: join(props.className, baseClassName),
    style: props.style
  }, React.createElement(Box, {
    flex: 1,
    className: `${baseClassName}__rows`
  }, (_c = (_b = (_a = props.items).filter) === null || _b === void 0 ? void 0 : _b.call(_a, Boolean)) === null || _c === void 0 ? void 0 : _c.map((tag, index) => {
    var _a;
    let labelEl = tag.label;
    if (typeof tag.label === 'function') {
      labelEl = React.createElement(tag.label, {
        key: index,
        data: props.abObject
      });
    }
    labelEl = labelEl !== null && labelEl !== void 0 ? labelEl : tag.name;
    return React.createElement(Flex, {
      "data-name": tag.name,
      key: `${index}-${tag.name}`,
      mb: 2,
      className: `${baseClassName}__row`
    }, React.createElement(Box, {
      className: `${baseClassName}__label`,
      mr: 3
    }, labelEl, props.showEditButton && React.createElement(SimpleButton, {
      "data-name": `${tag.name}-edit-button`,
      accessLevel: props.accessLevel,
      className: `${baseClassName}__edit-property`,
      ml: 1,
      icon: "edit",
      tooltip: "edit",
      iconSize: 18,
      variant: "text",
      onClick: () => {
        props.handleOnEdit(tag.name);
      }
    })), React.createElement(Box, {
      flex: 1,
      className: `${baseClassName}__values`
    }, typeof tag.view === 'function' ? React.createElement(tag.view, {
      data: props.abObject,
      module: props.module
    }) : tag.view, Boolean((tag === null || tag === void 0 ? void 0 : tag.values) && ((_a = tag === null || tag === void 0 ? void 0 : tag.values) === null || _a === void 0 ? void 0 : _a.length)) && React.createElement(ValueOptionsTags, {
      style: {
        marginRight: 0
      },
      readOnly: true,
      options: tag.values,
      value: tag.values,
      allowWrap: true,
      toIdentifier: c => c,
      toLabel: c => React.createElement(React.Fragment, null, c)
    }), typeof tag.viewAfter === 'function' ? React.createElement(tag.viewAfter, {
      data: props.abObject,
      module: props.module
    }) : tag.viewAfter));
  })), props.showActions && React.createElement(Flex, {
    flexDirection: "column",
    className: `${baseClassName}__buttons`
  }, React.createElement(Flex, {
    justifyContent: "end"
  }, props.actions, props.teamSharingActivated && React.createElement(ButtonShare, {
    iconSize: ICON_SIZE,
    Header: `TeamSharing ${props.entityType}`,
    accessLevel: props.accessLevel,
    onShare: props.onShare
  }), props.onDelete && React.createElement(SimpleButton, {
    "data-name": "delete",
    disabled: props.deleteDisabled,
    iconSize: ICON_SIZE,
    tooltip: props.deleteTooltip,
    icon: "delete",
    onClick: props.onDelete,
    variant: "text"
  }), props.deleteAction && deleteActionButton, props.showEditButton && React.createElement(ButtonEdit, {
    iconSize: ICON_SIZE,
    disabled: props.editDisabled,
    accessLevel: props.accessLevel,
    onClick: () => props.handleOnEdit()
  })), React.createElement(Box, {
    flex: 1
  }), props.suspendedEnabled && React.createElement(Flex, {
    justifyContent: "end"
  }, React.createElement(SuspendToggleButton, {
    onSuspend: props.onSuspend,
    onUnSuspend: props.onUnSuspend,
    suspendableObject: props.abObject,
    accessLevel: props.accessLevel
  }))));
};
export const AdaptableObjectListItem = props => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const adaptable = useAdaptable();
  const dispatch = useDispatch();
  const [isEditWizardVisible, setIsEditWizardVisible] = React.useState(false);
  const [wizardStepName, setWizardStepName] = React.useState(null);
  const viewOptions = (_c = (_b = (_a = props.module) === null || _a === void 0 ? void 0 : _a.getViewProperties) === null || _b === void 0 ? void 0 : _b.call(_a)) !== null && _c !== void 0 ? _c : {};
  const EditWizard = (_d = viewOptions.getEditWizard) === null || _d === void 0 ? void 0 : _d.call(viewOptions, props.data.abObject);
  const deleteAction = (_e = viewOptions === null || viewOptions === void 0 ? void 0 : viewOptions.getDeleteAction) === null || _e === void 0 ? void 0 : _e.call(viewOptions, props.data.abObject);
  const isObjectShareable = props.module.isModuleObjectsShareable();
  const teamSharingActivated = isObjectShareable && adaptable.api.teamSharingApi.isTeamSharingAvailable() && adaptable.api.teamSharingApi.hasTeamSharingFullRights();
  const entityType = props.module.moduleInfo.FriendlyName;
  const moduleAccessLevel = adaptable.api.entitlementApi.getEntitlementAccessLevelForModule(props.module.moduleInfo.ModuleName);
  const accessLevel = AdaptableHelper.getAccessLevelForObject(props.data.abObject, moduleAccessLevel);
  const itemClassName = join(props.data.className, ITEM_BASE_CLASS_NAME, props.data.abObject.IsSuspended && `${ITEM_BASE_CLASS_NAME}--is-suspended`);
  const handleCloseWizard = React.useCallback(() => {
    setIsEditWizardVisible(false);
    setWizardStepName(null);
  }, []);
  const handleOnEdit = React.useCallback(sectionName => {
    var _a;
    if (EditWizard) {
      setIsEditWizardVisible(true);
      if (sectionName) {
        setWizardStepName(sectionName);
      }
    }
    (_a = viewOptions === null || viewOptions === void 0 ? void 0 : viewOptions.onOpenEditPopup) === null || _a === void 0 ? void 0 : _a.call(viewOptions, props.data.abObject);
  }, []);
  const hasSuspend = Boolean(viewOptions.getSuspendAction);
  const actions = (_g = (_f = viewOptions === null || viewOptions === void 0 ? void 0 : viewOptions.actions) === null || _f === void 0 ? void 0 : _f.map) === null || _g === void 0 ? void 0 : _g.call(_f, (component, index) => {
    return React.createElement(component, {
      data: props.data.abObject,
      key: index,
      accessLevel
    });
  });
  const isEditDisabled = !Boolean(EditWizard || viewOptions.onOpenEditPopup);
  const showActions = !props.hideControls;
  const showEditButton = Boolean(EditWizard);
  const adaptableOpttions = adaptable.api.optionsApi.getAdaptableOptions();
  const disableDeleteConfirmationState = useSelector(adaptableState => SystemRedux.SystemDisableDeleteConfirmationSelector(adaptableState.System));
  const disableDeleteConfirmation = disableDeleteConfirmationState || ((_h = adaptableOpttions === null || adaptableOpttions === void 0 ? void 0 : adaptableOpttions.userInterfaceOptions) === null || _h === void 0 ? void 0 : _h.disableDeleteConfirmation);
  return React.createElement(React.Fragment, null, React.createElement(AdaptableObjectListItemView, {
    module: props.module,
    disableDeleteConfirmation: disableDeleteConfirmation,
    abObject: props.data.abObject,
    accessLevel: accessLevel,
    actions: actions,
    className: itemClassName,
    handleOnEdit: handleOnEdit,
    items: props.data.items,
    showActions: showActions,
    showEditButton: showEditButton,
    style: props.data.style,
    teamSharingActivated: teamSharingActivated,
    onShare: config => adaptable.api.teamSharingApi.shareAdaptableEntity(props.data.abObject, props.module.moduleInfo.ModuleName, config),
    entityType: entityType,
    deleteAction: deleteAction,
    deleteDisabled: props.deleteDisabled,
    deleteTooltip: props.deleteTooltip,
    editDisabled: isEditDisabled,
    suspendedEnabled: hasSuspend,
    onSuspend: () => dispatch(viewOptions.getSuspendAction(props.data.abObject)),
    onUnSuspend: () => dispatch(viewOptions.getUnSuspendAction(props.data.abObject))
  }), isEditWizardVisible && EditWizard && React.createElement(EditWizard, {
    defaultCurrentSectionName: wizardStepName,
    moduleInfo: props.module.moduleInfo,
    data: props.data.abObject,
    configEntities: null,
    onCloseWizard: handleCloseWizard,
    onFinishWizard: handleCloseWizard
  }));
};
export const AdaptableObjectList = props => {
  var _a;
  return React.createElement("ul", {
    className: LIST_BASE_CLASS_NAME
  }, (_a = props === null || props === void 0 ? void 0 : props.items) === null || _a === void 0 ? void 0 : _a.map((item, index) => {
    var _a;
    return React.createElement(AdaptableObjectListItem, Object.assign({
      key: (_a = item.abObject.Uuid) !== null && _a !== void 0 ? _a : index,
      data: item,
      module: props.module
    }, props.itemProps));
  }));
};