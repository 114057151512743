import { Badge } from '../View/Components/Badge';
import { renderWithAdaptableContext } from '../View/renderWithAdaptableContext';
import * as React from 'react';
export const getBadgeRendererForColumn = (badgeStyle, abColumn, api) => {
  return class BadgetRenderer {
    getAdaptableInstance(params) {
      const adaptable = params.context.__adaptable;
      return adaptable;
    }
    init(params) {
      var _a, _b, _c, _d, _e, _f;
      const adaptable = this.getAdaptableInstance(params);
      const adaptableApi = adaptable.api;
      this.eGui = document.createElement('div');
      this.eGui.className = 'ab-Badge__wrapper';
      const isGroupedRow = params.node.group;
      const isSummaryRow = adaptableApi.gridApi.isSummaryNode(params.node);
      let shouldRender = true;
      if (isGroupedRow) {
        if ((_a = badgeStyle.RowScope) === null || _a === void 0 ? void 0 : _a.ExcludeGroupRows) {
          shouldRender = false;
        }
      } else if (isSummaryRow) {
        if ((_b = badgeStyle.RowScope) === null || _b === void 0 ? void 0 : _b.ExcludeSummaryRows) {
          shouldRender = false;
        }
      } else {
        if ((_c = badgeStyle.RowScope) === null || _c === void 0 ? void 0 : _c.ExcludeDataRows) {
          shouldRender = false;
        }
      }
      if (!shouldRender) {
        const formattedValue = (_f = (_e = (_d = params.formatValue) === null || _d === void 0 ? void 0 : _d.call(params, params.value)) !== null && _e !== void 0 ? _e : params.value) !== null && _f !== void 0 ? _f : '';
        this.eGui.innerHTML = formattedValue;
        return;
      }
      if (['NumberArray', 'StringArray'].includes(abColumn.dataType)) {
        this.renderArrayValues(params, adaptableApi);
      } else {
        this.renderSingularValues(params, adaptableApi);
      }
    }
    renderArrayValues(params, adaptableApi) {
      var _a, _b, _c, _d, _e, _f;
      let badgesConfig = [];
      if (!Array.isArray(params.value) || params.value.length === 0) {
        const formattedValue = (_c = (_b = (_a = params.formatValue) === null || _a === void 0 ? void 0 : _a.call(params, params.value)) !== null && _b !== void 0 ? _b : params.value) !== null && _c !== void 0 ? _c : '';
        this.eGui.innerHTML = formattedValue;
        return;
      }
      for (const value of params.value) {
        const formattedValue = (_f = (_e = (_d = params.formatValue) === null || _d === void 0 ? void 0 : _d.call(params, value)) !== null && _e !== void 0 ? _e : value) !== null && _f !== void 0 ? _f : '';
        const predicateDefHandlerContext = Object.assign({
          value: value,
          oldValue: null,
          displayValue: params.formatValue,
          node: params.node,
          column: abColumn
        }, adaptableApi.internalApi.buildBaseContext());
        const badge = api.styledColumnApi.internalApi.getApplicableBadge(badgeStyle, predicateDefHandlerContext);
        const isNullValue = formattedValue === '' || formattedValue === null || formattedValue === undefined;
        if (!isNullValue && badge) {
          badgesConfig.push({
            badge: Object.assign({}, badge),
            value: formattedValue
          });
        }
      }
      this.renderBadges(badgesConfig, api, params);
    }
    renderSingularValues(params, adaptableApi) {
      var _a, _b, _c;
      const formattedValue = (_c = (_b = (_a = params.formatValue) === null || _a === void 0 ? void 0 : _a.call(params, params.value)) !== null && _b !== void 0 ? _b : params.value) !== null && _c !== void 0 ? _c : '';
      const predicateDefHandlerContext = Object.assign({
        value: params.value,
        oldValue: null,
        displayValue: params.formatValue,
        node: params.node,
        column: abColumn
      }, adaptableApi.internalApi.buildBaseContext());
      const badge = api.styledColumnApi.internalApi.getApplicableBadge(badgeStyle, predicateDefHandlerContext);
      const isNullValue = formattedValue === '' || formattedValue === null || formattedValue === undefined;
      if (
      // empty values
      isNullValue ||
      // no badge
      !badge) {
        this.eGui.innerHTML = formattedValue;
        return;
      }
      this.renderBadges([{
        badge,
        value: formattedValue
      }], api, params);
    }
    renderBadges(config, api, params) {
      const adaptable = this.getAdaptableInstance(params);
      const badges = config.map(({
        badge,
        value
      }, index) => {
        var _a;
        return React.createElement(Badge, {
          key: index,
          adaptableStyle: badge.Style,
          children: value,
          icon: badge.Icon,
          iconPosition: (_a = badge.IconPosition) !== null && _a !== void 0 ? _a : 'start',
          gap: 5
        });
      });
      this.unmountReactRoot = api.internalApi.getAdaptableInstance().renderReactRoot(renderWithAdaptableContext(React.createElement(React.Fragment, {
        children: badges
      }), adaptable), this.eGui);
    }
    getGui() {
      return this.eGui;
    }
    destroy() {
      var _a;
      (_a = this.unmountReactRoot) === null || _a === void 0 ? void 0 : _a.call(this);
    }
    refresh(params) {
      // by returning FALSE we force a re-render every time the cell value changes
      return false;
    }
  };
};