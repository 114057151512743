import Dialog from '../../../components/Dialog';
import { Box, Flex } from 'rebass';
import { StringExtensions } from '../../../Utilities/Extensions/StringExtensions';
import Input from '../../../components/Input';
import { useState } from 'react';
import SimpleButton from '../../../components/SimpleButton';
import * as React from 'react';
import FormLayout, { FormRow } from '../../../components/FormLayout';
import HelpBlock from '../../../components/HelpBlock';
import Radio from '../../../components/Radio';
export const AdaptablePopupTeamSharing = props => {
  const {
    showPopup,
    onClose,
    onConfirm,
    header
  } = props;
  const [config, setConfig] = useState({
    description: '',
    type: 'Snapshot'
  });
  return React.createElement(React.Fragment, null, React.createElement(Dialog, {
    modal: true,
    "data-name": "teamsharing-popup",
    isOpen: showPopup,
    onDismiss: onClose,
    autoFocus: false,
    showCloseButton: false,
    style: {
      minHeight: 'auto',
      maxWidth: '50%'
    }
  }, React.createElement(Flex, {
    flexDirection: "column"
  }, React.createElement(HelpBlock, {
    "data-name": "teamsharing-popup-description",
    margin: 2,
    mb: 2,
    p: 2,
    style: {
      fontSize: 'var(--ab-font-size-3)'
    }
  }, "Create a ", header, " which may be either:", React.createElement("ul", null, React.createElement("li", null, React.createElement("strong", null, "Snapshot"), ": a one-off share of an exact copy of the current object"), React.createElement("li", null, React.createElement("strong", null, "Active"), ": a \"live\" share that is automatically updated with every local change"))), React.createElement(FormLayout, {
    padding: 2
  }, React.createElement(FormRow, {
    label: "Name: "
  }, React.createElement(Input, {
    "data-name": "teamsharing-entity-name",
    autoFocus: true,
    value: config.description,
    type: "string",
    width: 300,
    placeholder: "Enter text",
    onChange: e => setConfig(Object.assign(Object.assign({}, config), {
      description: e.target.value
    }))
  })), React.createElement(FormRow, {
    label: "Share: "
  }, React.createElement(Flex, {
    flexDirection: "row"
  }, React.createElement(Radio, {
    "data-name": "teamsharing-entity-type-snapshot",
    checked: config.type === 'Snapshot',
    onChange: () => setConfig(Object.assign(Object.assign({}, config), {
      type: 'Snapshot'
    }))
  }, "Snapshot"), ' ', React.createElement(Radio, {
    "data-name": "teamsharing-entity-type-active",
    marginLeft: 3,
    checked: config.type === 'Active',
    onChange: () => setConfig(Object.assign(Object.assign({}, config), {
      type: 'Active'
    }))
  }, "Active"), ' '))), React.createElement(Box, {
    marginTop: 3
  }, React.createElement(Flex, {
    padding: 2
  }, React.createElement(SimpleButton, {
    "data-name": "cancel",
    tone: "neutral",
    variant: "raised",
    onClick: () => onClose()
  }, "Cancel"), React.createElement("div", {
    style: {
      flex: 1
    }
  }), React.createElement(SimpleButton, {
    "data-name": "ok",
    tone: "accent",
    variant: "raised",
    disabled: StringExtensions.IsNullOrEmpty(config.description),
    onClick: () => onConfirm(config)
  }, "OK"))))));
};