import * as DashboardRedux from '../../Redux/ActionsReducers/DashboardRedux';
import { ApiBase } from './ApiBase';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import * as React from 'react';
import { SystemDashboardRefresh } from '../../Redux/ActionsReducers/SystemRedux';
import { DashboardInternalApi } from '../Internal/DashboardInternalApi';
export class DashboardApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.internalApi = new DashboardInternalApi(adaptable);
  }
  getDashboardState() {
    return this.getAdaptableState().Dashboard;
  }
  getCustomToolbars() {
    return this.getDashboardOptions().customToolbars || [];
  }
  getCustomDashboardButtons() {
    return this.getDashboardOptions().customDashboardButtons || [];
  }
  getCustomDashboardButtonByLabel(buttonLabel) {
    const api = this.getAdaptableApi();
    const dashboardContext = Object.assign(Object.assign({}, this.getAdaptableApi().internalApi.buildBaseContext()), {
      dashboardState: api.dashboardApi.getDashboardState()
    });
    return this.getDashboardOptions().customDashboardButtons.find(cb => {
      api.internalApi.getLabelForButton(cb, dashboardContext) == buttonLabel;
    });
  }
  setModuleButtons(moduleButtons) {
    this.dispatchAction(DashboardRedux.DashboardSetModuleButtons(moduleButtons));
  }
  setDashboardTitle(title) {
    this.dispatchAction(DashboardRedux.DashboardSetTitle(title));
  }
  getCustomToolbarHTMLElement(customToolbarName) {
    let customToolbar = this.getCustomToolbarByName(customToolbarName);
    if (!customToolbar) {
      this.logWarn(`Could not find custom toolbar with name ${customToolbarName}`);
      return;
    }
    if (customToolbar.render) {
      return document.getElementById(this.internalApi.getCustomToolbarRenderContainerId(customToolbarName));
    }
    if (customToolbar.toolbarButtons) {
      this.logWarn(`(get/set)CustomToolbarHTMLElement(${customToolbarName}) should only be used in Vanilla AdapTable for toolbars created via a 'render' function! For React/Adaptable components let the framework renderer handle it!`);
      return document.getElementById(this.internalApi.getCustomToolbarButtonsContainerId(customToolbarName));
    }
    if (customToolbar.frameworkComponent) {
      this.logWarn(`(get/set)CustomToolbarHTMLElement(${customToolbarName}) should only be used in Vanilla AdapTable for toolbars created via a 'render' function! For React/Adaptable components let the framework renderer handle it!`);
      return document.getElementById(this.internalApi.getCustomToolbarComponentContainerId(customToolbarName));
    }
  }
  setCustomToolbarHTMLContent(customToolbarName, htmlContent) {
    let customRenderedContainer = this.getCustomToolbarHTMLElement(customToolbarName);
    if (customRenderedContainer) {
      this.adaptable.renderReactRoot(React.createElement("div", {
        dangerouslySetInnerHTML: {
          __html: htmlContent
        }
      }), customRenderedContainer);
    }
  }
  getCustomToolbarByName(customToolbarName) {
    return this.getCustomToolbars().find(ct => ct.name == customToolbarName);
  }
  getTabByName(tabName) {
    return (this.getDashboardState().Tabs || []).find(tab => tab.Name == tabName);
  }
  collapseDashboard() {
    this.dispatchAction(DashboardRedux.DashboardSetIsCollapsed(true));
  }
  expandDashboard() {
    this.dispatchAction(DashboardRedux.DashboardSetIsCollapsed(false));
  }
  refreshDashboard() {
    this.dispatchAction(SystemDashboardRefresh());
  }
  floatDashboard() {
    this.dispatchAction(DashboardRedux.DashboardSetIsFloating(true));
  }
  dockDashboard() {
    this.dispatchAction(DashboardRedux.DashboardSetIsFloating(false));
  }
  hideDashboard() {
    this.dispatchAction(DashboardRedux.DashboardSetIsHidden(true));
  }
  showDashboard() {
    this.dispatchAction(DashboardRedux.DashboardSetIsHidden(false));
  }
  getActiveTabIndex() {
    return this.getDashboardState().ActiveTabIndex;
  }
  getActiveTab() {
    return this.getDashboardState().Tabs[this.getActiveTabIndex()];
  }
  setTabs(Tabs) {
    this.dispatchAction(DashboardRedux.DashboardSetTabs(Tabs));
  }
  getTabs() {
    return this.getDashboardState().Tabs;
  }
  getModuleButtons() {
    return this.getDashboardState().ModuleButtons;
  }
  setPinnedToolbars(toolbars) {
    this.dispatchAction(DashboardRedux.DashboardSetPinnedToolbars(toolbars));
  }
  getPinnedToolbars() {
    return this.getDashboardState().PinnedToolbars;
  }
  isToolbarVisible(toolbar) {
    return (this.getCurrentToolbars() || []).find(c => c == toolbar) != undefined;
  }
  setActiveTabIndex(tabIndex) {
    this.dispatchAction(DashboardRedux.DashboardSetActiveTabIndex(tabIndex));
  }
  setActiveTab(tabName) {
    var _a;
    const tab = this.getTabByName(tabName);
    if (tab) {
      let tabIndex = (_a = this.getDashboardState().Tabs) === null || _a === void 0 ? void 0 : _a.findIndex(t => t);
      this.setActiveTabIndex(tabIndex);
    }
  }
  getCurrentToolbars() {
    // if hidden or collapsed return fasle
    if (this.getDashboardState().IsCollapsed == true || this.getDashboardState().IsHidden == true) {
      return [];
    }
    const currentTab = this.getActiveTab();
    if (!currentTab) {
      return [];
    }
    return currentTab.Toolbars;
  }
  openDashboardSettingsPanel() {
    this.showModulePopup(ModuleConstants.DashboardModuleId);
  }
  isDashboardCollapsed() {
    return this.getDashboardState().IsCollapsed;
  }
  isDashboardExpanded() {
    return !this.getDashboardState().IsCollapsed;
  }
  isDashboardFloating() {
    return this.getDashboardState().IsFloating;
  }
  isDashboardDocked() {
    return !this.getDashboardState().IsFloating;
  }
  isDashboardHidden() {
    return this.getDashboardState().IsHidden;
  }
  isDashboardVisible() {
    return !this.getDashboardState().IsHidden;
  }
}