import { ApiBase } from './ApiBase';
import { SystemDataChangeHistoryClearRow, SystemDataChangeHistoryDisable, SystemDataChangeHistoryEnable, SystemDataChangeHistoryResume, SystemDataChangeHistorySuspend, SystemDataChangeHistoryUndo, SystemDataChangeHistoryAdd } from '../../Redux/ActionsReducers/SystemRedux';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
export class DataChangeHistoryApiImpl extends ApiBase {
  constructor() {
    super(...arguments);
    this.getDataChangeHistoryKey = dataChangeInfo => {
      const columnId = dataChangeInfo.column.columnId;
      const primaryKeyValue = dataChangeInfo.primaryKeyValue;
      const changedAt = dataChangeInfo.changedAt;
      const showLastDataChangeOnly = this.getAdaptableApi().optionsApi.getDataChangeHistoryOptions().showLastDataChangeOnly;
      if (showLastDataChangeOnly) {
        return JSON.stringify({
          columnId,
          primaryKeyValue
        });
      } else {
        return JSON.stringify({
          columnId,
          primaryKeyValue,
          changedAt
        });
      }
    };
  }
  getDataChangeHistoryMode() {
    return this.getAdaptableState().System.DataChangeHistory.currentMode;
  }
  activateDataChangeHistory(forceReset) {
    const currentMode = this.getDataChangeHistoryMode();
    if (currentMode === 'ACTIVE' && !forceReset) {
      return;
    }
    if (forceReset || currentMode === 'INACTIVE') {
      this.dispatchAction(SystemDataChangeHistoryEnable());
    } else if (currentMode === 'SUSPENDED') {
      this.dispatchAction(SystemDataChangeHistoryResume());
    }
  }
  deactivateDataChangeHistory() {
    if (this.getDataChangeHistoryMode() === 'ACTIVE' || this.getDataChangeHistoryMode() === 'SUSPENDED') {
      this.dispatchAction(SystemDataChangeHistoryDisable());
    }
  }
  suspendDataChangeHistory() {
    if (this.getDataChangeHistoryMode() === 'ACTIVE') {
      this.dispatchAction(SystemDataChangeHistorySuspend());
    }
  }
  getDataChangeHistoryLog() {
    var _a;
    const changeLog = (_a = this.getAdaptableState().System.DataChangeHistory.logs) !== null && _a !== void 0 ? _a : {};
    return Object.values(changeLog);
  }
  addDataChangeHistoryEntry(dataChangeInfo) {
    const uniqueKey = this.getDataChangeHistoryKey(dataChangeInfo);
    this.dispatchAction(SystemDataChangeHistoryAdd(dataChangeInfo, uniqueKey));
  }
  undoDataChangeHistoryEntry(dataChangeInfo) {
    const uniqueKey = this.getDataChangeHistoryKey(dataChangeInfo);
    this.dispatchAction(SystemDataChangeHistoryUndo(dataChangeInfo, uniqueKey));
  }
  clearDataChangeHistoryEntry(dataChangeInfo) {
    const uniqueKey = this.getDataChangeHistoryKey(dataChangeInfo);
    this.dispatchAction(SystemDataChangeHistoryClearRow(dataChangeInfo, uniqueKey));
  }
  openDataChangeHistorySettingsPanel() {
    this.showModulePopup(ModuleConstants.DataChangeHistoryModuleId);
  }
}