import { AdaptableModuleBase } from './AdaptableModuleBase';
import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
import { EditGridFilterButton } from '../View/NamedQuery/EditCurrentQueryButton';
import { GridFilterStatusbar } from '../View/GridFilter/GridFilterStatusbar';
export class GridFilterModule extends AdaptableModuleBase {
  constructor(api) {
    super(ModuleConstants.GridFilterModuleId, ModuleConstants.GridFilterFriendlyName, 'filter-list', 'GridFilterPopup', 'Controls the Grid Filter funtionality', api);
  }
  getViewProperties() {
    return {
      getStatusBarPanelProps: () => {
        const popover = this.api.gridFilterApi.getCurrentGridFilterExpression();
        return {
          content: GridFilterStatusbar,
          extraActions: [EditGridFilterButton],
          popover
        };
      }
    };
  }
}