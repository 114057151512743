import * as React from 'react';
import isPlainObject from 'lodash/isPlainObject';
export default (({
  domProps,
  item,
  onItemClick,
  index,
  columns,
  className,
  idProperty,
  style
}) => {
  const key = item[idProperty] || index;
  if (!isPlainObject(item)) {
    return React.createElement("tr", Object.assign({
      onClick: onItemClick,
      key: key,
      className: className
    }, domProps), React.createElement("td", {
      colSpan: columns.length,
      style: style
    }, item));
  }
  return React.createElement("tr", Object.assign({
    onClick: onItemClick,
    key: key,
    className: className
  }, domProps), columns.map(col => {
    return React.createElement("td", {
      key: col,
      style: style
    }, item[col]);
  }));
});