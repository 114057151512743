import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
import { AdaptableModuleBase } from './AdaptableModuleBase';
import * as PopupRedux from '../Redux/ActionsReducers/PopupRedux';
export class SettingsPanelModule extends AdaptableModuleBase {
  constructor(api) {
    const friendlyName = api.optionsApi.getSettingsPanelOptions().title;
    super(ModuleConstants.SettingsPanelModuleId, friendlyName, 'settings', null, 'Manage all Adaptable Settings', api);
  }
  createColumnMenuItems(column) {
    return [
    // To do : get the icon and name from settings in case they have changed?
    this.createMenuItemReduxAction('settings-panel-open', 'Open ' + this.moduleInfo.FriendlyName, this.moduleInfo.Glyph, PopupRedux.PopupShowScreen())];
  }
  createContextMenuItems(menuContext) {
    return [this.createMenuItemReduxAction('settings-panel-open', 'Open ' + this.moduleInfo.FriendlyName, this.moduleInfo.Glyph, PopupRedux.PopupShowScreen())];
  }
  createModuleMenuItem(source) {
    if (this.isModuleAvailable()) {
      return this.createMenuItemReduxAction('settings-panel-open', 'Open ' + this.moduleInfo.FriendlyName, this.moduleInfo.Glyph, PopupRedux.PopupShowScreen());
    }
  }
}