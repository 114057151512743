import * as React from 'react';
import { StringExtensions } from '../../../Utilities/Extensions/StringExtensions';
import FieldWrap from '../../../components/FieldWrap';
import SimpleButton from '../../../components/SimpleButton';
import Input from '../../../components/Input';
export const AdaptableFormControlTextClear = React.forwardRef((props, ref) => {
  let closeButtonTooltip = props.value ? 'Clear' : null;
  const inputRef = React.useRef(null);
  const {
    focusOnClear = true
  } = props;
  return React.createElement(FieldWrap, {
    onClick: props.onClick,
    className: props.className,
    style: Object.assign({
      background: 'var(--ab-color-defaultbackground)',
      overflow: 'visible',
      width: '100%'
    }, props.style)
  }, React.createElement(Input, {
    autoFocus: props.autoFocus,
    style: props.inputStyle,
    ref: r => {
      inputRef.current = r;
      if (!ref) {
        return;
      }
      if (typeof ref === 'function') {
        ref(r);
      } else {
        ref.current = r;
      }
    },
    type: "text",
    placeholder: props.placeholder,
    value: props.value,
    onChange: x => props.OnTextChange(x.target.value)
  }), React.createElement(SimpleButton, {
    variant: "text",
    icon: "close",
    tooltip: closeButtonTooltip,
    px: 0,
    py: 0,
    marginRight: 1,
    onClick: () => {
      props.OnTextChange('');
      const input = inputRef.current;
      if (!focusOnClear) {
        return;
      }
      requestAnimationFrame(() => {
        if (input && input.focus) {
          input.focus();
        }
      });
    },
    disabled: StringExtensions.IsNullOrEmpty(props.value.toString())
  }));
});