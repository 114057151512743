import Helper from '../Utilities/Helpers/Helper';
import clamp from 'lodash/clamp';
export const getPercentBarRendererForColumn = (styledColumn, abColumn, api) => {
  if (!styledColumn.PercentBarStyle) {
    return;
  }
  return class PercentBarRenderer {
    init(params) {
      var _a, _b;
      const min = api.styledColumnApi.internalApi.getNumericStyleMinValue(styledColumn, abColumn, params.node, params.value);
      const max = api.styledColumnApi.internalApi.getNumericStyleMaxValue(styledColumn, abColumn, params.node, params.value);
      let value = params.value;
      if (Helper.objectNotExists(value)) {
        value = 0;
      }
      // We no longer support showing Percent Bar in Group Rows as it only made sense for Min / Max
      if (api.gridApi.isGroupRowNode(params.node)) {
        if (params.value) {
          this.eGui = document.createElement('div');
          this.eGui.append(params.value);
        }
        return;
      }
      const clampedValue = clamp(value, min, max);
      const percentageValue = (clampedValue - min) / (max - min) * 100;
      let cellBackColor;
      const percentBarStyle = styledColumn.PercentBarStyle;
      if (percentBarStyle.ColumnComparison) {
        cellBackColor = percentBarStyle.ColumnComparison.Color;
      } else {
        const matchingRange = api.styledColumnApi.internalApi.findRangeForColumn(percentBarStyle.CellRanges, abColumn, percentBarStyle.RangeValueType, params.value);
        if (matchingRange) {
          cellBackColor = matchingRange.Color;
        }
      }
      const hasCellText = percentBarStyle.CellText && percentBarStyle.CellText.length > 0;
      const isTextPositionMerged = percentBarStyle.CellTextPosition === 'Merged';
      if (cellBackColor) {
        this.eGui = document.createElement('div');
        this.eGui.className = 'ab-PercentBar__wrapper';
        this.eGui.style.height = hasCellText && !isTextPositionMerged ? '80%' : '100%';
        this.eGui.style.display = 'flex';
        this.eGui.style.flexDirection = 'column';
        this.eGui.style.justifyContent = 'center';
        const barEl = document.createElement('div');
        barEl.className = 'ab-PercentBar__bar';
        barEl.style.position = 'relative';
        barEl.style.background = percentBarStyle.BackColor;
        barEl.style.flex = '1';
        const barInsideEl = document.createElement('div');
        barInsideEl.className = 'ab-PercentBar__barInside';
        barInsideEl.style.background = cellBackColor;
        barInsideEl.style.height = '100%';
        barInsideEl.style.width = `${percentageValue.toFixed(0)}%`;
        barEl.append(barInsideEl);
        this.eGui.append(barEl);
        if (hasCellText) {
          const textEl = document.createElement('div');
          textEl.className = 'ab-PercentBar__text';
          textEl.style.lineHeight = '1.2';
          if (percentBarStyle.CellText.includes('CellValue')) {
            textEl.innerText = (_b = (_a = params.formatValue) === null || _a === void 0 ? void 0 : _a.call(params, params.value)) !== null && _b !== void 0 ? _b : params.value;
          }
          if (percentBarStyle.CellText.includes('PercentageValue')) {
            textEl.innerText += ' ' + `(${percentageValue.toFixed(0)}%)`;
          }
          switch (percentBarStyle.CellTextPosition) {
            case 'Above':
              this.eGui.prepend(textEl);
              break;
            case 'Below':
              this.eGui.append(textEl);
              break;
            case 'Merged':
              textEl.style.position = 'absolute';
              // This allows to center verticly without knowing the height of the row
              textEl.style.top = '50%';
              textEl.style.transform = 'translateY(-50%)';
              // adds a bit of padding to the start/end of the bar
              textEl.style.left = '0';
              textEl.style.right = '0';
              textEl.style.paddingLeft = '5px';
              textEl.style.paddingRight = '5px';
              barEl.append(textEl);
              break;
            default:
              this.eGui.append(textEl);
              break;
          }
        }
      }
    }
    getGui() {
      return this.eGui;
    }
    refresh(params) {
      // by returning FALSE we force a re-render every time the cell value changes
      return false;
    }
  };
};