import * as React from 'react';
import { IconComponent } from '../../../components/Icon';
import { useCustomIcon } from './useCustomIcon';
/**
 * This is a connected componnet.
 * It needs to be used only inside adaptable context.
 */
export const AdaptableIconComponent = props => {
  const icon = useCustomIcon(props.icon);
  return React.createElement(IconComponent, Object.assign({}, props, {
    icon: icon
  }));
};