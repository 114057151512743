export const editorButtonsSearch = [{
  functionName: 'AND',
  data: 'AND'
}, {
  functionName: 'OR',
  data: 'OR'
}, {
  functionName: 'ADD',
  data: '+',
  icon: 'plus'
}, {
  functionName: 'SUB',
  data: '-',
  icon: 'minus'
}, {
  functionName: 'MUL',
  data: '*',
  icon: 'multiplication'
}, {
  functionName: 'DIV',
  data: '/',
  icon: 'division'
}, {
  functionName: 'MOD',
  data: '%',
  icon: 'percent'
}, {
  functionName: 'POW',
  data: '^',
  icon: 'exponent'
}, {
  functionName: 'EQ',
  data: '=',
  icon: 'equals'
}, {
  functionName: 'NEQ',
  data: '!=',
  icon: 'not-equal'
}, {
  functionName: 'LT',
  data: '<',
  icon: 'less-than'
}, {
  functionName: 'GT',
  data: '>',
  icon: 'greater-than'
}, {
  functionName: 'LTE',
  data: '<=',
  icon: 'less-than-or-equal'
}, {
  functionName: 'GTE',
  data: '>=',
  icon: 'greater-than-or-equal'
}];