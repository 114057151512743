import { EMPTY_ARRAY } from '../../Utilities/Constants/GeneralConstants';
import { LAYOUT_SELECT } from './LayoutRedux';
import AdaptableHelper from '../../Utilities/Helpers/AdaptableHelper';
/**
 * @ReduxAction Columns have been set in the Grid
 */
export const GRID_SET_COLUMNS = 'GRID_SET_COLUMNS';
/**
 * @ReduxAction A Column has been added to the Grid
 */
export const GRID_ADD_COLUMN = 'GRID_ADD_COLUMN';
/**
 * @ReduxAction Columns have been added to the Grid
 */
export const GRID_ADD_COLUMNS = 'GRID_ADD_COLUMNS';
/**
 * @ReduxAction A Column has been removed from the Grid
 */
export const GRID_REMOVE_COLUMN = 'GRID_REMOVE_COLUMN';
/**
 * @ReduxAction A Column has been edited in the Grid
 */
export const GRID_EDIT_COLUMN = 'GRID_EDIT_COLUMN';
/**
 * @ReduxAction Sorting has been set in the Grid
 */
export const GRID_SET_SORT = 'GRID_SET_SORT';
/**
 * @ReduxAction Sorting has been cleared in the Grid
 */
export const GRID_CLEAR_SORT = 'GRID_CLEAR_SORT';
/**
 * @ReduxAction Cells have been selected in the Grid
 */
export const GRID_SET_SELECTED_CELLS = 'GRID_SET_SELECTED_CELLS';
/**
 * @ReduxAction Rows have been selected in the Grid
 */
export const GRID_SET_SELECTED_ROWS = 'GRID_SET_SELECTED_ROWS';
/**
 * @ReduxAction Cell Summary Information has been created
 */
export const GRID_CREATE_CELLS_SUMMARY = 'GRID_CREATE_CELLS_SUMMARY';
/**
 * @ReduxAction Grid Cell summary is set
 */
export const GRID_SET_CELLS_SUMMARY = 'GRID_SET_CELLS_SUMMARY';
/**
 * @ReduxAction Grid cells have been refreshed
 */
export const GRID_REFRESH_CELLS = 'GRID_REFRESH_CELLS';
/**
 * @ReduxAction Module Dropdown menu items have been set
 */
export const GRID_SET_SETTINGS_PANEL_ITEMS = 'GRID_SET_SETTINGS_PANEL_ITEMS';
/**
 * @ReduxAction Module Button menu items have been set
 */
export const GRID_SET_MENU_MODULE_ITEMS = 'GRID_SET_MENU_MODULE_ITEMS';
/**
 * @ReduxAction Grid is put in pivot mode
 */
export const GRID_SET_PIVOT_MODE_ON = 'GRID_SET_PIVOT_MODE_ON';
/**
 * @ReduxAction Grid is taken out of pivot mode
 */
export const GRID_SET_PIVOT_MODE_OFF = 'GRID_SET_PIVOT_MODE_OFF';
/**
 * @ReduxAction Grid is put in tree mode
 */
export const GRID_SET_TREE_MODE_ON = 'GRID_SET_TREE_MODE_ON';
/**
 * @ReduxAction Grid is taken out of tree mode
 */
export const GRID_SET_TREE_MODE_OFF = 'GRID_SET_TREE_MODE_OFF';
export const LAYOUT_UPDATE_CURRENT_DRAFT = 'LAYOUT_UPDATE_CURRENT_DRAFT';
export const LAYOUT_DRAFT_COLUMN_FILTER_ADD = 'LAYOUT_DRAFT_COLUMN_FILTER_ADD';
export const LAYOUT_DRAFT_COLUMN_FILTER_EDIT = 'LAYOUT_DRAFT_COLUMN_FILTER_EDIT';
export const LAYOUT_DRAFT_COLUMN_FILTER_SET = 'LAYOUT_DRAFT_COLUMN_FILTER_SET';
export const LAYOUT_DRAFT_COLUMN_FILTER_CLEAR_ALL = 'LAYOUT_DRAFT_COLUMN_FILTER_CLEAR_ALL';
export const LAYOUT_DRAFT_COLUMN_FILTER_CLEAR = 'LAYOUT_DRAFT_COLUMN_FILTER_CLEAR';
export const LAYOUT_DRAFT_COLUMN_FILTER_SUSPEND = 'LAYOUT_DRAFT_COLUMN_FILTER_SUSPEND';
export const LAYOUT_DRAFT_COLUMN_FILTER_SUSPEND_ALL = 'LAYOUT_DRAFT_COLUMN_FILTER_SUSPEND_ALL';
export const LAYOUT_DRAFT_COLUMN_FILTER_UNSUSPEND = 'LAYOUT_DRAFT_COLUMN_FILTER_UNSUSPEND';
export const LAYOUT_DRAFT_COLUMN_FILTER_UNSUSPEND_ALL = 'LAYOUT_DRAFT_COLUMN_FILTER_UNSUSPEND_ALL';
export const LAYOUT_DRAFT_GRID_FILTER_SET = 'LAYOUT_DRAFT_GRID_FILTER_SET';
export const GridSetColumns = Columns => ({
  type: GRID_SET_COLUMNS,
  columns: Columns
});
export const GridAddColumn = Column => ({
  type: GRID_ADD_COLUMN,
  column: Column
});
export const GridAddColumns = Columns => ({
  type: GRID_ADD_COLUMNS,
  columns: Columns
});
export const GridRemoveColumn = Column => ({
  type: GRID_REMOVE_COLUMN,
  column: Column
});
export const GridEditColumn = Column => ({
  type: GRID_EDIT_COLUMN,
  column: Column
});
export const GridSetSort = ColumnSorts => ({
  type: GRID_SET_SORT,
  columnSorts: ColumnSorts
});
export const GridClearSort = () => ({
  type: GRID_CLEAR_SORT
});
export const GridSetSelectedCells = SelectedCellInfo => ({
  type: GRID_SET_SELECTED_CELLS,
  selectedCellInfo: SelectedCellInfo
});
export const GridSetSelectedRows = SelectedRowInfo => ({
  type: GRID_SET_SELECTED_ROWS,
  selectedRowInfo: SelectedRowInfo
});
export const GridCreateCellSummary = () => ({
  type: GRID_CREATE_CELLS_SUMMARY
});
export const GridSetCellSummary = CellSummary => ({
  type: GRID_SET_CELLS_SUMMARY,
  cellSummary: CellSummary
});
export const GridRefreshCells = (rowNodes, columnIds) => ({
  type: GRID_REFRESH_CELLS,
  rowNodes,
  columnIds
});
export const SetPivotModeOn = () => ({
  type: GRID_SET_PIVOT_MODE_ON
});
export const SetPivotModeOff = () => ({
  type: GRID_SET_PIVOT_MODE_OFF
});
export const SetTreeModeOn = () => ({
  type: GRID_SET_TREE_MODE_ON
});
export const SetTreeModeOff = () => ({
  type: GRID_SET_TREE_MODE_OFF
});
export const setSettingsPanelItems = MenuItems => ({
  type: GRID_SET_SETTINGS_PANEL_ITEMS,
  menuItems: MenuItems
});
export const SetMenuModuleItems = MenuItems => ({
  type: GRID_SET_MENU_MODULE_ITEMS,
  menuItems: MenuItems
});
export const LayoutUpdateCurrentDraft = layout => ({
  type: LAYOUT_UPDATE_CURRENT_DRAFT,
  layout
});
export const LayoutDraftColumnFilterAdd = columnFilter => ({
  type: LAYOUT_DRAFT_COLUMN_FILTER_ADD,
  columnFilter
});
export const LayoutDraftColumnFilterEdit = columnFilter => ({
  type: LAYOUT_DRAFT_COLUMN_FILTER_EDIT,
  columnFilter
});
export const LayoutDraftColumnFilterSet = columnFilter => ({
  type: LAYOUT_DRAFT_COLUMN_FILTER_SET,
  columnFilter
});
export const LayoutDraftColumnFilterClear = columnFilter => ({
  type: LAYOUT_DRAFT_COLUMN_FILTER_CLEAR,
  columnFilter
});
export const LayoutDraftColumnFilterClearAll = () => ({
  type: LAYOUT_DRAFT_COLUMN_FILTER_CLEAR_ALL
});
export const LayoutDraftColumnFilterSuspend = columnFilter => ({
  type: LAYOUT_DRAFT_COLUMN_FILTER_SUSPEND,
  columnFilter
});
export const LayoutDraftColumnFilterSuspendAll = () => ({
  type: LAYOUT_DRAFT_COLUMN_FILTER_SUSPEND_ALL
});
export const LayoutDraftColumnFilterUnsuspend = columnFilter => ({
  type: LAYOUT_DRAFT_COLUMN_FILTER_UNSUSPEND,
  columnFilter
});
export const LayoutDraftColumnFilterUnsuspendAll = () => ({
  type: LAYOUT_DRAFT_COLUMN_FILTER_UNSUSPEND_ALL
});
export const LayoutDraftGridFilterSet = gridFilter => ({
  type: LAYOUT_DRAFT_GRID_FILTER_SET,
  gridFilter
});
const initialState = {
  Columns: EMPTY_ARRAY,
  CurrentLayout: null,
  ColumnSorts: EMPTY_ARRAY,
  SelectedCellInfo: null,
  SelectedRowInfo: null,
  CellSummary: null,
  SettingPanelModuleMenuItems: EMPTY_ARRAY,
  ModuleButtonMenuItems: EMPTY_ARRAY,
  IsGridInPivotMode: false,
  IsGridInTreeMode: false
};
export const GridReducer = (state = initialState, action) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  switch (action.type) {
    case GRID_SET_COLUMNS:
      return Object.assign({}, state, {
        Columns: [].concat(action.columns)
      });
    case GRID_ADD_COLUMN:
      const actionTypedAdd = action;
      let columns = [].concat(state.Columns);
      columns.push(actionTypedAdd.column);
      return Object.assign({}, state, {
        Columns: columns
      });
    case GRID_ADD_COLUMNS:
      const actionTypedAddMany = action;
      return Object.assign({}, state, {
        Columns: [...state.Columns, ...actionTypedAddMany.columns]
      });
    case GRID_EDIT_COLUMN:
      const actionGridColumn = action.column;
      return Object.assign(Object.assign({}, state), {
        Columns: state.Columns.map(abObject => abObject.Uuid === actionGridColumn.Uuid ? actionGridColumn : abObject)
      });
    case GRID_REMOVE_COLUMN:
      const removeColumn = action.column;
      return Object.assign(Object.assign({}, state), {
        Columns: state.Columns.filter(abObject => abObject.Uuid !== removeColumn.Uuid)
      });
    case GRID_SET_SORT:
      return Object.assign({}, state, {
        ColumnSorts: action.columnSorts
      });
    case GRID_CLEAR_SORT:
      return Object.assign({}, state, {
        ColumnSorts: undefined
      });
    case GRID_SET_SELECTED_CELLS:
      return Object.assign({}, state, {
        SelectedCellInfo: action.selectedCellInfo
      });
    case GRID_SET_SELECTED_ROWS:
      return Object.assign({}, state, {
        SelectedRowInfo: action.selectedRowInfo
      });
    case GRID_SET_CELLS_SUMMARY:
      return Object.assign({}, state, {
        CellSummary: action.cellSummary
      });
    case GRID_SET_SETTINGS_PANEL_ITEMS:
      {
        const actionTyped = action;
        const menuItems = actionTyped.menuItems.sort((a, b) => a.label < b.label ? -1 : a.label > b.label ? 1 : 0);
        return Object.assign({}, state, {
          SettingPanelModuleMenuItems: menuItems
        });
      }
    case GRID_SET_MENU_MODULE_ITEMS:
      {
        const actionTyped = action;
        const menuItems = actionTyped.menuItems.sort((a, b) => a.label < b.label ? -1 : a.label > b.label ? 1 : 0);
        return Object.assign({}, state, {
          ModuleButtonMenuItems: menuItems
        });
      }
    case LAYOUT_UPDATE_CURRENT_DRAFT:
      {
        const currentDraftLayout = action.layout;
        return Object.assign({}, state, {
          CurrentLayout: currentDraftLayout
        });
      }
    case LAYOUT_DRAFT_COLUMN_FILTER_SET:
      {
        const currentDraftLayout = state.CurrentLayout;
        if (currentDraftLayout) {
          let columnFilters = (_a = currentDraftLayout.ColumnFilters) !== null && _a !== void 0 ? _a : [];
          const columnFilterAction = action.columnFilter;
          AdaptableHelper.addUuidAndSource(columnFilterAction);
          columnFilters = columnFilters.filter(colFilter => {
            return colFilter.ColumnId !== columnFilterAction.ColumnId;
          }).concat(columnFilterAction);
          return Object.assign({}, state, {
            CurrentLayout: Object.assign(Object.assign({}, currentDraftLayout), {
              ColumnFilters: columnFilters
            })
          });
        }
      }
    case LAYOUT_DRAFT_COLUMN_FILTER_ADD:
      {
        const currentDraftLayout = state.CurrentLayout;
        if (currentDraftLayout) {
          let columnFilters = [...((_b = currentDraftLayout.ColumnFilters) !== null && _b !== void 0 ? _b : [])];
          const columnFilterAction = action.columnFilter;
          AdaptableHelper.addUuidAndSource(columnFilterAction);
          columnFilters.push(columnFilterAction);
          return Object.assign({}, state, {
            CurrentLayout: Object.assign(Object.assign({}, currentDraftLayout), {
              ColumnFilters: columnFilters
            })
          });
        }
      }
    case LAYOUT_DRAFT_COLUMN_FILTER_EDIT:
      {
        const currentDraftLayout = state.CurrentLayout;
        if (currentDraftLayout) {
          const columnFilterAction = action.columnFilter;
          let columnFilters = ((_c = currentDraftLayout.ColumnFilters) !== null && _c !== void 0 ? _c : []).map(abObject => abObject.Uuid === columnFilterAction.Uuid ? columnFilterAction : abObject);
          return Object.assign({}, state, {
            CurrentLayout: Object.assign(Object.assign({}, currentDraftLayout), {
              ColumnFilters: columnFilters
            })
          });
        }
      }
    case LAYOUT_DRAFT_COLUMN_FILTER_CLEAR:
      {
        const currentDraftLayout = state.CurrentLayout;
        if (currentDraftLayout) {
          let columnFilters = [...((_d = currentDraftLayout.ColumnFilters) !== null && _d !== void 0 ? _d : [])];
          const actionTypedDelete = action;
          const index = actionTypedDelete.columnFilter ? columnFilters.findIndex(i => i.Uuid == actionTypedDelete.columnFilter.Uuid) : -1;
          if (index != -1) {
            columnFilters.splice(index, 1);
          }
          return Object.assign({}, state, {
            CurrentLayout: Object.assign(Object.assign({}, currentDraftLayout), {
              ColumnFilters: columnFilters
            })
          });
        }
      }
    case LAYOUT_DRAFT_COLUMN_FILTER_CLEAR_ALL:
      {
        const currentDraftLayout = state.CurrentLayout;
        if (currentDraftLayout) {
          return Object.assign({}, state, {
            CurrentLayout: Object.assign(Object.assign({}, currentDraftLayout), {
              ColumnFilters: []
            })
          });
        }
      }
    case LAYOUT_DRAFT_COLUMN_FILTER_SUSPEND:
      {
        const currentDraftLayout = state.CurrentLayout;
        if (currentDraftLayout) {
          const columnFilterAction = action.columnFilter;
          let columnFilters = ((_e = currentDraftLayout.ColumnFilters) !== null && _e !== void 0 ? _e : []).map(abObject => abObject.Uuid === columnFilterAction.Uuid ? Object.assign(Object.assign({}, abObject), {
            IsSuspended: true
          }) : abObject);
          return Object.assign({}, state, {
            CurrentLayout: Object.assign(Object.assign({}, currentDraftLayout), {
              ColumnFilters: columnFilters
            })
          });
        }
      }
    case LAYOUT_DRAFT_COLUMN_FILTER_SUSPEND_ALL:
      {
        const currentDraftLayout = state.CurrentLayout;
        if (currentDraftLayout) {
          let columnFilters = ((_f = currentDraftLayout.ColumnFilters) !== null && _f !== void 0 ? _f : []).map(abObject => Object.assign(Object.assign({}, abObject), {
            IsSuspended: true
          }));
          return Object.assign({}, state, {
            CurrentLayout: Object.assign(Object.assign({}, currentDraftLayout), {
              ColumnFilters: columnFilters
            })
          });
        }
      }
    case LAYOUT_DRAFT_COLUMN_FILTER_UNSUSPEND:
      {
        const currentDraftLayout = state.CurrentLayout;
        if (currentDraftLayout) {
          const columnFilterAction = action.columnFilter;
          let columnFilters = ((_g = currentDraftLayout.ColumnFilters) !== null && _g !== void 0 ? _g : []).map(abObject => abObject.Uuid === columnFilterAction.Uuid ? Object.assign(Object.assign({}, abObject), {
            IsSuspended: false
          }) : abObject);
          return Object.assign({}, state, {
            CurrentLayout: Object.assign(Object.assign({}, currentDraftLayout), {
              ColumnFilters: columnFilters
            })
          });
        }
      }
    case LAYOUT_DRAFT_COLUMN_FILTER_UNSUSPEND_ALL:
      {
        const currentDraftLayout = state.CurrentLayout;
        if (currentDraftLayout) {
          let columnFilters = ((_h = currentDraftLayout.ColumnFilters) !== null && _h !== void 0 ? _h : []).map(abObject => Object.assign(Object.assign({}, abObject), {
            IsSuspended: false
          }));
          return Object.assign({}, state, {
            CurrentLayout: Object.assign(Object.assign({}, currentDraftLayout), {
              ColumnFilters: columnFilters
            })
          });
        }
      }
    case LAYOUT_DRAFT_GRID_FILTER_SET:
      {
        const currentDraftLayout = state.CurrentLayout;
        if (currentDraftLayout) {
          const gridFilter = action.gridFilter;
          const CurrentLayout = Object.assign(Object.assign({}, currentDraftLayout), {
            GridFilter: gridFilter
          });
          if (!gridFilter) {
            delete CurrentLayout.GridFilter;
          }
          return Object.assign({}, state, {
            CurrentLayout
          });
        }
      }
    case LAYOUT_SELECT:
      {
        return Object.assign({}, state, {
          CurrentLayout: null
        });
      }
    case GRID_SET_PIVOT_MODE_ON:
      return Object.assign({}, state, {
        IsGridInPivotMode: true
      });
    case GRID_SET_PIVOT_MODE_OFF:
      return Object.assign({}, state, {
        IsGridInPivotMode: false
      });
    case GRID_SET_TREE_MODE_ON:
      return Object.assign({}, state, {
        IsGridInTreeMode: true
      });
    case GRID_SET_TREE_MODE_OFF:
      return Object.assign({}, state, {
        IsGridInTreeMode: false
      });
    default:
      return state;
  }
};