import { AdaptableModuleBase } from './AdaptableModuleBase';
import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
export class ToolPanelModule extends AdaptableModuleBase {
  constructor(api) {
    super(ModuleConstants.ToolPanelModuleId, ModuleConstants.ToolPanelFriendlyName, 'clipboard', 'ToolPanelPopup', 'An alternative to using the Dashboard is the AdapTable Tool Panel, the Tool Panel is the collapsible area to the right of the Grid.', api);
  }
  isModuleAvailable() {
    if (!super.isModuleAvailable()) {
      return false;
    }
    return this.api.internalApi.getAdaptableInstance().hasAdaptableToolPanel;
  }
}