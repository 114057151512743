import * as React from 'react';
import { StatusColour } from '../../PredefinedConfig/Common/Enums';
import { PreviewResultsPanel } from '../Components/PreviewResultsPanel';
import { Flex } from 'rebass';
import join from '../../components/utils/join';
import * as GeneralConstants from '../../Utilities/Constants/GeneralConstants';
import { ColumnValueSelector } from '../Components/Selectors/ColumnValueSelector';
import { StringExtensions } from '../../Utilities/Extensions/StringExtensions';
import { ButtonApply } from '../Components/Buttons/ButtonApply';
import { AdaptablePopover } from '../AdaptablePopover';
import { UIHelper } from '../UIHelper';
import * as SystemRedux from '../../Redux/ActionsReducers/SystemRedux';
import * as BulkUpdateRedux from '../../Redux/ActionsReducers/BulkUpdateRedux';
import { connect } from 'react-redux';
class BulkUpdateViewPanelComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Disabled: true
    };
  }
  componentDidMount() {
    if (this.props.api) {
      let adaptable = this.props.api.internalApi.getAdaptableInstance();
      if (adaptable) {
        this.cleanupEvent = adaptable._on('CellsSelected', () => {
          this.checkSelectedCells();
        });
      }
    }
    this.checkSelectedCells();
  }
  componentWillUnmount() {
    var _a;
    (_a = this.cleanupEvent) === null || _a === void 0 ? void 0 : _a.call(this);
  }
  render() {
    let statusColour = this.getStatusColour();
    let selectedColumn = this.props.BulkUpdateValidationResult.Column;
    let previewPanel = React.createElement(PreviewResultsPanel, {
      previewInfo: this.props.PreviewInfo,
      api: this.props.api,
      selectedColumn: selectedColumn,
      showPanel: true,
      showHeader: false
    });
    let shouldDisable = this.props.accessLevel == 'ReadOnly' || !this.props.BulkUpdateValidationResult.IsValid || this.props.InPivotMode == true;
    const applyStyle = {
      color: statusColour,
      fill: 'currentColor'
    };
    const elementType = this.props.viewType === 'Toolbar' ? 'DashboardToolbar' : 'ToolPanel';
    return React.createElement(Flex, {
      minWidth: 150,
      flexDirection: "row",
      className: join(shouldDisable ? GeneralConstants.READ_ONLY_STYLE : '', `ab-${elementType}__BulkUpdate__wrap`),
      flexWrap: this.props.viewType === 'ToolPanel' ? 'wrap' : 'nowrap'
    }, React.createElement(Flex, null, React.createElement(ColumnValueSelector, {
      selectedGridCells: this.props.SelectedGridCells,
      newLabel: "New",
      existingLabel: "Existing",
      dropdownButtonProps: {
        listMinWidth: 160
      },
      className: `ab-${elementType}__BulkUpdate__select`,
      disabled: shouldDisable,
      selectedColumnValue: this.props.BulkUpdateValue,
      selectedColumn: selectedColumn,
      api: this.props.api,
      onColumnValueChange: columns => this.onColumnValueSelectedChanged(columns)
    })), React.createElement(Flex, null, !shouldDisable && StringExtensions.IsNotNullOrEmpty(this.props.BulkUpdateValue) && React.createElement(ButtonApply, {
      marginLeft: 2,
      className: `ab-${elementType}__BulkUpdate__apply`,
      onClick: () => this.onApplyClick(),
      style: applyStyle,
      tooltip: "Apply Bulk Update",
      disabled: StringExtensions.IsNullOrEmpty(this.props.BulkUpdateValue) || this.props.PreviewInfo != null && this.props.PreviewInfo.previewValidationSummary.validationResult == 'All',
      accessLevel: this.props.accessLevel
    }, this.props.viewType === 'ToolPanel' && 'Update'), !shouldDisable && StringExtensions.IsNotNullOrEmpty(this.props.BulkUpdateValue) && React.createElement(AdaptablePopover, {
      popoverMinWidth: 360,
      className: `ab-${elementType}__BulkUpdate__info`,
      headerText: "Preview Results",
      bodyText: [previewPanel],
      MessageType: UIHelper.getMessageTypeByStatusColour(statusColour),
      useButton: true,
      showEvent: 'focus',
      hideEvent: "blur"
    })));
  }
  onColumnValueSelectedChanged(selectedColumnValue) {
    this.props.onBulkUpdateValueChange(selectedColumnValue);
  }
  checkSelectedCells() {
    this.props.onBulkUpdateCheckSelectedCells();
    if (StringExtensions.IsNotNullOrEmpty(this.props.BulkUpdateValue)) {
      this.props.onBulkUpdateValueChange('');
    }
  }
  getStatusColour() {
    if (StringExtensions.IsNotNullOrEmpty(this.props.BulkUpdateValue) && this.props.PreviewInfo) {
      if (this.props.PreviewInfo.previewValidationSummary.validationResult == 'All') {
        return StatusColour.Error;
      }
      if (this.props.PreviewInfo.previewValidationSummary.validationResult == 'Some') {
        return StatusColour.Warn;
      }
    }
    return StatusColour.Success;
  }
  onApplyClick() {
    this.onApplyBulkUpdate();
  }
  onApplyBulkUpdate() {
    this.props.onRunBulkUpdate();
    this.props.onBulkUpdateValueChange(undefined);
  }
}
function mapStateToProps(state, ownProps) {
  var _a;
  return {
    SelectedGridCells: (_a = state.Grid.SelectedCellInfo) === null || _a === void 0 ? void 0 : _a.gridCells,
    BulkUpdateValue: state.System.BulkUpdateValue,
    BulkUpdateValidationResult: state.System.BulkUpdateValidationResult,
    PreviewInfo: state.System.BulkUpdatePreviewInfo,
    InPivotMode: state.Grid.IsGridInPivotMode
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onBulkUpdateValueChange: value => dispatch(SystemRedux.BulkUpdateChangeValue(value)),
    onBulkUpdateCheckSelectedCells: () => dispatch(SystemRedux.BulkUpdateCheckCellSelection()),
    onRunBulkUpdate: () => dispatch(BulkUpdateRedux.BulkUpdateComplete(false))
  };
}
export let BulkUpdateViewPanelControl = connect(mapStateToProps, mapDispatchToProps)(BulkUpdateViewPanelComponent);