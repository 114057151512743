import { __rest } from "tslib";
import * as React from 'react';
import { IconComponent } from '../Icon';
import join from '../utils/join';
export const Toggle = _a => {
  var {
      onPressedChange,
      pressed,
      icon,
      children
    } = _a,
    rest = __rest(_a, ["onPressedChange", "pressed", "icon", "children"]);
  const baseClassName = 'ab-Toggle';
  return React.createElement("button", Object.assign({}, rest, {
    className: join(baseClassName, pressed && `${baseClassName}--pressed`),
    onClick: () => onPressedChange(!pressed)
  }), icon && React.createElement(IconComponent, {
    icon: {
      name: icon
    }
  }), children);
};