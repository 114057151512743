export const waitForCondition = (isReady, timeout = 5000) => {
  const startTime = Date.now();
  const ready = isReady();
  if (ready) {
    return Promise.resolve();
  }
  return new Promise((resolve, reject) => {
    const wait = callback => {
      const ready = isReady();
      if (Date.now() - startTime > timeout) {
        reject('Wait time expired');
        return;
      }
      if (!ready) {
        setTimeout(() => {
          wait(callback);
        }, 16);
      } else {
        callback();
      }
    };
    wait(resolve);
  });
};