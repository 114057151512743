import { ApiBase } from './ApiBase';
import { ActionColumnRenderer, ReactActionColumnRenderer } from '../../agGrid/ActionColumnRenderer';
import { AB_SPECIAL_COLUMN } from '../../Utilities/Constants/GeneralConstants';
export class ActionColumnApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
  }
  getActionColumns() {
    var _a;
    return (_a = this.getActionColumnOptions().actionColumns) !== null && _a !== void 0 ? _a : [];
  }
  getColDefsForActionColumns() {
    const defaultSpecialColumnSettings = this.getGridApi().internalApi.deriveSpecialColumnSettingsFromAgGridDefaultColDef();
    const defaultActionColumnSettings = Object.assign(Object.assign({}, defaultSpecialColumnSettings), {
      resizable: true,
      suppressMenu: false,
      suppressMovable: false
    });
    return this.getActionColumnApi().getActionColumns().map(actionColumn => {
      const actionColumnSettings = Object.assign(Object.assign({}, defaultActionColumnSettings), actionColumn.actionColumnSettings);
      const newColDef = {
        headerName: actionColumn.friendlyName ? actionColumn.friendlyName : actionColumn.columnId,
        colId: actionColumn.columnId,
        hide: false,
        editable: false,
        width: actionColumnSettings.width,
        resizable: actionColumnSettings.resizable,
        suppressHeaderMenuButton: actionColumnSettings.suppressMenu,
        suppressMovable: actionColumnSettings.suppressMovable,
        filter: false,
        sortable: false,
        enableRowGroup: false,
        cellRenderer: this.getAdaptableApi().internalApi.getAdaptableInstance().variant === 'react' ? ReactActionColumnRenderer : ActionColumnRenderer,
        type: [AB_SPECIAL_COLUMN, 'abColDefObject']
      };
      this.getAdaptableApi().logInfo('Setting up Action Column: ' + actionColumn.columnId);
      return newColDef;
    });
  }
}