import * as React from 'react';
import { Box, Flex } from 'rebass';
import { CheckBox } from '../../../../components/CheckBox';
import ErrorBox from '../../../../components/ErrorBox';
import FormLayout, { FormRow } from '../../../../components/FormLayout';
import { Tag } from '../../../../components/Tag';
import { ColorPicker } from '../../../../components/ColorPicker';
import { RangesComponent } from '../../../Components/RangesComponent';
import { StyleVisualItem } from '../../../Components/StyleVisualItem';
import { getHexForName, GRAY } from '../../../UIHelper';
import { useOnePageAdaptableWizardContext } from '../../../Wizard/OnePageAdaptableWizard';
import { PercentBarColumnComparisonPreview } from './Components/PercentBarColumnComparisonPreview';
import { PercentBarPreview } from './Components/PercentBarRangesPreview';
import DropdownButton from '../../../../components/DropdownButton';
import ArrayExtensions from '../../../../Utilities/Extensions/ArrayExtensions';
export const renderFormatColumnStyleWizardSummary = data => {
  const {
    api
  } = useOnePageAdaptableWizardContext();
  return renderStyledColumnStyleSummary(data, api);
};
const getRanges = (ranges, rangeValueType) => {
  let postfix = '';
  if (rangeValueType === 'Percentage') {
    postfix = '%';
  }
  return ranges.map((r, i) => React.createElement(Flex, {
    key: i,
    alignItems: "center",
    mr: 3
  }, React.createElement(Box, {
    mr: 1
  }, r.Min, postfix, " to ", r.Max, postfix, ' '), React.createElement(StyleVisualItem, {
    Style: {
      BackColor: r.Color,
      ForeColor: r.Color
    }
  })));
};
const getColumnComparison = (columnComparison, api) => {
  return React.createElement(Flex, {
    key: 'cc',
    alignItems: "center",
    mr: 3
  }, React.createElement(Box, {
    mr: 1
  }, "Min Value:", ' ', isNaN(Number(columnComparison.MinValue)) ? '[' + api.columnApi.getFriendlyNameForColumnId(String(columnComparison.MinValue)) + ']' : columnComparison.MinValue, ' ', ", Max Value:", ' ', isNaN(Number(columnComparison.MaxValue)) ? '[' + api.columnApi.getFriendlyNameForColumnId(String(columnComparison.MaxValue)) + ']' : columnComparison.MaxValue, ' '), React.createElement(StyleVisualItem, {
    Style: {
      BackColor: columnComparison.Color,
      ForeColor: columnComparison.Color
    }
  }));
};
export const renderStyledColumnStyleSummary = (data, api) => {
  if (!api) {
    const {
      api: adaptableApi
    } = useOnePageAdaptableWizardContext();
    api = adaptableApi;
  }
  if (data.GradientStyle) {
    if (data.GradientStyle.CellRanges) {
      return getRanges(data.GradientStyle.CellRanges, data.GradientStyle.RangeValueType);
    }
    if (data.GradientStyle.ColumnComparison) {
      return getColumnComparison(data.GradientStyle.ColumnComparison, api);
    }
  }
  if (data.PercentBarStyle) {
    if (data.PercentBarStyle.ColumnComparison) {
      return React.createElement(PercentBarColumnComparisonPreview, {
        percentBarStyle: data.PercentBarStyle
      });
    }
    if (data.PercentBarStyle.CellRanges) {
      return React.createElement(PercentBarPreview, {
        percentBarStyle: data.PercentBarStyle
      });
    }
  }
  return React.createElement(Tag, null, "No Styling Defined");
};
export const StyledColumnWizardStyleSection = props => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
  const {
    data,
    api
  } = useOnePageAdaptableWizardContext();
  const minMaxRangeValues = React.useMemo(() => {
    const columnId = data.ColumnId;
    if (!columnId) {
      return null;
    }
    const column = api.columnApi.getColumnWithColumnId(columnId);
    return {
      min: api.styledColumnApi.internalApi.getMinValueForNumericColumn(column),
      max: api.styledColumnApi.internalApi.getMaxValueForNumericColumn(column)
    };
  }, [data]);
  const scope = {
    ColumnIds: [data.ColumnId]
  }; // TODO: remove scope when format column columnStyle is removed
  // gradient
  const onUpdateGradientStyleRanges = ranges => {
    const gradientStyle = Object.assign(Object.assign({}, data.GradientStyle), {
      CellRanges: ranges
    });
    // delete gradientStyle.ColumnComparison in case we are switching from ColumnComparison to CellRanges
    delete gradientStyle.ColumnComparison;
    props.onChange(Object.assign(Object.assign({}, data), {
      GradientStyle: gradientStyle
    }));
  };
  const onUpdateGradientStyleColumnComparison = columnComparison => {
    const GradientStyle = {
      ColumnComparison: columnComparison
    };
    props.onChange(Object.assign(Object.assign({}, data), {
      GradientStyle
    }));
  };
  // percentbar
  const onUpdatePercentBarStyleColumnComparison = columnComparison => {
    const PercentBarStyle = Object.assign(Object.assign({}, data.PercentBarStyle), {
      ColumnComparison: columnComparison
    });
    props.onChange(Object.assign(Object.assign({}, data), {
      PercentBarStyle
    }));
  };
  const onUpdatePercentBarStyleRanges = ranges => {
    const PercentBarStyle = Object.assign(Object.assign({}, data.PercentBarStyle), {
      CellRanges: ranges
    });
    props.onChange(Object.assign(Object.assign({}, data), {
      PercentBarStyle
    }));
  };
  const onPercentBarCellTextCellValueChanged = checked => {
    let percentBarStyle = data === null || data === void 0 ? void 0 : data.PercentBarStyle;
    if (checked) {
      if (!percentBarStyle.CellText) {
        percentBarStyle.CellText = ['CellValue'];
      } else {
        percentBarStyle.CellText.push('CellValue');
      }
    } else {
      if (percentBarStyle.CellText && percentBarStyle.CellText.includes('PercentageValue')) {
        percentBarStyle.CellText = ['PercentageValue'];
      } else {
        percentBarStyle.CellText = [];
      }
    }
    props.onChange(Object.assign(Object.assign({}, data), {
      PercentBarStyle: percentBarStyle
    }));
  };
  const onPercentBarCellTextPercentValueChanged = checked => {
    let percentBarStyle = data === null || data === void 0 ? void 0 : data.PercentBarStyle;
    if (checked) {
      if (!percentBarStyle.CellText) {
        percentBarStyle.CellText = ['PercentageValue'];
      } else {
        percentBarStyle.CellText.push('PercentageValue');
      }
    } else {
      if (percentBarStyle.CellText && percentBarStyle.CellText.includes('CellValue')) {
        percentBarStyle.CellText = ['CellValue'];
      } else {
        percentBarStyle.CellText = [];
      }
    }
    props.onChange(Object.assign(Object.assign({}, data), {
      PercentBarStyle: percentBarStyle
    }));
  };
  const handlePercentBarCellTextPositionChange = percentBarPosition => {
    props.onChange(Object.assign(Object.assign({}, data), {
      PercentBarStyle: Object.assign(Object.assign({}, data.PercentBarStyle), {
        CellTextPosition: percentBarPosition
      })
    }));
  };
  const onPercentBarToolTipTextCellValueChanged = checked => {
    let percentBarStyle = data === null || data === void 0 ? void 0 : data.PercentBarStyle;
    if (checked) {
      if (!percentBarStyle.ToolTipText) {
        percentBarStyle.ToolTipText = ['CellValue'];
      } else {
        percentBarStyle.ToolTipText.push('CellValue');
      }
    } else {
      if (percentBarStyle.ToolTipText && percentBarStyle.ToolTipText.includes('PercentageValue')) {
        percentBarStyle.ToolTipText = ['PercentageValue'];
      } else {
        percentBarStyle.ToolTipText = [];
      }
    }
    props.onChange(Object.assign(Object.assign({}, data), {
      PercentBarStyle: percentBarStyle
    }));
  };
  const onPercentBarToolTipTextPercentValueChanged = checked => {
    let percentBarStyle = data.PercentBarStyle;
    if (checked) {
      if (!percentBarStyle.ToolTipText) {
        percentBarStyle.ToolTipText = ['PercentageValue'];
      } else {
        percentBarStyle.ToolTipText.push('PercentageValue');
      }
    } else {
      if (percentBarStyle.ToolTipText && percentBarStyle.ToolTipText.includes('CellValue')) {
        percentBarStyle.ToolTipText = ['CellValue'];
      } else {
        percentBarStyle.ToolTipText = [];
      }
    }
    props.onChange(Object.assign(Object.assign({}, data), {
      PercentBarStyle: percentBarStyle
    }));
  };
  const onUsePercentStyleColorCheckChanged = checked => {
    let percentBarStyle = data === null || data === void 0 ? void 0 : data.PercentBarStyle;
    percentBarStyle.BackColor = checked ? getHexForName(GRAY) : null;
    props.onChange(Object.assign(Object.assign({}, data), {
      PercentBarStyle: percentBarStyle
    }));
  };
  const onForeColorSelectChange = color => {
    let percentBarStyle = data.PercentBarStyle;
    percentBarStyle.BackColor = color;
    props.onChange(Object.assign(Object.assign({}, data), {
      PercentBarStyle: percentBarStyle
    }));
  };
  const handleRangeValueTypeChange = React.useCallback(rangeValueType => {
    const ranges = rangeValueType === 'Number' ? api.columnScopeApi.createCellColorRangesForScope(scope) : [{
      Min: 0,
      Max: 100,
      Color: getHexForName(GRAY)
    }];
    let newStyledColumn = data;
    const commonStyle = {
      CellRanges: ranges,
      RangeValueType: rangeValueType
    };
    if ('GradientStyle' in newStyledColumn) {
      newStyledColumn = Object.assign(Object.assign({}, newStyledColumn), {
        GradientStyle: Object.assign(Object.assign({}, newStyledColumn.GradientStyle), commonStyle)
      });
    } else {
      newStyledColumn = Object.assign(Object.assign({}, newStyledColumn), {
        PercentBarStyle: Object.assign(Object.assign({}, newStyledColumn.PercentBarStyle), commonStyle)
      });
    }
    props.onChange(newStyledColumn);
  }, [data]);
  const disabled = !data.ColumnId;
  return React.createElement(Box, null, (data === null || data === void 0 ? void 0 : data.GradientStyle) && React.createElement(RangesComponent, {
    disabled: !data.ColumnId,
    minMaxRangeValues: minMaxRangeValues,
    api: api,
    scope: scope,
    showRangeDirection: true,
    ranges: (_a = data.GradientStyle) === null || _a === void 0 ? void 0 : _a.CellRanges,
    rangeValueType: (_b = data.GradientStyle) === null || _b === void 0 ? void 0 : _b.RangeValueType,
    onRangeValueTypeChange: handleRangeValueTypeChange,
    columnComparison: (_c = data === null || data === void 0 ? void 0 : data.GradientStyle) === null || _c === void 0 ? void 0 : _c.ColumnComparison,
    updateRanges: ranges => onUpdateGradientStyleRanges(ranges),
    updateColumnComparison: columnComparison => onUpdateGradientStyleColumnComparison(columnComparison)
  }), data.PercentBarStyle && React.createElement(React.Fragment, null, React.createElement(RangesComponent, {
    disabled: disabled,
    minMaxRangeValues: minMaxRangeValues,
    api: api,
    scope: scope,
    showRangeDirection: false,
    ranges: (_d = data.PercentBarStyle) === null || _d === void 0 ? void 0 : _d.CellRanges,
    rangeValueType: (_e = data.PercentBarStyle) === null || _e === void 0 ? void 0 : _e.RangeValueType,
    onRangeValueTypeChange: handleRangeValueTypeChange,
    columnComparison: (_f = data === null || data === void 0 ? void 0 : data.PercentBarStyle) === null || _f === void 0 ? void 0 : _f.ColumnComparison,
    updateRanges: ranges => onUpdatePercentBarStyleRanges(ranges),
    updateColumnComparison: columnComparison => onUpdatePercentBarStyleColumnComparison(columnComparison)
  }), React.createElement(FormLayout, {
    marginLeft: 2,
    marginTop: 2
  }, React.createElement(FormRow, {
    label: "Cell Display:"
  }, React.createElement(CheckBox, {
    disabled: disabled,
    marginLeft: 2,
    checked: (_h = (_g = data.PercentBarStyle) === null || _g === void 0 ? void 0 : _g.CellText) === null || _h === void 0 ? void 0 : _h.includes('CellValue'),
    onChange: checked => onPercentBarCellTextCellValueChanged(checked)
  }, "Cell Value"), ' ', React.createElement(CheckBox, {
    disabled: disabled,
    marginLeft: 3,
    checked: (_k = (_j = data === null || data === void 0 ? void 0 : data.PercentBarStyle) === null || _j === void 0 ? void 0 : _j.CellText) === null || _k === void 0 ? void 0 : _k.includes('PercentageValue'),
    onChange: checked => onPercentBarCellTextPercentValueChanged(checked)
  }, "Percent Value", ' '), ' '), React.createElement(FormRow, {
    label: "Cell Display Position:"
  }, React.createElement(DropdownButton, {
    disabled: ArrayExtensions.IsNullOrEmpty(data.PercentBarStyle.CellText) || disabled,
    columns: ['label'],
    items: [{
      label: 'Above Bar',
      onClick: () => handlePercentBarCellTextPositionChange('Above')
    }, {
      label: 'Below Bar',
      onClick: () => handlePercentBarCellTextPositionChange('Below')
    }, {
      label: 'Merged',
      onClick: () => handlePercentBarCellTextPositionChange('Merged')
    }]
  }, (_m = (_l = data.PercentBarStyle) === null || _l === void 0 ? void 0 : _l.CellTextPosition) !== null && _m !== void 0 ? _m : 'Below')), React.createElement(FormRow, {
    label: "ToolTip Display:"
  }, React.createElement(CheckBox, {
    disabled: disabled,
    marginLeft: 2,
    checked: (_p = (_o = data === null || data === void 0 ? void 0 : data.PercentBarStyle) === null || _o === void 0 ? void 0 : _o.ToolTipText) === null || _p === void 0 ? void 0 : _p.includes('CellValue'),
    onChange: checked => onPercentBarToolTipTextCellValueChanged(checked)
  }, "Cell Value"), ' ', React.createElement(CheckBox, {
    disabled: disabled,
    marginLeft: 3,
    checked: (_r = (_q = data === null || data === void 0 ? void 0 : data.PercentBarStyle) === null || _q === void 0 ? void 0 : _q.ToolTipText) === null || _r === void 0 ? void 0 : _r.includes('PercentageValue'),
    onChange: checked => onPercentBarToolTipTextPercentValueChanged(checked)
  }, "Percent Value", ' '), ' '), React.createElement(FormRow, {
    label: `Back ${api.internalApi.getCorrectEnglishVariant('Colour')}`
  }, React.createElement(Flex, {
    alignItems: "center",
    marginLeft: 2
  }, React.createElement(CheckBox, {
    disabled: disabled,
    checked: !!((_s = data.PercentBarStyle) === null || _s === void 0 ? void 0 : _s.BackColor),
    onChange: checked => onUsePercentStyleColorCheckChanged(checked),
    mr: 2
  }), ((_t = data.PercentBarStyle) === null || _t === void 0 ? void 0 : _t.BackColor) !== undefined && React.createElement(ColorPicker, {
    disabled: disabled,
    api: api,
    value: (_u = data.PercentBarStyle) === null || _u === void 0 ? void 0 : _u.BackColor,
    onChange: x => onForeColorSelectChange(x)
  }))))), !data.ColumnId && React.createElement(ErrorBox, {
    mt: 2
  }, "You need to select a column before styling."));
};