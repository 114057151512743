import * as React from 'react';
import { Box } from 'rebass';
import FormLayout, { FormRow } from '../../../../components/FormLayout';
import { columnFilter } from './Utilities';
import { Tabs } from '../../../../components/Tabs';
import { Tag } from '../../../../components/Tag';
import { useAdaptable } from '../../../AdaptableContext';
import { ValueSelector } from '../../../Components/ValueSelector';
import { useOnePageAdaptableWizardContext } from '../../../Wizard/OnePageAdaptableWizard';
import ArrayExtensions from '../../../../Utilities/Extensions/ArrayExtensions';
export const RowGroupingSectionSummary = () => {
  var _a;
  const adaptable = useAdaptable();
  const {
    data: layout
  } = useOnePageAdaptableWizardContext();
  return React.createElement(Box, null, ((_a = layout.RowSummaries) === null || _a === void 0 ? void 0 : _a.length) ? layout === null || layout === void 0 ? void 0 : layout.RowSummaries.map((rowSummary, index) => {
    var _a;
    const columns = Object.entries((_a = rowSummary.ColumnsMap) !== null && _a !== void 0 ? _a : {}).map(([columnId, expression]) => {
      if (columnId === 'Source' || columnId === 'Uuid') {
        return '';
      }
      return `${expression}(${adaptable.api.columnApi.getFriendlyNameForColumnId(columnId)})`;
    }).filter(Boolean).join(', ');
    return React.createElement(Box, {
      mb: 2
    }, React.createElement(Tag, {
      key: index
    }, rowSummary.Position, " ", columns));
  }) : React.createElement(Tag, null, "No Row Summaries"));
};
export const RowGroupingSection = props => {
  var _a, _b, _c;
  const adaptable = useAdaptable();
  const {
    data: layout
  } = useOnePageAdaptableWizardContext();
  const allGroupableColumns = adaptable.api.columnApi.getGroupableColumns();
  const sortedGroupableColumns = React.useMemo(() => {
    var _a;
    return ArrayExtensions.sortArrayWithOrder(allGroupableColumns.map(col => col.columnId), (_a = layout.RowGroupedColumns) !== null && _a !== void 0 ? _a : [], {
      sortUnorderedItems: false
    }).map(colId => adaptable.api.columnApi.getColumnWithColumnId(colId));
  }, [layout, allGroupableColumns]);
  const handleColumnsChange = columnIds => {
    props.onChange(Object.assign(Object.assign({}, layout), {
      RowGroupedColumns: columnIds
    }));
  };
  return React.createElement(Tabs, {
    style: {
      height: '100%'
    }
  }, React.createElement(Tabs.Tab, null, "Group Rows"), ((_a = layout.ExpandedRowGroupValues) === null || _a === void 0 ? void 0 : _a.length) && React.createElement(Tabs.Tab, null, "Expanded Rows"), React.createElement(Tabs.Content, null, React.createElement(ValueSelector, {
    showFilterInput: true,
    showSelectedOnlyPosition: "top",
    filter: columnFilter,
    toIdentifier: option => `${option.columnId}`,
    toLabel: option => {
      var _a;
      return (_a = option.friendlyName) !== null && _a !== void 0 ? _a : option.columnId;
    },
    options: sortedGroupableColumns,
    value: (_b = layout.RowGroupedColumns) !== null && _b !== void 0 ? _b : [],
    allowReorder: true,
    xSelectedLabel: () => {
      return `Selected Columns:`;
    },
    onChange: handleColumnsChange
  })), ((_c = layout.ExpandedRowGroupValues) === null || _c === void 0 ? void 0 : _c.length) && React.createElement(Tabs.Content, null, React.createElement(Box, {
    backgroundColor: "var(--ab-color-defaultbackground)"
  }, React.createElement(FormLayout, {
    p: 2
  }, React.createElement(FormRow, {
    label: "Values"
  }, layout.ExpandedRowGroupValues.map((value, index) => React.createElement(Tag, {
    mb: 2,
    mr: 2,
    key: index
  }, value)))))));
};