const initialGradientColumnState = {};
export const GRADIENT_COLUMN_CLEAR = 'GRADIENT_COLUMN_CLEAR';
export const GradientColumnClear = () => ({
  type: GRADIENT_COLUMN_CLEAR
});
export const GradientColumnReducer = (state = initialGradientColumnState, action) => {
  switch (action.type) {
    case GRADIENT_COLUMN_CLEAR:
      {
        return Object.assign({}, initialGradientColumnState);
      }
    default:
      return state;
  }
};
const initialPercentBarState = {};
export const PERCENT_BAR_CLEAR = 'PERCENT_BAR_CLEAR';
export const PercentBarClear = () => ({
  type: PERCENT_BAR_CLEAR
});
export const PercentBarReducer = (state = initialPercentBarState, action) => {
  switch (action.type) {
    case PERCENT_BAR_CLEAR:
      {
        return Object.assign({}, initialPercentBarState);
      }
    default:
      return state;
  }
};
const initialFlashingCellState = {};
export const FLASHING_CELL_CLEAR = 'FLASHING_CELL_CLEAR';
export const FlashingCellClear = () => ({
  type: FLASHING_CELL_CLEAR
});
export const FlashingCellReducer = (state = initialFlashingCellState, action) => {
  switch (action.type) {
    case FLASHING_CELL_CLEAR:
      {
        return Object.assign({}, initialFlashingCellState);
      }
    default:
      return state;
  }
};
const initialUpdatedRowState = {};
export const UPDATED_ROW_CLEAR = 'UPDATED_ROW_CLEAR';
export const UpdatedRowClear = () => ({
  type: UPDATED_ROW_CLEAR
});
export const UpdatedRowReducer = (state = initialUpdatedRowState, action) => {
  switch (action.type) {
    case UPDATED_ROW_CLEAR:
      {
        return Object.assign({}, initialUpdatedRowState);
      }
    default:
      return state;
  }
};
const initialConditionalStyleState = {};
export const CONDITIONAL_STYLE_DELETE = 'CONDITIONAL_STYLE_DELETE';
export const ConditionalStyleDelete = conditionalStyle => ({
  type: CONDITIONAL_STYLE_DELETE,
  conditionalStyle
});
export const ConditionalStyleReducer = (state = initialConditionalStyleState, action) => {
  switch (action.type) {
    case CONDITIONAL_STYLE_DELETE:
      {
        const actionConditionalStyle = action.conditionalStyle;
        return Object.assign(Object.assign({}, state), {
          ConditionalStyles: state.ConditionalStyles.filter(abObject => abObject.Uuid !== actionConditionalStyle.Uuid)
        });
      }
    default:
      return state;
  }
};