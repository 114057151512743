import * as React from 'react';
import { useSelector } from 'react-redux';
import { ModuleManager } from '../../components/DragAndDropContext/ModuleManager';
import EmptyContent from '../../components/EmptyContent';
import { ALL_STATUS_SUB_PANELS } from '../../PredefinedConfig/Common/Types';
import { getStatusPanelsSelector } from '../../Redux/ActionsReducers/StatusBarRedux';
import { useAdaptable } from '../AdaptableContext';
import { PopupPanel } from '../Components/Popups/AdaptablePopup/PopupPanel';
export const StatusBarPopup = props => {
  const statusPanels = useSelector(getStatusPanelsSelector);
  const adaptable = useAdaptable();
  const tabs = React.useMemo(() => statusPanels.map(statusPanel => {
    return {
      Name: statusPanel.Key,
      Items: statusPanel.StatusBarPanels
    };
  }), [statusPanels]);
  const handleTabChange = React.useCallback(tabs => {
    const newStatusPanels = tabs.map(tab => {
      const statusPanel = statusPanels.find(sp => sp.Key === tab.Name);
      return Object.assign(Object.assign({}, statusPanel), {
        StatusBarPanels: tab.Items
      });
    });
    adaptable.api.statusBarApi.setStatusBarPanels(newStatusPanels);
  }, [statusPanels]);
  const availableItems = ALL_STATUS_SUB_PANELS.filter(panel => {
    const module = adaptable.ModuleService.getModuleById(panel);
    return module && module.isModuleAvailable();
  }).map(panel => {
    const module = adaptable.ModuleService.getModuleById(panel);
    return {
      Id: panel,
      Title: module.moduleInfo.FriendlyName
    };
  });
  const disabled = props.accessLevel === 'ReadOnly';
  return React.createElement(PopupPanel, {
    headerText: props.moduleInfo.FriendlyName,
    glyphicon: props.moduleInfo.Glyph,
    infoLink: props.moduleInfo.HelpPage,
    infoLinkDisabled: !props.api.internalApi.isDocumentationLinksDisplayed()
  }, statusPanels.length ? React.createElement(ModuleManager, {
    permittedActions: {
      createTab: false,
      dragAndDropTab: false,
      deleteTab: false,
      editTabName: false
    },
    onTabsChange: handleTabChange,
    disabled: disabled,
    tabs: tabs,
    availableItems: availableItems,
    tabsTitle: 'AdapTable Status Bar Panels',
    unusedPanelTitle: "Available Status Bar Components",
    dragItemText: "Drag into a Status Bar Panel below"
  }) : React.createElement(EmptyContent, null, "To enable this feature add to Grid Options statusPanels the Adaptable Status Panel."));
};