import * as React from 'react';
import { useState } from 'react';
import { Box } from 'rebass';
import { OnePageAdaptableWizard, OnePageWizardSummary } from '../../Wizard/OnePageAdaptableWizard';
import { isValidNamedQuerySettings, NamedQuerySettingsWizardSection, renderNamedQuerySettingsSummary } from './NamedQuerySettingsWizardSection';
import { cloneObject } from '../../../Utilities/Helpers/Helper';
import { isValidNamedQueryExpression, NamedQueryExpressionWizardSection, renderNamedQueryExpressionSummary } from './NamedQueryExpressionWizardSection';
import ObjectFactory from '../../../Utilities/ObjectFactory';
import { useDispatch } from 'react-redux';
import * as NamedQueryRedux from '../../../Redux/ActionsReducers/NamedQueryRedux';
import { ObjectTagsWizardSection, renderObjectTagsSummary } from '../../Wizard/ObjectTagsWizardSection';
export function NamedQueryWizard(props) {
  const [namedQuery, setNamedQuery] = useState(() => {
    var _a, _b;
    const value = (_b = (_a = props.popupParams) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '';
    return props.data ? cloneObject(props.data) : ObjectFactory.CreateEmptyNamedQuery(value);
  });
  const dispatch = useDispatch();
  const handleFinish = () => {
    if (props.data) {
      dispatch(NamedQueryRedux.NamedQueryEdit(namedQuery));
    } else {
      dispatch(NamedQueryRedux.NamedQueryAdd(namedQuery));
    }
    props.onFinishWizard(namedQuery);
  };
  return React.createElement(OnePageAdaptableWizard, {
    defaultCurrentSectionName: props.defaultCurrentSectionName,
    onFinish: handleFinish,
    sections: [{
      title: 'Expression',
      details: "Build the Named Query's Expression",
      renderSummary: renderNamedQueryExpressionSummary,
      isValid: isValidNamedQueryExpression,
      render: () => React.createElement(NamedQueryExpressionWizardSection, {
        onChange: setNamedQuery
      })
    }, {
      title: 'Query Name',
      details: 'Specify a unique Name for the Named Query',
      renderSummary: renderNamedQuerySettingsSummary,
      isValid: isValidNamedQuerySettings,
      render: () => {
        return React.createElement(NamedQuerySettingsWizardSection, {
          onChange: setNamedQuery
        });
      }
    }, {
      details: 'Select Named Query Tags',
      title: 'Tags',
      isVisible: (_, api) => api.internalApi.shouldDisplayTagSections(),
      render: () => React.createElement(Box, {
        padding: 2
      }, React.createElement(ObjectTagsWizardSection, {
        onChange: setNamedQuery
      })),
      renderSummary: renderObjectTagsSummary
    }, '-', {
      title: 'Summary',
      details: 'Review your Named Query',
      render: () => {
        return React.createElement(Box, {
          padding: 2
        }, React.createElement(OnePageWizardSummary, null));
      }
    }],
    onHide: props.onCloseWizard,
    moduleInfo: props.moduleInfo,
    data: namedQuery
  });
}