import * as React from 'react';
import { isValid, parse } from 'date-fns';
import { useState } from 'react';
import Input from '../../../components/Input';
import { useAdaptable } from '../../AdaptableContext';
export const AdaptableDateInlineInput = React.forwardRef((props, ref) => {
  var _a;
  const [hasFocus, setHasFocus] = useState(false);
  const dateInputOptions = useAdaptable().adaptableOptions.userInterfaceOptions.dateInputOptions;
  const dateProps = {
    format: dateInputOptions.dateFormat,
    locale: dateInputOptions.locale
  };
  const [inputValue, setInputValue] = useState(() => {
    return props.value;
  });
  React.useEffect(() => {
    if (hasFocus) {
      return;
    }
    setInputValue(props.value);
  }, [props.value]);
  const handleInputChange = e => {
    setInputValue(e.currentTarget.value);
    const date = parse(e.currentTarget.value, dateProps.format, new Date());
    if (isValid(date)) {
      props.onChange(e.currentTarget.value);
    } else {
      props.onChange(undefined);
    }
  };
  return React.createElement(Input, Object.assign({}, props, {
    onFocus: event => {
      var _a;
      (_a = props.onFocus) === null || _a === void 0 ? void 0 : _a.call(props, event);
      setHasFocus(true);
    },
    onBlur: event => {
      var _a;
      (_a = props.onBlur) === null || _a === void 0 ? void 0 : _a.call(props, event);
      setHasFocus(false);
    },
    ref: ref,
    value: inputValue,
    onChange: e => handleInputChange(e),
    placeholder: (_a = props.placeholder) !== null && _a !== void 0 ? _a : dateProps.format,
    style: props.style,
    disabled: props.disabled
  }));
});