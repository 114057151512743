import * as React from 'react';
import { Select } from '../../../components/Select';
import { runIfNotResolvedIn } from '../../../Utilities/runIfNotResolvedIn';
import { useAdaptable } from '../../AdaptableContext';
export const PermittedValuesSelector = function (props) {
  const adaptable = useAdaptable();
  const [isLoading, setIsLoading] = React.useState(true);
  const [options, setOptions] = React.useState([]);
  const [filter, setFilter] = React.useState('');
  const dataType = React.useMemo(() => {
    return adaptable.api.columnApi.getColumnDataTypeForColumnId(props.columnId);
  }, [props.columnId]);
  React.useEffect(() => {
    if (!props.columnId) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    (async () => {
      const {
        values: distinctColumnValues
      } = await runIfNotResolvedIn(adaptable.api.gridApi.internalApi.getDistinctFilterDisplayValuesForColumn(props.columnId, filter, adaptable.api.optionsApi.getColumnFilterOptions().valuesFilterOptions.showCurrentlyFilteredValuesOnly), () => {
        setIsLoading(true);
      });
      setIsLoading(false);
      setOptions(distinctColumnValues);
    })();
  }, [props.columnId, filter]);
  const isCreatable = props.allowNewValues && !dataType || ['String', 'Number'].includes(dataType);
  const handleSingleValueChange = value => {
    if (isCreatable) {
      // try to convert to correct type, when there is not type, e.g. field
      if (!dataType && typeof value === 'string' && !isNaN(parseFloat(value))) {
        props.onChange(parseFloat(value));
      } else if (dataType === 'Number') {
        let num = parseFloat(value);
        num = isNaN(num) ? null : num;
        props.onChange(num);
      } else {
        props.onChange(value);
      }
    } else {
      props.onChange(value);
    }
  };
  const handleMultiValueChange = values => {
    if (isCreatable) {
      if (dataType === 'Number') {
        const nums = values.map(v => parseFloat(v)).filter(v => !isNaN(v));
        props.onChange(nums);
      } else {
        props.onChange(values);
      }
    } else {
      props.onChange(values);
    }
  };
  const handleOnChange = value => {
    // convert numbers
    if (props.isMulti) {
      handleMultiValueChange(value);
    } else {
      handleSingleValueChange(value);
    }
  };
  return React.createElement(Select, {
    isMulti: props.isMulti,
    className: props.className,
    disabled: props.disabled,
    "data-name": "permitted-values-selector",
    placeholder: props.placeholder || (props.allowNewValues ? 'Select or type new value' : 'Select value'),
    isCreatable: isCreatable,
    isClearable: true,
    options: options,
    isLoading: isLoading,
    onInputChange: setFilter,
    onChange: handleOnChange,
    value: props.value
  });
};