import matches from './matches';
export default ((selector, node) => {
  if (!node) {
    return null;
  }
  while (node = node.parentElement) {
    if (matches.call(node, selector)) {
      return node;
    }
  }
  return null;
});