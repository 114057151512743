import * as React from 'react';
import { useMemo } from 'react';
import { Box, Text } from 'rebass';
import { ExpressionEditor } from '../../../components/ExpressionEditor';
import { ExpressionPreview } from '../../../components/ExpressionEditor/ExpressionPreview';
import Radio from '../../../components/Radio';
import { Tabs } from '../../../components/Tabs';
import { Tag } from '../../../components/Tag';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
export const isValidReportRowsQuery = report => {
  var _a;
  if (report.ReportRowScope === 'ExpressionRows' && !((_a = report.Query) === null || _a === void 0 ? void 0 : _a.BooleanExpression)) {
    return 'Rows query cannot be empty';
  }
  return true;
};
export const renderReportRowsSummary = report => {
  const {
    api
  } = useOnePageAdaptableWizardContext();
  return React.createElement(Box, {
    fontSize: 2
  }, report.ReportRowScope === 'AllRows' ? React.createElement(React.Fragment, null, "Include ", React.createElement("b", null, "all Rows"), " in the Report") : null, report.ReportRowScope === 'VisibleRows' ? React.createElement(React.Fragment, null, "Include ", React.createElement("b", null, "only visible Rows"), " in the Report") : null, report.ReportRowScope === 'ExpressionRows' ? React.createElement(React.Fragment, null, "Include Rows matching the query:", ' ', React.createElement(Tag, null, React.createElement(ExpressionPreview, {
    query: report.Query
  }))) : null);
};
export const ReportRowsWizardSection = props => {
  var _a, _b;
  const {
    api,
    data,
    moduleInfo
  } = useOnePageAdaptableWizardContext();
  const initialData = useMemo(() => api.internalApi.getQueryPreviewData(), []);
  return React.createElement(React.Fragment, null, React.createElement(Tabs, {
    m: 2,
    value: data.ReportRowScope,
    onValueChange: ReportRowScope => {
      const report = Object.assign(Object.assign({}, data), {
        ReportRowScope
      });
      if (report.ReportRowScope !== 'ExpressionRows') {
        delete report.Query;
      } else {
        if (!report.Query) {
          report.Query = {
            BooleanExpression: ''
          };
        }
      }
      props.onChange(report);
    }
  }, React.createElement(Tabs.Tab, {
    value: "AllRows"
  }, React.createElement(Radio, {
    margin: 0,
    checked: data.ReportRowScope == 'AllRows',
    tabIndex: -1
  }, "All Rows")), React.createElement(Tabs.Tab, {
    value: "VisibleRows"
  }, React.createElement(Radio, {
    margin: 0,
    checked: data.ReportRowScope == 'VisibleRows',
    tabIndex: -1
  }, "Visible Rows")), React.createElement(Tabs.Tab, {
    value: "ExpressionRows"
  }, React.createElement(Radio, {
    margin: 0,
    checked: data.ReportRowScope == 'ExpressionRows',
    tabIndex: -1
  }, "By Query")), React.createElement(Tabs.Content, {
    value: "AllRows"
  }, React.createElement(Text, {
    fontSize: 2,
    padding: 2
  }, "All Rows in the datasource will be included in the report, whether visible or not at time of export.")), React.createElement(Tabs.Content, {
    value: "VisibleRows"
  }, React.createElement(Text, {
    fontSize: 2,
    padding: 2
  }, "Only Rows that are visible at the time the Report is exported will be included in the Export")), React.createElement(Tabs.Content, {
    value: "ExpressionRows"
  }, React.createElement(Text, {
    fontSize: 2,
    padding: 2,
    pl: 0
  }, "Only the Rows which match the Query will be exported - whether visible or not."), React.createElement(ExpressionEditor, {
    allowSaveNamedQuery: true,
    type: 'boolean',
    module: moduleInfo.ModuleName,
    style: {
      paddingLeft: 0
    },
    value: (_b = (_a = data.Query) === null || _a === void 0 ? void 0 : _a.BooleanExpression) !== null && _b !== void 0 ? _b : '',
    onChange: BooleanExpression => {
      props.onChange(Object.assign(Object.assign({}, data), {
        Query: {
          BooleanExpression
        }
      }));
    },
    initialData: initialData,
    columns: api.columnApi.getQueryableColumns(),
    fields: api.expressionApi.internalApi.getAvailableFields(),
    namedQueries: api.namedQueryApi.getNamedQueries(),
    api: api
  }))));
};