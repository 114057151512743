import { isObservable } from 'rxjs';
import * as parser from '../../parser/src';
import { aggregatedBooleanExpressionFunctions } from '../ExpressionFunctions/aggregatedBooleanExpressionFunctions';
import { addGroupByParams, aggregatedScalarExpressionFunctions } from '../ExpressionFunctions/aggregatedScalarExpressionFunctions';
import { booleanExpressionFunctions } from '../ExpressionFunctions/booleanExpressionFunctions';
import { extractColumnParameters, extractParameter } from '../ExpressionFunctions/expressionFunctionUtils';
import { observableExpressionFunctions } from '../ExpressionFunctions/observableExpressionFunctions';
import { scalarExpressionFunctions } from '../ExpressionFunctions/scalarExpressionFunctions';
import { getTypedKeys } from '../Extensions/TypeExtensions';
import { createBaseContext } from '../ObjectFactory';
import { AggregatedScalarLiveValue } from './AggregatedScalarLiveValue';
export class QueryLanguageService {
  constructor(adaptableApi) {
    this.adaptableApi = adaptableApi;
    this.cacheBooleanValidation = new Map();
    this.cacheObservableValidation = new Map();
    this.cacheAggregatedBooleanValidation = new Map();
    this.cacheAggregatedScalarValidation = new Map();
    this.cacheModuleSpecificExpressionFunctions = new Map();
  }
  evaluateBooleanExpression(expression, module, rowNode) {
    const moduleExpressionFunctions = this.getModuleExpressionFunctionsMap(module);
    const booleanAndScalarFunctions = this.getBooleanAndScalarFunctions(moduleExpressionFunctions);
    return parser.evaluate(expression, {
      node: rowNode,
      adaptableApi: this.adaptableApi,
      userName: this.adaptableApi.optionsApi.getUserName(),
      adaptableId: this.adaptableApi.optionsApi.getAdaptableId(),
      functions: booleanAndScalarFunctions,
      evaluateCustomQueryVariable: this.evaluateCustomQueryVariable
    });
  }
  evaluateScalarExpression(expression, module, rowNode) {
    // currently scalar and boolean expressions are evaluated the same
    return this.evaluateBooleanExpression(expression, module, rowNode);
  }
  evaluateAggregatedScalarExpression(expression, module, getRowNodes) {
    const aggregatedScalarFunctions = this.getModuleExpressionFunctionsMap(module).aggregatedScalarFunctions;
    return parser.evaluate(expression, {
      node: null,
      adaptableApi: this.adaptableApi,
      userName: this.adaptableApi.optionsApi.getUserName(),
      adaptableId: this.adaptableApi.optionsApi.getAdaptableId(),
      functions: aggregatedScalarFunctions,
      evaluateCustomQueryVariable: this.evaluateCustomQueryVariable,
      getRowNodes
    });
  }
  evaluateObservableExpression(reactiveExpression, module) {
    const moduleExpressionFunctions = this.getModuleExpressionFunctionsMap(module);
    const booleanAndScalarFunctions = this.getBooleanAndScalarFunctions(moduleExpressionFunctions);
    const reactiveExpression$ = parser.evaluate(reactiveExpression, {
      node: this.adaptableApi.gridApi.getFirstRowNode(),
      adaptableApi: this.adaptableApi,
      userName: this.adaptableApi.optionsApi.getUserName(),
      adaptableId: this.adaptableApi.optionsApi.getAdaptableId(),
      functions: moduleExpressionFunctions.observableFunctions,
      whereClauseFunctions: booleanAndScalarFunctions,
      evaluateCustomQueryVariable: this.evaluateCustomQueryVariable
    });
    return reactiveExpression$;
  }
  evaluateAggregatedBooleanExpression(aggregationExpression, module) {
    const moduleExpressionFunctionsMap = this.getModuleExpressionFunctionsMap(module);
    const booleanAndScalarFunctions = this.getBooleanAndScalarFunctions(moduleExpressionFunctionsMap);
    const aggregationEvaluation = parser.evaluate(aggregationExpression, {
      node: this.adaptableApi.gridApi.getFirstRowNode(),
      adaptableApi: this.adaptableApi,
      userName: this.adaptableApi.optionsApi.getUserName(),
      adaptableId: this.adaptableApi.optionsApi.getAdaptableId(),
      functions: moduleExpressionFunctionsMap.aggregatedBooleanFunctions,
      whereClauseFunctions: booleanAndScalarFunctions,
      evaluateCustomQueryVariable: this.evaluateCustomQueryVariable
    });
    return aggregationEvaluation;
  }
  validateBoolean(expressionInput = '', module, config = {
    force: false
  }) {
    var _a;
    const cacheKey = this.getExpressionCacheKey(expressionInput, module);
    try {
      if (this.cacheBooleanValidation.has(cacheKey)) {
        return this.cacheBooleanValidation.get(cacheKey);
      }
      const expression = expressionInput.trim();
      if (expression === '') {
        const result = {
          isValid: false,
          errorMessage: 'empty boolean expression'
        };
        this.cacheBooleanValidation.set(cacheKey, result);
        return result;
      }
      const force = (_a = config === null || config === void 0 ? void 0 : config.force) !== null && _a !== void 0 ? _a : false;
      // see if validation should be performed
      if (!this.adaptableApi.optionsApi.getExpressionOptions().performExpressionValidation && !force) {
        const result = {
          isValid: true,
          errorMessage: ''
        };
        this.cacheBooleanValidation.set(cacheKey, result);
        return result;
      }
      const {
        ast
      } = parser.parse(expression.trim());
      const rootFn = ast[ast.length - 1];
      const moduleExpressionFunctions = this.getModuleExpressionFunctionsMap(module);
      const booleanAndScalarFunctions = this.getBooleanAndScalarFunctions(moduleExpressionFunctions);
      if (rootFn.type === undefined || booleanAndScalarFunctions[rootFn.type] === undefined) {
        const result = {
          isValid: false,
          errorMessage: `provided function ${rootFn === null || rootFn === void 0 ? void 0 : rootFn.type} cannot be handled`
        };
        this.cacheBooleanValidation.set(cacheKey, result);
        return result;
      }
      if (booleanAndScalarFunctions[rootFn.type].returnType !== 'boolean') {
        const result = {
          isValid: false,
          errorMessage: `provided function ${rootFn === null || rootFn === void 0 ? void 0 : rootFn.type} is not a predicate`
        };
        this.cacheBooleanValidation.set(cacheKey, result);
        return result;
      }
      // evaluating the expression is the only way to catch circular named query references
      let firstRowNode = this.adaptableApi.gridApi.getFirstRowNode();
      this.evaluateBooleanExpression(expression, module, firstRowNode);
      const result = {
        isValid: true,
        errorMessage: ''
      };
      this.cacheBooleanValidation.set(cacheKey, result);
      return result;
    } catch (error) {
      const result = {
        isValid: false,
        errorMessage: error
      };
      this.cacheBooleanValidation.set(cacheKey, result);
      return result;
    }
  }
  validateObservable(expressionInput = '', module) {
    const cacheKey = this.getExpressionCacheKey(expressionInput, module);
    if (this.cacheObservableValidation.has(cacheKey)) {
      return this.cacheObservableValidation.get(cacheKey);
    }
    const expression = expressionInput.trim();
    if (expression === '') {
      const result = {
        isValid: false,
        errorMessage: 'empty observable expression'
      };
      this.cacheObservableValidation.set(cacheKey, result);
      return result;
    }
    try {
      const observableExpression = this.evaluateObservableExpression(expression, module);
      if (!isObservable(observableExpression)) {
        const result = {
          isValid: false,
          errorMessage: `provided observable expression does not evaluate to an Observable`
        };
        this.cacheObservableValidation.set(cacheKey, result);
        return result;
      }
      const result = {
        isValid: true,
        errorMessage: ''
      };
      this.cacheObservableValidation.set(cacheKey, result);
      return result;
    } catch (error) {
      const result = {
        isValid: false,
        errorMessage: error
      };
      this.cacheObservableValidation.set(cacheKey, result);
      return result;
    }
  }
  validateAggregatedBoolean(expressionInput = '', module) {
    const cacheKey = this.getExpressionCacheKey(expressionInput, module);
    if (this.cacheAggregatedBooleanValidation.has(cacheKey)) {
      return this.cacheAggregatedBooleanValidation.get(cacheKey);
    }
    const expression = expressionInput.trim();
    if (expression === '') {
      const result = {
        isValid: false,
        errorMessage: 'empty AggregatedBoolean expression'
      };
      this.cacheAggregatedBooleanValidation.set(cacheKey, result);
      return result;
    }
    try {
      const evaluationResult = this.evaluateAggregatedBooleanExpression(expression, module);
      if (evaluationResult.type !== 'aggregationBoolean') {
        const result = {
          isValid: false,
          errorMessage: 'provided AggregatedBoolean expression does not evaluate to a supported aggregation'
        };
        this.cacheAggregatedBooleanValidation.set(cacheKey, result);
        return result;
      }
      // no exception,so everything seems to be fine
      const result = {
        isValid: true,
        errorMessage: ''
      };
      this.cacheAggregatedBooleanValidation.set(cacheKey, result);
      return result;
    } catch (error) {
      const result = {
        isValid: false,
        errorMessage: error
      };
      this.cacheAggregatedBooleanValidation.set(cacheKey, result);
      return result;
    }
  }
  validateAggregatedScalar(expressionInput = '', module) {
    const cacheKey = this.getExpressionCacheKey(expressionInput, module);
    if (this.cacheAggregatedScalarValidation.has(cacheKey)) {
      return this.cacheAggregatedScalarValidation.get(cacheKey);
    }
    const expression = expressionInput.trim();
    if (expression === '') {
      const result = {
        isValid: false,
        errorMessage: 'empty AggregatedScalar expression'
      };
      this.cacheAggregatedScalarValidation.set(cacheKey, result);
      return result;
    }
    try {
      const evaluationResult = this.evaluateAggregatedScalarExpression(expression, module);
      if (evaluationResult.type !== 'aggregationScalar') {
        const result = {
          isValid: false,
          errorMessage: 'provided AggregatedScalar expression does not evaluate to a supported aggregation'
        };
        this.cacheAggregatedScalarValidation.set(cacheKey, result);
        return result;
      }
      // no exception,so everything seems to be fine
      const result = {
        isValid: true,
        errorMessage: ''
      };
      this.cacheAggregatedScalarValidation.set(cacheKey, result);
      return result;
    } catch (error) {
      const result = {
        isValid: false,
        errorMessage: error
      };
      this.cacheAggregatedScalarValidation.set(cacheKey, result);
      return result;
    }
  }
  computeAggregatedBooleanValue(expression, module) {
    const booleanAggregationParameter = this.evaluateAggregatedBooleanExpression(expression, module);
    const aggregatedScalarExpressionEvaluation = booleanAggregationParameter.scalarAggregation.value;
    const aggregatedScalarLiveValue = new AggregatedScalarLiveValue({
      aggregatedScalarExpressionEvaluation
    }, module, this.adaptableApi);
    const allAggregationValues = aggregatedScalarLiveValue.getAllAggregationValues();
    const numericOperand = booleanAggregationParameter.conditionValue;
    const booleanConditionFn = booleanAggregationParameter.conditionFn;
    return allAggregationValues.some(aggregationValue => {
      return booleanConditionFn(aggregationValue, numericOperand);
    });
  }
  getColumnsFromExpression(input = '') {
    return this.getNodesFromExpression(input, 'COL');
  }
  getNamedQueryNamesFromExpression(input = '') {
    return this.getNodesFromExpression(input, 'QUERY');
  }
  getExpressionWithColumnFriendlyNames(expression = '') {
    let result = expression;
    const columnIds = this.getColumnsFromExpression(expression);
    columnIds.forEach(columnId => {
      const columnFriendlyName = this.adaptableApi.columnApi.getFriendlyNameForColumnId(columnId);
      result = result.split(columnId).join(columnFriendlyName);
    });
    return result;
  }
  // Returns the ExpressionFunctions available for the given Module as specified in the `QueryLanguageOptions.moduleExpressionFunctions`
  // if there are no specific functions defined, it falls back to the default values
  getModuleExpressionFunctionsMap(module) {
    var _a, _b, _c;
    const expressionOptions = this.adaptableApi.optionsApi.getExpressionOptions();
    if (module) {
      let cachedResult = this.cacheModuleSpecificExpressionFunctions.get(module);
      if (cachedResult) {
        return cachedResult;
      }
    }
    const generalBooleanExpressionFunctions = this.extractMappedExpressionFunctions(booleanExpressionFunctions, expressionOptions.systemBooleanFunctions, expressionOptions.customBooleanFunctions);
    const generalScalarExpressionFunctions = this.extractMappedExpressionFunctions(scalarExpressionFunctions, expressionOptions.systemScalarFunctions, expressionOptions.customScalarFunctions);
    const generalObservableExpressionFunctions = this.extractMappedExpressionFunctions(observableExpressionFunctions, expressionOptions.systemObservableFunctions);
    const generalAggregatedBooleanExpressionFunctions = this.extractMappedExpressionFunctions(aggregatedBooleanExpressionFunctions, expressionOptions.systemAggregatedBooleanFunctions,
    // right now the custom aggregated scalar functions are used in the aggregated boolean functions as well
    // hopefully we'll simplify this in the future
    this.getCustomAggregatedScalarFunctions());
    const generalAggregatedScalarExpressionFunctions = this.extractMappedExpressionFunctions(aggregatedScalarExpressionFunctions, expressionOptions.systemAggregatedScalarFunctions, this.getCustomAggregatedScalarFunctions());
    if (!module) {
      this.adaptableApi.logWarn(`QueryLanguageService.getModuleExpressionFunctions() was called with an undefined 'module' param, this should never happen`);
      return {
        booleanFunctions: generalBooleanExpressionFunctions,
        scalarFunctions: generalScalarExpressionFunctions,
        observableFunctions: generalObservableExpressionFunctions,
        aggregatedBooleanFunctions: generalAggregatedBooleanExpressionFunctions,
        aggregatedScalarFunctions: generalAggregatedScalarExpressionFunctions
      };
    }
    let moduleExpressionFunctions;
    if (typeof expressionOptions.moduleExpressionFunctions === 'function') {
      const context = {
        adaptableApi: this.adaptableApi,
        userName: this.adaptableApi.optionsApi.getUserName(),
        adaptableId: this.adaptableApi.optionsApi.getAdaptableId(),
        module,
        availableBooleanFunctionNames: getTypedKeys(generalBooleanExpressionFunctions),
        availableScalarFunctionNames: getTypedKeys(generalScalarExpressionFunctions),
        availableObservableFunctionNames: getTypedKeys(generalObservableExpressionFunctions),
        availableAggregatedBooleanFunctionNames: getTypedKeys(generalAggregatedBooleanExpressionFunctions),
        availableAggregatedScalarFunctionNames: getTypedKeys(generalAggregatedScalarExpressionFunctions)
      };
      moduleExpressionFunctions = (_a = expressionOptions.moduleExpressionFunctions(context)) !== null && _a !== void 0 ? _a : {};
    } else {
      moduleExpressionFunctions = (_c = (_b = expressionOptions.moduleExpressionFunctions) === null || _b === void 0 ? void 0 : _b[module]) !== null && _c !== void 0 ? _c : {};
    }
    const moduleExpressionFunctionsMap = {
      booleanFunctions: this.extractMappedExpressionFunctions(generalBooleanExpressionFunctions, moduleExpressionFunctions.systemBooleanFunctions, moduleExpressionFunctions.customBooleanFunctions),
      scalarFunctions: this.extractMappedExpressionFunctions(generalScalarExpressionFunctions, moduleExpressionFunctions.systemScalarFunctions, moduleExpressionFunctions.customScalarFunctions),
      observableFunctions: this.extractMappedExpressionFunctions(generalObservableExpressionFunctions, moduleExpressionFunctions.systemObservableFunctions),
      aggregatedBooleanFunctions: this.extractMappedExpressionFunctions(generalAggregatedBooleanExpressionFunctions, moduleExpressionFunctions.systemAggregatedBooleanFunctions),
      aggregatedScalarFunctions: this.extractMappedExpressionFunctions(generalAggregatedScalarExpressionFunctions, moduleExpressionFunctions.systemAggregatedScalarFunctions)
    };
    this.cacheModuleSpecificExpressionFunctions.set(module, moduleExpressionFunctionsMap);
    return moduleExpressionFunctionsMap;
  }
  getCustomAggregatedScalarFunctions() {
    var _a;
    const customAggregatedScalarFunctions = (_a = this.adaptableApi.optionsApi.getExpressionOptions()) === null || _a === void 0 ? void 0 : _a.customAggregatedFunctions;
    if (!customAggregatedScalarFunctions) {
      return {};
    }
    if (typeof customAggregatedScalarFunctions === 'function') {
      return context => {
        const customDefinitions = customAggregatedScalarFunctions(context);
        return this.mapCustomAggregatedScalarFunctionsToInternal(customDefinitions);
      };
    }
    return this.mapCustomAggregatedScalarFunctionsToInternal(customAggregatedScalarFunctions);
  }
  mapCustomAggregatedScalarFunctionsToInternal(aggregatedScalarFunctions) {
    const getValueForColId = (colId, rowNode) => {
      return this.adaptableApi.gridApi.getRawValueFromRowNode(rowNode, colId);
    };
    return Object.entries(aggregatedScalarFunctions).reduce((acc, [expressionName, customExpressionDefinition]) => {
      const expression = {
        handler: (args, context) => {
          const [aggColParam, ...restOfColParams] = extractColumnParameters(expressionName, args);
          const aggColumnId = aggColParam === null || aggColParam === void 0 ? void 0 : aggColParam.value;
          const groupByParameter = extractParameter(expressionName, 'operand', ['GROUP_BY'], args, {
            isOptional: true
          });
          const groupByColumnIds = groupByParameter === null || groupByParameter === void 0 ? void 0 : groupByParameter.value;
          const contextArgs = args.filter(arg => {
            if (typeof arg === 'object' && arg.name === 'COL') {
              // filter out aggregation column
              return arg.value !== aggColumnId;
            }
            if (typeof arg === 'object' && arg.name === 'GROUP_BY') {
              // filter out groupBy column
              return false;
            }
            return true;
          });
          const aggregationExpressionEvaluation = {
            aggregationParams: {
              reducers: {
                [expressionName]: {
                  name: expressionName,
                  field: aggColumnId,
                  initialValue: customExpressionDefinition.initialValue,
                  reducer: (acc, value, rowNode, dataIndex) => {
                    const context = Object.assign({
                      accumulator: acc,
                      currentValue: value,
                      index: dataIndex,
                      rowNode,
                      args: contextArgs.map(arg => {
                        // if col, replace with value
                        if (typeof arg === 'object' && arg.name === 'COL') {
                          return getValueForColId(arg.value, rowNode);
                        }
                        return arg;
                      }),
                      aggColumnId,
                      groupByColumnIds,
                      getValueForColId: colId => getValueForColId(colId, rowNode)
                    }, createBaseContext(this.adaptableApi));
                    return customExpressionDefinition.reducer(context);
                  },
                  done: (accValue, dataArray) => {
                    if (customExpressionDefinition.processAggregatedValue) {
                      const context = Object.assign(Object.assign({}, createBaseContext(this.adaptableApi)), {
                        aggregatedValue: accValue,
                        rowNodes: dataArray,
                        args: contextArgs,
                        aggColumnId,
                        groupByColumnIds
                      });
                      return customExpressionDefinition.processAggregatedValue(context);
                    }
                    return accValue;
                  }
                }
              }
            },
            rowValueGetter: (rowNode, aggregationValue) => {
              if (customExpressionDefinition.prepareRowValue) {
                const context = Object.assign(Object.assign({
                  rowNode: rowNode,
                  aggregatedValue: aggregationValue
                }, createBaseContext(this.adaptableApi)), {
                  args: contextArgs.map(arg => {
                    // if col, replace with value
                    if (typeof arg === 'object' && arg.name === 'COL') {
                      return getValueForColId(arg.value, rowNode);
                    }
                    return arg;
                  }),
                  aggColumnId,
                  groupByColumnIds,
                  getValueForColId: colId => getValueForColId(colId, rowNode)
                });
                return customExpressionDefinition.prepareRowValue(context);
              }
              return aggregationValue;
            },
            rowFilterFn: rowNode => {
              var _a, _b;
              if (customExpressionDefinition.filterRow) {
                return customExpressionDefinition.filterRow(Object.assign(Object.assign({
                  rowNode
                }, createBaseContext(this.adaptableApi)), {
                  args: contextArgs.map(arg => {
                    // if col, replace with value
                    if (typeof arg === 'object' && arg.name === 'COL') {
                      return getValueForColId(arg.value, rowNode);
                    }
                    return arg;
                  }),
                  aggColumnId,
                  groupByColumnIds,
                  getValueForColId: colId => getValueForColId(colId, rowNode)
                }));
              }
              return (_b = (_a = context === null || context === void 0 ? void 0 : context.filterFn) === null || _a === void 0 ? void 0 : _a.call(context, rowNode)) !== null && _b !== void 0 ? _b : true;
            },
            getRowNodes: context.getRowNodes
          };
          addGroupByParams(groupByParameter === null || groupByParameter === void 0 ? void 0 : groupByParameter.value, aggregationExpressionEvaluation);
          const result = {
            name: expressionName,
            type: 'aggregationScalar',
            value: aggregationExpressionEvaluation
          };
          return result;
        },
        description: customExpressionDefinition.description,
        signatures: customExpressionDefinition.signatures,
        inputs: customExpressionDefinition.inputTypes
      };
      acc[expressionName] = expression;
      return acc;
    }, {});
  }
  extractMappedExpressionFunctions(availableExpressionFunctions, systemFunctions, customFunctions) {
    const systemFunctionNames = typeof systemFunctions === 'function' ? systemFunctions({
      adaptableApi: this.adaptableApi,
      userName: this.adaptableApi.optionsApi.getUserName(),
      adaptableId: this.adaptableApi.optionsApi.getAdaptableId(),
      availableExpressionFunctionNames: getTypedKeys(availableExpressionFunctions)
    }) : systemFunctions;
    let generalExpressionFunctions = {};
    // add system functions
    if (Array.isArray(systemFunctionNames)) {
      // add only system functions specified by user
      systemFunctionNames.forEach(systemFunctionName => {
        generalExpressionFunctions[systemFunctionName] = availableExpressionFunctions[systemFunctionName];
      });
    } else {
      // add ALL system functions
      generalExpressionFunctions = Object.assign({}, availableExpressionFunctions);
    }
    const customFunctionDefinitions = typeof customFunctions === 'function' ? customFunctions({
      adaptableApi: this.adaptableApi,
      userName: this.adaptableApi.optionsApi.getUserName(),
      adaptableId: this.adaptableApi.optionsApi.getAdaptableId(),
      availableExpressionFunctionNames: getTypedKeys(generalExpressionFunctions)
    }) : customFunctions;
    if (customFunctionDefinitions) {
      generalExpressionFunctions = Object.assign(Object.assign({}, generalExpressionFunctions), customFunctionDefinitions);
    }
    return generalExpressionFunctions;
  }
  evaluateCustomQueryVariable(functionName, args) {
    var _a, _b;
    const context = {
      adaptableApi: this.adaptableApi,
      userName: this.adaptableApi.optionsApi.getUserName(),
      adaptableId: this.adaptableApi.optionsApi.getAdaptableId(),
      args
    };
    const customQueryVariableDefinition = (_b = (_a = this.adaptableApi.optionsApi.getExpressionOptions()) === null || _a === void 0 ? void 0 : _a.customQueryVariables) === null || _b === void 0 ? void 0 : _b[functionName];
    return typeof customQueryVariableDefinition === 'function' ? customQueryVariableDefinition(context) : customQueryVariableDefinition;
  }
  getBooleanAndScalarFunctions(moduleExpressionFunctionsMap) {
    return Object.assign(Object.assign({}, moduleExpressionFunctionsMap.booleanFunctions), moduleExpressionFunctionsMap.scalarFunctions);
  }
  getExpressionCacheKey(expression, module) {
    return `${module}::${expression}`;
  }
  getNodesFromExpression(input, nodeType) {
    try {
      const resultSet = new Set();
      // @ts-ignore
      const walk = node => {
        if (typeof node !== 'object') {
          return false;
        }
        if (Array.isArray(node)) {
          return node.map(walk);
        }
        node.args.map(walk);
        if (node.type === nodeType) {
          resultSet.add(String(node.args[0]));
        }
      };
      const {
        ast
      } = parser.parse(input.trim());
      walk(ast);
      return Array.from(resultSet.values());
    } catch (e) {
      return [];
    }
  }
}