import * as React from 'react';
import { Flex } from 'rebass';
import * as GeneralConstants from '../../../../Utilities/Constants/GeneralConstants';
import TopBar from './TopBar';
import join from '../../../../components/utils/join';
import { Navigation } from './Navigation';
import PopupContext from './PopupContext';
import { AdaptablePopupBody } from './AdaptablePopupBody';
import { useAdaptable } from '../../../AdaptableContext';
import { CustomSettingsPanelView } from './CustomSettingsPanelView';
import { useMenuItems } from './useMenuItems';
import { AdaptablePopupDialog } from './AdaptablePopupDialog';
export const AdaptablePopup = props => {
  var _a, _b, _c;
  const adaptable = useAdaptable();
  const settingsPanelOptions = adaptable.adaptableOptions.settingsPanelOptions;
  const settingsPanelTitle = adaptable.api.internalApi.getModuleFriendlyName('SettingsPanel');
  const menuItems = useMenuItems();
  const isWindowModal = settingsPanelOptions.popupType === 'window';
  const modalContainer = (_b = (_a = adaptable.adaptableOptions) === null || _a === void 0 ? void 0 : _a.containerOptions) === null || _b === void 0 ? void 0 : _b.modalContainer;
  let friendlyName = null;
  /**
   * This means that it is not rendered in the context of Settings Panel
   * - no topbar
   * - no navigation
   */
  let isStandalone = false;
  let moduleViewContent = null;
  let activeItem = null;
  let accessLevel = 'Full';
  const firstMenuItem = menuItems.find(menuItem => menuItem !== '-');
  const firstMenuModule = firstMenuItem === null || firstMenuItem === void 0 ? void 0 : firstMenuItem.module;
  let componentModule = props.componentModule;
  let componentName = props.componentName;
  // ts forces to check for General, there is no menu item General
  const useFirstItem = !props.componentName;
  if (useFirstItem && firstMenuModule !== 'General' && firstMenuModule !== 'CustomSettingsPanel' && firstMenuModule !== 'Group') {
    componentModule = firstMenuModule;
  }
  const customSettingsPanel = (_c = settingsPanelOptions === null || settingsPanelOptions === void 0 ? void 0 : settingsPanelOptions.customSettingsPanels) === null || _c === void 0 ? void 0 : _c.find(customPanel => useFirstItem ? customPanel.name === firstMenuItem.label : customPanel.name === componentName);
  if (customSettingsPanel) {
    friendlyName = customSettingsPanel.name;
    activeItem = customSettingsPanel.name;
    moduleViewContent = React.createElement(CustomSettingsPanelView, {
      settingsPanel: customSettingsPanel
    });
  } else {
    const module = props.api.internalApi.getModuleService().getModuleById(componentModule);
    const moduleInfo = module === null || module === void 0 ? void 0 : module.moduleInfo;
    accessLevel = props.api.entitlementApi.getEntitlementAccessLevelForModule(componentModule);
    friendlyName = moduleInfo.FriendlyName;
    isStandalone = moduleInfo && (GeneralConstants.STANDALONE_MODULE_POPUPS.includes(moduleInfo === null || moduleInfo === void 0 ? void 0 : moduleInfo.ModuleName) || props.componentName && props.componentName !== (moduleInfo === null || moduleInfo === void 0 ? void 0 : moduleInfo.Popup));
    activeItem = moduleInfo.ModuleName;
    moduleViewContent = React.createElement(AdaptablePopupBody, {
      api: props.api,
      module: module,
      componentName: props.componentName,
      moduleParams: props.moduleParams,
      onClearParams: props.onClearParams,
      onHide: props.onHide
    });
  }
  const baseClassName = 'ab-Adaptable-Popup';
  const className = join(baseClassName, `${baseClassName}--${isWindowModal ? 'window' : 'modal'}`, isStandalone ? `${baseClassName}--action-popup` : `${baseClassName}--settings-popup`);
  return React.createElement(PopupContext.Provider, {
    value: {
      hidePopup: () => {
        if (props.onHide) {
          props.onHide();
        }
      }
    }
  }, React.createElement(AdaptablePopupDialog, {
    dataName: componentModule,
    baseClassName: baseClassName,
    className: className,
    friendlyName: friendlyName,
    isActionModule: isStandalone,
    isWindowModal: isWindowModal,
    onHide: props.onHide,
    modalContainer: modalContainer
  }, !isStandalone && React.createElement(TopBar, {
    icon: settingsPanelOptions.icon
  }, settingsPanelTitle), React.createElement(Flex, {
    flexDirection: "row",
    flex: "1 1 0",
    style: {
      minHeight: 0
    },
    className: accessLevel == 'ReadOnly' ? GeneralConstants.READ_ONLY_STYLE : ''
  }, !isStandalone && React.createElement(Navigation, {
    menuItems: menuItems,
    api: props.api,
    activeItem: activeItem,
    customSettingsPanels: settingsPanelOptions.customSettingsPanels
  }), React.createElement(Flex, {
    flex: 1,
    className: "ab-Adaptable-Popup__Body"
  }, moduleViewContent))));
};