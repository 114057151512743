import * as React from 'react';
export const Drawer = props => {
  const [isOpen, setIsOpen] = React.useState(() => {
    return props.open;
  });
  const [show, setShow] = React.useState(props.open);
  React.useEffect(() => {
    // CLOSED -> OPEN
    // SHOW Element, with closed class
    // CHANGE class to opened
    if (!isOpen && props.open) {
      setShow(true);
      requestAnimationFrame(() => {
        setIsOpen(true);
      });
    }
    // OPEN -> CLOSED
    // CHANGE class to closed
    // HIDE Element, when animation is finsihed
    if (isOpen && !props.open) {
      setIsOpen(false);
    }
  }, [props.open]);
  const handleOnKeyDown = React.useCallback(event => {
    var _a;
    // escape
    if (event.key === 'Escape') {
      (_a = props.onOpenChange) === null || _a === void 0 ? void 0 : _a.call(props, false);
    }
  }, []);
  const handletransitionEnd = () => {
    if (!isOpen) {
      setShow(false);
    }
  };
  if (!show) {
    return null;
  }
  const style = {
    position: 'fixed',
    top: 0,
    right: 0,
    zIndex: 1000,
    transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out'
  };
  if (isOpen) {
    style.transform = 'translateY(0)';
    style.opacity = 1;
  } else {
    style.transform = 'translateY(-120%)';
    style.opacity = 0;
  }
  return React.createElement("div", {
    onKeyDown: handleOnKeyDown,
    style: style,
    onTransitionEnd: handletransitionEnd
  }, props.children);
};