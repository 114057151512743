import * as React from 'react';
import { useState, useCallback } from 'react';
import { Box } from 'rebass';
import { OnePageAdaptableWizard, OnePageWizardSummary } from '../../Wizard/OnePageAdaptableWizard';
import { FlashingAlertSettingsWizardSection, renderFlashingAlertSettingsSummary } from './FlashingCellSettingsWizardSection';
import { cloneObject } from '../../../Utilities/Helpers/Helper';
import { FlashingAlertRulesWizardSection, renderFlashingAlertRulesSummary } from './FlashingCellRulesWizardSection';
import { FlashingAlertStyleWizardSection, renderFlashingAlertStyleSummary } from './FlashingCellStyleWizardSection';
import { FlashingAlertScopeWizardSection } from './FlashingCellScopeWizardSection';
import { renderScopeSummary } from '../../Alert/Wizard/BaseAlertScopeWizardSection';
import { useAdaptable } from '../../AdaptableContext';
import { isScopeValid } from '../../Components/NewScopeComponent';
import ObjectFactory from '../../../Utilities/ObjectFactory';
import { useDispatch } from 'react-redux';
import { isValidFlashingCellRules } from './isValidFlashingCellRules';
import * as FlashingCellRedux from '../../../Redux/ActionsReducers/FlashingCellRedux';
import { ObjectTagsWizardSection, renderObjectTagsSummary } from '../../Wizard/ObjectTagsWizardSection';
export const FlashingCellWizard = props => {
  const {
    api
  } = useAdaptable();
  const [flashingCell, setFlashingCell] = useState(() => {
    var _a, _b, _c, _d;
    let flashingCell = props.data ? cloneObject(props.data) : ObjectFactory.CreateEmptyFlashingCellDefinition();
    flashingCell = api.flashingCellApi.internalApi.mergeFlashingCellDefinitionWithDefaults(flashingCell);
    if (!flashingCell.Rule.BooleanExpression && !((_b = (_a = flashingCell.Rule) === null || _a === void 0 ? void 0 : _a.Predicates) === null || _b === void 0 ? void 0 : _b.length)) {
      flashingCell.Rule.BooleanExpression = '';
    }
    if (((_c = props.popupParams) === null || _c === void 0 ? void 0 : _c.column) && ((_d = props.popupParams) === null || _d === void 0 ? void 0 : _d.action) === 'New') {
      flashingCell.Scope = {
        ColumnIds: [props.popupParams.column.columnId]
      };
    }
    return flashingCell;
  });
  const updateProperty = propName => {
    return value => {
      setFlashingCell(flashingCell => {
        flashingCell = Object.assign(Object.assign({}, flashingCell), {
          [propName]: value
        });
        return flashingCell;
      });
    };
  };
  const updateStyles = {
    DownChangeStyle: useCallback(updateProperty('DownChangeStyle'), []),
    UpChangeStyle: useCallback(updateProperty('UpChangeStyle'), []),
    NeutralChangeStyle: useCallback(updateProperty('NeutralChangeStyle'), [])
  };
  const dispatch = useDispatch();
  const handleFinish = () => {
    if (props.data) {
      dispatch(FlashingCellRedux.FlashingCellDefinitionEdit(flashingCell));
    } else {
      dispatch(FlashingCellRedux.FlashingCellDefinitionAdd(flashingCell));
    }
    props.onFinishWizard(flashingCell);
  };
  return React.createElement(OnePageAdaptableWizard, {
    defaultCurrentSectionName: props.defaultCurrentSectionName,
    moduleInfo: props.moduleInfo,
    data: flashingCell,
    onHide: props.onCloseWizard,
    onFinish: handleFinish,
    sections: [{
      details: 'Specify which data changes should trigger Cell Flashing',
      isValid: isScopeValid,
      render: () => React.createElement(FlashingAlertScopeWizardSection, {
        onChange: setFlashingCell
      }),
      renderSummary: renderScopeSummary,
      title: 'Scope'
    }, {
      details: 'Build the Rules for when Cells should Flash',
      isValid: isValidFlashingCellRules,
      render: () => React.createElement(FlashingAlertRulesWizardSection, {
        onChange: setFlashingCell
      }),
      renderSummary: renderFlashingAlertRulesSummary,
      title: 'Rule'
    }, {
      details: 'Choose Flash Styles for Up, Down and Neutral Changes',
      render: () => React.createElement(Box, {
        padding: 2
      }, React.createElement(FlashingAlertStyleWizardSection, {
        onStyleChange: (styleName, style) => {
          updateStyles[styleName](style);
        }
      })),
      renderSummary: renderFlashingAlertStyleSummary,
      title: 'Flash Styles'
    }, {
      details: 'Set Duration and Target Properties',
      render: () => React.createElement(Box, {
        padding: 2
      }, React.createElement(FlashingAlertSettingsWizardSection, {
        onChange: setFlashingCell
      })),
      renderSummary: renderFlashingAlertSettingsSummary,
      title: 'Settings'
    }, {
      details: 'Select Flashing Cell Tags',
      title: 'Tags',
      isVisible: (_, api) => api.internalApi.shouldDisplayTagSections(),
      render: () => React.createElement(Box, {
        padding: 2
      }, React.createElement(ObjectTagsWizardSection, {
        onChange: setFlashingCell
      })),
      renderSummary: renderObjectTagsSummary
    }, '-', {
      details: 'Review the Flashing Cell Rule',
      render: () => {
        return React.createElement(Box, {
          padding: 2
        }, React.createElement(OnePageWizardSummary, null));
      },
      title: 'Summary'
    }]
  });
};