import * as React from 'react';
import { useAdaptable } from '../AdaptableContext';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import { AdaptableObjectCompactList } from '../Components/AdaptableObjectList/AdaptableObjectCompactList';
import { useSelector } from 'react-redux';
export const ActiveAlertsPanel = () => {
  const adaptable = useAdaptable();
  const filterModule = adaptable.ModuleService.getModuleById(ModuleConstants.AlertModuleId);
  const alerts = useSelector(state => state.System.AdaptableAlerts);
  return React.createElement(AdaptableObjectCompactList, {
    abObjects: alerts,
    module: filterModule
  });
};