import * as React from 'react';
import { SelectList } from '../../components/SelectList';
import { useAdaptable } from '../AdaptableContext';
export const DataSetStatusPanelPopover = () => {
  const adaptable = useAdaptable();
  const dataSets = adaptable.api.dataSetApi.getDataSets();
  const handleDataSetChange = React.useCallback(option => {
    adaptable.api.dataSetApi.setDataSet(option.label);
  }, []);
  const options = dataSets.map(dataSet => ({
    label: dataSet.name,
    value: dataSet.name
  }));
  return React.createElement(SelectList, {
    options: options,
    onChange: handleDataSetChange
  });
};