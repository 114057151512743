import { isSameDay } from 'date-fns';
import * as React from 'react';
import ReactSelect, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Icon } from '../icons';
const commonStyles = ({
  isFocused,
  isDisabled
}) => {
  return {
    color: 'var(--ab-cmp-input__color)',
    background: isDisabled ? 'var(--ab-cmp-input--disabled__background)' : 'var(--ab-cmp-input__background)',
    fontSize: 'var(--ab-cmp-select__font-size)',
    fontFamily: 'var(--ab-cmp-select__font-family)'
  };
};
export const Select = function (props) {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const doesOptionMatchValue = function (option) {
    if (typeof option.value === 'object' && option.value instanceof Date) {
      return isSameDay(option.value, props.value);
    }
    return option.value === props.value;
  };
  let selectedOption = null;
  if (props.isMulti) {
    selectedOption = (_b = ((_a = props.value) !== null && _a !== void 0 ? _a : []).map(value => {
      var _a;
      // return (props.value as any[]).includes(option.value);
      const option = ((_a = props.options) !== null && _a !== void 0 ? _a : []).find(doesOptionMatchValue);
      if (!option) {
        return {
          value,
          label: value
        };
      }
      return option;
    })) !== null && _b !== void 0 ? _b : null;
  } else {
    selectedOption = (_d = ((_c = props.options) !== null && _c !== void 0 ? _c : []).find(doesOptionMatchValue)) !== null && _d !== void 0 ? _d : null;
    if (!selectedOption && props.value !== undefined && props.value !== null) {
      selectedOption = {
        value: props.value,
        label: props.value
      };
    }
  }
  let disabled = (_e = props.disabled) !== null && _e !== void 0 ? _e : false;
  const accessLevel = (_f = props.accessLevel) !== null && _f !== void 0 ? _f : 'Full';
  if (accessLevel === 'Hidden') {
    return null;
  }
  if (accessLevel === 'ReadOnly') {
    disabled = true;
  }
  /**
   * If on each render a new reference is passed, the menu will not open using the keyboard.
   */
  const SelectContainer = React.useMemo(() => {
    return inputProps => {
      return React.createElement(components.SelectContainer, Object.assign({}, inputProps, {
        innerProps: Object.assign({
          // @ts-ignore
          'data-name': props['data-name'],
          'data-id': props['data-id']
        }, inputProps.innerProps)
      }));
    };
  }, []);
  const ValueContainer = React.useMemo(() => {
    return inputProps => {
      return React.createElement(components.ValueContainer, Object.assign({}, inputProps, {
        innerProps: Object.assign({
          'data-name': 'value-container'
        }, inputProps.innerProps)
      }));
    };
  }, []);
  const MenuComponent = React.useMemo(() => {
    return inputProps => {
      return React.createElement(components.Menu, Object.assign({}, inputProps, {
        innerProps: Object.assign({
          'data-name': 'menu-container'
        }, inputProps.innerProps)
      }));
    };
  }, []);
  const SelectComponent = props.isCreatable ? CreatableSelect : ReactSelect;
  const ClearIndicator = React.useMemo(() => {
    return clearIndicatorProps => {
      return React.createElement(components.ClearIndicator, Object.assign({}, clearIndicatorProps), React.createElement(Icon, {
        name: "close"
      }));
    };
  }, []);
  const DropdownIndicator = React.useMemo(() => {
    return props => {
      return React.createElement(components.DropdownIndicator, Object.assign({}, props), React.createElement(Icon, {
        name: "triangle-down",
        style: {
          height: 20,
          width: 20
        }
      }));
    };
  }, []);
  return React.createElement(SelectComponent, {
    onInputChange: props.onInputChange,
    onFocus: props.onFocus,
    isLoading: props.isLoading,
    options: props.options,
    className: props.className,
    isDisabled: disabled,
    menuPlacement: (_g = props.menuPlacement) !== null && _g !== void 0 ? _g : 'auto',
    isSearchable: props.searchable,
    isMulti: props.isMulti,
    value: selectedOption,
    menuPosition: (_h = props.menuPosition) !== null && _h !== void 0 ? _h : 'absolute',
    // This needed so the menu is not clipped by overflow: hidden
    menuPortalTarget: props.menuPortalTarget === undefined ? document.body : null,
    isClearable: props.isClearable,
    onChange: option => {
      if (props.isMulti) {
        props.onChange(option.map(x => x === null || x === void 0 ? void 0 : x.value));
      } else {
        props.onChange(option === null || option === void 0 ? void 0 : option.value);
      }
    },
    placeholder: props.placeholder,
    createOptionPosition: 'first',
    components: {
      SelectContainer,
      ValueContainer,
      Menu: MenuComponent,
      SingleValue: singleValueProps => {
        return React.createElement(components.SingleValue, Object.assign({}, singleValueProps), props.renderSingleValue ? props.renderSingleValue(selectedOption) : singleValueProps.children);
      },
      ClearIndicator,
      DropdownIndicator
    },
    /**
     * Using styles is the preferred way to style react-select.
     * https://react-select.com/styles#the-styles-prop
     */
    styles: {
      // Typescript issue with csstype@3.1.3
      // https://github.com/JedWatson/react-select/issues/5825#issuecomment-1850472549
      // Remove
      container: baseStyle => {
        return Object.assign(Object.assign({}, baseStyle), props.style);
      },
      // @ts-ignorets-ignore when fixed
      menuPortal: baseStyle => {
        return Object.assign(Object.assign({}, baseStyle), {
          zIndex: 999999,
          textAlign: 'left'
        });
      },
      // @ts-ignore
      menu: (baseStyle, state) => {
        return Object.assign(Object.assign(Object.assign({}, baseStyle), {
          zIndex: 999999,
          boxShadow: 'var(--ab-cmp-select-menu__box-shadow)',
          minWidth: 160
        }), commonStyles(state));
      },
      // @ts-ignore
      option: (baseStyle, state) => {
        const style = Object.assign(Object.assign(Object.assign({}, baseStyle), commonStyles(state)), {
          '&:active': {
            background: 'var(--ab-cmp-select-option-active__background)'
          },
          opacity: state.isDisabled ? 0.5 : 1
        });
        if (state.isSelected) {
          style.background = 'var(--ab-cmp-select-option-active__background)';
          style.color = 'var(--ab-cmp-select-option-active__color)';
        }
        if (state.isFocused) {
          style.background = 'var(--ab-cmp-select-option-focused__background)';
        }
        return style;
      },
      // @ts-ignore
      input: (baseStyle, state) => {
        return Object.assign(Object.assign({}, baseStyle), {
          padding: props.size === 'small' ? 0 : baseStyle.padding,
          color: 'var(--ab-cmp-select__color)'
        });
      },
      valueContainer: baseStyle => {
        return Object.assign(Object.assign({}, baseStyle), {
          padding: props.size === 'small' ? `0 var(--ab-space-1)` : baseStyle.padding
        });
      },
      // @ts-ignore
      singleValue: (baseStyle, state) => {
        return Object.assign(Object.assign({}, baseStyle), commonStyles(state));
      },
      // @ts-ignore
      control: (baseStyle, state) => {
        state;
        return Object.assign(Object.assign(Object.assign({}, baseStyle), commonStyles(state)), {
          // height: 30,
          minHeight: props.size === 'small' ? 0 : 32,
          boxShadow: state.isFocused ? 'var(--ab-cmp-select-focused__box-shadow)' : 'none',
          outline: state.isFocused ? 'var(--ab-cmp-select-focused__outline)' : 'none',
          border: 'var(--ab-cmp-select__border)',
          borderRadius: 'var(--ab-cmp-select__border-radius)',
          '&:hover': {
            border: 'var(--ab-cmp-select__border)'
          }
        });
      },
      // @ts-ignore
      dropdownIndicator: baseStyle => {
        return Object.assign(Object.assign({}, baseStyle), {
          padding: 0
        });
      },
      // @ts-ignore
      clearIndicator: baseStyle => {
        return Object.assign(Object.assign({}, baseStyle), {
          padding: '2px 3px'
        });
      },
      multiValue: baseStyle => {
        return Object.assign(Object.assign({}, baseStyle), {
          color: 'var(--ab-cmp-select__color)',
          background: 'var(--ab-cmp-select-multi-value__background)',
          border: 'var(--ab-cmp-select__border)'
        });
      },
      multiValueLabel: baseStyle => {
        return Object.assign(Object.assign({}, baseStyle), {
          color: 'var(--ab-cmp-select__color)'
        });
      }
    }
  });
};