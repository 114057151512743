import * as React from 'react';
import Radio from '../../components/Radio';
import { useAdaptable } from '../AdaptableContext';
export const DataSetSelector = ({
  data
}) => {
  const adaptable = useAdaptable();
  const dataSets = adaptable.api.dataSetApi.getDataSets();
  const selectedDataSet = adaptable.api.dataSetApi.getCurrentDataSet();
  const isDisabled = (dataSets === null || dataSets === void 0 ? void 0 : dataSets.length) === 1;
  const isSelected = (selectedDataSet === null || selectedDataSet === void 0 ? void 0 : selectedDataSet.name) === (data === null || data === void 0 ? void 0 : data.name);
  const handleToggle = React.useCallback(() => {
    adaptable.api.dataSetApi.setDataSet(data.name);
  }, [isSelected]);
  return React.createElement(Radio, {
    "data-name": "select-dataset-button",
    "data-value": data.name,
    disabled: isDisabled,
    checked: isSelected,
    onClick: handleToggle
  });
};