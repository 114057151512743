import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
import { AdaptableModuleBase } from './AdaptableModuleBase';
export class DataImportModule extends AdaptableModuleBase {
  constructor(api) {
    super(ModuleConstants.DataImportModuleId, ModuleConstants.DataImportFriendyName, 'import', 'DataImportPopup', 'Import Data', api);
  }
  createModuleMenuItem(source) {
    if (this.isModuleAvailable()) {
      return this.createDataImportMenuItem(source);
    }
  }
  createContextMenuItems() {
    if (this.isModuleAvailable()) {
      return [this.createDataImportMenuItem('ContextMenu')];
    }
    return undefined;
  }
  createColumnMenuItems() {
    if (this.isModuleAvailable()) {
      return [this.createDataImportMenuItem('ColumnMenu')];
    }
  }
  createDataImportMenuItem(source) {
    const moduleParams = {
      action: 'New',
      source: source,
      maxWidth: this.getPopupMaxWidth()
    };
    return this.createMenuItemShowPopup('data-import', 'Import Data', this.moduleInfo.Popup, this.moduleInfo.Glyph, moduleParams);
  }
}