import { __rest } from "tslib";
import * as React from 'react';
import { createPortal } from 'react-dom';
import { useMemo } from 'react';
import join from '../utils/join';
import { RemoveScroll } from 'react-remove-scroll';
import { Flex } from 'rebass';
import { baseClassName, default as Backdrop } from './Backdrop';
import { createUuid } from '../utils/uuid';
import { isBrowserDocumentAvailable } from '../../View/UIHelper';
let portalElement;
export const ensurePortalElement = () => {
  if (!isBrowserDocumentAvailable()) {
    return null;
  }
  if (portalElement) {
    return portalElement;
  }
  portalElement = document.createElement('div');
  document.body.appendChild(portalElement);
  return portalElement;
};
let globalCounter = 0;
export const Modal = props => {
  ensurePortalElement();
  const {
      className,
      style,
      children,
      isOpen,
      onBringToFront
    } = props,
    boxProps = __rest(props, ["className", "style", "children", "isOpen", "onBringToFront"]);
  const uuid = useMemo(() => createUuid(), []);
  const counter = useMemo(() => globalCounter++, [isOpen]);
  const openTimestamp = counter;
  const backdropZIndexOffset = props.backdropZIndexOffset || 1;
  const zIndex = (props.baseZIndex || 2000) + counter;
  return createPortal(isOpen ? React.createElement(React.Fragment, null, React.createElement(Backdrop, {
    timestamp: openTimestamp,
    uuid: uuid,
    zIndex: zIndex - backdropZIndexOffset,
    onBringToFront: onBringToFront
  }), React.createElement(RemoveScroll, null, React.createElement(Flex, Object.assign({
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  }, boxProps, {
    style: Object.assign({
      zIndex
    }, style),
    className: join(baseClassName, className)
  }), children))) : null, portalElement);
};