import { __rest } from "tslib";
import * as React from 'react';
export const InputGroup = _a => {
  var _b;
  var {
      Component = 'div',
      children
    } = _a,
    rest = __rest(_a, ["Component", "children"]);
  const Comp = Component;
  return React.createElement(Comp, Object.assign({}, rest, {
    className: `ab-cmp-input-group ${(_b = rest.className) !== null && _b !== void 0 ? _b : ''}`
  }), children);
};