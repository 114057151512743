import { __rest } from "tslib";
import * as React from 'react';
import { Flex, Box } from 'rebass';
import { useReducer, useRef } from 'react';
import join from '../../components/utils/join';
import contains from '../../components/utils/contains';
import SimpleButton from '../SimpleButton';
import { Icon } from '../icons';
import HelpBlock from '../HelpBlock';
import reducer, { ActionTypes } from './reducer';
const initialState = {
  dragOver: false,
  message: null
};
const preventEventPropagation = e => {
  e.preventDefault();
  e.stopPropagation();
};
export const readJSONFile = async (file, toJSON) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = function (e) {
      try {
        const fn = toJSON || JSON.parse;
        const json = fn(e.target.result);
        Promise.resolve(json).then(resolve);
      } catch (ex) {
        reject('Invalid JSON');
      }
    };
    reader.onerror = function (e) {
      reject(e);
    };
    reader.readAsText(file);
  });
};
const FileDroppable = (props = {}) => {
  var _a;
  const {
      onDropSuccess,
      message,
      fileAccept = '.json',
      helpText = 'AdapTable No Code Version',
      defaultText = 'Click here to select a JSON file to load or drag it here',
      dragOverText = 'Drop file here to start Adaptable Wizard',
      icon = React.createElement(Icon, {
        name: "paperclip",
        size: 48
      }),
      value
    } = props,
    domProps = __rest(props, ["onDropSuccess", "message", "fileAccept", "helpText", "defaultText", "dragOverText", "icon", "value"]);
  const [state, dispatch] = useReducer(reducer, initialState);
  const onDragEnter = e => {
    dispatch({
      type: ActionTypes.DRAG_OVER
    });
  };
  const onDragLeave = event => {
    preventEventPropagation(event);
    if (domRef.current != event.target && contains(domRef.current, event.target)) {
      return;
    }
    dispatch({
      type: ActionTypes.DRAG_OUT
    });
  };
  const onDrop = async e => {
    let files;
    preventEventPropagation(e);
    let nativeEvent = e.nativeEvent;
    if (nativeEvent && nativeEvent.dataTransfer) {
      files = nativeEvent.dataTransfer.files;
    } else {
      files = e.target.files;
    }
    onDragLeave(e);
    const file = files[0];
    if (file) {
      let json;
      try {
        json = await (props.readFile || readJSONFile)(file, props.toJSON);
        dispatch({
          type: ActionTypes.DROP_SUCCES,
          payload: {
            message: React.createElement(Box, null, props.loadingText === undefined ? 'Initializing adaptable...' : props.loadingText)
          }
        });
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            if (onDropSuccess) {
              onDropSuccess(json, file);
            }
          });
        });
      } catch (err) {
        dispatch({
          type: ActionTypes.SET_INVALID_FILE,
          payload: {
            message: React.createElement(Box, null, 'The file is not a valid JSON file! Please try again!')
          }
        });
      }
    }
  };
  const domRef = useRef();
  let form = React.createElement("form", {
    onSubmit: preventEventPropagation
  }, React.createElement(SimpleButton, {
    style: {
      cursor: 'pointer'
    },
    variant: "outlined"
  }, React.createElement("div", null, state.dragOver ? dragOverText : defaultText), React.createElement("input", {
    type: "file",
    onChange: onDrop,
    accept: fileAccept,
    value: (_a = props === null || props === void 0 ? void 0 : props.file) === null || _a === void 0 ? void 0 : _a.name,
    style: {
      opacity: 0,
      position: 'absolute',
      cursor: 'pointer',
      fontSize: 0,
      lineHeight: 0,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1
    }
  })));
  const msg = message || state.message;
  return React.createElement(Flex, Object.assign({}, domProps, {
    flexDirection: "column",
    className: join(props.className, 'ab-FileDroppable', state.dragOver ? 'ab-FileDroppable--drag-over' : ''),
    alignItems: "center",
    justifyContent: "center",
    onDragEnter: onDragEnter,
    onDragLeave: onDragLeave,
    onDrop: onDrop,
    onDragOver: preventEventPropagation,
    ref: domRef
  }), props.children, helpText || icon ? React.createElement(Flex, {
    flexDirection: "column"
  }, helpText ? React.createElement(Flex, {
    flexDirection: "column",
    alignItems: "center",
    margin: 2
  }, React.createElement(HelpBlock, null, React.createElement("b", null, helpText))) : null, icon ? React.createElement(Flex, {
    flexDirection: "column",
    alignItems: "center",
    margin: 2
  }, icon) : null) : null, msg, form);
};
export default FileDroppable;