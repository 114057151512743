import * as React from 'react';
import { isAdaptableSystemIcon } from '../../../components/Icon';
import SimpleButton from '../../../components/SimpleButton';
import { useAdaptable } from '../../AdaptableContext';
// This component is connected to adaptable and know about custom icons
export const AdaptableButtonComponent = props => {
  let icon = props.icon;
  const adaptable = useAdaptable();
  if (isAdaptableSystemIcon(icon)) {
    icon = adaptable.api.userInterfaceApi.internalApi.prepareAdaptableIconDef(icon);
  }
  return React.createElement(SimpleButton, Object.assign({}, props, {
    icon: icon
  }));
};