import { ApiBase } from '../Implementation/ApiBase';
import * as SystemRedux from '../../Redux/ActionsReducers/SystemRedux';
export class SystemStatusInternalApi extends ApiBase {
  /**
   * Fires SystemStatusMessageDisplayed Event
   * @param systemStatusMessageInfo Info about new System Status Message
   */
  fireSystemStatusMessageDisplayedEvent(systemStatusMessageInfo) {
    const systemStatusMessageDisplayedInfo = Object.assign({
      systemStatusMessageInfo: systemStatusMessageInfo
    }, this.getAdaptableApi().internalApi.buildBaseContext());
    this.getAdaptableApi().eventApi.emit('SystemStatusMessageDisplayed', systemStatusMessageDisplayedInfo);
  }
  addSystemStatusMessageInfo(systemStatusMessageInfo) {
    const maxMessagesInStore = this.getNotificationsOptions().maxSystemMessagesInStore;
    this.dispatchAction(SystemRedux.SystemStatusMessageInfoAdd(systemStatusMessageInfo, maxMessagesInStore));
  }
  getSystemStatusMessageInfos() {
    return this.getAdaptableState().System.SystemStatusMessages;
  }
}