import { ScheduleType } from '../../../../PredefinedConfig/Common/Enums';
import StringExtensions from '../../../../Utilities/Extensions/StringExtensions';
export const isSettingsValid = schedule => {
  var _a, _b, _c, _d;
  if (schedule.ScheduleType === ScheduleType.Reminder) {
    const reminder = schedule;
    if (!reminder.MessageType) {
      return 'Message type not selected';
    }
    if (StringExtensions.IsNullOrEmpty(reminder === null || reminder === void 0 ? void 0 : reminder.Header)) {
      return 'Reminder header not specified';
    }
    if (StringExtensions.IsNullOrEmpty(reminder === null || reminder === void 0 ? void 0 : reminder.Message)) {
      return 'Reminder message not specified';
    }
  }
  if (schedule.ScheduleType === ScheduleType.Report) {
    const report = schedule;
    if (StringExtensions.IsNullOrEmpty(report === null || report === void 0 ? void 0 : report.ReportName)) {
      return 'Report source not specified';
    }
    if (StringExtensions.IsNullOrEmpty(report === null || report === void 0 ? void 0 : report.ExportDestination)) {
      return 'Report destination not selected';
    }
  }
  if (schedule.ScheduleType === ScheduleType.ipushpull) {
    const ipushpull = schedule;
    if (StringExtensions.IsNullOrEmpty((_a = ipushpull === null || ipushpull === void 0 ? void 0 : ipushpull.IPushPullReport) === null || _a === void 0 ? void 0 : _a.ReportName)) {
      return 'Report not selected';
    }
    if (StringExtensions.IsNullOrEmpty((_b = ipushpull === null || ipushpull === void 0 ? void 0 : ipushpull.IPushPullReport) === null || _b === void 0 ? void 0 : _b.Folder)) {
      return 'Report folder not selected';
    }
    if (StringExtensions.IsNullOrEmpty((_c = ipushpull === null || ipushpull === void 0 ? void 0 : ipushpull.IPushPullReport) === null || _c === void 0 ? void 0 : _c.Page)) {
      return 'Report page not selected';
    }
  }
  if (schedule.ScheduleType === ScheduleType.OpenFin) {
    const openfin = schedule;
    if (StringExtensions.IsNullOrEmpty((_d = openfin === null || openfin === void 0 ? void 0 : openfin.OpenFinReport) === null || _d === void 0 ? void 0 : _d.ReportName)) {
      return 'Report not selected';
    }
  }
  return true;
};