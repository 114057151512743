import * as React from 'react';
import { EntityRulesEditor, EntityRulesSummary } from '../../Components/EntityRulesEditor';
import { Tag } from '../../../components/Tag';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
import { getAvailablePredicateDefinitions } from '../Utilities/getAvailablePredicates';
import { Tabs } from '../../../components/Tabs';
import Radio from '../../../components/Radio';
import { Box } from 'rebass';
export const getRuleStepDescription = alertType => {
  switch (alertType) {
    case 'DataChange':
      return React.createElement(React.Fragment, null, "Build the Rule ", React.createElement("b", null, "when"), " the Alert should trigger (using a Predicate or a Query)");
    case 'RowChange':
      return React.createElement(React.Fragment, null, "Specify which type of Row Change will trigger the Alert");
    case 'Validation':
      return React.createElement(React.Fragment, null, "Create the Validation Rule which will trigger the Alert (using a Predicate or a Query)");
    case 'Aggregation':
      return React.createElement(React.Fragment, null, "Build an Aggregation Boolean Rule to specify when the Alert should trigger");
    case 'Observable':
      return React.createElement(React.Fragment, null, "Build an Observable Rule to specify when the Alert should trigger");
  }
  return '';
};
export const renderAlertRulesSummary = alertDefinition => {
  return React.createElement(EntityRulesSummary, {
    data: alertDefinition,
    renderPredicate: contents => {
      return React.createElement(React.Fragment, null, "Alert Rule ", React.createElement(Tag, null, contents));
    },
    renderQueryExpression: contents => {
      return React.createElement(React.Fragment, null, "Alert when ", React.createElement(Tag, null, contents));
    }
  });
};
const RowChangeEditor = props => {
  var _a, _b, _c;
  const predicate = (_c = (_b = (_a = props.alert.Rule) === null || _a === void 0 ? void 0 : _a.Predicates) === null || _b === void 0 ? void 0 : _b[0]) !== null && _c !== void 0 ? _c : {
    PredicateId: null
  };
  const handleChange = predicateId => {
    props.onChange(Object.assign(Object.assign({}, props.alert), {
      Rule: {
        Predicates: [{
          PredicateId: predicateId
        }]
      }
    }));
  };
  return React.createElement(Box, {
    p: 2
  }, React.createElement(Tabs, null, React.createElement(Tabs.Tab, null, "Row Change"), React.createElement(Tabs.Content, null, React.createElement(Radio, {
    onClick: () => handleChange('AddedRow'),
    checked: predicate.PredicateId === 'AddedRow'
  }, "Row Added"), React.createElement(Radio, {
    onClick: () => handleChange('RemovedRow'),
    checked: predicate.PredicateId === 'RemovedRow'
  }, "Row Removed"))));
};
export const AlertRulesWizardSection = props => {
  const {
    data,
    api,
    moduleInfo
  } = useOnePageAdaptableWizardContext();
  const predicateDefs = getAvailablePredicateDefinitions(api, data.Scope, props.alertType);
  const showObservable = props.alertType === 'Observable';
  const showAggregation = props.alertType === 'Aggregation';
  const showBoolean = props.alertType === 'DataChange' || props.alertType == 'Validation';
  const showPredicate = props.alertType === 'DataChange' || props.alertType == 'Validation' || props.alertType === 'RowChange';
  const enablePredicateColumnId = props.alertType === 'DataChange' || props.alertType == 'Validation';
  if (props.alertType === 'RowChange') {
    return React.createElement(RowChangeEditor, {
      alert: data,
      onChange: props.onChange
    });
  }
  return React.createElement(EntityRulesEditor, {
    module: moduleInfo.ModuleName,
    defaultPredicateId: "AnyChange",
    data: data,
    predicateDefs: predicateDefs,
    enablePredicateColumnId: enablePredicateColumnId,
    getPredicateDefsForColId: colId => getAvailablePredicateDefinitions(api, {
      ColumnIds: [colId]
    }, props.alertType),
    onChange: props.onChange,
    showAggregation: showAggregation,
    showObservable: showObservable,
    showBoolean: showBoolean,
    showPredicate: showPredicate,
    descriptions: {
      selectPredicate: 'Build the Predicate',
      useBooleanQuery: React.createElement(React.Fragment, null, "Use an BooleanQuery if ", React.createElement("i", null, "Scope"), " is 'All Columns' - so any data change may be evaluated in a complex BooleanExpression"),
      useObservableQuery: React.createElement(React.Fragment, null, "Use an ObservableQuery if ", React.createElement("i", null, "Scope"), " is 'All Columns' - so any data change may be evaluated in a complex ObservableExpression"),
      useAggregationQuery: React.createElement(React.Fragment, null, "Use an AggregatedBooleanQuery if ", React.createElement("i", null, "Scope"), " is 'All Columns' - so any data change may be evaluated in a complex AggregatedBooleanExpression")
    }
  });
};