import { __rest } from "tslib";
import * as React from 'react';
import { Box, Text } from 'rebass';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
import { Tabs } from '../../../components/Tabs';
import { StyleComponent } from '../../Components/StyleComponent';
import { StylePreview } from '../../../components/StylePreview';
const changeStyle = React.createElement(Text, {
  fontSize: 2,
  style: {
    display: 'inline-block'
  }
}, "Change style");
export const FlashingAlertStyleWizardSection = props => {
  var _a, _b, _c;
  let {
    data: flashingAlert,
    api
  } = useOnePageAdaptableWizardContext();
  flashingAlert = flashingAlert !== null && flashingAlert !== void 0 ? flashingAlert : props.flashingAlert;
  return React.createElement(React.Fragment, null, React.createElement(Tabs, {
    defaultValue: "up"
  }, React.createElement(Tabs.Tab, {
    value: "up"
  }, "Up ", changeStyle), React.createElement(Tabs.Content, {
    value: "up"
  }, React.createElement(StyleComponent, {
    headless: true,
    api: api,
    Style: (_a = flashingAlert.UpChangeStyle) !== null && _a !== void 0 ? _a : {},
    UpdateStyle: style => props.onStyleChange('UpChangeStyle', style)
  })), React.createElement(Tabs.Tab, {
    value: "down"
  }, "Down ", changeStyle), React.createElement(Tabs.Content, {
    value: "down"
  }, React.createElement(StyleComponent, {
    headless: true,
    api: api,
    Style: (_b = flashingAlert.DownChangeStyle) !== null && _b !== void 0 ? _b : {},
    UpdateStyle: style => props.onStyleChange('DownChangeStyle', style)
  })), React.createElement(Tabs.Tab, {
    value: "neutral"
  }, "Neutral ", changeStyle), React.createElement(Tabs.Content, {
    value: "neutral"
  }, React.createElement(StyleComponent, {
    headless: true,
    api: api,
    Style: (_c = flashingAlert.NeutralChangeStyle) !== null && _c !== void 0 ? _c : {},
    UpdateStyle: style => props.onStyleChange('NeutralChangeStyle', style)
  }))));
};
export const FlashingCellStyle = props => {
  const {
      data
    } = props,
    boxProps = __rest(props, ["data"]);
  return React.createElement(Box, Object.assign({}, boxProps), React.createElement(StylePreview, {
    styleObject: props.data.UpChangeStyle
  }, "Up change style ", !props.data.UpChangeStyle ? ' - no style' : ''), React.createElement(StylePreview, {
    mt: 2,
    styleObject: props.data.DownChangeStyle
  }, "Down change style ", !props.data.DownChangeStyle ? ' - no style' : ''), React.createElement(StylePreview, {
    mt: 2,
    styleObject: props.data.NeutralChangeStyle
  }, "Neutral change style ", !props.data.NeutralChangeStyle ? ' - no style' : ''));
};
export const renderFlashingAlertStyleSummary = flashingAlert => {
  return React.createElement(FlashingCellStyle, {
    data: flashingAlert
  });
};