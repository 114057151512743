import * as React from 'react';
import { useLayoutEffect, useRef, useState } from 'react';
import { useAdaptable } from '../../AdaptableContext';
import { createUuid } from '../../../components/utils/uuid';
import SimpleButton from '../../../components/SimpleButton';
import kebabCase from 'lodash/kebabCase';
export const CustomToolPanelContent = props => {
  var _a;
  const {
    customToolPanel
  } = props;
  const {
    api
  } = useAdaptable();
  const contentsDivDomRef = useRef();
  const contentsDivId = `ab-ToolPanel__${customToolPanel.name}__contents`;
  const buttonsDivId = `ab-ToolPanel__${customToolPanel.name}__buttons`;
  const [contentsHTML, setContentsHTML] = useState('');
  useLayoutEffect(() => {
    const element = contentsDivDomRef.current;
    if (hasCustomRenderFn(customToolPanel)) {
      const customRenderContext = Object.assign({
        visible: true,
        phase: 'onMount',
        element
      }, api.internalApi.buildBaseContext());
      const html = customToolPanel.render(customRenderContext);
      setContentsHTML(html);
    } else if (hasCustomFrameworkComponent(customToolPanel)) {
      api.internalApi.createFrameworkComponent(element, customToolPanel.frameworkComponent, 'toolPanel');
    }
    return () => {
      if (hasCustomRenderFn(customToolPanel)) {
        customToolPanel.render(Object.assign({
          visible: false,
          phase: 'onDestroy',
          element
        }, api.internalApi.buildBaseContext()));
      } else if (hasCustomFrameworkComponent(customToolPanel) && !api.isDestroyed()) {
        api.internalApi.destroyFrameworkComponent(element, customToolPanel.frameworkComponent, 'toolPanel');
      }
    };
  }, []);
  return React.createElement(React.Fragment, null, React.createElement("div", {
    ref: contentsDivDomRef,
    id: contentsDivId,
    className: contentsDivId,
    dangerouslySetInnerHTML: {
      __html: contentsHTML
    },
    style: {
      width: '100%'
    }
  }), ((_a = customToolPanel.buttons) === null || _a === void 0 ? void 0 : _a.length) && React.createElement("div", {
    id: buttonsDivId,
    className: `ab-ToolPanel__custom-content__buttons ${buttonsDivId}`
  }, customToolPanel.buttons.map(button => {
    // TODO: variants of this mapping are present in several places (just search for api.internalApi.getStyleForButton() usages)
    // with the next opportunity we should abstract it
    const toolPanelContext = Object.assign(Object.assign({}, api.internalApi.buildBaseContext()), {
      toolPanelState: api.toolPanelApi.getToolPanelState(),
      customToolPanel: customToolPanel
    });
    const buttonIcon = api.internalApi.getIconForButton(button, toolPanelContext);
    let buttonStyle = api.internalApi.getStyleForButton(button, toolPanelContext);
    let buttonLabel = api.internalApi.getLabelForButton(button, toolPanelContext);
    let buttonTooltip = api.internalApi.getTooltipForButton(button, toolPanelContext);
    if (button.hidden && button.hidden(button, toolPanelContext)) {
      return null;
    }
    const disabled = button.disabled && button.disabled(button, toolPanelContext);
    const buttonVariant = buttonStyle && buttonStyle.variant ? buttonStyle.variant : 'text';
    const buttonTone = buttonStyle && buttonStyle.tone ? buttonStyle.tone : 'none';
    const uniqueKey = buttonLabel !== null && buttonLabel !== void 0 ? buttonLabel : createUuid();
    return React.createElement(SimpleButton, {
      key: uniqueKey,
      variant: buttonVariant,
      tone: buttonTone,
      className: `ab-ToolPanel__Home__${kebabCase(buttonLabel)} ${(buttonStyle === null || buttonStyle === void 0 ? void 0 : buttonStyle.className) || ''}`,
      tooltip: buttonTooltip,
      icon: buttonIcon,
      disabled: disabled,
      onClick: () => button.onClick(button, toolPanelContext),
      accessLevel: 'Full'
    }, buttonLabel);
  })));
};
const hasCustomRenderFn = customToolPanel => customToolPanel.render;
const hasCustomFrameworkComponent = customToolPanel => customToolPanel.frameworkComponent;