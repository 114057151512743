import * as CommentRedux from '../../Redux/ActionsReducers/CommentsRedux';
import { ApiBase } from './ApiBase';
import ObjectFactory from '../../Utilities/ObjectFactory';
import AdaptableHelper from '../../Utilities/Helpers/AdaptableHelper';
import { CommentsInternalApi } from '../Internal/CommentsInternalApi';
export class CommentsApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.internalApi = new CommentsInternalApi(adaptable);
  }
  getCommentThread(address) {
    return CommentRedux.GetCellCommentSelector(this.getAdaptableState().Comment, address);
  }
  getGridComments() {
    var _a;
    return (_a = this.getAdaptableState().Comment.CommentThreads) !== null && _a !== void 0 ? _a : [];
  }
  editComment(comment, address) {
    this.dispatchAction(CommentRedux.CommentsEdit({
      comment,
      cellAddress: address
    }));
  }
  deleteComment(comment, address) {
    this.dispatchAction(CommentRedux.CommentsDelete({
      commentUuid: comment.Uuid,
      cellAddress: address
    }));
  }
  addComment(commentText, address) {
    this.dispatchAction(CommentRedux.CommentsAdd({
      comment: commentText ? ObjectFactory.CreateAdaptableComment(commentText, this.getAdaptableApi()) : null,
      cellAddress: address
    }));
  }
  addCommentThread(commentThread) {
    if (!commentThread.Uuid) {
      AdaptableHelper.addUuidAndSource(commentThread);
    }
    this.dispatchAction(CommentRedux.CommentsCellAdd({
      commentThread: commentThread
    }));
  }
  deleteCommentThread(cellAddress) {
    this.dispatchAction(CommentRedux.CommentsCellDelete({
      cellAddress
    }));
  }
  setGridComments(cellComments) {
    this.dispatchAction(CommentRedux.CommentsSet({
      commentThread: cellComments
    }));
  }
  clearGridComments() {
    this.setGridComments([]);
  }
  hideCommentsPopup() {
    this.adaptable.api.internalApi.getCellPopupService().hidePopup();
  }
}