import * as React from 'react';
import { connect } from 'react-redux';
import * as BulkUpdateRedux from '../../Redux/ActionsReducers/BulkUpdateRedux';
import * as SystemRedux from '../../Redux/ActionsReducers/SystemRedux';
import { PanelWithImage } from '../Components/Panels/PanelWithImage';
import { AdaptablePopover } from '../AdaptablePopover';
import { StringExtensions } from '../../Utilities/Extensions/StringExtensions';
import { PreviewResultsPanel } from '../Components/PreviewResultsPanel';
import { PreviewHelper } from '../../Utilities/Helpers/PreviewHelper';
import { ColumnValueSelector } from '../Components/Selectors/ColumnValueSelector';
import { Flex } from 'rebass';
import SimpleButton from '../../components/SimpleButton';
import HelpBlock from '../../components/HelpBlock';
class BulkUpdatePopupComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowingError: false,
      errorText: '',
      useSelector: false
    };
  }
  componentDidMount() {
    this.props.onBulkUpdateValueChange('');
    this.props.onBulkUpdateCheckSelectedCells();
  }
  render() {
    let col = this.props.BulkUpdateValidationResult.Column;
    let hasDataTypeError = false;
    let dataTypeErrorMessage = '';
    if (col && StringExtensions.IsNotNullOrEmpty(this.props.BulkUpdateValue)) {
      // check that the update value is a number for a numeric column.  not issue for dates as we dont allow free text
      if (col.dataType == 'Number') {
        if (isNaN(Number(this.props.BulkUpdateValue))) {
          hasDataTypeError = true;
          dataTypeErrorMessage = 'This column only accepts numbers';
        }
      }
    }
    let globalValidationMessage = PreviewHelper.GetValidationMessage(this.props.PreviewInfo, this.props.BulkUpdateValue);
    let showPanel = this.props.PreviewInfo && StringExtensions.IsNotNullOrEmpty(this.props.BulkUpdateValue) && StringExtensions.IsNotNullOrEmpty(globalValidationMessage);
    let previewPanel = showPanel ? React.createElement(PreviewResultsPanel, {
      previewInfo: this.props.PreviewInfo,
      api: this.props.api,
      selectedColumn: col,
      showPanel: showPanel,
      showHeader: true
    }) : null;
    if (!col) {
      return null;
    }
    return React.createElement(PanelWithImage, {
      bodyProps: {
        padding: 2
      },
      flex: 1,
      glyphicon: this.props.moduleInfo.Glyph,
      header: this.props.moduleInfo.FriendlyName,
      infoLink: this.props.moduleInfo.HelpPage,
      infoLinkDisabled: !this.props.api.internalApi.isDocumentationLinksDisplayed(),
      variant: "primary"
    }, col.dataType == 'Date' ? React.createElement(React.Fragment, null, React.createElement(HelpBlock, {
      marginTop: 2,
      marginBottom: 2
    }, "Enter a date value. Alternatively, tick the checkbox and select from an existing column value."), React.createElement(Flex, {
      padding: 2,
      flexDirection: "row",
      alignItems: "center"
    }, React.createElement(Flex, {
      alignItems: "center",
      flexDirection: "row",
      flex: 1,
      marginRight: 2
    }, React.createElement(ColumnValueSelector, {
      selectedGridCells: this.props.SelectedGridCells,
      selectedColumnValue: this.props.BulkUpdateValue,
      selectedColumn: col,
      api: this.props.api,
      onColumnValueChange: values => this.onColumnValueSelectedChanged(values),
      allowNew: false,
      style: {
        width: '100%',
        maxWidth: 'inherit'
      }
    })), React.createElement(SimpleButton, {
      disabled: StringExtensions.IsNullOrEmpty(this.props.BulkUpdateValue) || this.props.PreviewInfo.previewValidationSummary.validationResult == 'All',
      onClick: () => {
        this.onApplyClick();
      },
      variant: "raised",
      tone: "accent"
    }, "Apply Bulk Update"))) : React.createElement(React.Fragment, null, React.createElement(HelpBlock, {
      marginTop: 2,
      marginBottom: 2
    }, "Select an existing Column value from the dropdown, or enter a new value"), React.createElement(Flex, {
      marginTop: 2,
      flexDirection: "row",
      alignItems: "center"
    }, React.createElement(Flex, {
      alignItems: "center",
      flexDirection: "row",
      flex: 1,
      marginRight: 2
    }, React.createElement(ColumnValueSelector, {
      selectedGridCells: this.props.SelectedGridCells,
      selectedColumnValue: this.props.BulkUpdateValue,
      selectedColumn: col,
      api: this.props.api,
      onColumnValueChange: columns => this.onColumnValueSelectedChanged(columns),
      style: {
        width: '100%',
        maxWidth: 'inherit'
      }
    })), React.createElement(SimpleButton, {
      disabled: StringExtensions.IsNullOrEmpty(this.props.BulkUpdateValue) || this.props.PreviewInfo.previewValidationSummary.validationResult == 'All' || hasDataTypeError,
      variant: "raised",
      tone: "accent",
      marginRight: 2,
      onClick: () => {
        this.onApplyClick();
      }
    }, "Apply Bulk Update"), ' ', hasDataTypeError && React.createElement(AdaptablePopover, {
      headerText: 'Update Error',
      bodyText: [dataTypeErrorMessage],
      MessageType: 'Error'
    }), StringExtensions.IsNotNullOrEmpty(this.props.BulkUpdateValue) && this.props.PreviewInfo.previewValidationSummary.validationResult != 'None' && React.createElement(AdaptablePopover, {
      headerText: 'Validation Error',
      bodyText: [globalValidationMessage],
      MessageType: 'Error'
    }))), previewPanel);
  }
  onColumnValueSelectedChanged(selectedColumnValue) {
    this.props.onBulkUpdateValueChange(selectedColumnValue);
  }
  onUseColumnValuesSelectorChanged(checked) {
    this.setState({
      useSelector: checked
    });
    this.props.onBulkUpdateValueChange('');
  }
  onBulkUpdateValueChange(event) {
    const e = event.target;
    this.props.onBulkUpdateValueChange(e.value);
  }
  onApplyClick() {
    this.onApplyBulkUpdate();
  }
  onApplyBulkUpdate() {
    this.props.onRunBulkUpdate();
  }
}
function mapStateToProps(state, ownProps) {
  var _a;
  return {
    BulkUpdateValue: state.System.BulkUpdateValue,
    PreviewInfo: state.System.BulkUpdatePreviewInfo,
    BulkUpdateValidationResult: state.System.BulkUpdateValidationResult,
    SelectedGridCells: (_a = state.Grid.SelectedCellInfo) === null || _a === void 0 ? void 0 : _a.gridCells
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onBulkUpdateValueChange: value => dispatch(SystemRedux.BulkUpdateChangeValue(value)),
    onBulkUpdateCheckSelectedCells: () => dispatch(SystemRedux.BulkUpdateCheckCellSelection()),
    onRunBulkUpdate: () => dispatch(BulkUpdateRedux.BulkUpdateComplete(false))
  };
}
export let BulkUpdatePopup = connect(mapStateToProps, mapDispatchToProps)(BulkUpdatePopupComponent);