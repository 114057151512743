import { __rest } from "tslib";
import * as React from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '../../../../components/Dialog';
import { SystemSettingsPanelSet } from '../../../../Redux/ActionsReducers/SystemRedux';
import { useAdaptable } from '../../../AdaptableContext';
import { getActionPanelSize, getMiddlePosition, getSettingsPanelSize } from '../Utilities';
const WindowDialog = props => {
  var _a, _b;
  const {
      onChange,
      style,
      baseClassName,
      className,
      isActionModule,
      settingsPanelOptionsKey,
      onHide
    } = props,
    dialogProps = __rest(props, ["onChange", "style", "baseClassName", "className", "isActionModule", "settingsPanelOptionsKey", "onHide"]);
  const adaptable = useAdaptable();
  const dispatch = useDispatch();
  const settingsPanelOptions = adaptable.adaptableOptions.settingsPanelOptions;
  let popupSettings = useSelector(state => {
    var _a, _b;
    return (_b = (_a = state === null || state === void 0 ? void 0 : state.System) === null || _a === void 0 ? void 0 : _a.SettingsPanel) === null || _b === void 0 ? void 0 : _b[settingsPanelOptionsKey];
  });
  const handleWindowSettings = settings => {
    dispatch(SystemSettingsPanelSet(settingsPanelOptionsKey, settings));
  };
  if (!popupSettings) {
    const size = isActionModule ? getActionPanelSize() : (_a = settingsPanelOptions.size) !== null && _a !== void 0 ? _a : getSettingsPanelSize();
    popupSettings = {
      size: size,
      position: (_b = settingsPanelOptions.position) !== null && _b !== void 0 ? _b : getMiddlePosition(size)
    };
  }
  const windowModalProps = {
    handleSelector: isActionModule ? `.${baseClassName} .ab-Panel__header` : '.ab-Adaptable-Popup__TopBar',
    minHeight: 460,
    minWidth: 400,
    size: popupSettings.size,
    position: popupSettings.position,
    onChange: handleWindowSettings
  };
  return React.createElement(Dialog, Object.assign({
    windowModal: true,
    fixed: false,
    windowModalProps: windowModalProps,
    style: Object.assign(Object.assign({}, props.style), {
      height: '100%'
    }),
    padding: 0,
    className: className,
    onDismiss: onHide,
    isOpen: true,
    showCloseButton: true
  }, dialogProps));
};
const PopupDialog = props => {
  const {
      style,
      className,
      onHide,
      children
    } = props,
    dialogProps = __rest(props, ["style", "className", "onHide", "children"]);
  return React.createElement(Dialog, Object.assign({
    fixed: false,
    style: props.style,
    padding: 0,
    height: '90vh',
    width: '90vw',
    className: className,
    onDismiss: onHide,
    isOpen: true,
    showCloseButton: true
  }, dialogProps), children);
};
export const AdaptablePopupDialog = props => {
  const {
    isActionModule,
    style,
    friendlyName,
    baseClassName,
    className,
    children,
    onHide,
    isWindowModal,
    modalContainer
  } = props;
  if (modalContainer) {
    let ref = null;
    if (typeof modalContainer === 'string') {
      ref = globalThis.document.querySelector(modalContainer);
    } else {
      ref = modalContainer;
    }
    return createPortal(React.createElement(Dialog, {
      "data-name": props.dataName,
      modal: false,
      fixed: false,
      onDismiss: onHide,
      className: className
    }, children), ref);
  }
  if (isWindowModal) {
    const settingsPanelOptionsKey = isActionModule ? `action-${friendlyName}` : 'settings';
    return React.createElement(WindowDialog, {
      dataName: props.dataName,
      settingsPanelOptionsKey: settingsPanelOptionsKey,
      isActionModule: isActionModule,
      onHide: onHide,
      style: style,
      baseClassName: baseClassName,
      className: className
    }, children);
  } else {
    const modalStyle = Object.assign(Object.assign({}, style), isActionModule ? {
      maxWidth: 800,
      maxHeight: 600
    } : {
      maxWidth: 1200,
      maxHeight: 1000
    });
    return React.createElement(PopupDialog, {
      "data-name": props.dataName,
      onHide: onHide,
      style: modalStyle,
      className: className
    }, children);
  }
};