export function divideBy100(value) {
  // the simple solution does not work
  // return value / 100;
  // needed because 4012.3456/100 = 40.123456000000004
  // but we dont want that to happen
  // this solution does not handle  -0 correctly
  // return parseFloat((value / 100).toFixed(12));
  if (isNaN(value) || !value) {
    return value;
  }
  const isNegative = value < 0;
  if (isNegative) {
    value = -value;
  }
  const valueAsStr = value.toPrecision();
  const separator = Number(1.1).toLocaleString()[1];
  let indexOfSeparator = valueAsStr.indexOf(separator);
  if (indexOfSeparator === -1) {
    indexOfSeparator = 0;
  }
  let [integer = '', decimal] = valueAsStr.split(separator);
  decimal = decimal || '';
  integer = integer.padStart(2, '0');
  const integerArr = [...integer];
  const num = integerArr.slice(0, integerArr.length - 2).join('') + separator + integerArr.slice(-2).join('') + decimal;
  return Number(num) * (isNegative ? -1 : 1);
}