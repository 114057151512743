import { clamp } from '../../../Utilities/Helpers/Helper';
export const getMiddlePosition = size => {
  const height = window.innerHeight;
  const width = window.innerWidth;
  return {
    x: width / 2 - size.width / 2,
    y: height / 2 - size.height / 2
  };
};
export const getActionPanelSize = () => {
  const height = window.innerHeight;
  const width = window.innerWidth;
  return {
    height: clamp(600, 0, height * 0.9),
    width: clamp(800, 0, width * 0.9)
  };
};
export const getSettingsPanelSize = () => {
  const height = window.innerHeight;
  const width = window.innerWidth;
  return {
    height: clamp(height * 0.9, 0, 800),
    width: clamp(width * 0.9, 0, 800)
  };
};
export const getWindowPopupSize = () => {
  return getSettingsPanelSize();
};