import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Flex } from 'rebass';
import { ExpressionEditor } from '../../components/ExpressionEditor';
import { NamedQueryContext } from '../../components/ExpressionEditor/NamedQueryContext';
import SimpleButton from '../../components/SimpleButton';
import * as NamedQueryRedux from '../../Redux/ActionsReducers/NamedQueryRedux';
import { PopupShowForm } from '../../Redux/ActionsReducers/PopupRedux';
import { GridFilterModuleId } from '../../Utilities/Constants/ModuleConstants';
import { IsNotNullOrEmpty, IsNullOrEmpty } from '../../Utilities/Extensions/StringExtensions';
import Helper from '../../Utilities/Helpers/Helper';
import { useAdaptable } from '../AdaptableContext';
import { useGridFilterOptionsForExpressionEditorProps } from './useGridFilterOptionsForExpressionEditor';
export const GridFilterExpressionEditor = props => {
  const dispatch = useDispatch();
  const {
    api
  } = useAdaptable();
  const [namedQuery, setNamedQuery] = React.useState(null);
  const [expression, setExpression] = React.useState(props.expression);
  const isExpressionValid = api.internalApi.getQueryLanguageService().validateBoolean(expression, GridFilterModuleId).isValid;
  const isExistingNamedQuery = Helper.objectExists(api.namedQueryApi.getNamedQueries().find(q => q.BooleanExpression == expression));
  const initialData = React.useMemo(() => api.internalApi.getQueryPreviewData(), []);
  const namedQueryValidationError = namedQuery ? api.namedQueryApi.isValidNamedQuery(namedQuery).message : '';
  const hasNamedQueryError = IsNotNullOrEmpty(namedQueryValidationError);
  const onRunQuery = expression => {
    api.gridFilterApi.setGridFilterExpression(expression);
  };
  const onAddNamedQuery = namedQuery => dispatch(NamedQueryRedux.NamedQueryAdd(namedQuery));
  const handleSaveQuery = () => {
    dispatch(PopupShowForm({
      Id: 'save_query_form',
      Form: {
        title: 'Save as Named Query',
        fields: [{
          name: 'name',
          label: 'Name',
          fieldType: 'text'
        }],
        buttons: [{
          label: 'Cancel',
          buttonStyle: {
            variant: 'text',
            tone: 'none'
          }
        }, {
          label: 'Save',
          buttonStyle: {
            variant: 'raised',
            tone: 'accent'
          },
          disabled: (button, context) => {
            if (context.formData.name === '') {
              return true;
            }
            const testQuery = {
              Name: context.formData.name,
              BooleanExpression: expression
            };
            const namedQueryValidationResult = api.namedQueryApi.isValidNamedQuery(testQuery);
            if (!namedQueryValidationResult.valid) {
              return true;
            }
          },
          onClick: (button, context) => {
            onAddNamedQuery({
              Name: context.formData.name,
              BooleanExpression: expression
            });
          }
        }]
      }
    }));
  };
  const expressionEditorProps = useGridFilterOptionsForExpressionEditorProps();
  const actionsDisabled = !isExpressionValid || IsNullOrEmpty(expression) || hasNamedQueryError || isExistingNamedQuery;
  return React.createElement(NamedQueryContext.Provider, {
    value: {
      namedQuery: namedQuery,
      setNamedQuery: newQuery => {
        setNamedQuery(newQuery);
      }
    }
  }, React.createElement(Flex, {
    height: "100%",
    flexDirection: "column"
  }, React.createElement(ExpressionEditor, Object.assign({}, expressionEditorProps, {
    type: 'boolean',
    module: GridFilterModuleId,
    value: expression,
    onChange: expression => setExpression(expression),
    initialData: initialData,
    columns: api.columnApi.getQueryableColumns(),
    fields: api.expressionApi.internalApi.getAvailableFields(),
    namedQueries: api.namedQueryApi.getNamedQueries(),
    api: api
  })), React.createElement(Flex, {
    flexDirection: "row",
    padding: 1,
    alignItems: "center"
  }, props.onDismiss && React.createElement(SimpleButton, {
    margin: 1,
    variant: "text",
    "data-name": "action-close",
    onClick: () => {
      props.onDismiss();
    }
  }, "CLOSE"), React.createElement(Box, {
    flex: 1
  }), React.createElement(SimpleButton, {
    disabled: actionsDisabled,
    onClick: handleSaveQuery,
    icon: "save"
  }, "Save Query"), React.createElement(SimpleButton, {
    variant: "raised",
    tone: "accent",
    "data-name": "action-run",
    margin: 1,
    icon: "play",
    onClick: () => {
      var _a;
      if (namedQuery) {
        onAddNamedQuery({
          Name: namedQuery.Name,
          BooleanExpression: expression
        });
      }
      onRunQuery(expression);
      (_a = props === null || props === void 0 ? void 0 : props.onDismiss) === null || _a === void 0 ? void 0 : _a.call(props);
    },
    disabled: actionsDisabled
  }, "Apply Grid Filter"))));
};