import * as React from 'react';
import { Flex } from 'rebass';
import Panel from '../../../components/Panel';
import { Tabs } from '../../../components/Tabs';
import ArrayExtensions from '../../../Utilities/Extensions/ArrayExtensions';
import Helper from '../../../Utilities/Helpers/Helper';
import { AdaptablePopover } from '../../AdaptablePopover';
import { AdaptableObjectCollection } from '../../Components/AdaptableObjectCollection';
import { AdaptableObjectRow } from '../../Components/AdaptableObjectRow';
import { PopupPanel } from '../../Components/Popups/AdaptablePopup/PopupPanel';
import { AdaptableObjectsSummary } from './AdaptableObjectsSummary';
import { ADAPTABLE_VERSION } from '../../../EnvVars';
const version = ADAPTABLE_VERSION;
export const GridInfoPopup = props => {
  const CreateGridSummaries = colItems => {
    var _a;
    const returnRows = [];
    const calcColumns = props.api.calculatedColumnApi.getCalculatedColumns().map(c => c.ColumnId);
    const actionColumns = props.api.actionColumnApi.getActionColumns().map(ac => ac.columnId);
    const freeTextColumns = props.api.freeTextColumnApi.getFreeTextColumns().map(c => c.ColumnId);
    const columnFilterDescription = props.api.columnFilterApi.internalApi.columnFiltersToString(props.api.columnFilterApi.getColumnFilters());
    const gridFilterExpression = props.api.gridFilterApi.getCurrentGridFilterExpression();
    const sorts = ArrayExtensions.IsNotNullOrEmpty(props.api.gridApi.getColumnSorts()) ? props.api.gridApi.getColumnSorts().map(gs => {
      return props.api.columnApi.getFriendlyNameForColumnId(gs.ColumnId) + ': ' + gs.SortOrder;
    }) : null;
    const selectedRowInfo = props.api.gridApi.getSelectedRowInfo();
    if ((_a = props.api.optionsApi.getUserInterfaceOptions()) === null || _a === void 0 ? void 0 : _a.showAdapTableVersion) {
      returnRows.push(createReadOnlyColItem(colItems, 'AdapTable Version', version));
    }
    returnRows.push(createReadOnlyColItem(colItems, 'Sorted Columns', ArrayExtensions.IsNotNullOrEmpty(sorts) ? sorts.join('; ') : 'None'));
    returnRows.push(createReadOnlyColItem(colItems, 'Column Filters', columnFilterDescription));
    returnRows.push(createReadOnlyColItem(colItems, 'Grid Filter', gridFilterExpression));
    returnRows.push(createReadOnlyColItem(colItems, 'All Rows', props.api.gridApi.getRowCount()));
    returnRows.push(createReadOnlyColItem(colItems, 'Selected Rows', selectedRowInfo === null || selectedRowInfo === void 0 ? void 0 : selectedRowInfo.gridRows.length));
    returnRows.push(createReadOnlyColItem(colItems, 'Visible Selected Rows', selectedRowInfo === null || selectedRowInfo === void 0 ? void 0 : selectedRowInfo.gridRows.filter(gr => {
      var _a;
      return ((_a = gr.rowNode) === null || _a === void 0 ? void 0 : _a.displayed) == true;
    }).length));
    returnRows.push(createReadOnlyColItem(colItems, 'All Columns', props.api.gridApi.getColumnCount()));
    returnRows.push(createReadOnlyColItem(colItems, 'Visible Columns', props.api.gridApi.getVisibleColumnCount()));
    returnRows.push(createReadOnlyColItem(colItems, 'Calculated Columns', ArrayExtensions.IsNotNullOrEmpty(calcColumns) ? calcColumns : 'None'));
    returnRows.push(createReadOnlyColItem(colItems, 'Free Text Columns', ArrayExtensions.IsNotNullOrEmpty(freeTextColumns) ? freeTextColumns : 'None'));
    returnRows.push(createReadOnlyColItem(colItems, 'Action Columns', ArrayExtensions.IsNotNullOrEmpty(actionColumns) ? actionColumns : 'None'));
    return returnRows;
  };
  const createReadOnlyColItem = (colItems, item1, item2, item3) => {
    let rowColItems = Helper.cloneObject(colItems);
    rowColItems[0].Content = item1;
    rowColItems[1].Content = item2;
    if (item3) {
      let infoButton = React.createElement(AdaptablePopover, {
        headerText: null,
        bodyText: [item3]
      });
      rowColItems[2].Content = infoButton;
    }
    return rowColItems;
  };
  let propValueColItems = [{
    Content: 'Property',
    Size: 5
  }, {
    Content: 'Value',
    Size: 7
  }];
  let gridSummaries = CreateGridSummaries(propValueColItems).map((x, index) => {
    return React.createElement(AdaptableObjectRow, {
      key: index,
      colItems: x
    });
  });
  const baseClassName = 'ab-GridInfo';
  return React.createElement(PopupPanel, {
    className: baseClassName,
    headerText: 'Grid Info',
    glyphicon: 'info'
  }, React.createElement(Flex, {
    flexDirection: "column",
    flex: 1,
    height: "100%"
  }, React.createElement(Panel, {
    flex: 1
  }, React.createElement(Tabs, null, React.createElement(Tabs.Tab, null, "Grid Summary"), React.createElement(Tabs.Tab, null, "Config"), React.createElement(Tabs.Content, null, React.createElement(AdaptableObjectCollection, {
    margin: 2,
    colItems: propValueColItems,
    items: gridSummaries
  })), React.createElement(Tabs.Content, null, React.createElement(AdaptableObjectsSummary, null))))));
};