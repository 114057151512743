import * as React from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_SETTINGS_PANEL_NAVIGATION_ITEMS } from '../../../../Utilities/Defaults/DefaultSettingsPanel';
import { useAdaptable } from '../../../AdaptableContext';
import * as PopupRedux from '../../../../Redux/ActionsReducers/PopupRedux';
import { STANDALONE_MODULE_POPUPS } from '../../../../Utilities/Constants/GeneralConstants';
export const useMenuItems = () => {
  const adaptable = useAdaptable();
  const settingsPanelOptions = adaptable.adaptableOptions.settingsPanelOptions;
  const allMenuItems = useSelector(state => {
    var _a;
    return (_a = state === null || state === void 0 ? void 0 : state.Grid) === null || _a === void 0 ? void 0 : _a.SettingPanelModuleMenuItems;
  });
  return React.useMemo(() => {
    var _a;
    let navigationItems = (_a = settingsPanelOptions === null || settingsPanelOptions === void 0 ? void 0 : settingsPanelOptions.navigation) === null || _a === void 0 ? void 0 : _a.items;
    if (!navigationItems) {
      navigationItems = [...DEFAULT_SETTINGS_PANEL_NAVIGATION_ITEMS];
      if (settingsPanelOptions.customSettingsPanels) {
        navigationItems.push(...settingsPanelOptions.customSettingsPanels.map(panel => panel.name));
      }
    }
    const visibleItems = allMenuItems.filter(item => item.module !== 'General' && item.module !== 'CustomSettingsPanel' && item.module !== 'Group' && !STANDALONE_MODULE_POPUPS.includes(item.module) && item.isVisible);
    let navItems = navigationItems.map(moduleName => {
      var _a, _b;
      if (moduleName === '-') {
        return moduleName;
      }
      const customSettingsPanel = (_b = (_a = settingsPanelOptions === null || settingsPanelOptions === void 0 ? void 0 : settingsPanelOptions.customSettingsPanels) === null || _a === void 0 ? void 0 : _a.find) === null || _b === void 0 ? void 0 : _b.call(_a, panel => panel.name === moduleName);
      let customPanelMenuItem = null;
      if (customSettingsPanel) {
        // @ts-ignore
        customPanelMenuItem = {
          module: 'CustomSettingsPanel',
          label: customSettingsPanel.name,
          isVisible: true,
          reduxAction: PopupRedux.PopupShowScreen(null, customSettingsPanel.name)
        };
      }
      return customPanelMenuItem || visibleItems.find(item => item.module === moduleName);
    }).filter(Boolean);
    // remove sequential '-' items
    navItems = navItems.filter((item, index) => {
      if (item === '-' && navItems[index - 1] === '-') {
        return false;
      }
      return true;
    });
    return navItems;
  }, [allMenuItems]);
};