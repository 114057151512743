import { ApiBase } from '../Implementation/ApiBase';
export class PredicateInternalApi extends ApiBase {
  /**
   * Returns true if the predicate has a dropdown.
   *
   * @param predicate
   */
  hasPredicateValues(predicate) {
    return (predicate === null || predicate === void 0 ? void 0 : predicate.PredicateId) === 'Values' || (predicate === null || predicate === void 0 ? void 0 : predicate.PredicateId) === 'ExcludeValues';
  }
  /**
   * Get all Filter Predicate Definitions - System and Custom
   */
  getFilterPredicateDefs(scope) {
    var _a;
    return this.mergeSystemAndCustomPredicates((_a = this.getSystemFilterPredicateIds(scope)) === null || _a === void 0 ? void 0 : _a.map(predicateId => this.getPredicateApi().getPredicateDefById(predicateId)), this.getPredicateApi().getCustomPredicateDefs()).filter(predicateDef => predicateDef.moduleScope.includes('columnFilter'));
  }
  getSystemFilterPredicateIds(scope) {
    const systemFilterPredicates = this.getPredicateOptions().systemFilterPredicates;
    if (typeof systemFilterPredicates === 'function') {
      const systemPredicateContext = Object.assign(Object.assign({}, this.getAdaptableApi().internalApi.buildBaseContext()), {
        systemPredicateDefs: this.getPredicateApi().getSystemPredicateDefsByModuleScope('columnFilter'),
        moduleScope: 'columnFilter',
        columnScope: scope
      });
      return systemFilterPredicates(systemPredicateContext);
    }
    return systemFilterPredicates;
  }
  /**
   * Get all Alert Predicate Definitions - System and Custom
   */
  getAlertPredicateDefs(scope) {
    var _a;
    return this.mergeSystemAndCustomPredicates((_a = this.getSystemAlertPredicateIds(scope)) === null || _a === void 0 ? void 0 : _a.map(predicateId => this.getPredicateApi().getPredicateDefById(predicateId)), this.getPredicateApi().getCustomPredicateDefs()).filter(predicateDef => predicateDef.moduleScope.includes('alert'));
  }
  getSystemAlertPredicateIds(scope) {
    const systemAlertPredicates = this.getPredicateOptions().systemAlertPredicates;
    if (typeof systemAlertPredicates === 'function') {
      const systemPredicateContext = Object.assign(Object.assign({}, this.getAdaptableApi().internalApi.buildBaseContext()), {
        systemPredicateDefs: this.getPredicateApi().getSystemPredicateDefsByModuleScope('alert'),
        moduleScope: 'alert',
        columnScope: scope
      });
      return systemAlertPredicates(systemPredicateContext);
    }
    return systemAlertPredicates;
  }
  getSystemBadgeStylePredicateIds(scope) {
    const systemBadgeStylePredicates = this.getPredicateOptions().systemBadgeStylePredicates;
    if (typeof systemBadgeStylePredicates === 'function') {
      const systemPredicateContext = Object.assign(Object.assign({}, this.getAdaptableApi().internalApi.buildBaseContext()), {
        systemPredicateDefs: this.getPredicateApi().getSystemPredicateDefsByModuleScope('badgeStyle'),
        moduleScope: 'badgeStyle',
        columnScope: scope
      });
      return systemBadgeStylePredicates(systemPredicateContext);
    }
    return systemBadgeStylePredicates;
  }
  /**
   * Get all Format Column Predicate Definitions - System and Custom
   */
  getFormatColumnPredicateDefs(scope) {
    var _a;
    return this.mergeSystemAndCustomPredicates((_a = this.getSystemFormatColumnPredicateIds(scope)) === null || _a === void 0 ? void 0 : _a.map(predicateId => this.getPredicateApi().getPredicateDefById(predicateId)), this.getPredicateApi().getCustomPredicateDefs()).filter(predicateDef => predicateDef.moduleScope.includes('formatColumn'));
  }
  getSystemFormatColumnPredicateIds(scope) {
    const systemFormatColumnPredicates = this.getPredicateOptions().systemFormatColumnPredicates;
    if (typeof systemFormatColumnPredicates === 'function') {
      const systemPredicateContext = Object.assign(Object.assign({}, this.getAdaptableApi().internalApi.buildBaseContext()), {
        systemPredicateDefs: this.getPredicateApi().getSystemPredicateDefsByModuleScope('formatColumn'),
        moduleScope: 'formatColumn',
        columnScope: scope
      });
      return systemFormatColumnPredicates(systemPredicateContext);
    }
    return systemFormatColumnPredicates;
  }
  /**
   * Get all Flashing Cell Predicate Definitions - System and Custom
   */
  getFlashingCellPredicateDefs(scope) {
    var _a;
    return this.mergeSystemAndCustomPredicates((_a = this.getSystemFlashingCellPredicateIds(scope)) === null || _a === void 0 ? void 0 : _a.map(predicateId => this.getPredicateApi().getPredicateDefById(predicateId)), this.getPredicateApi().getCustomPredicateDefs()).filter(predicateDef => predicateDef.moduleScope.includes('flashingcell'));
  }
  getSystemFlashingCellPredicateIds(scope) {
    const systemFlashingCellPredicates = this.getPredicateOptions().systemFlashingCellPredicates;
    if (typeof systemFlashingCellPredicates === 'function') {
      const systemPredicateContext = Object.assign({
        systemPredicateDefs: this.getPredicateApi().getSystemPredicateDefsByModuleScope('flashingcell'),
        moduleScope: 'flashingcell',
        columnScope: scope
      }, this.getAdaptableApi().internalApi.buildBaseContext());
      return systemFlashingCellPredicates(systemPredicateContext);
    }
    return systemFlashingCellPredicates;
  }
  /**
   * Gets the correct Equality-type System Predicate for a particular DataType
   * @param dataType DataType of Column
   */
  getEqualityPredicateForDataType(dataType) {
    let predicateId;
    switch (dataType) {
      case 'Number':
        predicateId = 'Equals';
        break;
      case 'Date':
        predicateId = 'On';
        break;
      case 'String':
        predicateId = 'Is';
        break;
      default:
        predicateId = 'Equals';
        break;
    }
    return predicateId;
  }
  /**
   * Get all Badge Style Predicate Definitions - System and Custom
   */
  getBadgeStylePredicateDefs(scope) {
    var _a;
    return this.mergeSystemAndCustomPredicates((_a = this.getSystemBadgeStylePredicateIds(scope)) === null || _a === void 0 ? void 0 : _a.map(predicateId => this.getPredicateApi().getPredicateDefById(predicateId)), this.getPredicateApi().getCustomPredicateDefs()).filter(predicateDef => predicateDef.moduleScope.includes('badgeStyle'));
  }
  /**
   * Merges System and Custom Predicate Definitions but ensures that Custom ones take precedence
   * (i.e. if there is a Custom Predicate with the same Id as a System one, the Custom one is used)
   */
  mergeSystemAndCustomPredicates(systemDefs, customDefs) {
    const systemDefsToUse = systemDefs.map(systemDef => {
      const customDef = customDefs.find(customDef => customDef.id === systemDef.id);
      return customDef ? customDef : systemDef;
    });
    const customDefsToUse = customDefs.filter(customDef => !systemDefsToUse.find(systemDef => systemDef.id === customDef.id));
    return [...systemDefsToUse, ...customDefsToUse];
  }
}