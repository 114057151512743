import * as React from 'react';
import SimpleButton from '../../../components/SimpleButton';
export const ButtonEdit = props => {
  var _a;
  return React.createElement(SimpleButton, Object.assign({
    "data-name": "edit",
    tooltip: "Edit",
    variant: "text",
    icon: "edit",
    iconSize: (_a = props.iconSize) !== null && _a !== void 0 ? _a : 24
  }, props));
};