import { isGridDataChangedInfo } from './Interface/IAlertService';
import Emitter from '../Emitter';
import { combineLatest, of } from 'rxjs';
import { AlertModuleId } from '../Constants/ModuleConstants';
export class AlertService {
  constructor(adaptableApi) {
    this.adaptableApi = adaptableApi;
    this.reactiveAlertsMap = new Map();
    this.onReactiveAlertTriggered = callback => {
      return this.emitter.on('ReactiveAlertTriggered', callback);
    };
    this.emitter = new Emitter();
  }
  destroy() {
    this.emitter.destroy();
    this.emitter = null;
    this.reactiveAlertsMap.forEach(alertsSubscriptionInfo => alertsSubscriptionInfo.subscription.unsubscribe());
    this.reactiveAlertsMap.clear();
    this.reactiveAlertsMap = null;
  }
  createReactiveAlert(alertDefinition) {
    // if there is already a reactive alert for this definition, delete it (possible in case of editing definitions),
    this.deleteReactiveAlert(alertDefinition);
    if (!alertDefinition.Rule.ObservableExpression) {
      return;
    }
    const isValidExpression = this.adaptableApi.expressionApi.isValidObservableExpression(alertDefinition.Rule.ObservableExpression, AlertModuleId, `Invalid Alert rule with observable expression `);
    if (!isValidExpression) {
      this.adaptableApi.logWarn(`Could NOT create reactive alert due to invalid Rule definition!`, alertDefinition.Rule);
      return;
    }
    const reactiveExpressionObservable = this.evaluateReactiveExpression(alertDefinition.Rule);
    const reactiveAlertSubscription = combineLatest([of(alertDefinition), reactiveExpressionObservable]).subscribe(([alertDefinition, changeLogEntry]) => {
      const reactiveAlertInfo = isGridDataChangedInfo(changeLogEntry) ? {
        alertDefinition,
        type: 'gridDataChangedAlert',
        gridChangeLogEntry: changeLogEntry
      } : {
        alertDefinition,
        type: 'cellDataChangedAlert',
        cellChangeLogEntry: changeLogEntry
      };
      this.emitter.emitSync('ReactiveAlertTriggered', reactiveAlertInfo);
    });
    this.reactiveAlertsMap.set(alertDefinition.Uuid, {
      definition: alertDefinition,
      subscription: reactiveAlertSubscription
    });
  }
  deleteReactiveAlert(alertDefinition) {
    if (this.reactiveAlertsMap.has(alertDefinition.Uuid)) {
      const obsoleteReactiveAlert = this.reactiveAlertsMap.get(alertDefinition.Uuid);
      obsoleteReactiveAlert === null || obsoleteReactiveAlert === void 0 ? void 0 : obsoleteReactiveAlert.subscription.unsubscribe();
      this.reactiveAlertsMap.delete(alertDefinition.Uuid);
    }
  }
  getReactiveActiveAlerts() {
    return [...this.reactiveAlertsMap.values()].map(item => item.definition);
  }
  isReactiveAlertActive(alertDefinition) {
    return this.reactiveAlertsMap.has(alertDefinition.Uuid);
  }
  evaluateReactiveExpression(rule) {
    return this.adaptableApi.internalApi.getQueryLanguageService().evaluateObservableExpression(rule.ObservableExpression, AlertModuleId);
  }
}