import * as React from 'react';
import { Flex } from 'rebass';
import join from '../utils/join';
export const ToggleButton = ({
  checked,
  onChange,
  disabled,
  className,
  children,
  style = {},
  'data-name': dataName
}) => {
  const handleChange = event => {
    if (disabled) {
      return;
    }
    onChange(Boolean(event.target.checked));
  };
  const baseClassName = 'ab-ToggleButton';
  const preparedClassName = join(className, baseClassName, checked && `${baseClassName}--checked`, disabled && `${baseClassName}--disabled`);
  return React.createElement("label", {
    "data-name": dataName,
    style: style,
    className: preparedClassName
  }, React.createElement("input", {
    type: "checkbox",
    disabled: disabled,
    checked: Boolean(checked),
    className: `${baseClassName}__input`,
    onChange: handleChange
  }), React.createElement(Flex, {
    alignItems: "center",
    justifyContent: checked ? 'start' : 'end',
    className: `${baseClassName}__slider`
  }, React.createElement("div", {
    className: `${baseClassName}__text`
  }, children)));
};