import * as React from 'react';
import { Box, Flex } from 'rebass';
import SimpleButton from '../../components/SimpleButton';
import { Tag } from '../../components/Tag';
import { useAdaptable } from '../AdaptableContext';
import { AdaptableObjectListItem } from '../Components/AdaptableObjectList/AdaptableObjectList';
export const SharedEntityTypeItemView = props => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const adaptable = useAdaptable();
  const moduleName = props.data.Module;
  const module = adaptable.ModuleService.getModuleById(moduleName);
  const sharedObject = props.data.Entity;
  const item = module === null || module === void 0 ? void 0 : module.toView(sharedObject);
  return React.createElement(React.Fragment, null, React.createElement(Flex, {
    mb: 2,
    mt: 2
  }, React.createElement(Flex, {
    mr: 2,
    alignItems: "center"
  }, React.createElement(Tag, {
    style: {
      margin: 0
    }
  }, props.data.Module)), item ? isExpanded ? React.createElement(SimpleButton, {
    "data-name": "shared-entity-collapse-button",
    onClick: () => setIsExpanded(false),
    icon: "arrow-up"
  }, "Collapse") : React.createElement(SimpleButton, {
    "data-name": "shared-entity-expand-button",
    onClick: () => setIsExpanded(true),
    icon: "arrow-down"
  }, "Expand") : null), isExpanded && item && React.createElement(Box, {
    as: "ul",
    padding: 0,
    className: "ab-Shared-Entity-Shared-Object"
  }, React.createElement(AdaptableObjectListItem, {
    hideControls: true,
    data: item,
    module: module
  })));
};
export const getSharedEntityActiveStatusObjectView = isDependency => props => {
  var _a, _b;
  const adaptable = useAdaptable();
  const activeEntries = adaptable.api.internalApi.getState().TeamSharing.ActiveSharedEntityMap;
  const sharedEntity = props.data;
  const isAdaptableObjectPresentInLocalState = !!adaptable.api.internalApi.getModuleService().getModuleById('TeamSharing').isAdaptableObjectPresentInLocalState(sharedEntity);
  const isUpToDateAndActive = isAdaptableObjectPresentInLocalState && sharedEntity.Type === 'Active' && ((_a = activeEntries[sharedEntity.Entity.Uuid]) === null || _a === void 0 ? void 0 : _a.Revision) === sharedEntity.Revision;
  const staleActiveEntities = adaptable.api.internalApi.getTeamSharingService().getStaleActiveSharedEntities();
  const isStaleAndActive = isAdaptableObjectPresentInLocalState && !!staleActiveEntities[sharedEntity.Uuid];
  let activeInfo = null;
  if (isAdaptableObjectPresentInLocalState) {
    const activeRevision = (_b = activeEntries === null || activeEntries === void 0 ? void 0 : activeEntries[sharedEntity.Entity.Uuid]) === null || _b === void 0 ? void 0 : _b.Revision;
    if (sharedEntity.Type === 'Active') {
      const newRevision = sharedEntity.Revision;
      let statusInfo = null;
      if (isUpToDateAndActive) {
        statusInfo = React.createElement(Box, {
          ml: 1,
          style: {
            color: 'var(--ab-color-success)'
          }
        }, React.createElement("b", null, "up-to-date"));
      }
      if (isStaleAndActive) {
        statusInfo = React.createElement(Box, {
          ml: 1,
          style: {
            color: 'var(--ab-color-warn)'
          }
        }, ` (Rev. ${newRevision} available)`);
      }
      activeInfo = activeRevision && React.createElement(React.Fragment, null, activeRevision && `Imported Rev. ${activeRevision} ${isStaleAndActive ? 'is stale' : ''} `, ' ', statusInfo);
    } else {
      // snapshot
      activeInfo = activeRevision && React.createElement(React.Fragment, null, `Imported Rev. ${activeRevision}`);
    }
  }
  const sharedValue = `by ${sharedEntity.UserName} at ${new Date(sharedEntity.Timestamp).toLocaleString()}`;
  return React.createElement(React.Fragment, null, !isDependency && React.createElement(Tag, null, sharedValue), activeInfo && React.createElement(Tag, null, activeInfo));
};
export const getSharedEntityStaleDepsItemView = staleDependencies => () => {
  let staleDepsString = 'Shared entity has';
  if ((staleDependencies === null || staleDependencies === void 0 ? void 0 : staleDependencies.length) === 1) {
    staleDepsString += ` one (${staleDependencies[0].Module}) stale dependency`;
  } else {
    staleDepsString += ` ${staleDependencies.length} stale dependencies`;
  }
  return React.createElement(Tag, null, React.createElement(Box, {
    ml: 1,
    style: {
      color: 'var(--ab-color-warn)'
    }
  }, staleDepsString));
};