import { AdaptableModuleBase } from './AdaptableModuleBase';
import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
export class GridInfoModule extends AdaptableModuleBase {
  constructor(api) {
    super(ModuleConstants.GridInfoModuleId, ModuleConstants.GridInfoFriendlyName, 'grid-info', 'GridInfoPopup', 'Display information about the Grid, the Options selected and all columns', api);
  }
  getViewAccessLevel() {
    return 'Full';
  }
  createColumnMenuItems(column) {
    if (this.isModuleAvailable()) {
      return [this.createMainMenuItemShowPopup({
        Name: 'grid-info-show',
        Label: 'Grid Info',
        ComponentName: this.moduleInfo.Popup,
        Icon: this.moduleInfo.Glyph
      })];
    }
  }
  createContextMenuItems(menuContext) {
    if (this.isModuleAvailable()) {
      return [this.createMainMenuItemShowPopup({
        Name: 'grid-info-show',
        Label: 'Grid Info',
        ComponentName: this.moduleInfo.Popup,
        Icon: this.moduleInfo.Glyph
      })];
    }
  }
}