import { ExpressionEvaluationError } from './ExpressionEvaluationError';
export function evaluateNode(node, context) {
  if (typeof node === 'boolean' || typeof node === 'number' || typeof node === 'string' ||
  // handles NULL literal
  node == null) {
    return node;
  }
  if (Array.isArray(node)) {
    return node.map(n => evaluateNode(n, context));
  }
  const functionDef = context.functions[node.type];
  if (functionDef) {
    if (functionDef.hasEagerEvaluation) {
      return functionDef.handler(node.args, context);
    }
    const args = node.args.map(n => evaluateNode(n, context));
    return functionDef.handler(args, context);
  } else {
    throw new ExpressionEvaluationError('', `Function is not available in this context: "${node.type}"`);
  }
}