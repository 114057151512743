import * as React from 'react';
import { useSelector } from 'react-redux';
import { Box, Flex } from 'rebass';
import FormLayout, { FormRow } from '../../components/FormLayout';
import { DataSource, InfiniteTable } from '../../components/InfiniteTable';
import Panel from '../../components/Panel';
import { Tabs } from '../../components/Tabs';
import { useAdaptable } from '../AdaptableContext';
import { AdaptableObjectList } from '../Components/AdaptableObjectList';
import { ButtonNew } from '../Components/Buttons/ButtonNew';
import { ColumnSelector } from '../Components/Selectors/ColumnSelector';
const BASE_CLASS_NAME = 'ab-AdaptableColumns';
// TODOS:
// - use column from props as initial value
// - table summary
// - access level for edit/new buttons
const MODULES_WITH_COLUMN = ['Alert', 'CalculatedColumn', 'CustomSort', 'ColumnFilter', 'FormatColumn', 'FlashingCell', 'PlusMinus', 'Shortcut', 'StyledColumn' // Scope
];
const MODULES_WITH_SCOPE = ['Alert', 'FormatColumn', 'PlusMinus', 'Shortcut', 'StyledColumn'];
// These modules are shown only if they have objects defiend
const MODULES_WITH_VIEW_ONLY = ['CalculatedColumn', 'ColumnFilter'];
const MODULES_WITHOUT_NEW = ['CalculatedColumn', 'ColumnFilter'];
const ColumnDefPreview = props => {
  const keyValuePairs = [{
    Key: 'Column Id',
    Value: props.column ? props.column.columnId : null
  }, {
    Key: 'Header',
    Value: props.column ? props.column.friendlyName : null
  }, {
    Key: 'DataType',
    Value: props.column ? props.column.dataType : null
  }, {
    Key: 'Groupable',
    Value: props.column ? props.column.groupable : null
  }, {
    Key: 'Aggregatable',
    Value: props.column ? props.column.aggregatable : null
  }, {
    Key: 'Filterable',
    Value: props.column ? props.column.filterable : null
  }, {
    Key: 'Hideable',
    Value: props.column ? props.column.hideable : null
  }, {
    Key: 'Editable',
    Value: props.column ? !props.column.IsReadOnly : null
  }, {
    Key: 'Moveable',
    Value: props.column ? props.column.moveable : null
  }, {
    Key: 'Pivotable',
    Value: props.column ? props.column.pivotable : null
  }, {
    Key: 'Queryable',
    Value: props.column ? props.column.queryable : null
  }, {
    Key: 'Sortable',
    Value: props.column ? props.column.sortable : null
  }, {
    Key: 'Visible',
    Value: props.column ? props.column.visible : null
  }, {
    Key: 'Grouped',
    Value: props.column ? props.column.isGrouped : null
  }].map(keyValuePair => {
    return {
      Key: keyValuePair.Key,
      Value: keyValuePair.Value === true ? 'Yes' : keyValuePair.Value
    };
  }).filter(x => x.Value);
  const columnsMap = {
    Key: {
      field: 'Key',
      header: 'Property',
      defaultFlex: 1
    },
    Value: {
      field: 'Value',
      header: 'Value',
      defaultFlex: 3
    }
  };
  return React.createElement(DataSource, {
    data: keyValuePairs,
    primaryKey: "Key"
  }, React.createElement(InfiniteTable, {
    domProps: {
      style: {
        height: '460px',
        width: '100%'
      }
    },
    columns: columnsMap
  }));
};
const ModuleView = props => {
  var _a, _b, _c, _d;
  const adaptable = useAdaptable();
  const module = adaptable.ModuleService.getModuleById(props.moduleName);
  if (!module || !module.isModuleAvailable()) {
    return null;
  }
  const moduleInfo = module.moduleInfo;
  const [isWizardOpen, setIsWizardOpen] = React.useState(false);
  const EditWizard = (_c = (_b = (_a = module === null || module === void 0 ? void 0 : module.getViewProperties) === null || _a === void 0 ? void 0 : _a.call(module)) === null || _b === void 0 ? void 0 : _b.getEditWizard) === null || _c === void 0 ? void 0 : _c.call(_b);
  const column = adaptable.api.columnApi.getColumnWithColumnId(props.selectedColumnId);
  const allItems = (_d = module === null || module === void 0 ? void 0 : module.toViewAll) === null || _d === void 0 ? void 0 : _d.call(module);
  if (MODULES_WITH_VIEW_ONLY.includes(props.moduleName) && !(allItems === null || allItems === void 0 ? void 0 : allItems.length)) {
    return React.createElement(React.Fragment, null);
  }
  const itemsInScope = allItems.filter(item => {
    var _a;
    if ('Scope' in item.abObject) {
      const abColumn = adaptable.api.columnApi.getColumnWithColumnId(props.selectedColumnId);
      return adaptable.api.columnScopeApi.isColumnInScope(abColumn, item.abObject.Scope);
    }
    if ('ColumnId' in item.abObject) {
      return ((_a = item.abObject) === null || _a === void 0 ? void 0 : _a.ColumnId) === props.selectedColumnId;
    }
    return false;
  });
  if (!itemsInScope) {
    return null;
  }
  const handleWizardClose = React.useCallback(() => {
    setIsWizardOpen(false);
  }, []);
  // filter out items with scope
  return React.createElement(Box, {
    "data-name": module.moduleInfo.ModuleName,
    className: `${BASE_CLASS_NAME}__module-list`,
    key: module.moduleInfo.ModuleName,
    mb: 4
  }, React.createElement(Flex, null, React.createElement("b", {
    className: `${BASE_CLASS_NAME}__header`
  }, module.moduleInfo.FriendlyName), React.createElement(Box, {
    flex: 1
  }), !MODULES_WITHOUT_NEW.includes(props.moduleName) && React.createElement(ButtonNew, {
    onClick: () => setIsWizardOpen(true)
  }, "New")), (itemsInScope === null || itemsInScope === void 0 ? void 0 : itemsInScope.length) ? React.createElement(AdaptableObjectList, {
    items: itemsInScope,
    module: module
  }) : null, isWizardOpen && EditWizard && React.createElement(EditWizard, {
    popupParams: {
      column,
      action: 'New',
      source: 'ModuleButton'
    },
    moduleInfo: moduleInfo,
    data: null,
    configEntities: null,
    onCloseWizard: handleWizardClose,
    onFinishWizard: handleWizardClose
  }));
};
export const ColumnInfo = props => {
  const state = useSelector(state => state);
  const adaptable = useAdaptable();
  const [selectedColumnId, setSelectedColumnId] = React.useState(props.selectedColumnId);
  const column = adaptable.api.columnApi.getColumnWithColumnId(selectedColumnId);
  return React.createElement(Panel, {
    flex: 1
  }, React.createElement(Box, {
    mb: 3
  }, React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Select Column"
  }, React.createElement(ColumnSelector, {
    value: selectedColumnId,
    onChange: columnId => setSelectedColumnId(columnId)
  })))), selectedColumnId && React.createElement(Tabs, {
    mb: 3
  }, React.createElement(Tabs.Tab, null, "Config"), React.createElement(Tabs.Tab, null, "Column Summary"), React.createElement(Tabs.Content, null, selectedColumnId && React.createElement(Box, {
    className: BASE_CLASS_NAME
  }, MODULES_WITH_COLUMN.map(moduleName => {
    return React.createElement(ModuleView, {
      key: moduleName,
      moduleName: moduleName,
      selectedColumnId: selectedColumnId
    });
  }))), React.createElement(Tabs.Content, null, React.createElement(Flex, {
    flex: 1
  }, selectedColumnId && React.createElement(ColumnDefPreview, {
    column: column
  })))));
};