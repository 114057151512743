import { debug as debugFactory } from '@infinite-table/infinite-react';
const performanceTime = previousTime => {
  const now = performance.now();
  return Math.round(previousTime ? now - previousTime : now);
};
export class AdaptableLogger {
  // use static loggers whenever access to AdaptableLogger instance is not feasible
  static consoleErrorBase(message, ...optionalParams) {
    if (optionalParams === null || optionalParams === void 0 ? void 0 : optionalParams.length) {
      console.error(message, optionalParams);
    } else {
      console.error(message);
    }
  }
  static consoleWarnBase(message, ...optionalParams) {
    if (optionalParams === null || optionalParams === void 0 ? void 0 : optionalParams.length) {
      console.warn(message, optionalParams);
    } else {
      console.warn(message);
    }
  }
  static consoleLogBase(message, ...optionalParams) {
    if (optionalParams === null || optionalParams === void 0 ? void 0 : optionalParams.length) {
      console.log(message, optionalParams);
    } else {
      console.log(message);
    }
  }
  constructor(adaptableId) {
    this.adaptableId = adaptableId;
    this.debugger = debugFactory(`Adaptable:${adaptableId}`);
    this.infoLogger = this.debugger.extend(`info`);
    this.infoLogger.logFn = console.info.bind(console);
    this.successLogger = this.debugger.extend(`success`);
    this.successLogger.logFn = console.log.bind(console);
    this.warnLogger = this.debugger.extend(`warn`);
    this.warnLogger.logFn = console.warn.bind(console);
    this.errorLogger = this.debugger.extend(`error`);
    this.errorLogger.logFn = console.error.bind(console);
    this.perfLogger = this.debugger.extend(`perf`);
    this.perfLogger.logFn = console.info.bind(console);
  }
  beginPerf(sectionName) {
    this.perfLogger(`[BEGIN] - ${sectionName}`);
    const startTime = performanceTime();
    return {
      end: additionalComment => {
        this.perfLogger(`[END]   - ${sectionName} ${additionalComment ? `(${additionalComment})` : ''} :: [delta +${performanceTime(startTime)}ms]`);
      }
    };
  }
  info(message, ...optionalParams) {
    if (optionalParams === null || optionalParams === void 0 ? void 0 : optionalParams.length) {
      this.infoLogger(message, ...optionalParams);
    } else {
      this.infoLogger(message);
    }
  }
  success(message, ...optionalParams) {
    if (optionalParams === null || optionalParams === void 0 ? void 0 : optionalParams.length) {
      this.successLogger(message, ...optionalParams);
    } else {
      this.successLogger(message);
    }
  }
  warn(message, ...optionalParams) {
    if (optionalParams === null || optionalParams === void 0 ? void 0 : optionalParams.length) {
      this.warnLogger(message, ...optionalParams);
    } else {
      this.warnLogger(message);
    }
  }
  error(message, ...optionalParams) {
    if (optionalParams === null || optionalParams === void 0 ? void 0 : optionalParams.length) {
      this.errorLogger(message, ...optionalParams);
    } else {
      this.errorLogger(message);
    }
  }
  consoleLogByMessageType(message, messageType, ...optionalParams) {
    message = `AdapTable:${this.adaptableId} ${messageType}: ${message}`;
    switch (messageType) {
      case 'Info':
        console.info(message, optionalParams);
        break;
      case 'Success':
        console.log(message, optionalParams);
        break;
      case 'Warning':
        console.warn(message, optionalParams);
        break;
      case 'Error':
        console.error(message, optionalParams);
        break;
    }
  }
  consoleInfo(message, ...optionalParams) {
    message = `AdapTable:${this.adaptableId}: ${message}`;
    if (optionalParams === null || optionalParams === void 0 ? void 0 : optionalParams.length) {
      console.info(message, optionalParams);
    } else {
      console.info(message);
    }
  }
  consoleLog(message, ...optionalParams) {
    message = `AdapTable:${this.adaptableId}: ${message}`;
    if (optionalParams === null || optionalParams === void 0 ? void 0 : optionalParams.length) {
      console.log(message, optionalParams);
    } else {
      console.log(message);
    }
  }
  consoleWarn(message, ...optionalParams) {
    message = `AdapTable:${this.adaptableId}: ${message}`;
    if (optionalParams === null || optionalParams === void 0 ? void 0 : optionalParams.length) {
      console.warn(message, optionalParams);
    } else {
      console.warn(message);
    }
  }
  consoleError(message, ...optionalParams) {
    message = `AdapTable:${this.adaptableId}: ${message}`;
    if (optionalParams === null || optionalParams === void 0 ? void 0 : optionalParams.length) {
      console.error(message, optionalParams);
    } else {
      console.error(message);
    }
  }
}
// change the default colors a bit to remove
// the dark blues that are hard to read on my monitor
debugFactory.colors = ['#0033FF', '#0066CC', '#0066FF', '#0099CC', '#0099FF', '#00CC00', '#00CC33', '#00CC66', '#00CC99', '#00CCCC', '#00CCFF', '#3399FF', '#33CC00', '#33CC33', '#33CC66', '#33CC99', '#33CCCC', '#33CCFF', '#6600CC', '#6600FF', '#6633CC', '#6633FF', '#66CC00', '#66CC33', '#9900CC', '#9900FF', '#9933CC', '#9933FF', '#99CC00', '#99CC33', '#CC0000', '#CC0033', '#CC0066', '#CC0099', '#CC00CC', '#CC00FF', '#CC3300', '#CC3333', '#CC3366', '#CC3399', '#CC33CC', '#CC33FF', '#CC6600', '#CC6633', '#CC9900', '#CC9933', '#CCCC00', '#CCCC33', '#FF0000', '#FF0033', '#FF0066', '#FF0099', '#FF00CC', '#FF00FF', '#FF3300', '#FF3333', '#FF3366', '#FF3399', '#FF33CC', '#FF33FF', '#FF6600', '#FF6633', '#FF9900', '#FF9933', '#FFCC00', '#FFCC33'];