import * as React from 'react';
import SimpleButton from '../../../components/SimpleButton';
export class ButtonInvalid extends React.Component {
  render() {
    return React.createElement(SimpleButton, Object.assign({
      "data-name": "invalid",
      tooltip: "Invalid",
      iconSize: 20,
      icon: "warning"
    }, this.props));
  }
}