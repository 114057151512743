import * as React from 'react';
import { Box, Flex } from 'rebass';
import { CheckBox } from '../../../components/CheckBox';
import { ColorPicker } from '../../../components/ColorPicker';
import FormLayout, { FormRow } from '../../../components/FormLayout';
import Input from '../../../components/Input';
import { Select } from '../../../components/Select';
import { Tabs } from '../../../components/Tabs';
import { Tag } from '../../../components/Tag';
import { setInPath } from '../../../Utilities/Extensions/ObjectExtensions';
import { Humanize } from '../../../Utilities/Extensions/StringExtensions';
import AdaptableInput from '../../Components/AdaptableInput';
import { BLACK, LIGHT_BLUE, LIGHT_GRAY, YELLOW } from '../../UIHelper';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
const CommonProperties = props => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8;
  const {
    data,
    api
  } = useOnePageAdaptableWizardContext();
  const axisTypeOptions = React.useMemo(() => {
    return [{
      label: 'Number',
      value: 'number'
    }, {
      label: 'Category',
      value: 'category'
    }, {
      label: 'Time',
      value: 'time'
    }];
  }, [data]);
  return React.createElement(Tabs, {
    mb: 2
  }, React.createElement(Tabs.Tab, null, "Padding"), React.createElement(Tabs.Tab, null, "Axis"), React.createElement(Tabs.Tab, null, "Highlight Style"), React.createElement(Tabs.Content, null, React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Top"
  }, React.createElement(Input, {
    onChange: event => {
      var _a;
      return props.onOptionChange(['padding', 'top'], (_a = event.target.value) !== null && _a !== void 0 ? _a : 0);
    },
    type: "number",
    value: (_b = (_a = data.SparkLineStyle.options) === null || _a === void 0 ? void 0 : _a.padding) === null || _b === void 0 ? void 0 : _b.top
  })), React.createElement(FormRow, {
    label: "Bottom"
  }, React.createElement(Input, {
    onChange: event => {
      var _a;
      return props.onOptionChange(['padding', 'bottom'], (_a = event.target.value) !== null && _a !== void 0 ? _a : 0);
    },
    type: "number",
    value: (_d = (_c = data.SparkLineStyle.options) === null || _c === void 0 ? void 0 : _c.padding) === null || _d === void 0 ? void 0 : _d.bottom
  })), React.createElement(FormRow, {
    label: "Left"
  }, React.createElement(Input, {
    onChange: event => {
      var _a;
      return props.onOptionChange(['padding', 'left'], (_a = event.target.value) !== null && _a !== void 0 ? _a : 0);
    },
    type: "number",
    value: (_f = (_e = data.SparkLineStyle.options) === null || _e === void 0 ? void 0 : _e.padding) === null || _f === void 0 ? void 0 : _f.left
  })), React.createElement(FormRow, {
    label: "Right"
  }, React.createElement(Input, {
    onChange: event => {
      var _a;
      return props.onOptionChange(['padding', 'right'], (_a = event.target.value) !== null && _a !== void 0 ? _a : 0);
    },
    type: "number",
    value: (_h = (_g = data.SparkLineStyle.options) === null || _g === void 0 ? void 0 : _g.padding) === null || _h === void 0 ? void 0 : _h.right
  })))), React.createElement(Tabs.Content, null, React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Axis Type"
  }, React.createElement(Box, {
    maxWidth: 180
  }, React.createElement(Select, {
    value: (_l = (_k = (_j = data === null || data === void 0 ? void 0 : data.SparkLineStyle) === null || _j === void 0 ? void 0 : _j.options) === null || _k === void 0 ? void 0 : _k.axis) === null || _l === void 0 ? void 0 : _l.type,
    options: axisTypeOptions,
    onChange: value => props.onOptionChange(['axis', 'type'], value),
    placeholder: "Select Axis Type"
  }))), React.createElement(FormRow, {
    label: "Stroke Color"
  }, React.createElement(ColorPicker, {
    api: api,
    value: (_q = (_p = (_o = (_m = data.SparkLineStyle) === null || _m === void 0 ? void 0 : _m.options) === null || _o === void 0 ? void 0 : _o.axis) === null || _p === void 0 ? void 0 : _p.stroke) !== null && _q !== void 0 ? _q : '#fff',
    onChange: color => props.onOptionChange(['axis', 'stroke'], color)
  })), React.createElement(FormRow, {
    label: "Stroke Width"
  }, React.createElement(AdaptableInput, {
    type: "number",
    value: (_u = (_t = (_s = (_r = data.SparkLineStyle) === null || _r === void 0 ? void 0 : _r.options) === null || _s === void 0 ? void 0 : _s.axis) === null || _t === void 0 ? void 0 : _t.strokeWidth) !== null && _u !== void 0 ? _u : 0,
    onChange: event => props.onOptionChange(['axis', 'strokeWidth'], parseFloat(event.target.value))
  })))), React.createElement(Tabs.Content, null, React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Size"
  }, React.createElement(AdaptableInput, {
    type: "number",
    value: (_x = (_w = (_v = data.SparkLineStyle) === null || _v === void 0 ? void 0 : _v.options) === null || _w === void 0 ? void 0 : _w.highlightStyle) === null || _x === void 0 ? void 0 : _x.size,
    onChange: event => props.onOptionChange(['highlightStyle', 'size'], parseFloat(event.target.value))
  })), React.createElement(FormRow, {
    label: "Fill"
  }, React.createElement(ColorPicker, {
    api: api,
    value: (_1 = (_0 = (_z = (_y = data.SparkLineStyle) === null || _y === void 0 ? void 0 : _y.options) === null || _z === void 0 ? void 0 : _z.highlightStyle) === null || _0 === void 0 ? void 0 : _0.fill) !== null && _1 !== void 0 ? _1 : YELLOW,
    onChange: color => props.onOptionChange(['highlightStyle', 'fill'], color)
  })), React.createElement(FormRow, {
    label: "Stroke"
  }, React.createElement(ColorPicker, {
    api: api,
    value: (_5 = (_4 = (_3 = (_2 = data.SparkLineStyle) === null || _2 === void 0 ? void 0 : _2.options) === null || _3 === void 0 ? void 0 : _3.highlightStyle) === null || _4 === void 0 ? void 0 : _4.stroke) !== null && _5 !== void 0 ? _5 : LIGHT_GRAY,
    onChange: color => props.onOptionChange(['highlightStyle', 'stroke'], color)
  })), React.createElement(FormRow, {
    label: "Stroke Width"
  }, React.createElement(AdaptableInput, {
    type: "number",
    value: (_8 = (_7 = (_6 = data.SparkLineStyle) === null || _6 === void 0 ? void 0 : _6.options) === null || _7 === void 0 ? void 0 : _7.highlightStyle) === null || _8 === void 0 ? void 0 : _8.strokeWidth,
    onChange: event => props.onOptionChange(['highlightStyle', 'strokeWidth'], parseFloat(event.target.value))
  })))));
};
const SparklineMarkerProperties = props => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
  const {
    data,
    api
  } = useOnePageAdaptableWizardContext();
  const sparklineOptions = data.SparkLineStyle.options;
  return React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Enabled"
  }, React.createElement(CheckBox, {
    checked: (_b = (_a = sparklineOptions === null || sparklineOptions === void 0 ? void 0 : sparklineOptions.marker) === null || _a === void 0 ? void 0 : _a.enabled) !== null && _b !== void 0 ? _b : true,
    onChange: checked => props.onOptionChange(['marker', 'enabled'], checked)
  })), React.createElement(FormRow, {
    label: "Size"
  }, React.createElement(AdaptableInput, {
    type: "number",
    value: (_d = (_c = sparklineOptions === null || sparklineOptions === void 0 ? void 0 : sparklineOptions.marker) === null || _c === void 0 ? void 0 : _c.size) !== null && _d !== void 0 ? _d : 0,
    onChange: event => props.onOptionChange(['marker', 'size'], parseFloat(event.target.value))
  })), React.createElement(FormRow, {
    label: "Fill"
  }, React.createElement(ColorPicker, {
    api: api,
    value: (_f = (_e = sparklineOptions === null || sparklineOptions === void 0 ? void 0 : sparklineOptions.marker) === null || _e === void 0 ? void 0 : _e.fill) !== null && _f !== void 0 ? _f : LIGHT_BLUE,
    onChange: color => props.onOptionChange(['marker', 'fill'], color)
  })), React.createElement(FormRow, {
    label: "Stroke"
  }, React.createElement(ColorPicker, {
    api: api,
    value: (_h = (_g = sparklineOptions === null || sparklineOptions === void 0 ? void 0 : sparklineOptions.marker) === null || _g === void 0 ? void 0 : _g.stroke) !== null && _h !== void 0 ? _h : LIGHT_BLUE,
    onChange: color => props.onOptionChange(['marker', 'stroke'], color)
  })), React.createElement(FormRow, {
    label: "Stroke Width"
  }, React.createElement(AdaptableInput, {
    type: "number",
    value: (_k = (_j = sparklineOptions === null || sparklineOptions === void 0 ? void 0 : sparklineOptions.marker) === null || _j === void 0 ? void 0 : _j.strokeWidth) !== null && _k !== void 0 ? _k : 1,
    onChange: event => props.onOptionChange(['marker', 'strokeWidth'], parseFloat(event.target.value))
  })));
};
const SparklineLineProperties = props => {
  var _a, _b, _c;
  const {
    data,
    api
  } = useOnePageAdaptableWizardContext();
  const sparklineOptions = data.SparkLineStyle.options;
  return React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Stroke"
  }, React.createElement(ColorPicker, {
    api: api,
    value: (_b = (_a = sparklineOptions === null || sparklineOptions === void 0 ? void 0 : sparklineOptions.line) === null || _a === void 0 ? void 0 : _a.stroke) !== null && _b !== void 0 ? _b : LIGHT_BLUE,
    onChange: color => props.onOptionChange(['line', 'stroke'], color)
  })), React.createElement(FormRow, {
    label: "Stroke Width"
  }, React.createElement(AdaptableInput, {
    type: "number",
    value: (_c = sparklineOptions === null || sparklineOptions === void 0 ? void 0 : sparklineOptions.line) === null || _c === void 0 ? void 0 : _c.strokeWidth,
    onChange: event => props.onOptionChange(['line', 'strokeWidth'], parseFloat(event.target.value))
  })));
};
const CrossHairsProperties = props => {
  const {
    data,
    api
  } = useOnePageAdaptableWizardContext();
  const sparklineOptions = data.SparkLineStyle.options;
  const lineDashes = ['dash', 'dashDot', 'dashDotDot', 'dot', 'longDash', 'longDashDot', 'longDashDotDot', 'shortDash', 'shortDashDot', 'shortDashDotDot', 'shortDot', 'solid'];
  const lineCapOptions = ['butt', 'round', 'square'];
  const buildLineDashProperties = key => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    const lineDashOptions = lineDashes.map(item => ({
      label: Humanize(item),
      value: item
    }));
    const lineCapOptionsItems = lineCapOptions.map(item => ({
      label: Humanize(item),
      value: item
    }));
    return React.createElement(FormLayout, null, React.createElement(FormRow, {
      label: "Show"
    }, React.createElement(CheckBox, {
      checked: (_c = (_b = (_a = sparklineOptions === null || sparklineOptions === void 0 ? void 0 : sparklineOptions.crosshairs) === null || _a === void 0 ? void 0 : _a[key]) === null || _b === void 0 ? void 0 : _b.enabled) !== null && _c !== void 0 ? _c : true,
      onChange: checked => props.onOptionChange(['crosshairs', key, 'enabled'], checked)
    })), React.createElement(FormRow, {
      label: "Stroke"
    }, React.createElement(ColorPicker, {
      api: api,
      value: (_f = (_e = (_d = sparklineOptions === null || sparklineOptions === void 0 ? void 0 : sparklineOptions.crosshairs) === null || _d === void 0 ? void 0 : _d[key]) === null || _e === void 0 ? void 0 : _e.stroke) !== null && _f !== void 0 ? _f : BLACK,
      onChange: color => props.onOptionChange(['crosshairs', key, 'stroke'], color)
    })), React.createElement(FormRow, {
      label: "Stroke Width"
    }, React.createElement(AdaptableInput, {
      type: "number",
      value: (_j = (_h = (_g = sparklineOptions === null || sparklineOptions === void 0 ? void 0 : sparklineOptions.crosshairs) === null || _g === void 0 ? void 0 : _g[key]) === null || _h === void 0 ? void 0 : _h.strokeWidth) !== null && _j !== void 0 ? _j : 1,
      onChange: event => props.onOptionChange(['crosshairs', key, 'strokeWidth'], event.target.value)
    })), React.createElement(FormRow, {
      label: "Line Dash"
    }, React.createElement(Select, {
      value: (_m = (_l = (_k = sparklineOptions === null || sparklineOptions === void 0 ? void 0 : sparklineOptions.crosshairs) === null || _k === void 0 ? void 0 : _k[key]) === null || _l === void 0 ? void 0 : _l.lineDash) !== null && _m !== void 0 ? _m : 'solid',
      onChange: value => props.onOptionChange(['crosshairs', key, 'lineDash'], value),
      options: lineDashOptions,
      placeholder: "Select Line Dash"
    })), React.createElement(FormRow, {
      label: "Line Cap"
    }, React.createElement(Select, {
      value: (_q = (_p = (_o = sparklineOptions === null || sparklineOptions === void 0 ? void 0 : sparklineOptions.crosshairs) === null || _o === void 0 ? void 0 : _o[key]) === null || _p === void 0 ? void 0 : _p.lineCap) !== null && _q !== void 0 ? _q : 'butt',
      onChange: value => props.onOptionChange(['crosshairs', key, 'lineCap'], value),
      options: lineCapOptionsItems,
      placeholder: "Select Line Cap"
    })));
  };
  return React.createElement(Flex, null, React.createElement(Box, {
    mr: 3
  }, React.createElement(Box, null, "X Line"), buildLineDashProperties('xLine')), React.createElement(Box, null, React.createElement(Box, null, "Y Line"), buildLineDashProperties('yLine')));
};
const TypeAreaProperties = props => {
  var _a;
  const {
    data,
    api
  } = useOnePageAdaptableWizardContext();
  const sparklineOptions = data.SparkLineStyle.options;
  return React.createElement(Tabs, {
    mb: 2
  }, React.createElement(Tabs.Tab, null, "Stroke"), React.createElement(Tabs.Tab, null, "Line"), React.createElement(Tabs.Tab, null, "Marker"), React.createElement(Tabs.Tab, null, "Cross Hairs"), React.createElement(Tabs.Content, null, React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Stroke"
  }, React.createElement(ColorPicker, {
    api: api,
    value: (_a = sparklineOptions === null || sparklineOptions === void 0 ? void 0 : sparklineOptions.fill) !== null && _a !== void 0 ? _a : LIGHT_BLUE,
    onChange: color => props.onOptionChange(['fill'], color)
  })))), React.createElement(Tabs.Content, null, React.createElement(SparklineLineProperties, {
    onOptionChange: props.onOptionChange
  })), React.createElement(Tabs.Content, null, React.createElement(SparklineMarkerProperties, {
    onOptionChange: props.onOptionChange
  })), React.createElement(Tabs.Content, null, React.createElement(CrossHairsProperties, {
    onOptionChange: props.onOptionChange
  })));
};
const TypeLineProperties = props => {
  return React.createElement(Tabs, {
    mb: 2
  }, React.createElement(Tabs.Tab, null, "Line"), React.createElement(Tabs.Tab, null, "Marker"), React.createElement(Tabs.Tab, null, "Cross Hairs"), React.createElement(Tabs.Content, null, React.createElement(SparklineLineProperties, {
    onOptionChange: props.onOptionChange
  })), React.createElement(Tabs.Content, null, React.createElement(SparklineMarkerProperties, {
    onOptionChange: props.onOptionChange
  })), React.createElement(Tabs.Content, null, React.createElement(CrossHairsProperties, {
    onOptionChange: props.onOptionChange
  })));
};
const TypeColumnBarProperties = props => {
  var _a, _b, _c, _d, _e;
  const {
    data,
    api
  } = useOnePageAdaptableWizardContext();
  const sparklineOptions = data.SparkLineStyle.options;
  return React.createElement(Tabs, {
    mb: 2
  }, React.createElement(Tabs.Tab, null, "General"), React.createElement(Tabs.Content, null, React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Fill"
  }, React.createElement(ColorPicker, {
    api: api,
    value: (_a = sparklineOptions === null || sparklineOptions === void 0 ? void 0 : sparklineOptions.fill) !== null && _a !== void 0 ? _a : LIGHT_BLUE,
    onChange: color => props.onOptionChange(['fill'], color)
  })), React.createElement(FormRow, {
    label: "Stroke"
  }, React.createElement(ColorPicker, {
    api: api,
    value: (_b = sparklineOptions === null || sparklineOptions === void 0 ? void 0 : sparklineOptions.stroke) !== null && _b !== void 0 ? _b : LIGHT_GRAY,
    onChange: color => props.onOptionChange(['stroke'], color)
  })), React.createElement(FormRow, {
    label: "Stroke Width"
  }, React.createElement(AdaptableInput, {
    type: "number",
    value: (_c = sparklineOptions === null || sparklineOptions === void 0 ? void 0 : sparklineOptions.strokeWidth) !== null && _c !== void 0 ? _c : 1,
    onChange: event => props.onOptionChange(['strokeWidth'], event.target.value)
  })), React.createElement(FormRow, {
    label: "Padding Inner"
  }, React.createElement(AdaptableInput, {
    type: "number",
    value: (_d = sparklineOptions === null || sparklineOptions === void 0 ? void 0 : sparklineOptions.paddingInner) !== null && _d !== void 0 ? _d : 0.1,
    onChange: event => props.onOptionChange(['paddingInner'], event.target.value)
  })), React.createElement(FormRow, {
    label: "Padding Outer"
  }, React.createElement(AdaptableInput, {
    type: "number",
    value: (_e = sparklineOptions === null || sparklineOptions === void 0 ? void 0 : sparklineOptions.paddingOuter) !== null && _e !== void 0 ? _e : 0.2,
    onChange: event => props.onOptionChange(['paddingOuter'], event.target.value)
  })))));
};
const SparklineObjectNumberArrayProperties = props => {
  var _a, _b;
  const {
    data,
    api
  } = useOnePageAdaptableWizardContext();
  const sparkLineOptions = data.SparkLineStyle.options;
  const [sparklineDataItem, setSparklineDataItem] = React.useState('');
  React.useEffect(() => {
    var _a, _b, _c;
    try {
      let cellData = null;
      const MAX_ROWS_TO_CHECK = 20;
      let currentRow = 0;
      while (!cellData || currentRow < MAX_ROWS_TO_CHECK) {
        const rowWithData = (_a = api.gridApi.getRowNodeForIndex(currentRow)) === null || _a === void 0 ? void 0 : _a.data;
        const column = api.columnApi.getColumnWithColumnId(data.ColumnId);
        if ((_b = rowWithData === null || rowWithData === void 0 ? void 0 : rowWithData[column === null || column === void 0 ? void 0 : column.field]) === null || _b === void 0 ? void 0 : _b[0]) {
          cellData = (_c = rowWithData === null || rowWithData === void 0 ? void 0 : rowWithData[column === null || column === void 0 ? void 0 : column.field]) === null || _c === void 0 ? void 0 : _c[0];
          break;
        }
        currentRow++;
      }
      if (cellData) {
        const str = JSON.stringify(cellData, null, 2).replace(/[{}",]/g, '');
        setSparklineDataItem(str);
      }
    } catch (e) {
      api.logError('Error parsing sparkline data', e);
    }
  }, [data]);
  return React.createElement(React.Fragment, null, React.createElement(FormRow, {
    label: "X Key"
  }, React.createElement(Input, {
    onChange: event => props.onOptionChange(['xKey'], event.target.value),
    value: (_a = sparkLineOptions === null || sparkLineOptions === void 0 ? void 0 : sparkLineOptions.xKey) !== null && _a !== void 0 ? _a : 'x'
  })), React.createElement(FormRow, {
    label: "Y Key"
  }, React.createElement(Input, {
    onChange: event => props.onOptionChange(['yKey'], event.target.value),
    value: (_b = sparkLineOptions === null || sparkLineOptions === void 0 ? void 0 : sparkLineOptions.yKey) !== null && _b !== void 0 ? _b : 'y'
  })), sparklineDataItem && React.createElement(FormRow, {
    label: "Preview"
  }, React.createElement(Tag, null, sparklineDataItem)));
};
export const StyledColumnSparklineSettingsSection = props => {
  var _a, _b;
  const {
    data,
    api
  } = useOnePageAdaptableWizardContext();
  const handleTypeChange = React.useCallback(type => {
    const newStyledColumn = Object.assign(Object.assign({}, data), {
      SparkLineStyle: Object.assign(Object.assign({}, data.SparkLineStyle), {
        options: Object.assign(Object.assign({}, data.SparkLineStyle.options), {
          type
        })
      })
    });
    props.onChange(newStyledColumn);
  }, [data]);
  const sparklineTypeOptions = React.useMemo(() => [{
    label: 'Line',
    value: 'line'
  }, {
    label: 'Area',
    value: 'area'
  }, {
    label: 'Column',
    value: 'column'
  }, {
    label: 'Bar',
    value: 'bar'
  }], [data]);
  const handleOptionChange = React.useCallback((path, value) => {
    var _a;
    const newOptions = setInPath((_a = data.SparkLineStyle.options) !== null && _a !== void 0 ? _a : {}, path, value);
    props.onChange(Object.assign(Object.assign({}, data), {
      SparkLineStyle: Object.assign(Object.assign({}, data.SparkLineStyle), {
        options: newOptions
      })
    }));
  }, [data]);
  const isObjectNumberArray = ((_a = api.columnApi.getColumnWithColumnId(data.ColumnId)) === null || _a === void 0 ? void 0 : _a.dataType) === 'ObjectNumberArray';
  return React.createElement(React.Fragment, null, React.createElement(Tabs, {
    mb: 2
  }, React.createElement(Tabs.Tab, null, "Settings"), React.createElement(Tabs.Content, null, React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Sparkline Type"
  }, React.createElement(Box, {
    maxWidth: 160
  }, React.createElement(Select, {
    options: sparklineTypeOptions,
    value: (_b = data.SparkLineStyle.options) === null || _b === void 0 ? void 0 : _b.type,
    onChange: value => handleOptionChange(['type'], value),
    placeholder: "Select Sparkline Type"
  }))), isObjectNumberArray && React.createElement(SparklineObjectNumberArrayProperties, {
    onOptionChange: handleOptionChange
  })))), React.createElement(CommonProperties, {
    onOptionChange: handleOptionChange
  }), (() => {
    var _a;
    switch ((_a = data.SparkLineStyle.options) === null || _a === void 0 ? void 0 : _a.type) {
      case 'line':
        return React.createElement(TypeLineProperties, {
          onOptionChange: handleOptionChange
        });
      case 'area':
        return React.createElement(TypeAreaProperties, {
          onOptionChange: handleOptionChange
        });
      case 'column':
        return React.createElement(TypeColumnBarProperties, {
          onOptionChange: handleOptionChange
        });
      case 'bar':
        // It is the same as column. If they diverge, we can split them out.
        return React.createElement(TypeColumnBarProperties, {
          onOptionChange: handleOptionChange
        });
    }
  })());
};