const isAdaptableColumn = input => {
  return typeof (input === null || input === void 0 ? void 0 : input.columnId) === 'string' && typeof input.dataType === 'string';
};
// this is only implemented for 1 level groups
export const isValidOrderForColumnGroups = ({
  oldColumns,
  newColumns
}) => {
  const getGroups = columns => {
    let prevGroup = '';
    let count = 0;
    columns.forEach(column => {
      var _a, _b;
      if (column && column.columnGroup && column.columnGroup.groupCount > 1 && !column.columnGroup.allowGroupSplit) {
        if (prevGroup !== column.columnGroup.columnGroupId) {
          count++;
        }
      }
      prevGroup = (_b = (_a = column === null || column === void 0 ? void 0 : column.columnGroup) === null || _a === void 0 ? void 0 : _a.columnGroupId) !== null && _b !== void 0 ? _b : '';
    });
    return count;
  };
  if (getGroups(oldColumns) != getGroups(newColumns)) {
    return false;
  }
  return true;
};