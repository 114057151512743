export const Fdc3IntentConfiguration = {
  CreateInteraction: {
    icon: 'sync'
  },
  SendChatMessage: {
    icon: 'chat'
  },
  StartCall: {
    icon: 'call'
  },
  StartChat: {
    icon: 'chat'
  },
  StartEmail: {
    icon: 'mail'
  },
  ViewAnalysis: {
    icon: 'spark-line'
  },
  ViewChat: {
    icon: 'chat'
  },
  ViewChart: {
    icon: 'pie-chart'
  },
  ViewContact: {
    icon: 'person'
  },
  ViewHoldings: {
    icon: 'building'
  },
  ViewInstrument: {
    icon: 'money'
  },
  ViewInteractions: {
    icon: 'interactions'
  },
  ViewMessages: {
    icon: 'mail'
  },
  ViewNews: {
    icon: 'news'
  },
  ViewOrders: {
    icon: 'order'
  },
  ViewProfile: {
    icon: 'badge'
  },
  ViewQuote: {
    icon: 'quote'
  },
  ViewResearch: {
    icon: 'science'
  }
};