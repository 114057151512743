export const editorButtonsAggregatedBoolean = [{
  functionName: 'SUM',
  data: 'SUM()',
  text: 'Sum'
}, {
  functionName: 'MIN',
  data: 'MIN()',
  text: 'Min'
}, {
  functionName: 'MAX',
  data: 'MAX()',
  text: 'Max'
}, {
  functionName: 'AVG',
  data: 'AVG()',
  text: 'Average'
}, {
  functionName: 'COUNT',
  data: 'COUNT()',
  text: 'Count'
}, {
  functionName: 'GROUP_BY',
  data: 'GROUP_BY()',
  text: 'GroupBy'
}, {
  functionName: 'EQ',
  data: '=',
  icon: 'equals'
}, {
  functionName: 'NEQ',
  data: '!=',
  icon: 'not-equal'
}, {
  functionName: 'LT',
  data: '<',
  icon: 'less-than'
}, {
  functionName: 'GT',
  data: '>',
  icon: 'greater-than'
}, {
  functionName: 'LTE',
  data: '<=',
  icon: 'less-than-or-equal'
}, {
  functionName: 'GTE',
  data: '>=',
  icon: 'greater-than-or-equal'
}];