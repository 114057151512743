import { __rest } from "tslib";
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '../../../../components/Dialog';
import { PopupHideWindow } from '../../../../Redux/ActionsReducers/PopupRedux';
import { useAdaptable } from '../../../AdaptableContext';
import { ExternalRenderer } from '../../ExternalRenderer';
import { PanelWithImage } from '../../Panels/PanelWithImage';
import { getMiddlePosition, getWindowPopupSize } from '../Utilities';
import { windowFactory } from './windowFactory';
const NoopComponent = () => {
  return React.createElement(React.Fragment, null);
};
export const CUSTOM_WINDOW_FACTORY_ID = 'CUSTOM_WINDOW_FACTORY_ID';
export const WindowPopups = () => {
  const [windowModalSettings, setWindowModalSettings] = React.useState({});
  const adaptable = useAdaptable();
  const dispatch = useDispatch();
  const windowItems = useSelector(state => state.Popup.WindowPopup.PopupList);
  return React.createElement(React.Fragment, null, windowItems === null || windowItems === void 0 ? void 0 : windowItems.map(windowItem => {
    var _a, _b, _c, _d, _e, _f;
    let Component = (_a = windowFactory[windowItem.FactoryId]) !== null && _a !== void 0 ? _a : null;
    const _g = (_b = windowItem === null || windowItem === void 0 ? void 0 : windowItem.PopupProps) !== null && _b !== void 0 ? _b : {},
      {
        windowModalProps
      } = _g,
      restPopupProps = __rest(_g, ["windowModalProps"]);
    const handleDismiss = () => {
      dispatch(PopupHideWindow(windowItem.Id));
    };
    const size = (_d = (_c = windowModalSettings === null || windowModalSettings === void 0 ? void 0 : windowModalSettings[windowItem.Id]) === null || _c === void 0 ? void 0 : _c.size) !== null && _d !== void 0 ? _d : getWindowPopupSize();
    const position = (_f = (_e = windowModalSettings === null || windowModalSettings === void 0 ? void 0 : windowModalSettings[windowItem.Id]) === null || _e === void 0 ? void 0 : _e.position) !== null && _f !== void 0 ? _f : getMiddlePosition(size);
    let componentNode = null;
    if (!Component && (windowItem.PopupProps.render || windowItem.PopupProps.frameworkComponent)) {
      componentNode = React.createElement(ExternalRenderer
      // TODO AFL: add configurable width&height for custom popups
      , {
        // TODO AFL: add configurable width&height for custom popups
        style: {
          height: '100%'
        },
        render: windowItem.PopupProps.render,
        frameworkComponent: windowItem.PopupProps.frameworkComponent,
        onDestroy: windowItem.PopupProps.onFrameworkComponentDestroyed
      });
    } else {
      Component = Component !== null && Component !== void 0 ? Component : NoopComponent;
      componentNode = React.createElement(Component, {
        api: adaptable.api,
        onDismiss: handleDismiss,
        popupProps: restPopupProps
      });
    }
    return React.createElement(Dialog, {
      "data-name": windowItem.Id,
      style: {
        height: '100%'
      },
      className: "ab-Window-Modal",
      key: windowItem.Id,
      windowModal: true,
      windowModalProps: Object.assign(Object.assign({}, windowModalProps), {
        onChange: settings => {
          setWindowModalSettings(settingsMap => Object.assign(Object.assign({}, settingsMap), {
            [windowItem.Id]: settings
          }));
        },
        handleSelector: '.ab-Window-Modal .ab-Panel__header',
        size: size,
        position: position
      }),
      fixed: false,
      padding: 0,
      onDismiss: handleDismiss,
      isOpen: true,
      showCloseButton: true
    }, React.createElement(PanelWithImage, {
      style: {
        height: '100%'
      },
      bodyProps: {
        padding: 0,
        height: '100%'
      },
      header: windowItem.Title,
      glyphicon: windowItem.Icon,
      variant: "primary"
    }, componentNode));
  }));
};