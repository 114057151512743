import * as React from 'react';
import FieldWrap from '../../../components/FieldWrap';
import UIHelper from '../../UIHelper';
import AdaptableInput from '../AdaptableInput';
import { Box } from 'rebass';
import { PermittedValuesSelector } from './PermittedValuesSelector';
import { Select } from '../../../components/Select';
export const ColumnValueSelector = props => {
  var _a, _b;
  const [valueType, setValueType] = React.useState('existing');
  const columnType = (_a = props.selectedColumn) === null || _a === void 0 ? void 0 : _a.dataType;
  const isDateType = props.selectedColumn && columnType === 'Date';
  const permittedValueSelector = React.createElement(Box, null, React.createElement(PermittedValuesSelector, {
    allowNewValues: true,
    disabled: props.disabled || !props.selectedColumn,
    value: props.selectedColumnValue === '' ? null : props.selectedColumnValue,
    columnId: (_b = props.selectedColumn) === null || _b === void 0 ? void 0 : _b.columnId,
    placeholder: isDateType ? 'Select' : 'Select or type new value',
    onChange: value => {
      if (!value) {
        props.onColumnValueChange(null);
      } else {
        props.onColumnValueChange(value);
      }
    }
  }));
  const input = React.createElement(AdaptableInput, {
    "data-name": "bulkupdate-value-input",
    type: props.selectedColumn ? UIHelper.getDescriptionForDataType(props.selectedColumn.dataType) : 'text',
    placeholder: props.selectedColumn ? UIHelper.getPlaceHolderforDataType(props.selectedColumn.dataType) : 'Enter Value',
    autoFocus: true,
    disabled: props.disabled,
    style: {
      width: '100%'
    },
    value: props.selectedColumnValue,
    onChange: e => {
      props.onColumnValueChange(e.target.value);
    }
  });
  let valueTypeSelector = null;
  if (isDateType) {
    valueTypeSelector = React.createElement(Select, {
      options: [{
        label: 'New',
        value: 'new'
      }, {
        label: 'Existing',
        value: 'existing'
      }],
      value: valueType,
      onChange: value => {
        setValueType(value);
      }
    });
  }
  return React.createElement(FieldWrap, {
    className: props.className,
    style: Object.assign(Object.assign({}, props.style), {
      overflow: 'visible',
      maxWidth: '100%'
    })
  }, isDateType ? React.createElement(React.Fragment, null, valueType === 'new' ? input : permittedValueSelector, valueTypeSelector) : permittedValueSelector);
};