import * as React from 'react';
import { connect } from 'react-redux';
import { PopupPanel } from '../Components/Popups/AdaptablePopup/PopupPanel';
import { DataChangeHistoryGrid } from './DataChangeHistoryGrid';
import { SystemDataChangeHistoryDisable, SystemDataChangeHistoryEnable, SystemDataChangeHistoryResume, SystemDataChangeHistorySuspend } from '../../Redux/ActionsReducers/SystemRedux';
import { Flex, Text } from 'rebass';
import { ButtonPlay } from '../Components/Buttons/ButtonPlay';
import { ButtonPause } from '../Components/Buttons/ButtonPause';
import { ButtonStop } from '../Components/Buttons/ButtonStop';
import { DateFormatter } from '../../Utilities/Helpers/FormatHelper';
class DataChangeHistoryPopupComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataChangeHistoryAdaptableApi: undefined
    };
  }
  render() {
    const {
      changeHistoryMode,
      activationTime,
      suspensionTime,
      onChangeHistoryEnable,
      onChangeHistoryDisable,
      onChangeHistorySuspend,
      onChangeHistoryResume
    } = this.props;
    const adaptableContainerId = 'dataChangeHistoryAdaptableContainer';
    const agGridContainerId = 'dataChangeHistoryAgGridContainer';
    const enabled = changeHistoryMode === 'ACTIVE';
    const disabled = changeHistoryMode === 'INACTIVE';
    const suspended = changeHistoryMode === 'SUSPENDED';
    const buttonPanel = React.createElement(Flex, {
      className: "ab-DataChangeHistoryPopup--button-panel",
      padding: 2,
      style: {
        gap: 'var(--ab-space-1)'
      }
    }, disabled && React.createElement(ButtonPlay, {
      className: "ab-DataChangeHistoryPopup--button-activate",
      "data-name": 'data-change-history--button-activate"',
      variant: 'raised',
      tone: 'accent',
      tooltip: '',
      onClick: () => onChangeHistoryEnable()
    }, "Activate"), suspended && React.createElement(ButtonPlay, {
      className: "ab-DataChangeHistoryPopup--button-resume",
      "data-name": 'data-change-history--button-resume',
      variant: 'outlined',
      tooltip: 'Resume tracking data changes',
      onClick: () => onChangeHistoryResume()
    }, "Resume"), enabled && React.createElement(ButtonPause, {
      className: "ab-DataChangeHistoryPopup--button-suspend",
      "data-name": 'data-change-history--button-suspend',
      variant: 'outlined',
      tooltip: 'Suspend tracking data changes',
      onClick: () => onChangeHistorySuspend()
    }, "Suspend"), (enabled || suspended) && React.createElement(ButtonStop, {
      className: "ab-DataChangeHistoryPopup--button-deactivate",
      "data-name": 'data-change-history--button-deactivate',
      variant: 'outlined',
      tooltip: 'Deactivate data change tracking',
      onClick: () => onChangeHistoryDisable()
    }, "Deactivate"));
    const dateFormat = `${this.props.api.optionsApi.getUserInterfaceOptions().dateInputOptions.dateFormat} HH:mm:ss`;
    const statusPanel = React.createElement(Flex, {
      className: "ab-DataChangeHistoryPopup--status-panel",
      alignItems: 'center',
      style: {
        gap: 'var(--ab-space-1)'
      }
    }, enabled && React.createElement(React.Fragment, null, React.createElement(Text, {
      className: "ab-DataChangeHistoryPanel--status-active",
      style: {
        color: 'var(--ab-color-success)',
        fontWeight: 'bold'
      }
    }, "Active"), !!activationTime && React.createElement(Text, {
      style: {
        fontStyle: 'italic'
      },
      fontSize: 2
    }, "(since ", DateFormatter(activationTime, {
      Pattern: dateFormat
    }), ")")), suspended && React.createElement(React.Fragment, null, React.createElement(Text, {
      className: "ab-DataChangeHistoryPopup--status-suspended",
      style: {
        color: 'var(--ab-color-warn)',
        fontWeight: 'bold'
      }
    }, "Suspended"), !!suspensionTime && React.createElement(Text, {
      style: {
        fontStyle: 'italic'
      },
      fontSize: 2
    }, "(since ", DateFormatter(suspensionTime, {
      Pattern: dateFormat
    }), ")")), disabled && React.createElement(Text, {
      className: "ab-DataChangeHistoryPopup--status-disabled",
      fontSize: 2
    }));
    const handleChangeUndo = changeKey => {
      const changeToBeUndone = this.props.changeHistoryLog[changeKey];
      if (changeToBeUndone) {
        this.props.api.dataChangeHistoryApi.undoDataChangeHistoryEntry(changeToBeUndone);
      }
    };
    const handleClearRow = changeKey => {
      const changeToBeUndone = this.props.changeHistoryLog[changeKey];
      if (changeToBeUndone) {
        this.props.api.dataChangeHistoryApi.clearDataChangeHistoryEntry(changeToBeUndone);
      }
    };
    const currentAgGridTheme = this.props.api.themeApi.getAgGridCurrentThemeName();
    return React.createElement(PopupPanel, {
      headerText: this.props.moduleInfo.FriendlyName,
      glyphicon: this.props.moduleInfo.Glyph,
      infoLink: this.props.moduleInfo.HelpPage,
      infoLinkDisabled: !this.props.api.internalApi.isDocumentationLinksDisplayed()
    }, React.createElement(Flex, {
      className: "ab-DataChangeHistoryPopup",
      flexDirection: "column",
      style: {
        height: '100%'
      }
    }, React.createElement(Flex, {
      style: {
        color: 'var(--ab-dashboard__color)',
        background: 'var(--ab-dashboard__background)'
      }
    }, React.createElement(Flex, {
      flexDirection: 'row'
    }, buttonPanel, statusPanel)), React.createElement(Flex, {
      className: "ab-DataChangeHistoryPopup--grid",
      "data-name": 'data-change-history--grid',
      flex: 1,
      alignSelf: 'stretch',
      flexDirection: 'column'
    }, React.createElement("div", {
      id: adaptableContainerId
    }), React.createElement("div", {
      id: agGridContainerId,
      className: currentAgGridTheme,
      style: {
        height: '100%'
      }
    }), React.createElement(DataChangeHistoryGrid, {
      adaptableContainerId: adaptableContainerId,
      agGridContainerId: agGridContainerId,
      changeHistoryLog: this.props.changeHistoryLog,
      onUndoChange: handleChangeUndo,
      onClearRow: handleClearRow
    }))));
  }
}
function mapStateToProps(state, ownProps) {
  return {
    changeHistoryLog: state.System.DataChangeHistory.logs,
    changeHistoryMode: state.System.DataChangeHistory.currentMode,
    activationTime: state.System.DataChangeHistory.enableTime,
    suspensionTime: state.System.DataChangeHistory.suspendTime
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onChangeHistoryEnable: () => dispatch(SystemDataChangeHistoryEnable()),
    onChangeHistoryDisable: () => dispatch(SystemDataChangeHistoryDisable()),
    onChangeHistorySuspend: () => dispatch(SystemDataChangeHistorySuspend()),
    onChangeHistoryResume: () => dispatch(SystemDataChangeHistoryResume())
  };
}
export let DataChangeHistoryPopup = connect(mapStateToProps, mapDispatchToProps)(DataChangeHistoryPopupComponent);