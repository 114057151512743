import * as AlertRedux from '../Redux/ActionsReducers/AlertRedux';
import * as SystemRedux from '../Redux/ActionsReducers/SystemRedux';
import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
import { ArrayExtensions } from '../Utilities/Extensions/ArrayExtensions';
import { ActiveAlertsPanelItemLabel } from '../View/Alert/ActiveAlertsPanelItemLabel';
import { AlertEmptyView } from '../View/Alert/AlertEmptyView';
import { AlertStatusPanel } from '../View/Alert/AlertStatusSubPanel';
import { getAlertType } from '../View/Alert/Utilities/getAlertType';
import { AlertWizard } from '../View/Alert/Wizard/AlertWizard';
import { AdaptableModuleBase } from './AdaptableModuleBase';
import { getAlertBehaviourViewItems } from './Utilities/Alert/getAlertBehaviourViewItems';
import { getAlertPreviewViewItems } from './Utilities/Alert/getAlertPreviewViewItems';
import { getAlertTypeViewItems } from './Utilities/Alert/getAlertTypeViewItems';
import { getObjectTagsViewItems } from './Utilities/getObjectTagsViewItems';
import { getRuleViewItems } from './Utilities/getRuleViewItems';
import { getScopeViewItems } from './Utilities/getScopeViewItems';
export class AlertModule extends AdaptableModuleBase {
  constructor(api) {
    super(ModuleConstants.AlertModuleId, ModuleConstants.AlertModuleFriendlyName, 'alert', 'AlertPopup', 'Get notified when things happen in Adaptable that you need to know about', api);
  }
  onAdaptableReady() {
    this.api.internalApi.getDataService().on('CellDataChanged', cellDataChangedInfo => {
      if (cellDataChangedInfo.trigger === 'undo' || cellDataChangedInfo.trigger === 'aggChange') {
        // do NOT handle reverted or aggregated changes
        return;
      }
      if (this.api.optionsApi.getAlertOptions().dataChangeDetectionPolicy === 'formattedValue') {
        const {
          oldValue,
          newValue,
          rowNode
        } = cellDataChangedInfo;
        const columnId = cellDataChangedInfo.column.columnId;
        const oldFormattedValue = this.api.gridApi.getDisplayValueFromRawValue(rowNode, columnId, oldValue);
        const newFormattedValue = this.api.gridApi.getDisplayValueFromRawValue(rowNode, columnId, newValue);
        if (oldFormattedValue === newFormattedValue) {
          // if the formattedValues are identical, then no alert is fired
          return;
        }
      }
      this.handleCellDataChanged(cellDataChangedInfo);
    });
    this.api.eventApi.on('GridDataChanged', gridDataChangedInfo => {
      if (gridDataChangedInfo.rowTrigger === 'Update') {
        // changed row alerts should be handled by standard 'AnyChange' Predicate
        return;
      }
      this.handleGridDataChanged(gridDataChangedInfo);
    });
    this.api.internalApi.getAlertService().onReactiveAlertTriggered(reactiveAlertInfo => this.handleReactiveAlertTriggered(reactiveAlertInfo));
  }
  getModuleAdaptableObjects(config) {
    return this.api.alertApi.getAlertDefinitions(config);
  }
  getExplicitlyReferencedColumnIds(alertDefinition) {
    const queryExpression = this.api.expressionApi.getAdaptableQueryExpression(alertDefinition.Rule);
    if (queryExpression) {
      return this.api.expressionApi.getColumnsFromExpression(queryExpression);
    } else if (this.api.columnScopeApi.scopeHasColumns(alertDefinition.Scope)) {
      return this.api.columnScopeApi.getColumnsForScope(alertDefinition.Scope).map(adaptableColumn => adaptableColumn.columnId);
    }
    return [];
  }
  getReferencedNamedQueryNames(alertDefinition) {
    const queryExpression = this.api.expressionApi.getAdaptableQueryExpression(alertDefinition.Rule);
    if (!queryExpression) {
      return [];
    }
    return this.api.namedQueryApi.internalApi.getReferencedNamedQueryNames(queryExpression);
  }
  createContextMenuItems(menuContext) {
    const items = [];
    if (!menuContext.isRowGroupColumn && this.isModuleAvailable()) {
      if (menuContext.adaptableColumn && menuContext.rowNode) {
        let relevantAlert;
        // find alerts which highlight the current cell
        relevantAlert = this.api.alertApi.internalApi.getAdaptableAlertWithHighlightCell(menuContext.adaptableColumn.columnId, menuContext.rowNode);
        if (!relevantAlert) {
          // find alerts which highlight the current row
          relevantAlert = this.api.alertApi.internalApi.getAdaptableAlertWithHighlightRow(menuContext.rowNode);
        }
        if (relevantAlert) {
          items.push(this.createMenuItemReduxAction('alert-clear', 'Clear Alert', this.moduleInfo.Glyph, SystemRedux.SystemAlertDelete(relevantAlert)));
        }
      }
    }
    return items;
  }
  handleCellDataChanged(cellDataChangedInfo) {
    const alertDefinitions = this.api.alertApi.internalApi.getAlertDefinitionsForCellDataChange(cellDataChangedInfo);
    if (ArrayExtensions.IsNotNullOrEmpty(alertDefinitions)) {
      this.api.alertApi.internalApi.showAlertForDefinitions(cellDataChangedInfo, alertDefinitions);
    }
  }
  handleGridDataChanged(gridDataChangedInfo) {
    const alertDefinitions = this.getAlertDefinitionsForGridDataChange(gridDataChangedInfo);
    this.api.alertApi.internalApi.showAlertsForGridDataChanges(gridDataChangedInfo, alertDefinitions);
  }
  handleReactiveAlertTriggered(reactiveAlertInfo) {
    // TODO  - suppress Notification if alert is not relevant for layout
    var _a;
    if (reactiveAlertInfo.type === 'cellDataChangedAlert') {
      // the row node data is most probably stale because the reactive queries buffer the changed data
      // so we have to refresh it
      reactiveAlertInfo.cellChangeLogEntry.rowNode = this.api.gridApi.getRowNodeForPrimaryKey(reactiveAlertInfo.cellChangeLogEntry.primaryKeyValue);
      reactiveAlertInfo.cellChangeLogEntry.rowData = (_a = reactiveAlertInfo.cellChangeLogEntry.rowNode) === null || _a === void 0 ? void 0 : _a.data;
      this.api.alertApi.internalApi.showAlertForDefinitions(reactiveAlertInfo.cellChangeLogEntry, [reactiveAlertInfo.alertDefinition]);
    } else {
      // reactiveAlertInfo.type === gridDataChangedAlert'
      this.api.alertApi.internalApi.showAlertForDefinitions(reactiveAlertInfo.gridChangeLogEntry, [reactiveAlertInfo.alertDefinition]);
    }
  }
  getAlertDefinitionsForGridDataChange(gridDataChangedInfo) {
    return this.api.alertApi.internalApi.getActiveNonReactiveAlertDefinitions().filter(alertDefinition => this.api.alertApi.internalApi.isAlertDefinitionForRowChangeEvent(alertDefinition)).filter(alertDefinition => {
      var _a, _b;
      if (gridDataChangedInfo.rowTrigger === 'Add') {
        return (_a = alertDefinition.Rule) === null || _a === void 0 ? void 0 : _a.Predicates.some(predicate => (predicate === null || predicate === void 0 ? void 0 : predicate.PredicateId) === 'AddedRow');
      }
      if (gridDataChangedInfo.rowTrigger === 'Delete') {
        return (_b = alertDefinition.Rule) === null || _b === void 0 ? void 0 : _b.Predicates.some(predicate => (predicate === null || predicate === void 0 ? void 0 : predicate.PredicateId) === 'RemovedRow');
      }
      return false;
    });
  }
  getTeamSharingAction() {
    return {
      ModuleEntities: this.api.alertApi.getAlertDefinitions(),
      AddAction: AlertRedux.AlertDefinitionAdd,
      EditAction: AlertRedux.AlertDefinitionEdit
    };
  }
  toViewCompact(alert) {
    return {
      item: {
        name: alert.header,
        label: ActiveAlertsPanelItemLabel,
        values: [alert.message]
      },
      abObject: alert
    };
  }
  toView(alert) {
    const alertType = getAlertType(alert);
    return {
      items: [getAlertTypeViewItems(alert), alertType === 'DataChange' && Object.assign(Object.assign({}, getScopeViewItems(alert.Scope, this.api)), {
        label: 'Trigger',
        name: 'Trigger'
      }), Object.assign(Object.assign({}, getRuleViewItems(alert.Rule, this.api)), {
        label: 'Rule',
        name: 'Rule'
      }), getAlertBehaviourViewItems(this.api), getAlertPreviewViewItems(alert, this.api), getObjectTagsViewItems(alert, this.api)].filter(Boolean),
      abObject: alert
    };
  }
  toViewAll() {
    return this.getModuleAdaptableObjects({
      includeLayoutNotAssociatedObjects: this.showLayoutNotAssociatedObjects()
    }).map(alert => this.toView(alert));
  }
  getViewProperties() {
    return {
      getDeleteAction: AlertRedux.AlertDefinitionDelete,
      getEditAction: AlertRedux.AlertDefinitionEdit,
      getSuspendAction: AlertRedux.AlertDefinitionSuspend,
      getUnSuspendAction: AlertRedux.AlertDefinitionUnSuspend,
      getSuspendAllAction: AlertRedux.AlertDefinitionSuspendAll,
      getUnSuspendAllAction: AlertRedux.AlertDefinitionUnSuspendAll,
      getCompactDeleteAction: SystemRedux.SystemAlertDelete,
      getDeleteAllAction: () => {
        const alerts = this.api.internalApi.getState().System.AdaptableAlerts;
        return SystemRedux.SystemAlertDeleteAll(alerts);
      },
      emptyView: AlertEmptyView,
      getEditWizard: () => AlertWizard,
      getStatusBarPanelProps: () => {
        return {
          popoverMinWidth: 360,
          view: AlertStatusPanel
        };
      }
    };
  }
  canBeAssociatedWithLayouts() {
    return true;
  }
}