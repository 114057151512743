import SimpleButton from '../components/SimpleButton';
import * as React from 'react';
import { useRerender } from '../components/utils/useRerender';
import { renderWithAdaptableContext } from '../View/renderWithAdaptableContext';
const ActionButtons = props => {
  const {
    buttons,
    adaptableApi,
    context,
    rerender
  } = props;
  return React.createElement(React.Fragment, null, buttons.map((button, index) => {
    var _a, _b, _c, _d, _e, _f;
    if (button.hidden && button.hidden(button, context)) {
      return;
    }
    const buttonIcon = adaptableApi.internalApi.getIconForButton(button, context, {
      height: 15,
      width: 15
    });
    const buttonStyle = adaptableApi.internalApi.getStyleForButton(button, context);
    const buttonLabel = adaptableApi.internalApi.getLabelForButton(button, context);
    const buttonTooltip = adaptableApi.internalApi.getTooltipForButton(button, context);
    const handleClick = () => {
      var _a;
      (_a = button.onClick) === null || _a === void 0 ? void 0 : _a.call(button, button, context);
      // Timeout to let any updates to be done before re-rendering the component
      setTimeout(() => {
        // when called again it triggers a re-render
        // https:reactjs.org/docs/react-dom.html#render
        rerender();
      }, 16);
    };
    const disabled = button.disabled && button.disabled(button, context);
    const identifier = (_d = (_c = (_a = button.label) !== null && _a !== void 0 ? _a : (_b = button.icon) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : button.tooltip) !== null && _d !== void 0 ? _d : `${index + 1}`;
    return React.createElement(SimpleButton, {
      key: button.Uuid,
      "data-name": `action-button-${identifier}`,
      variant: (_e = buttonStyle === null || buttonStyle === void 0 ? void 0 : buttonStyle.variant) !== null && _e !== void 0 ? _e : 'text',
      disabled: disabled,
      tooltip: buttonTooltip,
      icon: buttonIcon,
      tone: (_f = buttonStyle === null || buttonStyle === void 0 ? void 0 : buttonStyle.tone) !== null && _f !== void 0 ? _f : 'none',
      onClick: handleClick,
      className: buttonStyle === null || buttonStyle === void 0 ? void 0 : buttonStyle.className,
      accessLevel: 'Full'
    }, buttonLabel);
  }));
};
export const ReactActionColumnRenderer = props => {
  var _a, _b, _c;
  const rerender = useRerender();
  const adaptable = props.context.__adaptable;
  if (!adaptable) {
    console.warn('Adaptable not found in context of ActionColumnRenderer');
    return null;
  }
  const {
    actionButtons,
    actionColumn
  } = adaptable.api.internalApi.getActionButtonsAndActionColumn(props.colDef);
  if (!actionColumn || !actionButtons.length) {
    return null;
  }
  const isGroupedRow = adaptable.api.gridApi.isGroupRowNode(props.node);
  const isSummaryRow = adaptable.api.gridApi.isSummaryNode(props.node);
  let shouldRender = true;
  const isActionRowColumn = adaptable.api.columnApi.internalApi.isActionRowButtonColumn(props.colDef.colId);
  if (isGroupedRow) {
    if (isActionRowColumn || ((_a = actionColumn.rowScope) === null || _a === void 0 ? void 0 : _a.ExcludeGroupRows)) {
      shouldRender = false;
    }
  } else if (isSummaryRow) {
    if (isActionRowColumn || ((_b = actionColumn.rowScope) === null || _b === void 0 ? void 0 : _b.ExcludeSummaryRows)) {
      shouldRender = false;
    }
  } else {
    if ((_c = actionColumn.rowScope) === null || _c === void 0 ? void 0 : _c.ExcludeDataRows) {
      shouldRender = false;
    }
  }
  if (!shouldRender) {
    return null;
  }
  const pkValue = adaptable.getPrimaryKeyValueFromRowNode(props.node, props.api);
  const buttonContext = Object.assign(Object.assign({
    actionColumn,
    primaryKeyValue: pkValue,
    rowNode: props.node
  }, adaptable.api.internalApi.buildBaseContext()), {
    data: props.data
  });
  return React.createElement("div", {
    className: "ab-ActionColumn"
  }, React.createElement(ActionButtons, {
    buttons: actionButtons,
    adaptableApi: adaptable.api,
    context: buttonContext,
    rerender: rerender
  }));
};
export class ActionColumnRenderer {
  // gets called once before the renderer is used
  init(params) {
    var _a, _b, _c;
    const adaptable = params.context.__adaptable;
    if (!adaptable) {
      console.warn('Adaptable not found in context of ActionColumnRenderer');
      return null;
    }
    const {
      actionButtons,
      actionColumn
    } = adaptable.api.internalApi.getActionButtonsAndActionColumn(params.colDef);
    if (!actionColumn || !actionButtons.length) {
      return;
    }
    // create the cell
    this.eGui = document.createElement('div');
    this.eGui.className = 'ab-ActionColumn';
    const isGroupedRow = adaptable.api.gridApi.isGroupRowNode(params.node);
    const isSummaryRow = adaptable.api.gridApi.isSummaryNode(params.node);
    let shouldRender = true;
    const isActionRowColumn = adaptable.api.columnApi.internalApi.isActionRowButtonColumn(params.colDef.colId);
    if (isGroupedRow) {
      if (isActionRowColumn || ((_a = actionColumn.rowScope) === null || _a === void 0 ? void 0 : _a.ExcludeGroupRows)) {
        shouldRender = false;
      }
    } else if (isSummaryRow) {
      if (isActionRowColumn || ((_b = actionColumn.rowScope) === null || _b === void 0 ? void 0 : _b.ExcludeSummaryRows)) {
        shouldRender = false;
      }
    } else {
      if ((_c = actionColumn.rowScope) === null || _c === void 0 ? void 0 : _c.ExcludeDataRows) {
        shouldRender = false;
      }
    }
    if (!shouldRender) {
      this.eGui.innerHTML = '';
      return;
    }
    const pkValue = adaptable.getPrimaryKeyValueFromRowNode(params.node, params.api);
    const buttonContext = Object.assign(Object.assign({
      actionColumn,
      primaryKeyValue: pkValue,
      rowNode: params.node
    }, adaptable.api.internalApi.buildBaseContext()), {
      data: params.data
    });
    const eGui = this.eGui;
    const doRender = () => {
      this.unmountReactRoot = adaptable.renderReactRoot(renderWithAdaptableContext(ActionButtons({
        buttons: actionButtons,
        context: buttonContext,
        rerender: doRender,
        adaptableApi: adaptable.api
      }), adaptable), eGui);
    };
    this.render = doRender;
    doRender();
  }
  // defined on init
  // used to re-render in refresh
  render() {}
  // gets called once when grid ready to insert the element
  getGui() {
    return this.eGui;
  }
  // gets called whenever the user gets the cell to refresh
  refresh(params) {
    // return true to tell the grid we refreshed successfully
    this.render();
    return true;
  }
  // gets called when the cell is removed from the grid
  destroy() {
    var _a;
    (_a = this.unmountReactRoot) === null || _a === void 0 ? void 0 : _a.call(this);
    // do cleanup, remove event listener from button
    if (this.eGui) {
      this.eGui.removeEventListener('click', this.eventListener);
    }
  }
}