import * as React from 'react';
import SimpleButton from '../../../components/SimpleButton';
export class ButtonMaximise extends React.Component {
  render() {
    return React.createElement(SimpleButton, Object.assign({
      "data-name": "maximise",
      iconSize: 20,
      tooltip: "Maximize",
      icon: this.props.useHoirzontalChevron ? 'arrow-right' : 'arrow-down',
      variant: "text"
    }, this.props));
  }
}