import * as React from 'react';
import { useSelector } from 'react-redux';
import { DataSource, InfiniteTable } from '../../components/InfiniteTable';
import Panel from '../../components/Panel';
import { Box, Flex } from 'rebass';
import dateFnsFormat from 'date-fns/format';
import { useAdaptable } from '../AdaptableContext';
import { PopupPanel } from '../Components/Popups/AdaptablePopup/PopupPanel';
import { AdaptableButtonComponent } from '../Components/AdaptableButton';
import { DEFAULT_DATE_FORMAT_PATTERN_WITH_TIME } from '../../Utilities/Constants/GeneralConstants';
const tableDOMProps = {
  style: {
    minHeight: 160,
    minWidth: '10rem'
  }
};
const CellComments = props => {
  var _a;
  const adaptable = useAdaptable();
  const dateFormat = (_a = adaptable.api.optionsApi.getCommentOptions().dateFormat) !== null && _a !== void 0 ? _a : DEFAULT_DATE_FORMAT_PATTERN_WITH_TIME;
  const isReadOnlyModule = adaptable.api.entitlementApi.getEntitlementAccessLevelForModule('Comment') === 'ReadOnly';
  const columnsMap = React.useMemo(() => {
    const columns = {
      author: {
        field: 'Author',
        maxWidth: 100,
        valueGetter: params => params.data.Author.UserName
      },
      timestamp: {
        field: 'Timestamp',
        maxWidth: 150,
        valueGetter: params => {
          return dateFnsFormat(params.data.Timestamp, dateFormat);
        }
      },
      text: {
        header: 'Text',
        field: 'Value',
        defaultFlex: 3
      }
    };
    return columns;
  }, []);
  const cellValue = adaptable.api.gridApi.getCellDisplayValue(props.commentThread.PrimaryKeyValue, props.commentThread.ColumnId);
  const primaryKeyHeader = React.useMemo(() => {
    const primaryKey = adaptable.api.optionsApi.getPrimaryKey();
    return adaptable.api.columnApi.getFriendlyNameForColumnId(primaryKey);
  }, []);
  const columnFriendlyName = adaptable.api.columnApi.getFriendlyNameForColumnId(props.commentThread.ColumnId);
  return React.createElement(Panel, {
    mb: 2,
    header: React.createElement(Flex, {
      width: "100%",
      alignItems: "center"
    }, React.createElement(Box, {
      mr: 2
    }, React.createElement("b", null, "Cell Value:"), " ", cellValue), React.createElement(Box, {
      mr: 2
    }, React.createElement("b", null, primaryKeyHeader, ":"), " ", props.commentThread.PrimaryKeyValue), React.createElement(Box, null, React.createElement("b", null, "Column:"), " ", columnFriendlyName), React.createElement(Box, {
      flex: 1
    }), React.createElement(AdaptableButtonComponent, {
      mr: 1,
      variant: "text",
      icon: "visibility-on",
      onClick: () => {
        adaptable.api.internalApi.getCellPopupService().showPopup({
          ColumnId: props.commentThread.ColumnId,
          PrimaryKeyValue: props.commentThread.PrimaryKeyValue
        }, true);
      }
    }), React.createElement(AdaptableButtonComponent, {
      icon: "delete",
      disabled: isReadOnlyModule,
      variant: "text",
      onClick: () => {
        adaptable.api.commentApi.deleteCommentThread(props.commentThread);
      }
    }))
  }, React.createElement(DataSource, {
    data: props.commentThread.Comments,
    primaryKey: "Uuid"
  }, React.createElement(InfiniteTable, {
    columnTypes: {
      default: {
        align: 'start',
        defaultFlex: 1,
        defaultSortable: false
      }
    },
    headerOptions: {
      alwaysReserveSpaceForSortIcon: false
    },
    rowHeight: 30,
    columnHeaderHeight: 30,
    domProps: tableDOMProps,
    columns: columnsMap
  })));
};
export const CommentsPopup = props => {
  const commentThreads = useSelector(state => {
    return state.Comment.CommentThreads;
  });
  return React.createElement(PopupPanel, {
    headerText: 'Comments',
    glyphicon: 'comments',
    infoLink: props.moduleInfo.HelpPage
  }, (commentThreads !== null && commentThreads !== void 0 ? commentThreads : []).map((commentThread, index) => {
    var _a;
    return React.createElement(CellComments, {
      key: (_a = commentThread === null || commentThread === void 0 ? void 0 : commentThread.Uuid) !== null && _a !== void 0 ? _a : index,
      commentThread: commentThread
    });
  }));
};