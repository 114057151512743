import * as React from 'react';
import { Box } from 'rebass';
import { CheckBox } from '../../../components/CheckBox';
import FormLayout, { FormRow } from '../../../components/FormLayout';
import Radio from '../../../components/Radio';
import { Tabs } from '../../../components/Tabs';
import ArrayExtensions from '../../../Utilities/Extensions/ArrayExtensions';
import AdaptableInput from '../../Components/AdaptableInput';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
export const isScheduleValid = (schedule, isOneOff) => {
  var _a;
  if (!schedule.Schedule) {
    return 'Schedule is not specified';
  }
  if (schedule.Schedule.Hour === null || schedule.Schedule.Minute === null) {
    return 'Schedule Time is not specified';
  }
  if (isOneOff && !schedule.Schedule.OneOffDate) {
    return 'Schedule Date is not specified';
  }
  if (!isOneOff && ArrayExtensions.IsEmpty((_a = schedule === null || schedule === void 0 ? void 0 : schedule.Schedule) === null || _a === void 0 ? void 0 : _a.DaysOfWeek)) {
    return 'Schedule Days are not specified';
  }
  return true;
};
const daysMap = [{
  label: 'Monday',
  value: 'Monday'
}, {
  label: 'Tuesday',
  value: 'Tuesday'
}, {
  label: 'Wednesday',
  value: 'Wednesday'
}, {
  label: 'Thursday',
  value: 'Thursday'
}, {
  label: 'Friday',
  value: 'Friday'
}, {
  label: 'Saturday',
  value: 'Saturday'
}, {
  label: 'Sunday',
  value: 'Sunday'
}];
export const ScheduleScheduleWizard = props => {
  var _a, _b, _c, _d, _e;
  const {
    data
  } = useOnePageAdaptableWizardContext();
  const handleIsOneOfChange = isOneOffNew => {
    var _a, _b;
    props.onSetIsOneOff(isOneOffNew);
    const OneOffDate = !isOneOffNew ? null : (_a = data === null || data === void 0 ? void 0 : data.Schedule) === null || _a === void 0 ? void 0 : _a.OneOffDate;
    const DaysOfWeek = isOneOffNew ? [] : (_b = data === null || data === void 0 ? void 0 : data.Schedule) === null || _b === void 0 ? void 0 : _b.DaysOfWeek;
    props.onChange(Object.assign(Object.assign({}, data), {
      Schedule: Object.assign(Object.assign({}, data.Schedule), {
        OneOffDate,
        DaysOfWeek
      })
    }));
  };
  const handleTimeChange = event => {
    var _a, _b;
    const [hours, minutes] = (_b = (_a = event.target.value || '00:00') === null || _a === void 0 ? void 0 : _a.split(':')) === null || _b === void 0 ? void 0 : _b.map(item => parseInt(item, 10));
    props.onChange(Object.assign(Object.assign({}, data), {
      Schedule: Object.assign(Object.assign({}, data.Schedule), {
        Hour: hours,
        Minute: minutes
      })
    }));
  };
  const handleDayChange = (checked, day) => {
    let newDays = data.Schedule.DaysOfWeek || [];
    if (checked) {
      newDays = [...newDays, day];
    } else {
      newDays = newDays.filter(previousDay => previousDay !== day);
    }
    props.onChange(Object.assign(Object.assign({}, data), {
      Schedule: Object.assign(Object.assign({}, data.Schedule), {
        DaysOfWeek: newDays
      })
    }));
  };
  const handleOneOfDateChange = event => {
    props.onChange(Object.assign(Object.assign({}, data), {
      Schedule: Object.assign(Object.assign({}, data.Schedule), {
        OneOffDate: event.target.value
      })
    }));
  };
  let timeValue = '00:00';
  if (typeof ((_a = data.Schedule) === null || _a === void 0 ? void 0 : _a.Hour) === 'number' || typeof ((_b = data === null || data === void 0 ? void 0 : data.Schedule) === null || _b === void 0 ? void 0 : _b.Minute) === 'number') {
    const hours = `${(_c = data.Schedule) === null || _c === void 0 ? void 0 : _c.Hour}` || '00';
    const minute = `${(_d = data.Schedule) === null || _d === void 0 ? void 0 : _d.Minute}` || '00';
    timeValue = `${hours.padStart(2, '0')}:${minute.padStart(2, '0')}`;
  }
  return React.createElement(Box, {
    "data-name": "schedule-setup"
  }, React.createElement(Tabs, {
    autoFocus: false
  }, React.createElement(Tabs.Tab, null, "Schedule"), React.createElement(Tabs.Content, null, React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Frequency"
  }, React.createElement(Radio, {
    "data-name": "frequency-recurring",
    mr: 2,
    value: "recurring",
    checked: !props.isOneOff,
    onChange: checked => checked && handleIsOneOfChange(false)
  }, "Recurring Days"), React.createElement(Radio, {
    "data-name": "frequency-one-off",
    checked: props.isOneOff,
    value: "oneoff",
    onChange: checked => checked && handleIsOneOfChange(true)
  }, "One-Off Date")), props.isOneOff ? React.createElement(FormRow, {
    label: "Date"
  }, React.createElement(AdaptableInput, {
    "data-name": "one-off-date",
    width: 300,
    type: "date",
    placeholder: "Date",
    onChange: handleOneOfDateChange,
    value: ((_e = data.Schedule) === null || _e === void 0 ? void 0 : _e.OneOffDate) || ''
  })) : React.createElement(FormRow, {
    label: "Days"
  }, daysMap.map(day => {
    var _a, _b;
    return React.createElement(CheckBox, {
      "data-name": `day-${day.value}`,
      width: "33%",
      ml: 2,
      value: day.value,
      checked: (_b = (_a = data.Schedule) === null || _a === void 0 ? void 0 : _a.DaysOfWeek) === null || _b === void 0 ? void 0 : _b.includes(day.value),
      key: day.value,
      onChange: checked => handleDayChange(checked, day.value)
    }, day.label);
  })), React.createElement(FormRow, {
    label: "Time"
  }, React.createElement(AdaptableInput, {
    "data-name": "time",
    width: 300,
    value: timeValue,
    type: "time",
    onChange: handleTimeChange
  }))))));
};