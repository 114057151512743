import { ApiBase } from '../Implementation/ApiBase';
export class UserInterfaceInternalApi extends ApiBase {
  prepareAdaptableIconDef(icon) {
    if (icon && 'name' in icon) {
      let customIcon = icon && 'name' in icon ? this.getAdaptableApi().userInterfaceApi.getCustomIconDefinition(icon.name) : null;
      if (customIcon) {
        icon = customIcon;
      }
    }
    return icon;
  }
}