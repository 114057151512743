import * as React from 'react';
import { useMemo } from 'react';
import { Box, Flex } from 'rebass';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
import FormLayout, { FormRow } from '../../../components/FormLayout';
import Input from '../../../components/Input';
import ErrorBox from '../../../components/ErrorBox';
import { Tabs } from '../../../components/Tabs';
import { ValueOptionsTags } from '../../Components/ValueSelector';
import { SpecialColumnSettingsWizardStep } from '../../SpecialColumnSettingsWizardStep';
import { getCalculatedColumnSettingsTags } from '../Utilities/getCalculatedColumnSettingsTags';
import { Select } from '../../../components/Select';
export const renderCalculatedColumnSettingsSummary = data => {
  const options = getCalculatedColumnSettingsTags(data.CalculatedColumnSettings);
  return React.createElement(Box, {
    fontSize: 2
  }, React.createElement(ValueOptionsTags, {
    toLabel: x => x,
    toIdentifier: c => c,
    readOnly: true,
    options: options,
    value: options
  }));
};
export const isValidCalculatedColumnSettings = data => {
  var _a;
  if (!((_a = data.CalculatedColumnSettings) === null || _a === void 0 ? void 0 : _a.DataType)) {
    return 'No data type is specified and it could not be inferred from the defined expression';
  }
  return true;
};
export const CalculatedColumnSettingsWizardSection = props => {
  var _a, _b;
  const {
    data,
    api
  } = useOnePageAdaptableWizardContext();
  const calculatedColumnExpressionService = useMemo(() => api.internalApi.getCalculatedColumnExpressionService(), []);
  const handleDataTypeChange = dataType => {
    var _a;
    const aggregatable = dataType == 'Number' ? (_a = data.CalculatedColumnSettings) === null || _a === void 0 ? void 0 : _a.Aggregatable : false;
    props.onChange(Object.assign(Object.assign({}, data), {
      CalculatedColumnSettings: Object.assign(Object.assign({}, data.CalculatedColumnSettings), {
        DataType: dataType,
        Aggregatable: aggregatable
      })
    }));
  };
  const validCheck = isValidCalculatedColumnSettings(data);
  const ErrorMessage = validCheck === true ? null : validCheck;
  const {
    DataType: dataType
  } = (_a = data.CalculatedColumnSettings) !== null && _a !== void 0 ? _a : {};
  const {
    Width
  } = (_b = data.CalculatedColumnSettings) !== null && _b !== void 0 ? _b : {};
  const handleSpecialColumnSettingsChange = settings => {
    props.onChange(Object.assign(Object.assign({}, data), {
      CalculatedColumnSettings: Object.assign(Object.assign({}, data.CalculatedColumnSettings), settings)
    }));
  };
  React.useEffect(() => {
    if (!dataType) {
      const computedDataType = calculatedColumnExpressionService.getCalculatedColumnDataType(data.Query);
      handleSpecialColumnSettingsChange(Object.assign(Object.assign({}, data === null || data === void 0 ? void 0 : data.CalculatedColumnSettings), {
        DataType: computedDataType
      }));
    }
  }, []);
  const options = [{
    value: 'Number',
    label: 'Number'
  }, {
    value: 'String',
    label: 'String'
  }, {
    value: 'Date',
    label: 'Date'
  }, {
    value: 'Boolean',
    label: 'Boolean'
  }];
  return React.createElement(Box, {
    "data-name": 'calculated-column-settings'
  }, React.createElement(Tabs, {
    autoFocus: false
  }, React.createElement(Tabs.Tab, null, "Column Settings"), React.createElement(Tabs.Content, null, React.createElement(Flex, {
    flexDirection: "row"
  }, React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Data Type"
  }, React.createElement(Select, {
    "data-name": "column-type",
    placeholder: "Select Data Type",
    options: options,
    value: dataType,
    onChange: value => handleDataTypeChange(value)
  })), React.createElement(FormRow, {
    label: "Width"
  }, React.createElement(Input, {
    "data-name": "column-width",
    type: "number",
    width: 300,
    value: Width || '',
    onChange: e => handleSpecialColumnSettingsChange({
      Width: Number(e.target.value)
    })
  })))))), ErrorMessage ? React.createElement(ErrorBox, {
    marginTop: 2
  }, ErrorMessage) : null, React.createElement(SpecialColumnSettingsWizardStep, {
    isEditable: false,
    // @ts-ignore CalculatedColumn has a broader DataType
    settings: data.CalculatedColumnSettings,
    onChange: handleSpecialColumnSettingsChange
  }));
};