import * as React from 'react';
import { useOnePageAdaptableWizardContext } from '../../Wizard/OnePageAdaptableWizard';
import { CodeBlock } from '../../../components/CodeBlock';
import { EntityRulesEditor, EntityRulesSummary } from '../../Components/EntityRulesEditor';
export const renderFlashingAlertRulesSummary = flashingAlert => {
  var _a;
  const {
    api: {
      flashingCellApi
    }
  } = useOnePageAdaptableWizardContext();
  const FlashTarget = (_a = flashingAlert.FlashTarget) !== null && _a !== void 0 ? _a : flashingCellApi.getFlashingCellFlashTarget(flashingAlert);
  return React.createElement(EntityRulesSummary, {
    data: flashingAlert,
    renderPredicate: content => {
      return React.createElement(React.Fragment, null, "Flash ", React.createElement(CodeBlock, null, FlashTarget), " - value condition", ' ', React.createElement(CodeBlock, null, content));
    },
    renderQueryExpression: content => {
      return React.createElement(React.Fragment, null, "Flash ", React.createElement(CodeBlock, null, FlashTarget), " when", ' ', React.createElement("b", null, React.createElement(CodeBlock, null, content)));
    }
  });
};
export const FlashingAlertRulesWizardSection = props => {
  const {
    data,
    api,
    moduleInfo
  } = useOnePageAdaptableWizardContext();
  const predicateDefs = api.flashingCellApi.getFlashingCellPredicateDefsForScope(data.Scope);
  return React.createElement(EntityRulesEditor, {
    module: moduleInfo.ModuleName,
    defaultPredicateId: "AnyChange",
    data: data,
    predicateDefs: predicateDefs,
    getPredicateDefsForColId: colId => api.flashingCellApi.getFlashingCellPredicateDefsForScope({
      ColumnIds: [colId]
    }),
    onChange: props.onChange,
    showAggregation: false,
    showObservable: false,
    showBoolean: true,
    showPredicate: true,
    descriptions: {
      selectPredicate: 'Select an Flashing Cell Rule - to be applied when data changes',
      useBooleanQuery: React.createElement(React.Fragment, null, "Use an BooleanQuery if ", React.createElement("i", null, "Scope"), " is 'All Columns' - so any data change may be evaluated in a complex BooleanExpression"),
      useObservableQuery: React.createElement(React.Fragment, null, "Use an ObservableQuery if ", React.createElement("i", null, "Scope"), " is 'All Columns' - so any data change may be evaluated in a complex ObservableExpression"),
      useAggregationQuery: React.createElement(React.Fragment, null, "Use an AggregatedBooleanQuery if ", React.createElement("i", null, "Scope"), " is 'All Columns' - so any data change may be evaluated in a complex AggregatedBooleanExpression")
    }
  });
};