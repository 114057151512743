import * as React from 'react';
import { renderWithAdaptableContext } from '../../../View/renderWithAdaptableContext';
import { InternalAdaptablePercentageEditor } from './InternalAdaptablePercentageEditor';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { getStartValue } from '../AdaptableNumberEditor';
import { times100 } from '../../../Utilities/times100';
import { divideBy100 } from '../../../Utilities/divideBy100';
const defaultValueParser = ({
  newValue,
  oldValue: _
}) => {
  return newValue;
};
const style = {
  position: 'absolute',
  top: '0px',
  left: '0px',
  right: '0px',
  bottom: '0px'
};
export const AdaptableReactPercentageEditor = forwardRef((props, ref) => {
  var _a, _b;
  const [initialValue] = useState(() => times100(getStartValue(props)));
  const valueRef = useRef(initialValue);
  const columnId = props.column.getColId();
  const adaptable = props.context.__adaptable;
  const colValueParser = props.column.getColDef().valueParser;
  const valueParser = typeof colValueParser === 'function' ? colValueParser : defaultValueParser;
  function onValueChange(value) {
    var _a;
    const newValue = valueParser ? valueParser(Object.assign(Object.assign({}, props), {
      oldValue: props.value,
      newValue: value
    })) : value;
    valueRef.current = newValue;
    (_a = props.onValueChange) === null || _a === void 0 ? void 0 : _a.call(props, divideBy100(newValue));
  }
  const editorRef = useRef(null);
  useImperativeHandle(ref, () => {
    return {
      focusIn() {
        var _a;
        (_a = editorRef.current) === null || _a === void 0 ? void 0 : _a.focus();
      },
      // the final value to send to the grid, on completion of editing
      getValue() {
        return divideBy100(valueRef.current);
      }
    };
  });
  const editorElement = React.createElement(InternalAdaptablePercentageEditor, {
    defaultValue: initialValue,
    showClearButton: (_a = props.showClearButton) !== null && _a !== void 0 ? _a : true,
    emptyValue: (_b = props.emptyValue) !== null && _b !== void 0 ? _b : '',
    onValueChange: onValueChange,
    ref: editor => {
      editorRef.current = editor;
      editor === null || editor === void 0 ? void 0 : editor.focus();
    }
  });
  function onKeyDown(keyDownEvent) {
    adaptable._emit('CellEditorKeyDown', {
      keyDownEvent,
      cellValue: valueRef.current,
      columnId,
      updateValueCallback: updatedValue => {
        editorRef.current.setValue(updatedValue);
      }
    });
  }
  return React.createElement("div", {
    style: style,
    onKeyDown: onKeyDown
  }, renderWithAdaptableContext(editorElement, adaptable));
});
AdaptableReactPercentageEditor.displayName = 'AdaptableReactPercentageEditor';
/**
 * Used to edit percentage columns as percentages (similar to how its done in Excel)
 */
export class AdaptablePercentageEditor {
  constructor() {
    this.valueParser = defaultValueParser;
    this.onValueChange = value => {
      const newValue = this.valueParser ? this.valueParser(Object.assign(Object.assign({}, this.params), {
        oldValue: this.params.value,
        newValue: value
      })) : value;
      this.value = divideBy100(newValue);
    };
  }
  init(params) {
    this.value = getStartValue(params);
    this.params = params;
    this.columnId = params.column.getColId();
    const {
      valueParser
    } = params.column.getColDef();
    if (typeof valueParser === 'function') {
      this.valueParser = valueParser;
    }
    this.el = document.createElement('div');
    Object.keys(style).forEach(key => {
      //@ts-ignore
      this.el.style[key] = style[key];
    });
  }
  /* Component Editor Lifecycle methods */
  // gets called once when grid ready to insert the element
  getGui() {
    return this.el;
  }
  // the final value to send to the grid, on completion of editing
  getValue() {
    return this.value;
  }
  focusIn() {
    var _a;
    (_a = this.editor) === null || _a === void 0 ? void 0 : _a.focus();
  }
  focusOut() {}
  // after this component has been created and inserted into the grid
  afterGuiAttached() {
    var _a, _b;
    const adaptable = this.params.context.__adaptable;
    const defaultValue = times100(this.value);
    const editorElement = React.createElement(InternalAdaptablePercentageEditor, {
      defaultValue: defaultValue,
      showClearButton: (_a = this.params.showClearButton) !== null && _a !== void 0 ? _a : true,
      emptyValue: (_b = this.params.emptyValue) !== null && _b !== void 0 ? _b : '',
      onValueChange: this.onValueChange,
      ref: editor => {
        this.editor = editor;
        editor === null || editor === void 0 ? void 0 : editor.focus();
      }
    });
    this.unmountReactRoot = adaptable.renderReactRoot(renderWithAdaptableContext(editorElement, adaptable), this.el);
    this.getGui().addEventListener('keydown', keyDownEvent => {
      adaptable._emit('CellEditorKeyDown', {
        keyDownEvent,
        cellValue: this.value,
        columnId: this.columnId,
        updateValueCallback: updatedValue => {
          this.editor.setValue(updatedValue);
        }
      });
    });
  }
  destroy() {
    var _a;
    (_a = this.unmountReactRoot) === null || _a === void 0 ? void 0 : _a.call(this);
  }
}