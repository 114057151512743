import * as React from 'react';
import SimpleButton from '../../../components/SimpleButton';
export class ButtonSave extends React.Component {
  render() {
    return React.createElement(SimpleButton, Object.assign({
      "data-name": "save",
      iconSize: 20,
      icon: "save",
      tooltip: "Save",
      variant: "text"
    }, this.props));
  }
}