import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
import { createBaseContext } from '../Utilities/ObjectFactory';
import { AdaptableModuleBase } from './AdaptableModuleBase';
export class CommentModule extends AdaptableModuleBase {
  constructor(api) {
    super(ModuleConstants.CommentModuleId, ModuleConstants.CommentFriendlyName, 'comments', 'CommentsPopup', 'comments', api);
  }
  onAdaptableReady() {
    this.loadComments();
  }
  isModuleAvailable() {
    const options = this.api.optionsApi.getCommentOptions();
    if (!options || !options.persistCommentThreads || !options.loadCommentThreads) {
      return false;
    }
    if (this.api.optionsApi.getAutogeneratePrimaryKey()) {
      return false;
    }
    return super.isModuleAvailable();
  }
  async loadComments() {
    var _a, _b;
    const commentThreads = await ((_b = (_a = this.api.optionsApi.getCommentOptions()) === null || _a === void 0 ? void 0 : _a.loadCommentThreads) === null || _b === void 0 ? void 0 : _b.call(_a, createBaseContext(this.api)));
    this.api.commentApi.setGridComments(commentThreads);
  }
  createContextMenuItems(menuContext) {
    var _a, _b, _c, _d, _e;
    if (!this.isModuleAvailable()) {
      return;
    }
    if (!this.api.commentApi.internalApi.areCommentsSupported()) {
      return;
    }
    if (menuContext.isRowGroupColumn || menuContext.isGroupedNode) {
      return;
    }
    const items = [];
    const isCellCommentable = typeof ((_b = (_a = this.api.optionsApi) === null || _a === void 0 ? void 0 : _a.getCommentOptions()) === null || _b === void 0 ? void 0 : _b.isCellCommentable) === 'function' ? (_d = (_c = this.api.optionsApi) === null || _c === void 0 ? void 0 : _c.getCommentOptions()) === null || _d === void 0 ? void 0 : _d.isCellCommentable(Object.assign({
      gridCell: menuContext.gridCell
    }, createBaseContext(this.api))) : true;
    if (!isCellCommentable) {
      return;
    }
    const cellAddress = {
      PrimaryKeyValue: menuContext.primaryKeyValue,
      ColumnId: menuContext.adaptableColumn.columnId
    };
    const cellComments = (_e = this.api.commentApi.getCommentThread(cellAddress)) === null || _e === void 0 ? void 0 : _e.Comments;
    if (cellComments) {
      return [this.createMenuItemClickFunction('comment-remove', 'Remove Comment', this.moduleInfo.Glyph, () => {
        this.api.commentApi.deleteCommentThread(cellAddress);
      })];
    } else {
      return [this.createMenuItemClickFunction('comment-add', 'Add Comment', this.moduleInfo.Glyph, () => {
        // add an empty one
        this.api.commentApi.addCommentThread(Object.assign(Object.assign({}, cellAddress), {
          Comments: []
        }));
        requestAnimationFrame(() => {
          this.api.internalApi.getCellPopupService().showPopup({
            PrimaryKeyValue: menuContext.primaryKeyValue,
            ColumnId: menuContext.adaptableColumn.columnId
          }, true);
          this.api.internalApi.getCellPopupService().editFocusedEntity('Comment');
        });
      })];
    }
  }
}