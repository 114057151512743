import { __rest } from "tslib";
import * as React from 'react';
import { Box } from 'rebass';
import join from '../utils/join';
export const baseClassName = 'ab-Input';
const Input = React.forwardRef((props, ref) => {
  const {
      disabled,
      className
    } = props,
    inputProps = __rest(props, ["disabled", "className"]);
  let type = 'text';
  if (inputProps && inputProps.type) {
    type = inputProps.type;
  }
  if (type === 'string') {
    type = 'text';
  }
  return React.createElement(Box, Object.assign({
    as: "input",
    ref: ref
  }, inputProps, {
    type: type,
    disabled: disabled,
    className: join(className, baseClassName, type ? `${baseClassName}--type-${type}` : '', disabled ? `${baseClassName}--disabled` : '')
  }));
});
export default Input;