import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as SystemRedux from '../../Redux/ActionsReducers/SystemRedux';
import { GridFilterModuleId, NamedQueryModuleId } from '../../Utilities/Constants/ModuleConstants';
import StringExtensions from '../../Utilities/Extensions/StringExtensions';
import { useAdaptable } from '../AdaptableContext';
import * as PopupRedux from '../../Redux/ActionsReducers/PopupRedux';
export const useGridFilterExpressionEditor = () => {
  var _a, _b, _c;
  const adaptable = useAdaptable();
  const dispatch = useDispatch();
  const currentLayout = useSelector(state => {
    return state.Layout.Layouts.find(l => l.Name == state.Layout.CurrentLayout);
  });
  const isReadOnly = Boolean(currentLayout === null || currentLayout === void 0 ? void 0 : currentLayout.IsReadOnly);
  const gridFilter = currentLayout === null || currentLayout === void 0 ? void 0 : currentLayout.GridFilter;
  const [isAdaptableReady, setIsAdaptableReady] = React.useState(false);
  const [expression, setExpression] = React.useState(() => {
    return adaptable.api.gridFilterApi.getCurrentGridFilterExpression();
  });
  const namedQueries = (_a = useSelector(state => state.NamedQuery.NamedQueries)) !== null && _a !== void 0 ? _a : [];
  const cachedQueries = (_b = useSelector(state => state.System.CachedQueries)) !== null && _b !== void 0 ? _b : [];
  const isSuspended = (_c = gridFilter === null || gridFilter === void 0 ? void 0 : gridFilter.IsSuspended) !== null && _c !== void 0 ? _c : false;
  const onAddCachedQuery = cachedQuery => {
    dispatch(SystemRedux.SystemCachedQueryAdd(cachedQuery));
  };
  const gridFilterHelpPage = adaptable.ModuleService.getModuleById('GridFilter').moduleInfo.HelpPage;
  const gridFilterLinkDisabled = !adaptable.api.internalApi.isDocumentationLinksDisplayed();
  React.useEffect(() => {
    adaptable.api.eventApi.on('AdaptableReady', () => {
      setIsAdaptableReady(true);
    });
  }, []);
  React.useEffect(() => {
    var _a;
    if ((gridFilter === null || gridFilter === void 0 ? void 0 : gridFilter.Expression) != expression) {
      setExpression((_a = gridFilter === null || gridFilter === void 0 ? void 0 : gridFilter.Expression) !== null && _a !== void 0 ? _a : '');
    }
  }, [gridFilter === null || gridFilter === void 0 ? void 0 : gridFilter.Expression]);
  const isExpressionValid = expression == '' || adaptable.api.internalApi.getQueryLanguageService().validateBoolean(expression, NamedQueryModuleId).isValid;
  const isExpressionNamedQuery = namedQueries.find(sq => sq.BooleanExpression == expression) != null;
  let availableColumns = adaptable.api.columnApi.getColumns().map(col => {
    return {
      label: col.friendlyName,
      onClick: () => {
        // Bogdan - im sure there is a better way to do this check with fancy operators i dont know
        const newExp = StringExtensions.IsNotNullOrEmpty(expression) ? expression : '';
        setExpression(newExp + `[${col.columnId}]`);
      }
    };
  });
  const clearQuery = () => {
    adaptable.api.gridFilterApi.clearGridFilter();
    setExpression('');
  };
  const runQuery = (newExpression = expression) => {
    if (StringExtensions.IsNullOrEmpty(newExpression) && StringExtensions.IsNotNullOrEmpty(gridFilter === null || gridFilter === void 0 ? void 0 : gridFilter.Expression)) {
      // user pressed enter key with an empty input => clear existing expression
      adaptable.api.gridFilterApi.setGridFilterExpression('');
      return;
    }
    if (!adaptable.api.internalApi.getQueryLanguageService().validateBoolean(newExpression, NamedQueryModuleId).isValid) {
      return;
    }
    if (StringExtensions.IsNotNullOrEmpty(expression)) {
      let cachedQuery = {
        expression: expression,
        time: new Date()
      };
      if (cachedQuery) {
        onAddCachedQuery(cachedQuery);
      }
    }
    adaptable.api.gridFilterApi.setGridFilterExpression(newExpression);
  };
  const gridFilterAccessLevel = React.useMemo(() => {
    return adaptable.api.entitlementApi.getEntitlementAccessLevelForModule(GridFilterModuleId);
  }, []);
  const namedQueryModuleAccessLevel = adaptable.api.entitlementApi.getEntitlementAccessLevelForModule(NamedQueryModuleId);
  const onShowNamedQueries = (value, popup) => dispatch(PopupRedux.PopupShowScreen(NamedQueryModuleId, popup, {
    action: 'New',
    source: 'Other',
    value
  }));
  const saveQuery = () => {
    const namedqueryPopuName = adaptable.api.internalApi.getModuleService().getModuleById(NamedQueryModuleId).moduleInfo.Popup;
    onShowNamedQueries(expression, namedqueryPopuName);
  };
  const suspendGridFilter = () => {
    adaptable.api.gridFilterApi.suspendGridFilter();
  };
  const unSuspendGridFilter = () => {
    adaptable.api.gridFilterApi.unSuspendGridFilter();
  };
  return {
    isReadOnly,
    namedQueryModuleAccessLevel,
    cachedQueries,
    expression,
    setExpression,
    isExpressionNamedQuery,
    isExpressionValid,
    isSuspended,
    gridFilter,
    isAdaptableReady,
    namedQueries,
    onAddCachedQuery,
    availableColumns,
    runQuery,
    clearQuery,
    onExpand: () => adaptable.api.gridFilterApi.openUIEditorForGridFilter(expression),
    saveQuery,
    suspendGridFilter,
    unSuspendGridFilter,
    setGridFilterExpression: expression => {
      adaptable.api.gridFilterApi.setGridFilterExpression(expression);
    },
    gridFilterAccessLevel,
    gridFilterHelpPage,
    gridFilterLinkDisabled
  };
};