import { ApiBase } from './ApiBase';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import * as ScheduleRedux from '../../Redux/ActionsReducers/ScheduleRedux';
import { ScheduleInternalApi } from '../Internal/ScheduleInternalApi';
export class ScheduleApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.internalApi = new ScheduleInternalApi(adaptable);
  }
  getScheduleState() {
    return this.getAdaptableState().Schedule;
  }
  getSchedules() {
    let allSchedules = [];
    allSchedules.push(...this.getReminderSchedules());
    allSchedules.push(...this.getReportSchedules());
    allSchedules.push(...this.getIPushPullSchedules());
    allSchedules.push(...this.getOpenFinSchedules());
    return allSchedules;
  }
  getReminderSchedules(config) {
    var _a;
    return (_a = this.handleLayoutAssociatedObjects(this.getScheduleState().Reminders, 'Schedule', config)) !== null && _a !== void 0 ? _a : [];
  }
  getScheduleById(id) {
    return this.getSchedules().find(schedule => (schedule === null || schedule === void 0 ? void 0 : schedule.Uuid) === id);
  }
  getActiveReminderSchedules() {
    return this.getReminderSchedules().filter(schedule => !schedule.IsSuspended);
  }
  getSuspendedReminderSchedules() {
    return this.getReminderSchedules().filter(schedule => schedule.IsSuspended);
  }
  addReminderSchedule(schedule) {
    this.dispatchAction(ScheduleRedux.ReminderScheduleAdd(schedule));
    return this.getScheduleById(schedule.Uuid);
  }
  deleteReminderSchedule(schedule) {
    this.dispatchAction(ScheduleRedux.ReminderScheduleDelete(schedule));
  }
  editReminderSchedule(schedule) {
    this.dispatchAction(ScheduleRedux.ReminderScheduleEdit(schedule));
    return this.getScheduleById(schedule.Uuid);
  }
  suspendReminderSchedule(schedule) {
    this.dispatchAction(ScheduleRedux.ReminderScheduleSuspend(schedule));
    return this.getScheduleById(schedule.Uuid);
  }
  unSuspendReminderSchedule(schedule) {
    this.dispatchAction(ScheduleRedux.ReminderScheduleUnSuspend(schedule));
    return this.getScheduleById(schedule.Uuid);
  }
  getReportSchedules(config) {
    var _a;
    return (_a = this.handleLayoutAssociatedObjects(this.getAdaptableApi().exportApi.getReportSchedules(), 'Schedule', config)) !== null && _a !== void 0 ? _a : [];
  }
  getActiveReportSchedules() {
    return this.getReportSchedules().filter(schedule => !schedule.IsSuspended);
  }
  getSuspendedReportSchedules() {
    return this.getReportSchedules().filter(schedule => schedule.IsSuspended);
  }
  addReportSchedule(schedule) {
    this.dispatchAction(ScheduleRedux.ReportScheduleAdd(schedule));
    return this.getScheduleById(schedule.Uuid);
  }
  editReportSchedule(schedule) {
    this.dispatchAction(ScheduleRedux.ReportScheduleEdit(schedule));
    return this.getScheduleById(schedule.Uuid);
  }
  deleteReportSchedule(schedule) {
    this.dispatchAction(ScheduleRedux.ReportScheduleDelete(schedule));
  }
  suspendReportSchedule(schedule) {
    this.dispatchAction(ScheduleRedux.ReportScheduleSuspend(schedule));
    return this.getScheduleById(schedule.Uuid);
  }
  unSuspendReportSchedule(schedule) {
    this.dispatchAction(ScheduleRedux.ReportScheduleUnSuspend(schedule));
    return this.getScheduleById(schedule.Uuid);
  }
  getIPushPullSchedules(config) {
    var _a;
    const ippApi = this.getAdaptableApi().pluginsApi.getipushpullPluginApi();
    return (_a = this.handleLayoutAssociatedObjects(ippApi === null || ippApi === void 0 ? void 0 : ippApi.getIPushPullSchedules(), 'Schedule', config)) !== null && _a !== void 0 ? _a : [];
  }
  getActiveIPushPullSchedules() {
    return this.getIPushPullSchedules().filter(schedule => !schedule.IsSuspended);
  }
  getSuspendedIPushPullSchedules() {
    return this.getIPushPullSchedules().filter(schedule => schedule.IsSuspended);
  }
  addIPushPullSchedule(schedule) {
    this.dispatchAction(ScheduleRedux.IPushPullScheduleAdd(schedule));
    return this.getScheduleById(schedule.Uuid);
  }
  editIPushPullSchedule(schedule) {
    this.dispatchAction(ScheduleRedux.IPushPullScheduleEdit(schedule));
    return this.getScheduleById(schedule.Uuid);
  }
  deleteIPushPullSchedule(schedule) {
    this.dispatchAction(ScheduleRedux.IPushPullScheduleDelete(schedule));
  }
  suspendIPushPullSchedule(schedule) {
    this.dispatchAction(ScheduleRedux.IPushPullScheduleSuspend(schedule));
    return this.getScheduleById(schedule.Uuid);
  }
  unSuspendIPushPullSchedule(schedule) {
    this.dispatchAction(ScheduleRedux.IPushPullScheduleUnSuspend(schedule));
    return this.getScheduleById(schedule.Uuid);
  }
  getOpenFinSchedules(config) {
    var _a;
    const openFinApi = this.getAdaptableApi().pluginsApi.getOpenFinPluginApi();
    return (_a = this.handleLayoutAssociatedObjects(openFinApi === null || openFinApi === void 0 ? void 0 : openFinApi.getOpenFinSchedules(), 'Schedule', config)) !== null && _a !== void 0 ? _a : [];
  }
  getActiveOpenFinSchedules() {
    return this.getOpenFinSchedules().filter(schedule => !schedule.IsSuspended);
  }
  getSuspendedOpenFinSchedules() {
    return this.getOpenFinSchedules().filter(schedule => schedule.IsSuspended);
  }
  addOpenFinSchedule(schedule) {
    this.dispatchAction(ScheduleRedux.OpenFinScheduleAdd(schedule));
    return this.getScheduleById(schedule.Uuid);
  }
  editOpenFinSchedule(schedule) {
    this.dispatchAction(ScheduleRedux.OpenFinScheduleEdit(schedule));
    return this.getScheduleById(schedule.Uuid);
  }
  deleteOpenFinSchedule(schedule) {
    this.dispatchAction(ScheduleRedux.OpenFinScheduleDelete(schedule));
  }
  suspendOpenFinSchedule(schedule) {
    this.dispatchAction(ScheduleRedux.OpenFinScheduleSuspend(schedule));
    return this.getScheduleById(schedule.Uuid);
  }
  unSuspendOpenFinSchedule(schedule) {
    this.dispatchAction(ScheduleRedux.OpenFinScheduleUnSuspend(schedule));
    return this.getScheduleById(schedule.Uuid);
  }
  applySchedule(schedule, scheduleType) {
    if (scheduleType == 'Reminder') {
      const reminderSchedule = schedule;
      //   const reminder: Reminder = reminderSchedule.Reminder;
      const alert = {
        alertType: 'generic',
        header: reminderSchedule.Header,
        message: reminderSchedule.Message,
        alertDefinition: {
          MessageType: reminderSchedule.MessageType,
          AlertProperties: {
            DisplayNotification: reminderSchedule.DisplayNotification
          },
          Rule: undefined,
          Scope: undefined
        }
      };
      this.getAdaptableApi().alertApi.showAdaptableAlert(alert);
      if (reminderSchedule.DisplaySystemStatusMessage) {
        this.getAdaptableApi().systemStatusApi.setSystemStatus(reminderSchedule.Header, reminderSchedule.MessageType, reminderSchedule.Message);
      }
    } else if (scheduleType == 'Report') {
      const reportSchedule = schedule;
      this.getAdaptableApi().exportApi.runReport(reportSchedule.ReportName, reportSchedule.ExportDestination);
    } else if (scheduleType == 'ipushpull') {
      const ippApi = this.getAdaptableApi().pluginsApi.getipushpullPluginApi();
      const iPushPullSchedule = schedule;
      if (iPushPullSchedule.Transmission == 'Snapshot') {
        ippApi.sendSnapshot(iPushPullSchedule.IPushPullReport);
      } else if (iPushPullSchedule.Transmission == 'Live Data') {
        ippApi.startLiveData(iPushPullSchedule.IPushPullReport);
      }
    } else if (scheduleType == 'OpenFin') {
      const openFinApi = this.getAdaptableApi().pluginsApi.getOpenFinPluginApi();
      const openFinSchedule = schedule;
      openFinApi.startLiveData(openFinSchedule.OpenFinReport);
    }
    // now also send the action to Store so it can be audited if required
    this.dispatchAction(ScheduleRedux.ScheduleJobRun(schedule, scheduleType));
  }
  openScheduleSettingsPanel() {
    this.showModulePopup(ModuleConstants.ScheduleModuleId);
  }
}