import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Text } from 'rebass';
import * as NamedQueryRedux from '../../Redux/ActionsReducers/NamedQueryRedux';
import SimpleButton from '../../components/SimpleButton';
import { useAdaptable } from '../AdaptableContext';
import { Tabs } from '../../components/Tabs';
import { NamedQueryContext } from '../../components/ExpressionEditor/NamedQueryContext';
import { OnePageWizard, useOnePageWizardContext } from './OnePageWizards';
import kebabCase from 'lodash/kebabCase';
export const SummaryTag = props => React.createElement(Text, Object.assign({}, props));
export const SummaryText = props => React.createElement(Text, Object.assign({
  fontSize: 2,
  mb: 3
}, props));
export const FormDescriptionText = props => React.createElement(Text, Object.assign({
  fontSize: 2,
  mt: 1
}, props));
export const OnePageAdaptableWizardContext = React.createContext({
  data: null,
  sections: [],
  moduleInfo: null,
  api: null,
  namedQuery: false
});
export function useOnePageAdaptableWizardContext() {
  return React.useContext(OnePageAdaptableWizardContext);
}
export const OnePageAdaptableWizard = props => {
  const {
    api
  } = useAdaptable();
  const dispatch = useDispatch();
  const saveNamedQuery = React.useCallback(namedQuery => dispatch(NamedQueryRedux.NamedQueryAdd(namedQuery)), []);
  const [namedQuery, setNamedQuery] = useState(false);
  const name = props.moduleInfo.FriendlyName ? `${kebabCase(props.moduleInfo.FriendlyName)}-wizard` : '';
  const extraContext = {
    data: props.data,
    api,
    namedQuery,
    moduleInfo: props.moduleInfo,
    sections: props.sections
  };
  const sections = props.sections.map(section => {
    if (section === '-') {
      return '-';
    }
    const isValid = typeof section.isValid === 'function' ? (data, innerContext) => {
      return section.isValid(data, api, Object.assign(Object.assign({}, innerContext), extraContext));
    } : void 0;
    const isVisible = typeof section.isVisible === 'function' ? (data, innerContext) => {
      return section.isVisible(data, api, Object.assign(Object.assign({}, innerContext), extraContext));
    } : void 0;
    return Object.assign(Object.assign({}, section), {
      isValid,
      isVisible
    });
  });
  const handleClickFinish = () => {
    var _a;
    if (namedQuery && namedQuery.Name) {
      saveNamedQuery(namedQuery);
    }
    (_a = props.onFinish) === null || _a === void 0 ? void 0 : _a.call(props, props.data);
  };
  return React.createElement(OnePageAdaptableWizardContext.Provider, {
    value: extraContext
  }, React.createElement(NamedQueryContext.Provider, {
    value: {
      namedQuery,
      setNamedQuery
    }
  }, React.createElement(OnePageWizard, Object.assign({}, props, {
    name: name,
    sections: sections,
    onFinish: handleClickFinish
  }))));
};
export const OnePageWizardSummary = () => {
  const {
    sections,
    data,
    api
  } = useOnePageAdaptableWizardContext();
  const {
    setCurrentSection
  } = useOnePageWizardContext();
  const renderEdit = index => React.createElement(SimpleButton, {
    px: 1,
    variant: "text",
    style: {
      textDecoration: 'underline',
      display: 'inline-block',
      fontSize: 'var(--ab-font-size-2)'
    },
    onClick: () => {
      setCurrentSection(index);
    }
  }, "edit");
  return React.createElement(Box, {
    className: "ab-OnePageWizardSummary"
  }, sections.map((section, index) => {
    if (section === '-') {
      return null;
    }
    if (!section.renderSummary) {
      return null;
    }
    return React.createElement(Tabs, {
      keyboardNavigation: false,
      mt: index ? 2 : 0,
      key: index
    }, React.createElement(Tabs.Tab, null, section.title, " ", renderEdit(index)), React.createElement(Tabs.Content, null, section.renderSummary(data, api)));
  }).filter(Boolean));
};