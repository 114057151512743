import * as React from 'react';
import { IconSelector } from '../../../components/IconSelector';
import { useAdaptable } from '../../AdaptableContext';
/**
 * This component connects to adaptable to retrieve custom icons
 */
export const AdaptableIconSelector = props => {
  var _a, _b;
  const adaptable = useAdaptable();
  const userInterfaceOptions = (_a = adaptable.api.optionsApi.getAdaptableOptions()) === null || _a === void 0 ? void 0 : _a.userInterfaceOptions;
  const customDefinedIcons = (_b = userInterfaceOptions.customIcons) !== null && _b !== void 0 ? _b : [];
  return React.createElement(IconSelector, Object.assign({
    customIcons: customDefinedIcons
  }, props));
};