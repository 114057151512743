var am = Object.create;
var kr = Object.defineProperty,
  lm = Object.defineProperties,
  sm = Object.getOwnPropertyDescriptor,
  um = Object.getOwnPropertyDescriptors,
  cm = Object.getOwnPropertyNames,
  bo = Object.getOwnPropertySymbols,
  dm = Object.getPrototypeOf,
  Wr = Object.prototype.hasOwnProperty,
  fl = Object.prototype.propertyIsEnumerable;
var $r = Math.pow,
  ml = (o, e, t) => e in o ? kr(o, e, {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: t
  }) : o[e] = t,
  h = (o, e) => {
    for (var t in e || (e = {})) Wr.call(e, t) && ml(o, t, e[t]);
    if (bo) for (var t of bo(e)) fl.call(e, t) && ml(o, t, e[t]);
    return o;
  },
  D = (o, e) => lm(o, um(e));
var un = (o, e) => {
  var t = {};
  for (var n in o) Wr.call(o, n) && e.indexOf(n) < 0 && (t[n] = o[n]);
  if (o != null && bo) for (var n of bo(o)) e.indexOf(n) < 0 && fl.call(o, n) && (t[n] = o[n]);
  return t;
};
var pm = (o, e) => () => (e || o((e = {
  exports: {}
}).exports, e), e.exports);
var mm = (o, e, t, n) => {
  if (e && typeof e == "object" || typeof e == "function") for (let r of cm(e)) !Wr.call(o, r) && r !== t && kr(o, r, {
    get: () => e[r],
    enumerable: !(n = sm(e, r)) || n.enumerable
  });
  return o;
};
var jr = (o, e, t) => (t = o != null ? am(dm(o)) : {}, mm(e || !o || !o.__esModule ? kr(t, "default", {
  value: o,
  enumerable: !0
}) : t, o));
var He = (o, e, t) => new Promise((n, r) => {
  var i = s => {
      try {
        l(t.next(s));
      } catch (u) {
        r(u);
      }
    },
    a = s => {
      try {
        l(t.throw(s));
      } catch (u) {
        r(u);
      }
    },
    l = s => s.done ? n(s.value) : Promise.resolve(s.value).then(i, a);
  l((t = t.apply(o, e)).next());
});
var zo = pm((hT, ql) => {
  ql.exports = function (o, e, t, n, r) {
    var i, a;
    if (n === void 0) n = 0;else if (n = n | 0, n < 0 || n >= o.length) throw new RangeError("invalid lower bound");
    if (r === void 0) r = o.length - 1;else if (r = r | 0, r < n || r >= o.length) throw new RangeError("invalid upper bound");
    for (; n <= r;) if (i = n + (r - n >>> 1), a = +t(o[i], e, i, o), a < 0) n = i + 1;else if (a > 0) r = i - 1;else return i;
    return ~n;
  };
});
function St(o, {
  wait: e
}) {
  let t = null;
  return function (...n) {
    let r = this;
    t !== null && clearTimeout(t), t = setTimeout(() => {
      o.apply(r, n);
    }, e);
  };
}
import * as ge from "react";
var te = (...o) => o.filter(e => !!`${e}`).join(" ");
import * as Yt from "react";
import { useRef as Pl } from "react";
function Ft(o) {
  let e = !1,
    t = null;
  return (...r) => (e || (e = !0, t = o(...r)), t);
}
var gl = (o, e) => o - e;
var fm = (o, e) => gl(o.revision, e.revision),
  le = class {
    constructor(e) {
      this.map = new Map();
      this.length = 0;
      this.revision = 0;
      this.emptyKey = Symbol("emptyKey");
      this.visit = e => {
        this.map.forEach((t, n) => this.visitKey(n, this.map, [], e));
      };
      this.visitDepthFirst = e => {
        this.visitWithNext([], e);
      };
      this.visitWithNext = (e, t, n = this.map, r, i) => {
        if (!n) return;
        let a = 0,
          l = n.has(this.emptyKey),
          s = !i;
        if (r !== void 0) {
          if (r < 0) return;
          r--;
        }
        let u = (c, d) => {
          let p = n.get(d);
          if (!p) return;
          let {
              map: f
            } = p,
            m = d === this.emptyKey;
          if (m && !s) return;
          let g = m ? [] : [...e, d],
            C = f ? () => this.visitWithNext(g, t, f, r !== void 0 ? r - 1 : void 0) : void 0;
          p.hasOwnProperty("value") ? (t(p.value, g, a, C), a++) : C == null || C();
        };
        l && (u(void 0, this.emptyKey), s = !1, a = 0), n.forEach(u);
      };
      this.fill(e);
    }
    static clone(e) {
      let t = new le();
      return e.visit((n, r) => {
        t.set(r, n.value);
      }), t;
    }
    fill(e) {
      e && e.forEach(t => {
        let [n, r] = t;
        this.set(n, r);
      });
    }
    getValuesStartingWith(e, t, n) {
      let r = [];
      return this.getStartingWith(e, (i, a) => {
        r.push(a);
      }, t, n), r;
    }
    getKeysStartingWith(e, t, n) {
      let r = [];
      return this.getStartingWith(e, i => {
        r.push(i);
      }, t, n), r;
    }
    getStartingWith(e, t, n, r) {
      let i = this.map,
        a,
        l = !1;
      if (e.length) for (let s = 0, u = e.length; s < u; s++) {
        let c = e[s];
        if (a = i.get(c), !a || !a.map) if (l = !0, s === u - 1) {
          l = !0;
          break;
        } else return;
        i = a.map;
      }
      a && a.value !== void 0 && (n || t(e, a.value)), !l && this.visitWithNext(e, (s, u, c, d) => {
        t(u, s), d == null || d();
      }, i, r, n);
    }
    getMapAt(e) {
      let t = this.map,
        n;
      if (!e.length) return this.map;
      for (let r = 0, i = e.length; r < i; r++) {
        let a = e[r];
        if (n = t.get(a), !n || !n.map) return;
        t = n.map;
      }
      return t;
    }
    getAllChildrenSizeFor(e) {
      let t = this.map,
        n;
      if (!e.length) return this.length;
      for (let r = 0, i = e.length; r < i; r++) {
        let a = e[r];
        if (n = t.get(a), !n || !n.map) return 0;
        t = n.map;
      }
      return n.length;
    }
    getDirectChildrenSizeFor(e) {
      var n;
      let t = this.map;
      e.length || (e = [this.emptyKey]);
      for (let r = 0, i = e.length; r < i; r++) {
        let a = e[r],
          l = r === i - 1,
          s = t.get(a);
        if (!s || !s.map) return 0;
        if (t = s.map, l) return (n = t == null ? void 0 : t.size) != null ? n : 0;
      }
      return 0;
    }
    set(e, t) {
      let n = this.map;
      e.length || (e = [this.emptyKey]);
      for (let r = 0, i = e.length; r < i; r++) {
        let a = e[r],
          l = r === i - 1,
          s = n.get(a) || {
            length: 0
          };
        l ? (s.revision = this.revision++, s.value = t, n.set(a, s), this.length++) : (s.map || (s.map = new Map(), n.set(a, s)), s.length++, n = s.map);
      }
      return this;
    }
    get(e) {
      let t = this.map;
      e.length || (e = [this.emptyKey]);
      for (let n = 0, r = e.length; n < r; n++) {
        let i = e[n],
          a = n === r - 1,
          l = t.get(i);
        if (a) return l ? l.value : void 0;
        if (!l || !l.map) return;
        t = l.map;
      }
    }
    get size() {
      return this.length;
    }
    clear() {
      let e = t => {
        t.forEach((n, r) => {
          let {
            map: i
          } = n;
          i && e(i);
        }), t.clear();
      };
      e(this.map), this.length = 0, this.revision = 0;
    }
    delete(e) {
      let t = this.map;
      e.length ? e = [...e] : e = [this.emptyKey];
      let n = [t],
        r = [],
        i = !1;
      for (let a = 0, l = e.length; a < l; a++) {
        let s = e[a],
          u = a === l - 1,
          c = t.get(s);
        if (u) {
          c && (c.hasOwnProperty("value") && (delete c.value, delete c.revision, i = !0, r.forEach(d => {
            d.length--;
          }), this.length--), c.map && c.map.size === 0 && delete c.map, c.map || t.delete(s));
          break;
        } else {
          if (!c || !c.map) {
            i = !1;
            break;
          }
          r.push(c), t = c.map, n.push(t);
        }
      }
      for (; n.length;) {
        let a = n.pop(),
          l = e.length;
        if (e.pop(), l > 0 && (a == null ? void 0 : a.size) === 0) {
          let s = n[n.length - 1],
            u = e[e.length - 1],
            c = s == null ? void 0 : s.get(u);
          c && (delete c.map, c.hasOwnProperty("value") || s.delete(u));
        }
      }
      return i;
    }
    has(e) {
      let t = this.map;
      e.length || (e = [this.emptyKey]);
      for (let n = 0, r = e.length; n < r; n++) {
        let i = e[n],
          a = n === r - 1,
          l = t.get(i);
        if (a) return l ? l.hasOwnProperty("value") : !1;
        if (!l || !l.map) return !1;
        t = l.map;
      }
      return !1;
    }
    visitKey(e, t, n, r) {
      let i = t.get(e);
      if (!i) return;
      let {
          map: a
        } = i,
        l = e === this.emptyKey ? [] : [...n, e],
        s = Ft(() => {
          a && a.forEach((u, c) => {
            this.visitKey(c, a, l, r);
          });
        });
      i.hasOwnProperty("value") && r(i, l, s), s();
    }
    getArray(e) {
      let t = [];
      return this.visit((n, r) => {
        let i = e(D(h({}, n), {
          keys: r
        }));
        r.length === 0 ? t.splice(0, 0, i) : t.push(i);
      }), t;
    }
    valuesAt(e) {
      let t = this.getMapAt(e);
      if (!t) return [];
      let n = [];
      return t.forEach(r => {
        r.value !== void 0 && n.push(r.value);
      }), n;
    }
    values() {
      return this.sortedIterator(e => e.value);
    }
    keys() {
      return this.sortedIterator(t => t.keys);
    }
    entries() {
      return this.sortedIterator(e => [e.keys, e.value]);
    }
    topDownEntries() {
      return this.getArray(e => [e.keys, e.value]);
    }
    topDownKeys() {
      return this.getArray(e => e.keys);
    }
    topDownValues() {
      return this.getArray(e => e.value);
    }
    sortedIterator(e) {
      let t = [];
      this.visit((r, i) => {
        t.push(D(h({}, r), {
          keys: i
        }));
      }), t.sort(fm);
      function* n() {
        for (let r = 0, i = t.length; r < i; r++) yield e(t[r]);
      }
      return n();
    }
  };
function ve() {
  return globalThis;
}
var Bn = ["#0000CC", "#0000FF", "#0033CC", "#0033FF", "#0066CC", "#0066FF", "#0099CC", "#0099FF", "#00CC00", "#00CC33", "#00CC66", "#00CC99", "#00CCCC", "#00CCFF", "#3300CC", "#3300FF", "#3333CC", "#3333FF", "#3366CC", "#3366FF", "#3399CC", "#3399FF", "#33CC00", "#33CC33", "#33CC66", "#33CC99", "#33CCCC", "#33CCFF", "#6600CC", "#6600FF", "#6633CC", "#6633FF", "#66CC00", "#66CC33", "#9900CC", "#9900FF", "#9933CC", "#9933FF", "#99CC00", "#99CC33", "#CC0000", "#CC0033", "#CC0066", "#CC0099", "#CC00CC", "#CC00FF", "#CC3300", "#CC3333", "#CC3366", "#CC3399", "#CC33CC", "#CC33FF", "#CC6600", "#CC6633", "#CC9900", "#CC9933", "#CCCC00", "#CCCC33", "#FF0000", "#FF0033", "#FF0066", "#FF0099", "#FF00CC", "#FF00FF", "#FF3300", "#FF3333", "#FF3366", "#FF3399", "#FF33CC", "#FF33FF", "#FF6600", "#FF6633", "#FF9900", "#FF9933", "#FFCC00", "#FFCC33"],
  hl = Symbol("color"),
  Ro = new WeakMap();
Ro.set(Bn, Bn.map(o => 0));
var gm = (o = Bn) => {
    var n, r;
    let e = [];
    Ro.has(o) ? e = Ro.get(o) : (e = o.map(i => 0), Ro.set(o, e));
    let t = e.reduce((i, a, l, s) => a < s[i] ? l : i, 0);
    return e[t] != null && e[t]++, (r = (n = o[t]) != null ? n : o[0]) != null ? r : Bn[0];
  },
  Io = ":",
  Ur = "*",
  Cl = "-",
  hm = ",",
  Cm = "debug",
  Sm = "diffdebug",
  bl = !1,
  Kn = new le(),
  wo = new Map();
function Sl(o, e) {
  let t = o.split(Io),
    n = new le();
  n.set(t, !0);
  let r = e.split(Io),
    i = new Set(r).has(Ur),
    a = i ? r.slice(0, r.indexOf(Ur)) : r;
  if (n.getKeysStartingWith(a, i).length > 0) return !0;
}
function yl(o, e) {
  let t = `channel=${o}_permissions=${e}`;
  if (wo.has(t)) return wo.get(t);
  let n = e.split(hm);
  function r(l) {
    return wo.set(t, l), l;
  }
  let i = [],
    a = [];
  n.forEach(l => {
    l.includes(Ur) ? a.push(l) : i.push(l);
  });
  for (let l = 0; l < i.length; l++) {
    let s = i[l],
      u = s.startsWith(Cl);
    if (u && (s = s.substring(Cl.length)), Sl(o, s)) return r(!u);
  }
  for (let l = 0; l < a.length; l++) {
    let s = a[l],
      u = s.startsWith("-");
    if (u && (s = s.substring(1)), Sl(o, s)) return r(!u);
  }
  return r(!1);
}
var Yr = () => {
    var o, e, t;
    return (t = (e = yt.enable) != null ? e : ve() && ((o = ve().localStorage) == null ? void 0 : o.getItem(Cm))) != null ? t : "";
  },
  ym = () => {
    var o, e, t;
    return (t = (e = yt.diffenable) != null ? e : ve() && ((o = ve().localStorage) == null ? void 0 : o.getItem(Sm))) != null ? t : `${bl}`;
  },
  xo = "",
  Rl = bl;
function qr(o) {
  Rl = `${ym()}` == "true", xo !== o && (xo = o, wo.clear());
}
function wl(o) {
  qr(Yr()), typeof ve() != "undefined" && ve().addEventListener && ve().addEventListener("storage", function () {
    qr(Yr());
  });
  function e(t, n) {
    var m;
    let r = n ? `${n}${Io}${t}` : t,
      i = r.split(Io);
    if (Kn.has(i)) return Kn.get(i);
    let a = Kn.get(i.slice(0, -1)),
      l = (m = a ? a.logFn : yt.logFn) != null ? m : Il,
      s = l,
      u,
      c = 0,
      d = () => u != null ? u : yl(r, xo),
      p = gm(yt.colors),
      f = Object.defineProperties((...g) => {
        if (yl(r, xo)) {
          let C = Date.now();
          if (c && Rl) {
            let S = C - c;
            s(`%c[${r}]`, `color: ${p}`, `+${S}ms:`);
          }
          c = C;
          let T = [],
            b;
          if (g.forEach(S => {
            if (S[hl]) b === void 0 && (b = !0), T.push(...S);else {
              if (typeof S != "string" && typeof S != "number") {
                b = !1;
                return;
              }
              T.push(`${S}%s`);
            }
          }), b) {
            let S = [`%c[${r}]%c %s`, `color: ${p}`, "", ...T, ""];
            s(...S);
          } else s(`%c[${r}]`, `color: ${p}`, ...g);
        }
      }, {
        channel: {
          value: r
        },
        color: {
          value: (g, ...C) => {
            let T = [`%c${C.join(" ")}%c%s`, `color: ${g}`, ""];
            return T.toString = () => C.join(" "), T[hl] = !0, T;
          }
        },
        extend: {
          value: g => e(g, r)
        },
        enabled: {
          get: () => d()
        },
        logFn: {
          configurable: !1,
          get: () => s,
          set: g => {
            s = g != null ? g : l;
          }
        },
        destroy: {
          value: () => {
            Kn.delete(i);
          }
        }
      });
    return Kn.set(i, f), f;
  }
  return e(o);
}
var Il = console.log.bind(console),
  Tl;
Object.defineProperty(wl, "enable", {
  get: () => Tl,
  set: o => {
    Tl = o, qr(Yr());
  }
});
var yt = wl;
yt.colors = Bn;
yt.logFn = Il;
var xl = yt("InfiniteTable"),
  nt = o => {
    let e = xl.extend(o);
    return e.logFn = console.log.bind(console), e;
  },
  vo = o => {
    let e = xl.extend(`${o}:error`);
    return e.logFn = console.error.bind(console), e;
  },
  kn = () => kn;
kn.extend = () => kn;
var pt = class {
  constructor(e) {
    this.debug = kn, this.error = kn;
  }
};
import * as vl from "react";
import { useMemo as Tm, useRef as Xr, useEffect as bm, useLayoutEffect as Rm, useCallback as wm } from "react";
var Wn = (o, e, t = {
    debounce: 0
  }) => {
    var l;
    let n = (l = t.debounce) != null ? l : 0,
      r = window.ResizeObserver,
      i = n ? St(e, {
        wait: n
      }) : e,
      a = new r(s => {
        var p;
        let u = s[0],
          {
            width: c,
            height: d
          } = u.contentRect;
        if ((p = u.borderBoxSize) != null && p[0]) d = u.borderBoxSize[0].blockSize, c = u.borderBoxSize[0].inlineSize;else {
          let f = o.getBoundingClientRect();
          d = f.height, c = f.width;
        }
        i({
          width: c,
          height: d
        });
      });
    return a.observe(o), () => {
      a.disconnect();
    };
  },
  $n = (o, e, t = {
    earlyAttach: !1,
    debounce: 0
  }) => {
    let n = Xr({
        width: 0,
        height: 0
      }),
      r = i => {
        let a;
        return o.current && (a = Wn(o.current, l => {
          l = {
            width: Math.round(l.width),
            height: Math.round(l.height)
          };
          let s = n.current;
          (s.width !== l.width || s.height !== l.height) && (n.current = l, i(l));
        }, {
          debounce: t.debounce
        })), () => {
          a && a();
        };
      };
    bm(() => t.earlyAttach ? () => {} : r(e), [o.current, e, t.earlyAttach, t.debounce]), Rm(() => t.earlyAttach ? r(e) : () => {}, [o.current, e, t.earlyAttach]);
  },
  Im = o => {
    let e = Tm(() => ({
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        boxSizing: "border-box"
      }), []),
      {
        notifyOnMount: t,
        earlyAttach: n
      } = o,
      r = Xr(!0),
      i = Xr(null),
      a = wm(l => {
        if (r.current && !t) {
          r.current = !1;
          return;
        }
        o.onResize(l);
      }, [o.onResize]);
    return $n(i, a, {
      earlyAttach: n || !1
    }), vl.createElement("div", {
      ref: i,
      style: e
    });
  };
Im.defaultProps = {
  notifyOnMount: !0,
  earlyAttach: !1
};
var xm = vo("CSSVariableWatch"),
  vm = nt("CSSVariableWatch"),
  Pm = {
    position: "absolute",
    pointerEvents: "none",
    width: 0,
    height: 0,
    lineHeight: 0,
    fontSize: 0,
    overflow: "hidden"
  },
  Dm = o => {
    let e = Pl(0),
      t = Yt.useCallback(({
        height: n
      }) => {
        n != null && n !== e.current && (e.current = n, o.onChange(n));
      }, [o.onChange]);
    $n(o.ref, t, {
      earlyAttach: !0
    }), Yt.useLayoutEffect(() => {
      let n = o.ref.current.getBoundingClientRect().height;
      if (n) return e.current = n, vm(`Variable ${o.varName} found and equals ${n}.`), o.onChange(n);
      xm(`Specified variable ${o.varName} not found or does not have a numeric value.`);
    }, []);
  },
  Po = o => {
    let e = Pl(null);
    Dm(D(h({}, o), {
      ref: e
    }));
    let t = o.varName.startsWith("var(") ? o.varName : `var(${o.varName})`,
      {
        allowInts: n = !1
      } = o;
    return Yt.createElement("div", {
      "data-name": "css-variable-watcher",
      "data-var": o.varName,
      style: Pm
    }, Yt.createElement("div", {
      ref: e,
      style: {
        height: n ? `calc(1px * ${t})` : t
      }
    }));
  };
import * as Mo from "react";
import * as Dl from "react";
var Zr;
function jn() {
  return Zr || (Zr = Dl.createContext({
    api: null,
    getState: () => null,
    assignState: () => null,
    getDataSourceMasterContext: () => {},
    componentState: null,
    componentActions: null
  }));
}
import * as Ml from "react";
var Jr;
function Do() {
  return Jr || (Jr = Ml.createContext(void 0));
}
function cn() {
  let o = jn();
  return Mo.useContext(o).componentState;
}
function he() {
  let o = jn();
  return Mo.useContext(o);
}
function Ne() {
  let o = Do();
  return Mo.useContext(o);
}
import * as qt from "react";
import { useCallback as pf, useEffect as li, useRef as mf, useState as ff } from "react";
import * as Eo from "react";
function Mm(o) {
  let {
    height: e,
    width: t,
    count: n
  } = o;
  return Eo.createElement("div", {
    "data-count": n,
    "data-placeholder-width": void 0,
    "data-placeholder-height": void 0,
    "data-name": "SpacePlaceholder",
    style: {
      height: e,
      width: t,
      zIndex: -1,
      opacity: 0,
      pointerEvents: "none",
      contain: "strict"
    }
  });
}
var El = Eo.memo(Mm);
var Al = "mlx2t3";
import * as Ao from "react";
import { useRef as Am } from "react";
import { useEffect as Em } from "react";
var Fl = (o, e) => {
  Em(() => {
    let t = o == null ? void 0 : o.current,
      n = i => {
        let a = i.target;
        e == null || e({
          scrollTop: a.scrollTop,
          scrollLeft: a.scrollLeft
        });
      },
      r = {
        passive: !1
      };
    return t == null || t.addEventListener("scroll", n, r), () => {
      t == null || t.removeEventListener("scroll", n);
    };
  }, [e, o == null ? void 0 : o.current]);
};
var _l = {
    true: "_1ueijco1",
    false: "_1ueijco2",
    visible: "_1ueijco3",
    auto: "_1ueijco4",
    hidden: "_1ueijco5"
  },
  Ol = {
    true: "_1ueijco6",
    false: "_1ueijco7",
    visible: "_1ueijco8",
    auto: "_1ueijco9",
    hidden: "_1ueijcoa"
  },
  Vl = {
    true: "_1ueijcob",
    false: "_1ueijcoc",
    visible: "_1ueijcod",
    auto: "_1ueijcoe",
    hidden: "_1ueijcof"
  },
  zl = "_1ueijco0 _16lm1iw0";
var Gl = o => {
  let e = "";
  return typeof o == "boolean" || typeof o == "string" ? e = _l[`${o}`] : e = te(Ol[`${o.horizontal}`], Vl[`${o.vertical}`]), e;
};
var Fm = "InfiniteVirtualScrollContainer",
  Ll = Ao.forwardRef(function (e, t) {
    let {
        children: n,
        scrollable: r = !0,
        onContainerScroll: i,
        className: a,
        tabIndex: l,
        style: s
      } = e,
      u = t != null ? t : Am(null);
    return Fl(u, i), Ao.createElement("div", {
      ref: u,
      style: s,
      tabIndex: l,
      className: te(a, Fm, zl, Gl(r))
    }, n);
  });
import * as No from "react";
import { useEffect as ts, useLayoutEffect as km, useMemo as Wm } from "react";
import * as Hl from "react";
import { useEffect as _m, useRef as Om, useState as Vm } from "react";
function zm(o) {
  let [e, t] = Vm(o.updater.get),
    n = Om(null);
  return _m(() => {
    function r(a) {
      o.useraf ? (n.current != null && cancelAnimationFrame(n.current), n.current = requestAnimationFrame(() => {
        t(a);
      })) : t(a);
    }
    let i = o.updater.onChange(r);
    return () => {
      n.current != null && cancelAnimationFrame(n.current), i();
    };
  }, [o.updater, o.useraf]), e != null ? e : null;
}
var Un = Hl.memo(zm);
function xe(o = !1) {
  let e = null,
    t = [],
    n = null,
    r = (i, a) => {
      let l = [];
      if (o) n != null && (cancelAnimationFrame(n), n = null), requestAnimationFrame(() => {
        e = i, n = null;
        for (let s = 0, u = t.length; s < u; s++) l.push(t[s](i));
        a == null || a(l);
      });else {
        e = i;
        for (let s = 0, u = t.length; s < u; s++) l.push(t[s](i));
        a == null || a(l);
      }
    };
  return r.get = () => e, r.onChange = i => (t.push(i), () => {
    t = t.filter(a => a !== i);
  }), r.destroy = () => {
    r(null), t.length = 0;
  }, r.getListenersCount = () => t.length, r;
}
import * as ri from "react";
function Qr(...o) {
  if (!o.length) return [];
  let e = new Map();
  o.forEach(n => {
    var r;
    for (let i = 0, a = n.length; i < a; i++) {
      let l = n[i],
        s = (r = e.get(l)) != null ? r : 0;
      e.set(l, s + 1);
    }
    return e;
  });
  let t = o.length;
  return o[0].filter(n => e.get(n) === t);
}
var be = ve().requestAnimationFrame || (o => setTimeout(o, 16)),
  ot = ve().cancelAnimationFrame || (o => clearTimeout(o));
function Z(o) {
  return o.slice(4, -1);
}
var $ = {
    currentColumnTransformX: "var(--_1slvgu90)",
    y: "var(--_1slvgu91)",
    activeCellRowOffset: "var(--_1slvgu92)",
    activeCellRowHeight: "var(--_1slvgu93)",
    activeCellOffsetX: "var(--_1slvgu94)",
    activeCellOffsetY: "var(--_1slvgu95)",
    scrollTopForActiveRow: "var(--_1slvgu96)",
    activeCellColWidth: "var(--_1slvgu97)",
    activeCellColOffset: "var(--_1slvgu98)",
    columnReorderEffectDurationAtIndex: "var(--_1slvgu99)",
    columnWidthAtIndex: "var(--_1slvgu9a)",
    columnOffsetAtIndex: "var(--_1slvgu9b)",
    columnOffsetAtIndexWhileReordering: "var(--_1slvgu9c)",
    columnZIndexAtIndex: "var(--_1slvgu9d)",
    pinnedStartWidth: "var(--_1slvgu9e)",
    pinnedEndWidth: "var(--_1slvgu9f)",
    pinnedEndOffset: "var(--_1slvgu9g)",
    computedVisibleColumnsCount: "var(--_1slvgu9h)",
    baseZIndexForCells: "var(--_1slvgu9i)",
    bodyWidth: "var(--_1slvgu9j)",
    bodyHeight: "var(--_1slvgu9k)",
    scrollbarWidthHorizontal: "var(--_1slvgu9l)",
    scrollbarWidthVertical: "var(--_1slvgu9m)",
    scrollLeft: "var(--_1slvgu9n)",
    scrollTop: "var(--_1slvgu9o)"
  },
  Ve = {
    color: {
      accent: "var(--infinite-accent-color)",
      error: "var(--infinite-error-color)",
      color: "var(--infinite-color)"
    },
    spacing: {
      0: "var(--infinite-space-0)",
      1: "var(--infinite-space-1)",
      2: "var(--infinite-space-2)",
      3: "var(--infinite-space-3)",
      4: "var(--infinite-space-4)",
      5: "var(--infinite-space-5)",
      6: "var(--infinite-space-6)",
      7: "var(--infinite-space-7)",
      8: "var(--infinite-space-8)",
      9: "var(--infinite-space-9)",
      10: "var(--infinite-space-10)"
    },
    fontSize: {
      0: "var(--infinite-font-size-0)",
      1: "var(--infinite-font-size-1)",
      2: "var(--infinite-font-size-2)",
      3: "var(--infinite-font-size-3)",
      4: "var(--infinite-font-size-4)",
      5: "var(--infinite-font-size-5)",
      6: "var(--infinite-font-size-6)",
      7: "var(--infinite-font-size-7)"
    },
    fontFamily: "var(--infinite-font-family)",
    minHeight: "var(--infinite-min-height)",
    borderRadius: "var(--infinite-border-radius)",
    background: "var(--infinite-background)",
    iconSize: "var(--infinite-icon-size)",
    components: {
      LoadMask: {
        padding: "var(--infinite-load-mask-padding)",
        color: "var(--infinite-load-mask-color)",
        textBackground: "var(--infinite-load-mask-text-background)",
        overlayBackground: "var(--infinite-load-mask-overlay-background)",
        overlayOpacity: "var(--infinite-load-mask-overlay-opacity)",
        borderRadius: "var(--infinite-load-mask-border-radius)"
      },
      Header: {
        background: "var(--infinite-header-background)",
        color: "var(--infinite-header-color)",
        columnHeaderHeight: "var(--infinite-column-header-height)"
      },
      HeaderCell: {
        background: "var(--infinite-header-cell-background)",
        hoverBackground: "var(--infinite-header-cell-hover-background)",
        padding: "var(--infinite-header-cell-padding)",
        paddingX: "var(--infinite-header-cell-padding-x)",
        paddingY: "var(--infinite-header-cell-padding-y)",
        iconSize: "var(--infinite-header-cell-icon-size)",
        menuIconLineWidth: "var(--infinite-header-cell-menu-icon-line-width)",
        sortIconMargin: "var(--infinite-header-cell-sort-icon-margin)",
        resizeHandleActiveAreaWidth: "var(--infinite-resize-handle-active-area-width)",
        resizeHandleWidth: "var(--infinite-resize-handle-width)",
        resizeHandleHoverBackground: "var(--infinite-resize-handle-hover-background)",
        resizeHandleConstrainedHoverBackground: "var(--infinite-resize-handle-constrained-hover-background)",
        filterOperatorPaddingX: "var(--infinite-filter-operator-padding-x)",
        filterEditorPaddingX: "var(--infinite-filter-editor-padding-x)",
        filterEditorMarginX: "var(--infinite-filter-editor-margin-x)",
        filterOperatorPaddingY: "var(--infinite-filter-operator-padding-y)",
        filterEditorPaddingY: "var(--infinite-filter-editor-padding-y)",
        filterEditorMarginY: "var(--infinite-filter-editor-margin-y)",
        filterEditorBackground: "var(--infinite-filter-editor-background)",
        filterEditorBorder: "var(--infinite-filter-editor-border)",
        filterEditorFocusBorderColor: "var(--infinite-filter-editor-focus-border-color)",
        filterEditorBorderRadius: "var(--infinite-filter-editor-border-radius)",
        filterEditorColor: "var(--infinite-filter-editor-color)"
      },
      Cell: {
        padding: "var(--infinite-cell-padding)",
        borderWidth: "var(--infinite-cell-border-width)",
        border: "var(--infinite-cell-border)",
        borderInvisible: "var(--infinite-cell-border-invisible)",
        borderRadius: "var(--infinite-cell-border-radius)",
        reorderEffectDuration: "var(--infinite-column-reorder-effect-duration)",
        pinnedBorder: "var(--infinite-pinned-cell-border)",
        color: "var(--infinite-cell-color)",
        selectedBackground: "var(--infinite-selected-cell-background)",
        selectedBackgroundDefault: "var(--infinite-selected-cell-background-default)",
        selectedBackgroundAlpha: "var(--infinite-selected-cell-background-alpha)",
        selectedBackgroundAlphaWhenTableUnfocused: "var(--infinite-selected-cell-background-alpha--table-unfocused)",
        selectedBorderColor: "var(--infinite-selected-cell-border-color)",
        selectedBorderWidth: "var(--infinite-selected-cell-border-width)",
        selectedBorderStyle: "var(--infinite-selected-cell-border-style)",
        selectedBorder: "var(--infinite-selected-cell-border)",
        activeBackgroundAlpha: "var(--infinite-active-cell-background-alpha)",
        activeBackgroundAlphaWhenTableUnfocused: "var(--infinite-active-cell-background-alpha--table-unfocused)",
        activeBackground: "var(--infinite-active-cell-background)",
        activeBackgroundDefault: "var(--infinite-active-cell-background-default)",
        activeBorderColor: "var(--infinite-active-cell-border-color)",
        activeBorderWidth: "var(--infinite-active-cell-border-width)",
        activeBorderStyle: "var(--infinite-active-cell-border-style)",
        activeBorder: "var(--infinite-active-cell-border)"
      },
      SelectionCheckBox: {
        marginInline: "var(--infinite-selection-checkbox-margin-inline)"
      },
      Menu: {
        background: "var(--infinite-menu-background)",
        color: "var(--infinite-menu-color)",
        padding: "var(--infinite-menu-padding)",
        cellPaddingVertical: "var(--infinite-menu-cell-padding-vertical)",
        cellPaddingHorizontal: "var(--infinite-menu-cell-padding-horizontal)",
        cellMarginVertical: "var(--infinite-menu-cell-margin-vertical)",
        itemDisabledBackground: "var(--infinite-menu-item-disabled-background)",
        itemActiveBackground: "var(--infinite-menu-item-active-background)",
        itemActiveOpacity: "var(--infinite-menu-item-active-opacity)",
        itemPressedOpacity: "var(--infinite-menu-item-pressed-opacity)",
        itemPressedBackground: "var(--infinite-menu-item-pressed-background)",
        itemDisabledOpacity: "var(--infinite-menu-item-disabled-opacity)",
        borderRadius: "var(--infinite-menu-border-radius)",
        shadowColor: "var(--infinite-menu-shadow-color)"
      },
      RowDetail: {
        background: "var(--infinite-rowdetail-background)",
        padding: "var(--infinite-rowdetail-padding)",
        gridHeight: "var(--infinite-rowdetail-grid-height)"
      },
      Row: {
        background: "var(--infinite-row-background)",
        oddBackground: "var(--infinite-row-odd-background)",
        selectedBackground: "var(--infinite-row-selected-background)",
        activeBackground: "var(--infinite-active-row-background)",
        activeBorderColor: "var(--infinite-active-row-border-color)",
        activeBorderWidth: "var(--infinite-active-row-border-width)",
        activeBorderStyle: "var(--infinite-active-row-border-style)",
        activeBorder: "var(--infinite-active-row-border)",
        activeBackgroundAlpha: "var(--infinite-active-row-background-alpha)",
        activeBackgroundAlphaWhenTableUnfocused: "var(--infinite-active-row-background-alpha--table-unfocused)",
        hoverBackground: "var(--infinite-row-hover-background)",
        selectedHoverBackground: "var(--infinite-row-selected-hover-background)",
        groupRowBackground: "var(--infinite-group-row-background)",
        groupRowColumnNesting: "var(--infinite-group-row-column-nesting)",
        groupNesting: "var(--infinite-dont-override-group-row-nesting-length)",
        pointerEventsWhileScrolling: "var(--infinite-row-pointer-events-while-scrolling)"
      },
      ColumnCell: {
        background: "var(--infinite-column-cell-bg-dont-override)"
      }
    }
  },
  Nl = "column-header-height";
function dn(o, e) {
  let t = o;
  if (!t) return null;
  if (t && t.matches(e)) return t;
  for (; t = t.parentElement;) if (t.matches(e)) return t;
  return null;
}
function Kl(o, e, t) {
  let n = o;
  if (!n) return null;
  if (n && n.matches(e)) return n;
  for (; n = n.parentElement;) {
    if (n.matches(e)) return n;
    if (n === t) return null;
  }
  return null;
}
var _t = "Infinite",
  Re = {
    rootClassName: _t
  };
var Bl = `.${Re.rootClassName}`;
function ei(o) {
  return dn(o, Bl);
}
var kl = Z($.scrollLeft),
  Gm = Z($.scrollTop),
  Wl = Z($.columnWidthAtIndex),
  Fo = Z($.columnOffsetAtIndex),
  $l = Z($.columnReorderEffectDurationAtIndex),
  Lm = Z($.columnOffsetAtIndexWhileReordering),
  Hm = Z($.columnZIndexAtIndex);
function Tt(o, e, t) {
  let n = t ? ei(t) : document.querySelector(Bl);
  Nm(o, e, n);
}
function Nm(o, e, t) {
  if (t) {
    let n = $[o] ? Z($[o]) : o;
    t.style.setProperty(n, `${e}`);
  }
}
function pn(o, e) {
  if (e) for (var t in o) e.style.setProperty($[t] ? Z($[t]) : t, `${o[t]}`);
}
function _o(o, e, t) {
  Tt(`${Wl}-${o}`, typeof e == "number" ? e + "px" : e, t);
}
function mn(o, e, t) {
  Tt(`${Fo}-${o}`, typeof e == "number" ? e + "px" : e, t);
}
function ti(o, e, t) {
  Tt(`${Fo}-${o.computedVisibleIndex}`, o.computedPinned ? `calc( ${o.computedAbsoluteOffset + e}px + var(${kl}))` : `${o.computedAbsoluteOffset + e}px`, t);
}
function ni(o, e, t) {
  Tt(`${Hm}-${o}`, `${e}`, t);
}
function Yn(o, e, t) {
  Tt(`${Lm}-${o}`, typeof e == "number" ? `calc( var(${Fo}-${o}) + ${e}px )` : e, t);
}
function Oo(o, e, t) {
  Tt(`${$l}-${o}`, t != null ? t : "", e);
}
function jl(o, e) {
  let t = `${$l}-${o}`;
  Tt(t, Ve.components.Cell.reorderEffectDuration, e);
}
function Vo(o) {
  return `${Wl}-${o}`;
}
function Ul(o) {
  return `${Fo}-${o}`;
}
function Yl(o, e) {
  pn({
    [kl]: `${o.scrollLeft}px`,
    [Gm]: `${o.scrollTop}px`
  }, e);
}
var Zl = jr(zo());
var oi = o => {
    let {
        start: e,
        end: t
      } = o,
      [n, r] = e,
      [i, a] = t,
      l = i - n,
      s = a - r;
    return l * s;
  },
  Jl = o => {
    let {
        start: e,
        end: t
      } = o,
      [n] = e,
      [r] = t;
    return r - n;
  },
  fn = {
    horizontal: !0,
    vertical: !0
  },
  Xl = (o, e) => o - e,
  qn = typeof window != "undefined" ? requestAnimationFrame : o => setTimeout(o, 0),
  Xn = class extends pt {
    constructor(t) {
      let n = `MatrixBrain${t ? `:${t}` : ""}`;
      super(n);
      this.scrolling = !1;
      this.width = 0;
      this.name = "";
      this.height = 0;
      this.cols = 0;
      this.rows = 0;
      this.rowHeight = 0;
      this.colWidth = 0;
      this.verticalTotalSize = 0;
      this.horizontalTotalSize = 0;
      this.horizontalRenderCount = void 0;
      this.verticalRenderCount = void 0;
      this.horizontalRenderRange = {
        startIndex: 0,
        endIndex: 0
      };
      this.verticalRenderRange = {
        startIndex: 0,
        endIndex: 0
      };
      this.extraSpanCells = [];
      this.scrollPosition = {
        scrollLeft: 0,
        scrollTop: 0
      };
      this.onScrollFns = [];
      this.onRenderRangeChangeFns = new Set();
      this.onVerticalRenderRangeChangeFns = new Set();
      this.onHorizontalRenderRangeChangeFns = new Set();
      this.onDestroyFns = [];
      this.destroyed = !1;
      this.onRenderCountChangeFns = new Set();
      this.onAvailableSizeChangeFns = new Set();
      this.onScrollStartFns = [];
      this.onScrollStopFns = [];
      this.scrollTimeoutId = 0;
      this.scrollStopDelay = 250;
      this.fixedColsStart = 0;
      this.fixedColsEnd = 0;
      this.fixedRowsStart = 0;
      this.fixedRowsEnd = 0;
      this.setScrollStopDelay = t => {
        this.scrollStopDelay = t;
      };
      this.getRowCount = () => this.rows;
      this.getColCount = () => this.cols;
      this.update = t => {
        let {
            rows: n,
            cols: r,
            rowHeight: i,
            colWidth: a,
            width: l,
            height: s
          } = t,
          u = typeof l == "number",
          c = typeof s == "number",
          d = u && l !== this.width,
          p = c && s !== this.height;
        this.setAvailableSize({
          width: l,
          height: s
        }, {
          skipUpdateRenderCount: !0
        });
        let f = typeof n == "number",
          m = typeof r == "number",
          g = f && n !== this.rows,
          C = m && r !== this.cols;
        f && (this.rows = n), m && (this.cols = r);
        let T = i != null,
          b = a != null,
          S = T && i !== this.rowHeight,
          y = b && a !== this.colWidth;
        T && (this.rowHeight = i), b && (this.colWidth = a);
        let w = t.rowspan != null,
          v = t.colspan != null,
          I = w && t.rowspan != this.rowspan,
          R = v && t.colspan != this.colspan;
        I && (this.rowspan = t.rowspan), R && (this.colspan = t.colspan);
        let x = C || y || d || R,
          E = g || S || p || I;
        (x || E) && this.updateRenderCount({
          horizontal: x,
          vertical: E
        });
      };
      this.setRowsAndCols = ({
        rows: t,
        cols: n
      }) => {
        let r = t === this.rows,
          i = n === this.cols;
        this.rows = t, this.cols = n, this.updateRenderCount({
          horizontal: !i,
          vertical: !r
        });
      };
      this.updateRenderCount = (t = fn) => {
        this.reset(t), this.doUpdateRenderCount(t);
      };
      this.doUpdateRenderCount = (t = fn) => {
        (!this.width || !this.height) && this.setRenderCount({
          horizontal: 0,
          vertical: 0
        }), this.setRenderCount(this.computeRenderCount(t));
      };
      this.setScrolling = t => {
        let n = this.scrolling;
        this.scrolling = t, t && (this.scrollTimeoutId && clearTimeout(this.scrollTimeoutId), this.scrollTimeoutId = setTimeout(() => {
          this.setScrolling(!1), this.scrollTimeoutId = 0;
        }, this.scrollStopDelay)), t !== n && (t ? this.notifyScrollStart() : this.notifyScrollStop());
      };
      this.notifyScrollStart = () => {
        if (this.destroyed) return;
        let t = this.onScrollStartFns;
        for (let n = 0, r = t.length; n < r; n++) t[n]();
      };
      this.notifyScrollStop = () => {
        if (this.destroyed) return;
        let t = this.onScrollStopFns,
          n = this.scrollPosition,
          r = this.getRenderRange();
        for (let i = 0, a = t.length; i < a; i++) t[i](n, r);
      };
      this.setScrollPosition = (t, n) => {
        this.setScrolling(!0);
        let r = t.scrollLeft !== this.scrollPosition.scrollLeft,
          i = t.scrollTop !== this.scrollPosition.scrollTop;
        this.scrollPosition = t, (r || i) && (n == null || n(t), this.updateRenderRange({
          horizontal: r,
          vertical: i
        }), this.notifyScrollChange());
      };
      this.notifyAvailableSizeChange = () => {
        if (this.destroyed) return;
        let t = this.onAvailableSizeChangeFns,
          n = this.getAvailableSize();
        t.forEach(r => {
          qn(() => {
            this.destroyed || t.has(r) && r(n);
          });
        });
      };
      this.notifyRenderRangeChange = () => {
        if (this.destroyed) return;
        let t = this.onRenderRangeChangeFns,
          n = this.getRenderRange();
        t.forEach(r => {
          qn(() => {
            this.destroyed || t.has(r) && r(n);
          });
        });
      };
      this.notifyVerticalRenderRangeChange = () => {
        if (this.destroyed) return;
        let t = this.onVerticalRenderRangeChangeFns,
          n = this.verticalRenderRange;
        t.forEach(r => {
          qn(() => {
            this.destroyed || t.has(r) && r([n.startIndex, n.endIndex]);
          });
        });
      };
      this.notifyHorizontalRenderRangeChange = () => {
        if (this.destroyed) return;
        let t = this.onHorizontalRenderRangeChangeFns,
          n = this.horizontalRenderRange;
        t.forEach(r => {
          qn(() => {
            this.destroyed || t.has(r) && r([n.startIndex, n.endIndex]);
          });
        });
      };
      this.computeDirectionalRenderCount = (t, n, r, i) => {
        let a = 0,
          l = t === "horizontal" ? i.width : i.height;
        if (l -= this.getFixedSize(t), l <= 0) return 0;
        if (typeof n == "function") {
          let s = [];
          for (let c = 0; c < r; c++) s.push(this.getItemSize(c, t));
          s.sort(Xl);
          let u = 0;
          for (let c = 0; c < r && (u += s[c], a++, !(u > l)); c++);
          a += 1;
        } else a = (n ? Math.ceil(l / n) : 0) + 1;
        return a = Math.min(r, a), a;
      };
      this.isColFixedEnd = t => this.fixedColsEnd ? t >= this.cols - this.fixedColsEnd : !1;
      this.isColFixedStart = t => this.fixedColsStart ? t < this.fixedColsStart : !1;
      this.isRowFixedEnd = t => this.fixedRowsEnd ? t >= this.rows - this.fixedRowsEnd : !1;
      this.isRowFixedStart = t => this.fixedRowsStart ? t < this.fixedRowsStart : !1;
      this.isColFixed = t => this.isColFixedStart(t) || this.isColFixedEnd(t);
      this.isRowFixed = t => this.isRowFixedStart(t) || this.isRowFixedEnd(t);
      this.getFixedEndColsWidth = () => {
        if (!this.fixedColsEnd) return 0;
        let t = 0;
        for (let n = this.cols - this.fixedColsEnd; n < this.cols; n++) t += this.getColWidth(n);
        return t;
      };
      this.getFixedEndColsOffsets = ({
        skipScroll: t
      } = {
        skipScroll: !1
      }) => {
        if (!this.fixedColsEnd) return [];
        let {
            scrollLeft: n
          } = this.scrollPosition,
          {
            width: r
          } = this.getAvailableSize(),
          i = [],
          a = [],
          l = 0;
        for (let c = this.cols - this.fixedColsEnd; c < this.cols; c++) {
          let d = this.getColWidth(c);
          a.push(d), l += d;
        }
        let s = r - l + (t ? 0 : n);
        l = 0;
        let u = 0;
        for (let c = this.cols - this.fixedColsEnd; c < this.cols; c++) {
          let d = s + l;
          i[c] = d, l += a[u], u++;
        }
        return i;
      };
      this.getFixedEndRowsOffsets = ({
        skipScroll: t
      } = {
        skipScroll: !1
      }) => {
        if (!this.fixedRowsEnd) return [];
        let {
            scrollTop: n
          } = this.scrollPosition,
          {
            height: r
          } = this.getAvailableSize(),
          i = [],
          a = [],
          l = 0;
        for (let c = this.rows - this.fixedRowsEnd; c < this.rows; c++) {
          let d = this.getRowHeight(c);
          a.push(d), l += d;
        }
        let s = r - l + (t ? 0 : n);
        l = 0;
        let u = 0;
        for (let c = this.rows - this.fixedRowsEnd; c < this.rows; c++) {
          let d = s + l;
          i[c] = d, l += a[u], u++;
        }
        return i;
      };
      this.getFixedStartColsWidth = () => {
        if (!this.fixedColsStart) return 0;
        let t = 0;
        for (let n = 0; n < this.fixedColsStart; n++) t += this.getColWidth(n);
        return t;
      };
      this.getFixedEndRowsHeight = () => {
        if (!this.fixedRowsEnd) return 0;
        let t = 0;
        for (let n = this.rows - this.fixedRowsEnd; n < this.rows; n++) t += this.getRowHeight(n);
        return t;
      };
      this.getFixedStartRowsHeight = () => {
        if (!this.fixedRowsStart) return 0;
        let t = 0;
        for (let n = 0; n < this.fixedRowsStart; n++) t += this.getRowHeight(n);
        return t;
      };
      this.computeRenderCount = (t = fn) => {
        let n = t.horizontal,
          r = t.vertical,
          i = this.horizontalRenderCount || 0,
          a = this.verticalRenderCount || 0;
        return n && (i = this.computeDirectionalRenderCount("horizontal", this.colWidth, this.cols, this.getAvailableSize())), r && (a = this.computeDirectionalRenderCount("vertical", this.rowHeight, this.rows, this.getAvailableSize())), {
          horizontal: i,
          vertical: a
        };
      };
      this.setRenderCount = ({
        horizontal: t,
        vertical: n
      }) => {
        t === void 0 && (t = this.horizontalRenderCount), n === void 0 && (n = this.verticalRenderCount);
        let r = t === this.horizontalRenderCount,
          i = n === this.verticalRenderCount;
        r && i || (this.horizontalRenderCount = t, this.verticalRenderCount = n, this.updateRenderRange({
          horizontal: !r,
          vertical: !i
        }), this.notifyRenderCountChange());
      };
      this.updateFixedCells = t => {
        t.fixedColsStart != this.fixedColsStart && (this.fixedColsStart = t.fixedColsStart || 0), t.fixedColsEnd != this.fixedColsEnd && (this.fixedColsEnd = t.fixedColsEnd || 0), t.fixedRowsStart != this.fixedRowsStart && (this.fixedRowsStart = t.fixedRowsStart || 0), t.fixedRowsEnd != this.fixedRowsEnd && (this.fixedRowsEnd = t.fixedRowsEnd || 0);
      };
      this.getFixedCellInfo = () => {
        let {
          fixedRowsStart: t,
          fixedColsStart: n,
          fixedRowsEnd: r,
          fixedColsEnd: i
        } = this;
        return {
          fixedRowsStart: t,
          fixedColsStart: n,
          fixedRowsEnd: r,
          fixedColsEnd: i
        };
      };
      this.updateRenderRange = (t = fn) => {
        this.setRenderRange(this.computeRenderRange(t));
      };
      this.getExtraSpanCellsForRange = ({
        horizontal: t,
        vertical: n
      }) => {
        let {
            colspan: r,
            rowspan: i
          } = this,
          a = [];
        if (i) {
          let l = n.startIndex,
            s = t.startIndex,
            u = t.endIndex;
          for (let c = s; c < u; c++) {
            this.computeItemSpanUpTo(l, c, "vertical");
            let d = this.rowspanParent.get(c);
            if (d[l] != l) {
              let p = l;
              for (; d[p] != p;) p--;
              a.push([p, c]);
            }
          }
        }
        if (r) {
          let l = t.startIndex,
            s = n.startIndex,
            u = n.endIndex;
          for (let c = s; c < u; c++) {
            this.computeItemSpanUpTo(c, l, "horizontal");
            let d = this.colspanParent.get(c);
            if (d[l] != l) {
              let p = l;
              for (; d[p] != p;) p--;
              a.push([c, p]);
            }
          }
        }
        return a;
      };
      this.computeRenderRange = (t = fn) => {
        let n = t.horizontal ? this.computeDirectionalRenderRange("horizontal") : this.horizontalRenderRange,
          r = t.vertical ? this.computeDirectionalRenderRange("vertical") : this.verticalRenderRange;
        return {
          horizontal: n,
          vertical: r,
          extraCells: this.getExtraSpanCellsForRange({
            horizontal: n,
            vertical: r
          })
        };
      };
      this.computeDirectionalRenderRange = t => {
        let n = t === "horizontal" ? this.horizontalRenderCount : this.verticalRenderCount,
          r = t === "horizontal" ? this.cols : this.rows;
        if (!n) return {
          startIndex: 0,
          endIndex: 0
        };
        let i = (t === "horizontal" ? this.fixedColsStart : this.fixedRowsStart) || 0,
          a = t === "horizontal" ? this.scrollPosition.scrollLeft : this.scrollPosition.scrollTop;
        a += this.getFixedStartSize(t);
        let l = this.getItemAt(a, t),
          s = l + n,
          u = Math.max(i, r - (t === "horizontal" ? this.fixedColsEnd : this.fixedRowsEnd));
        return s > u && (s = u, l = Math.max(i, s - n)), {
          startIndex: l,
          endIndex: s
        };
      };
      this.getItemAt = (t, n) => {
        let r = n === "horizontal" ? this.colWidth : this.rowHeight,
          i = n === "horizontal" ? this.cols : this.rows;
        if (typeof r != "function") return Math.min(Math.max(0, Math.floor(t / r)), i - 1);
        let a = n === "horizontal" ? this.colOffsetCache : this.rowOffsetCache,
          l = n === "horizontal" ? this.colWidthCache : this.rowHeightCache,
          s = a.length - 1,
          u = l.length - 1,
          c = a[s],
          d;
        for (; c < t;) if (s >= i || (u += 1, this.computeCacheFor(u, n), d = c, c = a[u], d === c && d != 0)) return i - 1;
        let p = (0, Zl.default)(a, t, Xl);
        return p >= 0 ? p : ~p - 1;
      };
      this.getCellOffset = (t, n) => ({
        x: this.getItemOffsetFor(n, "horizontal"),
        y: this.getItemOffsetFor(t, "vertical")
      });
      this.getItemOffsetFor = (t, n) => {
        let r = n === "horizontal" ? this.colWidth : this.rowHeight;
        if (typeof r != "function") return t * r;
        let i = n === "horizontal" ? this.colOffsetCache : this.rowOffsetCache,
          a = n === "horizontal" ? this.colWidthCache : this.rowHeightCache,
          l = i[t];
        if (l == null) {
          let s = a.length - 1;
          for (; s < t;) s += 1, this.computeCacheFor(s, n);
          l = i[t];
        }
        return l;
      };
      this.computeCacheFor = (t, n) => {
        let r = n === "horizontal" ? this.colWidth : this.rowHeight,
          i = n === "horizontal" ? this.colWidthCache : this.rowHeightCache,
          a = n === "horizontal" ? this.colOffsetCache : this.rowOffsetCache;
        if (typeof r != "function") return;
        let l = r(t);
        if (i[t] = l, t > 0) {
          let s = a[t - 1],
            u = i[t - 1];
          s == null && console.error(`Offset was not available for ${n === "horizontal" ? "col" : "row"} ${t - 1}`), u == null && console.error(`Size was not available for ${n === "horizontal" ? "col" : "row"} ${t - 1}`);
          let c = s + u;
          a[t] = c;
        }
      };
      this.computeItemSpanUpTo = (t, n, r) => {
        let i = r === "horizontal" ? n : t,
          a = r === "horizontal" ? t : n,
          l = r === "horizontal" ? this.cols : this.rows,
          s = r === "horizontal" ? this.colspan : this.rowspan;
        if (!s) return;
        let u = r === "horizontal" ? this.colspanParent : this.rowspanParent;
        u.has(a) || u.set(a, []);
        let c = r === "horizontal" ? this.colspanValue : this.rowspanValue;
        c.has(a) || c.set(a, []);
        let d = u.get(a),
          p = c.get(a);
        if (d[i] === void 0) {
          let f = d.length,
            m = l ? Math.min(i, l - 1) : 0,
            g = {
              rowIndex: t,
              colIndex: n
            };
          for (; f <= m; f++) if (d[f] === void 0) {
            g[r === "horizontal" ? "colIndex" : "rowIndex"] = f;
            let C = s(g);
            d[f] = f, p[f] = C;
            let T = f;
            for (; C > 1;) T += 1, d[T] = f, p[T] = 0, C -= 1;
          }
        }
      };
      this.getCellSpan = (t, n, r) => {
        let i = r === "horizontal" ? this.colspan : this.rowspan,
          a = r === "horizontal" ? n : t,
          l = r === "horizontal" ? t : n;
        if (!i) return 1;
        let s = r === "horizontal" ? this.colspanValue : this.rowspanValue,
          u = s.get(l);
        return (!u || u[a] === void 0) && this.computeItemSpanUpTo(t, n, r), u || (u = s.get(l)), u[a] || 1;
      };
      this.getRowspan = (t, n) => this.getCellSpan(t, n, "vertical");
      this.getColspan = (t, n) => this.getCellSpan(t, n, "horizontal");
      this.getRowspanParent = (t, n) => this.getItemSpanParent(t, n, "vertical");
      this.getColspanParent = (t, n) => this.getItemSpanParent(t, n, "horizontal");
      this.getItemSpanParent = (t, n, r) => {
        let i = r == "horizontal" ? this.colspan : this.rowspan,
          a = r === "horizontal" ? n : t,
          l = r === "horizontal" ? t : n;
        if (!i) return a;
        let s = r === "horizontal" ? this.colspanParent : this.rowspanParent,
          u = s.get(l);
        return (!u || u[a] === void 0) && this.computeItemSpanUpTo(t, n, r), u || (u = s.get(l)), u[a];
      };
      this.getRowHeightWithSpan = (t, n, r) => this.getItemSizeWithSpan(t, n, r, "vertical");
      this.getColWidthWithSpan = (t, n, r) => this.getItemSizeWithSpan(t, n, r, "horizontal");
      this.getItemSizeWithSpan = (t, n, r, i) => {
        let a = i === "horizontal" ? n : t,
          l = this.getItemSize(a, i);
        for (; r > 1;) a += 1, r -= 1, l += this.getItemSize(a, i);
        return l;
      };
      this.getRowHeight = t => this.getItemSize(t, "vertical");
      this.getColWidth = t => this.getItemSize(t, "horizontal");
      this.getItemSize = (t, n) => {
        let r = n === "horizontal" ? this.cols : this.rows,
          i = n === "horizontal" ? this.colWidth : this.rowHeight;
        if (typeof i != "function") return i;
        let a = this.getItemSizeCacheFor(t, n),
          l = n === "horizontal" ? this.colWidthCache : this.rowHeightCache;
        if (a === void 0) {
          let s = l.length,
            u = Math.min(t, r - 1);
          for (; s <= u; s++) this.computeCacheFor(s, n);
          a = this.getItemSizeCacheFor(t, n);
        }
        return a;
      };
      this.getTotalSize = () => ({
        height: this.getTotalSizeFor("vertical"),
        width: this.getTotalSizeFor("horizontal")
      });
      this.getTotalSizeFor = t => {
        let n = t === "horizontal" ? this.cols : this.rows,
          r = t === "horizontal" ? this.colWidth : this.rowHeight,
          i = t === "horizontal" ? this.horizontalTotalSize : this.verticalTotalSize;
        if (typeof r != "function") return r * n;
        if (i !== 0 && !isNaN(i)) return i;
        let a = t === "horizontal" ? this.colOffsetCache : this.rowOffsetCache,
          l = n ? this.getItemSize(n - 1, t) : 0,
          s = n ? a[n - 1] : 0,
          u = l + s;
        return t === "horizontal" ? this.horizontalTotalSize = u : this.verticalTotalSize = u, u;
      };
      this.setRenderRange = ({
        horizontal: t,
        vertical: n,
        extraCells: r
      }) => {
        let i = !1;
        (t.startIndex !== this.horizontalRenderRange.startIndex || t.endIndex !== this.horizontalRenderRange.endIndex) && (this.horizontalRenderRange = t, i = !0);
        let a = !1;
        (n.startIndex !== this.verticalRenderRange.startIndex || n.endIndex !== this.verticalRenderRange.endIndex) && (this.verticalRenderRange = n, a = !0), this.extraSpanCells = r || [], (i || a) && this.notifyRenderRangeChange(), a && this.notifyVerticalRenderRangeChange(), i && this.notifyHorizontalRenderRangeChange();
      };
      this.getRenderRangeCellCount = oi;
      this.getExtraCells = () => this.extraSpanCells;
      this.getScrollPosition = () => this.scrollPosition;
      this.getRenderRange = () => ({
        start: [this.verticalRenderRange.startIndex, this.horizontalRenderRange.startIndex],
        end: [this.verticalRenderRange.endIndex, this.horizontalRenderRange.endIndex]
      });
      this.onScroll = t => (this.onScrollFns.push(t), () => {
        this.onScrollFns = this.onScrollFns.filter(n => n !== t);
      });
      this.onScrollStart = t => (this.onScrollStartFns.push(t), () => {
        this.onScrollStartFns = this.onScrollStartFns.filter(n => n !== t);
      });
      this.onScrollStop = t => (this.onScrollStopFns.push(t), () => {
        this.onScrollStopFns = this.onScrollStopFns.filter(n => n !== t);
      });
      this.onRenderRangeChange = t => (this.onRenderRangeChangeFns.add(t), () => {
        this.onRenderRangeChangeFns.delete(t);
      });
      this.onVerticalRenderRangeChange = t => (this.onVerticalRenderRangeChangeFns.add(t), () => {
        this.onVerticalRenderRangeChangeFns.delete(t);
      });
      this.onHorizontalRenderRangeChange = t => (this.onHorizontalRenderRangeChangeFns.add(t), () => {
        this.onHorizontalRenderRangeChangeFns.delete(t);
      });
      this.onRenderCountChange = t => (this.onRenderCountChangeFns.add(t), () => {
        this.onRenderCountChangeFns.delete(t);
      });
      this.onAvailableSizeChange = t => (this.onAvailableSizeChangeFns.add(t), () => {
        this.onAvailableSizeChangeFns.delete(t);
      });
      this.onDestroy = t => (this.onDestroyFns.push(t), () => {
        this.onDestroyFns = this.onDestroyFns.filter(n => n !== t);
      });
      this.getAvailableSize = () => ({
        width: this.width,
        height: this.height
      });
      this.destroy = () => {
        this.destroyed || (this.notifyDestroy(), this.reset(), this.rowspanParent.clear(), this.colspanParent.clear(), this.destroyed = !0, this.onDestroyFns = [], this.onScrollFns = [], this.onScrollStartFns = [], this.onScrollStopFns = [], this.onRenderCountChangeFns = new Set(), this.onRenderRangeChangeFns = new Set(), this.onVerticalRenderRangeChangeFns = new Set(), this.onHorizontalRenderRangeChangeFns = new Set());
      };
      this.name = n, this.reset();
    }
    reset(t = fn) {
      t.vertical && this.resetVertical(), t.horizontal && this.resetHorizontal(), this.extraSpanCells = [];
    }
    resetVertical() {
      this.rowspanParent = new Map(), this.rowspanValue = new Map(), this.rowHeightCache = [], this.rowOffsetCache = [0], this.verticalRenderCount = void 0, this.verticalTotalSize = 0;
    }
    resetHorizontal() {
      this.colspanParent = new Map(), this.colspanValue = new Map(), this.colWidthCache = [], this.colOffsetCache = [0], this.horizontalRenderCount = void 0, this.horizontalTotalSize = 0;
    }
    setRowAndColumnSizes({
      rowHeight: t,
      colWidth: n
    }) {
      let r = n === this.colWidth,
        i = t === this.rowHeight;
      this.rowHeight = t, this.colWidth = n, this.updateRenderCount({
        horizontal: !r,
        vertical: !i
      });
    }
    setAvailableSize(t, n) {
      let {
        width: r,
        height: i
      } = t;
      r = r != null ? r : this.width, i = i != null ? i : this.height;
      let a = r === this.width,
        l = i === this.height;
      a && l || (this.width = r, this.height = i, this.notifyAvailableSizeChange(), !(n && n.skipUpdateRenderCount) && this.updateRenderCount({
        horizontal: !a,
        vertical: !l
      }));
    }
    get scrollTopMax() {
      return this.getTotalSize().height - this.height;
    }
    get scrollLeftMax() {
      return this.getTotalSize().width - this.width;
    }
    notifyScrollChange() {
      if (this.destroyed) return;
      let {
          scrollPosition: t
        } = this,
        n = this.onScrollFns;
      for (let r = 0, i = n.length; r < i; r++) n[r](t);
    }
    getFixedSize(t) {
      return t === "horizontal" ? this.getFixedStartColsWidth() + this.getFixedEndColsWidth() : this.getFixedStartRowsHeight() + this.getFixedEndRowsHeight();
    }
    getFixedStartSize(t) {
      return t === "horizontal" ? this.getFixedStartColsWidth() : this.getFixedStartRowsHeight();
    }
    getFixedEndSize(t) {
      return t === "horizontal" ? this.getFixedEndColsWidth() : this.getFixedEndRowsHeight();
    }
    notifyRenderCountChange() {
      if (this.destroyed) return;
      let {
          horizontalRenderCount: t,
          verticalRenderCount: n
        } = this,
        r = {
          horizontal: t,
          vertical: n
        },
        i = this.onRenderCountChangeFns;
      i.forEach(a => {
        qn(() => {
          this.destroyed || i.has(a) && a(r);
        });
      });
    }
    getItemSizeCacheFor(t, n) {
      return (n === "horizontal" ? this.colWidthCache : this.rowHeightCache)[t];
    }
    notifyDestroy() {
      let t = this.onDestroyFns;
      for (let n = 0, r = t.length; n < r; n++) t[n]();
    }
  };
var Go = class extends pt {
  constructor() {
    super("MappedCells");
    this.getElementFromListForColumn = (t, n) => {
      let {
          elementIndexToCell: r
        } = this,
        i = t.length - 1;
      for (let a = i; a >= 0; a--) {
        let l = t[a],
          s = r[l];
        if (s && s[1] === n) return a === i ? t.pop() : (t.splice(a, 1), l);
      }
      return t.pop();
    };
    this.getElementsOutsideRenderRange = t => {
      let {
          start: n,
          end: r
        } = t,
        [i, a] = n,
        [l, s] = r,
        u = [];
      for (let c = 0, d = this.elementIndexToCell.length; c < d; c++) {
        let p = this.elementIndexToCell[c];
        if (!p) continue;
        let [f, m] = p,
          g = f < i,
          C = f >= l,
          T = m < a,
          b = m >= s;
        (g || C || T || b) && u.push(c);
      }
      return u;
    };
    this.isCellRendered = (t, n) => this.cellToElementIndex.has([t, n]);
    this.isElementRendered = t => !!this.elementIndexToCell[t];
    this.getElementsForRowIndex = t => this.cellToElementIndex.getValuesStartingWith([t]);
    this.getRenderedNodeAtElement = t => this.renderedElements[t] || null;
    this.getRenderedCellAtElement = t => this.elementIndexToCell[t] || null;
    this.getRenderedNodeForCell = (t, n) => {
      let r = this.cellToElementIndex.get([t, n]);
      return r != null && this.renderedElements[r] || null;
    };
    this.getElementIndexForCell = (t, n) => {
      let r = this.cellToElementIndex.get([t, n]);
      return r != null ? r : null;
    };
    this.renderCellAtElement = (t, n, r, i) => {
      let a = [t, n],
        l = this.elementIndexToCell[r];
      l && this.cellToElementIndex.delete([l[0], l[1]]), i && (this.renderedElements[r] = i), this.elementIndexToCell[r] = [t, n], this.cellToElementIndex.set(a, r);
    };
    this.discardCell = (t, n) => {
      let r = [t, n],
        i = this.cellToElementIndex.get(r);
      i != null && (this.renderedElements[i] = null, this.elementIndexToCell[i] = null, this.cellToElementIndex.delete(r));
    };
    this.discardElement = t => {
      let n = this.elementIndexToCell[t];
      if (n) {
        let r = [n[0], n[1]];
        return this.renderedElements[t] = null, this.elementIndexToCell[t] = null, this.cellToElementIndex.delete(r), n;
      }
      return null;
    };
    this.discardElementsStartingWith = (t, n) => {
      let r = null,
        i = !1;
      if (t < this.elementIndexToCell.length) {
        for (let a = t, l = this.elementIndexToCell.length; a < l; a++) r = this.discardElement(a), r && (i = !0), n && n(a, r);
        this.elementIndexToCell.length = t, this.renderedElements.length = t;
      }
      return i;
    };
    this.init();
  }
  init() {
    this.elementIndexToCell = [], this.cellToElementIndex = new le(), this.renderedElements = [];
  }
  reset() {
    this.init();
  }
  destroy() {
    this.elementIndexToCell = [], this.cellToElementIndex.clear(), this.renderedElements = [];
  }
};
var Lo = class extends pt {
  constructor() {
    super("MappedVirtualRows");
    this.getElementsOutsideRenderRange = t => {
      let {
          start: n,
          end: r
        } = t,
        [i] = n,
        [a] = r,
        l = [];
      for (let s = 0, u = this.elementIndexToRowIndex.length; s < u; s++) {
        let c = this.elementIndexToRowIndex[s];
        if (c == null) continue;
        let d = c < i,
          p = c >= a;
        (d || p) && l.push(s);
      }
      return l;
    };
    this.isRowRendered = t => this.rowToElementIndex[t] != null;
    this.isElementRendered = t => this.elementIndexToRowIndex[t] != null;
    this.getRenderedNodeAtElement = t => this.renderedElements[t] || null;
    this.getRenderedRowAtElement = t => {
      let n = this.elementIndexToRowIndex[t];
      return n != null ? n : null;
    };
    this.getRenderedNodeForRow = t => {
      var r;
      let n = this.rowToElementIndex[t];
      return n != null && (r = this.renderedElements[n]) != null ? r : null;
    };
    this.getElementIndexForRow = t => {
      let n = this.rowToElementIndex[t];
      return n != null ? n : null;
    };
    this.renderRowAtElement = (t, n, r) => {
      let i = this.elementIndexToRowIndex[n];
      i != null && (this.rowToElementIndex[i] = null), r !== void 0 && (this.renderedElements[n] = r), this.elementIndexToRowIndex[n] = t, this.rowToElementIndex[t] = n;
    };
    this.discardRow = t => {
      let n = this.rowToElementIndex[t];
      n != null && (this.renderedElements[n] = null, this.elementIndexToRowIndex[n] = null, this.rowToElementIndex[t] = null);
    };
    this.discardElement = t => {
      let n = this.elementIndexToRowIndex[t];
      return n != null ? (this.renderedElements[t] = null, this.elementIndexToRowIndex[t] = null, this.rowToElementIndex[n] = null, n) : null;
    };
    this.discardElementsStartingWith = (t, n) => {
      let r = null,
        i = !1;
      if (t < this.elementIndexToRowIndex.length) {
        for (let a = t, l = this.elementIndexToRowIndex.length; a < l; a++) r = this.discardElement(a), r && (i = !0), n && n(a, r);
        this.elementIndexToRowIndex.length = t, this.renderedElements.length = t;
      }
      return i;
    };
    this.init();
  }
  init() {
    this.elementIndexToRowIndex = [], this.rowToElementIndex = [], this.renderedElements = [];
  }
  reset() {
    this.init();
  }
  destroy() {
    this.elementIndexToRowIndex = [], this.rowToElementIndex = [], this.renderedElements = [];
  }
};
var Ql = !0,
  Ho = Z($.y),
  es = Z($.scrollTop),
  Km = Z($.columnOffsetAtIndex),
  Bm = Z($.columnOffsetAtIndexWhileReordering),
  gn = class extends pt {
    constructor(t) {
      super("ReactHeadlessTableRenderer");
      this.debugId = "";
      this.destroyed = !1;
      this.scrolling = !1;
      this.cellHoverClassNames = [];
      this.itemDOMElements = [];
      this.itemDOMRefs = [];
      this.updaters = [];
      this.detailRowDOMElements = [];
      this.detailRowDOMRefs = [];
      this.detailRowUpdaters = [];
      this.items = [];
      this.detailItems = [];
      this.currentHoveredRow = -1;
      this.hoverRowUpdatesInProgress = new Map();
      this.infiniteNode = null;
      this.setDetailTransform = (t, n, {
        y: r,
        scrollTop: i,
        scrollLeft: a
      }) => {
        t.style.setProperty(Ho, i ? `calc( ${r}px + var(${es}) )` : `${r}px`), t.style.transform = `translate3d(${a || 0}px, var(${Ho}), 0)`;
      };
      this.setTransform = (t, n, r, {
        x: i,
        y: a,
        scrollLeft: l,
        scrollTop: s
      }, u) => {
        let c = `${Km}-${r}`,
          d = `${Bm}-${r}`;
        t.style.setProperty(Ho, s ? `calc( ${a}px + var(${es}) )` : `${a}px`), t.style.transform = `translate3d(var(${d}, var(${c})), var(${Ho}), 0)`, u != null;
      };
      this.getFullyVisibleRowsRange = () => {
        let {
          start: [t],
          end: [n]
        } = this.brain.getRenderRange();
        for (; !this.isRowFullyVisible(t);) if (t++, t === n) return null;
        for (; !this.isRowFullyVisible(n);) if (n--, n === t) return null;
        return {
          start: t,
          end: n
        };
      };
      this.getScrollPositionForScrollRowIntoView = (t, n = {
        offset: 0
      }) => {
        if (this.destroyed) return null;
        let {
            brain: r
          } = this,
          i = r.getScrollPosition(),
          {
            scrollAdjustPosition: a,
            offset: l = 0
          } = n;
        if (this.isRowFullyVisible(t) && !a) return i;
        let s = r.getItemOffsetFor(t, "vertical"),
          u = r.getItemSize(t, "vertical"),
          c = r.getFixedStartRowsHeight(),
          d = r.getFixedEndRowsHeight(),
          p = i.scrollTop,
          f = r.getAvailableSize();
        if (!f.height) return null;
        let m = p + f.height;
        a || (a = s > m - d ? "end" : s < p + c ? "start" : "end");
        let g = i.scrollTop;
        return a === "center" ? g = s - Math.floor((r.getAvailableSize().height - c - d) / 2) : a === "start" ? (l = -l, g += s - p + l - c) : (l += u, g += s - m + l + d), D(h({}, i), {
          scrollTop: g
        });
      };
      this.getScrollPositionForScrollColumnIntoView = (t, n = {
        offset: 0
      }) => {
        if (this.destroyed) return null;
        let {
            brain: r
          } = this,
          i = r.getScrollPosition(),
          {
            scrollAdjustPosition: a,
            offset: l = 0
          } = n;
        if (this.isColumnFullyVisible(t) && !a) return i;
        let s = r.getItemOffsetFor(t, "horizontal"),
          u = r.getItemSize(t, "horizontal"),
          c = r.getFixedStartColsWidth(),
          d = r.getFixedStartColsWidth(),
          p = i.scrollLeft,
          f = r.getAvailableSize();
        if (!f.width) return null;
        let m = p + f.width;
        a || (a = s > m - d ? "end" : s < p + c ? "start" : "end");
        let g = i.scrollLeft;
        return a === "center" ? g = s - Math.floor((r.getAvailableSize().width - c - d) / 2) : a === "start" ? (l = -l, g += s - p + l - c) : (l += u, g += s - m + l + d), D(h({}, i), {
          scrollLeft: g
        });
      };
      this.getScrollPositionForScrollCellIntoView = (t, n, r = {
        offsetLeft: 0,
        offsetTop: 0
      }) => {
        if (this.destroyed) return null;
        let i = this.getScrollPositionForScrollColumnIntoView(n, {
            scrollAdjustPosition: r.colScrollAdjustPosition || r.scrollAdjustPosition,
            offset: r.offsetLeft
          }),
          a = this.getScrollPositionForScrollRowIntoView(t, {
            scrollAdjustPosition: r.rowScrollAdjustPosition || r.scrollAdjustPosition,
            offset: r.offsetTop
          });
        if (!i || !a) return null;
        let {
            scrollLeft: l
          } = i,
          {
            scrollTop: s
          } = a;
        return {
          scrollLeft: l,
          scrollTop: s
        };
      };
      this.isRowFullyVisible = (t, n = 2) => this.isRowVisible(t, this.brain.getRowHeight(t) - n);
      this.isRowVisible = (t, n = 10) => {
        if (!this.isRowRendered(t)) return !1;
        let {
          brain: r
        } = this;
        if (r.isRowFixed(t)) return !0;
        let {
            start: [i],
            end: [a]
          } = this.brain.getRenderRange(),
          l = Math.floor((i + a) / 2);
        if (t < i || t >= a) return !1;
        if (t >= l) {
          let s = r.getItemAt(r.getAvailableSize().height + r.getScrollPosition().scrollTop - n, "vertical");
          return t <= s;
        }
        if (t < l) {
          let s = r.getItemAt(r.getScrollPosition().scrollTop + n, "vertical");
          return t >= s;
        }
        return !0;
      };
      this.isRowRendered = t => this.mappedCells.getElementsForRowIndex(t).length > 0;
      this.isCellVisible = (t, n) => this.isRowVisible(t) && this.isColumnVisible(n);
      this.isCellFullyVisible = (t, n) => this.isRowFullyVisible(t) && this.isColumnVisible(n);
      this.isColumnFullyVisible = (t, n = 2) => this.isColumnVisible(t, this.brain.getColWidth(t) - n);
      this.isColumnVisible = (t, n = 10) => {
        if (!this.isColumnRendered(t)) return !1;
        let {
          brain: r
        } = this;
        if (r.isColFixed(t)) return !0;
        let {
          start: [i, a],
          end: [l, s]
        } = r.getRenderRange();
        if (t < a || t >= s) return !1;
        let u = Math.floor((a + s) / 2);
        if (t >= u) {
          let c = r.getItemAt(r.getAvailableSize().width + r.getScrollPosition().scrollLeft - n - r.getFixedEndColsWidth(), "horizontal");
          return t <= c;
        }
        if (t < u) {
          let c = r.getItemAt(r.getScrollPosition().scrollLeft + n + r.getFixedStartColsWidth(), "horizontal");
          return t >= c;
        }
        return !0;
      };
      this.isCellRendered = (t, n) => this.isRowRendered(t) && this.isColumnRendered(n);
      this.isColumnRendered = t => {
        let {
          start: [n]
        } = this.brain.getRenderRange();
        return this.mappedCells.getRenderedNodeForCell(n, t) !== null;
      };
      this.getExtraSpanCellsForRange = t => {
        let {
            start: n,
            end: r
          } = t,
          [i, a] = n,
          [l, s] = r;
        return this.brain.getExtraSpanCellsForRange({
          horizontal: {
            startIndex: a,
            endIndex: s
          },
          vertical: {
            startIndex: i,
            endIndex: l
          }
        });
      };
      this.renderRange = (t, {
        renderCell: n,
        renderDetailRow: r,
        force: i,
        onRender: a
      }) => {
        if (this.destroyed) return [];
        let {
            start: l,
            end: s
          } = t,
          {
            mappedCells: u,
            mappedDetailRows: c
          } = this,
          d = this.getFixedRanges(t),
          p = [t, ...d],
          f = new Map(),
          m = p.map(this.getExtraSpanCellsForRange).flat();
        m && m.forEach(([I, R]) => {
          f.set(`${I}:${R}`, !0);
        });
        let g = p.reduce((I, R) => I + oi(R), 0),
          C = r ? p.reduce((I, R) => I + Jl(R), 0) : 0;
        this.itemDOMElements.length >= g && (u.discardElementsStartingWith(g, I => {
          this.updaters[I] && this.updaters[I].destroy();
        }), this.itemDOMElements.length = Math.min(this.itemDOMElements.length, g), this.itemDOMRefs.length = Math.min(this.itemDOMRefs.length, g), this.updaters.length = Math.min(this.updaters.length, g), this.items.length = Math.min(this.items.length, g)), r && this.detailRowDOMElements.length && this.detailRowDOMElements.length >= C && (c.discardElementsStartingWith(C, I => {
          this.detailRowUpdaters[I] && this.detailRowUpdaters[I].destroy();
        }), this.detailRowDOMElements.length = Math.min(this.detailRowDOMElements.length, C), this.detailRowDOMRefs.length = Math.min(this.detailRowDOMRefs.length, C), this.detailRowUpdaters.length = Math.min(this.detailRowUpdaters.length, C), this.detailItems.length = Math.min(this.detailItems.length, C));
        let T = Qr(...p.map(u.getElementsOutsideRenderRange)),
          b = r ? Qr(...p.map(c.getElementsOutsideRenderRange)) : [],
          S = T.filter(I => {
            let R = this.mappedCells.getRenderedCellAtElement(I);
            return !(R && f.has(`${R[0]}:${R[1]}`));
          });
        this.items.length > g && (this.items.length = g), r && this.detailItems.length > C && (this.detailItems.length = C);
        for (let I = this.items.length; I < g; I++) this.renderElement(I), S.splice(0, 0, I);
        if (r) for (let I = this.detailItems.length; I < C; I++) this.renderDetailElement(I), b.splice(0, 0, I);
        let y = new Map(),
          w = new Map();
        p.forEach(I => {
          let {
              start: R,
              end: x
            } = I,
            [E, M] = R,
            [V, z] = x;
          for (let P = E; P < V; P++) {
            for (let O = M; O < z; O++) {
              let B = `${P}:${O}`;
              if (y.has(B)) continue;
              y.set(B, !0);
              let k = u.isCellRendered(P, O);
              if (P === E || O === M) {
                let oe = this.isCellCovered(P, O);
                if (oe && f.has(`${oe[0]}:${oe[1]}`)) {
                  let q = u.getElementIndexForCell(P, O);
                  q != null && S.push(q);
                  continue;
                }
              }
              if (k && !i) continue;
              let J = k ? u.getElementIndexForCell(P, O) : u.getElementFromListForColumn(S, O);
              J != null && this.renderCellAtElement(P, O, J, n);
            }
            if (!r || w.has(P)) continue;
            w.set(P, !0);
            let F = c.isRowRendered(P);
            if (F && !i) continue;
            let A = F ? c.getElementIndexForRow(P) : b.pop();
            A != null && this.renderDetailRowAtElement(P, A, r);
          }
        }), m.forEach(([I, R]) => {
          if (u.isCellRendered(I, R)) {
            if (i) {
              let E = u.getElementIndexForCell(I, R);
              this.renderCellAtElement(I, R, E, n);
            }
            return;
          }
          let x = S.pop();
          x != null && this.renderCellAtElement(I, R, x, n);
        });
        let v = this.items;
        return v = [...this.items, ...this.detailItems], this.adjustFixedElementsOnScroll(), a && a(v), v;
      };
      this.getFixedRanges = t => {
        let {
          fixedRowsStart: n,
          fixedRowsEnd: r,
          fixedColsStart: i,
          fixedColsEnd: a
        } = this.brain.getFixedCellInfo();
        if (!n && !i && !r && !a) return [];
        let l = this.brain.getColCount(),
          s = this.brain.getRowCount(),
          {
            start: u,
            end: c
          } = t,
          [d, p] = u,
          [f, m] = c,
          g = [];
        if (i && (n && g.push({
          rowFixed: "start",
          colFixed: "start",
          start: [0, 0],
          end: [n, i]
        }), r && g.push({
          rowFixed: "end",
          colFixed: "start",
          start: [s - r, 0],
          end: [s, i]
        }), g.push({
          rowFixed: !1,
          colFixed: "start",
          start: [d, 0],
          end: [f, i]
        })), a && (n && g.push({
          colFixed: "end",
          rowFixed: "start",
          start: [0, l - a],
          end: [n, l]
        }), r && g.push({
          colFixed: "end",
          rowFixed: "end",
          start: [s - r, l - a],
          end: [s, l]
        }), g.push({
          colFixed: "end",
          rowFixed: !1,
          start: [d, l - a],
          end: [f, l]
        })), n) {
          let C = {
            start: [0, p],
            end: [n, m],
            rowFixed: "start",
            colFixed: !1
          };
          g.push(C);
        }
        if (r) {
          let C = {
            rowFixed: "end",
            colFixed: !1,
            start: [s - r, p],
            end: [s, m]
          };
          g.push(C);
        }
        return g;
      };
      this.isCellFixed = (t, n) => {
        let {
            fixedRowsStart: r,
            fixedRowsEnd: i,
            fixedColsStart: a,
            fixedColsEnd: l
          } = this.brain.getFixedCellInfo(),
          s = this.brain.getColCount(),
          u = this.brain.getRowCount(),
          c = !1,
          d = !1,
          p = !1;
        if (t < r && (c = !0, p = "start"), i && !c) {
          let f = u - i;
          t >= f && (c = !0, p = "end");
        }
        if (n < a && (d = !0, p = "start"), l && !d) {
          let f = s - l;
          n >= f && (d = !0, p = "end");
        }
        return {
          row: c ? p : !1,
          col: d ? p : !1
        };
      };
      this.isCellCovered = (t, n) => {
        let r = this.brain.getRowspanParent(t, n),
          i = this.brain.getColspanParent(t, n),
          a = r != t,
          l = i != n;
        return a || l ? [r, i] : !1;
      };
      this.onMouseEnter = t => {
        this.currentHoveredRow = t, !this.scrolling && this.addHoverClass(t);
      };
      this.addHoverClass = t => {
        this.mappedCells.getElementsForRowIndex(t).forEach(n => {
          let r = this.itemDOMElements[n];
          r && this.cellHoverClassNames.forEach(i => {
            r.classList.add(i);
          });
        });
      };
      this.onMouseLeave = t => {
        this.currentHoveredRow != -1 && this.currentHoveredRow === t && this.removeHoverClass(t), this.currentHoveredRow = -1, !this.scrolling && this.removeHoverClass(t);
      };
      this.removeHoverClass = t => {
        this.mappedCells.getElementsForRowIndex(t).forEach(n => {
          let r = this.itemDOMElements[n];
          r && this.cellHoverClassNames.forEach(i => {
            r.classList.remove(i);
          });
        });
      };
      this.updateHoverClassNamesForRow = t => {
        if (this.scrolling || this.hoverRowUpdatesInProgress.has(t)) return;
        this.hoverRowUpdatesInProgress.set(t, !0);
        let n = () => {
          this.currentHoveredRow != -1 && !this.scrolling && (this.currentHoveredRow === t ? this.addHoverClass(t) : this.removeHoverClass(t));
        };
        be(() => {
          this.destroyed || (n(), this.hoverRowUpdatesInProgress.delete(t));
        });
      };
      this.updateElementPosition = (t, n) => {
        if (this.destroyed) return;
        let r = this.itemDOMElements[t],
          i = this.mappedCells.getRenderedCellAtElement(t);
        if (i == null) return;
        let [a, l] = i,
          s = this.brain.getCellOffset(a, l);
        if (s == null) return;
        let {
          x: u,
          y: c
        } = s;
        if (r) if (this.updateHoverClassNamesForRow(a), r.dataset.rowIndex = a, r.dataset.colIndex = l, Ql) {
          this.setTransform(r, a, l, {
            x: u,
            y: c
          }, null), r.style.willChange = "transform", r.style.backfaceVisibility = "hidden";
          let d = n ? n.hidden : !!this.isCellCovered(a, l);
          r.style.zIndex = d ? "-1" : r.dataset.zIndex || "auto";
        } else r.style.display = "", r.style.left = `${u}px`, r.style.top = `${c}px`;
      };
      this.updateDetailElementPosition = t => {
        if (this.destroyed) return;
        let n = this.detailRowDOMElements[t],
          r = this.mappedDetailRows.getRenderedRowAtElement(t);
        if (r == null) return;
        let i = this.brain.getItemOffsetFor(r, "vertical");
        if (i == null) return;
        let a = i;
        n && (n.dataset.detailRowIndex = r, Ql && (this.setDetailTransform(n, r, {
          y: a,
          scrollLeft: this.brain.getScrollPosition().scrollLeft
        }), n.style.willChange = "transform", n.style.backfaceVisibility = "hidden", n.style.zIndex = n.dataset.zIndex || "auto"));
      };
      this.onScrollStart = () => {
        this.scrolling = !0;
      };
      this.onScrollStop = () => {
        this.scrolling = !1, this.currentHoveredRow != -1 && this.addHoverClass(this.currentHoveredRow);
      };
      this.adjustFixedElementsOnScroll = (t = this.brain.getScrollPosition()) => {
        let {
            mappedCells: n,
            brain: r,
            itemDOMElements: i,
            detailRowDOMElements: a
          } = this,
          l = this.brain.getColCount(),
          s = this.brain.getRowCount(),
          {
            fixedColsStart: u,
            fixedColsEnd: c,
            fixedRowsStart: d,
            fixedRowsEnd: p
          } = this.brain.getFixedCellInfo();
        if (a.length && this.detailRowDOMElements.forEach((P, F) => {
          if (!P) return;
          let A = this.mappedDetailRows.getRenderedRowAtElement(F);
          if (A != null) {
            let O = this.brain.getItemOffsetFor(A, "vertical");
            if (O == null) return;
            this.setDetailTransform(P, A, {
              y: O,
              scrollLeft: t.scrollLeft
            });
          }
        }), !u && !c && !d && !p) return;
        i[0] && Yl(t, this.getInfiniteNode(i[0]));
        let f = this.brain.getFixedEndColsOffsets({
            skipScroll: !0
          }),
          m = this.brain.getFixedEndRowsOffsets({
            skipScroll: !0
          }),
          {
            start: g,
            end: C
          } = this.brain.getRenderRange(),
          [T, b] = g,
          [S, y] = C;
        function w(P, F, A) {
          let O = n.getElementIndexForCell(P, F);
          if (O === null) return;
          let B = r.getCellOffset(P, F),
            k = i[O];
          O != null && k && B && A(P, F, k, B);
        }
        let v = (P, F, A, {
            x: O,
            y: B
          }) => {
            this.setTransform(A, P, F, {
              x: O,
              y: B,
              scrollLeft: !0
            }, u - F);
          },
          I = (P, F, A, O) => {
            this.setTransform(A, P, F, {
              x: O.x,
              y: O.y,
              scrollTop: !0
            }, d - P);
          },
          R = (P, F, A, {
            y: O
          }) => {
            let B = f[F];
            this.setTransform(A, P, F, {
              x: B,
              y: O,
              scrollLeft: !0
            }, l - F);
          },
          x = (P, F, A, O) => {
            this.setTransform(A, P, F, {
              x: O.x,
              y: m[P],
              scrollTop: !0
            }, s - P);
          },
          E = (P, F, A, {
            x: O,
            y: B
          }) => {
            this.setTransform(A, P, F, {
              x: O,
              scrollLeft: !0,
              y: B,
              scrollTop: !0
            }, 1e5);
          },
          M = (P, F, A, {
            x: O
          }) => {
            this.setTransform(A, P, F, {
              x: O,
              scrollLeft: !0,
              y: m[P],
              scrollTop: !0
            }, 2e5);
          },
          V = (P, F, A, O) => {
            this.setTransform(A, P, F, {
              x: f[F],
              y: O.y,
              scrollLeft: !0,
              scrollTop: !0
            }, 3e5);
          },
          z = (P, F, A) => {
            this.setTransform(A, P, F, {
              x: f[F],
              y: m[P],
              scrollLeft: !0,
              scrollTop: !0
            }, 4e5);
          };
        if (u || c) for (let P = T; P < S; P++) {
          for (let F = 0; F < u; F++) w(P, F, v);
          if (c) {
            let F = l - c;
            for (let A = F; A < l; A++) w(P, A, R);
          }
        }
        if (d || p) for (let P = b; P < y; P++) {
          for (let F = 0; F < d; F++) w(F, P, I);
          if (p) {
            let F = s - p;
            for (let A = F; A < s; A++) w(A, P, x);
          }
        }
        if (u && d) for (let P = 0; P < d; P++) for (let F = 0; F < u; F++) w(P, F, E);
        if (u && p) {
          let P = s - p;
          for (let F = P; F < s; F++) for (let A = 0; A < u; A++) w(F, A, M);
        }
        if (c && d) {
          let P = l - c;
          for (let F = 0; F < d; F++) for (let A = P; A < l; A++) w(F, A, V);
        }
        if (c && p) {
          let P = s - p,
            F = l - c;
          for (let A = P; A < s; A++) for (let O = F; O < l; O++) w(A, O, z);
        }
      };
      this.destroy = () => {
        this.destroyed || (this.destroyed = !0, this.reset(), this.onDestroy(), this.hoverRowUpdatesInProgress.clear(), this.hoverRowUpdatesInProgress = null, this.brain = null, this.mappedCells = null, this.mappedDetailRows = null);
      };
      this.brain = t, this.debugId = t.name, this.mappedCells = new Go(), this.mappedDetailRows = new Lo();
      let n = t.onScroll(this.adjustFixedElementsOnScroll),
        r = t.onAvailableSizeChange(() => {
          this.adjustFixedElementsOnScroll(), be(() => {
            this.destroyed || this.adjustFixedElementsOnScroll();
          });
        }),
        i = t.onScrollStart(this.onScrollStart),
        a = t.onScrollStop(this.onScrollStop);
      this.onDestroy = () => {
        n(), r(), i(), a();
      };
    }
    getInfiniteNode(t) {
      return this.infiniteNode || (this.infiniteNode = ei(t)), this.infiniteNode;
    }
    renderElement(t) {
      let n = i => {
        i && (this.itemDOMElements[t] = i, i.style.position = "absolute", i.style.left = "0px", i.style.top = "0px", this.updateElementPosition(t));
      };
      this.itemDOMRefs[t] = n, this.updaters[t] = xe();
      let r = ri.createElement(Un, {
        key: t,
        name: `${t}`,
        updater: this.updaters[t]
      });
      return this.items[t] = r, r;
    }
    renderDetailElement(t) {
      let n = i => {
        i && (this.detailRowDOMElements[t] = i, i.style.position = "absolute", i.style.left = "0px", this.updateDetailElementPosition(t));
      };
      this.detailRowDOMRefs[t] = n, this.detailRowUpdaters[t] = xe();
      let r = ri.createElement(Un, {
        key: `detail-${t}`,
        name: `detail-${t}`,
        updater: this.detailRowUpdaters[t]
      });
      return this.detailItems[t] = r, r;
    }
    renderDetailRowAtElement(t, n, r) {
      if (this.destroyed) return;
      let i = !1,
        a = this.brain.getRowHeight(t),
        l = this.brain.isRowFixedStart(t) ? "start" : this.brain.isRowFixedEnd(t) ? "end" : !1,
        u = r({
          rowIndex: t,
          height: a,
          rowFixed: l,
          hidden: !!i,
          onMouseEnter: () => {},
          onMouseLeave: () => {},
          domRef: this.detailRowDOMRefs[n]
        }),
        c = this.detailRowUpdaters[n];
      if (!c) {
        this.error(`Cannot find detail item updater for item ${t} at this time... sorry.`);
        return;
      }
      this.mappedDetailRows.renderRowAtElement(t, n, u), c(u), this.updateDetailElementPosition(n);
    }
    renderCellAtElement(t, n, r, i) {
      if (this.destroyed) return;
      let a = this.isCellCovered(t, n),
        l = this.brain.getRowHeight(t),
        s = this.brain.getColWidth(n),
        u = this.brain.getRowspan(t, n),
        c = this.brain.getColspan(t, n),
        d = u === 1 ? l : this.brain.getRowHeightWithSpan(t, n, u),
        p = c === 1 ? s : this.brain.getColWidthWithSpan(t, n, c),
        {
          row: f,
          col: m
        } = this.isCellFixed(t, n),
        g = !!a,
        C = i({
          rowIndex: t,
          colIndex: n,
          height: l,
          width: s,
          rowspan: u,
          colspan: c,
          rowFixed: f,
          colFixed: m,
          hidden: g,
          heightWithRowspan: d,
          widthWithColspan: p,
          onMouseEnter: this.onMouseEnter.bind(null, t),
          onMouseLeave: this.onMouseLeave.bind(null, t),
          domRef: this.itemDOMRefs[r]
        }),
        T = this.updaters[r];
      if (!T) {
        this.error(`Cannot find item updater for item ${t},${n} at this time... sorry.`);
        return;
      }
      this.mappedCells.renderCellAtElement(t, n, r, C), T(C), this.updateElementPosition(r, {
        hidden: g,
        rowspan: u,
        colspan: c
      });
    }
    reset() {
      this.itemDOMElements = [], this.itemDOMRefs = [], this.updaters = [], this.items = [], this.mappedCells.reset(), this.mappedDetailRows.reset();
    }
  };
function $m(o) {
  let e = new gn(o),
    t = xe();
  return o.onDestroy(() => {
    e.destroy(), t.destroy();
  }), {
    renderer: e,
    onRenderUpdater: t
  };
}
function jm(o) {
  let {
      brain: e,
      renderCell: t,
      renderDetailRow: n
    } = o,
    {
      renderer: r,
      onRenderUpdater: i
    } = Wm(() => o.onRenderUpdater && o.renderer ? {
      renderer: o.renderer,
      onRenderUpdater: o.onRenderUpdater
    } : $m(e), [e, o.onRenderUpdater, o.renderer]);
  return ts(() => {
    r.cellHoverClassNames = o.cellHoverClassNames || [];
  }, [r, o.cellHoverClassNames]), km(() => {
    let a = e.getRenderRange();
    r.renderRange(a, {
      onRender: i,
      force: !0,
      renderCell: t,
      renderDetailRow: n
    });
  }, [r, e, t, n, i]), ts(() => e.onRenderRangeChange(l => {
    r.renderRange(l, {
      force: !1,
      onRender: s => {
        let u = i.get();
        u && s && u.length === s.length || i(s);
      },
      renderCell: t,
      renderDetailRow: n
    });
  }), [t, n]), No.createElement(Un, {
    updater: i
  });
}
var Ko = No.memo(jm);
import * as Zn from "react";
import { useEffect as qm, useLayoutEffect as Xm, useRef as Zm } from "react";
import { useCallback as Um, useState as Ym } from "react";
var Ze = () => {
  let [o, e] = Ym(0);
  return [o, Um(() => {
    e(t => t + 1);
  }, [e])];
};
var ii = {
    visible: "nxbq1c2 nxbq1c1 _16lm1iwl _16lm1iw4 _16lm1iw1e _16lm1iw1c",
    hidden: "nxbq1c3 nxbq1c1 _16lm1iwl _16lm1iw4 _16lm1iw1e _16lm1iw1c"
  },
  Bo = "_16lm1iwl _16lm1iw4 _16lm1iw1e _16lm1iw1c _16lm1iw18 _16lm1iwu";
var ai = {
  visible: "j26lrx1 j26lrx0 _16lm1iwl _16lm1iw4 _16lm1iw1b _16lm1iw1c _16lm1iw1e",
  hidden: "j26lrx2 j26lrx0 _16lm1iwl _16lm1iw4 _16lm1iw1b _16lm1iw1c _16lm1iw1e"
};
var {
    rootClassName: Jm
  } = Re,
  Qm = `${Jm}-ActiveRowIndicator`,
  ef = {
    [Z($.activeCellOffsetY)]: $.activeCellRowOffset,
    transform: `translate3d(0px, calc( ${$.activeCellOffsetY} + var(${Z($.scrollTopForActiveRow)}, 0px)), 0px)`,
    height: $.activeCellRowHeight
  },
  tf = o => {
    let {
        brain: e
      } = o,
      t = Zm(null),
      n = o.activeRowIndex != null && e.getRowCount() > o.activeRowIndex,
      [r, i] = Ze();
    return Xm(() => {
      if (o.activeRowIndex == null) return;
      let a = o.activeRowIndex,
        l = e.getRowHeight(a),
        s = e.getItemOffsetFor(a, "vertical"),
        u = t.current,
        c = {
          activeCellRowHeight: `${l}px`,
          activeCellRowOffset: `${s}px`
        };
      pn(c, u);
    }, [o.activeRowIndex]), qm(() => {
      let a = e.onScroll(s => {
          pn({
            scrollTopForActiveRow: `${-s.scrollTop}px`
          }, t.current);
        }),
        l = e.onRenderCountChange(i);
      return () => {
        l(), a();
      };
    }, [e]), Zn.createElement("div", {
      className: Bo,
      "data-name": "active-row"
    }, Zn.createElement("div", {
      ref: t,
      "data-name": "active-row-indicator",
      className: `${Qm} ${n ? ai.visible : ai.hidden}`,
      style: n ? ef : void 0
    }));
  },
  ns = Zn.memo(tf);
import * as Jn from "react";
import { useEffect as nf, useLayoutEffect as of, useRef as rf } from "react";
var {
    rootClassName: af
  } = Re,
  lf = `${af}-ActiveCellIndicator`,
  sf = Z($.columnZIndexAtIndex),
  uf = {
    [Z($.activeCellOffsetX)]: $.activeCellColOffset,
    [Z($.activeCellOffsetY)]: $.activeCellRowOffset,
    transform: `translate3d(${$.activeCellOffsetX}, ${$.activeCellOffsetY}, 0px)`,
    width: $.activeCellColWidth,
    height: $.activeCellRowHeight
  },
  cf = (o, e, t, n) => {
    if (e == null) return;
    let r = e[0],
      i = typeof t == "function" ? t(r) : t != null ? t : o.getRowHeight(r),
      a = o.getItemOffsetFor(r, "vertical"),
      l = n.current;
    pn({
      activeCellRowHeight: `${i}px`,
      activeCellRowOffset: `${a}px`
    }, l);
  },
  df = o => {
    let {
        brain: e
      } = o,
      [t, n] = Ze(),
      r = rf(null),
      i = o.activeCellIndex != null && e.getRowCount() > o.activeCellIndex[0];
    return of(() => {
      cf(e, o.activeCellIndex, o.rowHeight, r);
    }, [o.activeCellIndex, o.rowHeight]), nf(() => {
      let a = e.onRenderCountChange(() => {
        n();
      });
      return () => {
        a();
      };
    }, [e]), Jn.createElement("div", {
      className: Bo,
      "data-name": "active-cell",
      style: i ? {
        zIndex: `var(${sf}-${o.activeCellIndex[1]})`
      } : void 0
    }, Jn.createElement("div", {
      ref: r,
      "data-name": "active-cell-indicator",
      className: `${lf} ${i ? ii.visible : ii.hidden}`,
      style: i ? uf : void 0
    }));
  },
  os = Jn.memo(df);
function ko(o, e, t) {
  t && (t.fixedColsStart || t.fixedColsEnd || t.fixedRowsStart || t.fixedRowsEnd) && o.updateFixedCells({
    fixedColsStart: t.fixedColsStart,
    fixedColsEnd: t.fixedColsEnd,
    fixedRowsStart: t.fixedRowsStart,
    fixedRowsEnd: t.fixedRowsEnd
  }), o.update({
    height: e.height,
    width: e.width,
    cols: e.cols,
    rows: e.rows,
    colWidth: e.colWidth,
    rowHeight: e.rowHeight,
    rowspan: e.rowspan,
    colspan: e.colspan
  });
}
function rs(o) {
  let T = o,
    {
      brain: e,
      scrollerDOMRef: t,
      scrollStopDelay: n,
      renderCell: r,
      renderDetailRow: i,
      activeCellRowHeight: a,
      cellHoverClassNames: l,
      renderer: s,
      activeRowIndex: u,
      activeCellIndex: c,
      onRenderUpdater: d
    } = T,
    p = un(T, ["brain", "scrollerDOMRef", "scrollStopDelay", "renderCell", "renderDetailRow", "activeCellRowHeight", "cellHoverClassNames", "renderer", "activeRowIndex", "activeCellIndex", "onRenderUpdater"]),
    f = mf(null),
    [m, g] = ff({
      width: 0,
      height: 0
    });
  li(() => {
    n != null && e.setScrollStopDelay(n);
  }, [n, e]), li(() => {
    var w;
    let b = (w = f.current) == null ? void 0 : w.parentNode;
    return b ? Wn(b, () => {
      let v = {
        height: b.clientHeight,
        width: b.clientWidth
      };
      e.update(v);
    }, {
      debounce: 50
    }) : void 0;
  }, []);
  let C = pf(b => {
    e.setScrollPosition(b, S => {
      f.current.style.transform = `translate3d(${-S.scrollLeft}px, ${-S.scrollTop}px, 0px)`;
    });
  }, [e]);
  return li(() => {
    let b = e.onRenderCountChange(() => {
      g(e.getTotalSize());
    });
    return g(e.getTotalSize()), b;
  }, [e]), qt.createElement(Ll, D(h({
    onContainerScroll: C
  }, p), {
    ref: t
  }), qt.createElement("div", {
    ref: f,
    className: Al,
    "data-name": "scroll-transform-target"
  }, qt.createElement(Ko, {
    renderer: s,
    onRenderUpdater: d,
    renderCell: r,
    renderDetailRow: i,
    brain: e,
    cellHoverClassNames: l
  }), qt.createElement(os, {
    brain: e,
    rowHeight: a,
    activeCellIndex: c
  })), qt.createElement(ns, {
    brain: e,
    activeRowIndex: u
  }), qt.createElement(El, {
    width: m.width,
    height: m.height
  }));
}
import * as hn from "react";
import { useReducer as bf, createContext as Rf, useMemo as di, useEffect as wf, useState as as, useRef as If, useLayoutEffect as xf } from "react";
function si(o, e) {
  let t = new Set();
  function n(...r) {
    let i = e ? e.getProxyTargetFromArgs(...r) : r[0];
    t.clear();
    let a = {
        get: function (u, c) {
          return t.add(c), u[c];
        }
      },
      l = new Proxy(i, a),
      s = e ? e.putProxyToArgs(l, ...r) : [l];
    return o.apply(this, s);
  }
  return {
    fn: n,
    propertyReads: t
  };
}
var ui = o => o && o.substr(0, 1).toUpperCase() + o.substr(1);
function Qn(o) {
  return o !== void 0;
}
function Ot(o, e) {
  let t = e[o];
  return Qn(t);
}
import { useCallback as gf, useEffect as hf, useRef as ci } from "react";
var is = o => {
  let e = gf(o, []),
    t = ci(!1),
    n = ci(!1),
    r = ci(),
    [i, a] = Ze();
  t.current && (n.current = !0), hf(() => (t.current || (r.current = e, t.current = !0), a(), () => {
    n.current && r.current && r.current();
  }), []);
};
import { useCallback as Cf, useRef as Sf } from "react";
function _e(o) {
  let e = Sf(o);
  return e.current = o, Cf(() => e.current, []);
}
import { useRef as yf, useLayoutEffect as Tf } from "react";
var Wo = (o, e) => {
  let t = yf(e === void 0 ? o : e);
  return Tf(() => {
    t.current = o;
  }), t.current;
};
var vf = (o, e, t) => {
    let n = o[e];
    typeof n == "function" && n(...t);
  },
  Pf = nt("rerender"),
  pi;
function ls() {
  return pi || (pi = Rf(null));
}
function Df(o, e, t, n, r, i, a) {
  let l = e();
  return Object.keys(l).reduce((s, u) => {
    let c = u;
    return Object.defineProperty(s, u, {
      set: p => {
        let f = t(),
          m = e(),
          g = m[c],
          C = !0;
        if (i && typeof i[c] == "function" && i[c](p, {
          actions: s,
          state: m
        }) === !1 && (C = !1), C) {
          let S = [p],
            y = `on${ui(u)}Change`;
          if (a && a[c]) {
            let w = a[c](p, m);
            y = w.callbackName || y, S = w.callbackParams;
          }
          vf(f, y, S);
        }
        let T = n[c];
        typeof T == "function" && (p = T(p));
        let b = !!(r != null && r[c]);
        Ot(u, f) && !b || o({
          payload: {
            updatedProps: null,
            mappedState: {
              [u]: p
            }
          }
        });
      }
    }), s;
  }, {});
}
function Mf(o, e, t) {
  let n = {};
  for (let r in o) if (o.hasOwnProperty(r)) {
    let i = o[r],
      a = Ot(r, e) ? e[r] : e[`default${ui(r)}`];
    typeof i == "function" && (a = i(a, t)), n[r] = a;
  }
  return n;
}
function bt(o) {
  return hn.memo(function (t) {
    var w, v;
    let [n] = as(() => o.initSetupState ? o.initSetupState(t) : {}),
      r = If(new Set()),
      i = di(() => o.forwardProps ? o.forwardProps(n) : {}, [n]),
      a = (v = (w = o.getParentState) == null ? void 0 : w.call(o)) != null ? v : null,
      l = _e(a);
    function s() {
      let I = {};
      i && (I = Mf(i, t, n));
      let R = h(h({}, n), I);
      if (o.mapPropsToState) {
        let {
            fn: x,
            propertyReads: E
          } = si(o.mapPropsToState, {
            getProxyTargetFromArgs: V => V.props,
            putProxyToArgs: (V, z) => [D(h({}, z), {
              props: V
            })]
          }),
          M = x({
            props: t,
            state: R,
            oldState: null,
            parentState: a
          });
        return r.current = new Set([...r.current, ...E]), h(h({}, R), M);
      }
      return R;
    }
    let [u] = as(s),
      c = _e(t),
      d = (I, R) => {
        var P;
        if (R.type === "REPLACE_STATE") return R.payload;
        let x = (P = l == null ? void 0 : l()) != null ? P : null,
          E = R.payload.mappedState,
          M = R.payload.updatedPropsToState,
          V = Object.assign({}, I);
        if (E && Object.assign(V, E), o.mapPropsToState) {
          let {
              fn: F,
              propertyReads: A
            } = si(o.mapPropsToState, {
              getProxyTargetFromArgs: B => B.props,
              putProxyToArgs: (B, k) => [D(h({}, k), {
                props: B
              })]
            }),
            O = F({
              props: c(),
              state: V,
              oldState: I,
              parentState: x
            });
          r.current = new Set([...r.current, ...A]), Object.assign(V, O);
        }
        return R.type === "ASSIGN_STATE" && Object.assign(V, R.payload), o.concludeReducer ? o.concludeReducer({
          previousState: I,
          state: V,
          updatedProps: M,
          parentState: x
        }) : V;
      },
      [p, f] = bf(d, u),
      m = _e(p),
      {
        allowedControlledPropOverrides: g
      } = o,
      C = di(() => Df(f, m, c, i, g, o.interceptActions, o.mappedCallbacks), [f, i, g]),
      T = ls(),
      b = di(() => ({
        componentState: p,
        componentActions: C,
        getComponentState: m,
        replaceState: I => {
          f({
            type: "REPLACE_STATE",
            payload: I
          });
        },
        assignState: I => {
          f({
            type: "ASSIGN_STATE",
            payload: I
          });
        }
      }), [p, C, m]),
      S = Wo(t);
    return (o.layoutEffect ? xf : wf)(() => {
      let I = t,
        R = {},
        x = 0,
        E = {},
        M = 0,
        V = {},
        z = 0;
      if (new Set([...Object.keys(I), ...Object.keys(S)]).forEach(A => {
        let O = A,
          B = S[O],
          k = I[O];
        if (O !== "children" && B !== k && (V[O] = {
          newValue: k,
          oldValue: B
        }, z++, Ot(O, t) || Ot(O, S))) if (i.hasOwnProperty(A)) {
          let J = k,
            oe = i[A];
          typeof oe == "function" && (J = oe(k)), p[O] !== J && (R[O] = J, x++);
        } else r.current.has(A) && (E[O] = I[O], M++);
      }), M > 0 || x > 0) {
        if (Pf("Triggered by new values for the following props", ...Object.keys(R != null ? R : {}), ...Object.keys(E != null ? E : {})), f({
          payload: {
            mappedState: x ? R : null,
            updatedPropsToState: M ? E : null
          }
        }), o.onPropChange) {
          for (var F in V) if (V.hasOwnProperty(F)) {
            let {
              newValue: O,
              oldValue: B
            } = V[F];
            o.onPropChange({
              name: F,
              newValue: O,
              oldValue: B
            }, t, C, p);
          }
        }
        o.onPropsChange && z && o.onPropsChange(V, t, C, p);
      }
    }), is(() => () => {
      var I;
      (I = o.cleanup) == null || I.call(o, m());
    }), hn.createElement(T.Provider, {
      value: b
    }, t.children);
  });
}
function Ie() {
  let o = ls();
  return hn.useContext(o);
}
import * as jo from "react";
var Cn = () => Re;
var ss = "_16lm1iw2 _16lm1iw1c _16lm1iw1e _16lm1iw1j _16lm1iw1h",
  us = {
    center: "_16lm1iw1q",
    stretch: "_16lm1iw1r"
  };
var mt = "_16lm1iw23 _16lm1iw24 _16lm1iw1x",
  cs = {
    pointer: "_16lm1iwi",
    default: "_16lm1iwj",
    colResize: "_16lm1iwk"
  },
  Sn = {
    flex: "_16lm1iwz",
    contents: "_16lm1iw10",
    none: "_16lm1iw11",
    block: "_16lm1iw12",
    grid: "_16lm1iw13",
    inlineBlock: "_16lm1iw14",
    inlineFlex: "_16lm1iw15",
    inlineGrid: "_16lm1iw16"
  };
var $o = {
    1: "_16lm1iwm",
    none: "_16lm1iwn"
  },
  eo = {
    column: "_16lm1iw1m",
    columnReverse: "_16lm1iw1n",
    row: "_16lm1iw1o",
    rowReverse: "_16lm1iw1p"
  };
var ds = {
    center: "_16lm1iw1s",
    spaceBetween: "_16lm1iw1t",
    spaceAround: "_16lm1iw1u",
    start: "_16lm1iw1v",
    end: "_16lm1iw1w"
  },
  ps = {
    0: "_16lm1iw1e",
    "100%": "_16lm1iw1f",
    auto: "_16lm1iw1g"
  };
var ms = {
    none: "_16lm1iwd"
  },
  fs = {
    hidden: "_16lm1iw1x",
    auto: "_16lm1iw1y",
    visible: "_16lm1iw1z"
  };
var yn = {
  relative: "_16lm1iw1",
  absolute: "_16lm1iw2",
  static: "_16lm1iw3",
  sticky: "_16lm1iw4",
  fixed: "_16lm1iw5"
};
var gs = {
  0: "_16lm1iw1c",
  "100%": "_16lm1iw1d"
};
var hs = "_16lm1iwe",
  Cs = {
    none: "_16lm1iw17"
  };
var Ss = {
  visible: "_16lm1iw20",
  hidden: "_16lm1iw21"
};
var ys = {
  1: "_16lm1iwo",
  10: "_16lm1iwp",
  100: "_16lm1iwq",
  1e3: "_16lm1iwr",
  1e4: "_16lm1iws",
  1e5: "_16lm1iwt",
  1e6: "_16lm1iwu",
  1e7: "_16lm1iwv",
  "1k": "_16lm1iww",
  "10k": "_16lm1iwx",
  "100k": "_16lm1iwy"
};
var Ts = jo.forwardRef(function (e, t) {
  let {
    rootClassName: n
  } = Cn();
  return jo.createElement("div", D(h({
    ref: t
  }, e), {
    className: te(`${n}Body`, yn.relative, $o[1], hs, e.className)
  }));
});
import * as Uo from "react";
var bs = Uo.forwardRef(function (e, t) {
  let {
    rootClassName: n
  } = Cn();
  return Uo.createElement("div", D(h({
    ref: t
  }, e), {
    className: te(`${n}Footer`, yn.relative, Sn.flex, eo.row, e.className)
  }));
});
import * as Ce from "react";
import { useCallback as Lg, useContext as Hg, useEffect as Ng, useRef as Kg } from "react";
import { createPortal as Bg } from "react-dom";
function Yo(o) {
  let e = {};
  return Object.keys(o).forEach(t => {
    e[t] = t;
  }), e;
}
import * as Rs from "react";
var De = o => {
  var n;
  let e = (n = o.size) != null ? n : "var(--infinite-icon-size)",
    t = h({
      flex: "none",
      width: e,
      height: e,
      fill: "currentColor"
    }, o.style);
  return Rs.createElement("svg", D(h({
    viewBox: "0 0 24 24"
  }, o), {
    style: t
  }), o.children);
};
import * as mi from "react";
var ws = o => mi.createElement(De, h({}, o), mi.createElement("path", {
  d: "M5,4V7H10.5V19H13.5V7H19V4H5Z"
}));
import * as fi from "react";
var Is = o => fi.createElement(De, h({}, o), fi.createElement("path", {
  d: "M11.14 4L6.43 16H8.36L9.32 13.43H14.67L15.64 16H17.57L12.86 4M12 6.29L14.03 11.71H9.96M20 14V18H2V20H22V14Z"
}));
import * as gi from "react";
var hi = o => gi.createElement(De, h({}, o), gi.createElement("path", {
  d: "M19,10H5V8H19V10M19,16H5V14H19V16Z"
}));
import * as Ci from "react";
var xs = o => Ci.createElement(De, h({}, o), Ci.createElement("path", {
  d: "M6.5,2.27L20,10.14L6.5,18L5.5,16.27L16.03,10.14L5.5,4L6.5,2.27M20,20V22H5V20H20Z"
}));
import * as Si from "react";
var vs = o => Si.createElement(De, h({}, o), Si.createElement("path", {
  d: "M5.5,4.14L4.5,5.86L15,12L4.5,18.14L5.5,19.86L19,12L5.5,4.14Z"
}));
import * as yi from "react";
var Ps = o => yi.createElement(De, h({}, o), yi.createElement("path", {
  d: "M18.5,2.27L5,10.14L18.5,18L19.5,16.27L8.97,10.14L19.5,4L18.5,2.27M5,20V22H20V20H5Z"
}));
import * as Ti from "react";
var Ds = o => Ti.createElement(De, h({}, o), Ti.createElement("path", {
  d: "M18.5,4.14L19.5,5.86L8.97,12L19.5,18.14L18.5,19.86L5,12L18.5,4.14Z"
}));
import * as bi from "react";
var Ms = o => bi.createElement(De, h({}, o), bi.createElement("path", {
  d: "M21,10H9V8H21V10M21,16H9V14H21V16M4,5H6V16H4V5M6,18V20H4V18H6Z"
}));
import * as Ri from "react";
var Es = o => Ri.createElement(De, h({}, o), Ri.createElement("path", {
  d: "M11.14 4L6.43 16H8.36L9.32 13.43H14.67L15.64 16H17.57L12.86 4M12 6.29L14.03 11.71H9.96M4 18V15H2V20H22V18Z"
}));
import * as Fi from "react";
import * as ke from "react";
import { useEffect as Yf, useState as lu } from "react";
import { useContext as Af } from "react";
import { createContext as Ef } from "react";
var wi;
function Tn() {
  return wi || (wi = Ef(null));
}
var ae = () => {
  let o = Tn();
  return Af(o);
};
import { useContext as Ff } from "react";
var As = () => {
  let o = Tn(),
    {
      state: e
    } = Ff(o);
  return e;
};
import * as bn from "react";
import { useEffect as Gf, useLayoutEffect as Lf, useState as Js } from "react";
function _f(o, e) {
  if (typeof o != "object" || !o) return o;
  var t = o[Symbol.toPrimitive];
  if (t !== void 0) {
    var n = t.call(o, e || "default");
    if (typeof n != "object") return n;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (e === "string" ? String : Number)(o);
}
function Of(o) {
  var e = _f(o, "string");
  return typeof e == "symbol" ? e : String(e);
}
function Vf(o, e, t) {
  return e = Of(e), e in o ? Object.defineProperty(o, e, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : o[e] = t, o;
}
function Fs(o, e) {
  var t = Object.keys(o);
  if (Object.getOwnPropertySymbols) {
    var n = Object.getOwnPropertySymbols(o);
    e && (n = n.filter(function (r) {
      return Object.getOwnPropertyDescriptor(o, r).enumerable;
    })), t.push.apply(t, n);
  }
  return t;
}
function _s(o) {
  for (var e = 1; e < arguments.length; e++) {
    var t = arguments[e] != null ? arguments[e] : {};
    e % 2 ? Fs(Object(t), !0).forEach(function (n) {
      Vf(o, n, t[n]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(o, Object.getOwnPropertyDescriptors(t)) : Fs(Object(t)).forEach(function (n) {
      Object.defineProperty(o, n, Object.getOwnPropertyDescriptor(t, n));
    });
  }
  return o;
}
function Os(o, e) {
  var t = {};
  for (var n in o) t[n] = e(o[n], n);
  return t;
}
var zf = (o, e, t) => {
    for (var n of Object.keys(o)) {
      var r;
      if (o[n] !== ((r = e[n]) !== null && r !== void 0 ? r : t[n])) return !1;
    }
    return !0;
  },
  Se = o => {
    var e = t => {
      var n = o.defaultClassName,
        r = _s(_s({}, o.defaultVariants), t);
      for (var i in r) {
        var a,
          l = (a = r[i]) !== null && a !== void 0 ? a : o.defaultVariants[i];
        if (l != null) {
          var s = l;
          typeof s == "boolean" && (s = s === !0 ? "true" : "false");
          var u = o.variantClassNames[i][s];
          u && (n += " " + u);
        }
      }
      for (var [c, d] of o.compoundVariants) zf(c, r, o.defaultVariants) && (n += " " + d);
      return n;
    };
    return e.variants = () => Object.keys(o.variantClassNames), e.classNames = {
      get base() {
        return o.defaultClassName.split(" ")[0];
      },
      get variants() {
        return Os(o.variantClassNames, t => Os(t, n => n.split(" ")[0]));
      }
    }, e;
  };
var Vs = "_1eexc2a5 _16lm1iwz _16lm1iw1o _16lm1iw1q _16lm1iw2 _16lm1iw22 _16lm1iw23 _16lm1iw17";
var zs = Se({
    defaultClassName: "_12zfob11y _16lm1iw19 _16lm1iw1b _16lm1iwz _16lm1iw1o _16lm1iw1q _16lm1iw1v",
    variantClassNames: {
      filtered: {
        false: "_12zfob11z",
        true: "_12zfob120"
      },
      verticalAlign: {
        start: "_12zfob121",
        end: "_12zfob122",
        center: "_12zfob123"
      },
      align: {
        start: "_12zfob124",
        end: "_12zfob125",
        center: "_12zfob126"
      }
    },
    defaultVariants: {},
    compoundVariants: []
  }),
  Gs = "_12zfob1n _16lm1iw23 _16lm1iw24 _16lm1iw1x",
  Ls = Se({
    defaultClassName: "_12zfob1o _16lm1iw12",
    variantClassNames: {
      rowActive: {
        false: "_12zfob1p",
        true: "_12zfob1q"
      },
      cellSelected: {
        false: "_12zfob1r",
        true: "_12zfob1s"
      },
      rowSelected: {
        false: "_12zfob1t",
        true: "_12zfob1u",
        null: "_12zfob1v"
      },
      groupRow: {
        false: "_12zfob1w",
        true: "_12zfob1x"
      },
      groupCell: {
        false: "_12zfob1y",
        true: "_12zfob1z"
      },
      align: {
        start: "_12zfob110",
        end: "_12zfob111",
        center: "_12zfob112"
      },
      verticalAlign: {
        start: "_12zfob113",
        end: "_12zfob114",
        center: "_12zfob115"
      },
      zebra: {
        false: "_12zfob116",
        even: "_12zfob117",
        odd: "_12zfob118"
      },
      dragging: {
        true: "_12zfob119",
        false: "_12zfob11a"
      },
      first: {
        true: "_12zfob11b",
        false: "_12zfob11c"
      },
      last: {
        true: "_12zfob11d",
        false: "_12zfob11e"
      },
      groupByField: {
        true: "_12zfob11f",
        false: "_12zfob11g"
      },
      firstInCategory: {
        true: "_12zfob11h",
        false: "_12zfob11i"
      },
      lastInCategory: {
        true: "_12zfob11j",
        false: "_12zfob11k"
      },
      pinned: {
        start: "_12zfob11l",
        end: "_12zfob11m",
        false: "_12zfob11n"
      },
      filtered: {
        true: "_12zfob11o",
        false: "_12zfob11p"
      }
    },
    defaultVariants: {},
    compoundVariants: [[{
      pinned: "start",
      lastInCategory: !0
    }, "_12zfob11q"], [{
      pinned: "end",
      firstInCategory: !0
    }, "_12zfob11r"], [{
      pinned: !1,
      lastInCategory: !0
    }, "_12zfob11s"]]
  }),
  Hs = Se({
    defaultClassName: "_12zfob19 _16lm1iw12 _16lm1iw2",
    variantClassNames: {
      pinned: {
        start: "_12zfob1a",
        end: "_12zfob1b",
        false: "_12zfob1c"
      },
      firstInCategory: {
        true: "_12zfob1d",
        false: "_12zfob1e"
      },
      lastInCategory: {
        true: "_12zfob1f",
        false: "_12zfob1g"
      },
      overflow: {
        true: "_12zfob1h",
        false: "_12zfob1i"
      }
    },
    defaultVariants: {},
    compoundVariants: [[{
      overflow: !0,
      pinned: "start"
    }, "_12zfob1j"], [{
      pinned: "start",
      firstInCategory: !0
    }, "_12zfob1k"], [{
      overflow: !0,
      pinned: "end"
    }, "_12zfob1l"], [{
      pinned: "end",
      lastInCategory: !0
    }, "_12zfob1m"]]
  }),
  Ns = "_12zfob12g _16lm1iw1b _16lm1iw19";
var Ks = "_12zfob17",
  Bs = "_12zfob12c _16lm1iwz _16lm1iw1o _16lm1iw1q _16lm1iw1",
  ks = Se({
    defaultClassName: "_12zfob12d _16lm1iw1 _16lm1iw1c",
    variantClassNames: {
      disabled: {
        true: "_12zfob12e",
        false: "_12zfob12f"
      }
    },
    defaultVariants: {},
    compoundVariants: []
  }),
  Ii = Se({
    defaultClassName: "_12zfob129 _16lm1iwz _16lm1iw1o _16lm1iw1r _16lm1iw1",
    variantClassNames: {
      active: {
        true: "_12zfob12a",
        false: "_12zfob12b"
      }
    },
    defaultVariants: {},
    compoundVariants: []
  }),
  Ws = "_12zfob128 _16lm1iwz _16lm1iw1o _16lm1iw1q",
  $s = Se({
    defaultClassName: "_12zfob11t _16lm1iw1 _16lm1iwz _16lm1iw1m _16lm1iw1u _16lm1iw21",
    variantClassNames: {
      menuVisible: {
        true: "_12zfob11u"
      },
      reserveSpaceWhenHidden: {
        true: "_12zfob11v",
        false: "_12zfob11w"
      }
    },
    defaultVariants: {},
    compoundVariants: [[{
      menuVisible: !0,
      reserveSpaceWhenHidden: !1
    }, "_12zfob11x"]]
  }),
  js = "_12zfob18 _16lm1iw2",
  Us = "_16lm1iw1",
  Ys = "_12zfob16",
  qs = Se({
    defaultClassName: "_12zfob12",
    variantClassNames: {
      align: {
        start: "_12zfob13",
        center: "_12zfob14",
        end: "_12zfob15"
      }
    },
    defaultVariants: {},
    compoundVariants: []
  }),
  Xs = "_12zfob127 _16lm1iw1x _16lm1iw1 _16lm1iwz _16lm1iw1o";
var Ye = "InfiniteTableIcon";
var Zs = "_6aunoi0 _16lm1iwz _16lm1iw1m _16lm1iw1 _16lm1iw1u _16lm1iw1q";
var Hf = {
  transition: "width 0.25s, opacity 0.25s"
};
function Rn(o) {
  var g;
  let [e, t] = Js(!0),
    [n, r] = Js(0),
    {
      lineWidth: i = 1,
      style: a,
      className: l,
      index: s
    } = o,
    u = s != null && s > 0,
    c = (g = o.size) != null ? g : 16,
    d = Math.floor(c / 4),
    p = [c - 1 * d, c - 2 * d, c - 3 * d],
    f = D(h(D(h({}, Hf), {
      borderTop: `${i}px solid currentColor`
    }), o.lineStyle), {
      opacity: n
    });
  if (Gf(() => {
    if (!e) return;
    let C = requestAnimationFrame(() => {
      r(1);
    });
    return () => {
      cancelAnimationFrame(C);
    };
  }, [e]), Lf(() => {
    t(!0);
  }, []), !e) return null;
  let m = {};
  return bn.createElement("div", {
    "data-name": "filter-icon",
    style: D(h({}, a), {
      width: c
    }),
    className: te(l, Ye, Zs, `${Ye}-sort`)
  }, u ? bn.createElement("div", {
    "data-name": "index",
    style: m,
    className: Ks
  }, s) : null, bn.createElement("div", {
    style: h({
      width: p[0]
    }, f)
  }), bn.createElement("div", {
    style: h({
      width: p[1]
    }, f)
  }), bn.createElement("div", {
    style: h({
      width: p[2]
    }, f)
  }));
}
var xi = o => {
  let {
    availableSize: e,
    items: t,
    minSize: n,
    maxSize: r
  } = o;
  if (e < 0) throw "The provided availableSize cannot be negative!";
  let i = 0,
    a = 0,
    l = 0,
    s = [],
    u = [];
  t.forEach((I, R) => {
    var V, z, P, F, A;
    let x = (z = (V = I.maxSize) != null ? V : r) != null ? z : void 0,
      E = (F = (P = I.minSize) != null ? P : n) != null ? F : void 0;
    s.push(E), u.push(x), I.size != null && (x != null && I.size > x && (I.size = x), E != null && I.size < E && (I.size = E));
    let M = I.flex == 0 ? null : I.flex;
    if (I.size == null && M == null) throw `Items must specify either a size or a flex property. Item at index ${R} doesn't have either of those.`;
    M != null && (l += 1), a += M != null ? M : 0, i += (A = I.size) != null ? A : 0;
  });
  let c = o.computeSpecialSpaceDistribution ? Math.max(e, 0) : Math.max(e - i, 0),
    d = c / a,
    p = {},
    f = {},
    m = 0;
  t.forEach((I, R) => {
    var x, E, M, V;
    if (I.flex != null) {
      let z = d * I.flex,
        P = (E = (x = I.maxSize) != null ? x : r) != null ? E : void 0,
        F = (V = (M = I.minSize) != null ? M : n) != null ? V : void 0,
        A = !1,
        O = 0;
      P != null && z > P && (p[R] = !0, A = !0, O = P), F != null && z < F && (f[R] = !0, A = !0, O = F), A && (m += 1, l -= 1, a -= I.flex, c -= O);
    }
  }), m && (d = c / a);
  let g = [],
    C = 0,
    T = c,
    b = a,
    S = 0,
    y = 0,
    w = [];
  return {
    items: t.map((I, R) => {
      var O, B, k, J, oe;
      let x = h({}, I),
        E = (B = (O = I.maxSize) != null ? O : r) != null ? B : void 0,
        M = (J = (k = I.minSize) != null ? k : n) != null ? J : void 0,
        V = 0,
        z = p[R],
        P = f[R],
        F = z || P,
        A = (oe = I.size) != null ? oe : 0;
      if (I.flex != null) {
        if (F) P && (V = M), z && (V = E);else {
          C += 1;
          let q = Q => (E != null && Q > E && (Q = E), M != null && Q < M && (Q = M), Q);
          C === l ? V = q(T) : (V = q(Math.round(I.flex * d)), y += V, T = c - y, b -= I.flex, d = T / b);
        }
        A = V, x.flexSize = V;
      }
      return g.push(V), w.push(A), x.computedSize = A, S += A, x;
    }),
    flexSizes: g,
    minSizes: s,
    maxSizes: u,
    computedSizes: w,
    remainingSize: Math.round(e - S)
  };
};
function qo(o, e, t, n) {
  return e != null && o <= e ? {
    value: e,
    clamped: "min",
    diff: (o - e) * n
  } : t != null && o >= t ? {
    value: t,
    clamped: "max",
    diff: (t - o) * n
  } : {
    value: o,
    clamped: !1,
    diff: 0
  };
}
var vi = o => {
  let {
      availableSize: e,
      reservedWidth: t = 0
    } = o,
    n = Object.keys(o.columnSizing).reduce((E, M) => (E[M] = h({}, o.columnSizing[M]), E), {});
  if (e < 0) throw "The provided availableSize cannot be negative!";
  let r = o.dragHandleOffset,
    i = o.dragHandlePositionAfter,
    a = o.dragHandlePositionAfter + 1,
    l = o.items[i],
    s = o.items[a],
    u = l.id,
    c = s == null ? void 0 : s.id,
    d = l.computedWidth,
    p = l.computedMinWidth,
    f = l.computedMaxWidth,
    m = l.computedFlex ? "flex" : "width",
    g = s == null ? void 0 : s.computedWidth,
    C = s == null ? void 0 : s.computedMinWidth,
    T = s == null ? void 0 : s.computedMaxWidth,
    b = s != null && s.computedFlex ? "flex" : "width",
    S = !1,
    y = !1,
    w = r > 0 ? 1 : -1,
    {
      value: v,
      clamped: I,
      diff: R
    } = qo(d + r, p, f, w);
  if (o.shareSpaceOnResize) {
    if (s == null) return n[u] = D(h({}, n[u]), {
      [m]: v
    }), y = I === "min", S = I === "max", {
      adjustedDiff: v - d,
      reservedWidth: t,
      columnSizing: n,
      minReached: y,
      maxReached: S,
      constrained: y || S
    };
    let {
      value: E,
      clamped: M,
      diff: V
    } = qo(g - r, C, T, w);
    if (I && M && (Math.abs(R) > Math.abs(V) ? M = !1 : I = !1), !(!I && !M)) {
      if (I) {
        let z = qo(g - r - R, C, T, w);
        E = z.value, M = z.clamped, V = z.diff;
      } else if (M) {
        let z = qo(d + r + V, p, f, w);
        v = z.value, I = z.clamped, R = z.diff;
      }
    }
    n[u] = D(h({}, n[u]), {
      [m]: v
    }), n[c] = D(h({}, n[c]), {
      [b]: E
    }), y = I === "min" || M === "min", S = I === "max" || M === "max";
  } else n[u] = D(h({}, n[u]), {
    [m]: v
  }), y = I === "min", S = I === "max";
  let x = v - d;
  return {
    adjustedDiff: x,
    reservedWidth: o.shareSpaceOnResize ? t : t - x,
    columnSizing: n,
    minReached: y,
    maxReached: S,
    constrained: y || S
  };
};
function Qs(o, e) {
  return o + e;
}
function Nf(o) {
  return Object.keys(o).reduce((t, n) => (t[n] = h({}, o[n]), t), {});
}
var eu = o => {
  let {
      availableSize: e,
      reservedWidth: t = 0
    } = o,
    n = Object.keys(o.columnSizing).reduce((T, b) => (T[b] = h({}, o.columnSizing[b]), T), {});
  if (e < 0) throw "The provided availableSize cannot be negative!";
  let r = o.dragHandleOffset,
    i = [...new Array(o.columnGroupSize)].map((T, b) => o.dragHandlePositionAfter - b).reverse(),
    a = i.map(T => o.items[T]),
    l = a.map(T => T.computedWidth),
    s = a.map(T => T.computedFlex ? "flex" : "width"),
    u = 0,
    {
      computedSizes: c
    } = xi({
      items: a.map(T => (T.resizable === !1 && (u += T.computedWidth), {
        maxSize: T.computedMaxWidth,
        minSize: T.computedMinWidth,
        flex: T.resizable ? T.computedWidth : null,
        size: 0
      })),
      computeSpecialSpaceDistribution: !0,
      availableSize: Math.max(l.reduce(Qs, 0) + r - u, 0)
    }),
    d = a.map((T, b) => c[b] - T.computedWidth),
    p = !0,
    f = !0,
    m = !0,
    g = [];
  d.forEach((T, b) => {
    let S = a[b];
    if (!S.resizable) {
      g.push(0);
      return;
    }
    let y = s[b],
      w = l[b],
      v = Nf(n),
      {
        maxReached: I,
        minReached: R,
        constrained: x,
        adjustedDiff: E
      } = vi({
        availableSize: o.availableSize,
        reservedWidth: o.reservedWidth,
        dragHandleOffset: T,
        dragHandlePositionAfter: i[b],
        shareSpaceOnResize: !1,
        items: o.items,
        columnSizing: v
      });
    n[S.id] = D(h({}, v[S.id]), {
      [y]: w + E
    }), g.push(E), I || (f = !1), R || (p = !1), x || (m = !1);
  });
  let C = g.reduce(Qs, 0);
  return {
    minReached: p,
    maxReached: f,
    constrained: m,
    adjustedDiffs: g,
    adjustedDiff: C,
    columnSizing: n,
    reservedWidth: t - C
  };
};
var Xo;
function Xt() {
  var n, r;
  if (Xo != null) return Xo;
  let o = (n = ve().document) != null ? n : null;
  if (!o) return 0;
  let e = o.createElement("div");
  e.style.visibility = "hidden", e.style.overflow = "scroll", o.body.appendChild(e);
  let t = o.createElement("div");
  return e.appendChild(t), Xo = e.offsetWidth - t.offsetWidth, (r = e.parentNode) == null || r.removeChild(e), Xo;
}
var tu = ["start", void 0, "end"],
  nu = (o, e) => (e && Object.keys(e).length > 0 && o.sort((t, n) => {
    let r = e[t],
      i = e[n];
    return r === !0 && (r = "start"), i === !0 && (i = "start"), r == i ? 0 : tu.indexOf(r) - tu.indexOf(i);
  }), o);
function ou(o, e, ...t) {
  let n = e;
  return t.forEach(r => {
    for (let i in r) r.hasOwnProperty(i) && o(r[i], i) && (n[i] = r[i]);
  }), n;
}
function Pi(o, ...e) {
  return ou(t => t != null, o, ...e);
}
function ru(o, e, ...t) {
  return ou((n, r) => !(r in o), e, ...t);
}
var Zo = Object.freeze({}),
  Kf = {
    string: Zo,
    number: {}
  };
function Di(o, e) {
  let t = e.default || Zo;
  return o.type === void 0 ? t : o.type === null ? Zo : typeof o.type == "string" ? e[o.type] || Kf[o.type] || Zo : o.type.reduce((n, r) => Object.assign(n, e[r]), {});
}
var Jo = !0,
  Bf = !0,
  kf = !0,
  Wf = "string",
  wn = "unknown",
  $f = vo("getComputedVisibleColumns"),
  jf = (o, e, t) => o[t] !== !1,
  Mi = (o, e) => {
    if (!e) return !1;
    let t = e[o];
    return t === "start" || t === !0 ? "start" : t === "end" ? "end" : !1;
  },
  iu = ({
    columns: o,
    bodySize: e,
    columnMinWidth: t,
    columnMaxWidth: n,
    columnDefaultWidth: r,
    columnDefaultFlex: i,
    scrollbarWidth: a,
    columnCssEllipsis: l,
    columnHeaderCssEllipsis: s,
    pinnedStartMaxWidth: u,
    pinnedEndMaxWidth: c,
    filterValue: d,
    sortable: p,
    sortInfo: f,
    multiSort: m,
    filterTypes: g = Zt,
    viewportReservedWidth: C,
    resizableColumns: T,
    draggableColumns: b,
    columnOrder: S,
    columnPinning: y,
    editable: w,
    columnDefaultEditable: v,
    columnDefaultFilterable: I,
    columnDefaultSortable: R,
    columnSizing: x,
    columnTypes: E,
    columnVisibility: M,
    columnVisibilityAssumeVisible: V
  }) => {
    let z = 0,
      P = (d || []).reduce((K, U) => {
        let {
          id: ie,
          field: ce
        } = U;
        return ce && (K[ce] = U), ie && (K[ie] = U), K;
      }, {}),
      F = nu(S === !0 ? [...o.keys()] : S, y),
      A = F.filter(K => o.get(K) ? jf(M, V, K) : ($f(`Column with id "${K}" specified in columnOrder array cannot be found in the columns map.`), !1)),
      O = new Map(),
      B = A.map((K, U) => {
        let ie = o.get(K);
        return O.set(K, U), ie;
      }),
      k = (f != null ? f : []).reduce((K, U, ie) => (U.id ? K[U.id] = {
        sortInfo: U,
        index: ie
      } : U.field && (K[U.field] = {
        sortInfo: U,
        index: ie
      }), K), {});
    function J(K) {
      var Fe, et, ut, ct, Ct, At;
      let U = o.get(K),
        ie = Di(U, E),
        ce = {
          minWidth: ie == null ? void 0 : ie.minWidth,
          maxWidth: ie == null ? void 0 : ie.maxWidth,
          width: ie == null ? void 0 : ie.defaultWidth,
          flex: ie == null ? void 0 : ie.defaultFlex
        },
        Le = Pi({
          width: U == null ? void 0 : U.defaultWidth,
          flex: U == null ? void 0 : U.defaultFlex,
          minWidth: U == null ? void 0 : U.minWidth,
          maxWidth: U == null ? void 0 : U.maxWidth
        }, x[K]);
      Le.width != null && delete ce.flex, Le.flex != null && delete ce.width, Le = Pi(ce, Le);
      let ne = (Fe = Le.flex) != null ? Fe : void 0,
        je = (ut = (et = Le.minWidth) != null ? et : U == null ? void 0 : U.minWidth) != null ? ut : t,
        Ue = (Ct = (ct = Le.maxWidth) != null ? ct : U == null ? void 0 : U.maxWidth) != null ? Ct : n,
        Te = ne != null ? void 0 : (At = Le.width) != null ? At : i ? void 0 : r;
      return !Te && ne == null && (i ? ne = i : Te = je), {
        size: Te,
        flex: ne,
        minSize: je,
        maxSize: Ue
      };
    }
    let oe = xi({
        availableSize: Math.max(e.width - (C != null ? C : 0) - (a != null ? a : Xt()), 0),
        maxSize: n,
        minSize: t,
        items: A.map(J)
      }),
      {
        computedSizes: q,
        flexSizes: Q,
        minSizes: L,
        maxSizes: N
      } = oe,
      j = [],
      _ = [],
      H = [],
      G = [];
    G.length = A.length;
    let W = new Map(),
      X = new Map(),
      Y = 0,
      ee = 0,
      pe = 0,
      ue = null,
      Ae = [],
      ze = 0,
      Ge = 0,
      fe = 0;
    B.forEach((K, U) => {
      let ie = A[U],
        ce = q[U],
        Le = Ae[U] = Mi(ie, y);
      Le === "start" ? ze += ce : Le === "end" ? fe += ce : Ge += ce;
    });
    let ye = e.width - fe,
      Ke = [],
      se = new Map();
    A.forEach((K, U) => {
      let ie = B[U];
      se.set(K, ie);
    });
    let lt = [];
    o.forEach((K, U) => {
      lt.push(U), se.has(U) || se.set(U, K);
    });
    let st = new Map();
    se.forEach((K, U) => {
      var La, Ha, Na, Ka, Ba, ka, Wa, $a, ja, Ua, Ya, qa, Xa, Za, Ja, Qa, el, tl, nl, ol, rl, il, al, ll, sl, ul, cl, dl, pl;
      let ie = O.get(U);
      ie == null && (ie = -1);
      let ce = ie != -1,
        Le = A[ie + 1],
        ne = Di(K, E),
        je = ie,
        Ue = k[U] ? k[U] : K.field && (La = k[K.field]) != null ? La : null,
        Te = (Ha = Ue == null ? void 0 : Ue.sortInfo) != null ? Ha : null,
        Fe = !!Te,
        et = Fe && Te.dir === 1,
        ut = Fe && !et,
        ct = (Na = Ue == null ? void 0 : Ue.index) != null ? Na : -1,
        Ct = ce ? {} : J(U),
        At = ce ? q[ie] : Ct.size || 0,
        re = ce && Q[ie] || null,
        tt = ce ? L[ie] || 0 : Ct.minSize || 0,
        Hr = ce ? N[ie] || 1e4 : Ct.maxSize || 1e4,
        Be = ce ? Mi(U, y) : !1,
        Nn = ce ? ie === A.length - 1 : !1,
        dt = je,
        Ut = ce ? Be === "start" ? ee : Be === "end" ? pe : z - ee : 0;
      Be == "start" ? dt = ce ? je : -1 : Be === "end" ? dt = ce ? je - (j.length + H.length) : -1 : dt = ce ? je - j.length : -1;
      let Zp = ce ? Be === "start" || Be === !1 ? z : ye + pe : -1,
        Jp = ce ? Be !== ue : !1,
        Qp = ce ? Nn || Be !== Mi(Le, y) : !1,
        _a = (Ba = (Ka = K.cssEllipsis) != null ? Ka : ne.cssEllipsis) != null ? Ba : l,
        em = (Ua = (ja = ($a = (Wa = (ka = K.headerCssEllipsis) != null ? ka : ne.cssEllipsis) != null ? Wa : K.cssEllipsis) != null ? $a : ne.headerCssEllipsis) != null ? ja : s) != null ? Ua : _a,
        To = ce && (P[U] || K.field || K.groupByForColumn) && (P[U] || P[K.field || K.groupByForColumn]) || null,
        tm = (Xa = (qa = (Ya = K.defaultFilterable) != null ? Ya : ne.defaultFilterable) != null ? qa : I) != null ? Xa : !0,
        nm = (Qa = (Ja = (Za = w != null ? w : K.defaultEditable) != null ? Za : ne.defaultEditable) != null ? Ja : v) != null ? Qa : !1,
        Nr = K.dataType || ne.dataType || (Array.isArray(K.type) ? K.type[0] : K.type) || Wf,
        om = K.sortType || ne.sortType || Nr,
        Oa = K.filterType || ne.filterType || Nr,
        Va = !1;
      if (To != null && !To.disabled) {
        let Br = g[Oa];
        Br && (!Br.emptyValues || !Br.emptyValues.includes(To.filter.value)) && (Va = !0);
      }
      let za = (el = K.field) != null ? el : ne.field,
        Ga = (tl = K.valueGetter) != null ? tl : ne.valueGetter,
        Kr = (nl = K.defaultSortable) != null ? nl : ne.defaultSortable;
      Kr == null && !K.groupByForColumn && za == null && Ga == null && (Kr = !1);
      let rm = (rl = (ol = p != null ? p : Kr) != null ? ol : R) != null ? rl : Jo,
        im = (ll = (al = (il = K.resizable) != null ? il : ne.resizable) != null ? al : T) != null ? ll : Bf,
        Oe = D(h({
          colType: ne,
          align: ne.align,
          headerAlign: ne.headerAlign,
          computedVisible: ce,
          verticalAlign: ne.verticalAlign,
          defaultHiddenWhenGroupedBy: ne.defaultHiddenWhenGroupedBy,
          valueGetter: Ga,
          valueFormatter: ne.valueFormatter,
          renderValue: ne.renderValue,
          render: ne.render,
          style: ne.style,
          contentFocusable: ne.contentFocusable,
          renderMenuIcon: ne.renderMenuIcon,
          renderSortIcon: ne.renderSortIcon,
          renderRowDetailIcon: ne.renderRowDetailIcon,
          renderSelectionCheckBox: ne.renderSelectionCheckBox,
          renderHeaderSelectionCheckBox: ne.renderHeaderSelectionCheckBox,
          headerStyle: ne.headerStyle,
          headerClassName: ne.headerClassName,
          columnGroup: ne.columnGroup,
          getValueToEdit: ne.getValueToEdit,
          getValueToPersist: ne.getValueToPersist,
          shouldAcceptEdit: ne.shouldAcceptEdit,
          field: za
        }, K), {
          components: h(h({}, ne.components), K.components),
          computedResizable: im,
          computedMinWidth: tt,
          computedMaxWidth: Hr,
          computedFlex: re,
          computedDataType: Nr,
          computedEditable: nm,
          computedSortType: om,
          computedFilterType: Oa,
          cssEllipsis: _a,
          headerCssEllipsis: em,
          computedFilterValue: To,
          computedFiltered: Va,
          computedFilterable: tm,
          computedWidth: At,
          computedAbsoluteOffset: Zp,
          computedPinningOffset: Ut,
          computedVisibleIndexInCategory: dt,
          computedOffset: z,
          computedSortable: rm,
          computedSortInfo: Te,
          computedSortIndex: ct,
          computedMultiSort: m,
          computedSorted: Fe,
          computedSortedAsc: et,
          computedSortedDesc: ut,
          computedVisibleIndex: je,
          computedPinned: Be,
          computedDraggable: (ul = (sl = K.draggable) != null ? sl : b) != null ? ul : kf,
          computedFirstInCategory: Jp,
          computedLastInCategory: Qp,
          computedFirst: ie === 0,
          computedLast: Nn,
          id: U,
          header: (pl = (dl = (cl = K.header) != null ? cl : ne.header) != null ? dl : K.name) != null ? pl : K.field
        });
      ce && (z += At, W.set(Oe.id, Oe), G[ie] = Oe, Be === "start" && (j.push(Oe), ee += Oe.computedWidth), Be === "end" && (_.push(Oe), pe += Oe.computedWidth), Be || (H.push(Oe), Y += Oe.computedWidth), ue = Be), Oe.groupByForColumn && (Oe.computedSortType = K.sortType || ne.sortType || wn, Ke.push(Oe)), X.set(Oe.id, Oe), Oe.field && !Oe.groupByForColumn && !st.has(Oe.field) && st.set(Oe.field, Oe);
    }), Ke.forEach(K => {
      K.computedSortable = au(K, {
        fieldsToColumn: st,
        sortable: p,
        columnDefaultSortable: R
      });
    });
    let Et = new Map();
    lt.forEach(K => {
      let U = X.get(K);
      U && Et.set(K, U);
    }), X.forEach(K => {
      Et.has(K.id) || Et.set(K.id, K);
    });
    let Wt = u != null ? Math.min(u, ee) : ee,
      $t = c != null ? Math.min(c, pe) : pe;
    return {
      computedRemainingSpace: e.width - (ze + fe + Ge),
      computedPinnedStartColumnsWidth: ee,
      computedPinnedEndColumnsWidth: pe,
      computedUnpinnedColumnsWidth: Y,
      computedColumnOrder: F,
      computedPinnedStartColumns: j,
      computedPinnedEndColumns: _,
      computedUnpinnedOffset: ee,
      computedPinnedEndOffset: ee + Y,
      computedUnpinnedColumns: H,
      computedVisibleColumns: G,
      computedColumnsMap: X,
      computedColumnsMapInInitialOrder: Et,
      computedVisibleColumnsMap: W,
      computedPinnedEndWidth: $t,
      computedPinnedStartWidth: Wt,
      renderSelectionCheckBox: G.reduce((K, U) => K || !!U.renderSelectionCheckBox, !1),
      fieldsToColumn: st
    };
  };
function au(o, e) {
  var s;
  let {
    sortable: t,
    fieldsToColumn: n,
    columnDefaultSortable: r
  } = e;
  if (t) return t;
  let i = (s = o.defaultSortable) != null ? s : o.colType.defaultSortable;
  if (i != null) return i;
  let a = r != null ? r : Jo;
  return o.computedSortType !== wn && a ? !0 : u => {
    let {
        column: c,
        api: d
      } = u,
      p = c.groupByForColumn || [];
    return p != null && !Array.isArray(p) && (p = [p]), (p || []).reduce((f, m) => {
      var T;
      if (!f) return !1;
      let g = m.field || m.groupField,
        C;
      if (g) {
        let b = n.get(g),
          S = b ? d.getColumnApi(b.id) : void 0;
        b && S ? C = S.isSortable() : C = !1;
      }
      return C === void 0 && m.valueGetter && (C = !0), (T = C != null ? C : r) != null ? T : Jo;
    }, !0);
  };
}
function We(o, e) {
  let {
    getComputed: t,
    getState: n,
    actions: r,
    api: i
  } = e;
  typeof o == "number" && (o = t().computedVisibleColumns[o]);
  let a = typeof o == "string" ? t().computedColumnsMap.get(o) : o;
  if (!a) return null;
  let l = {
    getCellValuesByPrimaryKey(s) {
      return e.api.getCellValues({
        columnId: a.id,
        primaryKey: s
      });
    },
    getCellValueByPrimaryKey(s) {
      var u, c;
      return (c = (u = l.getCellValuesByPrimaryKey(s)) == null ? void 0 : u.value) != null ? c : null;
    },
    toggleContextMenu(s) {
      n().columnMenuVisibleForColumnId === a.id ? this.hideContextMenu() : this.showContextMenu(s);
    },
    toggleFilterOperatorMenu(s) {
      n().filterOperatorMenuVisibleForColumnId === a.id ? this.hideFilterOperatorMenu() : this.showFilterOperatorMenu(s);
    },
    showFilterOperatorMenu(s) {
      n().onFilterOperatorMenuClick({
        target: s,
        column: a
      });
    },
    hideFilterOperatorMenu() {
      i.hideFilterOperatorMenu();
    },
    showContextMenu(s) {
      n().onColumnMenuClick({
        target: s,
        column: a
      });
    },
    hideContextMenu() {
      r.columnMenuVisibleForColumnId = null;
    },
    isVisible() {
      return t().computedVisibleColumnsMap.has(a.id);
    },
    toggleSort(s = {
      multiSortBehavior: "replace"
    }) {
      return i.toggleSortingForColumn(a.id, s);
    },
    isSortable() {
      let {
        computedColumnsMap: s
      } = t();
      return typeof a.computedSortable == "function" ? a.computedSortable({
        api: i,
        columnApi: l,
        column: a,
        columns: s
      }) : a.computedSortable;
    },
    getSortDir() {
      return i.getSortingForColumn(a.id);
    },
    setFilter(s) {
      return i.setColumnFilter(a.id, s);
    },
    clearFilter() {
      return i.clearColumnFilter(a.id);
    },
    getSortInfo() {
      return i.getSortInfoForColumn(a.id);
    },
    setSort(s) {
      s ? i.setSortingForColumn(a.id, s) : i.setSortInfoForColumn(a.id, null);
    },
    clearSort() {
      this.setSort(null);
    }
  };
  return l;
}
function Qo(o, e) {
  var g;
  let t = typeof o == "string" ? e.getComputed().computedColumnsMap.get(o) : o,
    {
      api: n,
      getComputed: r,
      getDataSourceState: i,
      actions: a,
      dataSourceActions: l,
      dataSourceApi: s
    } = e,
    u = i(),
    {
      allRowsSelected: c,
      someRowsSelected: d,
      selectionMode: p
    } = u,
    f = r(),
    m = t.header && typeof t.header != "function" ? t.header : t.name || t.id || "";
  if (typeof t.header == "function") {
    let C = We(t.id, D(h({}, e), {
      actions: a,
      dataSourceActions: l,
      dataSourceApi: s
    }));
    m = (g = t.header({
      column: t,
      columnApi: C,
      insideColumnMenu: !0,
      dragging: !1,
      columnsMap: f.computedColumnsMap,
      columnSortInfo: t.computedSortInfo,
      columnFilterValue: t.computedFilterValue,
      filtered: t.computedFiltered,
      allRowsSelected: c,
      someRowsSelected: d,
      selectionMode: p,
      api: n,
      renderBag: {
        header: m
      }
    })) != null ? g : null;
  }
  return m;
}
import Uf from "react";
var er = `${_t}HeaderCell__filter`,
  Ei = `${_t}HeaderCell__filterOperator`,
  Ai = Uf.createContext(null);
var qf = `${er}__input`,
  tr = o => o.stopPropagation();
function su(o) {
  let {
      filterOperatorMenuVisibleForColumnId: e
    } = As(),
    {
      column: t
    } = nr(),
    n = o.filterEditor,
    r = o.filterOperatorSwitch,
    [i, a] = lu(!1),
    l = ke.useCallback(() => {
      a(!0);
    }, []),
    s = ke.useCallback(() => {
      a(!1);
    }, []),
    u = e === t.id || i;
  return ke.createElement("div", {
    onPointerUp: tr,
    onPointerDown: tr,
    onFocus: l,
    onBlur: s,
    className: `${er} ${Ii({
      active: u
    })}`,
    style: {
      height: o.columnHeaderHeight
    }
  }, ke.createElement(Ai.Provider, {
    value: o
  }, ke.createElement(r, null), ke.createElement(n, null)));
}
function uu() {
  var r, i;
  let {
      columnApi: o,
      disabled: e,
      operator: t
    } = to(),
    n = (i = (r = t == null ? void 0 : t.components) == null ? void 0 : r.Icon) != null ? i : Rn;
  return ke.createElement("div", {
    "data-name": "filter-operator",
    "data-disabled": e,
    onMouseDown: a => {
      a.stopPropagation(), !e && o.toggleFilterOperatorMenu(a.target);
    },
    className: te(Ei, Bs, e ? `${Ei}--disabled` : "")
  }, ke.createElement(n, {
    size: 20,
    className: `${ks({
      disabled: e
    })}`
  }));
}
function cu() {
  return ke.createElement("div", {
    onPointerUp: tr,
    onPointerDown: tr,
    className: `${er} ${Ii({
      active: !1
    })}`,
    style: {
      height: "100%"
    }
  });
}
function to() {
  var C;
  let o = ae(),
    {
      column: e,
      columnApi: t
    } = nr(),
    n = Qo(e, o),
    r = ke.useContext(Ai),
    {
      columnFilterType: i,
      filterTypes: a,
      columnFilterValue: l
    } = r,
    s = a[i],
    [u, c] = lu((C = l == null ? void 0 : l.filter.value) != null ? C : ""),
    d = ke.useCallback(T => {
      c(T), r.onChange(T);
    }, [r.onChange]),
    p = ke.useCallback(() => {
      o.api.clearColumnFilter(e.id);
    }, [e.id]),
    f = ke.useCallback(() => {
      o.api.removeColumnFilter(e.id);
    }, [e.id]);
  Yf(() => {
    if (l) u !== l.filter.value && c(l.filter.value);else if (s) {
      let T = [...s.emptyValues][0];
      T !== u && c(T);
    }
  }, [l == null ? void 0 : l.filter.value]);
  let m = r.operator,
    g = m == null ? void 0 : m.name;
  return {
    api: o.api,
    column: e,
    columnFilterValue: l,
    columnApi: t,
    operatorName: g,
    operator: m,
    value: u,
    disabled: l == null ? void 0 : l.disabled,
    filterType: s,
    filterTypes: a,
    filterTypeKey: i,
    filtered: e.computedFiltered,
    setValue: d,
    clearValue: p,
    removeColumnFilter: f,
    ariaLabel: `Filter for ${n}`,
    className: `${Ns} ${qf}`
  };
}
function In() {
  let {
    ariaLabel: o,
    value: e,
    setValue: t,
    className: n,
    disabled: r
  } = to();
  return Fi.createElement("input", {
    "data-xxx": !0,
    "aria-label": o,
    type: "text",
    disabled: r,
    value: e,
    onChange: i => {
      t(i.target.value);
    },
    className: n
  });
}
function or() {
  let {
    ariaLabel: o,
    value: e,
    setValue: t,
    className: n,
    disabled: r
  } = to();
  return Fi.createElement("input", {
    "aria-label": o,
    type: "number",
    "data-yyy": !0,
    disabled: r,
    value: e,
    onChange: i => {
      let a = isNaN(i.target.valueAsNumber) ? i.target.value : i.target.valueAsNumber;
      t(a);
    },
    className: n
  });
}
function Xf() {
  return {
    string: {
      label: "Text",
      emptyValues: [""],
      defaultOperator: "includes",
      components: {
        FilterEditor: In
      },
      operators: [{
        name: "includes",
        components: {
          Icon: ws
        },
        label: "Includes",
        fn: ({
          currentValue: e,
          filterValue: t
        }) => typeof e == "string" && typeof t == "string" && e.toLowerCase().includes(t.toLowerCase())
      }, {
        label: "Equals",
        components: {
          Icon: hi
        },
        name: "eq",
        fn: ({
          currentValue: e,
          filterValue: t
        }) => typeof e == "string" && e === t
      }, {
        name: "startsWith",
        components: {
          Icon: Es
        },
        label: "Starts With",
        fn: ({
          currentValue: e,
          filterValue: t
        }) => e.startsWith(t)
      }, {
        name: "endsWith",
        components: {
          Icon: Is
        },
        label: "Ends With",
        fn: ({
          currentValue: e,
          filterValue: t
        }) => e.endsWith(t)
      }]
    },
    number: {
      label: "Number",
      emptyValues: ["", null, void 0],
      defaultOperator: "eq",
      components: {
        FilterEditor: or
      },
      operators: [{
        label: "Equals",
        components: {
          Icon: hi
        },
        name: "eq",
        fn: ({
          currentValue: e,
          filterValue: t
        }) => e == t
      }, {
        label: "Not Equals",
        components: {
          Icon: Ms
        },
        name: "neq",
        fn: ({
          currentValue: e,
          filterValue: t
        }) => e != t
      }, {
        name: "gt",
        label: "Greater Than",
        components: {
          Icon: vs
        },
        fn: ({
          currentValue: e,
          filterValue: t,
          emptyValues: n
        }) => n.includes(e) ? !0 : e > t
      }, {
        name: "gte",
        components: {
          Icon: xs
        },
        label: "Greater Than or Equal",
        fn: ({
          currentValue: e,
          filterValue: t,
          emptyValues: n
        }) => n.includes(e) ? !0 : e >= t
      }, {
        name: "lt",
        components: {
          Icon: Ds
        },
        label: "Less Than",
        fn: ({
          currentValue: e,
          filterValue: t,
          emptyValues: n
        }) => n.includes(e) ? !0 : e < t
      }, {
        name: "lte",
        components: {
          Icon: Ps
        },
        label: "Less Than or Equal",
        fn: ({
          currentValue: e,
          filterValue: t,
          emptyValues: n
        }) => n.includes(e) ? !0 : e <= t
      }]
    }
  };
}
var Zt = Xf();
function rr(o, e, t, n) {
  var l;
  let r = [...e],
    i = {
      filtered: o.computedFiltered,
      first: o.computedFirst,
      last: o.computedLast,
      groupByField: !!o.groupByForColumn,
      firstInCategory: o.computedFirstInCategory,
      lastInCategory: o.computedLastInCategory,
      pinned: o.computedPinned || !1,
      cellSelected: n.cellSelected || !1,
      rowSelected: (l = n.rowSelected) != null ? l : "null",
      rowActive: n.rowActive,
      dragging: n.dragging,
      groupRow: n.groupRow,
      groupCell: n.groupCell,
      verticalAlign: n.verticalAlign,
      align: n.align,
      zebra: n.zebra
    },
    a = t(i);
  return r.push(a), o.computedFirst && r.push(...e.map(s => `${s}--first`)), o.groupByForColumn && r.push(...e.map(s => `${s}--group-column`)), o.computedLast && r.push(...e.map(s => `${s}--last`)), o.computedFirstInCategory && r.push(...e.map(s => `${s}--first-in-category`)), o.computedLastInCategory && r.push(...e.map(s => `${s}--last-in-category`)), n.rowSelected && r.push(...e.map(s => `${s}--row-selected`)), n.cellSelected && r.push(...e.map(s => `${s}--cell-selected`)), n.groupRow && (r.push(...e.map(s => `${s}--group-row`)), r.push(...e.map(s => `${s}--${n.rowExpanded ? "group-row-expanded" : "group-row-collapsed"}`))), o.computedPinned ? r.push(...e.map(s => `${s}--pinned-${o.computedPinned}`)) : r.push(...e.map(s => `${s}--unpinned`)), r.filter(Boolean).join(" ");
}
import { useCallback as Du, useState as ag } from "react";
var du = "_16lm1iw1",
  pu = "_1yeub2v0 _16lm1iw1 _16lm1iwz _16lm1iw1m _16lm1iw0",
  mu = "_1yeub2vq",
  fu = "_1yeub2vp",
  gu = Se({
    defaultClassName: "_1yeub2v2",
    variantClassNames: {
      focused: {
        true: "_1yeub2v3",
        false: "_1yeub2v4"
      },
      focusedWithin: {
        true: "_1yeub2v5",
        false: "_1yeub2v6"
      },
      hasPinnedStart: {
        true: "_1yeub2v7",
        false: "_1yeub2v8"
      },
      hasPinnedEnd: {
        true: "_1yeub2v9",
        false: "_1yeub2va"
      },
      hasPinnedStartOverflow: {
        true: "_1yeub2vb",
        false: "_1yeub2vc"
      },
      hasPinnedEndOverflow: {
        true: "_1yeub2vd",
        false: "_1yeub2ve"
      }
    },
    defaultVariants: {},
    compoundVariants: [[{
      focused: !1,
      focusedWithin: !1
    }, "_1yeub2vf"]]
  });
var _i = "_1yeub2vn";
var QI = Se({
  defaultClassName: "_1yeub2vj",
  variantClassNames: {
    pinned: {
      start: "_1yeub2vk",
      end: "_1yeub2vl",
      false: "_1yeub2vm"
    }
  },
  defaultVariants: {},
  compoundVariants: []
});
var Jt = o => {
  let e = 0;
  return (...t) => {
    e && cancelAnimationFrame(e), e = requestAnimationFrame(() => {
      e = 0, o(...t);
    });
  };
};
var Zf = Z($.scrollbarWidthHorizontal),
  hu = Z($.scrollbarWidthVertical),
  Cu = Z($.columnWidthAtIndex),
  Jf = Z($.columnOffsetAtIndex),
  Qf = Z($.columnZIndexAtIndex),
  Su = Z($.scrollLeft),
  eg = Z($.activeCellColWidth),
  tg = Z($.activeCellColOffset),
  Ru = Z($.baseZIndexForCells),
  ng = Z($.pinnedStartWidth),
  yu = Z($.bodyWidth),
  og = Z($.bodyHeight),
  Tu = Z($.pinnedEndOffset),
  bu = Z($.pinnedEndWidth),
  rg = Z($.computedVisibleColumnsCount);
function Oi(o, e) {
  let t = "auto",
    n = o.computedVisibleIndex;
  return o.computedPinned ? o.computedPinned === "start" ? t = e.pinnedStartColsCount - o.computedVisibleIndexInCategory : o.computedPinned === "end" && (t = e.visibleColsCount + o.computedVisibleIndexInCategory) : t = -n * 10, typeof t == "number" ? `calc( var(${Ru}) ` + (t < 0 ? "-" : "+") + ` ${Math.abs(t)} )` : "auto";
}
function wu(o) {
  let e = Xt(),
    {
      computed: t,
      state: n,
      actions: r,
      getState: i
    } = ae(),
    {
      focused: a,
      focusedWithin: l,
      domRef: s,
      scrollerDOMRef: u,
      onBlurWithin: c,
      onFocusWithin: d,
      onSelfFocus: p,
      onSelfBlur: f,
      bodySize: m,
      activeCellIndex: g
    } = n,
    {
      computedPinnedStartColumnsWidth: C,
      computedPinnedEndColumnsWidth: T,
      computedPinnedStartColumns: b,
      computedPinnedEndColumns: S,
      computedVisibleColumns: y,
      scrollbars: w
    } = t,
    v = [],
    I = y.reduce((P, F) => {
      let A = F.computedVisibleIndex;
      P[`${Cu}-${A}`] = F.computedWidth + "px", P[`${Jf}-${A}`] = F.computedPinned === "start" ? `calc( ${F.computedOffset}px + var(${Su}) )` : F.computedPinned === "end" ? `calc( var(${Tu}) ${v.length ? "+" : ""} ${v.map(B => `var(${Cu}-${B.computedVisibleIndex})`).join(" + ")} + var(${Su}) )` : `${F.computedOffset}px`;
      let O = Oi(F, {
        pinnedStartColsCount: b.length,
        visibleColsCount: y.length
      });
      return P[`${Qf}-${A}`] = `${O}`, F.computedPinned === "end" && v.push(F), P;
    }, {});
  g != null && (I[eg] = `var(${Vo(g[1])})`, I[tg] = `var(${Ul(g[1])})`), I[rg] = y.length, I[Zf] = w.horizontal ? `${e}px` : "0px", I[hu] = w.vertical ? `${e}px` : "0px", I[ng] = "calc( " + b.map(P => `var(${Vo(P.computedVisibleIndex)})`).join(" + ") + ")", I[bu] = "calc( " + S.map(P => `var(${Vo(P.computedVisibleIndex)})`).join(" + ") + ")", I[Ru] = y.length * 10, I[Tu] = `calc( var(${yu}) - var(${bu}) - var(${hu}) )`, I[yu] = `${m.width}px`, I[og] = `${m.height}px`;
  let R = Jt(P => {
      r.focused = P;
    }),
    x = Jt(P => {
      r.focusedWithin = P;
    }),
    E = P => {
      var F;
      if ((F = o == null ? void 0 : o.onFocus) == null || F.call(o, P), P.target === s.current || P.target === u.current) {
        if (i().focused) return;
        R(!0), p == null || p(P), l && (x(!1), c == null || c(P));
        return;
      }
      l || (x(!0), d == null || d(P));
    },
    M = P => {
      var A, O;
      if ((A = o == null ? void 0 : o.onBlur) == null || A.call(o, P), P.target === s.current || P.target === u.current) {
        if (!i().focused) return;
        R(!1), f == null || f(P), l && (x(!1), c == null || c(P));
        return;
      }
      ((O = s.current) == null ? void 0 : O.contains(P.relatedTarget)) || (x(!1), c == null || c(P));
    },
    V = D(h({}, o), {
      onFocus: E,
      onBlur: M
    }),
    z = te(Vt, pu, V == null ? void 0 : V.className, C ? `${Vt}--has-pinned-start ${fu}` : null, T ? `${Vt}--has-pinned-end  ${mu}` : null, a ? `${Vt}--focused` : null, l ? `${Vt}--focused-within` : null, a && n.focusedClassName ? n.focusedClassName : null, l && n.focusedWithinClassName ? n.focusedWithinClassName : null, t.computedPinnedStartOverflow ? `${Vt}--has-pinned-start-overflow` : null, t.computedPinnedEndOverflow ? `${Vt}--has-pinned-end-overflow` : null, gu({
      hasPinnedStart: !!C,
      hasPinnedEnd: !!T,
      focused: a,
      focusedWithin: l
    }));
  return V.className = z, V.style = h(h({}, o == null ? void 0 : o.style), I), a && n.focusedStyle && Object.assign(V.style, n.focusedStyle), l && n.focusedWithinStyle && Object.assign(V.style, n.focusedWithinStyle), V;
}
var vu = jr(zo());
var no = (o, e, t) => {
  if (o = [...o], e === t || o[e] === void 0 || o[t] === void 0) return o;
  let n = o[e];
  return t < e, o.splice(e, 1), o.splice(t, 0, n), o;
};
var Iu = o => {
  let e = null,
    t = null;
  return {
    stop() {
      e !== null && ot(e), t != null && ot(t);
    },
    scroll(n) {
      e !== null && ot(e), t != null && ot(t);
      let r = h(h({
          offset: 100,
          scrollAmountsPerRaf: [1, 1, 2, 4, 6, 8, 11, 15]
        }, o), n),
        i = this;
      function a() {
        let s = r.scrollOffsetStart + r.offset - r.mousePosition.clientX;
        if (!(s > 0 && s <= r.offset)) return !1;
        let c = r.scrollAmountsPerRaf.length,
          d = Math.round(r.offset / c),
          p = 0;
        for (let f = 0; f < c; f++) s > (f + 1) * d && (p = r.scrollAmountsPerRaf[f]);
        return n.scrollLeft = n.scrollLeft - p, e = be(() => {
          i.scroll(n);
        }), !0;
      }
      function l() {
        let s = r.mousePosition.clientX - (r.scrollOffsetEnd - r.offset);
        if (!(s > 0 && s <= r.offset)) return !1;
        let c = r.scrollAmountsPerRaf.length,
          d = Math.round(r.offset / c),
          p = 0;
        for (let f = 0; f < c; f++) s > (f + 1) * d && (p = r.scrollAmountsPerRaf[f]);
        return n.scrollLeft = n.scrollLeft + p, t = be(() => {
          i.scroll(n);
        }), !0;
      }
      return !!(a() || l());
    }
  };
};
var xu = 10,
  ig = Z($.pinnedStartWidth);
function Pu(o) {
  let {
    columnOffsetAtIndexCSSVar: e,
    columnWidthAtIndexCSSVar: t,
    computedVisibleColumns: n,
    computedVisibleColumnsMap: r,
    computedPinnedEndColumns: i,
    computedPinnedStartColumns: a,
    dragColumnId: l,
    infiniteDOMNode: s,
    brain: u,
    dragColumnHeaderTargetRect: c,
    tableRect: d,
    setProxyPosition: p,
    api: f
  } = o;
  function m(_) {
    let H = _ === "start" ? z : P;
    return E === V ? H : V === _ ? H + R.computedWidth : E === _ ? H - R.computedWidth : H;
  }
  function g(_) {
    if (_.index === x) return !0;
    let H = n[_.index];
    if (H.computedPinned) return !0;
    let G = u.getScrollPosition().scrollLeft;
    return H.computedOffset >= G + m("start") && H.computedOffset + H.computedWidth < G + d.width - m("end");
  }
  let C = _ => {
      let H = [];
      return _.forEach(G => {
        let W = {
          columnId: G.id,
          index: G.computedVisibleIndex,
          breakpoint: G.computedOffset + Math.round(G.computedWidth / 2)
        };
        g(W) && H.push(W);
      }), H;
    },
    b = a.length,
    S = {
      clientX: Math.round(o.initialMousePosition.clientX),
      clientY: Math.round(o.initialMousePosition.clientY)
    },
    y = {
      left: S.clientX - d.left - (S.clientX - c.left) + xu,
      top: S.clientY - d.top - (S.clientY - c.top) + xu
    },
    w = u.getScrollPosition(),
    v = n.map(_ => _.computedVisibleIndex),
    I = v,
    R = r.get(l),
    x = R.computedVisibleIndex,
    E = R == null ? void 0 : R.computedPinned,
    M = x,
    V = E,
    z = u.getFixedStartColsWidth(),
    P = u.getFixedEndColsWidth(),
    F = S,
    A = {
      x: 0,
      y: 0
    },
    O = 1,
    B = 0;
  function k(_) {
    let H = Math.round(_.clientX),
      G = Math.round(_.clientY);
    if (H === F.clientX && G === F.clientY) return;
    F = {
      clientX: H,
      clientY: G
    };
    let X = u.getScrollPosition().scrollLeft - w.scrollLeft;
    A.x = F.clientX - S.clientX, A.y = F.clientY - S.clientY, B = A.x + X, O = B < 0 ? -1 : 1, p({
      left: y.left + A.x,
      top: y.top + A.y
    });
  }
  function J(_) {
    let H = 0;
    E ? E === "start" && _.forEach((G, W) => {
      var X;
      H || ((X = n[G]) == null ? void 0 : X.computedPinned) !== "start" && (H = W);
    }) : _.forEach((G, W) => {
      var X;
      ((X = n[G]) == null ? void 0 : X.computedPinned) === "start" && (H = W + 1);
    }), b = H, oe("start", _, H);
  }
  function oe(_, H, G) {
    if (_ === "start") {
      let W = 0;
      for (let X = 0; X < G; X++) {
        let Y = H[X],
          ee = n[Y];
        W += ee.computedWidth;
      }
      Tt(ig, `${W}px`, s);
    }
  }
  function q(_) {
    if (_ === M) return;
    M = _;
    let H = no(v, x, M > x ? M - 1 : M);
    b = 0;
    let W = new Set();
    return H.forEach((X, Y) => {
      let ee = I[Y];
      X !== ee && W.add(X), J(H);
    }), I = H, Q(W), {
      newColumnIndexes: H,
      indexesWithTransition: W,
      pinnedStartCount: b
    };
  }
  function Q(_) {
    n.forEach(H => {
      let G = H.computedVisibleIndex;
      _.has(G) ? jl(G, s) : Oo(G, s);
      let W = G === x,
        X = O === -1 ? G < M || G > x : G < x || G >= M,
        Y = "";
      O === -1 ? Y = W ? `var(${e}-${M})` : X ? `var(${e}-${G})` : `calc( var(${e}-${G}) + var(${t}-${x}) )` : Y = X ? `var(${e}-${G})` : W ? `calc( var(${e}-${M - 1}) + var(${t}-${M - 1}) - var(${t}-${x}) )` : `calc( var(${e}-${G}) - var(${t}-${x}) )`, Yn(G, Y, s);
    });
  }
  let L = d.left + m("start"),
    N = d.right - m("end"),
    j = Iu({
      scrollOffsetStart: L,
      scrollOffsetEnd: N
    });
  return k(S), {
    speedScroller: j,
    stop() {
      j.stop();
    },
    onMove(_) {
      k(_), j.scroll({
        mousePosition: F,
        get scrollLeft() {
          return u.getScrollPosition().scrollLeft;
        },
        set scrollLeft(ee) {
          f.scrollLeft = ee;
        }
      });
      let H = C(n),
        G = O === -1 ? R.computedOffset + B : R.computedOffset + R.computedWidth + B,
        W = (0, vu.default)(H, G, ({
          breakpoint: ee
        }, pe) => ee < pe ? -1 : ee > pe ? 1 : 0);
      W < 0 && (W = ~W), W = H[W] ? H[W].index : H[H.length - 1].index + 1, W != null && W !== M && (O === 1 && M === x + 1 && (W = x), q(W));
      let X = {};
      i.forEach(ee => {
        X[ee.id] = "end";
      });
      for (let ee = 0; ee < b; ee++) {
        let pe = I[ee],
          ue = n[pe];
        X[ue.id] = "start";
      }
      let Y = I.map(ee => n[ee].id);
      return {
        columnPinning: X,
        columnOrder: Y
      };
    }
  };
}
function zt(o, e, t) {
  if (o === e) return !0;
  if (!o || !e) return !1;
  var n = Object.keys(o),
    r = Object.keys(e);
  t && (n = n.filter(s => !t.has(s)), r = r.filter(s => !t.has(s)));
  var i = n.length;
  if (r.length !== i) return !1;
  for (var a = 0; a < i; a++) {
    var l = n[a];
    if (!(t && t.has(l)) && (o[l] !== e[l] || !Object.prototype.hasOwnProperty.call(e, l))) return !1;
  }
  return !0;
}
function Vi(o) {
  let {
    newColumnOrder: e,
    visibleColumnOrder: t,
    existingColumnOrder: n,
    dragColumnId: r
  } = o;
  if (t.length !== e.length) return console.warn("new column order cannot contain less columns than the visible column order"), e;
  let i = t.indexOf(r),
    a = e.indexOf(r);
  if (i === a) return n;
  if (a > i) {
    let l = e.indexOf(r),
      s = e[l - 1],
      u = n.indexOf(s),
      c = n.indexOf(r);
    return no(n, c, u);
  } else {
    let l = e.indexOf(r),
      s = e[l + 1],
      u = n.indexOf(s),
      c = n.indexOf(r);
    return no(n, c, u);
  }
  return e;
}
var lg = Z($.columnOffsetAtIndex),
  sg = Z($.columnWidthAtIndex),
  ug = Z($.baseZIndexForCells),
  cg = (o, e) => {
    let t = !o || Object.keys(o).length === 0,
      n = !e || Object.keys(e).length === 0;
    return t && n ? !0 : !!o != !!e ? !1 : zt(o, e);
  },
  Mu = ({
    columnId: o,
    domRef: e
  }) => {
    var p;
    let [t, n] = ag(null),
      {
        actions: r,
        computed: i,
        getComputed: a,
        getState: l,
        api: s,
        state: {
          domRef: u
        }
      } = ae(),
      c = Du(f => {
        let {
          multiSortBehavior: m
        } = l();
        s.toggleSortingForColumn(o, {
          multiSortBehavior: m === "replace" && (f.ctrlKey || f.metaKey) ? "append" : m
        });
      }, []),
      d = Du(f => {
        var O, B;
        if (((O = f.target) == null ? void 0 : O.tagName) === "INPUT") return;
        let {
            brain: m
          } = l(),
          {
            computedVisibleColumns: g,
            computedVisibleColumnsMap: C,
            computedPinnedStartColumns: T,
            computedUnpinnedColumns: b,
            computedPinnedEndColumns: S
          } = a(),
          y = C.get(o);
        if (!y.computedDraggable) {
          c(f);
          return;
        }
        let w = e.current,
          v = w.getBoundingClientRect(),
          I = u.current.getBoundingClientRect(),
          R = m.getAvailableSize(),
          x = y.computedVisibleIndex,
          E = (B = w.style.cursor) != null ? B : "auto",
          M = !1,
          V = Pu({
            brain: m,
            columnOffsetAtIndexCSSVar: lg,
            columnWidthAtIndexCSSVar: sg,
            computedPinnedEndColumns: S,
            computedPinnedStartColumns: T,
            computedUnpinnedColumns: b,
            computedVisibleColumns: g,
            computedVisibleColumnsMap: C,
            dragColumnHeaderTargetRect: v,
            dragColumnId: o,
            api: s,
            infiniteDOMNode: u.current,
            setProxyPosition: n,
            tableRect: I,
            initialMousePosition: {
              clientX: f.clientX,
              clientY: f.clientY
            }
          }),
          z = null;
        function P(k) {
          let {
            columnPinning: J,
            columnOrder: oe
          } = k;
          cg(l().columnPinning, J) || (r.columnPinning = J);
          let q = a().computedColumnOrder;
          JSON.stringify(oe, q) && (g.forEach(Q => {
            Yn(Q.computedVisibleIndex, "", u.current);
          }), r.columnOrder = Vi({
            newColumnOrder: oe,
            visibleColumnOrder: a().computedVisibleColumns.map(Q => Q.id),
            existingColumnOrder: q,
            dragColumnId: o
          }));
        }
        let F = k => {
            var q;
            let {
              headerBrain: J,
              brain: oe
            } = l();
            M || (M = !0, oe.setRenderCount({
              horizontal: g.length,
              vertical: void 0
            }), J.setRenderCount({
              horizontal: g.length,
              vertical: void 0
            }), r.columnReorderDragColumnId = y.id, ni(x, `calc( var(${ug}) + 10000 )`, u.current), (q = u.current) == null || q.classList.add(_i), w.style.cursor = "grabbing"), z = V.onMove(k);
          },
          A = k => {
            var Q, L, N;
            let {
                multiSortBehavior: J
              } = l(),
              oe = e.current;
            (Q = u.current) == null || Q.classList.remove(_i), V.stop(), m.setAvailableSize(h({}, R)), g.forEach(j => {
              Oo(j.computedVisibleIndex, u.current), Yn(j.computedVisibleIndex, "", u.current);
            }), ni(x, Oi(y, {
              pinnedStartColsCount: T.length,
              visibleColsCount: g.length
            }), u.current), oe.style.cursor = E, oe.releasePointerCapture(k.pointerId), oe.removeEventListener("pointermove", F), oe.removeEventListener("pointerup", A), n(null);
            let q = (N = (L = s.getColumnApi(y.id)) == null ? void 0 : L.isSortable()) != null ? N : !1;
            !M && q && s.toggleSortingForColumn(y.id, {
              multiSortBehavior: J === "replace" && (k.ctrlKey || k.metaKey) ? "append" : J
            }), z && P(z), r.columnReorderDragColumnId = !1;
          };
        w.addEventListener("pointermove", F), w.addEventListener("pointerup", A), w.setPointerCapture(f.pointerId);
      }, [o]);
    return {
      onPointerDown: (p = i.computedVisibleColumnsMap.get(o)) != null && p.computedDraggable ? d : c,
      proxyPosition: t
    };
  };
import * as Pn from "react";
import * as de from "react";
import { useCallback as ft, useContext as Ig, useMemo as xg } from "react";
import * as Qt from "react";
var Eu = o => o.dataSourceHasGrouping && o.isGroupRow && o.childrenLoading || !o.selfLoaded;
import * as oo from "react";
import { useState as dg } from "react";
var Au = "_16lm1iw7 _16lm1iwn _16lm1iwi _16lm1iw9",
  Fu = Se({
    defaultClassName: "_198z9781",
    variantClassNames: {
      expanded: {
        true: "_16lm1iwg",
        false: "_198z9783"
      },
      direction: {
        end: "_198z9784",
        start: "_198z9785"
      }
    },
    defaultVariants: {},
    compoundVariants: [[{
      expanded: !1,
      direction: "end"
    }, "_16lm1iwh"]]
  });
function xn(o) {
  var a;
  let {
      size: e = 24,
      direction: t = "start"
    } = o,
    [n, r] = dg((a = o.expanded) != null ? a : o.defaultExpanded),
    i = () => {
      var l;
      (l = o.onChange) == null || l.call(o, !n), Ot("expanded", o) || r(!n);
    };
  return oo.useEffect(() => {
    Ot("expanded", o) && r(o.expanded);
  }, [o.expanded]), oo.createElement("svg", {
    "data-name": "expander-icon",
    xmlns: "http://www.w3.org/2000/svg",
    height: `${e}px`,
    viewBox: "0 0 24 24",
    width: `${e}px`,
    style: o.style,
    onClick: i,
    className: te(o.className, Au, Fu({
      direction: t || "start",
      expanded: n
    }), `${Ye}`, `${Ye}-expander`, `InfiniteIcon-expander--${n ? "expanded" : "collapsed"}`, `InfiniteIcon-expander--${t === "end" ? "end" : "start"}`)
  }, oo.createElement("path", {
    d: "M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"
  }));
}
import * as ir from "react";
var _u = "_16lm1iwb _16lm1iwn _16lm1iwi";
var Ou = o => ir.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  style: {
    margin: "auto",
    display: "block",
    shapeRendering: "auto"
  },
  width: "24px",
  height: "24px",
  viewBox: "0 0 100 100",
  preserveAspectRatio: "xMidYMid",
  className: te(o.className, _u, "InfiniteIcon", "InfiniteIcon-loading")
}, ir.createElement("circle", {
  cx: "50",
  cy: "50",
  fill: "none",
  strokeWidth: "10",
  r: "35",
  strokeDasharray: "164.93361431346415 56.97787143782138"
}, ir.createElement("animateTransform", {
  attributeName: "transform",
  type: "rotate",
  repeatCount: "indefinite",
  dur: "1s",
  values: "0 50 50;360 50 50",
  keyTimes: "0;1"
})));
var Vu = Se({
    defaultClassName: "_7pupv0c",
    variantClassNames: {
      align: {
        start: "_7pupv0d",
        center: "_7pupv0e",
        end: "_7pupv0f"
      }
    },
    defaultVariants: {},
    compoundVariants: []
  }),
  cv = Se({
    defaultClassName: "_7pupv02 _7pupv00",
    variantClassNames: {
      zebra: {
        false: "_7pupv03",
        even: "_7pupv04",
        odd: "_7pupv05"
      },
      groupRow: {
        true: "_7pupv06",
        false: "_7pupv07"
      },
      inlineGroupRow: {
        true: "_7pupv08",
        false: "_7pupv09"
      },
      showHoverRows: {
        true: "_7pupv0a",
        false: "_7pupv0b"
      }
    },
    defaultVariants: {},
    compoundVariants: []
  }),
  zu = "_7pupv01";
function Gu({
  rowInfo: o
}) {
  return {
    [Z(Ve.components.Row.groupNesting)]: o.dataSourceHasGrouping ? o.isGroupRow ? o.groupNesting - 1 : o.groupNesting : 0
  };
}
function ar({
  groupIndexForColumn: o,
  groupRenderStrategy: e
}) {
  return t => {
    let {
        rowInfo: n,
        renderBag: r,
        column: i,
        align: a
      } = t,
      {
        value: l,
        groupIcon: s,
        selectionCheckBox: u
      } = r,
      c = n,
      d = te(Sn.flex, i.align === "end" ? eo.rowReverse : eo.row, us.center, `${en}Expander`, e === "single-column" || e === "multi-column" && (!n.isGroupRow || u) ? Vu({
        align: a
      }) : null);
    if (e === "multi-column") {
      if (o + 1 !== c.groupNesting && c.isGroupRow) return u ? Qt.createElement("div", {
        className: d
      }, u) : null;
    } else e === "single-column" && c.isGroupRow;
    return Qt.createElement("div", {
      className: d
    }, s, u, Qt.createElement("div", {
      className: mt
    }, l != null ? l : null));
  };
}
function lr({
  groupIndexForColumn: o,
  groupRenderStrategy: e,
  toggleGroupRow: t,
  initialRenderGroupIcon: n
}) {
  return r => {
    var C, T, b;
    let {
        rowInfo: i,
        value: a,
        rootGroupBy: l,
        pivotBy: s,
        column: u
      } = r,
      c = e !== "inline" ? i : i.isGroupRow && ((C = i.parents) == null ? void 0 : C[o]) || i;
    if (!c) return null;
    let d = c.collapsed;
    if (e === "inline") {
      if (c.groupCount === 1) return a;
      if (c.groupNesting === o && d) return null;
    } else if (!c.isGroupRow || e === "multi-column" && o + 1 !== c.groupNesting) return null;
    let p = c.groupKeys,
      f = null,
      m = s ? (((T = c.groupKeys) == null ? void 0 : T.length) || 0) < (l == null ? void 0 : l.length) : (((b = c.groupKeys) == null ? void 0 : b.length) || 0) <= (l == null ? void 0 : l.length);
    return Eu(c) ? f = Qt.createElement(Ou, null) : m && (f = Qt.createElement(xn, {
      expanded: !d,
      direction: u.align === "end" ? "end" : "start",
      onChange: () => {
        t(p);
      }
    })), n && (r.renderBag.groupIcon = f, f = Qt.createElement(Je, {
      render: n,
      renderParam: r
    })), f;
  };
}
function Lu(o, e, t) {
  let {
      groupByForColumn: n,
      groupIndexForColumn: r,
      groupRenderStrategy: i
    } = o,
    a = n.column ? h({}, n.column) : {},
    l = D(h({
      header: `Group by ${n.field || n.groupField}`,
      groupByForColumn: n,
      render: ar({
        groupIndexForColumn: r,
        groupRenderStrategy: i
      })
    }, a), {
      renderGroupIcon: lr({
        initialRenderGroupIcon: a == null ? void 0 : a.renderGroupIcon,
        groupIndexForColumn: r,
        toggleGroupRow: e,
        groupRenderStrategy: i
      })
    });
  return t && (typeof t == "function" ? l = h(h({}, l), t(o, e)) : l = h(h({}, l), t)), l;
}
function Hu(o, e, t) {
  let n = typeof t == "function" ? t(o, e) : t,
    r = {};
  return o.sortable != null && (r.sortable = o.sortable), D(h(D(h({}, r), {
    header: "Group",
    groupByForColumn: o.groupBy,
    renderSelectionCheckBox: o.selectionMode === "multi-row",
    render: ar({
      groupIndexForColumn: 0,
      groupRenderStrategy: "single-column"
    })
  }), n), {
    renderGroupIcon: lr({
      initialRenderGroupIcon: n == null ? void 0 : n.renderGroupIcon,
      groupIndexForColumn: 0,
      toggleGroupRow: e,
      groupRenderStrategy: "single-column"
    })
  });
}
function Nu(o, e) {
  let t = [];
  for (let n in o) o.hasOwnProperty(n) && !e.hasOwnProperty(n) && t.push(o[n]);
  return t;
}
var Ku = "_1eexc2a6 _1eexc2a5 _16lm1iwz _16lm1iw1o _16lm1iw1q _16lm1iw2 _16lm1iw22 _16lm1iw23 _16lm1iw17",
  Bu = Se({
    defaultClassName: "_1eexc2ah",
    variantClassNames: {
      dragging: {
        false: "_1eexc2ai",
        true: "_1eexc2aj"
      },
      cellSelected: {
        false: "_1eexc2ak",
        true: "_1eexc2al"
      },
      align: {
        start: "_1eexc2am",
        end: "_1eexc2an",
        center: "_1eexc2ao"
      },
      verticalAlign: {
        start: "_1eexc2ap",
        end: "_1eexc2aq",
        center: "_1eexc2ar"
      },
      rowActive: {
        false: "_1eexc2as",
        true: "_1eexc2at"
      },
      groupRow: {
        false: "_1eexc2au",
        true: "_1eexc2av"
      },
      groupCell: {
        false: "_1eexc2aw",
        true: "_1eexc2ax"
      },
      zebra: {
        false: "_1eexc2ay",
        even: "_1eexc2az",
        odd: "_1eexc2a10"
      },
      rowSelected: {
        true: "_1eexc2a11",
        false: "_1eexc2a12",
        null: "_1eexc2a13"
      },
      first: {
        true: "_1eexc2a14",
        false: "_1eexc2a15"
      },
      last: {
        true: "_1eexc2a16",
        false: "_1eexc2a17"
      },
      groupByField: {
        true: "_1eexc2a18",
        false: "_1eexc2a19"
      },
      firstInCategory: {
        true: "_1eexc2a1a",
        false: "_1eexc2a1b"
      },
      lastInCategory: {
        true: "_1eexc2a1c",
        false: "_1eexc2a1d"
      },
      pinned: {
        start: "_1eexc2a1e",
        end: "_1eexc2a1f",
        false: "_1eexc2a1g"
      },
      filtered: {
        true: "_1eexc2a1h",
        false: "_1eexc2a1i"
      }
    },
    defaultVariants: {},
    compoundVariants: [[{
      pinned: "start",
      lastInCategory: !0
    }, "_1eexc2a1j"], [{
      pinned: "start",
      firstInCategory: !0
    }, "_1eexc2a1k"], [{
      pinned: "end",
      firstInCategory: !0
    }, "_1eexc2a1l"], [{
      pinned: "end",
      lastInCategory: !0
    }, "_1eexc2a1m"], [{
      align: "center",
      groupCell: !1
    }, "_1eexc2a1n"]]
  }),
  ku = Se({
    defaultClassName: "_1eexc2a8",
    variantClassNames: {
      right: {
        true: "_1eexc2a9",
        false: "_1eexc2aa"
      },
      left: {
        true: "_1eexc2ab",
        false: "_1eexc2ac"
      },
      top: {
        true: "_1eexc2ad",
        false: "_1eexc2ae"
      },
      bottom: {
        true: "_1eexc2af",
        false: "_1eexc2ag"
      }
    },
    defaultVariants: {},
    compoundVariants: []
  });
var sr = "_1eexc2a7";
import * as ur from "react";
import { useEffect as pg, useRef as mg } from "react";
var Wu = "qz8ht90 _16lm1iwi";
function fg() {
  return {
    checked: 1,
    disabled: o => o != null ? o : !1,
    domProps: 1
  };
}
var gg = bt({
  forwardProps: fg,
  mappedCallbacks: {
    checked: o => ({
      callbackName: "onChange",
      callbackParams: [o]
    })
  },
  debugName: "InfiniteCheckBox"
});
function hg() {
  let {
      componentState: o,
      componentActions: e
    } = Ie(),
    {
      checked: t,
      domProps: n,
      disabled: r
    } = o,
    i = mg(null);
  return pg(() => {
    i.current.indeterminate = t == null;
  }, [t]), ur.createElement("input", D(h({}, n), {
    className: te("InfiniteCheckBox", Wu, n == null ? void 0 : n.className),
    type: "checkbox",
    ref: i,
    checked: !!t,
    disabled: r,
    onChange: () => {
      r || (e.checked = !t);
    }
  }));
}
function Gt(o) {
  return ur.createElement(gg, h({}, o), ur.createElement(hg, null));
}
function Cg(o) {
  return typeof o.field == "string";
}
function $u(o) {
  let {
      column: e,
      rowInfo: t,
      fieldsToColumn: n
    } = o,
    r;
  if (e.groupByForColumn) {
    if (t.isGroupRow) {
      let i = t.groupBy[t.groupBy.length - 1];
      r = i ? n.get(i.field || i.groupField) : void 0;
    }
    !r && e.field && (r = n.get(e.field));
  }
  return r;
}
function qe(o) {
  let {
      getComputed: e,
      getDataSourceState: t,
      getState: n,
      rowIndex: r,
      columnId: i,
      api: a,
      dataSourceApi: l
    } = o,
    {
      dataArray: s
    } = t(),
    u = s[r],
    {
      isGroupRow: c
    } = u,
    d = e().computedColumnsMap.get(i),
    {
      activeRowIndex: p,
      keyboardNavigation: f,
      isRowDetailEnabled: m
    } = n(),
    g = r === p && f === "row",
    C = !m || typeof m == "function" && !m(u) ? !1 : a.rowDetailApi.isRowDetailExpanded(u.id) ? "expanded" : "collapsed",
    {
      formattedValue: T,
      formattedValueContext: {
        rawValue: b
      }
    } = vn({
      column: d,
      rowInfo: u,
      rowDetailState: C,
      columnsMap: e().computedColumnsMap,
      context: o
    }),
    S = We(d.id, o);
  return c ? {
    api: a,
    dataSourceApi: l,
    column: d,
    columnApi: S,
    isGroupRow: !0,
    rowDetailState: !1,
    data: u.data,
    rowActive: g,
    rowInfo: u,
    rowSelected: u.rowSelected,
    value: T,
    rawValue: b
  } : {
    api: a,
    dataSourceApi: l,
    columnApi: S,
    column: d,
    isGroupRow: !1,
    rowDetailState: C,
    data: u.data,
    rowActive: g,
    rowInfo: u,
    rowSelected: u.rowSelected,
    value: T,
    rawValue: b
  };
}
function ju(o) {
  return Yu(o.column, o.rowInfo);
}
function Uu(o) {
  var m, g;
  let {
      column: e,
      context: t,
      rowInfo: n,
      visibleColumnsIds: r
    } = o,
    i = $u({
      rowInfo: n,
      column: e,
      fieldsToColumn: o.fieldsToColumn,
      groupRenderStrategy: t.getState().groupRenderStrategy
    }),
    {
      getState: a
    } = t,
    {
      editingCell: l
    } = a(),
    s = vn(D(h({}, o), {
      column: e
    })),
    {
      formattedValueContext: u
    } = s,
    c = t.api.isEditorVisibleForCell({
      columnId: e.id,
      rowIndex: n.indexInAll
    }),
    d = D(h({
      column: o.column,
      inEdit: c,
      rowHasSelectedCells: !1
    }, u), {
      editError: l && l.primaryKey === n.id && l.columnId === e.id && !l.active && l.accepted instanceof Error ? l.accepted : void 0
    });
  Object.defineProperty(d, "rowHasSelectedCells", {
    get() {
      return n.hasSelectedCells(r);
    }
  });
  let p = typeof e.align == "function" ? e.align(h({
      isHeader: !1
    }, d)) : (m = e.align) != null ? m : "start",
    f = typeof e.verticalAlign == "function" ? e.verticalAlign(h({
      isHeader: !1
    }, d)) : (g = e.verticalAlign) != null ? g : "center";
  return {
    inEdit: c,
    stylingParam: d,
    formattedValueContext: u,
    renderFunctions: {
      renderGroupIcon: e.renderGroupIcon || (i == null ? void 0 : i.renderGroupIcon),
      renderSelectionCheckBox: e.renderSelectionCheckBox,
      renderRowDetailIcon: e.renderRowDetailIcon,
      renderValue: e.renderValue || (i == null ? void 0 : i.renderValue),
      renderGroupValue: e.renderGroupValue || (i == null ? void 0 : i.renderGroupValue),
      renderLeafValue: e.renderLeafValue || (i == null ? void 0 : i.renderLeafValue)
    },
    renderParams: Sg(D(h({}, o), {
      align: p,
      verticalAlign: f,
      formattedValueContext: u
    })),
    groupByColumnReference: i
  };
}
function Sg(o) {
  var I;
  let {
      column: e,
      context: t,
      rowInfo: n,
      align: r,
      verticalAlign: i,
      visibleColumnsIds: a,
      columnsMap: l,
      fieldsToColumn: s,
      formattedValueContext: u
    } = o,
    {
      value: c
    } = u,
    {
      api: d,
      getDataSourceState: p,
      getState: f
    } = t,
    {
      editingCell: m
    } = f(),
    {
      indexInAll: g
    } = n,
    C = p(),
    {
      selectionMode: T,
      cellSelection: b
    } = C,
    S = $u({
      rowInfo: n,
      column: e,
      fieldsToColumn: s,
      groupRenderStrategy: t.getState().groupRenderStrategy
    }),
    y = d.toggleGroupRow,
    w = (I = b == null ? void 0 : b.isCellSelected(n.id, e.id)) != null ? I : !1,
    v = D(h({
      column: e,
      columnsMap: l,
      fieldsToColumn: s,
      align: r,
      verticalAlign: i,
      cellSelected: w,
      rowHasSelectedCells: !1
    }, u), {
      editError: m && m.primaryKey === n.id && m.columnId === e.id && !m.active && m.accepted instanceof Error ? m.accepted : void 0,
      groupByColumn: S,
      selectionMode: T,
      api: d,
      toggleCurrentRowDetails: () => d.rowDetailApi.toggleRowDetail(n.id),
      toggleRowDetails: d.rowDetailApi.toggleRowDetail,
      expandRowDetails: d.rowDetailApi.expandRowDetail,
      collapseRowDetails: d.rowDetailApi.collapseRowDetail,
      selectRow: d.rowSelectionApi.selectRow,
      deselectRow: d.rowSelectionApi.deselectRow,
      toggleRowSelection: d.rowSelectionApi.toggleRowSelection,
      toggleGroupRowSelection: d.rowSelectionApi.toggleGroupRowSelection,
      selectCell: () => {
        d.cellSelectionApi.selectCell({
          rowId: n.id,
          colId: e.id
        });
      },
      deselectCell: () => {
        d.cellSelectionApi.deselectCell({
          rowId: n.id,
          colId: e.id
        });
      },
      renderBag: {
        all: null,
        value: typeof c == "boolean" ? `${c}` : c,
        selectionCheckBox: null,
        groupIcon: null
      },
      selectCurrentRow: () => d.rowSelectionApi.selectRow(n.id, n.dataSourceHasGrouping ? n.groupKeys : void 0),
      deselectCurrentRow: () => d.rowSelectionApi.deselectRow(n.id, n.dataSourceHasGrouping ? n.groupKeys : void 0),
      rowIndex: g,
      toggleGroupRow: y,
      toggleCurrentGroupRow: () => {
        n.isGroupRow && y(n.groupKeys);
      },
      toggleCurrentRowSelection: () => {
        if (!n.isGroupRow) return d.rowSelectionApi.toggleRowSelection(n.id);
      },
      toggleCurrentGroupRowSelection: () => {
        if (n.isGroupRow) return d.rowSelectionApi.toggleGroupRowSelection(n.isGroupRow ? n.groupKeys : []);
      },
      rootGroupBy: C.groupBy,
      pivotBy: C.pivotBy
    });
  return Object.defineProperty(v, "rowHasSelectedCells", {
    get() {
      return n.hasSelectedCells(a);
    }
  }), v;
}
function Yu(o, e) {
  let {
      data: t,
      isGroupRow: n,
      dataSourceHasGrouping: r
    } = e,
    i = r ? e.groupBy : void 0,
    a = n && i && o.groupByForColumn && (Array.isArray(o.groupByForColumn) || o.groupByForColumn === i[i.length - 1]) ? e.value : Cg(o) ? t == null ? void 0 : t[o.field] : null;
  return o.field && e.isGroupRow && e.reducerData && e.reducerData[o.field] != null && (a = e.reducerData[o.field]), o.valueGetter && !e.isGroupRow && e.data && (a = o.valueGetter({
    data: e.data,
    field: o.field
  })), a;
}
function yg(o, e, t, n, r) {
  let {
      rowSelected: i,
      indexInAll: a
    } = t,
    {
      activeRowIndex: l,
      keyboardNavigation: s
    } = n.getState(),
    u = a === l && s === "row";
  return t.isGroupRow ? {
    rowInfo: t,
    isGroupRow: t.isGroupRow,
    data: t.data,
    rowDetailState: !1,
    rowSelected: i,
    rowActive: u,
    value: o,
    rawValue: o,
    field: e.field
  } : {
    rowInfo: t,
    isGroupRow: t.isGroupRow,
    data: t.data,
    rowDetailState: r,
    rowSelected: i,
    rowActive: u,
    value: o,
    rawValue: o,
    field: e.field
  };
}
function vn(o) {
  let {
      column: e,
      rowInfo: t,
      context: n,
      rowDetailState: r
    } = o,
    i = Yu(e, t),
    a = yg(i, e, t, n, r),
    l = e.valueFormatter ? e.valueFormatter(a) : i;
  return a.value = l, {
    formattedValue: l,
    formattedValueContext: a
  };
}
import * as Rt from "react";
var {
    rootClassName: qu
  } = Re,
  tn = `${qu}Cell`,
  zi = `${qu}Cell_content`,
  Tg = Z($.columnWidthAtIndex),
  bg = Z($.columnReorderEffectDurationAtIndex);
function Rg(o) {
  var w, v;
  let y = o,
    {
      cssEllipsis: e = !0,
      virtualized: t = !0,
      skipColumnShifting: n = !1,
      contentStyle: r,
      column: i,
      domRef: a,
      width: l,
      contentClassName: s,
      rowId: u,
      afterChildren: c,
      beforeChildren: d,
      cellType: p,
      cssPosition: f,
      renderChildren: m
    } = y,
    g = un(y, ["cssEllipsis", "virtualized", "skipColumnShifting", "contentStyle", "column", "domRef", "width", "contentClassName", "rowId", "afterChildren", "beforeChildren", "cellType", "cssPosition", "renderChildren"]),
    C = m(),
    T = h({
      width: `var(${Tg}-${i.computedVisibleIndex})`,
      transition: `transform var(${bg}-${i.computedVisibleIndex}, 0s)`
    }, g.style),
    b = D(h({}, g), {
      style: T,
      "data-column-id": i.id,
      "data-z-index": T.zIndex,
      className: te(g.className, tn, Ku),
      children: Rt.createElement(Rt.Fragment, null, d, Rt.createElement("div", {
        className: te(zi, e ? mt : fs.hidden, s),
        style: r
      }, C), c)
    });
  u != null && (b["data-row-id"] = `${u}`);
  let S = p === "body" ? (w = i.components) == null ? void 0 : w.ColumnCell : (v = i.components) == null ? void 0 : v.HeaderCell;
  return S ? Rt.createElement(S, h({}, b)) : Rt.createElement("div", D(h({}, b), {
    ref: a
  }));
}
var cr = Rt.memo(Rg);
import * as nn from "react";
import { useCallback as Xu, useRef as wg } from "react";
function Zu() {
  let {
      initialValue: o,
      setValue: e,
      confirmEdit: t,
      cancelEdit: n,
      readOnly: r
    } = Gi(),
    i = wg(),
    a = nn.useCallback(s => {
      i.current = s, s && s.focus();
    }, []),
    l = Xu(s => {
      let {
        key: u
      } = s;
      u === "Enter" || u === "Tab" ? t() : u === "Escape" ? n() : s.stopPropagation();
    }, []);
  return nn.createElement(nn.Fragment, null, nn.createElement("input", {
    readOnly: r,
    ref: a,
    onKeyDown: l,
    onBlur: () => t(),
    className: te(ss, ms.none),
    type: "text",
    defaultValue: o,
    onChange: Xu(s => {
      e(s.target.value);
    }, [])
  }));
}
var {
    rootClassName: vg
  } = Re,
  Pg = Z($.columnZIndexAtIndex),
  ro = de.createContext(null),
  en = `${vg}ColumnCell`,
  Dg = o => {
    let {
      toggleCurrentRowDetails: e,
      rowDetailState: t
    } = o;
    return de.createElement(xn, {
      style: {
        visibility: t === !1 ? "hidden" : "visible"
      },
      expanded: t === "expanded",
      onChange: e
    });
  },
  Mg = o => {
    let {
      rowInfo: e,
      selectCurrentRow: t,
      deselectCurrentRow: n,
      toggleCurrentGroupRowSelection: r,
      column: i
    } = o;
    return e.isGroupRow && i.groupByForColumn, de.createElement(Gt, {
      domProps: {
        className: sr
      },
      onChange: a => {
        if (e.isGroupRow) {
          r();
          return;
        }
        a ? t() : n();
      },
      checked: e.rowSelected
    });
  };
function Li(o, e) {
  return o ? typeof o == "function" ? o(e) : o : void 0;
}
function Hi(o, e, t) {
  return typeof e == "function" ? h(h({}, o), e(t)) : h(h({}, o), e);
}
function Eg(o) {
  var ne, je, Ue;
  let {
    rowInfo: e,
    rowStyle: t,
    rowClassName: n,
    getData: r,
    cellStyle: i,
    cellClassName: a,
    rowDetailState: l,
    width: s,
    column: u,
    onMouseLeave: c,
    onMouseEnter: d,
    rowIndex: p,
    rowHeight: f,
    columnsMap: m,
    fieldsToColumn: g,
    domRef: C,
    hidden: T,
    showZebraRows: b
  } = o;
  if (!u) return de.createElement("div", {
    ref: C
  }, "no column");
  let {
      rowSelected: S
    } = e,
    {
      getState: y,
      actions: w,
      computed: v,
      api: I,
      getDataSourceMasterContext: R
    } = ae(),
    {
      componentState: x,
      getState: E,
      api: M,
      componentActions: V
    } = he(),
    {
      activeRowIndex: z,
      keyboardNavigation: P
    } = y(),
    F = p === z && P === "row",
    A = {
      getState: y,
      getDataSourceState: E,
      getDataSourceMasterContext: R,
      actions: w,
      dataSourceActions: V,
      api: I,
      dataSourceApi: M
    },
    O = v.computedVisibleColumns.map(Te => Te.id),
    B = Uu({
      column: u,
      rowInfo: e,
      rowDetailState: l,
      columnsMap: m,
      visibleColumnsIds: O,
      fieldsToColumn: g,
      context: A
    }),
    {
      stylingParam: k,
      renderParams: J,
      formattedValueContext: oe,
      renderFunctions: q,
      groupByColumnReference: Q,
      inEdit: L
    } = B,
    {
      align: N,
      verticalAlign: j
    } = J,
    _ = J,
    H = de.useRef(_),
    G = ft(Te => {
      let Fe = u.computedVisibleIndex;
      y().cellClick({
        rowIndex: p,
        colIndex: Fe,
        event: Te
      });
    }, [p, u.computedVisibleIndex, P]),
    W = ft(Te => {
      let Fe = u.computedVisibleIndex;
      if (y().cellMouseDown({
        rowIndex: p,
        colIndex: Fe,
        event: Te
      }), P === "row") {
        w.activeRowIndex = p;
        return;
      }
      P === "cell" && (w.activeCellIndex = [p, Fe]);
    }, [p, u.computedVisibleIndex, P]),
    {
      selectionMode: X,
      cellSelection: Y
    } = x,
    ee = _.cellSelected;
  _.domRef = C, _.selectCell = ft(_.selectCell, [e]), _.deselectCell = ft(_.deselectCell, [e]), _.selectCurrentRow = ft(_.selectCurrentRow, [e]), _.deselectCurrentRow = ft(_.deselectCurrentRow, [e]), _.toggleCurrentGroupRow = ft(_.toggleCurrentGroupRow, [e]), _.toggleCurrentRowSelection = ft(_.toggleCurrentRowSelection, [e]), _.toggleCurrentGroupRowSelection = ft(_.toggleCurrentGroupRowSelection, [e]);
  let pe = (je = (ne = u.components) == null ? void 0 : ne.Editor) != null ? je : Zu,
    ue = L ? de.createElement(ec, {
      contextValue: _
    }, de.createElement(pe, null)) : null,
    Ae = ft(Ft(() => {
      if (T || L) return null;
      q.renderGroupIcon && (_.renderBag.groupIcon = de.createElement(Je, {
        render: q.renderGroupIcon,
        renderParam: D(h({}, _), {
          renderBag: h({}, _.renderBag)
        })
      })), q.renderSelectionCheckBox && X == "multi-row" && (_.renderBag.selectionCheckBox = de.createElement(Je, {
        render: Mg,
        renderParam: _
      }), q.renderSelectionCheckBox !== !0 && (_.renderBag.selectionCheckBox = de.createElement(Je, {
        render: q.renderSelectionCheckBox,
        renderParam: D(h({}, _), {
          renderBag: h({}, _.renderBag)
        })
      }))), q.renderRowDetailIcon && (_.renderBag.rowDetailsIcon = de.createElement(Je, {
        render: Dg,
        renderParam: D(h({}, _), {
          renderBag: h({}, _.renderBag)
        })
      }), typeof q.renderRowDetailIcon == "function" && (_.renderBag.rowDetailsIcon = de.createElement(Je, {
        render: q.renderRowDetailIcon,
        renderParam: D(h({}, _), {
          renderBag: h({}, _.renderBag)
        })
      }))), q.renderValue && (_.renderBag.value = de.createElement(Je, {
        render: q.renderValue,
        renderParam: D(h({}, _), {
          renderBag: h({}, _.renderBag)
        })
      })), e.isGroupRow && q.renderGroupValue && (_.renderBag.value = de.createElement(Je, {
        render: q.renderGroupValue,
        renderParam: D(h({}, _), {
          renderBag: h({}, _.renderBag)
        })
      })), !e.isGroupRow && q.renderLeafValue && (_.renderBag.value = de.createElement(Je, {
        render: q.renderLeafValue,
        renderParam: D(h({}, _), {
          renderBag: h({}, _.renderBag)
        })
      })), H.current = _;
      let Te = _.renderBag.value;
      Te instanceof Date && (Te = Te.toLocaleDateString());
      let Fe = de.createElement(de.Fragment, null, N !== "end" ? _.renderBag.groupIcon : null, N !== "end" ? _.renderBag.rowDetailsIcon : null, N !== "end" ? _.renderBag.selectionCheckBox : null, Te, N === "end" ? _.renderBag.selectionCheckBox : null, N === "end" ? _.renderBag.rowDetailsIcon : null, N === "end" ? _.renderBag.groupIcon : null);
      return u.render ? de.createElement(Je, {
        render: u.render,
        renderParam: D(h({}, _), {
          renderBag: D(h({}, _.renderBag), {
            all: Fe
          })
        })
      }) : Fe;
    }), [u, T, L, l, ...Nu(_, {
      renderBag: !0,
      selectCell: !0,
      deselectCell: !0,
      selectCurrentRow: !0,
      deselectCurrentRow: !0,
      toggleCurrentGroupRow: !0,
      toggleCurrentRowDetails: !0,
      toggleCurrentRowSelection: !0,
      toggleCurrentGroupRowSelection: !0,
      rowHasSelectedCells: !0
    })]),
    ze = v.computedVisibleColumns.map(Te => Te.id),
    Ge = v.computedColumnOrder,
    fe = D(h({}, oe), {
      visibleColumnIds: ze,
      allColumnIds: Ge,
      rowIndex: p,
      rowHasSelectedCells: !1
    });
  Object.defineProperty(fe, "rowHasSelectedCells", {
    get() {
      return e.hasSelectedCells(ze);
    }
  });
  let ye = typeof n == "function" ? n(fe) : n,
    Ke;
  Q != null && Q.className && (Ke = Li(Q.className, k)), a && (Ke = te(Ke, Li(a, k))), u.className && (Ke = te(Ke, Li(u.className, k)));
  let se;
  e.dataSourceHasGrouping && u.groupByForColumn && (se = Gu({
    rowInfo: e
  })), t && (se = typeof t == "function" ? h(h({}, se), t(fe)) : h(h({}, se), t)), i && (se = Hi(se, i, k)), Q != null && Q.style && (se = Hi(se, Q.style, k)), u.style && (se = Hi(se, u.style, k)), se = se || {}, se.height = f, se.zIndex = `var(${Pg}-${u.computedVisibleIndex})`;
  let lt = xg(() => se, [se ? JSON.stringify(se) : null]),
    st = (e.indexInAll != null ? e.indexInAll : p) % 2 === 1,
    Et = b ? st ? "odd" : "even" : !1,
    Wt = !1,
    $t = !1,
    jt = !1,
    K = !1;
  if (Y && ee) {
    let Te = r(),
      Fe = Te[e.indexInAll - 1],
      et = Te[e.indexInAll + 1],
      ut = v.computedVisibleColumns[u.computedVisibleIndex + 1],
      ct = v.computedVisibleColumns[u.computedVisibleIndex - 1];
    Wt = Fe ? !Y.isCellSelected(Fe.id, u.id) : !0, u.computedPinned === "end" && u.computedFirstInCategory ? $t = !0 : $t = ct ? !Y.isCellSelected(e.id, ct.id) : !0, u.computedPinned === "start" && u.computedLastInCategory ? jt = !0 : jt = ut ? !Y.isCellSelected(e.id, ut.id) : !0, K = et ? !Y.isCellSelected(et.id, u.id) : !0;
  }
  let U = Y && ee ? {
      top: Wt,
      left: $t,
      right: jt,
      bottom: K
    } : null,
    ie = ue,
    ce = {
      domRef: C,
      cellType: "body",
      column: u,
      width: s,
      rowId: e.id,
      style: lt,
      onMouseLeave: c,
      onMouseEnter: d,
      onClick: G,
      afterChildren: ie,
      onMouseDown: W,
      cssEllipsis: (Ue = u.cssEllipsis) != null ? Ue : !0,
      className: te(U ? ku(U) : "", rr(u, [en, tn], Bu, {
        dragging: !1,
        zebra: Et,
        align: N,
        verticalAlign: j,
        rowActive: F,
        cellSelected: ee,
        rowSelected: S,
        groupRow: e.isGroupRow,
        groupCell: e.isGroupRow ? !!u.groupByForColumn : !1,
        rowExpanded: e.isGroupRow ? !e.collapsed : !1
      }), Ke, ye),
      renderChildren: Ae
    },
    Le = ro.Provider;
  return de.createElement(Le, {
    value: H.current
  }, de.createElement(cr, h({}, ce)));
}
var Ju = de.memo(Eg);
function Qu() {
  return Ig(ro);
}
function Gi() {
  let {
      api: o,
      state: {
        editingValueRef: e,
        editingCell: t
      }
    } = ae(),
    {
      column: n,
      rowInfo: r
    } = Qu(),
    [i] = de.useState(() => t == null ? void 0 : t.value),
    [a, l] = de.useState(i),
    s = t ? !t.active && !!t.waiting : !1,
    u = de.useCallback(f => {
      e.current = f, l(f);
    }, []);
  de.useLayoutEffect(() => {
    e.current = i;
  }, []);
  let c = o.confirmEdit,
    d = o.cancelEdit,
    p = o.rejectEdit;
  return {
    api: o,
    initialValue: i,
    value: a,
    readOnly: s,
    column: n,
    rowInfo: r,
    setValue: u,
    confirmEdit: c,
    cancelEdit: d,
    rejectEdit: p
  };
}
function io(o) {
  var e;
  return (e = o.render(o.renderParam)) != null ? e : null;
}
function Je(o) {
  let e = ro.Provider;
  return Pn.createElement(e, {
    value: o.renderParam
  }, Pn.createElement(io, {
    render: o.render,
    renderParam: o.renderParam
  }));
}
function ec(o) {
  let e = ro.Provider;
  return Pn.createElement(e, {
    value: o.contextValue
  }, o.children);
}
function Lt(o) {
  let e = dr.Provider;
  return Pn.createElement(e, {
    value: o.renderParam
  }, Pn.createElement(io, {
    render: o.render,
    renderParam: o.renderParam
  }));
}
import * as Ht from "react";
var Ag = {
    width: "100%",
    pointerEvents: "none"
  },
  Ni = `${Ye}-menu`,
  Ki = Yo({
    "data-name": ""
  }),
  Bi = {
    [Ki["data-name"]]: "menu-icon"
  };
function ao(o) {
  let {
      style: e,
      className: t,
      domProps: n,
      reserveSpaceWhenHidden: r,
      menuVisible: i,
      children: a
    } = o,
    l = h(D(h({}, Ag), {
      borderTop: `${Ve.components.HeaderCell.menuIconLineWidth} solid currentColor`
    }), o.lineStyle);
  return Ht.createElement("div", D(h(D(h({}, n), {
    style: e
  }), Bi), {
    onPointerDown: s => s.stopPropagation(),
    className: te(t, $s({
      menuVisible: i,
      reserveSpaceWhenHidden: r
    }), Ye, `${Ye}-menu`)
  }), a != null ? a : Ht.createElement(Ht.Fragment, null, Ht.createElement("div", {
    className: Ni,
    style: l
  }), Ht.createElement("div", {
    className: Ni,
    style: l
  }), Ht.createElement("div", {
    className: Ni,
    style: l
  })));
}
import * as Dn from "react";
import { useEffect as Fg, useState as nc } from "react";
var tc = "_1ek6mqy0 _16lm1iwz _16lm1iw1m _16lm1iw1 _16lm1iw1u";
var _g = {
  transition: "width 0.25s, opacity 0.25s"
};
function oc(o) {
  var T;
  let [e, t] = nc(!0),
    [n, r] = nc(0),
    {
      direction: i,
      lineWidth: a = 1,
      style: l,
      className: s,
      index: u
    } = o,
    c = u != null && u > 0,
    d = (T = o.size) != null ? T : 16,
    p = Math.floor(d / 4),
    f = i ? [d - 3 * p, d - 2 * p, d - p] : [0, 0, 0];
  i === -1 && f.reverse();
  let m = D(h(D(h({}, _g), {
    borderTop: `${a}px solid currentColor`
  }), o.lineStyle), {
    opacity: n
  });
  Fg(() => {
    let b = i != 0 ? 1 : 0;
    if (!e && b && t(!0), b !== n) {
      let S = requestAnimationFrame(() => {
        r(b);
      });
      return () => {
        cancelAnimationFrame(S);
      };
    }
    return () => {};
  }, [i, e]);
  let g = () => {
    let S = !(n === 0 && i === 0);
    S !== e && t(S);
  };
  if (!e) return null;
  let C = {};
  return i === -1 && (C.top = "100%"), Dn.createElement("div", {
    "data-name": "sort-icon",
    style: D(h({}, l), {
      width: d
    }),
    className: te(s, Ye, tc, `${Ye}-sort`)
  }, c ? Dn.createElement("div", {
    "data-name": "index",
    style: C,
    className: Ys
  }, u) : null, Dn.createElement("div", {
    style: h({
      width: f[0]
    }, m),
    onTransitionEnd: g
  }), Dn.createElement("div", {
    style: h({
      width: f[1]
    }, m)
  }), Dn.createElement("div", {
    style: h({
      width: f[2]
    }, m)
  }));
}
import * as uc from "react";
import { useCallback as sc } from "react";
import { useRef as ac, useState as Og } from "react";
import * as lo from "react";
function rc(o, {
  columns: e,
  shareSpaceOnResize: t,
  domRef: n
}) {
  let r = e[o];
  return {
    resize(i) {
      _o(o, r.computedWidth + i, n.current);
      let a = o + 1;
      if (t) {
        let l = e[a];
        if (!l) return;
        _o(a, l.computedWidth - i, n.current), l.computedPinned === "start" ? ti(l, i, n.current) : mn(a, l.computedOffset + i, n.current);
        return;
      }
      if (r.computedPinned !== "end") {
        if (r.computedPinned === "start") {
          for (let l = a, s = e.length; l < s; l++) {
            let u = e[l];
            if (u.computedPinned === "start") {
              ti(u, i, n.current);
              continue;
            }
            u.computedPinned !== "end" && mn(l, u.computedOffset + i, n.current);
          }
          return;
        }
        for (let l = a, s = e.length; l < s; l++) {
          let u = e[l];
          u.computedPinned || mn(l, u.computedOffset + i, n.current);
        }
      }
    }
  };
}
function ic(o, e) {
  let t = o[o.length - 1];
  return {
    resize(n) {
      let {
          columns: r,
          domRef: i
        } = e,
        a = r[o[0]],
        l = a.computedOffset,
        s = 0,
        u = i.current;
      if (n.forEach((c, d) => {
        s += c;
        let p = o[d],
          f = r[p],
          m = f.computedWidth + c;
        _o(p, m, u), f.computedPinned !== "end" && (l && mn(p, l, u), l += m);
      }), a.computedPinned !== "end") for (let c = t + 1; c < r.length; c++) {
        let d = r[c];
        d.computedPinned !== "end" && mn(c, d.computedOffset + s, u);
      }
    }
  };
}
var pr = "zneyzh0 _16lm1iw2 _16lm1iw1c _16lm1iw1j _16lm1iw1h _16lm1iwk _16lm1iw1x",
  mr = Se({
    defaultClassName: "zneyzhc _16lm1iw2 _16lm1iw1c _16lm1iw1h",
    variantClassNames: {
      constrained: {
        false: "zneyzhd",
        true: "zneyzhe"
      },
      computedPinned: {
        start: "zneyzhf",
        end: "zneyzhg",
        false: "zneyzhh"
      },
      computedFirstInCategory: {
        true: "zneyzhi",
        false: "zneyzhj"
      },
      computedLastInCategory: {
        true: "zneyzhk",
        false: "zneyzhl"
      }
    },
    defaultVariants: {},
    compoundVariants: [[{
      computedPinned: "start",
      computedLastInCategory: !0
    }, "zneyzhm"], [{
      computedPinned: "end",
      computedFirstInCategory: !0
    }, "zneyzhn"]]
  }),
  fr = Se({
    defaultClassName: "zneyzh1",
    variantClassNames: {
      computedPinned: {
        start: "zneyzh2",
        end: "zneyzh3 _16lm1iw1e _16lm1iw1l",
        false: "zneyzh4"
      },
      computedFirstInCategory: {
        true: "zneyzh5",
        false: "zneyzh6"
      },
      computedLastInCategory: {
        true: "zneyzh7",
        false: "zneyzh8"
      }
    },
    defaultVariants: {},
    compoundVariants: [[{
      computedPinned: "end",
      computedLastInCategory: !1,
      computedFirstInCategory: !0
    }, "zneyzh9"], [{
      computedPinned: !1,
      computedFirstInCategory: !1,
      computedLastInCategory: !0
    }, "zneyzha"], [{
      computedPinned: "start",
      computedFirstInCategory: !1,
      computedLastInCategory: !0
    }, "zneyzhb"]]
  });
var {
    rootClassName: Vg
  } = Re,
  zg = `${Vg}HeaderCell_ResizeHandle`;
function Gg(o) {
  let e = ac(null),
    [t, n] = Og(!1),
    r = ac(t);
  r.current = t;
  let i = o.columns[o.columnIndex];
  if (!i) return null;
  let a = i.computedPinned,
    l = i.computedFirstInCategory,
    s = i.computedLastInCategory,
    u = d => {
      d.stopPropagation();
      let p = !!d.shiftKey,
        f = d.pointerId,
        m = d.clientX,
        g = d.target;
      g.setPointerCapture(f);
      let C = rc(o.columnIndex, {
          columns: o.columns,
          shareSpaceOnResize: p,
          domRef: e
        }),
        T = y => {
          a === "end" && (y *= -1);
          let {
            adjustedDiff: w,
            constrained: v
          } = o.computeResize({
            diff: y,
            shareSpaceOnResize: p
          });
          return r.current !== v && n(v), C.resize(w), w;
        },
        b = y => {
          T(Math.round(y.clientX - m));
        },
        S = y => {
          g.releasePointerCapture(f), g.removeEventListener("pointermove", b), g.removeEventListener("pointerup", S);
          let w = Math.round(y.clientX - m),
            v = T(w);
          o.onResize({
            diff: v,
            shareSpaceOnResize: p
          });
        };
      g.addEventListener("pointermove", b), g.addEventListener("pointerup", S);
    },
    c = (a === !1 || a === "start") && s ? {
      right: a === "start" ? void 0 : 0
    } : a === "end" && l ? {
      right: void 0
    } : void 0;
  return lo.createElement("div", {
    ref: e,
    className: `${zg} ${pr} ${fr({
      computedPinned: a,
      computedFirstInCategory: l,
      computedLastInCategory: s
    })}`,
    onPointerDown: u
  }, lo.createElement("div", {
    style: c,
    className: mr({
      constrained: t,
      computedPinned: a,
      computedFirstInCategory: l,
      computedLastInCategory: s
    })
  }));
}
var lc = lo.memo(Gg);
function cc(o) {
  let {
      computed: {
        computedVisibleColumns: e
      },
      getState: t,
      getComputed: n,
      actions: r
    } = ae(),
    i = sc(({
      diff: s,
      shareSpaceOnResize: u
    }) => {
      let c = t(),
        {
          columnSizing: d,
          viewportReservedWidth: p,
          bodySize: f
        } = c,
        m = n().computedVisibleColumns,
        g = !1,
        C = m.reduce((S, y) => (y.computedFlex && (S[y.id] = D(h({}, d[y.id]), {
          flex: y.computedWidth
        }), g = !0), S), {}),
        T = g ? h(h({}, d), C) : d;
      return vi({
        shareSpaceOnResize: u,
        availableSize: f.width,
        reservedWidth: p || 0,
        dragHandleOffset: s,
        dragHandlePositionAfter: o.computedVisibleIndex,
        columnSizing: T,
        items: m.map(S => ({
          id: S.id,
          computedFlex: S.computedFlex,
          computedWidth: S.computedWidth,
          computedMinWidth: S.computedMinWidth,
          computedMaxWidth: S.computedMaxWidth
        }))
      });
    }, [o]),
    a = sc(({
      diff: s,
      shareSpaceOnResize: u
    }) => {
      let {
        columnSizing: c,
        reservedWidth: d
      } = i({
        diff: s,
        shareSpaceOnResize: u
      });
      u || (r.viewportReservedWidth = d), r.columnSizing = c;
    }, [i]);
  return o.computedResizable ? uc.createElement(lc, {
    columns: e,
    columnIndex: o.computedVisibleIndex,
    computeResize: i,
    onResize: a
  }) : null;
}
var kg = o => {
    let {
      allRowsSelected: e,
      someRowsSelected: t,
      api: n
    } = o;
    return Ce.createElement(Gt, {
      domProps: {
        className: sr
      },
      onChange: i => {
        i ? n.rowSelectionApi.selectAll() : n.rowSelectionApi.deselectAll();
      },
      checked: e ? !0 : t ? null : !1
    });
  },
  dr = Ce.createContext(null),
  {
    rootClassName: Wg
  } = Re,
  Mn = `${Wg}HeaderCell`;
var $g = Z($.columnZIndexAtIndex),
  ki = Ce.createElement("div", {
    className: $o[1]
  }),
  dc = Yo({
    "data-name": "",
    "data-field": "",
    "data-header-align": "",
    "data-column-id": "",
    "data-sort": "",
    "data-sort-index": ""
  });
function pc(o) {
  var jt, K, U, ie, ce, Le, ne, je, Ue, Te, Fe, et, ut, ct, Ct, At;
  let e = o.column,
    {
      api: t,
      getComputed: n,
      getState: r,
      actions: i,
      state: {
        showColumnFilters: a,
        components: l,
        portalDOMRef: s,
        columnHeaderHeight: u,
        columnReorderDragColumnId: c,
        columnMenuVisibleForColumnId: d
      },
      getDataSourceMasterContext: p
    } = ae(),
    {
      api: f,
      componentActions: m,
      getState: g,
      componentState: {
        filterDelay: C,
        filterTypes: T
      }
    } = he(),
    {
      allRowsSelected: b,
      someRowsSelected: S,
      selectionMode: y
    } = cn(),
    w = c === e.id,
    {
      onResize: v,
      height: I,
      width: R,
      headerOptions: x,
      columnsMap: E
    } = o,
    M = e.computedSortInfo,
    V = e.header,
    z = Lg(re => {
      var tt;
      W.current = re, (tt = o.domRef) == null || tt.call(o, re);
    }, [o.domRef]),
    P = x.alwaysReserveSpaceForSortIcon,
    F = (jt = e.headerAlign) != null ? jt : e.align,
    A = (K = typeof F == "function" ? F({
      isHeader: !0,
      column: e
    }) : F) != null ? K : "start",
    O = (U = typeof e.verticalAlign == "function" ? e.verticalAlign({
      isHeader: !0,
      column: e
    }) : e.verticalAlign) != null ? U : "center",
    B = We(e, {
      actions: i,
      api: t,
      dataSourceActions: m,
      dataSourceApi: f,
      getComputed: n,
      getDataSourceState: g,
      getDataSourceMasterContext: p,
      getState: r
    }),
    k = B.isSortable(),
    J = k && (e.computedSorted || P) ? Ce.createElement(oc, {
      index: e.computedMultiSort ? e.computedSortIndex + 1 : void 0,
      style: {
        marginInlineStart: Ve.spacing[2],
        marginInlineEnd: Ve.spacing[2]
      },
      className: `${Mn}__sort-icon ${Us} ${qs({
        align: A
      })}`,
      direction: e.computedSorted ? e.computedSortedAsc ? 1 : -1 : 0
    }) : null,
    q = e.computedFilterable && e.computedFiltered ? Ce.createElement(Rn, null) : null,
    Q = (ce = (ie = e.headerCssEllipsis) != null ? ie : e.cssEllipsis) != null ? ce : !0,
    L = {
      reserveSpaceWhenHidden: A === "center",
      menuVisible: d === e.id,
      style: A === "end" ? {
        marginInlineStart: `calc(${Ve.components.HeaderCell.resizeHandleActiveAreaWidth} / 2)`,
        marginInlineEnd: Ve.spacing[2]
      } : {
        marginInlineEnd: `calc(${Ve.components.HeaderCell.resizeHandleActiveAreaWidth} / 2)`,
        marginInlineStart: Ve.spacing[2]
      },
      domProps: {
        onMouseDown: re => {
          re.stopPropagation(), B.toggleContextMenu(re.target);
        }
      }
    },
    N = ((Le = e.components) == null ? void 0 : Le.MenuIcon) || (l == null ? void 0 : l.MenuIcon) || ao,
    j = Ce.createElement(N, h({}, L)),
    _ = {
      dragging: w,
      domRef: z,
      insideColumnMenu: !1,
      column: e,
      columnsMap: E,
      columnSortInfo: M,
      columnFilterValue: e.computedFilterValue,
      filtered: e.computedFiltered,
      someRowsSelected: S,
      allRowsSelected: b,
      selectionMode: y,
      api: t,
      columnApi: B,
      renderBag: {
        sortIcon: J,
        filterIcon: q,
        menuIcon: j,
        menuIconProps: L,
        selectionCheckBox: null,
        header: e.header && typeof e.header != "function" ? e.header : e.name
      }
    },
    H = _,
    G = () => {
      var Nn;
      let re = D(h({}, _), {
        renderBag: h({}, _.renderBag)
      });
      if (e.renderSortIcon && (re.renderBag.sortIcon = Ce.createElement(Lt, {
        render: e.renderSortIcon,
        renderParam: D(h({}, re), {
          renderBag: h({}, re.renderBag)
        })
      })), e.renderFilterIcon && (re.renderBag.filterIcon = Ce.createElement(Lt, {
        render: e.renderFilterIcon,
        renderParam: D(h({}, re), {
          renderBag: h({}, re.renderBag)
        })
      })), typeof e.renderMenuIcon == "function" && (re.renderBag.menuIcon = Ce.createElement(Lt, {
        render: dt => {
          if (typeof e.renderMenuIcon != "function") return null;
          let Ut = e.renderMenuIcon(dt);
          return Ut ? Ut.type === N || Ut.type === ao ? Ut : Ce.createElement(N, h({}, L), Ut) : null;
        },
        renderParam: D(h({}, re), {
          renderBag: h({}, re.renderBag)
        })
      })), e.renderSelectionCheckBox && y === "multi-row") {
        re.renderBag.selectionCheckBox = Ce.createElement(Lt, {
          render: kg,
          renderParam: D(h({}, re), {
            renderBag: h({}, re.renderBag)
          })
        });
        let dt = (Nn = e.renderHeaderSelectionCheckBox) != null ? Nn : e.renderSelectionCheckBox;
        dt && dt !== !0 && (re.renderBag.selectionCheckBox = Ce.createElement(Lt, {
          render: dt,
          renderParam: D(h({}, re), {
            renderBag: h({}, re.renderBag)
          })
        }));
      }
      V instanceof Function && (re.renderBag.header = Ce.createElement(Lt, {
        render: V,
        renderParam: D(h({}, re), {
          renderBag: h({}, re.renderBag)
        })
      }));
      let tt = e.renderMenuIcon === !1 ? null : re.renderBag.menuIcon,
        Hr = Q ? Ce.createElement("div", {
          className: mt
        }, re.renderBag.header) : re.renderBag.header,
        Be = Ce.createElement(Ce.Fragment, null, A === "center" ? ki : null, re.renderBag.selectionCheckBox, Hr, re.renderBag.sortIcon, re.renderBag.filterIcon, A === "center" ? ki : null, A !== "center" ? ki : null, tt);
      return e.renderHeader ? Ce.createElement(Lt, {
        render: e.renderHeader,
        renderParam: D(h({}, re), {
          renderBag: D(h({}, re.renderBag), {
            all: Be
          })
        })
      }) : Be;
    },
    W = Kg(null);
  Ng(() => {
    let re = null,
      tt = W.current;
    return v && tt && (re = Wn(tt, v)), () => {
      re == null || re();
    };
  }, [W.current, o.onResize]);
  let X = {
    height: I
  };
  e.headerStyle && (X = typeof e.headerStyle == "function" ? h(h({}, X), e.headerStyle(H) || {}) : h(h({}, X), e.headerStyle));
  let Y = typeof e.headerClassName == "function" ? e.headerClassName(H) : e.headerClassName,
    {
      onPointerDown: ee,
      proxyPosition: pe
    } = Mu({
      columnId: e.id,
      domRef: W
    }),
    ue = null;
  w && pe && (ue = Ce.createElement("div", {
    key: e.id,
    className: `${Mn}Proxy ${Gs}`,
    style: {
      position: "absolute",
      height: I,
      width: R,
      left: (ne = pe.left) != null ? ne : 0,
      top: (je = pe.top) != null ? je : 0,
      zIndex: 1e9
    }
  }, H.renderBag.header), ue = Bg(ue, s.current));
  let Ae = dr.Provider,
    ze = {
      filtered: e.computedFiltered,
      verticalAlign: O,
      align: A
    },
    Ge = Ce.useMemo(() => {
      let re = tt => {
        t.setColumnFilter(e.id, tt);
      };
      return C > 0 ? St(re, {
        wait: C
      }) : re;
    }, [C, e.id]),
    fe = e.computedFilterType,
    ye = T[fe],
    Ke = e.computedFilterable ? (Te = (Ue = e.computedFilterValue) == null ? void 0 : Ue.filter.operator) != null ? Te : ye == null ? void 0 : ye.defaultOperator : void 0,
    se = e.computedFilterable && ye ? ye.operators.find(re => re.name === Ke) : void 0,
    lt = ((Fe = se == null ? void 0 : se.components) == null ? void 0 : Fe.FilterEditor) || ((et = ye == null ? void 0 : ye.components) == null ? void 0 : et.FilterEditor) || ((ut = e.components) == null ? void 0 : ut.FilterEditor) || In,
    st = ((ct = ye == null ? void 0 : ye.components) == null ? void 0 : ct.FilterOperatorSwitch) || ((Ct = e.components) == null ? void 0 : Ct.FilterOperatorSwitch),
    Et = cc(e),
    Wt = `var(${$g}-${e.computedVisibleIndex})`;
  X.zIndex = Wt;
  let $t = {
    "data-field": `${e.field || ""}`,
    "data-column-id": e.id,
    "data-header-align": A,
    "data-name": "HeaderCell",
    "data-sort": e.computedSortedAsc ? "asc" : e.computedSortedDesc ? "desc" : "none",
    "data-sort-index": `${(At = e.computedSortIndex) != null ? At : -1}`
  };
  return Ce.createElement(Ae, {
    value: H
  }, Ce.createElement(cr, D(h({
    domRef: z,
    cellType: "header",
    column: e
  }, $t), {
    "data-z-index": Wt,
    style: X,
    width: R,
    onPointerDown: ee,
    contentClassName: te(zs(ze), ds[A]),
    contentStyle: a ? {
      height: I / 2
    } : void 0,
    className: te(Mn, Cs.none, k ? cs.pointer : "", Y, rr(e, [Mn, tn], Ls, {
      dragging: w,
      align: A,
      verticalAlign: O,
      rowSelected: !1,
      cellSelected: !1,
      zebra: !1,
      rowActive: !1,
      groupCell: !1,
      groupRow: !1,
      rowExpanded: !1
    }), Vs),
    cssEllipsis: Q,
    afterChildren: Ce.createElement(Ce.Fragment, null, a ? e.computedFilterable ? Ce.createElement(su, {
      filterEditor: lt,
      filterOperatorSwitch: st != null ? st : uu,
      operator: se,
      filterTypes: T,
      onChange: Ge,
      columnFilterType: fe,
      columnLabel: e.field || e.name || e.id,
      columnFilterValue: e.computedFilterValue,
      columnHeaderHeight: u
    }) : Ce.createElement(cu, null) : null, Et),
    renderChildren: G
  })), ue);
}
function nr() {
  return Hg(dr);
}
import * as rt from "react";
function mc(o, e, t, n) {
  let r = new Map(),
    i = new Map(),
    a = new Map();
  o.forEach((c, d) => {
    var w, v, I, R;
    let p = c.id,
      f = o[d - 1],
      m = (w = f == null ? void 0 : f.id) != null ? w : "",
      g = new Set(),
      C = (f == null ? void 0 : f.computedPinned) !== c.computedPinned ? new Set() : (v = a.get(m)) != null ? v : new Set();
    a.set(p, g);
    let T = {
      type: "column",
      id: p,
      ref: c,
      groupOffset: 0,
      computedPinned: c.computedPinned,
      computedWidth: c.computedWidth,
      depth: c.columnGroup ? ((I = t.get(c.columnGroup)) != null ? I : 0) + 1 : 0
    };
    r.set([p], T);
    let b = c.columnGroup,
      S,
      y = T;
    for (; b;) {
      let x = e.get(b);
      if (x) {
        g.add(b);
        let E = [p, ...g];
        S = C.has(b) ? i.get(b) : void 0, S || (S = {
          id: b,
          type: "group",
          ref: x,
          columnItems: [],
          children: [],
          groupOffset: 0,
          uniqueGroupId: [b],
          computedWidth: 0,
          computedPinned: T.computedPinned,
          depth: (R = t.get(b)) != null ? R : 0
        }, r.set(E, S), i.set(b, S)), S.columnItems.push(T), S.uniqueGroupId.push(T.id), y && !C.has(y.id) && S.children.push(y), y = S, b = x.columnGroup;
      } else break;
    }
  });
  let l = [],
    s = new le(),
    u = 0;
  return r.forEach(c => {
    if (!c.ref.columnGroup) {
      let d = c.type === "column" ? c.ref.computedWidth : c.columnItems.reduce((p, f) => p + f.ref.computedWidth, 0);
      c.groupOffset = u, c.computedWidth = d, c.type === "group" && fc(c.children), l.push(c), u += d;
    }
    if (c.type === "group") c.columnItems.forEach(d => {
      s.set([c.depth, d.ref.computedVisibleIndex], c);
    });else for (let d = c.depth; d <= n + 1; d++) s.set([d, c.ref.computedVisibleIndex], c);
  }), r.clear(), i.clear(), {
    tree: l,
    pathsToCells: s
  };
}
function fc(o, e = 0) {
  o.forEach(t => {
    let n = t.type === "column" ? t.ref.computedWidth : t.columnItems.reduce((r, i) => r + i.ref.computedWidth, 0);
    t.computedWidth = n, t.groupOffset = e, t.type === "group" && fc(t.children), e += n;
  });
}
import * as on from "react";
import { useCallback as Zg, useEffect as Jg, useRef as Qg } from "react";
import * as Wi from "react";
import * as Sc from "react";
import { useCallback as Cc } from "react";
import { useRef as gc, useState as jg } from "react";
import * as so from "react";
var {
    rootClassName: Ug
  } = Re,
  Yg = `${Ug}HeaderCell_ResizeHandle`;
function qg(o) {
  let e = gc(null),
    [t, n] = jg(!1),
    r = gc(t);
  r.current = t;
  let i = o.groupColumns[0];
  if (!i) return null;
  let a = i.computedPinned,
    l = i.computedFirstInCategory,
    s = i.computedLastInCategory,
    u = d => {
      d.stopPropagation();
      let p = d.pointerId,
        f = d.clientX,
        m = d.target;
      m.setPointerCapture(p);
      let g = !0,
        C = ic(o.groupColumns.map(y => y.computedVisibleIndex), {
          columns: o.columns,
          domRef: e
        }),
        T = y => {
          a === "end" && (y *= -1);
          let {
            constrained: w,
            adjustedDiffs: v,
            adjustedDiff: I
          } = o.computeResize(y);
          return r.current !== w && n(w), C.resize(v), I;
        },
        b = y => {
          if (g) {
            let w = o.groupColumns.reduce((R, x) => R + x.computedWidth - x.computedMinWidth, 0),
              v = o.brain.getAvailableSize(),
              I = {
                width: v.width + w,
                height: v.height
              };
            o.brain.setAvailableSize(I), g = !1;
          }
          T(Math.round(y.clientX - f));
        },
        S = y => {
          m.releasePointerCapture(p), m.removeEventListener("pointermove", b), m.removeEventListener("pointerup", S);
          let w = Math.round(y.clientX - f),
            v = T(w);
          o.onResize(v);
        };
      m.addEventListener("pointermove", b), m.addEventListener("pointerup", S);
    },
    c = (a === !1 || a === "start") && s ? {
      right: a === "start" ? void 0 : Ve.components.HeaderCell.resizeHandleWidth
    } : a === "end" && l ? {
      right: void 0
    } : void 0;
  return so.createElement("div", {
    ref: e,
    className: `${Yg} ${pr} ${fr({
      computedPinned: a,
      computedFirstInCategory: l,
      computedLastInCategory: s
    })}`,
    onPointerDown: u
  }, so.createElement("div", {
    style: h(h({}, c), o.style),
    className: mr({
      constrained: t
    })
  }));
}
var hc = so.memo(qg);
function yc(o, e) {
  let {
      bodyBrain: t,
      groupHeight: n,
      columnGroup: r,
      columnGroupsMaxDepth: i
    } = e,
    {
      computed: {
        computedVisibleColumns: a
      },
      getState: l,
      getComputed: s,
      actions: u
    } = ae(),
    c = o[o.length - 1],
    d = Cc(g => {
      let C = l(),
        {
          columnSizing: T,
          viewportReservedWidth: b,
          bodySize: S
        } = C,
        y = s().computedVisibleColumns,
        w = !1,
        v = y.reduce((x, E) => (E.computedFlex && (x[E.id] = D(h({}, T[E.id]), {
          flex: E.computedWidth
        }), w = !0), x), {}),
        I = w ? h(h({}, T), v) : T;
      return eu({
        availableSize: S.width,
        reservedWidth: b || 0,
        dragHandleOffset: g,
        dragHandlePositionAfter: c.computedVisibleIndex,
        columnSizing: I,
        columnGroupSize: o.length,
        items: y.map(x => ({
          id: x.id,
          resizable: x.computedResizable,
          computedFlex: x.computedFlex,
          computedWidth: x.computedWidth,
          computedMinWidth: x.computedMinWidth,
          computedMaxWidth: x.computedMaxWidth
        }))
      });
    }, [c]),
    p = Cc(g => {
      let {
        columnSizing: C,
        reservedWidth: T
      } = d(g);
      u.viewportReservedWidth = T, u.columnSizing = C;
    }, [d]);
  return o.some(g => g.computedResizable) ? Sc.createElement(hc, {
    brain: t,
    style: {
      height: n * (i - r.depth + 2)
    },
    columns: a,
    groupColumns: o,
    computeResize: d,
    onResize: p
  }) : null;
}
var Tc = `${_t}HeaderGroup`,
  bc = Z($.columnWidthAtIndex),
  Xg = Z($.columnZIndexAtIndex);
function Rc(o) {
  let {
      columnGroup: e,
      height: t,
      columns: n,
      bodyBrain: r,
      columnGroupsMaxDepth: i
    } = o,
    {
      header: a
    } = e;
  a instanceof Function && (a = a({
    columnGroup: e
  }));
  let l = yc(n, {
      bodyBrain: r,
      groupHeight: t,
      columnGroup: e,
      columnGroupsMaxDepth: i
    }),
    s = n[0],
    u = n.length > 1 ? "calc( " + n.map(d => `var(${bc}-${d.computedVisibleIndex})`).join(" + ") + " )" : `var(${bc}-${s.computedVisibleIndex})`,
    c = `calc(var(${Xg}-${s.computedVisibleIndex}) + ${i - e.depth})`;
  return Wi.createElement("div", {
    ref: o.domRef,
    "data-group-id": e.uniqueGroupId,
    className: te(Ws, Tc),
    style: {
      width: u,
      height: t
    },
    "data-z-index": c
  }, Wi.createElement("div", {
    className: te(`${Tc}__header-content`, mt)
  }, a), l);
}
var {
    rootClassName: eh
  } = Re,
  wc = `${eh}Header`;
function th(o) {
  let {
      bodyBrain: e,
      headerBrain: t,
      columns: n,
      style: r,
      className: i,
      columnHeaderHeight: a,
      columnAndGroupTreeInfo: l,
      columnGroupsMaxDepth: s
    } = o,
    {
      computed: u,
      state: {
        headerBrain: c,
        headerOptions: d,
        showColumnFilters: p
      }
    } = ae(),
    {
      computedColumnsMap: f
    } = u;
  Jg(() => {
    let b = y => {
      m.current && (m.current.style.transform = `translate3d(-${y.scrollLeft}px, 0px, 0px)`);
    };
    return t.onScroll(b);
  }, [t]);
  let m = Qg(null),
    g = Hs({
      overflow: !1
    }),
    C = {
      ref: m,
      className: te(wc, `${wc}--virtualized`, i, g),
      style: D(h({}, r), {
        height: a
      })
    },
    T = Zg(b => {
      let {
          rowIndex: S,
          colIndex: y,
          domRef: w,
          height: v,
          widthWithColspan: I,
          heightWithRowspan: R,
          hidden: x
        } = b,
        E = n[y];
      if (!E || x) return null;
      let M = l ? l.pathsToCells.get([S, y]) : null;
      if (M && M.type === "group") {
        let V = M.columnItems.map(P => P.ref),
          z = D(h({}, M.ref), {
            id: M.id,
            uniqueGroupId: M.uniqueGroupId,
            depth: M.depth,
            columns: V.map(P => P.id),
            computedWidth: M.computedWidth,
            groupOffset: M.groupOffset
          });
        return on.createElement(Rc, {
          bodyBrain: e,
          columnGroupsMaxDepth: s,
          domRef: w,
          columns: V,
          width: I,
          height: v,
          columnGroup: z
        });
      }
      return on.createElement(pc, {
        domRef: w,
        column: E,
        headerOptions: d,
        width: I,
        height: R,
        columnsMap: f
      });
    }, [d, n, a, l, s, p]);
  return on.createElement("div", h({}, C), on.createElement(Ko, {
    name: "header",
    renderCell: T,
    brain: c,
    cellHoverClassNames: []
  }));
}
var Ic = on.memo(th);
function xc(o) {
  let {
      headerBrain: e,
      bodyBrain: t,
      scrollbars: n
    } = o,
    r = ae(),
    {
      computedPinnedStartColumns: i,
      computedPinnedEndColumns: a,
      computedVisibleColumns: l,
      columnSize: s
    } = r.computed,
    {
      state: {
        columnHeaderHeight: u,
        columnGroupsDepthsMap: c,
        columnGroupsMaxDepth: d,
        computedColumnGroups: p,
        showColumnFilters: f
      }
    } = r,
    m = !p || !p.size ? 1 : d + 2,
    g = m * u + (f ? u : 0),
    C = rt.useMemo(() => {
      if (!(!p || !p.size)) return mc(l, p, c, d);
    }, [l, p, c]),
    T = rt.useCallback(({
      rowIndex: I,
      colIndex: R
    }) => {
      let x = l[R],
        E = 1,
        M = 1;
      if (!x || !C) return {
        rowspan: E,
        colspan: M
      };
      let V = C.pathsToCells.get([I, R]);
      if (!V) return {
        rowspan: E,
        colspan: M
      };
      if (V.type === "column") return {
        colspan: M,
        rowspan: m - V.depth
      };
      let z = V.columnItems.findIndex(P => P.id === x.id);
      return {
        rowspan: E,
        colspan: z === 0 ? V.columnItems.length : 1
      };
    }, [p, d, l, C, m, c]),
    b = rt.useCallback(({
      rowIndex: I,
      colIndex: R
    }) => T({
      rowIndex: I,
      colIndex: R
    }).rowspan, [T]),
    S = rt.useCallback(({
      rowIndex: I,
      colIndex: R
    }) => T({
      rowIndex: I,
      colIndex: R
    }).colspan, [T]),
    y = rt.useCallback(I => f ? I < m - 1 ? u : 2 * u : u, [m, f, u]);
  ko(e, {
    colWidth: s,
    rowHeight: y,
    rows: m,
    cols: l.length,
    height: g,
    rowspan: b,
    colspan: S
  }, {
    fixedColsEnd: a.length,
    fixedColsStart: i.length
  });
  let w = rt.createElement(Ic, {
      columns: l,
      headerBrain: e,
      bodyBrain: t,
      columnHeaderHeight: g,
      columnGroupsMaxDepth: d,
      columnAndGroupTreeInfo: C
    }),
    v = n.vertical && Xt() ? rt.createElement("div", {
      className: js,
      style: {
        zIndex: 1e3,
        width: Xt()
      }
    }) : null;
  return rt.createElement("div", {
    className: Xs,
    style: {
      height: g
    }
  }, w, v);
}
import * as wt from "react";
import { useEffect as nh } from "react";
function vc(o, e) {
  let {
    separator: t
  } = e != null ? e : {
    separator: "-"
  };
  return o.replace(/([a-z\d])([A-Z])/g, "$1" + t + "$2").replace(new RegExp("(" + t + "[A-Z])([A-Z])", "g"), "$1" + t + "$2").toLowerCase();
}
var Pc = {
    padding: Ve.spacing[2],
    textAlign: "end",
    opacity: "1",
    visibility: "visible",
    display: "block",
    fontSize: "0.85rem",
    background: "white",
    color: "black"
  },
  oh = {
    opacity: 1,
    visibility: "visible",
    display: "inline-block",
    pointerEvents: "all",
    color: "black",
    textDecoration: "underline"
  },
  Dc = (o, e) => {
    if (o) for (let [t, n] of Object.entries(e)) o.style.setProperty(vc(t), n, "important");
  },
  Mc = wt.forwardRef(function (e, t) {
    let {
        rootClassName: n
      } = Cn(),
      r = wt.useRef(null),
      i = wt.useCallback(a => {
        r.current = a, t && (typeof t == "function" ? t(a) : t.current = a);
      }, []);
    return nh(() => {
      let a = () => {
        be(() => {
          var s;
          Dc(r.current, Pc), Dc((s = r.current) == null ? void 0 : s.firstElementChild, oh);
        });
      };
      a();
      let l = setInterval(a, 1e3);
      return () => {
        clearInterval(l);
      };
    }, []), wt.createElement("div", D(h({
      ref: i
    }, e), {
      className: te(`${n}-Footer`, du, e.className),
      style: Pc
    }), "Powered by", " ", wt.createElement("a", {
      href: "https://infinite-table.com",
      rel: "noopener noreferrer",
      role: "link",
      target: "_blank"
    }, "Infinite Table"));
  });
import * as En from "react";
var Ec = {
    visible: "qy58ya1 qy58ya0 _16lm1iw2 _16lm1iw1c _16lm1iw1e _16lm1iw1j _16lm1iw1h",
    hidden: "qy58ya2 qy58ya0 _16lm1iw2 _16lm1iw1c _16lm1iw1e _16lm1iw1j _16lm1iw1h"
  },
  Ac = "qy58ya3 _16lm1iw2 _16lm1iw1c _16lm1iw1e _16lm1iw1j _16lm1iw1h",
  Fc = "qy58ya4";
var {
    rootClassName: rh
  } = Re,
  $i = `${rh}-LoadMask`;
function ih(o) {
  let {
    visible: e,
    children: t = "Loading"
  } = o;
  return En.createElement("div", {
    className: `${Ec[e ? "visible" : "hidden"]} ${$i}`
  }, En.createElement("div", {
    className: `${Ac} ${$i}-Overlay`
  }), En.createElement("div", {
    className: `${Fc} ${$i}-Text`
  }, t));
}
var gr = En.memo(ih);
function ji(o) {
  return o.map(Nt);
}
function Nt(o) {
  if (ve().structuredClone) return ve().structuredClone(o);
  if (o === null || typeof o != "object") return o;
  if (Array.isArray(o)) return ji(o);
  if (o instanceof Date) return new Date(o);
  if (o instanceof Set) return new Set(ji(Array.from(o)));
  if (o instanceof Map) return new Map(ji(Array.from(o)));
  let e = {};
  for (let t in o) o.hasOwnProperty(t) && (e[t] = Nt(o[t]));
  return e;
}
function uo(o) {
  return o;
}
var rn = Object.seal({
  data: null,
  field: null
});
var Kt = "____root____",
  Oc = "____not_loaded_yet____";
function hr() {
  return !1;
}
function Vc(o) {
  if (!o || !Object.keys(o).length) return {};
  let e = {};
  for (let t in o) if (o.hasOwnProperty(t)) {
    let n = o[t].initialValue;
    e[t] = typeof n == "function" ? n() : n;
  }
  return e;
}
function Yi(o, e, t, n, r) {
  var i;
  if (!(!t || !Object.keys(t).length)) {
    for (let a in t) if (t.hasOwnProperty(a)) {
      let l = t[a];
      if (typeof l.reducer != "function") continue;
      let s = n[a],
        u = l.getter ? (i = l.getter(o)) != null ? i : null : l.field ? o[l.field] : null;
      n[a] = l.reducer(s, u, o, e, r);
    }
  }
}
function zc(o, e) {
  let {
      reducers: t = {},
      pivot: n,
      groupBy: r,
      indexer: i,
      toPrimaryKey: a,
      cache: l,
      mappings: s
    } = o,
    u = new le();
  function c(C, T, b, S = 0, y = []) {
    let w = !b.length || S === b.length - 1,
      v = T[(s == null ? void 0 : s.values) || "values"] || {};
    for (let I in v) if (v.hasOwnProperty(I)) {
      let R = I;
      y.push(R), d.set(y, !0), C.set(y, {
        reducerResults: v[I][(s == null ? void 0 : s.totals) || "totals"] || {},
        items: []
      }), w || c(C, v[I], b, S + 1, y), y.pop();
    }
  }
  let d = n ? new le() : void 0,
    p = [],
    f = Vc(t),
    m = Nt(f);
  e.visitDepthFirst((C, T, b, S) => {
    let [y, ...w] = T,
      v = C.children,
      I = u.get(w);
    if (I && (I.cache = C.cache, I.childrenLoading = C.childrenLoading, I.childrenAvailable = C.childrenAvailable, I.error = C.error), w.length == r.length && r.length) {
      if (I) {
        I.items = v;
        for (let R = 0, x = w.length; R < x; R++) {
          let E = w.slice(0, R),
            M = u.get(E);
          M && (M.items = M.items || [], M.items = M.items.concat(v));
        }
        i.indexArray(v, {
          toPrimaryKey: a,
          cache: l
        });
      }
      return S == null ? void 0 : S();
    }
    for (let R = 0, x = v.length; R < x; R++) {
      if (!v[R]) {
        let z = {
          items: [],
          reducerResults: {},
          cache: !1,
          childrenLoading: !1,
          childrenAvailable: !1
        };
        u.set([...w, `${Oc}${R}`], z);
        continue;
      }
      let E = v[R].data,
        M = v[R].keys || [];
      if (M.length) {
        let z = M[M.length - 1];
        w.push(z);
      }
      let V = {
        items: [],
        cache: !1,
        childrenLoading: !1,
        childrenAvailable: !1,
        commonData: E,
        totalChildrenCount: v[R].totalChildrenCount,
        reducerResults: v[R].aggregations || {}
      };
      if (u.set(w, V), n) {
        let z = V.pivotDeepMap = new le(),
          P = v[R].pivot;
        c(z, P, n, 0, p);
      }
      M.length && w.pop();
    }
    S == null || S();
  });
  let g = {
    deepMap: u,
    groupParams: o,
    initialData: e,
    reducerResults: m
  };
  return n && (g.topLevelPivotColumns = d, g.pivot = n), g;
}
function _c(o, e, t, n, r, i, a, l, s, u = uo) {
  let {
    items: c,
    reducerResults: d,
    pivotDeepMap: p
  } = o.get(e);
  if (c.push(n), a && Yi(n, r, a, d, e), i) {
    for (let f = 0, m = i.length; f < m; f++) {
      let {
          field: g,
          valueGetter: C,
          toKey: T
        } = i[f],
        b = g ? n[g] : null;
      C && (rn.data = n, rn.field = g, b = C(rn));
      let S = (T || u)(b, n);
      t.push(S), p.has(t) || (l.set(t, !0), p == null || p.set(t, {
        reducerResults: Nt(s),
        items: []
      }));
      let {
        reducerResults: y,
        items: w
      } = p.get(t);
      w.push(n), a && Yi(n, r, a, y, e);
    }
    t.length = 0;
  }
}
function qi(o, e) {
  let {
      groupBy: t,
      defaultToKey: n = uo,
      pivot: r,
      reducers: i
    } = o,
    a = t.length,
    l = r ? new le() : void 0,
    s = new le(),
    u = [],
    c = [],
    d = Vc(i),
    p = Nt(d);
  if (!a) {
    let m = {
      items: [],
      cache: !1,
      childrenLoading: !1,
      childrenAvailable: !1,
      reducerResults: Nt(d)
    };
    r && (m.pivotDeepMap = new le()), s.set(u, m);
  }
  for (let m = 0, g = e.length; m < g; m++) {
    let C = e[m],
      T = {};
    for (let b = 0; b < a; b++) {
      let {
          field: S,
          groupField: y,
          valueGetter: w,
          toKey: v
        } = t[b],
        I = S ? C[S] : null;
      w && (rn.data = C, rn.field = S, I = w(rn));
      let R = (v || n)(I, C);
      if (T[S || y] = R, u.push(R), !s.has(u)) {
        let x = {
          items: [],
          cache: !1,
          commonData: h({}, T),
          childrenLoading: !1,
          childrenAvailable: !1,
          reducerResults: Nt(d)
        };
        r && (x.pivotDeepMap = new le()), s.set(u, x);
      }
      _c(s, u, c, C, m, r, i, l, d, n);
    }
    a || _c(s, u, c, C, m, r, i, l, d, n), i && Yi(C, m, i, p, u), u.length = 0;
  }
  i && s.visitDepthFirst((m, g, C, T) => {
    Ui(i, m.reducerResults, m.items), r && m.pivotDeepMap.visitDepthFirst(({
      items: b,
      reducerResults: S
    }, y, w, v) => {
      Ui(i, S, b), v == null || v();
    }), T == null || T();
  }), i && Ui(i, p, e);
  let f = {
    deepMap: s,
    groupParams: o,
    initialData: e,
    reducerResults: p
  };
  return r && (f.topLevelPivotColumns = l, f.pivot = r), f;
}
function ah(o) {
  let {
      groupParams: e,
      deepMap: t
    } = o,
    n = e.groupBy.length,
    r = [];
  return t.topDownKeys().reduce((i, a) => {
    if (a.length === n) {
      let l = t.get(a).items;
      i.push(...l);
    }
    return i;
  }, r), r;
}
function lh(o, e) {
  let {
      groupBy: t,
      groupKeys: n,
      parents: r,
      reducers: i,
      lazyLoad: a
    } = o,
    l = n.length,
    {
      items: s,
      reducerResults: u,
      pivotDeepMap: c,
      commonData: d
    } = e,
    p = d != null ? d : null,
    f;
  if (Object.keys(u).length > 0) {
    p = h({}, d), f = {};
    for (let b in i) if (i.hasOwnProperty(b)) {
      let y = i[b].field;
      y && (f[y] = u[b]), y && p[y] == null && (p[y] = u[b]);
    }
  }
  let m = !0,
    g = n[n.length - 1],
    C = g;
  if (p != null) {
    let b = t[n.length - 1];
    b && b.field && (C = p[b.field]), b && b.toKey && (C = b.toKey(C, p)), C = C != null ? C : g;
  }
  return typeof C == "string" && C.startsWith(Oc) && (m = !1, C = null), {
    data: p,
    reducerData: f,
    groupCount: s.length,
    groupData: s,
    groupKeys: n,
    rowSelected: !1,
    selectedChildredCount: 0,
    deselectedChildredCount: 0,
    id: `${n}`,
    collapsed: !1,
    dataSourceHasGrouping: !0,
    isCellSelected: hr,
    hasSelectedCells: hr,
    selfLoaded: m,
    error: o.error,
    parents: r,
    deepRowInfoArray: [],
    collapsedChildrenCount: 0,
    collapsedGroupsCount: 0,
    totalChildrenCount: o.totalChildrenCount,
    childrenAvailable: a ? o.childrenAvailable : !0,
    childrenLoading: o.childrenLoading,
    indexInParentGroups: o.indexInParentGroups,
    indexInGroup: o.indexInGroup,
    indexInAll: o.indexInAll,
    directChildrenCount: o.directChildrenCount,
    directChildrenLoadedCount: a ? o.directChildrenLoadedCount : o.directChildrenCount,
    value: C,
    rootGroupBy: t,
    groupBy: l === t.length ? t : t.slice(0, l),
    isGroupRow: !0,
    pivotValuesMap: c,
    groupNesting: l,
    reducerResults: u
  };
}
function Ui(o, e, t) {
  if (o) {
    for (let n in o) if (o.hasOwnProperty(n)) {
      let r = o[n];
      r.done && (e[n] = r.done(e[n], t));
    }
  }
  return e;
}
function Gc(o) {
  let {
      lazyLoad: e,
      groupResult: t,
      withRowInfo: n,
      toPrimaryKey: r,
      groupRowsState: i,
      isRowSelected: a,
      rowSelectionState: l,
      generateGroupRows: s,
      reducers: u = {}
    } = o,
    {
      groupParams: c,
      deepMap: d,
      pivot: p
    } = t,
    {
      groupBy: f
    } = c,
    m = [],
    g = [],
    C = [],
    T = [];
  return d.visitDepthFirst((b, S, y, w) => {
    var P, F;
    let v = b.items,
      I = S.length,
      R = (P = i == null ? void 0 : i.isGroupRowCollapsed(S)) != null ? P : !1;
    T.push(y);
    let x = lh({
      lazyLoad: e,
      groupBy: f,
      parents: Array.from(C),
      reducers: u,
      indexInGroup: y,
      indexInParentGroups: Array.from(T),
      indexInAll: m.length,
      groupKeys: S,
      error: b.error,
      totalChildrenCount: b.totalChildrenCount,
      childrenLoading: (b.childrenLoading || !R && !b.childrenAvailable) && e,
      childrenAvailable: b.childrenAvailable,
      directChildrenCount: S.length === f.length ? b.items.length : d.getDirectChildrenSizeFor(S),
      directChildrenLoadedCount: 0
    }, b);
    if (a && (x.rowSelected = a(x), l)) {
      let A = l.getSelectionCountFor(x.groupKeys);
      x.selectedChildredCount = A.selectedCount, x.deselectedChildredCount = A.deselectedCount;
    }
    let E = C[C.length - 1],
      M = (F = E == null ? void 0 : E.collapsed) != null ? F : !1;
    M && (R = !0), x.collapsed = R;
    let V = s || R;
    M && (V = !1), V && (m.push(x), g.push(m.length - 1)), x.collapsedChildrenCount = 0, C.forEach(A => {
      A.deepRowInfoArray.push(x), A.collapsedGroupsCount += R ? 1 : 0;
    }), E && x.selfLoaded && e && (E.directChildrenLoadedCount += 1), n && n(x), C.push(x);
    let z = !0;
    if (z) {
      if (w) w();else if (!p) {
        let A = m.length;
        R || (m.length += v.length);
        for (let O = 0, B = v.length; O < B; O++) {
          let k = v[O],
            J = A + O,
            q = {
              id: k ? r(k, J) : `${S}-${O}`,
              data: k,
              isCellSelected: hr,
              hasSelectedCells: hr,
              dataSourceHasGrouping: !0,
              isGroupRow: !1,
              selfLoaded: !!k,
              rowSelected: !1,
              rootGroupBy: f,
              collapsed: R,
              groupKeys: S,
              parents: Array.from(C),
              indexInParentGroups: [...T, O],
              indexInGroup: O,
              indexInAll: J,
              groupBy: f,
              groupNesting: I + 1,
              groupCount: x.groupCount
            };
          a && (q.rowSelected = a(q)), n && n(q), C.forEach((Q, L) => {
            L === C.length - 1 && k && (Q.directChildrenLoadedCount += 1), R && (Q.collapsedChildrenCount += 1), Q.deepRowInfoArray.push(q);
          }), R || (m[A + O] = q);
        }
      }
    }
    C.pop(), T.pop();
  }), {
    data: m,
    groupRowsIndexes: g
  };
}
import * as gt from "react";
import { useEffect as sd, useLayoutEffect as ld } from "react";
var sh = function (o, e) {
    return o - e;
  },
  uh = function (o, e) {
    return o = `${o}`, e = `${e}`, o.localeCompare(e);
  },
  ch = function (o, e) {
    return o - e;
  },
  dh = {
    number: sh,
    string: uh,
    date: ch
  },
  co = dh;
var It = (o, e, t) => {
  let n = o.map(r => Array.isArray(r.field) ? r.field.map((i, a) => {
    var u;
    let l = Array.isArray(r.type) ? (u = r.type[a]) != null ? u : r.type[0] : r.type;
    return typeof i == "function" ? D(h({}, r), {
      valueGetter: i,
      field: void 0,
      type: l
    }) : D(h({}, r), {
      type: l,
      field: i
    });
  }) : r).flat();
  return e.sort(fh(n, t)), e;
};
It.knownTypes = co;
var ph = o => {
    if (!o) return;
    let e = o.field,
      t = o.valueGetter,
      n = o.dir < 0 ? -1 : o.dir > 0 ? 1 : 0;
    if (!n) return;
    let r = o.fn;
    if (!r && o.type) {
      let i = Array.isArray(o.type) ? o.type[0] : o.type;
      r = It.knownTypes[i], r || console.warn(`Unknown sort type "${o.type}" - please pass one of ${Object.keys(It.knownTypes).join(", ")}`);
    }
    return r || (r = co.string), (i, a) => {
      let l = t ? t(i) : e ? i[e] : i,
        s = t ? t(a) : e ? a[e] : a,
        u = r(l, s);
      return u === 0 ? u : n * u;
    };
  },
  mh = o => o.map(ph).filter(e => e instanceof Function),
  fh = (o, e) => {
    let t = mh(o);
    return (n, r) => {
      e && (n = e(n), r = e(r));
      let i = 0,
        a = 0,
        l,
        s = t.length;
      for (; a < s; a++) if (l = t[a], !!l && (i = l(n, r), i !== 0)) return i;
      return i;
    };
  };
var xt = class {
  constructor() {
    this.affectedFields = new Set();
    this.allFieldsAffected = !1;
    this.primaryKeyToData = new Map();
    this.getAffectedFields = () => this.allFieldsAffected ? !0 : this.affectedFields;
    this.delete = (e, t, n) => {
      this.allFieldsAffected = !0;
      let r = e,
        i = this.primaryKeyToData.get(r) || [];
      i.push({
        type: "delete",
        primaryKey: e,
        originalData: t,
        metadata: n
      }), this.primaryKeyToData.set(r, i);
    };
    this.insert = (e, t, n, r) => {
      let i = e,
        a = this.primaryKeyToData.get(i) || [];
      this.allFieldsAffected = !0, a.push({
        type: "insert",
        primaryKey: e,
        data: t,
        position: n,
        originalData: null,
        metadata: r
      }), this.primaryKeyToData.set(i, a);
    };
    this.update = (e, t, n, r) => {
      this.allFieldsAffected || Object.keys(t).forEach(s => this.affectedFields.add(s));
      let i = e,
        a = this.primaryKeyToData.get(i) || [];
      a.push({
        type: "update",
        primaryKey: e,
        data: t,
        originalData: n,
        metadata: r
      }), this.primaryKeyToData.set(e, a);
    };
    this.clear = () => {
      this.allFieldsAffected = !1, this.affectedFields.clear(), this.primaryKeyToData.clear();
    };
    this.isEmpty = () => this.primaryKeyToData.size === 0;
    this.removeInfo = e => {
      this.primaryKeyToData.delete(e);
    };
    this.getMutationsForPrimaryKey = e => this.primaryKeyToData.get(e);
    this.getMutations = () => new Map(this.primaryKeyToData);
  }
  static clone(e, {
    light: t = !1
  } = {}) {
    let n = new xt();
    return n.allFieldsAffected = e.allFieldsAffected, n.affectedFields = new Set(e.affectedFields), n.primaryKeyToData = t ? e.primaryKeyToData : new Map(e.primaryKeyToData), n;
  }
};
function An(o, e) {
  return e().dataArray[o];
}
function Cr(o) {
  return o().dataArray;
}
function Lc(o) {
  return new Xi(o);
}
var Xi = class {
  constructor(e) {
    this.pendingOperations = [];
    this.pendingPromise = null;
    this.resolvePendingPromise = null;
    this.toPrimaryKey = e => this.getState().toPrimaryKey(e);
    this.getRowInfoArray = () => Cr(this.getState);
    this.getRowInfoByIndex = e => {
      var t;
      return (t = An(e, this.getState)) != null ? t : null;
    };
    this.getRowInfoByPrimaryKey = e => {
      let t = this.getIndexByPrimaryKey(e);
      return this.getRowInfoByIndex(t);
    };
    this.getIndexByPrimaryKey = e => {
      var n;
      return (n = this.getState().idToIndexMap.get(e)) != null ? n : -1;
    };
    this.getPrimaryKeyByIndex = e => {
      let t = this.getRowInfoByIndex(e);
      return t ? t.id : void 0;
    };
    this.getOriginalDataArray = () => this.getState().originalDataArray;
    this.getDataByPrimaryKey = e => {
      var n;
      let {
        indexer: t
      } = this.getState();
      return (n = t.getDataForPrimaryKey(e)) != null ? n : null;
    };
    this.updateData = (e, t) => this.updateDataArray([e], t);
    this.updateDataArray = (e, t) => {
      let n = this.batchOperation({
        type: "update",
        array: e,
        primaryKeys: e.map(r => this.toPrimaryKey(r)),
        metadata: t == null ? void 0 : t.metadata
      });
      return t != null && t.flush && this.commit(), n;
    };
    this.removeDataByPrimaryKey = (e, t) => {
      let n = this.batchOperation({
        type: "delete",
        primaryKeys: [e],
        metadata: t == null ? void 0 : t.metadata
      });
      return t != null && t.flush && this.commit(), n;
    };
    this.removeData = (e, t) => {
      let n = this.batchOperation({
        type: "delete",
        primaryKeys: [this.toPrimaryKey(e)],
        metadata: t == null ? void 0 : t.metadata
      });
      return t != null && t.flush && this.commit(), n;
    };
    this.removeDataArrayByPrimaryKeys = (e, t) => {
      let n = this.batchOperation({
        type: "delete",
        primaryKeys: e,
        metadata: t == null ? void 0 : t.metadata
      });
      return t != null && t.flush && this.commit(), n;
    };
    this.removeDataArray = (e, t) => {
      let n = this.batchOperation({
        type: "delete",
        primaryKeys: e.map(this.toPrimaryKey),
        metadata: t == null ? void 0 : t.metadata
      });
      return t != null && t.flush && this.commit(), n;
    };
    this.addData = (e, t) => this.addDataArray([e], t);
    this.addDataArray = (e, t) => this.insertDataArray(e, D(h({}, t), {
      position: "end"
    }));
    this.insertData = (e, t) => this.insertDataArray([e], t);
    this.insertDataArray = (e, t) => {
      let n = "before",
        r;
      if (t.position === "before" || t.position === "after") n = t.position, r = t.primaryKey;else {
        let a = this.getOriginalDataArray();
        t.position === "start" ? (n = "before", r = this.toPrimaryKey(a[0])) : (n = "after", r = this.toPrimaryKey(a[a.length - 1]));
      }
      let i = this.batchOperation({
        type: "insert",
        array: e,
        position: n,
        metadata: t == null ? void 0 : t.metadata,
        primaryKey: r
      });
      return t != null && t.flush && this.commit(), i;
    };
    this.setSortInfo = e => {
      let t = this.getState().multiSort;
      if (Array.isArray(e)) {
        this.actions.sortInfo = e.length ? t ? e : e[0] : null;
        return;
      }
      this.actions.sortInfo = e;
    };
    this.getState = e.getState, this.actions = e.actions;
  }
  batchOperation(e) {
    return this.pendingPromise || (this.pendingPromise = new Promise(t => {
      this.resolvePendingPromise = t;
    }), be(() => {
      this.commit();
    })), this.pendingOperations.push(e), this.pendingPromise;
  }
  batchOperations(e) {
    this.pendingOperations.push(...e);
  }
  commitOperations(e) {
    if (!e.length) return;
    let t = this.getState().cache,
      n = t ? xt.clone(t, {
        light: !0
      }) : new xt();
    e.forEach(r => {
      switch (r.type) {
        case "update":
          r.array.forEach((l, s) => {
            let u = r.primaryKeys[s],
              c = this.getRowInfoByPrimaryKey(u);
            c && !c.isGroupRow && n.update(r.primaryKeys[s], l, c.data, r.metadata);
          });
          break;
        case "delete":
          r.primaryKeys.forEach(l => {
            let s = this.getRowInfoByPrimaryKey(l);
            s && !s.isGroupRow && n.delete(l, s.data, r.metadata);
          });
          break;
        case "insert":
          let i = r.primaryKey,
            a = r.position;
          r.array.forEach(l => {
            n.insert(i, l, a, r.metadata), i = this.toPrimaryKey(l), a = "after";
          });
          break;
      }
    }), this.actions.cache = n;
  }
  commit() {
    if (this.commitOperations(this.pendingOperations), this.pendingOperations.length = 0, this.pendingPromise && this.resolvePendingPromise) {
      let e = this.resolvePendingPromise;
      requestAnimationFrame(() => {
        e(!0);
      }), this.pendingPromise = null, this.resolvePendingPromise = null;
    }
  }
};
function Hc(o) {
  let e = o.cache;
  if (!e) return {
    sortInfo: !1,
    groupBy: !1,
    filterValue: !1,
    aggregationReducers: !1
  };
  let t = !1,
    n = !1,
    r = !1,
    i = !1,
    a = e.getAffectedFields(),
    {
      sortInfo: l,
      groupBy: s,
      filterValue: u,
      aggregationReducers: c
    } = o;
  if (l && l.length) if (a === !0) t = !0;else for (let d of l) {
    let p = d.field;
    if (p && (p = Array.isArray(p) ? p : [p], t = p.reduce((f, m) => f || typeof m != "function" ? a.has(m) : !1, !1), t)) break;
  }
  if (s && s.length) {
    if (a === !0) n = !0;else for (let d of s) if (d.field && a.has(d.field)) {
      n = !0;
      break;
    }
  }
  if (u && u.length) if (a === !0) r = !0;else for (let d of u) {
    if (d.id) {
      r = !0;
      break;
    }
    if (d.field && a.has(d.field)) {
      r = !0;
      break;
    }
  }
  if (c && Object.keys(c).length) {
    if (a === !0) i = !0;else for (let d in c) if (c.hasOwnProperty(d)) {
      let p = c[d];
      if (p.field) {
        if (a.has(p.field)) {
          i = !0;
          break;
        }
      } else {
        i = !0;
        break;
      }
    }
  }
  return {
    sortInfo: t,
    groupBy: n,
    filterValue: r,
    aggregationReducers: i
  };
}
var Sr = class {
  constructor(e) {
    let t = e instanceof Object.getPrototypeOf(this).constructor ? e.getState() : e,
      n = this.getPositiveFromState(t),
      r = this.getNegativeFromState(t);
    this.update({
      negativeItems: r,
      positiveItems: n
    });
  }
  getInitialState() {
    return this.initialState;
  }
  destroy() {
    var e, t;
    (e = this.positiveMap) == null || e.clear(), (t = this.negativeMap) == null || t.clear(), delete this.positiveMap, delete this.negativeMap;
  }
  update(e) {
    let {
      positiveItems: t,
      negativeItems: n
    } = e;
    if (this.allNegative = n === !0, this.allPositive = t === !0, this.allNegative && this.allPositive) throw "Cannot have both negativeItems and positiveItems be true!";
    n !== !0 && (this.negativeMap ? (this.negativeMap.clear(), this.negativeMap.fill(n.map(r => [r, !0]))) : this.negativeMap = new le(n.map(r => [r, !0])), this.positiveMap && this.positiveMap.clear()), t !== !0 && (this.positiveMap ? (this.positiveMap.clear(), this.positiveMap.fill(t.map(r => [r, !0]))) : this.positiveMap = new le(t.map(r => [r, !0])), this.negativeMap && this.negativeMap.clear());
  }
  areAllNegative() {
    return this.allNegative && (this.positiveMap ? this.positiveMap.size === 0 : !0);
  }
  areAllPositive() {
    return this.allPositive && (this.negativeMap ? this.negativeMap.size === 0 : !0);
  }
  makeAllNegative() {
    this.update({
      negativeItems: !0,
      positiveItems: []
    });
  }
  makeAllPositive() {
    this.update({
      positiveItems: !0,
      negativeItems: []
    });
  }
  isItemPositive(e) {
    var t, n;
    if (this.allPositive === !0) {
      if (this.allNegative === !0) throw 'Cannot have both positiveItems and negativeItems be "true"';
      return !((t = this.negativeMap) != null && t.has(e));
    }
    return (n = this.positiveMap) == null ? void 0 : n.has(e);
  }
  isItemNegative(e) {
    return !this.isItemPositive(e);
  }
  setItemValue(e, t) {
    var n;
    if (t !== this.isItemPositive(e)) {
      if (t) {
        if (this.allNegative === !0) {
          if (!this.positiveMap) throw `No positiveMap found when trying to set item ${e.join(",")} be positive`;
          this.positiveMap.set(e, !0);
        } else if (this.allPositive === !0) {
          if (!this.negativeMap) throw `No negativeMap found when trying to set item ${e.join(",")} be positive`;
          this.negativeMap.delete(e);
        }
      } else if (this.allPositive === !0) {
        if (!this.negativeMap) throw `No negativeMap found when trying to set item ${e.join(",")} be negative`;
        this.negativeMap.set(e, !0);
      } else if (this.allNegative === !0) {
        if (!this.positiveMap) throw `No positiveMap found when trying to set item ${e.join(",")} be negative`;
        (n = this.positiveMap) == null || n.delete(e);
      }
    }
  }
  makeItemNegative(e) {
    this.setItemValue(e, !1);
  }
  makeItemPositive(e) {
    this.setItemValue(e, !0);
  }
  toggleItem(e) {
    this.setItemValue(e, !this.isItemPositive(e));
  }
};
var Xe = class extends Sr {
  constructor(e) {
    super(e);
  }
  getState() {
    var e, t, n, r;
    return {
      expandedRows: this.allPositive ? !0 : (t = (e = this.positiveMap) == null ? void 0 : e.topDownKeys()) != null ? t : [],
      collapsedRows: this.allNegative ? !0 : (r = (n = this.negativeMap) == null ? void 0 : n.topDownKeys()) != null ? r : []
    };
  }
  getPositiveFromState(e) {
    return e.expandedRows;
  }
  getNegativeFromState(e) {
    return e.collapsedRows;
  }
  areAllCollapsed() {
    return this.areAllNegative();
  }
  areAllExpanded() {
    return this.areAllPositive();
  }
  collapseAll() {
    this.makeAllNegative();
  }
  expandAll() {
    this.makeAllPositive();
  }
  isGroupRowExpanded(e) {
    return this.isItemPositive(e);
  }
  isGroupRowCollapsed(e) {
    return !this.isGroupRowExpanded(e);
  }
  setGroupRowExpanded(e, t) {
    return this.setItemValue(e, t);
  }
  collapseGroupRow(e) {
    this.setGroupRowExpanded(e, !1);
  }
  expandGroupRow(e) {
    this.setGroupRowExpanded(e, !0);
  }
  toggleGroupRow(e) {
    this.toggleItem(e);
  }
};
import { useEffect as Vn, useMemo as Uc, useRef as ra, useState as yh } from "react";
import { useEffect as Nc, useRef as Zi } from "react";
function Fn(o, e) {
  let t = Zi({}),
    n = Zi({}),
    r = n.current,
    i = Zi({}),
    a = i.current,
    l = [];
  for (let s in e) e.hasOwnProperty(s) && (e[s] !== t.current[s] && (a[s] = e[s], r[s] = t.current[s]), l.push(e[s]));
  t.current = e, Nc(() => {
    let s = i.current,
      u;
    return Object.keys(s).length !== 0 && (u = o(s, r)), i.current = {}, n.current = {}, u;
  }, l);
}
function FE(o, e) {
  let t = [];
  for (let n in e) e.hasOwnProperty(n) && t.push(e[n]);
  Nc(o, t);
}
function Kc(...o) {
  return (...t) => {
    o.forEach(n => {
      typeof n == "function" && n(...t);
    });
  };
}
function Ji(o) {
  let {
    pivotByAtIndex: e,
    pivotAggregator: t
  } = o;
  return e != null && e.column && (typeof e.column == "function" ? Object.assign(o, e.column({
    column: o
  })) : Object.assign(o, e.column)), t != null && t.pivotColumn && (typeof t.pivotColumn == "function" ? Object.assign(o, t.pivotColumn({
    column: o
  })) : Object.assign(o, t.pivotColumn)), o;
}
function Qi(o) {
  let {
    pivotByAtIndex: e
  } = o;
  return e != null && e.columnGroup && (typeof e.columnGroup == "function" ? Object.assign(o, e.columnGroup({
    columnGroup: o
  })) : Object.assign(o, e.columnGroup)), o;
}
function Bc({
  deepMap: o,
  pivotBy: e,
  pivotTotalColumnPosition: t,
  pivotGrandTotalColumnPosition: n,
  reducers: r = {},
  showSeparatePivotColumnForSingleAggregation: i = !1
}) {
  let a = e.length,
    l = Object.keys(r).map(f => D(h({}, r[f]), {
      id: f
    }));
  l.length || (i = !0, n = !1, t = !1, l.push({
    id: "__empty-aggregation-reducer__",
    name: "-",
    initialValue: null,
    reducer: () => null
  }));
  let s = new Map([]),
    u = new Map(),
    c = Ft(function () {
      l.forEach((f, m) => {
        s.set(`total:${f.id}`, Ji({
          header: `${f.name || f.id} total`,
          pivotBy: e,
          pivotColumn: !0,
          pivotTotalColumn: !0,
          pivotAggregator: f,
          pivotAggregatorIndex: m,
          pivotGroupKeys: [],
          pivotGroupKey: "",
          pivotIndex: -1,
          valueFormatter: ({
            rowInfo: g
          }) => {
            var C;
            return g.isGroupRow ? (C = g.reducerResults) == null ? void 0 : C[f.id] : null;
          }
        }));
      });
    });
  (!a && t === "start" || n === "start") && c();
  let d = !i && l.length === 1;
  return o.visitDepthFirst((f, m, g, C) => {
    if (m = [...m], t === "end" && (C == null || C()), m.length === a) {
      let T = e[m.length - 1],
        S = m.slice(0, -1).join("/");
      if (!d) {
        let y = S;
        S = m.join("/");
        let w = m[m.length - 1];
        u.set(S, Qi({
          header: `${w}`,
          columnGroup: y,
          pivotBy: e,
          pivotGroupKeys: m,
          pivotByAtIndex: T,
          pivotIndex: m.length - 1,
          pivotGroupKey: w
        }));
      }
      l.forEach((y, w) => {
        let v = d ? `${m[m.length - 1]}` : y.name || y.id,
          I = Ji({
            pivotBy: e,
            pivotColumn: !0,
            pivotTotalColumn: !1,
            pivotAggregator: y,
            pivotAggregatorIndex: w,
            pivotGroupKeys: m,
            pivotGroupKey: m[m.length - 1],
            pivotIndex: m.length - 1,
            pivotByAtIndex: T,
            defaultSortable: !1,
            columnGroup: S,
            header: v,
            valueFormatter: ({
              rowInfo: x
            }) => {
              var E, M;
              return x.isGroupRow ? (M = (E = x.pivotValuesMap) == null ? void 0 : E.get(m)) == null ? void 0 : M.reducerResults[y.id] : null;
            }
          }),
          R = `${y.id}:${m.join("/")}`;
        s.set(R, I);
      });
    } else {
      let T = m.join("/"),
        b = m.slice(0, -1);
      if (u.set(T, Qi({
        columnGroup: b.length ? b.join("/") : void 0,
        header: `${m[m.length - 1]}`,
        pivotBy: e,
        pivotGroupKeys: m,
        pivotIndex: m.length - 1,
        pivotByAtIndex: e[m.length - 1],
        pivotGroupKey: m[m.length - 1]
      })), t !== !1) {
        let S = e[m.length - 1],
          y = b.length ? b.join("/") : void 0;
        if (!d) {
          let w = y;
          y = `total:${m.join("/")}`, u.set(y, Qi({
            header: `${m[m.length - 1]} total`,
            columnGroup: w,
            pivotBy: e,
            pivotTotalColumnGroup: !0,
            pivotGroupKeys: m,
            pivotIndex: m.length - 1,
            pivotByAtIndex: S,
            pivotGroupKey: m[m.length - 1]
          }));
        }
        l.forEach((w, v) => {
          let I = d ? `${m[m.length - 1]} total ` : `${w.name || w.id} total`,
            R = Ji({
              columnGroup: y,
              header: I,
              pivotAggregator: w,
              pivotAggregatorIndex: v,
              pivotColumn: !0,
              pivotTotalColumn: !0,
              pivotGroupKeys: m,
              pivotGroupKey: m[m.length - 1],
              pivotByAtIndex: S,
              pivotIndex: m.length - 1,
              pivotBy: e,
              defaultSortable: !1,
              valueFormatter: ({
                rowInfo: x
              }) => {
                var E, M;
                return x.isGroupRow ? (M = (E = x.pivotValuesMap) == null ? void 0 : E.get(m)) == null ? void 0 : M.reducerResults[w.id] : null;
              }
            });
          s.set(`total:${w.id}:${m.join("/")}`, R);
        });
      }
    }
    (t === "start" || t === !1) && (C == null || C());
  }), (!a && t === "end" || n === "end") && c(), {
    columns: s,
    columnGroups: u
  };
}
function _n(o, e) {
  return e.reduce((t, n) => {
    let {
        field: r,
        valueGetter: i,
        toKey: a
      } = n,
      l = r ? o[r] : i == null ? void 0 : i({
        data: o,
        field: r
      }),
      s = (a || uo)(l, o);
    return t.push(s), t;
  }, []);
}
function yr(o, e) {
  return new me(o, $e(e));
}
function $e(o) {
  return () => {
    let e = typeof o == "function" ? o() : o;
    return {
      lazyLoad: !!e.lazyLoad,
      toPrimaryKey: e.toPrimaryKey,
      onlyUsePrimaryKeys: !e.useGroupKeysForMultiRowSelection,
      groupBy: e.groupBy,
      groupDeepMap: e.groupDeepMap,
      indexer: e.indexer,
      totalCount: e.unfilteredCount
    };
  };
}
function kc(o) {
  let {
    getDataSourceState: e,
    dataSourceActions: t
  } = o;
  return {
    get allRowsSelected() {
      return e().allRowsSelected;
    },
    selectAll() {
      let {
        rowSelection: r,
        selectionMode: i
      } = e();
      if (i !== "multi-row") throw "Selection mode is not multi-row";
      if (!(r instanceof me)) throw "Invalid row selection";
      let a = new me(r, $e(e));
      a.selectAll(), t.rowSelection = a;
    },
    deselectAll() {
      let {
        rowSelection: r,
        selectionMode: i
      } = e();
      if (i !== "multi-row") throw "Selection mode is not multi-row";
      if (!(r instanceof me)) throw "Invalid row selection";
      let a = new me(r, $e(e));
      a.deselectAll(), t.rowSelection = a;
    },
    isRowSelected(r, i) {
      let {
        rowSelection: a,
        selectionMode: l,
        indexer: s,
        groupBy: u
      } = e();
      if (l === "single-row") return a === r;
      if (l !== "multi-row") throw "Selection mode is not multi-row or single-row";
      if (!(a instanceof me)) throw "Invalid row selection";
      if (!i) {
        let c = s.getDataForPrimaryKey(r);
        i = c ? _n(c, u) : [];
      }
      return a.isRowSelected(r, i);
    },
    isRowDeselected(r, i) {
      return !this.isRowSelected(r, i);
    },
    selectRow(r, i) {
      let {
        rowSelection: a,
        selectionMode: l,
        indexer: s,
        groupBy: u
      } = e();
      if (l === "single-row") {
        t.rowSelection = r;
        return;
      }
      if (l !== "multi-row") throw "Selection mode is not multi-row or single-row";
      if (!(a instanceof me)) throw "Invalid row selection";
      if (!i) {
        let d = s.getDataForPrimaryKey(r);
        i = d ? _n(d, u) : [];
      }
      let c = new me(a, $e(e));
      c.selectRow(r, i), t.rowSelection = c;
    },
    deselectRow(r, i) {
      let {
        selectionMode: a,
        rowSelection: l,
        indexer: s,
        groupBy: u
      } = e();
      if (a === "single-row") {
        t.rowSelection = null;
        return;
      }
      if (a !== "multi-row") throw "Selection mode is not multi-row or single-row";
      if (!(l instanceof me)) throw "Invalid row selection";
      if (a === "multi-row") {
        if (!i) {
          let d = s.getDataForPrimaryKey(r);
          i = d ? _n(d, u) : [];
        }
        let c = new me(l, $e(e));
        c.deselectRow(r, i), t.rowSelection = c;
      }
    },
    toggleRowSelection(r, i) {
      this.isRowSelected(r, i) ? this.deselectRow(r, i) : this.selectRow(r, i);
    },
    selectGroupRow(r) {
      let {
        selectionMode: i,
        rowSelection: a,
        groupBy: l
      } = e();
      if (i !== "multi-row") throw 'Selection mode should be "multi-row"';
      if (!l) throw "No grouping specified";
      if (!(a instanceof me)) throw "Invalid row selection";
      let s = new me(a, $e(e));
      s.selectGroupRow(r), t.rowSelection = s;
    },
    deselectGroupRow(r) {
      let {
        selectionMode: i,
        rowSelection: a,
        groupBy: l
      } = e();
      if (i !== "multi-row") throw 'Selection mode should be "multi-row"';
      if (!l) throw "No grouping specified";
      if (!(a instanceof me)) throw "Invalid row selection";
      let s = new me(a, $e(e));
      s.deselectGroupRow(r), t.rowSelection = s;
    },
    toggleGroupRowSelection(r) {
      let {
        selectionMode: i,
        rowSelection: a,
        groupBy: l
      } = e();
      if (i !== "multi-row") throw 'Selection mode should be "multi-row"';
      if (!l) throw "No grouping specified";
      if (!(a instanceof me)) throw "Invalid row selection";
      let s = new me(a, $e(e));
      s.toggleGroupRowSelection(r), t.rowSelection = s;
    },
    getGroupRowSelectionState(r, i) {
      let a = e(),
        {
          selectionMode: l,
          groupBy: s
        } = a,
        u = i ? new me(i, $e(a)) : a.rowSelection;
      if (l !== "multi-row") throw 'Selection mode should be "multi-row"';
      if (!s) throw "No grouping specified";
      if (!(u instanceof me)) throw "Invalid row selection";
      return u.getGroupRowSelectionState(r);
    },
    getSelectedPrimaryKeys: r => {
      let i = e(),
        a = r ? new me(r, $e(i)) : i.rowSelection,
        l = [];
      return i.lazyLoad && console.error("getSelectedPrimaryKeys  should not be called for lazy-loaded datasources as it wont return reliable results"), i.originalDataArray.forEach(s => {
        let u = i.toPrimaryKey(s);
        a.isRowSelected(u) && l.push(u);
      }), l;
    }
  };
}
var gh = nt("CellSelectionState"),
  hh = "*",
  Ee = class {
    constructor(e) {
      this.wildcard = hh;
      this.cache = new le();
      this.selectedRowsToColumns = new Map();
      this.selectedColumnsToRows = new Map();
      this.deselectedRowsToColumns = new Map();
      this.deselectedColumnsToRows = new Map();
      this.defaultSelection = !1;
      this.deselectAll = () => {
        this.update({
          defaultSelection: !1,
          selectedCells: []
        });
      };
      this.selectAll = () => {
        this.update({
          defaultSelection: !0,
          deselectedCells: []
        });
      };
      let t = e && e instanceof Object.getPrototypeOf(this).constructor ? e.getState() : e;
      t && this.update(t);
    }
    selectCell(e, t) {
      this.setCellSelected(e, t, !0);
    }
    deselectCell(e, t) {
      this.setCellSelected(e, t, !1);
    }
    selectColumn(e) {
      this.setCellSelected(this.wildcard, e, !0);
    }
    deselectColumn(e) {
      this.setCellSelected(this.wildcard, e, !1);
    }
    setCellSelected(e, t, n) {
      let r = e === this.wildcard,
        i = t === this.wildcard;
      if (this.isCellSelected_Internal(e, t) === n) return;
      let l = [];
      if (r) {
        let u = this.deselectedColumnsToRows.get(t),
          c = this.selectedColumnsToRows.get(t);
        u == null || u.forEach(d => {
          d !== this.wildcard && this.setCellInDeselection(d, t, !1);
        }), c == null || c.forEach(d => {
          d !== this.wildcard && this.setCellInSelection(d, t, !1);
        }), [...this.cache.keys()].forEach(d => {
          let [p, f] = d;
          f === t && l.push([p, t]);
        });
      }
      if (i) {
        let u = this.deselectedRowsToColumns.get(t),
          c = this.selectedRowsToColumns.get(t);
        u == null || u.forEach(d => {
          d !== this.wildcard && this.setCellInDeselection(e, d, !1);
        }), c == null || c.forEach(d => {
          d !== this.wildcard && this.setCellInSelection(e, d, !1);
        }), [...this.cache.keys()].forEach(d => {
          let [p] = d;
          p === e && l.push([e, t]);
        });
      }
      let s = [e, t];
      if (l.forEach(u => {
        this.cache.delete(u);
      }), this.cache.delete(s), n) {
        if (this.defaultSelection) {
          let u = this.deselectedRowsToColumns.get(e);
          u && u.has(t) && this.setCellInDeselection(e, t, !1);
          let c = this.deselectedRowsToColumns.get(this.wildcard),
            d = this.deselectedColumnsToRows.get(this.wildcard);
          (c != null && c.has(t) || d != null && d.has(e)) && this.setCellInSelection(e, t, !0);
        } else {
          let u = this.deselectedRowsToColumns.get(e);
          u && u.has(t) && this.setCellInDeselection(e, t, !1), this.setCellInSelection(e, t, !0);
        }
      } else if (this.defaultSelection) {
        let u = this.selectedRowsToColumns.get(e);
        u != null && u.has(t) && this.setCellInSelection(e, t, !1), this.setCellInDeselection(e, t, !0);
      } else {
        let u = this.selectedRowsToColumns.get(e);
        u != null && u.has(t) && this.setCellInSelection(e, t, !1);
        let c = this.selectedRowsToColumns.get(this.wildcard),
          d = this.selectedColumnsToRows.get(this.wildcard);
        if (c != null && c.has(t) || d != null && d.has(e)) {
          this.setCellInDeselection(e, t, !0);
          return;
        }
      }
    }
    setCellInDeselection(e, t, n) {
      if (n) {
        let r = this.deselectedRowsToColumns.get(e) || new Set();
        r.add(t), this.deselectedRowsToColumns.set(e, r);
      } else {
        let r = this.deselectedRowsToColumns.get(e);
        r && (r.delete(t), r.size === 0 && this.deselectedRowsToColumns.delete(e));
      }
      if (n) {
        let r = this.deselectedColumnsToRows.get(t) || new Set();
        r.add(e), this.deselectedColumnsToRows.set(t, r);
      } else {
        let r = this.deselectedColumnsToRows.get(t);
        r && (r.delete(e), r.size === 0 && this.deselectedColumnsToRows.delete(t));
      }
    }
    setCellInSelection(e, t, n) {
      if (e === this.wildcard && t === this.wildcard) throw new Error("rowId and colId cannot be used as a wildcard at the same time!");
      if (n) {
        let r = this.selectedRowsToColumns.get(e) || new Set();
        r.add(t), this.selectedRowsToColumns.set(e, r);
      } else {
        let r = this.selectedRowsToColumns.get(e);
        r && (r.delete(t), r.size === 0 && this.selectedRowsToColumns.delete(e));
      }
      if (n) {
        let r = this.selectedColumnsToRows.get(t) || new Set();
        r.add(e), this.selectedColumnsToRows.set(t, r);
      } else {
        let r = this.selectedColumnsToRows.get(t);
        r && (r.delete(e), r.size === 0 && this.selectedColumnsToRows.delete(t));
      }
    }
    update(e) {
      let t = e.selectedCells || null,
        n = e.deselectedCells || null;
      this.selectedRowsToColumns.clear(), this.selectedColumnsToRows.clear(), this.deselectedRowsToColumns.clear(), this.deselectedColumnsToRows.clear(), this.cache.clear(), t && t.forEach(([r, i]) => {
        this.setCellInSelection(r, i, !0);
      }), n && n.forEach(([r, i]) => {
        this.setCellInDeselection(r, i, !0);
      }), this.defaultSelection = e.defaultSelection;
    }
    isCellSelectionInRow(e, t) {
      if (this.defaultSelection) {
        let r = this.deselectedRowsToColumns.get(e);
        if (r) {
          if (r.has(this.wildcard)) {
            let i = this.selectedRowsToColumns.get(e);
            return i && i.size > 0 ? t.some(a => i.has(a)) : !1;
          }
          return t.some(i => !r.has(i));
        }
        return !0;
      }
      let n = this.selectedRowsToColumns.get(e);
      if (n) {
        if (n.has(this.wildcard)) {
          let r = this.deselectedRowsToColumns.get(e);
          return r && r.size > 0 ? !t.every(a => r.has(a)) : !0;
        }
        return t ? t.some(r => n.has(r)) : !1;
      }
      return !1;
    }
    isCellSelected(e, t) {
      return e === this.wildcard || t === this.wildcard ? (console.error("CellSelectionState.isCellSelected should not be called with wildcard"), gh("CellSelectionState.isCellSelected should not be called with wildcard"), !1) : this.isCellSelected_Internal(e, t);
    }
    isCellSelected_Internal(e, t) {
      var m, g;
      let n = [e, t],
        {
          cache: r
        } = this,
        i = r.get(n);
      if (i != null) return i;
      let a = () => (r.set(n, !1), !1),
        l = () => (r.set(n, !0), !0),
        s = this.deselectedColumnsToRows.get(this.wildcard),
        u = this.deselectedRowsToColumns.get(this.wildcard),
        c = this.selectedColumnsToRows.get(this.wildcard),
        d = this.selectedRowsToColumns.get(this.wildcard);
      if (this.defaultSelection) {
        let C = this.deselectedRowsToColumns.get(e);
        return C && C.has(t) ? a() : s != null && s.has(e) || u != null && u.has(t) ? (m = this.selectedRowsToColumns.get(e)) != null && m.has(t) ? l() : a() : l();
      }
      let f = this.selectedRowsToColumns.get(e);
      return f && f.has(t) ? l() : c != null && c.has(e) || d != null && d.has(t) ? (g = this.deselectedRowsToColumns.get(e)) != null && g.has(t) ? a() : l() : a();
    }
    getState() {
      let e = [],
        t = [];
      return this.deselectedRowsToColumns.forEach((n, r) => {
        n.forEach(i => {
          e.push([r, i]);
        });
      }), this.selectedRowsToColumns.forEach((n, r) => {
        n.forEach(i => {
          t.push([r, i]);
        });
      }), {
        defaultSelection: this.defaultSelection,
        deselectedCells: e,
        selectedCells: t
      };
    }
  };
var me = class {
  constructor(e, t, n) {
    this.selectedRows = null;
    this.deselectedRows = null;
    this.defaultSelection = !1;
    this.selectedMap = new le();
    this.deselectedMap = new le();
    this.onlyUsePrimaryKeys = !1;
    this.selectionCache = new le();
    this.selectionCountCache = new le();
    this.mapSet = (e, t) => {
      Array.isArray(t) || (this.onlyUsePrimaryKeys ? t = [t] : t = [...this.getGroupKeysForPrimaryKey(t), t]), this.xcache(), e === "selected" ? this.selectedMap.set(t, !0) : this.deselectedMap.set(t, !0);
    };
    this._selectedMapSet = e => {
      this.mapSet("selected", e);
    };
    this._deselectedMapSet = e => {
      this.mapSet("deselected", e);
    };
    let r = e instanceof Object.getPrototypeOf(this).constructor ? e.getState() : e;
    this.getConfig = t, this.onlyUsePrimaryKeys = t().onlyUsePrimaryKeys, n && Object.assign(this, n), this.update(r);
  }
  getGroupKeysForPrimaryKey(e) {
    let {
        indexer: t,
        groupBy: n
      } = this.getConfig(),
      r = t.getDataForPrimaryKey(e);
    return r ? _n(r, n) : [];
  }
  getGroupDeepMap() {
    return this.getConfig().groupDeepMap;
  }
  getGroupCount(e) {
    var r;
    if (e.length == 0) return this.getConfig().totalCount;
    let t = this.getGroupDeepMap(),
      n = t == null ? void 0 : t.get(e);
    return n ? (r = n.totalChildrenCount) != null ? r : n.items.length || 0 : 0;
  }
  getGroupKeysDirectlyInsideGroup(e) {
    let {
      groupDeepMap: t
    } = this.getConfig();
    return (t == null ? void 0 : t.getKeysStartingWith(e, !0, 1)) || [];
  }
  getAllPrimaryKeysInsideGroup(e) {
    let {
      groupDeepMap: t
    } = this.getConfig();
    if (!e.length) return ((t == null ? void 0 : t.getKeysStartingWith([], !0, 1)) || []).map(i => this.getAllPrimaryKeysInsideGroup(i)).flat();
    let n = t == null ? void 0 : t.get(e);
    return n ? n.items.map(this.getConfig().toPrimaryKey) : [];
  }
  getGroupByLength() {
    return this.getConfig().groupBy.length;
  }
  static from(e, t, n) {
    return new me(e, t, n);
  }
  update(e) {
    var t, n;
    this.selectedRows = e.selectedRows || null, this.deselectedRows = e.deselectedRows || null, this.selectedMap.clear(), this.deselectedMap.clear(), (t = this.selectedRows) == null || t.forEach(this._selectedMapSet), (n = this.deselectedRows) == null || n.forEach(this._deselectedMapSet), this.defaultSelection = e.defaultSelection, this.xcache();
  }
  xcache() {
    this.selectionCache.clear(), this.selectionCountCache.clear();
  }
  getState() {
    let e = r => {
        let i = this.getGroupByLength();
        return r.map(a => this.onlyUsePrimaryKeys ? a[0] : a.length > i ? a.pop() : a);
      },
      t = e(this.selectedMap.topDownKeys()),
      n = e(this.deselectedMap.topDownKeys());
    return {
      defaultSelection: this.defaultSelection,
      selectedRows: t,
      deselectedRows: n
    };
  }
  deselectAll() {
    this.update({
      defaultSelection: !1,
      selectedRows: [],
      deselectedRows: []
    });
  }
  selectAll() {
    this.update({
      defaultSelection: !0,
      deselectedRows: [],
      selectedRows: []
    });
  }
  isRowDefaultSelected() {
    return this.defaultSelection === !0;
  }
  isRowDefaultDeselected() {
    return this.defaultSelection === !1;
  }
  isRowSelected(e, t) {
    if (this.onlyUsePrimaryKeys) return this.defaultSelection ? !this.deselectedMap.has([e]) : this.selectedMap.has([e]);
    t = t || this.getGroupKeysForPrimaryKey(e);
    let n = [...t, e],
      r = this.selectionCache.get(n);
    if (r !== void 0) return r;
    let i = this.selectedMap.has(n),
      a = this.deselectedMap.has(n);
    if (i) return this.selectionCache.set(n, !0), !0;
    if (a) return this.selectionCache.set(n, !1), !1;
    if (this.selectedMap.has(t)) return this.selectionCache.set(n, !0), !0;
    if (this.deselectedMap.has(t)) return this.selectionCache.set(n, !1), !1;
    for (t = [...t]; t.length;) {
      t.pop();
      let l = this.selectedMap.has(t),
        s = this.deselectedMap.has(t);
      if (l) return this.selectionCache.set(n, !0), !0;
      if (s) return this.selectionCache.set(n, !1), !1;
    }
    return this.selectionCache.set(n, this.defaultSelection), this.defaultSelection;
  }
  isRowDeselected(e, t) {
    return !this.isRowSelected(e, t);
  }
  setRowSelected(e, t, n) {
    t ? this.setRowAsSelected(e, n) : this.setRowAsDeselected(e, n);
  }
  getGroupRowSelectionState(e) {
    let t = this.selectionCache.get(e);
    if (t !== void 0) return t;
    let {
        selectedCount: n,
        deselectedCount: r
      } = this.getSelectionCountFor(e, this.onlyUsePrimaryKeys ? void 0 : this.getGroupRowBooleanSelectionStateFromParent(e)),
      i = n && r ? null : !!n;
    return this.selectionCache.set(e, i), i;
  }
  getGroupRowBooleanSelectionStateFromParent(e) {
    if (!e.length) return this.defaultSelection;
    let t = [...e],
      n = this.deselectedMap.has(t),
      r = this.selectedMap.has(t),
      i = r && !n ? !0 : n && !r ? !1 : void 0;
    if (i === void 0) for (; t.length;) {
      if (t.pop(), this.deselectedMap.has(t)) {
        i = !1;
        break;
      }
      if (this.selectedMap.has(t)) {
        i = !0;
        break;
      }
    }
    return i === void 0 && (i = this.defaultSelection), i;
  }
  isGroupRowPartlySelected(e) {
    return this.getGroupRowSelectionState(e) === null;
  }
  isGroupRowSelected(e) {
    return this.getGroupRowSelectionState(e) === !0;
  }
  isGroupRowDeselected(e) {
    return this.getGroupRowSelectionState(e) === !1;
  }
  selectGroupRow(e) {
    if (this.onlyUsePrimaryKeys) {
      this.getAllPrimaryKeysInsideGroup(e).forEach(i => {
        this.defaultSelection === !0 ? this.deselectedMap.delete([i]) : this._selectedMapSet(i);
      }), this.xcache();
      return;
    }
    this.selectedMap.getKeysStartingWith(e, !0).forEach(r => {
      this.selectedMap.delete(r);
    }), e.length === 1 && this.defaultSelection === !0 || this._selectedMapSet(e), this.deselectedMap.getKeysStartingWith(e).forEach(r => {
      this.deselectedMap.delete(r);
    });
  }
  deselectGroupRow(e) {
    if (this.onlyUsePrimaryKeys) {
      this.getAllPrimaryKeysInsideGroup(e).forEach(i => {
        this.defaultSelection === !1 ? this.selectedMap.delete([i]) : this._deselectedMapSet(i);
      }), this.xcache();
      return;
    }
    this.deselectedMap.getKeysStartingWith(e, !0).forEach(r => {
      this.deselectedMap.delete(r);
    }), e.length === 1 && this.defaultSelection === !1 || this._deselectedMapSet(e), this.selectedMap.getKeysStartingWith(e).forEach(r => {
      this.selectedMap.delete(r);
    });
  }
  setRowAsSelected(e, t) {
    if (this.onlyUsePrimaryKeys) {
      this.defaultSelection ? this.deselectedMap.delete([e]) : this._selectedMapSet(e), this.xcache();
      return;
    }
    t = t || this.getGroupKeysForPrimaryKey(e);
    let n = [...t, e];
    this.deselectedMap.delete(n), this.xcache(), this.getGroupRowSelectionState(t) !== !0 && (this._selectedMapSet(n), this.getGroupRowSelectionState(t) === !0 && this.selectGroupRow(t));
  }
  setRowAsDeselected(e, t) {
    if (this.onlyUsePrimaryKeys) {
      this.defaultSelection ? this._deselectedMapSet(e) : this.selectedMap.delete([e]), this.xcache();
      return;
    }
    t = t || this.getGroupKeysForPrimaryKey(e);
    let n = [...t, e];
    this.selectedMap.delete(n), this.xcache(), this.getGroupRowSelectionState(t) !== !1 && (this._deselectedMapSet(n), this.getGroupRowSelectionState(t) === !1 && this.deselectGroupRow(t));
  }
  deselectRow(e, t) {
    this.setRowSelected(e, !1, t);
  }
  selectRow(e, t) {
    this.setRowSelected(e, !0, t);
  }
  toggleGroupRowSelection(e) {
    this.getGroupRowSelectionState(e) === !0 ? this.deselectGroupRow(e) : this.selectGroupRow(e);
  }
  toggleRowSelection(e, t) {
    this.isRowSelected(e, t) ? this.deselectRow(e, t) : this.selectRow(e, t);
  }
  getSelectedCount() {
    return this.getSelectionCountFor([]).selectedCount;
  }
  getDeselectedCount() {
    return this.getSelectionCountFor([]).deselectedCount;
  }
  getSelectionCountFor(e = [], t) {
    var u, c;
    let n = this.selectionCountCache.get(e);
    if (n != null) return n;
    let r = this.getGroupByLength();
    if (e.length > r) {
      let d = this.isRowSelected(e) ? {
        selectedCount: 1,
        deselectedCount: 0
      } : {
        selectedCount: 0,
        deselectedCount: 1
      };
      return this.selectionCountCache.set(e, d), d;
    }
    if (r && this.onlyUsePrimaryKeys) {
      let d = this.getAllPrimaryKeysInsideGroup(e),
        p = 0,
        f = 0;
      d.forEach(g => {
        if (this.defaultSelection) {
          if (this.deselectedMap.has([g])) {
            f++;
            return;
          }
          p++;
        } else {
          if (this.selectedMap.has([g])) {
            p++;
            return;
          }
          f++;
        }
      });
      let m = {
        selectedCount: p,
        deselectedCount: f
      };
      return this.selectionCountCache.set(e, m), m;
    }
    t = t != null ? t : this.getGroupRowBooleanSelectionStateFromParent(e);
    let i = this.getGroupKeysDirectlyInsideGroup(e);
    if (e.length === this.getGroupByLength()) {
      let d = this.selectedMap.has(e) ? !0 : this.deselectedMap.has(e) ? !1 : t,
        p = this.getGroupCount(e),
        f = this.selectedMap.getKeysStartingWith(e, !0, 1).length,
        m = this.deselectedMap.getKeysStartingWith(e, !0, 1).length,
        g = p - (f + m),
        C = {
          selectedCount: f + (d ? g : 0),
          deselectedCount: m + (d ? 0 : g)
        };
      return this.selectionCountCache.set(e, C), C;
    }
    let a = 0,
      l = 0;
    if (this.getConfig().lazyLoad && !i.length) {
      let d = ((c = (u = this.getConfig().groupDeepMap) == null ? void 0 : u.get(e)) == null ? void 0 : c.totalChildrenCount) || 0;
      t ? (a = d, l = this.deselectedMap.getAllChildrenSizeFor(e), l === d && (a = 0)) : (l = d, a = this.selectedMap.getAllChildrenSizeFor(e), a === d && (l = 0));
    }
    i.forEach(d => {
      let p = this.selectedMap.get(d),
        f = this.deselectedMap.get(d),
        m = p ? !0 : f ? !1 : t,
        {
          selectedCount: g,
          deselectedCount: C
        } = this.getSelectionCountFor(d, m);
      a += g, l += C;
    });
    let s = {
      selectedCount: a,
      deselectedCount: l
    };
    return this.selectionCountCache.set(e, s), s;
  }
};
function ea(o) {
  if (!o) return;
  let e = Object.keys(o);
  if (!e.length) return;
  let t = {};
  for (let n = 0, r = e.length; n < r; n++) {
    let i = e[n],
      a = o[i].initialValue;
    a !== void 0 && (t[i] = typeof a == "function" ? a() : a);
  }
  return t;
}
function ta(o) {
  let e = o.reducerKeys,
    t = o.reducers,
    n = o.results;
  for (let r = 0, i = e.length; r < i; r++) {
    let a = e[r],
      l = t[a].reducer;
    n[a] = l(n[a], o.rowInfo);
  }
}
function na(o) {
  let e = Object.keys(o.reducers || {});
  if (!e.length || !o.results) return o.results;
  let t = o.reducers,
    n = o.results;
  for (let r = 0, i = e.length; r < i; r++) {
    let a = e[r],
      l = t[a].done;
    typeof l == "function" && (n[a] = l(n[a], o.array));
  }
  return n;
}
function oa(o, e) {
  return o && o.filter(t => {
    let n = e[t.filter.type];
    return !(!n || n.emptyValues && n.emptyValues.includes(t.filter.value));
  });
}
var On = (o, e, t) => {
  for (let n = 0, r = t.length; n < r; n++) {
    let i = t[n],
      a = o[i],
      l = e[i];
    if (a !== l) return !0;
  }
  return !1;
};
function Wc() {
  return !1;
}
function Ch(o, e, t, n, r) {
  let i = {
    dataSourceHasGrouping: !1,
    data: o,
    id: e,
    indexInAll: t,
    isGroupRow: !1,
    selfLoaded: !0,
    rowSelected: !1,
    isCellSelected: Wc,
    hasSelectedCells: Wc
  };
  return n && (i.rowSelected = n(i)), r && (i.isCellSelected = a => r.isCellSelected(i.id, a), i.hasSelectedCells = a => r.isCellSelectionInRow(i.id, a)), i;
}
function Sh(o) {
  let {
      filterTypes: e,
      operatorsByFilterType: t,
      filterFunction: n,
      toPrimaryKey: r
    } = o,
    {
      dataArray: i
    } = o;
  n && (i = i.filter((l, s, u) => n({
    data: l,
    index: s,
    dataArray: u,
    primaryKey: r(l, s)
  })));
  let a = oa(o.filterValue, e) || [];
  return a && a.length ? i.filter((l, s, u) => {
    var d;
    let c = {
      data: l,
      index: s,
      dataArray: u,
      primaryKey: r(l, s),
      field: void 0
    };
    for (let p = 0, f = a.length; p < f; p++) {
      let m = a[p],
        {
          disabled: g,
          field: C,
          valueGetter: T,
          filter: {
            type: b,
            value: S,
            operator: y
          }
        } = m,
        w = e[b];
      if (g || !w) continue;
      let v = (d = t[b]) == null ? void 0 : d[y];
      if (!v) continue;
      let R = T || w.valueGetter || (({
        data: E,
        field: M
      }) => M ? E[M] : E);
      c.field = C;
      let x = c;
      if (x.filterValue = S, x.currentValue = R(x), x.emptyValues = w.emptyValues, !v.fn(x)) return !1;
    }
    return !0;
  }) : i;
}
function $c(o) {
  var L, N;
  let {
      state: e,
      previousState: t
    } = o,
    n = Hc(e),
    r = e.sortInfo,
    i = e.sortMode,
    a = r != null && r.length ? i === "local" : !1;
  (e.lazyLoad || e.livePagination) && (a = !1);
  let l = On(t, e, ["cache", "originalDataArray", "originalLazyGroupDataChangeDetect"]),
    s = t && e.data !== t.data,
    u = e.lazyLoad && t && (t.groupBy !== e.groupBy || t.sortInfo !== e.sortInfo);
  s && (u = !0), u && (e.originalLazyGroupData = new le(), l = !0);
  let c = e.cache ? xt.clone(e.cache) : void 0;
  c && !c.isEmpty() && (l = !0);
  let d = e.toPrimaryKey,
    p;
  l && (e.indexer.clear(), e.lazyLoad || (p = c == null ? void 0 : c.getMutations(), e.originalDataArray = e.indexer.indexArray(e.originalDataArray, {
    toPrimaryKey: d,
    cache: c
  }))), c && (c.clear(), e.cache = void 0);
  let {
      filterFunction: m,
      filterValue: g,
      filterTypes: C,
      operatorsByFilterType: T
    } = e,
    b = !!m || Array.isArray(g) && g.length,
    S = b && e.filterMode === "local",
    y = On(t, e, ["filterFunction", "filterValue", "filterTypes"]),
    w = l || y,
    v = On(t, e, ["sortInfo"]),
    I = l || y || v,
    R = e.filterMode === "local" && (w || !e.lastFilterDataArray),
    x = a && (I || !e.lastSortDataArray || n.sortInfo),
    E = e.groupBy,
    M = e.pivotBy,
    V = E.length > 0 || !!M,
    z = On(t, e, ["rowSelection", "cellSelection", "isRowSelected", "originalLazyGroupDataChangeDetect"]),
    P = l || I || On(t, e, ["generateGroupRows", "originalLazyGroupData", "originalLazyGroupDataChangeDetect", "groupBy", "groupRowsState", "pivotBy", "aggregationReducers", "pivotTotalColumnPosition", "pivotGrandTotalColumnPosition", "showSeparatePivotColumnForSingleAggregation"]),
    F = On(t, e, ["rowInfoReducers"]),
    A = V && (P || !e.lastGroupDataArray || n.groupBy) || z || F,
    O = Date.now(),
    B = e.originalDataArray;
  if (b || (e.unfilteredCount = B.length), S && (e.unfilteredCount = B.length, B = R ? Sh({
    dataArray: B,
    toPrimaryKey: d,
    filterTypes: C,
    operatorsByFilterType: T,
    filterFunction: m,
    filterValue: g
  }) : e.lastFilterDataArray, e.lastFilterDataArray = B, e.filteredAt = O), e.filteredCount = B.length, e.postFilterDataArray = B, a) {
    let j = It.knownTypes;
    It.knownTypes = h(h({}, j), e.sortTypes);
    let _ = e.sortFunction || It;
    B = x ? _(r, [...B]) : e.lastSortDataArray, It.knownTypes = j, e.lastSortDataArray = B, e.sortedAt = O;
  }
  e.postSortDataArray = B;
  let k = e.dataArray,
    J = e.rowSelection instanceof me ? e.rowSelection : void 0,
    oe = e.cellSelection instanceof Ee ? e.cellSelection : void 0;
  J == null || J.xcache();
  let q = e.selectionMode === "single-row" ? j => j.id === e.rowSelection : e.selectionMode === "multi-row" ? j => {
    let _ = J;
    return j.isGroupRow ? _.getGroupRowSelectionState(j.groupKeys) : _.isRowSelected(j.id, j.dataSourceHasGrouping ? j.groupKeys : void 0);
  } : void 0;
  e.isRowSelected && e.selectionMode === "multi-row" && (q = j => e.isRowSelected(j, J, e.selectionMode));
  let Q = e.rowInfoReducers;
  if (V) {
    if (A) {
      let j = e.aggregationReducers,
        _ = e.lazyLoad ? zc({
          groupBy: E,
          pivot: M,
          mappings: e.pivotMappings,
          reducers: j,
          indexer: e.indexer,
          toPrimaryKey: d,
          cache: c
        }, e.originalLazyGroupData) : qi({
          groupBy: E,
          pivot: M,
          reducers: j
        }, B);
      e.groupDeepMap = _.deepMap, J && (J.getConfig = $e(e));
      let H = Object.keys(Q || {}),
        G = ea(Q),
        W = {
          reducers: Q,
          results: G,
          reducerKeys: H,
          rowInfo: {}
        },
        X = G ? Ae => {
          W.rowInfo = Ae, ta(W);
        } : void 0,
        Y = oe ? Ae => {
          Ae.isCellSelected = ze => oe.isCellSelected(Ae.id, ze);
        } : void 0,
        ee = X || Y ? Kc(X, Y) : void 0,
        pe = Gc({
          groupResult: _,
          lazyLoad: !!e.lazyLoad,
          reducers: j,
          toPrimaryKey: d,
          isRowSelected: q,
          rowSelectionState: J,
          withRowInfo: ee,
          groupRowsState: e.groupRowsState,
          generateGroupRows: e.generateGroupRows
        });
      k = pe.data, e.rowInfoReducerResults = na({
        reducers: Q,
        results: G,
        array: k
      }), e.groupRowsIndexesInDataArray = pe.groupRowsIndexes, e.reducerResults = _.reducerResults;
      let ue = M ? Bc({
        deepMap: _.topLevelPivotColumns,
        pivotBy: M,
        pivotTotalColumnPosition: (L = e.pivotTotalColumnPosition) != null ? L : "end",
        pivotGrandTotalColumnPosition: (N = e.pivotGrandTotalColumnPosition) != null ? N : !1,
        reducers: e.aggregationReducers,
        showSeparatePivotColumnForSingleAggregation: e.showSeparatePivotColumnForSingleAggregation
      }) : void 0;
      e.pivotColumns = ue == null ? void 0 : ue.columns, e.pivotColumnGroups = ue == null ? void 0 : ue.columnGroups;
    } else k = e.lastGroupDataArray;
    e.lastGroupDataArray = k, e.groupedAt = O;
  } else if (e.groupDeepMap = void 0, e.pivotColumns = void 0, e.groupRowsIndexesInDataArray = void 0, t.postSortDataArray != e.postSortDataArray || P || z || F) {
    let _ = Object.keys(Q || {}),
      H = ea(Q),
      G = {
        reducers: Q,
        results: H,
        reducerKeys: _,
        rowInfo: {}
      };
    k = B.map((W, X) => {
      let Y = Ch(W, W ? d(W) : X, X, q, oe);
      return H && (G.rowInfo = Y, ta(G)), Y;
    }), e.rowInfoReducerResults = na({
      reducers: Q,
      results: H,
      array: k
    });
  }
  if (e.postGroupDataArray = k, k !== e.dataArray && (e.updatedAt = O), e.dataArray = k, e.reducedAt = O, e.selectionMode === "multi-row") if (V && e.lazyLoad) {
    let j = !0,
      _ = !1;
    e.dataArray.forEach(H => {
      if (H.isGroupRow && H.groupKeys.length === 1) {
        let {
          rowSelected: G
        } = H;
        G !== !0 && (j = !1), (G === !0 || G === null) && (_ = !0);
      }
    }), e.allRowsSelected = j, e.someRowsSelected = _;
  } else {
    let j = e.filteredCount,
      _ = e.rowSelection.getSelectedCount();
    e.allRowsSelected = j === _, e.someRowsSelected = _ > 0;
  }
  return e.originalDataArrayChanged = l, l && (e.originalDataArrayChangedInfo = {
    timestamp: O,
    mutations: p != null && p.size ? p : void 0
  }), e;
}
function it() {
  var e;
  let o = (e = ve().performance) == null ? void 0 : e.now();
  return `${Date.now()}:${o}`;
}
var Th = !0,
  bh = () => new Promise(o => {
    be(o);
  }),
  Rh = {
    originalDataArray: !0,
    changes: !0
  },
  wh = {
    filterValue: (o, e) => JSON.stringify(o) === JSON.stringify(e),
    groupRowsState: (o, e) => JSON.stringify(o) === JSON.stringify(e)
  };
function vt(o, e, t) {
  var l, s;
  let n = o.multiSort ? o.sortInfo : (s = (l = o.sortInfo) == null ? void 0 : l[0]) != null ? s : null,
    r = {
      append: !1,
      originalDataArray: o.originalDataArray,
      sortInfo: n,
      refetchKey: o.refetchKey,
      groupBy: o.groupBy,
      pivotBy: o.pivotBy,
      filterValue: o.filterValue,
      aggregationReducers: o.aggregationReducers
    };
  if (t && (r.masterRowInfo = t.masterRowInfo), r.groupBy && (r.groupRowsState = o.groupRowsState.getState()), o.livePagination !== void 0 && (r.livePaginationCursor = o.livePaginationCursor, r.__cursorId = o.cursorId), o.lazyLoad && (o.lazyLoadBatchSize != null && o.lazyLoadBatchSize > 0 && (r.lazyLoadBatchSize = o.lazyLoadBatchSize, r.lazyLoadStartIndex = 0), r.groupKeys = []), e && Object.assign(r, e), r.filterValue && r.filterValue.length) {
    let u = Yc(r.filterValue, {
      filterMode: o.filterMode,
      filterTypes: o.filterTypes
    });
    u && u.length && (r.filterValue = u);
  }
  let i = {},
    a = o.dataParams || {};
  for (let u in r) if (r.hasOwnProperty(u) && !Rh[u]) {
    let c = u,
      d = wh[c],
      p = r[c],
      f = a[c];
    (d ? d(p, f) : p === f) || (i[c] = !0);
  }
  return r.changes = i, r;
}
function an(o, e, t, n, r) {
  let i = vt(e, n, r),
    a = i.append;
  if (e.lazyLoad) {
    let s = e.originalLazyGroupData,
      u = [Kt, ...(i.groupKeys || [])],
      c = s.get(u);
    if (!c) {
      let d = [...(i.groupKeys || []), i.lazyLoadStartIndex];
      e.lazyLoadCacheOfLoadedBatches.set(d, !0);
    }
    if (c && c.cache && u.length > 1) {
      let d = c.children,
        p = d.length,
        f = !0;
      for (let m = 0; m < p; m++) if (d[m] == null) {
        f = !1;
        break;
      }
      if (f) return Promise.resolve(!0);
    }
    c ? c.childrenLoading || (c.childrenLoading = !0) : s.set(u, {
      error: void 0,
      children: [],
      childrenLoading: !0,
      childrenAvailable: !1,
      cache: Th,
      totalCount: 0,
      totalCountUnfiltered: 0
    }), t.originalLazyGroupDataChangeDetect = it();
  }
  typeof o == "function" && (o = o(i));
  let l = typeof o == "object" && typeof o.then == "function";
  return l && !e.lazyLoad && (t.loading = !0), Promise.resolve(o).then(s => {
    let u = [],
      c = !1;
    if (Array.isArray(s.data)) {
      let p = s;
      if (u = p.data, p.livePaginationCursor !== void 0 && (t.livePaginationCursor = p.livePaginationCursor), p.mappings && (t.pivotMappings = p.mappings), p.totalCountUnfiltered && (t.unfilteredCount = p.totalCountUnfiltered), e.lazyLoad) {
        let m = function (g, C, T) {
          var I, R, x, E;
          let b = [Kt, ...g],
            S = C.data,
            y = {
              cache: !!C.cache,
              childrenLoading: !1,
              childrenAvailable: !0,
              totalCount: (I = C.totalCount) != null ? I : S.length,
              totalCountUnfiltered: (R = C.totalCount) != null ? R : S.length,
              children: S,
              error: C.error
            },
            w = [];
          if (i.lazyLoadBatchSize && !T) {
            let M = f.get(b),
              V = !M,
              z = ru({
                children: !0
              }, M || {}, y);
            V && (z.chidren = []), z.children.length = z.totalCount;
            let P = (x = i.lazyLoadStartIndex) != null ? x : 0,
              F = Math.min((E = C.totalCount) != null ? E : S.length, P + i.lazyLoadBatchSize);
            for (let A = P; A < F; A++) {
              let O = y.children[A - P];
              if (!O) throw `lazily loaded item not found at index ${A - P}`;
              z.children[A] = O, O.dataset && w.push({
                keys: O.keys,
                dataset: O.dataset
              });
            }
            V && f.set(b, z);
          } else y.children.forEach(M => {
            M && M.dataset && w.push({
              keys: M.keys,
              dataset: M.dataset
            });
          }), y.children.length = y.totalCount, f.set(b, y);
          let v = !1;
          if (!g || !g.length) {
            let M = f.get([Kt]);
            t.originalDataArray = [...M.children], v = !0;
          }
          if (v || (t.originalLazyGroupDataChangeDetect = it()), w.length) {
            let M = g;
            w.forEach(({
              keys: V,
              dataset: z
            }) => {
              m(V, z, M);
            });
          }
        };
        var d = m;
        let f = e.originalLazyGroupData;
        c = !0, m(i.groupKeys || [], p);
      }
    } else u = s;
    c || (t.originalDataArray = a ? e.originalDataArray.concat(u) : u), l && !e.lazyLoad && (t.loading = !1);
  });
}
function Yc(o, {
  filterTypes: e,
  filterMode: t
}) {
  return t === "local" ? o : (oa(o, e) || []).map(n => {
    let r = h({}, n);
    return delete r.valueGetter, r;
  });
}
function jc(o, e) {
  let t = !!o,
    {
      stateReadyAsDetails: n
    } = e(),
    r = t && o.shouldRestoreState ? n : !0;
  return {
    isDetail: t,
    isDetailReady: r
  };
}
function qc() {
  let {
      getComponentState: o,
      componentActions: e,
      componentState: t
    } = Ie(),
    {
      data: n,
      dataArray: r,
      notifyScrollbarsChange: i,
      refetchKey: a,
      sortInfo: l,
      sortMode: s,
      groupBy: u,
      pivotBy: c,
      filterValue: d,
      filterMode: p,
      livePagination: f,
      livePaginationCursor: m,
      filterTypes: g,
      cursorId: C
    } = t,
    [T, b] = yh({
      vertical: !1,
      horizontal: !1
    }),
    S = ra(T);
  Vn(() => (i.onChange(R => {
    R && (S.current = R, b(R));
  }), () => i.destroy()), [i]), Vn(() => {
    if (!f) return;
    let R = requestAnimationFrame(() => {
      var x;
      (x = S.current) != null && x.vertical || m && (e.cursorId = m);
    });
    return () => cancelAnimationFrame(R);
  }, [m]), Vn(() => {
    if (!f || m !== void 0) return;
    let R = requestAnimationFrame(() => {
      var x;
      (x = S.current) != null && x.vertical || C != null && r.length && (e.cursorId = r.length);
    });
    return () => cancelAnimationFrame(R);
  }, [r.length, m]), Vn(() => {
    let R = o(),
      {
        livePaginationCursor: x,
        livePagination: E,
        dataArray: M
      } = R;
    !T.vertical && E && (x ? e.cursorId = x : x === void 0 && M.length && (e.cursorId = M.length));
  }, [T.vertical]);
  let y = Uc(() => Yc(d, {
      filterTypes: g,
      filterMode: p
    }), [d, p, g]),
    w = {
      sortInfo: s === "remote" ? l : null,
      groupBy: u,
      pivotBy: c,
      refetchKey: a,
      filterValue: y,
      cursorId: f ? C : null
    },
    v = ra(!0);
  Ih();
  let I = _e(Ne());
  Fn(() => {
    let R = o(),
      x = I(),
      {
        isDetail: E,
        isDetailReady: M
      } = jc(x, o);
    E && !M || typeof R.data != "function" && an(R.data, R, e, void 0, x);
  }, {
    data: n
  }), Fn(R => {
    let x = Object.keys(R),
      E = !1;
    if (x.length === 1 && (E = !!R.cursorId, R.filterValue && o().filterMode === "local")) return;
    let M = I(),
      {
        isDetail: V,
        isDetailReady: z
      } = jc(M, o);
    if (V && !z) return;
    let P = o();
    typeof P.data == "function" && an(P.data, P, e, {
      append: E
    }, M);
  }, D(h({}, w), {
    data: n
  })), Fn(() => {
    let R = o();
    if (v.current) {
      v.current = !1;
      let x = vt(R, void 0, I());
      e.dataParams = x;
    }
  }, w);
}
function Ih() {
  let {
    getComponentState: o,
    componentActions: e,
    componentState: t
  } = Ie();
  Vn(() => {
    e.lazyLoadCacheOfLoadedBatches = new le();
  }, [t.data, t.dataParams]);
  let {
      lazyLoadBatchSize: n,
      lazyLoad: r,
      originalLazyGroupDataChangeDetect: i,
      notifyRenderRangeChange: a,
      dataArray: l,
      groupRowsState: s,
      scrollStopDelayUpdatedByTable: u
    } = t,
    c = ra(null),
    d = (f, m = o().lazyLoadCacheOfLoadedBatches) => {
      let g = o();
      if (f = f || c.current, !f) return;
      let {
          renderStartIndex: C,
          renderEndIndex: T
        } = f,
        {
          lazyLoadBatchSize: b,
          lazyLoad: S
        } = g;
      S && ((!b || b <= 0) && (!g.groupBy || g.groupBy.length === 0) || xh({
        startIndex: C,
        endIndex: T,
        lazyLoadBatchSize: b,
        componentState: g,
        componentActions: e
      }, m));
    },
    p = Uc(() => St(d, {
      wait: u
    }), [u]);
  Fn(f => {
    if (r) return (f.originalLazyGroupDataChangeDetect || f.groupRowsState) && d(a.get()), a.onChange(m => {
      c.current = m, d(m);
    });
  }, {
    lazyLoadBatchSize: n,
    lazyLoad: r,
    originalLazyGroupDataChangeDetect: i,
    groupRowsState: s
  }), Vn(() => {
    n && n > 0 && p();
  }, [l]);
}
function xh(o, e) {
  let {
      startIndex: t,
      endIndex: n,
      lazyLoadBatchSize: r,
      componentState: i,
      componentActions: a
    } = o,
    {
      dataArray: l
    } = i,
    s = f => l[f].data != null,
    u = new le(),
    c = ["_______xxx______"];
  for (let f = t; f <= n; f++) {
    let m = l[f];
    if (!m) continue;
    let g = s(f),
      C = m.dataSourceHasGrouping && m.groupKeys ? [...m.groupKeys] : [];
    m.isGroupRow && m.dataSourceHasGrouping && m.groupKeys && C.pop();
    let T = C.length ? C : c,
      b = m.dataSourceHasGrouping ? m.indexInGroup : m.indexInAll;
    if (m.isGroupRow && !m.collapsed && !m.childrenAvailable) {
      let S = {
          lazyLoadStartIndex: 0,
          lazyLoadBatchSize: r,
          groupKeys: m.groupKeys
        },
        y = m.groupKeys,
        w = u.get(y);
      if (!(w != null && w[m.id])) {
        let v = !w;
        w = w || {}, w[m.id] || (w[m.id] = S), v && u.set(y, w);
      }
    }
    if (!g && r != null) {
      let S = u.get(T),
        y = Math.floor(b / r) * r,
        w = b - y,
        v = l[m.indexInAll - w].indexInAll,
        I = s(v),
        R = l[v].id;
      if (I || S != null && S[R]) continue;
      let x = !S;
      S = S || {};
      let E = {
        lazyLoadStartIndex: y,
        lazyLoadBatchSize: r,
        groupKeys: C
      };
      S[R] || (S[R] = E), x && u.set(T, S);
    }
  }
  let d = Promise.resolve(!0),
    p = [];
  u.topDownValues().forEach(f => {
    p.push(...Object.values(f));
  }), p.reduce((f, m) => {
    let g = [...m.groupKeys, m.lazyLoadStartIndex];
    if (e && e.has(g)) return f;
    e == null || e.set(g, !0);
    let C = [i.data, i, a, m];
    return f.then(() => bh()).then(() => an(...C));
  }, d);
}
var ia = new WeakMap(),
  vh = o => o;
function Xc(o, e = 50, t) {
  ia.set(o, {
    lastCallTime: 0,
    lastCallArg: null
  });
  let n = t != null ? t : vh;
  return r => {
    let {
        lastCallTime: i,
        lastCallArg: a
      } = ia.get(o) || {
        lastCallTime: 0,
        lastCallArg: null
      },
      l = Date.now(),
      s = l - i,
      u = zt(n(a), n(r));
    s < e && u || (ia.set(o, {
      lastCallTime: l,
      lastCallArg: r
    }), o(r));
  };
}
var Tr = class {
  constructor() {
    this.primaryKeyToData = new Map();
    this.add = (e, t) => {
      this.primaryKeyToData.set(e, t);
    };
    this.clear = () => {
      this.primaryKeyToData.clear();
    };
    this.getDataForPrimaryKey = e => this.primaryKeyToData.get(e);
    this.indexArray = (e, t) => {
      let {
        cache: n,
        toPrimaryKey: r
      } = t;
      n && !n.isEmpty() && (e = e.concat());
      for (let i = 0; i < e.length; i++) {
        let a = e[i],
          l = !1;
        if (a != null) {
          let s = r(a),
            u = n == null ? void 0 : n.getMutationsForPrimaryKey(s);
          if (u && u.length) {
            for (let c = 0, d = u.length; c < d; c++) {
              let p = u[c];
              if (p.type === "delete" && !l && (this.primaryKeyToData.delete(s), l = !0, e.splice(i, 1)), p.type === "update" && !l && (a = h(h({}, a), p.data), e[i] = a), p.type === "insert") {
                let f = r(p.data);
                this.add(f, p.data), p.position === "before" ? (e.splice(i, 0, p.data), i--) : e.splice(i + 1, 0, p.data);
              }
            }
            n == null || n.removeInfo(s);
          }
          l || this.add(s, a);
        }
      }
      return e;
    };
  }
};
var Ph = Object.freeze([]),
  Zc = (o, e) => {
    let t = o != null ? o : Ph,
      n = Array.isArray(t) ? t : [t];
    if (e && e.has(t)) {
      let r = e.get(t);
      if (r && r.length === n.length) return r;
    }
    return e && t && e.set(t, n), n;
  };
var Dh = Symbol("cursorId");
function Qc() {
  let o = Date.now(),
    e = [],
    t = [],
    n = new le();
  return {
    indexer: new Tr(),
    destroyedRef: {
      current: !1
    },
    idToIndexMap: new Map(),
    getDataSourceMasterContextRef: {
      current: () => {}
    },
    cache: void 0,
    detailDataSourcesStateToRestore: new Map(),
    stateReadyAsDetails: !1,
    originalDataArrayChanged: !1,
    originalDataArrayChangedInfo: {
      timestamp: 0,
      mutations: void 0
    },
    lazyLoadCacheOfLoadedBatches: new le(),
    dataParams: void 0,
    onCleanup: xe(),
    notifyScrollbarsChange: xe(),
    notifyScrollStop: xe(),
    notifyRenderRangeChange: xe(),
    pivotTotalColumnPosition: "end",
    pivotGrandTotalColumnPosition: !1,
    scrollStopDelayUpdatedByTable: 100,
    originalLazyGroupData: n,
    originalLazyGroupDataChangeDetect: 0,
    originalDataArray: e,
    cursorId: Dh,
    showSeparatePivotColumnForSingleAggregation: !1,
    propsCache: new Map([["sortInfo", new WeakMap()]]),
    rowInfoReducerResults: void 0,
    pivotMappings: void 0,
    pivotColumns: void 0,
    pivotColumnGroups: void 0,
    dataArray: t,
    unfilteredCount: t.length,
    filteredCount: t.length,
    updatedAt: o,
    groupedAt: 0,
    sortedAt: 0,
    filteredAt: 0,
    reducedAt: o,
    generateGroupRows: !0,
    groupDeepMap: void 0,
    reducerResults: void 0,
    allRowsSelected: !1,
    someRowsSelected: !1,
    postSortDataArray: void 0,
    postGroupDataArray: void 0,
    lastSortDataArray: void 0,
    lastGroupDataArray: void 0
  };
}
function Mh(o) {
  let e = h({}, o);
  return delete e.originalDataArray, e;
}
var Eh = [],
  ed = o => {
    o.destroyedRef.current = !0;
  },
  td = o => ({
    onDataParamsChange: e => e ? Xc(e, 100, Mh) : void 0,
    lazyLoad: e => !!e,
    data: 1,
    debugId: 1,
    pivotBy: 1,
    primaryKey: 1,
    livePagination: 1,
    refetchKey: e => e != null ? e : "",
    filterFunction: 1,
    filterValue: 1,
    useGroupKeysForMultiRowSelection: e => e != null ? e : !1,
    filterDelay: e => e != null ? e : 200,
    filterTypes: e => h(h({}, Zt), e),
    sortTypes: e => h(h({}, co), e),
    sortFunction: 1,
    onReady: 1,
    rowInfoReducers: (e, t) => {
      let n = {
        initialValue: () => {
          t.idToIndexMap.clear();
        },
        reducer: (r, i) => {
          t.idToIndexMap.set(i.id, i.indexInAll);
        }
      };
      return e ? D(h({}, e), {
        __idToIndex: n
      }) : {
        __idToIndex: n
      };
    },
    isRowSelected: 1,
    onDataArrayChange: 1,
    onDataMutations: 1,
    aggregationReducers: 1,
    collapseGroupRowsOnDataFunctionChange: e => e != null ? e : !0,
    loading: e => e != null ? e : !1,
    sortInfo: e => Zc(e, o.propsCache.get("sortInfo")),
    groupBy: e => e != null ? e : Eh
  });
function Ah(o, e) {
  return typeof o == "function" ? o({
    array: e.originalDataArray,
    length: e.originalDataArray.length,
    lastItem: e.originalDataArray[e.originalDataArray.length - 1]
  }) : o;
}
var Jc = new WeakMap();
function nd(o) {
  var T, b, S;
  let {
      props: e,
      state: t,
      oldState: n
    } = o,
    r = Qn(e.sortInfo),
    i = Qn(e.filterValue),
    {
      filterTypes: a
    } = t,
    l = Object.keys(a).reduce((y, w) => {
      let v = a[w].operators;
      y[w] = y[w] || {};
      let I = y[w];
      return v.forEach(R => {
        I[R.name] = R;
      }), y;
    }, {}),
    s = e.selectionMode;
  if (s === void 0) if (e.cellSelection !== void 0 || e.defaultCellSelection !== void 0) s = "multi-cell";else {
    let y = e.rowSelection !== void 0 ? e.rowSelection : e.defaultRowSelection;
    y !== void 0 && (y && typeof y == "object" ? s = "multi-row" : s = "single-row"), s = s != null ? s : !1;
  }
  let u = null,
    c = null,
    d = e.rowSelection !== void 0 ? e.rowSelection : t.rowSelection === void 0 ? e.defaultRowSelection !== void 0 ? e.defaultRowSelection : null : t.rowSelection,
    p = e.cellSelection !== void 0 ? e.cellSelection : t.cellSelection === void 0 ? e.defaultCellSelection !== void 0 ? e.defaultCellSelection : null : t.cellSelection || null;
  if (s !== !1 && ((s === "single-row" || s === "multi-row") && (d === null ? u = s === "single-row" ? null : new me({
    selectedRows: [],
    deselectedRows: [],
    defaultSelection: !1
  }, $e(t)) : s === "single-row" || d instanceof me ? u = d : u = new me(d, $e(t))), s === "single-cell" || s === "multi-cell")) if (p === null) c = s === "single-cell" ? null : new Ee();else if (p instanceof Ee) c = p;else {
    let y = (T = Jc.get(p)) != null ? T : new Ee(p);
    Jc.set(p, y), c = y;
  }
  let f = t.primaryKey,
    m = typeof f == "function" ? y => f(y) : y => y[f],
    g = e.groupRowsState || t.groupRowsState || e.defaultGroupRowsState;
  g && !(g instanceof Xe) && (g = new Xe(g)), g = g || new Xe(t.lazyLoad ? {
    expandedRows: [],
    collapsedRows: !0
  } : {
    expandedRows: !0,
    collapsedRows: []
  });
  let C = {
    selectionMode: s,
    groupRowsState: g,
    rowSelection: u,
    cellSelection: c,
    toPrimaryKey: m,
    operatorsByFilterType: l,
    controlledSort: r,
    controlledFilter: i,
    sortMode: e.sortFunction ? "local" : (b = e.sortMode) != null ? b : r ? "remote" : "local",
    filterMode: typeof e.filterFunction == "function" ? "local" : (S = e.filterMode) != null ? S : typeof e.data == "function" ? "remote" : "local",
    multiSort: Array.isArray(r ? e.sortInfo : e.defaultSortInfo),
    lazyLoadBatchSize: typeof e.lazyLoad == "object" ? e.lazyLoad.batchSize : void 0
  };
  if (e.livePagination) {
    let y = !n || n.originalDataArray !== t.originalDataArray,
      w = typeof e.livePaginationCursor == "function" ? y ? Ah(e.livePaginationCursor, t) : t.livePaginationCursor : e.livePaginationCursor;
    C.livePaginationCursor = w;
  }
  return C;
}
var Fh = nt("DataSource:fullLazyLoad");
function od(o, e, t) {
  let {
    name: n,
    newValue: r
  } = o;
  n === "data" && typeof r == "function" && !e.groupRowsState && (e.lazyLoad && Fh('"data" function prop has changed'), e.collapseGroupRowsOnDataFunctionChange !== !1 && (t.groupRowsState = new Xe({
    collapsedRows: !0,
    expandedRows: []
  })));
}
var _h = nt("DataSource:dataParams");
function rd() {
  return {
    rowSelection: (o, e) => e.selectionMode === "single-row" ? {
      callbackParams: [o, "single-row"]
    } : {
      callbackParams: [o.getState(), "multi-row"]
    },
    cellSelection: (o, e) => e.selectionMode === "single-cell" ? {
      callbackParams: [o instanceof Ee ? o.getState().selectedCells : null, "single-cell"]
    } : {
      callbackParams: [o.getState(), "multi-cell"]
    }
  };
}
function id() {
  return {
    sortInfo: (o, {
      actions: e,
      state: t
    }) => {
      let n = t.getDataSourceMasterContextRef.current,
        r = vt(t, {
          sortInfo: o,
          livePaginationCursor: null
        }, n());
      e.dataParams = r, t.livePagination && be(() => {
        e.livePaginationCursor = null;
      });
    },
    groupBy: (o, {
      actions: e,
      state: t
    }) => {
      let n = t.getDataSourceMasterContextRef.current,
        r = vt(t, {
          groupBy: o,
          livePaginationCursor: null
        }, n());
      e.dataParams = r, t.livePagination && be(() => {
        e.livePaginationCursor = null;
      });
    },
    pivotBy: (o, {
      actions: e,
      state: t
    }) => {
      let n = t.getDataSourceMasterContextRef.current,
        r = vt(t, {
          pivotBy: o,
          livePaginationCursor: null
        }, n());
      e.dataParams = r, t.livePagination && be(() => {
        e.livePaginationCursor = null;
      });
    },
    filterValue: (o, {
      actions: e,
      state: t
    }) => {
      let n = t.getDataSourceMasterContextRef.current,
        r = vt(t, {
          filterValue: o,
          livePaginationCursor: null
        }, n());
      e.dataParams = r, t.livePagination && be(() => {
        e.livePaginationCursor = null;
      });
    },
    cursorId: (o, {
      actions: e,
      state: t
    }) => {
      let n = t.getDataSourceMasterContextRef.current,
        r = vt(t, {
          __cursorId: o
        }, n());
      e.dataParams = r;
    },
    livePaginationCursor: (o, {
      actions: e,
      state: t
    }) => {
      let n = t.getDataSourceMasterContextRef.current,
        r = vt(t, {
          livePaginationCursor: o
        }, n());
      e.dataParams = r;
    },
    dataParams: (o, {
      state: e
    }) => zt(o, e.dataParams, new Set(["changes", "originalDataArray", "masterRowInfo"])) ? !1 : (_h("onDataParamsChange triggered because the following values have changed", o == null ? void 0 : o.changes), !0)
  };
}
function ad(o) {
  return {
    originalDataArray: o.originalDataArray,
    groupRowsState: o.groupRowsState,
    groupBy: o.groupBy,
    pivotBy: o.pivotBy,
    sortInfo: o.sortInfo,
    filterValue: o.filterValue,
    livePaginationCursor: o.livePaginationCursor
  };
}
var aa = class {
    constructor(e, t) {
      this.keysInOrder = [];
      if (typeof e == "number") this.maxSize = e, this.currentMap = new Map();else if (this.maxSize = t != null ? t : aa.DEFAULT_SIZE, e) {
        let n = Array.from(e);
        this.maxSize < n.length && n.slice(n.length - this.maxSize), this.currentMap = new Map(), n.forEach(([r, i]) => {
          this.set(r, i);
        });
      } else this.currentMap = new Map();
    }
    values() {
      return this.currentMap.values();
    }
    get size() {
      return this.currentMap.size;
    }
    delete(e) {
      return this.currentMap.has(e) ? (this.currentMap.delete(e), this.keysInOrder = this.keysInOrder.filter(t => t !== e), !0) : !1;
    }
    has(e) {
      return this.currentMap.has(e);
    }
    get(e) {
      return this.currentMap.get(e);
    }
    set(e, t) {
      if (this.has(e) && this.delete(e), this.maxSize - this.currentMap.size <= 0) {
        let [r] = this.keysInOrder.splice(0, 1);
        r != null && this.currentMap.delete(r);
      }
      return this.keysInOrder.push(e), this.currentMap.set(e, t), this;
    }
  },
  po = aa;
po.DEFAULT_SIZE = 10;
var la = class {
    add(e, t) {}
    get(e) {}
    delete(e) {}
    has(e) {
      return !1;
    }
  },
  br = class {
    constructor(e) {
      this.storage = new po(e);
    }
    add(e, t) {
      this.storage.set(e, t);
    }
    get(e) {
      return this.storage.get(e);
    }
    delete(e) {
      this.storage.delete(e);
    }
    has(e) {
      return this.storage.has(e);
    }
  },
  sa = class {
    constructor() {
      this.storage = new Map();
    }
    add(e, t) {
      this.storage.set(e, t);
    }
    get(e) {
      return this.storage.get(e);
    }
    delete(e) {
      this.storage.delete(e);
    }
    has(e) {
      return this.storage.has(e);
    }
  },
  mo = class {
    constructor(e) {
      this.cacheStorage = e === !1 ? new la() : e === !0 ? new sa() : typeof e == "number" ? new br(e) : new br(5);
    }
    add(e, t) {
      this.cacheStorage.add(e, t);
    }
    get(e) {
      return this.cacheStorage.get(e);
    }
    delete(e) {
      this.cacheStorage.delete(e);
    }
    has(e) {
      return this.cacheStorage.has(e);
    }
  };
var Rr = class {
  constructor(e) {
    let t = e instanceof Object.getPrototypeOf(this).constructor ? e.getState() : e,
      n = this.getPositiveFromState(t),
      r = this.getNegativeFromState(t);
    this.update({
      negativeItems: r,
      positiveItems: n
    });
  }
  getInitialState() {
    return this.initialState;
  }
  destroy() {
    var e, t;
    (e = this.positiveMap) == null || e.clear(), (t = this.negativeMap) == null || t.clear(), delete this.positiveMap, delete this.negativeMap;
  }
  update(e) {
    let {
      positiveItems: t,
      negativeItems: n
    } = e;
    if (this.allNegative = n === !0, this.allPositive = t === !0, this.allNegative && this.allPositive) throw "Cannot have both negativeItems and positiveItems be true!";
    if (n && n !== !0) {
      this.negativeMap || (this.negativeMap = new Map()), this.negativeMap.clear();
      for (let r of n) this.negativeMap.set(r, !0);
      this.positiveMap && this.positiveMap.clear();
    }
    if (t && t !== !0) {
      this.positiveMap || (this.positiveMap = new Map()), this.positiveMap.clear();
      for (let r of t) this.positiveMap.set(r, !0);
      this.negativeMap && this.negativeMap.clear();
    }
  }
  isDefaultNegativeSelection() {
    return this.allNegative;
  }
  isDefaultPositiveSelection() {
    return this.allPositive;
  }
  areAllNegative() {
    return this.allNegative && (this.positiveMap ? this.positiveMap.size === 0 : !0);
  }
  areAllPositive() {
    return this.allPositive && (this.negativeMap ? this.negativeMap.size === 0 : !0);
  }
  makeAllNegative() {
    this.update({
      negativeItems: !0,
      positiveItems: []
    });
  }
  makeAllPositive() {
    this.update({
      positiveItems: !0,
      negativeItems: []
    });
  }
  isItemPositive(e) {
    var t, n;
    if (this.allPositive === !0) {
      if (this.allNegative === !0) throw 'Cannot have both positiveItems and negativeItems be "true"';
      return !((t = this.negativeMap) != null && t.has(e));
    }
    return !!((n = this.positiveMap) != null && n.has(e));
  }
  isItemNegative(e) {
    return !this.isItemPositive(e);
  }
  setItemValue(e, t) {
    var n;
    if (t !== this.isItemPositive(e)) {
      if (t) {
        if (this.allNegative === !0) {
          if (!this.positiveMap) throw `No positiveMap found when trying to set item ${e} be positive`;
          this.positiveMap.set(e, !0);
        } else if (this.allPositive === !0) {
          if (!this.negativeMap) throw `No negativeMap found when trying to set item ${e} be positive`;
          this.negativeMap.delete(e);
        }
      } else if (this.allPositive === !0) {
        if (!this.negativeMap) throw `No negativeMap found when trying to set item ${e} be negative`;
        this.negativeMap.set(e, !0);
      } else if (this.allNegative === !0) {
        if (!this.positiveMap) throw `No positiveMap found when trying to set item ${e} be negative`;
        (n = this.positiveMap) == null || n.delete(e);
      }
    }
  }
  makeItemNegative(e) {
    this.setItemValue(e, !1);
  }
  makeItemPositive(e) {
    this.setItemValue(e, !0);
  }
  toggleItem(e) {
    this.setItemValue(e, !this.isItemPositive(e));
  }
};
var Qe = class extends Rr {
  constructor(t) {
    super(t);
    this.isRowDetailsExpanded = t => !!this.isItemPositive(t);
  }
  getState() {
    var r, i, a, l;
    let t = this.allPositive ? !0 : [...((i = (r = this.positiveMap) == null ? void 0 : r.keys()) != null ? i : [])],
      n = this.allNegative ? !0 : [...((l = (a = this.negativeMap) == null ? void 0 : a.keys()) != null ? l : [])];
    return {
      expandedRows: t,
      collapsedRows: n
    };
  }
  getPositiveFromState(t) {
    return t.expandedRows;
  }
  getNegativeFromState(t) {
    return t.collapsedRows;
  }
  areAllCollapsed() {
    return this.areAllNegative();
  }
  areAllExpanded() {
    return this.areAllPositive();
  }
  collapseAll() {
    this.makeAllNegative();
  }
  expandAll() {
    this.makeAllPositive();
  }
  isRowDetailsCollapsed(t) {
    return !this.isRowDetailsExpanded(t);
  }
  setRowDetailsExpanded(t, n) {
    return this.setItemValue(t, n);
  }
  collapseRowDetails(t) {
    this.setRowDetailsExpanded(t, !1);
  }
  expandRowDetails(t) {
    this.setRowDetailsExpanded(t, !0);
  }
  toggleRowDetails(t) {
    this.toggleItem(t);
  }
};
var Oh = (e => (e.INIT = "INIT", e))(Oh || {});
function Vh(o) {
  let {
      children: e
    } = o,
    {
      api: t,
      componentState: n
    } = he();
  return typeof e == "function" && (e = e(n)), sd(() => {
    var r;
    (r = n.onReady) == null || r.call(n, t);
  }, []), gt.createElement(gt.Fragment, null, e);
}
var zh = bt({
  initSetupState: Qc,
  forwardProps: td,
  concludeReducer: $c,
  mapPropsToState: nd,
  onPropChange: od,
  cleanup: ed,
  interceptActions: id(),
  mappedCallbacks: rd()
});
function Gh({
  children: o,
  isDetail: e
}) {
  let t = jn(),
    n = Ne(),
    r = _e(n),
    {
      componentState: i,
      componentActions: a,
      assignState: l
    } = Ie();
  i.getDataSourceMasterContextRef.current = r;
  let s = _e(i),
    [u] = gt.useState(() => Lc({
      getState: s,
      actions: a
    })),
    c = {
      componentState: i,
      componentActions: a,
      getDataSourceMasterContext: r,
      getState: s,
      assignState: l,
      api: u
    };
  return ld(() => {
    n && n.registerDetail(c);
  }, []), ld(() => () => {
    let d = s();
    d.onCleanup(d);
  }, []), qc(), sd(() => {
    var d;
    (d = i.onDataArrayChange) == null || d.call(i, i.originalDataArray, i.originalDataArrayChangedInfo), i.onDataMutations && i.originalDataArrayChangedInfo.mutations && i.originalDataArrayChangedInfo.mutations.size && i.onDataMutations({
      primaryKeyField: typeof i.primaryKey == "string" ? i.primaryKey : void 0,
      dataArray: i.originalDataArray,
      mutations: i.originalDataArrayChangedInfo.mutations,
      timestamp: i.originalDataArrayChangedInfo.timestamp
    });
  }, [i.originalDataArrayChangedInfo]), gt.createElement(t.Provider, {
    value: c
  }, gt.createElement(Vh, {
    children: o
  }));
}
function M1(o) {
  let e = Ne(),
    t = !!e,
    n = t ? e.masterRowInfo.id : "master";
  return gt.createElement(zh, D(h({}, o), {
    key: n
  }), gt.createElement(Gh, {
    children: o.children,
    isDetail: t
  }));
}
function E1() {
  let {
    rowInfoReducerResults: o
  } = cn();
  return o;
}
function A1() {
  let o = Ne();
  if (o) return o.masterRowInfo;
}
function ua(o) {
  let {
      getComputed: e,
      getState: t,
      actions: n
    } = o,
    r = t().columnMenuVisibleForColumnId;
  if (!r) return;
  let i = e(),
    a = i.computedVisibleColumnsMap,
    l = i.computedColumnOrder;
  if (a.has(r)) {
    n.columnMenuVisibleKey = Date.now();
    return;
  }
  function s(f, m) {
    let g = l[u + f * m],
      C = a.get(g);
    if (C && C.renderMenuIcon !== !1) return g;
  }
  let u = l.indexOf(r),
    c = 0,
    d = -1,
    p;
  for (; !p && c < l.length && (c++, p = s(c, d), p || (d *= -1, p = s(c, d)), !p););
  return p && (n.columnMenuVisibleForColumnId = p), p;
}
function ca(o) {
  if (o == null) return !1;
  let e = o;
  return !0;
}
function Lh(o, e) {
  let t = new Set(o);
  return e.map(n => t.has(n) ? n : null).filter(n => n != null);
}
function pa(o, e) {
  let {
      rowIndex: t,
      colIndex: n
    } = o,
    {
      rowIndex: r,
      colIndex: i
    } = e,
    [a, l] = n > i ? [i, n] : [n, i],
    [s, u] = t > r ? [r, t] : [t, r];
  return [{
    rowIndex: s,
    colIndex: a
  }, {
    rowIndex: u,
    colIndex: l
  }];
}
var da = class {
  constructor(e) {
    this.getCellSelectionPosition = e => {
      var r, i, a;
      let t = (i = e.rowId) != null ? i : (r = An(e.rowIndex, this.getDataSourceState)) == null ? void 0 : r.id,
        n = (a = e.colId) != null ? a : this.getComputed().computedVisibleColumns[e.colIndex].id;
      return [t, n];
    };
    this.setRangeSelected = (e, t, n) => {
      let i = this.getDataSourceState().cellSelection;
      if (!i) return;
      let a = new Ee(i),
        [l, s] = this.getCellSelectionPosition(e),
        [u, c] = this.getCellSelectionPosition(t),
        d = this.getComputed().computedVisibleColumnsMap.get(s),
        p = this.getComputed().computedVisibleColumnsMap.get(c);
      if (!d || !p) return;
      let f = d.computedVisibleIndex,
        m = p.computedVisibleIndex,
        g = An(l, this.getDataSourceState),
        C = An(u, this.getDataSourceState);
      if (!g || !C) return;
      let T = g.indexInAll,
        b = C.indexInAll,
        [S, y] = pa({
          rowIndex: T,
          colIndex: f
        }, {
          rowIndex: b,
          colIndex: m
        });
      for (let w = S.rowIndex; w <= y.rowIndex; w++) for (let v = S.colIndex; v <= y.colIndex; v++) {
        let I = {
            rowIndex: w,
            colIndex: v
          },
          [R, x] = this.getCellSelectionPosition(I);
        n ? a.selectCell(R, x) : a.deselectCell(R, x);
      }
      this.dataSourceActions.cellSelection = a;
    };
    this.getCellSelectionPositionsWithFn = (e, t) => {
      let n = Cr(this.getDataSourceState),
        {
          computedVisibleColumns: r
        } = this.getComputed(),
        i = r.map(d => d.id),
        a = new Set(),
        l = [],
        s = new Set();
      n.filter(d => {
        r.forEach(p => {
          this.isCellSelected({
            rowId: d.id,
            colId: p.id
          }) && (a.has(p.id) || a.add(p.id), s.has(d.id) || (s.add(d.id), l.push(d)));
        });
      });
      let u = Lh(Array.from(a), i),
        c = l.map(d => u.map(p => this.isCellSelected({
          rowId: d.id,
          colId: p
        }) ? e(d, p) : t));
      return {
        columnIds: u,
        positions: c
      };
    };
    this.getAllCellSelectionPositions = () => this.getCellSelectionPositionsWithFn((e, t) => [e.id, t], null);
    this.mapCellSelectionPositions = (e, t) => this.getCellSelectionPositionsWithFn(e, t);
    this.deselectAll = () => {
      let t = this.getDataSourceState().cellSelection;
      if (!t) return;
      let n = new Ee(t);
      n.deselectAll(), this.dataSourceActions.cellSelection = n;
    };
    this.clear = () => {
      this.deselectAll();
    };
    this.selectAll = () => {
      let t = this.getDataSourceState().cellSelection;
      if (!t) return;
      let n = new Ee(t);
      n.selectAll(), this.dataSourceActions.cellSelection = n;
    };
    this.selectRange = (e, t) => {
      this.setRangeSelected(e, t, !0);
    };
    this.deselectRange = (e, t) => {
      this.setRangeSelected(e, t, !1);
    };
    this.isCellSelected = e => {
      var r, i;
      let [t, n] = this.getCellSelectionPosition(e);
      return (i = (r = this.getDataSourceState().cellSelection) == null ? void 0 : r.isCellSelected(t, n)) != null ? i : !1;
    };
    this.selectCell = e => {
      let t = this.getDataSourceState().cellSelection;
      if (!t) return;
      let [n, r] = this.getCellSelectionPosition(e),
        i = e.clear ? new Ee() : new Ee(t);
      i.selectCell(n, r), this.dataSourceActions.cellSelection = i;
    };
    this.deselectCell = e => {
      let t = this.getDataSourceState().cellSelection;
      if (!t) return;
      let [n, r] = this.getCellSelectionPosition(e),
        i = new Ee(t);
      i.deselectCell(n, r), this.dataSourceActions.cellSelection = i;
    };
    this.selectColumn = (e, t) => {
      t != null && t.clear && this.deselectAll();
      let n = this.getDataSourceState().cellSelection;
      if (!n) return;
      let r = new Ee(n);
      r.selectColumn(e), this.dataSourceActions.cellSelection = r;
    };
    this.deselectColumn = e => {
      let t = this.getDataSourceState().cellSelection;
      if (!t) return;
      let n = new Ee(t);
      n.deselectColumn(e), this.dataSourceActions.cellSelection = n;
    };
    this.getComputed = e.getComputed, this.getDataSourceState = e.getDataSourceState, this.dataSourceActions = e.dataSourceActions;
  }
};
function ud(o) {
  return new da(o);
}
function cd(o) {
  let {
      dataSourceApi: e,
      getState: t,
      actions: n
    } = o,
    r = {
      collapseAllDetails() {
        n.rowDetailState = new Qe({
          collapsedRows: !0,
          expandedRows: []
        });
      },
      expandAllDetails() {
        n.rowDetailState = new Qe({
          expandedRows: !0,
          collapsedRows: []
        });
      },
      isRowDetailCollapsed(i) {
        let a = t().isRowDetailExpanded;
        if (!a) return !0;
        let l = e.getRowInfoByPrimaryKey(i);
        return l ? !a(l) : !0;
      },
      isRowDetailEnabledForRow(i) {
        let {
          isRowDetailEnabled: a
        } = t();
        if (!a) return !1;
        if (typeof a == "function") {
          let l = e.getRowInfoByPrimaryKey(i);
          return l ? a(l) : !1;
        }
        return !0;
      },
      isRowDetailExpanded(i) {
        return !r.isRowDetailCollapsed(i);
      },
      collapseRowDetail(i) {
        let a = t().rowDetailState;
        if (!a) {
          console.error("Cannot collapse row details via rowDetailState, as no props.rowDetailState is provided.");
          return;
        }
        let l = new Qe(a);
        l.collapseRowDetails(i), n.rowDetailState = l;
      },
      expandRowDetail(i) {
        let a = t().rowDetailState;
        if (!a) {
          console.error("Cannot expand row details via rowDetailState, as no props.rowDetailState is provided.");
          return;
        }
        let l = new Qe(a);
        l.expandRowDetails(i), n.rowDetailState = l;
      },
      toggleRowDetail(i) {
        r.isRowDetailExpanded(i) ? r.collapseRowDetail(i) : r.expandRowDetail(i);
      }
    };
  return r;
}
function dd(o, e) {
  return o.id ? o.id === e.id : !!(o.field && o.field === e.field);
}
var ma = class {
  constructor(e) {
    this.hideFilterOperatorMenu = () => {
      this.actions.filterOperatorMenuVisibleForColumnId = null;
    };
    this.getColumnOrder = () => this.getComputed().computedColumnOrder;
    this.getComputedColumnById = e => this.getComputed().computedColumnsMap.get(e);
    this.getVisibleColumnOrder = () => {
      let e = this.getColumnOrder(),
        t = this.getComputed().computedVisibleColumnsMap;
      return e.filter(n => t.has(n));
    };
    this.persistEdit = e => He(this, null, function* () {
      var d, p;
      e = e != null ? e : {};
      let {
        editingCell: t,
        editingValueRef: n
      } = this.getState();
      if (!t) return Promise.resolve(new Error("no edit in progress"));
      yield new Promise(f => requestAnimationFrame(f));
      let r = this.getComputed().computedColumnsMap.get(t.columnId),
        i = (d = e.value) != null ? d : t.value;
      !t.active && t.waiting !== "persist" && (this.actions.editingCell = D(h({}, t), {
        active: !1,
        waiting: "persist"
      }));
      let a = D(h({}, qe(D(h({
          rowIndex: t.rowIndex,
          columnId: t.columnId
        }, this.context), {
          api: this
        }))), {
          value: i,
          initialValue: t.initialValue
        }),
        l = i;
      n.current = null, r.getValueToPersist && (l = yield r.getValueToPersist(a)), a.value = l, t.value = l;
      let s = (p = this.getState().persistEdit) != null ? p : () => {
          if (!a.column.field) return i;
          let f = this.getDataSourceState().primaryKey,
            m = a.data && typeof f == "string" ? {
              [f]: a.data[f],
              [a.column.field]: l
            } : D(h({}, a.data), {
              [a.column.field]: l
            });
          return this.context.dataSourceApi.updateData(m);
        },
        u;
      try {
        u = yield s(a);
      } catch (f) {
        u = f;
      }
      let c = u instanceof Error ? u : !0;
      return this.actions.editingCell = D(h({}, t), {
        active: !1,
        accepted: !1,
        waiting: !1,
        persisted: c
      }), Promise.resolve(c);
    });
    this.clearEditInfo = () => {
      this.actions.editingCell = null;
    };
    this.isEditInProgress = () => {
      let {
        editingCell: e
      } = this.getState();
      return e ? e.active : !1;
    };
    this.isEditorVisibleForCell = e => {
      let {
          rowIndex: t,
          columnId: n
        } = e,
        {
          dataArray: r
        } = this.getDataSourceState(),
        {
          editingCell: i
        } = this.getState(),
        a = r[t];
      return !a || !i ? !1 : i.columnId === n && i.primaryKey === a.id && !!(i.active || i.waiting);
    };
    this.confirmEdit = e => this.stopEdit({
      value: e
    });
    this.stopEdit = e => He(this, null, function* () {
      var a, l;
      let t = this.getState(),
        {
          editingCell: n,
          editingValueRef: r
        } = t;
      if (!n) return !0;
      e || (e = {});
      let i = (a = e.value) != null ? a : r.current;
      if (!e.cancel && !e.reject) {
        let {
          rowIndex: s,
          columnId: u
        } = n;
        this.actions.editingCell = D(h({}, t.editingCell), {
          value: i,
          active: !1,
          waiting: "accept"
        });
        let {
            computedColumnsMap: c
          } = this.getComputed(),
          d = c.get(u),
          p = (l = t.shouldAcceptEdit) != null ? l : d.shouldAcceptEdit,
          f = p ? p(D(h({}, qe(D(h({}, this.context), {
            api: this,
            dataSourceApi: this.context.dataSourceApi,
            columnId: u,
            rowIndex: s
          }))), {
            value: i,
            initialValue: n.initialValue
          })) : !0;
        return yield new Promise(m => requestAnimationFrame(m)), Promise.resolve(f).then(m => {
          if (m === !0) return this.actions.editingCell = D(h({}, t.editingCell), {
            active: !1,
            accepted: !0,
            persisted: !1,
            waiting: "persist",
            value: i
          }), !0;
          throw m;
        }).catch(m => (m instanceof Error || (m = new Error(`Edit rejected: ${m}`)), this.actions.editingCell = D(h({}, n), {
          value: i,
          persisted: !1,
          active: !1,
          waiting: !1,
          accepted: m
        }), {
          reject: m,
          value: i
        }));
      }
      return e.cancel && n != null && n.active ? (this.actions.editingCell = D(h({}, n), {
        active: !1,
        cancelled: !0,
        waiting: !1,
        persisted: !1
      }), {
        cancel: !0,
        value: i
      }) : e.reject && n != null && n.active ? (this.actions.editingCell = D(h({}, n), {
        value: i,
        active: !1,
        persisted: !1,
        waiting: !1,
        accepted: e.reject
      }), {
        reject: e.reject,
        value: i
      }) : !0;
    });
    this.cancelEdit = () => this.stopEdit({
      cancel: !0
    });
    this.rejectEdit = e => this.stopEdit({
      reject: e
    });
    this.isCellEditable = e => {
      let {
          rowIndex: t,
          primaryKey: n,
          columnId: r
        } = e,
        i = t != null ? t : this.context.dataSourceApi.getIndexByPrimaryKey(n),
        {
          computedColumnsMap: a
        } = this.getComputed(),
        l = a.get(r);
      if (!l || !l.computedEditable || l.groupByForColumn) return Promise.resolve(!1);
      let s = this.getDataSourceState().dataArray[i];
      if (!s || s.isGroupRow) return Promise.resolve(!1);
      if (l.computedEditable === !0) return Promise.resolve(!0);
      let u = l.computedEditable(qe(D(h({}, this.context), {
        api: this,
        columnId: r,
        rowIndex: i
      })));
      return typeof u == "boolean" ? Promise.resolve(u) : u;
    };
    this.getColumnApi = e => We(e, D(h({}, this.context), {
      api: this
    }));
    this.isColumnSortable = e => {
      var t, n;
      return (n = (t = this.getColumnApi(e)) == null ? void 0 : t.isSortable()) != null ? n : !1;
    };
    this.getCellValue = e => {
      var t, n;
      return (n = (t = this.getCellValues(e)) == null ? void 0 : t.value) != null ? n : null;
    };
    this.getCellValues = e => {
      let {
          rowIndex: t,
          primaryKey: n,
          columnId: r
        } = e,
        {
          dataSourceApi: i,
          getState: a
        } = this.context,
        {
          computedColumnsMap: l
        } = this.getComputed(),
        s = t != null ? t : i.getIndexByPrimaryKey(n),
        u = l.get(r),
        c = i.getRowInfoByIndex(s),
        d = c == null ? void 0 : c.id;
      if (!c || !u) return u || console.warn(`No column found with id: "${r}"`), c || console.warn(`No row found with index: "${s}" or primary key: "${d}"`), null;
      let p = this,
        {
          isRowDetailEnabled: f
        } = a(),
        m = !f || typeof f == "function" && !f(c) ? !1 : this.rowDetailApi.isRowDetailExpanded(c.id) ? "expanded" : "collapsed",
        g = vn({
          column: u,
          rowInfo: c,
          columnsMap: l,
          context: D(h({}, this.context), {
            api: p
          }),
          rowDetailState: m
        });
      return {
        value: g.formattedValueContext.value,
        formattedValue: g.formattedValue,
        rawValue: g.formattedValueContext.rawValue
      };
    };
    this.getVerticalRenderRange = () => {
      let e = this.getState().brain.getRenderRange();
      return {
        renderStartIndex: e.start[0],
        renderEndIndex: e.end[0]
      };
    };
    this.setColumnOrder = e => {
      this.actions.columnOrder = e;
    };
    this.collapseAllGroupRows = () => {
      let e = this.getDataSourceState(),
        t = new Xe(e.groupRowsState);
      t.collapseAll(), this.dataSourceActions.groupRowsState = t;
    };
    this.collapseGroupRow = e => this.getDataSourceState().groupRowsState.isGroupRowExpanded(e) ? (this.toggleGroupRow(e), !0) : !1;
    this.expandGroupRow = e => this.getDataSourceState().groupRowsState.isGroupRowCollapsed(e) ? (this.toggleGroupRow(e), !0) : !1;
    this.toggleGroupRow = e => {
      let t = this.getDataSourceState(),
        n = new Xe(t.groupRowsState);
      if (n.toggleGroupRow(e), this.dataSourceActions.groupRowsState = n, t.lazyLoad) {
        let r = [Kt, ...e],
          i = t.originalLazyGroupData.get(r);
        n.isGroupRowExpanded(e) ? i != null && i.cache || an(t.data, t, this.dataSourceActions, {
          groupKeys: e
        }, this.context.getDataSourceMasterContext()) : i != null && i.cache || (t.lazyLoadCacheOfLoadedBatches.getKeysStartingWith(e).forEach(l => {
          t.lazyLoadCacheOfLoadedBatches.delete(l);
        }), this.dataSourceActions.lazyLoadCacheOfLoadedBatches = le.clone(t.lazyLoadCacheOfLoadedBatches), t.originalLazyGroupData.delete(r), this.dataSourceActions.originalLazyGroupDataChangeDetect = it());
      }
    };
    this.toggleSortingForColumn = (e, t) => {
      if (!this.getComputed().computedColumnsMap.get(e)) return;
      let r = this.getSortingForColumn(e);
      r === null ? r = 1 : r = r === 1 ? -1 : null, this.setSortingForColumn(e, r, t);
    };
    this.getState = () => this.context.getState();
    this.getComputed = () => this.context.getComputed();
    this.getDataSourceState = () => this.context.getDataSourceState();
    this.scrollCellIntoView = (e, t, n = {
      offset: 0
    }) => {
      let r = this.getState(),
        i = this.getComputed(),
        a = t;
      if (typeof t == "string") {
        let g = i.computedVisibleColumnsMap.get(t);
        if (!g) return !1;
        a = g.computedVisibleIndex;
      }
      let l = r.renderer.getScrollPositionForScrollColumnIntoView(a, n),
        s = r.renderer.getScrollPositionForScrollRowIntoView(e, n);
      if (!l || !s) return !1;
      let u = {
          scrollLeft: l.scrollLeft,
          scrollTop: s.scrollTop
        },
        c = r.brain.getScrollPosition(),
        d = r.brain.scrollLeftMax,
        p = r.brain.scrollTopMax;
      if (d < 0 && p < 0) return !0;
      let f = u.scrollLeft > d + (n.offset || 0),
        m = u.scrollTop > p + (n.offset || 0);
      return f && m ? !1 : (u.scrollLeft !== c.scrollLeft && !f && (r.scrollerDOMRef.current.scrollLeft = u.scrollLeft), u.scrollTop !== c.scrollTop && !m && (r.scrollerDOMRef.current.scrollTop = u.scrollTop), !0);
    };
    this.context = e, this.rowSelectionApi = kc({
      dataSourceActions: e.dataSourceActions,
      getDataSourceState: e.getDataSourceState
    }), this.rowDetailApi = cd({
      getState: e.getState,
      actions: e.actions,
      dataSourceApi: e.dataSourceApi
    }), this.cellSelectionApi = ud({
      dataSourceActions: e.dataSourceActions,
      getDataSourceState: e.getDataSourceState,
      getComputed: e.getComputed
    });
  }
  get actions() {
    return this.context.actions;
  }
  get dataSourceActions() {
    return this.context.dataSourceActions;
  }
  get dataSourceApi() {
    return this.context.dataSourceApi;
  }
  focus() {
    var e;
    (e = this.getState().scrollerDOMRef.current) == null || e.focus();
  }
  hideContextMenu() {
    this.actions.contextMenuVisibleFor = null, this.actions.cellContextMenuVisibleFor = null;
  }
  realignColumnContextMenu(e) {
    let t = {
        actions: this.actions,
        getState: this.getState,
        getComputed: this.getComputed
      },
      n = this.getState().columnMenuRealignDelay;
    (!n || n < 0) && (ua(t), e == null || e()), setTimeout(() => {
      this.isDestroyed() || (ua(t), e == null || e());
    }, n);
  }
  isDestroyed() {
    return !this.getState().domRef.current;
  }
  startEdit(e) {
    return He(this, null, function* () {
      let {
          columnId: t,
          rowIndex: n,
          primaryKey: r
        } = e,
        i = n != null ? n : this.context.dataSourceApi.getIndexByPrimaryKey(r);
      return this.isCellEditable({
        rowIndex: i,
        columnId: t
      }).then(a => He(this, null, function* () {
        var l;
        if (a) {
          let s = this.getDataSourceState().dataArray,
            c = this.getComputed().computedColumnsMap.get(t),
            d = s[i],
            p = ju({
              column: c,
              rowInfo: d
            }),
            f = p;
          c.getValueToEdit && (p = yield c.getValueToEdit(qe(D(h({}, this.context), {
            rowIndex: i,
            columnId: t,
            api: this
          })))), this.actions.editingCell = {
            active: !0,
            accepted: !1,
            persisted: !1,
            columnId: t,
            value: p,
            initialValue: f,
            rowIndex: i,
            primaryKey: (l = s[i]) == null ? void 0 : l.id
          };
        }
        return a;
      }));
    });
  }
  setSortingForColumn(e, t, n) {
    let {
        computedColumnsMap: r
      } = this.getComputed(),
      i = r.get(e);
    if (!i || this.getSortingForColumn(e) === t && this.getDataSourceState().multiSort === !1) return;
    if (t === null) {
      this.setSortInfoForColumn(e, null, n);
      return;
    }
    let l = i.groupByForColumn,
      s = i.field,
      u = l ? Array.isArray(l) ? l : [l] : [],
      c = u.map(f => {
        var m;
        return f.valueGetter ? g => f.valueGetter({
          data: g,
          field: f.field
        }) : f.field ? f.field : (m = f.groupField) != null ? m : void 0;
      }).filter(ca);
    c.length && (s = c);
    let d = i.computedSortType;
    u.length && d === wn && (d = u.flatMap(m => {
      var T;
      let g = (T = m.field) != null ? T : m.groupField,
        C = g ? r.get(g) : null;
      return C ? C.computedSortType : wn;
    }));
    let p = {
      dir: t,
      id: i.id,
      field: s,
      type: d
    };
    i.valueGetter && (p.valueGetter = f => i.valueGetter({
      data: f,
      field: i.field
    })), this.setSortInfoForColumn(e, p, n);
  }
  setSortInfoForColumn(e, t, n) {
    var d, p, f;
    let r = this.getDataSourceState(),
      i = this.getComputed().computedColumnsMap.get(e);
    if (!i) return;
    if (!r.multiSort) {
      this.dataSourceApi.setSortInfo(t ? [t] : null);
      return;
    }
    let a = i.field,
      l = {
        id: e,
        field: a
      },
      s = (p = (d = r.sortInfo) == null ? void 0 : d.slice()) != null ? p : [];
    if (t === null) {
      s = s.filter(m => !dd(m, l)), this.dataSourceApi.setSortInfo(s);
      return;
    }
    let u = !1;
    if (((f = n == null ? void 0 : n.multiSortBehavior) != null ? f : this.getState().multiSortBehavior) === "replace") {
      this.dataSourceApi.setSortInfo([t]);
      return;
    }
    s = s.map(m => u ? m : dd(m, l) ? (u = !0, t) : m), u || s.push(t), this.dataSourceApi.setSortInfo(s);
  }
  getSortTypeForColumn(e) {
    let t = this.getComputed().computedColumnsMap.get(e);
    return t ? t.computedSortType : null;
  }
  getSortInfoForColumn(e) {
    let t = this.getComputed().computedColumnsMap.get(e);
    return t ? t.computedSortInfo : null;
  }
  getSortingForColumn(e) {
    let t = this.getSortInfoForColumn(e);
    return t ? t.dir : null;
  }
  setPinningForColumn(e, t) {
    let n = h({}, this.getState().columnPinning);
    t === !1 ? delete n[e] : n[e] = t, this.actions.columnPinning = n;
  }
  setColumnFilter(e, t) {
    let n = this.getComputed().computedColumnsMap.get(e);
    if (!n) return;
    let r = this.getDataSourceState(),
      {
        filterTypes: i
      } = r,
      a;
    if (n.computedFilterValue) a = D(h({}, n.computedFilterValue), {
      filter: h({}, n.computedFilterValue.filter)
    });else {
      let l = n.computedFilterType;
      if (!i[l]) return;
      let s = {
        filter: {
          type: l,
          value: t,
          operator: i[l].defaultOperator
        },
        valueGetter: n.valueGetter
      };
      (n.field || typeof n.groupByForColumn == "string") && (s.field = n.field || n.groupByForColumn), a = s;
    }
    a.id = n.id, a.filter.value = t, this.setFilterValueForColumn(e, a);
  }
  setColumnFilterOperator(e, t) {
    var d;
    let n = this.getComputed().computedColumnsMap.get(e);
    if (!n) return;
    let r = this.getDataSourceState(),
      {
        filterTypes: i
      } = r,
      a = n.computedFilterType,
      l = i[a].operators,
      s = (d = l.find(p => p.name === t)) != null ? d : l.find(p => p.name === i[a].defaultOperator),
      u = s.name,
      c;
    n.computedFilterValue ? (c = D(h({}, n.computedFilterValue), {
      filter: D(h({}, n.computedFilterValue.filter), {
        operator: u
      })
    }), s.defaultFilterValue !== void 0 && (c.filter.value = s.defaultFilterValue)) : (c = {
      filter: {
        operator: u,
        type: a,
        value: s.defaultFilterValue !== void 0 ? s.defaultFilterValue : [...i[a].emptyValues.values()][0]
      },
      valueGetter: n.valueGetter
    }, n.field && (c.field = n.field)), c.id = n.id, this.setFilterValueForColumn(e, c);
  }
  setFilterValueForColumn(e, t) {
    var l;
    let n = this.getComputed().computedColumnsMap.get(e);
    if (!n) return;
    let i = (l = this.getDataSourceState().filterValue) != null ? l : [],
      a = !1;
    i = i.map(s => s === n.computedFilterValue ? (a = !0, t) : s), a || i.push(t), this.dataSourceActions.filterValue = i;
  }
  removeColumnFilter(e) {
    var a;
    let t = this.getComputed().computedColumnsMap.get(e);
    if (!t) return;
    let r = (a = this.getDataSourceState().filterValue) != null ? a : [],
      i = !1;
    r = r.filter(l => l === t.computedFilterValue ? (i = !0, !1) : !0), i && (this.dataSourceActions.filterValue = r);
  }
  clearColumnFilter(e) {
    var a;
    let t = this.getComputed().computedColumnsMap.get(e);
    if (!t) return;
    let n = this.getDataSourceState(),
      r = (a = n.filterValue) != null ? a : [],
      i = !1;
    r = r.map(l => {
      var s, u, c;
      if (l === t.computedFilterValue) {
        let d = t.computedFilterValue.filter.type,
          p = n.operatorsByFilterType[d],
          f = n.filterTypes[d];
        if (i = !0, !f) return null;
        let m = (s = l.filter.operator) != null ? s : f == null ? void 0 : f.defaultOperator,
          C = (c = (u = p[m].defaultFilterValue) != null ? u : (f.emptyValues || [])[0]) != null ? c : void 0;
        return D(h({}, l), {
          filter: D(h({}, l.filter), {
            operator: m,
            value: C
          })
        });
      }
      return l;
    }).filter(ca), i && (this.dataSourceActions.filterValue = r);
  }
  setVisibilityForColumn(e, t) {
    let n = h({}, this.getState().columnVisibility);
    t ? delete n[e] : n[e] = !1, this.actions.columnVisibility = n;
  }
  getVisibleColumnsCount() {
    return this.getComputed().computedVisibleColumns.length;
  }
  setColumnVisibility(e) {
    this.actions.columnVisibility = e;
  }
  get scrollLeft() {
    return this.getState().brain.getScrollPosition().scrollLeft;
  }
  set scrollLeft(e) {
    let t = this.getState();
    t.scrollerDOMRef.current.scrollLeft = Math.max(e, 0);
  }
  get scrollTop() {
    return this.getState().brain.getScrollPosition().scrollTop;
  }
  set scrollTop(e) {
    let t = this.getState();
    t.scrollerDOMRef.current.scrollTop = Math.max(e, 0);
  }
  scrollRowIntoView(e, t = {
    offset: 0
  }) {
    let n = this.getState(),
      r = n.renderer.getScrollPositionForScrollRowIntoView(e, t);
    if (!r) return !1;
    let i = n.brain.getScrollPosition(),
      a = n.brain.scrollTopMax;
    return r.scrollTop > a + (t.offset || 0) ? !1 : (r.scrollTop !== i.scrollTop && (n.scrollerDOMRef.current.scrollTop = r.scrollTop), !0);
  }
  scrollColumnIntoView(e, t = {
    offset: 0
  }) {
    let n = this.getState(),
      i = this.getComputed().computedVisibleColumnsMap.get(e);
    if (!i) return !1;
    let a = i.computedVisibleIndex,
      l = n.renderer.getScrollPositionForScrollColumnIntoView(a, t);
    if (!l) return !1;
    let s = n.brain.getScrollPosition(),
      u = n.brain.scrollLeftMax;
    return l.scrollLeft > u + (t.offset || 0) ? !1 : (l.scrollLeft !== s.scrollLeft && (n.scrollerDOMRef.current.scrollLeft = l.scrollLeft), !0);
  }
};
function pd(o) {
  return new ma(o);
}
import { useEffect as md, useRef as Hh, useState as Nh } from "react";
var Kh = 10;
function Bh(o, e) {
  var u;
  let {
      includeHeader: t,
      columnsToResize: n,
      columnsToSkip: r
    } = e,
    i = `.${tn} > .${zi}`,
    a = (u = o.current) == null ? void 0 : u.querySelectorAll(i),
    l = {},
    s = {};
  return a && a.length && a.forEach(c => {
    let d = c.parentElement,
      p = d.matches(`.${Mn}`);
    if (t === !1 && p) return;
    let f = d.getAttribute("data-column-id"),
      m = c.cloneNode(!0);
    m.style.visibility = "hidden", m.style.pointerEvents = "none", m.classList.remove(...mt.split(" ")), m.style.position = "absolute", m.style.width = "auto", d.appendChild(m);
    let g = m.offsetWidth;
    if (d.removeChild(m), !s[f]) {
      let C = ve().getComputedStyle(d);
      s[f] = parseInt(C.paddingLeft, 10) + parseInt(C.paddingRight, 10) + parseInt(C.borderLeftWidth, 10) + parseInt(C.borderRightWidth, 10) + Kh;
    }
    if (g += s[f], p) {
      let C = 0;
      d.childNodes.forEach(T => {
        if (T !== c) {
          let b = T,
            S = Math.max(b.offsetWidth, parseInt(b.style.width, 10));
          if (isNaN(S) && (S = 0), C += S, S > 0) {
            let y = ve().getComputedStyle(b);
            C += parseInt(y.marginLeft, 10) + parseInt(y.marginRight, 10);
          }
        }
      }), g += C;
    }
    r && r.has(f) || n && !n.has(f) || (l[f] = Math.max(g, l[f] || 0));
  }), l;
}
function fd() {
  let {
      getComponentState: o,
      componentActions: e,
      componentState: {
        domRef: t,
        ready: n,
        autoSizeColumnsKey: r,
        brain: i
      }
    } = Ie(),
    [a, l] = Nh(0),
    s = typeof r == "object" ? r.key : r,
    u = _e(s),
    c = Hh(null);
  md(() => {
    if (u() == null) return;
    let {
        current: p
      } = c,
      f = () => {
        r !== p && l(a + 1);
      };
    return i.onRenderRangeChange(f);
  }, [i]), md(() => {
    var y;
    if (s == null) return;
    let {
        autoSizeColumnsKey: d
      } = o(),
      p,
      f,
      m = !0;
    typeof d == "object" && (d.columnsToResize && (p = new Set(d.columnsToResize)), d.columnsToSkip && (f = new Set(d.columnsToSkip)), m = (y = d.includeHeader) != null ? y : m), c.current = s;
    let g = Bh(t, {
        includeHeader: m,
        columnsToResize: p,
        columnsToSkip: f
      }),
      C = Object.keys(g);
    if (!C.length) return;
    let T = o().columnSizing,
      b = h({}, T),
      S = !1;
    C.forEach(w => {
      var v;
      b[w] = {
        width: g[w]
      }, S = S || b[w].width !== ((v = T[w]) == null ? void 0 : v.width);
    }), S && (e.columnSizing = b);
  }, [s, n, a]);
}
import { useMemo as Xh } from "react";
import { useCallback as Zh, useEffect as go, useRef as Jh } from "react";
import * as wr from "react";
function gd(o) {
  let {
    dataArray: e,
    brain: t,
    computedPinnedEndColumns: n,
    computedPinnedStartColumns: r,
    computedVisibleColumns: i,
    computedRowHeight: a,
    columnSize: l,
    rowspan: s
  } = o;
  return ko(t, {
    colWidth: l,
    rowHeight: a,
    rows: e.length,
    cols: i.length,
    rowspan: s
  }, {
    fixedColsEnd: n.length,
    fixedColsStart: r.length
  }), t;
}
import * as fo from "react";
var hd = Se({
  defaultClassName: "evqes20",
  variantClassNames: {},
  defaultVariants: {},
  compoundVariants: []
});
import { useMemo as Cd, useRef as kh } from "react";
function Wh(o, e) {
  let {
      masterState: t,
      detailContext: n
    } = e,
    r = t.detailDataSourcesStateToRestore;
  if (r.has(o)) {
    let i = r.get(o);
    return n.assignState(i), !0;
  }
  return !1;
}
function $h(o, e) {
  let {
      detailState: t,
      masterState: n,
      masterActions: r,
      cacheEntryForRow: i
    } = e,
    a = new Map(n.detailDataSourcesStateToRestore),
    l = ad(t);
  i.all || (i.groupBy || delete l.groupBy, i.sortInfo || delete l.sortInfo, i.filterValue || delete l.filterValue, i.data || delete l.originalDataArray), a.set(o, l), r.detailDataSourcesStateToRestore = a;
}
function jh(o, e) {
  let t = kh(!1);
  return {
    currentRowCache: Cd(() => ({
      getRowId: () => o,
      add: r => {
        t.current = !0, e.add(o, r);
      },
      get: () => e.get(o),
      delete: () => (t.current = !0, e.delete(o)),
      has: () => e.has(o)
    }), [e, o]),
    cacheCalledByRowDetailRenderer: t
  };
}
function Sd(o) {
  let {
      rowDetailsCache: e,
      rowInfo: t
    } = o,
    {
      getState: n,
      componentActions: r
    } = he(),
    {
      getState: i
    } = ae(),
    {
      currentRowCache: a,
      cacheCalledByRowDetailRenderer: l
    } = jh(t.id, e),
    s = Cd(() => {
      let u = n().detailDataSourcesStateToRestore.has(t.id) && a.has();
      return {
        registerDetail: Ft(d => {
          if (l.current || a.add({
            all: !0
          }), !a.has()) {
            requestAnimationFrame(() => {
              d.componentState.destroyedRef.current || (d.componentActions.stateReadyAsDetails = !0);
            });
            return;
          }
          Wh(t.id, {
            masterState: n(),
            detailContext: d
          }), requestAnimationFrame(() => {
            d.componentState.destroyedRef.current || (d.componentActions.stateReadyAsDetails = !0);
          }), d.componentState.onCleanup.onChange(() => {
            let p = a.get();
            p && $h(t.id, {
              detailState: d.getState(),
              masterActions: r,
              masterState: n(),
              cacheEntryForRow: p
            });
          });
        }),
        shouldRestoreState: u
      };
    }, [t.id, a]);
  return s.masterRowInfo = t, s.getMasterDataSourceState = n, s.getMasterState = i, {
    masterDetailContextValue: s,
    currentRowCache: a
  };
}
var {
    rootClassName: Uh
  } = Re,
  Yh = `${Uh}RowDetail`;
function qh(o) {
  let e = Do(),
    {
      domRef: t,
      rowDetailRenderer: n,
      rowDetailHeight: r,
      rowInfo: i,
      detailOffset: a,
      rowDetailsCache: l
    } = o,
    {
      masterDetailContextValue: s,
      currentRowCache: u
    } = Sd({
      rowDetailsCache: l,
      rowInfo: i
    });
  return fo.createElement(e.Provider, {
    value: s
  }, fo.createElement("div", {
    ref: t,
    className: te(Yh, hd({})),
    style: {
      position: "absolute",
      top: `${a}px`,
      left: 0,
      width: `calc(${$.bodyWidth} - ${$.scrollbarWidthVertical})`,
      height: r
    }
  }, n(i, u)));
}
var yd = fo.memo(qh);
var Qh = 1;
function Td(o) {
  let {
      computed: e,
      bodySize: t,
      imperativeApi: n
    } = o,
    {
      actions: r,
      state: i,
      getState: a
    } = ae(),
    {
      computedPinnedStartColumns: l,
      computedPinnedEndColumns: s,
      computedVisibleColumns: u,
      computedColumnsMap: c,
      computedRowHeight: d,
      computedRowSizeCacheForDetails: p,
      fieldsToColumn: f,
      rowspan: m,
      toggleGroupRow: g,
      columnSize: C
    } = e,
    {
      componentState: T,
      getState: b,
      componentActions: S,
      api: y
    } = he(),
    {
      dataArray: w
    } = T,
    v = _e(w),
    {
      rowHeight: I,
      rowDetailHeight: R,
      groupRenderStrategy: x,
      brain: E,
      showZebraRows: M,
      rowDetailRenderer: V,
      isRowDetailExpanded: z,
      isRowDetailEnabled: P,
      cellClassName: F,
      cellStyle: A,
      rowStyle: O,
      rowDetailCache: B,
      rowClassName: k,
      onScrollToTop: J,
      onScrollToBottom: oe,
      scrollToBottomOffset: q,
      ready: Q
    } = i,
    L = T.updatedAt;
  gd({
    columnSize: C,
    brain: E,
    computedPinnedStartColumns: l,
    computedPinnedEndColumns: s,
    computedVisibleColumns: u,
    computedRowHeight: d,
    dataArray: w,
    bodySize: t,
    rowspan: m
  });
  let N = Jh(0);
  go(() => E.onRenderCountChange(() => {
    N.current = E.scrollTopMax;
  }), [E]), go(() => E.onScrollStop(G => {
    let {
      scrollTop: W
    } = G;
    W === 0 && (J == null || J());
    let X = q != null ? q : Qh;
    if (W + X >= N.current) {
      oe == null || oe();
      let {
        livePagination: ee,
        livePaginationCursor: pe,
        dataArray: ue
      } = b();
      ee && (S.cursorId = pe !== void 0 ? Date.now() : ue.length);
    }
  }), [E, J, oe]), go(() => {
    t.height && (r.ready = !0);
  }, [!!t.height]), go(() => {
    let {
      onReady: G
    } = a();
    G && G({
      api: n,
      dataSourceApi: y
    });
  }, [Q]);
  let [, j] = Ze();
  go(() => {
    j();
  }, [T]);
  let _ = Zh(G => {
      let {
          rowIndex: W,
          colIndex: X,
          heightWithRowspan: Y,
          domRef: ee,
          hidden: pe,
          width: ue,
          onMouseLeave: Ae,
          onMouseEnter: ze
        } = G,
        fe = v()[W],
        ye = u[X];
      if (!fe) return null;
      let Ke = p ? p.getRowHeight(W) : Y,
        se = !1;
      return !P || typeof P == "function" && !P(fe) ? se = !1 : z && (se = z(fe) ? "expanded" : "collapsed"), wr.createElement(Ju, h({}, {
        getData: v,
        virtualized: !0,
        showZebraRows: M,
        groupRenderStrategy: x,
        rowIndex: W,
        rowInfo: fe,
        hidden: pe,
        toggleGroupRow: g,
        rowHeight: Ke,
        rowDetailState: se,
        onMouseEnter: ze,
        onMouseLeave: Ae,
        domRef: ee,
        width: ue,
        column: ye,
        columnsMap: c,
        fieldsToColumn: f,
        rowStyle: O,
        rowClassName: k,
        cellStyle: A,
        cellClassName: F
      }));
    }, [I, R, p, d, z, v, u, c, f, x, g, M, L, O, k, F, A]),
    H = Xh(() => {
      if (z) return G => {
        let {
            rowIndex: W,
            domRef: X
          } = G,
          ee = v()[W];
        if (!ee || !z(ee) || !p) return wr.createElement("div", {
          ref: X,
          className: Ss.hidden
        });
        let {
          rowDetailHeight: pe,
          rowHeight: ue
        } = p.getSize(W);
        return wr.createElement(yd, {
          rowInfo: ee,
          rowDetailsCache: B,
          rowIndex: W,
          domRef: X,
          detailOffset: ue,
          rowDetailHeight: pe,
          rowDetailRenderer: V
        });
      };
    }, [Q, _, V, R, z, B]);
  return {
    renderCell: _,
    renderDetailRow: H
  };
}
import { useEffect as bC, useState as Sa } from "react";
var Ir = class {
  constructor(e) {
    this.multiSelectStartPosition = {
      rowIndex: 0,
      colIndex: 0
    };
    this.getPrimaryKeyByIndex = e.getPrimaryKeyByIndex, this.getColumnIdByIndex = e.getColumnIdByIndex;
  }
  getCellSelectionPosition(e) {
    let {
        rowIndex: t,
        colIndex: n
      } = e,
      r = this.getPrimaryKeyByIndex(t),
      i = this.getColumnIdByIndex(n);
    if (!(r === void 0 || !i)) return [r, i];
  }
  set cellSelectionState(e) {
    this._cellSelectionState = e;
  }
  get cellSelectionState() {
    return this._cellSelectionState;
  }
  resetClick(e) {
    this.cellSelectionState.deselectAll();
    let t = this.getCellSelectionPosition(e);
    t && (this.cellSelectionState.deselectAll(), this.cellSelectionState.selectCell(...t), this.multiSelectStartPosition = e, this.multiSelectEndPosition = void 0);
  }
  singleAddClick(e) {
    let t = this.getCellSelectionPosition(e);
    if (t) if (this.cellSelectionState.isCellSelected(...t)) {
      this.cellSelectionState.deselectCell(...t);
      let n = {
        rowIndex: e.rowIndex + 1,
        colIndex: e.colIndex
      };
      this.getCellSelectionPosition(n) && (this.multiSelectStartPosition = n);
    } else this.cellSelectionState.selectCell(...t), this.multiSelectStartPosition = e, this.multiSelectEndPosition = void 0;
  }
  multiSelectClick(e) {
    !this.multiSelectStartPosition || !this.getCellSelectionPosition(e) || (this.multiSelectEndPosition && this.deselectRange(this.multiSelectStartPosition, this.multiSelectEndPosition), this.selectRange(this.multiSelectStartPosition, e), this.multiSelectEndPosition = e);
  }
  setRangeSelected(e, t, n) {
    let [r, i] = pa(e, t),
      a = this.getCellSelectionPosition(r),
      l = this.getCellSelectionPosition(i);
    if (!(!a || !l)) for (let s = r.rowIndex; s <= i.rowIndex; s++) for (let u = r.colIndex; u <= i.colIndex; u++) {
      let c = this.getCellSelectionPosition({
        rowIndex: s,
        colIndex: u
      });
      c && (n ? this.cellSelectionState.selectCell(...c) : this.cellSelectionState.deselectCell(...c));
    }
  }
  deselectRange(e, t) {
    this.setRangeSelected(e, t, !1);
  }
  selectRange(e, t) {
    this.setRangeSelected(e, t, !0);
  }
};
function bd(o, e) {
  return o < e ? [o, e] : [e, o];
}
var xr = class {
  constructor(e) {
    this.multiSelectStartIndex = 0;
    this.getIdForIndex = e.getIdForIndex;
  }
  set rowSelectionState(e) {
    this._rowSelectionState = e;
  }
  get rowSelectionState() {
    return this._rowSelectionState;
  }
  selectRange(e, t) {
    let [n, r] = bd(e, t),
      i = this.rowSelectionState;
    for (let a = n; a <= r; a++) {
      let l = this.getIdForIndex(a);
      i.selectRow(l);
    }
  }
  deselectRange(e, t) {
    let [n, r] = bd(e, t),
      i = this.rowSelectionState;
    for (let a = n; a <= r; a++) {
      let l = this.getIdForIndex(a);
      i.deselectRow(l);
    }
  }
  resetClick(e) {
    this.rowSelectionState.deselectAll();
    let t = this.getIdForIndex(e);
    this.rowSelectionState.selectRow(t), this.multiSelectStartIndex = e, this.multiSelectEndIndex = void 0;
  }
  singleAddClick(e) {
    let t = this.getIdForIndex(e);
    if (this.rowSelectionState.isRowSelected(t)) this.rowSelectionState.deselectRow(t), this.rowSelectionState.isRowSelected(this.getIdForIndex(e + 1)) && (this.multiSelectStartIndex = e + 1);else {
      this.rowSelectionState.selectRow(t), this.multiSelectStartIndex = e;
      let n;
      for (let r = e - 1; r >= 0; r--) {
        let i = this.getIdForIndex(r);
        if (this.rowSelectionState.isRowSelected(i)) n = r;else break;
      }
      this.multiSelectEndIndex = n;
    }
  }
  multiSelectClick(e) {
    this.multiSelectEndIndex != null && this.deselectRange(this.multiSelectStartIndex, this.multiSelectEndIndex), this.selectRange(this.multiSelectStartIndex, e), this.multiSelectEndIndex = e;
  }
};
import { useEffect as Rd } from "react";
import { useEffect as i_ } from "react";
function Pt(o, e) {
  let {
    set: t,
    delete: n,
    clear: r
  } = o;
  return e.set && (o.set = (i, a) => (t.call(o, i, a), e.set(i, a), o)), e.delete && (o.delete = i => {
    let a = n.call(o, i);
    return e.delete(i), a;
  }), (e.clear || e.beforeClear) && (o.clear = () => {
    e.beforeClear && e.beforeClear(o);
    let i = r.call(o);
    return e.clear && e.clear(), i;
  }), () => {
    t && (o.set = t), n && (o.delete = n), r && (o.clear = r);
  };
}
function vr(o) {
  let e = new Map();
  return o.forEach((t, n) => {
    let r = t.columnGroup,
      i = 0;
    for (; r;) {
      let a = o.get(r);
      if (!a) break;
      i++, r = a.columnGroup;
    }
    e.set(n, i);
  }), e;
}
function wd() {
  let {
    componentState: {
      computedColumnGroups: o,
      collapsedColumnGroups: e
    },
    componentActions: t,
    getComponentState: n
  } = Ie();
  Rd(() => {
    let i = Jt(() => {
      t.columnGroupsDepthsMap = vr(o);
    });
    return Pt(o, {
      clear: i,
      delete: i,
      set: i
    });
  }, [o]), Rd(() => Pt(e, {
    set: (r, i) => {
      let a = r.slice(1),
        l = h({}, n().columnVisibility);
      a.forEach(s => {
        l[s] = !1;
      }), delete l[i], t.columnVisibility = l;
    },
    beforeClear: r => {
      let i = h({}, n().columnVisibility);
      [...r.keys()].forEach(l => {
        l.slice(1).forEach(u => {
          delete i[u];
        });
      }), t.columnVisibility = i;
    },
    delete: r => {
      let i = h({}, n().columnVisibility);
      r.slice(1).forEach(l => {
        delete i[l];
      }), t.columnVisibility = i;
    }
  }), [e]);
}
import { useMemo as eC } from "react";
function Id(o) {
  let {
    getState: e
  } = he();
  return eC(() => o.filter(r => typeof r.rowspan == "function").length ? ({
    rowIndex: r,
    colIndex: i
  }) => {
    let a = e().dataArray,
      l = a[r];
    if (!l) return 1;
    let s = l.data,
      u = o[i];
    return !u || !u.rowspan ? 1 : u.rowspan({
      column: u,
      data: s,
      dataArray: a,
      rowInfo: l,
      rowIndex: r
    });
  } : void 0, [o]);
}
import { useCallback as tC } from "react";
var T_ = nt("useColumnSizeFn");
function xd(o) {
  return tC(t => {
    let n = o[t];
    return n ? n.computedWidth : 0;
  }, [o]);
}
import { useEffect as zn, useLayoutEffect as aC, useMemo as lC } from "react";
import { createRef as ln } from "react";
var nC = {
    scrollLeft: 0,
    scrollTop: 0
  },
  ho = class {
    constructor() {
      this.scrollPosition = nC;
      this.onScrollFns = [];
      this.getScrollPosition = () => this.scrollPosition;
      this.onScroll = e => (this.onScrollFns.push(e), () => {
        this.onScrollFns = this.onScrollFns.filter(t => t !== e);
      });
      this.setScrollPosition = e => {
        this.scrollPosition = e, this.notifyScrollChange();
      };
      this.destroy = () => {
        this.onScrollFns = [];
      };
    }
    notifyScrollChange() {
      let {
          scrollPosition: e
        } = this,
        t = this.onScrollFns;
      for (let n = 0, r = t.length; n < r; n++) t[n](e);
    }
  };
function fa(o, e) {
  if (!o) return new Map();
  if (o instanceof Map) return o;
  if (e && e.has(o)) return e.get(o);
  let t = new Map(Object.entries(o));
  return e && e.set(o, t), t;
}
import * as vd from "react";
function Pd(o) {
  return (e, t) => vd.createElement(o, {
    key: e.id,
    rowInfo: e,
    cache: t
  });
}
function oC(o) {
  let e = new gn(o),
    t = xe();
  return o.onDestroy(() => {
    e.destroy(), t.destroy();
  }), {
    renderer: e,
    onRenderUpdater: t
  };
}
function ga(o) {
  return `.${en}[data-row-index${o ? `="${o.rowIndex}"` : ""}][data-col-index${o ? `="${o.colIndex}"` : ""}]`;
}
function Dd({
  debugId: o
}) {
  let e = new Map(),
    t = new Xn(o),
    n = new Xn("header");
  t.onScroll(l => {
    n.setScrollPosition({
      scrollLeft: l.scrollLeft,
      scrollTop: 0
    });
  });
  let {
    renderer: r,
    onRenderUpdater: i
  } = oC(t);
  t.onAvailableSizeChange(l => {
    n.setAvailableSize({
      width: l.width
    });
  });
  let a = ln();
  return {
    renderer: r,
    onRenderUpdater: i,
    propsCache: new Map([]),
    cellContextMenuVisibleFor: null,
    contextMenuVisibleFor: null,
    columnMenuVisibleForColumnId: null,
    columnMenuVisibleKey: 0,
    filterOperatorMenuVisibleForColumnId: null,
    getDOMNodeForCell: l => {
      if (!a.current) return null;
      let s = ga(l);
      return a.current.querySelector(s) || null;
    },
    brain: t,
    headerBrain: n,
    domRef: a,
    columnMenuTargetRef: ln(),
    editingValueRef: ln(),
    scrollerDOMRef: ln(),
    portalDOMRef: ln(),
    focusDetectDOMRef: ln(),
    activeCellIndicatorDOMRef: ln(),
    onColumnMenuClick: xe(),
    cellContextMenu: xe(),
    contextMenu: xe(),
    onFilterOperatorMenuClick: xe(),
    onRowHeightCSSVarChange: xe(),
    onRowDetailHeightCSSVarChange: xe(),
    onColumnHeaderHeightCSSVarChange: xe(),
    cellClick: xe(),
    cellMouseDown: xe(),
    keyDown: xe(),
    bodySize: {
      width: 0,
      height: 0
    },
    scrollPosition: {
      scrollTop: 0,
      scrollLeft: 0
    },
    columnReorderDragColumnId: !1,
    ready: !1,
    focused: !1,
    focusedWithin: !1,
    columnsWhenGrouping: e,
    columnVisibilityForGrouping: {},
    pinnedStartScrollListener: new ho(),
    pinnedEndScrollListener: new ho(),
    columnsWhenInlineGroupRenderStrategy: void 0,
    editingCell: null
  };
}
var Md = o => ({
  debugId: 1,
  scrollTopKey: 1,
  components: 1,
  id: 1,
  loadingText: 1,
  pivotColumns: 1,
  groupColumn: 1,
  onReady: 1,
  domProps: 1,
  onKeyDown: 1,
  onCellClick: 1,
  focusedClassName: 1,
  focusedWithinClassName: 1,
  focusedStyle: 1,
  focusedWithinStyle: 1,
  onSelfFocus: 1,
  onFocusWithin: 1,
  onSelfBlur: 1,
  onBlurWithin: 1,
  onContextMenu: 1,
  onCellContextMenu: 1,
  onScrollToTop: 1,
  onScrollToBottom: 1,
  onScrollStop: 1,
  scrollToBottomOffset: 1,
  getColumnMenuItems: 1,
  getCellContextMenuItems: 1,
  getContextMenuItems: 1,
  columnPinning: 1,
  editable: 1,
  columnDefaultEditable: 1,
  columnDefaultFilterable: 1,
  columnDefaultSortable: 1,
  rowStyle: 1,
  cellStyle: 1,
  rowProps: 1,
  rowClassName: 1,
  cellClassName: 1,
  pinnedStartMaxWidth: 1,
  pinnedEndMaxWidth: 1,
  pivotColumn: 1,
  pivotColumnGroups: 1,
  getFilterOperatorMenuItems: 1,
  onScrollbarsChange: 1,
  autoSizeColumnsKey: 1,
  columnDefaultFlex: 1,
  columnMenuRealignDelay: e => e != null ? e : 50,
  scrollStopDelay: e => e != null ? e : 250,
  viewportReservedWidth: e => e != null ? e : 0,
  resizableColumns: e => e != null ? e : !0,
  rowDetailCache: e => new mo(e),
  hideColumnWhenGrouped: e => e != null ? e : !1,
  columnMinWidth: e => e != null ? e : 30,
  columnMaxWidth: e => e != null ? e : 5e3,
  columnDefaultWidth: e => e != null ? e : 200,
  columnCssEllipsis: e => e != null ? e : !0,
  draggableColumns: e => e != null ? e : !0,
  headerOptions: e => h({
    alwaysReserveSpaceForSortIcon: !0
  }, e),
  showSeparatePivotColumnForSingleAggregation: e => e != null ? e : !1,
  sortable: 1,
  hideEmptyGroupColumns: e => e != null ? e : !1,
  pivotTotalColumnPosition: e => e != null ? e : "end",
  pivotGrandTotalColumnPosition: 1,
  licenseKey: e => e || "",
  keyboardSelection: e => e != null ? e : !0,
  activeRowIndex: 1,
  activeCellIndex: 1,
  columnOrder: e => e != null ? e : !0,
  header: e => e != null ? e : !0,
  showZebraRows: e => e != null ? e : !0,
  showHoverRows: e => e != null ? e : !0,
  virtualizeColumns: e => e != null ? e : !0,
  rowHeight: e => typeof e == "number" || typeof e == "function" ? e : 0,
  rowDetailHeight: e => typeof e == "number" || typeof e == "function" ? e : 300,
  columnHeaderHeight: e => typeof e == "number" ? e : 30,
  columns: e => fa(e, o.propsCache.get("columns")),
  columnVisibility: e => e != null ? e : {},
  columnSizing: e => e || {},
  columnTypes: e => e || {},
  onEditCancelled: 1,
  onEditRejected: 1,
  onEditAccepted: 1,
  persistEdit: 1,
  shouldAcceptEdit: 1,
  onEditPersistError: 1,
  onEditPersistSuccess: 1,
  multiSortBehavior: e => e != null ? e : "replace",
  collapsedColumnGroups: e => e != null ? e : new Map(),
  columnGroups: e => fa(e, o.propsCache.get("columnGroups"))
});
function rC(o) {
  let {
    groupBy: e,
    groupColumn: t,
    groupRenderStrategy: n
  } = o;
  return n || (t != null && typeof t == "object" ? "single-column" : (e.filter(i => i.column).length, "multi-column"));
}
var Ed = o => {
  o.brain.destroy(), o.headerBrain.destroy(), o.renderer.destroy(), o.onRenderUpdater.destroy(), o.onRowHeightCSSVarChange.destroy(), o.onColumnHeaderHeightCSSVarChange.destroy(), o.onColumnMenuClick.destroy(), o.onFilterOperatorMenuClick.destroy(), o.domRef.current = null, o.columnMenuTargetRef.current = null, o.scrollerDOMRef.current = null, o.portalDOMRef.current = null, o.onRowDetailHeightCSSVarChange.destroy(), o.pinnedEndScrollListener.destroy(), o.pinnedStartScrollListener.destroy();
};
function Ad(o) {
  return o.reduce((e, t, n) => {
    let r = {
      groupBy: t,
      groupIndex: n
    };
    return t.field ? e.set(t.field, r) : t.groupField && e.set(t.groupField, r), e;
  }, new Map());
}
var Pr = new WeakMap(),
  Fd = o => {
    var g, C, T, b, S;
    let {
        props: e,
        state: t,
        oldState: n,
        parentState: r
      } = o,
      i = t.pivotColumnGroups || t.columnGroups,
      a = t.columnGroups && t.columnGroups != (n == null ? void 0 : n.columnGroups) || t.pivotColumnGroups && t.pivotColumnGroups != (n == null ? void 0 : n.pivotColumnGroups) ? vr(i) : t.columnGroupsDepthsMap,
      l = r == null ? void 0 : r.groupBy,
      s = rC({
        groupRenderStrategy: e.groupRenderStrategy,
        groupBy: l,
        groupColumn: e.groupColumn
      }),
      u,
      c = e.rowDetailRenderer,
      d = (g = e.components) == null ? void 0 : g.RowDetail;
    if (!c && d) {
      let y = Pr.get(d);
      y || (y = Pd(d), Pr.set(d, y)), c = y;
    }
    c && (u = e.rowDetailState || t.rowDetailState || e.defaultRowDetailState, u && !(u instanceof Qe) && (u = new Qe(u)), u = u || new Qe({
      expandedRows: [],
      collapsedRows: !0
    }));
    let p = t.columnsWhenGrouping || t.columnsWhenInlineGroupRenderStrategy || t.columns,
      f = u ? e.isRowDetailExpanded : void 0;
    !f && u && (f = Pr.get(u), f || (f = y => u.isRowDetailsExpanded(y.id), typeof u == "object" && Pr.set(u, f)));
    let m = c ? e.isRowDetailEnabled || !0 : !1;
    return {
      rowDetailRenderer: c,
      rowDetailState: u,
      isRowDetailExpanded: f,
      isRowDetailEnabled: m,
      showColumnFilters: (C = e.showColumnFilters) != null ? C : !!r.filterValue,
      controlledColumnVisibility: !!e.columnVisibility,
      groupRenderStrategy: s,
      groupBy: l,
      computedColumns: p,
      initialColumns: e.columns,
      keyboardNavigation: (T = e.keyboardNavigation) != null ? T : t.activeCellIndex != null ? "cell" : t.activeRowIndex != null ? "row" : "cell",
      columnHeaderCssEllipsis: (S = (b = e.columnHeaderCssEllipsis) != null ? b : e.columnCssEllipsis) != null ? S : !0,
      columnGroupsDepthsMap: a,
      columnGroupsMaxDepth: a != t.columnGroupsDepthsMap ? Math.max(...a.values(), 0) : t.columnGroupsMaxDepth,
      computedColumnGroups: i,
      rowHeightCSSVar: typeof e.rowHeight == "string" ? e.rowHeight : "",
      rowDetailHeightCSSVar: typeof e.rowDetailHeight == "string" ? e.rowDetailHeight : "",
      columnHeaderHeightCSSVar: typeof e.columnHeaderHeight == "string" ? e.columnHeaderHeight || Ve.components.Header.columnHeaderHeight : ""
    };
  };
function _d(o, e) {
  let t = new Map();
  return o.forEach((n, r) => {
    let i = n;
    if (!i.groupByForColumn || Array.isArray(i.groupByForColumn)) return;
    let {
        groupByForColumn: a
      } = i,
      l = a.field || a.groupField;
    l && e.get(l) && t.set(r, n);
  }), t;
}
function Od(o) {
  let {
      hideEmptyGroupColumns: e,
      groupByMap: t,
      computedGroupColumns: n,
      groupRowsIndexesInDataArray: r,
      dataArray: i,
      groupBy: a
    } = o,
    l = h({}, o.columnVisibility);
  if (!e) return n.forEach((d, p) => {
    l[p] === !1 && delete l[p];
  }), l;
  if (!r || !r.length) return l;
  let s = a.length,
    u = 0,
    c = r.length;
  for (let d = 0; d < c; d++) {
    let p = r[d],
      f = i[p];
    if (f.isGroupRow && (u = Math.max(u, f.groupNesting - 1), u === s - 1)) break;
  }
  return n.forEach((d, p) => {
    let {
      groupByForColumn: f
    } = d;
    if (Array.isArray(f)) return;
    let m = f.field || f.groupField;
    if (!m) return;
    t.get(m).groupIndex > u && e ? l[p] !== !1 && (l[p] = !1) : l.hasOwnProperty(p) && delete l[p];
  }), l;
}
import { useCallback as iC } from "react";
function ha() {
  let {
    getState: o,
    componentActions: e,
    getDataSourceMasterContext: t
  } = he();
  return iC(r => {
    let i = o(),
      a = new Xe(i.groupRowsState);
    if (a.toggleGroupRow(r), e.groupRowsState = a, i.lazyLoad) {
      let l = [Kt, ...r],
        s = i.originalLazyGroupData.get(l);
      a.isGroupRowExpanded(r) ? s != null && s.cache || an(i.data, i, e, {
        groupKeys: r
      }, t()) : s != null && s.cache || (i.lazyLoadCacheOfLoadedBatches.getKeysStartingWith(r).forEach(c => {
        i.lazyLoadCacheOfLoadedBatches.delete(c);
      }), e.lazyLoadCacheOfLoadedBatches = le.clone(i.lazyLoadCacheOfLoadedBatches), i.originalLazyGroupData.delete(l), e.originalLazyGroupDataChangeDetect = it());
    }
  }, []);
}
function sC(o) {
  return lC(() => Ad(o), [o]);
}
function Vd() {
  let {
      componentState: {
        groupBy: o
      },
      componentActions: e
    } = he(),
    {
      componentState: {
        groupRenderStrategy: t,
        pivotTotalColumnPosition: n,
        pivotGrandTotalColumnPosition: r
      }
    } = Ie();
  zn(() => {
    e.generateGroupRows = t !== "inline";
  }, [t]), zn(() => {
    e.pivotTotalColumnPosition = n;
  }, [n]), zn(() => {
    r != null && (e.pivotGrandTotalColumnPosition = r);
  }, [r]);
  let i = sC(o);
  uC(i);
  let {
    toggleGroupRow: a
  } = cC();
  return dC(i), {
    toggleGroupRow: a
  };
}
function uC(o) {
  let e = ha(),
    {
      componentActions: t,
      componentState: {
        columns: n,
        groupRenderStrategy: r
      }
    } = Ie();
  function i(a, l, s, u) {
    let c = new Map();
    if (s === "inline") return a.forEach((d, p) => {
      var C, T;
      let f = {},
        m = l.get(d.field);
      if (m && s === "inline") {
        let {
          groupIndex: b
        } = m;
        f = {
          groupByForColumn: m.groupBy,
          field: (C = m.groupBy) == null ? void 0 : C.field,
          valueGetter: (T = m.groupBy) == null ? void 0 : T.valueGetter,
          valueFormatter: ({
            rowInfo: S
          }) => {
            var y;
            return S.isGroupRow ? (y = S.groupKeys) == null ? void 0 : y[b] : S.value;
          },
          rowspan: ({
            rowInfo: S,
            dataArray: y
          }) => {
            if (!S.isGroupRow) return 1;
            let w = y[S.indexInAll - 1] || {
              indexInParentGroups: []
            };
            if (!w || !w.dataSourceHasGrouping) return 1;
            let v = w.indexInParentGroups || [],
              I = S.indexInParentGroups || [],
              R = !1;
            for (let M = 0; M <= b; M++) {
              let V = v[M];
              if (I[M] !== V) {
                R = !0;
                break;
              }
            }
            if (!R) return 1;
            let x = S.parents[b];
            return x ? x.groupCount - (x.collapsedChildrenCount || 0) + (x.collapsedGroupsCount || 0) : 1;
          },
          renderGroupIcon: lr({
            groupIndexForColumn: b,
            groupRenderStrategy: s,
            toggleGroupRow: u,
            initialRenderGroupIcon: d.renderGroupIcon
          }),
          render: ar({
            groupIndexForColumn: b,
            groupRenderStrategy: s
          })
        };
      }
      let g = h(h({}, f), d);
      c.set(p, g);
    }), c.size === 0 ? void 0 : c;
  }
  zn(() => {
    let a = () => {
      t.columnsWhenInlineGroupRenderStrategy = i(n, o, r, e);
    };
    return a(), Pt(n, {
      set: a,
      delete: a,
      clear: a
    });
  }, [n, o, r, e]);
}
function cC() {
  let {
      componentState: {
        groupBy: o,
        pivotBy: e,
        selectionMode: t
      }
    } = he(),
    {
      componentActions: n,
      componentState: {
        columns: r,
        groupColumn: i,
        hideColumnWhenGrouped: a,
        groupRenderStrategy: l,
        pivotColumns: s,
        pivotColumn: u,
        pivotTotalColumnPosition: c,
        pivotGrandTotalColumnPosition: d
      },
      getComponentState: p
    } = Ie(),
    f = ha();
  return zn(() => {
    let m = () => {
      let {
        columns: g,
        groupColumnIds: C
      } = pC({
        columns: r,
        groupColumn: i,
        pivotColumn: u,
        pivotTotalColumnPosition: c,
        pivotGrandTotalColumnPosition: d,
        groupRenderStrategy: l === "inline" ? "single-column" : l,
        groupBy: o,
        pivotBy: e,
        pivotColumns: s,
        toggleGroupRow: f,
        selectionMode: t
      });
      n.columnsWhenGrouping = g;
      let T = p().columnOrder;
      if (C.length && Array.isArray(T)) {
        let b = new Set(T),
          S = !1;
        C.forEach(y => {
          b.has(y) || (S = !0, T = [y, ...T]);
        }), S && (n.columnOrder = T);
      }
    };
    return m(), Pt(r, {
      set: m,
      delete: m,
      clear: m
    });
  }, [r, o, e, t, a, i, l, s, c, u]), {
    toggleGroupRow: f
  };
}
function dC(o) {
  let {
      componentState: {
        dataArray: e,
        groupRowsIndexesInDataArray: t,
        groupBy: n,
        groupRowsState: r,
        originalLazyGroupDataChangeDetect: i
      },
      getState: a
    } = he(),
    {
      getComponentState: l,
      componentActions: s,
      componentState: {
        columnTypes: u,
        computedColumns: c,
        hideColumnWhenGrouped: d,
        hideEmptyGroupColumns: p,
        groupRenderStrategy: f
      }
    } = Ie();
  aC(() => {
    if (f !== "multi-column") return;
    let m = l(),
      g = _d(c, o),
      C = Od({
        computedGroupColumns: g,
        columnVisibility: m.columnVisibility,
        hideEmptyGroupColumns: p,
        groupRowsIndexesInDataArray: t,
        dataArray: e,
        groupBy: n,
        groupByMap: o
      });
    zt(m.columnVisibility, C) || (s.columnVisibility = C);
  }, [a, f, i, n, o, c, p ? e : null, p ? r : null, p ? t : null, p]), zn(() => {
    let m = n.length > 0,
      g = l(),
      {
        columnVisibility: C,
        columnVisibilityForGrouping: T,
        hideColumnWhenGrouped: b
      } = g,
      S = h({}, C),
      y = h({}, T),
      w = !1,
      v = new Set(),
      I = new Set();
    c.forEach((R, x) => {
      if (R.defaultHiddenWhenGroupedBy || b != null) if (R.defaultHiddenWhenGroupedBy === "*" && m || (R.defaultHiddenWhenGroupedBy === !0 || b) && R.field && o.get(R.field) || typeof R.defaultHiddenWhenGroupedBy == "string" && o.get(R.defaultHiddenWhenGroupedBy) || typeof R.defaultHiddenWhenGroupedBy == "object" && Object.keys(R.defaultHiddenWhenGroupedBy).reduce((M, V) => M || o.has(V), !1)) {
        if (y[x] !== !1) {
          if (C[x] === !1) return;
          y[x] = !1, w = !0, v.add(x);
        }
      } else y[x] === !1 && (delete y[x], w = !0, I.add(x));
    }), w && (s.columnVisibilityForGrouping = y, I.forEach(R => {
      delete S[R];
    }), v.forEach(R => {
      S[R] = !1;
    }), s.columnVisibility = S);
  }, [c, u, n, o, d]);
}
function pC(o) {
  let {
    pivotColumns: e,
    groupBy: t,
    pivotBy: n,
    selectionMode: r,
    groupColumn: i,
    pivotTotalColumnPosition: a,
    pivotGrandTotalColumnPosition: l,
    pivotColumn: s,
    groupRenderStrategy: u,
    toggleGroupRow: c,
    columns: d
  } = o;
  if (u === "inline") return {
    columns: void 0,
    groupColumnIds: []
  };
  let p = new Map(),
    f = [];
  if (u === "multi-column") t.forEach((m, g, C) => {
    let T = Lu({
        groupByForColumn: m,
        groupBy: t,
        pivotBy: n,
        groupIndexForColumn: g,
        groupCount: C.length,
        groupRenderStrategy: u,
        selectionMode: r
      }, c, i),
      b = T.id || `group-by-${m.field || m.groupField}`;
    f.push(b), p.set(b, T);
  });else if (u === "single-column" && t.length) {
    let m = Hu({
        groupCount: t.length,
        groupBy: t,
        pivotBy: n,
        groupRenderStrategy: u,
        selectionMode: r
      }, c, i),
      g = m.id || "group-by";
    f.push(g), p.set(g, m);
  }
  if (e) {
    let m = {};
    d.forEach(g => {
      g.field && (m[g.field] = g);
    }), e.forEach((g, C) => {
      var y, w;
      let T = g.pivotTotalColumn && g.columnGroup,
        b = g.pivotTotalColumn && !g.columnGroup;
      if (T && a === !1 || b && l === !1) return;
      let S = h({}, g);
      if (s && (typeof s == "function" ? S = h(h({}, S), s({
        column: S,
        pivotBy: n,
        groupBy: t
      })) : S = h(h({}, S), s)), S.inheritFromColumn !== !1) {
        let v = typeof S.inheritFromColumn == "string" ? d.get(S.inheritFromColumn) : (y = S.pivotAggregator) != null && y.field ? m[(w = S.pivotAggregator) == null ? void 0 : w.field] : void 0;
        S = h(h({}, v), S);
      }
      !S.render && S.renderValue && (S.render = v => S.renderValue(v)), p.set(C, S);
    });
  } else d.forEach((m, g) => {
    p.set(g, m);
  });
  return {
    columns: p.size ? p : void 0,
    groupColumnIds: f
  };
}
import { useMemo as fC } from "react";
import { useEffect as mC } from "react";
var zd = o => {
  let [e, t] = Ze();
  return mC(() => {
    let n = Jt(t);
    return Pt(o, {
      set: n,
      clear: n,
      delete: n
    });
  }, [o]), e;
};
var Gd = ({
  columns: o,
  bodySize: e,
  columnMinWidth: t,
  columnMaxWidth: n,
  columnDefaultWidth: r,
  columnDefaultFlex: i,
  sortable: a,
  columnCssEllipsis: l,
  columnHeaderCssEllipsis: s,
  draggableColumns: u,
  sortInfo: c,
  multiSort: d,
  filterValue: p,
  filterTypes: f,
  columnOrder: m,
  columnPinning: g,
  editable: C,
  columnDefaultEditable: T,
  columnDefaultFilterable: b,
  columnDefaultSortable: S,
  scrollbarWidth: y,
  columnTypes: w,
  pinnedEndMaxWidth: v,
  pinnedStartMaxWidth: I,
  viewportReservedWidth: R,
  resizableColumns: x,
  columnVisibility: E,
  columnVisibilityAssumeVisible: M,
  columnSizing: V
}) => {
  let z = zd(o),
    {
      computedRemainingSpace: P,
      computedColumnOrder: F,
      computedVisibleColumns: A,
      computedVisibleColumnsMap: O,
      computedPinnedStartColumns: B,
      computedPinnedEndColumns: k,
      computedUnpinnedColumns: J,
      computedPinnedStartColumnsWidth: oe,
      computedPinnedEndColumnsWidth: q,
      computedUnpinnedColumnsWidth: Q,
      computedUnpinnedOffset: L,
      computedPinnedEndOffset: N,
      computedPinnedEndWidth: j,
      computedPinnedStartWidth: _,
      renderSelectionCheckBox: H,
      computedColumnsMap: G,
      computedColumnsMapInInitialOrder: W,
      fieldsToColumn: X
    } = fC(() => iu({
      columns: o,
      scrollbarWidth: y,
      bodySize: e,
      columnMinWidth: t,
      columnMaxWidth: n,
      columnDefaultWidth: r,
      columnDefaultFlex: i,
      columnCssEllipsis: l,
      columnHeaderCssEllipsis: s,
      viewportReservedWidth: R,
      resizableColumns: x,
      filterValue: p,
      filterTypes: f,
      sortable: a,
      sortInfo: c,
      multiSort: d,
      pinnedEndMaxWidth: v,
      pinnedStartMaxWidth: I,
      draggableColumns: u,
      columnOrder: m,
      columnPinning: g,
      columnDefaultEditable: T,
      columnDefaultFilterable: b,
      columnDefaultSortable: S,
      editable: C,
      columnSizing: V,
      columnTypes: w,
      columnVisibility: E,
      columnVisibilityAssumeVisible: M != null ? M : !0
    }), [o, e.width, t, n, r, R, y, a, c, d, p, f, m, E, M, x, v, I, V, w, g, T, b, C, z]);
  return {
    renderSelectionCheckBox: H,
    columns: o,
    computedColumnsMap: G,
    computedPinnedEndWidth: j,
    computedPinnedStartWidth: _,
    computedRemainingSpace: P,
    computedUnpinnedOffset: L,
    computedPinnedEndOffset: N,
    computedPinnedStartColumnsWidth: oe,
    computedPinnedEndColumnsWidth: q,
    computedUnpinnedColumnsWidth: Q,
    computedPinnedStartColumns: B,
    computedPinnedEndColumns: k,
    computedUnpinnedColumns: J,
    computedVisibleColumns: A,
    computedVisibleColumnsMap: O,
    computedColumnOrder: F,
    computedColumnsMapInInitialOrder: W,
    fieldsToColumn: X
  };
};
import { useMemo as gC } from "react";
var Ca = class {
  constructor() {
    this.rowHeight = new Map();
    this.rowDetailHeight = new Map();
    this.getRowHeight = e => {
      var t;
      return (t = this.rowHeight.get(e)) != null ? t : 0;
    };
    this.getRowDetailHeight = e => {
      var t;
      return (t = this.rowDetailHeight.get(e)) != null ? t : 0;
    };
  }
  getTotalRowHeight(e) {
    return this.getRowHeight(e) + this.getRowDetailHeight(e);
  }
  getSize(e) {
    let t = this.getRowHeight(e),
      n = this.getRowDetailHeight(e);
    return {
      rowHeight: t,
      rowDetailHeight: n,
      totalRowHeight: t + n
    };
  }
};
function hC(o, e) {
  let t = o.rowDetailHeight,
    n = o.rowHeight,
    {
      isRowDetailsExpanded: r,
      isRowDetailsEnabled: i,
      getDataSourceState: a
    } = o;
  if (!r) return typeof n == "function" ? c => {
    if (e && e.rowHeight.has(c)) return e.rowHeight.get(c);
    let d = a().dataArray[c],
      p = d ? n(d) : 0;
    return e && e.rowHeight.set(c, p), p;
  } : n;
  let l = typeof n == "function",
    s = typeof t == "function";
  return c => {
    if (e && e.rowHeight.has(c) && e.rowDetailHeight.has(c)) return e.rowHeight.get(c) + e.rowDetailHeight.get(c);
    let d = a().dataArray[c];
    if (!d) return e && (e.rowHeight.set(c, 0), e.rowDetailHeight.set(c, 0)), 0;
    let p = l ? n(d) : n;
    if (!!(!i || typeof i == "function" && i(d) === !1) || !r(d)) return e && (e.rowHeight.set(c, p), e.rowDetailHeight.set(c, 0)), p;
    let m = 0;
    return s ? m = t(d) : m = t, e && (e.rowHeight.set(c, p), e.rowDetailHeight.set(c, m)), p + m;
  };
}
function Ld(o) {
  return gC(() => {
    let e = new Ca();
    return {
      computedRowHeight: hC(o, e),
      computedRowSizeCacheForDetails: o.isRowDetailsExpanded ? e : void 0
    };
  }, [o.rowHeight, o.rowDetailHeight, o.isRowDetailsExpanded, o.isRowDetailsEnabled, o.getDataSourceState]);
}
import { useState as CC, useEffect as SC, useLayoutEffect as yC } from "react";
var TC = {
  vertical: !1,
  horizontal: !1
};
function Hd(o) {
  let {
      getComponentState: e
    } = Ie(),
    {
      getState: t
    } = he(),
    [n, r] = CC(TC);
  return SC(() => o.onRenderCountChange(() => {
    let {
      scrollTopMax: i,
      scrollLeftMax: a
    } = o;
    r({
      vertical: i > 0,
      horizontal: a > 0
    });
  }), [o]), yC(() => {
    let i = t(),
      {
        onScrollbarsChange: a
      } = e(),
      {
        notifyScrollbarsChange: l
      } = i;
    a && i.updatedAt && i.dataArray.length && a(n), l(n);
  }, [n]), n;
}
function Nd() {
  var Ge;
  let {
      componentActions: o,
      componentState: e
    } = Ie(),
    {
      componentActions: t,
      componentState: n,
      getState: r,
      api: i
    } = he(),
    {
      columnOrder: a,
      columnVisibility: l,
      columnPinning: s,
      columnSizing: u,
      editable: c,
      columnDefaultEditable: d,
      columnDefaultFilterable: p,
      columnTypes: f,
      rowHeight: m,
      rowDetailHeight: g,
      isRowDetailExpanded: C,
      isRowDetailEnabled: T,
      brain: b,
      bodySize: S,
      showSeparatePivotColumnForSingleAggregation: y
    } = e;
  Sa(() => {
    e.onRowHeightCSSVarChange.onChange(fe => {
      fe && (o.rowHeight = fe);
    }), e.onRowDetailHeightCSSVarChange.onChange(fe => {
      fe && (o.rowDetailHeight = fe);
    }), e.onColumnHeaderHeightCSSVarChange.onChange(fe => {
      fe && (o.columnHeaderHeight = fe);
    });
  }), bC(() => {
    t.showSeparatePivotColumnForSingleAggregation = y;
  }, [y]);
  let {
    multiSort: w,
    filterValue: v,
    filterTypes: I
  } = n;
  wd();
  let {
      toggleGroupRow: R
    } = Vd(),
    x = e.computedColumns,
    {
      computedColumnOrder: E,
      computedVisibleColumns: M,
      computedVisibleColumnsMap: V,
      computedPinnedStartColumns: z,
      computedPinnedEndColumns: P,
      computedUnpinnedColumns: F,
      computedPinnedStartColumnsWidth: A,
      computedPinnedEndColumnsWidth: O,
      computedUnpinnedColumnsWidth: B,
      computedUnpinnedOffset: k,
      computedPinnedEndOffset: J,
      computedRemainingSpace: oe,
      computedPinnedStartWidth: q,
      computedPinnedEndWidth: Q,
      renderSelectionCheckBox: L,
      computedColumnsMap: N,
      computedColumnsMapInInitialOrder: j,
      fieldsToColumn: _
    } = Gd({
      columns: x,
      scrollbarWidth: void 0,
      columnCssEllipsis: e.columnCssEllipsis,
      columnHeaderCssEllipsis: e.columnHeaderCssEllipsis,
      columnMinWidth: e.columnMinWidth,
      columnMaxWidth: e.columnMaxWidth,
      columnDefaultWidth: e.columnDefaultWidth,
      columnDefaultFlex: e.columnDefaultFlex,
      columnDefaultSortable: e.columnDefaultSortable,
      pinnedStartMaxWidth: e.pinnedStartMaxWidth,
      pinnedEndMaxWidth: e.pinnedEndMaxWidth,
      bodySize: S,
      viewportReservedWidth: e.viewportReservedWidth,
      resizableColumns: e.resizableColumns,
      sortable: e.sortable,
      draggableColumns: e.draggableColumns,
      sortInfo: (Ge = n.sortInfo) != null ? Ge : void 0,
      multiSort: w,
      columnOrder: a,
      columnVisibility: l,
      columnVisibilityAssumeVisible: !0,
      columnPinning: s,
      editable: c,
      columnDefaultEditable: d,
      columnDefaultFilterable: p,
      filterValue: v,
      filterTypes: I,
      columnSizing: u,
      columnTypes: f
    }),
    H = Id(M),
    G = xd(M),
    W = Hd(b),
    X = q ? A > q : !1,
    Y = Q ? O > Q : !1,
    [ee] = Sa(() => new xr({
      getIdForIndex: ye => r().dataArray[ye].id
    })),
    pe = _e(M),
    [ue] = Sa(() => new Ir({
      getPrimaryKeyByIndex: i.getPrimaryKeyByIndex,
      getColumnIdByIndex: fe => {
        var ye;
        return ((ye = pe()[fe]) == null ? void 0 : ye.id) || "";
      }
    })),
    {
      computedRowHeight: Ae,
      computedRowSizeCacheForDetails: ze
    } = Ld({
      rowDetailHeight: g,
      rowHeight: m,
      isRowDetailsExpanded: C,
      isRowDetailsEnabled: T,
      getDataSourceState: r
    });
  return {
    multiRowSelector: ee,
    multiCellSelector: ue,
    computedRowSizeCacheForDetails: ze,
    computedRowHeight: Ae,
    scrollbars: W,
    columnSize: G,
    rowspan: H,
    toggleGroupRow: R,
    computedColumnsMap: N,
    computedColumnsMapInInitialOrder: j,
    renderSelectionCheckBox: L,
    computedPinnedStartOverflow: X,
    computedPinnedEndOverflow: Y,
    computedPinnedStartWidth: q,
    computedPinnedEndWidth: Q,
    computedVisibleColumns: M,
    computedColumnOrder: E,
    computedRemainingSpace: oe,
    computedVisibleColumnsMap: V,
    computedPinnedStartColumns: z,
    computedPinnedEndColumns: P,
    computedUnpinnedColumns: F,
    computedPinnedStartColumnsWidth: A,
    computedPinnedEndColumnsWidth: O,
    computedUnpinnedColumnsWidth: B,
    computedUnpinnedOffset: k,
    computedPinnedEndOffset: J,
    fieldsToColumn: _
  };
}
import { useMemo as DC } from "react";
function RC(o = 3988292384) {
  var e = new Array(),
    t,
    n,
    r;
  for (t = 0; t < 256; t++) {
    for (r = t, n = 8; n > 0; n--) (r & 1) == 1 ? r = r >>> 1 ^ o : r = r >>> 1;
    e[t] = r;
  }
  return e;
}
function wC() {
  return 4294967295;
}
function IC(o, e, t) {
  return e = e >>> 8 ^ o[t ^ e & 255], e;
}
function xC(o) {
  return o = ~o, o = o < 0 ? 4294967295 + o + 1 : o, o;
}
function ya(o, e = 3988292384) {
  var t = RC(e),
    n = 0,
    r;
  for (n = wC(), r = 0; r < o.length; r++) n = IC(t, n, o.charCodeAt(r));
  return n = xC(n), `${n}`;
}
var vC = o => {
    var n;
    let e = o.reduce((r, i) => (r.set(i.name, i.value), r), new Map());
    return {
      distribution: e.get("Type") === "distribution",
      count: e.get("DevCount") ? Number(e.get("DevCount")) : 0,
      start: new Date(e.get("StartDate")),
      end: new Date(e.get("EndDate")),
      owner: e.get("Owner"),
      type: e.get("Type"),
      timestamp: e.get("TS") ? Number(e.get("TS")) : 0,
      trial: e.get("Trial") === "true",
      ref: (n = e.get("Ref")) != null ? n : ""
    };
  },
  Kd = (o, e = console.error.bind(console)) => {
    o = ["* * * infinite-table.com * * *", ...o];
    let t = Math.max(Math.max(...o.map(n => n.length)) + 4 * 2, 60);
    o.forEach(n => {
      let r = (t - n.length) / 2 - 1,
        i = 0;
      r !== Math.round(r) && (i = -1, r = Math.round(r));
      let a = r + i,
        l = r;
      e("*".repeat(a) + " " + n + " " + "*".repeat(l));
    }), e("*".repeat(t));
  },
  Bd = (o = "", e, t) => {
    let n;
    try {
      n = PC(o);
    } catch (a) {
      return Kd(["You don't have a valid Infinite Table license", "Please visit infinite-table.com to purchase a license.", "Thank you for playing fair!"], t), !1;
    }
    let r = new Date(e.publishedAt),
      i = new Date(n.end);
    return i < r ? (Kd([`
Your Infinite Table license has expired on ${i.toDateString()}.
`, `You are using version "${e.version}" of Infinite Table, which was published on ${r.toDateString()}.`, "Please visit infinite-table.com to renew your license.", "Thank you for playing fair!"], t), !1) : !0;
  },
  PC = o => {
    let e = "",
      t = o.split("|").map(l => {
        let [s, u] = l.split("=");
        return s === "C" && (e = u), {
          name: s,
          value: u
        };
      });
    if (!e) throw "Invalid license";
    let n = e.split(",").reverse(),
      r = n.pop();
    n.forEach((l, s) => {
      let u = t[s];
      if (ya(u.value) !== l) throw "Invalid license";
    });
    let i = [...t];
    i.pop();
    let a = i.map(l => `${l.name}=${l.value}`).join("|");
    if (ya(a) !== r) throw "Invalid license";
    return t = t.map(l => D(h({}, l), {
      value: decodeURI(l.value)
    })), vC(t);
  };
var MC = /(https):\/\/\d+\-\d+\-\d+\-(sandpack\.codesandbox\.io)/g,
  EC = /(https):\/\/\S+(\.csb\.app)/g,
  kd = typeof window != "undefined" ? window.location.origin : "",
  AC = () => {
    let [o, e, t] = Array.from(MC.exec(kd) || []);
    return e === "https" && t === "sandpack.codesandbox.io";
  },
  FC = () => {
    let [o, e, t] = Array.from(EC.exec(kd) || []);
    return e === "https" && t === ".csb.app";
  },
  _C = FC() || AC(),
  Wd = (o = "") => {
    let t = !!Ne();
    return DC(() => {
      if (t) return !0;
      let r = Bd(o, {
        publishedAt: 1624970570587,
        version: "3.2.10"
      });
      return !o && !r && _C ? !0 : r;
    }, [o, t]);
  };
import { useRef as $d, useEffect as jd } from "react";
var OC = 10;
function Ud(o, e, t) {
  let n = $d(!1),
    r = $d(null);
  jd(() => {
    o != null && (n.current = !1, ot(r.current));
  }, [o]), jd(() => {
    if (o != null && !n.current) {
      let a = function (l = 0) {
        l++, ot(r.current), r.current = be(() => {
          n.current = t.scrollCellIntoView(o[0], o[1], {
            offset: 30
          }), !n.current && l < OC && a(l);
        });
      };
      var i = a;
      a();
    }
    return () => {
      ot(r.current);
    };
  }, [o, e]);
}
import { useRef as Yd, useEffect as qd } from "react";
var VC = 10;
function Xd(o, e, t) {
  let n = Yd(!1),
    r = Yd(null);
  qd(() => {
    o != null && (n.current = !1, cancelAnimationFrame(r.current));
  }, [o]), qd(() => {
    if (o != null && !n.current) {
      let a = function (l = 0) {
        l++, r.current = requestAnimationFrame(() => {
          n.current = t.scrollRowIntoView(o, {
            offset: 0
          }), !n.current && l < VC && a(l);
        });
      };
      var i = a;
      a();
    }
    return () => {
      cancelAnimationFrame(r.current);
    };
  }, [o, e]);
}
var Zd = o => `--infinite-${o}`;
import { useCallback as jC, useMemo as UC, useEffect as YC } from "react";
function Jd(o, e) {
  var t, n;
  Ta(o, e), GC(o, e), e.detail === 2 && zC(o, e), (n = (t = o.getState()).onCellClick) == null || n.call(t, o, e);
}
function zC(o, e) {
  let n = o.getComputed().computedVisibleColumns[o.colIndex];
  o.api.startEdit({
    rowIndex: o.rowIndex,
    columnId: n.id
  });
}
function GC(o, e) {
  let {
      getDataSourceState: t,
      getComputed: n,
      rowIndex: r,
      colIndex: i,
      dataSourceActions: a
    } = o,
    l = t(),
    {
      selectionMode: s,
      cellSelection: u
    } = l;
  if (!u || s !== "multi-cell") return;
  let {
      multiCellSelector: c
    } = n(),
    d = new Ee(u);
  c.cellSelectionState = d;
  let p = {
    rowIndex: r,
    colIndex: i
  };
  e.metaKey || e.ctrlKey ? c.singleAddClick(p) : e.shiftKey ? c.multiSelectClick(p) : c.resetClick(p), a.cellSelection = d;
}
function Ta(o, e) {
  let {
      rowIndex: t,
      getDataSourceState: n,
      getComputed: r,
      dataSourceActions: i,
      api: a,
      cloneRowSelection: l
    } = o,
    {
      multiRowSelector: s,
      renderSelectionCheckBox: u
    } = r(),
    c = n(),
    {
      selectionMode: d,
      groupBy: p,
      dataArray: f
    } = c;
  if (p.length) return !1;
  if (d === "multi-row") {
    if (u && !e.key) return !1;
    let {
      rowSelection: m
    } = c;
    if (m = l(m), s.rowSelectionState = m, m && typeof m == "object") return e.shiftKey ? s.multiSelectClick(t) : e.metaKey || e.ctrlKey ? s.singleAddClick(t) : s.resetClick(t), i.rowSelection = m, !0;
  } else if (d === "single-row") {
    let m = f[t].id;
    e.metaKey || e.ctrlKey ? a.rowSelectionApi.toggleRowSelection(m) : a.rowSelectionApi.selectRow(m);
  }
  return !1;
}
function Qd(o, e) {
  e.detail !== 2 && void 0;
}
function Dt(o, e, t) {
  return Math.min(Math.max(o, e), t);
}
function LC(o, e) {
  let {
      getState: t,
      getDataSourceState: n,
      actions: r,
      api: i
    } = o,
    {
      key: a
    } = e,
    l = n().dataArray,
    s = l.length,
    u = t(),
    {
      brain: c
    } = u,
    {
      activeRowIndex: d
    } = u;
  if (d == null) return !1;
  let {
      start: [p],
      end: [f]
    } = c.getRenderRange(),
    m = d,
    g = f - p - 1,
    C = 0,
    T = s - 1,
    S = {
      ArrowDown: () => {
        d = Dt(d + 1, C, T);
      },
      ArrowUp: () => {
        d = Dt(d - 1, C, T);
      },
      ArrowLeft: () => {
        let y = l[d];
        return y && y.isGroupRow ? i.collapseGroupRow(y.groupKeys) : y && i.rowDetailApi.isRowDetailEnabledForRow(y.id) ? i.rowDetailApi.collapseRowDetail(y.id) : !1;
      },
      ArrowRight: () => {
        let y = l[d];
        return y && y.isGroupRow ? i.expandGroupRow(y.groupKeys) : y && i.rowDetailApi.isRowDetailEnabledForRow(y.id) ? i.rowDetailApi.expandRowDetail(y.id) : !1;
      },
      Enter: () => {
        let y = l[d];
        return y && y.isGroupRow ? i.toggleGroupRow(y.groupKeys) : i.rowDetailApi.isRowDetailEnabledForRow(y.id) ? i.rowDetailApi.toggleRowDetail(y.id) : !1;
      },
      PageDown: () => {
        d = Math.min(d + g, s - 1);
      },
      PageUp: () => {
        d = Math.max(d - g, C);
      },
      End: () => {
        d = T;
      },
      Home: () => {
        d = C;
      }
    }[a];
  return S ? (S(), m !== d ? (r.activeRowIndex = d, !0) : !1) : !1;
}
function HC(o, e) {
  let {
      api: t,
      getState: n,
      getComputed: r,
      getDataSourceState: i,
      actions: a
    } = o,
    {
      key: l,
      shiftKey: s
    } = e,
    u = i().dataArray,
    c = n(),
    {
      activeCellIndex: d,
      brain: p
    } = c;
  if (!d) return !1;
  let [f, m] = d,
    {
      start: [g, C],
      end: [T, b]
    } = p.getRenderRange(),
    S = T - g - 1,
    y = b - C - 1,
    w = i().dataArray.length - 1,
    v = r().computedVisibleColumns.length - 1,
    I = 0,
    R = 0;
  f = Dt(f, 0, w), m = Dt(m, 0, v), a.activeCellIndex = [f, m];
  let x = {
      ArrowDown: () => {
        f = Dt(f + 1, I, w);
      },
      ArrowUp: () => {
        f = Dt(f - 1, I, w);
      },
      ArrowLeft: () => {
        m === R ? (f !== I && (m = v), x.ArrowUp()) : m = Dt(m - 1, R, v);
      },
      ArrowRight: () => {
        m === v ? (f !== w && (m = R), x.ArrowDown()) : m = Dt(m + 1, R, v);
      },
      Enter: () => {
        let M = u[f];
        return M && M.isGroupRow ? t.toggleGroupRow(M.groupKeys) : M && t.rowDetailApi.isRowDetailEnabledForRow(M.id) ? t.rowDetailApi.toggleRowDetail(M.id) : !1;
      },
      PageDown: () => {
        s ? m = Math.min(m + y, v) : f = Math.min(f + S, w);
      },
      PageUp: () => {
        s ? m = Math.max(m - y, R) : f = Math.max(f - S, I);
      },
      End: () => {
        s ? m = v : f = w;
      },
      Home: () => {
        s ? m = R : f = I;
      }
    },
    E = x[l];
  return E ? (E(), a.activeCellIndex = [f, m], !0) : !1;
}
var NC = {
  ArrowUp: !0,
  ArrowDown: !0,
  ArrowLeft: !0,
  ArrowRight: !0,
  PageUp: !0,
  PageDown: !0,
  Home: !0,
  End: !0,
  Enter: !0,
  " ": !0
};
function ep(o, e) {
  let {
      key: t
    } = e,
    {
      getState: n
    } = o,
    r = n(),
    {
      activeRowIndex: i,
      activeCellIndex: a,
      keyboardNavigation: l,
      cellContextMenuVisibleFor: s,
      contextMenuVisibleFor: u
    } = r;
  return s || u || !NC[t] ? !1 : i != null && l === "row" ? LC(o, e) : a != null && l === "cell" ? HC(o, e) : !1;
}
var KC = {
  Enter: !0,
  " ": !0,
  a: !0
};
function tp(o, e) {
  let {
      getState: t,
      getDataSourceState: n,
      dataSourceActions: r,
      api: i,
      cloneRowSelection: a
    } = o,
    {
      key: l,
      ctrlKey: s,
      metaKey: u
    } = e;
  if (!KC[l]) return !1;
  let c = t(),
    d = n(),
    {
      activeRowIndex: p,
      activeCellIndex: f,
      keyboardNavigation: m,
      keyboardSelection: g
    } = c,
    {
      selectionMode: C,
      groupBy: T,
      rowSelection: b
    } = d;
  if (g === !1) return !1;
  let S = m === "row" && p != null ? p : m === "cell" && f != null ? f[0] : null,
    y = S != null ? d.dataArray[S] : null;
  if (!y || S == null) return !1;
  if (C === "single-row" && l === " ") return i.rowSelectionApi.toggleRowSelection(y.id), !0;
  if (C !== "multi-row") return !1;
  if (l === "a" && (s || u)) {
    let w = a(b);
    return w.selectAll(), r.rowSelection = w, !0;
  }
  if (l === " ") {
    if (T.length) return y.isGroupRow && y.groupKeys ? i.rowSelectionApi.toggleGroupRowSelection(y.groupKeys) : i.rowSelectionApi.toggleRowSelection(y.id), !0;
    {
      let w = h({}, e),
        {
          renderSelectionCheckBox: v
        } = o.getComputed();
      return v && (w.metaKey = !1, w.ctrlKey = !0), Ta(D(h({}, o), {
        rowIndex: S
      }), w);
    }
  }
  return !1;
}
var np = ["input", "textarea", "select", "[tabindex]", "a[href]", "button", "object"].join(", "),
  op = o => Array.from(o.querySelectorAll(np)).filter(t => !!t.offsetParent),
  Dr = o => o.querySelector(np);
var ip = jr(zo());
function rp(o, e = 10, t = 300) {
  return He(this, null, function* () {
    return new Promise((n, r) => {
      let i = Date.now();
      function a() {
        if (Date.now() - i > t) return r(!1);
        if (!o()) {
          setTimeout(a, e);
          return;
        }
        n(!0);
      }
      a();
    });
  });
}
var BC = 10,
  kC = (o, e) => o - e;
function Mr(o, e) {
  let {
      getComputed: t,
      getDataSourceState: n,
      getState: r,
      api: i,
      dataSourceApi: a
    } = e,
    {
      editingCell: l,
      isRowDetailEnabled: s
    } = r(),
    {
      dataArray: u
    } = n(),
    {
      computedVisibleColumns: c,
      computedColumnsMap: d
    } = t(),
    p = c[o.colIndex];
  if (!p) return !1;
  if (l && l.columnId === p.id && l.rowIndex === o.rowIndex) return !0;
  if (!p.contentFocusable) return !1;
  if (p.contentFocusable === !0) return !0;
  let f = u[o.rowIndex],
    m = !s || typeof s == "function" && !s(f) ? !1 : i.rowDetailApi.isRowDetailExpanded(f.id) ? "expanded" : "collapsed",
    g = We(p.id, e);
  return p.contentFocusable(D(h({
    column: p
  }, vn({
    column: p,
    rowInfo: f,
    columnsMap: d,
    context: e,
    rowDetailState: m
  }).formattedValueContext), {
    columnApi: g,
    api: i,
    dataSourceApi: a
  }));
}
function ba(o, e, t) {
  let n = t.getState().editingCell;
  return WC(o, e, t, {
    isCellFocusable: Mr,
    isColumnElligible: i => n && n.columnId === i.id ? !0 : !!i.contentFocusable
  });
}
function ap(o, e, t) {
  var r;
  let n = (0, ip.default)(t, o + e, kC);
  return n < 0 && (n = ~n, e > 0 && n >= t.length || e < 0 && n === 0) ? null : (r = t[n]) != null ? r : null;
}
globalThis.getNextCol = ap;
function WC(o, e, t, n) {
  let {
      isColumnElligible: r,
      isCellFocusable: i
    } = n,
    a = t.getComputed().computedVisibleColumns.filter(r).reduce((d, p) => (d.push(p.computedVisibleIndex), d), []);
  if (!a.length) return null;
  let {
      getDataSourceState: l
    } = t,
    {
      dataArray: s
    } = l(),
    u = o.rowIndex,
    c = o.colIndex;
  for (; u != null || c != null;) {
    if (c = c === null ? a[e === -1 ? a.length - 1 : 0] : ap(c, e, a), c === null) {
      if (u += e, u < 0 || u >= s.length) return null;
      continue;
    }
    if (i({
      colIndex: c,
      rowIndex: u
    }, t)) return {
      colIndex: c,
      rowIndex: u
    };
  }
  return null;
}
function lp(o) {
  return He(this, null, function* () {
    let e = {
        rowIndex: o.getDataSourceState().dataArray.length - 1,
        colIndex: o.getComputed().computedVisibleColumns.length - 1
      },
      t = Mr(e, o) ? e : ba(e, -1, o);
    if (t) {
      yield Ra([t.rowIndex, t.colIndex], o);
      let n = o.getState().getDOMNodeForCell(t);
      if (!n) return;
      let r = Dr(n);
      r == null || r.focus();
    }
  });
}
function Ra(n, r) {
  return He(this, arguments, function* ([o, e], t) {
    let {
      api: i
    } = t;
    return new Promise((a, l) => {
      let s = BC,
        u = null,
        c = !1;
      function d(p = 0) {
        p++, ot(u), u = be(() => He(this, null, function* () {
          if (c = i.scrollCellIntoView(o, e, {
            offset: 30
          }), !c && p < s && d(p), c) {
            try {
              yield rp(() => !!t.getState().getDOMNodeForCell({
                rowIndex: o,
                colIndex: e
              }));
            } catch (f) {
              l(f);
              return;
            }
            a(!0);
          }
        }));
      }
      d();
    });
  });
}
var $C = `.${en}`;
function sp(o, e) {
  if (e.key !== "Tab") return !1;
  let t = e.shiftKey ? -1 : 1,
    n = e.target,
    r = o.getState().scrollerDOMRef.current,
    i = document.activeElement === r || n === r;
  if (!r || i && t === -1) return !1;
  let a = i ? null : dn(n, $C),
    l = null,
    s = null;
  if (a) l = Number(a.getAttribute("data-row-index")), s = Number(a.getAttribute("data-col-index"));else if (i) {
    let {
      activeCellIndex: c
    } = o.getState();
    [l, s] = c || [0, 0];
  }
  if (l == null || s == null || isNaN(l) || isNaN(s)) return !1;
  let u = i && Mr({
    colIndex: s,
    rowIndex: l
  }, o) ? {
    rowIndex: l,
    colIndex: s
  } : ba({
    rowIndex: l,
    colIndex: s
  }, t, o);
  if (!u) {
    if (t === -1) r.focus();else {
      let c = op(document.body),
        d = o.getState().focusDetectDOMRef.current,
        p = c.findIndex(m => m === d),
        f = c[p + 1];
      f == null || f.focus();
    }
    return !0;
  }
  return Ra([u.rowIndex, u.colIndex], o).then(() => {
    let c = o.getState().getDOMNodeForCell(u);
    if (!c) return;
    let d = Dr(c);
    d == null || d.focus();
  }), !0;
}
function up(o, e) {
  var n, r;
  let t = D(h({}, o), {
    cloneRowSelection: i => yr(i, o.getDataSourceState)
  });
  if (tp(t, e) && e.preventDefault(), (sp(t, e) || !o.getState().focusedWithin && ep(t, e)) && e.preventDefault(), e.key === "Enter") {
    let {
      activeCellIndex: i
    } = o.getState();
    if (i) {
      let [a, l] = i,
        s = o.getComputed().computedVisibleColumns[l];
      s.computedEditable && o.api.startEdit({
        rowIndex: a,
        columnId: s.id
      });
    }
  }
  o.api.isEditInProgress() && (e.key === "Escape" && o.api.stopEdit({
    cancel: !0
  }), e.key === "Tab" && e.preventDefault()), (r = (n = o.getState()).onKeyDown) == null || r.call(n, o, e);
}
function qC() {
  let {
      getState: o,
      actions: e,
      api: t,
      getComputed: n,
      getDataSourceMasterContext: r
    } = ae(),
    {
      getState: i,
      componentActions: a,
      api: l
    } = he();
  return UC(() => ({
    getComputed: n,
    getDataSourceMasterContext: r,
    dataSourceApi: l,
    api: t,
    getState: o,
    actions: e,
    getDataSourceState: i,
    dataSourceActions: a,
    cloneRowSelection: c => yr(c, i)
  }), [o, e, i, a, t, l, n]);
}
function XC() {
  let o = qC();
  YC(() => {
    let e = o.getState().keyDown.onChange(a => {
      up(o, a);
    });
    function t(a) {
      if (!a) return;
      let l = a.event,
        s = o.getComputed().computedVisibleColumns[a.colIndex],
        u = We(s.id, o);
      Jd(D(h({}, o), {
        rowIndex: a.rowIndex,
        colIndex: a.colIndex,
        column: s,
        columnApi: u
      }), D(h({}, l), {
        key: ""
      }));
    }
    let n = o.getState().cellClick.onChange(t);
    function r(a) {
      if (!a) return;
      let {
          event: l,
          rowIndex: s,
          colIndex: u
        } = a,
        c = o.getComputed().computedVisibleColumns[u],
        d = We(c.id, o);
      Qd(D(h({}, o), {
        rowIndex: s,
        colIndex: u,
        column: c,
        columnApi: d
      }), l);
    }
    let i = o.getState().cellMouseDown.onChange(r);
    return () => {
      e(), n(), i();
    };
  }, []);
}
function ZC() {
  let {
    getState: o
  } = ae();
  return {
    onKeyDown: jC(t => {
      o().keyDown(t);
    }, [])
  };
}
function cp() {
  return XC(), ZC();
}
import { useEffect as zp } from "react";
import * as Pe from "react";
import { useCallback as Ia, useEffect as tS, useLayoutEffect as nS, useState as Ar } from "react";
import { createPortal as oS } from "react-dom";
var Me = class {
  constructor(e) {
    this.top = 0;
    this.left = 0;
    this.left = e.left, this.top = e.top;
  }
  static clone(e) {
    return new Me(e);
  }
  static from(e) {
    return new Me(e);
  }
  shift(e) {
    return e.top != null && (this.top += e.top), e.left != null && (this.left += e.left), this;
  }
  getDistanceToPoint(e) {
    let t = e.top - this.top,
      n = e.left - this.left;
    return {
      top: t,
      left: n
    };
  }
};
function dp(o, e) {
  let t = Math.atan2(o.top - e.top, o.left - e.left);
  return t <= 0 && (t = 2 * Math.PI + t), t;
}
function pp(o, e) {
  return Math.sqrt($r(e.top - o.top, 2) + $r(e.left - o.left, 2));
}
function JC(o, e, t) {
  let n = dp(o, t),
    r = dp(e, t);
  return n === r ? pp(t, e) - pp(t, o) : n - r;
}
function QC(o) {
  if (o.length < 3) return o;
  let [...e] = o,
    t = {
      top: 0,
      left: 0
    };
  return o.forEach(n => {
    t.top += n.top, t.left += n.left;
  }), t.top /= o.length, t.left /= o.length, e.sort((n, r) => JC(n, r, t)), e;
}
function mp(o, e) {
  o = QC(o);
  let [t] = o;
  for (let n = 1, r = o.length - 1; n < r; n++) {
    let i = o[n],
      a = o[n + 1];
    if (eS([t, i, a], e)) return !0;
  }
  return !1;
}
function eS(o, e) {
  let [t, n, r] = o,
    i = e,
    a = (n.left - t.left) * (r.top - t.top) - (n.top - t.top) * (r.left - t.left);
  return a * ((n.left - t.left) * (i.top - t.top) - (n.top - t.top) * (i.left - t.left)) >= 0 && a * ((r.left - n.left) * (i.top - n.top) - (r.top - n.top) * (i.left - n.left)) >= 0 && a * ((t.left - r.left) * (i.top - r.top) - (t.top - r.top) * (i.left - r.left)) >= 0;
}
var Gn = class {
  containsPoint(e) {
    return mp(this.getPoints(), e);
  }
  contains(e) {
    let t = e.getPoints();
    for (let n = 0, r = t.length; n < r; n++) if (!this.containsPoint(t[n])) return !1;
    return !0;
  }
  intersects(e) {
    return this.privateIntersects(e, !1);
  }
  privateIntersects(e, t) {
    let n = e.getPoints();
    for (let r = 0, i = n.length; r < i; r++) if (this.containsPoint(n[r])) return !0;
    return t ? !1 : e.privateIntersects(this, !0);
  }
};
var Bt = class extends Gn {
  constructor(t) {
    super();
    this.points = t.map(Me.clone);
  }
  static clone(t) {
    return new Bt(t);
  }
  getPoints() {
    return this.points;
  }
  shift(t) {
    return this.points.forEach(n => {
      n.shift(t);
    }), this;
  }
};
var we = class extends Gn {
  constructor(t) {
    super();
    this.top = 0;
    this.left = 0;
    this.width = 0;
    this.height = 0;
    this.top = t.top, this.left = t.left, typeof t.width == "number" ? (this.width = t.width, this.height = t.height) : (this.width = t.right - t.left, this.height = t.bottom - t.top);
  }
  static fromDOMRect(t) {
    return new we(t);
  }
  static clone(t) {
    return new we(t);
  }
  static from(t) {
    return we.clone(t);
  }
  static fromPoint(t) {
    return we.from({
      top: t.top,
      left: t.left,
      width: 0,
      height: 0
    });
  }
  containsPoint(t) {
    return new Bt(this.getPoints()).containsPoint(t);
  }
  getTopLeft() {
    return {
      left: this.left,
      top: this.top
    };
  }
  getTopRight() {
    return {
      left: this.left + this.width,
      top: this.top
    };
  }
  getBottomLeft() {
    return {
      left: this.left,
      top: this.top + this.height
    };
  }
  getBottomRight() {
    return {
      left: this.left + this.width,
      top: this.top + this.height
    };
  }
  getPoints() {
    return [this.getTopLeft(), this.getTopRight(), this.getBottomLeft(), this.getBottomRight()].map(Me.from);
  }
  shift(t) {
    return t.top != null && (this.top += t.top), t.left != null && (this.left += t.left), this;
  }
};
function wa(o) {
  return !!o.tagName;
}
function hp(o) {
  let {
      alignTarget: e,
      alignAnchor: t,
      constrainTo: n,
      alignPosition: r
    } = o,
    i = we.from(wa(e) ? e.getBoundingClientRect() : e),
    a = we.from(wa(t) ? t.getBoundingClientRect() : t),
    l = n ? we.from(wa(n) ? n.getBoundingClientRect() : n) : null;
  if (!l) {
    let u = fp({
      anchorRect: a,
      targetRect: i,
      position: r[0]
    });
    return D(h({}, u), {
      index: 0
    });
  }
  let s = null;
  for (let u = 0, c = r.length; u < c; u++) {
    let d = fp({
      anchorRect: a,
      targetRect: i,
      position: r[u],
      constrainRect: l
    });
    if (u === 0 && (s = D(h({}, d), {
      index: 0
    })), d.valid) return D(h({}, d), {
      index: u
    });
  }
  return s;
}
function fp(o) {
  let e = we.from(o.targetRect),
    t = we.from(o.anchorRect),
    n = o.constrainRect ? we.from(o.constrainRect) : null,
    {
      position: r
    } = o,
    [i, a] = r,
    l = gp(e, i),
    s = gp(t, a),
    u = l.getDistanceToPoint(s);
  e.shift(u);
  let c = n ? n.contains(e) : !0;
  return {
    alignPosition: r,
    alignedRect: e,
    valid: c,
    distance: u
  };
}
function gp(o, e) {
  return e === "TopLeft" ? Me.from({
    top: o.top,
    left: o.left
  }) : e === "TopCenter" ? Me.from({
    top: o.top,
    left: o.left + (o.width > 0 ? Math.round(o.width / 2) : 0)
  }) : e === "TopRight" ? Me.from({
    top: o.top,
    left: o.left + o.width
  }) : e === "BottomLeft" ? Me.from({
    left: o.left,
    top: o.top + o.height
  }) : e === "BottomCenter" ? Me.from({
    left: o.left + (o.width > 0 ? Math.round(o.width / 2) : 0),
    top: o.top + o.height
  }) : e === "BottomRight" ? Me.from({
    left: o.left + o.width,
    top: o.top + o.height
  }) : e === "CenterLeft" ? Me.from({
    left: o.left,
    top: o.top + (o.height > 0 ? Math.round(o.height / 2) : 0)
  }) : e === "CenterRight" ? Me.from({
    left: o.left + o.width,
    top: o.top + (o.height > 0 ? Math.round(o.height / 2) : 0)
  }) : Me.from({
    left: o.left + (o.width > 0 ? Math.round(o.width / 2) : 0),
    top: o.top + (o.height > 0 ? Math.round(o.height / 2) : 0)
  });
}
var Er = "__is_infinite_menu_component";
function rS(o) {
  return o && typeof o.then == "function" || o instanceof Promise;
}
function yp(o) {
  return o == null || typeof o == "string" ? !1 : typeof o.tagName == "string";
}
function Cp(o) {
  return He(this, null, function* () {
    return typeof o == "function" && (o = o()), rS(o) && (o = yield o), typeof o == "string" && (o = document.querySelector(o)), o && yp(o) && (o = o.getBoundingClientRect()), o ? we.from(o) : null;
  });
}
function iS(o) {
  return He(this, null, function* () {
    let e = null;
    typeof o == "function" ? e = o() : e = o;
    function t(n) {
      let r = null;
      return typeof n == "string" && (r = document.querySelector(n)), yp(n) && (r = n), r || null;
    }
    return t(yield e);
  });
}
function aS(o) {
  return Pe.createElement("div", {
    style: {
      position: "fixed",
      top: 0,
      left: 0
    }
  }, o.children);
}
function lS(o) {
  let e = Pe.useRef(null);
  return tS(() => o.realign.onChange(t => {
    e.current && t && Sp(e.current, t);
  }), [o.realign]), Pe.createElement("div", {
    style: {
      position: "absolute",
      top: 0,
      left: 0
    },
    ref: Ia(t => {
      t && Sp(t, o), e.current = t;
    }, [])
  }, typeof o.children == "function" ? o.children() : o.children);
}
function Sp(o, e) {
  return He(this, null, function* () {
    let {
      constrainTo: t,
      alignTo: n,
      alignPosition: r
    } = e;
    Object.keys(n).length === 2 && n.top !== void 0 && n.left !== void 0 && (n = D(h({}, n), {
      width: 0,
      height: 0
    })), typeof t == "boolean" && (t = t ? document.documentElement.getBoundingClientRect() : void 0);
    let i = t && (yield Cp(t)) || void 0,
      a = n ? yield Cp(n) : void 0,
      l = we.from(o.getBoundingClientRect());
    if (!a) return;
    let {
      alignedRect: s
    } = hp({
      constrainTo: i,
      alignAnchor: a,
      alignTarget: l,
      alignPosition: r
    });
    o.style.transform = `translate3d(${s.left}px,${s.top}px, 0px)`;
    let u = o.getBoundingClientRect();
    if (u.left !== s.left || u.top !== s.top) {
      let c = o.offsetParent;
      if (c) {
        let d = c.getBoundingClientRect(),
          p = d.left,
          f = d.top,
          m = s.left - p,
          g = s.top - f;
        o.style.transform = `translate3d(${m}px,${g}px, 0px)`;
      }
    }
  });
}
function sS(o, e) {
  let [t, n] = Ar(null);
  return nS(() => {
    function r() {
      return He(this, null, function* () {
        let i = e ? yield iS(e) : null;
        i != null && n(i);
      });
    }
    e && r();
  }, [e]), e ? t ? oS(o, t) : Pe.createElement(Pe.Fragment, null) : e === null || e === !1 ? o : Pe.createElement(aS, null, o);
}
function uS(o) {
  if (Pe.Children.count(o) === 1) {
    let e = Pe.Children.only(o);
    if (Pe.isValidElement(e)) return e.props.id || e.key;
  }
  return null;
}
function cS(o, e, t) {
  if (Pe.Children.count(o) === 1) {
    let n = Pe.Children.only(o);
    if (Pe.isValidElement(n) && n.props[Er]) {
      let r = {};
      return n.props.portalContainer === void 0 && (r.portalContainer = e), n.props.constrainTo === void 0 && (r.constrainTo = t), Pe.cloneElement(n, r);
    }
  }
  return o;
}
globalThis.allhandles = {};
globalThis.thehandles = {};
function Mt(o) {
  let e = o,
    [t] = Ar(() => new Map()),
    [n, r] = Ar(null),
    [i, a] = Ar(0),
    l = Ia(() => {
      let m = [];
      for (let [g, C] of t) m.push(Pe.createElement(lS, D(h({}, C), {
        key: C.key
      })));
      return m;
    }, []),
    [s, u] = Ze(),
    c = sS(l(), o.portalContainer),
    d = Ia((m, g) => {
      let T = `${g.id || uS(m) || it()}`,
        b = t.get(T),
        S = () => {
          var w;
          let y = typeof m == "function" ? m() : m;
          return cS(y, e.portalContainer, (w = g.constrainTo) != null ? w : e.constrainTo);
        };
      if (b) {
        Object.assign(b, g, {
          children: S
        }), u(), r(b.key), a(Date.now());
        return;
      }
      return b = {
        key: T,
        children: S,
        alignPosition: g.alignPosition,
        alignTo: g.alignTo,
        constrainTo: g.constrainTo,
        realign: xe()
      }, t.set(b.key, b), u(), () => u();
    }, [t, e.portalContainer, u]);
  Pe.useEffect(() => {
    if (n) {
      let m = t.get(n);
      m && m.realign(m);
    }
  }, [n, i]);
  let p = m => {
      m = `${m}`, t.has(m) && (t.delete(m), u());
    },
    f = () => {
      t.clear(), u();
    };
  return Pe.useEffect(() => {}, []), {
    portal: c,
    hideOverlay: p,
    clearAll: f,
    rerenderOverlays: u,
    showOverlay: d
  };
}
import * as Op from "react";
import * as Hn from "react";
import * as Ln from "react";
import * as Co from "react";
var Tp = "db3arf0 _16lm1iw0 _16lm1iw1 _16lm1iw16 _16lm1iw1m _16lm1iw8",
  bp = Se({
    defaultClassName: "db3arf2 _16lm1iwz _16lm1iw1q _16lm1iw17",
    variantClassNames: {
      disabled: {
        true: "db3arf3 _16lm1iwj _16lm1iw17",
        false: "_16lm1iwi"
      },
      active: {
        true: "db3arf5",
        false: "db3arf6"
      },
      pressed: {
        false: "db3arf7",
        true: "db3arf8"
      },
      keyboardActive: {
        true: "db3arf9",
        false: "db3arfa"
      }
    },
    defaultVariants: {},
    compoundVariants: [[{
      active: !0,
      disabled: !1
    }, "db3arfb"], [{
      pressed: !0,
      active: !0,
      disabled: !1
    }, "db3arfc"]]
  }),
  Rp = "db3arf1",
  wp = "db3arfd";
var dS = "-";
function pS() {
  return Co.createElement("hr", {
    className: wp
  });
}
var Fr = ({
  columns: o,
  keyboardActiveItemKey: e,
  activeItemKey: t,
  menuId: n
}, r) => {
  let i = r && (r.key != null || r.label != null) ? r : null,
    a = i === null ? r === dS ? Co.createElement(pS, null) : r : null;
  return i != null ? {
    type: "item",
    parentMenuId: n,
    disabled: !!i.disabled,
    keyboardActive: i.key === e,
    active: i.key === t,
    value: i,
    span: i.span || 1,
    key: i.key,
    menu: i.menu || null,
    style: i.style,
    className: i.className,
    originalMenuItem: i
  } : {
    type: "decoration",
    value: a,
    span: o.length,
    style: {
      gridColumnStart: 1,
      gridColumnEnd: o.length + 10
    }
  };
};
function Ip(o, e) {
  return Co.Children.map(e, t => {
    if (t != null && t.props.__is_menu_item) {
      let n = h({}, t.props);
      return n.key || (n.key = t.key), n.description || (n.description = t.props.children), n.label || (n.label = n.children), Fr(o, n);
    }
    return Fr(o, t);
  }) || [];
}
var xp = "submenu";
function vp() {
  let o = Ln.createRef();
  return {
    keyboardActiveItemKey: null,
    activeItemKey: null,
    generatedId: it(),
    focused: !1,
    destroyed: !1,
    domRef: o
  };
}
var Pp = () => ({
    parentMenuId: 1,
    parentMenuItemKey: 1,
    onHideIntent: 1,
    constrainTo: 1,
    onAction: 1,
    onShow: 1,
    onHide: 1,
    autoFocus: 1,
    wrapLabels: 1
  }),
  mS = [{
    name: "label"
  }],
  Dp = o => {
    let {
        props: e,
        state: t
      } = o,
      {
        activeItemKey: n,
        keyboardActiveItemKey: r
      } = t,
      {
        items: i,
        children: a,
        addSubmenuColumnIfNeeded: l,
        parentMenuId: s
      } = e,
      u = e.id || t.generatedId;
    s && (u = `${s}-${u}`);
    let c = [...(e.columns || mS)],
      d = {
        columns: c,
        keyboardActiveItemKey: r,
        activeItemKey: n,
        menuId: u
      },
      p = [];
    i ? p = i.map(Fr.bind(null, d)) : !i && typeof a != "function" && (p = Ip(d, a)), l && p.filter(g => g.type === "item" && g.menu != null).length > 0 && !c.find(g => g.name == xp) && c.push({
      name: xp,
      render: ({
        domProps: g,
        item: C
      }) => C.menu ? Ln.createElement("div", h({}, g), Ln.createElement(xn, {
        expanded: !1
      })) : Ln.createElement("div", h({}, g))
    });
    let f = p.filter(m => m.type === "item" && !m.disabled);
    return {
      runtimeItems: p,
      runtimeSelectableItems: f,
      columns: c,
      menuId: u
    };
  };
import * as at from "react";
import { useCallback as sn, useLayoutEffect as RS, useRef as wS, useState as Pa } from "react";
var So = class extends Bt {
  constructor(e) {
    super(e.slice(0, 3));
  }
};
var fS = 250;
function gS(o, e = 100) {
  let t = Date.now();
  return new Promise(n => {
    let r,
      i = () => {
        if (r = o(), r !== void 0) {
          n(r);
          return;
        }
        if (Date.now() - t > e) {
          n(void 0);
          return;
        }
        be(i);
      };
    be(() => {
      i();
    });
  });
}
var hS = ve(),
  _r = class {
    constructor(e) {
      this.activeItem = null;
      this.currentHoveredItem = null;
      this.activeItemLeavePoint = null;
      this.menuLeaveTimeoutId = null;
      this.activeItemMenuRect = null;
      this.lastPointerMoveTimestamp = 0;
      this.mouseMoveTimeoutId = 0;
      this.lastHorizontalDiff = 0;
      this.getTimeout = () => this.params.timeout || fS;
      this.getPointerMoveTarget = () => document.documentElement || hS;
      this.removePointerMoveListener = () => {
        this.getPointerMoveTarget().removeEventListener("pointermove", this.onPointerMoveWhileActiveItem);
      };
      this.onPointerMoveWhileActiveItem = e => {
        if (clearTimeout(this.mouseMoveTimeoutId), !e) {
          this.lastPointerMoveTimestamp = Date.now();
          return;
        }
        if (!this.currentHoveredItem || this.currentHoveredItem === this.activeItem) return;
        let t = Date.now();
        this.lastPointerMoveTimestamp || (this.lastPointerMoveTimestamp = t);
        let r = t - this.lastPointerMoveTimestamp > this.getTimeout();
        this.lastPointerMoveTimestamp = t;
        let i = () => {
          this.setActiveItem(null, null), this.onItemChange(!0, this.currentHoveredItem, e);
        };
        if (r) {
          i();
          return;
        }
        let a = this.currentHoveredItem;
        this.mouseMoveTimeoutId = setTimeout(() => {
          if (a !== this.currentHoveredItem) return;
          Date.now() - this.lastPointerMoveTimestamp > this.getTimeout() && i();
        }, this.getTimeout());
        let l = new Me({
          left: e.clientX,
          top: e.clientY
        });
        if (!this.activeItemMenuRect || !this.activeItemLeavePoint) return;
        let s = this.activeItemLeavePoint.left < this.activeItemMenuRect.left,
          c = (s ? 1 : -1) * (l.left - this.activeItemLeavePoint.left);
        if (c - this.lastHorizontalDiff < 0) {
          this.lastHorizontalDiff = 0, i();
          return;
        }
        this.lastHorizontalDiff = c, (s ? new So([this.activeItemLeavePoint, this.activeItemMenuRect.getTopLeft(), this.activeItemMenuRect.getBottomLeft()]) : new So([this.activeItemLeavePoint, this.activeItemMenuRect.getTopRight(), this.activeItemMenuRect.getBottomRight()])).containsPoint(l) || (this.setActiveItem(null, null), this.onItemChange(!0, this.currentHoveredItem, e));
      };
      this.setupMenuLeaveTracking = () => {
        this.menuLeaveTimeoutId = setTimeout(() => {
          this.activeItem && this.params.itemHasSubMenu(this.activeItem) || this.setActiveItem(null, null);
        }, this.getTimeout());
      };
      this.setActiveItem = (e, t) => {
        if (e !== this.activeItem) {
          if (e == null) {
            this.removePointerMoveListener(), this.params.onItemActivate(null, null), this.activeItem = null, this.activeItemMenuRect = null;
            return;
          }
          this.activeItem = e, this.params.onItemActivate(e, t), this.activeItemMenuRect = null, gS(() => this.activeItem != e ? !1 : this.params.getMenuRectFor(e)).then(n => {
            this.activeItem == e && n && (this.activeItemMenuRect = n);
          });
        }
      };
      this.onItemChange = (e, t, n) => {
        if (clearTimeout(this.menuLeaveTimeoutId), clearTimeout(this.mouseMoveTimeoutId), !e) {
          this.removePointerMoveListener(), t === this.activeItem && (this.activeItemLeavePoint = new Me({
            left: n.clientX,
            top: n.clientY
          })), this.setupMenuLeaveTracking();
          return;
        }
        if (this.currentHoveredItem = t, !this.activeItem || !this.params.itemHasSubMenu(this.activeItem)) {
          this.setActiveItem(t, n.target);
          return;
        }
        this.onPointerMoveWhileActiveItem(), this.getPointerMoveTarget().addEventListener("pointermove", this.onPointerMoveWhileActiveItem);
      };
      this.getHandlers = () => ({
        onItemEnter: (e, t) => {
          this.onItemChange(!0, e, t);
        },
        onItemLeave: (e, t) => {
          this.onItemChange(!1, e, t);
        }
      });
      this.params = e;
    }
  };
import { createContext as CS, useContext as SS } from "react";
var xa = CS(null);
function va() {
  return SS(xa);
}
import { useCallback as yS, useEffect as TS, useRef as bS } from "react";
function Mp() {
  let o = bS(!0);
  return TS(() => (o.current = !0, () => {
    o.current = !1;
  }), []), yS(() => o.current, []);
}
function IS(o, e) {
  let {
      parentMenuId: t,
      setSubmenuApi: n,
      setActiveItemKey: r,
      setKeyboardActiveItemKey: i,
      constrainTo: a,
      onHideIntent: l,
      api: s
    } = e,
    u = `${t}-submenu`,
    c = o.menu;
  return c ? (typeof c == "function" && (c = c()), at.createElement(ht, D(h({
    key: u,
    parentMenuItemKey: o.key,
    parentMenuId: t,
    constrainTo: a
  }, c), {
    autoFocus: !1,
    onShow: (d, p) => {
      var f;
      (f = c == null ? void 0 : c.onShow) == null || f.call(c, d, p), n(p);
    },
    onHideIntent: d => {
      var p;
      (p = c == null ? void 0 : c.onHideIntent) == null || p.call(c, d), l(d);
    },
    onHide: d => {
      d.focused && (r(null), i(o.key), s.focus());
    }
  }))) : null;
}
function xS(o, e) {
  var a;
  let t = Ep(o, e);
  if (!t.length) return null;
  let n = we.from((a = t[0]) == null ? void 0 : a.getBoundingClientRect()),
    r = we.from(t[t.length - 1].getBoundingClientRect()),
    i = n;
  return i.width = r.left + r.width - n.left, i;
}
function Ep(o, e) {
  let t = dn(document.querySelector(`[data-menu-id="${o}"] [data-menu-item-key="${e}"]`), `[data-menu-id="${o}"]`);
  if (!t) return [];
  let n = t.querySelectorAll(`[data-menu-item-key="${e}"]`);
  return Array.from(n);
}
function vS(o, e) {
  let t = Ep(o, e);
  if (!t.length) return null;
  for (let n of t) {
    let r = n.querySelector('input[type="checkbox"]');
    if (r) return r;
  }
  return null;
}
function PS({
  onItemActivate: o
}) {
  let {
      getState: e
    } = va(),
    [{
      onItemEnter: t,
      onItemLeave: n
    }] = Pa(() => new _r({
      parentMenuId: e().parentMenuId,
      onItemActivate: r => {
        if (!r) {
          o(null);
          return;
        }
        let i = e().runtimeItems.filter(a => a.type === "item" && a.key === r)[0];
        o(i);
      },
      itemHasSubMenu: r => !!e().runtimeItems.filter(a => a.type === "item" && a.key === r && a.menu)[0],
      getMenuRectFor: r => {
        let i = `[data-submenu-for="${r}"]`,
          a = document.querySelector(i);
        return a ? we.from(a.getBoundingClientRect()) : void 0;
      }
    }).getHandlers());
  return {
    onItemEnter: sn(t, []),
    onItemLeave: sn(n, [])
  };
}
function Ap(o) {
  let {
      domProps: e
    } = o,
    {
      componentState: {
        columns: t,
        runtimeItems: n,
        menuId: r,
        activeItemKey: i,
        keyboardActiveItemKey: a,
        constrainTo: l,
        destroyed: s,
        onAction: u,
        wrapLabels: c,
        onShow: d,
        onHide: p,
        autoFocus: f,
        parentMenuItemKey: m,
        domRef: g
      },
      componentActions: C,
      getState: T
    } = va(),
    b = sn(L => {
      C.keyboardActiveItemKey = L;
    }, []),
    S = sn(L => {
      C.activeItemKey = L;
    }, []),
    y = Mp(),
    [w, v] = Pa(null),
    I = null;
  RS(() => {
    let {
        runtimeItems: L
      } = T(),
      N = L.filter(j => j.type === "item" && i === j.key)[0];
    if (!N || !N.menu) {
      v(null), M();
      return;
    }
    if (N) {
      let j = xS(r, N.key);
      j && x(() => IS(N, {
        constrainTo: l,
        setSubmenuApi: v,
        setActiveItemKey: S,
        setKeyboardActiveItemKey: b,
        parentMenuId: r,
        onHideIntent: J,
        api: oe
      }), {
        id: `${r}-submenu`,
        alignTo: j,
        alignPosition: [["TopLeft", "TopRight"], ["TopRight", "TopLeft"]],
        constrainTo: l
      });
      return;
    }
  }, [i]);
  let R = (L, N) => {
      b(N.key), S(N.key), N.originalMenuItem.onClick && N.originalMenuItem.onClick(L);
      let j = {
        key: N.key,
        item: N.originalMenuItem,
        hideMenu: J
      };
      N.originalMenuItem.onAction && N.originalMenuItem.onAction(j), u && u(j), N.originalMenuItem.hideMenuOnAction && J();
    },
    {
      showOverlay: x,
      portal: E,
      clearAll: M
    } = Mt({
      portalContainer: !1
    }),
    {
      onItemEnter: V,
      onItemLeave: z
    } = PS({
      onItemActivate: L => {
        if (!y()) return;
        let N = L ? L.key : null;
        S(N);
      }
    });
  I = n.map((L, N) => at.createElement(DS, {
    key: L.type === "item" ? L.key : N,
    onItemEnter: (j, _) => {
      V(j.key, _);
    },
    onItemLeave: (j, _) => {
      z(j.key, _);
    },
    columns: t,
    active: L.type === "item" && L.key === i,
    keyboardActive: L.type === "item" && L.key === a,
    item: L,
    index: N,
    onClick: R
  }));
  let P = t.map(L => L.width || (typeof L.flex == "number" ? `${L.flex}fr` : L.flex) || "auto").join(" "),
    F = D(h({}, e.style), {
      gridTemplateColumns: P,
      gridTemplateRows: n.map(() => "auto").join(" ")
    });
  c || (F.whiteSpace = "nowrap");
  function A(L) {
    let {
        runtimeItems: N,
        runtimeSelectableItems: j
      } = T(),
      _ = N.findIndex(Y => Y.type === "item" && Y.key === a),
      H = a,
      G = N[_],
      W = {
        ArrowUp: () => {
          let Y = N.slice(0, _).filter(ee => ee.type === "item" && !ee.disabled).pop();
          Y && Y.type === "item" && Y.key && (H = Y.key);
        },
        ArrowDown: () => {
          let Y = N.slice(_ + 1).filter(ee => ee.type === "item" && !ee.disabled)[0];
          Y && Y.type === "item" && Y.key && (H = Y.key);
        },
        Home: () => {
          W.PageUp();
        },
        End: () => {
          W.PageDown();
        },
        PageUp: () => {
          H = j[0].key;
        },
        PageDown: () => {
          H = j[j.length - 1].key;
        },
        ArrowLeft: () => {
          S(null), T().parentMenuId && (C.destroyed = !0);
        },
        ArrowRight: () => {
          G && (S(G.key), be(() => {
            y() && w && w.getParentMenuId() === T().menuId && w.focus();
          }));
        },
        Enter: () => {
          var Y;
          if (G) if ((Y = G.originalMenuItem) != null && Y.menu) {
            if (G && G.originalMenuItem.menu && w) {
              W.ArrowLeft();
              return;
            }
            G && S(G.key);
          } else R(L, G);
        },
        " ": () => {
          if (!G) return;
          G.originalMenuItem.menu || R(L, G);
          let Y = vS(r, G.key);
          Y && Y.click();
        },
        Escape: () => {
          var ee;
          W.ArrowLeft();
          let Y = T();
          Y.parentMenuId || (ee = Y.onHideIntent) == null || ee.call(Y, Y);
        }
      },
      X = W[L.key];
    X && (X(), L.preventDefault()), H != a && b(H);
  }
  function O() {
    C.focused = !1;
  }
  let B = sn(function () {
      C.focused = !0, setTimeout(() => {
        var L, N;
        k.current && g.current && g.current === document.activeElement && T().keyboardActiveItemKey == null && b((N = (L = T().runtimeSelectableItems[0]) == null ? void 0 : L.key) != null ? N : null);
      });
    }, []),
    k = wS(!0),
    J = sn(() => {
      var N;
      let L = T();
      (N = L.onHideIntent) == null || N.call(L, L);
    }, [T]),
    [oe] = Pa(() => ({
      hideMenu: J,
      focus: () => {
        g.current && (g.current.focus(), setTimeout(() => {
          g.current && g.current !== document.activeElement && g.current.focus();
        }, 10), B());
      },
      getMenuId: () => T().menuId,
      getParentMenuId: () => T().parentMenuId
    })),
    q = sn(L => {
      L ? (g.current = L, d == null || d(T(), oe), f && oe.focus()) : (p == null || p(T()), g.current = L);
    }, []),
    Q = at.createElement("div", {
      className: Sn.contents
    }, at.createElement("div", D(h({}, e), {
      "data-menu-id": r,
      "data-submenu-for": m,
      tabIndex: 0,
      ref: q,
      onKeyDown: L => {
        var N;
        (N = e.onKeyDown) == null || N.call(e, L), A(L);
      },
      onMouseDown: L => {
        var N;
        k.current = !1, (N = e.onMouseDown) == null || N.call(e, L);
      },
      onMouseUp: L => {
        var N;
        k.current = !0, (N = e.onMouseUp) == null || N.call(e, L);
      },
      onFocus: B,
      onBlur: O,
      className: te(Tp, e.className, "InfiniteMenu"),
      style: F
    }), I), E);
  return s ? null : Q;
}
function DS(o) {
  let [e, t] = at.useState(!1),
    {
      columns: n,
      item: r,
      index: i,
      active: a,
      keyboardActive: l
    } = o,
    s = r.type === "item" ? r.value.key : i,
    u = null;
  if (r.type === "decoration") u = at.createElement("div", {
    style: r.style
  }, r.value);else {
    let c = 0;
    u = n.map((d, p) => {
      let f = r.value,
        m = d.field || d.name,
        g = p + 1,
        C = g + r.span;
      if (g < c) return null;
      c = C;
      let b = {
        style: D(h(h({}, d.style), r.style), {
          gridColumnStart: g,
          gridColumnEnd: C
        }),
        "data-menu-column-id": `${r.parentMenuId}-col-${d.name}`,
        "data-menu-col-name": `${d.name}`,
        "data-menu-row-id": `${r.parentMenuId}-row-${i}`,
        "data-menu-row-index": `${i}`,
        "data-menu-item-key": `${r.key}`,
        className: te(bp({
          active: a,
          disabled: !!r.disabled,
          keyboardActive: l,
          pressed: e || o.active
        }), r.className),
        onPointerEnter: S => {
          var y;
          r.disabled || (y = o.onItemEnter) == null || y.call(o, r, S);
        },
        onPointerLeave: S => {
          var y;
          r.disabled || (y = o.onItemLeave) == null || y.call(o, r, S);
        },
        onMouseDown: () => {
          r.disabled || t(!0);
        },
        onMouseUp: () => {
          r.disabled || t(!1);
        },
        onClick: S => {
          r.disabled || o.onClick(S, r);
        }
      };
      return d.render ? at.createElement(io, {
        key: `${s}-${m}`,
        render: d.render,
        renderParam: {
          item: f,
          column: d,
          value: f[m],
          domProps: b
        }
      }) : at.createElement("div", h({
        key: `${s}-${m}`
      }, b), f[m]);
    }).filter(Boolean);
  }
  return at.createElement("div", {
    className: Rp
  }, u);
}
function MS(o) {
  return null;
}
MS.defaultProps = {
  __is_menu_item: !0
};
var ES = bt({
  initSetupState: vp,
  forwardProps: Pp,
  mapPropsToState: Dp,
  layoutEffect: !0
});
function AS(o) {
  let {
      componentActions: e,
      componentState: t
    } = Ie(),
    n = _e(t);
  return Hn.createElement(xa.Provider, {
    value: {
      componentActions: e,
      componentState: t,
      getState: n
    }
  }, Hn.createElement(Ap, {
    domProps: o.domProps
  }));
}
function ht(o) {
  let y = o,
    {
      children: e,
      portalContainer: t,
      items: n,
      parentMenuId: r,
      addSubmenuColumnIfNeeded: i,
      bubbleActionsFromSubmenus: a,
      onShow: l,
      onHide: s,
      wrapLabels: u,
      onAction: c,
      constrainTo: d,
      columns: p,
      parentMenuItemKey: f,
      autoFocus: m,
      id: g,
      onHideIntent: C,
      __is_infinite_menu_component: T
    } = y,
    b = un(y, ["children", "portalContainer", "items", "parentMenuId", "addSubmenuColumnIfNeeded", "bubbleActionsFromSubmenus", "onShow", "onHide", "wrapLabels", "onAction", "constrainTo", "columns", "parentMenuItemKey", "autoFocus", "id", "onHideIntent", "__is_infinite_menu_component"]);
  return Hn.createElement(ES, h({}, o), Hn.createElement(AS, {
    domProps: b
  }));
}
var FS = {
  addSubmenuColumnIfNeeded: !0,
  bubbleActionsFromSubmenus: !0,
  wrapLabels: !1,
  [Er]: !0
};
ht.defaultProps = FS;
import * as Fp from "react";
function _p(o, e) {
  let {
      columnApi: t,
      column: n,
      getComputed: r,
      api: i
    } = e,
    l = t.isSortable() ? [{
      key: "sort-asc",
      label: "Sort Ascending",
      disabled: n.computedSortedAsc,
      onAction: () => {
        i.setSortingForColumn(n.id, 1);
      }
    }, {
      key: "sort-desc",
      label: "Sort Descending",
      disabled: n.computedSortedDesc,
      onAction: () => {
        i.setSortingForColumn(n.id, -1);
      }
    }, {
      key: "sort-none",
      label: "Unsort",
      disabled: !n.computedSorted,
      onAction: () => {
        i.setSortingForColumn(n.id, null);
      }
    }, "-"] : [];
  return [...(n.groupByForColumn ? [{
    key: "collapse-all",
    label: "Collapse All",
    onAction: () => {
      i.collapseAllGroupRows();
    }
  }, "-"] : []), ...l, n.computedFilterable ? {
    key: "clear-filter",
    label: "Clear Filter",
    disabled: !n.computedFiltered,
    onAction: () => {
      i.clearColumnFilter(n.id);
    }
  } : null, n.computedFilterable ? "-" : null, {
    key: "pin-start",
    label: "Pin to start",
    disabled: n.computedPinned === "start",
    onAction: () => {
      i.setPinningForColumn(n.id, "start");
    }
  }, {
    key: "unpin",
    label: "Unpin",
    disabled: !n.computedPinned,
    onAction: () => {
      i.setPinningForColumn(n.id, !1);
    }
  }, "-", {
    key: "columns",
    label: "Columns",
    menu: () => {
      let u = [];
      return r().computedColumnsMapInInitialOrder.forEach((d, p) => {
        let f = Qo(d, e);
        u.push({
          key: p,
          label: f,
          check: Fp.createElement(Gt, {
            key: d.id,
            disabled: d.computedVisible && i.getVisibleColumnsCount() === 1,
            checked: d.computedVisible,
            onChange: m => {
              let g = !!m;
              i.setVisibilityForColumn(d.id, g), requestAnimationFrame(() => {
                i.realignColumnContextMenu();
              });
            }
          })
        });
      }), {
        columns: [{
          name: "check"
        }, {
          name: "label"
        }],
        items: u
      };
    }
  }].filter(u => !!u);
}
function Vp(o, e, t) {
  var m;
  if (o == null) return null;
  let {
      getComputed: n,
      getState: r
    } = e,
    {
      components: i,
      getColumnMenuItems: a
    } = r(),
    l = (m = i == null ? void 0 : i.Menu) != null ? m : ht,
    s = n().computedColumnsMap.get(o);
  if (!s) return null;
  let u = h({
      column: s,
      columnApi: We(s.id, e)
    }, e),
    c = _p([], u),
    d = a ? a(c, u) : c,
    p = g => {
      g.__insideMenu = !0;
    },
    f = g => {
      var C, T;
      (T = (C = g.domRef.current) == null ? void 0 : C.parentNode) == null || T.removeEventListener("mousedown", p);
    };
  return !d || !d.length ? null : Op.createElement(l, {
    autoFocus: !0,
    items: d,
    onShow: g => {
      var C, T;
      (T = (C = g.domRef.current) == null ? void 0 : C.parentNode) == null || T.addEventListener("mousedown", p);
    },
    onHide: f,
    onHideIntent: g => {
      f(g), t == null || t();
    }
  });
}
var _S = `[${Ki["data-name"]}="${Bi["data-name"]}"]`;
function OS(o) {
  var d;
  let {
      columnId: e,
      context: t,
      clearAll: n,
      showOverlay: r
    } = o,
    {
      getState: i,
      actions: a
    } = t,
    {
      columnMenuTargetRef: l,
      domRef: s
    } = i();
  function u() {
    n(), a.columnMenuVisibleForColumnId = null;
  }
  let c = o.target;
  if (c || (c = (d = l.current) != null ? d : void 0, c && !s.current.contains(c) && (c = void 0)), !c) {
    let p = `[${dc["data-column-id"]}="${e}"] ${_S}`;
    c = i().domRef.current.querySelector(p);
  }
  if (!c) {
    console.warn(`Cannot show column menu for column "${e}"`);
    return;
  }
  l.current = null, r(() => Vp(e, t, u), {
    constrainTo: s.current,
    id: "column-menu",
    alignTo: c,
    alignPosition: [["TopLeft", "BottomLeft"], ["TopRight", "BottomRight"]]
  });
}
function Gp() {
  let o = ae(),
    e = Ne(),
    {
      getState: t,
      actions: n
    } = o,
    {
      showOverlay: r,
      portal: i,
      clearAll: a
    } = Mt({
      portalContainer: e ? e.getMasterState().portalDOMRef.current : !1
    });
  zp(() => {
    let {
        actions: u,
        getState: c
      } = o,
      d = c();
    return d.onColumnMenuClick.onChange(p => {
      p && (d.columnMenuTargetRef.current = p.target, u.contextMenuVisibleFor = null, u.cellContextMenuVisibleFor = null, u.filterOperatorMenuVisibleForColumnId = null, u.columnMenuVisibleForColumnId = p.column.id);
    });
  }, []);
  let {
    columnMenuVisibleForColumnId: l,
    columnMenuVisibleKey: s
  } = t();
  return zp(() => {
    let {
      columnMenuVisibleForColumnId: u,
      columnMenuTargetRef: c
    } = t();
    if (u) {
      let p = function (f) {
        f.__insideMenu !== !0 && (a(), n.columnMenuVisibleForColumnId = null);
      };
      var d = p;
      return document.documentElement.addEventListener("mousedown", p), OS({
        columnId: u,
        context: o,
        clearAll: a,
        showOverlay: r
      }), () => {
        document.documentElement.removeEventListener("mousedown", p);
      };
    } else c.current = null, a();
    return () => {};
  }, [l, s]), {
    menuPortal: i
  };
}
import * as Lp from "react";
import { useCallback as VS } from "react";
var zS = {
  width: 0,
  height: 0,
  overflow: "hidden",
  pointerEvents: "none",
  outline: "none",
  zIndex: -1
};
function Hp() {
  let {
      getState: o,
      api: e,
      actions: t,
      getComputed: n,
      dataSourceApi: r,
      dataSourceActions: i
    } = ae(),
    {
      getState: a,
      getDataSourceMasterContext: l
    } = he(),
    {
      focusDetectDOMRef: s
    } = o(),
    u = VS(() => He(this, null, function* () {
      lp({
        getDataSourceState: a,
        getDataSourceMasterContext: l,
        getComputed: n,
        actions: t,
        dataSourceActions: i,
        api: e,
        getState: o,
        dataSourceApi: r
      });
    }), []);
  return Lp.createElement("div", {
    onFocus: u,
    ref: s,
    tabIndex: 0,
    style: zS
  });
}
import { useEffect as yo } from "react";
function GS() {
  let o = ae(),
    {
      getState: e
    } = o,
    {
      editingCell: t
    } = e(),
    n = t && !t.active ? t.cancelled : void 0;
  yo(() => {
    if (n) {
      let {
          rowIndex: r,
          columnId: i,
          initialValue: a
        } = e().editingCell,
        {
          onEditCancelled: l
        } = e();
      l == null || l(D(h({}, qe(h({
        rowIndex: r,
        columnId: i
      }, o))), {
        initialValue: a
      }));
    }
  }, [n]);
}
function LS() {
  let o = ae(),
    {
      getState: e,
      state: {
        editingCell: t
      }
    } = o,
    n = t && !t.active && t.accepted instanceof Error ? t.accepted : void 0;
  yo(() => {
    if (n) {
      let {
          rowIndex: r,
          columnId: i,
          value: a,
          initialValue: l
        } = e().editingCell,
        {
          onEditRejected: s
        } = e();
      s == null || s(D(h({}, qe(h({
        rowIndex: r,
        columnId: i
      }, o))), {
        value: a,
        error: n,
        initialValue: l
      }));
    }
  }, [n]);
}
function HS() {
  let o = ae(),
    {
      state: {
        editingCell: e
      }
    } = o,
    t = e == null ? void 0 : e.active,
    n = Wo(t);
  yo(() => {
    !t && n && o.api.focus();
  }, [t, n]);
}
function NS() {
  let o = ae(),
    {
      state: {
        editingCell: e
      },
      getState: t
    } = o,
    n = e && !e.active && !e.cancelled && e.accepted === !0;
  yo(() => {
    var r, i;
    if (n) {
      let {
          editingCell: a
        } = t(),
        {
          value: l,
          rowIndex: s,
          columnId: u,
          initialValue: c
        } = a,
        d = D(h({}, qe(h({
          rowIndex: s,
          columnId: u
        }, o))), {
          value: l,
          initialValue: c
        });
      (i = (r = t()).onEditAccepted) == null || i.call(r, d), o.api.persistEdit({
        value: l
      });
    }
  }, [n]);
}
function KS() {
  let o = ae(),
    {
      state: {
        editingCell: e
      },
      getState: t
    } = o,
    n = e ? e.persisted : void 0;
  yo(() => {
    if (n) {
      let {
        editingCell: r,
        onEditPersistError: i,
        onEditPersistSuccess: a
      } = t();
      if (!r) return;
      let {
          rowIndex: l,
          columnId: s,
          value: u,
          initialValue: c
        } = r,
        d = D(h({}, qe(h({
          rowIndex: l,
          columnId: s
        }, o))), {
          value: u,
          initialValue: c
        });
      n instanceof Error ? i == null || i(D(h({}, d), {
        error: n
      })) : a == null || a(d);
    }
  }, [n]);
}
function Np() {
  GS(), LS(), NS(), KS(), HS();
}
import { useEffect as Wp } from "react";
import * as kt from "react";
import * as Da from "react";
var Kp = o => Da.createElement(De, h({}, o), Da.createElement("path", {
  d: "M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
}));
import * as Ma from "react";
var Bp = o => Ma.createElement(De, h({}, o), Ma.createElement("path", {
  d: "m9.55 19-6.725-6.725L5.25 9.85l4.3 4.325 9.225-9.225 2.425 2.4Z"
}));
function kp(o, e, t) {
  var I;
  if (o == null) return null;
  let {
      getComputed: n,
      getState: r,
      getDataSourceState: i,
      actions: a,
      dataSourceApi: l,
      dataSourceActions: s,
      api: u
    } = e,
    {
      components: c,
      getFilterOperatorMenuItems: d
    } = r(),
    p = (I = c == null ? void 0 : c.Menu) != null ? I : ht,
    f = n().computedColumnsMap.get(o);
  if (!f) return null;
  let m = R => {
      R.__insideMenu = !0;
    },
    g = R => {
      var x, E;
      (E = (x = R.domRef.current) == null ? void 0 : x.parentNode) == null || E.removeEventListener("mousedown", m);
    },
    {
      filterTypes: C
    } = i(),
    T = C[f.computedFilterType];
  if (!T) return null;
  let b = T.operators.map(R => {
      var V, z, P;
      let x = R.name,
        E = f.computedFilterValue ? f.computedFilterValue.filter.operator === x : x === T.defaultOperator,
        M = (z = (V = R.components) == null ? void 0 : V.Icon) != null ? z : Rn;
      return {
        key: x,
        icon: kt.createElement(M, null),
        label: kt.createElement(kt.Fragment, null, (P = R.label) != null ? P : R.name),
        onAction: () => {
          u.setColumnFilterOperator(o, x);
        },
        checked: E ? kt.createElement(Bp, {
          size: 16
        }) : null
      };
    }),
    y = [...[{
      key: "close",
      label: "Close Menu",
      icon: null,
      onAction: () => {
        u.hideFilterOperatorMenu();
      }
    }, {
      key: "reset",
      label: "Reset",
      icon: kt.createElement(Kp, null),
      disabled: !f.computedFiltered,
      onAction: () => {
        u.clearColumnFilter(o);
      }
    }, "-"], ...b],
    w = {
      column: f,
      api: u,
      getState: r,
      getDataSourceState: i,
      getComputed: n,
      actions: a,
      dataSourceApi: l,
      dataSourceActions: s,
      columnFilterValue: f.computedFilterValue,
      filterTypes: C
    },
    v = d ? d(y, w) : y;
  return kt.createElement(p, {
    autoFocus: !0,
    columns: [{
      name: "icon"
    }, {
      name: "label"
    }, {
      name: "checked"
    }],
    items: v,
    onShow: R => {
      var x, E;
      (E = (x = R.domRef.current) == null ? void 0 : x.parentNode) == null || E.addEventListener("mousedown", m);
    },
    onHide: g,
    onHideIntent: R => {
      g(R), t == null || t();
    }
  });
}
var Or = 10;
function $p() {
  let o = ae(),
    e = Ne(),
    {
      getState: t,
      actions: n
    } = o,
    {
      showOverlay: r,
      portal: i,
      clearAll: a
    } = Mt({
      portalContainer: e ? e.getMasterState().portalDOMRef.current : !1
    });
  return Wp(() => {
    let {
      actions: l,
      getState: s
    } = o;
    return s().onFilterOperatorMenuClick.onChange(c => {
      if (!c) return;
      let {
        target: d,
        column: p
      } = c;
      function f() {
        a(), l.filterOperatorMenuVisibleForColumnId = null;
      }
      let m = (d == null ? void 0 : d.parentElement) || d,
        g = we.from(m.getBoundingClientRect());
      g.top -= Or, g.left -= Or, g.width += 2 * Or, g.height += 2 * Or, r(() => kp(p.id, o, f), {
        constrainTo: s().domRef.current,
        id: "filter-operator-menu",
        alignTo: g,
        alignPosition: [["TopLeft", "BottomLeft"], ["TopRight", "BottomRight"]]
      }), l.contextMenuVisibleFor = null, l.cellContextMenuVisibleFor = null, l.columnMenuVisibleForColumnId = null, l.filterOperatorMenuVisibleForColumnId = p.id;
    });
  }, []), Wp(() => {
    let {
      filterOperatorMenuVisibleForColumnId: l
    } = t();
    if (l) {
      let u = function (c) {
        c.__insideMenu !== !0 && (a(), n.filterOperatorMenuVisibleForColumnId = null);
      };
      var s = u;
      return document.documentElement.addEventListener("mousedown", u), () => {
        document.documentElement.removeEventListener("mousedown", u);
      };
    } else a();
    return () => {};
  }, [t().filterOperatorMenuVisibleForColumnId]), {
    menuPortal: i
  };
}
import { useEffect as Vr } from "react";
import * as Ea from "react";
function jp(o, e, t) {
  var S;
  let {
      columnId: n,
      rowIndex: r,
      event: i
    } = o,
    {
      getComputed: a,
      getState: l
    } = e,
    {
      components: s,
      getCellContextMenuItems: u
    } = l(),
    c = (S = s == null ? void 0 : s.Menu) != null ? S : ht,
    {
      computedColumnsMap: d
    } = a(),
    p = d.get(n),
    f = D(h({}, qe(D(h({}, e), {
      rowIndex: r,
      columnId: p.id
    }))), {
      event: i
    });
  if (!p || !u) return {
    cellContext: f,
    menu: null,
    preventDefault: !1
  };
  let m = u(f, e),
    g = m ? Array.isArray(m) ? m : m.items : null,
    C = m && !Array.isArray(m) ? m.columns : void 0,
    T = y => {
      y.__insideMenu = !0;
    },
    b = y => {
      var w, v;
      (v = (w = y.domRef.current) == null ? void 0 : w.parentNode) == null || v.removeEventListener("mousedown", T);
    };
  return !g || !g.length ? {
    cellContext: f,
    menu: null,
    preventDefault: Array.isArray(g)
  } : {
    preventDefault: !0,
    menu: Ea.createElement(c, {
      columns: C,
      autoFocus: !0,
      items: g,
      onShow: y => {
        var w, v;
        (v = (w = y.domRef.current) == null ? void 0 : w.parentNode) == null || v.addEventListener("mousedown", T);
      },
      onHide: b,
      onHideIntent: y => {
        b(y), t == null || t();
      }
    }),
    cellContext: f
  };
}
function Up(o, e, t) {
  var C;
  let {
      getState: n,
      getComputed: r
    } = e,
    {
      components: i,
      getContextMenuItems: a
    } = n(),
    l = (C = i == null ? void 0 : i.Menu) != null ? C : ht,
    {
      computedColumnsMap: s
    } = r(),
    u = o.columnId ? s.get(o.columnId) : void 0,
    c = u ? h(h({}, o), qe(D(h({}, e), {
      rowIndex: o.rowIndex,
      columnId: u.id
    }))) : o;
  if (!a) return {
    cellContext: c,
    menu: null,
    preventDefault: !1
  };
  let d = a(c, e),
    p = d ? Array.isArray(d) ? d : d.items : null,
    f = d && !Array.isArray(d) ? d.columns : void 0,
    m = T => {
      T.__insideMenu = !0;
    },
    g = T => {
      var b, S;
      (S = (b = T.domRef.current) == null ? void 0 : b.parentNode) == null || S.removeEventListener("mousedown", m);
    };
  return !p || !p.length ? {
    cellContext: c,
    menu: null,
    preventDefault: Array.isArray(p)
  } : {
    preventDefault: !0,
    menu: Ea.createElement(l, {
      columns: f,
      autoFocus: !0,
      items: p,
      onShow: T => {
        var b, S;
        (S = (b = T.domRef.current) == null ? void 0 : b.parentNode) == null || S.addEventListener("mousedown", m);
      },
      onHide: g,
      onHideIntent: T => {
        g(T), t == null || t();
      }
    }),
    cellContext: c
  };
}
var zr = 5,
  Yp = [["TopLeft", "BottomLeft"], ["TopRight", "BottomRight"], ["BottomLeft", "BottomLeft"], ["BottomRight", "BottomRight"], ["TopCenter", "TopLeft"], ["BottomCenter", "TopLeft"], ["CenterLeft", "TopLeft"], ["CenterRight", "TopLeft"]];
function qp() {
  let o = ae(),
    e = Ne(),
    {
      getState: t,
      actions: n
    } = o,
    {
      showOverlay: r,
      portal: i,
      clearAll: a
    } = Mt({
      portalContainer: e ? e.getMasterState().portalDOMRef.current : !1
    });
  return Vr(() => {
    let {
      actions: l,
      getState: s,
      getDataSourceState: u
    } = o;
    return s().cellContextMenu.onChange(d => {
      if (!d) return;
      let {
        event: p
      } = d;
      function f() {
        a(), l.cellContextMenuVisibleFor = null;
      }
      let {
          menu: m,
          cellContext: g,
          preventDefault: C
        } = jp(d, o, f),
        T = we.fromPoint({
          top: p.clientY,
          left: p.clientX
        });
      T.width += zr, T.height += zr, r(m, {
        constrainTo: s().domRef.current,
        id: "cell-context-menu",
        alignTo: T,
        alignPosition: Yp
      }), C && p.preventDefault(), l.cellContextMenuVisibleFor = {
        columnId: d.columnId,
        rowId: d.rowId,
        rowIndex: d.rowIndex,
        colIndex: d.colIndex
      };
      let {
        onCellContextMenu: b
      } = s();
      b && b(D(h({}, g), {
        getState: s,
        getDataSourceState: u
      }), p);
    });
  }, []), Vr(() => {
    let {
      cellContextMenuVisibleFor: l
    } = t();
    if (l) {
      let u = function (c) {
        c.__insideMenu !== !0 && (a(), n.cellContextMenuVisibleFor = null);
      };
      var s = u;
      return document.documentElement.addEventListener("mousedown", u), () => {
        document.documentElement.removeEventListener("mousedown", u);
      };
    } else a();
    return () => {};
  }, [t().cellContextMenuVisibleFor]), {
    menuPortal: i
  };
}
function Xp() {
  let o = ae(),
    e = Ne(),
    {
      getState: t,
      actions: n
    } = o,
    {
      showOverlay: r,
      portal: i,
      clearAll: a
    } = Mt({
      portalContainer: e ? e.getMasterState().portalDOMRef.current : !1
    });
  return Vr(() => {
    let {
      actions: l,
      getState: s
    } = o;
    return s().contextMenu.onChange(c => {
      if (!c) return;
      let {
        event: d
      } = c;
      function p() {
        a(), l.contextMenuVisibleFor = null;
      }
      let {
          menu: f,
          cellContext: m,
          preventDefault: g
        } = Up(c, o, p),
        C = {
          top: d.clientY,
          left: d.clientX
        },
        T = we.fromPoint(C);
      T.width += zr, T.height += zr, r(f, {
        constrainTo: s().domRef.current,
        id: "table-context-menu",
        alignTo: T,
        alignPosition: Yp
      }), g && d.preventDefault(), l.contextMenuVisibleFor = h({
        point: C
      }, c);
      let {
        onContextMenu: b
      } = s();
      b && b(h(h({}, o), m), d);
    });
  }, []), Vr(() => {
    let {
      contextMenuVisibleFor: l
    } = t();
    if (l) {
      let u = function (c) {
        c.__insideMenu !== !0 && (a(), n.contextMenuVisibleFor = null);
      };
      var s = u;
      return document.documentElement.addEventListener("mousedown", u), () => {
        document.documentElement.removeEventListener("mousedown", u);
      };
    } else a();
    return () => {};
  }, [t().contextMenuVisibleFor]), {
    menuPortal: i
  };
}
var Vt = Re.rootClassName,
  BS = [zu, "InfiniteColumnCell--hovered"],
  kS = bt({
    initSetupState: Dd,
    forwardProps: Md,
    mapPropsToState: Fd,
    cleanup: Ed,
    allowedControlledPropOverrides: {
      rowHeight: !0,
      columnHeaderHeight: !0
    },
    getParentState: () => cn(),
    debugName: "InfiniteTable"
  }),
  WS = ge.memo(function () {
    var pe;
    let e = ae(),
      t = Ne(),
      {
        state: n,
        getComputed: r,
        computed: i,
        api: a
      } = e,
      {
        componentState: {
          loading: l,
          dataArray: s
        },
        getState: u,
        componentActions: c
      } = he(),
      {
        domRef: d,
        scrollerDOMRef: p,
        portalDOMRef: f,
        licenseKey: m,
        loadingText: g,
        rowDetailRenderer: C,
        header: T,
        onRowHeightCSSVarChange: b,
        onRowDetailHeightCSSVarChange: S,
        onColumnHeaderHeightCSSVarChange: y,
        rowHeightCSSVar: w,
        rowDetailHeightCSSVar: v,
        columnHeaderHeightCSSVar: I,
        components: R,
        scrollStopDelay: x,
        brain: E,
        headerBrain: M,
        renderer: V,
        keyboardNavigation: z,
        activeRowIndex: P,
        activeCellIndex: F,
        onRenderUpdater: A,
        debugId: O
      } = n;
    Xd(P, s.length, a), Ud(F, s.length, a);
    let {
        onKeyDown: B
      } = cp(),
      {
        bodySize: k
      } = n,
      {
        scrollbars: J,
        computedRowHeight: oe,
        computedRowSizeCacheForDetails: q
      } = i,
      Q = (q == null ? void 0 : q.getRowHeight) || oe,
      {
        renderCell: L,
        renderDetailRow: N
      } = Td({
        imperativeApi: a,
        getComputed: r,
        domRef: n.domRef,
        bodySize: k,
        computed: i
      });
    ge.useEffect(() => {
      let ue = u(),
        Ae = St(ze => {
          ue.notifyRenderRangeChange({
            renderStartIndex: ze[0],
            renderEndIndex: ze[1]
          });
        }, {
          wait: x
        });
      return E.onVerticalRenderRangeChange(Ae);
    }, [E, x]);
    let j = Wd(m),
      _ = wu(n.domProps),
      H = (pe = R == null ? void 0 : R.LoadMask) != null ? pe : gr;
    ge.useEffect(() => {
      E.setScrollStopDelay(x), c.scrollStopDelayUpdatedByTable = x;
    }, [x]), fd(), Np();
    let {
        menuPortal: G
      } = Gp(),
      {
        menuPortal: W
      } = qp(),
      {
        menuPortal: X
      } = Xp(),
      {
        menuPortal: Y
      } = $p();
    ge.useEffect(() => {
      typeof globalThis.__DO_NOT_USE_UNLESS_YOU_KNOW_WHAT_YOURE_DOING_IS_READY == "function" && globalThis.__DO_NOT_USE_UNLESS_YOU_KNOW_WHAT_YOURE_DOING_IS_READY(n.id, n.ready, e.api, e);
    }, [n.ready]);
    let ee = ge.useCallback(ue => {
      let Ae = e.getState(),
        ze = ue.target;
      if (!t && ue._from_row_detail) return;
      t && (ue._from_row_detail = !0);
      let Ge = Kl(ze, ga(), Ae.domRef.current),
        fe,
        ye,
        Ke,
        se;
      Ge && (ye = Number(Ge.dataset.colIndex), se = Number(Ge.dataset.rowIndex), fe = e.getComputed().computedVisibleColumns[ye].id, Ke = e.dataSourceApi.getRowInfoArray()[se].id);
      let lt = {
        columnId: fe,
        colIndex: ye,
        rowId: Ke,
        rowIndex: se,
        event: ue,
        target: Ge != null ? Ge : ue.target
      };
      Ge && Ae.cellContextMenu(lt), Ae.contextMenu(lt);
    }, []);
    return ge.useEffect(() => {
      t && (f.current = t.getMasterState().portalDOMRef.current);
    }, []), ge.createElement("div", h({
      onKeyDown: B,
      ref: d
    }, _), T ? ge.createElement(xc, {
      bodyBrain: E,
      headerBrain: M,
      scrollbars: J
    }) : null, ge.createElement(Ts, {
      onContextMenu: ee
    }, ge.createElement(rs, {
      debugId: O,
      tabIndex: 0,
      activeRowIndex: n.ready && z === "row" ? P : null,
      activeCellIndex: n.ready && z === "cell" && !n.columnReorderDragColumnId ? F : null,
      scrollStopDelay: x,
      renderer: V,
      onRenderUpdater: A,
      brain: E,
      activeCellRowHeight: Q,
      renderCell: L,
      renderDetailRow: C ? N : void 0,
      cellHoverClassNames: BS,
      scrollerDOMRef: p
    }), ge.createElement(H, {
      visible: l
    }, g)), ge.createElement("div", {
      ref: f,
      className: te(`${_t}Portal`, ys[1e7], yn.absolute, gs[0], ps[0])
    }, G, W, X, Y), w ? ge.createElement(Po, {
      varName: w,
      onChange: b
    }) : null, v ? ge.createElement(Po, {
      varName: v,
      onChange: S
    }) : null, I ? ge.createElement(Po, {
      varName: I,
      onChange: y
    }) : null, ge.createElement(bs, null), j ? null : ge.createElement(Mc, null), ge.createElement(Hp, null));
  });
function $S() {
  let {
      componentActions: o,
      componentState: e
    } = Ie(),
    {
      scrollerDOMRef: t,
      scrollTopKey: n
    } = e,
    r = Nd(),
    i = _e(r),
    a = _e(e),
    l = Ne(),
    {
      getState: s,
      componentActions: u,
      getDataSourceMasterContext: c,
      api: d
    } = he(),
    [p] = ge.useState(() => pd({
      getComputed: i,
      getState: a,
      getDataSourceState: s,
      getDataSourceMasterContext: c,
      dataSourceApi: d,
      actions: o,
      dataSourceActions: u
    })),
    f = {
      actions: o,
      state: e,
      computed: r,
      dataSourceActions: u,
      getDataSourceMasterContext: c,
      getDataSourceState: s,
      getComputed: i,
      getState: a,
      api: p,
      dataSourceApi: d
    };
  $n(t, g => {
    let C = {
      width: g.width,
      height: g.height
    };
    o.bodySize = C;
  }, {
    earlyAttach: !0,
    debounce: 50
  }), ge.useEffect(() => {
    t.current && (t.current.scrollTop = 0);
  }, [n, t]);
  let m = Tn();
  return ge.createElement(m.Provider, {
    value: f
  }, ge.createElement(WS, null));
}
function jS(o) {
  return ge.createElement(kS, h({}, o), ge.createElement($S, null));
}
jS.defaultProps = {
  rowHeight: 40,
  columnHeaderHeight: Zd(Nl)
};
var Aa = class {
    constructor(e, t) {
      this.orderRefs = [];
      if (typeof e == "number") this.maxSize = e, this.currentSet = new Set();else if (this.maxSize = t != null ? t : Aa.DEFAULT_SIZE, e) {
        let n = Array.from(e);
        this.maxSize < n.length && n.slice(n.length - this.maxSize), this.currentSet = new Set(), n.forEach(r => {
          this.add(r);
        });
      } else this.currentSet = new Set();
    }
    values() {
      return this.currentSet.values();
    }
    get size() {
      return this.currentSet.size;
    }
    delete(e) {
      return this.currentSet.has(e) ? (this.currentSet.delete(e), this.orderRefs = this.orderRefs.filter(t => t !== e), !0) : !1;
    }
    has(e) {
      return this.currentSet.has(e);
    }
    add(e) {
      if (this.currentSet.has(e)) return this;
      if (this.maxSize - this.currentSet.size <= 0) {
        let [n] = this.orderRefs.splice(0, 1);
        n && this.currentSet.delete(n);
      }
      return this.orderRefs.push(e), this.currentSet.add(e), this;
    }
  },
  Gr = Aa;
Gr.DEFAULT_SIZE = 10;
var Fa = class {
    constructor(e, t) {
      this.orderRefs = [];
      if (typeof e == "number") this.maxSize = e, this.currentSet = new Set();else if (this.maxSize = t != null ? t : Fa.DEFAULT_SIZE, e) {
        let n = Array.from(e);
        this.maxSize < n.length && n.slice(n.length - this.maxSize), this.currentSet = new Set(), n.forEach(r => {
          this.add(r);
        });
      } else this.currentSet = new Set();
    }
    values() {
      return this.currentSet.values();
    }
    get size() {
      return this.currentSet.size;
    }
    delete(e) {
      return this.currentSet.has(e) ? (this.currentSet.delete(e), this.orderRefs = this.orderRefs.filter(t => t.deref() !== e), !0) : !1;
    }
    has(e) {
      return this.currentSet.has(e);
    }
    add(e) {
      if (this.currentSet.has(e)) return this;
      if (this.maxSize - this.currentSet.size <= 0) {
        let [n] = this.orderRefs.splice(0, 1),
          r = n.deref();
        r && this.currentSet.delete(r);
      }
      return this.orderRefs.push(new WeakRef(e)), this.currentSet.add(e), this;
    }
  },
  Lr = Fa;
Lr.DEFAULT_SIZE = 10;
var hL = {
  CheckBox: Gt,
  LoadMask: gr,
  MenuIcon: ao,
  StringFilterEditor: In,
  NumberFilterEditor: or
};
export { Ee as CellSelectionState, M1 as DataSource, Oh as DataSourceActionType, le as DeepMap, Gr as FixedSizeSet, Xe as GroupRowsState, jS as InfiniteTable, Vt as InfiniteTableClassName, WS as InfiniteTableComponent, ht as Menu, mo as RowDetailCache, Qe as RowDetailState, me as RowSelectionState, Lr as WeakFixedSizeSet, hL as components, St as debounce, yt as debug, Zt as defaultFilterTypes, Zt as filterTypes, ah as flatten, bt as getComponentStateRoot, qi as group, Pt as interceptMap, It as multisort, Ie as useComponentState, cn as useDataSource, Fn as useEffectWithChanges, FE as useEffectWithObject, Qu as useInfiniteColumnCell, Gi as useInfiniteColumnEditor, to as useInfiniteColumnFilterEditor, nr as useInfiniteHeaderCell, A1 as useMasterRowInfo, Mt as useOverlay, sS as useOverlayPortal, E1 as useRowInfoReducers };
//!collapsed || lazyLoad;