/**
 * @ReduxAction Tool Panels have been set in AdapTable ToolPanel Component
 */
export const TOOLPANEL_SET_TOOLPANELS = 'TOOLPANEL_SET_TOOLPANELS';
/**
 * @ReduxAction A Tool Panel has been made visible
 */
export const TOOLPANEL_SHOW_TOOLPANEL = 'TOOLPANEL_SHOW_TOOLPANEL';
/**
 * @ReduxAction A Tool Panel has been hidden
 */
export const TOOLPANEL_HIDE_TOOLPANEL = 'TOOLPANEL_HIDE_TOOLPANEL';
/**
 * @ReduxAction A Tool Panel has been expanded
 */
export const TOOLPANEL_EXPAND_TOOLPANEL = 'TOOLPANEL_EXPAND_TOOLPANEL';
/**
 * @ReduxAction A Tool Panel has been collapsed
 */
export const TOOLPANEL_COLLAPSE_TOOLPANEL = 'TOOLPANEL_COLLAPSE_TOOLPANEL';
/**
 * @ReduxAction Module Buttons have set in AdapTable ToolPanel Component header
 */
export const TOOLPANEL_SET_MODULE_BUTTONS = 'TOOLPANEL_SET_MODULE_BUTTONS';
/**
 * @ReduxAction AdapTable ToolPanel Component is ready
 */
export const TOOLPANEL_READY = 'TOOLPANEL_READY';
export const ToolPanelSetToolPanels = toolPanels => ({
  type: TOOLPANEL_SET_TOOLPANELS,
  toolPanels
});
export const ToolPanelShowToolPanel = toolPanel => ({
  type: TOOLPANEL_SHOW_TOOLPANEL,
  toolPanel
});
export const ToolPanelHideToolPanel = toolPanel => ({
  type: TOOLPANEL_HIDE_TOOLPANEL,
  toolPanel
});
export const ToolPanelExpandToolPanel = toolPanel => ({
  type: TOOLPANEL_EXPAND_TOOLPANEL,
  toolPanel
});
export const ToolPanelCollapseToolPanel = toolPanel => ({
  type: TOOLPANEL_COLLAPSE_TOOLPANEL,
  toolPanel
});
export const ToolPanelSetModuleButtons = moduleButtons => ({
  type: TOOLPANEL_SET_MODULE_BUTTONS,
  moduleButtons: moduleButtons
});
export const ToolPanelReady = toolPanelState => ({
  type: TOOLPANEL_READY,
  toolPanelState
});
const initialState = {
  // default ToolPanels are set in ToolPanelModule.updateOldConfig() because we have to evaluate the ToolPanelOptions (for CustomToolPanel)
  ToolPanels: undefined,
  ModuleButtons: ['SettingsPanel']
};
export const ToolPanelReducer = (state = initialState, action) => {
  var _a, _b, _c, _d;
  const setToolPanels = (state, toolPanels = []) => {
    return Object.assign(Object.assign({}, state), {
      ToolPanels: [...toolPanels]
    });
  };
  switch (action.type) {
    case TOOLPANEL_SET_TOOLPANELS:
      {
        const actionTyped = action;
        const toolPanels = actionTyped.toolPanels;
        return setToolPanels(state, toolPanels);
      }
    case TOOLPANEL_SHOW_TOOLPANEL:
      {
        const actionTyped = action;
        const toolPanelDefinition = {
          Name: actionTyped.toolPanel,
          VisibilityMode: 'collapsed'
        };
        const toolPanels = [toolPanelDefinition, ...((_a = state.ToolPanels) !== null && _a !== void 0 ? _a : [])];
        return setToolPanels(state, toolPanels);
      }
    case TOOLPANEL_HIDE_TOOLPANEL:
      {
        const actionTyped = action;
        const toolPanels = ((_b = state.ToolPanels) !== null && _b !== void 0 ? _b : []).filter(a => a.Name !== actionTyped.toolPanel);
        return setToolPanels(state, toolPanels);
      }
    case TOOLPANEL_EXPAND_TOOLPANEL:
      {
        const actionTyped = action;
        const toolPanelDefinitions = (_c = state.ToolPanels) === null || _c === void 0 ? void 0 : _c.map(toolPanelDefinition => toolPanelDefinition.Name === actionTyped.toolPanel ? Object.assign(Object.assign({}, toolPanelDefinition), {
          VisibilityMode: 'expanded'
        }) : toolPanelDefinition);
        return setToolPanels(state, toolPanelDefinitions);
      }
    case TOOLPANEL_COLLAPSE_TOOLPANEL:
      {
        const actionTyped = action;
        const toolPanelDefinitions = (_d = state.ToolPanels) === null || _d === void 0 ? void 0 : _d.map(toolPanelDefinition => toolPanelDefinition.Name === actionTyped.toolPanel ? Object.assign(Object.assign({}, toolPanelDefinition), {
          VisibilityMode: 'collapsed'
        }) : toolPanelDefinition);
        return setToolPanels(state, toolPanelDefinitions);
      }
    case TOOLPANEL_SET_MODULE_BUTTONS:
      {
        const actionTyped = action;
        const moduleButtons = actionTyped.moduleButtons;
        return Object.assign({}, state, {
          ModuleButtons: moduleButtons
        });
      }
    default:
      return state;
  }
};