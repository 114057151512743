import * as React from 'react';
import { Box, Flex } from 'rebass';
import { CheckBox } from '../../../../components/CheckBox';
import FormLayout, { FormRow } from '../../../../components/FormLayout';
import Panel from '../../../../components/Panel';
import { Select } from '../../../../components/Select';
import SimpleButton from '../../../../components/SimpleButton';
import { Tabs } from '../../../../components/Tabs';
import { Tag } from '../../../../components/Tag';
import { summarySupportedExpressions, WEIGHTED_AVERAGE_AGGREATED_FUNCTION } from '../../../../PredefinedConfig/Common/RowSummary';
import { mapColumnDataTypeToExpressionFunctionType } from '../../../../Utilities/adaptableQlUtils';
import { LayoutModuleId } from '../../../../Utilities/Constants/ModuleConstants';
import { aggregatedExpressionFunctions } from '../../../../Utilities/ExpressionFunctions/aggregatedScalarExpressionFunctions';
import { useAdaptable } from '../../../AdaptableContext';
import { SuspendToggleButton } from '../../../Components/Buttons/SuspendToggleButton';
import { ValueSelector } from '../../../Components/ValueSelector';
import { useOnePageAdaptableWizardContext } from '../../../Wizard/OnePageAdaptableWizard';
import { columnFilter } from './Utilities';
import ArrayExtensions from '../../../../Utilities/Extensions/ArrayExtensions';
export const areSummaryRowsValid = layout => {
  var _a;
  if (!layout.RowSummaries) return true;
  let valid = true;
  (_a = layout.RowSummaries) === null || _a === void 0 ? void 0 : _a.find(rowSummary => {
    var _a;
    for (const [_, fn] of Object.entries((_a = rowSummary.ColumnsMap) !== null && _a !== void 0 ? _a : {})) {
      if (!fn) {
        valid = 'All row summary columns must have an aggregation function.';
        return true;
      }
    }
  });
  return valid;
};
const availableExpressionsForColumnTypeCache = new Map();
const getAvailableExpressionsForColumnType = (columnType, availableScalarExpressions) => {
  const key = columnType;
  if (availableExpressionsForColumnTypeCache.has(key)) {
    return availableExpressionsForColumnTypeCache.get(key);
  }
  const columnInputType = mapColumnDataTypeToExpressionFunctionType(columnType);
  const expressions = Object.keys(availableScalarExpressions).filter(availableExpression => {
    if (!aggregatedExpressionFunctions.includes(availableExpression)) {
      // is custom
      return true;
    }
    return Boolean(summarySupportedExpressions.includes(availableExpression));
  }).map(expression => {
    var _a, _b;
    const expressionDef = availableScalarExpressions[expression];
    let firstArg = null;
    // filter out expressions without inputs defined
    if (!(expressionDef === null || expressionDef === void 0 ? void 0 : expressionDef.inputs)) {
      return null;
    }
    if (Array.isArray((_a = expressionDef === null || expressionDef === void 0 ? void 0 : expressionDef.inputs) === null || _a === void 0 ? void 0 : _a[0])) {
      // @ts-ignore
      firstArg = (_b = expressionDef.inputs.find(input => input.includes(columnInputType))) === null || _b === void 0 ? void 0 : _b[0];
    } else {
      firstArg = expressionDef.inputs[0];
    }
    if (columnInputType === firstArg) {
      return expression;
    } else {
      return null;
    }
  }).filter(Boolean);
  availableExpressionsForColumnTypeCache.set(key, expressions);
  return expressions;
};
export const RowGroupingSectionSummary = () => {
  var _a;
  const adaptable = useAdaptable();
  const {
    data: layout
  } = useOnePageAdaptableWizardContext();
  return React.createElement(Box, null, ((_a = layout.RowGroupedColumns) === null || _a === void 0 ? void 0 : _a.length) ? layout.RowGroupedColumns.map(columnId => React.createElement(Tag, {
    mr: 1,
    key: columnId
  }, adaptable.api.columnApi.getFriendlyNameForColumnId(columnId))) : React.createElement(Tag, null, "No Row Grouping"));
};
const RowSummaryEditor = React.memo(({
  rowSummary,
  onChange,
  availableScalarExpressions,
  onDelete
}) => {
  var _a;
  const {
    data: layout
  } = useOnePageAdaptableWizardContext();
  const adaptable = useAdaptable();
  const columns = React.useMemo(() => {
    const colIds = adaptable.api.columnApi.getColumns().filter(column => {
      var _a, _b;
      if (!['Number', 'String', 'Date'].includes(column.dataType)) return false;
      return (_b = (_a = layout.Columns) === null || _a === void 0 ? void 0 : _a.includes) === null || _b === void 0 ? void 0 : _b.call(_a, column.columnId);
    }).map(c => c.columnId);
    return ArrayExtensions.sortArrayWithOrder(colIds, Object.keys(rowSummary.ColumnsMap), {
      sortUnorderedItems: false
    }).map(colId => adaptable.api.columnApi.getColumnWithColumnId(colId));
  }, [rowSummary.ColumnsMap]);
  return React.createElement(Panel, {
    header: React.createElement(Flex, {
      style: {
        width: '100%'
      }
    }, React.createElement(Flex, {
      flex: 1,
      alignItems: "center"
    }, "Row Summary"), React.createElement(Box, {
      mr: 2
    }, React.createElement(SuspendToggleButton, {
      onSuspend: () => {
        onChange(Object.assign(Object.assign({}, rowSummary), {
          IsSuspended: true
        }));
      },
      onUnSuspend: () => {
        onChange(Object.assign(Object.assign({}, rowSummary), {
          IsSuspended: false
        }));
      },
      suspendableObject: rowSummary
    })), React.createElement(SimpleButton, {
      icon: "delete",
      onClick: () => {
        onDelete();
      }
    })),
    p: 2
  }, React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Position"
  }, React.createElement(Select, {
    options: [{
      label: 'Top',
      value: 'Top'
    }, {
      label: 'Bottom',
      value: 'Bottom'
    }],
    value: rowSummary.Position,
    onChange: position => {
      onChange(Object.assign(Object.assign({}, rowSummary), {
        Position: position
      }));
    }
  })), React.createElement(FormRow, {
    label: ""
  }, React.createElement(CheckBox, {
    checked: (_a = rowSummary.IncludeOnlyFilteredRows) !== null && _a !== void 0 ? _a : true,
    onChange: IncludeOnlyFilteredRows => {
      onChange(Object.assign(Object.assign({}, rowSummary), {
        IncludeOnlyFilteredRows
      }));
    }
  }, "Include Only Filtered Rows"))), React.createElement(Flex, {
    flexDirection: 'column',
    mt: 2,
    mb: 1
  }, React.createElement(Flex, {
    alignItems: "center",
    flex: 1,
    mb: 2
  }, "Column Aggregations"), React.createElement(Panel, {
    bodyProps: {
      maxHeight: '100%'
    },
    style: {
      height: 360
    }
  }, React.createElement(ValueSelector, {
    style: {
      maxHeight: '100%'
    },
    showFilterInput: true,
    filter: columnFilter,
    toIdentifier: column => column.columnId,
    toLabel: option => {
      var _a;
      return (_a = option.friendlyName) !== null && _a !== void 0 ? _a : option.columnId;
    },
    options: columns,
    xSelectedLabel: () => {
      return 'Active Aggregations:';
    },
    toListLabel: column => {
      var _a, _b, _c;
      const label = (_a = column.friendlyName) !== null && _a !== void 0 ? _a : column.columnId;
      if (!(column.columnId in ((_b = rowSummary.ColumnsMap) !== null && _b !== void 0 ? _b : {}))) {
        return label;
      }
      const expressionOptions = getAvailableExpressionsForColumnType(column.dataType, availableScalarExpressions).map(expression => ({
        label: expression,
        value: expression
      }));
      // check out if this layout has a aggregation with wighted column
      const aggregation = (_c = layout.AggregationColumns) === null || _c === void 0 ? void 0 : _c[column.columnId];
      if (aggregation && typeof aggregation === 'object' && aggregation.weightedColumnId) {
        expressionOptions.push({
          label: 'WEIGHTERD_AVG',
          value: WEIGHTED_AVERAGE_AGGREATED_FUNCTION
        });
      }
      const expression = rowSummary.ColumnsMap[column.columnId];
      return React.createElement(Flex, null, React.createElement(Flex, {
        mr: 2,
        alignItems: 'center'
      }, label), React.createElement(Select, {
        value: expression,
        options: expressionOptions,
        onChange: expression => {
          onChange(Object.assign(Object.assign({}, rowSummary), {
            ColumnsMap: Object.assign(Object.assign({}, rowSummary.ColumnsMap), {
              [column.columnId]: expression
            })
          }));
        }
      }));
    },
    value: Object.keys(rowSummary.ColumnsMap),
    onChange: colIds => {
      const newColumnsMap = {};
      colIds.forEach(colId => {
        var _a;
        newColumnsMap[colId] = (_a = rowSummary.ColumnsMap[colId]) !== null && _a !== void 0 ? _a : null;
      });
      onChange(Object.assign(Object.assign({}, rowSummary), {
        ColumnsMap: newColumnsMap
      }));
    }
  }))));
});
export const RowSummarySection = props => {
  var _a;
  const adaptable = useAdaptable();
  const {
    data: layout
  } = useOnePageAdaptableWizardContext();
  const availableScalarExpressions = React.useMemo(() => {
    const sytemExpressions = adaptable.api.internalApi.getQueryLanguageService().getModuleExpressionFunctionsMap(LayoutModuleId).aggregatedScalarFunctions;
    return sytemExpressions;
  }, []);
  return React.createElement(Tabs, {
    style: {
      height: '100%'
    }
  }, React.createElement(Tabs.Tab, null, "Row Summaries"), React.createElement(Tabs.Content, null, React.createElement("div", null, React.createElement(Flex, {
    mb: 1
  }, React.createElement(Flex, {
    flex: 1
  }), React.createElement(SimpleButton, {
    onClick: () => {
      var _a;
      props.onChange(Object.assign(Object.assign({}, layout), {
        RowSummaries: [...((_a = layout.RowSummaries) !== null && _a !== void 0 ? _a : []), {
          Position: 'Top',
          ColumnsMap: {},
          IncludeOnlyFilteredRows: true
        }]
      }));
    },
    icon: "plus"
  }, "Add Row Summary")), ((_a = layout.RowSummaries) !== null && _a !== void 0 ? _a : []).map((rowSummary, index) => {
    return React.createElement(RowSummaryEditor, {
      key: index,
      onDelete: () => {
        const newSummaries = [...layout.RowSummaries];
        newSummaries.splice(index, 1);
        props.onChange(Object.assign(Object.assign({}, layout), {
          RowSummaries: newSummaries
        }));
      },
      availableScalarExpressions: availableScalarExpressions,
      rowSummary: rowSummary,
      onChange: rowSummary => {
        const newSummaries = [...layout.RowSummaries];
        newSummaries[index] = rowSummary;
        props.onChange(Object.assign(Object.assign({}, layout), {
          RowSummaries: newSummaries
        }));
      }
    });
  }))));
};