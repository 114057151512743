import * as React from 'react';
import { useAdaptable } from '../AdaptableContext';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import { useChartingElements } from './useChartingElements';
import { Box, Flex } from 'rebass';
export const ChartingStatusBarPopover = props => {
  const adaptable = useAdaptable();
  const accessLevel = adaptable.api.entitlementApi.getEntitlementAccessLevelForModule(ModuleConstants.ChartingModuleId);
  const {
    chartSelector,
    containerSelector,
    chartButton,
    deleteButton,
    editButton
  } = useChartingElements({
    elementType: 'Statusbar',
    accessLevel: accessLevel,
    size: 'small'
  });
  return React.createElement(Flex, {
    width: "100%",
    alignItems: "center"
  }, React.createElement(Box, null, chartSelector), containerSelector && React.createElement(Box, {
    ml: 1
  }, containerSelector), React.createElement(Box, {
    ml: 1
  }, chartButton, deleteButton, editButton));
};