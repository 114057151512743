import { Flex, Box, Text } from 'rebass';
import * as React from 'react';
import { sentenceCase } from 'sentence-case';
import SimpleButton from '../../../components/SimpleButton';
import Input from '../../../components/Input';
import FormLayout, { FormRow } from '../../../components/FormLayout';
import { CheckBox } from '../../../components/CheckBox';
import { cloneObject } from '../../../Utilities/Helpers/Helper';
import StringExtensions from '../../../Utilities/Extensions/StringExtensions';
import { AdaptableIconSelector } from '../../Components/AdaptableIconSelector';
import { Select } from '../../../components/Select';
export const AlertButtonsEditor = props => {
  const {
    api,
    adaptableAlert
  } = props;
  const onChange = newButtons => {
    props.onChange(newButtons);
  };
  const alertDefinition = adaptableAlert.alertDefinition;
  const rowAddedAlert = api.alertApi.internalApi.isAlertDefinitionForAddedRowChangeEvent(alertDefinition);
  const rowRemovedAlert = api.alertApi.internalApi.isAlertDefinitionForRemovedRowChangeEvent(alertDefinition);
  // except row change
  const hasHighlightCell = props.alertType !== 'RowChange';
  // all, except row removed
  const hasHighlightRow = !rowRemovedAlert;
  // except row removed
  const hasJumpToCell = props.alertType !== 'RowChange';
  // all
  const hasJumpToRow = !rowRemovedAlert;
  // all
  const hasSuspend = true;
  // only data change
  const hasUndo = props.alertType === 'DataChange';
  const AlertButtons = cloneObject(props.AlertButtons || []) || [];
  const buttonActions = [];
  if (hasHighlightRow) {
    buttonActions.push('highlight-row');
  }
  if (hasJumpToRow) {
    buttonActions.push('jump-to-row');
  }
  if (hasHighlightCell) {
    buttonActions.push('highlight-cell');
  }
  if (hasJumpToCell) {
    buttonActions.push('jump-to-cell');
  }
  if (hasUndo) {
    buttonActions.push('undo');
  }
  if (hasSuspend) {
    buttonActions.push('suspend');
  }
  return React.createElement(React.Fragment, null, React.createElement(Flex, {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  }, React.createElement(Text, {
    fontSize: 2,
    mt: 3,
    mb: 2
  }, "Add buttons to Alert Notification, and set Actions to perform when button is clicked"), React.createElement(SimpleButton, {
    icon: "plus",
    onClick: () => {
      onChange([...(AlertButtons || []), {
        Label: 'OK',
        ButtonStyle: {
          variant: 'raised'
        }
      }]);
    }
  }, "Add")), AlertButtons.map((button, index) => {
    var _a, _b;
    let buttonStyle = button.ButtonStyle;
    let buttonLabel = button.Label;
    let btnActions = [];
    if (button.Action && typeof button.Action === 'string') {
      btnActions = [button.Action];
    } else if (button.Action && Array.isArray(button.Action)) {
      btnActions = button.Action;
    }
    // filter out the adaptable standard actions
    const btnUserFunctions = btnActions.filter(action => !buttonActions.includes(action));
    const setVariant = variant => {
      onChange(AlertButtons.map((btn, i) => {
        if (i === index) {
          const buttonStyle = Object.assign({}, btn.ButtonStyle);
          buttonStyle.variant = variant;
          return Object.assign(Object.assign({}, btn), {
            ButtonStyle: buttonStyle
          });
        }
        return btn;
      }));
    };
    const setTone = tone => {
      onChange(AlertButtons.map((btn, i) => {
        if (i === index) {
          const buttonStyle = Object.assign({}, btn.ButtonStyle);
          if (tone == null) {
            delete buttonStyle.tone;
          } else {
            buttonStyle.tone = tone;
          }
          return Object.assign(Object.assign({}, btn), {
            ButtonStyle: buttonStyle
          });
        }
        return btn;
      }));
    };
    const handleActionChange = (checked, actionName) => {
      let actions = btnActions;
      if (!checked) {
        // remove current action
        actions = actions.filter(a => a !== actionName);
      } else {
        actions = [...actions, actionName];
      }
      onChange(AlertButtons.map((btn, i) => {
        if (i === index) {
          return Object.assign(Object.assign({}, btn), {
            Action: actions.length ? actions : undefined
          });
        }
        return btn;
      }));
    };
    let iconSelector = null;
    if (!button.icon || button.icon && 'name' in button.icon) {
      iconSelector = React.createElement(AdaptableIconSelector, {
        value: button.icon && 'name' in button.icon ? (_a = button === null || button === void 0 ? void 0 : button.icon) === null || _a === void 0 ? void 0 : _a.name : '',
        onChange: iconName => {
          onChange(AlertButtons.map(btn => {
            if (btn === button) {
              return Object.assign(Object.assign({}, btn), {
                icon: {
                  name: iconName
                }
              });
            }
            return btn;
          }));
        }
      });
    }
    return React.createElement(Box, {
      key: index,
      marginLeft: 3,
      style: {
        borderTop: index ? '1px dashed var(--ab-color-inputborder)' : ''
      }
    }, React.createElement(FormLayout, {
      marginBottom: 2,
      paddingTop: 2
    }, React.createElement(FormRow, {
      label: React.createElement(Text, {
        fontSize: 2,
        style: {
          whiteSpace: 'nowrap'
        }
      }, "Button Text")
    }, React.createElement(Flex, {
      flexDirection: "row"
    }, React.createElement(Input, {
      value: buttonLabel,
      mr: 2,
      style: {
        width: 80
      },
      onChange: e => {
        onChange(AlertButtons.map((btn, i) => {
          if (i === index) {
            return Object.assign(Object.assign({}, btn), {
              Label: e.target.value
            });
          }
          return btn;
        }));
      }
    }), React.createElement(Box, {
      mr: 2
    }, React.createElement(Select, {
      options: ['text', 'outlined', 'raised'].map(variant => {
        return {
          label: StringExtensions.CapitaliseFirstLetter(variant),
          value: variant
        };
      }),
      renderSingleValue: option => `Variant: ${option.label}`,
      value: buttonStyle === null || buttonStyle === void 0 ? void 0 : buttonStyle.variant,
      onChange: value => {
        setVariant(value);
      }
    })), React.createElement(Select, {
      options: [{
        label: 'Tone: Use Alert Tone',
        value: 'text'
      }, ...['success', 'info', 'error', 'warning', 'accent', 'neutral'].map(tone => {
        return {
          label: StringExtensions.CapitaliseFirstLetter(tone),
          value: tone
        };
      })],
      renderSingleValue: option => `Tone: ${option.label}`,
      onChange: value => {
        if (value === 'text') {
          setTone(null);
        }
        setTone(value);
      },
      value: (_b = buttonStyle === null || buttonStyle === void 0 ? void 0 : buttonStyle.tone) !== null && _b !== void 0 ? _b : 'text'
    }), React.createElement(SimpleButton, {
      icon: "close",
      tone: "error",
      disabled: AlertButtons.length <= 1,
      marginLeft: 1,
      variant: "text",
      tooltip: AlertButtons.length <= 1 ? 'Cannot remove last button' : 'Remove button',
      onClick: () => {
        onChange(AlertButtons.filter(btn => btn !== button));
      }
    }))), React.createElement(FormRow, {
      label: React.createElement(Text, {
        fontSize: 2
      }, "Actions")
    }, buttonActions.map(actionName => {
      return React.createElement(CheckBox, {
        key: actionName,
        marginRight: 3,
        onChange: checked => handleActionChange(checked, actionName),
        checked: button.Action === actionName || Array.isArray(button.Action) && button.Action.includes(actionName)
      }, React.createElement(Text, {
        fontSize: 2
      }, sentenceCase(actionName)));
    }), btnUserFunctions.length ? React.createElement(CheckBox, {
      checked: true,
      disabled: true
    }, React.createElement(Text, {
      fontSize: 2
    }, "User function", btnUserFunctions.length > 1 ? 's' : '', ":", ' ', btnUserFunctions.join(', '))) : null), iconSelector && React.createElement(FormRow, {
      label: React.createElement(Text, {
        fontSize: 2
      }, "Icon")
    }, React.createElement(Box, null, iconSelector))));
  }));
};