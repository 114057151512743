import * as React from 'react';
import { Select } from '../../../components/Select';
import { useAdaptable } from '../../AdaptableContext';
export const FieldSelector = props => {
  var _a;
  const {
    api
  } = useAdaptable();
  const fields = React.useMemo(() => {
    return api.expressionApi.internalApi.getFieldsOfType(props.type);
  }, []);
  const options = fields.map(field => {
    var _a;
    return {
      label: (_a = field.label) !== null && _a !== void 0 ? _a : field.name,
      value: field.name
    };
  });
  return React.createElement(React.Fragment, null, React.createElement(Select, {
    "data-name": "column-selector",
    disabled: props.disabled,
    isMulti: false,
    menuPosition: props.menuPosition,
    value: props.value || undefined,
    placeholder: (_a = props.placeholder) !== null && _a !== void 0 ? _a : 'Select Field',
    onChange: fieldValue => {
      props.onChange(fieldValue);
    },
    options: options
  }));
};