import * as React from 'react';
import SimpleButton from '../../../components/SimpleButton';
export class ButtonExpand extends React.Component {
  render() {
    return React.createElement(SimpleButton, Object.assign({
      "data-name": "expand",
      tooltip: "Expand",
      iconSize: 20,
      icon: "arrow-expand",
      variant: "text"
    }, this.props));
  }
}