import React from 'react';
import FormLayout, { FormRow } from '../../../../components/FormLayout';
import AdaptableInput from '../../../Components/AdaptableInput';
export const isSettingsValid = (chartDefinition, api) => {
  if (!chartDefinition.Name) {
    return 'Name is mandatory';
  }
  const allChartDefinitions = api.chartingApi.getExternalChartDefinitions();
  if (allChartDefinitions.some(chartDefinitionLoopItem => chartDefinitionLoopItem.Uuid !== chartDefinition.Uuid && chartDefinitionLoopItem.Name === chartDefinition.Name)) {
    return 'There is already a chart with this name';
  }
  return true;
};
export const SettingsSection = props => {
  return React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Name"
  }, React.createElement(AdaptableInput, {
    value: props.chartDefinition.Name,
    onChange: e => props.onChange(Object.assign(Object.assign({}, props.chartDefinition), {
      Name: e.target.value
    }))
  })));
};