import { EMPTY_ARRAY } from '../../Utilities/Constants/GeneralConstants';
import AdaptableHelper from '../../Utilities/Helpers/AdaptableHelper';
/**
 * @ReduxAction A Free Text Column has been added
 */
export const FREE_TEXT_COLUMN_ADD = 'FREE_TEXT_COLUMN_ADD';
/**
 * @ReduxAction A Free Text Column has been edited
 */
export const FREE_TEXT_COLUMN_EDIT = 'FREE_TEXT_COLUMN_EDIT';
/**
 * @ReduxAction A Free Text Column has been deleted
 */
export const FREE_TEXT_COLUMN_DELETE = 'FREE_TEXT_COLUMN_DELETE';
/**
 * @ReduxAction A Free Text Column's stored values have changed
 */
export const FREE_TEXT_COLUMN_ADD_EDIT_STORED_VALUE = 'FREE_TEXT_COLUMN_ADD_EDIT_STORED_VALUE';
/**
 * @ReduxAction FreeTextColumn Module is ready
 */
export const FREE_TEXT_COLUMN_READY = 'FREE_TEXT_COLUMN_READY';
export const FreeTextColumnAdd = freeTextColumn => ({
  type: FREE_TEXT_COLUMN_ADD,
  freeTextColumn
});
export const FreeTextColumnEdit = freeTextColumn => ({
  type: FREE_TEXT_COLUMN_EDIT,
  freeTextColumn
});
export const FreeTextColumnDelete = freeTextColumn => ({
  type: FREE_TEXT_COLUMN_DELETE,
  freeTextColumn
});
export const FreeTextColumnAddEditStoredValue = (FreeTextColumn, FreeTextStoredValue) => ({
  type: FREE_TEXT_COLUMN_ADD_EDIT_STORED_VALUE,
  FreeTextColumn,
  FreeTextStoredValue
});
export const FreeTextColumnReady = freeTextColumnState => ({
  type: FREE_TEXT_COLUMN_READY,
  freeTextColumnState
});
const initialState = {
  FreeTextColumns: EMPTY_ARRAY
};
export const FreeTextColumnReducer = (state = initialState, action) => {
  // let freeTextColumns: FreeTextColumn[];
  var _a;
  switch (action.type) {
    case FREE_TEXT_COLUMN_ADD:
      {
        const actionFreeTextColumn = action.freeTextColumn;
        AdaptableHelper.addUuidAndSource(actionFreeTextColumn);
        const freeTextColumns = [...state.FreeTextColumns];
        freeTextColumns.push(actionFreeTextColumn);
        return Object.assign(Object.assign({}, state), {
          FreeTextColumns: freeTextColumns
        });
      }
    // !! MUTABLE STATE
    case FREE_TEXT_COLUMN_EDIT:
      const actionFreeTextColumn = action.freeTextColumn;
      return Object.assign(Object.assign({}, state), {
        FreeTextColumns: state.FreeTextColumns.map(abObject => abObject.Uuid === actionFreeTextColumn.Uuid ?
        // we need to assign and return the same object
        // in order to make working with aggrid easier, as the col getter
        // references this object
        Object.assign(abObject, actionFreeTextColumn) : abObject)
      });
    case FREE_TEXT_COLUMN_DELETE:
      {
        const actionFreeTextColumn = action.freeTextColumn;
        return Object.assign(Object.assign({}, state), {
          FreeTextColumns: state.FreeTextColumns.filter(abObject => abObject.Uuid !== actionFreeTextColumn.Uuid)
        });
      }
    case FREE_TEXT_COLUMN_ADD_EDIT_STORED_VALUE:
      {
        const actionTypedAddEditStoredValue = action;
        actionTypedAddEditStoredValue.FreeTextColumn.FreeTextStoredValues = (_a = actionTypedAddEditStoredValue.FreeTextColumn.FreeTextStoredValues) !== null && _a !== void 0 ? _a : [];
        let existingIndex = actionTypedAddEditStoredValue.FreeTextColumn.FreeTextStoredValues.findIndex(ftsv => ftsv.PrimaryKey == actionTypedAddEditStoredValue.FreeTextStoredValue.PrimaryKey);
        if (existingIndex != -1) {
          actionTypedAddEditStoredValue.FreeTextColumn.FreeTextStoredValues[existingIndex] = actionTypedAddEditStoredValue.FreeTextStoredValue;
        } else {
          actionTypedAddEditStoredValue.FreeTextColumn.FreeTextStoredValues.push(actionTypedAddEditStoredValue.FreeTextStoredValue);
        }
        const freeTextColumns = [...state.FreeTextColumns];
        let index = freeTextColumns.findIndex(x => x.ColumnId == actionTypedAddEditStoredValue.FreeTextColumn.ColumnId);
        freeTextColumns[index] = actionTypedAddEditStoredValue.FreeTextColumn;
        return Object.assign({}, state, {
          FreeTextColumns: freeTextColumns
        });
      }
    default:
      return state;
  }
};