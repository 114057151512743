import UIHelper from '../../UIHelper';
export const getAlertButtonStyle = alerts => {
  const messageType = UIHelper.getMessageTypeFromAdaptableAlerts(alerts);
  const messageTypeColor = UIHelper.getColorByMessageType(messageType);
  const buttonBackground = UIHelper.getButtonColourForAdaptableAlerts(alerts, messageTypeColor);
  const buttonTextColor = UIHelper.getButtonTextColourForArrayandMessageType(alerts, messageType);
  return {
    color: buttonTextColor,
    background: buttonBackground
  };
};