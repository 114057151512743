import { __rest } from "tslib";
import * as React from 'react';
import Input, { baseClassName } from './index';
import join from '../utils/join';
/**
 * This component allows - and empty values as intermediery values
 * it calls change only with valid numeric values
 */
export const NumberInput = React.forwardRef((props, ref) => {
  const [value, setValue] = React.useState(() => {
    var _a;
    // awlays controlled
    return (_a = props.value) !== null && _a !== void 0 ? _a : '';
  });
  const handleChange = React.useCallback(event => {
    const {
      value
    } = event.target;
    let newVal = Number(value);
    if (value === '-' || value === '') {
      // we are saving invalid values so we allow values as empty string and '-'
      // there is a validation that prevents the
      newVal = value;
      // the value may be empty or negative
      setValue(value);
    } else if (!isNaN(newVal)) {
      setValue(value);
      // only set when a valid numer
      props.onChange(newVal);
    }
  }, []);
  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);
  const handleBlur = React.useCallback(event => {
    var _a;
    (_a = props === null || props === void 0 ? void 0 : props.onBlur) === null || _a === void 0 ? void 0 : _a.call(props, event);
    // show the prop value
    if (value !== props.value) {
      setValue(props.value);
    }
  }, [props.value, value]);
  const {
      noArrows
    } = props,
    restOfProps = __rest(props, ["noArrows"]);
  return React.createElement(Input, Object.assign({}, restOfProps, {
    ref: ref,
    className: join(noArrows ? `${baseClassName}--no-arrows` : '', props.className),
    value: value,
    onBlur: handleBlur,
    onChange: handleChange
  }));
});