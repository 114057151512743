export default {
  breakpoints: ['40em', '52em', '64em'],
  fontSizes: ['var(--ab-font-size-0)', 'var(--ab-font-size-1)', 'var(--ab-font-size-2)', 'var(--ab-font-size-3)', 'var(--ab-font-size-4)', 'var(--ab-font-size-5)', 'var(--ab-font-size-6)', 'var(--ab-font-size-7)'],
  colors: {
    accent: 'var(--ab-color-accent)',
    blue: 'var(--ab-color-blue)',
    warn: 'var(--ab-color-warn)',
    info: 'var(--ab-color-info)',
    error: 'var(--ab-color-error)',
    errorlight: 'var(--ab-color-errorlight)',
    defaultbackground: 'var(--ab-color-defaultbackground)',
    primary: 'var(--ab-color-primary)',
    primarydark: 'var(--ab-color-primarydark)',
    primarylight: 'var(--ab-color-primarylight)',
    'text-on-primary': 'var(--ab-color-text-on-primary)',
    secondary: 'var(--ab-color-secondary)',
    secondarydark: 'var(--ab-color-secondarydark)',
    secondarylight: 'var(--ab-color-secondarylight)',
    'text-on-secondary': 'var(--ab-color-text-on-secondary)',
    'text-on-secondarylight': 'var(--ab-color-text-on-secondarylight)'
  },
  space: ['var(--ab-space-0)', 'var(--ab-space-1)', 'var(--ab-space-2)', 'var(--ab-space-3)', 'var(--ab-space-4)', 'var(--ab-space-5)', 'var(--ab-space-6)', 'var(--ab-space-7)'],
  fonts: {
    sans: 'system-ui, sans-serif',
    mono: 'Menlo, monospace'
  },
  fontWeights: {
    normal: 'normal',
    bold: '700'
  }
};