import { AlertApiImpl } from './AlertApiImpl';
import { StatusBarApiImpl } from './StatusBarApiImpl';
import { PredicateApiImpl } from './PredicateApiImpl';
import { PluginsApiImpl } from './PluginsApiImpl';
import { BulkUpdateApiImpl } from './BulkUpdateApiImpl';
import { CalculatedColumnApiImpl } from './CalculatedColumnApiImpl';
import { CellSummaryApiImpl } from './CellSummaryApiImpl';
import { ConfigApiImpl } from './ConfigApiImpl';
import { CustomSortApiImpl } from './CustomSortApiImpl';
import { DashboardApiImpl } from './DashboardApiImpl';
import { ToolPanelApiImpl } from './ToolPanelApiImpl';
import { DataSetApiImpl } from './DataSetApiImpl';
import { EventApiImpl } from './EventApiImpl';
import { ExportApiImpl } from './ExportApiImpl';
import { FormatColumnApiImpl } from './FormatColumnApiImpl';
import { FreeTextColumnApiImpl } from './FreeTextColumnApiImpl';
import { GridApiImpl } from './GridApiImpl';
import { LayoutApiImpl } from './LayoutApiImpl';
import { PlusMinusApiImpl } from './PlusMinusApiImpl';
import { QuickSearchApiImpl } from './QuickSearchApiImpl';
import { ShortcutApiImpl } from './ShortcutApiImpl';
import { SmartEditApiImpl } from './SmartEditApiImpl';
import { SystemStatusApiImpl } from './SystemStatusApiImpl';
import { ThemeApiImpl } from './ThemeApiImpl';
import { UserInterfaceApiImpl } from './UserInterfaceApiImpl';
import { AdaptableInternalApi } from '../Internal/AdaptableInternalApi';
import { ScheduleApiImpl } from './ScheduleApiImpl';
import { TeamSharingApiImpl } from './TeamSharingApiImpl';
import { ColumnApiImpl } from './ColumnApiImpl';
import { ApplicationApiImpl } from './ApplicationApiImpl';
import { ColumnScopeApiImpl } from './ColumnScopeApiImpl';
import { DataChangeHistoryApiImpl } from './DataChangeHistoryApiImpl';
import { ExpressionApiImpl } from './ExpressionApiImpl';
import { FlashingCellApiImpl } from './FlashingCellApiImpl';
import { ChartingApiImpl } from './ChartingApiImpl';
import { SettingsPanelApiImpl } from './SettingsPanelApiImpl';
import { ActionColumnApiImpl } from './ActionColumnApiImpl';
import { ActionRowApiImpl } from './ActionRowApiImpl';
import { StyledColumnApiImpl } from './StyledColumnApiImpl';
import { CalendarApiImpl } from './CalendarApiImpl';
import { EntitlementApiImpl } from './EntitlementApiImpl';
import { OptionsApiImpl } from './OptionsApiImpl';
import { NoteApiImpl } from './NoteApiImpl';
import { Fdc3ApiImpl } from './Fdc3ApiImpl';
import { DataImportApiImpl } from './DataImportApiImpl';
import { GridFilterApiImpl } from './GridFilterApiImpl';
import { NamedQueryApiImpl } from './NamedQueryApiImpl';
import { ColumnFilterApiImpl } from './ColumnFilterApiImpl';
import { CommentsApiImpl } from './CommentsApiImpl';
import { ColumnMenuApiImpl } from './ColumnMenuApiImpl';
import { ContextMenuApiImpl } from './ContextMenuApiImpl';
export class AdaptableApiImpl {
  constructor(adaptable) {
    this.adaptable = adaptable;
    this.destroyed = false;
    this.adaptable = adaptable;
    this.optionsApi = new OptionsApiImpl(adaptable);
    this.actionColumnApi = new ActionColumnApiImpl(adaptable);
    this.actionRowApi = new ActionRowApiImpl(adaptable);
    this.applicationApi = new ApplicationApiImpl(adaptable);
    this.alertApi = new AlertApiImpl(adaptable);
    this.flashingCellApi = new FlashingCellApiImpl(adaptable);
    this.predicateApi = new PredicateApiImpl(adaptable);
    this.pluginsApi = new PluginsApiImpl(adaptable);
    this.bulkUpdateApi = new BulkUpdateApiImpl(adaptable);
    this.calendarApi = new CalendarApiImpl(adaptable);
    this.calculatedColumnApi = new CalculatedColumnApiImpl(adaptable);
    this.cellSummaryApi = new CellSummaryApiImpl(adaptable);
    this.columnApi = new ColumnApiImpl(adaptable);
    this.columnMenuApi = new ColumnMenuApiImpl(adaptable);
    this.contextMenuApi = new ContextMenuApiImpl(adaptable);
    this.configApi = new ConfigApiImpl(adaptable);
    this.customSortApi = new CustomSortApiImpl(adaptable);
    this.dashboardApi = new DashboardApiImpl(adaptable);
    this.dataSetApi = new DataSetApiImpl(adaptable);
    this.entitlementApi = new EntitlementApiImpl(adaptable);
    this.eventApi = new EventApiImpl(adaptable);
    this.exportApi = new ExportApiImpl(adaptable);
    this.formatColumnApi = new FormatColumnApiImpl(adaptable);
    this.freeTextColumnApi = new FreeTextColumnApiImpl(adaptable);
    this.gridApi = new GridApiImpl(adaptable);
    this.layoutApi = new LayoutApiImpl(adaptable);
    this.plusMinusApi = new PlusMinusApiImpl(adaptable);
    this.quickSearchApi = new QuickSearchApiImpl(adaptable);
    this.scheduleApi = new ScheduleApiImpl(adaptable);
    this.shortcutApi = new ShortcutApiImpl(adaptable);
    this.smartEditApi = new SmartEditApiImpl(adaptable);
    this.systemStatusApi = new SystemStatusApiImpl(adaptable);
    this.settingsPanelApi = new SettingsPanelApiImpl(adaptable);
    this.themeApi = new ThemeApiImpl(adaptable);
    this.userInterfaceApi = new UserInterfaceApiImpl(adaptable);
    this.namedQueryApi = new NamedQueryApiImpl(adaptable);
    this.expressionApi = new ExpressionApiImpl(adaptable);
    this.styledColumnApi = new StyledColumnApiImpl(adaptable);
    this.toolPanelApi = new ToolPanelApiImpl(adaptable);
    this.teamSharingApi = new TeamSharingApiImpl(adaptable);
    this.columnScopeApi = new ColumnScopeApiImpl(adaptable);
    this.dataChangeHistoryApi = new DataChangeHistoryApiImpl(adaptable);
    this.chartingApi = new ChartingApiImpl(adaptable);
    this.statusBarApi = new StatusBarApiImpl(adaptable);
    this.noteApi = new NoteApiImpl(adaptable);
    this.fdc3Api = new Fdc3ApiImpl(adaptable);
    this.dataImportApi = new DataImportApiImpl(adaptable);
    this.gridFilterApi = new GridFilterApiImpl(adaptable);
    this.columnFilterApi = new ColumnFilterApiImpl(adaptable);
    this.commentApi = new CommentsApiImpl(adaptable);
    // adaptable internal api
    this.internalApi = new AdaptableInternalApi(adaptable);
  }
  get agGridApi() {
    return this.adaptable.agGridAdapter.getAgGridApi();
  }
  /**
   * This is only meant to be called by the Adaptable instance.
   */
  internalDestroySelf() {
    if (this.destroyed) {
      return;
    }
    this.destroyed = true;
    this.eventApi.destroy();
    this.optionsApi = null;
    this.alertApi = null;
    this.applicationApi = null;
    this.bulkUpdateApi = null;
    this.calculatedColumnApi = null;
    this.cellSummaryApi = null;
    this.chartingApi = null;
    this.columnApi = null;
    this.configApi = null;
    this.customSortApi = null;
    this.dashboardApi = null;
    this.dataChangeHistoryApi = null;
    this.dataSetApi = null;
    this.eventApi = null;
    this.exportApi = null;
    this.columnApi = null;
    this.namedQueryApi = null;
    this.flashingCellApi = null;
    this.formatColumnApi = null;
    this.freeTextColumnApi = null;
    this.gridApi = null;
    this.internalApi = null;
    this.layoutApi = null;
    this.pluginsApi = null;
    this.plusMinusApi = null;
    this.predicateApi = null;
    this.expressionApi = null;
    this.quickSearchApi = null;
    this.scheduleApi = null;
    this.columnScopeApi = null;
    this.settingsPanelApi = null;
    this.shortcutApi = null;
    this.smartEditApi = null;
    this.statusBarApi = null;
    this.systemStatusApi = null;
    this.teamSharingApi = null;
    this.themeApi = null;
    this.toolPanelApi = null;
    this.userInterfaceApi = null;
    this.fdc3Api = null;
  }
  destroy(config) {
    this.adaptable.destroy(config);
  }
  isDestroyed() {
    return this.destroyed;
  }
  logInfo(message, ...optionalParams) {
    this.adaptable.logger.info(message, optionalParams);
  }
  logSuccess(message, ...optionalParams) {
    this.adaptable.logger.success(message, optionalParams);
  }
  logWarn(message, ...optionalParams) {
    this.adaptable.logger.warn(message, optionalParams);
  }
  logError(message, ...optionalParams) {
    this.adaptable.logger.error(message, optionalParams);
  }
  consoleError(message, ...optionalParams) {
    this.adaptable.logger.consoleError(message, optionalParams);
  }
  consoleWarn(message, ...optionalParams) {
    this.adaptable.logger.consoleWarn(message, optionalParams);
  }
}