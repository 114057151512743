import { isSameDay } from 'date-fns';
import { Resizable } from 're-resizable';
import * as React from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { Flex } from 'rebass';
import ArrayExtensions from '../../../Utilities/Extensions/ArrayExtensions';
import OverlayTrigger from '../../../components/OverlayTrigger';
import SimpleButton from '../../../components/SimpleButton';
import { ListBoxFilterForm } from './ListBoxFilterForm';
export const QuickFilterValues = props => {
  const {
    api
  } = props;
  const currentOverlayVisible = useRef(false);
  const valuesDropdownRef = React.useRef(null);
  const [quickFilterValues, setQuickFilterValues] = React.useState({
    values: [],
    suppressClientSideFilter: false,
    dataLoadIsComplete: false
  });
  const [isDistinctColumnValuesLoading, setIsDistinctColumnValuesLoading] = React.useState(false);
  const [textFilterValue, setTextFilterValue] = React.useState('');
  const [valuesLoadTrigger, setValuesLoadTrigger] = React.useState({
    counter: 0
  });
  const [transientColumnFilter, setTransientColumnFilter] = React.useState(null);
  const clearColumnFilter = () => {
    var _a;
    props.clearColumnFilter();
    if (!((_a = props.api.optionsApi.getColumnFilterOptions()) === null || _a === void 0 ? void 0 : _a.autoApplyFilter)) {
      props.api.gridApi.applyGridFiltering();
    }
  };
  const onColumnValueSelectedChange = columnValues => {
    var _a;
    const {
      columnFilter
    } = props;
    columnFilter.Predicate = {
      PredicateId: columnFilter.Predicate.PredicateId,
      Inputs: columnValues
    };
    if ((_a = api.optionsApi.getColumnFilterOptions()) === null || _a === void 0 ? void 0 : _a.autoApplyFilter) {
      props.updateColumnFilter(columnFilter);
    } else {
      setTransientColumnFilter(columnFilter);
    }
  };
  useEffect(() => {
    if (valuesLoadTrigger.counter === 0) {
      // skip the first render
      return;
    }
    // loadPermittedValues
    let ignore = false;
    setIsDistinctColumnValuesLoading(true);
    api.gridApi.internalApi.getDistinctFilterDisplayValuesForColumnForFiltersUI(props.columnFilter.ColumnId, textFilterValue, api.optionsApi.getColumnFilterOptions().valuesFilterOptions.showCurrentlyFilteredValuesOnly).then(distinctFilterDisplayValues => {
      if (ignore) {
        return;
      }
      setQuickFilterValues(Object.assign(Object.assign({}, distinctFilterDisplayValues), {
        dataLoadIsComplete: true
      }));
      setIsDistinctColumnValuesLoading(false);
    });
    return () => {
      ignore = true;
    };
  }, [valuesLoadTrigger]);
  const onVisibleChange = useCallback(visible => {
    if (visible) {
      setValuesLoadTrigger(Object.assign(Object.assign({}, valuesLoadTrigger), {
        counter: valuesLoadTrigger.counter + 1
      }));
    } else {
      setQuickFilterValues({
        values: [],
        suppressClientSideFilter: false,
        dataLoadIsComplete: false
      });
      setTextFilterValue('');
    }
  }, [valuesLoadTrigger, quickFilterValues]);
  const loadedDataIsEmpty = quickFilterValues.dataLoadIsComplete && ArrayExtensions.IsNullOrEmptyOrContainsSingleEmptyValue(quickFilterValues.values);
  const renderNoValuesDropdown = () => {
    return React.createElement(SimpleButton, {
      disabled: true,
      style: {
        flex: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        borderRadius: 0,
        borderLeftWidth: 0,
        borderColor: 'var(--ab-color-primarydark)'
      }
    }, 'No Column Values');
  };
  if (ArrayExtensions.IsNullOrEmpty(props.columnFilter.Predicate.Inputs) && loadedDataIsEmpty) {
    return renderNoValuesDropdown();
  }
  const handleFilterChange = filterText => {
    setTextFilterValue(filterText);
    if (quickFilterValues.suppressClientSideFilter) {
      setValuesLoadTrigger(Object.assign(Object.assign({}, valuesLoadTrigger), {
        counter: valuesLoadTrigger.counter + 1
      }));
    }
  };
  let showEvent = 'mouseenter';
  if (props.valuesFilterTrigger === 'click') {
    showEvent = 'click';
  }
  let selectedValues = props.columnFilter.Predicate.PredicateId === 'Values' ? 'Select Values' : 'Exclude Values';
  if (props.columnFilter.Predicate.Inputs.length) {
    selectedValues = props.columnFilter.Predicate.Inputs.map(input => {
      var _a, _b;
      const label = (_b = (_a = quickFilterValues.values) === null || _a === void 0 ? void 0 : _a.find(distinctValue => {
        if (input instanceof Date) {
          return isSameDay(input, distinctValue.value);
        }
        return distinctValue.value === input;
      })) === null || _b === void 0 ? void 0 : _b.label;
      return label !== null && label !== void 0 ? label : input;
    }).join(', ');
  }
  const quickFilterValuesWidth = props.api.optionsApi.getAdaptableOptions().columnFilterOptions.quickFilterOptions.quickFilterValuesWidth;
  const getPopoverWidth = targetWidth => {
    if (quickFilterValuesWidth === 'auto') {
      return Math.max(180, targetWidth);
    }
    if (typeof quickFilterValuesWidth === 'number') {
      return quickFilterValuesWidth;
    }
    return null;
  };
  const [defaultSize, setDefaultSize] = React.useState(undefined);
  return React.createElement(OverlayTrigger, {
    showEvent: showEvent,
    // cannot hide on blur, because the form input receives the input when this is opened
    hideEvent: "mouseleave",
    hideDelay: 50,
    ref: api => {
      valuesDropdownRef.current = api;
      props.registerValuesDropdownApi(api);
    },
    onVisibleChange: visible => {
      if (currentOverlayVisible.current !== visible) {
        // onVisibleChange() is called redundantly
        currentOverlayVisible.current = visible;
        onVisibleChange(visible);
      }
    },
    render: ({
      targetWidth
    }) => {
      var _a;
      return React.createElement(Resizable, {
        onResizeStop: (_e, _direction, element, _delta) => {
          const {
            width,
            height
          } = element.getBoundingClientRect();
          setDefaultSize({
            width,
            height
          });
        },
        defaultSize: defaultSize,
        style: {
          fontSize: 'var(--ab-font-size-2)',
          border: '1px solid var(--ab-color-primarydark)',
          background: 'var(--ab-color-defaultbackground)',
          zIndex: 1000,
          display: 'flex',
          flexFlow: 'column',
          flexWrap: 'nowrap'
        },
        handleClasses: {
          right: 'ab-QuickFilter-resize-handle ab-QuickFilter-resize-handle--right',
          bottom: 'ab-QuickFilter-resize-handle ab-QuickFilter-resize-handle--bottom',
          bottomRight: 'ab-QuickFilter-resize-handle ab-QuickFilter-resize-handle--bottom-right'
        },
        enable: {
          top: false,
          right: true,
          bottom: true,
          left: false,
          topRight: false,
          bottomRight: true,
          bottomLeft: false,
          topLeft: false
        },
        minWidth: getPopoverWidth(targetWidth)
      }, React.createElement(Flex, {
        onMouseEnter: () => {
          if (showEvent === 'click') {
            // For showEvent=mousenter this is not needed.
            // When mouseenter is triggered on the overlay, onShowFn is called, the overlay is no longer hidden.
            // But in this case because the trigger is click, another show is not triggered.
            // The hide event is added direcly on the element so it does not use the React event system.
            // But this event does, maybe beause it uses event delegation it is triggered to soon.
            requestAnimationFrame(() => {
              var _a;
              (_a = valuesDropdownRef.current) === null || _a === void 0 ? void 0 : _a.show();
            });
          }
        },
        "data-name": "quick-filter-form",
        flexDirection: "column",
        flex: 1,
        display: 'flex'
      }, React.createElement(Flex, {
        m: 2
      }, React.createElement(SimpleButton, {
        onClick: () => clearColumnFilter()
      }, "Clear Filter"), ((_a = api.optionsApi.getColumnFilterOptions()) === null || _a === void 0 ? void 0 : _a.autoApplyFilter) == false && React.createElement(SimpleButton, {
        ml: 2,
        onClick: () => {
          if (transientColumnFilter) {
            props.updateColumnFilter(transientColumnFilter);
          }
        }
      }, "Apply Filter")), React.createElement(ListBoxFilterForm, {
        disabled: props.isFilterDisabled,
        suppressClientSideFilter: quickFilterValues.suppressClientSideFilter,
        isLoading: isDistinctColumnValuesLoading,
        onFilterChange: handleFilterChange,
        currentColumn: props.currentColumn,
        columns: [],
        columnDistinctValues: quickFilterValues.values,
        dataType: props.currentColumn.dataType,
        uiSelectedColumnValues: props.columnFilter.Predicate.Inputs.filter(input => input !== ''),
        useAgGridStyle: true,
        onColumnValueSelectedChange: list => onColumnValueSelectedChange(list)
      })));
    }
  }, React.createElement(SimpleButton, {
    "data-name": 'Select Values',
    style: {
      flex: 1,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      borderRadius: 0,
      borderLeftWidth: 0,
      borderColor: 'var(--ab-color-primarydark)'
    },
    disabled: props.isFilterDisabled || loadedDataIsEmpty
  }, selectedValues));
};