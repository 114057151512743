import * as React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import * as QuickSearchRedux from '../../Redux/ActionsReducers/QuickSearchRedux';
import { PopupPanel } from '../Components/Popups/AdaptablePopup/PopupPanel';
import Panel from '../../components/Panel';
import FormLayout, { FormRow } from '../../components/FormLayout';
import { AdaptableFormControlTextClear } from '../Components/Forms/AdaptableFormControlTextClear';
import { StyleComponent } from '../Components/StyleComponent';
import { Flex } from 'rebass';
import { CheckBox } from '../../components/CheckBox';
import StringExtensions from '../../Utilities/Extensions/StringExtensions';
import { useQuickSearchDebounced } from './useQuickSearchDebounced';
const QuickSearchPopupComponent = props => {
  const [searchText, search] = useQuickSearchDebounced(props);
  const [state, setState] = useState({
    RunQueryAfterQuickSearch: props.api.optionsApi.getQuickSearchOptions().filterResultsAfterQuickSearch,
    EditedStyle: props.QuickSearchStyle
  });
  const onUpdateStyle = style => {
    setState(Object.assign(Object.assign({}, state), {
      EditedStyle: style
    }));
    props.onSetStyle(style);
  };
  const onQuickSearchBehaviourChange = checked => {
    setState(Object.assign(Object.assign({}, state), {
      RunQueryAfterQuickSearch: checked
    }));
    props.api.optionsApi.getAdaptableOptions().quickSearchOptions.filterResultsAfterQuickSearch = checked;
  };
  return React.createElement(PopupPanel, {
    headerText: props.moduleInfo.FriendlyName,
    glyphicon: props.moduleInfo.Glyph,
    infoLink: props.moduleInfo.HelpPage,
    infoLinkDisabled: !props.api.internalApi.isDocumentationLinksDisplayed()
  }, React.createElement(Panel, {
    header: props.moduleInfo.FriendlyName + ' Text',
    style: {
      height: 'auto'
    },
    variant: "default",
    borderRadius: "none",
    marginTop: 3,
    marginLeft: 2,
    marginRight: 2
  }, ' ', React.createElement(FormLayout, null, React.createElement(AdaptableFormControlTextClear, {
    "data-name": "quick-search-text",
    type: "text",
    marginLeft: 2,
    marginRight: 5,
    padding: 20,
    style: {
      height: '100%'
    },
    placeholder: props.api.optionsApi.getQuickSearchOptions().quickSearchPlaceholder,
    value: searchText,
    OnTextChange: search
  }))), React.createElement(Panel, {
    header: props.api.internalApi.getCorrectEnglishVariant('Behaviour'),
    style: {
      height: 'auto'
    },
    variant: "default",
    borderRadius: "none",
    marginTop: 3,
    marginLeft: 2,
    marginRight: 2
  }, ' ', React.createElement(Flex, {
    flexDirection: "column"
  }, React.createElement(FormLayout, {
    columns: [1, 2]
  }, React.createElement(FormRow, null, React.createElement(CheckBox, {
    "data-name": "filter-quick-search-results",
    value: "existing",
    marginLeft: 1,
    marginRight: 3,
    checked: state.RunQueryAfterQuickSearch,
    disabled: StringExtensions.IsNotNullOrEmpty(searchText),
    onChange: onQuickSearchBehaviourChange
  }, "Filter using Quick Search Results"))))), React.createElement(StyleComponent, {
    style: {
      height: '100%'
    },
    api: props.api,
    Style: props.QuickSearchStyle,
    UpdateStyle: onUpdateStyle
  }));
};
function mapStateToProps(state, ownProps) {
  return {
    QuickSearchText: state.QuickSearch.QuickSearchText,
    QuickSearchStyle: state.QuickSearch.Style
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onRunQuickSearch: quickSearchText => dispatch(QuickSearchRedux.QuickSearchRun(quickSearchText)),
    onSetStyle: style => dispatch(QuickSearchRedux.QuickSearchSetStyle(style))
  };
}
export const QuickSearchPopup = connect(mapStateToProps, mapDispatchToProps)(QuickSearchPopupComponent);