import * as React from 'react';
import { ModuleManager } from '../DragAndDropContext/ModuleManager';
const DashboardManager = ({
  tabs,
  onTabsChange,
  availableToolbars,
  api,
  disabled
}) => {
  const moduleService = api.internalApi.getModuleService();
  const moduleInfo = moduleService.getModuleInfoByModule('Dashboard');
  const moduleTabs = tabs.map(tab => {
    return {
      Name: tab.Name,
      Items: tab.Toolbars
    };
  });
  const handleTabsChange = React.useCallback(tabs => {
    const dashboardTabs = tabs.map(tab => ({
      Name: tab.Name,
      Toolbars: tab.Items
    }));
    onTabsChange(dashboardTabs);
  }, []);
  return React.createElement(ModuleManager, {
    availableItems: availableToolbars,
    disabled: disabled,
    onTabsChange: handleTabsChange,
    tabs: moduleTabs,
    tabsTitle: moduleInfo.FriendlyName + ' Tabs',
    unusedPanelTitle: 'Available Toolbars',
    dragItemText: "Drag into a Tab below"
  });
};
export default DashboardManager;