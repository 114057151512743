import { evaluateNode } from './evaluator';
// @ts-ignore
import parser from './parser';
export { findPathTo } from './utils';
const getParser = () => {
  return parser;
};
export function parse(input) {
  const ast = getParser().parse(input.trim());
  const evaluate = context => {
    const result = evaluateNode(ast, context);
    const lastResult = result[result.length - 1];
    return lastResult;
  };
  return {
    ast,
    evaluate
  };
}
export function getAST(input) {
  const {
    ast
  } = parse(input);
  return ast;
}
const cache = {};
export function evaluate(input, context) {
  cache[input] = cache[input] || parse(input);
  return cache[input].evaluate(context);
}
export function getAbstractSyntaxTree(expression) {
  const {
    ast
  } = parse(expression);
  return ast;
}