import { QUICK_SEARCH_DEFAULT_BACK_COLOR, QUICK_SEARCH_DEFAULT_FORE_COLOR, EMPTY_STRING } from '../../Utilities/Constants/GeneralConstants';
/**
 * @ReduxAction Runs Quick Search
 */
export const QUICK_SEARCH_RUN = 'QUICK_SEARCH_RUN';
/**
 * @ReduxAction Sets Quick Search style
 */
export const QUICK_SEARCH_SET_STYLE = 'QUICK_SEARCH_SET_STYLE';
/**
 * @ReduxAction Quick Search Module is ready
 */
export const QUICK_SEARCH_READY = 'QUICK_SEARCH_READY';
export const QuickSearchRun = quickSearchText => ({
  type: QUICK_SEARCH_RUN,
  quickSearchText
});
export const QuickSearchSetStyle = style => ({
  type: QUICK_SEARCH_SET_STYLE,
  style
});
export const QuickSearchReady = quickSearchState => ({
  type: QUICK_SEARCH_READY,
  quickSearchState
});
const initialState = {
  QuickSearchText: EMPTY_STRING,
  Style: {
    BackColor: QUICK_SEARCH_DEFAULT_BACK_COLOR,
    ForeColor: QUICK_SEARCH_DEFAULT_FORE_COLOR
  }
};
export const QuickSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case QUICK_SEARCH_RUN:
      return Object.assign({}, state, {
        QuickSearchText: action.quickSearchText
      });
    case QUICK_SEARCH_SET_STYLE:
      return Object.assign({}, state, {
        Style: action.style
      });
    default:
      return state;
  }
};