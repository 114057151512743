import { EMPTY_ARRAY } from '../../Utilities/Constants/GeneralConstants';
import AdaptableHelper from '../../Utilities/Helpers/AdaptableHelper';
import { changeIsSuspendInList, suspendAllInList, unsuspendAllInList } from './utils';
/**
 * @ReduxAction A Special Column Style has been added
 */
export const STYLED_COLUMN_ADD = 'STYLED_COLUMN_ADD';
/**
 * @ReduxAction A Special Column Style has been edited
 */
export const STYLED_COLUMN_EDIT = 'STYLED_COLUMN_EDIT';
/**
 * @ReduxAction A Special Column Style has been deleted
 */
export const STYLED_COLUMN_DELETE = 'STYLED_COLUMN_DELETE';
/**
 * @ReduxAction StyledColumn Module is ready
 */
export const STYLED_COLUMN_READY = 'STYLED_COLUMN_READY';
/**
 * @ReduxAction StyledColumn Module is suspended
 */
export const STYLED_COLUMN_SUSPEND = 'STYLED_COLUMN_SUSPEND';
/**
 * @ReduxAction StyledColumn Module is unsuspended, or activated
 */
export const STYLED_COLUMN_UNSUSPEND = 'STYLED_COLUMN_UNSUSPEND';
/**
 * @ReduxAction All StyledColumn Modules are suspended
 */
export const STYLED_COLUMN_SUSPEND_ALL = 'STYLED_COLUMN_SUSPEND_ALL';
/**
 * @ReduxAction All StyledColumn Modules are unsuspended, or activated
 */
export const STYLED_COLUMN_UNSUSPEND_ALL = 'STYLED_COLUMN_UNSUSPEND_ALL';
export const StyledColumnAdd = formatColumn => ({
  type: STYLED_COLUMN_ADD,
  StyledColumn: formatColumn
});
export const StyledColumnEdit = formatColumn => ({
  type: STYLED_COLUMN_EDIT,
  StyledColumn: formatColumn
});
export const StyledColumnDelete = formatColumn => ({
  type: STYLED_COLUMN_DELETE,
  StyledColumn: formatColumn
});
export const StyledColumnSuspend = formatColumn => ({
  type: STYLED_COLUMN_SUSPEND,
  StyledColumn: formatColumn
});
export const StyledColumnUnSuspend = formatColumn => ({
  type: STYLED_COLUMN_UNSUSPEND,
  StyledColumn: formatColumn
});
export const StyledColumnSuspendAll = () => ({
  type: STYLED_COLUMN_SUSPEND_ALL
});
export const StyledColumnUnSuspendAll = () => ({
  type: STYLED_COLUMN_UNSUSPEND_ALL
});
export const StyledColumnReady = formatColumnState => ({
  type: STYLED_COLUMN_READY,
  StyledColumnState: formatColumnState
});
const initialState = {
  StyledColumns: EMPTY_ARRAY
};
export const StyledColumnReducer = (state = initialState, action) => {
  let formatColumns;
  switch (action.type) {
    case STYLED_COLUMN_ADD:
      {
        const actionStyledColumn = action.StyledColumn;
        AdaptableHelper.addUuidAndSource(actionStyledColumn);
        formatColumns = [].concat(state.StyledColumns);
        formatColumns.push(actionStyledColumn);
        return Object.assign(Object.assign({}, state), {
          StyledColumns: formatColumns
        });
      }
    case STYLED_COLUMN_EDIT:
      const actionStyledColumn = action.StyledColumn;
      return Object.assign(Object.assign({}, state), {
        StyledColumns: state.StyledColumns.map(abObject => abObject.Uuid === actionStyledColumn.Uuid ? actionStyledColumn : abObject)
      });
    case STYLED_COLUMN_DELETE:
      {
        const actionStyledColumn = action.StyledColumn;
        return Object.assign(Object.assign({}, state), {
          StyledColumns: state.StyledColumns.filter(abObject => abObject.Uuid !== actionStyledColumn.Uuid)
        });
      }
    case STYLED_COLUMN_SUSPEND:
      {
        return Object.assign(Object.assign({}, state), {
          StyledColumns: changeIsSuspendInList(action.StyledColumn, state.StyledColumns, true)
        });
      }
    case STYLED_COLUMN_UNSUSPEND:
      {
        return Object.assign(Object.assign({}, state), {
          StyledColumns: changeIsSuspendInList(action.StyledColumn, state.StyledColumns, false)
        });
      }
    case STYLED_COLUMN_SUSPEND_ALL:
      {
        return Object.assign(Object.assign({}, state), {
          StyledColumns: suspendAllInList(state.StyledColumns)
        });
      }
    case STYLED_COLUMN_UNSUSPEND_ALL:
      {
        return Object.assign(Object.assign({}, state), {
          StyledColumns: unsuspendAllInList(state.StyledColumns)
        });
      }
    default:
      return state;
  }
};