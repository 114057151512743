import { ApiBase } from './ApiBase';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import * as PlusMinusRedux from '../../Redux/ActionsReducers/PlusMinusRedux';
export class PlusMinusApiImpl extends ApiBase {
  getPlusMinusState() {
    return this.getAdaptableState().PlusMinus;
  }
  getAllPlusMinus(config) {
    var _a;
    const layoutSpecificPlusMinusNudges = (_a = this.handleLayoutAssociatedObjects(this.getPlusMinusState().PlusMinusNudges, 'PlusMinus', config)) !== null && _a !== void 0 ? _a : [];
    return layoutSpecificPlusMinusNudges.filter(plusMinusNudge => plusMinusNudge.Rule == undefined || plusMinusNudge.Rule.BooleanExpression != undefined);
  }
  getPlusMinusById(id) {
    return this.getAllPlusMinus().find(plusMinus => (plusMinus === null || plusMinus === void 0 ? void 0 : plusMinus.Uuid) === id);
  }
  getAllActivePlusMinus() {
    return this.getAllPlusMinus().filter(plusMinusNudge => !plusMinusNudge.IsSuspended);
  }
  getAllSuspendedPlusMinus() {
    return this.getAllPlusMinus().filter(plusMinusNudge => plusMinusNudge.IsSuspended);
  }
  runPlusMinusNudge(plusMinusNudge, cellsToUpdate, direction) {
    let plusMinusModule = this.adaptable.adaptableModules.get(ModuleConstants.PlusMinusModuleId);
    plusMinusModule.applyPlusMinus([plusMinusNudge], cellsToUpdate, direction);
  }
  applyPlusMinus(cellUpdateRequests) {
    this.dispatchAction(PlusMinusRedux.PlusMinusApply(cellUpdateRequests));
  }
  openPlusMinusSettingsPanel() {
    this.showModulePopup(ModuleConstants.PlusMinusModuleId);
  }
  editPlusMinusNudge(plusMinusNudge) {
    this.dispatchAction(PlusMinusRedux.PlusMinusNudgeEdit(plusMinusNudge));
    return this.getPlusMinusById(plusMinusNudge.Uuid);
  }
  suspendPlusMinusNudge(plusMinusNudge) {
    this.dispatchAction(PlusMinusRedux.PlusMinusNudgeSuspend(plusMinusNudge));
    return this.getPlusMinusById(plusMinusNudge.Uuid);
  }
  unSuspendPlusMinusNudge(plusMinusNudge) {
    this.dispatchAction(PlusMinusRedux.PlusMinusNudgeUnSuspend(plusMinusNudge));
    return this.getPlusMinusById(plusMinusNudge.Uuid);
  }
  addPlusMinusNudge(plusMinusNudge) {
    this.addUidToAdaptableObject(plusMinusNudge);
    this.dispatchAction(PlusMinusRedux.PlusMinusNudgeAdd(plusMinusNudge));
    return this.getPlusMinusById(plusMinusNudge.Uuid);
  }
}