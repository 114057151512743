import { AdaptableModuleBase } from './AdaptableModuleBase';
import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
export class Fdc3Module extends AdaptableModuleBase {
  constructor(api) {
    super(ModuleConstants.Fdc3ModuleId, ModuleConstants.Fdc3FriendlyName, 'fdc3', null, 'The FDC3 Module - handles the FDC3 integration', api);
  }
  createContextMenuItems(menuContext) {
    if (!this.isModuleAvailable()) {
      return;
    }
    if (menuContext.adaptableColumn && menuContext.rowNode) {
      const currentColumnId = menuContext.adaptableColumn.columnId;
      const currentRowNode = menuContext.rowNode;
      const contextMenuItems = [];
      this.getFdc3Api().internalApi.getAllRaiseIntentConfigs().filter(raiseIntentConfig => {
        var _a;
        return (_a = raiseIntentConfig.contextMenu) === null || _a === void 0 ? void 0 : _a.columnIds.includes(currentColumnId);
      }).forEach(raiseIntentConfig => {
        contextMenuItems.push(this.buildRaiseIntentMenuItem(raiseIntentConfig, currentRowNode));
      });
      this.getFdc3Api().internalApi.getAllBroadcastContextConfigs().filter(broadcastConfig => {
        var _a;
        return (_a = broadcastConfig.contextMenu) === null || _a === void 0 ? void 0 : _a.columnIds.includes(currentColumnId);
      }).forEach(broadcastConfig => {
        contextMenuItems.push(this.buildBroadcastMenuItem(broadcastConfig, currentRowNode));
      });
      contextMenuItems.sort((a, b) => a.label.localeCompare(b.label));
      return contextMenuItems;
    }
  }
  buildRaiseIntentMenuItem(intentConfig, rowNode) {
    var _a, _b, _c;
    const {
      intentType,
      contextType
    } = intentConfig;
    const menuItem = {
      name: 'fdc3-raise-intent',
      label: `Raise ${intentType}`,
      onClick: () => {
        this.getFdc3Api().raiseIntentFromRow(rowNode, intentType, contextType);
      },
      isVisible: true,
      module: this.moduleInfo.ModuleName
    };
    if ((_a = intentConfig.contextMenu) === null || _a === void 0 ? void 0 : _a.icon) {
      const menuItemIcon = ((_b = intentConfig.contextMenu) === null || _b === void 0 ? void 0 : _b.icon) === '_defaultFdc3' ? this.getFdc3Api().internalApi.getDefaultIconForIntent(intentType) : (_c = intentConfig.contextMenu) === null || _c === void 0 ? void 0 : _c.icon;
      menuItem.icon = menuItemIcon;
    }
    return menuItem;
  }
  buildBroadcastMenuItem(contextConfig, rowNode) {
    var _a, _b, _c;
    const {
      contextType
    } = contextConfig;
    const menuItem = {
      name: 'fdc3-broadcast',
      label: `Broadcast ${this.getFdc3Api().getContextLabel(contextType)}`,
      onClick: () => {
        this.getFdc3Api().broadcastFromRow(rowNode, contextType);
      },
      isVisible: true,
      module: this.moduleInfo.ModuleName
    };
    if ((_a = contextConfig.contextMenu) === null || _a === void 0 ? void 0 : _a.icon) {
      const menuItemIcon = ((_b = contextConfig.contextMenu) === null || _b === void 0 ? void 0 : _b.icon) === '_defaultFdc3' ? this.getFdc3Api().internalApi.getDefaultIconForContext(contextType) : (_c = contextConfig.contextMenu) === null || _c === void 0 ? void 0 : _c.icon;
      menuItem.icon = menuItemIcon;
    }
    return menuItem;
  }
  getFdc3Api() {
    return this.api.fdc3Api;
  }
}