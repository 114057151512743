import { ApiBase } from '../Implementation/ApiBase';
import ArrayExtensions from '../../Utilities/Extensions/ArrayExtensions';
export class StyledColumnInternalApi extends ApiBase {
  getMinValueForNumericColumn(column) {
    if (column.dataType !== 'Number') {
      return undefined;
    }
    return this.adaptable.getMinMaxCachedValueForColumn(column, 'min');
  }
  getMaxValueForNumericColumn(column) {
    if (column.dataType !== 'Number') {
      return undefined;
    }
    return this.adaptable.getMinMaxCachedValueForColumn(column, 'max');
  }
  /**
   * Gets the Minimum Value to display for a Styled Column
   * @param styledColumn Styled Column to check
   * @param rowNode current Row Node
   * @param cellValue current Cell Value
   */
  getNumericStyleMinValue(styledColumn, column, rowNode, cellValue) {
    var _a, _b;
    const columnComparison = styledColumn.GradientStyle ? styledColumn.GradientStyle.ColumnComparison : (_a = styledColumn.PercentBarStyle) === null || _a === void 0 ? void 0 : _a.ColumnComparison;
    if (columnComparison) {
      if (!isNaN(Number(columnComparison.MinValue))) {
        return Number(columnComparison.MinValue);
      } else {
        let columnId = columnComparison.MinValue;
        if (!this.getAdaptableApi().columnApi.isColumnInGrid(columnId)) {
          return 0;
        } else {
          return this.getAdaptableApi().gridApi.getRawValueFromRowNode(rowNode, columnId);
        }
      }
    }
    // for Gradient Column we want just the range that contains cell value
    if (styledColumn.GradientStyle) {
      const range = this.findRangeForColumn(styledColumn.GradientStyle.CellRanges, column, styledColumn.GradientStyle.RangeValueType, cellValue);
      return this.getCellColorRangeMinValue(range, column, styledColumn.GradientStyle.RangeValueType);
    }
    // for percentbar we want to get the whole Ranges
    if (styledColumn.PercentBarStyle) {
      const ranges = (_b = styledColumn.PercentBarStyle) === null || _b === void 0 ? void 0 : _b.CellRanges;
      if (ranges) {
        return this.getCellColorRangeMinValue(ranges[0], column, styledColumn.PercentBarStyle.RangeValueType);
      }
    }
  }
  /**
   * Returns last range that matches the cell value.
   *
   * @param cellRanges
   * @param column
   * @param rangeValueType
   * @param cellValue
   */
  findRangeForColumn(cellRanges, column, rangeValueType, cellValue) {
    if (!cellRanges || cellRanges.length === 0) {
      return null;
    }
    let lastMatchingRange = null;
    // findLast is not available on [], target is not es6
    for (let i = cellRanges.length; i >= 0; i--) {
      const cellRange = cellRanges[i];
      const minRange = this.getCellColorRangeMinValue(cellRange, column, rangeValueType);
      const maxRange = this.getCellColorRangeMaxValue(cellRange, column, rangeValueType);
      if (cellValue >= minRange && cellValue <= maxRange) {
        lastMatchingRange = cellRange;
        break;
      }
    }
    return lastMatchingRange;
  }
  /**
   * Gets the Maximum Value to display for a Styled Column
   * @param styledColumn Styled Column to check
   * @param rowNode current Row Node
   * @param cellValue current Cell Value
   */
  getNumericStyleMaxValue(styledColumn, column, rowNode, cellValue) {
    var _a, _b;
    const columnComparison = styledColumn.GradientStyle ? styledColumn.GradientStyle.ColumnComparison : (_a = styledColumn.PercentBarStyle) === null || _a === void 0 ? void 0 : _a.ColumnComparison;
    if (columnComparison) {
      if (!isNaN(Number(columnComparison.MaxValue))) {
        return Number(columnComparison.MaxValue);
      } else {
        let columnId = columnComparison.MaxValue;
        if (!this.getAdaptableApi().columnApi.isColumnInGrid(columnId)) {
          return 0;
        } else {
          return this.getAdaptableApi().gridApi.getRawValueFromRowNode(rowNode, columnId);
        }
      }
    }
    if (styledColumn.GradientStyle) {
      let range = this.findRangeForColumn(styledColumn.GradientStyle.CellRanges, column, styledColumn.GradientStyle.RangeValueType, cellValue);
      return this.getCellColorRangeMaxValue(range, column, styledColumn.GradientStyle.RangeValueType);
    }
    if (styledColumn.PercentBarStyle) {
      const ranges = (_b = styledColumn.PercentBarStyle) === null || _b === void 0 ? void 0 : _b.CellRanges;
      if (ranges) {
        return this.getCellColorRangeMaxValue(ranges[ranges.length - 1], column, styledColumn.PercentBarStyle.RangeValueType);
      }
    }
  }
  /**
   * Returns the smallest number in a Range
   * @param range Range to check
   * @param column current Column
   */
  getCellColorRangeMinValue(range, column, rangeValueType) {
    if (!range) {
      return undefined;
    }
    if (range.Min == undefined) {
      return undefined;
    }
    let minValue;
    if (typeof range.Min === 'number' && rangeValueType === 'Percentage') {
      const minColumnValue = this.getMinValueForNumericColumn(column);
      const maxColumnValue = this.getMaxValueForNumericColumn(column);
      minValue = minColumnValue + range.Min / 100 * (maxColumnValue - minColumnValue);
    } else if (range.Min === 'Col-Min') {
      const minColumnValue = this.getMinValueForNumericColumn(column);
      minValue = minColumnValue;
    } else {
      minValue = range.Min;
    }
    return minValue;
  }
  /**
   * Returns the largest number in a Range
   * @param range Range to check
   * @param column current Column
   */
  getCellColorRangeMaxValue(range, column, rangeValueType) {
    if (!range) {
      return undefined;
    }
    if (range.Max == undefined) {
      return undefined;
    }
    let maxValue;
    if (typeof range.Max === 'number' && rangeValueType === 'Percentage') {
      const minColumnValue = this.getMinValueForNumericColumn(column);
      const maxColumnValue = this.getMaxValueForNumericColumn(column);
      maxValue = minColumnValue + range.Max / 100 * (maxColumnValue - minColumnValue);
    } else if (range.Max === 'Col-Max') {
      const maxColumnValue = this.getMaxValueForNumericColumn(column);
      maxValue = maxColumnValue;
    } else {
      maxValue = range.Max;
    }
    return maxValue;
  }
  /**
   * Retrieves the ColumnComparison property, if there, from a Styled Column
   * @param styledColumn Styled Column to Add
   */
  getColumnComparisonForStyledColumn(styledColumn) {
    var _a, _b;
    if (styledColumn.GradientStyle) {
      if ((_a = styledColumn.GradientStyle) === null || _a === void 0 ? void 0 : _a.ColumnComparison) {
        return styledColumn.GradientStyle.ColumnComparison;
      }
    }
    if (styledColumn.PercentBarStyle) {
      if ((_b = styledColumn.PercentBarStyle) === null || _b === void 0 ? void 0 : _b.ColumnComparison) {
        return styledColumn.PercentBarStyle.ColumnComparison;
      }
    }
    return undefined;
  }
  /**
   * Returns any ColumnIds referenced in a Column Comparison
   * @param columnComparision Column Comparison to check
   */
  getColumnIdsFromColumnComparison(columnComparison) {
    let returnValues = [];
    if (typeof columnComparison.MinValue === 'string') {
      let minIds = this.getDependentColumnIds(columnComparison.MinValue);
      if (ArrayExtensions.IsNotNullOrEmpty(minIds)) {
        returnValues.push(...minIds);
      }
    }
    if (typeof columnComparison.MaxValue === 'string') {
      let maxIds = this.getDependentColumnIds(columnComparison.MaxValue);
      if (ArrayExtensions.IsNotNullOrEmpty(maxIds)) {
        returnValues.push(...maxIds);
      }
    }
    return returnValues;
  }
  getDependentColumnIds(columnId) {
    if (this.getColumnApi().isCalculatedColumn(columnId)) {
      return this.getCalculatedColumnApi().internalApi.getReferencedColumnIdsForCalculatedColumnId(columnId);
    } else {
      return [columnId];
    }
  }
  /**
   * Checks if the styled column has a range with a relative value
   * @param styledColumn Styled Column to Add
   */
  hasStyledColumnRelativeCellRange(styledColumn) {
    var _a, _b, _c, _d, _e;
    let cellColorRange = null;
    let rangeValueType = 'Number';
    if (styledColumn.GradientStyle) {
      if ((_a = styledColumn.GradientStyle) === null || _a === void 0 ? void 0 : _a.CellRanges) {
        cellColorRange = styledColumn.GradientStyle.CellRanges;
      }
      if ((_b = styledColumn.GradientStyle) === null || _b === void 0 ? void 0 : _b.RangeValueType) {
        rangeValueType = styledColumn.GradientStyle.RangeValueType;
      }
    }
    if (styledColumn.PercentBarStyle) {
      if ((_c = styledColumn.PercentBarStyle) === null || _c === void 0 ? void 0 : _c.CellRanges) {
        cellColorRange = styledColumn.PercentBarStyle.CellRanges;
      }
      if ((_d = styledColumn.PercentBarStyle) === null || _d === void 0 ? void 0 : _d.RangeValueType) {
        rangeValueType = styledColumn.PercentBarStyle.RangeValueType;
      }
    }
    return (_e = rangeValueType === 'Percentage' || (cellColorRange === null || cellColorRange === void 0 ? void 0 : cellColorRange.some(cellColorRange => {
      return cellColorRange.Min == 'Col-Min' || cellColorRange.Max == 'Col-Max';
    }))) !== null && _e !== void 0 ? _e : false;
  }
  getApplicableBadge(badgeStyle, context) {
    if (!badgeStyle.Badges.length) {
      return null;
    }
    // first that matches, sort last ones without predicate
    const badgesWithoutAll = [];
    const badgesWithAll = [];
    for (let badge of badgeStyle.Badges) {
      if (!badge.Predicate) {
        badgesWithAll.push(badge);
      } else {
        badgesWithoutAll.push(badge);
      }
    }
    for (let badge of badgesWithoutAll) {
      if (this.getPredicateApi().handlePredicate(badge.Predicate, context, false)) {
        return badge;
      }
    }
    const [firstBadgeWithAll] = badgesWithAll;
    return firstBadgeWithAll !== null && firstBadgeWithAll !== void 0 ? firstBadgeWithAll : null;
  }
  getBadgePredicateDefsForColumn(columnId) {
    const column = this.getAdaptableApi().columnApi.getColumnWithColumnId(columnId);
    if (!column) {
      return undefined;
    }
    const scope = {
      ColumnIds: [column.columnId]
    };
    return this.getAdaptableApi().predicateApi.internalApi.getBadgeStylePredicateDefs(scope).filter(predicate => this.getAdaptableApi().columnScopeApi.isColumnInScope(column, predicate.columnScope));
  }
}