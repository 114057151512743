import { isAdaptableRuleValid } from '../../Components/EntityRulesEditor/Utilities';
export const isValidFlashingCellRules = (flashingCell, api, context) => {
  var _a, _b;
  if (!((_b = (_a = flashingCell.Rule) === null || _a === void 0 ? void 0 : _a.Predicates) === null || _b === void 0 ? void 0 : _b.length) && !flashingCell.Rule.BooleanExpression) {
    return 'No Rule defined for Flashing Cell';
  }
  const isRuleValid = isAdaptableRuleValid(flashingCell, api, context);
  if (typeof isRuleValid === 'string') {
    return isRuleValid;
  }
  // boolean expressions may also be saved as named queries
  if (context.namedQuery != false) {
    const isValidName = api.namedQueryApi.isValidNamedQuery(context.namedQuery);
    if (!isValidName.valid) {
      return isValidName.message;
    }
  }
  return true;
};