import * as React from 'react';
import { connect } from 'react-redux';
import * as GridRedux from '../../Redux/ActionsReducers/GridRedux';
import { CellSummaryDetails } from './CellSummaryDetails';
import { PanelWithButton } from '../Components/Panels/PanelWithButton';
class CellSummaryPopupComponent extends React.Component {
  componentDidMount() {
    var _a, _b, _c;
    if (((_a = this.props.popupParams) === null || _a === void 0 ? void 0 : _a.source) === 'ColumnMenu' && ((_b = this.props.popupParams) === null || _b === void 0 ? void 0 : _b.column)) {
      // if the summary was requested from the column menu, we need to select the entire column
      // afterwards, we will go on with the cell summary logic, as that will evaluate the current(column) cell selection
      this.props.api.columnApi.selectColumn((_c = this.props.popupParams) === null || _c === void 0 ? void 0 : _c.column.columnId);
      // we also need to update the internal state of the selected cells
      this.props.api.internalApi.getAdaptableInstance().refreshSelectedCellsState();
    }
    this.props.onCreateCellSummary();
  }
  render() {
    return React.createElement(PanelWithButton, {
      headerText: this.props.moduleInfo.FriendlyName,
      glyphicon: this.props.moduleInfo.Glyph,
      infoLink: this.props.moduleInfo.HelpPage,
      infoLinkDisabled: !this.props.api.internalApi.isDocumentationLinksDisplayed()
    }, React.createElement(CellSummaryDetails, {
      CellSummary: this.props.CellSummary
    }));
  }
}
function mapStateToProps(state, ownProps) {
  return {
    CellSummary: state.Grid.CellSummary
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onCreateCellSummary: () => dispatch(GridRedux.GridCreateCellSummary())
  };
}
export let CellSummaryPopup = connect(mapStateToProps, mapDispatchToProps)(CellSummaryPopupComponent);