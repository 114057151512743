import * as React from 'react';
import { Flex, Text } from 'rebass';
import { useAdaptable } from '../../../AdaptableContext';
import { AdaptableIconComponent } from '../../AdaptableIconComponent';
export const TopBar = props => {
  var _a;
  const baseClassName = 'ab-Adaptable-Popup__TopBar';
  const adaptable = useAdaptable();
  const applicationIcon = (_a = adaptable.api.optionsApi.getUserInterfaceOptions()) === null || _a === void 0 ? void 0 : _a.applicationIcon;
  let icon = React.createElement(React.Fragment, null);
  const iconClassName = `${baseClassName}__Icon`;
  if (props.icon === 'ConfigurationIcon') {
    icon = React.createElement(AdaptableIconComponent, {
      iconClassName: iconClassName,
      icon: {
        name: 'settings'
      }
    });
  } else if (props.icon === 'ApplicationIcon' && applicationIcon) {
    icon = React.createElement(AdaptableIconComponent, {
      icon: applicationIcon,
      iconClassName: iconClassName
    });
  } else if (typeof props.icon === 'object') {
    icon = React.createElement(AdaptableIconComponent, {
      icon: props.icon,
      iconClassName: iconClassName
    });
  }
  return React.createElement(Flex, {
    flex: 0,
    className: baseClassName
  }, icon, React.createElement(Text, {
    className: `${baseClassName}__Title`
  }, props.children));
};
export default TopBar;