import { ApiBase } from '../Implementation/ApiBase';
import { cloneObject } from '../../Utilities/Helpers/Helper';
import { FLASHING_CELL_ROW_KEY } from '../../Redux/ActionsReducers/SystemRedux';
import * as AlertRedux from '../../Redux/ActionsReducers/AlertRedux';
export class FlashingCellInternalApi extends ApiBase {
  /**
   * Merges a Flashing Cell Definition with default values
   * @param flashingCellDefinition Flashing Cell Definition to Merge
   */
  mergeFlashingCellDefinitionWithDefaults(flashingCellDefinition) {
    let flashingDefaults = this.adaptable.adaptableOptions.flashingCellOptions;
    if (!flashingDefaults) {
      return flashingCellDefinition;
    }
    // deep clone it, otherwise we will mutate&share(!!) the global flashingCellOptions object
    flashingDefaults = cloneObject(flashingDefaults);
    if (!flashingCellDefinition.DownChangeStyle && flashingDefaults.defaultDownChangeStyle) {
      flashingCellDefinition.DownChangeStyle = flashingDefaults.defaultDownChangeStyle;
    }
    if (!flashingCellDefinition.UpChangeStyle && flashingDefaults.defaultUpChangeStyle) {
      flashingCellDefinition.UpChangeStyle = flashingDefaults.defaultUpChangeStyle;
    }
    if (!flashingCellDefinition.NeutralChangeStyle && flashingDefaults.defaultNeutralChangeStyle) {
      flashingCellDefinition.NeutralChangeStyle = flashingDefaults.defaultNeutralChangeStyle;
    }
    if (!flashingCellDefinition.FlashDuration && flashingDefaults.defaultFlashDuration) {
      flashingCellDefinition.FlashDuration = flashingDefaults.defaultFlashDuration;
    }
    if (!flashingCellDefinition.FlashTarget && flashingDefaults.defaultFlashTarget) {
      flashingCellDefinition.FlashTarget = flashingDefaults.defaultFlashTarget;
    }
    return flashingCellDefinition;
  }
  getAdaptableFlashingCellFor(primaryKey, columnId) {
    var _a;
    const {
      AdaptableFlashingCells: AdaptableFlashingAlerts,
      AdaptableFlashingCellsMap: AdaptableFlashingAlertsMap
    } = this.getAdaptableState().System;
    const forPrimaryKey = (_a = AdaptableFlashingAlerts[primaryKey]) !== null && _a !== void 0 ? _a : {};
    const toFlashingAlert = uuid => {
      var _a;
      return uuid ? (_a = AdaptableFlashingAlertsMap[uuid]) !== null && _a !== void 0 ? _a : null : null;
    };
    const adaptableFlashingCell = columnId ? toFlashingAlert(forPrimaryKey[columnId]) : toFlashingAlert(forPrimaryKey[FLASHING_CELL_ROW_KEY]);
    if ((adaptableFlashingCell === null || adaptableFlashingCell === void 0 ? void 0 : adaptableFlashingCell.flashingCellDefinition) && !this.getAdaptableApi().layoutApi.internalApi.isObjectAvailableInLayout(adaptableFlashingCell.flashingCellDefinition, 'FlashingCell', this.getAdaptableApi().layoutApi.getCurrentLayout())) {
      return;
    }
    return adaptableFlashingCell;
  }
  clearFlashingCellState() {
    this.dispatchAction(AlertRedux.AlertClearFlashingCells());
  }
}