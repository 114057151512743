import * as React from 'react';
import { useSelector } from 'react-redux';
import { Flex } from 'rebass';
import { Drawer } from '../../../components/Drawer/index';
import { SystemQuickSearchFloatingVisibilitySelector } from '../../../Redux/ActionsReducers/SystemRedux';
import { useAdaptable } from '../../AdaptableContext';
import { QuickSearchInput } from '../QuickSearchInput';
export const QuickSearchDrawer = () => {
  const adaptable = useAdaptable();
  const isOpen = useSelector(state => SystemQuickSearchFloatingVisibilitySelector(state.System));
  return React.createElement(Drawer, {
    open: isOpen,
    onOpenChange: open => {
      if (open) {
        adaptable.api.quickSearchApi.showFloatingQuickSearch();
      } else {
        adaptable.api.quickSearchApi.hideFloatingQuickSearch();
      }
    }
  }, React.createElement(Flex, {
    className: "ab-FloatinQuickSearch"
  }, React.createElement(QuickSearchInput, {
    autoFocus: true
  })));
};