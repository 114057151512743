import * as React from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import * as SystemRedux from '../../../../Redux/ActionsReducers/SystemRedux';
import { useAdaptable } from '../../../AdaptableContext';
export const PreviewChartSection = props => {
  const currentChartModels = useSelector(state => SystemRedux.SystemChartingCurrentChartModelsSelector(state.System));
  const [localChartId, setLocalChartId] = React.useState(null);
  const adaptable = useAdaptable();
  const divRef = React.useRef(null);
  React.useEffect(() => {
    if (!divRef.current) {
      return;
    }
    const chartRef = adaptable.api.chartingApi.showChartDefinition(props.chartDefinition, divRef.current);
    setLocalChartId(chartRef.chartId);
    chartRef.chart;
    return () => {
      chartRef.destroyChart();
    };
  }, [divRef]);
  React.useEffect(() => {
    const model = currentChartModels.find(model => model.chartId == localChartId);
    if (model && !isEqual(model, props.chartDefinition.Model)) {
      props.onChange(Object.assign(Object.assign({}, props.chartDefinition), {
        Model: model
      }));
    }
  }, [currentChartModels]);
  return React.createElement("div", {
    ref: divRef
  });
};