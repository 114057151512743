import { StringExtensions } from '../Extensions/StringExtensions';
import { AdaptableLogger } from '../../agGrid/AdaptableLogger';
export function objectExists(item) {
  return item != null && item != undefined;
}
export function objectNotExists(item) {
  return !objectExists(item);
}
export function objectHasKeys(item) {
  return Object.keys(item).length > 0;
}
export function getStringRepresentionFromKey(event) {
  if (event.key == null) {
    return event.char; // IE
  }
  return event.key;
}
export function cloneObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}
export const arrayToKeyMap = arr => {
  const defaultAccumulator = {};
  if (!arr || !Array.isArray(arr)) {
    return defaultAccumulator;
  }
  return arr.reduce((acc, key) => {
    acc[key] = true;
    return acc;
  }, defaultAccumulator);
};
// converts an array (or an array of arrays) to CSV
export function convertArrayToCsv(array, separator = " ' ") {
  let csvContent = '';
  array.forEach((infoArray, index) => {
    let line = [];
    let item;
    let i;
    for (i = 0; i < infoArray.length; ++i) {
      item = infoArray[i];
      if (separator == ',') {
        if (item != null && item != undefined) {
          if (typeof item === 'string' || item instanceof String) {
            if (item.indexOf(',') !== -1 || item.indexOf('"') !== -1) {
              item = `"${item.replace(/"/g, '""')}"`;
            }
            // bit of a hack but we have a user where they have "+2502+S" as a value which Excel then thinks is a formula
            if (item.indexOf('+') == 0) {
              item = `'${item}'`;
            }
          }
        }
      }
      line.push(item);
    }
    csvContent += line.join(separator) + (index != array.length - 1 ? '\n' : '');
  });
  return csvContent;
}
export function createDownloadedFile(content, fileName, mimeType) {
  let a = document.createElement('a');
  mimeType = mimeType || 'application/octet-stream';
  if (URL && 'download' in a) {
    // html5 A[download]
    a.href = URL.createObjectURL(new Blob([content], {
      type: mimeType
    }));
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    location.href = `data:application/octet-stream,${encodeURIComponent(content)}`; // only this mime type is supported
  }
}
// Copies a string to the clipboard. Must be called from within an
// event handler such as click. May return false if it failed, but
// this is not always possible. Browser support for Chrome 43+,
// Firefox 42+, Safari 10+, Edge and IE 10+.
// IE: The clipboard feature may be disabled by an administrator. By
// default a prompt is shown the first time the clipboard is
// used (per session).
export function copyToClipboard(text) {
  if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    let textarea = document.createElement('textarea');
    textarea.textContent = text;
    textarea.style.width = '1px';
    textarea.style.height = '1px';
    textarea.style.top = '0px';
    textarea.style.left = '0px';
    textarea.style.position = 'absolute';
    textarea.style.opacity = '0.0';
    document.body.appendChild(textarea);
    textarea.select();
    textarea.focus();
    try {
      return document.execCommand('copy'); // Security exception may be thrown by some browsers.
    } catch (ex) {
      AdaptableLogger.consoleErrorBase('Copy to clipboard failed.', ex);
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }
  AdaptableLogger.consoleWarnBase('Copy not available on this computer.');
}
export function returnItemCount(items, itemName) {
  if (items.length == 0) {
    return `No ${itemName}s`;
  }
  return items.length == 1 ? `1 ${itemName}` : `${items.length} ${itemName}s`;
}
export function isInputNullOrEmpty(itemToCheck) {
  if (itemToCheck == 'Invalid Date ') {
    return true;
  }
  if (typeof itemToCheck === 'string') {
    return StringExtensions.IsNullOrEmpty(itemToCheck);
  }
  if (typeof itemToCheck === 'number') {
    return StringExtensions.IsNullOrEmpty(itemToCheck.toString());
  }
  if (itemToCheck instanceof Date) {
    return StringExtensions.IsNullOrEmpty(itemToCheck.toString());
  }
  return itemToCheck == null;
}
export function isInputNotNullOrEmpty(itemToCheck) {
  return !isInputNullOrEmpty(itemToCheck);
}
export function roundNumber(numberToRound, decimalPlaces) {
  switch (decimalPlaces) {
    case 1:
      return Math.round(numberToRound * 10) / 10;
    case 2:
      return Math.round(numberToRound * 100) / 100;
    case 3:
      return Math.round(numberToRound * 1000) / 1000;
    case 4:
      return Math.round(numberToRound * 10000) / 10000;
    case 5:
      return Math.round(numberToRound * 100000) / 100000;
    case 6:
      return Math.round(numberToRound * 1000000) / 1000000;
  }
}
export function roundNumberTo4dp(numberToRound) {
  return roundNumber(numberToRound, 4);
}
// putting this here to remove lodash clamp which seems to cause an issue
export function clamp(value, boundOne, boundTwo) {
  if (!boundTwo) {
    return Math.max(value, boundOne) === boundOne ? value : boundOne;
  } else if (Math.min(value, boundOne) === value) {
    return boundOne;
  } else if (Math.max(value, boundTwo) === value) {
    return boundTwo;
  }
  return value;
}
export function extractColsFromText(text) {
  // rowData.columnName => columnName
  const regex = /\[rowData\.(.*?)\]/g;
  let m;
  const cols = [];
  while ((m = regex.exec(text)) !== null) {
    cols.push(m[1]);
  }
  return cols;
}
export function replaceAll(text, toReplace, replaceWith) {
  if (!text) {
    return text;
  }
  // fails for []
  toReplace = toReplace.replace('[', '\\[').replace(']', '\\]');
  return text.replace(new RegExp(toReplace, 'g'), replaceWith);
}
export function extractContextKeysFromText(text) {
  // context.columnName => columnName
  const regex = /\[context\.(.*?)\]/g;
  let m;
  const contextKeys = [];
  while ((m = regex.exec(text)) !== null) {
    contextKeys.push(m[1]);
  }
  return contextKeys;
}
export const Helper = {
  objectExists,
  objectNotExists,
  objectHasKeys,
  getStringRepresentionFromKey,
  cloneObject,
  convertArrayToCsv,
  createDownloadedFile,
  copyToClipboard,
  returnItemCount,
  isInputNullOrEmpty,
  isInputNotNullOrEmpty,
  roundNumber,
  roundNumberTo4dp,
  clamp,
  extractColsFromText,
  replaceAll,
  extractContextKeysFromText
};
export default Helper;