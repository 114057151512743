import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useAdaptable } from '../AdaptableContext';
import { StatusBarPanel } from '../StatusBar/StatusBarPanel';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import * as GridRedux from '../../Redux/ActionsReducers/GridRedux';
import { CellSummaryStatusBarSubPanelPopover } from './CellSummaryStatusBarSubPanelPopover';
export const CellSummaryStatusPanel = () => {
  var _a;
  const adaptable = useAdaptable();
  const dispatch = useDispatch();
  const cellSummaryApi = adaptable.api.cellSummaryApi;
  const module = adaptable.ModuleService.getModuleById(ModuleConstants.CellSummaryModuleId);
  const text = `${cellSummaryApi.getCurrentCellSummaryOperation()}: ${(_a = cellSummaryApi.getCurrentCellSummaryOperationValue()) !== null && _a !== void 0 ? _a : 'N/A'}`;
  const onAction = () => {
    adaptable.api.cellSummaryApi.openCellSummaryPopupSettingsPanel();
  };
  React.useEffect(() => {
    let cleanupEvent = () => null;
    const checkSelectedCells = () => {
      dispatch(GridRedux.GridCreateCellSummary());
    };
    if (adaptable) {
      cleanupEvent = adaptable._on('CellsSelected', () => {
        checkSelectedCells();
      });
    }
    checkSelectedCells();
    return () => cleanupEvent === null || cleanupEvent === void 0 ? void 0 : cleanupEvent();
  }, []);
  return React.createElement(StatusBarPanel, {
    tooltip: module.moduleInfo.ModuleName,
    "data-name": module.moduleInfo.ModuleName,
    icon: module.moduleInfo.Glyph,
    onAction: onAction,
    content: text,
    popover: CellSummaryStatusBarSubPanelPopover,
    popoverMinWidth: 360
  });
};