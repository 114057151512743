import * as React from 'react';
import Radio from '../../components/Radio';
import { useAdaptable } from '../AdaptableContext';
export const LayoutRadioSelector = ({
  data
}) => {
  const adaptable = useAdaptable();
  const currentLayout = adaptable.api.layoutApi.getCurrentLayout();
  const layouts = adaptable.api.layoutApi.getLayouts();
  const isDisabled = (layouts === null || layouts === void 0 ? void 0 : layouts.length) === 1;
  const isSelected = currentLayout.Name === data.Name;
  const handleToggle = React.useCallback(() => {
    adaptable.api.layoutApi.setLayout(data.Name);
  }, [isSelected]);
  return React.createElement(Radio, {
    "data-name": "layout-select-button",
    "data-value": data.Name,
    disabled: isDisabled,
    checked: isSelected,
    onClick: handleToggle
  });
};