import * as React from 'react';
import { Box } from 'rebass';
import { useAdaptable } from '../../../AdaptableContext';
import { OnePageAdaptableWizard } from '../../../Wizard/OnePageAdaptableWizard';
import { isSettingsValid, SettingsSection } from './SettingsSection';
const PreviewChartSection = ({
  chartDefinition
}) => {
  const adaptable = useAdaptable();
  const divRef = React.useRef(null);
  React.useEffect(() => {
    if (!divRef.current) {
      return;
    }
    const container = {
      name: 'Preview Chart',
      element: divRef.current
    };
    const transientChartDefinition = adaptable.api.chartingApi.internalApi.onPreviewExternalChart(chartDefinition, container);
    return () => {
      adaptable.api.chartingApi.internalApi.onHideExternalChart(transientChartDefinition);
    };
  }, [divRef]);
  return React.createElement("div", {
    ref: divRef
  });
};
export const ExternalChartingWizard = props => {
  var _a;
  const adaptable = useAdaptable();
  const onPreviewChart = (_a = adaptable.api.optionsApi.getChartingOptions().externalChartingOptions) === null || _a === void 0 ? void 0 : _a.onPreviewChart;
  const [chartDefinition, setChartDefinition] = React.useState(() => {
    var _a, _b;
    return (_a = props.data) !== null && _a !== void 0 ? _a : (_b = props.popupParams) === null || _b === void 0 ? void 0 : _b.value;
  });
  const handleFinish = () => {
    adaptable.api.chartingApi.editExternalChartDefinition(chartDefinition);
    props.onFinishWizard(props.data);
  };
  return React.createElement(OnePageAdaptableWizard, {
    defaultCurrentSectionName: props.defaultCurrentSectionName,
    moduleInfo: props.moduleInfo,
    data: chartDefinition,
    onHide: props.onCloseWizard,
    onFinish: handleFinish,
    sections: [{
      title: 'Settings',
      details: 'Chart Settings',
      isValid: isSettingsValid,
      render: () => React.createElement(Box, {
        padding: 2
      }, React.createElement(SettingsSection, {
        chartDefinition: chartDefinition,
        onChange: setChartDefinition
      }))
    }, onPreviewChart ? {
      title: 'Preview Chart',
      render: () => {
        return React.createElement(Box, {
          padding: 2
        }, React.createElement(PreviewChartSection, {
          chartDefinition: chartDefinition
        }));
      }
    } : null].filter(Boolean)
  });
};