import * as React from 'react';
import { Box, Flex } from 'rebass';
import { Tag } from '../../../../../components/Tag';
import { DEFAULT_INTEGER_DISPLAY_VALUE, DEFAULT_STRING_DISPLAY_VALUE } from '../../../../../Utilities/Constants/GeneralConstants';
import { useAdaptable } from '../../../../AdaptableContext';
import { Badge } from '../../../../Components/Badge';
export const StyledColumnBadgePreview = ({
  data
}) => {
  const adaptable = useAdaptable();
  const badgeStyle = data.BadgeStyle;
  if (!badgeStyle || badgeStyle.Badges.length === 0) {
    return React.createElement("div", null, "No Badges Defined");
  }
  const value = adaptable.api.columnApi.getColumnDataTypeForColumnId(data.ColumnId) === 'Number' ? DEFAULT_INTEGER_DISPLAY_VALUE : DEFAULT_STRING_DISPLAY_VALUE;
  return React.createElement(Flex, {
    flexDirection: "column"
  }, badgeStyle.Badges.map((badge, index) => {
    const predicateStr = badge.Predicate ? adaptable.api.predicateApi.predicateToString(badge.Predicate) : 'No Predicate';
    return React.createElement(Box, {
      mb: 1,
      key: index
    }, React.createElement(Badge, {
      icon: badge.Icon,
      adaptableStyle: badge.Style,
      iconPosition: badge.IconPosition
    }, value), React.createElement(Tag, {
      ml: 2
    }, predicateStr));
  }));
};