import startCase from 'lodash/startCase';
export function IsNull(stringToCheck) {
  return stringToCheck == null || stringToCheck == undefined;
}
export function IsNotNull(stringToCheck) {
  return !IsNull(stringToCheck);
}
export function IsEmpty(stringToCheck) {
  return stringToCheck == '';
}
export function IsNotEmpty(stringToCheck) {
  return !IsEmpty(stringToCheck);
}
export function IsNullOrEmpty(stringToCheck) {
  return IsNull(stringToCheck) || IsEmpty(stringToCheck);
}
export function IsNotNullOrEmpty(stringToCheck) {
  return !IsNullOrEmpty(stringToCheck);
}
export function IsNullOrEmptyOrWhiteSpace(stringToCheck) {
  return IsNullOrEmpty(stringToCheck) || IsEmpty(stringToCheck.trim());
}
export function IsNotNullOrEmptyOrWhiteSpace(stringToCheck) {
  return !IsNullOrEmptyOrWhiteSpace(stringToCheck);
}
// from https://stackoverflow.com/questions/15369566/putting-space-in-camel-case-string-using-regular-expression
export function CamelCaseToHumanText(camelCase) {
  if (!camelCase || camelCase == null) {
    return null;
  }
  const rex = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;
  const words = camelCase.replace(rex, '$1$4 $2$3$5').replace('.', ' ').split(' ');
  return words.map(word => word.substring(0, 1).toUpperCase() + (word.length > 1 ? word.substring(1, word.length) : '')).join(' ');
}
export function RemoveTrailingComma(stringToCheck) {
  return stringToCheck.replace(/,\s*$/, '');
}
export function ToLowerCase(stringToCheck) {
  return IsNullOrEmpty(stringToCheck) ? stringToCheck : stringToCheck.toLowerCase();
}
export function Includes(stringToCheck, valueToCheck) {
  return stringToCheck.includes(valueToCheck);
}
export function NotIncludes(stringToCheck, valueToCheck) {
  return !Includes(stringToCheck, valueToCheck);
}
export function AbbreviateString(stringToAbbreviate, maxLength) {
  return stringToAbbreviate.length < maxLength ? stringToAbbreviate : stringToAbbreviate.substr(0, maxLength) + '...';
}
export function CapitaliseFirstLetter(str) {
  return `${str.charAt(0).toUpperCase()}${str.substring(1)}`;
}
export function Humanize(str) {
  return startCase(str);
}
export function ReplaceEmptySpacesWithUnderscore(str = '') {
  return str.replace(/ /g, '_');
}
export function IsNumeric(str) {
  if (typeof str !== 'string') {
    return false;
  }
  return !isNaN(parseFloat(str));
}
export function UnescapeHtmlEntities(str) {
  if (!str || typeof str !== 'string') {
    return str;
  }
  let preparedStr = str;
  const map = [{
    target: /&apos;|&#39;/gi,
    replacement: "'"
  }, {
    target: /&quot;|&#34;/gi,
    replacement: '"'
  }, {
    target: /&amp;|&#38;/gi,
    replacement: '&'
  }, {
    target: /&lt;|&#60;/gi,
    replacement: '<'
  }, {
    target: /&gt;|&#62;/gi,
    replacement: '>'
  }, {
    target: /&nbsp;|&#160;/gi,
    replacement: ' '
  }];
  for (const transform of map) {
    preparedStr = preparedStr.replace(transform.target, transform.replacement);
  }
  return preparedStr;
}
export const StringExtensions = {
  IsNull,
  IsNotNull,
  IsEmpty,
  IsNotEmpty,
  IsNullOrEmpty,
  IsNotNullOrEmpty,
  IsNullOrEmptyOrWhiteSpace,
  IsNotNullOrEmptyOrWhiteSpace,
  CamelCaseToHumanText,
  RemoveTrailingComma,
  ToLowerCase,
  Includes,
  NotIncludes,
  AbbreviateString,
  CapitaliseFirstLetter,
  Humanize,
  ReplaceEmptySpacesWithUnderscore,
  IsNumeric,
  UnescapeHtmlEntities
};
export default StringExtensions;