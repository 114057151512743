import * as React from 'react';
import SimpleButton from '../../../components/SimpleButton';
export class ButtonConfigure extends React.Component {
  render() {
    return React.createElement(SimpleButton, Object.assign({
      "data-name": "configure",
      tooltip: "Configure",
      iconSize: 18,
      icon: "spanner"
    }, this.props, {
      variant: "text"
    }));
  }
}