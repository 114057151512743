import * as React from 'react';
import SimpleButton from '../../components/SimpleButton';
import { useAdaptable } from '../AdaptableContext';
export const TeamSharingApplyButton = props => {
  const adaptable = useAdaptable();
  const handleImport = React.useCallback(() => {
    adaptable.api.teamSharingApi.importSharedEntry(props.data);
  }, []);
  return React.createElement(SimpleButton, {
    "data-name": "shared-entity-apply-button",
    onClick: handleImport,
    variant: "text",
    tooltip: "Import",
    icon: "import-export"
  });
};