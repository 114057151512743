import * as React from 'react';
import Dialog from '../../../components/Dialog';
import { Box } from 'rebass';
export function AdaptableLoadingScreen(props) {
  const {
    showLoadingScreen,
    loadingScreenDelay,
    loadingScreenTitle,
    loadingScreenText
  } = props;
  const [delayElapsed, setDelayElapsed] = React.useState(typeof loadingScreenDelay === 'number' ? false : showLoadingScreen);
  React.useEffect(() => {
    if (typeof loadingScreenDelay === 'number') {
      const timeoutId = setTimeout(() => {
        setDelayElapsed(true);
      }, loadingScreenDelay);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [loadingScreenDelay]);
  return React.createElement(Dialog, {
    modal: true,
    isOpen: delayElapsed ? showLoadingScreen : false,
    onDismiss: props.onClose,
    showCloseButton: false,
    className: "ab-LoadingScreen",
    style: {
      minHeight: 'auto'
    }
  }, React.createElement(Box, {
    padding: 3
  }, React.createElement("h4", null, loadingScreenTitle !== null && loadingScreenTitle !== void 0 ? loadingScreenTitle : 'Initialising Grid'), React.createElement("p", null, loadingScreenText !== null && loadingScreenText !== void 0 ? loadingScreenText : 'Retrieving your settings and setting up the grid...')));
}