import * as React from 'react';
import { useState } from 'react';
import { Box } from 'rebass';
import { useDispatch } from 'react-redux';
import { OnePageAdaptableWizard, OnePageWizardSummary } from '../../Wizard/OnePageAdaptableWizard';
import ObjectFactory from '../../../Utilities/ObjectFactory';
import * as StyledColumnRedux from '../../../Redux/ActionsReducers/StyledColumnRedux';
import { renderStyledColumnTypeSummary, StyledColumnWizardTypeSection } from './StyledColumnWizardTypeSection';
import { isValidStyledColumnColumn, renderStyledColumnColumnSummary, StyledColumnWizardColumnSection } from './StyledColumnWizardColumnSection';
import { renderStyledColumnStyleSummary, StyledColumnWizardStyleSection } from './StyledColumnWizardStyleSection';
import { cloneObject } from '../../../Utilities/Helpers/Helper';
import { renderStyledColumnWizardSettingsSummary, StyledColumnWizardSettingsSection } from './StyledColumnWizardSettingsSection';
import { StyledColumnSparklineSettingsSection } from './StyledColumnSparklineSettingsSection';
import { ObjectTagsWizardSection, renderObjectTagsSummary } from '../../Wizard/ObjectTagsWizardSection';
import { renderBadgeSummary, StyledColumnBadgeSection } from './StyledColumnBadgeSection';
export const StyledColumnWizard = props => {
  var _a, _b, _c, _d, _e, _f;
  const data = (_a = props.data) !== null && _a !== void 0 ? _a : (_b = props.popupParams) === null || _b === void 0 ? void 0 : _b.value;
  const popupDefaultCurrentSectionName = (_e = (_d = (_c = props === null || props === void 0 ? void 0 : props.popupParams) === null || _c === void 0 ? void 0 : _c.config) === null || _d === void 0 ? void 0 : _d.defaultCurrentSectionName) !== null && _e !== void 0 ? _e : undefined;
  const defaultCurrentSectionName = (_f = props.defaultCurrentSectionName) !== null && _f !== void 0 ? _f : popupDefaultCurrentSectionName;
  const [styledColumn, setStyledColumn] = useState(() => {
    var _a, _b;
    if (data) {
      return cloneObject(data);
    }
    const newData = ObjectFactory.CreateEmptyStyledColumn();
    newData.GradientStyle = {};
    if (((_a = props.popupParams) === null || _a === void 0 ? void 0 : _a.column) && ((_b = props.popupParams) === null || _b === void 0 ? void 0 : _b.action) === 'New') {
      newData.ColumnId = props.popupParams.column.columnId;
      if (props.popupParams.column.dataType === 'String') {
        newData.BadgeStyle = {
          Badges: [ObjectFactory.CreateDefaultStyledColumnBadge()]
        };
        delete newData.GradientStyle;
      } else if (props.popupParams.column.dataType.includes('Array')) {
        newData.SparkLineStyle = {};
        delete newData.GradientStyle;
      }
    }
    return newData;
  });
  const dispatch = useDispatch();
  const handleFinish = () => {
    var _a;
    if (((_a = props === null || props === void 0 ? void 0 : props.popupParams) === null || _a === void 0 ? void 0 : _a.action) === 'New' || !data) {
      dispatch(StyledColumnRedux.StyledColumnAdd(styledColumn));
    } else {
      dispatch(StyledColumnRedux.StyledColumnEdit(styledColumn));
    }
    props.onFinishWizard(styledColumn);
  };
  const AdditionalSettingSection = {
    details: 'Apply Additional Settings',
    renderSummary: renderStyledColumnWizardSettingsSummary,
    render: () => {
      return React.createElement(Box, {
        padding: 2
      }, React.createElement(StyledColumnWizardSettingsSection, {
        onChange: setStyledColumn
      }));
    },
    title: 'Settings'
  };
  const specificSteps = [];
  if (styledColumn.SparkLineStyle) {
    specificSteps.push({
      title: 'Settings',
      render: () => React.createElement(Box, {
        p: 2
      }, React.createElement(StyledColumnSparklineSettingsSection, {
        onChange: setStyledColumn
      }))
    });
  } else if (styledColumn.GradientStyle || styledColumn.PercentBarStyle) {
    specificSteps.push({
      details: 'Create the Style to apply',
      renderSummary: renderStyledColumnStyleSummary,
      render: () => {
        return React.createElement(Box, {
          padding: 2
        }, React.createElement(StyledColumnWizardStyleSection, {
          onChange: setStyledColumn
        }));
      },
      title: 'Style',
      isValid: () => {
        var _a, _b, _c;
        const columnComparison = (_b = (_a = styledColumn.GradientStyle) === null || _a === void 0 ? void 0 : _a.ColumnComparison) !== null && _b !== void 0 ? _b : (_c = styledColumn.PercentBarStyle) === null || _c === void 0 ? void 0 : _c.ColumnComparison;
        return columnComparison ? columnComparison.MaxValue == undefined && columnComparison.MinValue == undefined ? 'Define Min and Max Values for the Column Comparison' : columnComparison.MaxValue == undefined ? 'Define a Max Value for the Column Comparison' : columnComparison.MinValue == undefined ? 'Define a Min Value for the Column Comparison' : true : true;
      }
    }
    // Only available for Badge
    // AdditionalSettingSection
    );
  } else if (styledColumn.BadgeStyle) {
    specificSteps.push({
      title: 'Badges',
      details: 'Create Badge Styles',
      isValid: () => {
        var _a;
        if (((_a = styledColumn.BadgeStyle.Badges) === null || _a === void 0 ? void 0 : _a.length) === 0) {
          return 'Define at least one Badge';
        }
        if (styledColumn.BadgeStyle.Badges.filter(badge => !badge.Predicate).length > 1) {
          return 'Only one Badge can have no Predicate';
        }
        return true;
      },
      renderSummary: () => renderBadgeSummary(styledColumn),
      render: () => React.createElement(Box, {
        p: 2
      }, React.createElement(StyledColumnBadgeSection, {
        onChange: setStyledColumn
      }))
    });
    specificSteps.push(AdditionalSettingSection);
  }
  return React.createElement(OnePageAdaptableWizard, {
    defaultCurrentSectionName: defaultCurrentSectionName,
    moduleInfo: props.moduleInfo,
    data: styledColumn,
    onFinish: handleFinish,
    onHide: props.onCloseWizard,
    sections: [{
      details: 'Select a Styled Column type',
      renderSummary: renderStyledColumnTypeSummary,
      render: () => {
        return React.createElement(StyledColumnWizardTypeSection, {
          onChange: setStyledColumn
        });
      },
      title: 'Type'
    }, {
      isValid: isValidStyledColumnColumn,
      renderSummary: renderStyledColumnColumnSummary,
      details: 'Select the Column where the Style will be applied',
      render: () => {
        return React.createElement(Box, {
          padding: 2,
          style: {
            height: '100%'
          }
        }, React.createElement(StyledColumnWizardColumnSection, {
          isNew: props.isNew,
          onChange: setStyledColumn
        }));
      },
      title: 'Column'
    }, ...specificSteps, {
      details: 'Select Format Column Tags',
      title: 'Tags',
      isVisible: (_, api) => api.internalApi.shouldDisplayTagSections(),
      render: () => React.createElement(Box, {
        padding: 2
      }, React.createElement(ObjectTagsWizardSection, {
        onChange: setStyledColumn
      })),
      renderSummary: renderObjectTagsSummary
    }, '-', {
      title: 'Summary',
      details: 'Review your Styled Column',
      render: () => {
        return React.createElement(Box, {
          padding: 2
        }, React.createElement(OnePageWizardSummary, null));
      }
    }]
  });
};