import * as React from 'react';
import { Flex } from 'rebass';
import join from '../utils/join';
const defaulProps = {
  flexDirection: 'row',
  alignItems: 'center'
};
const FieldWrap = props => {
  const flexProps = Object.assign(Object.assign({}, defaulProps), props);
  return React.createElement(Flex, Object.assign({}, flexProps, {
    className: join(props.className, 'ab-FieldWrap')
  }));
};
export default FieldWrap;