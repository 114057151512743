export const getFilterPreview = (columnFilter, api) => {
  var _a, _b, _c;
  const columnIdToFriendlyName = columnId => {
    return api.columnApi.getFriendlyNameForColumnId(columnId);
  };
  return `[${columnIdToFriendlyName(columnFilter.ColumnId)}: ${(_a = columnFilter === null || columnFilter === void 0 ? void 0 : columnFilter.Predicate) === null || _a === void 0 ? void 0 : _a.PredicateId}${((_c = (_b = columnFilter.Predicate) === null || _b === void 0 ? void 0 : _b.Inputs) !== null && _c !== void 0 ? _c : []).map(input => {
    if (typeof input === 'string') {
      return input;
    }
    if (typeof (input === null || input === void 0 ? void 0 : input.toString) === 'function') {
      return input.toString();
    }
    return '';
  }).filter(Boolean).join(', ')}]`;
};
export const getLayoutFilterViewItems = (layout, api) => {
  var _a;
  return {
    name: 'Column Filters',
    values: ((_a = layout.ColumnFilters) !== null && _a !== void 0 ? _a : []).map(columnFilter => getFilterPreview(columnFilter, api))
  };
};