import { EMPTY_ARRAY, EMPTY_STRING } from '../../Utilities/Constants/GeneralConstants';
import AdaptableHelper from '../../Utilities/Helpers/AdaptableHelper';
/**
 * @ReduxAction A new caption / header has been set for a Column in the Layout
 */
export const LAYOUT_COLUMN_SET_CAPTION = 'LAYOUT_COLUMN_SET_CAPTION';
/**
 * @ReduxAction Layout Module is ready
 */
export const LAYOUT_READY = 'LAYOUT_READY';
/**
 * @ReduxAction A Layout has been added
 */
export const LAYOUT_ADD = 'LAYOUT_ADD';
/**
 * @ReduxAction A Layout has been edited
 */
export const LAYOUT_EDIT = 'LAYOUT_EDIT';
/**
 * @ReduxAction A Layout has been deleted
 */
export const LAYOUT_DELETE = 'LAYOUT_DELETE';
/**
 * @ReduxAction A Layout has been selected
 */
export const LAYOUT_SELECT = 'LAYOUT_SELECT';
/**
 * @ReduxAction A Layout has been (auto)saved
 */
export const LAYOUT_SAVE = 'LAYOUT_SAVE';
/**
 * @ReduxAction A Column has been added
 */
export const LAYOUT_COLUMN_ADD = 'LAYOUT_COLUMN_ADD';
/**
 * @ReduxAction A Column has been removed
 */
export const LAYOUT_COLUMN_REMOVE = 'LAYOUT_COLUMN_REMOVE';
/**
 * @ReduxAction A Column Filter has been added
 */
export const LAYOUT_COLUMN_FILTER_ADD = 'LAYOUT_COLUMN_FILTER_ADD';
/**
 * @ReduxAction A Column Filter has been edited
 */
export const LAYOUT_COLUMN_FILTER_EDIT = 'LAYOUT_COLUMN_FILTER_EDIT';
/**
 * @ReduxAction Column Filters have been set
 */
export const LAYOUT_COLUMN_FILTER_SET = 'LAYOUT_COLUMN_FILTER_SET';
/**
 * @ReduxAction All Column Filters have been cleared
 */
export const LAYOUT_COLUMN_FILTER_CLEAR_ALL = 'LAYOUT_COLUMN_FILTER_CLEAR_ALL';
/**
 * @ReduxAction A Column Filter has been cleared
 */
export const LAYOUT_COLUMN_FILTER_CLEAR = 'LAYOUT_COLUMN_FILTER_CLEAR';
/**
 * @ReduxAction Column Filter is suspended
 */
export const LAYOUT_COLUMN_FILTER_SUSPEND = 'LAYOUT_COLUMN_FILTER_SUSPEND';
/**
 * @ReduxAction Column Filter is un-suspended, or activated
 */
export const LAYOUT_COLUMN_FILTER_UNSUSPEND = 'LAYOUT_COLUMN_FILTER_UNSUSPEND';
/**
 * @ReduxAction All Column Filters are suspended
 */
export const LAYOUT_COLUMN_FILTER_SUSPEND_ALL = 'LAYOUT_COLUMN_FILTER_SUSPEND_ALL';
/**
 * @ReduxAction All Column Filters are un-suspended, or activated
 */
export const LAYOUT_COLUMN_FILTER_UNSUSPEND_ALL = 'LAYOUT_COLUMN_FILTER_UNSUSPEND_ALL';
/**
 * @ReduxAction Grid Filter is un-suspended, or activated
 */
export const LAYOUT_GRID_FILTER_SUSPEND = 'LAYOUT_GRID_FILTER_SUSPEND';
/**
 * @ReduxAction Grid Filter is un-suspended, or activated
 */
export const LAYOUT_GRID_FILTER_UNSUSPEND = 'LAYOUT_GRID_FILTER_UNSUSPEND';
/**
 * @ReduxAction Edit Grid filter
 */
export const LAYOUT_GRID_FILTER_SET = 'LAYOUT_GRID_FILTER_SET';
/**
 * @ReduxAction The Grid Filter has been cleared
 */
export const LAYOUT_GRID_FILTER_CLEAR = 'LAYOUT_GRID_FILTER_CLEAR';
// Column Filter Methods
export const LayoutColumnFilterAdd = columnFilter => ({
  type: LAYOUT_COLUMN_FILTER_ADD,
  columnFilter
});
export const LayoutColumnFilterEdit = columnFilter => ({
  type: LAYOUT_COLUMN_FILTER_EDIT,
  columnFilter
});
export const LayoutColumnFilterSet = columnFilter => ({
  type: LAYOUT_COLUMN_FILTER_SET,
  columnFilter
});
export const LayoutColumnFilterClearAll = () => ({
  type: LAYOUT_COLUMN_FILTER_CLEAR_ALL
});
export const LayoutColumnFilterClear = columnFilter => ({
  type: LAYOUT_COLUMN_FILTER_CLEAR,
  columnFilter
});
export const LayoutColumnFilterSuspend = columnFilter => ({
  type: LAYOUT_COLUMN_FILTER_SUSPEND,
  columnFilter
});
export const LayoutColumnFilterUnSuspend = columnFilter => ({
  type: LAYOUT_COLUMN_FILTER_UNSUSPEND,
  columnFilter
});
export const LayoutColumnFilterSuspendAll = () => ({
  type: LAYOUT_COLUMN_FILTER_SUSPEND_ALL
});
export const LayoutColumnFilterUnSuspendAll = () => ({
  type: LAYOUT_COLUMN_FILTER_UNSUSPEND_ALL
});
export const LayoutGridFilterSet = gridFilter => ({
  type: LAYOUT_GRID_FILTER_SET,
  gridFilter
});
export const LayoutGridFilterSuspend = () => ({
  type: LAYOUT_GRID_FILTER_SUSPEND
});
export const LayoutGridFilterUnSuspend = () => ({
  type: LAYOUT_GRID_FILTER_UNSUSPEND
});
export const LayoutGridFilterClear = () => ({
  type: LAYOUT_GRID_FILTER_CLEAR
});
export const LayoutAdd = layout => ({
  type: LAYOUT_ADD,
  layout
});
export const LayoutDelete = layout => ({
  type: LAYOUT_DELETE,
  layout
});
export const LayoutSave = layout => ({
  type: LAYOUT_SAVE,
  layout
});
export const LayoutSetColumnCaption = (layoutName, columnId, caption) => ({
  type: LAYOUT_COLUMN_SET_CAPTION,
  layoutName,
  columnId,
  caption
});
export const LayoutAddColumn = (layoutName, columnId) => ({
  type: LAYOUT_COLUMN_ADD,
  layoutName,
  columnId
});
export const LayoutRemoveColumn = (layoutName, columnId) => ({
  type: LAYOUT_COLUMN_REMOVE,
  layoutName,
  columnId
});
export const LayoutSelect = layoutName => ({
  type: LAYOUT_SELECT,
  layoutName
});
export const LayoutReady = layoutState => ({
  type: LAYOUT_READY,
  layoutState
});
export const getColumnFilterSelector = state => {
  var _a, _b, _c, _d;
  // in case of non-saving Layouts, we load the current Layout(possibly draft) from GridState
  const currentLayout = state.Grid.CurrentLayout ? state.Grid.CurrentLayout : (_c = (_b = (_a = state.Layout) === null || _a === void 0 ? void 0 : _a.Layouts) === null || _b === void 0 ? void 0 : _b.find) === null || _c === void 0 ? void 0 : _c.call(_b, layout => layout.Name === state.Layout.CurrentLayout);
  return (_d = currentLayout === null || currentLayout === void 0 ? void 0 : currentLayout.ColumnFilters) !== null && _d !== void 0 ? _d : [];
};
const initialState = {
  CurrentLayout: EMPTY_STRING,
  Layouts: EMPTY_ARRAY
};
export const LayoutReducer = (state = initialState, action) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  let layouts;
  const currentLayout = state.Layouts.find(layout => layout.Name === state.CurrentLayout);
  switch (action.type) {
    case LAYOUT_SELECT:
      {
        const newCurrentLayout = action.layoutName;
        if ((state.Layouts || []).find(l => l.Name === newCurrentLayout)) {
          return Object.assign({}, state, {
            CurrentLayout: newCurrentLayout
          });
        }
        return state;
      }
    case LAYOUT_ADD:
      {
        const actionLayout = action.layout;
        AdaptableHelper.addUuidAndSource(actionLayout);
        layouts = [].concat(state.Layouts);
        layouts.push(actionLayout);
        return Object.assign(Object.assign({}, state), {
          Layouts: layouts
        });
      }
    case LAYOUT_SAVE:
      {
        const actionLayout = action.layout;
        return Object.assign(Object.assign({}, state), {
          Layouts: state.Layouts.map(abObject => abObject.Uuid === actionLayout.Uuid ? actionLayout : abObject)
        });
      }
    case LAYOUT_DELETE:
      {
        const actionLayout = action.layout;
        const newLayouts = state.Layouts.filter(abObject => abObject.Uuid !== actionLayout.Uuid);
        return Object.assign(Object.assign({}, state), {
          CurrentLayout: state.CurrentLayout === actionLayout.Name ? newLayouts[0].Name : state.CurrentLayout,
          Layouts: newLayouts
        });
      }
    case LAYOUT_COLUMN_SET_CAPTION:
      {
        const setColumnCaptionAction = action;
        const currentLayoutName = setColumnCaptionAction.layoutName;
        let currentLayout = state.Layouts.find(l => l.Name === currentLayoutName);
        if (currentLayout) {
          if (!currentLayout.ColumnHeadersMap) {
            currentLayout.ColumnHeadersMap = {};
          }
          currentLayout.ColumnHeadersMap[setColumnCaptionAction.columnId] = setColumnCaptionAction.caption;
          return Object.assign({}, state, {
            Layouts: state.Layouts.map(abObject => abObject.Uuid === currentLayout.Uuid ? currentLayout : abObject)
          });
        }
        return state;
      }
    case LAYOUT_COLUMN_ADD:
      {
        const addColumnAction = action;
        const layoutname = addColumnAction.layoutName;
        const colToAdd = addColumnAction.columnId;
        let layoutToUpdate = state.Layouts.find(l => l.Name === layoutname);
        if (layoutToUpdate) {
          layoutToUpdate.Columns.push(colToAdd);
          return Object.assign(Object.assign({}, state), {
            Layouts: state.Layouts.map(abObject => abObject.Uuid === layoutToUpdate.Uuid ? layoutToUpdate : abObject)
          });
        }
        return state;
      }
    case LAYOUT_COLUMN_REMOVE:
      {
        const removeColumnAction = action;
        const layoutname = removeColumnAction.layoutName;
        const colToRemove = removeColumnAction.columnId;
        let layoutToUpdate = state.Layouts.find(l => l.Name === layoutname);
        if (layoutToUpdate) {
          layoutToUpdate.Columns = layoutToUpdate.Columns.filter(col => {
            return col !== colToRemove;
          });
          return Object.assign(Object.assign({}, state), {
            Layouts: state.Layouts.map(abObject => abObject.Uuid === layoutToUpdate.Uuid ? layoutToUpdate : abObject)
          });
        }
        return state;
      }
    case LAYOUT_COLUMN_FILTER_SET:
      {
        let columnFilters = (_a = currentLayout.ColumnFilters) !== null && _a !== void 0 ? _a : [];
        const columnFilterAction = action.columnFilter;
        AdaptableHelper.addUuidAndSource(columnFilterAction);
        columnFilters = columnFilters.filter(colFilter => {
          return colFilter.ColumnId !== columnFilterAction.ColumnId;
        }).concat(columnFilterAction);
        const Layouts = state.Layouts.map(layout => {
          if (layout.Name === currentLayout.Name) {
            return Object.assign(Object.assign({}, layout), {
              ColumnFilters: columnFilters
            });
          }
          return layout;
        });
        return Object.assign(Object.assign({}, state), {
          Layouts
        });
      }
    case LAYOUT_COLUMN_FILTER_ADD:
      {
        const columnFilterAction = action.columnFilter;
        let columnFilters = ((_b = currentLayout.ColumnFilters) !== null && _b !== void 0 ? _b : []).filter(
        // make sure we are not adding a filter that already exists
        cf => cf.ColumnId !== columnFilterAction.ColumnId);
        AdaptableHelper.addUuidAndSource(columnFilterAction);
        columnFilters.push(columnFilterAction);
        const Layouts = state.Layouts.map(layout => {
          if (layout.Name === currentLayout.Name) {
            return Object.assign(Object.assign({}, layout), {
              ColumnFilters: columnFilters
            });
          }
          return layout;
        });
        return Object.assign(Object.assign({}, state), {
          Layouts
        });
      }
    case LAYOUT_COLUMN_FILTER_EDIT:
      {
        const columnFilterAction = action.columnFilter;
        // it is safer to change the col id, we cannot have multiple filter on the same id
        // first we filter out other filters with the same colId, to ensure we have only one
        const cleanColumnFilters = ((_c = currentLayout.ColumnFilters) !== null && _c !== void 0 ? _c : []).filter(cf => cf.ColumnId !== columnFilterAction.ColumnId);
        cleanColumnFilters.push(columnFilterAction);
        const Layouts = state.Layouts.map(layout => {
          if (layout.Name === currentLayout.Name) {
            return Object.assign(Object.assign({}, layout), {
              ColumnFilters: cleanColumnFilters
            });
          }
          return layout;
        });
        return Object.assign(Object.assign({}, state), {
          Layouts
        });
      }
    case LAYOUT_COLUMN_FILTER_CLEAR_ALL:
      {
        const Layouts = state.Layouts.map(layout => {
          if (layout.Name === currentLayout.Name) {
            return Object.assign(Object.assign({}, layout), {
              ColumnFilters: []
            });
          }
          return layout;
        });
        return Object.assign(Object.assign({}, state), {
          Layouts
        });
      }
    case LAYOUT_COLUMN_FILTER_CLEAR:
      {
        const actionTypedDelete = action;
        const columnFilters = ((_d = currentLayout.ColumnFilters) !== null && _d !== void 0 ? _d : []).filter(cf => {
          // make sure we are deleting all filters on the same column
          return cf.ColumnId !== actionTypedDelete.columnFilter.ColumnId;
        });
        const Layouts = state.Layouts.map(layout => {
          if (layout.Name === currentLayout.Name) {
            return Object.assign(Object.assign({}, layout), {
              ColumnFilters: columnFilters
            });
          }
          return layout;
        });
        return Object.assign(Object.assign({}, state), {
          Layouts
        });
      }
    case LAYOUT_COLUMN_FILTER_SUSPEND:
      {
        const actionTypedSuspend = action;
        const columnFilters = ((_e = currentLayout.ColumnFilters) !== null && _e !== void 0 ? _e : []).map(abObject => abObject.Uuid === actionTypedSuspend.columnFilter.Uuid ? Object.assign(Object.assign({}, abObject), {
          IsSuspended: true
        }) : abObject);
        const Layouts = state.Layouts.map(layout => {
          if (layout.Name === currentLayout.Name) {
            return Object.assign(Object.assign({}, layout), {
              ColumnFilters: columnFilters
            });
          }
          return layout;
        });
        return Object.assign(Object.assign({}, state), {
          Layouts
        });
      }
    case LAYOUT_COLUMN_FILTER_UNSUSPEND:
      {
        const actionTypedSuspend = action;
        const columnFilters = ((_f = currentLayout.ColumnFilters) !== null && _f !== void 0 ? _f : []).map(abObject => abObject.Uuid === actionTypedSuspend.columnFilter.Uuid ? Object.assign(Object.assign({}, abObject), {
          IsSuspended: false
        }) : abObject);
        const Layouts = state.Layouts.map(layout => {
          if (layout.Name === currentLayout.Name) {
            return Object.assign(Object.assign({}, layout), {
              ColumnFilters: columnFilters
            });
          }
          return layout;
        });
        return Object.assign(Object.assign({}, state), {
          Layouts
        });
      }
    case LAYOUT_COLUMN_FILTER_SUSPEND_ALL:
      {
        const columnFilters = ((_g = currentLayout.ColumnFilters) !== null && _g !== void 0 ? _g : []).map(abObject => Object.assign(Object.assign({}, abObject), {
          IsSuspended: true
        }));
        const Layouts = state.Layouts.map(layout => {
          if (layout.Name === currentLayout.Name) {
            return Object.assign(Object.assign({}, layout), {
              ColumnFilters: columnFilters
            });
          }
          return layout;
        });
        return Object.assign(Object.assign({}, state), {
          Layouts
        });
      }
    case LAYOUT_COLUMN_FILTER_UNSUSPEND_ALL:
      {
        const columnFilters = ((_h = currentLayout.ColumnFilters) !== null && _h !== void 0 ? _h : []).map(abObject => Object.assign(Object.assign({}, abObject), {
          IsSuspended: false
        }));
        const Layouts = state.Layouts.map(layout => {
          if (layout.Name === currentLayout.Name) {
            return Object.assign(Object.assign({}, layout), {
              ColumnFilters: columnFilters
            });
          }
          return layout;
        });
        return Object.assign(Object.assign({}, state), {
          Layouts
        });
      }
    case LAYOUT_GRID_FILTER_SET:
      {
        const actionTyped = action;
        return Object.assign(Object.assign({}, state), {
          Layouts: state.Layouts.map(layout => {
            if (layout.Name === currentLayout.Name) {
              return Object.assign(Object.assign({}, layout), {
                GridFilter: Object.assign(Object.assign({}, layout.GridFilter), {
                  Expression: actionTyped.gridFilter
                })
              });
            }
            return layout;
          })
        });
      }
    case LAYOUT_GRID_FILTER_SUSPEND:
      {
        const Layouts = state.Layouts.map(layout => {
          if (layout.Name === currentLayout.Name) {
            return Object.assign(Object.assign({}, layout), {
              GridFilter: Object.assign(Object.assign({}, layout.GridFilter), {
                IsSuspended: true
              })
            });
          }
          return layout;
        });
        return Object.assign(Object.assign({}, state), {
          Layouts
        });
      }
    case LAYOUT_GRID_FILTER_UNSUSPEND:
      {
        const Layouts = state.Layouts.map(layout => {
          if (layout.Name === currentLayout.Name) {
            return Object.assign(Object.assign({}, layout), {
              GridFilter: Object.assign(Object.assign({}, layout.GridFilter), {
                IsSuspended: false
              })
            });
          }
          return layout;
        });
        return Object.assign(Object.assign({}, state), {
          Layouts
        });
      }
    case LAYOUT_GRID_FILTER_CLEAR:
      {
        const Layouts = state.Layouts.map(layout => {
          let gridFilter = currentLayout.GridFilter;
          if (gridFilter) {
            gridFilter.IsSuspended = false;
            gridFilter.Expression = '';
          }
          if (layout.Name === currentLayout.Name) {
            return Object.assign(Object.assign({}, layout), {
              gridFilter
            });
          }
          return layout;
        });
        return Object.assign(Object.assign({}, state), {
          Layouts
        });
      }
    default:
      return state;
  }
};