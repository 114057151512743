import { __rest } from "tslib";
import * as React from 'react';
import { ValueSelector } from '../ValueSelector';
import { useAdaptable } from '../../AdaptableContext';
import { useMemo, useCallback } from 'react';
import ArrayExtensions from '../../../Utilities/Extensions/ArrayExtensions';
export const ModuleValueSelector = props => {
  const {
      options,
      value,
      onChange,
      selectionBoxPosition = 'top'
    } = props,
    valueSelectorProps = __rest(props, ["options", "value", "onChange", "selectionBoxPosition"]);
  const {
    api
  } = useAdaptable();
  const selectedModulesMap = useMemo(() => {
    if (!value || !value.length) {
      return new Map();
    }
    return value.reduce((acc, val) => {
      acc.set(val, true);
      return acc;
    }, new Map());
  }, [value]);
  const allowReorder = useCallback(option => {
    return selectedModulesMap.has(option);
  }, [selectedModulesMap]);
  const moduleLabelMap = useMemo(() => {
    const labelMap = new Map();
    options.forEach(module => {
      var _a;
      return labelMap.set(module, (_a = api.internalApi.getModuleFriendlyName(module)) !== null && _a !== void 0 ? _a : module);
    });
    return labelMap;
  }, [options]);
  const sortedOptions = useMemo(() => {
    return ArrayExtensions.sortArrayWithOrder(options, value, {
      sortUnorderedItems: true
    });
  }, [allowReorder, options, value]);
  return React.createElement(ValueSelector, Object.assign({}, valueSelectorProps, {
    options: sortedOptions,
    value: value,
    onChange: onChange,
    allowReorder: allowReorder,
    toIdentifier: module => module,
    toLabel: module => moduleLabelMap.get(module),
    selectionBoxPosition: selectionBoxPosition,
    isOptionDisabled: props.isOptionDisabled,
    disabled: props.disabled
  }));
};