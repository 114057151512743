import { AdaptableModuleBase } from './AdaptableModuleBase';
import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
import { QuickSearchStatusBarContent } from '../View/QuickSearch/QuickSearchStatusBarContent';
export class QuickSearchModule extends AdaptableModuleBase {
  constructor(api) {
    super(ModuleConstants.QuickSearchModuleId, ModuleConstants.QuickSearchFriendlyName, 'search-table', 'QuickSearchPopup', 'Quickly highlight all cells in the grid that contain matching query text', api);
  }
  getViewProperties() {
    return {
      getStatusBarPanelProps() {
        return {
          content: QuickSearchStatusBarContent
        };
      }
    };
  }
}