import { AdaptableModuleBase } from './AdaptableModuleBase';
import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
import * as CalculatedColumnRedux from '../Redux/ActionsReducers/CalculatedColumnRedux';
import { getExpressionViewItems } from './Utilities/getExpressionViewItems';
import { CalculatedColumnWizard } from '../View/CalculatedColumn/Wizard/CalculatedColumnWizard';
import ArrayExtensions from '../Utilities/Extensions/ArrayExtensions';
import { getObjectTagsViewItems } from './Utilities/getObjectTagsViewItems';
import { getCalculatedColumnSettingsTags } from '../View/CalculatedColumn/Utilities/getCalculatedColumnSettingsTags';
import Helper from '../Utilities/Helpers/Helper';
export class CalculatedColumnModule extends AdaptableModuleBase {
  constructor(api) {
    super(ModuleConstants.CalculatedColumnModuleId, ModuleConstants.CalculatedColumnFriendlyName, 'calculated-column', 'CalculatedColumnPopup', 'Create bespoke columns whose cell value is derived dynamically from an Expression', api);
  }
  onAdaptableReady() {
    this.api.calculatedColumnApi.refreshAggregatedCalculatedColumns();
  }
  onAdaptableStateReloaded() {
    this.api.calculatedColumnApi.refreshAggregatedCalculatedColumns();
  }
  getModuleAdaptableObjects() {
    return this.api.calculatedColumnApi.getCalculatedColumns();
  }
  getExplicitlyReferencedColumnIds(calculatedColumn) {
    var _a;
    return (_a = this.api.expressionApi.getColumnsFromExpression(this.api.expressionApi.getAdaptableQueryExpression(calculatedColumn.Query))) !== null && _a !== void 0 ? _a : [];
  }
  getReferencedNamedQueryNames(calculatedColumn) {
    var _a;
    return (_a = this.api.namedQueryApi.internalApi.getReferencedNamedQueryNames(this.api.expressionApi.getAdaptableQueryExpression(calculatedColumn.Query))) !== null && _a !== void 0 ? _a : [];
  }
  isCalculatedColumn(column) {
    return column && Helper.objectExists(this.api.calculatedColumnApi.getCalculatedColumns().find(cc => cc.ColumnId == column.columnId));
  }
  createColumnMenuItems(column) {
    if (this.isModuleEditable() && this.isCalculatedColumn(column)) {
      const popupParam = {
        column: column,
        action: 'Edit',
        source: 'ColumnMenu'
      };
      return [this.createMenuItemShowPopup('calculated-column-edit', 'Edit Calculated Column', this.moduleInfo.Popup, this.moduleInfo.Glyph, popupParam)];
    }
  }
  createContextMenuItems(menuContext) {
    if (!this.isModuleAvailable()) {
      return;
    }
    if (this.isModuleEditable() && this.isCalculatedColumn(menuContext.adaptableColumn) && !menuContext.isRowGroupColumn && menuContext.isSingleSelectedCell) {
      const popupParam = {
        column: menuContext.adaptableColumn,
        action: 'Edit',
        source: 'ContextMenu'
      };
      return [this.createMenuItemShowPopup('calculated-column-edit', 'Edit Calculated Column', this.moduleInfo.Popup, this.moduleInfo.Glyph, popupParam)];
    }
  }
  getTeamSharingAction() {
    return {
      ModuleEntities: this.api.calculatedColumnApi.getCalculatedColumns(),
      AddAction: CalculatedColumnRedux.CalculatedColumnAdd,
      EditAction: CalculatedColumnRedux.CalculatedColumnEdit
    };
  }
  toView(calculateColumn) {
    const settingsTags = getCalculatedColumnSettingsTags(calculateColumn.CalculatedColumnSettings);
    let items = [{
      name: 'Settings',
      label: 'Column Id',
      values: [calculateColumn.ColumnId]
    }, calculateColumn.FriendlyName && {
      name: 'Settings',
      label: 'Column Name',
      values: [calculateColumn.FriendlyName]
    }, getExpressionViewItems(calculateColumn.Query, this.api)].filter(Boolean);
    if (ArrayExtensions.IsNotNullOrEmpty(settingsTags)) {
      items.push({
        name: 'Settings',
        values: settingsTags
      });
    }
    items.push(getObjectTagsViewItems(calculateColumn, this.api));
    return {
      items: items,
      abObject: calculateColumn
    };
  }
  toViewAll() {
    return this.getModuleAdaptableObjects().map(calculatedColumn => this.toView(calculatedColumn));
  }
  getViewProperties() {
    return {
      getDeleteAction: CalculatedColumnRedux.CalculatedColumnDelete,
      getEditWizard: () => CalculatedColumnWizard
    };
  }
}