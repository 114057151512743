import * as React from 'react';
import { Box, Text } from 'rebass';
import { allIcons } from '../../components/icons';
import UIHelper from '../UIHelper';
export const ActiveAlertsPanelItemLabel = props => {
  const iconColor = UIHelper.getColorByMessageType(props.data.alertDefinition.MessageType);
  const iconStyle = {
    color: iconColor,
    fill: iconColor
  };
  const iconName = UIHelper.getGlyphByMessageType(props.data.alertDefinition.MessageType);
  const IconCmp = allIcons[iconName];
  const icon = IconCmp ? React.createElement(IconCmp, null) : null;
  return React.createElement(Box, null, React.createElement(Text, {
    style: Object.assign({
      float: 'left'
    }, iconStyle)
  }, icon), props.data.header);
};