import isEqual from 'lodash/isEqual';
import * as GridRedux from '../../Redux/ActionsReducers/GridRedux';
import { DEFAULT_LAYOUT } from '../../Utilities/Constants/GeneralConstants';
import { ColumnFilterModuleId, GridFilterModuleId } from '../../Utilities/Constants/ModuleConstants';
import ArrayExtensions from '../../Utilities/Extensions/ArrayExtensions';
import ObjectFactory from '../../Utilities/ObjectFactory';
import { ApiBase } from '../Implementation/ApiBase';
export class LayoutInternalApi extends ApiBase {
  /**
   * Fires the `LayoutChanged` event
   * @param trigger what caused event to fire
   * @param oldSate Layout State before change
   * @param newState Layout State after change
   */
  fireLayoutChangedEvent(trigger, oldSate, newState, skipEqualityCheck) {
    const layoutChangedInfo = Object.assign({
      actionName: trigger,
      oldLayoutState: oldSate,
      newLayoutState: newState
    }, this.getAdaptableApi().internalApi.buildBaseContext());
    if (!skipEqualityCheck && isEqual(oldSate, newState)) {
      return;
    }
    this.getAdaptableApi().eventApi.emit('LayoutChanged', layoutChangedInfo);
  }
  /**
   * Compares 2 Layouts for equality
   * @param layout1 First Layout
   * @param layout2 Second Layout
   */
  areLayoutsEqual(layout1, layout2) {
    if (!layout1 && !layout2) {
      return true;
    }
    if (!layout1 || !layout2) {
      return false;
    }
    const defaults = {
      ColumnSorts: [],
      //   ColumnFlexMap: {},
      ColumnWidthMap: {},
      RowGroupedColumns: []
    };
    layout1 = Object.assign(Object.assign({}, defaults), layout1);
    layout2 = Object.assign(Object.assign({}, defaults), layout2);
    return isEqual(layout1, layout2);
  }
  /**
   * Creates a Default Layout (when none provided in Config)
   * @returns layout
   */
  createDefaultLayoutIfNeeded() {
    let gridState = this.getAdaptableApi().gridApi.getGridState();
    let layoutState = this.getLayoutApi().getLayoutState();
    const isLayoutDefined = layoutName => !!layoutState.Layouts.filter(layout => layout.Name === layoutName)[0];
    let defaultLayoutColumns = gridState.Columns.filter(column => column.visible);
    let shouldCreateDefaultLayout = this.getLayoutOptions().createDefaultLayout;
    if (!layoutState.Layouts || !layoutState.Layouts.length) {
      shouldCreateDefaultLayout = true;
    }
    if (shouldCreateDefaultLayout) {
      if (!layoutState.Layouts || !isLayoutDefined(DEFAULT_LAYOUT)) {
        // augogroup columns are not in columns list
        // the code that adds column ok grid state explicitly ignores autoColumns
        const allGridColumns = this.getAdaptableApi().gridApi.getAllAgGridColumns();
        let defaultLayout = ObjectFactory.CreateEmptyLayout({
          Name: DEFAULT_LAYOUT,
          Columns: defaultLayoutColumns.map(c => c.columnId),
          AggregationColumns: defaultLayoutColumns.reduce((acc, col) => {
            if (col.aggregationFunction) {
              acc[col.columnId] = col.aggregationFunction;
            }
            return acc;
          }, {}),
          PinnedColumnsMap: allGridColumns.reduce((acc, col) => {
            const pinned = col.getPinned();
            if (pinned) {
              acc[col.getColId()] = pinned === true ? 'left' : pinned;
            }
            return acc;
          }, {})
        }, gridState.Columns);
        this.getLayoutApi().createOrUpdateLayout(defaultLayout);
        return defaultLayout;
      }
    }
  }
  /**
   * Returns true if Layouts will contain Expanded Row Groups information
   */
  areExpandedRowGroupsSavedInLayouts() {
    return this.getLayoutOptions().displayRowGroups === 'dynamic';
  }
  /**
   * Checks if the draft layout and the current layout are the same.
   * Useful when `autoSaveLayouts=true`
   */
  areDraftAndCurrentLayoutEqual() {
    const state = this.getAdaptableState();
    const currentLayout = this.getLayoutApi().getLayoutByName(state.Layout.CurrentLayout);
    const draftLayout = state.Grid.CurrentLayout;
    return this.areLayoutsEqual(currentLayout, draftLayout);
  }
  /**
   * Returns what the layout supports.
   * This takes into account the data-source.
   */
  getLayoutSupportedFeatures() {
    var _a, _b;
    const layoutSupportedFeatures = {
      RowGroupedColumns: true,
      AggregationColumns: true,
      PivotColumns: true,
      ColumnFilters: true,
      ColumnSorts: true,
      GridFilter: true,
      RowSummaries: true
    };
    if (this.getGridApi().getAgGridRowModelType() === 'viewport') {
      layoutSupportedFeatures.RowGroupedColumns = false;
      layoutSupportedFeatures.AggregationColumns = false;
      layoutSupportedFeatures.PivotColumns = false;
    }
    if (!this.getColumnFilterOptions().useAdaptableColumnFiltering || this.getEntitlementApi().getEntitlementAccessLevelForModule(ColumnFilterModuleId) == 'Hidden') {
      layoutSupportedFeatures.ColumnFilters = false;
    }
    if (this.getEntitlementApi().getEntitlementAccessLevelForModule(GridFilterModuleId) == 'Hidden') {
      layoutSupportedFeatures.GridFilter = false;
    }
    if (((_b = (_a = this.adaptable) === null || _a === void 0 ? void 0 : _a.getAgGridRowModelType) === null || _b === void 0 ? void 0 : _b.call(_a)) !== 'clientSide') {
      layoutSupportedFeatures.RowSummaries = false;
    }
    return layoutSupportedFeatures;
  }
  updateCurrentDraftLayout(layout) {
    this.dispatchAction(GridRedux.LayoutUpdateCurrentDraft(layout));
  }
  hasLayoutSpecificObjects() {
    const layoutTagOptions = this.getLayoutOptions().layoutTagOptions;
    if (!layoutTagOptions) {
      return false;
    }
    if (layoutTagOptions.autoCheckTagsForLayouts == true || typeof (layoutTagOptions === null || layoutTagOptions === void 0 ? void 0 : layoutTagOptions.isObjectAvailableInLayout) === 'function') {
      return true;
    }
    return false;
  }
  isObjectAvailableInLayout(object, module, layout) {
    var _a;
    if (!this.hasLayoutSpecificObjects()) {
      return true;
    }
    const layoutTagOptions = this.getLayoutOptions().layoutTagOptions;
    if ((layoutTagOptions === null || layoutTagOptions === void 0 ? void 0 : layoutTagOptions.autoCheckTagsForLayouts) == true) {
      return ArrayExtensions.IsNullOrEmpty(object.Tags) ? true : (_a = object.Tags) === null || _a === void 0 ? void 0 : _a.includes(layout.Name);
    }
    const context = Object.assign({
      adaptableObject: object,
      module,
      layout
    }, this.getAdaptableApi().internalApi.buildBaseContext());
    return layoutTagOptions === null || layoutTagOptions === void 0 ? void 0 : layoutTagOptions.isObjectAvailableInLayout(context);
  }
  showLayoutNotAssociatedObjects() {
    return this.getAdaptableState().System.ShowLayoutNotAssociatedObjects;
  }
  isLayoutGrouped(layout) {
    var _a;
    if (!layout) {
      layout = this.getLayoutApi().getCurrentLayout();
    }
    return ((_a = layout === null || layout === void 0 ? void 0 : layout.RowGroupedColumns) === null || _a === void 0 ? void 0 : _a.length) > 0;
  }
  setupRowSummaries() {
    var _a, _b, _c;
    const rowSummaries = (_c = (_b = (_a = this.getAdaptableState().System) === null || _a === void 0 ? void 0 : _a.RowSummary) === null || _b === void 0 ? void 0 : _b.rowSummaries) !== null && _c !== void 0 ? _c : [];
    const {
      top,
      bottom
    } = rowSummaries.reduce((acc, summaryRow) => {
      const row = summaryRow.RowData;
      if (summaryRow.Position === 'Bottom' || !summaryRow.Position) {
        acc.bottom.push(row);
      } else {
        acc.top.push(row);
      }
      return acc;
    }, {
      top: [],
      bottom: []
    });
    this.adaptable.agGridAdapter.setGridOption('pinnedTopRowData', top);
    this.adaptable.agGridAdapter.setGridOption('pinnedBottomRowData', bottom);
  }
}