export const POPUP_SHOW_SCREEN = 'POPUP_SHOW_SCREEN';
export const POPUP_HIDE_SCREEN = 'POPUP_HIDE_SCREEN';
export const POPUP_SHOW_ALERT = 'POPUP_SHOW_ALERT';
export const POPUP_SHOW_PROMPT = 'POPUP_SHOW_PROMPT';
export const POPUP_HIDE_PROMPT = 'POPUP_HIDE_PROMPT';
export const POPUP_CONFIRM_PROMPT = 'POPUP_CONFIRM_PROMPT';
export const POPUP_SHOW_CONFIRMATION = 'POPUP_SHOW_CONFIRMATION';
export const POPUP_CONFIRM_CONFIRMATION = 'POPUP_CONFIRM_CONFIRMATION';
export const POPUP_CANCEL_CONFIRMATION = 'POPUP_CANCEL_CONFIRMATION';
export const POPUP_CLEAR_PARAM = 'POPUP_CLEAR_PARAM';
export const POPUP_SHOW_WINDOW = 'POPUP_SHOW_WINDOW';
export const POPUP_HIDE_WINDOW = 'POPUP_HIDE_WINDOW';
export const POPUP_SHOW_FORM = 'POPUP_SHOW_FORM';
export const POPUP_HIDE_FORM = 'POPUP_FORM_HIDE';
export const PopupShowScreen = (componentModule, componentName, params, popupProps) => ({
  type: POPUP_SHOW_SCREEN,
  ComponentModule: componentModule,
  ComponentName: componentName,
  Params: params,
  PopupProps: popupProps
});
export const PopupHideScreen = () => ({
  type: POPUP_HIDE_SCREEN
});
export const PopupShowWindow = config => ({
  type: POPUP_SHOW_WINDOW,
  Id: config.Id,
  Title: config.Title,
  PopupProps: config.PopupProps,
  FactoryId: config.FactoryId,
  Icon: config.Icon
});
export const PopupHideWindow = id => ({
  type: POPUP_HIDE_WINDOW,
  Id: id
});
export const PopupShowForm = config => ({
  type: POPUP_SHOW_FORM,
  Id: config.Id,
  Form: config.Form,
  FormProps: config.FormProps,
  prepareContext: config.prepareContext
});
export const PopupHideForm = id => ({
  type: POPUP_HIDE_FORM,
  Id: id
});
export const PopupShowAlert = alert => {
  return {
    type: POPUP_SHOW_ALERT,
    alert: alert
  };
};
export const PopupShowPrompt = prompt => ({
  type: POPUP_SHOW_PROMPT,
  prompt: prompt
});
export const PopupHidePrompt = () => ({
  type: POPUP_HIDE_PROMPT
});
export const PopupConfirmPrompt = inputText => ({
  type: POPUP_CONFIRM_PROMPT,
  InputText: inputText
});
export const PopupShowConfirmation = confirmation => ({
  type: POPUP_SHOW_CONFIRMATION,
  confirmation: confirmation
});
export const PopupConfirmConfirmation = comment => ({
  type: POPUP_CONFIRM_CONFIRMATION,
  comment
});
export const PopupCancelConfirmation = () => ({
  type: POPUP_CANCEL_CONFIRMATION
});
export const PopupClearParam = () => ({
  type: POPUP_CLEAR_PARAM
});
const initialState = {
  ScreenPopup: {
    ShowScreenPopup: false,
    ComponentModule: undefined,
    ComponentName: '',
    Params: null
  },
  ConfirmationPopup: {
    ShowConfirmationPopup: false,
    Msg: '',
    Header: '',
    ConfirmButtonText: '',
    CancelButtonText: '',
    CancelAction: null,
    ConfirmAction: null,
    ShowInputBox: false,
    ConfirmationComment: null,
    MessageType: 'Info'
  },
  PromptPopup: {
    ShowPromptPopup: false,
    Header: '',
    Message: '',
    ConfirmAction: null
  },
  WindowPopup: {
    PopupList: []
  },
  FormPopup: {
    FormList: []
  }
};
export const PopupReducer = (state = initialState, action) => {
  switch (action.type) {
    case POPUP_SHOW_SCREEN:
      {
        let actionTypedShowPopup = action;
        let newScreenPopup = {
          ShowScreenPopup: true,
          ComponentModule: actionTypedShowPopup.ComponentModule,
          ComponentName: actionTypedShowPopup.ComponentName,
          Params: actionTypedShowPopup.Params,
          PopupProps: actionTypedShowPopup.PopupProps
        };
        return Object.assign({}, state, {
          ScreenPopup: newScreenPopup
        });
      }
    case POPUP_HIDE_SCREEN:
      {
        let newScreenPopup = {
          ShowScreenPopup: false,
          ComponentModule: undefined,
          ComponentName: '',
          Params: null
        };
        return Object.assign({}, state, {
          ScreenPopup: newScreenPopup
        });
      }
    case POPUP_SHOW_PROMPT:
      {
        let actionTyped = action;
        let newPromptPopup = {
          ShowPromptPopup: true,
          Header: actionTyped.prompt.Header,
          Message: actionTyped.prompt.Msg,
          ConfirmAction: actionTyped.prompt.ConfirmAction,
          ConfirmActionCreator: actionTyped.prompt.ConfirmActionCreator,
          DefaultValue: actionTyped.prompt.DefaultValue
        };
        return Object.assign({}, state, {
          PromptPopup: newPromptPopup
        });
      }
    case POPUP_HIDE_PROMPT:
      {
        let newPromptPopup = {
          ShowPromptPopup: false,
          Header: '',
          Message: '',
          ConfirmAction: null,
          ConfirmActionCreator: null,
          DefaultValue: ''
        };
        return Object.assign({}, state, {
          PromptPopup: newPromptPopup
        });
      }
    case POPUP_CONFIRM_PROMPT:
      {
        //we dispatch the Action of ConfirmAction in the middelware in order to keep the reducer pure
        let newPromptPopup = {
          ShowPromptPopup: false,
          Header: '',
          Message: '',
          ConfirmAction: null
        };
        return Object.assign({}, state, {
          PromptPopup: newPromptPopup
        });
      }
    case POPUP_SHOW_CONFIRMATION:
      {
        let actionTyped = action;
        let newConfirmationPopup = {
          ShowConfirmationPopup: true,
          Msg: actionTyped.confirmation.Msg,
          Header: actionTyped.confirmation.Header,
          ConfirmButtonText: actionTyped.confirmation.ConfirmButtonText,
          CancelButtonText: actionTyped.confirmation.CancelButtonText,
          ConfirmAction: actionTyped.confirmation.ConfirmAction,
          CancelAction: actionTyped.confirmation.CancelAction,
          ShowInputBox: actionTyped.confirmation.ShowInputBox,
          ConfirmationComment: null,
          MessageType: actionTyped.confirmation.MessageType
        };
        return Object.assign({}, state, {
          ConfirmationPopup: newConfirmationPopup
        });
      }
    case POPUP_CONFIRM_CONFIRMATION:
      {
        let actionTyped = action;
        //we dispatch the Action of ConfirmAction in the middelware in order to keep the reducer pure
        let newConfirmationPopup = {
          ShowConfirmationPopup: false,
          Msg: '',
          Header: '',
          ConfirmButtonText: '',
          CancelButtonText: '',
          ConfirmAction: null,
          CancelAction: null,
          ShowInputBox: false,
          ConfirmationComment: actionTyped.comment,
          MessageType: null // ???
        };
        return Object.assign({}, state, {
          ConfirmationPopup: newConfirmationPopup
        });
      }
    case POPUP_CANCEL_CONFIRMATION:
      {
        //we dispatch the Action of CancelAction in the middelware in order to keep the reducer pure
        let newConfirmationPopup = {
          ShowConfirmationPopup: false,
          Msg: '',
          Header: '',
          ConfirmButtonText: '',
          CancelButtonText: '',
          ConfirmAction: null,
          CancelAction: null,
          ShowInputBox: false,
          ConfirmationComment: null,
          MessageType: null
        };
        return Object.assign({}, state, {
          ConfirmationPopup: newConfirmationPopup
        });
      }
    case POPUP_CLEAR_PARAM:
      {
        let newScreenPopup = {
          ShowScreenPopup: state.ScreenPopup.ShowScreenPopup,
          ComponentModule: state.ScreenPopup.ComponentModule,
          ComponentName: state.ScreenPopup.ComponentName,
          Params: null
        };
        return Object.assign({}, state, {
          ScreenPopup: newScreenPopup
        });
      }
    case POPUP_SHOW_WINDOW:
      {
        const showWindowAction = action;
        const windowSettings = {
          Id: showWindowAction.Id,
          Icon: showWindowAction.Icon,
          Title: showWindowAction.Title,
          PopupProps: showWindowAction.PopupProps,
          FactoryId: showWindowAction.FactoryId
        };
        let PopupList = [...state.WindowPopup.PopupList];
        // Prevent opening the same popup twice
        if (state.WindowPopup.PopupList.some(windowPopup => windowPopup.Id === windowSettings.Id)) {
          PopupList = PopupList.map(windowPopup => {
            if (windowPopup.Id !== windowSettings.Id) {
              return windowPopup;
            }
            return Object.assign(Object.assign({}, windowPopup), windowSettings);
          });
        } else {
          PopupList = [...PopupList, windowSettings];
        }
        return Object.assign(Object.assign({}, state), {
          WindowPopup: Object.assign(Object.assign({}, state.WindowPopup), {
            PopupList
          })
        });
      }
    case POPUP_HIDE_WINDOW:
      {
        const PopupList = state.WindowPopup.PopupList.filter(windowPopup => windowPopup.Id !== action.Id);
        return Object.assign(Object.assign({}, state), {
          WindowPopup: Object.assign(Object.assign({}, state.WindowPopup), {
            PopupList
          })
        });
      }
    case POPUP_SHOW_FORM:
      {
        const showWindowAction = action;
        const windowSettings = {
          Id: showWindowAction.Id,
          FormProps: showWindowAction.FormProps,
          Form: showWindowAction.Form,
          prepareContext: showWindowAction.prepareContext
        };
        if (state.FormPopup.FormList.some(windowPopup => windowPopup.Id === windowSettings.Id)) {
          return state;
        }
        const FormList = [...state.FormPopup.FormList, windowSettings];
        return Object.assign(Object.assign({}, state), {
          FormPopup: Object.assign(Object.assign({}, state.WindowPopup), {
            FormList
          })
        });
      }
    case POPUP_HIDE_FORM:
      {
        const FormList = state.FormPopup.FormList.filter(form => form.Id !== action.Id);
        return Object.assign(Object.assign({}, state), {
          FormPopup: Object.assign(Object.assign({}, state.WindowPopup), {
            FormList
          })
        });
      }
    default:
      return state;
  }
};