export const normalizeDateParams = args => {
  let [first, second] = args;
  if (typeof first === 'string') {
    first = new Date(first);
  }
  if (typeof second === 'string') {
    second = new Date(second);
  }
  return [first, second];
};
export const normalizeDateParam = args => {
  if (typeof args === 'string') {
    args = new Date(args);
  }
  if (typeof args === 'number') {
    args = new Date(args);
  }
  return args;
};
export const formatDate = (date, format) => {
  date = normalizeDateParam(date);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();
  return format.replace('DD', day.toString().padStart(2, '0')).replace('MM', month.toString().padStart(2, '0')).replace('YYYY', year.toString()).replace('HH', hour.toString().padStart(2, '0')).replace('mm', minute.toString().padStart(2, '0')).replace('ss', second.toString().padStart(2, '0'));
};