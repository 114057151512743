import * as React from 'react';
import { useAdaptable } from '../AdaptableContext';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import { AdaptableObjectCompactList } from '../Components/AdaptableObjectList/AdaptableObjectCompactList';
export const ActiveFiltersPanel = () => {
  const adaptable = useAdaptable();
  const filterModule = adaptable.ModuleService.getModuleById(ModuleConstants.ColumnFilterModuleId);
  const columnFilters = adaptable.api.columnFilterApi.getColumnFilters();
  return React.createElement(AdaptableObjectCompactList, {
    abObjects: columnFilters,
    module: filterModule
  });
};