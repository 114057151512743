import * as React from 'react';
import { useSelector } from 'react-redux';
import { Box, Flex } from 'rebass';
import { useAdaptable } from '../AdaptableContext';
import { DashboardToolbarFactory } from './DashboardToolbarFactory';
export const PinnedDashboard = props => {
  var _a;
  const adaptable = useAdaptable();
  const dashboardState = useSelector(state => state.Dashboard);
  const pinnedToolbars = ((_a = dashboardState.PinnedToolbars) !== null && _a !== void 0 ? _a : []).filter(tb => adaptable.api.dashboardApi.internalApi.isToolbarCustom(tb) || adaptable.api.internalApi.getModuleService().isModuleAvailable(tb));
  if (!pinnedToolbars || pinnedToolbars.length == 0) {
    return React.createElement(React.Fragment, null);
  }
  return React.createElement(Flex, {
    p: 1,
    className: "ab-Dashboard__pinned"
  }, pinnedToolbars.map((toolbar, index) => {
    return React.createElement(Box, {
      key: index,
      mr: 1,
      mb: 1,
      className: `ab-Dashboard__pinned-container ab-Dashboard__pinned-container--${toolbar}`
    }, React.createElement(Box, {
      className: "ab-Dashboard__toolbar-content"
    }, React.createElement(DashboardToolbarFactory, {
      toolbarName: toolbar
    })));
  }));
};