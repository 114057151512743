import * as React from 'react';
import SimpleButton from '../../../components/SimpleButton';
export const ButtonInfo = props => {
  return React.createElement(SimpleButton, Object.assign({
    "data-name": "info",
    iconSize: 20,
    icon: "info",
    variant: "raised",
    tone: "accent"
  }, props));
};