import { ApiBase } from './ApiBase';
import * as StyledColumnRedux from '../../Redux/ActionsReducers/StyledColumnRedux';
import { StyledColumnInternalApi } from '../Internal/StyledColumnInternalApi';
import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
export class StyledColumnApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.internalApi = new StyledColumnInternalApi(adaptable);
  }
  getStyledColumnState() {
    return this.getAdaptableState().StyledColumn;
  }
  getStyledColumns(config) {
    var _a;
    return (_a = this.handleLayoutAssociatedObjects(this.getStyledColumnState().StyledColumns, 'StyledColumn', config)) !== null && _a !== void 0 ? _a : [];
  }
  getStyledColumnById(id) {
    return this.getStyledColumns().find(styledcolumn => styledcolumn.Uuid === id);
  }
  getStyledColumnForColumnId(columnId) {
    return this.getStyledColumns().find(styledcolumn => styledcolumn.ColumnId === columnId);
  }
  getActiveStyledColumns() {
    return this.getStyledColumns().filter(styledcolumn => !styledcolumn.IsSuspended);
  }
  getSuspendedStyledColumns() {
    return this.getStyledColumns().filter(styledcolumn => styledcolumn.IsSuspended);
  }
  deleteStyledColumn(styledColumn) {
    this.dispatchAction(StyledColumnRedux.StyledColumnDelete(styledColumn));
  }
  addStyledColumn(styledColumn) {
    this.dispatchAction(StyledColumnRedux.StyledColumnAdd(styledColumn));
  }
  isSparklineStyleStyledColumn(column) {
    if (!column) {
      return false;
    }
    return !!this.getStyledColumns().find(styledColumn => !!styledColumn.SparkLineStyle && styledColumn.ColumnId === column.columnId);
  }
  getActiveStyledColumnForColumn(column) {
    const styledColumns = this.getActiveStyledColumns();
    return styledColumns.find(sc => sc.ColumnId == column.columnId);
  }
  hasPercentBarStyle(columnId) {
    if (!columnId) {
      return false;
    }
    return !!this.getStyledColumns().find(styledColumn => !!styledColumn.PercentBarStyle && styledColumn.ColumnId === columnId);
  }
  hasGradientStyle(columnId) {
    if (!columnId) {
      return false;
    }
    return !!this.getStyledColumns().find(styledColumn => !!styledColumn.GradientStyle && styledColumn.ColumnId === columnId);
  }
  canDisplaySparklines() {
    return this.adaptable.canDisplaySparklines();
  }
  suspendStyledColumn(styledColumn) {
    this.dispatchAction(StyledColumnRedux.StyledColumnSuspend(styledColumn));
  }
  unSuspendStyledColumn(styledColumn) {
    this.dispatchAction(StyledColumnRedux.StyledColumnUnSuspend(styledColumn));
  }
  openStyledColumnSettingsPanel() {
    this.showModulePopup(ModuleConstants.StyledColumnModuleId);
  }
}