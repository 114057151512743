import * as ModuleConstants from '../../Utilities/Constants/ModuleConstants';
import { IsNullOrEmptyOrWhiteSpace } from '../../Utilities/Extensions/StringExtensions';
import { NamedQueryInternalApi } from '../Internal/NamedQueryInternalApi';
import { ApiBase } from './ApiBase';
import * as NamedQueryRedux from '../../Redux/ActionsReducers/NamedQueryRedux';
export class NamedQueryApiImpl extends ApiBase {
  constructor(adaptable) {
    super(adaptable);
    this.internalApi = new NamedQueryInternalApi(adaptable);
  }
  addNamedQuery(namedQuery) {
    this.dispatchAction(NamedQueryRedux.NamedQueryAdd(namedQuery));
  }
  addNamedQueries(namedQueries) {
    this.dispatchAction(NamedQueryRedux.NamedQueriesAdd(namedQueries));
  }
  getNamedQueryState() {
    return this.getAdaptableState().NamedQuery;
  }
  getNamedQueries() {
    return this.getNamedQueryState().NamedQueries || [];
  }
  getNamedQueryByName(namedQueryName) {
    return this.getNamedQueries().find(se => se.Name == namedQueryName);
  }
  isValidNamedQuery(namedQuery) {
    if (IsNullOrEmptyOrWhiteSpace(namedQuery === null || namedQuery === void 0 ? void 0 : namedQuery.Name)) {
      return {
        valid: false,
        message: 'Named Query name cannot be blank'
      };
    }
    // check that there is no other existing named query with the same name
    const duplicate = this.getNamedQueries().find(q => q.Name === namedQuery.Name && q.Uuid !== namedQuery.Uuid);
    if (duplicate) {
      return {
        valid: false,
        message: 'A Named Query already exists with that name'
      };
    }
    return {
      valid: true,
      message: ''
    };
  }
  openNamedQuerySettingsPanel() {
    this.showModulePopup(ModuleConstants.NamedQueryModuleId);
  }
  runNamedQuery(namedQuery) {
    this.getGridFilterApi().setGridFilterExpression(namedQuery.Name);
  }
  runQueryByName(queryName) {
    const namedQuery = this.getNamedQueryByName(queryName);
    if (namedQuery) {
      this.runNamedQuery(namedQuery);
    }
  }
}