import * as React from 'react';
import { useAdaptable } from '../../AdaptableContext';
import { AdaptableViewPanelFactory } from '../../AdaptableViewFactory';
import { CustomToolPanelContent } from './CustomToolPanelContent';
import { Box } from 'rebass';
import * as ToolPanelRedux from '../../../Redux/ActionsReducers/ToolPanelRedux';
import { PanelToolPanel } from '../Panels/PanelToolPanel';
import * as PopupRedux from '../../../Redux/ActionsReducers/PopupRedux';
import { ToolPanelModuleId } from '../../../Utilities/Constants/ModuleConstants';
export const ToolPanelWrapper = props => {
  const {
    adaptableToolPanel,
    customToolPanel,
    visibilityMode: visibilityMode,
    onVisibilityModeChange: onVisibilityModeChange
  } = props;
  const {
    api
  } = useAdaptable();
  const getToolPanelConfiguration = () => {
    var _a;
    if (adaptableToolPanel) {
      const accessLevel = api.entitlementApi.getEntitlementAccessLevelForModule(adaptableToolPanel);
      const moduleInfo = api.internalApi.getModuleService().getModuleInfoByModule(adaptableToolPanel);
      const ModuleToolPanel = AdaptableViewPanelFactory.get(adaptableToolPanel);
      return [adaptableToolPanel, moduleInfo.FriendlyName, ModuleToolPanel ? React.createElement(ModuleToolPanel, {
        viewType: "ToolPanel",
        api: api,
        accessLevel: accessLevel,
        moduleInfo: moduleInfo
      }) : null, moduleInfo];
    } else {
      // custom toolPanel
      return [customToolPanel.name, (_a = customToolPanel === null || customToolPanel === void 0 ? void 0 : customToolPanel.title) !== null && _a !== void 0 ? _a : customToolPanel.name, React.createElement(CustomToolPanelContent, {
        customToolPanel: customToolPanel
      })];
    }
  };
  const [toolPanelName, toolPanelHeader, toolPanelContent, moduleInfo] = getToolPanelConfiguration();
  const isMinimised = visibilityMode === 'collapsed';
  const isConfigurable =
  // only module toolPanels are configurable
  !!moduleInfo && toolPanelName !== 'OpenFin' && toolPanelName !== 'IPushPull';
  const isToolPanelModuleConfigurable = api.internalApi.getModuleService().getModuleById(ToolPanelModuleId).isModuleEditable();
  return React.createElement(React.Fragment, null, toolPanelContent && React.createElement(Box, {
    marginTop: 1,
    className: `ab-ToolPanel__container ab-ToolPanel__container--${toolPanelName}`
  }, React.createElement(PanelToolPanel, {
    className: `ab-ToolPanel__${toolPanelName}`,
    "data-name": `ab-ToolPanel__${toolPanelName}`,
    headerText: toolPanelHeader,
    disabled: !isToolPanelModuleConfigurable,
    onMinimiseChanged: () => {
      onVisibilityModeChange(isMinimised ? 'expanded' : 'collapsed');
    },
    isMinimised: isMinimised,
    onConfigure: isConfigurable ? () => {
      const maxWidth = api.internalApi.getModuleService().getPopupMaxWidth(moduleInfo.ModuleName);
      const popupParams = maxWidth ? {
        maxWidth,
        source: 'Toolbar'
      } : {
        source: 'Toolbar'
      };
      api.internalApi.dispatchReduxAction(PopupRedux.PopupShowScreen(moduleInfo.ModuleName, moduleInfo.Popup, popupParams));
    } : null,
    onClose: () => api.internalApi.dispatchReduxAction(ToolPanelRedux.ToolPanelHideToolPanel(toolPanelName))
  }, isMinimised ? null : toolPanelContent)));
};