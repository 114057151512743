import { CELL_SUMMARY_DEFAULT_OPERATION, EMPTY_ARRAY, EMPTY_STRING } from '../../Utilities/Constants/GeneralConstants';
import { MathOperation } from '../../PredefinedConfig/Common/Enums';
import { cloneObject } from '../../Utilities/Helpers/Helper';
import { CellPopupService } from '../../Utilities/Services/CellPopupService';
/*
Bit of a mixed bag of actions but essentially its those that are related to Strategies but where we DONT want to persist state
This allows us to keep the other reducers pure in terms of everything persists
Not sure if its a good idea or not and perhaps we need 2 stores but I think its better than it was...
*/
export const FLASHING_CELL_ROW_KEY = '__ROW';
// Alerts
export const SYSTEM_ALERT_ADD = 'SYSTEM_ALERT_ADD';
export const SYSTEM_ALERT_DELETE = 'SYSTEM_ALERT_DELETE';
export const SYSTEM_ALERT_DELETE_ALL = 'SYSTEM_ALERT_DELETE_ALL';
export const SYSTEM_ALERT_REMOVE_CELL_HIGHLIGHT = 'SYSTEM_ALERT_REMOVE_CELL_HIGHLIGHT';
export const SYSTEM_ALERT_REMOVE_ROW_HIGHLIGHT = 'SYSTEM_ALERT_REMOVE_ROW_HIGHLIGHT';
// Flashing Cells
export const SYSTEM_FLASHING_CELL_ADD = 'SYSTEM_FLASHING_CELL_ADD';
export const SYSTEM_FLASHING_CELL_DELETE = 'SYSTEM_FLASHING_CELL_DELETE';
export const SYSTEM_FLASHING_CELL_DELETE_ALL = 'SYSTEM_FLASHING_CELL_DELETE_ALL';
// Status Message
export const SYSTEM_STATUS_MESSAGE_INFO_ADD = 'SYSTEM_STATUS_MESSAGE_INFO_ADD';
export const SYSTEM_STATUS_MESSAGE_INFO_DELETE = 'SYSTEM_STATUS_MESSAGE_INFO_DELETE';
export const SYSTEM_STATUS_MESSAGE_INFO_DELETE_ALL = 'SYSTEM_STATUS_MESSAGE_INFO_DELETE_ALL';
// Smart Edit
export const SYSTEM_SMARTEDIT_CHECK_CELL_SELECTION = 'SYSTEM_SMARTEDIT_CHECK_CELL_SELECTION';
export const SYSTEM_SMARTEDIT_FETCH_PREVIEW = 'SYSTEM_SMARTEDIT_FETCH_PREVIEW';
export const SYSTEM_SMARTEDIT_SET_VALID_SELECTION = 'SYSTEM_SMARTEDIT_SET_VALID_SELECTION';
export const SYSTEM_SMARTEDIT_SET_PREVIEW = 'SYSTEM_SMARTEDIT_SET_PREVIEW';
export const SYSTEM_SMART_EDIT_CHANGE_VALUE = 'SYSTEM_SMART_EDIT_CHANGE_VALUE';
export const SYSTEM_SMART_EDIT_CHANGE_OPERATION = 'SYSTEM_SMART_EDIT_CHANGE_OPERATION';
// Bulk Update
export const SYSTEM_BULK_UPDATE_CHECK_CELL_SELECTION = 'SYSTEM_BULK_UPDATE_CHECK_CELL_SELECTION';
export const SYSTEM_BULK_UPDATE_SET_VALID_SELECTION = 'SYSTEM_BULK_UPDATE_SET_VALID_SELECTION';
export const SYSTEM_BULK_UPDATE_SET_PREVIEW = 'SYSTEM_BULK_UPDATE_SET_PREVIEW';
export const SYSTEM_BULK_UPDATE_CHANGE_VALUE = 'SYSTEM_BULK_UPDATE_CHANGE_VALUE';
// Cell Highlight
export const SYSTEM_HIGHLIGHT_CELL_ADD = 'SYSTEM_HIGHLIGHT_CELL_ADD';
export const SYSTEM_HIGHLIGHT_CELL_DELETE = 'SYSTEM_HIGHLIGHT_CELL_DELETE';
export const SYSTEM_HIGHLIGHT_CELL_DELETE_ALL = 'SYSTEM_HIGHLIGHT_CELL_DELETE_ALL';
// Rows Highlight
export const SYSTEM_HIGHLIGHT_ROW_ADD = 'SYSTEM_HIGHLIGHT_ROW_ADD';
export const SYSTEM_HIGHLIGHT_ROWS_ADD = 'SYSTEM_HIGHLIGHT_ROWS_ADD';
export const SYSTEM_HIGHLIGHT_ROW_DELETE = 'SYSTEM_HIGHLIGHT_ROW_DELETE';
export const SYSTEM_HIGHLIGHT_ROWS_DELETE = 'SYSTEM_HIGHLIGHT_ROWS_DELETE';
export const SYSTEM_HIGHLIGHT_ROW_DELETE_ALL = 'SYSTEM_HIGHLIGHT_ROW_DELETE_ALL';
// Columns
export const SYSTEM_SET_NEW_COLUMN_LIST_ORDER = 'SYSTEM_SET_NEW_COLUMN_LIST_ORDER';
// Shortcut
export const SYSTEM_SET_LAST_APPLIED_SHORTCUT = 'SYSTEM_SET_LAST_APPLIED_SHORTCUT';
// Cached Queries
export const SYSTEM_CACHED_QUERY_ADD = 'SYSTEM_CACHED_QUERY_ADD';
// Cell Summary
export const SYSTEM_CELL_SUMMARY_CHANGE_OPERATION = 'SYSTEM_CELL_SUMMARY_CHANGE_OPERATION';
// Progress Indicator
export const SYSTEM_PROGRESS_INDICATOR_SHOW = 'SYSTEM_PROGRESS_INDICATOR_SHOW';
export const SYSTEM_PROGRESS_INDICATOR_HIDE = 'SYSTEM_PROGRESS_INDICATOR_HIDE';
export const SYSTEM_LICENSE_SHOW_WATERMARK = 'SYSTEM_LICENSE_SHOW_WATERMARK';
export const SYSTEM_LICENSE_DISABLE_PERSISTENCE = 'SYSTEM_LICENSE_DISABLE_PERSISTENCE';
// Change History
export const SYSTEM_DATA_CHANGE_HISTORY_ADD = 'SYSTEM_DATA_CHANGE_HISTORY_ADD';
export const SYSTEM_DATA_CHANGE_HISTORY_UNDO = 'SYSTEM_DATA_CHANGE_HISTORY_UNDO';
export const SYSTEM_DATA_CHANGE_HISTORY_CLEAR_ROW = 'SYSTEM_DATA_CHANGE_HISTORY_CLEAR_ROW';
export const SYSTEM_DATA_CHANGE_HISTORY_ENABLE = 'SYSTEM_DATA_CHANGE_HISTORY_ENABLE';
export const SYSTEM_DATA_CHANGE_HISTORY_DISABLE = 'SYSTEM_DATA_CHANGE_HISTORY_DISABLE';
export const SYSTEM_DATA_CHANGE_HISTORY_SUSPEND = 'SYSTEM_DATA_CHANGE_HISTORY_SUSPEND';
export const SYSTEM_DATA_CHANGE_HISTORY_RESUME = 'SYSTEM_DATA_CHANGE_HISTORY_RESUME';
// Settings panel
export const SYSTEM_SETTINGS_PANEL_SET = 'SYSTEM_SETTINGS_PANEL_SET';
// Quick Filter
export const SYSTEM_QUICK_FILTER_BAR_SHOW = 'SYSTEM_QUICK_FILTER_BAR_SHOW';
export const SYSTEM_QUICK_FILTER_BAR_HIDE = 'SYSTEM_QUICK_FILTER_BAR_HIDE';
export const SYSTEM_FILTER_FORM_HIDE = 'SYSTEM_FILTER_FORM_HIDE';
// Delete warning
export const SYSTEM_DISABLE_DELETE_CONFIRMATION = 'SYSTEM_DISABLE_DELETE_CONFIRMATION';
// Layout
export const SYSTEM_LAYOUT_SHOW_NOT_ASSOCIATED_OBJECTS = 'SYSTEM_LAYOUT_SHOW_NOT_ASSOCIATED_OBJECTS';
export const DASHBOARD_REFRESH = 'DASHBOARD_REFRESH';
// DataSet
export const SYSTEM_DATA_SET_SELECT = 'SYSTEM_DATA_SET_SELECT';
// Charting
export const SYSTEM_CHARTING_SET_CURRENT_CHART_MODELS = 'SYSTEM_CHARTING_SET_CURRENT_CHART_MODELS';
// Layout
export const SYSTEM_SET_PREVIOUS_GROUPED_COLUMN_INDEX = 'SYSTEM_SET_PREVIOUS_GROUPED_COLUMN_INDEX';
// Quick Search
export const SYSTEM_SET_QUICK_SEARCH_FLOATING_VISIBILITY = 'SYSTEM_SET_QUICK_SEARCH_FLOATING_VISIBILITY';
// Data Export (Report)
export const SYSTEM_VISUAL_EXPORT_BEGIN = 'SYSTEM_VISUAL_EXPORT_BEGIN';
export const SYSTEM_VISUAL_EXPORT_END = 'SYSTEM_VISUAL_EXPORT_END';
// Notes and Comments
export const SYSTEM_CELL_POPUP_SHOW = 'SYSTEM_CELL_POPUP_SHOW';
export const SYSTEM_CELL_POPUP_HIDE = 'SYSTEM_CELL_POPUP_HIDE';
export const SYSTEM_CELL_POPUP_EDIT_FOCUSED_ENTITY = 'SYSTEM_CELL_POPUP_EDIT_FOCUSED_ENTITY';
// Row Summaries
export const SYSTEM_SUMMARY_ROW_SET = 'SYSTEM_SUMMARY_ROW_SET';
// Data Import
export const DATA_IMPORT_COMPLETED = 'DATA_IMPORT_COMPLETED';
export const SystemHighlightCellAdd = cellHighlightInfo => ({
  type: SYSTEM_HIGHLIGHT_CELL_ADD,
  cellHighlightInfo: cellHighlightInfo
});
export const SystemHighlightCellDelete = (primaryKeyValue, columnId) => ({
  type: SYSTEM_HIGHLIGHT_CELL_DELETE,
  primaryKeyValue,
  columnId
});
export const SystemHighlightCellDeleteAll = () => ({
  type: SYSTEM_HIGHLIGHT_CELL_DELETE_ALL
});
export const SystemHighlightRowAdd = rowHighlightInfo => ({
  type: SYSTEM_HIGHLIGHT_ROW_ADD,
  rowHighlightInfo: rowHighlightInfo
});
export const SystemHighlightRowsAdd = rowsHighlightInfo => ({
  type: SYSTEM_HIGHLIGHT_ROWS_ADD,
  rowsHighlightInfo: rowsHighlightInfo
});
export const SystemHighlightRowDelete = primaryKeyValue => ({
  type: SYSTEM_HIGHLIGHT_ROW_DELETE,
  primaryKeyValue: primaryKeyValue
});
export const SystemHighlightRowsDelete = primaryKeyValues => ({
  type: SYSTEM_HIGHLIGHT_ROWS_DELETE,
  primaryKeyValues: primaryKeyValues
});
export const SystemHighlightRowDeleteAll = () => ({
  type: SYSTEM_HIGHLIGHT_ROW_DELETE_ALL
});
export const SystemAlertAdd = (alert, maxAlerts) => ({
  type: SYSTEM_ALERT_ADD,
  alert: alert,
  maxAlerts: maxAlerts
});
export const SystemAlertDelete = alert => ({
  type: SYSTEM_ALERT_DELETE,
  alert: alert
});
export const SystemAlertDeleteAll = alerts => ({
  type: SYSTEM_ALERT_DELETE_ALL,
  alerts: alerts
});
export const SystemAlertRemoveCellHighlight = alert => ({
  type: SYSTEM_ALERT_REMOVE_CELL_HIGHLIGHT,
  alert: alert
});
export const SystemAlertRemoveRowHighlight = alert => ({
  type: SYSTEM_ALERT_REMOVE_ROW_HIGHLIGHT,
  alert: alert
});
export const SystemFlashingCellAdd = flashingCell => ({
  type: SYSTEM_FLASHING_CELL_ADD,
  flashingCell: flashingCell
});
export const SystemFlashingCellDelete = flashingCell => ({
  type: SYSTEM_FLASHING_CELL_DELETE,
  flashingCell: flashingCell
});
export const SystemFlashingCellDeleteAll = () => ({
  type: SYSTEM_FLASHING_CELL_DELETE_ALL
});
export const SystemStatusMessageInfoAdd = (SystemStatusMessageInfo, MaxSystemStatusMessagesInStore) => ({
  type: SYSTEM_STATUS_MESSAGE_INFO_ADD,
  systemStatusMessageInfo: SystemStatusMessageInfo,
  maxSystemStatusMessagesInStore: MaxSystemStatusMessagesInStore
});
export const SystemStatusMessageInfoDelete = SystemStatusMessageInfo => ({
  type: SYSTEM_STATUS_MESSAGE_INFO_DELETE,
  systemStatusMessageInfo: SystemStatusMessageInfo
});
export const SystemStatusMessageInfoDeleteAll = () => ({
  type: SYSTEM_STATUS_MESSAGE_INFO_DELETE_ALL
});
export const SmartEditChangeValue = value => ({
  type: SYSTEM_SMART_EDIT_CHANGE_VALUE,
  value
});
export const SmartEditChangeOperation = smartEditOperation => ({
  type: SYSTEM_SMART_EDIT_CHANGE_OPERATION,
  smartEditOperation
});
export const SmartEditCheckCellSelection = () => ({
  type: SYSTEM_SMARTEDIT_CHECK_CELL_SELECTION
});
export const SmartEditSetValidSelection = IsValidSmartEditSelection => ({
  type: SYSTEM_SMARTEDIT_SET_VALID_SELECTION,
  isValidSmartEditSelection: IsValidSmartEditSelection
});
export const SmartEditSetPreview = SmartEditPreviewInfo => ({
  type: SYSTEM_SMARTEDIT_SET_PREVIEW,
  smartEditPreviewInfo: SmartEditPreviewInfo
});
export const BulkUpdateCheckCellSelection = () => ({
  type: SYSTEM_BULK_UPDATE_CHECK_CELL_SELECTION
});
export const BulkUpdateSetValidSelection = bulkUpdateValidationResult => ({
  type: SYSTEM_BULK_UPDATE_SET_VALID_SELECTION,
  bulkUpdateValidationResult
});
export const BulkUpdateSetPreview = BulkUpdatePreviewInfo => ({
  type: SYSTEM_BULK_UPDATE_SET_PREVIEW,
  bulkUpdatePreviewInfo: BulkUpdatePreviewInfo
});
export const BulkUpdateChangeValue = bulkUpdateValue => ({
  type: SYSTEM_BULK_UPDATE_CHANGE_VALUE,
  bulkUpdateValue
});
export const SetNewColumnListOrder = VisibleColumnList => ({
  type: SYSTEM_SET_NEW_COLUMN_LIST_ORDER,
  visibleColumnList: VisibleColumnList
});
export const SetLastAppliedShortcut = gridCell => ({
  type: SYSTEM_SET_LAST_APPLIED_SHORTCUT,
  gridCell
});
export const SystemCachedQueryAdd = cachedQuery => ({
  type: SYSTEM_CACHED_QUERY_ADD,
  cachedQuery
});
export const SystemCellSummaryChangeOperation = cellSummaryOperation => ({
  type: SYSTEM_CELL_SUMMARY_CHANGE_OPERATION,
  cellSummaryOperation: cellSummaryOperation
});
export const SystemProgressIndicatorShow = progressIndicatorConfig => ({
  type: SYSTEM_PROGRESS_INDICATOR_SHOW,
  progressIndicatorConfig: progressIndicatorConfig
});
export const SystemProgressIndicatorHide = () => ({
  type: SYSTEM_PROGRESS_INDICATOR_HIDE
});
export const SystemLicenseShowWatermark = text => ({
  type: SYSTEM_LICENSE_SHOW_WATERMARK,
  text
});
export const SystemLicenseDisablePersistence = () => ({
  type: SYSTEM_LICENSE_DISABLE_PERSISTENCE
});
export const SystemDataChangeHistoryAdd = (changeInfo, uniqueKey) => ({
  type: SYSTEM_DATA_CHANGE_HISTORY_ADD,
  changeInfo,
  uniqueKey
});
export const SystemDataChangeHistoryUndo = (changeInfo, uniqueKey) => ({
  type: SYSTEM_DATA_CHANGE_HISTORY_UNDO,
  changeInfo,
  uniqueKey
});
export const SystemDataChangeHistoryClearRow = (changeInfo, uniqueKey) => ({
  type: SYSTEM_DATA_CHANGE_HISTORY_CLEAR_ROW,
  changeInfo,
  uniqueKey
});
export const SystemDataChangeHistoryEnable = () => ({
  type: SYSTEM_DATA_CHANGE_HISTORY_ENABLE
});
export const SystemDataChangeHistoryDisable = () => ({
  type: SYSTEM_DATA_CHANGE_HISTORY_DISABLE
});
export const SystemDataChangeHistorySuspend = () => ({
  type: SYSTEM_DATA_CHANGE_HISTORY_SUSPEND
});
export const SystemDataChangeHistoryResume = () => ({
  type: SYSTEM_DATA_CHANGE_HISTORY_RESUME
});
export const SystemSettingsPanelSet = (name, settings) => ({
  type: SYSTEM_SETTINGS_PANEL_SET,
  name,
  settings
});
export const SystemQuickFilterBarShow = () => ({
  type: SYSTEM_QUICK_FILTER_BAR_SHOW
});
export const SystemQuickFilterBarHide = () => ({
  type: SYSTEM_QUICK_FILTER_BAR_HIDE
});
export const SystemFilterFormHide = () => ({
  type: SYSTEM_FILTER_FORM_HIDE
});
export const SystemLayoutShowNotAssociatedObjects = showNotAssociatedObjects => ({
  type: SYSTEM_LAYOUT_SHOW_NOT_ASSOCIATED_OBJECTS,
  showNotAssociatedObjects
});
export const SystemDashboardRefresh = () => ({
  type: DASHBOARD_REFRESH
});
export const SystemDataSetSelect = dataSet => ({
  type: SYSTEM_DATA_SET_SELECT,
  dataSet: dataSet
});
export const SystemChartingSetCurrentChartModels = chartModels => ({
  type: SYSTEM_CHARTING_SET_CURRENT_CHART_MODELS,
  chartModels
});
export const SystemChartingCurrentChartModelsSelector = state => {
  return state.Charting.currentChartModels;
};
export const SystemSetPreviousGroupedColumnsIndex = (layoutId, columnId, columnIndex) => ({
  type: SYSTEM_SET_PREVIOUS_GROUPED_COLUMN_INDEX,
  layoutId,
  columnId,
  columnIndex
});
export const SystemDisableDeleteConfirmation = () => ({
  type: SYSTEM_DISABLE_DELETE_CONFIRMATION
});
export const SystemVisualExportBegin = () => ({
  type: SYSTEM_VISUAL_EXPORT_BEGIN
});
export const SystemVisualExportEnd = () => ({
  type: SYSTEM_VISUAL_EXPORT_END
});
export const SystemQuickSearchFloatingVisibility = visible => ({
  type: SYSTEM_SET_QUICK_SEARCH_FLOATING_VISIBILITY,
  visible
});
export const SystemCellPopupShow = (cellPosition, editMode) => ({
  type: SYSTEM_CELL_POPUP_SHOW,
  editMode,
  cellPosition
});
export const SystemCellPopupEditFocusedEntity = focusedEntity => ({
  type: SYSTEM_CELL_POPUP_EDIT_FOCUSED_ENTITY,
  focusedEntity
});
export const SystemRowSummarySet = systemRowSummaries => ({
  type: SYSTEM_SUMMARY_ROW_SET,
  rowSummaries: systemRowSummaries
});
export const SystemCellPopupHide = () => ({
  type: SYSTEM_CELL_POPUP_HIDE
});
export const SystemDisableDeleteConfirmationSelector = state => {
  return state.DisableDeleteConfirmation;
};
export const SystemPreviousGroupedColumnsSelector = state => {
  return state.Layout.previousGroupedColumns;
};
export const SystemQuickSearchFloatingVisibilitySelector = state => {
  var _a;
  return (_a = state === null || state === void 0 ? void 0 : state.QuickSearch) === null || _a === void 0 ? void 0 : _a.floatingVisible;
};
export const SystemCommentsAndNotesSelector = state => {
  var _a;
  return (_a = state === null || state === void 0 ? void 0 : state.NotesAndComments) === null || _a === void 0 ? void 0 : _a.popupPosition;
};
export const SystemCommentsAndNotesEditModeSelector = state => {
  var _a;
  return (_a = state === null || state === void 0 ? void 0 : state.NotesAndComments) === null || _a === void 0 ? void 0 : _a.editMode;
};
export const SystemCommentsAndNotesFocusedEntitySelector = state => {
  var _a;
  return (_a = state === null || state === void 0 ? void 0 : state.NotesAndComments) === null || _a === void 0 ? void 0 : _a.focusedEntity;
};
export const DataImportCompleted = dataImportedInfo => ({
  type: DATA_IMPORT_COMPLETED,
  dataImportedInfo: dataImportedInfo
});
const initialState = {
  AdaptableAlerts: EMPTY_ARRAY,
  AdaptableFlashingCells: {},
  AdaptableFlashingCellsMap: {},
  SystemStatusMessages: EMPTY_ARRAY,
  HighlightedCells: EMPTY_ARRAY,
  HighlightedRows: EMPTY_ARRAY,
  IsValidSmartEditSelection: false,
  SmartEditPreviewInfo: null,
  BulkUpdateValidationResult: {
    IsValid: false,
    Column: null
  },
  BulkUpdatePreviewInfo: null,
  CalculatedColumnErrorMessage: EMPTY_STRING,
  ReportErrorMessage: EMPTY_STRING,
  LastAppliedShortCut: null,
  CachedQueries: EMPTY_ARRAY,
  BulkUpdateValue: EMPTY_STRING,
  SmartEditValue: 1,
  SmartEditOperation: MathOperation.Multiply,
  CellSummaryOperation: CELL_SUMMARY_DEFAULT_OPERATION,
  ProgressIndicator: {
    active: false,
    text: ''
  },
  License: {
    watermark: {
      show: false,
      text: null
    },
    disablePersistence: false
  },
  DataChangeHistory: {
    logs: {},
    currentMode: 'INACTIVE',
    enableTime: null,
    suspendTime: null
  },
  SettingsPanel: {},
  ShowLayoutNotAssociatedObjects: false,
  DashboardRevision: 1,
  IsQuickFilterVisible: true,
  CurrentDataSet: EMPTY_STRING,
  Charting: {
    currentChartModels: [],
    externalChartingDefinitions: []
  },
  Layout: {
    previousGroupedColumns: {}
  },
  DisableDeleteConfirmation: false,
  Export: {
    visualDataExportInProgress: false
  }
};
export const SystemReducer = (state = initialState, action) => {
  var _a;
  let alerts;
  let systemStatusMessages;
  switch (action.type) {
    case SYSTEM_ALERT_ADD:
      {
        const actionTypedAdd = action;
        alerts = [].concat(state.AdaptableAlerts);
        if (alerts.length == actionTypedAdd.maxAlerts) {
          // we have hit the maximum so remove first item (oldest)
          alerts.splice(0, 1);
        }
        alerts.push(actionTypedAdd.alert);
        return Object.assign({}, state, {
          AdaptableAlerts: alerts
        });
      }
    case SYSTEM_ALERT_DELETE:
      {
        const adaptableAlert = action.alert;
        return Object.assign(Object.assign({}, state), {
          AdaptableAlerts: state.AdaptableAlerts.filter(abObject => abObject.Uuid !== adaptableAlert.Uuid)
        });
      }
    case SYSTEM_ALERT_DELETE_ALL:
      {
        return Object.assign({}, state, {
          AdaptableAlerts: []
        });
      }
    case SYSTEM_ALERT_REMOVE_CELL_HIGHLIGHT:
      {
        const adaptableAlert = action.alert;
        return Object.assign(Object.assign({}, state), {
          AdaptableAlerts: state.AdaptableAlerts.map(abObject => {
            if (abObject.Uuid === adaptableAlert.Uuid) {
              // AdaptableAlerts are still linked to the live AlertDefinitions, so we have to decouple them
              const newAbObject = Object.assign({}, abObject);
              newAbObject.alertDefinition = cloneObject(abObject.alertDefinition);
              delete newAbObject.alertDefinition.AlertProperties.HighlightCell;
              return newAbObject;
            }
            return abObject;
          })
        });
      }
    case SYSTEM_ALERT_REMOVE_ROW_HIGHLIGHT:
      {
        const adaptableAlert = action.alert;
        return Object.assign(Object.assign({}, state), {
          AdaptableAlerts: state.AdaptableAlerts.map(abObject => {
            if (abObject.Uuid === adaptableAlert.Uuid) {
              // AdaptableAlerts are still linked to the live AlertDefinitions, so we have to decouple them
              const newAbObject = Object.assign({}, abObject);
              newAbObject.alertDefinition = cloneObject(abObject.alertDefinition);
              delete newAbObject.alertDefinition.AlertProperties.HighlightRow;
              return newAbObject;
            }
            return abObject;
          })
        });
      }
    case SYSTEM_FLASHING_CELL_ADD:
      {
        const {
          flashingCell: FlashingCell
        } = action;
        const {
          rowPrimaryKey
        } = FlashingCell;
        const AdaptableFlashingCells = Object.assign({}, state.AdaptableFlashingCells);
        AdaptableFlashingCells[rowPrimaryKey] = Object.assign({}, AdaptableFlashingCells[rowPrimaryKey]);
        const secondaryIds = Object.keys(FlashingCell.flashColumnIds);
        if (FlashingCell.flashTarget === 'row' || FlashingCell.flashTarget.includes('row')) {
          secondaryIds.push(FLASHING_CELL_ROW_KEY);
        }
        secondaryIds.forEach(COL_ID => {
          AdaptableFlashingCells[rowPrimaryKey][COL_ID] = FlashingCell.Uuid;
        });
        const AdaptableFlashingCellsMap = Object.assign({}, state.AdaptableFlashingCellsMap);
        AdaptableFlashingCellsMap[FlashingCell.Uuid] = FlashingCell;
        return Object.assign({}, state, {
          AdaptableFlashingCells: AdaptableFlashingCells,
          AdaptableFlashingCellsMap: AdaptableFlashingCellsMap
        });
      }
    case SYSTEM_FLASHING_CELL_DELETE:
      {
        const {
          flashingCell: FlashingCell
        } = action;
        const {
          rowPrimaryKey
        } = FlashingCell;
        const AdaptableFlashingCells = Object.assign({}, state.AdaptableFlashingCells);
        const AdaptableFlashingCellsMap = Object.assign({}, state.AdaptableFlashingCellsMap);
        AdaptableFlashingCells[rowPrimaryKey] = Object.assign({}, AdaptableFlashingCells[rowPrimaryKey]);
        const secondaryIds = Object.keys(FlashingCell.flashColumnIds);
        if (FlashingCell.flashTarget === 'row' || FlashingCell.flashTarget.includes('row')) {
          secondaryIds.push(FLASHING_CELL_ROW_KEY);
        }
        secondaryIds.forEach(COL_ID => {
          if (AdaptableFlashingCells[rowPrimaryKey][COL_ID] === FlashingCell.Uuid) {
            delete AdaptableFlashingCells[rowPrimaryKey][COL_ID];
          }
        });
        if (!Object.keys(AdaptableFlashingCells[rowPrimaryKey]).length) {
          delete AdaptableFlashingCells[rowPrimaryKey];
        }
        delete AdaptableFlashingCellsMap[FlashingCell.Uuid];
        return Object.assign(Object.assign({}, state), {
          AdaptableFlashingCells: AdaptableFlashingCells,
          AdaptableFlashingCellsMap: AdaptableFlashingCellsMap
        });
      }
    case SYSTEM_FLASHING_CELL_DELETE_ALL:
      {
        return Object.assign({}, state, {
          AdaptableFlashingCells: {},
          AdaptableFlashingCellsMap: {}
        });
      }
    case SYSTEM_HIGHLIGHT_CELL_ADD:
      {
        const actionTypedAdd = action;
        return Object.assign({}, state, {
          HighlightedCells: [...state.HighlightedCells, actionTypedAdd.cellHighlightInfo]
        });
      }
    case SYSTEM_HIGHLIGHT_CELL_DELETE:
      {
        const actionTypedDelete = action;
        const actionId = `${actionTypedDelete.columnId}-${actionTypedDelete.primaryKeyValue}`;
        return Object.assign({}, state, {
          HighlightedCells: state.HighlightedCells.filter(celHighlightInfo => {
            const id = `${celHighlightInfo.columnId}-${celHighlightInfo.primaryKeyValue}`;
            return id !== actionId;
          })
        });
      }
    case SYSTEM_HIGHLIGHT_CELL_DELETE_ALL:
      {
        return Object.assign({}, state, {
          HighlightedCells: []
        });
      }
    case SYSTEM_HIGHLIGHT_ROW_ADD:
      {
        const actionTypedAdd = action;
        return Object.assign({}, state, {
          HighlightedRows: [...state.HighlightedRows, actionTypedAdd.rowHighlightInfo]
        });
      }
    case SYSTEM_HIGHLIGHT_ROWS_ADD:
      {
        const actionTypedAdd = action;
        const rowHighlightInfos = actionTypedAdd.rowsHighlightInfo.primaryKeyValues.map(primaryKeyValue => {
          return {
            primaryKeyValue,
            highlightStyle: actionTypedAdd.rowsHighlightInfo.highlightStyle,
            timeout: actionTypedAdd.rowsHighlightInfo.timeout
          };
        });
        return Object.assign({}, state, {
          HighlightedRows: [...state.HighlightedRows, ...rowHighlightInfos]
        });
      }
    case SYSTEM_HIGHLIGHT_ROW_DELETE:
      {
        const actionTypedDelete = action;
        const actionId = actionTypedDelete.primaryKeyValue;
        return Object.assign({}, state, {
          HighlightedRows: state.HighlightedRows.filter(rowHighlightInfo => {
            return rowHighlightInfo.primaryKeyValue !== actionId;
          })
        });
      }
    case SYSTEM_HIGHLIGHT_ROWS_DELETE:
      {
        const actionTypedDelete = action;
        return Object.assign({}, state, {
          HighlightedRows: state.HighlightedRows.filter(rowHighlightInfo => {
            return !actionTypedDelete.primaryKeyValues.includes(rowHighlightInfo.primaryKeyValue);
          })
        });
      }
    case SYSTEM_HIGHLIGHT_ROW_DELETE_ALL:
      {
        return Object.assign({}, state, {
          HighlightedRows: []
        });
      }
    case SYSTEM_STATUS_MESSAGE_INFO_ADD:
      {
        const actionTypedAdd = action;
        systemStatusMessages = [].concat(state.SystemStatusMessages);
        if (systemStatusMessages.length == actionTypedAdd.maxSystemStatusMessagesInStore) {
          // we have hit the maximum so remove first item (oldest)
          systemStatusMessages.splice(0, 1);
        }
        systemStatusMessages.push(actionTypedAdd.systemStatusMessageInfo);
        return Object.assign({}, state, {
          SystemStatusMessages: systemStatusMessages
        });
      }
    case SYSTEM_STATUS_MESSAGE_INFO_DELETE_ALL:
      {
        return Object.assign({}, state, {
          SystemStatusMessages: []
        });
      }
    case SYSTEM_SMART_EDIT_CHANGE_VALUE:
      return Object.assign({}, state, {
        SmartEditValue: action.value
      });
    case SYSTEM_SMART_EDIT_CHANGE_OPERATION:
      return Object.assign({}, state, {
        SmartEditOperation: action.smartEditOperation
      });
    case SYSTEM_SMARTEDIT_SET_VALID_SELECTION:
      return Object.assign({}, state, {
        IsValidSmartEditSelection: action.isValidSmartEditSelection
      });
    case SYSTEM_SMARTEDIT_SET_PREVIEW:
      return Object.assign({}, state, {
        SmartEditPreviewInfo: action.smartEditPreviewInfo
      });
    case SYSTEM_BULK_UPDATE_SET_VALID_SELECTION:
      return Object.assign({}, state, {
        BulkUpdateValidationResult: action.bulkUpdateValidationResult
      });
    case SYSTEM_BULK_UPDATE_SET_PREVIEW:
      return Object.assign({}, state, {
        BulkUpdatePreviewInfo: action.bulkUpdatePreviewInfo
      });
    case SYSTEM_BULK_UPDATE_CHANGE_VALUE:
      return Object.assign({}, state, {
        BulkUpdateValue: action.bulkUpdateValue
      });
    case SYSTEM_SET_LAST_APPLIED_SHORTCUT:
      {
        return Object.assign({}, state, {
          LastAppliedShortCut: action.gridCell
        });
      }
    case SYSTEM_CACHED_QUERY_ADD:
      {
        const actionTypedAdd = action;
        let cachedQueries = [].concat(state.CachedQueries);
        let queryToAdd = actionTypedAdd.cachedQuery;
        if (queryToAdd) {
          if (cachedQueries.length == 5) {
            // we have hit the maximum so remove first item (oldest)
            cachedQueries.splice(0, 1);
          }
          cachedQueries.push(actionTypedAdd.cachedQuery);
        }
        return Object.assign({}, state, {
          CachedQueries: cachedQueries
        });
      }
    case SYSTEM_CELL_SUMMARY_CHANGE_OPERATION:
      return Object.assign({}, state, {
        CellSummaryOperation: action.cellSummaryOperation
      });
    case SYSTEM_PROGRESS_INDICATOR_SHOW:
      {
        const actionTypedAdd = action;
        const updatedState = {
          ProgressIndicator: {
            active: true,
            text: actionTypedAdd.progressIndicatorConfig.text,
            render: actionTypedAdd.progressIndicatorConfig.render,
            frameworkComponent: actionTypedAdd.progressIndicatorConfig.frameworkComponent
          }
        };
        return Object.assign({}, state, updatedState);
      }
    case SYSTEM_PROGRESS_INDICATOR_HIDE:
      {
        const updatedState = {
          ProgressIndicator: {
            active: false
          }
        };
        return Object.assign({}, state, updatedState);
      }
    case SYSTEM_LICENSE_SHOW_WATERMARK:
      {
        return Object.assign(Object.assign({}, state), {
          License: Object.assign(Object.assign({}, state.License), {
            watermark: {
              show: true,
              text: action.text
            }
          })
        });
      }
    case SYSTEM_LICENSE_DISABLE_PERSISTENCE:
      {
        return Object.assign(Object.assign({}, state), {
          License: Object.assign(Object.assign({}, state.License), {
            disablePersistence: true
          })
        });
      }
    case SYSTEM_DATA_CHANGE_HISTORY_ADD:
      {
        const actionTypedAdd = action;
        const cellDataChangedInfo = actionTypedAdd.changeInfo;
        const uniqueKey = actionTypedAdd.uniqueKey;
        const updatedDataChangeHistoryLogs = Object.assign({}, state.DataChangeHistory.logs);
        updatedDataChangeHistoryLogs[uniqueKey] = cellDataChangedInfo;
        return Object.assign(Object.assign({}, state), {
          DataChangeHistory: Object.assign(Object.assign({}, state.DataChangeHistory), {
            logs: updatedDataChangeHistoryLogs
          })
        });
      }
    case SYSTEM_DATA_CHANGE_HISTORY_UNDO:
      {
        const actionTypedUndo = action;
        const cellDataChangedInfo = actionTypedUndo.changeInfo;
        const uniqueKey = actionTypedUndo.uniqueKey;
        const updatedDataChangeHistoryLogs = Object.assign({}, state.DataChangeHistory.logs);
        delete updatedDataChangeHistoryLogs[uniqueKey];
        return Object.assign(Object.assign({}, state), {
          DataChangeHistory: Object.assign(Object.assign({}, state.DataChangeHistory), {
            logs: updatedDataChangeHistoryLogs
          })
        });
      }
    case SYSTEM_DATA_CHANGE_HISTORY_CLEAR_ROW:
      {
        const actionTypedClearRow = action;
        const cellDataChangedInfo = actionTypedClearRow.changeInfo;
        const uniqueKey = actionTypedClearRow.uniqueKey;
        const updatedDataChangeHistoryLogs = Object.assign({}, state.DataChangeHistory.logs);
        delete updatedDataChangeHistoryLogs[uniqueKey];
        return Object.assign(Object.assign({}, state), {
          DataChangeHistory: Object.assign(Object.assign({}, state.DataChangeHistory), {
            logs: updatedDataChangeHistoryLogs
          })
        });
      }
    case SYSTEM_DATA_CHANGE_HISTORY_ENABLE:
      {
        return Object.assign(Object.assign({}, state), {
          DataChangeHistory: Object.assign(Object.assign({}, state.DataChangeHistory), {
            currentMode: 'ACTIVE',
            enableTime: new Date(),
            suspendTime: null
          })
        });
      }
    case SYSTEM_DATA_CHANGE_HISTORY_DISABLE:
      {
        return Object.assign(Object.assign({}, state), {
          DataChangeHistory: Object.assign(Object.assign({}, state.DataChangeHistory), {
            logs: {},
            currentMode: 'INACTIVE',
            enableTime: null,
            suspendTime: null
          })
        });
      }
    case SYSTEM_DATA_CHANGE_HISTORY_SUSPEND:
      {
        return Object.assign(Object.assign({}, state), {
          DataChangeHistory: Object.assign(Object.assign({}, state.DataChangeHistory), {
            currentMode: 'SUSPENDED',
            suspendTime: new Date()
          })
        });
      }
    case SYSTEM_DATA_CHANGE_HISTORY_RESUME:
      {
        return Object.assign(Object.assign({}, state), {
          DataChangeHistory: Object.assign(Object.assign({}, state.DataChangeHistory), {
            currentMode: 'ACTIVE',
            suspendTime: null
          })
        });
      }
    case SYSTEM_SETTINGS_PANEL_SET:
      {
        const setSettingsAction = action;
        return Object.assign(Object.assign({}, state), {
          SettingsPanel: Object.assign(Object.assign({}, state === null || state === void 0 ? void 0 : state.SettingsPanel), {
            [setSettingsAction.name]: setSettingsAction.settings
          })
        });
      }
    case SYSTEM_QUICK_FILTER_BAR_SHOW:
      return Object.assign({}, state, {
        IsQuickFilterVisible: true
      });
    case SYSTEM_QUICK_FILTER_BAR_HIDE:
      return Object.assign({}, state, {
        IsQuickFilterVisible: false
      });
    case SYSTEM_LAYOUT_SHOW_NOT_ASSOCIATED_OBJECTS:
      {
        const typedAction = action;
        return Object.assign(Object.assign({}, state), {
          ShowLayoutNotAssociatedObjects: typedAction.showNotAssociatedObjects
        });
      }
    case SYSTEM_DATA_SET_SELECT:
      return Object.assign({}, state, {
        CurrentDataSet: action.dataSet.name
      });
    case DASHBOARD_REFRESH:
      {
        return Object.assign(Object.assign({}, state), {
          DashboardRevision: state.DashboardRevision + 1
        });
      }
    case SYSTEM_CHARTING_SET_CURRENT_CHART_MODELS:
      {
        const typedAction = action;
        return Object.assign(Object.assign({}, state), {
          Charting: Object.assign(Object.assign({}, state.Charting), {
            currentChartModels: typedAction.chartModels
          })
        });
      }
    case SYSTEM_SET_PREVIOUS_GROUPED_COLUMN_INDEX:
      {
        const typedAction = action;
        const newLayoutMap = Object.assign({}, state.Layout.previousGroupedColumns[typedAction.layoutId]);
        if (typedAction.columnIndex !== null) {
          newLayoutMap[typedAction.columnId] = typedAction.columnIndex;
        } else if (newLayoutMap[typedAction.columnId] !== undefined) {
          newLayoutMap === null || newLayoutMap === void 0 ? true : delete newLayoutMap[typedAction.columnId];
        }
        const previousGroupedColumns = {
          [typedAction.layoutId]: newLayoutMap
        };
        return Object.assign(Object.assign({}, state), {
          Layout: Object.assign(Object.assign({}, state.Layout), {
            previousGroupedColumns
          })
        });
      }
    case SYSTEM_DISABLE_DELETE_CONFIRMATION:
      {
        return Object.assign(Object.assign({}, state), {
          DisableDeleteConfirmation: true
        });
      }
    case SYSTEM_VISUAL_EXPORT_BEGIN:
      {
        return Object.assign(Object.assign({}, state), {
          Export: {
            visualDataExportInProgress: true
          }
        });
      }
    case SYSTEM_VISUAL_EXPORT_END:
      {
        return Object.assign(Object.assign({}, state), {
          Export: {
            visualDataExportInProgress: false
          }
        });
      }
    case SYSTEM_SET_QUICK_SEARCH_FLOATING_VISIBILITY:
      {
        const typedAction = action;
        return Object.assign(Object.assign({}, state), {
          QuickSearch: Object.assign(Object.assign({}, state.QuickSearch), {
            floatingVisible: typedAction.visible
          })
        });
      }
    case SYSTEM_CELL_POPUP_SHOW:
      {
        const typedAction = action;
        // reuse address refrence if we are editing the same cell
        let newAddress = typedAction === null || typedAction === void 0 ? void 0 : typedAction.cellPosition;
        const oldAddress = (_a = state === null || state === void 0 ? void 0 : state.NotesAndComments) === null || _a === void 0 ? void 0 : _a.popupPosition;
        if (CellPopupService.isSameAddress(oldAddress, typedAction.cellPosition)) {
          newAddress = oldAddress;
          // if edit mode is the same, just exist
          if (typedAction.editMode === state.NotesAndComments.editMode) {
            return state;
          }
        }
        return Object.assign(Object.assign({}, state), {
          NotesAndComments: Object.assign(Object.assign({}, state.NotesAndComments), {
            editMode: typedAction.editMode,
            popupPosition: newAddress
          })
        });
      }
    case SYSTEM_CELL_POPUP_EDIT_FOCUSED_ENTITY:
      {
        const typedAction = action;
        return Object.assign(Object.assign({}, state), {
          NotesAndComments: Object.assign(Object.assign({}, state.NotesAndComments), {
            focusedEntity: typedAction.focusedEntity
          })
        });
      }
    case SYSTEM_CELL_POPUP_HIDE:
      {
        return Object.assign(Object.assign({}, state), {
          NotesAndComments: {
            editMode: false,
            popupPosition: null
          }
        });
      }
    case SYSTEM_SUMMARY_ROW_SET:
      {
        const typedAction = action;
        return Object.assign(Object.assign({}, state), {
          RowSummary: {
            rowSummaries: typedAction.rowSummaries
          }
        });
      }
    default:
      return state;
  }
};