import * as React from 'react';
import { Box, Flex } from 'rebass';
import { CheckBox } from '../../../../components/CheckBox';
import DropdownButton from '../../../../components/DropdownButton';
import HelpBlock from '../../../../components/HelpBlock';
import { Icon } from '../../../../components/icons';
import { DataSource, InfiniteTable } from '../../../../components/InfiniteTable';
import { Tabs } from '../../../../components/Tabs';
import { Tag } from '../../../../components/Tag';
import { useAdaptable } from '../../../AdaptableContext';
import { AdaptablePopover } from '../../../AdaptablePopover';
import UIHelper from '../../../UIHelper';
const tableDOMProps = {
  style: {
    height: '100%',
    minWidth: '10rem',
    minHeight: 300,
    flex: 1
  }
};
export const ColumnsSection = props => {
  const {
    api
  } = useAdaptable();
  const primaryKey = api.optionsApi.getPrimaryKey();
  const allColumns = React.useMemo(() => {
    return api.columnApi.getColumns().filter(col => col.field).map(column => ({
      label: column.friendlyName,
      value: column
    }));
  }, []);
  const handleColumnMapChange = newColMap => {
    var _a;
    props.onColumnsChange((_a = props.columnsMap) === null || _a === void 0 ? void 0 : _a.map(cm => {
      if (cm.field === newColMap.field) {
        return Object.assign(Object.assign({}, cm), newColMap);
      }
      return cm;
    }));
  };
  const columns = React.useMemo(() => {
    return {
      include: {
        field: 'include',
        header: 'Included',
        maxWidth: 110,
        align: 'center',
        render: params => {
          var _a, _b;
          return React.createElement(CheckBox, {
            disabled: ((_b = (_a = params.data) === null || _a === void 0 ? void 0 : _a.abColumn) === null || _b === void 0 ? void 0 : _b.field) === primaryKey,
            onChange: checked => {
              handleColumnMapChange(Object.assign(Object.assign({}, params.data), {
                include: checked
              }));
            },
            checked: Boolean(params.data.include)
          });
        }
      },
      field: {
        field: 'field',
        header: 'Data Field'
      },
      abColumn: {
        header: 'AdapTable Column',
        render: params => {
          var _a, _b, _c, _d, _e, _f;
          // filter out used columns
          const availableColumns = allColumns.filter(c => {
            var _a;
            return !((_a = props.columnsMap) === null || _a === void 0 ? void 0 : _a.find(cm => cm.abColumn === c.value));
          }).map(col => {
            return Object.assign(Object.assign({}, col), {
              onClick: () => {
                handleColumnMapChange(Object.assign(Object.assign({}, params.data), {
                  abColumn: col.value,
                  include: true
                }));
              }
            });
          });
          return React.createElement(DropdownButton, {
            showClearButton: true,
            onClear: () => {
              handleColumnMapChange(Object.assign(Object.assign({}, params.data), {
                abColumn: null,
                include: false
              }));
            },
            width: "100%",
            columns: ['label'],
            items: availableColumns
          }, (_f = (_c = (_b = (_a = params.data) === null || _a === void 0 ? void 0 : _a.abColumn) === null || _b === void 0 ? void 0 : _b.friendlyName) !== null && _c !== void 0 ? _c : (_e = (_d = params.data) === null || _d === void 0 ? void 0 : _d.abColumn) === null || _e === void 0 ? void 0 : _e.columnId) !== null && _f !== void 0 ? _f : 'Select Column');
        }
      },
      valid: {
        header: 'Valid',
        maxWidth: 100,
        minWidth: 90,
        align: 'center',
        render: row => {
          if (!row.data.abColumn) {
            return React.createElement(AdaptablePopover, {
              popoverMinWidth: 200,
              showEvent: "mouseenter",
              hideEvent: "mouseleave",
              headerText: 'Validation Error',
              bodyText: ['Select Column'],
              MessageType: 'Error'
            });
          }
          const successColor = UIHelper.getColorByMessageType('Success');
          return React.createElement(Icon, {
            name: "check",
            style: {
              color: successColor,
              fill: 'currentColor'
            }
          });
        }
      }
    };
  }, [props.columnsMap]);
  if (!props.columnsMap) {
    return React.createElement(Box, {
      p: 2
    }, React.createElement(HelpBlock, {
      color: 'var(--ab-color-error)'
    }, "No Data has been Imported. Go to ", React.createElement(Tag, null, "Upload"), " step and provide Data"));
  }
  return React.createElement(Flex, {
    flexDirection: "column",
    p: 2,
    height: "100%"
  }, React.createElement(Tabs, {
    mb: 2,
    height: "100%"
  }, React.createElement(Tabs.Tab, null, "Columns"), React.createElement(Tabs.Content, null, React.createElement(DataSource, {
    data: props.columnsMap,
    primaryKey: 'field'
  }, React.createElement(InfiniteTable, {
    columnPinning: {
      valid: 'end',
      type: 'end'
    },
    columnTypes: {
      default: {
        minWidth: 100,
        defaultFlex: 1
      }
    },
    domProps: tableDOMProps,
    columns: columns
  })))));
};
ColumnsSection.displayName = 'ColumnsSection';