import { VersionUpgrade } from './VersionUpgrade';
import { updateSingleToMultiplePredicates } from '../Strategy/Utilities/updateSingleToMultiplePredicates';
import ArrayExtensions from '../Utilities/Extensions/ArrayExtensions';
import ObjectFactory from '../Utilities/ObjectFactory';
import StringExtensions from '../Utilities/Extensions/StringExtensions';
export class VersionUpgrade17 extends VersionUpgrade {
  migrateState(state) {
    return this.migrateStateWithMethods(state, [this.migrateCalculatedColumnState, this.migrateAlertState, this.migrateToolPanelState, this.migrateStyledColumnState, this.migrateNamedQueryState, this.migrateGridFilterState, this.migrateFreeTextColumnState, this.migrateFormatColumnState, this.migrateFlashinCellState, this.migrateDashboardState, this.migrateColumnFilterState, this.migrateChartingState]);
  }
  migrateChartingState(state) {
    var _a;
    // const allChartDefinitions = this.api.chartingApi.getChartDefinitions();
    const allChartDefinitions = (_a = state.Charting) === null || _a === void 0 ? void 0 : _a.ChartDefinitions;
    if (Array.isArray(allChartDefinitions)) {
      allChartDefinitions.filter(chartDefinition => 'model' in chartDefinition || !('Name' in chartDefinition)).forEach(charDefinition => {
        if ('model' in charDefinition) {
          charDefinition.Model = charDefinition.model;
          delete charDefinition.model;
        }
        if (!('Name' in charDefinition)) {
          // default to chartId
          // @ts-ignore possible because First iteration had no 'Name' and model was under 'model' key (lowercase)
          charDefinition.Name = charDefinition.Model.chartId;
        }
      });
    }
    return state;
  }
  migrateColumnFilterState(state) {
    var _a, _b, _c, _d, _e;
    // Update Module Buttons
    const moduleButtons = (_a = state.Dashboard) === null || _a === void 0 ? void 0 : _a.ModuleButtons;
    if ((moduleButtons === null || moduleButtons === void 0 ? void 0 : moduleButtons.length) && !moduleButtons.includes('ColumnFilter')) {
      const dashboardState = (_b = state.Dashboard) !== null && _b !== void 0 ? _b : {};
      dashboardState.ModuleButtons = moduleButtons.map(moduleButton => {
        if (moduleButton == 'Filter') {
          return 'ColumnFilter';
        }
        return moduleButton;
      });
    }
    // Update Toolbars
    const tabs = (_c = state.Dashboard) === null || _c === void 0 ? void 0 : _c.Tabs;
    if (Array.isArray(tabs)) {
      state.Dashboard.Tabs = tabs.map(tab => {
        if (tab.Toolbars.includes('Filter') && !tab.Toolbars.includes('ColumnFilter')) {
          return Object.assign(Object.assign({}, tab), {
            // replace with 'GridFilter'
            Toolbars: tab.Toolbars.map(t => t == 'Filter' ? 'ColumnFilter' : t)
          });
        }
        return tab;
      });
    }
    // - move pinned toolbars
    const pinnedToolbars = (_d = state.Dashboard) === null || _d === void 0 ? void 0 : _d.PinnedToolbars;
    if (pinnedToolbars && !(pinnedToolbars === null || pinnedToolbars === void 0 ? void 0 : pinnedToolbars.includes('ColumnFilter'))) {
      state.Dashboard.PinnedToolbars = pinnedToolbars.map(pinnedToolbar => {
        if (pinnedToolbar == 'Filter') {
          return 'ColumnFilter';
        }
        return pinnedToolbar;
      });
    }
    // Update Status Bar
    const statusBars = (_e = state.StatusBar) === null || _e === void 0 ? void 0 : _e.StatusBars;
    if (statusBars) {
      state.StatusBar.StatusBars = statusBars.map(panel => {
        return Object.assign(Object.assign({}, panel), {
          StatusBarPanels: panel.StatusBarPanels.map(panel => {
            if (panel === 'Filter') {
              return 'ColumnFilter';
            }
            return panel;
          })
        });
      });
    }
    return state;
  }
  migrateDashboardState(state) {
    // let dashboardState: any = this.api.dashboardApi.getDashboardState();
    let dashboardState = state.Dashboard;
    if (dashboardState === null || dashboardState === void 0 ? void 0 : dashboardState.VisibleButtons) {
      this.logger.warn(`Updating Obsolete VisibleButtons Config for DashboardState: [${dashboardState.VisibleButtons}]`);
      state.Dashboard.ModuleButtons = [...state.Dashboard.ModuleButtons, ...dashboardState.VisibleButtons];
      //@ts-ignore delete as no longer needed
      delete state.Dashboard.VisibleButtons;
    }
    return state;
  }
  migrateFlashinCellState(state) {
    const flashingCellState = state.FlashingCell;
    if (Array.isArray(flashingCellState === null || flashingCellState === void 0 ? void 0 : flashingCellState.FlashingCellDefinitions)) {
      flashingCellState.FlashingCellDefinitions.forEach(flashingCellDefinition => {
        if (flashingCellDefinition.Rule && 'Predicate' in flashingCellDefinition.Rule) {
          updateSingleToMultiplePredicates(flashingCellDefinition.Rule);
        }
      });
    }
    return state;
  }
  migrateFormatColumnState(state) {
    var _a, _b, _c, _d;
    // const formatColumns = this.api.formatColumnApi.getFormatColumns();
    const formatColumns = ((_a = state.FormatColumn) === null || _a === void 0 ? void 0 : _a.FormatColumns) || [];
    formatColumns.forEach(formatColumn => {
      if (formatColumn.Rule && 'Predicate' in formatColumn.Rule) {
        //@ts-ignore ignore
        updateSingleToMultiplePredicates(formatColumn.Rule);
      }
    });
    // update ConditionalStyle StatusBarPanel
    // const oldStatusBars = this.api.internalApi.getState().StatusBar.StatusBars;
    const statusBars = ((_b = state.StatusBar) === null || _b === void 0 ? void 0 : _b.StatusBars) || [];
    let replaceConditionalStyleStatusBar = false;
    statusBars.forEach(statusBar => Object.assign(Object.assign({}, statusBar), {
      StatusBarPanels: [...new Set(statusBar.StatusBarPanels.map(statusBarPanel => {
        if (statusBarPanel === 'ConditionalStyle') {
          replaceConditionalStyleStatusBar = true;
          return 'FormatColumn';
        }
        return statusBarPanel;
      }))]
    }));
    // update ConditionalStyle dashboard buttons
    // const dashboardModuleButtons = this.api.internalApi.getState().Dashboard.ModuleButtons as any;
    const dashboardModuleButtons = (_c = state.Dashboard) === null || _c === void 0 ? void 0 : _c.ModuleButtons;
    if (dashboardModuleButtons && dashboardModuleButtons.includes('ConditionalStyle')) {
      dashboardModuleButtons[dashboardModuleButtons.indexOf('ConditionalStyle')] = 'FormatColumn';
    }
    // update ConditionalStyle toolPanel buttons
    // const toolPanelModuleButtons = this.api.internalApi.getState().ToolPanel.ModuleButtons as any;
    const toolPanelModuleButtons = (_d = state.ToolPanel) === null || _d === void 0 ? void 0 : _d.ModuleButtons;
    if (toolPanelModuleButtons && toolPanelModuleButtons.includes('ConditionalStyle')) {
      toolPanelModuleButtons[toolPanelModuleButtons.indexOf('ConditionalStyle')] = 'FormatColumn';
    }
    return state;
  }
  migrateFreeTextColumnState(state) {
    var _a;
    // make sure all default to type string
    const allFreeTextColumns = ((_a = state.FreeTextColumn) === null || _a === void 0 ? void 0 : _a.FreeTextColumns) || [];
    for (const freeTextColumn of allFreeTextColumns) {
      const unTypedCol = freeTextColumn;
      if (unTypedCol.DataType) {
        const dataType = unTypedCol.DataType;
        delete unTypedCol['DataType'];
        if (!freeTextColumn.FreeTextColumnSettings) {
          freeTextColumn.FreeTextColumnSettings = {
            DataType: dataType
          };
        } else {
          freeTextColumn.FreeTextColumnSettings.DataType = dataType;
        }
      }
      if (!freeTextColumn.FreeTextColumnSettings) {
        freeTextColumn.FreeTextColumnSettings = {
          DataType: 'String'
        };
      }
      if (!freeTextColumn.FreeTextColumnSettings.DataType) {
        freeTextColumn.FreeTextColumnSettings.DataType = 'String';
      }
    }
    return state;
  }
  migrateGridFilterState(state) {
    var _a, _b;
    const oldQueryState = state.Query;
    if (!oldQueryState) {
      return state;
    }
    // - move query to current layout
    const expression = oldQueryState.CurrentQuery;
    const currentLayout = (_a = state.Layout.Layouts) === null || _a === void 0 ? void 0 : _a.find(l => l.Name === state.Layout.CurrentLayout);
    const currentGridFilter = currentLayout === null || currentLayout === void 0 ? void 0 : currentLayout.GridFilter;
    const currentGridFilterExpression = currentGridFilter && !currentGridFilter.IsSuspended ? currentGridFilter.Expression || '' : '';
    if (expression && StringExtensions.IsNullOrEmpty(currentGridFilterExpression)) {
      currentLayout.GridFilter = Object.assign(Object.assign({}, currentGridFilter), {
        Expression: expression
      });
    }
    // - move dashboard/toolbar
    (_b = state.Dashboard.Tabs) === null || _b === void 0 ? void 0 : _b.forEach(tab => {
      if (tab.Toolbars.includes('Query')) {
        tab.Toolbars = tab.Toolbars.map(t => t == 'Query' ? 'GridFilter' : t);
      }
    });
    // - move pinned toolbars
    let updatePinnedToolbars = false;
    if (state.Dashboard.PinnedToolbars) {
      state.Dashboard.PinnedToolbars = state.Dashboard.PinnedToolbars.map(pinnedToolbar => {
        if (pinnedToolbar == 'Query') {
          return 'GridFilter';
        }
        return pinnedToolbar;
      });
    }
    return state;
  }
  migrateNamedQueryState(state) {
    // - move named queries from old Query State to this Module
    // Note: GridFilter Module moves the "old" CurrentQuery into Layout
    // const oldQueryState: QueryState = this.api.internalApi.getAdaptableState().Query;
    const oldQueryState = state.Query;
    const oldNamedQueries = oldQueryState === null || oldQueryState === void 0 ? void 0 : oldQueryState.NamedQueries;
    if (ArrayExtensions.IsNotNullOrEmpty(oldNamedQueries)) {
      const currentNamedQueries = state.NamedQuery.NamedQueries;
      oldNamedQueries.forEach(oldNamedQuery => {
        var _a, _b;
        const currentQuery = currentNamedQueries.find(q => q.Name === oldNamedQuery.Name);
        if (!currentQuery) {
          (_b = (_a = state.NamedQuery) === null || _a === void 0 ? void 0 : _a.NamedQueries) === null || _b === void 0 ? void 0 : _b.push(oldNamedQuery);
        }
      });
    }
    if (oldQueryState) {
      delete state.Query;
    }
    return state;
  }
  migrateStyledColumnState(state) {
    var _a, _b, _c, _d;
    var _e;
    const formatColumnsWithColumnStyles = ((_b = (_a = state.FormatColumn) === null || _a === void 0 ? void 0 : _a.FormatColumns) === null || _b === void 0 ? void 0 : _b.filter(fc => fc.ColumnStyle)) || [];
    if (!formatColumnsWithColumnStyles.length) {
      return state;
    }
    this.logger.info(`Converting ${formatColumnsWithColumnStyles.length} FormatColumns Styles to Styled Columns`, formatColumnsWithColumnStyles);
    // delete the format columns with column styles
    state.FormatColumn.FormatColumns = (_c = state.FormatColumn.FormatColumns) === null || _c === void 0 ? void 0 : _c.filter(fc => !fc.ColumnStyle);
    const styledColumns = formatColumnsWithColumnStyles.map(formatColumn => {
      if (!('ColumnIds' in formatColumn.Scope)) {
        return null;
      }
      return Object.assign(Object.assign(Object.assign({}, ObjectFactory.CreateEmptyStyledColumn()), {
        ColumnId: formatColumn.Scope.ColumnIds[0]
      }), formatColumn.ColumnStyle);
    }).filter(Boolean);
    (_d = (_e = state.StyledColumn).StyledColumns) !== null && _d !== void 0 ? _d : _e.StyledColumns = [];
    styledColumns.forEach(styledColumn => {
      state.StyledColumn.StyledColumns.push(styledColumn);
    });
    return state;
  }
  migrateToolPanelState(state) {
    var _a;
    // const deprecatedToolPanelConfigs: AdaptableToolPanels | undefined = (
    //   this.api.toolPanelApi.getToolPanelState() as any
    // ).VisibleToolPanels;
    const deprecatedToolPanelConfigs = (_a = state.ToolPanel) === null || _a === void 0 ? void 0 : _a.VisibleToolPanels;
    if (deprecatedToolPanelConfigs === null || deprecatedToolPanelConfigs === void 0 ? void 0 : deprecatedToolPanelConfigs.length) {
      this.logger.warn(`Updating Obsolete VisibleToolPanels Config for ToolPanelState: [${deprecatedToolPanelConfigs}]`);
      const migratedToolPanelConfigs = deprecatedToolPanelConfigs.map(toolPanel => ({
        Name: toolPanel,
        State: 'collapsed'
      }));
      state.ToolPanel.ToolPanels = migratedToolPanelConfigs;
    }
    return state;
  }
  migrateAlertState(state) {
    var _a, _b, _c, _d, _e;
    (_b = (_a = state.Alert) === null || _a === void 0 ? void 0 : _a.AlertDefinitions) === null || _b === void 0 ? void 0 : _b.forEach(alertDefinition => {
      if (alertDefinition.Rule && 'Predicate' in alertDefinition.Rule) {
        updateSingleToMultiplePredicates(alertDefinition.Rule);
      }
    });
    const alertState = state.Alert;
    if (ArrayExtensions.IsEmpty((_c = state.FlashingCell) === null || _c === void 0 ? void 0 : _c.FlashingCellDefinitions) && ArrayExtensions.IsNotNullOrEmpty(alertState === null || alertState === void 0 ? void 0 : alertState.FlashingAlertDefinitions)) {
      state.FlashingCell.FlashingCellDefinitions = alertState.FlashingAlertDefinitions;
      //@ts-ignore delete as no longer needed
      delete state.Alert.FlashingAlertDefinitions;
    }
    (_e = (_d = state.Alert) === null || _d === void 0 ? void 0 : _d.AlertDefinitions) === null || _e === void 0 ? void 0 : _e.forEach(alertDefinition => {
      // if ShowPopup then change to DisplayNotificate
      if (alertDefinition.AlertProperties && alertDefinition.AlertProperties.ShowPopup && alertDefinition.AlertProperties.ShowPopup == true && !alertDefinition.AlertProperties.DisplayNotification) {
        alertDefinition.AlertProperties.DisplayNotification = true;
        alertDefinition.AlertProperties.ShowPopup = undefined;
      }
      // if no rule but a predicate then use that
      if (!alertDefinition.Rule) {
        if (alertDefinition.Predicate && alertDefinition.Predicate != undefined) {
          const predicate = alertDefinition.Predicate;
          alertDefinition.Rule = {
            Predicate: predicate
          };
          alertDefinition.Predicate = undefined;
        }
      } else {
        //migrate alert definitions with `AggregationExpression` (now `AggregatedBooleanExpression`)
        const obsoleteAggregationExpression = alertDefinition.Rule['AggregationExpression'];
        if (obsoleteAggregationExpression) {
          alertDefinition.Rule.AggregatedBooleanExpression = obsoleteAggregationExpression;
        }
      }
    });
    return state;
  }
  migrateCalculatedColumnState(state) {
    var _a, _b;
    (_b = (_a = state.CalculatedColumn) === null || _a === void 0 ? void 0 : _a.CalculatedColumns) === null || _b === void 0 ? void 0 : _b.forEach(cc => {
      if (cc.ColumnExpression && !cc.Query) {
        cc.Query = {
          ScalarExpression: cc.ColumnExpression
        };
        cc.ColumnExpression = undefined;
      }
      if (!cc.CalculatedColumnSettings) {
        cc.CalculatedColumnSettings = {
          DataType: 'Number'
        };
        this.logger.warn(`Updating incorrect Predefined Config for Calculated Column: ${cc.ColumnId}`);
      }
    });
    return state;
  }
  migrateUserState(state) {
    return state;
  }
}