import { StringExtensions } from '../Extensions/StringExtensions';
export function GetPreviewValidationSummary(previewResults) {
  let hasAnyValidation = false;
  let hasAllValidation = true;
  previewResults.forEach(previewResult => {
    const hasValidationErrors = previewResult.validationRules.length > 0;
    if (hasValidationErrors) {
      hasAnyValidation = true;
    } else {
      hasAllValidation = false;
    }
  });
  return {
    validationResult: hasAnyValidation ? hasAllValidation ? 'All' : 'Some' : 'None'
  };
}
export function GetValidationMessage(previewInfo, newValue) {
  if (previewInfo && StringExtensions.IsNotNullOrEmpty(newValue)) {
    let previewValidationSummary = previewInfo.previewValidationSummary;
    if (previewValidationSummary.validationResult == 'All') {
      return 'All proposed edits have failed validation (see Preview for details).';
    } else if (previewValidationSummary.validationResult == 'Some') {
      return 'Some proposed edits have failed validatio (see Preview for details).\nThese updates will be ignored.';
    }
  }
  return '';
}
export function GetCellInfosFromPreview(previewInfo, bypassValidationWarnings) {
  let newValues = [];
  if (bypassValidationWarnings) {
    for (let previewResult of previewInfo.previewResults) {
      if (previewResult.validationRules.length == 0) {
        newValues.push({
          primaryKeyValue: previewResult.id,
          rawValue: previewResult.computedValue,
          displayValue: previewResult.computedValue,
          normalisedValue: previewResult.computedValue,
          rowNode: previewResult.rowNode,
          column: previewInfo.column,
          isPivotCell: false,
          isRowGroupCell: false
        });
      }
    }
  } else {
    previewInfo.previewResults.filter(p => p.validationRules.length == 0).forEach(pr => {
      newValues.push({
        primaryKeyValue: pr.id,
        column: previewInfo.column,
        rawValue: pr.computedValue,
        displayValue: pr.computedValue,
        normalisedValue: pr.computedValue,
        rowNode: pr.rowNode,
        isPivotCell: false,
        isRowGroupCell: false
      });
    });
  }
  return newValues;
}
export function GetCellUpdateRequestsFromPreview(previewInfo, bypassValidationWarnings) {
  let newValues = [];
  if (bypassValidationWarnings) {
    for (let previewResult of previewInfo.previewResults) {
      if (previewResult.validationRules.length == 0) {
        newValues.push({
          primaryKeyValue: previewResult.id,
          newValue: previewResult.computedValue,
          rowNode: previewResult.rowNode,
          columnId: previewInfo.column.columnId
        });
      }
    }
  } else {
    previewInfo.previewResults.filter(p => p.validationRules.length == 0).forEach(pr => {
      newValues.push({
        primaryKeyValue: pr.id,
        columnId: previewInfo.column.columnId,
        newValue: pr.computedValue,
        rowNode: pr.rowNode
      });
    });
  }
  return newValues;
}
export const PreviewHelper = {
  GetPreviewValidationSummary,
  GetValidationMessage,
  GetCellInfosFromPreview,
  GetCellUpdateRequestsFromPreview
};
export default PreviewHelper;