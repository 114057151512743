import * as React from 'react';
import { useSelector } from 'react-redux';
import { useAdaptable } from '../AdaptableContext';
import * as SystemRedux from '../../Redux/ActionsReducers/SystemRedux';
export const useAgChartState = chartDefinition => {
  const [chartRef, setChartRef] = React.useState(null);
  const adaptable = useAdaptable();
  const currentChartModels = useSelector(state => SystemRedux.SystemChartingCurrentChartModelsSelector(state.System));
  React.useEffect(() => {
    var _a, _b;
    if (!chartDefinition) {
      setChartRef(null);
      return;
    }
    const currentChartRef = (_b = adaptable.api.chartingApi.getChartRef((_a = chartDefinition === null || chartDefinition === void 0 ? void 0 : chartDefinition.Model) === null || _a === void 0 ? void 0 : _a.chartId)) !== null && _b !== void 0 ? _b : null;
    setChartRef(currentChartRef);
  }, [currentChartModels, chartDefinition]);
  const closeAlreadyOpenedChartsInContainer = element => {
    const chartModelAlreadyInChartContainer = currentChartModels.find(chartModel => {
      const chartRef = adaptable.api.chartingApi.getChartRef(chartModel.chartId);
      return chartRef && element.contains(chartRef.chartElement);
    });
    if (chartModelAlreadyInChartContainer) {
      const chartRef = adaptable.api.chartingApi.getChartRef(chartModelAlreadyInChartContainer.chartId);
      if (chartRef) {
        chartRef.destroyChart();
      }
    }
  };
  const showChart = React.useCallback(chartContainer => {
    if (!adaptable || !chartDefinition) {
      return;
    }
    const element = typeof (chartContainer === null || chartContainer === void 0 ? void 0 : chartContainer.element) === 'string' ? document.querySelector(chartContainer.element) : chartContainer === null || chartContainer === void 0 ? void 0 : chartContainer.element;
    if (!element && typeof (chartContainer === null || chartContainer === void 0 ? void 0 : chartContainer.element) === 'string') {
      adaptable.logger.consoleLogByMessageType(`Chart container element not found: ${chartContainer.element}`, 'Error');
      return;
    }
    const chartRef = adaptable.api.chartingApi.getChartRef(chartDefinition.Model.chartId);
    if (chartRef) {
      // chart alredy opened
      return;
    }
    /**
     * When using a custom container, make sure multiple charts are not opened in the same container.
     * If multple charts are opened in the same contianer, it infinitly adds the charts to the container.
     */
    if (chartContainer && element) {
      if (chartContainer.chartsDisplay !== 'multiple') {
        closeAlreadyOpenedChartsInContainer(element);
      }
      adaptable.api.chartingApi.showChartDefinitionOnce(chartDefinition, element);
    } else {
      adaptable.api.chartingApi.showChartDefinitionOnce(chartDefinition);
    }
  }, [chartDefinition, currentChartModels]);
  const closeChart = React.useCallback(() => {
    if (chartRef) {
      chartRef.destroyChart();
    }
  }, [chartRef]);
  return {
    isOpen: Boolean(chartRef),
    showChart,
    closeChart
  };
};