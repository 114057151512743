import parseISO from 'date-fns/parseISO';
import parse from 'date-fns/parse';
import { DateFormatter } from './FormatHelper';
import { AdaptableLogger } from '../../agGrid/AdaptableLogger';
export const isValidDate = date => {
  return date instanceof Date && !isNaN(date.getTime());
};
/**
 * Checks if the data contains a value that represents a valid date.
 *
 * @param data any kind of data
 */
export const isValueValidDate = data => {
  if (!data || typeof data === 'boolean') {
    return false;
  }
  const date = new Date(data);
  return !isNaN(date.getTime());
};
export const dateToISO = date => {
  return DateFormatter(date, {
    Pattern: 'yyyy-MM-dd'
  });
};
export const parseToISO = (date, dateFormat) => {
  const dateInstance = parseDateValue(date, dateFormat);
  return isValidDate(dateInstance) ? dateToISO(dateInstance) : '';
};
export const parseDateValue = (dateValue, dateFormat) => {
  if (dateValue == undefined || typeof dateValue === 'string' && dateValue.trim() === '') {
    return undefined;
  }
  if (dateValue instanceof Date) {
    return !isNaN(dateValue.getTime()) ? dateValue : undefined;
  }
  let dateInstance;
  if (typeof dateValue === 'number') {
    dateInstance = new Date(dateValue);
  } else {
    // typeof dateValue === 'string'
    if (dateFormat) {
      dateInstance = parse(dateValue, dateFormat, new Date());
    } else {
      dateInstance = parseISO(dateValue);
    }
    if (!isValidDate(dateInstance)) {
      // last chance: try to use the native Date.parse(), https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse
      dateInstance = new Date(Date.parse(dateValue));
    }
  }
  if (!isValidDate(dateInstance)) {
    AdaptableLogger.consoleWarnBase(`Invalid date value "${dateValue}" - cannot be converted to a Date instance. Please make sure you specify adaptableOptions.userInterfaceOptions.dateInputOptions.dateFormat correctly.`);
    dateInstance = undefined;
  }
  return dateInstance;
};
export const parseFilterInputDate = stringDate => new Date(stringDate);