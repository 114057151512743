import * as React from 'react';
import { Box } from 'rebass';
import { Tabs } from '../../../../components/Tabs';
import FormLayout, { FormRow } from '../../../../components/FormLayout';
import Input from '../../../../components/Input';
import { CheckBox } from '../../../../components/CheckBox';
import { Select } from '../../../../components/Select';
const MESSAGE_TYPES = ['Success', 'Info', 'Warning', 'Error'];
export const ScheduleSettingsReminder = props => {
  var _a, _b, _c, _d, _e;
  const messageTypes = MESSAGE_TYPES.map(messageType => ({
    label: messageType,
    value: messageType,
    onClick: () => {
      props.onChange(Object.assign(Object.assign({}, props.reminderSchedule), {
        MessageType: messageType
      }));
    }
  }));
  const handleHeaderChange = event => {
    var _a;
    props.onChange(Object.assign(Object.assign({}, props.reminderSchedule), {
      Header: (_a = event.target) === null || _a === void 0 ? void 0 : _a.value
    }));
  };
  const handleMessageChange = event => {
    var _a;
    props.onChange(Object.assign(Object.assign({}, props.reminderSchedule), {
      Message: (_a = event.target) === null || _a === void 0 ? void 0 : _a.value
    }));
  };
  const handleDisplayNotificationChange = checked => {
    props.onChange(Object.assign(Object.assign({}, props.reminderSchedule), {
      DisplayNotification: checked
    }));
  };
  const handleDisplaySystemStatusChange = checked => {
    props.onChange(Object.assign(Object.assign({}, props.reminderSchedule), {
      DisplaySystemStatusMessage: checked
    }));
  };
  return React.createElement(Box, {
    "data-name": "schedule-settings-remainder"
  }, React.createElement(Tabs, {
    autoFocus: false
  }, React.createElement(Tabs.Tab, null, "Reminder Schedule Settings"), React.createElement(Tabs.Content, null, React.createElement(FormLayout, null, React.createElement(FormRow, {
    label: "Header"
  }, React.createElement(Input, {
    "data-name": "header",
    width: 300,
    onChange: handleHeaderChange,
    placeholder: "Enter Reminder Header",
    type: "string",
    value: (_a = props.reminderSchedule) === null || _a === void 0 ? void 0 : _a.Header
  })), React.createElement(FormRow, {
    label: "Message"
  }, React.createElement(Input, {
    "data-name": "message",
    width: 300,
    onChange: handleMessageChange,
    placeholder: "Enter Reminder Message",
    type: "string",
    value: (_b = props.reminderSchedule) === null || _b === void 0 ? void 0 : _b.Message
  })), React.createElement(FormRow, {
    label: "Type"
  }, React.createElement(Box, {
    maxWidth: 300
  }, React.createElement(Select, {
    "data-name": "message-type",
    placeholder: "Select Option",
    options: messageTypes,
    value: (_c = props.reminderSchedule) === null || _c === void 0 ? void 0 : _c.MessageType,
    onChange: value => {
      props.onChange(Object.assign(Object.assign({}, props.reminderSchedule), {
        MessageType: value
      }));
    }
  }))), React.createElement(FormRow, {
    label: ""
  }, React.createElement(CheckBox, {
    "data-name": "display-notification",
    checked: (_d = props.reminderSchedule) === null || _d === void 0 ? void 0 : _d.DisplayNotification,
    onChange: handleDisplayNotificationChange
  }, "Show the Reminder as a Notification")), React.createElement(FormRow, {
    label: ""
  }, React.createElement(CheckBox, {
    "data-name": "display-system-status",
    checked: (_e = props.reminderSchedule) === null || _e === void 0 ? void 0 : _e.DisplaySystemStatusMessage,
    onChange: handleDisplaySystemStatusChange
  }, "Display a System Status Message"))))));
};