import { ApiBase } from '../Implementation/ApiBase';
export class GridFilterInternalApi extends ApiBase {
  fireGridFilterAppliedEvent() {
    if (this.adaptable.isReady) {
      const currentGridFilter = this.getGridFilterApi().getCurrentGridFilter();
      const adaptableApi = this.getAdaptableApi();
      const gridFilterAppliedInfo = Object.assign({
        gridFilter: currentGridFilter,
        gridFilterExpressionAST: currentGridFilter ? adaptableApi.expressionApi.getASTForExpression(currentGridFilter === null || currentGridFilter === void 0 ? void 0 : currentGridFilter.Expression) : null
      }, this.getAdaptableApi().internalApi.buildBaseContext());
      adaptableApi.eventApi.emit('GridFilterApplied', gridFilterAppliedInfo);
    }
  }
  /**
   * Compares to Grid Filters to see if they are identical
   */
  isGridFilterDifferent(oldFilter, newFilter) {
    return (oldFilter === null || oldFilter === void 0 ? void 0 : oldFilter.Expression) === (newFilter === null || newFilter === void 0 ? void 0 : newFilter.Expression);
  }
}