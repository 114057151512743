import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Flex } from 'rebass';
import SimpleButton from '../../../components/SimpleButton';
import { useAdaptable } from '../../AdaptableContext';
import { ValueOptionsTags } from '../ValueSelector';
const baseClassName = 'ab-Adaptable-Object-Compact-List';
const ICON_SIZE = 20;
export const AdaptableObjectCompactListItem = props => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const dispatch = useDispatch();
  const adaptable = useAdaptable();
  const objectView = props.module.toViewCompact(props.abObject);
  const viewOptions = (_b = (_a = props.module) === null || _a === void 0 ? void 0 : _a.getViewProperties) === null || _b === void 0 ? void 0 : _b.call(_a);
  const deleteAction = (_c = viewOptions === null || viewOptions === void 0 ? void 0 : viewOptions.getCompactDeleteAction) === null || _c === void 0 ? void 0 : _c.call(viewOptions, props.abObject);
  const moduleAccessLevel = adaptable.api.entitlementApi.getEntitlementAccessLevelForModule(props.module.moduleInfo.ModuleName);
  const suspendAction = (_d = viewOptions === null || viewOptions === void 0 ? void 0 : viewOptions.getCompactSuspendAction) === null || _d === void 0 ? void 0 : _d.call(viewOptions, props.abObject);
  const unSuspendAction = (_e = viewOptions === null || viewOptions === void 0 ? void 0 : viewOptions.getCompactUnSuspendAction) === null || _e === void 0 ? void 0 : _e.call(viewOptions, props.abObject);
  const isSuspended = props.abObject.IsSuspended;
  const handleDelete = React.useCallback(event => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(deleteAction);
  }, []);
  const handleSuspendUnSuspend = React.useCallback(event => {
    event.stopPropagation();
    event.preventDefault();
    if (isSuspended) {
      dispatch(unSuspendAction);
    } else {
      dispatch(suspendAction);
    }
  }, [props.abObject]);
  let labelEl = objectView.item.label;
  if (typeof labelEl === 'function') {
    labelEl = React.createElement(labelEl, {
      data: props.abObject
    });
  }
  labelEl = labelEl !== null && labelEl !== void 0 ? labelEl : objectView.item.name;
  return React.createElement(Flex, {
    className: `${baseClassName}__Item`,
    alignItems: "center"
  }, labelEl && React.createElement(Flex, {
    flex: '1 1 auto',
    className: `${baseClassName}__Item__Name`
  }, labelEl), React.createElement(Box, {
    flex: '2 1 auto'
  }, typeof objectView.item.view === 'function' ? React.createElement(objectView.item.view, {
    data: props.abObject
  }) : objectView.item.view, Boolean(((_f = objectView.item) === null || _f === void 0 ? void 0 : _f.values) && ((_h = (_g = objectView.item) === null || _g === void 0 ? void 0 : _g.values) === null || _h === void 0 ? void 0 : _h.length)) && React.createElement(Box, {
    mb: 2,
    className: `${baseClassName}__Item__Values`
  }, React.createElement(ValueOptionsTags, {
    style: {
      marginRight: 0
    },
    readOnly: true,
    options: objectView.item.values,
    value: objectView.item.values,
    allowWrap: true,
    toIdentifier: c => c,
    toLabel: c => React.createElement(React.Fragment, null, c)
  }))), suspendAction && unSuspendAction && React.createElement(SimpleButton, {
    onMouseDown: handleSuspendUnSuspend,
    tone: isSuspended ? 'neutral' : 'success',
    variant: "text",
    icon: isSuspended ? 'resume' : 'pause'
  }), React.createElement(Flex, {
    ml: 1,
    flex: '0 0 auto'
  }, deleteAction && React.createElement(SimpleButton, {
    iconSize: ICON_SIZE,
    icon: "delete",
    variant: "text",
    onMouseDown: handleDelete,
    accessLevel: moduleAccessLevel
  })));
};
export const AdaptableObjectCompactList = props => {
  var _a, _b, _c, _d, _e;
  const adaptable = useAdaptable();
  const viewProperties = (_b = (_a = props.module).getViewProperties) === null || _b === void 0 ? void 0 : _b.call(_a);
  const deleteAllAction = (_c = viewProperties === null || viewProperties === void 0 ? void 0 : viewProperties.getDeleteAllAction) === null || _c === void 0 ? void 0 : _c.call(viewProperties);
  const suspendAllAction = (_d = viewProperties === null || viewProperties === void 0 ? void 0 : viewProperties.getSuspendAllAction) === null || _d === void 0 ? void 0 : _d.call(viewProperties);
  const unSuspendAllAction = (_e = viewProperties === null || viewProperties === void 0 ? void 0 : viewProperties.getUnSuspendAllAction) === null || _e === void 0 ? void 0 : _e.call(viewProperties);
  const dispatch = useDispatch();
  const accessLevel = adaptable.api.entitlementApi.getEntitlementAccessLevelForModule(props.module.moduleInfo.ModuleName);
  const isAtLeastOneAbObjectActive = props.abObjects.some(abObject => {
    return !abObject.IsSuspended;
  });
  const handleSuspendUnsuspendAll = React.useCallback(event => {
    event.stopPropagation();
    event.preventDefault();
    if (isAtLeastOneAbObjectActive) {
      dispatch(suspendAllAction);
    } else {
      dispatch(unSuspendAllAction);
    }
  }, [isAtLeastOneAbObjectActive]);
  return React.createElement(Flex, {
    flexDirection: "column",
    className: baseClassName
  }, React.createElement(Flex, {
    className: `${baseClassName}__Header`,
    fontSize: 3
  }, React.createElement(Flex, {
    className: `${baseClassName}__Title`,
    alignItems: 'center'
  }, props.module.moduleInfo.FriendlyName), React.createElement(Box, {
    flex: 1
  }), suspendAllAction && unSuspendAllAction && React.createElement(SimpleButton, {
    mr: 2,
    onMouseDown: handleSuspendUnsuspendAll,
    tone: isAtLeastOneAbObjectActive ? 'neutral' : 'success',
    variant: "raised",
    icon: isAtLeastOneAbObjectActive ? 'pause' : 'resume',
    accessLevel: accessLevel
  }, isAtLeastOneAbObjectActive ? 'Suspend All' : 'Unsuspend All'), deleteAllAction && React.createElement(SimpleButton, {
    onMouseDown: () => dispatch(deleteAllAction),
    variant: "raised",
    tone: "neutral",
    accessLevel: accessLevel
  }, "Clear All")), React.createElement(Box, {
    className: `${baseClassName}__Body`
  }, props.abObjects.map(abObject => {
    return React.createElement(AdaptableObjectCompactListItem, {
      key: abObject.Uuid,
      abObject: abObject,
      module: props.module
    });
  })));
};