import * as React from 'react';
/// <reference path="../../typings/.d.ts" />
import { PanelWithRow } from '../Panels/PanelWithRow';
import { Box } from 'rebass';
export const AdaptableObjectCollection = props => {
  const allowOverflow = props.allowOverflow ? 'visible' : 'auto';
  return React.createElement(Box, {
    style: Object.assign({}, props.style),
    className: "ab-ObjectCollection"
  }, React.createElement(PanelWithRow, {
    border: "none",
    colItems: props.colItems,
    headerAlign: props.headerAlign
  }), React.createElement("div", {
    className: "ab-ObjectCollection__items",
    style: {
      overflowY: allowOverflow,
      boxShadow: 'none'
    }
  }, props.items));
};