import * as React from 'react';
import { Tabs } from '../../components/Tabs';
import { TagValueOptionsTags, TagValueSelector } from '../Components/TagValueSelector';
import { useOnePageAdaptableWizardContext } from './OnePageAdaptableWizard';
import { Text } from 'rebass';
export const ObjectTagsWizardSection = props => {
  const {
    onChange
  } = props;
  const {
    data
  } = useOnePageAdaptableWizardContext();
  return React.createElement(Tabs, {
    style: {
      height: '100%'
    }
  }, React.createElement(Tabs.Tab, null, "Tags"), React.createElement(Tabs.Content, null, React.createElement(TagValueSelector, {
    selectedTags: data.Tags,
    onChange: selectedTags => onChange(Object.assign(Object.assign({}, data), {
      Tags: selectedTags
    }))
  })));
};
export const renderObjectTagsSummary = data => {
  var _a;
  return ((_a = data.Tags) === null || _a === void 0 ? void 0 : _a.length) ? React.createElement(Text, {
    fontSize: 2
  }, React.createElement(TagValueOptionsTags, {
    tags: data.Tags
  })) : null;
};