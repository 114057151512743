import * as React from 'react';
import { Box } from 'rebass';
import { useAdaptable } from '../AdaptableContext';
import { ThemePopup } from './ThemePopup';
export const ThemeEditorWindow = () => {
  const adaptable = useAdaptable();
  const moduleInfo = adaptable.ModuleService.getModuleInfoByModule('Theme');
  return React.createElement(Box, {
    p: 2
  }, React.createElement(ThemePopup, {
    hideShowInWindow: true,
    moduleInfo: moduleInfo,
    api: adaptable.api
  }));
};