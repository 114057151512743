import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import { createGrid, EventService, GridOptionsService, LocalEventService, ModuleNames } from '@ag-grid-community/core';
import { AdaptableLogger } from './AdaptableLogger';
import { PrimaryKeyDocsLink } from '../Utilities/Constants/DocumentationLinkConstants';
import StringExtensions from '../Utilities/Extensions/StringExtensions';
import Emitter from '../Utilities/Emitter';
import { applyDefaultAdaptableOptions } from './defaultAdaptableOptions';
import { AgGridAdapter } from './AgGridAdapter';
import * as GeneralConstants from '../Utilities/Constants/GeneralConstants';
import { AB_FDC3_COLUMN, AB_SPECIAL_COLUMN, ADAPTABLE_ROW_ACTION_BUTTONS, AG_GRID_GROUPED_COLUMN, AUTOGENERATED_PK_COLUMN, DARK_THEME, DEFAULT_LAYOUT, GROUP_PATH_SEPARATOR, HALF_SECOND, LIGHT_THEME } from '../Utilities/Constants/GeneralConstants';
import { DataService } from '../Utilities/Services/DataService';
import { AdaptableStore } from '../Redux/Store/AdaptableStore';
import { AdaptableApiImpl } from '../Api/Implementation/AdaptableApiImpl';
import { Fdc3Service } from '../Utilities/Services/Fdc3Service';
import { CellPopupService } from '../Utilities/Services/CellPopupService';
import { ChartingService } from '../Utilities/Services/ChartingService';
import { ThemeService } from '../Utilities/Services/ThemeService';
import { ValidationService } from '../Utilities/Services/ValidationService';
import { ModuleService } from '../Utilities/Services/ModuleService';
import { CalculatedColumnExpressionService } from '../Utilities/Services/CalculatedColumnExpressionService';
import { QueryLanguageService } from '../Utilities/Services/QueryLanguageService';
import { AlertService } from '../Utilities/Services/AlertService';
import { TeamSharingService } from '../Utilities/Services/TeamSharingService';
import { MetamodelService } from '../Utilities/Services/MetamodelService';
import { LicenseService } from '../Utilities/Services/LicenseService';
import { ALL_TOOL_PANELS } from '../PredefinedConfig/Common/Types';
import * as ModuleConstants from '../Utilities/Constants/ModuleConstants';
import { GridFilterModuleId } from '../Utilities/Constants/ModuleConstants';
import { DashboardModule } from '../Strategy/DashboardModule';
import { AlertModule } from '../Strategy/AlertModule';
import { FlashingCellModule } from '../Strategy/FlashingCellModule';
import { BulkUpdateModule } from '../Strategy/BulkUpdateModule';
import { CalculatedColumnModule } from '../Strategy/CalculatedColumnModule';
import { CellSummaryModule } from '../Strategy/CellSummaryModule';
import { CustomSortModule } from '../Strategy/CustomSortModule';
import { DataChangeHistoryModule } from '../Strategy/DataChangeHistoryModule';
import { DataImportModule } from '../Strategy/DataImportModule';
import { DataSetModule } from '../Strategy/DataSetModule';
import { ExportModule } from '../Strategy/ExportModule';
import { ColumnFilterModule } from '../Strategy/ColumnFilterModule';
import { FormatColumnModule } from '../Strategy/FormatColumnModule';
import { FreeTextColumnModule } from '../Strategy/FreeTextColumnModule';
import { LayoutModule } from '../Strategy/LayoutModule';
import { PlusMinusModule } from '../Strategy/PlusMinusModule';
import { QuickSearchModule } from '../Strategy/QuickSearchModule';
import { ScheduleModule } from '../Strategy/ScheduleModule';
import { SmartEditModule } from '../Strategy/SmartEditModule';
import { ShortcutModule } from '../Strategy/ShortcutModule';
import { StateManagementModule } from '../Strategy/StateManagementModule';
import { TeamSharingModule } from '../Strategy/TeamSharingModule';
import { ToolPanelModule } from '../Strategy/ToolPanelModule';
import { SystemStatusModule } from '../Strategy/SystemStatusModule';
import { ThemeModule } from '../Strategy/ThemeModule';
import { GridInfoModule } from '../Strategy/GridInfoModule';
import { ColumnInfoModule } from '../Strategy/ColumnInfoModule';
import { SettingsPanelModule } from '../Strategy/SettingsPanelModule';
import { StatusBarModule } from '../Strategy/StatusBarModule';
import { ChartingModule } from '../Strategy/ChartingModule';
import { NoteModule } from '../Strategy/NoteModule';
import { StyledColumnModule } from '../Strategy/StyledColumnModule';
import { Fdc3Module } from '../Strategy/Fdc3Module';
import { GridFilterModule } from '../Strategy/GridFilterModule';
import { NamedQueryModule } from '../Strategy/NamedQueryModule';
import { CommentModule } from '../Strategy/CommentModule';
import { Helper } from '../Utilities/Helpers/Helper';
import { createUuid } from '../components/utils/uuid';
import UIHelper from '../View/UIHelper';
import { getAdaptableToolPanelAgGridComponent } from '../View/Components/ToolPanel/AdaptableToolPanel';
import { ADAPTABLE_STATUS_PANEL } from '../PredefinedConfig/StatusBarState';
import { createAgStatusPanelComponent } from './createAgStatusPanelComponent';
import { AdaptableStatusBar } from '../View/StatusBar/AdaptableStatusBar';
import ArrayExtensions from '../Utilities/Extensions/ArrayExtensions';
import { AgGridMenuAdapter } from './AgGridMenuAdapter';
import { AdaptableApp } from '../View/AdaptableView';
import { renderReactRoot as defaultRenderReactRoot } from '../renderReactRoot';
import { AgGridOptionsService } from './AgGridOptionsService';
import { parseDateValue } from '../Utilities/Helpers/DateHelper';
import { AgGridColumnAdapter, getEditorsForColumnTypes } from './AgGridColumnAdapter';
import uniqBy from 'lodash/uniqBy';
import getScrollbarSize from '../Utilities/getScrollbarSize';
import { isWeightedAverageAggregation, WEIGHTED_AVERAGE_AGG_FN_NAME } from '../PredefinedConfig/Common/AggregationColumns';
import lodashIsEqual from 'lodash/isEqual';
import { RowEditService } from '../Utilities/Services/RowEditService';
import { weightedAverage } from './weightedAverage';
import { FilterOnDataChangeOptions } from '../PredefinedConfig/Common/Enums';
import ObjectFactory, { createBaseContext } from '../Utilities/ObjectFactory';
import { ADAPTABLE_PUBLISH_TIMESTAMP } from '../EnvVars';
import { AdaptableUpgradeHelper } from '../migration/AdaptableUpgradeHelper';
import { ensurePortalElement } from '../components/Modal';
import { AdaptableLoadingScreen } from '../View/Components/Popups/AdaptableLoadingScreen';
import { createElement } from 'react';
import { removeUuidAndSource } from '../Utilities/Helpers/AdaptableHelper';
import { buildSortedColumnStateForLayout } from './buildSortedColumnStateForLayout';
import { ROW_SUMMARY_ROW_ID } from '../PredefinedConfig/Common/RowSummary';
const LocalEventService_Prototype = LocalEventService.prototype;
const LocalEventService_dispatchEvent = LocalEventService_Prototype.dispatchEvent;
const GridOptionsService_updateGridOptions = GridOptionsService.prototype.updateGridOptions;
const EventService_Prototype = EventService.prototype;
const EventService_wireBeans = EventService_Prototype.wireBeans;
// AG GRID obfuscates its internals, this is (currently) the best way to get hold of its internal services
const DANGER_AG_GRID_BEANS_MAP = {};
EventService_Prototype.wireBeans = function (beans) {
  var _a;
  EventService_wireBeans.apply(this, arguments);
  const gridId = (_a = beans === null || beans === void 0 ? void 0 : beans.context) === null || _a === void 0 ? void 0 : _a.getGridId();
  if (!gridId) {
    console.error('CRITICAL: No gridId found in beans, this should never happen!');
  }
  DANGER_AG_GRID_BEANS_MAP[gridId] = beans;
};
LocalEventService_Prototype.dispatchEvent = function (event) {
  LocalEventService_dispatchEvent.apply(this, arguments);
  if (event.type === 'cellChanged' || event.type === 'dataChanged') {
    const eventRowNode = event.node;
    const extractGridApiFromRowNode = rowNode => {
      var _a;
      const rowNodeApi = (_a = rowNode === null || rowNode === void 0 ? void 0 : rowNode.beans) === null || _a === void 0 ? void 0 : _a.gridApi;
      if (!rowNodeApi) {
        AdaptableLogger.consoleErrorBase(`No GridAPI found in passed RowNode, this should never happen!`, rowNode);
      }
      return rowNodeApi;
    };
    // we don't know from which instance of aggrid this is coming,
    // as this fn is shared by all instances
    if (eventRowNode) {
      AdaptableAgGrid.forEachAdaptable(adaptable => {
        var _a;
        if (extractGridApiFromRowNode(eventRowNode) !== ((_a = adaptable.agGridAdapter) === null || _a === void 0 ? void 0 : _a.getAgGridApi(true))) {
          // the event is coming from another aggrid instance
          // so IGNORE IT
          return;
        }
        // we're on the correct instance, so do this
        //@ts-ignore
        const fn = adaptable.rowListeners ? adaptable.rowListeners[event.type] : null;
        if (fn) {
          fn(event);
        }
      });
    }
  }
};
const adaptableInstances = {};
const publishTimestamp = Number(ADAPTABLE_PUBLISH_TIMESTAMP);
export class AdaptableAgGrid {
  constructor() {
    /**
     * once layouts are properly handled with the new aggrid methods & events
     * we can remove this hack
     */
    this.previousAgGridLayoutState = '';
    this.columnMinMaxValuesCache = {};
    this.renderReactRoot = (node, container) => defaultRenderReactRoot(node, container);
    /**
     * do NOT mutate this array reference, this is passed only initially to AG Grid and we can only change it's internal state
     */
    this.DANGER_excelStyles = [];
    this.originalExcelStyles = [];
    /**
     * Temporary, these are MIGRATION technical debts, and should be removed as soon as possible
     */
    this.adaptableStatusPanelKeys = [];
    // only for our private / internal events used within Adaptable
    // public events are emitted through the EventApi
    this._emit = (eventName, data) => {
      if (this.emitter) {
        return this.emitter.emit(eventName, data);
      }
    };
    this._emitSync = (eventName, data) => {
      if (this.emitter) {
        return this.emitter.emitSync(eventName, data);
      }
    };
    this._on = (eventName, callback) => {
      if (!this.emitter) {
        return () => {};
      }
      return this.emitter.on(eventName, callback);
    };
    this._onIncludeFired = (eventName, callback) => {
      if (!this.emitter) {
        return () => {};
      }
      return this.emitter.onIncludeFired(eventName, callback);
    };
    this.lifecycleState = 'initial';
    this.emitter = new Emitter();
    this.agGridOptionsService = new AgGridOptionsService(this);
    this.agGridAdapter = new AgGridAdapter(this);
    this.agGridMenuAdapter = new AgGridMenuAdapter(this);
    this.agGridColumnAdapter = new AgGridColumnAdapter(this);
    this.DataService = new DataService(this);
  }
  static forEachAdaptable(fn) {
    Object.keys(adaptableInstances).forEach(key => {
      fn(adaptableInstances[key]);
    });
  }
  static collectInstance(adaptable, adaptableId) {
    adaptable._id = adaptableId;
    adaptableInstances[adaptable._id] = adaptable;
  }
  static dismissInstance(adaptable) {
    delete adaptableInstances[adaptable._id];
  }
  get isAgGridInitialising() {
    return this.lifecycleState === 'initAgGrid';
  }
  get isReady() {
    return this.lifecycleState === 'ready';
  }
  get isAvailable() {
    return this.lifecycleState === 'available' || this.lifecycleState === 'ready';
  }
  get isDestroyed() {
    return this.lifecycleState === 'preDestroyed';
  }
  /**
   * Internal initializer for Adaptable, directly called by the React and Angular Adaptable wrappers
   * @private
   */
  static async _initInternal(config) {
    let promise = null;
    if (Array.isArray(config.adaptableOptions.plugins)) {
      const agGridOptions = {
        gridOptions: config.gridOptions,
        modules: config.modules
      };
      for (let plugin of config.adaptableOptions.plugins) {
        promise = promise && promise.then ? promise.then(() => {
          return plugin.beforeInit(config.adaptableOptions, agGridOptions);
        }) : plugin.beforeInit(config.adaptableOptions, agGridOptions);
      }
      // if gridOptions changed, we need to update the runtimeConfig
      if (agGridOptions.gridOptions !== config.gridOptions) {
        // This allows plugins to modify
        // FIXME AFL MIG: clarify if this is still needed (for NoCode Plugin?)
        // it looks like a code smell, ideally we should get rid of it
        config.gridOptions = agGridOptions.gridOptions;
      }
    }
    const doInit = adaptableInstance => {
      return adaptableInstance._initAdaptableAgGrid(config).then(api => {
        if (Array.isArray(config.adaptableOptions.plugins)) {
          config.adaptableOptions.plugins.forEach(plugin => {
            plugin.afterInit(adaptableInstance);
          });
        }
        return api;
      });
    };
    if (promise && promise.then) {
      return promise.then(() => {
        const adaptableInstance = new AdaptableAgGrid();
        return doInit(adaptableInstance);
      });
    } else {
      const adaptableInstance = new AdaptableAgGrid();
      return doInit(adaptableInstance);
    }
  }
  async _initAdaptableAgGrid(config) {
    var _a, _b;
    // Phase 1: Preprocess Adaptable Options
    this._isDetailGrid = config.isDetailGrid === true;
    this.lifecycleState = 'preprocessOptions';
    this._rawAdaptableOptions = config.adaptableOptions;
    if (StringExtensions.IsNullOrEmptyOrWhiteSpace(this._rawAdaptableOptions.adaptableId)) {
      this._rawAdaptableOptions.adaptableId = `adaptable_id_${Date.now()}`;
    }
    this.logger = (_a = this.logger) !== null && _a !== void 0 ? _a : new AdaptableLogger(this._rawAdaptableOptions.adaptableId);
    const perfInitAdaptableAgGrid = this.logger.beginPerf(`Adaptable._initAdaptableAgGrid()`);
    AdaptableAgGrid.collectInstance(this, this._rawAdaptableOptions.adaptableId);
    this.variant = config.variant;
    this.initWithLazyData = config.gridOptions.rowData == undefined || config.gridOptions.rowData.length === 0;
    this.hasAutogeneratedPrimaryKey = !!this._rawAdaptableOptions.autogeneratePrimaryKey;
    this.adaptableOptions = applyDefaultAdaptableOptions(this._rawAdaptableOptions);
    this.adaptableOptions = this.normalizeAdaptableOptions(this.adaptableOptions);
    const {
      showLoadingScreen,
      loadingScreenDelay,
      loadingScreenText,
      loadingScreenTitle
    } = this.adaptableOptions.userInterfaceOptions;
    if (showLoadingScreen) {
      this.logger.info(`Show Loading Screen`);
      const portalElement = ensurePortalElement();
      if (portalElement) {
        this.unmountLoadingScreen = this.renderReactRoot(createElement(AdaptableLoadingScreen, {
          showLoadingScreen,
          loadingScreenDelay,
          loadingScreenText,
          loadingScreenTitle
        }), portalElement);
      } else {
        this.logger.consoleError(`Adaptable failed to show the loading screen!`);
      }
    }
    this.forPlugins(plugin => plugin.afterInitOptions(this, this.adaptableOptions));
    this.api = new AdaptableApiImpl(this);
    this.forPlugins(plugin => plugin.afterInitApi(this, this.api));
    this.lifecycleState = 'initAdaptableState';
    // just in case Adaptable was destroyed while loading the store (which is an async operation)
    if (this.isDestroyed) {
      return Promise.reject('Adaptable was destroyed while loading the store.');
      // FIXME AFL MIG: is this enough?! talk with the team
    }
    this.initServices();
    this.forPlugins(plugin => plugin.afterInitServices(this));
    this.adaptableModules = this.initModules();
    this.forPlugins(plugin => plugin.afterInitModules(this, this.adaptableModules));
    const perfLoadStore = this.logger.beginPerf(`loadStore()`);
    this.adaptableStore = this.initAdaptableStore();
    this.forPlugins(plugin => plugin.afterInitStore(this));
    await this.adaptableStore.loadStore({
      adaptable: this,
      adaptableStateKey: this.adaptableOptions.adaptableStateKey,
      /**
       * This method is called after the store is loaded;
       * it allows to modify the state before it is used by the application
       * e.g. adding default Layout, migrating deprecated state, etc.
       */
      postLoadHook: state => {
        if (this.adaptableOptions.stateOptions.autoMigrateState) {
          state = AdaptableUpgradeHelper.migrateAdaptableState(state, {
            // version 16 actually includes all versions up until 16
            fromVersion: 16,
            logger: this.logger
          });
        }
        state = this.normalizeAdaptableState(state, config.gridOptions);
        return state;
      }
    });
    perfLoadStore.end();
    // just in case Adaptable was destroyed while loading the store (which is an async operation)
    if (this.isDestroyed) {
      return Promise.reject('Adaptable was destroyed while loading the store.');
      // FIXME AFL MIG: is this enough?! talk with the team
    }
    this.forPlugins(plugin => plugin.afterInitialStateLoaded(this));
    // do this now so it sets module entitlements
    this.api.entitlementApi.internalApi.setModulesEntitlements();
    /**
     * At this point it's mandatory to have the ALL the Adaptable blocks initialized:
     * Store, APIs, Services, Modules
     */
    this.lifecycleState = 'setupAgGrid';
    const gridOptions = config.gridOptions;
    // Needed here because special column defs are required for deriving the adaptable column state
    const columnDefs = this.getColumnDefinitionsInclSpecialColumns(gridOptions.columnDefs || []);
    gridOptions.columnDefs = columnDefs;
    this.setInitialGridOptions(gridOptions, config.variant);
    gridOptions.initialState = this.mapAdaptableStateToAgGridState(this.adaptableStore.TheStore.getState(), gridOptions.columnDefs);
    this.lifecycleState = 'initAgGrid';
    this.agGridAdapter.initialGridOptions = gridOptions;
    const perfInitAgGrid = this.logger.beginPerf(`initAgGrid()`);
    // AG Grid evaluates early on the floatingFilter params, so we need to "suppres" the floating filter temporarily
    // we will reset it once Adaptable is ready
    this.agGridColumnAdapter.setupColumnFloatingFilterTemporarily(gridOptions);
    const agGridApi = await this.initializeAgGrid(gridOptions, config.modules, config.renderAgGridFrameworkComponent);
    if (agGridApi === false) {
      this.logger.consoleError(`Adaptable failed to initialize AG Grid!`);
      return Promise.reject('Adaptable failed to initialize AG Grid!');
    }
    this.logger.info(`Hide Loading Screen`);
    (_b = this.unmountLoadingScreen) === null || _b === void 0 ? void 0 : _b.call(this);
    perfInitAgGrid.end();
    // we need to intercept several AG Grid Api methods and trigger Adaptale state changes
    this.monkeyPatchingGridOptionsUpdates(agGridApi);
    this.agGridAdapter.setAgGridApi(agGridApi);
    this.lifecycleState = 'agGridReady';
    this.logger.info(`Registered AG Grid modules: `, this.agGridAdapter.getRegisteredModuleNames().sort());
    /**
     * At this point AG Grid is initialized!
     */
    this.deriveAdaptableColumnStateFromAgGrid();
    this.agGridColumnAdapter.setupColumns();
    // we need this because we need the internal Column state to be ready before doing any extra business logic
    this.lifecycleState = 'available';
    this.api.themeApi.applyCurrentTheme();
    this.validatePrimaryKey();
    this.embedColumnMenu = this.agGridAdapter.isModulePresent(ModuleNames.MenuModule);
    this.api.internalApi.setTreeMode(!!this.agGridAdapter.getAgGridApi().getGridOption('treeData'));
    // TODO AFL MIG: we could just patch the defautl Layout on init? instead
    this.checkShouldClearExistingFiltersOrSearches();
    this.applyColumnFiltering();
    this.addGridEventListeners();
    this.temporaryAdaptableStateUpdates();
    this.redrawBody();
    this.refreshHeader();
    const currentLayout = this.api.layoutApi.getCurrentLayout();
    if (currentLayout.EnablePivot) {
      // this is very very strange!
      // for some projects, if the initial layout is pivot, the columnDefs of the pivot resutl columns are NOT derived correctly from the main colDefs
      // doing the following line fixes the issue because it foces the pivot columns to be created again
      // this proj works without the hack: /tests/pages/format-column/initial-pivot-layout.page.tsx
      // but this proj needs the hack: /tests/pages/format-column/initial-pivot-layout-docs.page.tsx
      this.agGridAdapter.setGridOption('pivotMode', false);
      this.agGridAdapter.setGridOption('pivotMode', true);
    }
    // create the module menu (for use in the dashboard and the toolpanel)
    // TODO see #create-create-module-menu - make sure it's the same here and there
    this.ModuleService.createModuleMenus();
    // update initial mode of DataChangeHistory
    this.api.internalApi.initializeDataChangeHistory();
    const adaptableContainerElem = this.getAdaptableContainerElement();
    if (adaptableContainerElem != null) {
      adaptableContainerElem.innerHTML = '';
      if (this.variant === 'react') {
        /**
         * #no_additional_react_root
         * This is only used for the React variant
         * Where we don't want to create a new React render tree here
         * by rendering it as a React root, but instead we want to
         * render it as is in the React tree of our AdaptableReact component
         */
        this._PRIVATE_adaptableJSXElement = AdaptableApp({
          Adaptable: this
        });
      } else {
        this.unmountReactRoot = this.renderReactRoot(AdaptableApp({
          Adaptable: this
        }), adaptableContainerElem);
      }
    }
    this.lifecycleState = 'ready';
    this.forPlugins(plugin => plugin.onAdaptableReady(this, this.adaptableOptions));
    setTimeout(() => {
      // without the setTimeout, calling autoSizeAllColumns immediately in the onAdaptableReady
      // does not work. (I prefer setTimeout to rAF, as raf is not running when you switch tabs)
      //
      // it also makes it possible to listen to CALCULATED_COLUMN_READY, DASHBOARD_READY, etc.
      // in onAdaptableReady - without this those event listeners are not triggered
      this.api.eventApi.emit('AdaptableReady', {
        adaptableApi: this.api,
        agGridApi: this.agGridAdapter.getAgGridApi()
      });
    });
    perfInitAdaptableAgGrid.end();
    return Promise.resolve(this.api);
  }
  normalizeAdaptableState(state, agGridOptions) {
    state = this.normaliseLayoutState(state, agGridOptions);
    state = this.normaliseToolPanelState(state);
    return state;
  }
  normaliseLayoutState(state, gridOptions) {
    var _a, _b, _c, _d, _e, _f;
    if (this.shouldCreateDefaultLayout(state, this.adaptableOptions)) {
      const defaultLayout = this.createDefaultLayout(state, gridOptions);
      const layoutState = state.Layout || {};
      const availableLayouts = layoutState.Layouts || [];
      availableLayouts.push(defaultLayout);
      layoutState.Layouts = availableLayouts;
      layoutState.CurrentLayout = defaultLayout.Name;
      state.Layout = layoutState;
    } else {
      const layoutState = state.Layout;
      // ensure CurrentLayout is valid
      if (!layoutState.CurrentLayout || !layoutState.Layouts.find(l => l.Name === layoutState.CurrentLayout)) {
        layoutState.CurrentLayout = (_b = (_a = layoutState.Layouts) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.Name;
      }
    }
    // for the initial state, we need to dynamically add the ActionRowColumn (if necessary)
    // for subsequent layout changes, the `Adaptable.setLayout()` method handles this
    // this is a serious code smell, hopefully we will delete `setLayout()` at some point
    const hasActionRowButtons = !!((_c = this.adaptableOptions.actionRowOptions) === null || _c === void 0 ? void 0 : _c.actionRowButtons);
    if (hasActionRowButtons) {
      const currentLayout = state.Layout.Layouts.find(l => l.Name === state.Layout.CurrentLayout);
      if (currentLayout && !currentLayout.Columns.includes(ADAPTABLE_ROW_ACTION_BUTTONS)) {
        currentLayout.Columns.push(ADAPTABLE_ROW_ACTION_BUTTONS);
        const columnPosition = (_e = (_d = this.adaptableOptions.actionRowOptions) === null || _d === void 0 ? void 0 : _d.actionRowButtonOptions) === null || _e === void 0 ? void 0 : _e.position;
        currentLayout.PinnedColumnsMap = currentLayout.PinnedColumnsMap || {};
        currentLayout.PinnedColumnsMap[ADAPTABLE_ROW_ACTION_BUTTONS] = columnPosition === 'pinnedRight' ? 'right' : 'left';
      }
    }
    /**
     * Viewport mode does not support a few
     * features instead of complicating the
     * logic where layout is applied, it is easier and
     * less error prone to just remove it.
     */
    if (gridOptions.rowModelType === 'viewport') {
      (_f = state.Layout.Layouts) === null || _f === void 0 ? void 0 : _f.forEach(layout => {
        if (layout.RowGroupedColumns) {
          delete layout.RowGroupedColumns;
        }
        if (layout.AggregationColumns) {
          delete layout.AggregationColumns;
        }
        if (layout.PivotColumns) {
          delete layout.PivotColumns;
          delete layout.EnablePivot;
        }
      });
    }
    return state;
  }
  normaliseToolPanelState(state) {
    var _a, _b, _c;
    if ((_a = state === null || state === void 0 ? void 0 : state.ToolPanel) === null || _a === void 0 ? void 0 : _a.ToolPanels) {
      return state;
    }
    // no predefined config provided, we will display all the panels collapsed (custom & module)
    const defaultToolPanels = [];
    (_c = (_b = this.adaptableOptions.toolPanelOptions) === null || _b === void 0 ? void 0 : _b.customToolPanels) === null || _c === void 0 ? void 0 : _c.forEach(customToolPanel => defaultToolPanels.push({
      Name: customToolPanel.name
    }));
    ALL_TOOL_PANELS.forEach(moduleToolPanel => defaultToolPanels.push({
      Name: moduleToolPanel
    }));
    const toolPanelState = state.ToolPanel || {};
    toolPanelState.ToolPanels = defaultToolPanels;
    state.ToolPanel = toolPanelState;
    return state;
  }
  refreshQuickFilter() {
    const isQuickFilterVisible = this.api.internalApi.getSystemState().IsQuickFilterVisible;
    if (this.isQuickFilterAvailable()) {
      if (isQuickFilterVisible) {
        this.api.columnFilterApi.showQuickFilterBar();
      } else {
        this.api.columnFilterApi.hideQuickFilterBar();
      }
    }
  }
  applyColumnFiltering() {
    this.refreshQuickFilter();
    this.applyGridFiltering();
    this.agGridAdapter.updateColumnFilterActiveState();
  }
  applyGridFiltering() {
    this.agGridAdapter.getAgGridApi().onFilterChanged();
    this._emit('AdapTableFiltersApplied');
    this.refreshSelectedCellsState();
    this.refreshSelectedRowsState();
  }
  // refreshAgGridWithAdaptableState() {
  //   this.refreshColDefs();
  //   this.api.themeApi.applyCurrentTheme();
  //   this.api.internalApi.setTreeMode(this.agGridAdapter.initialGridOptions.treeData);
  //   this.checkShouldClearExistingFiltersOrSearches();
  //   this.applyColumnFiltering();
  // }
  refreshColDefs(agGridColDefs) {
    const freshColDefs = this.getColumnDefinitionsInclSpecialColumns(agGridColDefs);
    this.agGridAdapter.setGridOption('columnDefs', freshColDefs);
  }
  showQuickFilter() {
    const height = this.api.optionsApi.getColumnFilterOptions().quickFilterOptions.quickFilterHeight;
    this.agGridAdapter.getAgGridApi().setGridOption('floatingFiltersHeight', height);
  }
  hideQuickFilter() {
    this.agGridAdapter.getAgGridApi().setGridOption('floatingFiltersHeight', 0);
  }
  normalizeAdaptableOptions(adaptableOptions) {
    if (this.hasAutogeneratedPrimaryKey) {
      this.logger.warn(`Autogenerated primary key (adaptableOptions.autogeneratedPrimaryKey = TRUE) should be used only as a last resort,
      when no unique column is available, as it limits some Adaptable functionalities!

      For more details see: ${PrimaryKeyDocsLink}`);
      this.adaptableOptions.primaryKey = AUTOGENERATED_PK_COLUMN;
      return this.adaptableOptions;
    }
    if (StringExtensions.IsNullOrEmpty(adaptableOptions.primaryKey)) {
      this.logger.consoleError(`AdaptableOptions.primaryKey is required and cannot be empty or null!
      As a fallback, you can set adaptableOptions.autogeneratedPrimaryKey = TRUE

      For more details see: ${PrimaryKeyDocsLink}`);
    }
    return adaptableOptions;
  }
  setInitialGridOptions(gridOptions, variant) {
    /**
     * set Adaptable instance on the AG Grid context
     */
    this.agGridOptionsService.setGridOptionsProperty(gridOptions, 'context', original_context => {
      const userContext = original_context || {};
      return Object.assign(Object.assign({}, userContext), {
        __adaptable: this,
        adaptableApi: this.api
      });
    });
    /**
     * `gridId`
     */
    this.agGridOptionsService.setGridOptionsProperty(gridOptions, 'gridId', original_gridId => {
      const agGridId = original_gridId || this.adaptableOptions.adaptableId;
      this._agGridId = agGridId;
      return agGridId;
    });
    /**
     *  `getRowId`
     */
    this.agGridOptionsService.setGridOptionsProperty(gridOptions, 'getRowId', original_getRowId => {
      if (original_getRowId) {
        return original_getRowId;
      }
      const primaryKey = this.adaptableOptions.primaryKey;
      if (StringExtensions.IsNullOrEmpty(primaryKey)) {
        // if no valid PK then do nothing
        return original_getRowId;
      }
      if (this.hasAutogeneratedPrimaryKey) {
        return params => {
          // if the PK value is autogenerated, we need to make sure that the rowData has a valid PK value
          // this should be taken care of in the Adaptable.[loadDataSource/setDataSource/updateRows]() methods, but the users will always make silly decisions
          // so just to be safe we'll check here and add a PK value is missing
          // thus adding a side-effect in a getter, but what can we do against it?! :)
          if (Helper.objectNotExists(params.data[primaryKey])) {
            params.data[primaryKey] = createUuid();
          }
          return params.data[primaryKey];
        };
      }
      return params => {
        var _a, _b, _c;
        if ((_a = params.data) === null || _a === void 0 ? void 0 : _a[primaryKey]) {
          const primaryKeyValue = params.data[primaryKey];
          return typeof primaryKeyValue === 'number' ? `${primaryKeyValue}` : params.data[primaryKey];
        }
        // might be a summary row
        if ((_b = params.data) === null || _b === void 0 ? void 0 : _b[ROW_SUMMARY_ROW_ID]) {
          return params.data[ROW_SUMMARY_ROW_ID];
        }
        // AFL 2024.08.17 - no idea why is this here and when it's used
        //  might be a group row
        const parentKeys = (_c = params.parentKeys) !== null && _c !== void 0 ? _c : [];
        const values = Object.values(params.data);
        if (values.length) {
          const id = [...parentKeys, values[0]].join('/');
          return id;
        }
      };
    });
    /**
     * `suppressAggFuncInHeader`
     */
    this.agGridOptionsService.setGridOptionsProperty(gridOptions, 'suppressAggFuncInHeader', original_suppressAggFuncInHeader => {
      const currentLayout = this.api.layoutApi.getCurrentLayout();
      if (!currentLayout) {
        return original_suppressAggFuncInHeader;
      }
      return currentLayout.SuppressAggFuncInHeader;
    });
    /**
     * `aggFuncs`
     */
    this.agGridOptionsService.setGridOptionsProperty(gridOptions, 'aggFuncs', original_aggFuncs => {
      const aggregationFunctions = original_aggFuncs || {};
      aggregationFunctions[WEIGHTED_AVERAGE_AGG_FN_NAME] = params => {
        const columnId = params.column.getColId();
        const adaptableAggFunc = this.getActiveAdaptableAggFuncForCol(columnId);
        if (!adaptableAggFunc) {
          return undefined;
        }
        if (adaptableAggFunc.type === 'weightedAverage') {
          return weightedAverage(params, params.colDef.colId, adaptableAggFunc.weightedColumnId);
        }
        return undefined;
      };
      return aggregationFunctions;
    });
    /**
     * `allowContextMenuWithControlKey`
     */
    this.agGridOptionsService.setGridOptionsProperty(gridOptions, 'allowContextMenuWithControlKey', original_allowContextMenuWithControlKey => {
      return original_allowContextMenuWithControlKey === undefined ? true : original_allowContextMenuWithControlKey;
    });
    /**
     * `isExternalFilterPresent`
     */
    this.agGridOptionsService.setGridOptionsProperty(gridOptions, 'isExternalFilterPresent', original_isExternalFilterPresent => {
      return params => {
        if (!this.isAvailable) {
          return true;
        }
        const columnFilters = this.api.columnFilterApi.getActiveColumnFilters();
        const isColumnFiltersActive = ArrayExtensions.IsNotNullOrEmpty(columnFilters);
        const isGridFilterActive = StringExtensions.IsNotNullOrEmpty(this.api.gridFilterApi.getCurrentGridFilterExpression());
        return isColumnFiltersActive || isGridFilterActive || (
        // it means that userPropertyValue will be called so we re-init that collection
        original_isExternalFilterPresent ? original_isExternalFilterPresent(params) : false);
      };
    });
    /**
     * `doesExternalFilterPass`
     */
    this.agGridOptionsService.setGridOptionsProperty(gridOptions, 'doesExternalFilterPass', original_doesExternalFilterPass => {
      return node => {
        if (!this.isAvailable) {
          return true;
        }
        // first we assess a Grid Filter (if its running locally)
        if (!this.isGroupRowNode(node)) {
          const currentGridFilter = this.api.gridFilterApi.getCurrentGridFilterExpression();
          if (currentGridFilter) {
            const evaluateGridFilterOnClient = this.api.expressionApi.internalApi.evaluateExpressionInAdaptableQL('GridFilter', undefined, currentGridFilter);
            if (evaluateGridFilterOnClient) {
              const isCurrentGridFilterValid = this.api.expressionApi.isValidBooleanExpression(currentGridFilter, GridFilterModuleId, `Invalid Grid Filter '${currentGridFilter}'`);
              if (!isCurrentGridFilterValid || !this.api.internalApi.getQueryLanguageService().evaluateBooleanExpression(currentGridFilter, GridFilterModuleId, node)) {
                return false;
              }
            }
          }
        }
        const columnFilters = this.api.columnFilterApi.getActiveColumnFilters();
        try {
          if (columnFilters.length > 0) {
            const isRowFiltrable = this.api.optionsApi.getColumnFilterOptions().isRowFilterable;
            for (const columnFilter of columnFilters) {
              const evaluateFilterOnClient = this.api.expressionApi.internalApi.evaluatePredicatesInAdaptableQL('ColumnFilter', columnFilter, [columnFilter.Predicate]);
              if (evaluateFilterOnClient) {
                // we then assess filters (if running locally)
                if (typeof isRowFiltrable === 'function' && !isRowFiltrable(Object.assign(Object.assign({}, createBaseContext(this.api)), {
                  rowNode: node,
                  data: node.data
                }))) {
                  return true;
                }
                if (!this.api.columnFilterApi.internalApi.evaluateColumnFilter(columnFilter, node)) {
                  return false;
                }
              }
            }
          }
        } catch (ex) {
          this.logger.error(ex);
          return false;
        }
        return original_doesExternalFilterPass ? original_doesExternalFilterPass(node) : true;
      };
    });
    /**
     * `getMainMenuItems`
     */
    this.agGridOptionsService.setGridOptionsProperty(gridOptions, 'getMainMenuItems', original_getMainMenuItems => {
      return params => {
        // couldnt find a way to listen for menu close. There is a Menu Item Select, but you can also close menu from filter and clicking outside menu....
        return this.agGridMenuAdapter.buildColumnMenu(params, original_getMainMenuItems);
      };
    });
    /**
     * `getContextMenuItems`
     */
    this.agGridOptionsService.setGridOptionsProperty(gridOptions, 'getContextMenuItems', original_getContextMenuItems => {
      return params => {
        return this.agGridMenuAdapter.buildContextMenu(params, original_getContextMenuItems);
      };
    });
    /**
     * `initialGroupOrderComparator
     */
    // Build the default group sort comparator - will get custom sort values (but not functions) in real time
    // TODO: if  a custom 'aggFunc' property is defined (see setupColumnAggFunc()), it won't be evaluated
    if (this.getAgGridRowModelType(gridOptions) === 'clientSide' && this.adaptableOptions.groupingOptions.autoOrderGroupedColumns) {
      this.agGridOptionsService.setGridOptionsProperty(gridOptions, 'initialGroupOrderComparator', original_initialGroupOrderComparator => {
        if (original_initialGroupOrderComparator) {
          return original_initialGroupOrderComparator;
        }
        return params => {
          const {
            nodeA,
            nodeB
          } = params;
          const firstGroupedColumnId = this.agGridAdapter.getFirstGroupedColumn();
          if (firstGroupedColumnId) {
            const definedColumnComparator = this.api.columnApi.internalApi.getActiveColumnComparator(firstGroupedColumnId, this.api.customSortApi.getCustomSortForColumn(firstGroupedColumnId), this.api.customSortApi.internalApi.getCustomSortComparer(firstGroupedColumnId));
            if (definedColumnComparator) {
              return definedColumnComparator(nodeA.key, nodeB.key);
            }
            const sortOder = this.api.layoutApi.getCurrentLayoutColumnSort(firstGroupedColumnId);
            if (sortOder === 'Desc') {
              return nodeA.key > nodeB.key ? -1 : 1;
            }
          }
          // if no comparator available, just sort alphanumerically
          if (nodeA.key == nodeB.key) {
            return 0;
          }
          return nodeA.key < nodeB.key ? -1 : 1;
        };
      });
      /**
       * `autoSizeStrategy`
       *
       * This is needed here, even if we do auto sizing on FIRST_DATA_RENDERED.
       * Sometimes FIRST_DATA_RENDERED is triggered too early and autoSizing doesn't work initially
       * so we need this block
       */
      this.agGridOptionsService.setGridOptionsProperty(gridOptions, 'autoSizeStrategy', original_autoSizeStrategy => {
        return this.shouldAutoSizeLayout() ? {
          type: 'fitCellContents'
        } : original_autoSizeStrategy;
      });
    }
    /**
     * `components`
     */
    this.agGridOptionsService.setGridOptionsProperty(gridOptions, 'components', original_components => {
      const AdaptableToolPanel = getAdaptableToolPanelAgGridComponent(this);
      const components = original_components || {};
      const adaptableComponents = Object.assign(Object.assign({}, components), {
        AdaptableToolPanel
      });
      return adaptableComponents;
    });
    if (variant === 'react') {
      // TODO very soon we have to transition to reactiveCustomComponents in React
      // but for now, if we simply set it to true, it will break our editors, etc
      // this.agGridOptionsService.setGridOptionsProperty(
      //   gridOptions,
      //   'reactiveCustomComponents',
      //   () => true
      // );
    }
    /**
     * `sidebar`
     */
    this.agGridOptionsService.setGridOptionsProperty(gridOptions, 'sideBar', original_sideBar => {
      var _a, _b;
      if (!original_sideBar) {
        // lucky us, no sideBar is defined, so we don't have to do anything
        return original_sideBar;
      }
      const isAdaptableToolPanelHidden = this.api.entitlementApi.isModuleHiddenEntitlement('ToolPanel');
      const adaptableToolPanelDef = {
        id: GeneralConstants.ADAPTABLE_TOOLPANEL_ID,
        toolPanel: GeneralConstants.ADAPTABLE_TOOLPANEL_COMPONENT,
        labelDefault: GeneralConstants.ADAPTABLE,
        labelKey: 'adaptable',
        iconKey: 'menu',
        width: UIHelper.getAdaptableToolPanelWidth(),
        minWidth: UIHelper.getAdaptableToolPanelWidth()
        // maxWidth = undefined,
      };
      const mapToolPanelDefs = (toolPanelDefs = []) => {
        // if it's an alias for the adaptable tool panel, map it to a ToolPanelDef, otherwise return it as it is
        return toolPanelDefs.map(toolPanelDef => toolPanelDef === GeneralConstants.ADAPTABLE_TOOLPANEL_ID ? adaptableToolPanelDef : toolPanelDef);
      };
      const isSideBarDefObject = sidebarDef => {
        return Array.isArray(sidebarDef === null || sidebarDef === void 0 ? void 0 : sidebarDef.toolPanels);
      };
      let result;
      if (original_sideBar === true) {
        // create all tool panels with default settings
        const toolPanels = [];
        toolPanels.push(GeneralConstants.AGGRID_TOOLPANEL_FILTERS);
        toolPanels.push(GeneralConstants.AGGRID_TOOLPANEL_COLUMNS);
        if (!isAdaptableToolPanelHidden) {
          toolPanels.push(adaptableToolPanelDef);
        }
        result = {
          toolPanels: toolPanels
        };
      }
      // if there is only one tool panel, and it's the adaptable one => we have to handle it
      else if (typeof original_sideBar === 'string') {
        if (gridOptions.sideBar === GeneralConstants.ADAPTABLE_TOOLPANEL_ID) {
          if (!isAdaptableToolPanelHidden) result = {
            toolPanels: [adaptableToolPanelDef]
          };
        } else {
          result = original_sideBar;
        }
      }
      // if it's an array, process the tool panel definitions
      else if (Array.isArray(original_sideBar)) {
        if (!original_sideBar.includes(GeneralConstants.ADAPTABLE_TOOLPANEL_ID) || isAdaptableToolPanelHidden) {
          result = original_sideBar;
        }
        // if it's an array, process the tool panel definitions
        const sidebarDef = {};
        sidebarDef.toolPanels = mapToolPanelDefs(original_sideBar);
        result = sidebarDef;
      }
      // if it's fully-fledged SideBarDef, process its tool panel definitions
      else if (isSideBarDefObject(original_sideBar)) {
        if ((_a = original_sideBar.toolPanels) === null || _a === void 0 ? void 0 : _a.some(toolpanelDef => typeof toolpanelDef !== 'string' && toolpanelDef.id === GeneralConstants.ADAPTABLE_TOOLPANEL_ID && !isAdaptableToolPanelHidden)) {
          // if there is an Adaptable SideBarDef, don't touch it as it may contain user-defined properties
          result = original_sideBar;
        } else {
          result = Object.assign(Object.assign({}, original_sideBar), {
            toolPanels: mapToolPanelDefs(original_sideBar.toolPanels)
          });
        }
      }
      this.hasAdaptableToolPanel = isSideBarDefObject(result) && ((_b = result.toolPanels) === null || _b === void 0 ? void 0 : _b.some(toolPanelDef => typeof toolPanelDef !== 'string' && toolPanelDef.id === GeneralConstants.ADAPTABLE_TOOLPANEL_ID));
      return result;
    });
    /**
     * `statusBar`
     */
    this.agGridOptionsService.setGridOptionsProperty(gridOptions, 'statusBar', original_statusBar => {
      var _a, _b;
      const statusPanels = (_b = (_a = original_statusBar === null || original_statusBar === void 0 ? void 0 : original_statusBar.statusPanels) !== null && _a !== void 0 ? _a : []) === null || _b === void 0 ? void 0 : _b.map(statusPanel => {
        if (statusPanel.statusPanel === ADAPTABLE_STATUS_PANEL) {
          this.adaptableStatusPanelKeys.push(statusPanel.key);
          const context = {
            Key: statusPanel.key
          };
          return Object.assign(Object.assign({}, statusPanel), {
            statusPanel: createAgStatusPanelComponent(AdaptableStatusBar, this, context)
          });
        }
        return statusPanel;
      });
      return Object.assign(Object.assign({}, original_statusBar), {
        statusPanels
      });
    });
    /**
     * `getRowStyle`
     */
    this.agGridOptionsService.setGridOptionsProperty(gridOptions, 'getRowStyle', original_getRowStyle => {
      return params => {
        const result = Object.assign(Object.assign(Object.assign({}, original_getRowStyle === null || original_getRowStyle === void 0 ? void 0 : original_getRowStyle(params)), this.api.gridApi.internalApi.getRowHighlightStyle(params)), this.api.gridApi.internalApi.getAlertRowStyle(params));
        return result;
      };
    });
    /**
     * `getRowClass`
     */
    this.agGridOptionsService.setGridOptionsProperty(gridOptions, 'getRowClass', original_getRowClass => {
      return params => {
        const alertHighlightClassName = this.api.gridApi.internalApi.getAlertRowClass(params);
        const highlightClassName = this.api.gridApi.internalApi.getRowHighlightClass(params);
        const returnValue = [typeof original_getRowClass === 'function' ? original_getRowClass(params) : original_getRowClass, highlightClassName, alertHighlightClassName]
        // we flatten it because 'original_getRowClass' might return a string[]
        .flat().filter(x => !!x);
        return (returnValue === null || returnValue === void 0 ? void 0 : returnValue.length) ? returnValue : undefined;
      };
    });
    /**
     * `floatingFiltersHeight`
     */
    this.agGridOptionsService.setGridOptionsProperty(gridOptions, 'floatingFiltersHeight', original_floatingFiltersHeight => {
      if (this.api.layoutApi.getCurrentLayout().EnablePivot) {
        // if the default layout is a pivot on, hide the floating filters from the beginning, otherwise we get an annoying flicker
        return 0;
      }
      return original_floatingFiltersHeight;
    });
    /**
     * `excelStyles`
     */
    this.agGridOptionsService.setGridOptionsProperty(gridOptions, 'excelStyles', original_excelStyles => {
      this.originalExcelStyles = original_excelStyles !== null && original_excelStyles !== void 0 ? original_excelStyles : [];
      this.DANGER_excelStyles = this.originalExcelStyles;
      // this array reference will be used for the entire AG Grid session
      return this.DANGER_excelStyles;
    });
    /**
     * `processPivotResultColDef`
     */
    this.agGridOptionsService.setGridOptionsProperty(gridOptions, 'processPivotResultColDef', () => {
      return colDef => {
        if (this.adaptableOptions.columnFilterOptions.useAdaptableColumnFiltering) {
          colDef.floatingFilter = false;
          colDef.filter = false;
        }
      };
    });
    /**
     * `columnTypes`
     */
    // this will have to go/be heavily extended with https://github.com/AdaptableTools/adaptable/issues/2230
    this.agGridOptionsService.setGridOptionsProperty(gridOptions, 'columnTypes', original_columnTypes => {
      var _a;
      const providedColumnTypes = original_columnTypes || {};
      const gridOptionsColumnTypes = gridOptions.columnTypes || {};
      const patchedColumnTypes = Object.assign({}, providedColumnTypes, {
        [AB_SPECIAL_COLUMN]: {},
        [AB_FDC3_COLUMN]: {},
        abColDefNumber: Object.assign({}, gridOptionsColumnTypes.abColDefNumber),
        abColDefString: Object.assign({}, gridOptionsColumnTypes.abColDefString),
        abColDefBoolean: Object.assign({}, gridOptionsColumnTypes.abColDefBoolean),
        abColDefDate: Object.assign({}, gridOptionsColumnTypes.abColDefDate),
        abColDefObject: Object.assign({}, gridOptionsColumnTypes.abColDefObject),
        abColDefCustom: Object.assign({}, gridOptionsColumnTypes.abColDefCustom),
        abColDefStringArray: Object.assign({}, gridOptionsColumnTypes.abColDefStringArray),
        abColDefNumberArray: Object.assign({}, gridOptionsColumnTypes.abColDefNumberArray),
        abColDefTupleNumberArray: Object.assign({}, gridOptionsColumnTypes.abColDefTupleNumberArray),
        abColDefObjectNumberArray: Object.assign({}, gridOptionsColumnTypes.abColDefObjectNumberArray)
      });
      const customColumnTypes = (_a = this.api.columnApi.getColumnTypes()) !== null && _a !== void 0 ? _a : [];
      for (const customColumnType of customColumnTypes) {
        if (!patchedColumnTypes[customColumnType]) {
          patchedColumnTypes[customColumnType] = {};
        }
      }
      const colTypesToEditors = getEditorsForColumnTypes(this.variant);
      // we used to patch here the column types
      // and have the editors for abColDefNumber and abColDefDate set here
      // but AG Grid seems to have a bug where if we have a custom editor
      // for a specific number column, it's applied to all abColDefNumber columns, because they
      // share the same column type
      // so we're now doing this logic in AgGridColumnAdaptapter.setupColumnCellEditor
      // and instead, here we're just assigning the editor to null
      // because if we don't assign to null, the default that AG Grid provides
      // will be used instead, and we don't want that for those column types
      Object.keys(colTypesToEditors).forEach(colType => {
        if (patchedColumnTypes[colType].cellEditor == undefined) {
          patchedColumnTypes[colType].cellEditor = null; // colTypesToEditors[colType];
          // if you dont believe me, just put colTypesToEditors[colType] in the line above instead of the null value
          // and see the editing/percentage-editor.spec.ts test fail as it wont be using the correct editor
        }
      });
      return patchedColumnTypes;
    });
    /**
     * `dataTypeDefinitions`
     */
    this.agGridOptionsService.setGridOptionsProperty(gridOptions, 'dataTypeDefinitions', () => {
      // temporary workaround until https://github.com/AdaptableTools/adaptable/issues/2230
      const revertedDateTypeDefinitions = {
        date: {
          baseDataType: 'date',
          extendsDataType: 'date',
          valueParser: null,
          valueFormatter: null,
          suppressDefaultProperties: true
        },
        dateString: {
          baseDataType: 'dateString',
          extendsDataType: 'dateString',
          valueParser: null,
          valueFormatter: null,
          suppressDefaultProperties: true
        }
      };
      return revertedDateTypeDefinitions;
    });
  }
  isDetailGrid() {
    return this._isDetailGrid;
  }
  /**
   * Either initializes the AG Grid instance or delegates it to the framework wrappers (React/Anglar)
   */
  async initializeAgGrid(gridOptions, modules, renderAgGridFrameworkComponent) {
    var _a;
    if (renderAgGridFrameworkComponent) {
      const result = await renderAgGridFrameworkComponent(gridOptions);
      if (result === false) {
        return false;
      }
      const agGridApi = result;
      // framework wrapper may have altered the context value via props
      // in that case, we have to re-populate it with the Adaptable context values
      const agGridContext = agGridApi.getGridOption('context');
      if (!agGridContext.__adaptable) {
        agGridContext.__adaptable = this;
        agGridContext.adaptableApi = this.api;
      }
      // framework wrapper may pass the rowData as a prop
      const rowData = agGridApi.getGridOption('rowData');
      this.initWithLazyData = rowData == undefined || rowData.length === 0;
      if (this.initWithLazyData) {
        this.logger.info('initWithLazyData = TRUE');
      }
      if (!this.getAgGridContainerElement()) {
        // initialize the agGridContainerElement from the AgGrid instance
        // @ts-ignore
        const gridRoot = (_a = this.DANGER_getPrivateAgGridBeans()) === null || _a === void 0 ? void 0 : _a.eGridDiv;
        const gridContainer = gridRoot === null || gridRoot === void 0 ? void 0 : gridRoot.closest('[class*="ag-theme"]');
        if (!gridContainer) {
          this.logger.consoleError(`No AG Grid container could be derived from the Adaptable framework wrapper.
            Please contact AdapTable Support and in the meantime provide a valid container element in 'ContainerOptions.agGridContainer'!`);
        }
        this.DANGER_USE_GETTER_agGridContainerElement = gridContainer;
      }
      return agGridApi;
    }
    const agGridContainer = this.getAgGridContainerElement();
    if (!agGridContainer) {
      this.logger.consoleError('No AG Grid container element found in the DOM. Please provide a valid container element in `ContainerOptions.agGridContainer`');
      return Promise.resolve(false);
    }
    let gridParams;
    if (modules === null || modules === void 0 ? void 0 : modules.length) {
      gridParams = {
        modules
      };
    }
    const agGridApi = createGrid(agGridContainer, gridOptions, gridParams);
    return agGridApi;
  }
  getColumnDefinitionsInclSpecialColumns(agGridColDefs) {
    const allColDefs = this.enhanceColDefsWithSpecialColumns(agGridColDefs !== null && agGridColDefs !== void 0 ? agGridColDefs : this.agGridAdapter.getAgGridApi().getColumnDefs());
    return allColDefs;
  }
  getSpecialColDefs() {
    const specialColDefs = [...this.api.calculatedColumnApi.internalApi.getColDefsForCalculatedColumns(), ...this.api.actionColumnApi.getColDefsForActionColumns(), ...this.api.freeTextColumnApi.internalApi.getColDefsForFreeTextColumns(), ...this.api.actionRowApi.internalApi.getColDefsForActionRowColumns(), ...this.api.fdc3Api.internalApi.getFdc3ActionColDefs()];
    this.agGridAdapter.assignColumnIdsToColDefs(specialColDefs);
    return specialColDefs;
  }
  enhanceColDefsWithSpecialColumns(agGridColDefs) {
    this.agGridAdapter.assignColumnIdsToColDefs(agGridColDefs);
    const specialColDefs = this.getSpecialColDefs();
    const isSpecialColDef = colDef => {
      const {
        type
      } = colDef;
      if (type === AB_SPECIAL_COLUMN || Array.isArray(type) && type.includes(AB_SPECIAL_COLUMN)) {
        return true;
      }
      return false;
    };
    const processedSpecialColDefIds = [];
    const mapColDefs = colDefs => {
      return colDefs.map(colDef => {
        if (this.agGridColumnAdapter.isColGroupDef(colDef)) {
          // if it's a group column, recursively map its children
          colDef.children = mapColDefs(colDef.children);
          return colDef;
        } else {
          if (!isSpecialColDef(colDef)) {
            // if it's not a special column, return it as is
            // without a minWidth, columns in details grid are VERY wide
            // so the line below fixes https://github.com/AdaptableTools/adaptable/issues/2559
            return Object.assign({
              minWidth: 10
            }, colDef);
          }
          const newlyCreatedSpecialColDef = specialColDefs.find(specialColDef => specialColDef.colId === colDef.colId);
          if (newlyCreatedSpecialColDef) {
            // if it's a special column and we have a special col def for it, return the special col def
            processedSpecialColDefIds.push(colDef.colId);
            // merge the user defined colDef with the special col def
            // this way the user may provide some custom settings for the special col def (tooltip, etc)
            const mergedColDef = Object.assign(Object.assign({
              // see above comment for minWidth
              minWidth: 10
            }, colDef), newlyCreatedSpecialColDef);
            return mergedColDef;
          } else {
            // otherwise, return the original col def
            return colDef;
          }
        }
      });
    };
    let resultColDefs = mapColDefs(agGridColDefs);
    // check if there are any special colDefs that were not processed
    // in that case, add them to the end of the colDefs
    specialColDefs.forEach(specialColDef => {
      if (!processedSpecialColDefIds.includes(specialColDef.colId)) {
        resultColDefs.push(specialColDef);
      }
    });
    // remove special column that are no longer defined
    resultColDefs = resultColDefs.filter(colDef => {
      if (isSpecialColDef(colDef)) {
        // must be in specialColDefs
        return specialColDefs.some(specialColDef => specialColDef.colId === colDef.colId);
      }
      return true;
    });
    return resultColDefs;
  }
  useRowNodeLookUp() {
    return this.agGridAdapter.getAgGridApi().getGridOption('getRowId') != undefined;
  }
  getAgGridContainerElement() {
    if (!this.DANGER_USE_GETTER_agGridContainerElement) {
      this.DANGER_USE_GETTER_agGridContainerElement = typeof this.adaptableOptions.containerOptions.agGridContainer === 'string' ? document.getElementById(this.adaptableOptions.containerOptions.agGridContainer) : this.adaptableOptions.containerOptions.agGridContainer;
    }
    return this.DANGER_USE_GETTER_agGridContainerElement;
  }
  getAdaptableContainerElement() {
    if (!this.DANGER_USE_GETTER_adaptableContainerElement) {
      this.DANGER_USE_GETTER_adaptableContainerElement = typeof this.adaptableOptions.containerOptions.adaptableContainer === 'string' ? document.getElementById(this.adaptableOptions.containerOptions.adaptableContainer) : this.adaptableOptions.containerOptions.adaptableContainer;
    }
    return this.DANGER_USE_GETTER_adaptableContainerElement;
  }
  // This method returns selected cells ONLY (if selection mode is cells or multiple cells).
  // If the selection mode is row it will returns nothing - use the setSelectedRows() method
  refreshSelectedCellsState() {
    if (!this.isGridRangeSelectable()) {
      return;
    }
    const selectedCellInfo = this.agGridAdapter.deriveSelectedCellInfoFromAgGrid();
    this.api.gridApi.internalApi.setSelectedCells(selectedCellInfo);
    this._emit('CellsSelected');
    let cellSelectionChangedInfo = Object.assign(Object.assign({}, this.api.internalApi.buildBaseContext()), {
      selectedCellInfo: this.api.gridApi.getGridState().SelectedCellInfo
    });
    this.api.eventApi.emit('CellSelectionChanged', cellSelectionChangedInfo);
    return selectedCellInfo;
  }
  refreshSelectedRowsState() {
    if (!this.isGridRowSelectable()) {
      return;
    }
    const selectedRowInfo = this.agGridAdapter.deriveSelectedRowInfoFromAgGrid();
    this.api.gridApi.internalApi.setSelectedRows(selectedRowInfo);
    const rowSelectionChangedInfo = Object.assign(Object.assign({}, this.api.internalApi.buildBaseContext()), {
      selectedRowInfo: this.api.gridApi.getGridState().SelectedRowInfo
    });
    this.api.eventApi.emit('RowSelectionChanged', rowSelectionChangedInfo);
    return selectedRowInfo;
  }
  isGridRowSelectable() {
    const rowSelection = this.agGridAdapter.getAgGridApi().getGridOption('rowSelection');
    return rowSelection === 'single' || rowSelection === 'multiple';
  }
  isGridRangeSelectable() {
    return this.agGridAdapter.isModulePresent(ModuleNames.RangeSelectionModule) && this.agGridAdapter.getGridOption('enableRangeSelection');
  }
  initAdaptableStore() {
    const perfNewAdaptableStore = this.logger.beginPerf(`initAdaptableStore()`);
    const adaptableStore = new AdaptableStore(this);
    adaptableStore.onAny((eventName, data) => {
      this.performAudit(data.action, data.state, data.newState);
      this.forPlugins(plugin => plugin.onStoreEvent(eventName, data, this.adaptableStore));
    });
    perfNewAdaptableStore.end();
    return adaptableStore;
  }
  mapAdaptableStateToAgGridState(adaptableState, agGridColDefs) {
    var _a, _b, _c;
    const agGridState = {};
    const currentLayoutName = (_a = adaptableState.Layout) === null || _a === void 0 ? void 0 : _a.CurrentLayout;
    const _currentLayout = currentLayoutName && ((_c = (_b = adaptableState.Layout) === null || _b === void 0 ? void 0 : _b.Layouts) === null || _c === void 0 ? void 0 : _c.find(l => l.Name === currentLayoutName));
    if (!_currentLayout) {
      return agGridState;
    }
    const currentLayout = removeUuidAndSource(_currentLayout);
    const allAgGridFlattenedColDefs = this.agGridAdapter.getFlattenedColDefs(agGridColDefs);
    const allAgGridColDefIds = allAgGridFlattenedColDefs.map(colDef => colDef.colId);
    const {
      columnApi
    } = this.api;
    // also add the row group columns, if they are specified in the layout
    currentLayout.Columns.forEach(colId => {
      if (columnApi.isAutoRowGroupColumn(colId)) {
        allAgGridColDefIds.push(colId);
      }
    });
    const getColDef = colId => allAgGridFlattenedColDefs.find(colDef => colDef.colId === colId);
    agGridState.columnVisibility = {
      hiddenColIds: allAgGridFlattenedColDefs.filter(colDef => {
        var _a;
        return !((_a = currentLayout.Columns) === null || _a === void 0 ? void 0 : _a.includes(colDef.colId)) || colDef.hide || colDef.initialHide;
      }).map(colDef => colDef.colId)
    };
    agGridState.columnOrder = {
      orderedColIds: ArrayExtensions.sortArrayWithOrder(allAgGridColDefIds, currentLayout.Columns || [], {
        sortUnorderedItems: false
      })
    };
    if (currentLayout.ColumnWidthMap) {
      agGridState.columnSizing = {
        columnSizingModel: Object.keys(currentLayout.ColumnWidthMap).map(colId => {
          const width = currentLayout.ColumnWidthMap[colId];
          return {
            colId,
            width
          };
        })
      };
    }
    if (currentLayout.ColumnSorts) {
      agGridState.sort = {
        sortModel: currentLayout.ColumnSorts.map(columnSort => {
          return {
            colId: columnSort.ColumnId,
            sort: columnSort.SortOrder === 'Asc' ? 'asc' : 'desc'
          };
        })
      };
    }
    if (currentLayout.RowGroupedColumns) {
      agGridState.rowGroup = {
        groupColIds: currentLayout.RowGroupedColumns
      };
    }
    if (currentLayout.AggregationColumns) {
      agGridState.aggregation = {
        aggregationModel: Object.keys(currentLayout.AggregationColumns).map(colId => {
          let aggFunc = currentLayout.AggregationColumns[colId];
          if (aggFunc === true) {
            const colDef = getColDef(colId);
            // fallback to SUM if no defaultAggFunc is defined
            aggFunc = (colDef === null || colDef === void 0 ? void 0 : colDef.defaultAggFunc) || 'sum';
          }
          if (isWeightedAverageAggregation(aggFunc)) {
            aggFunc = WEIGHTED_AVERAGE_AGG_FN_NAME;
          }
          return {
            colId,
            aggFunc
          };
        })
      };
    }
    if (currentLayout.PivotColumns || currentLayout.EnablePivot) {
      agGridState.pivot = {
        pivotMode: currentLayout.EnablePivot,
        pivotColIds: currentLayout.PivotColumns || []
      };
    }
    if (currentLayout.PinnedColumnsMap) {
      const columnPinning = {
        leftColIds: [],
        rightColIds: []
      };
      Object.keys(currentLayout.PinnedColumnsMap).forEach(colId => {
        const pinned = currentLayout.PinnedColumnsMap[colId];
        if (pinned === 'left') {
          columnPinning.leftColIds.push(colId);
        } else if (pinned === 'right') {
          columnPinning.rightColIds.push(colId);
        }
      });
      agGridState.columnPinning = columnPinning;
    }
    return agGridState;
  }
  addGridEventListeners() {
    /**
     * Intercept DOM events and emit them as Adaptable events
     */
    const gridContainerElement = this.getAgGridContainerElement();
    if (gridContainerElement) {
      gridContainerElement.addEventListener('keydown', this.agGridListenerKeydown = event => this._emit('KeyDown', event),
      // This is needed to be able to prevent the editor to be opened
      // in bubling phase the opening is not prevented with ag-grid v30
      true);
      gridContainerElement.addEventListener('mouseenter', this.agGridListenerMouseEnter = event => {
        this._emit('MouseEnter', event);
      }, true);
      gridContainerElement.addEventListener('mouseleave', this.agGridListenerMouseLeave = event => this._emit('MouseLeave', event));
    }
    this.throttleFilterOnEditDataChange = throttle(
    // the extra function is to make sure we have a reference to ag-grid-api
    () => {
      var _a;
      return (_a = this.agGridAdapter.getAgGridApi()) === null || _a === void 0 ? void 0 : _a.onFilterChanged();
    }, this.adaptableOptions.columnFilterOptions.filterActionOnUserDataChange.throttleDelay, {
      trailing: true,
      leading: false
    });
    this.throttleFilterOnTickingDataChange = throttle(() => {
      var _a;
      return (_a = this.agGridAdapter.getAgGridApi()) === null || _a === void 0 ? void 0 : _a.onFilterChanged();
    }, this.adaptableOptions.columnFilterOptions.filterActionOnExternalDataChange.throttleDelay, {
      trailing: true,
      leading: false
    });
    /**
     * Use Case: AG Grid columns have changed
     * Action: Set Columns in store and filter grid
     */
    this.debouncedSetColumnIntoStore = debounce(() => {
      if (!this.isReady) {
        return;
      }
      this.deriveAdaptableColumnStateFromAgGrid();
    }, HALF_SECOND);
    const columnEventsThatTriggersStateChange = ['columnMoved', 'gridColumnsChanged', 'columnEverythingChanged', 'displayedColumnsChanged', 'columnVisible', 'newColumnsLoaded'];
    this.agGridAdapter.getAgGridApi().addGlobalListener(this.listenerGlobalColumnEventsThatTriggerStateChange = type => {
      if (columnEventsThatTriggersStateChange.indexOf(type) > -1) {
        this.debouncedSetColumnIntoStore();
      }
    });
    /**
     * Use Case: User has started inline editing but its distabled in Action Row Options
     * Action: Stop editing
     */
    this.agGridAdapter.getAgGridApi().addEventListener('cellEditingStarted', this.listenerCellEditingStarted = () => {
      var _a;
      if ((_a = this.adaptableOptions.actionRowOptions) === null || _a === void 0 ? void 0 : _a.disableInlineEditing) this.agGridAdapter.getAgGridApi().stopEditing();
    });
    /**
     * Use Case: initial data has been displayed in grid
     * Action1: Set the Layout
     * Action2: Ensure that we have set column data types
     * Note: Deals with scenario where the data is provided to AdapTable after grid has been setup
     */
    this.agGridAdapter.getAgGridApi().addEventListener('firstDataRendered', this.listenerFirstDataRendered = () => {
      if (this.initWithLazyData) {
        this.updateColumnModelAndRefreshGrid({
          skipColDefsRefresh: true
        });
        this.api.calculatedColumnApi.refreshAggregatedCalculatedColumns();
        this._emit('FirstDataRendered');
      }
      this.autoSizeLayoutIfNeeded();
    });
    /**
     * Use Case: Entered or Left Pivot Mode
     * Action 1: Autosize pivot columns when entering pivot mode (if autosize pivot in Layout is true)
     * Action 2: Set pivot mode on / off in api as necessary
     */
    this.agGridAdapter.getAgGridApi().addEventListener('columnPivotModeChanged', this.listenerPivotModeChanged = params => {
      if (params.type == 'columnPivotModeChanged' && params.columnApi != null && params.columnApi.columnController != null && params.columnApi.columnController.pivotMode == true) {
        if (this.adaptableOptions.layoutOptions.autoSizeColumnsInPivotLayout == true) {
          this.agGridAdapter.getAgGridApi().autoSizeAllColumns();
        }
        this.api.internalApi.setPivotModeOn();
      } else {
        this.api.internalApi.setPivotModeOff();
      }
    });
    /**
     * Use Case: A pivot column has changed
     * Action: Autosize pivot columns (if autosize pivot in Layout is true)
     */
    this.agGridAdapter.getAgGridApi().addEventListener('columnPivotChanged', this.listenerPivotChanged = params => {
      if (params.type == 'columnPivotChanged' && params.columnApi != null && params.columnApi.columnController != null && params.columnApi.columnController.pivotMode == true) {
        if (this.adaptableOptions.layoutOptions.autoSizeColumnsInPivotLayout == true) {
          this.agGridAdapter.getAgGridApi().autoSizeAllColumns();
        }
      }
    });
    /**
     * Use Case: Things have changed in the grid that require the Layout to be saved
     * Action: Save the Layout (on a debounce)
     */
    const columnEventsThatTriggersAutoLayoutSave = ['columnPinned', 'columnPivotChanged', 'columnPivotModeChanged', 'displayedColumnsChanged', 'sortChanged', 'columnRowGroupChanged', 'columnValueChanged'];
    // ADD filter event
    this.agGridAdapter.getAgGridApi().addGlobalListener(this.listenerGlobalColumnEventsThatTriggerAutoLayoutSave = type => {
      if (columnEventsThatTriggersAutoLayoutSave.indexOf(type) > -1) {
        this.debouncedSaveGridLayout(type);
      }
    });
    /**
     * Save Layout if Display Row Groups is "dynamic
     */
    this.debouncedSaveGridLayout = debounce(type => {
      if (!this.isReady) {
        return;
      }
      this.logger.info('Event Triggering Auto Layout Save', type || '');
      this.updateLayoutFromGrid();
    }, HALF_SECOND);
    const rowGroupEventsThatTriggersAutoLayoutSave = ['rowGroupOpened', 'expandOrCollapseAll'];
    this.agGridAdapter.getAgGridApi().addGlobalListener(this.listenerGlobalRowGroupEventsThatTriggerAutoLayoutSave = type => {
      if (rowGroupEventsThatTriggersAutoLayoutSave.indexOf(type) > -1) {
        if (this.adaptableOptions.layoutOptions.displayRowGroups == 'dynamic') {
          this.debouncedSaveGridLayout(type);
        }
      }
    });
    /**
     * Use Case: Column Row Grouping changes and 'restoreUngroupedColumns' is true
     * Action: Make the column invisiblel
     */
    this.agGridAdapter.getAgGridApi().addEventListener('columnRowGroupChanged', this.listenerColumnRowGroupChanged = params => {
      var _a, _b;
      if (this.api.internalApi.isGridInPivotMode()) {
        return;
      }
      if ((_b = (_a = this.adaptableOptions) === null || _a === void 0 ? void 0 : _a.groupingOptions) === null || _b === void 0 ? void 0 : _b.restoreUngroupedColumns) {
        this.persistColumnIndexBeforeGrouping(params);
      }
    });
    /**
     * Use Case: A Column has finished being resized
     * Action 1: Save the Layout (on a debounce)
     * Action 2: Emit the internal ColumnResized event - used by Sparkline Column (in Charts)
     */
    this.agGridAdapter.getAgGridApi().addEventListener('columnResized', this.listenerColumnResized = params => {
      if (params.finished == true && params.type == 'columnResized' && params.column) {
        this.debouncedSaveGridLayout(params.type);
      }
    });
    /**
     * Use Case: Row Selection has changed
     * Action: Set Selected Rows (on a debeounce)
     */
    this.debouncedSetSelectedRows = debounce(() => {
      if (!this.isReady) {
        return;
      }
      this.refreshSelectedRowsState();
    }, HALF_SECOND);
    const columnEventsThatTriggerSetRowSelection = ['rowGroupOpened', 'selectionChanged', 'rowSelected'];
    this.agGridAdapter.getAgGridApi().addGlobalListener(this.listenerGlobalSetRowSelection = type => {
      if (ArrayExtensions.ContainsItem(columnEventsThatTriggerSetRowSelection, type)) {
        this.debouncedSetSelectedRows();
      }
    });
    /**
     * Use Case: User has selected a range of cells
     * Action: Set Selected Cells (on a debounce)
     */
    this.debouncedSetSelectedCells = debounce(() => {
      if (!this.isReady) {
        return;
      }
      this.refreshSelectedCellsState();
    }, 250);
    this.agGridAdapter.getAgGridApi().addEventListener('rangeSelectionChanged', this.listenerRangeSelectionChanged = params => {
      if (params.finished == true) {
        this.debouncedSetSelectedCells();
      }
    });
    /**
     * Use Case: Sort has changed in the Grid
     * Action1: Update AdapTable Sort Info
     * Action2: Set Selected Cells (on a debounce)
     */
    this.agGridAdapter.getAgGridApi().addEventListener('sortChanged', this.listenerSortChanged = () => {
      this.onSortChanged();
      this.debouncedSetSelectedCells();
    });
    const eventsThatTriggerChartingChanges = ['chartCreated', /** Chart Range selection has changed */
    'chartRangeSelectionChanged', /** Chart Options have changed */
    'chartOptionsChanged', /** Chart was destroyed */
    'chartDestroyed'];
    const chartingModule = this.ModuleService.getModuleById('Charting');
    if (chartingModule.isModuleAvailable()) {
      this.agGridAdapter.getAgGridApi().addGlobalListener((type, params) => {
        if (ArrayExtensions.ContainsItem(eventsThatTriggerChartingChanges, type)) {
          this.ChartingService.onChartModelChange(this.getChartModels(), type, params);
        }
      });
    }
    /**
     * Row and Cell listeners created in 2020
     * These have supplanted many of the events we previously had and simplified things
     */
    this.rowListeners = {
      dataChanged: event => {
        this.onRowDataChanged({
          rowNode: event.node,
          oldData: event.oldData,
          newData: event.newData
        });
      },
      cellChanged: event => {
        if (event.column) {
          this.onCellDataChanged({
            rowNode: event.node,
            oldValue: event.oldValue,
            newValue: event.newValue,
            colId: event.column.colId
          });
        }
      }
    };
  }
  shouldAutoSizeLayout() {
    const {
      layoutApi
    } = this.api;
    const {
      layoutOptions
    } = this.adaptableOptions;
    const currentLayout = layoutApi.getCurrentLayout();
    if (currentLayout.ColumnWidthMap && Object.keys(currentLayout.ColumnWidthMap).length > 0) {
      return;
    }
    const autoSize = currentLayout.EnablePivot ? layoutOptions.autoSizeColumnsInPivotLayout : layoutOptions.autoSizeColumnsInLayout;
    return autoSize;
  }
  autoSizeLayoutIfNeeded() {
    if (this.shouldAutoSizeLayout()) {
      requestAnimationFrame(() => {
        this.autoSizeAllColumns();
      });
    }
  }
  performAudit(action, oldState, newState) {
    if (this.isReady) {
      const adaptableStateChangedInfo = Object.assign(Object.assign({
        actionName: action.type,
        clientTimestamp: new Date()
      }, this.api.internalApi.buildBaseContext()), {
        adaptableStateKey: this.adaptableOptions.adaptableStateKey,
        action: action,
        oldState: oldState,
        newState: newState
      });
      this.api.eventApi.emit('AdaptableStateChanged', adaptableStateChangedInfo);
    }
  }
  forPlugins(callback) {
    if (Array.isArray(this.adaptableOptions.plugins)) {
      this.adaptableOptions.plugins.forEach(plugin => {
        callback(plugin);
      });
    }
  }
  getPluginProperty(pluginId, propertyName, ...args) {
    const plugins = this.adaptableOptions.plugins || [];
    const thePlugin = plugins.filter(p => p.pluginId === pluginId)[0];
    if (!thePlugin) {
      throw `Cannot find plugin "${pluginId}". Make sure you spelled it right!`;
    }
    if (thePlugin && thePlugin.hasProperty(propertyName)) {
      return thePlugin.getProperty(propertyName)(...args);
    }
  }
  getPlugin(pluginId) {
    const plugins = this.adaptableOptions.plugins || [];
    const thePlugin = plugins.filter(p => p.pluginId === pluginId)[0];
    if (!thePlugin) {
      throw `Cannot find plugin "${pluginId}". Make sure you spelled it right!`;
    }
    return thePlugin;
  }
  initServices() {
    // create the services
    this.LicenseService = this.initLicenseService();
    this.ChartingService = new ChartingService(this.api);
    this.ThemeService = new ThemeService(this.api);
    this.ValidationService = new ValidationService(this.api);
    this.ModuleService = new ModuleService(this.api);
    this.CalculatedColumnExpressionService = new CalculatedColumnExpressionService(this.api);
    this.QueryLanguageService = new QueryLanguageService(this.api);
    this.AlertService = new AlertService(this.api);
    this.TeamSharingService = new TeamSharingService(this.api);
    this.Fdc3Service = new Fdc3Service(this.api);
    this.CellPopupService = new CellPopupService(this.api);
    this.RowEditService = new RowEditService(this.api);
    this.MetamodelService = new MetamodelService(() => this.api.optionsApi.getAdaptableOptions(), true);
  }
  initLicenseService() {
    const globalObject = typeof globalThis !== 'undefined' ? globalThis : window;
    const licenseKey = globalObject.ADAPTABLE_LICENSE_KEY || this.api.optionsApi.getLicenseKey();
    return new LicenseService(this, licenseKey, {
      publishedAt: publishTimestamp
    });
  }
  initModules() {
    const modules = new Map();
    modules.set(ModuleConstants.AlertModuleId, new AlertModule(this.api));
    modules.set(ModuleConstants.BulkUpdateModuleId, new BulkUpdateModule(this.api));
    modules.set(ModuleConstants.CalculatedColumnModuleId, new CalculatedColumnModule(this.api));
    modules.set(ModuleConstants.CellSummaryModuleId, new CellSummaryModule(this.api));
    modules.set(ModuleConstants.ChartingModuleId, new ChartingModule(this.api));
    modules.set(ModuleConstants.ColumnFilterModuleId, new ColumnFilterModule(this.api));
    modules.set(ModuleConstants.ColumnInfoModuleId, new ColumnInfoModule(this.api));
    modules.set(ModuleConstants.CommentModuleId, new CommentModule(this.api));
    modules.set(ModuleConstants.CustomSortModuleId, new CustomSortModule(this.api));
    modules.set(ModuleConstants.DashboardModuleId, new DashboardModule(this.api));
    modules.set(ModuleConstants.DataChangeHistoryModuleId, new DataChangeHistoryModule(this.api));
    modules.set(ModuleConstants.DataImportModuleId, new DataImportModule(this.api));
    modules.set(ModuleConstants.DataSetModuleId, new DataSetModule(this.api));
    modules.set(ModuleConstants.ExportModuleId, new ExportModule(this.api));
    modules.set(ModuleConstants.Fdc3ModuleId, new Fdc3Module(this.api));
    modules.set(ModuleConstants.FlashingCellModuleId, new FlashingCellModule(this.api));
    modules.set(ModuleConstants.FormatColumnModuleId, new FormatColumnModule(this.api));
    modules.set(ModuleConstants.FreeTextColumnModuleId, new FreeTextColumnModule(this.api));
    modules.set(ModuleConstants.GridFilterModuleId, new GridFilterModule(this.api));
    modules.set(ModuleConstants.GridInfoModuleId, new GridInfoModule(this.api));
    modules.set(ModuleConstants.LayoutModuleId, new LayoutModule(this.api));
    modules.set(ModuleConstants.NamedQueryModuleId, new NamedQueryModule(this.api));
    modules.set(ModuleConstants.NoteModuleId, new NoteModule(this.api));
    modules.set(ModuleConstants.PlusMinusModuleId, new PlusMinusModule(this.api));
    modules.set(ModuleConstants.QuickSearchModuleId, new QuickSearchModule(this.api));
    modules.set(ModuleConstants.ScheduleModuleId, new ScheduleModule(this.api));
    modules.set(ModuleConstants.SettingsPanelModuleId, new SettingsPanelModule(this.api));
    modules.set(ModuleConstants.ShortcutModuleId, new ShortcutModule(this.api));
    modules.set(ModuleConstants.SmartEditModuleId, new SmartEditModule(this.api));
    modules.set(ModuleConstants.StateManagementModuleId, new StateManagementModule(this.api));
    modules.set(ModuleConstants.StatusBarModuleId, new StatusBarModule(this.api));
    modules.set(ModuleConstants.StyledColumnModuleId, new StyledColumnModule(this.api));
    modules.set(ModuleConstants.SystemStatusModuleId, new SystemStatusModule(this.api));
    modules.set(ModuleConstants.TeamSharingModuleId, new TeamSharingModule(this.api));
    modules.set(ModuleConstants.ThemeModuleId, new ThemeModule(this.api));
    modules.set(ModuleConstants.ToolPanelModuleId, new ToolPanelModule(this.api));
    return modules;
  }
  /**
   * This method contains all the updates on the AdaptableState which were made AFTER Adaptbale was ready
   * This was contidioned because we required AG Grid to be ready before we could make these updates
   * We should be able to refactor the code, no that we no fore sure that Adaptable State is ready BEFORE AG Grid init
   */
  temporaryAdaptableStateUpdates() {
    this.api.eventApi.on('AdaptableReady', () => {
      var _a, _b;
      // update status bar state
      const adaptableStatusPanels = (_b = (_a = this.agGridAdapter.getAgGridApi().getGridOption('statusBar')) === null || _a === void 0 ? void 0 : _a.statusPanels) === null || _b === void 0 ? void 0 : _b.filter(statusPanel => this.adaptableStatusPanelKeys.includes(statusPanel.key));
      const statusBarModule = this.ModuleService.getModuleById(ModuleConstants.StatusBarModuleId);
      // need to add only the adaptable panels
      statusBarModule.syncStateWithOptions(adaptableStatusPanels);
    });
  }
  validatePrimaryKey() {
    var _a;
    if (this.hasAutogeneratedPrimaryKey) {
      return;
    }
    const primaryKey = this.adaptableOptions.primaryKey;
    // first check if there is a primary key column
    const primaryKeyColDef = this.agGridAdapter.getAgGridApi().getColumnDef(primaryKey);
    if (!primaryKeyColDef) {
      let errorMessage;
      // if no primary key column then lets check the first row to see if its a data item
      const primaryKeyDataItem = (_a = this.getFirstRowNode()) === null || _a === void 0 ? void 0 : _a.data[primaryKey];
      if (!primaryKeyDataItem) {
        errorMessage = `The Primary Key '${this.adaptableOptions.primaryKey}' does not exist.  This will affect many functions in AdapTable.`;
        if (this.adaptableOptions.alertOptions.showMissingPrimaryKeyAlert) {
          // show an alert if that is the option
          this.api.alertApi.showAlertError('No Primary Key', errorMessage);
        } else {
          if (this.adaptableOptions.columnOptions.showMissingColumnsWarning) {
            this.logger.consoleError(errorMessage);
          }
        }
      }
    }
  }
  deriveAdaptableColumnStateFromAgGrid() {
    const allColumns = [];
    const agGridCols = this.agGridAdapter.getAgGridApi().getColumns();
    const columnGroupChildren = this.agGridAdapter.getAgGridApi()
    // TODO AFL MIG: check why this assertion is here
    .getAllDisplayedColumnGroups();
    const groupsCount = {};
    const colsToGroups = (columnGroupChildren !== null && columnGroupChildren !== void 0 ? columnGroupChildren : []).reduce((acc, columnGroup) => {
      var _a, _b, _c;
      if (!((_b = (_a = columnGroup.getProvidedColumnGroup) === null || _a === void 0 ? void 0 : _a.call(columnGroup)) === null || _b === void 0 ? void 0 : _b.getColGroupDef())) {
        return acc;
      }
      const ColumnGroupId = columnGroup.getGroupId();
      const AllowGroupSplit = !columnGroup.getProvidedColumnGroup().getColGroupDef().marryChildren;
      const FriendlyName = (_c = columnGroup.getProvidedColumnGroup().getColGroupDef().headerName) !== null && _c !== void 0 ? _c : ColumnGroupId;
      const columnsInGroup = columnGroup.getLeafColumns();
      columnsInGroup.forEach(col => {
        const group = {
          columnGroupId: ColumnGroupId,
          friendlyName: FriendlyName,
          allowGroupSplit: AllowGroupSplit,
          groupCount: 0
        };
        groupsCount[group.columnGroupId] = groupsCount[group.columnGroupId] || 0;
        groupsCount[group.columnGroupId] += columnsInGroup.length;
        acc[col.getColId()] = group;
      });
      return acc;
    }, {});
    Object.keys(colsToGroups).forEach(colId => {
      colsToGroups[colId].groupCount = groupsCount[colsToGroups[colId].columnGroupId];
    });
    // TODO sort the visible columns by layout order
    agGridCols.forEach(agGridColumn => {
      const colId = agGridColumn.getColId();
      if (!this.api.columnApi.isAutoRowGroupColumn(colId)) {
        allColumns.push(this.agGridAdapter.createAdaptableColumnFromAgGridColumn(agGridColumn, colsToGroups));
      }
    });
    this.api.gridApi.internalApi.setColumns(allColumns);
  }
  checkShouldClearExistingFiltersOrSearches() {
    // if they have selected to clear column filters on startup then do it
    if (this.adaptableOptions.columnFilterOptions.clearColumnFiltersOnStartUp) {
      if (ArrayExtensions.IsNotNullOrEmpty(this.api.columnFilterApi.getColumnFilters())) {
        this.logger.warn('Clearing existing Column Filters as "clearColumnFiltersOnStartUp" is true');
        this.api.columnFilterApi.clearColumnFilters();
      }
    }
    // if they have selected to clear the Grid filter on startup then do it
    if (this.adaptableOptions.gridFilterOptions.clearGridFilterOnStartUp) {
      if (StringExtensions.IsNotNullOrEmpty(this.api.gridFilterApi.getCurrentGridFilterExpression())) {
        this.logger.warn('Clearing existing Grid Filter as "clearGridFilterOnStartUp" is true');
        this.api.gridFilterApi.setGridFilterExpression('');
      }
    }
    // if they have selected to clear searches on startup then do it
    if (this.adaptableOptions.quickSearchOptions.clearQuickSearchOnStartUp) {
      if (StringExtensions.IsNotNullOrEmpty(this.api.quickSearchApi.getQuickSearchState().QuickSearchText)) {
        this.logger.warn('Clearing existing Searches as "clearQuickSearchOnStartUp" is true');
        this.api.quickSearchApi.clearQuickSearch();
      }
    }
  }
  getGridCellFromRowNode(rowNode, columnId) {
    if (rowNode == null) {
      return undefined;
    }
    const abColumn = this.api.columnApi.getColumnWithColumnId(columnId);
    const pkValue = this.getPrimaryKeyValueFromRowNode(rowNode);
    const rawValue = this.getRawValueFromRowNode(rowNode, columnId);
    const displayValue = this.getDisplayValueFromRawValue(rowNode, columnId, rawValue);
    const normalisedValue = this.getNormalisedValueFromRawValue(rawValue, abColumn);
    const isPivotCell = this.api.columnApi.isAutoPivotColumn(columnId);
    const isRowGroupCell = rowNode.group;
    return {
      rawValue: rawValue,
      displayValue: displayValue,
      normalisedValue: normalisedValue,
      column: abColumn,
      primaryKeyValue: pkValue,
      rowNode: rowNode,
      isPivotCell,
      isRowGroupCell
    };
  }
  getPrimaryKeyValueFromRowNode(rowNode, gridApi) {
    if (!rowNode) {
      return null;
    }
    const agGridApi = gridApi || this.agGridAdapter.getAgGridApi();
    let result;
    if (!this.hasAutogeneratedPrimaryKey) {
      // support both AG Grid pre & post v31.3.x
      // TODO remove this with the next major Adaptable version
      result = this.agGridAdapter._agGridApi_getValue(this.adaptableOptions.primaryKey, rowNode, agGridApi);
    }
    if (result == undefined && rowNode.data) {
      result = rowNode.data[this.adaptableOptions.primaryKey];
    }
    if (result == undefined && rowNode.id != undefined) {
      // when getPrimaryKeyValueFromRowNode is called for group rows,
      // which don't have a rowNode.data object, the result is undefined
      // but we do have an id property on the rowNode object, so we'll use that
      result = rowNode.id;
    }
    return result;
  }
  getRawValueFromRowNode(rowNode, columnId) {
    if (rowNode == null) {
      return undefined;
    }
    return this.agGridAdapter._agGridApi_getValue(columnId, rowNode);
  }
  getDisplayValueFromRowNode(rowNode, columnId) {
    if (rowNode == null) {
      return undefined;
    }
    const rawValue = this.getRawValueFromRowNode(rowNode, columnId);
    return this.getDisplayValueFromRawValue(rowNode, columnId, rawValue);
  }
  getDisplayValueFromRawValue(rowNode, columnId, rawValue) {
    const isActionColumn = this.api.columnApi.isActionColumn(columnId);
    if (isActionColumn) {
      return this.getCleanValue(rawValue);
    }
    const colDef = this.agGridAdapter.getAgGridApi().getColumnDef(columnId);
    if (colDef) {
      if (typeof colDef.valueFormatter == 'function') {
        const column = this.agGridAdapter.getAgGridApi().getColumn(columnId);
        const params = {
          value: rawValue,
          node: rowNode,
          data: rowNode.data,
          colDef,
          column,
          api: this.agGridAdapter.getAgGridApi(),
          context: this.agGridAdapter.getGridOption('context')
        };
        const formattedValue = colDef.valueFormatter(params);
        return formattedValue || '';
      }
    }
    return this.getCleanValue(rawValue);
  }
  getCleanValue(value) {
    if (typeof value === 'string') {
      return value;
    }
    if (value == null || value == 'null' || value == undefined || value == 'undefined') {
      return undefined;
    }
    return String(value) || '';
  }
  getNormalisedValueFromRawValue(rawValue, column) {
    if (!column) {
      return rawValue;
    }
    // prevents from null
    if (rawValue === undefined || rawValue === null) {
      return rawValue;
    }
    const dataType = column.dataType;
    if (dataType === 'String') {
      return typeof rawValue !== 'string' ? String(rawValue) : rawValue;
    }
    if (dataType === 'Number') {
      // empty string or space should not be converted to 0
      return typeof rawValue !== 'number' && StringExtensions.IsNumeric(rawValue) ? Number(rawValue) : rawValue;
    }
    if (dataType === 'Boolean') {
      return typeof rawValue !== 'boolean' ? Boolean(rawValue) : rawValue;
    }
    if (dataType === 'Date') {
      return rawValue instanceof Date ? rawValue : parseDateValue(rawValue);
    }
    return rawValue;
  }
  updateColumnModelAndRefreshGrid(config) {
    if (config === null || config === void 0 ? void 0 : config.preemptiveColumnStateRefresh) {
      this.deriveAdaptableColumnStateFromAgGrid();
    }
    if (!(config === null || config === void 0 ? void 0 : config.skipColDefsRefresh)) {
      this.refreshColDefs();
    }
    this.deriveAdaptableColumnStateFromAgGrid();
    this.agGridColumnAdapter.setupColumns();
    this.redrawBody();
    this.refreshHeader();
  }
  redrawBody() {
    this.redrawRows();
    this._emit('GridRefreshed');
  }
  refreshHeader() {
    this.agGridAdapter.getAgGridApi().refreshHeader();
  }
  // TODO AFL: this method seems to be used A LOT
  // we should check if we couldn't use the `refresh` method instead for some cases
  // see https://www.ag-grid.com/react-data-grid/view-refresh/#redraw-rows
  redrawRows(rowNodes) {
    const redrawRowsParams = rowNodes ? {
      rowNodes
    } : undefined;
    try {
      this.agGridAdapter.getAgGridApi().redrawRows(redrawRowsParams);
    } catch (ex) {
      this.logger.consoleError('AG Grid redrawRows was unable to find some row nodes. Tried to redraw row nodes: ', rowNodes, ex);
    }
  }
  redrawRow(rowNode) {
    this.redrawRows([rowNode]);
  }
  refreshCells(rowNodes, columns, forceUpdate, suppressFlash = false) {
    const refreshCellParams = {
      rowNodes,
      columns: columns,
      force: forceUpdate,
      suppressFlash
    };
    this.agGridAdapter.getAgGridApi().refreshCells(refreshCellParams);
  }
  refreshColumns(columns, forceUpdate, suppressFlash) {
    this.refreshCells(null, columns, forceUpdate, suppressFlash);
  }
  jumpToRow(rowNode) {
    this.agGridAdapter.getAgGridApi().ensureNodeVisible(rowNode, 'middle');
  }
  jumpToColumn(columnId) {
    this.agGridAdapter.getAgGridApi().ensureColumnVisible(columnId);
  }
  jumpToCell(columnId, rowNode) {
    this.jumpToRow(rowNode);
    this.jumpToColumn(columnId);
  }
  selectColumn(columnId, config) {
    if (!(config === null || config === void 0 ? void 0 : config.keepExistingSelection)) {
      this.agGridAdapter.getAgGridApi().clearRangeSelection();
    }
    const cellRangeParams = {
      rowStartIndex: 0,
      rowEndIndex: this.agGridAdapter.getAgGridApi().getDisplayedRowCount() - 1,
      columnStart: columnId,
      columnEnd: columnId
    };
    this.agGridAdapter.getAgGridApi().addCellRange(cellRangeParams);
  }
  selectColumns(columnIds, config) {
    if (!(config === null || config === void 0 ? void 0 : config.keepExistingSelection)) {
      this.agGridAdapter.getAgGridApi().clearRangeSelection();
    }
    const rowCount = this.agGridAdapter.getAgGridApi().getDisplayedRowCount();
    columnIds.forEach(colId => {
      const cellRangeParams = {
        rowStartIndex: 0,
        rowEndIndex: rowCount - 1,
        columnStart: colId,
        columnEnd: colId
      };
      this.agGridAdapter.getAgGridApi().addCellRange(cellRangeParams);
    });
  }
  selectAll() {
    this.agGridAdapter.getAgGridApi().selectAll();
  }
  deselectAll() {
    // need to do both as first just clears selected rows and second clears ranges
    this.agGridAdapter.getAgGridApi().deselectAll();
    this.agGridAdapter.getAgGridApi().clearRangeSelection();
  }
  setGridData(dataSource) {
    if (!this.isReady) {
      return;
    }
    if (this.hasAutogeneratedPrimaryKey) {
      this.addSyntheticPrimaryKey(dataSource);
    }
    this.agGridAdapter.setGridOption('rowData', dataSource);
    this.updateRowGroupsExpandedState();
    this.updateColumnModelAndRefreshGrid();
  }
  getGridData() {
    const data = [];
    this.agGridAdapter.getAgGridApi().forEachNode(rowNode => {
      if (!this.isGroupRowNode(rowNode)) {
        data.push(rowNode.data);
      }
    });
    return data;
  }
  addSyntheticPrimaryKey(rowData = []) {
    for (let i = 0; i < rowData.length; i++) {
      rowData[i][AUTOGENERATED_PK_COLUMN] = createUuid();
    }
  }
  // add a synthetic PK only if missing (useful in case of updating row data)
  addSyntheticPrimaryKeyIfMissing(rowData = []) {
    this.adaptableOptions.primaryKey = AUTOGENERATED_PK_COLUMN;
    for (let i = 0; i < rowData.length; i++) {
      if (!rowData[i][AUTOGENERATED_PK_COLUMN]) {
        rowData[i][AUTOGENERATED_PK_COLUMN] = createUuid();
      }
    }
  }
  getFirstDisplayedRowNode() {
    const firstDisplayedRowIndex = this.agGridAdapter.getAgGridApi().getFirstDisplayedRowIndex();
    return this.agGridAdapter.getAgGridApi().getDisplayedRowAtIndex(firstDisplayedRowIndex);
  }
  getFirstRowNode() {
    let firstRowNode = this.getFirstDisplayedRowNode();
    if (firstRowNode === null || firstRowNode === void 0 ? void 0 : firstRowNode.group) {
      // all groups may be closed so it is safer to get first leaf node
      // all groups should have at least one leafe node (though not necessarily if using SSRM)
      if (ArrayExtensions.IsNotNullOrEmpty(firstRowNode.allLeafChildren)) {
        firstRowNode = firstRowNode.allLeafChildren[0];
      }
    }
    return firstRowNode;
  }
  updateRowGroupsExpandedState(layout) {
    if (!layout) {
      layout = this.api.layoutApi.getCurrentLayout();
    }
    if (this.api.layoutApi.internalApi.areExpandedRowGroupsSavedInLayouts() && ArrayExtensions.IsNotNullOrEmpty(layout.ExpandedRowGroupValues)) {
      this.expandRowGroupsForValues(layout.ExpandedRowGroupValues);
    }
    if (this.adaptableOptions.layoutOptions.displayRowGroups === 'expanded') {
      this.expandAllRowGroups();
    }
  }
  isGroupRowNode(rowNode) {
    if (!rowNode) {
      return false;
    }
    if (rowNode.isEmptyRowGroupNode()) {
      return true;
    }
    if (rowNode.group && rowNode.group === true) {
      return true;
    }
    if (rowNode.leafGroup && rowNode.leafGroup === true) {
      return true;
    }
    return false;
  }
  getFilteredData() {
    const data = [];
    this.agGridAdapter.getAgGridApi().forEachNodeAfterFilter(rowNode => {
      if (!this.isGroupRowNode(rowNode)) {
        data.push(rowNode.data);
      }
    });
    return data;
  }
  updateRows(dataRows, dataUpdateConfig) {
    if (this.hasAutogeneratedPrimaryKey) {
      this.addSyntheticPrimaryKeyIfMissing(dataRows);
    }
    dataUpdateConfig = dataUpdateConfig || {};
    if (dataUpdateConfig.runAsync) {
      return new Promise(resolve => {
        this.agGridAdapter.getAgGridApi().applyTransactionAsync({
          update: dataRows
        }, transaction => {
          if (typeof dataUpdateConfig.callback === 'function') {
            dataUpdateConfig.callback(transaction);
          }
          resolve(transaction === null || transaction === void 0 ? void 0 : transaction.update);
        });
        if (dataUpdateConfig.flushAsync) {
          this.agGridAdapter.getAgGridApi().flushAsyncTransactions();
        }
      });
    } else {
      const transaction = this.agGridAdapter.getAgGridApi().applyTransaction({
        update: dataRows
      });
      if (dataUpdateConfig.flushAsync) {
        this.agGridAdapter.getAgGridApi().flushAsyncTransactions();
      }
      return Promise.resolve(transaction === null || transaction === void 0 ? void 0 : transaction.update);
    }
  }
  addRows(dataRows, dataUpdateConfig) {
    if (this.hasAutogeneratedPrimaryKey) {
      this.addSyntheticPrimaryKey(dataRows);
    }
    dataUpdateConfig = dataUpdateConfig || {};
    const newData = {
      add: dataRows
    };
    if (dataUpdateConfig.addIndex !== undefined) {
      newData.addIndex = dataUpdateConfig.addIndex;
    }
    if (dataUpdateConfig.runAsync) {
      return new Promise(resolve => {
        this.agGridAdapter.getAgGridApi().applyTransactionAsync(newData, transaction => {
          if (typeof dataUpdateConfig.callback === 'function') {
            dataUpdateConfig.callback(transaction);
          }
          resolve(transaction === null || transaction === void 0 ? void 0 : transaction.add);
          this.updateRowGroupsExpandedState();
        });
        if (dataUpdateConfig.flushAsync) {
          this.agGridAdapter.getAgGridApi().flushAsyncTransactions();
        }
      });
    } else {
      const transaction = this.agGridAdapter.getAgGridApi().applyTransaction(newData);
      if (dataUpdateConfig.flushAsync) {
        this.agGridAdapter.getAgGridApi().flushAsyncTransactions();
      }
      this.updateRowGroupsExpandedState();
      return Promise.resolve(transaction === null || transaction === void 0 ? void 0 : transaction.add);
    }
  }
  addOrUpdateRows(dataRows, dataUpdateConfig) {
    const addDataRows = [];
    const updateDataRows = [];
    const primaryKey = this.adaptableOptions.primaryKey;
    dataRows.forEach(dataRow => {
      const node = this.getRowNodeForPrimaryKey(dataRow[primaryKey]);
      if (node) {
        updateDataRows.push(dataRow);
      } else {
        addDataRows.push(dataRow);
      }
    });
    if (this.hasAutogeneratedPrimaryKey) {
      this.addSyntheticPrimaryKeyIfMissing(addDataRows);
    }
    dataUpdateConfig = dataUpdateConfig || {};
    if (dataUpdateConfig.runAsync) {
      return new Promise(resolve => {
        this.agGridAdapter.getAgGridApi().applyTransactionAsync({
          update: updateDataRows,
          add: addDataRows,
          addIndex: dataUpdateConfig.addIndex
        }, transaction => {
          if (typeof dataUpdateConfig.callback === 'function') {
            dataUpdateConfig.callback(transaction);
          }
          if (transaction === null || transaction === void 0 ? void 0 : transaction.add) {
            this.updateRowGroupsExpandedState();
          }
          resolve({
            added: transaction === null || transaction === void 0 ? void 0 : transaction.add,
            updated: transaction === null || transaction === void 0 ? void 0 : transaction.update
          });
        });
        if (dataUpdateConfig.flushAsync) {
          this.agGridAdapter.getAgGridApi().flushAsyncTransactions();
        }
      });
    } else {
      const transaction = this.agGridAdapter.getAgGridApi().applyTransaction({
        update: updateDataRows,
        add: addDataRows,
        addIndex: dataUpdateConfig.addIndex
      });
      if (transaction === null || transaction === void 0 ? void 0 : transaction.add) {
        this.updateRowGroupsExpandedState();
      }
      if (dataUpdateConfig.flushAsync) {
        this.agGridAdapter.getAgGridApi().flushAsyncTransactions();
      }
      return Promise.resolve({
        added: transaction === null || transaction === void 0 ? void 0 : transaction.add,
        updated: transaction === null || transaction === void 0 ? void 0 : transaction.update
      });
    }
  }
  deleteRows(dataRows, dataUpdateConfig) {
    dataUpdateConfig = dataUpdateConfig || {};
    if (dataUpdateConfig.runAsync) {
      return new Promise(resolve => {
        this.agGridAdapter.getAgGridApi().applyTransactionAsync({
          remove: dataRows
        }, transaction => {
          if (typeof dataUpdateConfig.callback === 'function') {
            dataUpdateConfig.callback(transaction);
          }
          resolve(transaction === null || transaction === void 0 ? void 0 : transaction.remove);
        });
        if (dataUpdateConfig.flushAsync) {
          this.agGridAdapter.getAgGridApi().flushAsyncTransactions();
        }
      });
    } else {
      const transaction = this.agGridAdapter.getAgGridApi().applyTransaction({
        remove: dataRows
      });
      if (dataUpdateConfig.flushAsync) {
        this.agGridAdapter.getAgGridApi().flushAsyncTransactions();
      }
      return Promise.resolve(transaction.remove);
    }
  }
  getRowNodeForPrimaryKey(primaryKeyValue) {
    if (this.useRowNodeLookUp) {
      return this.agGridAdapter.getAgGridApi().getRowNode(primaryKeyValue);
    } else {
      this.agGridAdapter.getAgGridApi().forEachNode(rowNode => {
        if (primaryKeyValue == this.getPrimaryKeyValueFromRowNode(rowNode)) {
          return rowNode;
        }
      });
    }
  }
  hideColumn(columnId) {
    let agGridColumn = this.agGridAdapter.getAgGridApi().getColumn(columnId);
    if (agGridColumn) {
      this.agGridAdapter.getAgGridApi().setColumnsVisible([columnId], false);
      this.deriveAdaptableColumnStateFromAgGrid();
    }
  }
  showColumn(columnId) {
    let agGridColumn = this.agGridAdapter.getAgGridApi().getColumn(columnId);
    if (agGridColumn) {
      this.agGridAdapter.getAgGridApi().setColumnsVisible([columnId], true);
      this.deriveAdaptableColumnStateFromAgGrid();
    }
  }
  autoSizeColumn(columnId) {
    this.autoSizeColumns([columnId]);
  }
  autoSizeColumns(columnIds) {
    this.agGridAdapter.getAgGridApi().autoSizeColumns(columnIds);
  }
  autoSizeAllColumns() {
    this.agGridAdapter.getAgGridApi().autoSizeAllColumns();
  }
  setColumnOrder(VisibleColumnList) {
    const newColumnState = this.getSortedColumnStateForVisibleColumns(VisibleColumnList);
    this.agGridAdapter.getAgGridApi().applyColumnState({
      state: newColumnState,
      applyOrder: true
    });
    this.deriveAdaptableColumnStateFromAgGrid();
  }
  getSortedColumnStateForVisibleColumns(visibleColumnList, columnState, layout) {
    layout = layout || this.api.layoutApi.getCurrentLayout();
    visibleColumnList = visibleColumnList || layout.Columns;
    columnState = columnState || this.agGridAdapter.getAgGridApi().getColumnState();
    return buildSortedColumnStateForLayout({
      columnState: columnState.map(colState => {
        return {
          colId: colState.colId,
          hide: colState.hide
        };
      }),
      layout: Object.assign(Object.assign({}, layout), {
        Columns: visibleColumnList
      }),
      groupDisplayType: this.agGridAdapter.getGridOption('groupDisplayType')
    });
  }
  getDistinctValuesForColumn(column, distinctValuesParams) {
    let gridCells = this.getGridCellsForPermittedValues(column, distinctValuesParams);
    if (ArrayExtensions.IsNullOrEmpty(gridCells)) {
      gridCells = this.getDistinctGridCellsForColumn(column, distinctValuesParams);
    }
    return this.getUniqueGridCells(column, gridCells);
  }
  getGridCellsForPermittedValues(column, distinctValuesParams) {
    var _a;
    let gridCells = [];
    const permittedValues = (_a = distinctValuesParams.permittedValues) !== null && _a !== void 0 ? _a : this.api.userInterfaceApi.getPermittedValuesForColumn(column);
    if (ArrayExtensions.IsNotNullOrEmpty(permittedValues)) {
      gridCells = permittedValues.map(pv => {
        return {
          rawValue: pv,
          displayValue: pv,
          normalisedValue: pv,
          columnId: column.columnId,
          column: column,
          rowNode: undefined,
          isPivotCell: false,
          isRowGroupCell: false
        };
      });
      return gridCells;
    }
  }
  getDistinctGridCellsForColumn(column, distinctValuesParams) {
    let gridCells = [];
    if (distinctValuesParams.visibleRowsOnly === true) {
      this.agGridAdapter.getAgGridApi().forEachNodeAfterFilter(rowNode => {
        const gridCell = this.addDistinctColumnValue(rowNode, column.columnId);
        if (gridCell && gridCell.rowNode !== distinctValuesParams.skipRowNode) {
          gridCells.push(gridCell);
        }
      });
    } else {
      this.agGridAdapter.getAgGridApi().forEachNode(rowNode => {
        const gridCell = this.addDistinctColumnValue(rowNode, column.columnId);
        if (gridCell && gridCell.rowNode !== distinctValuesParams.skipRowNode) {
          gridCells.push(gridCell);
        }
      });
    }
    return gridCells;
  }
  addDistinctColumnValue(rowNode, columnId) {
    // we do not return the values of the aggregates when in grouping mode
    // otherwise they would appear in the filter dropdown etc....
    if (rowNode && !this.isGroupRowNode(rowNode)) {
      const returnValue = this.getGridCellFromRowNode(rowNode, columnId);
      if (Helper.objectExists(returnValue)) {
        return returnValue;
      }
    } else {
      return undefined;
    }
  }
  getUniqueGridCells(column, gridCells) {
    let uniqueVals = uniqBy(gridCells, dataItem => {
      const value = dataItem.rawValue;
      if (value instanceof Date) {
        return value.toISOString();
      }
      return value;
    });
    if (column.dataType == 'String' && this.api.predicateApi.useCaseSensitivity()) {
      uniqueVals = uniqBy(uniqueVals, d => d.displayValue.toLowerCase());
    }
    return uniqueVals.slice(0, this.api.columnFilterApi.internalApi.getFilterValuesMaxNumberOfItems(column));
  }
  getGridCellsForColumn(columnId, onlyVisibleRows = false) {
    let returnValues = [];
    const handler = rowNode => {
      if (!this.isGroupRowNode(rowNode)) {
        const gridCell = this.getGridCellFromRowNode(rowNode, columnId);
        if (gridCell && gridCell.rawValue !== undefined && gridCell.rawValue !== null) {
          returnValues.push(gridCell);
        }
      }
    };
    if (onlyVisibleRows) {
      this.agGridAdapter.getAgGridApi().forEachNodeAfterFilter(handler);
    } else {
      this.agGridAdapter.getAgGridApi().forEachNode(handler);
    }
    return returnValues;
  }
  // This horrible method is temporary until we can get rid of having predicates inside values which is coming soon
  // We need it in case Blanks is requested
  // once we go to the new multi predicate screen then we wont show blanks any more - which we should never have done
  getGridCellsForColumnTemp(columnId, onlyVisibleRows) {
    let returnValues = [];
    const handler = rowNode => {
      if (!this.isGroupRowNode(rowNode)) {
        const gridCell = this.getGridCellFromRowNode(rowNode, columnId);
        if (gridCell) {
          returnValues.push(gridCell);
        }
      }
    };
    if (onlyVisibleRows) {
      this.agGridAdapter.getAgGridApi().forEachNodeAfterFilter(handler);
    } else {
      this.agGridAdapter.getAgGridApi().forEachNode(handler);
    }
    return returnValues;
  }
  getRowNodesForPrimaryKeys(primaryKeyValues) {
    let rowNodes = [];
    if (this.useRowNodeLookUp) {
      primaryKeyValues.forEach(pkValue => {
        const rowNode = this.agGridAdapter.getAgGridApi().getRowNode(pkValue);
        if (rowNode) {
          rowNodes.push(rowNode);
        }
      });
    } else {
      primaryKeyValues.forEach(pkValue => {
        let foundRow = false;
        this.agGridAdapter.getAgGridApi().forEachNode(rowNode => {
          if (!foundRow && pkValue == this.getPrimaryKeyValueFromRowNode(rowNode)) {
            rowNodes.push(rowNode);
            foundRow = true;
          }
        });
      });
    }
    return rowNodes;
  }
  getRowNodeByIndex(index) {
    return this.agGridAdapter.getAgGridApi().getDisplayedRowAtIndex(index);
  }
  getAgGridStatusPanels() {
    var _a, _b;
    return (_b = (_a = this.agGridAdapter.getGridOption('statusBar')) === null || _a === void 0 ? void 0 : _a.statusPanels) !== null && _b !== void 0 ? _b : [];
  }
  setDataValue(value, column, primaryKeyValue, rowNode) {
    // note: because we use RowNode.setDataValue() this will cause Validation to fire
    // see https://www.ag-grid.com/javascript-data-grid/change-detection/#triggering-value-change-detection
    let newValue;
    let dataType = column.dataType;
    newValue = dataType == 'Number' ? Number(value) : value;
    if (dataType == undefined) {
      return; // no point continuing as probably a wrong  column
    }
    if (rowNode) {
      rowNode.setDataValue(column.columnId, newValue);
    } else {
      if (this.useRowNodeLookUp) {
        const rowNode = this.agGridAdapter.getAgGridApi().getRowNode(primaryKeyValue);
        if (rowNode != null) {
          rowNode.setDataValue(column.columnId, newValue);
        }
      } else {
        let isUpdated = false;
        // prefer not to use this method but if we do then at least we can prevent further lookups once we find
        this.agGridAdapter.getAgGridApi().forEachNode(rowNode => {
          if (!isUpdated) {
            if (primaryKeyValue == this.getPrimaryKeyValueFromRowNode(rowNode)) {
              rowNode.setDataValue(column.columnId, newValue);
              isUpdated = true;
            }
          }
        });
      }
    }
  }
  isCellEditable(rowNode, column) {
    // it's safe to rely on the AG Grid implementation because we override the colDef.editable property, which is in this case the single source of truth
    return column === null || column === void 0 ? void 0 : column.isCellEditable(rowNode);
  }
  forAllRowNodesDo(func, config) {
    this.agGridAdapter.getAgGridApi().forEachNode((rowNode, rowIndex) => {
      const includeGroupRows = (config === null || config === void 0 ? void 0 : config.includeGroupRows) || !this.isGroupRowNode(rowNode);
      const filterFnFulfilled = !(config === null || config === void 0 ? void 0 : config.filterFn) || (config === null || config === void 0 ? void 0 : config.filterFn(rowNode));
      if (includeGroupRows && filterFnFulfilled) {
        func(rowNode, rowIndex);
      }
    });
  }
  forAllVisibleRowNodesDo(func, config) {
    if (this.getAgGridRowModelType() !== 'clientSide') {
      // only in client-side row model can we loop through filtered&sorted rows
      // see https://www.ag-grid.com/javascript-data-grid/accessing-data/#iterating-rows
      this.logger.warn('`forAllVisibleRowNodesDo()` is only supported in client-side row model. `forAllRowNodesDo` will be used instead.');
      return this.forAllRowNodesDo(func, config);
    }
    this.agGridAdapter.getAgGridApi().forEachNodeAfterFilterAndSort((rowNode, rowIndex) => {
      const includeGroupRows = (config === null || config === void 0 ? void 0 : config.includeGroupRows) || !this.isGroupRowNode(rowNode);
      const filterFnFulfilled = !(config === null || config === void 0 ? void 0 : config.filterFn) || (config === null || config === void 0 ? void 0 : config.filterFn(rowNode));
      if (includeGroupRows && filterFnFulfilled) {
        func(rowNode, rowIndex);
      }
    });
  }
  getAgGridRowModelType(gridOptions) {
    // it seems that this can be null so we need explicitly to return "clientSide" in this case
    // need to check that for ServerSideRowModel it is ALWAYS returned...
    var _a, _b;
    if (gridOptions) {
      return (_a = gridOptions.rowModelType) !== null && _a !== void 0 ? _a : 'clientSide';
    }
    return (_b = this.agGridAdapter.getAgGridApi().getGridOption('rowModelType')) !== null && _b !== void 0 ? _b : 'clientSide';
  }
  getAllRowNodes(config) {
    let rowNodes = [];
    this.forAllRowNodesDo(rowNode => rowNodes.push(rowNode), config);
    return rowNodes;
  }
  getGroupRowNodes() {
    return this.getAllRowNodes({
      includeGroupRows: true,
      filterFn: rowNode => this.isGroupRowNode(rowNode)
    });
  }
  getRowsInViewport() {
    return this.agGridAdapter.getAgGridApi().getRenderedNodes();
  }
  isRowNodeVisible(rowNode) {
    const foundNode = this.agGridAdapter.getAgGridApi().getRenderedNodes().find(n => n.id == rowNode.id);
    return foundNode != null;
  }
  selectNodes(rowNodes, clearSelection) {
    if (ArrayExtensions.IsNotNullOrEmpty(rowNodes)) {
      rowNodes.forEach(node => this.selectNode(node, clearSelection));
    }
  }
  deSelectNodes(rowNodes, clearSelection) {
    if (ArrayExtensions.IsNotNullOrEmpty(rowNodes)) {
      rowNodes.forEach(node => this.deSelectNode(node, clearSelection));
    }
  }
  selectNode(rowNode, clearSelection) {
    if (!rowNode) {
      this.logger.error('No node to select');
      return;
    }
    rowNode.setSelected(true, clearSelection);
  }
  deSelectNode(rowNode, clearSelection) {
    if (!rowNode) {
      this.logger.error('No node to deselect');
      return;
    }
    rowNode.setSelected(false, clearSelection);
  }
  selectCells(columnIds, startNode, endNode, clearSelection) {
    if (clearSelection) {
      this.agGridAdapter.getAgGridApi().clearRangeSelection();
    }
    const cellRangeParams = {
      rowStartIndex: startNode.rowIndex,
      rowEndIndex: endNode.rowIndex,
      columns: columnIds
    };
    this.agGridAdapter.getAgGridApi().addCellRange(cellRangeParams);
  }
  getAgGridColumnType(columnId) {
    var _a;
    const {
      type
    } = (_a = this.agGridAdapter.getAgGridApi().getColumnDef(columnId)) !== null && _a !== void 0 ? _a : {};
    return type || '';
  }
  setColumnSort(columnSorts) {
    if (!this.isReady) {
      return;
    }
    const columnSortsMap = columnSorts === null || columnSorts === void 0 ? void 0 : columnSorts.reduce((acc, columnSort, index) => {
      acc[columnSort.ColumnId] = Object.assign(Object.assign({}, columnSort), {
        SortIndex: index
      });
      return acc;
    }, {});
    const newColumnState = this.agGridAdapter.getAgGridApi().getColumnState().map(colState => {
      const {
        colId
      } = colState;
      const state = Object.assign({}, colState);
      const colSort = columnSortsMap ? columnSortsMap[colId] : undefined;
      if (colSort) {
        state.sort = colSort.SortOrder === 'Asc' ? 'asc' : 'desc';
        state.sortIndex = colSort.SortIndex;
      } else {
        state.sort = null;
        state.sortIndex = null;
      }
      return state;
    });
    this.agGridAdapter.getAgGridApi().applyColumnState({
      state: newColumnState,
      applyOrder: true
    });
    this.agGridAdapter.getAgGridApi().onSortChanged();
  }
  clearColumnSort() {
    this.setColumnSort(null);
  }
  hideColumnFilterForm() {
    if (this.hideFilterFormPopup) {
      this.hideFilterFormPopup();
    }
  }
  clearColumnFiltering() {
    this.agGridAdapter.getAgGridApi().getColumns().forEach(c => {
      this.agGridAdapter.getAgGridApi().destroyFilter(c);
    });
  }
  clearColumnFilteringForColumns(columnIds) {
    columnIds.forEach(c => {
      const column = this.agGridAdapter.getAgGridApi().getColumns().find(col => col.getColId() === c);
      if (column) {
        this.agGridAdapter.getAgGridApi().destroyFilter(column);
      }
    });
  }
  canGenerateCharts() {
    return this.agGridAdapter.isModulePresent(ModuleNames.GridChartsModule) && this.agGridAdapter.getAgGridApi().getGridOption('enableCharts');
  }
  canDisplaySparklines() {
    return this.agGridAdapter.isModulePresent(ModuleNames.SparklinesModule);
  }
  showCharts(chartsDefinitions, chartContainer) {
    return chartsDefinitions.map(chartDefinition => this.showChart(chartDefinition, chartContainer));
  }
  showChart(chartDefinition, container) {
    if (!this.isReady) {
      this.logger.consoleError('Adaptable must be instantiated before calling showChart');
      return null;
    }
    /**
     * Ag-grid always creates new charts behind the sences.
     * So we need to update the model inside adaptable state.
     * This is important to be done as soon as possible so it is not considered new.
     */
    const chartRef = this.agGridAdapter.getAgGridApi().restoreChart(chartDefinition.Model, container);
    const chartModel = this.getChartModels().find(chartModel => chartModel.chartId === chartRef.chartId);
    // Update the definition in state so it is not considered new
    this.api.chartingApi.editChartDefinition(Object.assign(Object.assign({}, chartDefinition), {
      Model: chartModel
    }));
    return chartRef;
  }
  updateChart(chart) {
    const upgradableProperties = {
      // Only Range charts are supported to be created at run time
      // the other two are pivot & cross-filter
      type: 'rangeChartUpdate',
      chartId: chart.Model.chartId,
      unlinkChart: Boolean(chart.Model.unlinkChart),
      suppressChartRanges: Boolean(chart.Model.suppressChartRanges),
      aggFunc: chart.Model.aggFunc
    };
    this.agGridAdapter.getAgGridApi().updateChart(upgradableProperties);
  }
  getChartModels() {
    if (!this.isReady) {
      this.logger.consoleError('Adaptable must be instantiated before calling getChartModels');
      return [];
    }
    return this.agGridAdapter.getAgGridApi().getChartModels();
  }
  getRowCount() {
    return this.agGridAdapter.getAgGridApi().getDisplayedRowCount();
  }
  getColumnCount() {
    var _a, _b;
    return (_b = (_a = this.agGridAdapter.getAgGridApi().getColumns()) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0;
  }
  getVisibleColumnCount() {
    var _a, _b;
    return (_b = (_a = this.agGridAdapter.getAgGridApi().getColumns()) === null || _a === void 0 ? void 0 : _a.filter(c => c.isVisible()).length) !== null && _b !== void 0 ? _b : 0;
  }
  isGridGroupable() {
    return !this.api.internalApi.isGridInTreeMode();
  }
  isGridGroupingActive() {
    let isGroupedActive = false;
    this.agGridAdapter.getAgGridApi().forEachNode(node => {
      if (!isGroupedActive) {
        if (node.group) {
          isGroupedActive = true;
        }
      }
    });
    return isGroupedActive;
  }
  setAgGridQuickSearch(searchText) {
    this.agGridAdapter.setGridOption('quickFilterText', searchText);
  }
  getAgGridCurrentThemeName() {
    const container = this.getAgGridContainerElement();
    if (container && container.classList) {
      // we detect the ag theme class
      const classList = container.classList;
      for (let i = 0, len = classList.length; i < len; i++) {
        const cls = classList[i];
        if (cls.indexOf('ag-theme-') === 0) {
          return cls;
        }
      }
    }
    return this.getAgGridLightThemeName();
  }
  getAgGridLightThemeName() {
    const container = this.getAgGridContainerElement();
    if (container && container.classList) {
      // we detect the ag theme class
      const classList = container.classList;
      for (let i = 0, len = classList.length; i < len; i++) {
        const cls = classList[i];
        if (cls.indexOf('ag-theme-') === 0) {
          // even if dark theme is included, we compute the light theme name out of it
          return cls.replace('-dark', '');
        }
      }
    } else {
      this.logger.warn('No AgGrid container found, defaulting to ag-theme-balham for the light theme');
    }
    this.logger.warn('No ag-theme- class found on the grid container, defaulting to ag-theme-balham');
    // fallback to the default light theme
    return 'ag-theme-balham';
  }
  applyAdaptableTheme(theme) {
    const container = this.getAgGridContainerElement();
    if (container != null) {
      container.classList.add('ab-Grid');
      if (this.adaptableOptions.columnFilterOptions.indicateFilteredColumns) {
        container.classList.add('ab-Grid--indicate-filtered-columns');
      }
    }
    if (this._isDetailGrid) {
      return;
    }
    theme = this.ThemeService.mapOsTheme(theme);
    const themeName = typeof theme === 'string' ? theme : theme.Name;
    const isSystemTheme = this.api.themeApi.internalApi.isSystemTheme(themeName);
    const themeClassNamesToRemove = [];
    const themesToRemove = [];
    const allThemes = this.api.themeApi.getThemes().map(t => {
      // we mutate the theme later,
      // and since we don't want the mutation to end up in state
      // we better clone it here
      return Object.assign({}, t);
    });
    const allThemesMap = allThemes.reduce((acc, theme) => {
      acc[theme.Name] = theme;
      return acc;
    }, {});
    const themeObject = allThemesMap[themeName];
    // REMOVE PREVIOUS THEME
    // const themePrefix = 'ab--theme-'
    const el = document.documentElement;
    el.classList.forEach(cssClassName => {
      const index = cssClassName.indexOf(GeneralConstants.THEME_STYLE);
      if (index === 0) {
        themeClassNamesToRemove.push(cssClassName);
        const themeName = cssClassName.substring(GeneralConstants.THEME_STYLE.length);
        if (allThemesMap[themeName]) {
          themesToRemove.push(allThemesMap[themeName]);
        }
      }
    });
    themeClassNamesToRemove.forEach(cssClassName => el.classList.remove(cssClassName));
    // remove infinite table classnames
    themesToRemove.forEach(theme => {
      el.classList.remove(`infinite-${theme.Name}`);
    });
    // VARIANT
    let variantTheme = '';
    if (!isSystemTheme && themeObject.Variant) {
      variantTheme = themeObject.Variant;
    }
    // APPLY NEW THEME
    const newTheme = allThemesMap[themeName];
    const getClassName = theme => GeneralConstants.THEME_STYLE + theme;
    el.classList.add(getClassName(themeName));
    if (variantTheme) {
      el.classList.add(getClassName(variantTheme));
    }
    if (isSystemTheme) {
      // add infinite table classname for theme
      el.classList.add(`infinite-${themeName}`);
    } else if (variantTheme) {
      el.classList.add(`infinite-${variantTheme}`);
    }
    // AG THEME CLASS NAME
    const getAgGridLightThemeName = () => this.getAgGridLightThemeName();
    const getAgGridDarkThemeName = () => getAgGridLightThemeName() + '-dark';
    if (newTheme && (isSystemTheme || variantTheme)) {
      if ((variantTheme || themeName) === LIGHT_THEME) {
        newTheme.AgGridClassName = newTheme.AgGridClassName || getAgGridLightThemeName();
      }
      if ((variantTheme || themeName) === DARK_THEME) {
        newTheme.AgGridClassName = newTheme.AgGridClassName || getAgGridDarkThemeName();
      }
    }
    if (!newTheme.AgGridClassName) {
      // default AG Grid to its light theme
      newTheme.AgGridClassName = getAgGridLightThemeName();
    }
    if (container != null) {
      if (themesToRemove.length) {
        themesToRemove.forEach(theme => {
          if (theme.AgGridClassName) {
            container.classList.remove(theme.AgGridClassName);
          }
        });
      }
      // also remove all AG Grid theme class names
      const agGridClassNamesToRemove = [];
      container.classList.forEach(x => {
        if (x && x.indexOf('ag-theme-') === 0) {
          agGridClassNamesToRemove.push(x);
        }
      });
      agGridClassNamesToRemove.forEach(x => container.classList.remove(x));
      if (newTheme && newTheme.AgGridClassName) {
        container.classList.add(newTheme.AgGridClassName);
      }
    }
    // MAC LIKE SCROLLBARS
    if (this.adaptableOptions.userInterfaceOptions && this.adaptableOptions.userInterfaceOptions.useCustomMacLikeScrollbars && getScrollbarSize() > 0) {
      el.classList.add('ab--custom-mac-like-scrollbars');
    } else {
      el.classList.remove('ab--custom-mac-like-scrollbars');
    }
  }
  setRowGroupColumns(columnIds) {
    this.agGridAdapter.getAgGridApi().setRowGroupColumns(columnIds);
  }
  getAllGridColumns() {
    return this.agGridAdapter.getAgGridApi().getAllGridColumns();
  }
  clearRowGroupColumns() {
    this.agGridAdapter.getAgGridApi().removeRowGroupColumns(this.agGridAdapter.getAgGridApi().getRowGroupColumns());
  }
  expandAllRowGroups() {
    this.agGridAdapter.getAgGridApi().expandAll();
  }
  collapseAllRowGroups() {
    this.agGridAdapter.getAgGridApi().collapseAll();
  }
  expandRowGroupsForValues(columnValues) {
    if (ArrayExtensions.IsNotNullOrEmpty(columnValues)) {
      const expandedKeys = columnValues.reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {});
      this.agGridAdapter.getAgGridApi().forEachNode(node => {
        if (node.group && !node.expanded) {
          const nodePath = [];
          let current = node;
          while (current) {
            nodePath.push(current.key);
            current = current.parent;
          }
          const nodeKey = nodePath.filter(x => !!x).reverse().join(GROUP_PATH_SEPARATOR);
          if (expandedKeys[nodeKey]) {
            node.setExpanded(true);
          }
        }
      });
      this.agGridAdapter.getAgGridApi().onGroupExpandedOrCollapsed();
    }
  }
  getExpandRowGroupsKeys() {
    let returnValues = [];
    if (this.api.layoutApi.internalApi.areExpandedRowGroupsSavedInLayouts()) {
      this.agGridAdapter.getAgGridApi().forEachNode(node => {
        if (node.group && node.expanded) {
          let current = node;
          const path = [];
          while (current) {
            path.push(current.key);
            current = current.parent;
          }
          returnValues.push(path.filter(x => !!x).reverse().join(GROUP_PATH_SEPARATOR));
        }
      });
    }
    return returnValues;
  }
  getAgGridColumnForColumnId(columnId) {
    return this.agGridAdapter.getAgGridApi().getColumn(columnId);
  }
  getMinMaxCachedValueForColumn(column, minMax) {
    var _a;
    const {
      columnId,
      dataType
    } = column;
    if (dataType !== 'Number') {
      return undefined;
    }
    let value = (_a = this.columnMinMaxValuesCache[columnId]) === null || _a === void 0 ? void 0 : _a[minMax];
    if (value !== undefined) {
      return value;
    }
    const distinctRawValues = this.api.gridApi.internalApi.getUnsortedDistinctRawValuesForColumn(columnId).map(item => item.rawValue);
    value = minMax === 'min' ? Math.min(...distinctRawValues) : Math.max(...distinctRawValues);
    this.columnMinMaxValuesCache[columnId] = Object.assign(Object.assign({}, this.columnMinMaxValuesCache[columnId]), {
      [minMax]: value
    });
    return value;
  }
  getAgGridRegisteredModules() {
    return this.agGridAdapter.getRegisteredModules();
  }
  destroy(config) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6;
    if (((_a = this.agGridAdapter) === null || _a === void 0 ? void 0 : _a.getAgGridApi()) && !this.agGridAdapter.getAgGridApi().isDestroyed()) {
      this.agGridAdapter.getAgGridApi().removeEventListener('firstDataRendered', this.listenerFirstDataRendered);
      this.agGridAdapter.getAgGridApi().removeEventListener('columnPivotModeChanged', this.listenerPivotModeChanged);
      this.agGridAdapter.getAgGridApi().removeEventListener('columnPivotChanged', this.listenerPivotChanged);
      this.agGridAdapter.getAgGridApi().removeEventListener('cellEditingStarted', this.listenerCellEditingStarted);
      this.agGridAdapter.getAgGridApi().removeEventListener('columnRowGroupChanged', this.listenerColumnRowGroupChanged);
      this.agGridAdapter.getAgGridApi().removeEventListener('rangeSelectionChanged', this.listenerRangeSelectionChanged);
      this.agGridAdapter.getAgGridApi().removeEventListener('columnResized', this.listenerColumnResized);
      this.agGridAdapter.getAgGridApi().removeEventListener('sortChanged', this.listenerSortChanged);
      this.agGridAdapter.getAgGridApi().removeEventListener('modelUpdated', this.listenerModelUpdated);
      this.agGridAdapter.getAgGridApi().removeGlobalListener(this.listenerGlobalSetRowSelection);
      this.agGridAdapter.getAgGridApi().removeGlobalListener(this.listenerGlobalColumnEventsThatTriggerStateChange);
      this.agGridAdapter.getAgGridApi().removeGlobalListener(this.listenerGlobalColumnEventsThatTriggerAutoLayoutSave);
      this.agGridAdapter.getAgGridApi().removeGlobalListener(this.listenerGlobalRowGroupEventsThatTriggerAutoLayoutSave);
      this.listenerFirstDataRendered = null;
      this.listenerPivotModeChanged = null;
      this.listenerPivotChanged = null;
      this.listenerCellEditingStarted = null;
      this.listenerColumnRowGroupChanged = null;
      this.listenerRangeSelectionChanged = null;
      this.listenerColumnResized = null;
      this.listenerGlobalSetRowSelection = null;
      this.listenerSortChanged = null;
      this.listenerModelUpdated = null;
      this.listenerGlobalColumnEventsThatTriggerStateChange = null;
      this.listenerGlobalColumnEventsThatTriggerAutoLayoutSave = null;
      this.listenerGlobalRowGroupEventsThatTriggerAutoLayoutSave = null;
      this.throttleFilterOnEditDataChange = null;
      this.throttleFilterOnTickingDataChange = null;
      const liveGridOptions = this.DANGER_getPrivateAgGridBeans().gridOptions;
      if (liveGridOptions) {
        this.agGridOptionsService.revertGridOptionsPropertiesToUserValue(liveGridOptions, ['aggFuncs', 'allowContextMenuWithControlKey', 'columnTypes', 'components', 'context', 'dataTypeDefinitions', 'doesExternalFilterPass', 'excelStyles', 'getContextMenuItems', 'getMainMenuItems', 'getRowClass', 'getRowId', 'getRowStyle', 'gridId', 'initialGroupOrderComparator', 'isExternalFilterPresent', 'sideBar', 'statusBar', 'suppressAggFuncInHeader']);
        if (liveGridOptions.components) {
          liveGridOptions.components.AdaptableToolPanel = null;
        }
      }
    }
    DANGER_AG_GRID_BEANS_MAP[this._agGridId] = null;
    if ((config === null || config === void 0 ? void 0 : config.destroyApi) === true) {
      (_b = this.agGridAdapter.getAgGridApi()) === null || _b === void 0 ? void 0 : _b.destroy();
    }
    this.previousAgGridLayoutState = '';
    const gridContainerElement = this.getAgGridContainerElement();
    if (gridContainerElement) {
      gridContainerElement.removeEventListener('keydown', this.agGridListenerKeydown);
      gridContainerElement.removeEventListener('mouseenter', this.agGridListenerMouseEnter);
      gridContainerElement.removeEventListener('mouseleave', this.agGridListenerMouseLeave);
      this.agGridListenerKeydown = null;
      this.agGridListenerMouseEnter = null;
      this.agGridListenerMouseLeave = null;
    }
    this.api.internalDestroySelf();
    (_c = this.agGridOptionsService) === null || _c === void 0 ? void 0 : _c.destroy();
    this.agGridOptionsService = null;
    (_d = this.agGridAdapter) === null || _d === void 0 ? void 0 : _d.destroy();
    this.agGridAdapter = null;
    (_e = this.agGridMenuAdapter) === null || _e === void 0 ? void 0 : _e.destroy();
    this.agGridMenuAdapter = null;
    (_f = this.agGridColumnAdapter) === null || _f === void 0 ? void 0 : _f.destroy();
    this.agGridColumnAdapter = null;
    this.rowListeners = null;
    this.emitter.destroy();
    this.emitter = null;
    this.adaptableOptions = null;
    this.columnMinMaxValuesCache = null;
    this.lifecycleState = 'preDestroyed';
    AdaptableAgGrid.dismissInstance(this);
    (_g = this.unmountLoadingScreen) === null || _g === void 0 ? void 0 : _g.call(this);
    this.unmountLoadingScreen = null;
    const abContainerElement = this.getAdaptableContainerElement();
    if (config && !config.unmount) {
      return;
    }
    if (abContainerElement != null) {
      (_h = this.unmountReactRoot) === null || _h === void 0 ? void 0 : _h.call(this);
    }
    this.unmountReactRoot = null;
    this.DANGER_USE_GETTER_adaptableContainerElement = null;
    this.DANGER_USE_GETTER_agGridContainerElement = null;
    (_j = this.adaptableStore) === null || _j === void 0 ? void 0 : _j.destroy();
    this.adaptableStore = null;
    this.adaptableOptions = null;
    this.adaptableStatusPanelKeys = null;
    (_l = (_k = this.CalculatedColumnExpressionService) === null || _k === void 0 ? void 0 : _k.destroy) === null || _l === void 0 ? void 0 : _l.call(_k);
    this.CalculatedColumnExpressionService = null;
    (_o = (_m = this.DataService) === null || _m === void 0 ? void 0 : _m.destroy) === null || _o === void 0 ? void 0 : _o.call(_m);
    this.DataService = null;
    (_q = (_p = this.Fdc3Service) === null || _p === void 0 ? void 0 : _p.destroy) === null || _q === void 0 ? void 0 : _q.call(_p);
    this.Fdc3Service = null;
    (_s = (_r = this.ModuleService) === null || _r === void 0 ? void 0 : _r.destroy) === null || _s === void 0 ? void 0 : _s.call(_r);
    this.ModuleService = null;
    (_u = (_t = this.ValidationService) === null || _t === void 0 ? void 0 : _t.destroy) === null || _u === void 0 ? void 0 : _u.call(_t);
    this.ValidationService = null;
    (_w = (_v = this.QueryLanguageService) === null || _v === void 0 ? void 0 : _v.destroy) === null || _w === void 0 ? void 0 : _w.call(_v);
    this.QueryLanguageService = null;
    (_y = (_x = this.AlertService) === null || _x === void 0 ? void 0 : _x.destroy) === null || _y === void 0 ? void 0 : _y.call(_x);
    this.AlertService = null;
    (_0 = (_z = this.TeamSharingService) === null || _z === void 0 ? void 0 : _z.destroy) === null || _0 === void 0 ? void 0 : _0.call(_z);
    this.TeamSharingService = null;
    (_2 = (_1 = this.RowEditService) === null || _1 === void 0 ? void 0 : _1.destroy) === null || _2 === void 0 ? void 0 : _2.call(_1);
    this.RowEditService = null;
    (_4 = (_3 = this.MetamodelService) === null || _3 === void 0 ? void 0 : _3.destroy) === null || _4 === void 0 ? void 0 : _4.call(_3);
    this.MetamodelService = null;
    (_6 = (_5 = this.LicenseService) === null || _5 === void 0 ? void 0 : _5.destroy) === null || _6 === void 0 ? void 0 : _6.call(_5);
    this.LicenseService = null;
  }
  canExportToExcel() {
    return this.agGridAdapter.isModulePresent(ModuleNames.ExcelExportModule);
  }
  exportToExcel(reportData, fileName) {
    const columnDefs = reportData.columns.map(column => ({
      field: column.columnId,
      headerName: column.friendlyName
    }));
    const NESTED_COL_SEPARATOR = '.';
    const nestedColumns = reportData.columns.filter(column => column.columnId.includes(NESTED_COL_SEPARATOR));
    if (nestedColumns.length) {
      // the rowData of nested fields (containing dots) was flattened during the report data extraction and we need to un-flatten it back
      // ex.
      // current state: { field: 'outerKey.innerKey', value: 2}
      // original (desired) state: { field: 'outerKey.innerKey', value: {outerKey: {innerKey: 2}}}
      reportData.rows.forEach(rowDataEntry => nestedColumns.forEach(nestedColumn => {
        const cellExportValue = rowDataEntry[nestedColumn.columnId];
        let intermediaryValue = rowDataEntry;
        // augment the rowDataEntry with the new object
        nestedColumn.columnId.split(NESTED_COL_SEPARATOR).forEach((nestedKey, index, nestedCols) => {
          // we reached the leaf node, so we set the cell value and break
          if (index === nestedCols.length - 1) {
            intermediaryValue[nestedKey] = cellExportValue;
            return;
          }
          if (intermediaryValue[nestedKey] == undefined) {
            intermediaryValue[nestedKey] = {};
          } // else means that the object already has a property with this key
          intermediaryValue = intermediaryValue[nestedKey];
        });
        // delete the obsolete flattened property
        delete rowDataEntry[nestedColumn.columnId];
      }));
    }
    const gridOptions = {
      columnDefs,
      rowData: reportData.rows
    };
    let gridParams = {
      modules: this.getAgGridRegisteredModules()
    };
    const ephemeralGridApi = createGrid(document.createElement('div'), gridOptions, gridParams);
    ephemeralGridApi.exportDataAsExcel({
      sheetName: 'Sheet 1',
      fileName: fileName
    });
    ephemeralGridApi.destroy();
  }
  exportVisualDataToExcel() {
    try {
      const exportExcelStyles = this.api.exportApi.internalApi.buildExcelStylesForVisualReports();
      // set DANGER_excelStyles without changing the array reference
      this.DANGER_excelStyles.splice(0, this.DANGER_excelStyles.length, ...exportExcelStyles);
      // this.agGridOptionsService.CAREFUL_patchGridOptionsProperty('excelStyles', exportExcelStyles);
      this.agGridAdapter.getAgGridApi().exportDataAsExcel({
        sheetName: 'Sheet 1',
        fileName: this.api.exportApi.internalApi.getReportFileName(this.adaptableOptions.adaptableId, 'Excel'),
        // delegate the cell value processing to Adaptable
        processCellCallback: ({
          node,
          column,
          value
        }) => {
          var _a;
          const columnId = column.getColId();
          if ((node === null || node === void 0 ? void 0 : node.group) && (this.api.columnApi.isAutoRowGroupColumn(columnId) ||
          // we would still need to process the cell if this is a group row with an aggregated value
          ((_a = node === null || node === void 0 ? void 0 : node.aggData) === null || _a === void 0 ? void 0 : _a[columnId]) == undefined)) {
            // skip processing of row groups, this was already handled in processRowGroupCallback()
            return value;
          }
          return this.processCellForExcelExport(node, columnId);
        },
        processRowGroupCallback: params => {
          var _a;
          // recreating the standard AG Grid styling for row groups: 'Parent -> Child'
          // additionally the values are formatted
          let rowGroupNode = params.node;
          const isFooterRow = rowGroupNode.footer;
          const rowGroupSummary = [(_a = this.processRowGroupForExcelExport(rowGroupNode)) !== null && _a !== void 0 ? _a : ''];
          while (rowGroupNode.parent) {
            rowGroupNode = rowGroupNode.parent;
            const formattedParentNode = this.processRowGroupForExcelExport(rowGroupNode);
            if (formattedParentNode) {
              rowGroupSummary.push(formattedParentNode);
            }
          }
          let summary = rowGroupSummary.reverse().join(' -> ');
          if (isFooterRow) {
            summary = `Total: ${summary}`;
          }
          return summary;
        }
      });
      this.DANGER_excelStyles.splice(0, this.DANGER_excelStyles.length, ...this.originalExcelStyles);
    } catch (error) {
      this.logger.consoleError('Error exporting visual data to Excel', error);
    }
  }
  processRowGroupForExcelExport(rowNode) {
    var _a, _b, _c, _d, _e;
    const columnId = (_c = (_a = rowNode.field) !== null && _a !== void 0 ? _a : (_b = rowNode.rowGroupColumn) === null || _b === void 0 ? void 0 : _b.getColId()) !== null && _c !== void 0 ? _c : (_e = (_d = rowNode.rowGroupColumn) === null || _d === void 0 ? void 0 : _d.getColDef()) === null || _e === void 0 ? void 0 : _e.field;
    if (!columnId || !rowNode.key) {
      return;
    }
    let rawValue = rowNode.key;
    if (this.api.columnApi.getColumnDataTypeForColumnId(columnId) === 'Date' && typeof rawValue === 'string' &&
    // rawValue is composed only of digits
    /^\d+$/.test(rawValue)) {
      // AG-Grid converts the value to string, we have to reconvert it back
      const dateRawValue = parseInt(rawValue);
      if (dateRawValue != undefined) {
        // @ts-ignore
        rawValue = dateRawValue;
      }
    }
    return this.api.exportApi.internalApi.getCellExportValueFromRawValue(rowNode, rawValue, columnId);
  }
  processCellForExcelExport(rowNode, columnId) {
    if (this.api.exportApi.internalApi.isVisualDataExportInProgress()) {
      const cellKey = this.agGridColumnAdapter.getExcelClassNameForCell(columnId, this.getPrimaryKeyValueFromRowNode(rowNode));
      const isoFormattedDate = this.api.exportApi.internalApi.getExcelStyleWithFormattedDate(cellKey);
      if (isoFormattedDate) {
        // this is a Date cell which will be formatted by Excel
        return isoFormattedDate;
      }
    }
    return this.api.exportApi.internalApi.getCellExportValueFromRowNode(rowNode, columnId);
  }
  isQuickFilterAvailable() {
    if (this.api.layoutApi.isCurrentLayoutPivot() && this.adaptableOptions.columnFilterOptions.useAdaptableColumnFiltering) {
      // hide completely the quick filter if pivot is enabled
      return false;
    }
    return this.hasFloatingFilterOnAtLeastOneColumn(this.agGridAdapter.getAgGridApi().getColumnDefs());
  }
  hasFloatingFilterOnAtLeastOneColumn(columnDefs) {
    let col;
    for (col of columnDefs) {
      if (col.floatingFilter) {
        return true;
      }
      if (col.children) {
        if (this.hasFloatingFilterOnAtLeastOneColumn(col.children)) {
          return true;
        }
      }
    }
    return false;
  }
  getChartRef(chartId) {
    return this.agGridAdapter.getAgGridApi().getChartRef(chartId);
  }
  setLayout(layout) {
    var _a, _b, _c, _d, _e, _f;
    if (!layout) {
      layout = this.api.layoutApi.getCurrentLayout();
    }
    const perfSetLayout = this.logger.beginPerf(`setLayout(${layout.Name})`);
    layout.Columns = layout.Columns || [];
    const actionRowColumn = this.api.actionRowApi.internalApi.getColDefsForActionRowColumns()[0];
    if (actionRowColumn) {
      layout.Columns.push(actionRowColumn.colId);
      layout.PinnedColumnsMap = layout.PinnedColumnsMap || {};
      layout.PinnedColumnsMap[actionRowColumn.colId] = actionRowColumn.pinned;
    }
    const columnsState = this.agGridAdapter.getAgGridApi().getColumnState();
    const columnsStateIndexes = {};
    const columnsStateMap = columnsState.reduce((acc, colState, index) => {
      columnsStateIndexes[colState.colId] = index;
      acc[colState.colId] = colState;
      return acc;
    }, {});
    let sortIndex = 0;
    const sortModelMap = ((_a = layout.ColumnSorts) !== null && _a !== void 0 ? _a : []).reduce((acc, customSort) => {
      const colId = customSort.ColumnId;
      acc[colId] = {
        colId,
        sort: customSort.SortOrder === 'Asc' ? 'asc' : 'desc',
        sortIndex
      };
      sortIndex++;
      return acc;
    }, {});
    const groupedColumnsIndexesMap = (layout.RowGroupedColumns || []).reduce((acc, colId, index) => {
      acc[colId] = index;
      return acc;
    }, {});
    let pivotedColumnsIndexesMap = {};
    const aggregationFunctionsColumnsMap = layout.AggregationColumns || {};
    const columnsToShow = !layout.EnablePivot ? layout.Columns : ((_b = this.agGridAdapter.getAgGridApi().getPivotResultColumns()) === null || _b === void 0 ? void 0 : _b.map(column => column.getColId())) || [];
    let isChanged = false;
    const colsToAutoSize = {};
    let newColumnsState = this.getSortedColumnStateForVisibleColumns(columnsToShow, columnsState, layout);
    newColumnsState = newColumnsState.map(colState => {
      var _a, _b, _c;
      const {
        colId
      } = colState;
      const oldColState = columnsStateMap[colId];
      // it's important to have width here, so it's not inherited from the existing colState
      // which could be from a different layout
      const newColState = Object.assign(Object.assign(Object.assign({}, oldColState), {
        width: null
      }), colState);
      if (layout.ColumnWidthMap && layout.ColumnWidthMap[colId] != null) {
        newColState.width = layout.ColumnWidthMap[colId];
      } else if (!colState.hide) {
        // autosize only the columns which are part of the selected layout
        colsToAutoSize[colId] = true;
      }
      if (actionRowColumn && actionRowColumn.colId === colId) {
        newColState.width = actionRowColumn.width;
      }
      newColState.rowGroupIndex = groupedColumnsIndexesMap[colId] != null ? groupedColumnsIndexesMap[colId] : null;
      newColState.rowGroup = newColState.rowGroupIndex != null;
      const normalizePinned = pinnedValue => {
        if (typeof pinnedValue === 'string') {
          return pinnedValue;
        }
        return pinnedValue == true ? 'left' : !!pinnedValue;
      };
      const newValuePinned = normalizePinned(layout.PinnedColumnsMap ? layout.PinnedColumnsMap[colId] : false);
      const stateValuePinned = normalizePinned(newColState.pinned);
      if (newValuePinned !== stateValuePinned) {
        newColState.pinned = newValuePinned;
      }
      newColState.pivotIndex = null;
      if (pivotedColumnsIndexesMap[colId] != null) {
        newColState.pivotIndex = pivotedColumnsIndexesMap[colId];
      }
      newColState.aggFunc = null;
      if (aggregationFunctionsColumnsMap[colId] != null) {
        const colDef = this.agGridAdapter.getAgGridApi().getColumnDef(colId);
        let aggFunc = null;
        const aggFuncFromLayout = aggregationFunctionsColumnsMap[colId];
        const adaptableAggFunc = this.getActiveAdaptableAggFuncForCol(colId);
        if (aggFuncFromLayout === true) {
          // if we have true, it means - take the default aggFunc from colDef
          // NOTE: colState gives us the current aggFunc, which can be null,
          // while the colDef gives us the initially configured aggFunc for that column
          aggFunc = (_c = (_b = (_a = colState.aggFunc) !== null && _a !== void 0 ? _a : colDef === null || colDef === void 0 ? void 0 : colDef.aggFunc) !== null && _b !== void 0 ? _b :
          // @ts-ignore available only wth ag-Grid v27.3.x
          colDef === null || colDef === void 0 ? void 0 : colDef.defaultAggFunc) !== null && _c !== void 0 ? _c : 'sum';
        } else if (adaptableAggFunc && adaptableAggFunc.type === 'weightedAverage') {
          aggFunc = WEIGHTED_AVERAGE_AGG_FN_NAME;
        } else if (typeof aggFuncFromLayout === 'string') {
          aggFunc = aggFuncFromLayout;
        }
        newColState.aggFunc = aggFunc;
      }
      if (sortModelMap[colId]) {
        newColState.sort = sortModelMap[colId].sort;
        newColState.sortIndex = sortModelMap[colId].sortIndex;
      } else {
        newColState.sort = null;
        newColState.sortIndex = null;
      }
      isChanged = isChanged || !lodashIsEqual(newColState, oldColState);
      return newColState;
    }).filter(x => !!x);
    if (!isChanged) {
      // order changed
      const toString = c => `${c.colId}-${c.rowGroupIndex}-${c.pivotIndex}-${c.aggFunc}-${c.pinned}-${c.width}-${c.hide}`;
      const oldColStateString = columnsState.map(toString).join(',');
      const newColStateString = newColumnsState.map(toString).join(',');
      isChanged = newColStateString != oldColStateString;
    }
    const pivoted = !!layout.EnablePivot;
    const shouldUpdatePivoted = this.agGridAdapter.getAgGridApi().isPivotMode() !== pivoted;
    /**
     * Pivot columns are secondary columns that are created on the fly and base
     * their configuration on the main columns by copying that configuration.
     * Because they copy the colDefs they might have an old copy of the colldefs.
     * This is why when the layout is pivoted we need to set columns before pivoting is applied.
     * e.g. {
     *  field: 'pivot-1',
     *  pivotValueColumn: {
     *   colDef: {
     *     field: 'price',
     *     // this is a copy of the original column
     *   }
     * }
     *
     */
    if (shouldUpdatePivoted) {
      this.updateColumnModelAndRefreshGrid();
    }
    isChanged = isChanged || shouldUpdatePivoted;
    let shouldUpdateHeaders = false;
    // update the header name for all columns
    // there should be a simpler solution for this, once the Layout Management is refactored
    this.agGridAdapter.getAgGridApi().getColumns().forEach(col => {
      const colDef = col.getColDef();
      const colId = col.getColId();
      const abColumn = this.api.columnApi.getColumnWithColumnId(colId);
      const colSetupInfo = {
        col,
        colDef,
        colId,
        abColumn
      };
      shouldUpdateHeaders = this.agGridColumnAdapter.setupColumnHeader(colSetupInfo) || shouldUpdateHeaders;
    });
    isChanged = isChanged || shouldUpdateHeaders;
    if (isChanged) {
      // it's important we set pivot mode
      // before we set column state
      // as otherwise column order is not preserved properly when
      // going from pivoted to unpivoted layout
      if (shouldUpdatePivoted) {
        this.agGridAdapter.setGridOption('pivotMode', pivoted);
      }
      const perfApplyColumnState = this.logger.beginPerf('applyColumnState (layout.isChanged)');
      this.agGridAdapter.getAgGridApi().applyColumnState({
        state: newColumnsState,
        applyOrder: true
      });
      perfApplyColumnState.end();
      this.api.gridApi.setColumnSorts(layout.ColumnSorts);
      this.agGridAdapter.getAgGridApi().setPivotColumns(layout.PivotColumns || []);
      // aggrid 25.1.0 introduced a bug such that a layout that has a grouped column, if the column has enableRowGroup: true but not rowGroup: true
      // the group column is not possitioned correctly at the start of the layout
      // see the test in layout/layout-switch/"should be able to switch from grouped to non-grouped and back"
      // so we figured out the following line fixes the issue
      // this.gridOptions.api.setColumnDefs(this.gridOptions.api.getColumnDefs()); //TODO find a solution for weighted averages - column floating filters are not showing
      // these updates need to be at the end, the methods are based on the grid state/col defs
      // the layout needs to be applied for them to work
      this.updateRowGroupsExpandedState(layout);
    }
    const colsToAutoSizeArray = Object.keys(colsToAutoSize);
    if (pivoted && ((_d = (_c = this.adaptableOptions) === null || _c === void 0 ? void 0 : _c.layoutOptions) === null || _d === void 0 ? void 0 : _d.autoSizeColumnsInPivotLayout)) {
      // when a pivoted layout loads, autosize all cols
      requestAnimationFrame(() => {
        this.agGridAdapter.getAgGridApi().autoSizeAllColumns();
      });
      // //but if it's also the first time the grid is loading
      // //it's not timely enough the above call, so we keep trying... I know it's ugly, we need to find a better way
      // setTimeout(() => {
      //   this.agGridAdapter.getAgGridApi().autoSizeAllColumns();
      //   setTimeout(() => {
      //     this.agGridAdapter.getAgGridApi().autoSizeAllColumns();
      //   }, 200);
      // }, 100);
    } else {
      if (((_f = (_e = this.adaptableOptions) === null || _e === void 0 ? void 0 : _e.layoutOptions) === null || _f === void 0 ? void 0 : _f.autoSizeColumnsInLayout) && colsToAutoSizeArray.length) {
        requestAnimationFrame(() => {
          this.autoSizeColumns(colsToAutoSizeArray);
        });
      }
    }
    this.forPlugins(plugin => {
      if (plugin.afterSetLayout) {
        plugin.afterSetLayout(this, layout);
      }
    });
    perfSetLayout.end();
  }
  getActiveAdaptableAggFuncForCol(columnId) {
    if (!columnId) {
      return null;
    }
    const currentLayout = this.api.layoutApi.getCurrentLayout();
    const aggregationFunctionsColumnsMap = currentLayout.AggregationColumns || {};
    const adaptableAggFunc = aggregationFunctionsColumnsMap[columnId];
    if (typeof adaptableAggFunc === 'object' && 'type' in adaptableAggFunc && adaptableAggFunc.type === 'weightedAverage') {
      return adaptableAggFunc;
    }
    return null;
  }
  onRowDataChanged({
    rowNode,
    oldData,
    newData
  }) {
    if (oldData == null || oldData == undefined) {
      return;
    }
    if (oldData == newData) {
      return;
    }
    const primaryKeyValue = this.getPrimaryKeyValueFromRowNode(rowNode);
    if (!primaryKeyValue) {
      return;
    }
    // rowNode = this.getRowNodeForPrimaryKey(primaryKeyValue);
    let cellDataChangedInfos = [];
    Object.keys(oldData).forEach(key => {
      if (this.api.columnApi.isColumnInGrid(key)) {
        const oldValue = oldData[key];
        const newValue = newData[key];
        if (oldValue != newValue) {
          const cellDataChangedInfo = this.api.internalApi.buildDataChangedInfo({
            oldValue: oldValue,
            newValue: newValue,
            column: this.api.columnApi.getColumnWithColumnId(key),
            primaryKeyValue: primaryKeyValue,
            rowNode: rowNode,
            trigger: 'tick'
          });
          if (this.isUndoChange(cellDataChangedInfo)) {
            cellDataChangedInfo.trigger = 'undo';
          }
          cellDataChangedInfos.push(cellDataChangedInfo);
        }
      }
    });
    this.performPostEditChecks(cellDataChangedInfos);
  }
  onCellDataChanged({
    rowNode,
    oldValue,
    newValue,
    colId
  }) {
    if (oldValue == newValue) {
      return;
    }
    const abColumn = this.api.columnApi.getColumnWithColumnId(colId);
    if (!abColumn) {
      return;
    }
    if (this.isGroupRowNode(rowNode)) {
      const cellDataChangedInfo = this.api.internalApi.buildDataChangedInfo({
        oldValue: oldValue,
        newValue: newValue,
        column: abColumn,
        /**
         * A grouped row does not have an underling data item.
         * Because of this we use the rowNode.id as an identifier.
         */
        primaryKeyValue: rowNode.id,
        rowNode: rowNode,
        trigger: 'aggChange'
      });
      this.DataService.CreateDataChangedEvent(cellDataChangedInfo);
      return;
    }
    const primaryKeyValue = this.getPrimaryKeyValueFromRowNode(rowNode);
    if (!primaryKeyValue) {
      return;
    }
    const cellDataChangedInfo = this.api.internalApi.buildDataChangedInfo({
      oldValue: oldValue,
      newValue: newValue,
      column: abColumn,
      primaryKeyValue: primaryKeyValue,
      rowNode: rowNode,
      trigger: 'edit'
    });
    if (this.isUndoChange(cellDataChangedInfo)) {
      cellDataChangedInfo.trigger = 'undo';
    }
    this.performPostEditChecks([cellDataChangedInfo]);
  }
  isUndoChange(dataChange) {
    // check if this is not a reverted change
    const undoChange = this.api.internalApi.getDataService().extractUndoChange(dataChange);
    return !!undoChange;
  }
  /**
   * There are a few things that we need to do AFTER we edit a cell and it makes sense to put them in one place
   */
  performPostEditChecks(cellDataChangedInfos) {
    const firstInfo = cellDataChangedInfos[0];
    if (!firstInfo || !firstInfo.rowNode) {
      return;
    }
    cellDataChangedInfos.forEach(cellDataChangedInfo => {
      if (cellDataChangedInfo.trigger === 'undo') {
        this.logger.info(`Undo data change: PK(${cellDataChangedInfo.primaryKeyValue}) Col(${cellDataChangedInfo.column}) RevertedValue(${cellDataChangedInfo.oldValue}) OriginalValue(${cellDataChangedInfo.newValue})`);
      }
      if (cellDataChangedInfo.trigger === 'edit' || cellDataChangedInfo.trigger === 'undo') {
        this.checkChangedCellCurrentlySelected(cellDataChangedInfo);
        this.api.freeTextColumnApi.internalApi.handleFreeTextColumnDataChange(cellDataChangedInfo);
      }
      this.DataService.CreateDataChangedEvent(cellDataChangedInfo);
      this.resetMinMaxCachedValueForColumn(cellDataChangedInfo.column);
    });
    this.refreshCellsBasedOnCellDataChange(cellDataChangedInfos);
    firstInfo.trigger == 'tick' ? this.filterOnTickingDataChange() : this.filterOnEditDataChange();
  }
  refreshCellsBasedOnCellDataChange(cellDataChangedInfos) {
    const [firstInfo] = cellDataChangedInfos;
    // if node is visible then check if need to refresh other columns / whole row if the updating column is:
    // 1. referenced in Format Column Styles that have Expressions (refreshing whole row if Scope is All)
    // 2. referenced in Format Column styles that use Column Comparisons (which might also be calculated columns)
    if (this.agGridAdapter.isVisibleNode(firstInfo.rowNode)) {
      let dataChangedScope = {
        wholeRow: false,
        columnIds: new Set()
      };
      this.getFormatColumnExpressionStylesChanges(dataChangedScope, cellDataChangedInfos);
      if (dataChangedScope.wholeRow === false) {
        this.getFormatColumnPredicateStyleChanges(dataChangedScope, cellDataChangedInfos);
      }
      if (dataChangedScope.wholeRow) {
        this.redrawRow(firstInfo.rowNode);
      } else {
        this.getStyledColumnComparisonChanges(dataChangedScope, cellDataChangedInfos);
        if (dataChangedScope.columnIds.size > 0) {
          this.refreshCells([firstInfo.rowNode], Array.from(dataChangedScope.columnIds.values()), true);
        }
      }
    }
    this.refreshColumnForRelativeRangeStyledColumns(cellDataChangedInfos);
  }
  refreshColumnForRelativeRangeStyledColumns(cellDataChangedInfos) {
    const columnIdMap = new Set();
    cellDataChangedInfos.forEach(cellDataChangeInfo => {
      const styledColumn = this.api.styledColumnApi.getActiveStyledColumnForColumn(cellDataChangeInfo.column);
      if (styledColumn && this.api.styledColumnApi.internalApi.hasStyledColumnRelativeCellRange(styledColumn)) {
        columnIdMap.add(styledColumn.ColumnId);
      }
    });
    const columnIdsToUpdate = [...columnIdMap];
    if (columnIdsToUpdate.length) {
      this.refreshColumns(columnIdsToUpdate, true);
    }
  }
  getStyledColumnComparisonChanges(dataChangedScope, cellDataChangedInfos) {
    this.api.styledColumnApi.getStyledColumns().forEach(sc => {
      let columnComparison = this.api.styledColumnApi.internalApi.getColumnComparisonForStyledColumn(sc);
      if (columnComparison) {
        let affectedColumnIds = this.api.styledColumnApi.internalApi.getColumnIdsFromColumnComparison(columnComparison);
        if (ArrayExtensions.IsNotNullOrEmpty(affectedColumnIds)) {
          cellDataChangedInfos.forEach(cellDataChangedInfo => {
            if (affectedColumnIds.includes(cellDataChangedInfo.column.columnId)) {
              dataChangedScope.columnIds.add(sc.ColumnId);
            }
          });
        }
      }
    });
  }
  getFormatColumnPredicateStyleChanges(dataChangedScope, cellDataChangedInfos) {
    cellDataChangedInfos.forEach(cellDataChangeInfo => {
      const dependentColumns = this.api.formatColumnApi.internalApi.getFormatColumnColumnsDependentOnColumnChange(cellDataChangeInfo.column);
      for (let colId of dependentColumns) {
        dataChangedScope.columnIds.add(colId);
      }
    });
  }
  getFormatColumnExpressionStylesChanges(dataChangedScope, cellDataChangedInfos) {
    const formatColumnsWithExpression = [];
    formatColumnsWithExpression.push(...this.api.formatColumnApi.internalApi.getFormatColumnsWithExpression());
    if (ArrayExtensions.IsNullOrEmpty(formatColumnsWithExpression)) {
      return;
    }
    cellDataChangedInfos.forEach(cellDataChangedInfo => {
      if (!dataChangedScope.wholeRow) {
        formatColumnsWithExpression.forEach(styleModule => {
          if (!dataChangedScope.wholeRow) {
            const columnIds = this.api.expressionApi.getColumnsFromExpression(styleModule.Rule.BooleanExpression);
            if (columnIds.includes(cellDataChangedInfo.column.columnId)) {
              if (this.api.columnScopeApi.scopeIsAll(styleModule.Scope)) {
                dataChangedScope.wholeRow = true;
                return;
              } else {
                this.api.columnScopeApi.getColumnsForScope(styleModule.Scope).map(c => c.columnId).forEach(colId => {
                  dataChangedScope.columnIds.add(colId);
                });
              }
            }
          }
        });
      }
    });
  }
  checkChangedCellCurrentlySelected(cellDataChangedInfo) {
    let selectedCellInfo = this.api.gridApi.getSelectedCellInfo();
    if (selectedCellInfo && ArrayExtensions.IsNotNullOrEmpty(selectedCellInfo.gridCells)) {
      let matchingCell = selectedCellInfo.gridCells.find(gc => gc.primaryKeyValue == cellDataChangedInfo.primaryKeyValue && gc.column == cellDataChangedInfo.column);
      if (matchingCell) {
        this.refreshSelectedCellsState();
      }
    }
    let selectedRowInfo = this.api.gridApi.getSelectedRowInfo();
    if (selectedRowInfo && ArrayExtensions.IsNotNullOrEmpty(selectedRowInfo.gridRows)) {
      let matchingRow = selectedRowInfo.gridRows.find(gr => gr.primaryKeyValue == cellDataChangedInfo.primaryKeyValue);
      if (matchingRow) {
        this.refreshSelectedRowsState();
      }
    }
  }
  resetMinMaxCachedValueForColumn(column) {
    if (!column) {
      this.columnMinMaxValuesCache[column.columnId] = {};
    }
    if (this.columnMinMaxValuesCache[column.columnId]) {
      this.columnMinMaxValuesCache[column.columnId] = undefined;
    }
  }
  filterOnTickingDataChange() {
    var _a;
    if (this.adaptableOptions.columnFilterOptions.filterActionOnExternalDataChange.applyFilter == FilterOnDataChangeOptions.Always) {
      (_a = this.agGridAdapter.getAgGridApi()) === null || _a === void 0 ? void 0 : _a.onFilterChanged();
    } else if (this.adaptableOptions.columnFilterOptions.filterActionOnExternalDataChange.applyFilter == FilterOnDataChangeOptions.Throttle) {
      this.throttleFilterOnTickingDataChange();
    }
  }
  filterOnEditDataChange() {
    var _a;
    if (this.adaptableOptions.columnFilterOptions.filterActionOnUserDataChange.applyFilter == FilterOnDataChangeOptions.Always) {
      (_a = this.agGridAdapter.getAgGridApi()) === null || _a === void 0 ? void 0 : _a.onFilterChanged();
    } else if (this.adaptableOptions.columnFilterOptions.filterActionOnUserDataChange.applyFilter == FilterOnDataChangeOptions.Throttle) {
      this.throttleFilterOnEditDataChange();
    }
  }
  shouldCreateDefaultLayout(adaptableState, adaptableOptions) {
    var _a, _b, _c;
    const layoutState = adaptableState.Layout || {};
    if (((_a = adaptableOptions.layoutOptions) === null || _a === void 0 ? void 0 : _a.createDefaultLayout) && !((_b = layoutState.Layouts) === null || _b === void 0 ? void 0 : _b.find(layout => layout.Name === DEFAULT_LAYOUT))) {
      return true;
    }
    return !((_c = layoutState.Layouts) === null || _c === void 0 ? void 0 : _c.length);
  }
  createDefaultLayout(state, agGridOptions) {
    var _a, _b, _c;
    const agGridColDefs = agGridOptions.columnDefs;
    const agGridAutoGroupColDef = agGridOptions.autoGroupColumnDef;
    const allColumnDefs = this.agGridAdapter.getFlattenedColDefs(agGridColDefs);
    const defaultLayout = ObjectFactory.CreateEmptyLayout({
      Name: DEFAULT_LAYOUT,
      Columns: allColumnDefs.map(c => c.colId),
      AggregationColumns: allColumnDefs.reduce((acc, col) => {
        if (typeof col.aggFunc === 'string') {
          acc[col.colId] = col.aggFunc;
        }
        return acc;
      }, {}),
      PinnedColumnsMap: allColumnDefs.reduce((acc, col) => {
        const pinned = col.pinned;
        if (pinned) {
          acc[col.colId] = pinned === true ? 'left' : pinned;
        }
        return acc;
      }, {}),
      RowGroupedColumns: allColumnDefs.reduce((acc, col) => {
        if (col.rowGroup) {
          acc.push(col.colId);
        }
        return acc;
      }, [])
    });
    // handle optional 'gridOptions.autoGroupColumnDefs' parameter
    if (agGridAutoGroupColDef && (((_a = defaultLayout.RowGroupedColumns) === null || _a === void 0 ? void 0 : _a.length) || agGridOptions.treeData)) {
      defaultLayout.Columns = [AG_GRID_GROUPED_COLUMN, ...defaultLayout.Columns];
    }
    if (agGridAutoGroupColDef === null || agGridAutoGroupColDef === void 0 ? void 0 : agGridAutoGroupColDef.pinned) {
      defaultLayout.PinnedColumnsMap[AG_GRID_GROUPED_COLUMN] = agGridAutoGroupColDef.pinned === true ? 'left' : agGridAutoGroupColDef.pinned;
    }
    if (agGridAutoGroupColDef === null || agGridAutoGroupColDef === void 0 ? void 0 : agGridAutoGroupColDef.width) {
      defaultLayout.ColumnWidthMap[AG_GRID_GROUPED_COLUMN] = agGridAutoGroupColDef.width;
    }
    // ADD special columns
    const calculatedColumns = ((_b = state.CalculatedColumn) === null || _b === void 0 ? void 0 : _b.CalculatedColumns) || [];
    if (calculatedColumns.length) {
      defaultLayout.Columns.push(...calculatedColumns.map(c => c.ColumnId));
    }
    const freeTextColumns = ((_c = state.FreeTextColumn) === null || _c === void 0 ? void 0 : _c.FreeTextColumns) || [];
    if (freeTextColumns.length) {
      defaultLayout.Columns.push(...freeTextColumns.map(c => c.ColumnId));
    }
    return defaultLayout;
  }
  /*
   * This is the opposite of setLayout
   */
  updateLayoutFromGrid() {
    var _a, _b;
    this.logger.info('updateLayoutFromGrid()');
    const agGridApi = this.agGridAdapter.getAgGridApi();
    const columnState = agGridApi.getColumnState();
    const expandedState = agGridApi.getState().rowGroupExpansion || {
      expandedRowGroupIds: []
    };
    const currentLayoutState = {
      columnState,
      expandedState
    };
    try {
      const stringifiedLayoutState = JSON.stringify(currentLayoutState);
      if (stringifiedLayoutState === this.previousAgGridLayoutState) {
        // same grid column state as a previous,
        // so no need to update, as the layout has already been updated
        // for this grid column state
        return;
      }
      this.previousAgGridLayoutState = stringifiedLayoutState;
    } catch (ex) {
      this.logger.consoleError('Error stringifying column state', ex);
    }
    const currentLayout = this.api.layoutApi.getCurrentLayout();
    if (currentLayout.IsReadOnly) {
      // reaply the layout so the grid is reverted
      this.setLayout();
      return;
    }
    const layout = Object.assign({}, currentLayout);
    let columnOrder = [];
    const columnFlexes = {};
    const pinnedColumns = {};
    const columnSorts = [];
    let groupedColumns = [...new Array(columnState.length)];
    let pivotedColumns = [...new Array(columnState.length)];
    const pivotColumns = [];
    const aggregatedColumns = {};
    const columnWidths = columnState.reduce((acc, colState) => {
      const {
        colId
      } = colState;
      if (colState.sort && colState.sortIndex != null) {
        columnSorts.push({
          ColumnId: colId,
          SortOrder: colState.sort === 'asc' ? 'Asc' : 'Desc',
          SortIndex: colState.sortIndex
        });
      }
      if (colState.width != null) {
        acc[colId] = colState.width;
      }
      if (colState.flex != null) {
        columnFlexes[colId] = colState.flex;
      }
      if (colState.pinned === 'left') {
        pinnedColumns[colId] = 'left';
      }
      if (colState.pinned === 'right') {
        pinnedColumns[colId] = 'right';
      }
      if (!colState.hide) {
        columnOrder.push(colId);
      }
      if (colState.rowGroupIndex != null) {
        groupedColumns[colState.rowGroupIndex] = colId;
      }
      if (colState.pivotIndex != null) {
        pivotedColumns[colState.pivotIndex] = colId;
      }
      if (colState.aggFunc && typeof colState.aggFunc === 'string') {
        aggregatedColumns[colId] = colState.aggFunc;
      }
      return acc;
    }, {});
    columnSorts.sort((a, b) => a.SortIndex - b.SortIndex);
    this.agGridAdapter.getAgGridApi().getPivotColumns().forEach(col => {
      pivotColumns.push(col.getColId());
    });
    groupedColumns = groupedColumns.filter(x => !!x);
    pivotedColumns = pivotedColumns.filter(x => !!x);
    if ((_b = (_a = this.adaptableOptions) === null || _a === void 0 ? void 0 : _a.groupingOptions) === null || _b === void 0 ? void 0 : _b.restoreUngroupedColumns) {
      columnOrder = this.restoreUnGroupColumnOrder({
        columnOrder,
        newGroupColumns: groupedColumns
      });
    }
    layout.ColumnWidthMap = columnWidths;
    //  layout.ColumnFlexMap = columnFlexes;
    layout.PinnedColumnsMap = pinnedColumns;
    layout.Columns = columnOrder;
    layout.ColumnSorts = columnSorts;
    layout.RowGroupedColumns = groupedColumns;
    if (Object.keys(aggregatedColumns).length) {
      // AG Grid aggregations are not 1-1 with adaptable column-aggregations
      layout.AggregationColumns = this.mapAggregationColumnsFromGrid(aggregatedColumns, currentLayout);
    } else {
      layout.AggregationColumns = {};
    }
    layout.EnablePivot = this.agGridAdapter.getAgGridApi().isPivotMode();
    layout.PivotColumns = pivotColumns;
    if (
    // check first row node for presence of data without iterating over whole grid
    this.api.layoutApi.internalApi.areExpandedRowGroupsSavedInLayouts() && this.api.gridApi.getFirstDisplayedRowNode()) {
      layout.ExpandedRowGroupValues = this.getExpandRowGroupsKeys();
    }
    const previousVisibleColumns = currentLayout.Columns;
    const newVisibleColumns = layout.Columns;
    // check if all new columns were previously visible
    const someColumnsAreNew = newVisibleColumns.some(colId => !previousVisibleColumns.includes(colId));
    if (someColumnsAreNew) {
      this.updateColumnModelAndRefreshGrid();
    }
    this.persistLayout(layout);
  }
  persistLayout(layout) {
    if (this.api.layoutApi.shouldAutoSaveLayouts()) {
      this.api.layoutApi.createOrUpdateLayout(layout);
    } else {
      this.api.layoutApi.internalApi.updateCurrentDraftLayout(layout);
    }
  }
  /**
   * When reading the state from the grid, we have to make sure 'avg' is not overriden with the 'avg' string.
   */
  mapAggregationColumnsFromGrid(aggFuncFromGrid, currentLayout) {
    return Object.entries(aggFuncFromGrid).reduce((acc, [colId, agg]) => {
      const adaptableAggFunc = this.getActiveAdaptableAggFuncForCol(colId);
      if ((adaptableAggFunc === null || adaptableAggFunc === void 0 ? void 0 : adaptableAggFunc.type) === 'weightedAverage' && agg === WEIGHTED_AVERAGE_AGG_FN_NAME) {
        // do not override the agg func
        acc[colId] = currentLayout.AggregationColumns[colId];
      } else {
        acc[colId] = agg;
      }
      return acc;
    }, {});
  }
  persistColumnIndexBeforeGrouping(params) {
    const layout = this.api.layoutApi.getCurrentLayout();
    const columnGroupsInLayout = layout.RowGroupedColumns;
    const columnGroupsInGrid = params.columns.map(col => col.getColId());
    // what is new
    const newGroups = columnGroupsInGrid.filter(colId => !columnGroupsInLayout.includes(colId));
    newGroups.forEach(colId => {
      const columnIndex = layout.Columns.filter(colId => !(this.api.columnApi.isAutoRowGroupColumn(colId) || this.api.columnApi.isAutoPivotColumn(colId))).findIndex(columnIdInLayout => columnIdInLayout === colId);
      // user may group after a column not in layout
      if (columnIndex !== -1) {
        this.api.internalApi.persistPreviousGroupedColumnsIndex(layout.Uuid, colId, columnIndex);
      }
    });
  }
  /**
   * Restores the order previous grouping order.
   */
  restoreUnGroupColumnOrder({
    newGroupColumns,
    columnOrder
  }) {
    var _a;
    const newColumnOrder = [...columnOrder];
    const currentLayout = this.api.layoutApi.getCurrentLayout();
    const previousGroupedColumnsIndex = this.api.internalApi.getPreviousGroupedColumnsIndex(currentLayout.Uuid);
    const isUnGroup = newGroupColumns.length < ((_a = currentLayout === null || currentLayout === void 0 ? void 0 : currentLayout.RowGroupedColumns) === null || _a === void 0 ? void 0 : _a.length);
    if (!isUnGroup) {
      return columnOrder;
    }
    if (!previousGroupedColumnsIndex) {
      return columnOrder;
    }
    for (let [colId, previousIndex] of Object.entries(previousGroupedColumnsIndex)) {
      const isStillGrouped = newGroupColumns.includes(colId);
      const isAlreadyInGrid = currentLayout.Columns.includes(colId); // was not just added by ungrouping
      if (isStillGrouped) {
        continue;
      }
      if (isAlreadyInGrid) {
        // no longer grouped, but already in grid, this means it was already in grid before ungrouping
        // in this case the state can be cleared
        this.api.internalApi.persistPreviousGroupedColumnsIndex(currentLayout.Uuid, colId, null);
        continue;
      }
      // need to ajust index based if grouped
      const numberOfGroupedColumns = newColumnOrder.filter(colId => this.api.columnApi.isAutoRowGroupColumn(colId)).length;
      const adjustedPreviousIndex = previousIndex + numberOfGroupedColumns;
      const indexInGrid = newColumnOrder.indexOf(colId);
      const hasDifferentPositionAsPreviousLayout = adjustedPreviousIndex !== null &&
      // if null, the reorder was already applied
      indexInGrid > 0 &&
      // needs to be in grid
      adjustedPreviousIndex !== indexInGrid;
      const isPreviousPositionInRange = adjustedPreviousIndex < newColumnOrder.length;
      if (hasDifferentPositionAsPreviousLayout && isPreviousPositionInRange) {
        newColumnOrder.splice(indexInGrid, 1);
        newColumnOrder.splice(adjustedPreviousIndex, 0, colId);
      }
      this.api.internalApi.persistPreviousGroupedColumnsIndex(currentLayout.Uuid, colId, null);
    }
    return newColumnOrder;
  }
  onSortChanged() {
    const columnSorts = this.getColumnSorts();
    this.api.gridApi.setColumnSorts(columnSorts);
    this._emit('SortChanged', columnSorts);
  }
  getColumnSorts() {
    const columnSorts = [];
    const columnState = this.agGridAdapter.getAgGridApi().getColumnState();
    columnState.forEach(colState => {
      const {
        colId
      } = colState;
      if (colState.sort && colState.sortIndex != null) {
        columnSorts.push({
          ColumnId: colId,
          SortOrder: colState.sort === 'asc' ? 'Asc' : 'Desc',
          SortIndex: colState.sortIndex
        });
      }
    });
    columnSorts.sort((a, b) => a.SortIndex - b.SortIndex);
    return columnSorts.map(c => {
      return {
        ColumnId: c.ColumnId,
        SortOrder: c.SortOrder
      };
    });
  }
  // we need to intercept some of the GridOptions updates and refresh the Adaptable state
  monkeyPatchingGridOptionsUpdates(agGridApi) {
    var _a;
    const gridOptionsService = (_a = this.DANGER_getPrivateAgGridBeans()) === null || _a === void 0 ? void 0 : _a.gos;
    if (!gridOptionsService) {
      this.logger.consoleError('Could not get hold of GridOptionsService! This is a critical error and will prevent Adaptable from working correctly.');
    }
    const self = this;
    gridOptionsService.updateGridOptions = function ({
      options,
      force,
      source = 'api'
    }) {
      // `columnDefs`
      const passedColumnDefs = options.columnDefs;
      if (passedColumnDefs) {
        const colDefsWithSpecialColumns = self.getColumnDefinitionsInclSpecialColumns(passedColumnDefs);
        const allDisplayedColIds = self.agGridAdapter.getAgGridApi().getAllDisplayedColumns().map(col => col.getColId());
        // mark as hidden the colDefs of special columns which are not visible
        self.agGridAdapter.patchColDefs(colDefsWithSpecialColumns, colDef => {
          if (self.api.columnApi.isSpecialColumn(colDef.colId)) {
            colDef.hide = !allDisplayedColIds.includes(colDef.colId);
          }
        });
        options['columnDefs'] = colDefsWithSpecialColumns;
        self.logger.info(`Added SpecialColumns on GridOptions.columnDefs update (source=${source})`);
      }
      // `context`
      const passedContext = options.context;
      if (passedContext) {
        passedContext['__adaptable'] = self;
        passedContext['adaptableApi'] = self.api;
      }
      // we mutated the options array, so it's OK to use the 'arguments' object
      GridOptionsService_updateGridOptions.apply(this, arguments);
    };
  }
  DANGER_getPrivateAgGridBeans() {
    const beans = DANGER_AG_GRID_BEANS_MAP[this._agGridId];
    if (!beans) {
      this.logger.consoleError('Could not get hold of AgGridBeans! This is a critical error and will prevent Adaptable from working correctly.');
    }
    return beans;
  }
}