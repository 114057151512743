import { EMPTY_ARRAY } from '../../Utilities/Constants/GeneralConstants';
import AdaptableHelper from '../../Utilities/Helpers/AdaptableHelper';
import { changeIsSuspendInList, suspendAllInList, unsuspendAllInList } from './utils';
/**
 * @ReduxAction A Custom Sort has been added
 */
export const CUSTOM_SORT_ADD = 'CUSTOM_SORT_ADD';
/**
 * @ReduxAction A Custom Sort has been edited
 */
export const CUSTOM_SORT_EDIT = 'CUSTOM_SORT_EDIT';
/**
 * @ReduxAction A Custom Sort has been deleted
 */
export const CUSTOM_SORT_DELETE = 'CUSTOM_SORT_DELETE';
/**
 * @ReduxAction A Custom Sort has been suspended
 */
export const CUSTOM_SORT_SUSPEND = 'CUSTOM_SORT_SUSPEND';
/**
 * @ReduxAction A Custom Sort has been unsuspended (activated)
 */
export const CUSTOM_SORT_UNSUSPEND = 'CUSTOM_SORT_UNSUSPEND';
/**
 * @ReduxAction Suspend all Custom Sorts
 */
export const CUSTOM_SORT_SUSPEND_ALL = 'CUSTOM_SORT_SUSPEND_ALL';
/**
 * @ReduxAction Unsuspend all Custom Sorts
 */
export const CUSTOM_SORT_UNSUSPEND_ALL = 'CUSTOM_SORT_UNSUSPEND_ALL';
/**
 * @ReduxAction Custom Sort Module is ready
 */
export const CUSTOM_SORT_READY = 'CUSTOM_SORT_READY';
export const CustomSortAdd = customSort => ({
  type: CUSTOM_SORT_ADD,
  customSort
});
export const CustomSortEdit = customSort => ({
  type: CUSTOM_SORT_EDIT,
  customSort
});
export const CustomSortDelete = customSort => ({
  type: CUSTOM_SORT_DELETE,
  customSort
});
export const CustomSortSuspend = customSort => ({
  type: CUSTOM_SORT_SUSPEND,
  customSort
});
export const CustomSortUnSuspend = customSort => ({
  type: CUSTOM_SORT_UNSUSPEND,
  customSort
});
export const CustomSortSuspendAll = () => ({
  type: CUSTOM_SORT_SUSPEND_ALL
});
export const CustomSortUnSuspendAll = () => ({
  type: CUSTOM_SORT_UNSUSPEND_ALL
});
export const CustomSortReady = customSortState => ({
  type: CUSTOM_SORT_READY,
  customSortState
});
const initialState = {
  CustomSorts: EMPTY_ARRAY
};
export const CustomSortReducer = (state = initialState, action) => {
  let customSorts;
  switch (action.type) {
    case CUSTOM_SORT_ADD:
      {
        const actionCustomSort = action.customSort;
        AdaptableHelper.addUuidAndSource(actionCustomSort);
        customSorts = [].concat(state.CustomSorts);
        customSorts.push(actionCustomSort);
        return Object.assign(Object.assign({}, state), {
          CustomSorts: customSorts
        });
      }
    case CUSTOM_SORT_EDIT:
      {
        const actionCustomSort = action.customSort;
        return Object.assign(Object.assign({}, state), {
          CustomSorts: state.CustomSorts.map(abObject => abObject.Uuid === actionCustomSort.Uuid ? actionCustomSort : abObject)
        });
      }
    case CUSTOM_SORT_DELETE:
      {
        const actionCustomSort = action.customSort;
        return Object.assign(Object.assign({}, state), {
          CustomSorts: state.CustomSorts.filter(abObject => abObject.Uuid !== actionCustomSort.Uuid)
        });
      }
    case CUSTOM_SORT_SUSPEND:
      {
        return Object.assign(Object.assign({}, state), {
          CustomSorts: changeIsSuspendInList(action.customSort, state.CustomSorts, true)
        });
      }
    case CUSTOM_SORT_UNSUSPEND:
      {
        return Object.assign(Object.assign({}, state), {
          CustomSorts: changeIsSuspendInList(action.customSort, state.CustomSorts, false)
        });
      }
    case CUSTOM_SORT_SUSPEND_ALL:
      {
        return Object.assign(Object.assign({}, state), {
          CustomSorts: suspendAllInList(state.CustomSorts)
        });
      }
    case CUSTOM_SORT_UNSUSPEND_ALL:
      {
        return Object.assign(Object.assign({}, state), {
          CustomSorts: unsuspendAllInList(state.CustomSorts)
        });
      }
    default:
      return state;
  }
};