import * as React from 'react';
import { useState } from 'react';
import { Box } from 'rebass';
import { cloneObject } from '../../../Utilities/Helpers/Helper';
import { OnePageAdaptableWizard, OnePageWizardSummary } from '../../Wizard/OnePageAdaptableWizard';
import { CalculatedColumnExpressionWizardSection, isValidCalculatedColumnExpression, renderCalculatedColumnExpressionSummary } from './CalculatedColumnExpressionWizardSection';
import { CalculatedColumnDefinitionWizardSection, isValidCalculatedColumnDefinition, renderCalculatedColumnDefinitionSummary } from './CalculatedColumnDefinitionWizardSection';
import { useAdaptable } from '../../AdaptableContext';
import ObjectFactory from '../../../Utilities/ObjectFactory';
import { useDispatch, useSelector } from 'react-redux';
import * as CalculatedColumnRedux from '../../../Redux/ActionsReducers/CalculatedColumnRedux';
import { ObjectTagsWizardSection, renderObjectTagsSummary } from '../../Wizard/ObjectTagsWizardSection';
import { CalculatedColumnSettingsWizardSection, isValidCalculatedColumnSettings, renderCalculatedColumnSettingsSummary } from './CalculatedColumnSettingsWizardSection';
import { getExpressionType } from '../utils';
import { Humanize } from '../../../Utilities/Extensions/StringExtensions';
import { Tag } from '../../../components/Tag';
import { CalculatedColumnTypeWizardSection } from './CalculatedColumnTypeSection';
export const calculatedColumnTypes = ['ScalarExpression', 'AggregatedScalarExpression', 'CumulativeAggregatedExpression', 'QuantileAggregatedExpression'];
export const CalculatedColumnWizard = props => {
  var _a;
  const adaptable = useAdaptable();
  const allCalculatedColumns = useSelector(state => state.CalculatedColumn.CalculatedColumns);
  const [calculatedColumn, setCalculatedColumn] = useState(() => {
    var _a;
    if (props.data) {
      return cloneObject(props.data);
    }
    if ((_a = props.popupParams) === null || _a === void 0 ? void 0 : _a.column) {
      const calculatedColumn = allCalculatedColumns.find(calculatedColumn => calculatedColumn.ColumnId === props.popupParams.column.columnId);
      if (calculatedColumn) {
        return cloneObject(calculatedColumn);
      }
    }
    return ObjectFactory.CreateEmptyCalculatedColumn(adaptable.api.gridApi.internalApi.deriveSpecialColumnSettingsFromAgGridDefaultColDef());
  });
  const dispatch = useDispatch();
  const isEdit = Boolean(props.data) || ((_a = props.popupParams) === null || _a === void 0 ? void 0 : _a.action) === 'Edit';
  const handleFinish = () => {
    if (isEdit) {
      dispatch(CalculatedColumnRedux.CalculatedColumnEdit(calculatedColumn));
    } else {
      dispatch(CalculatedColumnRedux.CalculatedColumnAdd(calculatedColumn));
    }
    props.onCloseWizard();
  };
  const [expressionType, setExpressionType] = useState(() => {
    var _a;
    return (_a = getExpressionType(calculatedColumn, adaptable.api)) !== null && _a !== void 0 ? _a : 'ScalarExpression';
  });
  const handleExpressionTypeChange = type => {
    setExpressionType(type);
    if (expressionType === 'ScalarExpression') {
      setCalculatedColumn(Object.assign(Object.assign({}, calculatedColumn), {
        Query: {
          ScalarExpression: ''
        }
      }));
    } else {
      // Aggregated, Cumulative & Quantile are all AggregatedScalarExpression
      setCalculatedColumn(Object.assign(Object.assign({}, calculatedColumn), {
        Query: {
          AggregatedScalarExpression: ''
        }
      }));
    }
  };
  return React.createElement(OnePageAdaptableWizard, {
    defaultCurrentSectionName: props.defaultCurrentSectionName,
    moduleInfo: props.moduleInfo,
    data: calculatedColumn,
    onHide: props.onCloseWizard,
    onFinish: handleFinish,
    sections: [{
      title: 'Type',
      renderSummary: () => {
        return React.createElement(Box, null, "Expression Type: ", React.createElement(Tag, null, Humanize(expressionType)));
      },
      render: () => {
        return React.createElement(Box, {
          p: 2
        }, React.createElement(CalculatedColumnTypeWizardSection, {
          type: expressionType,
          onTypeChange: handleExpressionTypeChange
        }));
      }
    }, {
      title: 'Details',
      details: 'Specify Calculated Column details',
      isValid: isValidCalculatedColumnDefinition,
      renderSummary: renderCalculatedColumnDefinitionSummary,
      render: () => {
        return React.createElement(Box, {
          padding: 2
        }, React.createElement(CalculatedColumnDefinitionWizardSection, {
          isEdit: isEdit,
          onChange: setCalculatedColumn
        }));
      }
    }, {
      title: 'Expression',
      details: 'Specify the Calculated Column Expression',
      isValid: isValidCalculatedColumnExpression,
      renderSummary: renderCalculatedColumnExpressionSummary,
      render: () => {
        return React.createElement(Box, {
          height: "100%",
          p: 2
        }, React.createElement(CalculatedColumnExpressionWizardSection, {
          expressionType: expressionType,
          onChange: setCalculatedColumn
        }));
      }
    }, {
      title: 'Settings',
      details: 'Specify Column properties',
      isValid: isValidCalculatedColumnSettings,
      renderSummary: renderCalculatedColumnSettingsSummary,
      render: () => {
        return React.createElement(Box, {
          padding: 2
        }, React.createElement(CalculatedColumnSettingsWizardSection, {
          isEdit: isEdit,
          onChange: setCalculatedColumn
        }));
      }
    }, {
      details: 'Select Calculated Column Tags',
      title: 'Tags',
      isVisible: () => adaptable.api.internalApi.shouldDisplayTagSections(),
      render: () => React.createElement(Box, {
        padding: 2
      }, React.createElement(ObjectTagsWizardSection, {
        onChange: setCalculatedColumn
      })),
      renderSummary: renderObjectTagsSummary
    }, '-', {
      render: () => {
        return React.createElement(Box, {
          padding: 2
        }, React.createElement(OnePageWizardSummary, null));
      },
      title: 'Summary'
    }]
  });
};