import { __rest } from "tslib";
import * as React from 'react';
import { isAgChartDefinition } from '../../../PredefinedConfig/ChartingState';
import { AgChargingWizard } from './AgChargingWizard';
import { ExternalChartingWizard } from './ExternalChartingWizard';
export const ChartingWizard = props => {
  const {
      data,
      configEntities
    } = props,
    other = __rest(props, ["data", "configEntities"]);
  if (isAgChartDefinition(data)) {
    const chartDef = data;
    return React.createElement(AgChargingWizard, Object.assign({}, other, {
      data: chartDef
    }));
  } else {
    const chartDef = data;
    return React.createElement(ExternalChartingWizard, Object.assign({}, other, {
      data: chartDef
    }));
  }
};