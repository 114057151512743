import { useState } from 'react';
const useSelection = (columns, defaultValue, fieldName, changeListeners) => {
  changeListeners = changeListeners || {};
  const onChange = changeListeners.onChange || ((column, flag) => {});
  const onBatchChange = changeListeners.onBatchChange || (flag => {});
  const [selected, setSelected] = useState(typeof defaultValue === 'object' ? defaultValue : columns.reduce((acc, col) => {
    const flag = fieldName ? col[fieldName] : defaultValue;
    if (flag) {
      acc[col.field] = flag;
    }
    return acc;
  }, {}));
  const getColumn = field => columns.filter(c => c.field === field)[0];
  const result = {
    selected,
    isSelected: field => !!selected[field],
    isAllSelected: () => Object.keys(selected).length === columns.length,
    isNoneSelected: () => Object.keys(selected).length === 0,
    selectColumn: field => {
      if (!selected[field]) {
        setSelected(Object.assign(Object.assign({}, selected), {
          [field]: true
        }));
        onChange(getColumn(field), true);
      }
    },
    deselectColumn: field => {
      if (selected[field]) {
        const newSelected = Object.assign({}, selected);
        delete newSelected[field];
        setSelected(newSelected);
        onChange(getColumn(field), false);
      }
    },
    toggleColumn: field => {
      if (result.isSelected(field)) {
        result.deselectColumn(field);
      } else {
        result.selectColumn(field);
      }
    },
    deselectAll: () => {
      setSelected({});
      columns.forEach(col => {
        onChange(col, false);
      });
      onBatchChange(false);
    },
    selectAll: () => {
      setSelected(columns.reduce((acc, col) => {
        acc[col.field] = true;
        return acc;
      }, {}));
      onBatchChange(true);
    }
  };
  return result;
};
export default useSelection;