import * as React from 'react';
import { useSelector } from 'react-redux';
export const GridFilterStatusbar = props => {
  var _a, _b;
  const queryMaxLength = 15;
  const currentLayoutName = useSelector(state => state.Layout.CurrentLayout);
  const currentLayout = useSelector(state => state.Layout.Layouts).find(layout => layout.Name === currentLayoutName);
  const query = (_b = (_a = currentLayout === null || currentLayout === void 0 ? void 0 : currentLayout.GridFilter) === null || _a === void 0 ? void 0 : _a.Expression) !== null && _b !== void 0 ? _b : '';
  let text = query;
  let popover = null;
  if (query.length > queryMaxLength) {
    text = query.substring(0, queryMaxLength - 3) + '...';
    popover = query;
  }
  return React.createElement(React.Fragment, null, text !== null && text !== void 0 ? text : 'No Query');
};