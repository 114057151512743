import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModuleManager } from '../../components/DragAndDropContext/ModuleManager';
import { DashboardSetPinnedToolbars } from '../../Redux/ActionsReducers/DashboardRedux';
import { useAdaptable } from '../AdaptableContext';
export const PinnedToolbarsSelector = props => {
  const {
    api
  } = useAdaptable();
  const dashboardAccessLevel = api.entitlementApi.getEntitlementAccessLevelForModule('Dashboard');
  const isDashboardDisabled = dashboardAccessLevel === 'ReadOnly';
  const dashboardState = useSelector(state => state.Dashboard);
  const dispatch = useDispatch();
  const pinnedToolbars = dashboardState.PinnedToolbars;
  const toolbars = React.useMemo(() => {
    const systemToolbars = api.dashboardApi.internalApi.getModuleToolbars().map(mt => ({
      Id: mt.id,
      Title: mt.friendlyName
    }));
    const customToolbars = api.dashboardApi.getCustomToolbars().map(ct => ({
      Id: ct.name,
      Title: ct.title ? ct.title : ct.name
    }));
    return [...systemToolbars, ...customToolbars];
  }, []);
  const tabs = React.useMemo(() => {
    return [{
      Name: 'Pinned Toolbars',
      Items: pinnedToolbars !== null && pinnedToolbars !== void 0 ? pinnedToolbars : []
    }];
  }, [pinnedToolbars]);
  return React.createElement(ModuleManager, {
    permittedActions: {
      createTab: false,
      dragAndDropTab: false,
      deleteTab: false,
      editTabName: false
    },
    onTabsChange: tabs => {
      dispatch(DashboardSetPinnedToolbars(tabs[0].Items));
    },
    disabled: isDashboardDisabled,
    tabs: tabs,
    availableItems: toolbars,
    tabsTitle: 'Pinned Toolbars',
    unusedPanelTitle: "Available Pinned Toolbars",
    dragItemText: "Drag into the Panel below"
  });
};