import { createContext, useContext } from 'react';
export const ExpressionEditorContext = createContext({
  textAreaRef: {
    current: null
  },
  selectedFunction: null,
  setSelectedFunction: _fn => {}
});
export const useExpressionEditor = () => {
  return useContext(ExpressionEditorContext);
};